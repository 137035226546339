import React from 'react';
import { useHistory } from 'react-router-dom';

import { Table, TableBody } from '@/component-style/Table';
import TableWrapper  from '@/components/UI/TableWrapper';
import Header from './Header';
import Item from './Item';

const TableInvitations = props => {
  const history = useHistory();

  const {
    invitationStatus, projectID, projectCode, projectName,
  } = props;

  return (
    <TableWrapper title = "媒體邀約列表">
      <Table>
        <Header
          showDetailButton = { props.showDetailButton }
        />
        <TableBody>
          {
            projectCode !== '' &&
            <Item
              projectCode = { projectCode }
              projectName = { projectName }
              invitationStatus = { invitationStatus }
              showDetailButton = { props.showDetailButton }
              goDetailHandler = { () => {
                history.push(`/Project/ReporterInvitation?id=${projectID}`);
              }}
            />
          }
        </TableBody>
      </Table>
    </TableWrapper>
  );
};

export default TableInvitations;
