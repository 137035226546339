import React from 'react';
import styled from 'styled-components';

import { TdItem, TableRow } from '@/component-style/Table';

import { ButtonCancel } from '@/component-style/Button';

const TrWrapper = styled(TableRow)`
  border-bottom: solid 2px #f2f2f2;
  box-sizing: border-box;
  min-width: 420px;

  &:last-child {
    border-bottom: none;
  }
`;

const Td100 = styled(TdItem)`
  padding: 8px 0;
  width: 100px;
  min-height: 40px;
`;

const Td200 = styled(TdItem)`
  padding: 8px 0;
  width: 200px;
  min-height: 40px;
`;

// const DisplayName = styled(Td200)`
//   cursor: pointer;
  
//   &:hover { color: #de2027 }
// `;

const ADownload = styled.a`
  width: 80px;
  height: 30px;
  line-height: 30px;
  border-radius: 8px;
  background-color: #de2027;
  border: none;
  outline: none;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.43px;
  color: #ffffff;
  text-decoration: none;

  &:hover { color: #ffffff }
`;

const ButtonDelete = styled(ButtonCancel)`
  width: 80px;
  height: 30px;
`;

const ADPreview = styled.a`
  width: 80px;
  height: 30px;
  line-height: 30px;
  border-radius: 8px;
  background-color: #de2027;
  border: none;
  outline: none;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.43px;
  color: #ffffff;
  text-decoration: none;

  &:hover { color: #ffffff }
`;

const Item = props => {

  return (
    <TrWrapper>
      {
        // props.displayable
        //   ? <DisplayName
        //       onClick = { props.displayFileHandler }
        //     >{ props.name }</DisplayName>
        //   : <Td200>{ props.name }</Td200>
        <Td200>{ props.name }</Td200>
      }
      <Td100>
        <ADownload
          href = { props.downloadURL }
          download = { props.name }
          target = "_blank"
        >下載</ADownload>
      </Td100>
      <Td100>
        <ButtonDelete
          disabled = { !props.fileChangable }
          onClick = { props.deleteFileHandler }
        >刪除
        </ButtonDelete>
      </Td100>
      <Td100>
        <ADPreview
          disabled = { !props.displayable }
          onClick = { props.displayFileHandler }
        >預覽</ADPreview>
      </Td100>
    </TrWrapper>
  );
};

export default Item;
