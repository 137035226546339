import React from 'react';
import styled from 'styled-components';

import { InputBasic } from '../../component-style/Input';

const DivWrapper = styled.div`
  width: 100%;
  
  @media (min-width: 768px) {
    width: calc(100% - 135px);
  }
`;

const DivFWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;


const InputAreaCode = styled(InputBasic)`
  width: 140px;
  margin-right: 20px;
  margin-bottom: 10px;

  @media (min-width: 1920px) {
    width: 140px;
  }
`;

const InputExtension = styled(InputAreaCode)`
  margin-right: 0;
`;

const InputNumber = styled(InputBasic)`
  width: 300px;
  margin-right: 20px;
  margin-bottom: 10px;

  @media (max-width: 320px) {
    width: 260px;
  };

  @media (min-width: 1920px) {
    width: 300px;
  }
`;

const PhoneInfoComponent = props => {
  const { phoneInfo } = props;

  return (
    <DivWrapper>
      <DivFWrapper>
        <InputAreaCode
          placeholder = "區碼"
          disabled = { props.disabled }
          value = { phoneInfo.areaCode }
          onChange = {({ target }) => {
            props.updatePhoneInfoHandler({
              ...phoneInfo,
              areaCode: target.value,
            });
          }}
        />
        <InputNumber
          placeholder = "電話號碼"
          disabled = { props.disabled }
          value = { phoneInfo.number }
          onChange = {({ target }) => {
            props.updatePhoneInfoHandler({
              ...phoneInfo,
              number: target.value,
            });
          }}
        />
        <InputExtension
          placeholder = "分機號碼"
          disabled = { props.disabled }
          value = { phoneInfo.extension }
          onChange = {({ target }) => {
            props.updatePhoneInfoHandler({
              ...phoneInfo,
              extension: target.value,
            });
          }}
        />
      </DivFWrapper>
    </DivWrapper>
  );
};

export default PhoneInfoComponent;
