import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import {
  deleteUploadFile,
  postNewUploadFile,
  postToGetUploadFiles,
} from '@/api/storageService';

import {
  deleteJournalists,
  postToGetJournalistDetail,
  putModifyJournalistDetail,
} from '@/api/journalistService';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import {
  FETCH_MEDIA_ID_OPTIONS,
  FETCH_JOURNALIST_TYPES,
} from '@/store/actions/srpOptionsAction';

import MainContent from '@/component-style/MainContent';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import PopupExecuteConfirm from '@/components/UI/Popup/PopupExecuteConfirm';
import PopupAttachmentList from '@/components/UI/Popup/PopupAttachmentList';
import PanelJournalistDetail from '@/components/Panel/PanelJournalistDetail';
import { ButtonBasic, ButtonCancel } from '@/component-style/Button';
import { DivFlexRow } from '@/component-style/RWDWrapper';

import { journalistConfig } from '@/constant/dataConfig/informationModule';

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding-right: 0;
  padding-left: 135px;
  padding-bottom: 30px;
  justify-content: flex-end;

  @media (max-width: 425px) {
    justify-content: center;
    padding-left: 0;
  }
`;

const ButtonSave = styled(ButtonBasic)`
  width: 120px;
  margin-right: 10px;
  margin-bottom: 10px;
`;

const ButtonClear = styled(ButtonCancel)`
  width: 120px;
  margin-bottom: 10px;
`;

const JournalistDetail = props => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const mediaIDList = useSelector(state => state.srpOptions.mediaIDOptions);
  const journalistTypes = useSelector(state => state.srpOptions.journalistTypeOptions);
  const informationRoutes = useSelector(state => state.login.informationRoutes);
  const showSaveFailPopup = useSelector(state => state.apiFail.showSaveFailPopup);
  const apiFailPopupTitle = useSelector(state => state.apiFail.title);
  const apiFailPopupMessage = useSelector(state => state.apiFail.message);

  const [deleteFileId, setDeleteFileId] = useState('');
  const [fileList, setFileList] = useState([]);
  const [uploadFiles, setUploadFiles] = useState([]);
  

  const [journalist, setJournalist] = useState({
    ...journalistConfig,
    type: [],
    businessPhone: { ...journalistConfig.businessPhone },
    companyAddress: { ...journalistConfig.companyAddress },
    privateAddress: { ...journalistConfig.privateAddress },
  });

  const [isEditing, setIsEditing] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [showDeleteFilePopup, setShowDeleteFilePopup] = useState(false);
  const [showAttachmentsPopup, setShowAttachmentsPopup] = useState(false);
  const [showSaveSuccessPopup, setShowSaveSuccessPopup] = useState(false);
  const [showDeleteSuccessPopup, setShowDeleteSuccessPopup] = useState(false);
  const [showUploadSuccessPopup, setShowUploadSuccessPopup] = useState(false);
  const [showDeleteFileSuccessPopup, setShowDeleteFileSuccessPopup] = useState(false);

  const userEditable = informationRoutes.indexOf('JournalistDetail-Edit') !== -1;
  const userDeletable = informationRoutes.indexOf('JournalistDetail-Delete') !== -1;
  const recordReadable = informationRoutes.indexOf('JournalistRecords') !== -1;
  const userUploadable = informationRoutes.indexOf('JournalistDetail-Attachments') !== -1;

  const updateJournalistHandler = (key, value) => {
    const updateObj = { ...journalist };
    updateObj[key] = value;
    setJournalist({ ...updateObj });
  };

  const closeSuccessPopupHandler = () => {
    setIsEditing(false);
    setShowSaveSuccessPopup(false);
  };

  const updateUploadFiles = value => {
    setUploadFiles([ ...value ]);
  };

  const uploadSuccessConfirmHandler = () => {
    setUploadFiles([]);
    setShowUploadSuccessPopup(false);
    setShowAttachmentsPopup(true);
  };

  const deleteFileHandler = id => {
    setDeleteFileId(id);
    setShowDeleteFilePopup(true);
  };

  const apiFailConfirmHandler = () => {
    dispatch(CLOSE_SAVE_FAIL_POPUP());

    apiFailPopupTitle === '上傳失敗'
      && setShowAttachmentsPopup(true);
  };

  const fileUploadHandler = async () => {
    setShowAttachmentsPopup(false);
    setShowLoadingPopup(true);

    try {
      const [file] = uploadFiles;
      const formData = new FormData();
      formData.append('upfile', file);
      formData.append('objectType', 'journalist');
      formData.append('objectID', id);
      formData.append('name', file.name);
      await postNewUploadFile(formData);
      await fetchUploadFiles();
      setShowLoadingPopup(false);
      setShowUploadSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '上傳失敗', err }));
      console.log(err);
    };
  };

  const deleteFile = async () => {
    setShowDeleteFilePopup(false);
    setShowAttachmentsPopup(false);
    setShowLoadingPopup(true);

    try {
      await deleteUploadFile(deleteFileId);
      await fetchUploadFiles();
      setShowLoadingPopup(false);
      setShowDeleteFileSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '刪除檔案失敗', err }));
      console.log(err);
    }
  };

  const fetchUploadFiles = async () => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetUploadFiles('journalist', id);
      setFileList([ ...data ]);
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const updateJournalistData = async () => {
    setShowLoadingPopup(true);

    try {
      await putModifyJournalistDetail(journalist)
      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '更新失敗', err }));
      console.log(err);
    }
  };

  const deleteJournalistHandler = async () => {
    setShowDeletePopup(false);
    setShowLoadingPopup(true);

    try {
      await deleteJournalists([id]);
      setShowLoadingPopup(false);
      setShowDeleteSuccessPopup(true);
    } catch(err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '刪除失敗', err }));
      console.log(err);
    }
  };

  const fetchJournalistData = async () => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetJournalistDetail(id);
      const {
        type,
        birthday,
        businessPhone,
        companyAddress,
        privateAddress,
        ...remainItems
      } = data;

      setJournalist(prevState => ({
        ...prevState,
        ...remainItems,
        birthday: birthday === '0001-01-01' ? null : birthday,
        type: [ ...prevState.type, ...type ],
        businessPhone: { ...prevState.businessPhone, ...businessPhone },
        companyAddress: { ...prevState.companyAddress, ...companyAddress },
        privateAddress: { ...prevState.privateAddress, ...privateAddress },
      }));
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const initOptions = useCallback(() => {
    dispatch(FETCH_MEDIA_ID_OPTIONS());
    dispatch(FETCH_JOURNALIST_TYPES());
  }, [dispatch]);

  const initUploadFiles = useCallback(
    fetchUploadFiles, []);

  const initJournalistData = useCallback(
    fetchJournalistData, []);

  const initData = useCallback(
    async () => {
      await initJournalistData();
      await initUploadFiles();
    }, [initJournalistData, initUploadFiles]);

  useEffect(() => {
    initOptions();
    initData();
  }, [initData, initOptions]);

  return (
    <MainContent>
      <PanelJournalistDetail
        title = "記者資料明細"
        disabled = { !isEditing }
        journalist = { journalist }
        mediaIDList = { mediaIDList }
        journalistTypes = { journalistTypes }
        updateJournalistHandler = { updateJournalistHandler }
      >
        <DivFlexRow>
          <DivButtonsWrapper>
            <ButtonSave
              disabled = { !recordReadable }
              onClick = { () => {
                history.push(`/Information/JournalistRecords/${id}`) }}
            >記者紀錄</ButtonSave>
            <ButtonSave
              disabled = { !userUploadable }
              onClick = { () => { setShowAttachmentsPopup(true) } }
            >附件管理</ButtonSave>
            {
              isEditing
                ? <ButtonSave
                    onClick = { updateJournalistData }
                  >儲存</ButtonSave>
                : <ButtonSave
                    disabled = { !userEditable }
                    onClick = { () => { setIsEditing(true) } }
                  >編輯</ButtonSave>
            }
            <ButtonSave
              disabled = { !userDeletable }
              onClick = { () => { setShowDeletePopup(true) }}
            >刪除</ButtonSave>
            <ButtonClear
              onClick = { () => { history.goBack() } }
            >離開</ButtonClear>
          </DivButtonsWrapper>
        </DivFlexRow>
      </PanelJournalistDetail>
      { showLoadingPopup && <PopupLoading/> }
      {
        showAttachmentsPopup &&
        <PopupAttachmentList
          fileChangable = { true }
          fileList = { fileList }
          uploadFiles = { uploadFiles }
          updateUploadFiles = { updateUploadFiles }
          fileUploadHandler = { fileUploadHandler }
          deleteFileHandler = { deleteFileHandler }
          cancelHandler = { () => { setShowAttachmentsPopup(false) }}
        />
      }
      {
        showDeletePopup &&
          <PopupExecuteConfirm
            title = "刪除記者名單"
            text = "確定刪除此記者資料"
            confirmHandler = { deleteJournalistHandler }
            cancelHandler = { () => {
              setShowDeletePopup(false);
            }}
          />
      }
      {
        showDeleteFilePopup &&
          <PopupExecuteConfirm
            title = "刪除檔案"
            text = "確定刪除此上傳檔案"
            confirmHandler = { deleteFile }
            cancelHandler = { () => {
              setShowDeleteFilePopup(false);
            }}
          />
      }
      {
        showSaveSuccessPopup &&
          <PopupExecuteResult
            title = "更新成功"
            confirmHandler = { closeSuccessPopupHandler }
          />
      }
      {
        showDeleteSuccessPopup &&
          <PopupExecuteResult
            title = "刪除成功"
            confirmHandler = { () => { history.goBack() }}
          />
      }
      {
        showUploadSuccessPopup &&
          <PopupExecuteResult
            title = "上傳成功"
            confirmHandler = { uploadSuccessConfirmHandler }
          />
      }
      {
        showDeleteFileSuccessPopup &&
          <PopupExecuteResult
            title = "刪除檔案成功"
            confirmHandler = { () => {
              setShowDeleteFileSuccessPopup(false);
              setShowAttachmentsPopup(true);
            }}
          />
      }
      {
        showSaveFailPopup &&
          <PopupExecuteResult
            title = { apiFailPopupTitle }
            text = { apiFailPopupMessage }
            confirmHandler = { apiFailConfirmHandler }
          />
      }
    </MainContent>
  )
};

export default JournalistDetail;
