import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

const LiWrapper = styled.li`
  &:hover {
    background-color: rgba(138, 138, 138, 0.1);
  }

  & a {
    display: flex;
    font-weight: 500;
    letter-spacing: 0.4px;
    // color: #979797;
    color: #4d4d4d;
    text-decoration: none;
    height: 40px;
    align-items: center;
  }

  & a.active {
    background-color: rgba(138, 138, 138, 0.1);
  }
`;

const SpanLinkTitle = styled.span`
  font-size: 14px;
  margin-left: ${ props => props.noIcon
    ? "95px"
    : "10px"
  };
`;

const NavigationItem = props => {

  return (
    <LiWrapper>
      <NavLink
        to = { props.url }
      >
        { props.children }
        <SpanLinkTitle
          noIcon = { props.children === undefined }
        >{ props.title }
        </SpanLinkTitle>
      </NavLink>
    </LiWrapper>
  );
};

export default NavigationItem;
