import React from 'react';
import { Upload, Button } from 'antd';
import 'antd/dist/antd.css';
import '../index.css';

const UploadMultiple = props => {

  const config = {
    multiple: true,
    onRemove: file => {
      const filteredList = props.uploadFiles
        .filter(({ name }) => name !== file.name );

      props.updateUploadFiles([...filteredList]);
    },
    beforeUpload: (file, fileList) => {
      const newFiles = fileList
        .filter(({ name }) => props.uploadFiles
          .every(file => file.name !== name));
      
      props.updateUploadFiles([...props.uploadFiles, ...newFiles]);
      return false;
    },
    showUploadList: {
      showPreviewIcon: true,
      showDownloadIcon: true,
      showRemoveIcon: true,
    },
    accept: '.gif,.jpg,.png,.pdf',
  };

  return (
    <Upload { ...config }
      fileList = { props.uploadFiles }
    >
      <Button
        disabled = { props.disabled }
      >選擇檔案
      </Button>
    </Upload>
  );
};

export default UploadMultiple;
