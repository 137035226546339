import React from 'react';
import TextArea from 'antd/lib/input/TextArea';
import 'antd/dist/antd.css';
import './index.css';

const MyTextArea = props => {
  return(
    <TextArea
      className = { 'textArea' }
      value = { props.value }
      disabled = { props.disabled }
      onChange = { value => {
        props.onChange(value);
      }}
      autoSize
    />
  )
};

export default MyTextArea;
