import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import TableWrapper from '@/components/UI/TableWrapper';
import PageSelector from '@/components/UI/PageSelector';
import { Table, TableBody } from '@/component-style/Table';
import Header from './Header';
import Item from './Item';

// import moment from 'moment';

// import PopupOvertimeDetail from '@/components/UI/Popup/PopupOvertimeDetail';

const TableLaborHealthInsurance = props => {
  const authorityRoutes = useSelector(state => state.login.authorityRoutes);

  const { laborHealthInsuranceSetting } = props;

  // const disableDetailPage = authorityRoutes.indexOf('UserDetail') === -1;

  // const [showOvertimeDetailPopup, setShowOvertimeDetailPopup] = useState(false);

  // const [viewOvertimeDetailUserID, setViewOvertimeDetailUserID] = useState("");

  return (
    <TableWrapper title = "勞、健保費用列表">
      <Table>
        <Header/>
        <TableBody>
          {
            laborHealthInsuranceSetting !== undefined && laborHealthInsuranceSetting.map(singleLaborHealthInsuranceSetting =>
              <Item
                key = { singleLaborHealthInsuranceSetting.insuranceGrade }
                laborHealthInsuranceSetting = { singleLaborHealthInsuranceSetting }
                editDetailHandler = { (insuranceGrade) => {
                  // console.log("TableLaborHealthInsurance index.js editDetailHandler() insuranceGrade:" + insuranceGrade);
                  props.editDetailHandler(insuranceGrade);
                }}
              />
            )
          }
        </TableBody>
      </Table>
      <PageSelector
        total = { props.totalCount }
        current = { props.currentPage }
        pageChangedHandler = { props.pageChangedHandler }
        showSizeChanger = { false }
      />
      {/* { 
        showOvertimeDetailPopup &&
          <PopupOvertimeDetail
            userID = { viewOvertimeDetailUserID }
            startDate = { startDate }
            endDate = { moment(startDate).endOf('month').format('YYYY-MM-DD') }
            cancelHandler = { () => {
              setShowOvertimeDetailPopup(false);
            }}
          />
      } */}
    </TableWrapper>
  );
};

export default TableLaborHealthInsurance;
