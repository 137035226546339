import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import IconClose from '@/assets/images/icon_close.svg';
import Logo from '@/components//UI/Logo';
import NavigationListDashboard from './NavigationListDashboard';
import NavigationListProjects from './NavigationListProjects';
import NavigationListInformation from './NavigationListInformation';
import NavigationListAttendance from './NavigationListAttendance';
import NavigationListExpense from './NavigationListExpense';
import NavigationListFinance from './NavigationListFinance';
import NavigationListAuthority from './NavigationListAuthority';
import NavigationSysConfig from './NavigationSysConfig';
import NavigationListUser from './NavigationListUser';
import NavigationListHR from './NavigationListHR';

const DivWrapper = styled.div`
  position: relative;
`;

const DivNavigation = styled.div`
  position: fixed;
  z-index: 3;
  width: 240px;
  height: 100%;
  background-color: #FFFFFF;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
`;

const DivMask = styled.div`
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);

  @media (min-width: 1025px) {
    display: none;
  }
`;

const DivLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 80px;
  height: 80px;
`;

const UlNavigationList = styled.ul`
  margin: 0;
  padding: 0;
  padding-bottom: 100px;
  height: calc(100vh - 87px);
  overflow-y: auto;

  user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
`;

const DivIcon = styled.div`
  cursor: pointer;
  position: absolute;
  top: 33px;
  left: 27px;
  width: 20px;
  height: 20px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${ props => props.url });

  @media (min-width: 1025px) {
    display: none;
  }
`;

const Navigation = props => {
  const needToChangePassword = useSelector(state => state.login.needToChangePassword);
  const projectRoutes = useSelector(state => state.login.projectRoutes);
  const expenseRoutes = useSelector(state => state.login.expenseRoutes);
  const financeRoutes = useSelector(state => state.login.financeRoutes);
  const authorityRoutes = useSelector(state => state.login.authorityRoutes);
  const sysConfigRoutes = useSelector(state => state.login.sysConfigRoutes);
  const attendanceRoutes = useSelector(state => state.login.attendanceRoutes);
  const informationRoutes = useSelector(state => state.login.informationRoutes);
  const hrRoutes = useSelector(state => state.login.hrRoutes);

  const [showProjectList, openProjectList] = useState(false);
  const [showInformationList, openInformationList] = useState(false);
  const [showAttendanceList, openAttendanceList] = useState(false);
  const [showExpenseList, openExpenseList] = useState(false);
  const [showFinanceList, openFinanceList] = useState(false);
  const [showAuthorityList, openAuthorityList] = useState(false);
  const [showSysConfigList, openSysConfigList] = useState(false);
  const [showHRList, openHRList] = useState(false);

  const validNavigations = <>
    <NavigationListDashboard />
    {
      projectRoutes.length > 0 &&
        <NavigationListProjects
          opened = { showProjectList }
          validRoutes = { projectRoutes }
          toogleList = { () => { openProjectList(!showProjectList) }}
        />
    }
    {
      informationRoutes.length > 0 &&
        <NavigationListInformation
          opened = { showInformationList }
          validRoutes = { informationRoutes }
          toogleList = { () => { openInformationList(!showInformationList) }}
        />
    }
    {
      attendanceRoutes.length > 0 &&
        <NavigationListAttendance
          opened = { showAttendanceList }
          validRoutes = { attendanceRoutes }
          toogleList = { () => { openAttendanceList(!showAttendanceList) }}
        />
    }
    {
      expenseRoutes.length > 0 &&
        <NavigationListExpense
          opened = { showExpenseList }
          validRoutes = { expenseRoutes }
          toogleList = { () => { openExpenseList(!showExpenseList) }}
        />
    }
    {
      financeRoutes.length > 0 &&
        <NavigationListFinance
          opened = { showFinanceList }
          validRoutes = { financeRoutes }
          toogleList = { () => { openFinanceList(!showFinanceList) }}
        />
    }
    {
      authorityRoutes.length > 0 &&
        <NavigationListAuthority
          opened = { showAuthorityList }
          validRoutes = { authorityRoutes }
          toogleList = { () => { openAuthorityList(!showAuthorityList) }}
        />
    }
    {
      sysConfigRoutes.length > 0 &&
        <NavigationSysConfig
          opened = { showSysConfigList }
          validRoutes = { sysConfigRoutes }
          toogleList = { () => { openSysConfigList(!showSysConfigList) }}
        />
    }
    {
      hrRoutes.length > 0 &&
        <NavigationListHR
          opened = { showHRList }
          validRoutes = { hrRoutes }
          toogleList = { () => { openHRList(!showHRList) }}
        />
    }
    {/* <NavigationListHR /> */}
  </>


  return (
    <DivWrapper>
      <DivNavigation>
        <DivIcon
          url = { IconClose }
          onClick = { () => { props.openNavigation(false) }}
        />
        <DivLogoWrapper>
          <Logo toDashboard = { true }/>
        </DivLogoWrapper>
        <UlNavigationList>
          { needToChangePassword ? null : validNavigations }
          <NavigationListUser
            needToChangePassword = { needToChangePassword }
          />
        </UlNavigationList>
      </DivNavigation>
      <DivMask/>
    </DivWrapper>
  );
};

export default Navigation;
