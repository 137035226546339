import React from 'react';
import styled from 'styled-components';

import { TdItem, TableRow } from '@/component-style/Table';
import { ButtonGoDetail, ButtonBasic } from '@/component-style/Button';

const TrWrapper = styled(TableRow)`
  border-bottom: solid 2px #f2f2f2;
  box-sizing: border-box;
  min-width: 1160px;

  &:last-child {
    border-bottom: none;
  }
`;

const Td120 = styled(TdItem)`
  width: 120px;
`;

const Td160 = styled(TdItem)`
  width: 160px;
`;

const Button = styled(ButtonBasic)`
  width: 80px;
`;


const Item = props => {
  const { tag } = props;

  return (
    <TrWrapper>
      <Td160>{ tag.name }</Td160>
      <Td160>{ tag.displayName }</Td160>
      <Td160>{ tag.engDisplayName }</Td160>
      <Td160>{ tag.serviceName }</Td160>
      <Td160>{ tag.engServiceName }</Td160>
      <Td120>{ tag.enable ? '啟用' : '停用' }</Td120>
      <Td120>
        <Button
          disabled = { !props.deletable }
          onClick = { props.deleteHandler }
        >刪除
        </Button>
      </Td120>
      <Td120>
        <ButtonGoDetail
          onClick = { props.showDetailHandler }
        >...
        </ButtonGoDetail>
      </Td120>
    </TrWrapper>
  );
};

export default Item;
