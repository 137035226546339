import axiosConfig from './axiosConfig';
import { getDefaultPort } from '@/utils/apiRequestUtils';

const { baseURL } = axiosConfig.defaults;

const port = process.env.REACT_APP_SERVICE_PORT_VENDOR !== undefined
  ? process.env.REACT_APP_SERVICE_PORT_VENDOR
  : getDefaultPort(baseURL);

const vendorModuleUrl = process.env.NODE_ENV === 'development'
  ? `${baseURL}:${port}/api`
  : `${baseURL}/vendor/api`;

export const postToGetVendorTypes = config => axiosConfig
  .post(`${vendorModuleUrl}/vendor/types`, config);

export const putNewVendorType = config => axiosConfig
  .put(`${vendorModuleUrl}/vendor/types`, config);

export const patchModifyVendorType = config => axiosConfig
  .patch(`${vendorModuleUrl}/vendor/types`, config);

export const deleteVendorTypes = ids => axiosConfig
  .delete(`${vendorModuleUrl}/vendor/types`, { data: [ ...ids ]});

export const postToGetVendorTags = config => axiosConfig
  .post(`${vendorModuleUrl}/vendor/type/tag`, config);

export const putNewVendorTag = config => axiosConfig
  .put(`${vendorModuleUrl}/vendor/type/tag`, config);

export const patchModifyVendorTag = config => axiosConfig
  .patch(`${vendorModuleUrl}/vendor/type/tag`, config);

export const deleteVendorTag = config => axiosConfig
  .delete(`${vendorModuleUrl}/vendor/type/tag`, { data: { ...config } });

export const postToGetVendorIDs = config => axiosConfig
  .post(`${vendorModuleUrl}/vendor/vendorIDs`, config);

export const postToGetVendors = config => axiosConfig
  .post(`${vendorModuleUrl}/vendor`, config);

export const putNewVendor = vendor => axiosConfig
  .put(`${vendorModuleUrl}/vendor`, vendor);

export const deleteVendors = vendors => axiosConfig
  .delete(`${vendorModuleUrl}/vendor`, { data: [ ...vendors ] });

export const postToGetVendorDetail = vendorID => axiosConfig
  .post(`${vendorModuleUrl}/vendor/detail`, { vendorID });

export const putModifyVendorDetail = vendor => axiosConfig
  .put(`${vendorModuleUrl}/vendor/detail`, vendor);


export const postToGetVendorWindows = config => axiosConfig
  .post(`${vendorModuleUrl}/vendor/contactWindow`, config);

export const putNewVendorWindow = contactWindow => axiosConfig
  .put(`${vendorModuleUrl}/vendor/contactWindow`, contactWindow);

export const deleteVendorWindow = contactWindowID => axiosConfig
  .delete(`${vendorModuleUrl}/vendor/contactWindow`, { data: { contactWindowID } });

export const postToGetVendorWindowList = () => axiosConfig
  .post(`${vendorModuleUrl}/vendor/contactWindowIDs`);

export const postToGetVendorWindowDetail = contactWindowID => axiosConfig
  .post(`${vendorModuleUrl}/vendor/contactWindow/detail`, { contactWindowID });

export const putModifyVendorWindowDetail = contactWindow => axiosConfig
  .put(`${vendorModuleUrl}/vendor/contactWindow/detail`, contactWindow);
