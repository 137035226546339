import React from 'react';
import styled from 'styled-components';

import Checkbox from '../../UI/Checkbox';
import { TdItem, TableRow } from '../../../component-style/Table';
import { ButtonGoDetail } from '../../../component-style/Button';

const TrWrapper = styled(TableRow)`
  border-bottom: solid 2px #f2f2f2;
  box-sizing: border-box;
  min-width: 1120px;

  &:last-child {
    border-bottom: none;
  }
`;

const Td80 = styled(TdItem)`
  width: 80px;
`;

const Td240 = styled(TdItem)`
  width: 240px;
`;

const Item = props => {
  const { media } = props;

  return (
    <TrWrapper>
      <Td80>
        <Checkbox
          checked = { media.isChecked }
          changeHandler = { props.checkHandler }
        />
      </Td80>
      <Td240>{ media.mediaName }</Td240>
      <Td240>{ media.mediaEnglishName }</Td240>
      <Td240>{ media.groupName }</Td240>
      <Td240>{ media.groupEnglishName }</Td240>
      <Td80>
        <ButtonGoDetail
          disabled = { props.disableDetailPage }
          onClick = { props.goDetailHandler }
        >...
        </ButtonGoDetail>
      </Td80>
    </TrWrapper>
  );
};

export default Item;
