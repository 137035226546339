import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { putToPostNewProject } from '@/api/projectService';

import {
  putNewProjectFinance,
  putNewProjectInvoice,
  putNewProjectPRServices,
} from '@/api/financeService';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import {
  FETCH_MEMBER_OPTIONS,
  FETCH_DIRECTOR_OPTIONS,
  FETCH_CUSTOMER_OPTIONS,
  FETCH_PROJECT_TYPE_OPTIONS,
  FETCH_COMPANY_TYPE_OPTIONS,
  FETCH_CONTACT_WINDOW_OPTIONS,
  FETCH_PROJECT_MANAGER_OPTIONS,
} from '@/store/actions/srpOptionsAction';

import MainContent from '@/component-style/MainContent';
import PanelProjectDetail from '@/components/Panel/PanelProjectDetail';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import { ButtonBasic, ButtonCancel } from '@/component-style/Button';
import { DivFlexRow } from '@/component-style/RWDWrapper';

import { projectConfig } from '@/constant/dataConfig/projectModule';

const DivButtonsWrapper = styled(DivFlexRow)`
  padding: 10px 135px 30px 135px;

  @media (max-width: 425px) {
    justify-content: center;
    padding-left: 0;
    padding-right: 0;
  }
`;

const ButtonSave = styled(ButtonBasic)`
  width: 120px;
`;

const ButtonClear = styled(ButtonCancel)`
  margin-left: 10px;
  width: 120px;
`;

const ProjectCreation = props => {
  const dispatch = useDispatch();
  const memberOptions = useSelector(state => state.srpOptions.memberOptions);
  const directorOptions = useSelector(state => state.srpOptions.directorOptions);
  const customerOptions = useSelector(state => state.srpOptions.customerOptions);
  const projectTypeOptions = useSelector(state => state.srpOptions.projectTypeOptions);
  const companyTypeOptions = useSelector(state => state.srpOptions.companyTypeOptions);
  const contactWindowOptions = useSelector(state => state.srpOptions.contactWindowOptions);
  const projectManagerOptions = useSelector(state => state.srpOptions.projectManagerOptions);
  const showSaveFailPopup = useSelector(state => state.apiFail.showSaveFailPopup);
  const apiFailPopupTitle = useSelector(state => state.apiFail.title);
  const apiFailPopupMessage = useSelector(state => state.apiFail.message);

  const [project, setProject] = useState({
    ...projectConfig,
    mediaDays: [],
    customerWindows: [],
    members: [],
    supports: [],
    projectTypes: [],
  });

  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [showSaveSuccessPopup, setShowSaveSuccessPopup] = useState(false);

  const disabledSaveBtn = project.projectCode === ''
    || project.companyType === ''
    || project.projectName === ''
    || project.receiveDate === null
    || project.actionTimeStart === null
    || project.actionTimeEnd === null
    || project.customer === null
    || project.customerWindows.length === 0
    || project.director === ''
    || project.projectManager === ''
    || project.mediaDays
        .some(day => day.start === null || day.end === null);


  const updateProjectHandler = (key, value) => {
    const updateObj = { ...project };
    updateObj[key] = value;
    if (key === "internal" && value === true) {
      updateObj["allmembers"] = value;
    }
    setProject({ ...updateObj });
  };

  const updateCustomerHandler = value => {
    setProject(prevState => ({
      ...prevState,
      customer: value,
      customerWindows: [],
    }));
  };

  const updateAllMemberHandler = value => {
    setProject( prevState => ({
      ...prevState,
      members: [],
      supports: [],
      allmembers: value,
    }));
  };

  const clearData = () => {
    setProject({
      ...projectConfig,
      mediaDays: [],
      customerWindows: [],
      members: [],
      supports: [],
      projectTypes: [],
    });
  };

  const closeSuccessPopupHandler = () => {
    clearData();
    setShowSaveSuccessPopup(false);
  };

  const createNewProjectHandler = async () => {
    setShowLoadingPopup(true);

    try {
      const { data } = await putToPostNewProject(project);
      await putNewProjectFinance({
        projectID: data,
        specialPrice: 0,
      });
      await putNewProjectInvoice({
        projectID: data,
        customerID: project.customer,
        invoices: [],
      });
      await putNewProjectPRServices({
        projectID: data,
        customerID: project.customer,
        prServices: [],
      });
      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '儲存失敗', err }));
      console.log(err);
    }
  };

  const initOptions = useCallback(() => {
    dispatch(FETCH_MEMBER_OPTIONS());
    dispatch(FETCH_DIRECTOR_OPTIONS());
    dispatch(FETCH_CUSTOMER_OPTIONS());
    dispatch(FETCH_PROJECT_TYPE_OPTIONS({ enable: true }));
    dispatch(FETCH_COMPANY_TYPE_OPTIONS({ enable: true }));
    dispatch(FETCH_PROJECT_MANAGER_OPTIONS());
    dispatch(FETCH_CONTACT_WINDOW_OPTIONS({
      type: 'customer',
      companyName: true,
    }));
  }, [dispatch]);

  useEffect(() => {
    initOptions();
  }, [initOptions])

  return (
    <MainContent>
      <PanelProjectDetail
        title = "新增專案"
        project = { project }
        memberOptions = { memberOptions }
        customerOptions = { customerOptions }
        directorOptions = { directorOptions }
        projectTypeOptions = { projectTypeOptions }
        companyTypeOptions = { companyTypeOptions }
        contactWindowOptions = { contactWindowOptions }
        projectManagerOptions = { projectManagerOptions }
        updateProjectHandler = { updateProjectHandler }
        updateCustomerHandler = { updateCustomerHandler }
        updateAllMemberHandler = { updateAllMemberHandler }
      >
        <DivFlexRow>
          <DivButtonsWrapper>
            <ButtonSave
              disabled = { disabledSaveBtn }
              onClick = { createNewProjectHandler }
            >儲存</ButtonSave>
            <ButtonClear
               onClick = { () => { 
                                    clearData();
                                  } }
            >清除欄位</ButtonClear>
          </DivButtonsWrapper>
        </DivFlexRow>
      </PanelProjectDetail>
      {
        showSaveSuccessPopup &&
          <PopupExecuteResult
            title = "儲存成功"
            confirmHandler = { closeSuccessPopupHandler }
          />
      }
      {
        showSaveFailPopup &&
          <PopupExecuteResult
            title = { apiFailPopupTitle }
            text = { apiFailPopupMessage }
            confirmHandler = { () => {
              dispatch(CLOSE_SAVE_FAIL_POPUP());
            }}
          />
      }
      {
        showLoadingPopup &&
        <PopupLoading/>
      }
    </MainContent>
  );
};

export default ProjectCreation;
