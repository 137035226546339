import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { cloneDeep } from 'lodash';
import { userConfig } from '@/constant/dataConfig/authorityModule';
import { useLocation } from "react-router-dom"

import {
  putModifyUser,
  postToGetUserInfo,
} from '@/api/authService';

import {
  getLaborHealthInsuranceSetting,
  getExtraPayments,
} from '@/api/financeService'

import {
  AZURE_SYNC_START,
  CLOSE_AZURE_SYNC_POP,
} from '@/store/actions/loginAction';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import {
  FETCH_DIRECTOR_OPTIONS,
  FETCH_DEPARTMENT_OPTIONS,
  FETCH_SYSTEM_ROLE_OPTIONS,
  FETCH_COMPANY_TYPE_OPTIONS,
} from '@/store/actions/srpOptionsAction';

import MainContent from '@/component-style/MainContent';
import TableWrapper from '@/components/UI/TableWrapper';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import { ButtonBasic } from '@/component-style/Button';
import {
  DivFlexRow,
} from '@/component-style/RWDWrapper';

import { integerConverter } from '@/utils/textUtils';

import { searchConfigGenerator } from '@/utils/apiRequestUtils';

import PopupViewWageStructure from '@/components/UI/Popup/PopupViewWageStructure';
import TableWageStructure from '@/components/Table/TableWageStructure';
import moment from 'moment';
import PanelMyDetail from '@/components/Panel/Authority/PanelMyDetail';
import PanelMySalary from '@/components/Panel/Authority/PanelMySalary';
import { leaveApplicationConfig } from '@/constant/dataConfig/financeModule';

import {
  subtractTime,
} from '@/utils/timeUtils';

import {
  // getLeaveSetting,
  // postCreateLeaveApplication,
  getLeave,
  getLeaveApplications,
  getPaycheckStructures,
  // getUserSalaries,
  getUserSalaryByMonth,
} from '@/api/financeService';

import TableSalaryExtraPayment from '@/components/Table/TableSalaryExtraPayment';

import {
  FETCH_MEMBER_OPTIONS,
  FETCH_PROJECT_ID_OPTIONS,
  FETCH_WORKINGHOURS_TYPE_OPTIONS,
} from '@/store/actions/srpOptionsAction';

const searchConfig = {
  startDate: null,
  endDate: null,
  userIDs: [],
  projectID: '',
  typeID: '',
  tag: '',
  isOverTime: true,
  overtimeType: '',
  approvementStatus: '',
  crossTeam: false,
  amount: 10,
};

const searchSalaryConfig = {
  salaryMonth: moment().subtract(1, 'M').format("YYYY-MM"),
  payMonth: moment().format("YYYY-MM"),
};


const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding: 0;
  padding-bottom: 30px;
  justify-content: space-between;

  @media (max-width: 425px) {
    justify-content: center;
    padding-left: 0;
  }
`;

const ButtonSave = styled(ButtonBasic)`
  width: 120px;
  margin-right: 10px;
  margin-bottom: 10px;
`;

const MyInfoSetting = () => {
  let { search } = useLocation();
  const params = new URLSearchParams(search);
  const searchedWorkingStartDate = params.get('searchedWorkingStartDate');
  const searchedWorkingEndDate = params.get('searchedWorkingEndDate');
  // console.log("leaveList.js searchedWorkingStartDate:" + searchedWorkingStartDate);
  // console.log("overtimesList.js searchedWorkingEndDate:" + searchedWorkingEndDate);

  const [showSaveSuccessPopup, setShowSaveSuccessPopup] = useState(false);

  const dispatch = useDispatch();
  const userID = useSelector(state => state.login.token);
  const departmentOptions = useSelector(state => state.srpOptions.departmentOptions);
  const directorOptions = useSelector(state => state.srpOptions.directorOptions);
  const companyTypeOptions = useSelector(state => state.srpOptions.companyTypeOptions);

  // const [overtimeRecords, setOvertimeRecords] = useState([]);
  const [user, setUser] = useState(cloneDeep(userConfig));

  const [salaryConfig, setSalaryConfig] = useState({ ...searchSalaryConfig });
  const [salary, setSalary] = useState({});

  const [paycheckStructures, setPaycheckStructures] = useState([]);

  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [userFilter, setUserFilter] = useState('self');


  const [showLoadingPopup, setShowLoadingPopup] = useState(false);

  const [leaveApplicationParam, setLeaveApplicationParam] = useState({ ...leaveApplicationConfig });

  const [leaveApplicationRecords, setLeaveApplicationRecords] = useState([]);
  const [leaveSettings, setLeaveSettings] = useState([]);
  const [leaveSettinsOptions, setLeaveSettinsOptions] = useState([]);

  const [showPopupViewWageStructure, setShowPopupViewWageStructure] = useState(false);
  const [viewPaycheckStructureId, setViewPaycheckStructureId] = useState("");

  const [extraPaymentCurrentPage, setExtraPaymentCurrentPage] = useState(1);
  const [extraPaymentTotalCount, setExtraPaymentTotalCount] = useState(0);
  const [extraPayments, setExtraPayments] = useState([]);

  const disabledMonth = current => current > subtractTime(moment(), 1, 'months');

  const updateConfigHandler = (key, value) => {
    console.log(`personalSalary.js updateConfigHandler key=${key}`);
    const updateObj = { ...leaveApplicationParam };
    updateObj[key] = value;
    setLeaveApplicationParam({...updateObj});
    // if (key === "leaveTo") {
    //   fetchLeaveApplication();
    // }
    // const updateObj = { ...config };
    // updateObj[key] = value;
    // setConfig({...updateObj});
  };

  // const typeChangeHandler = value => {
  //   setConfig(prevState => ({
  //     ...prevState,
  //     typeID: value,
  //     tag: '',
  //   }));
  // };

  // const userFilterChangeHandler = value => {
  //   setUserFilter(value);
  //   setConfig(prevState => ({
  //     ...prevState,
  //     userIDs: value === 'self' ? [userID] : [],
  //   }));
  // };

  const clearHandler = () => {
    setUserFilter('self');
    setLeaveApplicationParam({
      ...leaveApplicationConfig,
      userIDs: [userID],
    });
  };

  const cancelHandler = () => {
    setShowPopupViewWageStructure(false);
  }

  const searchHandler = () => {
    //set searched config
    // const params = new URLSearchParams()
    // if (leaveApplicationParam.leaveFrom !== null && leaveApplicationParam.leaveTo !== null) {
    //   // console.log("overtimesList.js workingDateChangeHanlder date:" + value);
    //   params.append("searchedWorkingStartDate", formatTime(leaveApplicationParam.leaveFrom, 'YYYY-MM-DD'))
    //   params.append("searchedWorkingEndDate", formatTime(leaveApplicationParam.leaveTo, 'YYYY-MM-DD'))
    //   history.push({search: params.toString()})
    // }
    //set searched config
    setCurrentPageHandler(1);
  };

  const setCurrentPageHandler = page => {
    setCurrentPage(page);
    fetchLeaveApplication(page);
    // fetchWorkingHours(page);
  };

  const fetchLeaveSetting = async () => {
    setShowLoadingPopup(true);

    try {
      const { data } = await getLeave();
      console.log("leaveApplication.js data:" + JSON.stringify(data));
      setLeaveSettings(data.leaves);
      const sorted = data.leaves.map(element => {
        return {value: element.id, desc: element.description };
      });
      console.log("leaveApplication.js sorted:" + JSON.stringify(sorted));
      setLeaveSettinsOptions(sorted);
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };
  
  const fetchLeaveApplication = async (date = leaveApplicationConfig.leaveFrom, page = currentPage) => {
    setShowLoadingPopup(true);

    try {
      console.log(`myInfoSetting.js fetchLeaveApplication() date=${date}`);
      const { data } = await getLeaveApplications( {
        userId: userID,
        leaveFrom: moment(leaveApplicationParam.leaveFrom).format('YYYY-MM-DD 00:00:00'), 
        leaveTo: moment(leaveApplicationParam.leaveTo).add(1, 'days').format('YYYY-MM-DD 00:00:00')
      });
      console.log(`myInfoSetting.js fetchLeaveApplication() JSON.stringify(data)=${JSON.stringify(data)}`);
      const { applications } = data;
      const tempApplicationRecords = [];

      for (let i = 0; i < applications.length; i += 1 ) {
        tempApplicationRecords.push({
          ...applications[i],
        });
      }

      setLeaveApplicationRecords([ ...tempApplicationRecords ]);
      // setTotalCount(totalCount || 0);
    } catch (err) { console.log(`myInfoSetting.js fetchLeaveApplication() err=${err}`) }

    setShowLoadingPopup(false);
  };

  const viewPaycheckStructureHandler = (paycheckStructureId) => {
    console.log(`myInfoSetting.js viewPaycheckStructureHandler paycheckStructureId=${paycheckStructureId}`);
    setViewPaycheckStructureId(paycheckStructureId);
    setShowPopupViewWageStructure(true);
  }

  const initOptions = useCallback(() => {
    dispatch(FETCH_MEMBER_OPTIONS());
    dispatch(FETCH_PROJECT_ID_OPTIONS());
    dispatch(FETCH_WORKINGHOURS_TYPE_OPTIONS());
  }, [dispatch]);

  const initLeaveSetting = useCallback(
    fetchLeaveSetting, []);

  useEffect(() => {
    initOptions();
    initLeaveSetting();
  }, [initOptions, initLeaveSetting]);

  useEffect(() => {
    if (searchedWorkingStartDate !== undefined && searchedWorkingStartDate !== "" && searchedWorkingEndDate !== undefined && searchedWorkingEndDate !== "") {
      setLeaveApplicationParam({
        ...searchConfig,
        startDate: searchedWorkingStartDate,
        endDate: searchedWorkingEndDate,
      });
    }
  }, [searchedWorkingStartDate, searchedWorkingEndDate]);
  
  const updateUserHandler = (key, value) => {
    const updateObj = cloneDeep(user);
    if (key === "basicSalary") {
      updateObj[key] = integerConverter(value);
    } else {
      updateObj[key] = value;
    }
    // console.log("userDetai.js updateUserHandler JSON.stringify(updateObj):" + JSON.stringify(updateObj));
    setUser({...updateObj});
  };

  const extraPaymentPageChangedHandler = page => {
    console.log(`myInfoSetting.js extraPaymentPageChangedHandler()`);
    setExtraPaymentCurrentPage(page);
    fetchExtraPayments(page);
  }

  const updateUserData = async () => {
    console.log(`myInfoSetting.js updateUserData()`);
    setShowLoadingPopup(true);

    try {
      await putModifyUser(searchConfigGenerator(user));
      fetchUser();
      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '更新失敗', err }));
      console.log(err);
    }
  };

  const closeSuccessPopupHandler = () => {
    setShowSaveSuccessPopup(false);
  };
  
  const fetchUser = async () => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetUserInfo(userID);
      setUser({
        ...cloneDeep(userConfig),
        ...data,
      });

      // const dataUserHistory = await getUserHistoryById(id);
      // console.log("userDetail.js fetchUser() JSON.stringify(dataUserHistory):" + JSON.stringify(dataUserHistory.data.history));
      // setUserHistory(dataUserHistory.data.history);

      // const dataLaborHealthInsurance = await getLaborHealthInsuranceSetting();
      // // console.log("userDetail.js fetchUser() JSON.stringify(dataLaborHealthInsurance):" + JSON.stringify(dataLaborHealthInsurance));
      // const laobrHealthInsuranceGrade = dataLaborHealthInsurance.data.settings.map((element) => {
      //   return {"desc": element.insuranceGrade, "value": element.insuranceGrade};
      // });

      // console.log("userDetail.js fetchUser() JSON.stringify(laobrHealthInsuranceGrade):" + JSON.stringify(laobrHealthInsuranceGrade));

    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const fetchPaycheckStructures = async () => {
    setShowLoadingPopup(true);

    try {
      const searchParameters = {
        userID: userID,
        orderBy: "applyDate_desc",
      }
      const { data } = await getPaycheckStructures(searchParameters);
      setPaycheckStructures(data.paycheckStructures);
      console.log("perosnalSalary.js fetchPaycheckStructures() data:" + JSON.stringify(data));
      const tempPaycheckStructures = data.paycheckStructures.filter((element) => {
        return element.userId === userID;
      });
      console.log("perosnalSalary.js fetchPaycheckStructures() tempPaycheckStructures:" + JSON.stringify(tempPaycheckStructures));
      setPaycheckStructures(tempPaycheckStructures);
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const updateSearchSalaryHandler = (key, value) => {
    console.log(`perosnalSalary.js updateSearchSalaryHandler() key=${key}&value=${value}`);
    const updateObj = { ...searchSalaryConfig };
    updateObj[key] = value;
    updateObj['payMonth'] = moment(value).add(1, 'M').format("YYYY-MM");
    setSalaryConfig({...updateObj});
  }

  const searchSalaryHandler = () => {
    console.log(`myInfoSetting.js searchSalaryHandler()`);
    fetchSalary();
  }

  const fetchSalary = async () => {
    console.log(`myInfoSetting.js fetchSalary() `);
    setShowLoadingPopup(true);

    try {
      // console.log(`perosnalSalary.js fetchSalary() moment(salaryConfig.salaryMonth).startOf('month').format('YYYY-MM-DD'):${moment(salaryConfig.salaryMonth).startOf('month').format('YYYY-MM-DD')}`);
      const { data } = await getUserSalaryByMonth( userID, moment(salaryConfig.salaryMonth).startOf('month').format('YYYY-MM-DD') );
      // console.log(`perosnalSalary.js fetchSalary() JSON.stringify(data.salaries):${JSON.stringify(data.salaries)}`);
      // const tempSalary = data.salaries.find((singleSalary) => singleSalary.userId === userID);
      console.log(`myInfoSetting.js fetchSalary() JSON.stringify(data)=${JSON.stringify(data)}`);
      setSalary(data);
    } catch(err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const fetchExtraPayments = async (page = extraPaymentCurrentPage) => {
    setShowLoadingPopup(true);
    try {
      console.log(`myInfoSetting.js fetchExtraPayments() JSON.stringify(id)=${JSON.stringify(userID)}&page=${page}`);
      const searchParameters = {
        userId: userID,
        page: page,
        amount: 10,
      }
      const { data } = await getExtraPayments(searchParameters);
      setExtraPayments(data.extraPayments);
      setExtraPaymentTotalCount(data.totalCount);
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const updateData = async () => {
    console.log(`myInfoSetting.js updateData() userID=${JSON.stringify(userID)}`);
    fetchUser();
    fetchSalary();
    fetchPaycheckStructures();
    fetchExtraPayments();
  };

  const initData = useCallback(
    updateData
  , []
  );

  useEffect(() => {
    initData();
  }, [initData]);

  return (
    <MainContent>
      <PanelMyDetail
        title = "個人資訊"
        user = { user }
        disabled = { true }
        departmentOptions = { departmentOptions }
        directorOptions = { directorOptions }
        companyTypeOptions = { companyTypeOptions }
        updateUserHandler = { updateUserHandler }
        updateUserData = { updateUserData }
      />
      <div>&nbsp;</div>
      <PanelMySalary
        title = "月薪資"
        salaryConfig = { salaryConfig }
        salary = { salary }
        disabled = { true }
        disabledMonth = { disabledMonth }
        updateSearchSalaryHandler = { (key, value) => {
          updateSearchSalaryHandler(key, value);
        }}
        searchSalaryHandler = { () => {
          searchSalaryHandler();
        }}
      />
      <div>&nbsp;</div>
      <TableWageStructure
        paycheckStructures = { paycheckStructures }
        viewPaycheckStructureHandler = {(paycheckStructureId) => {
          viewPaycheckStructureHandler(paycheckStructureId);
        }}
      />
      <div>&nbsp;</div>
      <TableSalaryExtraPayment
        removable = { false}
        extraPayments = { extraPayments }
        extraPaymentTotalCount = { extraPaymentTotalCount }
        extraPaymentCurrentPage = { extraPaymentCurrentPage }
        extraPaymentPageChangedHandler = { (value) => {
          extraPaymentPageChangedHandler(value);
        }}
      />
      { showLoadingPopup && <PopupLoading/> }
      { showPopupViewWageStructure && 
          <PopupViewWageStructure
            viewPaycheckStructureId = { viewPaycheckStructureId }
            cancelHandler = {() => {
              cancelHandler();
            }}
          /> 
      }
      {
        showSaveSuccessPopup &&
          <PopupExecuteResult
            title = "更新成功"
            confirmHandler = { closeSuccessPopupHandler }
          />
      }
    </MainContent>
  );
};

export default MyInfoSetting;
