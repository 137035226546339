import React from 'react';
import { useSelector } from 'react-redux';

import { Table, TableBody } from '@/component-style/Table';
import TableWrapper  from '@/components/UI/TableWrapper';
import Header from './Header';
import Item from './Item';

const TableUnlockPurchase = props => {
  const financeRoutes = useSelector(state => state.login.financeRoutes);
  const { projects } = props;

  const userUnlockable = financeRoutes.indexOf('UnlockPurchase-Unlock') !== -1;

  return (
    <TableWrapper title = "專案列表">
      <Table>
        <Header/>
        <TableBody>
          {
            projects.map(project =>
              <Item
                key = { project.projectID }
                project = { project }
                unlockable = { userUnlockable }
                financeUnlockHandler = { () => {
                  props.financeUnlockHandler(project.projectID);
                }}
              />
            )
          }
        </TableBody>
      </Table>
    </TableWrapper>
  );
};

export default TableUnlockPurchase;
