import React, { useState, useEffect, useCallback  } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { cloneDeep } from 'lodash';
import moment from 'moment';

import {
  postCreateLaborHealthInsuranceSetting,
  getLaborHealthInsuranceSettings,
  getLaborHealthInsuranceApplyDates,
} from '@/api/financeService';

import MyInputNumber from '@/components/UI/MyInputNumber';

import { laborHealthInsuranceTypeOptions } from '@/constant/options';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import MainContent from '@/component-style/MainContent';
import PanelWrapper from '@/components/UI/PanelWrapper';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import SelectNormal from '@/components/UI/Select/SelectNormal';
import { PSubtitle } from '@/component-style/TextElement';
import { ButtonBasic } from '@/component-style/Button';
import {
  DivFlexRow,
  DivItemWrapper,
} from '@/component-style/RWDWrapper';

import { integerConverter } from '@/utils/textUtils';
import { laborHealthInsuranceParamsConfig } from '@/constant/dataConfig/sysConfigModule.js';

import TableLaborHealthInsurance from '@/components/Table/LaborHealthInsurance/TableLaborHealthInsurance';

import PopupLaborHealthInsurance from '@/components/UI/Popup/PopupLaborHealthInsurance';

import DateSelectorTwo from '@/components/UI/DateSelectorTwo';

const DivPanelContainer = styled.div`
  margin-bottom: 40px;
`;

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding: 0;
  justify-content: flex-end;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const ButtonAdd = styled(ButtonBasic)`
  margin-right: 10px;
  width: 120px;

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`;

const LaborHealthInsuranceManagement = () => {
  const dispatch = useDispatch();
  const sysConfigRoutes = useSelector(state => state.login.sysConfigRoutes);
  const showSaveFailPopup = useSelector(state => state.apiFail.showSaveFailPopup);
  const apiFailPopupTitle = useSelector(state => state.apiFail.title);
  const apiFailPopupMessage = useSelector(state => state.apiFail.message);
  
  const [laborHealthInsuranceParams, setLaborHealthInsuranceParams] = useState(cloneDeep(laborHealthInsuranceParamsConfig));
  const [editInsuranceGrade, setEditInsuranceGrade] = useState(0);
  const [searchLaborHealthInsuranceType, setSearchLaborHealthInsuranceType] = useState(1);
  const [searchLaborHealthInsuranceApplyDatesOptionsByType, setSearchLaborHealthInsuranceApplyDatesOptionsByType] = useState([]);
  const [selectedApplyDate, setSelectedApplyDate] = useState("");

  const [laborHealthInsuranceSetting, setLaborHealthInsuranceSetting] = useState();
  const [showEditLaborHealthInsurancePopup, setShowEditLaborHealthInsurancePopup] = useState(false);

  const [isEditing, setIsEditing] = useState(false);
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [showSaveSuccessPopup, setShowSaveSuccessPopup] = useState(false);

  const userAddable = sysConfigRoutes.indexOf('LaborHealthInsuranceManagement-Add') !== -1;
  const userEditable = sysConfigRoutes.indexOf('LaborHealthInsuranceManagement-Edit') !== -1;

  // const uneditable = laborHealthInsuranceParams.holidays.length === 0
  //   && laborHealthInsuranceParams.restdays.length === 0
  //   && laborHealthInsuranceParams.workdays.length === 0;

  const reloadHandler = () => {
    setShowEditLaborHealthInsurancePopup(false);
    fetchLaborHealthInsuranceSetting();
  };

  const updateLaborHealthInsuranceParamsHandler = (key, value) => {
    const updateObj = { ...laborHealthInsuranceParams };
    updateObj[key] = value;
    setLaborHealthInsuranceParams({ ...updateObj });
  };

  const addNewLaborHealthInsuranceHandler = async () => {
    console.log("laborHealthInsuranceManagement.js addNewLaborHealthInsuranceHandler() laborHealthInsuranceParams:" + JSON.stringify(laborHealthInsuranceParams));
    try {
      await postCreateLaborHealthInsuranceSetting({
        ...laborHealthInsuranceParams,
      });
      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
      clearHandler();
      reloadHandler();
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '儲存失敗', err }));
      console.log(err);
    }
  };

  const clearHandler = () => {
    setIsEditing(false);
    // setNewDayDetail({ ...newDayConfig });
    setLaborHealthInsuranceParams(cloneDeep(laborHealthInsuranceParamsConfig))
  };

  const cancelHandler = () => {
    // setIsEditing(false);
    reloadHandler();
  };

  const apiFailConfirmHandler = () => {
    dispatch(CLOSE_SAVE_FAIL_POPUP());
  };

  const closeSaveSuccessPopupHandler = () => {
    setShowSaveSuccessPopup(false);
    setIsEditing(false);
  };

  // const goEditDetailHandler = insuranceGrade => {
  //   console.log("laobrHealthInsuranceManagement.js goEditDetailHandler() insuranceGrade:" + JSON.stringify(insuranceGrade));
    
  // }

  const fetchLaborHealthInsuranceSetting = async () => {
    setShowLoadingPopup(true);

    try {
      const { data } = await getLaborHealthInsuranceSettings({
        isEmployer: searchLaborHealthInsuranceType === 2,
        applyDate: selectedApplyDate,
      });
      setLaborHealthInsuranceSetting(data.settings);
      console.log(`laborHealthInsuranceManagement.js fetchLaborHealthInsuranceSetting() data=${JSON.stringify(data)}`);
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  // const initConfigData = useCallback(
  //   fetchLaborHealthInsuranceSetting, [])

  // useEffect(() => {
  //   initConfigData();
  // }, [initConfigData])

  const fetchLaborHealthInsuranceApplyDates = async () => {
    setSelectedApplyDate('');
    setShowLoadingPopup(true);

    try {
      console.log(`laborHealthInsuranceManagement.js fetchLaborHealthInsuranceApplyDates() searchLaborHealthInsuranceType=${JSON.stringify(searchLaborHealthInsuranceType)}`);
      const { data } = await getLaborHealthInsuranceApplyDates({
        isEmployer: searchLaborHealthInsuranceType === 2
      });
      // setLaborHealthInsuranceSetting(data.settings);
      console.log(`laborHealthInsuranceManagement.js fetchLaborHealthInsuranceApplyDates() data=${JSON.stringify(data)}`);
      setSearchLaborHealthInsuranceApplyDatesOptionsByType(
        data.dates.map((element)=> { return { value: element,
                                              desc: element,
                                            }
                                    }
      ));
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const initLaborHealthInsuranceApplyDates = useCallback(
    fetchLaborHealthInsuranceApplyDates, [searchLaborHealthInsuranceType]);

  useEffect(() => {
    initLaborHealthInsuranceApplyDates();
  }, [initLaborHealthInsuranceApplyDates]);

  return (
    <MainContent>
      <DivPanelContainer>
        <PanelWrapper title = "勞、健保參數管理">
          <DivFlexRow>
            <DivItemWrapper>
              <PSubtitle>類別</PSubtitle>
              <SelectNormal
                // disabled = { props.disabled }
                value = { searchLaborHealthInsuranceType }
                options = { laborHealthInsuranceTypeOptions }
                changeHandler = { value => {
                  setSearchLaborHealthInsuranceType(value);
                }}
              />  
            </DivItemWrapper>
            <DivItemWrapper>
              <PSubtitle>生效月份</PSubtitle>
              <SelectNormal
                value = { selectedApplyDate }
                options = {[
                  { value: '', desc: '請選擇生效年月' },
                  ...searchLaborHealthInsuranceApplyDatesOptionsByType,
                ]}
                changeHandler = { value => {
                  setSelectedApplyDate(value);
                }}
              />
            </DivItemWrapper>
          </DivFlexRow>
          <DivFlexRow>
            <DivButtonsWrapper>
              <ButtonAdd
               onClick = { fetchLaborHealthInsuranceSetting }
              >搜尋</ButtonAdd>
            </DivButtonsWrapper>
          </DivFlexRow>
        </PanelWrapper>
      </DivPanelContainer>
      <DivPanelContainer>
        <PanelWrapper>
          <DivFlexRow>
            <DivItemWrapper>
              <PSubtitle>類別</PSubtitle>
              <SelectNormal
                value = { laborHealthInsuranceParams.isEmployer ?  2 : 1 }
                options = { laborHealthInsuranceTypeOptions }
                changeHandler = { value => {
                  updateLaborHealthInsuranceParamsHandler('isEmployer', value === 2);
                }}
              />  
            </DivItemWrapper>
            <DivItemWrapper>
              <PSubtitle>生效月份</PSubtitle>
              <DateSelectorTwo
                value = { laborHealthInsuranceParams.applyDate }
                picker = "month"
                changeHandler = { value => {
                  updateLaborHealthInsuranceParamsHandler('applyDate', moment(value).format('YYYY-MM-01'));
                }}
              />
            </DivItemWrapper>
          </DivFlexRow>
          <DivFlexRow>
            <DivItemWrapper>
              <PSubtitle>投保級距</PSubtitle>
              <MyInputNumber
                disabled = { false }
                value = { laborHealthInsuranceParams.insuranceGrade }
                onChange = { value => {
                  updateLaborHealthInsuranceParamsHandler('insuranceGrade', value);
                }}
              />
            </DivItemWrapper>
            <DivItemWrapper>
              <PSubtitle>勞保僱主負擔</PSubtitle>
              <MyInputNumber
                disabled = { false }
                value = { laborHealthInsuranceParams.laborEmployerPay }
                onChange = { value => {
                  updateLaborHealthInsuranceParamsHandler('laborEmployerPay', integerConverter(value));
                }}
              />
            </DivItemWrapper>
            <DivItemWrapper>
              <PSubtitle>勞保勞工負擔</PSubtitle>
              <MyInputNumber
                disabled = { false }
                value = { laborHealthInsuranceParams.laborEmployeePay }
                onChange = { value => {
                  updateLaborHealthInsuranceParamsHandler('laborEmployeePay', integerConverter(value));
                }}
              />
            </DivItemWrapper>
          </DivFlexRow>
          <DivFlexRow>
            <DivItemWrapper>
              <PSubtitle>健保僱主負擔</PSubtitle>
              <MyInputNumber
                disabled = { false }
                value = { laborHealthInsuranceParams.healthEmployerPay }
                onChange = { value => {
                  updateLaborHealthInsuranceParamsHandler('healthEmployerPay', integerConverter(value));
                }}
              />
            </DivItemWrapper>
            <DivItemWrapper>
              <PSubtitle>健保勞工負擔</PSubtitle>
              <MyInputNumber
                disabled = { false }
                value = { laborHealthInsuranceParams.healthEmployeePay }
                onChange = { value => {
                  updateLaborHealthInsuranceParamsHandler('healthEmployeePay', integerConverter(value));
                }}
              />
            </DivItemWrapper>
          </DivFlexRow>
          <DivFlexRow>
            <DivButtonsWrapper>
              <ButtonAdd
                // disabled = { !userAddable }
                onClick = { addNewLaborHealthInsuranceHandler }
              >新增</ButtonAdd>
            </DivButtonsWrapper>
          </DivFlexRow>
        </PanelWrapper>
      </DivPanelContainer>
      {/* <TableWrapper> */}
        <TableLaborHealthInsurance
          laborHealthInsuranceSetting = { laborHealthInsuranceSetting }
          editDetailHandler = { (insuranceGrade) => {
            console.log(`laborHealthInsuranceManagement.js editDetailHandler insuranceGrade=${insuranceGrade}`);
            setEditInsuranceGrade(insuranceGrade);
            setShowEditLaborHealthInsurancePopup(true);
          }}
        />
      {/* </TableWrapper> */}
      { showLoadingPopup && <PopupLoading/> }
      {
        showSaveSuccessPopup &&
          <PopupExecuteResult
            title = "儲存成功"
            confirmHandler = { closeSaveSuccessPopupHandler }
          />
      }
      {
        showSaveFailPopup &&
          <PopupExecuteResult
            title = { apiFailPopupTitle }
            text = { apiFailPopupMessage }
            confirmHandler = { apiFailConfirmHandler }
          />
      }
      { 
        showEditLaborHealthInsurancePopup &&
          <PopupLaborHealthInsurance
            laborHealthInsuranceSetting = { laborHealthInsuranceSetting }
            editInsuranceGrade = { editInsuranceGrade }
            cancelHandler = { cancelHandler }
            reloadHandler = { reloadHandler }
          />
      }
    </MainContent>
  );
};

export default LaborHealthInsuranceManagement;
