import React from 'react';
import styled from 'styled-components';

import { InputBasic } from '@/component-style/Input';
import { ButtonCancel } from '@/component-style/Button';
import SelectNormal from '@/components/UI/Select/SelectNormal';
import {
  getDistOptions,
  getZipCode,
}  from '@/constant/zipCodes';

import { countyOptions } from '@/constant/options';

const DivWrapper = styled.div`
  width: 100%;
  
  @media (min-width: 768px) {
    width: calc(100% - 135px);
  }
`;

const DivFWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const DivSelectorWrapper = styled.div`
  margin-right: 15px;
  margin-bottom: 10px;

  & .ant-select {
    width: 140px;
  }

  @media (min-width: 1920px) {
    .ant-select { width: 140px; }
  }
`;

const InputPostalCode = styled(InputBasic)`
  width: 140px;
  margin-right: 15px;
  margin-bottom: 10px;

  @media (min-width: 1920px) {
    width: 140px;
  }
`;

const InputAddress = styled(InputBasic)`
  width: 300px;
  margin-right: 15px;
  margin-bottom: 10px;

  @media (max-width: 320px) {
    width: 260px;
  };

  @media (min-width: 1920px) {
    width: 300px;
  }
`;

const ButtonCopy = styled(ButtonCancel)`
  width: 100px;
  margin-bottom: 10px;
`;

const AddressInfoSelector = props => {
  const { address } = props;

  return (
    <DivWrapper>
      <DivFWrapper>
        <DivSelectorWrapper>
          <SelectNormal
            disabled = { props.disabled }
            value = { address.country }
            options = {[
              { value: 'taiwan', desc: '台灣' },
              { value: 'other', desc: '其他' }
            ]}
            changeHandler = { value => {
              props.updateAddressHandler({
                country: value,
                city: '',
                town: '',
                postal: '',
                address: '',
              });
            }}
          />
        </DivSelectorWrapper>
        {
          address.country === 'taiwan' &&
          <React.Fragment>
            <DivSelectorWrapper>
              <SelectNormal
                disabled = { props.disabled }
                value = { address.city }
                options = {[
                  { value: '', desc: '請選擇縣市' },
                  ...countyOptions,
                ]}
                changeHandler = { value => {
                  props.updateAddressHandler({
                    ...address,
                    city: value,
                    town: '',
                    postal: '',
                  });
                }}
              />
            </DivSelectorWrapper>
            <DivSelectorWrapper>
              <SelectNormal
                disabled = { props.disabled }
                value = { address.town }
                options = {[
                  { value: '', desc: '請選擇鄉鎮' },
                  ...getDistOptions(address.city),
                ]}
                changeHandler = { value => {
                  props.updateAddressHandler({
                    ...address,
                    town: value,
                    postal: getZipCode(address.city, value),
                  });
                }}
              />
            </DivSelectorWrapper>
            <InputPostalCode
              readonly
              disabled = { true }
              value = { address.postal }
              placeholder = "郵遞區號"
            />
          </React.Fragment>
        }
        <InputAddress
          disabled = { props.disabled }
          value = { address.address }
          placeholder = "詳細地址"
          onChange = {({ target }) => {
            props.updateAddressHandler({
              ...address,
              address: target.value,
            });
          }}
        />
        {
          props.showCopyButton &&
          <ButtonCopy
            disabled = { props.disabled }
            onClick = { props.copyAddressHandler }
          >同上</ButtonCopy>
        }
      </DivFWrapper>
    </DivWrapper>
  );
};

export default AddressInfoSelector;
