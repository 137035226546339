import React from 'react';
import styled from 'styled-components';

import NavigationItem from '../NavigationItem';
import IconDashboard from '../../../assets/images/icon_dashboard.svg';

const DivIcon = styled.span`
  display: inline-block;
  margin-left: 50px;
  width: 24px;
  height: 24px;
  background-image: url(${props => props.url});
`;

const NavigationListDashboard = props => {

  return (
    <NavigationItem
      url = "/Dashboard"
      title = "首頁"
    >
      <DivIcon
        url = { IconDashboard }
      />
    </NavigationItem>
  );
};

export default NavigationListDashboard;
