import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';

import ErrorAlert from '../UI/ErrorAlert';
import { InputBasic } from '../../component-style/Input';
import { ButtonBasic } from '../../component-style/Button';

const FormResetPassword = styled.form`
  width: 360px;

  @media (max-width: 768px) {
    padding: 0 30px;
    box-sizing: border-box;
  }

  @media (max-width: 320px) {
    width: 320px;
  }
`;

const H3Title = styled.h3`
  font-size: 24px;
  font-weight: 300;
  color: #57585a;
  margin-bottom: 10px;
`;

const PResetInfo = styled.p`
  width: 100%;
  font-size: 12px;
  font-weight: 300;
  line-height: 1.33;
  color: #8b8b8b;
  margin-bottom: 15px;
`;

const LinkElement = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  display: block;
  margin-top: 10px;
  height: 14px;
  line-height: 14px;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0.4px;
  text-align: center;
  color: #e45050;
`;

const InputLogin = styled(InputBasic)`
  width: 100%;
  border: solid 2px #f8f8f8;
  margin-bottom: 20px;

  @media (min-width: 1920px) {
    width: 100%;
  }
`;

const ResetPasswordForm = props => {

  const submitHandler = async (event) => {
    event.preventDefault();
    const reCaptchaToken = await props.googleReCaptchaProps
      .executeRecaptcha('resetPW');
    props.resetPW(reCaptchaToken);
  };

  return (
    <FormResetPassword
      onSubmit = { submitHandler }
    >
      <H3Title>Forgot your password?</H3Title>
      <PResetInfo>
        Enter your user ID and verification code, the system will automatically send a new password to your mailbox. Please sign in with new password.
      </PResetInfo>
      {
        props.showError &&
        <ErrorAlert
          errorMsg = { props.errorMsg }
        />
      }
      <InputLogin type="text"
        name="id"
        placeholder="User ID"
        value = { props.userId }
        onChange = { event => props.setAccount(event.target.value) }
      />
      <ButtonBasic>Resend</ButtonBasic>
      <LinkElement
        to="/Login"
      >Login</LinkElement>
    </FormResetPassword>
  );
};

export default withGoogleReCaptcha(ResetPasswordForm);
