import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import TableWrapper from '@/components/UI/TableWrapper';
import PageSelector from '@/components/UI/PageSelector';
import { Table, TableBody } from '@/component-style/Table';
import Header from './Header';
import Item from './Item';

const TableSystemRoles = props => {
  const history = useHistory();
  const authorityRoutes = useSelector(state => state.login.authorityRoutes);

  const { roles } = props;

  const disableDetailPage = authorityRoutes.indexOf('RoleDetail') === -1;
  const userDeletable = authorityRoutes.indexOf('RoleDetail-Delete') !== -1;

  return (
    <TableWrapper title = "系統角色列表">
      <Table>
        <Header/>
        <TableBody>
          {
            roles.map((role, index) =>
              <Item
                role = { role }
                key = { role.roleID }
                disableDetailPage = { disableDetailPage }
                deletable = { userDeletable }
                deleteHandler = { () => {
                  props.deleteHandler(index)
                }}
                goDetailHandler = {() => {
                  history.push(`/Authority/RoleDetail/${role.roleID}`);
                }}
              />
            )
          }
        </TableBody>
      </Table>
      <PageSelector
        total = { props.totalCount }
        current = { props.currentPage }
        pageChangedHandler = { props.pageChangedHandler }
      />
    </TableWrapper>
  );
};

export default TableSystemRoles;
