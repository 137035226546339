import React from 'react';
import styled from 'styled-components';

const FooterWrapper = styled.footer`
  position: absolute;
  bottom: 27px;
  width: 100%;
  text-align: center;
`;

const PCopyright = styled.p`
  font-size: 12px;
  font-weight: 300;
  color: #8b8b8b;
  height: 14px;
  line-height: 14px;
`;

const Footer = () => (
  <FooterWrapper>
    <PCopyright>
      &copy; 2020 Veda International Corp.
    </PCopyright>
  </FooterWrapper>
);

export default Footer;
