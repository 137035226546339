import React from 'react';
import { Upload, Button } from 'antd';
import 'antd/dist/antd.css';
import '../index.css';

const UploadNormal = props => {
  const config = {
    onRemove: () => {
      props.updateUploadFiles([]);
    },
    beforeUpload: file => {
      props.updateUploadFiles([file]);
      return false;
    },
    showUploadList: {
      showPreviewIcon: true,
      showDownloadIcon: true,
      showRemoveIcon: true,
    },
    accept: '.gif,.jpg,.png,.pdf',
  };


  return (
    <Upload { ...config }
      fileList = { props.uploadFiles }
    >
      <Button
        disabled = { props.disabled }
      >選擇檔案
      </Button>
    </Upload>
  );
};

export default UploadNormal;
