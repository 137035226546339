import React from 'react';
import styled from 'styled-components';

import UploadNormal from '@/components/UI/Upload/UploadNormal';
import TableAttachmentList from '@/components/Table/TableAttachmentList';
import { InputBasic } from '@/component-style/Input';
import { DivPopupWrapper, DivPopup } from '@/component-style/Popup';
import { ButtonBasic, ButtonCancel } from '@/component-style/Button';
import { DivFlexRow } from '@/component-style/RWDWrapper';

const DivItemWrapper = styled(DivFlexRow)`
  padding: 0;
  margin-bottom: 10px;
`;

const Input = styled(InputBasic)`

  @media (min-width: 1920px) { width: 300px }
`;

const DivButtonsWrapper = styled.div`
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const DivUploadWrapper = styled.div`
  margin-bottom: 20px;
`;

const H3Title = styled.h3`
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: #8b8b8b;
  margin-bottom: 10px;
`;

const ButtonConfirm = styled(ButtonBasic)`
  width: 120px;
`;

const ButtonDiscard = styled(ButtonCancel)`
  width: 120px;
  margin-right: 10px;
`;

const PTexts = styled.p`
  width: 120px;
  font-size: 16px;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0.43px;
  color: #8b8b8b;
`;

const PopupPurchaseAttachments = props => {
  const disableUpload = props.uploadFiles.length === 0
    || props.poFileName === '';

  return (
    <DivPopupWrapper>
      <DivPopup>
        <H3Title>{ props.title || '採購附件管理' }</H3Title>
        {
          props.fileChangable &&
          <>
            <DivItemWrapper>
              <PTexts>採購PO編號</PTexts>
              <Input
                value = { props.poFileName }
                onChange = { ({ target }) => {
                  props.updatePOFileNameHandler(target.value);
                }}
              />
            </DivItemWrapper>
            <DivUploadWrapper>
              <UploadNormal
                uploadFiles = { props.uploadFiles }
                updateUploadFiles = { props.updateUploadFiles }
              />
            </DivUploadWrapper>
          </>
        }
        <TableAttachmentList
          fileChangable = { props.fileChangable }
          fileDescTitle = "客戶採購編號"
          fileList = { props.fileList }
          deleteFileHandler = { props.deleteFileHandler }
        />
        <DivButtonsWrapper>
          <ButtonDiscard
            onClick = { props.cancelHandler }
          >關閉
          </ButtonDiscard>
          <ButtonConfirm
            disabled = { disableUpload }
            onClick = { props.fileUploadHandler }
          >上傳
          </ButtonConfirm>
        </DivButtonsWrapper>
      </DivPopup>
    </DivPopupWrapper>
  );
};

export default PopupPurchaseAttachments;
