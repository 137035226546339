import React from 'react';
import { Route } from 'react-router-dom';

import ExpenseParams from '@/containers/sysConfigModule/expenseParams';
import WorkingHoursParams from '@/containers/sysConfigModule/workingHoursParams';
import ExpenseTypeManagement from '@/containers/sysConfigModule/expenseTypeManagement';
import ExpenseTagManagement from '@/containers/sysConfigModule/expenseTagManagement';
import VendorTypeManagement from '@/containers/sysConfigModule/vendorTypeManagement';
import VendorTagManagement from '@/containers/sysConfigModule/vendorTagManagement';
import SystemParams from '@/containers/sysConfigModule/systemParams';
import CommonTypeManagement from '@/containers/sysConfigModule/commonTypeManagement';
import WorkingHoursTypeManagement from '@/containers/sysConfigModule/workingHoursTypeManagement';
import WorkingHoursTagManagement from '@/containers/sysConfigModule/workingHoursTagManagement';
import TodoTypeManagement from '@/containers/sysConfigModule/todoTypeManagement';
import TodoTagManagement from '@/containers/sysConfigModule/todoTagManagement';
import ActivityIdeaTypeManagement from '@/containers/sysConfigModule/activityIdeaTypeManagement';
import ActivityIdeaTagManagement from '@/containers/sysConfigModule/activityIdeaTagManagement';
import VenueTypeManagement from '@/containers/sysConfigModule/venueTypeManagement';
import HolidayManagement from '@/containers/sysConfigModule/holidayManagement';
import LaborHealthInsuranceManagement from '@/containers/sysConfigModule/laborHealthInsuranceManagement';
import LeaveManagement from '@/containers/sysConfigModule/leaveManagement';
// import ExtraPaymentsManagement from '@/containers/sysConfigModule/extraPaymentsManagement';

const sysConfigRoutes = [
  {
    key: 'ExpenseParams',
    path: '/SysConfig/ExpenseParams',
    component: ExpenseParams,
  },
  {
    key: 'WorkingHoursParams',
    path: '/SysConfig/WorkingHoursParams',
    component: WorkingHoursParams,
  },
  {
    key: 'ExpenseTypeManagement',
    path: '/SysConfig/ExpenseTypeManagement',
    component: ExpenseTypeManagement,
  },
  {
    key: 'ExpenseTagManagement',
    path: '/SysConfig/ExpenseTagManagement/:id',
    component: ExpenseTagManagement,
  },
  {
    key: 'VendorTypeManagement',
    path: '/SysConfig/VendorTypeManagement',
    component: VendorTypeManagement,
  },
  {
    key: 'VendorTagManagement',
    path: '/SysConfig/VendorTagManagement/:id',
    component: VendorTagManagement,
  },
  {
    key: 'SystemParams',
    path: '/SysConfig/SystemParams',
    component: SystemParams,
  },
  {
    key: 'CommonTypeManagement',
    path: '/SysConfig/CommonTypeManagement',
    component: CommonTypeManagement,
  },
  {
    key: 'WorkingHoursTypeManagement',
    path: '/SysConfig/WorkingHoursTypeManagement',
    component: WorkingHoursTypeManagement,
  },
  {
    key: 'WorkingHoursTagManagement',
    path: '/SysConfig/WorkingHoursTagManagement/:id',
    component: WorkingHoursTagManagement,
  },
  {
    key: 'TodoTypeManagement',
    path: '/SysConfig/TodoTypeManagement',
    component: TodoTypeManagement,
  },
  {
    key: 'TodoTagManagement',
    path: '/SysConfig/TodoTagManagement/:id',
    component: TodoTagManagement,
  },
  {
    key: 'ActivityIdeaTypeManagement',
    path: '/SysConfig/ActivityIdeaTypeManagement',
    component: ActivityIdeaTypeManagement,
  },
  {
    key: 'ActivityIdeaTagManagement',
    path: '/SysConfig/ActivityIdeaTagManagement/:id',
    component: ActivityIdeaTagManagement,
  },
  {
    key: 'VenueTypeManagement',
    path: '/SysConfig/VenueTypeManagement',
    component: VenueTypeManagement,
  },
  {
    key: 'HolidayManagement',
    path: '/SysConfig/HolidayManagement',
    component: HolidayManagement,
  },
  {
    key: 'LaborHealthInsuranceManagement',
    path: '/SysConfig/LaborHealthInsuranceManagement',
    component: LaborHealthInsuranceManagement,
  },
  {
    key: 'LeaveManagement',
    path: '/SysConfig/LeaveManagement',
    component: LeaveManagement,
  },
  // {
  //   key: 'ExtraPaymentsManagement',
  //   path: '/SysConfig/ExtraPaymentsManagement',
  //   component: ExtraPaymentsManagement,
  // },
];

const RoutesSysConfigModule = validRoutes => sysConfigRoutes
  .filter(({ key }) => validRoutes.indexOf(key) !== -1)
  .map(({ path, component }) =>
    <Route
      key = { path }
      path = { path }
      component = { component }
    />
  );

export default RoutesSysConfigModule;
