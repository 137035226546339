import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function useHeaderTitleHook () {
  const { pathname } = useLocation();
  const [headerTitle, setHeaderTitle] = useState('首頁');

  const headerTitleDict = {
    'Dashboard': '首頁',
    'Project': '專案管理',
    'Information': '資料管理',
    'Attendance': '同仁管理',
    'Expense': '費用報支',
    'Finance': '財務管理',
    'Authority': '權限管理',
    'SysConfig': '系統參數管理',
    'HR': '人事資料管理',
  };

  useEffect(() => {
    const module = pathname
      .replace(/^\//, '')
      .split('/')
      .shift();
    const title = headerTitleDict[module];

    setHeaderTitle(title);
  }, [pathname, headerTitleDict]);

  return headerTitle;
};

export default useHeaderTitleHook;
