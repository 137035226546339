import React from 'react';
import styled from 'styled-components';

import Checkbox from '@/components/UI/Checkbox';
import { TableHead, TableRow, ThItem } from '@/component-style/Table';

const THead = styled(TableHead)`
  min-width: ${ props => {
    if (props.showCheckbox && props.showDetailButton) return '1460px';
    if (props.showCheckbox || props.showDetailButton) return '1400px';
    return '1340px';
  }};
`;

const Th60 = styled(ThItem)`
  width: 60px;
`;

const Th80 = styled(ThItem)`
  width: 80px;
`;

const Th120 = styled(ThItem)`
  width: 120px;
`;

const Th160 = styled(ThItem)`
  width: 160px;
`;

const Header = props => {
  
  return (
    <THead
      showCheckbox = { props.showCheckbox }
      showDetailButton = { props.showDetailButton }
    >
      <TableRow>
        {
          props.showCheckbox &&
          <Th60>
            <Checkbox
              checked = { props.selectAll }
              changeHandler = { props.selectAllHandler }
            />
          </Th60>
        }
        <Th80>所屬公司</Th80>
        <Th120>專案代號</Th120>
        <Th160>專案名稱</Th160>
        <Th120>工作項目</Th120>
        <Th120>工作細項</Th120>
        <Th120>預計完成日</Th120>
        <Th120>實際完成日</Th120>
        <Th120>負責單位</Th120>
        <Th120>負責成員</Th120>
        <Th60>完成</Th60>
        <Th80>延遲天數</Th80>
        <Th120>工作備註</Th120>
        { props.showDetailButton && <Th60></Th60> }
      </TableRow>
    </THead>
  );
};

export default Header;
