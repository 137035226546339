import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import {
  deleteSystemRole,
  postToGetSystemRoles,
} from '@/api/authService';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import MainContent from '@/component-style/MainContent';
import PanelWrapper from '@/components/UI/PanelWrapper';
import TableSystemRoles from '@/components/Table/Authority/TableSystemRoles';
import { PSubtitle } from '@/component-style/TextElement';
import { InputBasic } from '@/component-style/Input';
import { ButtonBasic } from '@/component-style/Button';
import SearchButtonSet from '@/components/UI/SearchButtonSet';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import PopupExecuteConfirm from '@/components/UI/Popup/PopupExecuteConfirm';

import { searchConfigGenerator } from '@/utils/apiRequestUtils';

import {
  DivFlexRow,
  DivItemWrapper,
} from '@/component-style/RWDWrapper';

const DivPanelContainer = styled.div`
  margin-bottom: 40px;
`;

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding: 0;
  justify-content: space-between;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const ButtonAdd = styled(ButtonBasic)`
  margin-right: 10px;
  width: 120px;

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`;

const selectedRoleConfig = {
  roleID: '',
  roleCode: '',
  roleName: '',
};

const RoleManagement = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const authorityRoutes = useSelector(state => state.login.authorityRoutes);
  const showSaveFailPopup = useSelector(state => state.apiFail.showSaveFailPopup);
  const apiFailPopupTitle = useSelector(state => state.apiFail.title);
  const apiFailPopupMessage = useSelector(state => state.apiFail.message);

  const [roles, setRoles] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [config, setConfig] = useState({
    roleCode: '',
    roleName: '',
  });

  const [selectedRole, setSelectedRole] = useState({ ...selectedRoleConfig });

  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showDeleteSuccessPopup, setShowDeleteSuccessPopup] = useState(false);

  const userAddable = authorityRoutes.indexOf('RoleCreation') !== -1;

  const searchable = config.roleCode !== ''
    || config.roleName !== '';

  const clearHandler = () => {
    setConfig({ roleCode: '', roleName: '' });
  };

  const searchHandler = () => {
    setCurrentPageHandler(1);
  };

  const setCurrentPageHandler = page => {
    setCurrentPage(page);
    fetchRoles(page);
  };

  const deleteHandler = index => {
    const role = roles[index];
    setSelectedRole({ ...role });
    setShowDeletePopup(true);
  };

  const deleteRoleHandler = async () => {
    setShowDeletePopup(false);
    setShowLoadingPopup(true);
    const { roleID } = selectedRole;

    try {
      await deleteSystemRole(roleID);
      setShowLoadingPopup(false);
      setShowDeleteSuccessPopup(true);
    } catch(err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '刪除失敗', err }));
      console.log(err);
    }
  };

  const fetchRoles = async (page = currentPage) => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetSystemRoles(
        searchConfigGenerator({ ...config, page, amount: 10 })
      )
      
      const { roles, totalCount } = data;
      setRoles([...roles]);
      setTotalCount(totalCount|| 0);
    } catch(err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const initRoles = useCallback(fetchRoles, []);

  useEffect(() => {
    initRoles();
  }, [initRoles]);

  return (
    <MainContent>
      <DivPanelContainer>
        <PanelWrapper title = "角色管理">
          <DivFlexRow>
            <DivItemWrapper>
              <PSubtitle>角色編號</PSubtitle>
              <InputBasic
                value = { config.roleCode }
                onChange = { ({ target }) => {
                  setConfig(prevState => ({
                    ...prevState,
                    roleCode: target.value,
                  }))
                }}
              />
            </DivItemWrapper>
            <DivItemWrapper>
              <PSubtitle>角色名稱</PSubtitle>
              <InputBasic
                value = { config.roleName }
                onChange = { ({ target }) => {
                  setConfig(prevState => ({
                    ...prevState,
                    roleCode: target.value,
                  }))
                }}
              />
            </DivItemWrapper>
          </DivFlexRow>
          <DivFlexRow>
            <DivButtonsWrapper>
              <ButtonAdd
                disabled = { !userAddable }
                onClick = { () => {
                  history.push('/Authority/RoleCreation')
                }}
              >新增</ButtonAdd>
              <SearchButtonSet
                searchable = { searchable }
                clearHandler = { clearHandler }
                searchHandler = { searchHandler }
              />
            </DivButtonsWrapper>
          </DivFlexRow>
        </PanelWrapper>
      </DivPanelContainer>
      <TableSystemRoles
        roles = { roles }
        totalCount = { totalCount }
        currentPage = { currentPage }
        deleteHandler = { deleteHandler }
        pageChangedHandler = { setCurrentPageHandler }
      />
      { showLoadingPopup && <PopupLoading/> }
      {
        showDeletePopup &&
          <PopupExecuteConfirm
            title = "刪除系統角色"
            text = { `確定刪除 ${ selectedRole.roleName } 系統角色？` }
            confirmHandler = { deleteRoleHandler }
            cancelHandler = { () => { setShowDeletePopup(false) }}
          />
      }
      {
        showDeleteSuccessPopup &&
          <PopupExecuteResult
            title = "刪除成功"
            confirmHandler = { () => {
              setShowDeleteSuccessPopup(false);
              setCurrentPageHandler(1);
            }}
          />
      }
      {
        showSaveFailPopup &&
          <PopupExecuteResult
            title = { apiFailPopupTitle }
            text = { apiFailPopupMessage }
            confirmHandler = { () => {
              dispatch(CLOSE_SAVE_FAIL_POPUP());
            }}
          />
      }
    </MainContent>
  );
};

export default RoleManagement;
