import React from 'react';
import styled from 'styled-components';

import { TdItem, TableRow } from '@/component-style/Table';
import { ButtonBasic } from '@/component-style/Button';
import { ButtonGoDetail } from '@/component-style/Button';

const TrWrapper = styled(TableRow)`
  border-bottom: solid 2px #f2f2f2;
  box-sizing: border-box;
  min-width: 720px;

  &:last-child {
    border-bottom: none;
  }
`;

const Td80 = styled(TdItem)`
  width: 80px;
`;

const Td160 = styled(TdItem)`
  width: 160px;
`;

const ButtonDelete = styled(ButtonBasic)`
  width: 80px;
  height: 30px;
`;

const Item = props => {
  const { role } = props;

  return (
    <TrWrapper>
      <Td160>{ role.roleCode }</Td160>
      <Td160>{ role.roleName }</Td160>
      <Td160>
        { role.enable ? '啟用' : '停用' }
      </Td160>
      <Td160>
        <ButtonDelete
          disabled = { !props.deletable }
          onClick = { props.deleteHandler }
        >刪除</ButtonDelete>
      </Td160>
      <Td80>
        <ButtonGoDetail
          disabled = { props.disableDetailPage }
          onClick = { props.goDetailHandler }
        >...
        </ButtonGoDetail>
      </Td80>
    </TrWrapper>
  );
};

export default Item;
