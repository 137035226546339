export const basicBooleanOptions = [
  { value: '', desc: '請選擇' },
  { value: true, desc: '是' },
  { value: false, desc: '否' },
];

export const scheduleChargeOptions = [
  { value: '', desc: '請選擇' },
  { value: 'veda', desc: '頤德' },
  { value: 'customer', desc: '客戶' },
  { value: 'both', desc: '頤德/客戶' },
];

export const evaluationOptions = [
  { value: 0, desc: '請選擇' },
  { value: 1, desc: '1' },
  { value: 2, desc: '2' },
  { value: 3, desc: '3' },
  { value: 4, desc: '4' },
  { value: 5, desc: '5' },
  { value: 6, desc: '6' },
  { value: 7, desc: '7' },
  { value: 8, desc: '8' },
  { value: 9, desc: '9' },
  { value: 10, desc: '10' },
];

export const invitationConfirmStatus = [
  { value: '', desc: '請選擇出席狀況' },
  { value: 'unchecked', desc: '尚未確認' },
  { value: 'yes', desc: '確定會來' },
  { value: 'maybe', desc: '可能會來' },
  { value: 'delegate', desc: '另派人參加' },
  { value: 'depends', desc: '視截稿狀況' },
  { value: 'thatDay', desc: '當天決定' },
  { value: 'notSure', desc: '不確定' },
  { value: 'uncontacted', desc: '尚未連絡上' },
  { value: 'no', desc: '不會來' },
  { value: 'later', desc: '稍後再撥' },
];

export const genderOptions = [
  { value: '', desc: '請選擇性別' },
  { value: 'male', desc: '先生' },
  { value: 'female', desc: '小姐' },
];

export const identityTypeOptions = [
  { value: '', desc: '請選擇身份' },
  { value: 'local', desc: '本國人' },
  { value: 'under183', desc: '外國人未滿183天' },
  { value: 'over183', desc: '本國人已滿183天' },
  { value: 'resident', desc: '本國人持有居留證' },
  { value: 'work', desc: '本國人持有工作證' },
];

export const countyOptions = [
  { value: 'taipeiCity', desc: '臺北市' },
  { value: 'keelungCity', desc: '基隆市' },
  { value: 'lienchiangCounty', desc: '連江縣' },
  { value: 'newTaipeiCity', desc: '新北市' },
  { value: 'yilanCounty', desc: '宜蘭縣' },
  { value: 'diaoyutai', desc: '釣魚台' },
  { value: 'hsinchuCity', desc: '新竹市' },
  { value: 'hsinchuCounty', desc: '新竹縣' },
  { value: 'taoyuanCity', desc: '桃園市' },
  { value: 'miaoliCounty', desc: '苗栗縣' },
  { value: 'taichungCity', desc: '臺中市' },
  { value: 'changhuaCounty', desc: '彰化縣' },
  { value: 'nantouCounty', desc: '南投縣' },
  { value: 'chiayiCity', desc: '嘉義市' },
  { value: 'chiayiCounty', desc: '嘉義縣' },
  { value: 'yunlinCounty', desc: '雲林縣' },
  { value: 'tainanCity', desc: '臺南市' },
  { value: 'kaohsiungCity', desc: '高雄市' },
  { value: 'penghuCounty', desc: '澎湖縣' },
  { value: 'kinmenCounty', desc: '金門縣' },
  { value: 'pingtungCounty', desc: '屏東縣' },
  { value: 'taitungCounty', desc: '臺東縣' },
  { value: 'hualienCounty', desc: '花蓮縣' },
];

export const customerSourceOptions = [
  { value: 'media', desc: '媒體介紹' },
  { value: 'pitch', desc: 'Pitch' },
  { value: 'customer', desc: '客戶介紹' },
  { value: 'guest', desc: '自己上門' },
];

export const customerTypeOptions = [
  { value: 'contract', desc: '合約客戶' },
  { value: 'project', desc: '專案客戶' },
  { value: 'proposal', desc: '提案客戶' },
];

export const vendorQualifications = [
  { value: '', desc: '請選擇資格' },
  { value: 'contract', desc: '簽約' },
  { value: 'project', desc: '專案' },
  { value: 'deactivated', desc: '停用' },
  { value: 'assigned', desc: '客戶指定' },
  { value: 'unnegotiable', desc: '廠商無法議價' },
];

export const vendorInvoiceTypes = [
  { value: '', desc: '請選擇發票種類' },
  { value: 'backup', desc: '後補' },
  { value: 'taxable', desc: '應稅發票' },
  { value: 'untaxed', desc: '免稅發票' },
  { value: 'taxedVoucher', desc: '其他含稅額憑證' },
  { value: 'invoice', desc: '票根' },
  { value: 'receipt', desc: '收據' },
  { value: 'certificate', desc: '證明單' },
  { value: 'handlingFee', desc: '手續費' },
  { value: 'service', desc: '勞務單' },
  { value: 'note', desc: '折讓單' },
  { value: 'others', desc: '其他' },
];

export const vendorPayments = [
  { value: '銀行轉帳', desc: '銀行轉帳' },
  { value: 'creditCard', desc: '公司信用卡' },
  { value: 'internal', desc: '內部轉帳' },
  { value: 'cash', desc: '現金' },
  { value: 'loan', desc: '預借零用金' },
  { value: 'advance', desc: '代墊款' },
];

export const vendorInvoicePeriod = [
  { value: '', desc: '請選擇種類' },
  { value: '7', desc: '7天' },
  { value: '30', desc: '月結30天' },
  { value: '60', desc: '月結60天' },
  { value: '90', desc: '月結90天' },
  { value: '120', desc: '月結120天' },
];

export const taiwanAreaOptions = [
  { value: 'TNM', desc: '北北基' },
  { value: 'THM', desc: '桃竹苗' },
  { value: 'TCH', desc: '中彰投' },
  { value: 'YCT', desc: '雲嘉南' },
  { value: 'KP', desc: '高屏' },
  { value: 'YHT', desc: '宜花東' },
];

export const expenseApprovalStates = [
  { value: 'draft', desc: '填寫中' },
  { value: 'projectManagerVerify', desc: '專案負責人審核中' },
  { value: 'projectDirectorVerify', desc: '總監審核中' },
  { value: 'financeVerify', desc: '財會審核中' },
  { value: 'financeDiscard', desc: '會計作廢' },
  { value: 'financeReject', desc: '財會退回' },
  { value: 'generalManagerVerifying', desc: '總經理審核中' },
  { value: 'generalManagerApproved', desc: '總經理同意' },
  { value: 'userDeleted', desc: '經辦自行退回' },
];

export const commonTypeCategorys = [
  { value: 'companyType', desc: '公司別' },
  { value: 'industry', desc: '產業別' },
  { value: 'department', desc: '部門別' },
  { value: 'journalistType', desc: '採訪路線' },
  { value: 'mediaType', desc: '媒體類別' },
  { value: 'projectType', desc: '專案型態' },
  { value: 'declareType', desc: '申報種類' },
  { value: 'specialFeature', desc: '特殊資格' },
];

export const venueTypeCategories = [
  { value: 'type', desc: '場地類別' },
  { value: 'characteristics', desc: '場地特色' },
  { value: 'equipments', desc: '場地設備 '}
];

export const projectRolesOptions = [
  { value: 'chairman', desc: '董事長' },
  { value: 'generalManager', desc: '總經理' },
  { value: 'director', desc: '總監' },
  { value: 'projectManager', desc: '專案負責人' },
  { value: 'normal', desc: '專案執行/行政' },
  { value: 'accounting', desc: '會計' },
  { value: 'intern', desc: '實習生/工讀生' },
  { value: 'hr', desc: '人資' },
];

//加班日類別
export const overtimeDateTypeOptions = [
  { value: 'workdays', desc: '工作日' },
  { value: 'restdays', desc: '休息日' },
  { value: 'holidays', desc: '例假日' },
  { value: 'nationalHolidays', desc: '國定假日' },
];

//leave setting-適用對象
export const leaveTypeApplicableForOptions = [
  { value: 'female', desc: '女性' },
  { value: 'male', desc: '男性' },
  { value: 'all', desc: '全部' },
];

//leave setting-未休假時數轉換
export const leaveTypePostExpiryActionOptions = [
  { value: 'gone', desc: '作廢' },
  { value: 'encash', desc: '轉成薪水' },
  { value: 'carryforward', desc: '遞延使用' },
];

//leave setting-未休假時數轉換
export const leaveTypGiveDurationTypeOptions = [
  { value: 'yearly', desc: '每年給予' },
  { value: 'once', desc: '依次給予' },
  { value: 'onboard', desc: '依年資給予' },
];

export const extraPaymentsTypeOptions = [
  { value: 'bonus', desc: '獎金' },
  { value: 'compensation', desc: '補助' },
];

export const extraPaymentsRecipientTypeOptions = [
  { value: 'all', desc: '全部員工' },
  { value: 'list', desc: '部份員工' },
];

export const extraPaymentsMethodOptions = [
  { value: 'fixed', desc: '一次給予' },
  { value: 'installment', desc: '分期給予' },
];

export const onboardStateOptions = [
  { value: 'onboard', desc: '在職' },
  { value: 'offboard', desc: '離職' },
  { value: 'suspended', desc: '留職停薪' },
];

export const leaveApprovalOptions = [
  { value: '', desc: '請選擇審核狀態'},
  { value: 'pending', desc: '簽核中' },
  { value: 'approved', desc: '已審核通過' },
  { value: 'rejected', desc: '審核不通過'},
  { value: 'cancelled', desc: '已取消'},
];

//健保 眷屬 關系
export const healthInsuranceRelationWithUserOptions = [
  { value: 'grandmother', desc: '祖母' },
  { value: 'grandfather', desc: '祖父' },
  { value: 'mother', desc: '母親' },
  { value: 'father', desc: '父親' },
  { value: 'spouse', desc: '配偶' },
  { value: 'daughter', desc: '女' },
  { value: 'son', desc: '子' },
];

//勞、健保
export const laborHealthInsuranceTypeOptions = [
  { value: 1, desc: '勞工' },
  { value: 2, desc: '雇主' },
];

//學歷
export const educationLevelOptions = [
  { value: 'highSchool', desc: '高中' },
  { value: 'bachelor', desc: '大學' },
  { value: 'master', desc: '碩士' },
  { value: 'doctorate', desc: '博士' },
];

//學歷
export const salaryExtraPaymentOptions = [
  { value: "add", desc: '加項' },
  { value: "subtract", desc: '減項' },
];