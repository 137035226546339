import React from 'react';
import styled from 'styled-components';

const DivWrapper = styled.div`
  width: 100%;
  height: calc(100% - 80px);
  padding: 0;
  box-sizing: border-box;

  @media (min-width: 768px) {
    padding: 0 30px;
  }
`;

const Footer = styled.div`
  width: 100%;
  height: 90px;
  line-height: 90px;
  font-size: 12px;
  font-weight: 300;
  color: #8b8b8b;

  @media (max-width: 768px) {
    text-align: center;
  }
`;

const MainContentWrapper = props => {

  return (
    <DivWrapper>
      { props.children }
      <Footer>
        &copy; 2020 Veda International Corp.
      </Footer>
    </DivWrapper>
  );
};

export default MainContentWrapper;
