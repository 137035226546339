import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { putNewMedia } from '@/api/mediaService';

import { FETCH_MEDIA_TYPES } from '@/store/actions/srpOptionsAction';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import MainContent from '@/component-style/MainContent';
import PanelMediaDetail from '@/components/Panel/PanelMediaDetail';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import { ButtonBasic, ButtonCancel } from '@/component-style/Button';
import { DivFlexRow } from '@/component-style/RWDWrapper';

import { mediaConfig } from '@/constant/dataConfig/informationModule';

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding-right: 0;
  padding-left: 135px;
  padding-bottom: 30px;
  justify-content: space-between;

  @media (max-width: 425px) {
    justify-content: center;
    padding-left: 0;
  }
`;

const ButtonSave = styled(ButtonBasic)`
  width: 120px;
  margin-right: 10px;
  margin-bottom: 10px;
`;

const ButtonClear = styled(ButtonCancel)`
  width: 120px;
  margin-bottom: 10px;
`;

const MediaCreation = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const mediaTypes = useSelector(state => state.srpOptions.mediaTypeOptions);
  const showSaveFailPopup = useSelector(state => state.apiFail.showSaveFailPopup);
  const apiFailPopupTitle = useSelector(state => state.apiFail.title);
  const apiFailPopupMessage = useSelector(state => state.apiFail.message);

  const [media, setMedia] = useState({
    ...mediaConfig,
    contactAddress: { ...mediaConfig.contactAddress },
  });

  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [showSaveSuccessPopup, setShowSaveSuccessPopup] = useState(false);

  const updateMediaHandler = (key, value) => {
    const updateObj = { ...media };
    updateObj[key] = value;
    setMedia({ ...updateObj });
  };

  const clearData = () => {
    setMedia({
      ...mediaConfig,
      contactAddress: { ...mediaConfig.contactAddress },
    })
  };

  const closeSuccessPopupHandler = () => {
    clearData();
    setShowSaveSuccessPopup(false);
  };

  const saveNewMediaHandler = async () => {
    setShowLoadingPopup(true);

    try {
      await putNewMedia(media);
      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '儲存失敗', err }));
      console.log(err);
    }
  };

  const initMediaTypes = useCallback(() => {
    dispatch(FETCH_MEDIA_TYPES({ enable: true }));
  }, [dispatch]);

  useEffect(() => {
    initMediaTypes();
  }, [initMediaTypes]);

  return (
    <MainContent>
      <PanelMediaDetail
        title = "新增媒體資料"
        media = { media }
        mediaTypes = { mediaTypes }
        updateMediaHandler = { updateMediaHandler }
      >
      {
        <DivFlexRow>
          <DivButtonsWrapper>
            <div>
              <ButtonSave
                onClick = { saveNewMediaHandler }
              >儲存</ButtonSave>
              <ButtonClear
                onClick = { clearData }
              >清除欄位</ButtonClear>
            </div>
            <ButtonClear
              onClick = { () => { history.goBack() } }
            >離開</ButtonClear>
          </DivButtonsWrapper>
        </DivFlexRow>
      }
      </PanelMediaDetail>
      { showLoadingPopup && <PopupLoading/> }
      {
        showSaveSuccessPopup &&
          <PopupExecuteResult
            title = "儲存成功"
            confirmHandler = { closeSuccessPopupHandler }
          />
      }
      {
        showSaveFailPopup &&
          <PopupExecuteResult
            title = { apiFailPopupTitle }
            text = { apiFailPopupMessage }
            confirmHandler = { () => {
              dispatch(CLOSE_SAVE_FAIL_POPUP());
            }}
          />
      }
    </MainContent>
  )
};

export default MediaCreation;
