import React from 'react';
import styled from 'styled-components';

import { TdItem, TableRow } from '@/component-style/Table';
import moment from 'moment';
import { leaveApprovalOptions } from '@/constant/options';
import { ButtonBasic } from '@/component-style/Button';

import {
  getOptionDesc,
} from '@/utils/textUtils';

const ButtonDelete = styled(ButtonBasic)`
  width: 60px;
`;

const TrWrapper = styled(TableRow)`
  border-bottom: solid 2px #f2f2f2;
  box-sizing: border-box;
  min-width: 1080px;

  &:last-child {
    border-bottom: none;
  }
`;


const Td100 = styled(TdItem)`
  width: 100px;
`;

const Td120 = styled(TdItem)`
  width: 120px;
`;

const Td160 = styled(TdItem)`
  width: 160px;
`;

const Item = props => {
  const { leave } = props;

  return (
    <TrWrapper>
      <Td100>{ leave.username }</Td100>
      <Td120>{ leave.leaveName }</Td120>
      <Td120>{ moment.parseZone(leave.applyTime).format('YYYY/MM/DD') }</Td120>
      <Td120>{ leave.durationInSeconds == null ? "0" : Math.round(leave.durationInSeconds / 3600 * 100) / 100 }</Td120>
      <Td120>{ moment.parseZone(leave.leaveFrom).format('YYYY/MM/DD HH:mm:ss') }</Td120>
      <Td120>{ moment.parseZone(leave.leaveTo).format('YYYY/MM/DD HH:mm:ss') }</Td120>
      <Td120>{ getOptionDesc(leaveApprovalOptions, leave.status) }</Td120>
      <Td160>
        <ButtonDelete
          onClick = { () => { 
            props.deleteLeaveApplicationHandler(leave.id);
          } }
        >刪除</ButtonDelete>
      </Td160>
    </TrWrapper>
  )
};

export default Item;
