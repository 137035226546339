import {
  countyOptions,
  customerTypeOptions,
  customerSourceOptions,
  identityTypeOptions,
  vendorQualifications,
  vendorInvoiceTypes,
  vendorPayments,
} from '@/constant/options';

import { getDistOptions } from '@/constant/zipCodes';
import { devAPIModuleDict, APIErrorMsgDict } from '@/constant/errorMsgDict';

function getDevResErrorMsg({ data, config }) {
  const { url } = config;
  const { code } = data;
  const port = url.match(/:\d{4}\/(api){1}/gm)
    .pop().substring(1, 5);
  const module = devAPIModuleDict[port];
  const errorCodeDict = APIErrorMsgDict[module];

  return errorCodeDict && errorCodeDict[code] !== undefined
    ? errorCodeDict[code]
    : data.message;
};

function getProdResErrorMsg({ data, config }) {
  const { url } = config;
  const { code } = data;
  const module = url.match(/\/[a-zA-z]+\//gm)
    .pop().replaceAll('/', '');
  const errorCodeDict = APIErrorMsgDict[module];

  return errorCodeDict && errorCodeDict[code] !== undefined
    ? errorCodeDict[code]
    : data.message;
};

export const errorMsgConverter = err => {
  if (err.response) {
    return process.env.NODE_ENV === 'development'
      ? getDevResErrorMsg(err.response)
      : getProdResErrorMsg(err.response)
  }
  if (err.request) {
    return '伺服器沒有回應，請再試一次';
  }
  return '系統異常，請再試一次';
};

export const getCityString = cityName => {
  const selectCity = countyOptions
    .filter(city => city.value === cityName)
    .pop();

  return selectCity === undefined
    ? ''
    : selectCity.desc;
};

export const getTownString = (city, townName) => {
  const selectTown = getDistOptions(city)
    .filter(town => town.value === townName)
    .pop();

  return selectTown === undefined
    ? ''
    : selectTown.desc;
};

export const getAddressString = addressConfig => {
  const { country, city, town, address, postal } = {
    country: 'taiwan',
    city: '',
    town: '',
    postal: '',
    address: '',
    ...addressConfig,
  };

  return country === 'taiwan'
    ? `${postal}${getCityString(city)}${getTownString(city, town)}${address}`
    : address;
};

export const getCustomerTypeString = customerType => {
  const selectType = customerTypeOptions
    .filter(type => type.value === customerType)
    .pop();

  return selectType === undefined
    ? ''
    : selectType.desc;
};

export const getCustomerSourceString = customerSource => {
  const selectSource = customerSourceOptions
    .filter(source => source.value === customerSource)
    .pop();

  return selectSource === undefined
    ? ''
    : selectSource.desc;
};

export const getPhoneString = phone => {

  const areaCode = phone && phone.areaCode
    ? `${phone.areaCode} - ` : '';
  const number = phone && phone.number
    ? phone.number : '';
  const extension = phone.extension
    ? ` #${phone.extension}` : '';
  
  return `${areaCode}${number}${extension}`
};

export const integerConverter = input => input === ''
  ? 0 : parseInt(input);

export const floatConverter = input => input === ''
  ? 0 : parseFloat(input);

export const getVendorIdentityString = identity => {
  const selectIdentity = identityTypeOptions
    .filter(option => option.value === identity)
    .pop();

  return selectIdentity === undefined
    ? ''
    : selectIdentity.desc;
};

export const getVendorQualificationString = qualification => {
  const selectQualification = vendorQualifications
    .filter(option => option.value === qualification)
    .pop();

  return selectQualification === undefined
    ? ''
    : selectQualification.desc;
  
};

export const getVendorInvoiceTypeString = invoice => {
  const selectInvoice = vendorInvoiceTypes
    .filter(option => option.value === invoice)
    .pop();

  return selectInvoice === undefined
    ? ''
    : selectInvoice.desc;
};

export const getVendorPaymentString = payments => {
  const paymentNames = payments.map( payment => {
    const selectInvoice = vendorPayments
      .filter(option => option.value === payment)
      .pop();

    return selectInvoice === undefined
      ? ''
      : selectInvoice.desc;
  })

  return paymentNames.join(', ');
};

export const getOptionDesc = (options, value) => {
  const selectedOption = options && options
    .filter(option => option.value === value).pop();

  return selectedOption ? selectedOption.desc : '';
};

export const getOptionDescAndRemoveBrackets = (options, value) => {
  const selectedOption = options && options
    .filter(option => option.value === value).pop();

  const regex = /\(.*\)/i;


  return selectedOption ? selectedOption.desc.replace(regex, '') : '';
};

export const getCompanyInvoiceNumber = (options, companyType) => {
  // console.log("textUtils.js getCompanyInvoiceNumber options:" + JSON.stringify(options));
  // console.log("textUtils.js getCompanyInvoiceNumber companyId:" + companyType);
  const vedaOption = options && options.filter(option => option.value === companyType && option.desc === "頤德國際").pop();
  return vedaOption ? '84305863' : '';
};

export const getOvertimeApprovementStatus = status => {
  const statusDict = {
    draft: '暫存',
    unapproved: '審核中',
    approved: '已審核',
    disagree: '不同意',
    paid: '已撥付',
  };

  return statusDict[status];
};

export const currencyConverter = number => number.toString()
  .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const vendorOptionTaxIDConverter = taxID => taxID
  ? `${taxID.substring(0, 5)}****`
  : '';


export const getOvertimeDateTypeString = type => {
  const overtimeDateTypeDict = {
    workdays: '工作日',
    restdays: '休息日',
    holidays: '例假日',
    nationalHolidays: '國定假日',
  };

  return overtimeDateTypeDict[type];
};

export const decimalCommaConverter = (number, prependDolldarSign = true) =>  {
  var result = "";
  if (number == null) {
    result = "$ 0";
  } else {
    result = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    if (prependDolldarSign === true) {
      result = "$ " + result;
    }
  }
  return result;
}