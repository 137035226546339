import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { postToGetUserInfo } from '@/api/authService';
import { postToGetAttendances } from '@/api/attendanceService';

import {
  FETCH_MEMBER_OPTIONS,
  FETCH_COMPANY_TYPE_OPTIONS,
} from '@/store/actions/srpOptionsAction';

import MainContent from '@/component-style/MainContent';
import TableWrapper from '@/components/UI/TableWrapper';
import DateSelector from '@/components/UI/DateSelector';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import SelectNormal from '@/components/UI/Select/SelectNormal';
import SelectMultiple from '@/components/UI/Select/SelectMultiple';
import SearchButtonSet from '@/components/UI/SearchButtonSet';
import TablePunchTimes from '@/components/Table/Attendance/TablePunchTimes';
import { ButtonBasic } from '@/component-style/Button';
import { PSubtitle } from '@/component-style/TextElement';
import {
  DivFlexRow,
  DivItemWrapper,
  DivFullWidthWrapper,
  DivLongMultiSelector,
} from '@/component-style/RWDWrapper';

import { exportPunchTimeFile } from '@/utils/xlsx.js';
import { searchConfigGenerator } from '@/utils/apiRequestUtils';
import { formatTime } from '@/utils/timeUtils';

const DivTableContainer = styled.div`
  margin-bottom: 40px;
`;

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding: 0;
  padding-bottom: 30px;
  justify-content: space-between;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const ButtonExport = styled(ButtonBasic)`
  width: 120px;

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`;

const searchConfig = {
  startDate: null,
  endDate: null,
  reportedInTime: '',
  companyType: '',
  userIDs: [],
  amount: 10,
};

const PunchTimeManagement = () => {
  const dispatch = useDispatch();
  const memberOptions = useSelector(state => state.srpOptions.memberOptions);
  const attendanceRoutes = useSelector(state => state.login.attendanceRoutes);
  const companyTypeOptions = useSelector(state => state.srpOptions.companyTypeOptions);

  const [punchTimes, setPunchTimes] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [config, setConfig] = useState({ ...searchConfig });

  const [showLoadingPopup, setShowLoadingPopup] = useState(false);

  const userExportable = attendanceRoutes.indexOf('PunchTimeManagement-Export') !== -1;

  const searchHandler = async () => {
    setCurrentPageHandler(1);
  };

  const setCurrentPageHandler = page => {
    setCurrentPage(page);
    updatePunchRecords(page);
  };

  const exportHandler = async () => {
    setShowLoadingPopup(true);
    const punchTimeDatas = [];

    try {
      const { data } = await postToGetAttendances(
        searchConfigGenerator({
          ...config,
          page: 1,
          amount: totalCount || 1,
        })
      );

      const punchTimes = data.punchTimes
        .map(punchTime => ({ ...punchTime }))
        .sort((a, b) => new Date(a.date) - new Date(b.date));

      for (let i = 0; i < punchTimes.length; i += 1) {
        const { userID } = punchTimes[i];
        const userInfo = await postToGetUserInfo(userID);
        const { name } = userInfo.data;
        punchTimeDatas.push([
          formatTime(punchTimes[i].date, 'YYYY/MM/DD'),
          name,
          formatTime(punchTimes[i].startTime, 'YYYY/MM/DD HH:mm:ss'),
          formatTime(punchTimes[i].endTime, 'YYYY/MM/DD HH:mm:ss') || '',
          punchTimes[i].reportedInTime === true ? '使用者打卡' : '補打卡',
        ]);
      }

    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
    exportPunchTimeFile(punchTimeDatas);
  };

  const updatePunchRecords = async (page = currentPage) => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetAttendances(
        searchConfigGenerator({ ...config, page,})
      );

      const { punchTimes, totalCount } = data;
      const records = punchTimes
        .map(punchTime => ({ ...punchTime }))
        .sort((a, b) => new Date(a.date) - new Date(b.date));
      
      const punchRecords = [];

      for(let i = 0; i < records.length; i += 1) {
        const { userID } = records[i];
        const userInfo = await postToGetUserInfo(userID);
        const { name } = userInfo.data;
        punchRecords.push({ username: name, ...records[i] });
      }
      
      setPunchTimes([ ...punchRecords ]);
      setTotalCount(totalCount);
    } catch(err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const initOptions = useCallback(() => {
    dispatch(FETCH_MEMBER_OPTIONS());
    dispatch(FETCH_COMPANY_TYPE_OPTIONS());
  }, [dispatch]);

  useEffect(() => {
    initOptions();
  }, [initOptions]);

  return (
    <MainContent>
      <DivTableContainer>
        <TableWrapper title = "打卡管理" >
          <DivFlexRow>
            <DivItemWrapper>
              <PSubtitle>
                起始日期
                <span>*</span>
              </PSubtitle>
              <DateSelector
                value = { config.startDate }
                changeHandler = { value => {
                  setConfig(prevState => ({
                    ...prevState,
                    startDate: formatTime(value, 'YYYY-MM-DD'),
                  }))
                }}
              />
            </DivItemWrapper>
            <DivItemWrapper>
              <PSubtitle>
                結束時間
                <span>*</span>
              </PSubtitle>
              <DateSelector
                value = { config.endDate }
                changeHandler = { value => {
                  setConfig(prevState => ({
                    ...prevState,
                    endDate: formatTime(value, 'YYYY-MM-DD'),
                  }));
                }}
              />
            </DivItemWrapper>
          </DivFlexRow>
          <DivFlexRow>
            <DivItemWrapper>
              <PSubtitle>打卡類型</PSubtitle>
              <SelectNormal
                value = { config.reportedInTime }
                options = {[
                  { value: '', desc: '請選擇打卡類型' },
                  { value: true, desc: '使用者打卡' },
                  { value: false, desc: '補打卡' },
                ]}
                changeHandler = { value => {
                  setConfig(prevState => ({
                    ...prevState,
                    reportedInTime: value,
                  }));
                }}
              />
            </DivItemWrapper>
            <DivItemWrapper>
              <PSubtitle>所屬公司</PSubtitle>
              <SelectNormal
                value = { config.companyType }
                options = {[
                  { value: '', desc: '請選擇所屬公司' },
                  ...companyTypeOptions,
                ]}
                changeHandler = { value => {
                  setConfig(prevState => ({
                    ...prevState,
                    companyType: value,
                  }));
                }}
              />
            </DivItemWrapper>
          </DivFlexRow>
          <DivFlexRow>
            <DivFullWidthWrapper>
              <PSubtitle>使用者</PSubtitle>
              <DivLongMultiSelector>
                <SelectMultiple
                  options = { memberOptions }
                  changeHandler = { value => {
                    setConfig(prevState => ({
                      ...prevState,
                      userIDs: [...value],
                    }));
                  }}
                />
              </DivLongMultiSelector>
            </DivFullWidthWrapper>
          </DivFlexRow>
          <DivFlexRow>
            <DivButtonsWrapper>
              <ButtonExport
                disabled = { !userExportable }
                onClick = { exportHandler }
              >匯出</ButtonExport>
              <SearchButtonSet
                searchable = { config.startDate !== null && config.endDate !== null }
                clearHandler = { () => {
                  setConfig({
                    startDate: null,
                    endDate: null,
                    reportedInTime: '',
                    companyType: '',
                    userIDs: [],
                    amount: 10,
                  })
                }}
                searchHandler = { searchHandler }
              />
            </DivButtonsWrapper>
          </DivFlexRow>
        </TableWrapper>
      </DivTableContainer>
      <TablePunchTimes
        punchTimes = { punchTimes }
        totalCount = { totalCount }
        currentPage = { currentPage }
        pageChangedHandler = { value => {
          setCurrentPageHandler(value);
        }}
      />
      { showLoadingPopup && <PopupLoading/> }
    </MainContent>
  )
};

export default PunchTimeManagement;
