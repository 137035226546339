import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import { ButtonBasic, ButtonCancel } from '../../component-style/Button';

const ButtonSearch = styled(ButtonBasic)`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  width: 120px;
  margin-right: 10px;
`;

const ButtonClear = styled(ButtonCancel)`
  font-size: 14px;
  width: 120px;
`

const DivWrapper = styled.div`
  display: flex;
`;

const DivIconWrapper = styled.div`
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SearchButtonSet = props => {

  return (
    <DivWrapper>
      <ButtonSearch
        disabled = { props.searchable === false }
        onClick = { props.searchHandler }
      >
        <DivIconWrapper>
          <FontAwesomeIcon
            icon = { faSearch }
          />
        </DivIconWrapper>
        搜尋
      </ButtonSearch>
      <ButtonClear
        onClick = { props.clearHandler }
      >
        清除欄位
      </ButtonClear>
    </DivWrapper>
  );
};

export default SearchButtonSet;
