import React from 'react';
import { useSelector } from 'react-redux';

import TableWrapper  from '@/components/UI/TableWrapper';
import PageSelector from '@/components/UI/PageSelector';
import { Table, TableBody } from '@/component-style/Table';
import Header from './Header';
import Item from './Item';

const TableExpenseFormList = props => {
  const { showCheckbox, expenses } = props;
  const expenseRoutes = useSelector(state => state.login.expenseRoutes);
  const disableDetailPage = expenseRoutes.indexOf('ExpenseFormDetail') === -1;

  return (
    <TableWrapper title = "支出憑單列表">
      <Table>
        <Header
          showCheckbox = { showCheckbox }
        />
        <TableBody>
          {
            expenses.map(expense =>
              <Item
                key = { expense.expenseID }
                expense = { expense }
                disableDetailPage = { disableDetailPage }
                showCheckbox = { showCheckbox }
                goDetailHandler = { () => {
                  props.goDetailHandler(expense.expenseID)
                }}
              />
            )
          }
        </TableBody>
      </Table>
      <PageSelector
        current = { props.currentPage }
        total = { props.totalCount }
        pageChangedHandler = { props.pageChangedHandler }
      />
    </TableWrapper>
  );
};

export default TableExpenseFormList;
