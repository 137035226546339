import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Checkbox from '@/components/UI/Checkbox';
import { TdItem, TableRow } from '@/component-style/Table';
import { ButtonGoDetail } from '@/component-style/Button';

import { getOptionDesc } from '@/utils/textUtils';
import { expenseApprovalStates } from '@/constant/options';

import { decimalCommaConverter } from '@/utils/textUtils';

const TrWrapper = styled(TableRow)`
  border-bottom: solid 2px #f2f2f2;
  box-sizing: border-box;
  min-width: 1320px;

  &:last-child {
    border-bottom: none;
  }
`;

const Td80 = styled(TdItem)`
  width: 80px;
`;

const Td120 = styled(TdItem)`
  width: 120px;
`;

const Td160 = styled(TdItem)`
  width: 160px;
`;

const Td240 = styled(TdItem)`
  width: 240px;
`;

const Item = props => {
  const { expense } = props;
  const { user, objectID, approvalStatus } = expense;
  const vendorIDs = useSelector(state => state.srpOptions.vendorIDOptions);
  const memberOptions = useSelector(state => state.srpOptions.memberOptions);

  const username = getOptionDesc(memberOptions, user);

  const objectName = expense.object === 'user'
    ? getOptionDesc(memberOptions, objectID)
    : getOptionDesc(vendorIDs, objectID);

  const status = getOptionDesc(expenseApprovalStates, approvalStatus);

  return (
    <TrWrapper>
      <Td80>
        <Checkbox
          checked = { expense.isChecked }
          changeHandler = { props.checkHandler }
        />
      </Td80>
      <Td120>{ expense.expenseCode }</Td120>
      <Td160>{ expense.projectCode }</Td160>
      <Td160>{ expense.projectName }</Td160>
      <Td120>{ username }</Td120>
      <Td120>{ expense.applyDate }</Td120>
      <Td240>{ objectName }</Td240>
      <Td120>{ expense.price == null ? '' : decimalCommaConverter(expense.price) }</Td120>
      <Td120>{ status }</Td120>
      <Td80>
        <ButtonGoDetail
          disabled = { props.disableDetailPage }
          onClick = { props.goDetailHandler }
        >...
        </ButtonGoDetail>
      </Td80>
    </TrWrapper>
  );
};

export default Item;
