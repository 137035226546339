import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { AZURE_SYNC_SUCCESS } from '@/store/actions/loginAction';

const AzureSyncSuccess = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(AZURE_SYNC_SUCCESS());
    history.push('/AccountSetting');
  }, [dispatch, history]);

  return null;
};

export default AzureSyncSuccess;
