import React, { useCallback, useEffect, useState, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import styled from 'styled-components';

import { postToGetProjectDetail } from '@/api/projectService';

import {
  putModifyExpenseDetail,
  postToGetExpenseDetail,
} from '@/api/expenseService';

import {
  getDownloadFile,
  postToGetUploadFiles,
  postNewUploadFile,
} from '@/api/storageService';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import {
  FETCH_MEMBER_OPTIONS,
  FETCH_VENDOR_ID_OPTIONS,
  FETCH_PROJECT_ID_OPTIONS,
  FETCH_EXPENSE_TYPE_OPTIONS,
} from '@/store/actions/srpOptionsAction';

import MainContent from '@/component-style/MainContent';
import PanelExpenseFormDetail from '@/components/Panel/Expense/PanelExpenseFormDetail';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PopupDraggable from '@/components/UI/Popup/PopupDraggable';
import PopupDraggablePdf from '@/components/UI/Popup/PopupDraggablePdf';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import PopupAttachmentList from '@/components/UI/Popup/PopupAttachmentList';
import TableApprovalRecords from '@/components/Table/Expense/TableApprovalRecords';
import { ButtonBasic, ButtonCancel } from '@/component-style/Button';
import { DivFlexRow } from '@/component-style/RWDWrapper';

import { expenseFormConfig } from '@/constant/dataConfig/expenseModule';
import { toUTCTime } from '@/utils/timeUtils';
import { integerConverter } from '@/utils/textUtils';
import {
  getNextApprovalState,
  getNextApprovalRecord,
} from '@/utils/expenseUtils';

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding-right: 0;
  padding-bottom: 20px;
  justify-content: flex-end;

  @media (max-width: 425px) {
    justify-content: center;
    padding-left: 0;
  }
`;

const ButtonSave = styled(ButtonBasic)`
  margin-left: 10px;
  margin-top: 10px;
  width: 120px;

  @media (max-width: 425px) {
    &:nth-child(2n+1) { margin-left: 0 }
  }
`;

const ButtonClear = styled(ButtonCancel)`
  margin-left: 10px;
  margin-top: 10px;
  width: 120px;
`;

const DivTableWrapper = styled.div`
  padding-bottom: 30px;
`;

const ExpenseApprovalDetail = () => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const userID = useSelector(state => state.login.token);
  const vendorIDs = useSelector(state => state.srpOptions.vendorIDOptions);
  const expenseRoutes = useSelector(state => state.login.expenseRoutes);
  const memberOptions = useSelector(state => state.srpOptions.memberOptions);
  const projectIDOptions = useSelector(state => state.srpOptions.projectIDOptions);
  const showSaveFailPopup = useSelector(state => state.apiFail.showSaveFailPopup);
  const apiFailPopupTitle = useSelector(state => state.apiFail.title);
  const apiFailPopupMessage = useSelector(state => state.apiFail.message);

  const [fileList, setFileList] = useState([]);
  const [uploadFiles, setUploadFiles] = useState([]);
  const [fileUploadConfig, setFileUploadConfig] = useState({ type: '', index: 0 });
  
  const [expenseForm, setExpenseForm] = useState(cloneDeep(expenseFormConfig));

  const [suggestion, setSuggestion] = useState('');
  const [fileUrl, setFileUrl] = useState('');
  const [file, setFile] = useState(null);
  const [alertText, setAlertText] = useState('');
  const [showAlertPopup, setShowAlertPopup] = useState(false);
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [showVouchersPopup, setShowVouchersPopup] = useState(false);
  const [showSaveSuccessPopup, setShowSaveSuccessPopup] = useState(false);
  const [showAttachmentsPopup, setShowAttachmentsPopup] = useState(false);
  const [showDraggablePDFPopup, setShowDraggablePDFPopup] = useState(false);
  const [showDraggableImagePopup, setShowDraggableImagePopup] = useState(false);
  const [showUploadSuccessPopup, setShowUploadSuccessPopup] = useState(false);

  const [mapQuotationIDHasFile, setMapQuotationIDHasFile] = useState(new Map());

  const userApprovable = expenseRoutes.indexOf('ExpenseApproval-Approve') !== -1;

  const amountTotal = expenseForm.vouchers
    .map(({ amount }) => integerConverter(amount))
    .reduce((acc, cur) => acc + cur, 0);

  const unTaxedAmountTotal = expenseForm.vouchers
    .map(({ untaxedAmount }) => integerConverter(untaxedAmount))
    .reduce((acc, cur) => acc + cur, 0);

  const taxTotal = expenseForm.vouchers
    .map(({ tax }) => integerConverter(tax))
    .reduce((acc, cur) => acc + cur, 0);
  
  const objectList = () => {
    if (expenseForm.object === 'user') return memberOptions;
    if (expenseForm.object === 'vendor') return vendorIDs;

    return [];
  };

  const updateExpenseFormHandler = (key, value) => {
    const updateObj = { ...expenseForm };
    updateObj[key] = value;
    setExpenseForm({ ...updateObj });
  };

  const openAlertPopup = text => {
    setAlertText(text);
    setShowAlertPopup(true);
  };

  const openFilesPopupHandler = (type, index) => {
    setFileUploadConfig({ type, index });
    const { quotationID } = expenseForm.vouchers[index];

    openUploadFilesPopupHandler(type, quotationID);
  };

  const openUploadPopup = (isOpen, type = fileUploadConfig.type) => {
    type === 'quotationVoucher'
      ? setShowVouchersPopup(isOpen)
      : setShowAttachmentsPopup(isOpen);
  };

  const displayFileHandler = async fileInfo => {
    const { name, fileID, downloadURL } = fileInfo;

    if (name.match(/\.pdf$/)) {
      openUploadPopup(false);
      setShowLoadingPopup(true);
      const { data } = await getDownloadFile(fileID);
      
      setFile(data);
      setShowLoadingPopup(false);
      setShowDraggablePDFPopup(true);
    } else {
      setFileUrl(downloadURL);
      openUploadPopup(false);
      setShowDraggableImagePopup(true);
    }
  };

  const openUploadFilesPopupHandler = async (type, quotationID) => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetUploadFiles(type, quotationID);
      setFileList([ ...data ]);

    } catch(err) { console.log(err) }

    setShowLoadingPopup(false);
    openUploadPopup(true, type);
  };

  const disagreeHandler = async () => {
    if (suggestion === '') {
      return openAlertPopup('請填寫簽核意見才能退回申請');
    }

    setShowLoadingPopup(true);
    const oldRecords = cloneDeep(expenseForm.records);
    const lastRecord = oldRecords.pop();

    const records = [
      ...oldRecords,
      {
        time: toUTCTime(Date.now()),
        approval: userID,
        status: lastRecord.status.replace('審核中', '不同意'),
        suggestion,
      },
      {
        approval: expenseForm.user,
        status: '填寫中',
      }
    ];

    try {
      await putModifyExpenseDetail({
        ...expenseForm,
        approvalStatus: 'draft',
        records,
      });
      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '審核失敗', err }));
      console.log(err);
    }
  };

  const uploadSuccessConfirmHandler = () => {
    setUploadFiles([]);
    setShowUploadSuccessPopup(false);
    openUploadPopup(true);
  };

  const fileUploadHandler = async () => {
    const { type, index } = fileUploadConfig;
    const { quotationID } = expenseForm.vouchers[index];
    setShowLoadingPopup(true);

    type === 'quotationFiles'
      ? setShowAttachmentsPopup(false)
      : setShowVouchersPopup(false);

    try {
      for (let i = 0; i < uploadFiles.length; i += 1) {
        const file = uploadFiles[i];
        const formData = new FormData();
        formData.append('upfile', file);
        formData.append('objectType', type);
        formData.append('objectID', quotationID);
        formData.append('name', file.name);
        await postNewUploadFile(formData);
      }

      const { data } = await postToGetUploadFiles(type, quotationID);
      const vouchers = [...expenseForm.vouchers];
      
      if (type === 'quotationFiles') {
        vouchers[index].isFileUpload = data.length > 0;
      } else {
        vouchers[index].isVoucherUpload = data.length > 0;
      }

      setExpenseForm(prevState => ({ ...prevState, vouchers }));
      setFileList([ ...data ]);
      setShowLoadingPopup(false);
      setShowUploadSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '上傳失敗', err }));
      console.log(err);
    };
  };

  const agreeHandler = async () => {
    setShowLoadingPopup(true);

    try {
      const oldRecords = cloneDeep(expenseForm.records);
      const lastRecord = oldRecords.pop();
      const { data } = await postToGetProjectDetail(expenseForm.projectID);
      const { director } = data;
      const nextApprover = expenseForm.approvalStatus === 'projectManagerVerify'
        ? director : '';

      const records = [
        ...oldRecords,
        {
          time: toUTCTime(Date.now()),
          approval: userID,
          status: lastRecord.status.replace('審核中', '已同意'),
          suggestion,
        },
      ];

      await putModifyExpenseDetail({
        ...expenseForm,
        approvalStatus: getNextApprovalState(expenseForm.approvalStatus),
        records: expenseForm.approvalStatus === 'generalManagerVerifying'
          ? [...records]
          : [...records, getNextApprovalRecord(expenseForm.approvalStatus)(nextApprover)],
      });

      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '審核失敗', err }));
      console.log(err);
    }
  };

  const fetchExpenseFormDetail = async () => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetExpenseDetail(id);
      const vouchers = [];

      for (let i = 0; i < data.vouchers.length; i += 1) {
        const {
          quotationID, tax, amount, untaxedAmount, type, tag
        } = data.vouchers[i];
        const quotationFiles = await postToGetUploadFiles('quotationFiles', quotationID);
        const quotationVoucher = await postToGetUploadFiles('quotationVoucher', quotationID);
        const attachmentFiles = quotationFiles.data;
        const voucherFiles = quotationVoucher.data;

        vouchers.push({
          ...data.vouchers[i],
          tag: tag || '',
          type: type || '',
          tax: tax || 0,
          amount: amount || 0,
          untaxedAmount: untaxedAmount || 0,
          // isFileUpload: attachmentFiles.length > 0,
          // isVoucherUpload: voucherFiles.length > 0,
        })

        setMapQuotationIDHasFile(prev => new Map([...prev, [quotationID, {isFileUpload: attachmentFiles.length > 0,  isVoucherUpload: voucherFiles.length > 0}]]));
      }

      setExpenseForm({
        ...expenseFormConfig,
        ...data,
        vouchers,
      });

    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const initExpenseForm = useCallback(
    fetchExpenseFormDetail, []);

  const initOptions = useCallback(() => {
    dispatch(FETCH_MEMBER_OPTIONS());
    dispatch(FETCH_VENDOR_ID_OPTIONS());
    dispatch(FETCH_PROJECT_ID_OPTIONS());
    dispatch(FETCH_EXPENSE_TYPE_OPTIONS());
  }, [dispatch]);

  useEffect(() => {
    initOptions()
    initExpenseForm();
  }, [initExpenseForm, initOptions]);

  return (
    <MainContent>
      <PanelExpenseFormDetail
        title = "支出憑單明細"
        disabled = { true }
        showSuggestion = { true }
        suggestion = { suggestion }
        expenseForm = { expenseForm }
        objectIDOptions = { objectList() }
        projectIDOptions = { projectIDOptions }
        taxTotal = { taxTotal }
        amountTotal = { amountTotal }
        unTaxedAmountTotal = { unTaxedAmountTotal }
        hideAttachmentButton = { expenseForm.object === 'user' }
        fileManageHandler = { () => { setShowVouchersPopup(true) }}
        openFilesPopupHandler = { openFilesPopupHandler }
        updateSuggestionHandler = { setSuggestion }
        updateExpenseFormHandler = { updateExpenseFormHandler }
        mapQuotationIDHasFile = { mapQuotationIDHasFile }
      >
        <DivFlexRow>
          <DivButtonsWrapper>
            <ButtonSave
              disabled = { !userApprovable }
              onClick = { agreeHandler }
            >同意</ButtonSave>
            <ButtonSave
              disabled = { !userApprovable }
              onClick = { disagreeHandler }
            >不同意</ButtonSave>
            <ButtonClear
              onClick = { () => { history.goBack() }}
            >離開</ButtonClear>
          </DivButtonsWrapper>
        </DivFlexRow>
        <DivTableWrapper>
          <TableApprovalRecords
            records = { expenseForm.records }
          />
        </DivTableWrapper>
      </PanelExpenseFormDetail>
      { showLoadingPopup && <PopupLoading/> }
      {
        showAttachmentsPopup &&
        <PopupAttachmentList
          multiple = { true }
          displayable = { true }
          fileChangable = { true }
          fileList = { fileList }
          uploadFiles = { uploadFiles }
          displayFileHandler = { displayFileHandler }
          cancelHandler = { () => { setShowAttachmentsPopup(false) }}
          updateUploadFiles = { files => { setUploadFiles([ ...files ]) }}
          fileUploadHandler = { fileUploadHandler }
        />
      }
      {
        showVouchersPopup &&
        <PopupAttachmentList
          title = "憑證管理"
          displayable = { true }
          fileList = { fileList }
          uploadFiles = { uploadFiles }
          displayFileHandler = { displayFileHandler }
          cancelHandler = { () => { setShowVouchersPopup(false) }}
          updateUploadFiles = { files => { setUploadFiles([ ...files ]) }}
          fileChangable = { true }
          fileUploadHandler = { fileUploadHandler }
        />
      }
      {
        showUploadSuccessPopup &&
          <PopupExecuteResult
            title = "上傳成功"
            confirmHandler = { uploadSuccessConfirmHandler }
          />
      }
      {
        showDraggableImagePopup &&
          <PopupDraggable
            url = { fileUrl }
            cancelHandler = { () => {
              setShowDraggableImagePopup(false);
              openUploadPopup(true);
            }}
          />
      }
      {
        showDraggablePDFPopup &&
          <PopupDraggablePdf
            file = { file }
            cancelHandler = { () => {
              setShowDraggablePDFPopup(false);
              openUploadPopup(true);
            }}
          />
      }
      {
        showSaveSuccessPopup &&
          <PopupExecuteResult
            title = "審核成功"
            confirmHandler = { () => { history.goBack() }}
          />
      }
      {
        showAlertPopup &&
          <PopupExecuteResult
            title = "審核失敗"
            text = { alertText }
            confirmHandler = { () => { setShowAlertPopup(false) } }
          />
      }
      {
        showSaveFailPopup &&
          <PopupExecuteResult
            title = { apiFailPopupTitle }
            text = { apiFailPopupMessage }
            confirmHandler = { () => {
              dispatch(CLOSE_SAVE_FAIL_POPUP());
            }}
          />
      }
    </MainContent>
  );
};

export default ExpenseApprovalDetail;
