import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { DivPopupWrapper, DivPopupXLarge } from '@/component-style/Popup';
import { ButtonCancel, ButtonBasic } from '@/component-style/Button';
// import { DivFlexRow } from '@/component-style/RWDWrapper';
import { InputBasic } from '@/component-style/Input';
import SelectNormal from '@/components/UI/Select/SelectNormal';
import DateSelectorTwo from '@/components/UI/DateSelectorTwo';
import { formatTime } from '@/utils/timeUtils';
import Checkbox from '@/components/UI/Checkbox';
import moment from 'moment';
import { extraPaymentsParamsConfig } from '@/constant/dataConfig/sysConfigModule';

import PopupLoading from '@/components/UI/Popup/PopupLoading';

import { PSubtitle } from '@/component-style/TextElement';
import { integerConverter, floatConverter } from '@/utils/textUtils';
import MyInputNumber from '@/components/UI/MyInputNumber';
import { cloneDeep } from 'lodash';
import { leaveParamsConfig, leaveGiveHourParamsConfig, leaveApprovalParamsConfig } from '@/constant/dataConfig/sysConfigModule.js';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import { salaryExtraPaymentOptions } from '@/constant/options';
import TableWrapper from '@/components/UI/TableWrapper';
import PanelAddWageStructure from '@/components/Panel/Wage/PanelAddWageStructure';

import {
  DivFullWidthWrapper,
} from '@/component-style/RWDWrapper';

import {
  postCreateExtraPayments,
  getExtraPaymentsById,
  updateExtraPayments,
} from '@/api/financeService';

import { TableHead, TableRow, ThItem } from '@/component-style/Table';

import TableWageStructure from '@/components/Table/TableWageStructure';


// import {
//   // postCreateLeaveSetting,
//   patchLeaveSetting,
//   postCreateLeaveGiveSetting,
//   deleteLeaveGiveSetting,
//   postCreateLeaveGiveHourSetting,
//   postCreateLeave,
//   patchUpdateLeave,
//   postAddLeaveGiveHour,
//   getLeaveGiveHourById,
//   deleteRemoveLeaveGiveHours,
//   putSaveLeaveApprovalSetting,
//   deleteLeaveApprovalSetting,
//   getPaycheckStructures,
// } from '@/api/financeService';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import {
  DivFlexRow,
  DivItemWrapper,
} from '@/component-style/RWDWrapper';

const THead = styled(TableHead)`
  min-width: 1560px;
`;

const Th120 = styled(ThItem)`
  width: 120px;
`;

const DivButtonsWrapper = styled.div`
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const H3Title = styled.h3`
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: #8b8b8b;
  margin-bottom: 10px;
`;

const ButtonDiscard = styled(ButtonCancel)`
  width: 120px;
  margin-right: 10px;
`;

const ButtonSave = styled(ButtonBasic)`
  width: 120px;

  &:first-child { margin-right: 10px }
`;

const DivWrapper = styled.div`
  width: 100%;
  
  @media (min-width: 768px) {
    width: calc(100% - 135px);
  }
`;

var existingGiveHourIds = [];

const PopupSalaryExtraPayment = props => {
  const dispatch = useDispatch();
  const showSaveFailPopup = useSelector(state => state.apiFail.showSaveFailPopup);
  const apiFailPopupTitle = useSelector(state => state.apiFail.title);
  const apiFailPopupMessage = useSelector(state => state.apiFail.message);
  
  const [modifyPaycheckStructureId, setModifyPaycheckStructureId] = useState("");
  
  const [showSaveSuccessPopup, setShowSaveSuccessPopup] = useState(false);
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);

  const [extraPaymentsParams, setExtraPaymentsParams] = useState(cloneDeep(extraPaymentsParamsConfig));

  const saveExtraPaymentHandler = async () => {
    console.log(`PopupSalaryExtraPayment.js saveExtraPaymentHandler() extraPaymentsParams=${JSON.stringify(extraPaymentsParams)}`);
    try {
      await postCreateExtraPayments({
        ...extraPaymentsParams,
      });
      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '儲存失敗', err }));
      console.log(err);
    }
  };
  
  const updateExtraPaymentsParamsHandler = (key, value) => {
    console.log(`PopupSalaryExtraPayment.js updateExtraPaymentsParamsHandler() key=${JSON.stringify(key)}&value=${JSON.stringify(value)}`);
    const updateObj = { ...extraPaymentsParams };
    if (key === "startMonth") {
      console.log(`PopupSalaryExtraPayment.js updateExtraPaymentsParamsHandler() startMonth=${JSON.stringify(value)}`);
      updateObj[key] = moment(value).startOf('month').format('YYYY-MM-DD');
    } else {
      updateObj[key] = value;
    }
    setExtraPaymentsParams({ ...updateObj });
  };

  const getExtraPayment = async () => {
    // console.log(`PopupSalaryExtraPayment.js getExtraPayment() moment().startOf('month').format('YYYY-MM-DD')=${moment().startOf('month').format('YYYY-MM-DD')}`);
    console.log(`PopupSalaryExtraPayment.js getExtraPayment() editExtraPaymentId=${props.editExtraPaymentId}`);
    updateExtraPaymentsParamsHandler("recipientType", "list");
    updateExtraPaymentsParamsHandler("recipients", [props.editUserId]);
  };

  const initExtraPayment = useCallback(
    getExtraPayment
  , []
  );

  useEffect(() => {
    initExtraPayment();
  }, [initExtraPayment]);

  const cancelHandler = () => {
    console.log(`PopupEditWageStructure.js cancelHandler()`);
    props.cancelHandler();
  };

  return (
    <DivPopupWrapper>
      <DivPopupXLarge>
        <DivFlexRow>
          <DivItemWrapper>
            <PSubtitle>加、減項</PSubtitle>
            <SelectNormal
              // disabled = { disableHRFields || props.disabled }
              value = { extraPaymentsParams.type }
              options = { salaryExtraPaymentOptions }
              changeHandler = { value => {
                updateExtraPaymentsParamsHandler('type', value);
              }}
            />
          </DivItemWrapper>
          <DivItemWrapper>
            <PSubtitle>名稱</PSubtitle>
            <InputBasic
              type = "text"
              value = { extraPaymentsParams.name }
              onChange = { ({ target }) => {
                updateExtraPaymentsParamsHandler('name', target.value);
              }}
            />
          </DivItemWrapper>
          <DivItemWrapper>
            <PSubtitle>開始月份</PSubtitle>
            <DateSelectorTwo
              value = { extraPaymentsParams.startMonth === "" ? moment() : moment(extraPaymentsParams.startMonth) }
              picker = "month"
              changeHandler = { value => {
                updateExtraPaymentsParamsHandler(
                  'startMonth',
                  formatTime(value, 'YYYY-MM')
                );
              }}
            />
            &nbsp;&nbsp;
          </DivItemWrapper>
        </DivFlexRow>
        <DivFlexRow>
          <DivItemWrapper>
            <PSubtitle>次數(連續月份)</PSubtitle>
            <InputBasic
              value = { extraPaymentsParams.recurringMonth }
              onChange = { ({ target }) => {
                updateExtraPaymentsParamsHandler('recurringMonth', integerConverter(target.value));
              }}
            />
          </DivItemWrapper>
          <DivItemWrapper>
            <PSubtitle>每月金額</PSubtitle>
            <InputBasic
              value = { extraPaymentsParams.amountForEachMonth }
              onChange = { ({ target }) => {
                updateExtraPaymentsParamsHandler('amountForEachMonth', integerConverter(target.value));
              }}
            />
          </DivItemWrapper>
          <DivItemWrapper>
            <Checkbox
              title = "是否撥付"
              checked = { !extraPaymentsParams.forRecordOnly }
              changeHandler = { value => {
                if (value) {
                  updateExtraPaymentsParamsHandler('forRecordOnly', false);
                } else {
                  updateExtraPaymentsParamsHandler('forRecordOnly', true);
                }
              }}
            />
          &nbsp;&nbsp;
        </DivItemWrapper>
        </DivFlexRow>
        <DivFlexRow>
          &nbsp;
        </DivFlexRow>
        <DivFlexRow>
          <DivButtonsWrapper>
            <div>&nbsp;</div>
            <ButtonSave
              onClick = { () => { saveExtraPaymentHandler(); } }
            >{ "新增" }</ButtonSave>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>
            <ButtonDiscard
              onClick = { () => { cancelHandler(); } }
            >離開</ButtonDiscard>
          </DivButtonsWrapper>
        </DivFlexRow>
      </DivPopupXLarge>
      {
        showSaveSuccessPopup &&
          <PopupExecuteResult
            title = "更新成功"
            confirmHandler = { () => {
              setShowSaveSuccessPopup(false);
              props.reloadExtraPaymentsHandler();
            }}
          />
      }
    </DivPopupWrapper>
  );
};

export default PopupSalaryExtraPayment;
