import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

// import { postToGetProjectDetail } from '@/api/projectService';
// import { postToGetUploadFiles } from '@/api/storageService';
// import { postToGetProjectQuotations } from '@/api/financeService';
import { putNewActivityIdea } from '@/api/activityIdeaService';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import {
  FETCH_VENDOR_ID_OPTIONS,
  FETCH_PROJECT_ID_OPTIONS,
  FETCH_ACTIVITY_IDEA_TYPE_OPTIONS,
} from '@/store/actions/srpOptionsAction';

import MainContent from '@/component-style/MainContent';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import PanelActivityIdeaDetail from '@/components/Panel/PanelActivityIdeaDetail';
import { ButtonBasic, ButtonCancel } from '@/component-style/Button';
import { DivFlexRow } from '@/component-style/RWDWrapper';

// import { diffTime } from '@/utils/timeUtils';
import { integerConverter } from '@/utils/textUtils';
import { activityIdeaConfig } from '@/constant/dataConfig/projectModule';

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding-right: 0;
  padding-left: 135px;
  padding-bottom: 30px;
  justify-content: space-between;

  @media (max-width: 425px) {
    justify-content: center;
    padding-left: 0;
  }
`;

const ButtonSave = styled(ButtonBasic)`
  width: 120px;
  margin-right: 10px;
  margin-bottom: 10px;
`;

const ButtonClear = styled(ButtonCancel)`
  width: 120px;
  margin-bottom: 10px;
`;

const ActivityIdeaCreation = props => {
  const history = useHistory();
  const dispatch = useDispatch();
  const vendorIDs = useSelector(state => state.srpOptions.vendorIDOptions);
  const projectIDOptions = useSelector(state => state.srpOptions.projectIDOptions);
  const activityIdeaTypes = useSelector(state => state.srpOptions.activityIdeaTypeOptions);
  const activityIdeaTagDictionary = useSelector(state => state.srpOptions.activityIdeaTagDictionary);
  const showSaveFailPopup = useSelector(state => state.apiFail.showSaveFailPopup);
  const apiFailPopupTitle = useSelector(state => state.apiFail.title);
  const apiFailPopupMessage = useSelector(state => state.apiFail.message);

  const [activityIdea, setActivityIdea] = useState({ ...activityIdeaConfig });

  // const [alertText, setAlertText] = useState('');
  // const [showAlertPopup, setShowAlertPopup] = useState(false);
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [showSaveSuccessPopup, setShowSaveSuccessPopup] = useState(false);

  const updateActivityIdeaHandler = (key, value) => {
    const updateObj = { ...activityIdea };
    updateObj[key] = value;
    setActivityIdea({ ...updateObj });
  };

  const activityIdeaTypeChangeHandler = value => {
    setActivityIdea(prevState => ({
      ...prevState,
      activityIdeaTypeID: value,
      tag: '',
    }));
  };

  const clearHandler = () => {
    setActivityIdea({ ...activityIdeaConfig });
  };

  const closeSuccessPopupHandler = () => {
    clearHandler();
    setShowSaveSuccessPopup(false);
  }

  const saveNewActivityIdeaHandler = async () => {
    setShowLoadingPopup(true);
    // const { projectID } = activityIdea;

    try {
      // const projectData = await postToGetProjectDetail(projectID);
      // const projectFinanceFiles = await postToGetUploadFiles('projectFinance', projectID);
      // const projectQuotations = await postToGetProjectQuotations({
      //   projectID, amount: 1, page: 1,
      // });

      // const unlocked = projectData.data.projectUnlock !== null
      //   && projectData.data.projectUnlock !== undefined
      //   && diffTime(projectData.data.projectUnlock, Date.now(), 'days') <= 1;

      // const notQuoted = projectQuotations.data.totalCount === undefined
      //   && diffTime(projectData.data.actionTimeEnd, Date.now(), 'days') < 8;

      // const noPO = projectFinanceFiles.data.length === 0
      //   && diffTime(projectData.data.actionTimeEnd, Date.now(), 'days') < 4;

      // const isProjectLocked = !unlocked && (noPO || notQuoted);
      
      // if (isProjectLocked) {
      //   const alertText = `
      //     ${ noPO ? '無 PO 資料，' : '' }
      //     ${ notQuoted ? '無報價資料，' : '' }
      //     專案已被鎖定，請專案總監或總經理解鎖。
      //   `;
      //   setAlertText(alertText);
      //   setShowLoadingPopup(false);
      //   setShowAlertPopup(true);
      //   return 
      // }

      await putNewActivityIdea({
        ...activityIdea,
        budget: integerConverter(activityIdea.budget),
      });
      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '儲存失敗', err }));
      console.log(err);
    }
  };

  const initOptions = useCallback(() => {
    dispatch(FETCH_VENDOR_ID_OPTIONS());
    dispatch(FETCH_PROJECT_ID_OPTIONS());
    dispatch(FETCH_ACTIVITY_IDEA_TYPE_OPTIONS({
      enable: true,
      enableTag: true,
    }));
  }, [dispatch]);

  useEffect(() => {
    initOptions();
  }, [initOptions]);

  return (
    <MainContent>
      <PanelActivityIdeaDetail
        title = "新增活動創意"
        activityIdea = { activityIdea }
        vendorIDs = { vendorIDs }
        projectIDOptions = { projectIDOptions }
        activityIdeaTypes = { activityIdeaTypes }
        activityIdeaTagDictionary = { activityIdeaTagDictionary }
        updateActivityIdeaHandler = { updateActivityIdeaHandler }
        activityIdeaTypeChangeHandler = { activityIdeaTypeChangeHandler }
      >
        <DivFlexRow>
          <DivButtonsWrapper>
            <div>
              <ButtonSave
                onClick = { saveNewActivityIdeaHandler }
              >儲存</ButtonSave>
              <ButtonClear
                onClick = { clearHandler }
              >清除欄位</ButtonClear>
            </div>
            <ButtonClear
              onClick = { () => { history.goBack() } }
            >離開</ButtonClear>
          </DivButtonsWrapper>
        </DivFlexRow>
      </PanelActivityIdeaDetail>
      { showLoadingPopup && <PopupLoading/> }
      {
        showSaveSuccessPopup &&
          <PopupExecuteResult
            title = "儲存成功"
            confirmHandler = { closeSuccessPopupHandler }
          />
      }
      {/* {
        showAlertPopup &&
          <PopupExecuteResult
            text = { alertText }
            confirmHandler = { () => { setShowAlertPopup(false) } }
          />
      } */}
      {
        showSaveFailPopup &&
          <PopupExecuteResult
            title = { apiFailPopupTitle }
            text = { apiFailPopupMessage }
            confirmHandler = { () => {
              dispatch(CLOSE_SAVE_FAIL_POPUP());
            }}
          />
      }
    </MainContent>
  );
};

export default ActivityIdeaCreation;
