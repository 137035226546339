import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { TdItem, TableRow } from '@/component-style/Table';
import { getOptionDesc } from '@/utils/textUtils';
import { formatTime } from '@/utils/timeUtils';

const TrWrapper = styled(TableRow)`
  border-bottom: solid 2px #f2f2f2;
  box-sizing: border-box;
  min-width: 1360px;

  &:last-child {
    border-bottom: none;
  }
`;

const Td120 = styled(TdItem)`
  width: 120px;
`;

const Td160 = styled(TdItem)`
  width: 160px;
`;

const PLink = styled.a`
  cursor: pointer;
  color: #de2027;
  text-decoration: none;
`;

const Item = props => {
  const history = useHistory();
  const { benefit } = props;

  const memberOptions = useSelector(state => state.srpOptions.memberOptions);

  const director = getOptionDesc(memberOptions, benefit.director);
  const projectManager = getOptionDesc(memberOptions, benefit.projectManager);

  return (
    <TrWrapper>
      <Td120>
        <PLink
          onClick = { () => {
            history.push(`/Finance/ProjectFinanceInfo?id=${benefit.projectID}`)
          }}
        >{ benefit.projectCode }
        </PLink>
      </Td120>
      <Td160>{ benefit.projectName }</Td160>
      <Td120>{ director }</Td120>
      <Td120>{ projectManager }</Td120>
      <Td120>
        { formatTime(benefit.mbr, 'YYYY/MM/DD') || '' }
      </Td120>
      <Td120>{ benefit.prPrice || 0 }</Td120>
      <Td120>{ benefit.oopPrice || 0 }</Td120>
      <Td120>{ benefit.serviceIncome || 0 }</Td120>
      <Td120>{ benefit.invoiceIncome || 0 }</Td120>
      <Td120>{ benefit.voucherTotal || 0 }</Td120>
      <Td120>
        { formatTime(benefit.predictDate, 'YYYY/MM/DD') || '' }
      </Td120>
    </TrWrapper>
  );
};

export default Item;
