import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import PanelWrapper from '@/components/UI/PanelWrapper';
import SelectNormal from '@/components/UI/Select/SelectNormal';
import SelectSearchable from '@/components/UI/Select/SelectSearchable';
import SearchButtonSet from '@/components/UI/SearchButtonSet';
import { InputBasic } from '@/component-style/Input';

import { ButtonBasic } from '@/component-style/Button';
import { PSubtitle } from '@/component-style/TextElement';
import {
  DivFlexRow,
  DivItemWrapper,
} from '@/component-style/RWDWrapper';

import { countyOptions } from '@/constant/options';

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding: 0;
  justify-content: space-between;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const ButtonAdd = styled(ButtonBasic)`
  margin-right: 10px;
  width: 120px;

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`;

const ButtonExport = styled(ButtonBasic)`
  width: 120px;

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`;

const PanelVendorSearch = props => {
  const { config } = props;
  const history = useHistory();
  const informationRoutes = useSelector(state => state.login.informationRoutes);

  const userAddable = informationRoutes.indexOf('VendorCreation') !== -1;
  const userExportable = informationRoutes.indexOf('VendorManagement-Export') !== -1;

  const vendorTagOptions = vendorTypeID => 
    props.vendorTagDictionary[vendorTypeID] === undefined
      ? []
      : props.vendorTagDictionary[vendorTypeID];

  return (
    <PanelWrapper title = "廠商搜尋">
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>廠商名稱</PSubtitle>
          <InputBasic
            value = { config.vendorName }
            onChange = {({ target }) => {
              props.updateConfigHandler('vendorName', target.value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>統一編號</PSubtitle>
          <InputBasic
            value = { config.taxId }
            onChange = {({ target }) => {
              props.updateConfigHandler('taxId', target.value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>廠商大分類</PSubtitle>
          <SelectNormal
            value = { config.vendorTypeID }
            options = {[
              { value: '', desc: '請選擇大分類'},
              ...props.vendorTypeOptions
            ]}
            changeHandler = { props.vendorTypeChangeHandler }
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>廠商小分類</PSubtitle>
          <SelectNormal
            value = { config.tag }
            options = {[
              { value: '', desc: '請選擇小分類'},
              ...vendorTagOptions(config.vendorTypeID)
            ]}
            changeHandler = { value => {
              props.updateConfigHandler('tag', value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>
            聯絡人
          </PSubtitle>
          <SelectSearchable
            value = { config.contactWindowID }
            options = {[
              { value: '', desc: '請選擇聯絡人'},
              ...props.contactWindows,
            ]}
            changeHandler = { value => {
              props.updateConfigHandler('contactWindowID', value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>會計審核</PSubtitle>
          <SelectNormal
            value = { config.financeApproved }
            options = {[
              { value: '', desc: '請選擇審核狀態' },
              { value: true, desc: '已審核' },
              { value: false, desc: '未審核' },
            ]}
            changeHandler = { value => {
              props.updateConfigHandler('financeApproved', value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>縣/市</PSubtitle>
          <SelectNormal
            value = { config.city }
            options = {[
              { value: '', desc: '請選擇縣/市'},
              ...countyOptions
            ]}
            changeHandler = { value => {
              props.updateConfigHandler('city', value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>特殊資格</PSubtitle>
          <SelectNormal
            value = { config.specialFeature }
            options = {[
              { value: '', desc: '請選擇特殊資格' },
              ...props.specialFeatures,
            ]}
            changeHandler = { value => {
              props.updateConfigHandler('specialFeature', value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivButtonsWrapper>
          <div>
            <ButtonAdd
              disabled = { !userAddable }
              onClick = { () => { history.push('/Information/VendorCreation') } }
            >新增</ButtonAdd>
            <ButtonExport
              disabled = { !userExportable }
              onClick = { props.exportHandler }
            >匯出</ButtonExport>
          </div>
          <SearchButtonSet
            clearHandler = { props.clearHandler }
            searchHandler = { props.searchHandler }
          />
        </DivButtonsWrapper>
      </DivFlexRow>
    </PanelWrapper>
  );
};

export default PanelVendorSearch;
