import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';

import { DivPopupWrapper, DivPopupLarge } from '@/component-style/Popup';
import { ButtonCancel } from '@/component-style/Button';
// import { DivFlexRow } from '@/component-style/RWDWrapper';

import PopupLoading from '@/components/UI/Popup/PopupLoading';

import { workingHoursParams } from '@/constant/dataConfig/sysConfigModule';

import TableOvertimeByPersonnelDetail from '@/components/Table/Attendance/TableOvertimeByPersonnelDetail';

import {
  postToGetWorkingHours,
  postToGetScheduleTimeConfig,
} from '@/api/attendanceService';

import { postToGetProjectDetail } from '@/api/projectService';

import { postToGetUserInfo } from '@/api/authService';

const searchConfig = {
  isOverTime: true,
  // approvementStatus: 'approved',
  // userIDs: [],
  amount: 100000
};

const DivButtonsWrapper = styled.div`
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const H3Title = styled.h3`
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: #8b8b8b;
  margin-bottom: 10px;
`;

const ButtonDiscard = styled(ButtonCancel)`
  width: 120px;
  margin-right: 10px;
`;

const PopupOvertimeDetail = props => {
  // const dispatch = useDispatch();
  
  const viewOvertimeDetailUserID = props.userID;
  const startDate = props.startDate;
  const endDate = props.endDate;

  const [overtimeRecords, setOvertimeRecords] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [showLoadingPopup, setShowLoadingPopup] = useState(false);

  const [workingHoursParamsConfig, setWorkingHoursParamsConfig] = useState({ ...workingHoursParams });
  
  const setCurrentPageHandler = page => {
    setCurrentPage(page);
    fetchApprovedOvertime(page);
  };

  const fetchApprovedOvertime = async (page = currentPage) => {
    // console.log("fetchApprovedOvertime viewOvertimeDetailUserID:" + viewOvertimeDetailUserID + " startDate: " + startDate + " endDate: " + endDate);
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetWorkingHours({
        ...searchConfig,
        page,
        startDate: startDate,
        endDate: endDate,
        userIDs: [viewOvertimeDetailUserID],
        approvementStatus: "approved",
        overtimeType: "extraPay",
      });

      const { workingHours, totalCount } = data;
      const overtimeRecords = [];

      for (let i = 0; i < workingHours.length; i += 1 ) {
        const { projectID, userID } = workingHours[i];
        const projectInfo = await postToGetProjectDetail(projectID);
        const userInfo = await postToGetUserInfo(userID);
        const { projectCode, projectName } = projectInfo.data;
        const { name, basicSalary } = userInfo.data;
        overtimeRecords.push({
          ...workingHours[i],
          username: name,
          projectCode,
          projectName,
          basicSalary,
        });
      }

      setOvertimeRecords([ ...overtimeRecords ]);
      setTotalCount(totalCount || 0);
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const initUnapprovedOvertimes = useCallback(
    fetchApprovedOvertime, []);

  // const initOptions = useCallback(() => {
  //   dispatch(FETCH_WORKINGHOURS_TYPE_OPTIONS());
  // }, [dispatch]);

  useEffect(() => {
    // initOptions();
    initUnapprovedOvertimes();
  }, [initUnapprovedOvertimes]);

  const fetchScheduleTimeConfig = async () => {
    // console.log("fetchScheduleTimeConfig");
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetScheduleTimeConfig();
      const {
        normalDayExtraRate,
        restDayExtraRate,
        holidayExtraRate,
        nationalHolidayExtraRate,
        overTimeTotalLimit,
        ...remainItems
      } = data;

      setWorkingHoursParamsConfig({
        ...workingHoursParams,
        ...remainItems,
        normalDayExtraRate: {
          ...workingHoursParams.normalDayExtraRate,
          ...normalDayExtraRate,
        },
        restDayExtraRate: {
          ...workingHoursParams.restDayExtraRate,
          ...restDayExtraRate,
        },
        holidayExtraRate: {
          ...workingHoursParams.holidayExtraRate,
          ...holidayExtraRate,
        },
        nationalHolidayExtraRate: {
          ...workingHoursParams.nationalHolidayExtraRate,
          ...nationalHolidayExtraRate,
        },
        overTimeTotalLimit: {
          ...workingHoursParams.overTimeTotalLimit,
          ...overTimeTotalLimit,
        },
      });

    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  useEffect(() => {
    fetchScheduleTimeConfig();
  }, []);

  return (
    <DivPopupWrapper>
      <DivPopupLarge>
        <H3Title>加班明細</H3Title>
        <TableOvertimeByPersonnelDetail
          overtimeRecords = { overtimeRecords }
          totalCount = { totalCount }
          currentPage = { currentPage }
          pageChangedHandler = { value => {
            setCurrentPageHandler(value);
          }}
          workingHoursParamsConfig = { workingHoursParamsConfig }
        />
        <DivButtonsWrapper>
          <ButtonDiscard
            onClick = { props.cancelHandler }
          >關閉
          </ButtonDiscard>
        </DivButtonsWrapper>
      </DivPopupLarge>
      { showLoadingPopup && <PopupLoading/> }
    </DivPopupWrapper>
  );
};

export default PopupOvertimeDetail;
