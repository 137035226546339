import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { putNewJournalist } from '@/api/journalistService';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import {
  FETCH_MEDIA_ID_OPTIONS,
  FETCH_JOURNALIST_TYPES,
} from '@/store/actions/srpOptionsAction';

import MainContent from '@/component-style/MainContent';
import PanelJournalistDetail from '@/components/Panel/PanelJournalistDetail';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import { ButtonBasic, ButtonCancel } from '@/component-style/Button';
import { DivFlexRow } from '@/component-style/RWDWrapper';

import { journalistConfig } from '@/constant/dataConfig/informationModule';

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding-right: 0;
  padding-left: 135px;
  padding-bottom: 30px;
  justify-content: space-between;

  @media (max-width: 425px) {
    justify-content: center;
    padding-left: 0;
  }
`;

const ButtonSave = styled(ButtonBasic)`
  width: 120px;
  margin-right: 10px;
  margin-bottom: 10px;
`;

const ButtonClear = styled(ButtonCancel)`
  width: 120px;
  margin-bottom: 10px;
`;

const JournalistCreation = props => {
  const history = useHistory();
  const dispatch = useDispatch();
  const mediaIDList = useSelector(state => state.srpOptions.mediaIDOptions);
  const journalistTypes = useSelector(state => state.srpOptions.journalistTypeOptions);
  const showSaveFailPopup = useSelector(state => state.apiFail.showSaveFailPopup);
  const apiFailPopupTitle = useSelector(state => state.apiFail.title);
  const apiFailPopupMessage = useSelector(state => state.apiFail.message);

  const [journalist, setJournalist] = useState({
    ...journalistConfig,
    type: [],
    businessPhone: { ...journalistConfig.ButtonBasicbusinessPhone },
    companyAddress: { ...journalistConfig.companyAddress },
    privateAddress: { ...journalistConfig.privateAddress },
  });

  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [showSaveSuccessPopup, setShowSaveSuccessPopup] = useState(false);

  const updateJournalistHandler = (key, value) => {
    const updateObj = { ...journalist };
    updateObj[key] = value;
    setJournalist({ ...updateObj });
  };

  const clearData = () => {
    setJournalist({
      ...journalistConfig,
      type: [],
      businessPhone: { ...journalistConfig.ButtonBasicbusinessPhone },
      companyAddress: { ...journalistConfig.companyAddress },
      privateAddress: { ...journalistConfig.privateAddress },
    });
  };
  
  const saveNewJournalistHandler = async () => {
    setShowLoadingPopup(true);

    try {
      await putNewJournalist({ ...journalist });
      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '儲存失敗', err }));
      console.log(err);
    }
  };

  const closeSuccessPopupHandler = () => {
    clearData();
    setShowSaveSuccessPopup(false);
  };

  const initOptions = useCallback(() => {
    dispatch(FETCH_MEDIA_ID_OPTIONS());
    dispatch(FETCH_JOURNALIST_TYPES({ enable: true }));
  }, [dispatch]);

  useEffect(() => {
    initOptions();
  }, [initOptions]);

  return (
    <MainContent>
      <PanelJournalistDetail
        title = "新增記者資料"
        journalist = { journalist }
        mediaIDList = { mediaIDList }
        journalistTypes = { journalistTypes }
        updateJournalistHandler = { updateJournalistHandler }
      >
      {
        <DivFlexRow>
          <DivButtonsWrapper>
            <div>
              <ButtonSave
                onClick = { saveNewJournalistHandler }
              >儲存</ButtonSave>
              <ButtonClear
                onClick = { clearData }
              >清除欄位</ButtonClear>
            </div>
            <ButtonClear
              onClick = { () => { history.goBack() } }
            >離開</ButtonClear>
          </DivButtonsWrapper>
        </DivFlexRow>
      }
      </PanelJournalistDetail>
      { showLoadingPopup && <PopupLoading/> }
      {
        showSaveSuccessPopup &&
          <PopupExecuteResult
            title = "儲存成功"
            confirmHandler = { closeSuccessPopupHandler }
          />
      }
      {
        showSaveFailPopup &&
          <PopupExecuteResult
            title = { apiFailPopupTitle }
            text = { apiFailPopupMessage }
            confirmHandler = { () => {
              dispatch(CLOSE_SAVE_FAIL_POPUP());
            }}
          />
      }
    </MainContent>
  )
};

export default JournalistCreation;
