import React from 'react';
import styled from 'styled-components';

import { TableHead, TableRow, ThItem } from '@/component-style/Table';

const THead = styled(TableHead)`
  min-width: 1240px;
`;

const Th120 = styled(ThItem)`
  width: 120px;
`;

const Th160 = styled(ThItem)`
  width: 160px;
`;

const Header = props => {
  
  return (
    <THead>
      <TableRow>
        <Th120>客戶統編</Th120>
        <Th160>客戶名稱</Th160>
        <Th120>專案代號</Th120>
        <Th120>發票號碼</Th120>
        <Th120>發票開立統編</Th120>
        <Th120>發票開立日期</Th120>
        <Th120>未稅金額</Th120>
        <Th120>稅額</Th120>
        <Th120>發票金額</Th120>
        <Th120>匯會計系統</Th120>
      </TableRow>
    </THead>
  );
};

export default Header;
