import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  postToGetExpenses,
  postToGetExpenseDetail,
  putModifyExpenseDetail,
} from '@/api/expenseService';

import { postToGetProjectDetail } from '@/api/projectService';

import { FETCH_MEMBER_OPTIONS } from '@/store/actions/srpOptionsAction';

import MainContent from '@/component-style/MainContent';
import PanelWrapper from '@/components/UI/PanelWrapper';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import TableCashReports from '@/components/Table/Finance/TableCashReports';
import DatePeriodSelector from '@/components/UI/DatePeriodSelector';
import SearchButtonSet from '@/components/UI/SearchButtonSet';
import { ButtonBasic } from '@/component-style/Button';
import { PSubtitle } from '@/component-style/TextElement';
import {
  DivFlexRow,
  DivFullWidthWrapper,
  DivLongMultiSelector,
  DivItemWrapper
} from '@/component-style/RWDWrapper';

import { exportPettyCashFile } from '@/utils/xlsx.js';
import { getOptionDesc } from '@/utils/textUtils';
import { formatTime } from '@/utils/timeUtils';
import SelectNormal from '@/components/UI/Select/SelectNormal';
import { searchConfigGenerator } from '@/utils/apiRequestUtils';

const DivPanelContainer = styled.div`
  margin-bottom: 40px;
`;

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding: 0;
  justify-content: space-between;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const ButtonExport = styled(ButtonBasic)`
  width: 120px;

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`;

const CashExpenseList = () => {
  const dispatch = useDispatch();
  const financeRoutes = useSelector(state => state.login.financeRoutes);
  const memberOptions = useSelector(state => state.srpOptions.memberOptions);
  const companyTypeOptions = useSelector(state => state.srpOptions.companyTypeOptions);

  const [expenses, setExpenses] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [predictDate, setPredictDate] = useState({ start: null, end: null });

  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [companyType, setCompanyType] = useState('');

  const userExportable = financeRoutes.indexOf('CashExpenseList-Export') !== -1;

  const predictDateStartChangeHandler = value => {
    setPredictDate(prevState => ({
      ...prevState,
      start: formatTime(value, 'YYYY-MM-DD'),
    }));
  };

  const predictDateEndChangeHandler = value => {
    setPredictDate(prevState => ({
      ...prevState,
      end: formatTime(value, 'YYYY-MM-DD'),
    }));
  };

  const clearHandler = () => {
    setPredictDate({ start: null, end: null });
  };

  const exportHandler = async () => {
    setShowLoadingPopup(true);
    let pettyCashes = [];

    try {
      const { data } = await postToGetExpenses(
        searchConfigGenerator({
          page: 1,
          predictDate,
          isPaymentPrinted: false,
          amount: totalCount || 1,
          paymentMethod: ['advance'],
          approvalStatus: ['generalManagerApproved'],
          companyType: companyType,
        })
      );

      const { expenses } = data;

      const expensesData = [];

      for (let i = 0; i < expenses.length; i += 1) {
        const expense = expenses[i];
        const { projectID, expenseID } = expense;
        const expenseDetail = await postToGetExpenseDetail(expenseID);
        const projectDetail = await postToGetProjectDetail(projectID);
        const { predictDate, applyDate, vouchers, paymentDate } = expenseDetail.data;
        const { projectCode } = projectDetail.data;

        putModifyExpenseDetail({
          ...expenseDetail.data,
          isPaymentPrinted: true,
        });

        paymentDate === undefined && await putModifyExpenseDetail({
          ...expenseDetail.data,
          paymentDate: formatTime(predictDate, 'YYYY-MM-DD'),
        });

        expensesData.push({
          ...expense,
          vouchers,
          applyDate,
          projectCode,
          predictDate,
        });
      }

      pettyCashes = expensesData
        .reduce((acc, cur) => {
          const cashes = cur.vouchers.map(voucher => [
            cur.expenseCode || '',
            formatTime(cur.applyDate, 'YYYY/MM/DD'),
            cur.projectCode,
            voucher.note,
            voucher.amount,
            getOptionDesc(memberOptions, cur.user),
            formatTime(cur.predictDate, 'YYYY/MM/DD'),
          ]);
          return [...acc, ...cashes];
        }, []);

      } catch (err) { console.log(err) }
      
      setShowLoadingPopup(false);
      exportPettyCashFile(pettyCashes);
  };

  const setCurrentPageHandler = page => {
    setCurrentPage(page);
    fetchExpenses(page);
  };

  const searchHandler = () => {
    setCurrentPageHandler(1);
  };

  const fetchExpenses = async (page = currentPage) => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetExpenses(
        searchConfigGenerator({
          page,
          amount: 10,
          predictDate,
          isPaymentPrinted: false,
          paymentMethod: ['advance'],
          approvalStatus: ['generalManagerApproved'],
          companyType: companyType,
        })
      );

      const { expenses, totalCount } = data;
      const expenseForms = [];

      for (let i = 0; i < expenses.length; i += 1) {
        const expense = expenses[i];
        const { projectID, expenseID } = expense;
        const expenseDetail = await postToGetExpenseDetail(expenseID);
        const projectDetail = await postToGetProjectDetail(projectID);
        const { predictDate, price, isPaymentPrinted } = expenseDetail.data;
        const { projectCode, projectName } = projectDetail.data;
        expenseForms.push({
          ...expense,
          projectCode,
          projectName,
          price,
          isPaymentPrinted,
          predictDate: predictDate || null,
        });
      };

      setExpenses([...expenseForms]);
      setTotalCount(totalCount || 0);
    } catch(err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const initOptions = useCallback(() => {
    dispatch(FETCH_MEMBER_OPTIONS());
  }, [dispatch]);

  useEffect(() => {
    initOptions();
  }, [initOptions]);

  return (
    <MainContent>
      <DivPanelContainer>
        <PanelWrapper title = "代墊款支領報表">
        <DivFlexRow>
          <DivItemWrapper>
            <PSubtitle>所屬公司</PSubtitle>
            <SelectNormal
              value = { companyType }
              options = {[
                { value: '', desc: '請選擇公司' },
                ...companyTypeOptions,
              ]}
              changeHandler = { setCompanyType }
            />
          </DivItemWrapper>
        </DivFlexRow>
          <DivFlexRow>
            <DivFullWidthWrapper>
              <PSubtitle>預計付款日<span>*</span></PSubtitle>
              <DivLongMultiSelector>
                <DatePeriodSelector
                  startTime = { predictDate.start }
                  endTime = { predictDate.end }
                  endTimeOKHandler = { predictDateEndChangeHandler }
                  endTimeChangeHandler = { predictDateEndChangeHandler }
                  startTimeOKHandler = { predictDateStartChangeHandler }
                  startTimeChangeHandler = { predictDateStartChangeHandler }
                />
              </DivLongMultiSelector>
            </DivFullWidthWrapper>
          </DivFlexRow>
          <DivFlexRow>
            <DivButtonsWrapper>
              <ButtonExport
                disabled = { expenses.length === 0 || !userExportable }
                onClick = { exportHandler }
              >匯出</ButtonExport>
              <SearchButtonSet
                searchable = { predictDate.start !== null && predictDate.end !== null }
                searchHandler = { searchHandler }
                clearHandler = { clearHandler }
              />
            </DivButtonsWrapper>
          </DivFlexRow>
        </PanelWrapper>
      </DivPanelContainer>
      <TableCashReports
        expenses = { expenses }
        totalCount = { totalCount }
        currentPage = { currentPage }
        pageChangedHandler = { setCurrentPageHandler }
      />
      { showLoadingPopup && <PopupLoading/> }
    </MainContent>
  );
};

export default CashExpenseList;
