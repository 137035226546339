import React from 'react';
import styled from 'styled-components';

import { TdItem, TableRow } from '@/component-style/Table';
import { decimalCommaConverter } from '@/utils/textUtils';

const TrWrapper = styled(TableRow)`
  border-bottom: solid 2px #f2f2f2;
  box-sizing: border-box;
  min-width: 1080px;

  &:last-child {
    border-bottom: none;
  }
`;

const Td100 = styled(TdItem)`
  width: 100px;
`;

const Td120 = styled(TdItem)`
  width: 120px;
`;

const Td160 = styled(TdItem)`
  width: 160px;
`;

const Td180 = styled(TdItem)`
  width: 180px;
`;

const Item = props => {
  const { voucher } = props;

  // const type = getOptionDesc(vendorInvoiceTypes, voucher.voucherType);

  return (
    <TrWrapper>
      <Td100>{ voucher.companyType }</Td100>
      <Td120>{ voucher.projectID }</Td120>
      <Td120>{ voucher.projectName }</Td120>
      <Td120>{ voucher.director }</Td120>
      <Td120>{ voucher.invoiceQueryPeriod }</Td120>
      <Td120>{ voucher.projectQuotedPriceTotal == null ? "$ 0" : decimalCommaConverter(voucher.projectQuotedPriceTotal) }</Td120>
      <Td120>{ voucher.projectPRTotal == null ? "$ 0" : decimalCommaConverter(voucher.projectPRTotal) }</Td120>
      <Td120>{ voucher.projectTotal == null ? "$ 0" : decimalCommaConverter(voucher.projectTotal) }</Td120>
    </TrWrapper>
  )
};

export default Item;
