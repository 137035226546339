import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import LogoImage from '../../assets/images/veda_logo.png';

const ImgLogo = styled.img`
  width: 100%;
  height: auto;
  vertical-align: bottom;
  cursor: ${props => props.clickable ? 'pointer' : 'normal' };
`;

const Logo = props => {
  const history = useHistory();

  const toDashboard = () => {
    if (props.toDashboard) {
      history.push('/Dashboard');
    }
  }

  return (
    <ImgLogo
      src = { LogoImage }
      clickable = { props.toDashboard }
      onClick = { toDashboard }
    >
    </ImgLogo>
  )
};

export default Logo;