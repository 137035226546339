import React from 'react';
// import { useSelector } from 'react-redux';
import styled from 'styled-components';

import PanelWrapper from '@/components/UI/PanelWrapper';
import SelectSearchable from '@/components/UI/Select/SelectSearchable';
import { InputBasic } from '@/component-style/Input';
import SearchButtonSet from '@/components/UI/SearchButtonSet';
import { ButtonBasic } from '@/component-style/Button';
import { PSubtitle } from '@/component-style/TextElement';
import {
  DivFlexRow,
  DivItemWrapper,
  DivLongMultiSelector,
} from '@/component-style/RWDWrapper';
import { integerConverter } from '@/utils/textUtils';
import DateSelectorTwo from '@/components/UI/DateSelectorTwo';
import moment from 'moment';
import { formatTime } from '@/utils/timeUtils';

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding: 0;
  justify-content: space-between;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const ButtonExport = styled(ButtonBasic)`
  width: 120px;

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`;

const Input = styled(InputBasic)`
  @media (max-width: 320px) { width: 260px; }
  @media (min-width: 1920px) { width: 300px }
`;

const PanelAllLeaveTypeSearch = props => {
  // const financeRoutes = useSelector(state => state.login.financeRoutes);
  console.log(`PanelAllLeaveTypeSearch.js `);

  return (
    <PanelWrapper title = "同仁假別匯出">
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>所屬公司</PSubtitle>
          <SelectSearchable
            value = { props.searchConfig.companyType }
            options = { props.companyTypeOptions}
            changeHandler = { value => {
              props.updateSearchConfigHandler('companyType', value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivButtonsWrapper>
          <div></div>
          <ButtonExport
            onClick = { props.exportSalaryListXLSHandler }
          >匯出</ButtonExport>
          {/* <SearchButtonSet
            clearHandler = { props.clearHandler }
            searchHandler = { props.searchHandler }
          /> */}
        </DivButtonsWrapper>
      </DivFlexRow>
    </PanelWrapper>
  );
};

export default PanelAllLeaveTypeSearch;
