import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { postToGetUploadFiles } from '@/api/storageService';
// import { postToGetProjectTodos } from '@/api/projectTodoService';
import { postToGetInvitationStatus } from '@/api/journalistService';

import {
  postToGetQuotations,
  postToGetVouchersTotal,
  postToGetExpenseParamsConfig
} from '@/api/expenseService';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import {
  FETCH_MEMBER_OPTIONS,
  FETCH_DIRECTOR_OPTIONS,
  FETCH_CUSTOMER_OPTIONS,
  FETCH_TODO_TYPE_OPTIONS,
  FETCH_PROJECT_TYPE_OPTIONS,
  FETCH_COMPANY_TYPE_OPTIONS,
  FETCH_CONTACT_WINDOW_OPTIONS,
  FETCH_PROJECT_MANAGER_OPTIONS,
} from '@/store/actions/srpOptionsAction';

import {
  deleteProject,
  putProjectDetail,
  putProjectSetStatus,
  postToGetProjectDetail,
} from '@/api/projectService';

import {
  postToGetProjectQuotations,
  postToGetProjectInvoicesDetail,
} from '@/api/financeService';

import MainContent from '@/component-style/MainContent';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import PopupExecuteConfirm from '@/components/UI/Popup/PopupExecuteConfirm';
import TableInvitations from '@/components/Table/TableInvitations';
import TableProjectStatus from '@/components/Table/Project/TableProjectStatus';
// import TableSchedules from '@/components/Table/TableSchedules';
import PanelProjectDetail from '@/components/Panel/PanelProjectDetail';
import { ButtonBasic, ButtonCancel } from '@/component-style/Button';

import { integerConverter } from '@/utils/textUtils';
// import { searchConfigGenerator } from '@/utils/apiRequestUtils';
import { projectConfig } from '@/constant/dataConfig/projectModule';
import { invitationStatusConfig } from '@/constant/dataConfig/informationModule';

const DivTableContainer = styled.div`
  margin-bottom: 40px;
`;

const DivButtonsContainer = styled.div`
  border-top: solid 2px #f2f2f2;
  padding: 20px 30px;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const ButtonExecute = styled(ButtonBasic)`
  width: 120px;
  margin-right: 10px;
`;

const ButtonDelete = styled(ButtonCancel)`
  width: 120px;
  margin-right: 10px;
`

const ButtonDiscard = styled(ButtonCancel)`
  width: 120px;
  margin-right: 0;
`;

// const amount = 10;

const ProjectDetail = props => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  const projectRoutes = useSelector(state => state.login.projectRoutes);
  const memberOptions = useSelector(state => state.srpOptions.memberOptions);
  const customerOptions = useSelector(state => state.srpOptions.customerOptions);
  const directorOptions = useSelector(state => state.srpOptions.directorOptions);
  const projectTypeOptions = useSelector(state => state.srpOptions.projectTypeOptions);
  const companyTypeOptions = useSelector(state => state.srpOptions.companyTypeOptions);
  const contactWindowOptions = useSelector(state => state.srpOptions.contactWindowOptions);
  const projectManagerOptions = useSelector(state => state.srpOptions.projectManagerOptions);
  const showSaveFailPopup = useSelector(state => state.apiFail.showSaveFailPopup);
  const apiFailPopupTitle = useSelector(state => state.apiFail.title);
  const apiFailPopupMessage = useSelector(state => state.apiFail.message);

  const [project, setProject] = useState({
    ...projectConfig,
    projectID: id,
    mediaDays: [],
    customerWindows: [],
    members: [],
    supports: [],
    projectTypes: [],
    done: false,
  });

  // const [schedules, setSchedules] = useState([]);
  // const [scheduleCount, setScheduleCount] = useState(0);
  // const [scheduleCurrentPage, setScheduleCurrentPage] = useState(1);
  const [invitationStatus, setInvitationStatus] = useState({ ...invitationStatusConfig });
  const [isEditing, setIsEditing] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [showSaveSuccessPopup, setShowSaveSuccessPopup] = useState(false);
  const [showDeleteSuccessPopup, setShowDeleteSuccessPopup] = useState(false);
  const [showPopupConfirmationCancel, setShowPopupConfirmationCancel] = useState(false);
  const [showPopupCancelDoneSuccess, setShowPopupCancelDoneSuccess] = useState(false);
  const [showPopupCancel, setShowPopupCancel] = useState(false);
  const [showAlertPopup, setShowAlertPopup] = useState(false);
  const [alertText, setAlertText] = useState('');

  const userEditable = projectRoutes.indexOf('ProjectDetail-Edit') !== -1;
  var userClosable = projectRoutes.indexOf('ProjectDetail-Close') !== -1;
  const userDeletable = projectRoutes.indexOf('ProjectDetail-Delete') !== -1;

  const projectRole = useSelector(state => state.login.projectRole);
  if (projectRole.includes("director")) {
    userClosable = true;
  }
  console.log(`JSON.stringify(projectRole)=${JSON.stringify(projectRole)}`);

  const disabledSaveBtn = project.projectCode === ''
    || project.companyType === ''
    || project.projectName === ''
    || project.receiveDate === null
    || project.actionTimeStart === null
    || project.actionTimeEnd === null
    || project.customer === null
    || project.customerWindows.length === 0
    || project.director === ''
    || project.projectManager === ''
    || project.mediaDays
        .some(day => day.start === null || day.end === null);

  const updateProjectHandler = (key, value) => {
    const updateObj = { ...project };
    updateObj[key] = value;
    if (key === "internal" && value === true) {
      updateObj["allmembers"] = value;
    }
    setProject({ ...updateObj });
  };

  const updateCustomerHandler = value => {
    setProject(prevState => ({
      ...prevState,
      customer: value,
      customerWindows: [],
    }));
  };

  // const setSchedulePageHandler = page => {
  //   setScheduleCurrentPage(page);
  //   updateScheduleList(page);
  // };

  const updateAllMemberHandler = value => {
    setProject( prevState => ({
      ...prevState,
      members: [],
      supports: [],
      allmembers: value,
    }));
  };

  const fetchProjectDetail = async () => {
      setShowLoadingPopup(true);

      try {
        const { data } = await postToGetProjectDetail(id);
        const projectInvoices = await postToGetProjectInvoicesDetail(id);
        const expenseParamsDetail = await postToGetExpenseParamsConfig();
        const projectFinanceFiles = await postToGetUploadFiles('projectFinance', id);
        const projectQuotations = await postToGetProjectQuotations({
          projectID: id,
          page: 1,
          amount: 1000,
        });

        const quotationsDetail = await postToGetQuotations({ projectID: id });
        const voucherTotalDetail = await postToGetVouchersTotal({
          projectID: id,
          object: [],
          item: 'amount',
          approvalStatus: [
            'projectManagerVerify',
            'projectDirectorVerify',
            'financeVerify',
            'generalManagerVerifying',
            'generalManagerApproved',
          ],
        });

        const quotationPriceTotal = quotationsDetail.data
          .map(({ price }) => integerConverter(price))
          .reduce((acc, cur) => acc + cur, 0);
          
        const voucherPriceTotal = voucherTotalDetail.data.total || 0;
        const agentDifference = expenseParamsDetail.data.agentDifference || 0;

        const oopQuotations = projectQuotations.data.quotations
          .filter(quotation => quotation.type === 'oop');

        const oopPriceTotal = oopQuotations
          .map(({ price }) => integerConverter(price || 0))
          .reduce((acc, cur) => acc + cur, 0);

        const { invoices } = projectInvoices.data;
        // const isInvoiced = invoices.length > 0;
        const isPaid = invoices.length === 0 ? true : (invoices
          .some(quotation => quotation.paymentDate !== undefined));
        // console.log(`JSON.stringify(invoices)=${JSON.stringify(invoices)}`);
        // const predictDates = invoices
        //   .filter(({ predictDate }) => predictDate !== undefined)
        //   .map(({ predictDate }) => predictDate);

        // const predictDate = predictDates.length > 0
        //   ? predictDates.shift()
        //   : null;

        // let financeStatus = 'success';

        // const isFinanceFail = quotationPriceTotal - oopPriceTotal > agentDifference
        //   || voucherPriceTotal - oopPriceTotal > agentDifference;
  
        // const isFinanceWarning = quotationPriceTotal > oopPriceTotal
        //   || voucherPriceTotal > oopPriceTotal;
  
        // if (isFinanceWarning) { financeStatus = 'warning' }
        // if (isFinanceFail) { financeStatus = 'fail' }
  
        setProject({
          ...projectConfig,
          ...data,
          isPaid,
          // isInvoiced,
          // financeStatus,
          // predictDate: isInvoiced ? predictDate : null,
          // isVerified: projectFinanceFiles.data.length > 0,
          // isQuoted: projectQuotations.data.totalCount
          //   && projectQuotations.data.totalCount > 0,
        });
      } catch(err) { console.log(err) }

      setShowLoadingPopup(false);
    };

  // const updateScheduleList = async (page = scheduleCurrentPage) => {
  //     setShowLoadingPopup(true);

  //     try {
  //       const { data } = await postToGetProjectTodos(
  //         searchConfigGenerator({ page, amount, projectID: id }));
  //       const { todos, totalCount } = data;
  //       const projectTodos = todos
  //         .map(todo => ({ ...todo, isChecked: false }));
        
  //       for (let i = 0; i < projectTodos.length; i += 1) {
  //         const { projectID } = projectTodos[i];
  //         const { data } = await postToGetProjectDetail(projectID);
  //         const { projectCode, projectName, companyType } = data;
  //         projectTodos[i].projectCode = projectCode;
  //         projectTodos[i].projectName = projectName;
  //         projectTodos[i].companyType = companyType;
  //       }

  //       projectTodos
  //         .sort((a, b) => a.projectCode > b.projectCode ? 1 : -1);

  //       setSchedules([...projectTodos]);
  //       setScheduleCount(totalCount);
  //     } catch (err) { console.log(err) }
  //     setShowLoadingPopup(false);
  //   };

  const projectCancelDoneHandler = async () => {
    setShowPopupCancel(false)
    setShowLoadingPopup(true);

    try {
      await putProjectSetStatus({ projectID: id, done: false });
      setProject(prevState => ({ ...prevState, done: false }));
      setShowLoadingPopup(false);
      setShowPopupCancelDoneSuccess(true);
    } catch (err) {
      // console.log(err);
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '更新失敗', err }));
    }
  };

  const projectFinishHandler = async () => {
    setShowPopupConfirmationCancel(false)
    console.log(project);
    if (project.isReceived !== true) {
      setAlertText("全數銷項未入帳");
      setShowAlertPopup(true);
      return;
    }
    if (project.isPaid !== true) {
      setAlertText("全數進項未結清");
      setShowAlertPopup(true);
      return;
    }
    setShowLoadingPopup(true);
    

    try {
      await putProjectSetStatus({ projectID: id, done: true });
      setProject(prevState => ({ ...prevState, done: true }));
    } catch (err) { console.log(err) }
    setShowLoadingPopup(false);
  };

  const projectDeleteHandler = async () => {
    setShowDeletePopup(false);
    setShowLoadingPopup(true);

    try {
      await deleteProject(id);
      setShowLoadingPopup(false);
      setShowDeleteSuccessPopup(true);
    } catch (err) {
      console.log(err);
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '刪除失敗', err }));
    }
  };

  const updateProjectDetailHandler = async () => {
    console.log("projectDetai.js updateProjectDetailHandler() project['companyType']:" + project['companyType']);
    setShowLoadingPopup(true);

    try {
      const { done, ...remainItems } = project;
      await putProjectDetail({ ...remainItems });
      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '儲存失敗', err }));
      console.log(err);
    }
  };

  const fetchInvitationStatus = async () => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetInvitationStatus(id);
      setInvitationStatus(prevState => ({ ...prevState, ...data }));
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const initProjectDetail = useCallback(
    fetchProjectDetail, []);

  // const initScheduleList = useCallback(
  //   updateScheduleList, []);

  const initInvitationStatus = useCallback(
    fetchInvitationStatus, []);

    const initData = useCallback(
      async () => {
        await initProjectDetail();
        // await initScheduleList();
        await initInvitationStatus();
      }, [initInvitationStatus, initProjectDetail]);

    // const initData = useCallback(
    //   async () => {
    //     await initProjectDetail();
    //     // await initScheduleList();
    //     await initInvitationStatus();
    // }, [initInvitationStatus, initProjectDetail, initScheduleList]);

  const initOptions = useCallback(() => {
    dispatch(FETCH_MEMBER_OPTIONS());
    dispatch(FETCH_DIRECTOR_OPTIONS());
    dispatch(FETCH_CUSTOMER_OPTIONS());
    dispatch(FETCH_TODO_TYPE_OPTIONS());
    dispatch(FETCH_PROJECT_TYPE_OPTIONS());
    dispatch(FETCH_COMPANY_TYPE_OPTIONS());
    dispatch(FETCH_PROJECT_MANAGER_OPTIONS());
    dispatch(FETCH_CONTACT_WINDOW_OPTIONS({
      type: 'customer',
      companyName: true,
    }));
  }, [dispatch]);

  useEffect(() => {
    initOptions();
    initData();
  }, [initData, initOptions]);

  return (
    <MainContent>
      <DivTableContainer>
        <TableProjectStatus
          title = "專案詳情"
          project = { project }
        >
          <DivButtonsContainer>
            {
              <>
                <ButtonExecute
                  disabled = { !project.done }
                  onClick = { () => { setShowPopupCancel(true) } }
                >取消結案</ButtonExecute>
              </>
            }
            {
              !project.done &&
                <>
                  <ButtonExecute
                    disabled = { !userClosable }
                    onClick = { () => { setShowPopupConfirmationCancel(true) } }
                  >專案結案
                  </ButtonExecute>
                  <ButtonDelete
                    disabled = { !userDeletable }
                    onClick = { () => { setShowDeletePopup(true) } }
                  >專案刪除
                  </ButtonDelete>
                </>
            }
            <ButtonDiscard
              onClick = { () => { history.goBack() }}
            >離開
            </ButtonDiscard>
          </DivButtonsContainer>
        </TableProjectStatus>
      </DivTableContainer>
      <DivTableContainer>
        <TableInvitations
          showDetailButton = { true }
          projectID = { project.projectID }
          projectCode = { project.projectCode }
          projectName = { project.projectName }
          invitationStatus = { invitationStatus }
        />
      </DivTableContainer>
      {/* <DivTableContainer>
        <TableSchedules
          schedules = { schedules }
          showDetailButton = { true }
          totalCount = { scheduleCount }
          currentPage = { scheduleCurrentPage }
          pageChangedHandler = { setSchedulePageHandler }
        />
      </DivTableContainer> */}
      <DivTableContainer>
        <PanelProjectDetail
          disabled = { !isEditing }
          project = { project }
          memberOptions = { memberOptions }
          directorOptions = { directorOptions }
          customerOptions = { customerOptions }
          companyTypeOptions = { companyTypeOptions }
          projectTypeOptions = { projectTypeOptions }
          contactWindowOptions = { contactWindowOptions }
          projectManagerOptions = { projectManagerOptions }
          updateProjectHandler = { updateProjectHandler }
          updateCustomerHandler = { updateCustomerHandler }
          updateAllMemberHandler = { updateAllMemberHandler }
        >
          <DivButtonsContainer>
            {
              isEditing
                ? <ButtonExecute
                    disabled = { disabledSaveBtn }
                    onClick = { () => { updateProjectDetailHandler() } }
                  >儲存</ButtonExecute>
                : <ButtonExecute
                    disabled = { project.done || !userEditable }
                    onClick = { () => { setIsEditing(true) } }
                  >編輯</ButtonExecute>
            }
          </DivButtonsContainer>
        </PanelProjectDetail>
      </DivTableContainer>
      { showLoadingPopup && <PopupLoading/> }
      {
        showDeletePopup &&
          <PopupExecuteConfirm
            title = "刪除專案"
            text = "是否確定刪除?"
            cancelHandler = { () => { setShowDeletePopup(false) } }
            confirmHandler = { projectDeleteHandler }
          />
      }
      {
        showDeleteSuccessPopup &&
          <PopupExecuteResult
            title = "刪除成功"
            confirmHandler = { () => { history.goBack() } }
          />
      }
      {
        showSaveSuccessPopup &&
          <PopupExecuteResult
            title = "儲存成功"
            confirmHandler = { () => {
              setShowSaveSuccessPopup(false);
              setIsEditing(false);
            }}
          />
      }
      {
        showSaveFailPopup &&
          <PopupExecuteResult
            title = { apiFailPopupTitle }
            text = { apiFailPopupMessage }
            confirmHandler = { () => {
              dispatch(CLOSE_SAVE_FAIL_POPUP());
            }}
          />
      }
      {
        showPopupConfirmationCancel &&
          <PopupExecuteConfirm
            title = "專案結案"
            text = "是否確定結案?"
            cancelHandler = { () => { setShowPopupConfirmationCancel(false) } }
            confirmHandler = { () => { projectFinishHandler() } }
          />
      }
      {
        showPopupCancel &&
          <PopupExecuteConfirm
            title = "取消專案結案"
            text = "是否確定取消結案?"
            cancelHandler = { () => { setShowPopupCancel(false) } }
            confirmHandler = { () => { projectCancelDoneHandler(true) } }
          />
      }
      {
        showPopupCancelDoneSuccess &&
          <PopupExecuteResult
            title = "取消結案更新成功"
            confirmHandler = {() => {
              setShowPopupCancelDoneSuccess(false);
            }}
          />
      }
      {
        showAlertPopup &&
          <PopupExecuteResult
            title = "更新失敗"
            text = { alertText }
            confirmHandler = { () => { setShowAlertPopup(false) } }
          />
      }
    </MainContent>
  );
};

export default ProjectDetail;
