import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { uniq } from 'lodash';

import { postToGetQuotations } from '@/api/expenseService';

import {
  deleteVendors,
  postToGetVendors,
  postToGetVendorDetail,
} from '@/api/vendorService';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import {
  FETCH_SPECIAL_FEATURES,
  FETCH_VENDOR_TYPE_OPTIONS,
  FETCH_CONTACT_WINDOW_OPTIONS,
} from '@/store/actions/srpOptionsAction';

import MainContent from '@/component-style/MainContent';
import PanelVendorSearch from '@/components/Panel/PanelVendorSearch';
import TableVendors from '@/components/Table/TableVendors';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import PopupExecuteConfirm from '@/components/UI/Popup/PopupExecuteConfirm';

import {
  getOptionDesc,
  getAddressString,
  getVendorIdentityString,
  getVendorQualificationString,
  getVendorInvoiceTypeString,
  getVendorPaymentString,
} from '@/utils/textUtils';
import { searchConfigGenerator } from '@/utils/apiRequestUtils';
import { exportVendorData } from '@/utils/xlsx';


const DivPanelContainer = styled.div`
  margin-bottom: 40px;
`;

const VendorManagement = props => {
  const dispatch = useDispatch();
  const vendorTypeOptions = useSelector(state => state.srpOptions.vendorTypeOptions);
  const vendorTagDictionary = useSelector(state => state.srpOptions.vendorTagDictionary);
  const contactWindowOptions = useSelector(state => state.srpOptions.contactWindowOptions);
  const specialFeatures = useSelector(state => state.srpOptions.specialFeatures);
  const showSaveFailPopup = useSelector(state => state.apiFail.showSaveFailPopup);
  const apiFailPopupTitle = useSelector(state => state.apiFail.title);
  const apiFailPopupMessage = useSelector(state => state.apiFail.message);

  const [vendors, setVendors] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [searchConfig, setSearchConfig] = useState({
    vendorName: '',
    taxId: '',
    vendorTypeID: '',
    tag: '',
    contactWindowID: '',
    city: '',
    specialFeature: '',
    amount: 10,
  });

  const [selectAll, setSelectAll] = useState(false);
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showDeleteSuccessPopup, setShowDeleteSuccessPopup] = useState(false);

  const selectedItemNumber = vendors
    .filter(vendor => vendor.isChecked).length;

  const updateConfigHandler = (key, value) => {
    const updateObj = { ...searchConfig };
    updateObj[key] = value;
    setSearchConfig({ ...updateObj });
  };

  const vendorTypeChangeHandler = value => {
    setSearchConfig(prevState => ({
      ...prevState,
      vendorTypeID: value,
      tag: '',
    }));
  };

  const checkedHandler = (index, value) => {
    const updateVendors = [ ...vendors ];
    updateVendors[index].isChecked = value;
    setVendors([ ...updateVendors ]);
  };

  const selectAllHandler = value => {
    setSelectAll(value);
    const updateVendors = vendors
      .map(vendor => ({ ...vendor, isChecked: value }));
    setVendors([ ...updateVendors ]);
  };

  const setCurrentPageHandler = page => {
    setCurrentPage(page);
    updateVendors(page);
  };

  const searchHandler = async () => {
    setCurrentPageHandler(1);
  };

  const clearHandler = () => {
    setSearchConfig({
      vendorName: '',
      taxId: '',
      vendorTypeID: '',
      tag: '',
      contactWindowID: '',
      city: '',
      specialFeature: '',
      amount: 10,
    })
  };

  const exportHandler = async () => {
    setShowLoadingPopup(true);
    const vendorDatas = [];

    try {
      const { data } = await postToGetVendors(
        searchConfigGenerator({
          ...searchConfig, page: 1, amount: totalCount 
        })
      );

      const { vendors } = data;

      for (let i = 0; i < vendors.length; i += 1) {
        const { vendorID } = vendors[i];
        const { data } = await postToGetVendorDetail(vendorID);
        const { specialProperties, contactAddress,  invoiceAddress } = data;

        vendorDatas.push([
          data.vendorName,
          data.taxId || '',
          data.principal || '',
          specialProperties.personalService === true ? '是' : '否',
          specialProperties.transfer === true ? '是' : '否',
          specialProperties.foreigner === true ? '是' : '否',
          specialProperties.personalServiceDetail.payTax === true ? '是' : '否',
          specialProperties.personalServiceDetail.payPremium === true ? '是' : '否',
          getVendorIdentityString(specialProperties.personalServiceDetail.identityType),
          specialProperties.personalServiceDetail.nation || '',
          getOptionDesc(specialFeatures, specialProperties.personalServiceDetail.specialFeature),
          getAddressString(contactAddress),
          getAddressString(invoiceAddress),
          data.website || '',
          data.services || '',
          getVendorQualificationString(data.qualification),
          getVendorInvoiceTypeString(data.invoiceType),
          getVendorPaymentString(data.paymentMethods),
          data.note || '',
          data.available === true ? '是' : '否',
        ]);
      }

    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
    exportVendorData(vendorDatas);
  };

  const deleteVendorsHandler = async () => {
    setShowDeletePopup(false);
    setShowLoadingPopup(true);

    try {
      const selectedVendors = vendors
        .filter(vendor => vendor.isChecked)
        .map(vendor => vendor.vendorID);

      await deleteVendors(selectedVendors);
      setShowLoadingPopup(false);
      setShowDeleteSuccessPopup(true);
    } catch(err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '刪除失敗', err }));
      console.log(err);
    }
  };

  const updateVendors = async (page = currentPage) => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetVendors(
        searchConfigGenerator({ ...searchConfig, page })
      );

      const { vendors, totalCount } = data;
      const vendorList = vendors
        .map(vendor => ({ ...vendor, isChecked: false }));

      for (let i = 0; i < vendorList.length; i += 1) {
        const vendor = vendorList[i];
        const { vendorID } = vendor;
        const projectRecordDetail = await postToGetQuotations({ vendorID });

        const projectIDs = uniq(
          projectRecordDetail.data
            .filter(({ expenseCode }) => expenseCode !== undefined)
            .map(({ projectID }) => projectID)
        );
        vendor.times = projectIDs.length;
      }

      setVendors([ ...vendorList ]);
      setTotalCount(totalCount);
    } catch(err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const initVendors = useCallback(
    updateVendors, []);

  const initOptions = useCallback(() => {
    dispatch(FETCH_SPECIAL_FEATURES());
    dispatch(FETCH_VENDOR_TYPE_OPTIONS());
    dispatch(FETCH_CONTACT_WINDOW_OPTIONS({
      type: 'vendor',
      companyName: true,
    }));
  }, [dispatch]);

  useEffect(() => {
    initOptions();
    initVendors();
  }, [initOptions, initVendors]);

  return (
    <MainContent>
      <DivPanelContainer>
        <PanelVendorSearch
          config = { searchConfig }
          vendorTypeOptions = { vendorTypeOptions }
          vendorTagDictionary = { vendorTagDictionary }
          contactWindows = { contactWindowOptions }
          specialFeatures = { specialFeatures }
          clearHandler = { clearHandler }
          exportHandler = { exportHandler }
          searchHandler = { searchHandler }
          updateConfigHandler = { updateConfigHandler }
          vendorTypeChangeHandler = { vendorTypeChangeHandler }
        />
      </DivPanelContainer>
      <TableVendors
        disableDelete = { selectedItemNumber === 0 }
        vendors = { vendors }
        selectAll = { selectAll }
        totalCount = { totalCount }
        currentPage = { currentPage }
        checkHandler = { checkedHandler }
        selectAllHandler = { selectAllHandler }
        deleteHandler = { () =>  {
          setShowDeletePopup(true);
        }}
        pageChangedHandler = { value => {
          selectAllHandler(false);
          setCurrentPageHandler(value);
        }}
      />
      { showLoadingPopup && <PopupLoading/> }
      {
        showDeletePopup &&
          <PopupExecuteConfirm
            title = "刪除廠商名單"
            text = { `確定刪除${ selectedItemNumber }個項目` }
            confirmHandler = { deleteVendorsHandler }
            cancelHandler = { () => {
              setShowDeletePopup(false);
            }}
          />
      }
      {
        showDeleteSuccessPopup &&
          <PopupExecuteResult
            title = "刪除成功"
            confirmHandler = { () => {
              setShowDeleteSuccessPopup(false);
              updateVendors();
            }}
          />
      }
      {
        showSaveFailPopup &&
          <PopupExecuteResult
            title = { apiFailPopupTitle }
            text = { apiFailPopupMessage }
            confirmHandler = { () => {
              dispatch(CLOSE_SAVE_FAIL_POPUP());
            }}
          />
      }
    </MainContent>
  );
};

export default VendorManagement;
