import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
// import styled from 'styled-components';

import { postToGetQuotations } from '@/api/expenseService';

import {
  postToGetProjectDetail,
  postToGetEvaluationRecords,
} from '@/api/projectService';

import MainContent from '@/component-style/MainContent';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
// import TableVendorEvaluation from '@/components/Table/TableVendorEvaluation';
import TableVendorProjectRecords from '@/components/Table/TableVendorProjectRecords';

// const TableContainer = styled.div`
//   margin-bottom: 40px;
// `;

const VendorRecords = () => {
  const { id } = useParams();

  const [quotations, setQuotations] = useState([]);

  const [evaluations, setEvaluations] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [showLoadingPopup, setShowLoadingPopup] = useState(false);

  const setCurrentPageHandler = page => {
    setCurrentPage(page);
    fetchEvaluationRecords(page);
  };

  const fetchEvaluationRecords = async (page = currentPage) => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetEvaluationRecords({
        vendorID: id,
        amount: 10,
        page,
      });

      const { evaluations, totalCount } = data;

      for (let i = 0; i < evaluations.length; i += 1) {
        const evaluation = evaluations[i];
        const { projectID } = evaluation;
        const projectDetail = await postToGetProjectDetail(projectID);
        const { projectName, projectCode } = projectDetail.data;
        evaluation.projectCode = projectCode;
        evaluation.projectName = projectName;
      }
      
      setEvaluations([...evaluations]);
      setTotalCount(totalCount || 0);
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const fetchVendorQuotations = async () => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetQuotations({ vendorID: id });
      const quotations = data
        .filter(({ expenseCode }) => expenseCode !== undefined);

      for (let i = 0; i < quotations.length; i += 1) {
        const quotation = quotations[i];
        const { projectID } = quotation;
        const projectDetail = await postToGetProjectDetail(projectID);
        const { projectName, projectCode } = projectDetail.data;
        quotation.projectCode = projectCode;
        quotation.projectName = projectName;
      }
      
      setQuotations([...quotations]);
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const initEvaluationRecords = useCallback(
    fetchEvaluationRecords, []);

  const initVendorQuotations = useCallback(
    fetchVendorQuotations, []);

  const initData = useCallback(async () => {
    await initEvaluationRecords();
    await initVendorQuotations();
  }, [initEvaluationRecords, initVendorQuotations])

  useEffect(() => {
    initData();
  }, [initData])

  return (
    <MainContent>
      {/* <TableContainer>
        <TableVendorEvaluation
          totalCount = { totalCount }
          displayMode = { true }
          currentPage = { currentPage }
          evaluations = { evaluations }
          pageChangedHandler = { setCurrentPageHandler }
        />
      </TableContainer> */}
      <TableVendorProjectRecords
        quotations = { quotations }
      />
      { showLoadingPopup && <PopupLoading/> }
    </MainContent>
  );
};

export default VendorRecords;
