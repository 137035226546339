import React from 'react';
import styled from 'styled-components';

import TableWrapper from '@/components/UI/TableWrapper';
import Checkbox from '@/components/UI/Checkbox';
// import { PSubtitle } from '@/component-style/TextElement';
import { ButtonBasic } from '@/component-style/Button';
import { InputBasic, TextareaBasic } from '@/component-style/Input';
import {
  DivFlexRow,
  DivItemWrapper,
  DivFullWidthWrapper,
  // DivLongMultiSelector,
} from '@/component-style/RWDWrapper';

import { currencyConverter } from '@/utils/textUtils';

import MyInputNumber from '@/components/UI/MyInputNumber';

const DivCheckboxWrapper = styled.div`
  width: 300px;
  height: 40px;
  line-height: 40px;

  @media (max-width: 767px) {
    width: auto;
    height: 20px;
    line-height: 20px;
    margin-bottom: 5px;
  }
`;

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding: 0;
  padding-bottom: 15px;
  justify-content: space-between;

  @media (max-width: 425px) {
    justify-content: center;
  }
`;

const ButtonSave = styled(ButtonBasic)`
  width: 120px;
  margin-bottom: 10px;

  &:first-child { margin-right: 10px }
`;

const MyPSubtitle = styled.p`
  width: 145px;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  color: #767676;
  
  span { color: #de2027 }
  
  @media (max-width: 767px) {
    height: 20px;
    line-height: 20px;
    margin-bottom: 5px;
  }
`;

const MyPSubtitleLong = styled.p`
  width: 390px;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  color: #767676;
  
  span { color: #de2027 }
  
  @media (max-width: 767px) {
    height: 20px;
    line-height: 20px;
    margin-bottom: 5px;
  }
`;

export const MyDivLongMultiSelector = styled.div`
  width: calc(100% - 145px);

  @media (max-width: 767px) {
    width: 100%;
  }

  & .ant-select {
    width: 100%;
  }
`;



const PanelProjectAccounting = props => {
  const { projectFinance } = props;

  return (
    <TableWrapper title = "帳款資訊">
      <DivFlexRow>
        <DivItemWrapper>
          <MyPSubtitle>專案收入總金額(未稅)</MyPSubtitle>
          <MyInputNumber
            disabled = { true }
            value = { props.untaxedIncomeTotal }
            // value = { props.prServiceTotal }
          />
          {/* <InputBasic
            disabled = { true }
            value = { currencyConverter(props.prServiceTotal) }
          /> */}
        </DivItemWrapper>
        <DivItemWrapper>
          <MyPSubtitle>專案收入總金額(含稅)</MyPSubtitle>
          <MyInputNumber
            disabled = { true }
            value = { props.taxedIncomeTotal }
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <MyPSubtitle>專案支出總金額(未稅)</MyPSubtitle>
          <MyInputNumber
            disabled = { true }
            value = { props.untaxedExpenseTotal }
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <MyPSubtitle>專案支出總金額(含稅)</MyPSubtitle>
          <MyInputNumber
            disabled = { true }
            value = { props.voucherPriceTotal }
          />
          {/* <InputBasic
            disabled = { true }
            value = { currencyConverter(props.voucherPriceTotal) }
          /> */}
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <MyPSubtitle>專案其他收入</MyPSubtitle>
          <MyInputNumber
            disabled = { !props.isEditing || !props.canEditProjectOtherIncome}
            value = { projectFinance.projectOtherIncome }
            onChange = { value => {
              props.updateProjectFinanceHandler('projectOtherIncome', value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <MyPSubtitle>服務費總金額</MyPSubtitle>
          <MyInputNumber
            disabled = { true }
            value = { props.serviceFeeTotal }
            // value = { props.prServiceTotal }
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <MyPSubtitleLong>服務費總金額=專案收入(未稅)-專案支出(未稅)-專案其他收入</MyPSubtitleLong>
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <MyPSubtitle>全數進項已結清</MyPSubtitle>
          <DivCheckboxWrapper>
            <Checkbox
              disabled = { !props.isEditing || !props.projectFinanceInputClearedEditable}
              // disabled = { !props.isEditing}
              // checked = { projectFinance.isPaid }
              checked = { props.projectDetail.isPaid }
              changeHandler = { value => {
                // props.updateProjectFinanceHandler('isPaid', value);
                props.updateProjectDetailHandler('isPaid', value);
              }}
            />
          </DivCheckboxWrapper>
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivFullWidthWrapper>
          <MyPSubtitle>財務備註</MyPSubtitle>
          <MyDivLongMultiSelector>
            <TextareaBasic
              disabled = { !props.isEditing }
              value = { projectFinance.note }
              onChange = { ({ target }) => {
                props.updateProjectFinanceHandler('note', target.value);
              }}
            />
          </MyDivLongMultiSelector>
        </DivFullWidthWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivButtonsWrapper>
          <div>
            <ButtonSave
              disabled = { projectFinance.projectID === '' }
              onClick = { props.exportVouchersHandler }
            >支出細項總表</ButtonSave>
            <ButtonSave
              disabled = { projectFinance.projectID === '' }
              onClick = { props.downloadVoucherHandler }
            >單據下載</ButtonSave>
          </div>
          {
            props.isEditing
              ? <div>
                  <ButtonSave
                    onClick = { props.saveHandler }
                  >儲存</ButtonSave>
                  <ButtonSave
                     onClick = { props.cancelHandler }
                  >關閉</ButtonSave>
                </div>
              : <ButtonSave
                  onClick = { props.editHandler }
                  disabled = { projectFinance.projectID === '' || props.uneditable === true }
                >編輯</ButtonSave>
          }
        </DivButtonsWrapper>
      </DivFlexRow>
    </TableWrapper>
  );
};

export default PanelProjectAccounting;
