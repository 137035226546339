import axios from 'axios';

// const isDevelopMode = process.env.NODE_ENV === 'development';
const localDevBaseUrl = process.env.REACT_APP_API_BASE_URL || 'https://localhost';

const axiosConfig = axios.create();

axiosConfig.defaults.baseURL = localDevBaseUrl;
axiosConfig.defaults.withCredentials = true;
axiosConfig.interceptors.response.use(
  response => {
    const { headers } = response;
    if ('x-csrftokenbound' in headers) {
      axiosConfig.defaults.headers.common['x-csrftokenbound'] = headers['x-csrftokenbound'];
    }
    return response;
  },
  error => {
    const { response } = error;
    if (response
      && response.data.message === 'invalid CSRF token') {
      axiosConfig.defaults.headers.common['x-csrftokenbound'] = response.headers['x-csrftokenbound'];
      return axiosConfig.request(error.config);
    }

    return Promise.reject(error);
  }
);

export default axiosConfig;
