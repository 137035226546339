import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

// import { postToGetUploadFiles } from '@/api/storageService';
// import { postToGetProjectQuotations } from '@/api/financeService';
import { postToGetProjectDetail } from '@/api/projectService';
import { postToGetMediaReports } from '@/api/mediaService';

import {
  FETCH_MEDIA_ID_OPTIONS,
  FETCH_PROJECT_ID_OPTIONS,
  FETCH_JOURNALIST_ID_OPTIONS,
} from '@/store/actions/srpOptionsAction';

import MainContent from '@/component-style/MainContent';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
// import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import PanelMediaReportsSearch from '@/components/Panel/PanelMediaReportsSearch';
import TableMediaReports from '@/components/Table/TableMediaReports';

import { integerConverter, getOptionDesc } from '@/utils/textUtils';
import { searchConfigGenerator } from '@/utils/apiRequestUtils';
import { exportMediaReportData } from '@/utils/xlsx';
// import { diffTime } from '@/utils/timeUtils';

const DivPanelContainer = styled.div`
  margin-bottom: 40px;
`;

const MediaReports = props => {
  const history = useHistory();
  const dispatch = useDispatch();
  const mediaIDList = useSelector(state => state.srpOptions.mediaIDOptions);
  const projectIDList = useSelector(state => state.srpOptions.projectIDOptions);
  const journalistIDList = useSelector(state => state.srpOptions.journalistIDOptions);

  const [mediaReports, setMediaReports] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [searchConfig, setSearchConfig] = useState({
    projectID: '',
    origin: '',
    mediaID: '',
    journalistID: '',
    title: '',
    publishDate: {
      start: null,
      end: null,
    },
    benefit: {
      max: '',
      min: '',
    },
    amount: 10,
  });

  const [selectAll, setSelectAll] = useState(false);
  // const [alertText, setAlertText] = useState('');
  // const [showAlertPopup, setShowAlertPopup] = useState(false);
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);

  const journalistIDOptions = journalistIDList
    .filter(({ mediaID }) => mediaID === searchConfig.mediaID);

  const updateConfigHandler = (key, value) => {
    const updateObj = { ...searchConfig };
    updateObj[key] = value;
    setSearchConfig({ ...updateObj });
  };

  const mediaChangeHandler = value => {
    setSearchConfig(prevState => ({
      ...prevState,
      mediaID: value,
      journalistID: '',
    }));
  };

  const checkedHandler = (index, value) => {
    const updateMediaReports = [ ...mediaReports ];
    updateMediaReports[index].isChecked = value;
    setMediaReports([ ...updateMediaReports ]);
  };

  const selectAllHandler = value => {
    setSelectAll(value);
    const updateMediaReports = mediaReports
      .map(mediaReport => ({ ...mediaReport, isChecked: value }));
    setMediaReports([ ...updateMediaReports ]);
  };

  const clearHandler = () => {
    setSearchConfig({
      projectID: '',
      origin: '',
      mediaID: '',
      journalistID: '',
      title: '',
      benefit: { max: '', min: '' },
      publishDate: { start: null, end: null },
    });
  };

  const searchHandler = async () => {
    setCurrentPageHandler(1);
  };

  const setCurrentPageHandler = page => {
    setCurrentPage(page);
    updateMediaReports(page);
  };

  const goDetailHandler = async (projectID, announcementID) => {
    setShowLoadingPopup(true);

    try {
      // const projectData = await postToGetProjectDetail(projectID);
      // const projectFinanceFiles = await postToGetUploadFiles('projectFinance', projectID);
      // const projectQuotations = await postToGetProjectQuotations({
      //   projectID, amount: 1, page: 1,
      // });

      // const unlocked = projectData.data.projectUnlock !== null
      //   && projectData.data.projectUnlock !== undefined
      //   && diffTime(projectData.data.projectUnlock, Date.now(), 'days') <= 1;

      // const notQuoted = projectQuotations.data.totalCount === undefined
      //   && diffTime(projectData.data.actionTimeEnd, Date.now(), 'days') < 8;

      // const noPO = projectFinanceFiles.data.length === 0
      //   && diffTime(projectData.data.actionTimeEnd, Date.now(), 'days') < 4;

      setShowLoadingPopup(false);

      // const isProjectLocked = !unlocked && (noPO || notQuoted);

      history.push(`/Project/MediaReportDetail/${announcementID}`);
      // if (isProjectLocked) {
      //   const alertText = `
      //     ${ noPO ? '無 PO 資料，' : '' }
      //     ${ notQuoted ? '無報價資料，' : '' }
      //     專案已被鎖定，請專案總監或總經理解鎖。
      //   `;
      //   setAlertText(alertText);
      //   setShowAlertPopup(true);
      // } else {
      //   history.push(`/Project/MediaReportDetail/${announcementID}`);
      // }
    } catch (err) {
      console.log(err);
      setShowLoadingPopup(false);
    }
  };

  const exportHandler = async () => {
    setShowLoadingPopup(true);
    const mediaReportDatas = [];

    try {
      const { benefit, ...remainItems } = searchConfig;
      const { data } = await postToGetMediaReports(
        searchConfigGenerator({
          ...remainItems,
          benefit: {
            min: searchConfig.benefit.min === ''
              ? null : integerConverter(searchConfig.benefit.min),
            max: searchConfig.benefit.max === ''
              ? null : integerConverter(searchConfig.benefit.max),
          },
          page: 1,
          amount: totalCount || 1,
        })
      );

      const { mediaReports } = data;

      for (let i = 0; i < mediaReports.length; i += 1) {
        const mediaReport = mediaReports[i];
        const { projectID } = mediaReport;
        const projectDetail = await postToGetProjectDetail(projectID);
        const { projectCode, projectName } = projectDetail.data;

        mediaReportDatas.push([
          projectCode || '',
          projectName || '',
          mediaReport.origin || '',
          mediaReport.mediaTypeName || '',
          mediaReport.mediaName || '',
          mediaReport.publishDate,
          getOptionDesc(journalistIDList, mediaReport.journalistID),
          mediaReport.title || '',
          mediaReport.benefit || '',
        ]);
      }
      
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
    exportMediaReportData(mediaReportDatas);
  };

  const updateMediaReports = async (page = currentPage) => {
    setShowLoadingPopup(true);

    try {
      const { benefit, ...remainItems } = searchConfig;
      const { data } = await postToGetMediaReports(
        searchConfigGenerator({
          ...remainItems,
          benefit: {
            min: searchConfig.benefit.min === ''
              ? null : integerConverter(searchConfig.benefit.min),
            max: searchConfig.benefit.max === ''
              ? null : integerConverter(searchConfig.benefit.max),
          },
          page,
        })
      );

      const { mediaReports, totalCount } = data;
      const mediaReportList = [];

      for (let i = 0; i < mediaReports.length; i += 1) {
        const { projectID, journalistID, publishDate, ...remainItems } = mediaReports[i];
        const projectDetail = await postToGetProjectDetail(projectID);
        const { projectCode, projectName } = projectDetail.data;
        mediaReportList.push({
          ...remainItems,
          projectID,
          projectCode,
          projectName,
          journalistID,
          isChecked: false,
          publishDate: publishDate === '0001-01-01' ? '' : publishDate,
        });
      }

      mediaReportList
        .sort((a, b) => a.mediaName > b.mediaName ? 1 : -1);

      setMediaReports([ ...mediaReportList ]);
      setTotalCount(totalCount);
    } catch(err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const initMediaReports = useCallback(
    updateMediaReports, []);

  const initOptions = useCallback(() => {
    dispatch(FETCH_MEDIA_ID_OPTIONS());
    dispatch(FETCH_PROJECT_ID_OPTIONS());
    dispatch(FETCH_JOURNALIST_ID_OPTIONS([]));
  }, [dispatch]);

  useEffect(() => {
    initOptions();
    initMediaReports();
  }, [initMediaReports, initOptions]);

  return (
    <MainContent>
      <DivPanelContainer>
        <PanelMediaReportsSearch
          config = { searchConfig }
          mediaIDOptions = { mediaIDList }
          projectIDOptions = { projectIDList }
          journalistIDOptions = { journalistIDOptions }
          clearHandler = { clearHandler }
          exportHandler = { exportHandler }
          searchHandler = { searchHandler }
          mediaChangeHandler = { mediaChangeHandler }
          updateConfigHandler = { updateConfigHandler }
        />
      </DivPanelContainer>
      <TableMediaReports
        showDetailButton = { true }
        reports = { mediaReports }
        selectAll = { selectAll }
        totalCount = { totalCount }
        currentPage = { currentPage }
        checkHandler = { checkedHandler }
        goDetailHandler = { goDetailHandler }
        selectAllHandler = { selectAllHandler }
        pageChangedHandler = { value => {
          selectAllHandler(false);
          setCurrentPageHandler(value);
        }}
      />
      { showLoadingPopup && <PopupLoading/> }
      {/* {
        showAlertPopup &&
          <PopupExecuteResult
            text = { alertText }
            confirmHandler = { () => { setShowAlertPopup(false) } }
          />
      } */}
    </MainContent>
  );
};

export default MediaReports;
