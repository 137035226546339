import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import TableWrapper  from '@/components/UI/TableWrapper';
import PageSelector from '@/components/UI/PageSelector';
import { Table, TableBody } from '@/component-style/Table';
import Header from './Header';
import Item from './Item';

const TableFinanceApprovalList = props => {
  const history = useHistory();
  const financeRoutes = useSelector(state => state.login.financeRoutes);
  const disableDetailPage = financeRoutes.indexOf('FinanceApprovalDetail') === -1;

  const { expenses } = props;

  return (
    <TableWrapper title = "支憑審核列表">
      <Table>
        <Header/>
        <TableBody>
          {
            expenses.map(expense =>
              <Item
                key = { expense.expenseID }
                expense = { expense }
                disableDetailPage = { disableDetailPage }
                goDetailHandler = { () => {
                  history.push(`/Finance/FinanceApprovalDetail/${expense.expenseID}`)
                }}
              />
            )
          }
        </TableBody>
      </Table>
      <PageSelector
        current = { props.currentPage }
        total = { props.totalCount }
        pageChangedHandler = { props.pageChangedHandler }
      />
    </TableWrapper>
  );
};

export default TableFinanceApprovalList;
