import React from 'react';
import styled from 'styled-components';

import { TableHead, TableRow, ThItem } from '@/component-style/Table';

const THead = styled(TableHead)`
  min-width: 1380px;
`;

const Th80 = styled(ThItem)`
  width: 80px;
`;

const Th100 = styled(ThItem)`
  width: 100px;
`;

const Th120 = styled(ThItem)`
  width: 120px;
`;

const Th160 = styled(ThItem)`
  width: 160px;
`;

const Th240 = styled(ThItem)`
  width: 240px;
`;

const Header = props => {
  
  return (
    <THead>
      <TableRow>
        <Th80>選擇</Th80>
        <Th80>支憑單號</Th80>
        <Th160>廠商名稱</Th160>
        <Th80>報價種類</Th80>
        <Th80>時薪</Th80>
        <Th120>報價金額(含稅)</Th120>
        <Th120>議價後金額(含稅)</Th120>
        <Th240>服務說明</Th240>
        <Th120>附件</Th120>
        <Th120>單據</Th120>
        <Th100></Th100>
        <Th80></Th80>
      </TableRow>
    </THead>
  );
};

export default Header;
