import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindows } from '@fortawesome/free-brands-svg-icons';

import { ButtonBasic } from '@/component-style/Button';

const ButtonLogin = styled(ButtonBasic)`
  height: 50px;
  background-color: #e63b00;
  display: flex;
`;

const DivIconWrapper = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PButtonText = styled.p`
  width: 100%;
  line-height: 50px;
`;

const ButtonLoginBy365 = props => {

  return (
    <ButtonLogin onClick = { props.loginHandler }>
      <DivIconWrapper>
        <FontAwesomeIcon
          icon = { faWindows }
          size = "lg"
        />
      </DivIconWrapper>
      <PButtonText>Login By Office365</PButtonText>
    </ButtonLogin>
  );
};

export default ButtonLoginBy365;
