import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import PanelWrapper from '@/components/UI/PanelWrapper';
import SelectMultiple from '@/components/UI/Select/SelectMultiple';
import SelectSearchable from '@/components/UI/Select/SelectSearchable';
import SearchButtonSet from '@/components/UI/SearchButtonSet';
import { InputBasic } from '@/component-style/Input';
import { ButtonBasic } from '@/component-style/Button';
import { PSubtitle } from '@/component-style/TextElement';
import {
  DivFlexRow,
  DivItemWrapper,
  DivFullWidthWrapper,
  DivLongMultiSelector,
} from '@/component-style/RWDWrapper';

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding: 0;
  justify-content: space-between;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const ButtonAdd = styled(ButtonBasic)`
  margin-right: 10px;
  width: 120px;

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`;

const ButtonExport = styled(ButtonBasic)`
  width: 120px;

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`;

const PanelCustomerSearch = props => {
  const history = useHistory();
  const informationRoutes = useSelector(state => state.login.informationRoutes);
  const { config } = props;

  const userAddable = informationRoutes.indexOf('CustomerCreation') !== -1;
  const userExportable = informationRoutes.indexOf('CustomerManagement-Export') !== -1;

  return (
    <PanelWrapper title = "客戶搜尋">
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>客戶代號</PSubtitle>
          <InputBasic
            value = { config.customerCode }
            onChange = {({ target }) => {
              props.updateConfigHandler('customerCode', target.value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>客戶名稱</PSubtitle>
          <InputBasic
            value = { config.customerName }
            onChange = {({ target }) => {
              props.updateConfigHandler('customerName', target.value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>統一編號</PSubtitle>
          <InputBasic
            value = { config.customerTaxId }
            onChange = {({ target }) => {
              props.updateConfigHandler('customerTaxId', target.value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>聯絡人</PSubtitle>
          <SelectSearchable
            value = { config.contactWindowID }
            options = {[
              { value: '', desc: '請選擇聯絡人'},
              ...props.contactWindows,
            ]}
            changeHandler = { value => {
              props.updateConfigHandler('contactWindowID', value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivFullWidthWrapper>
          <PSubtitle>產業類別</PSubtitle>
          <DivLongMultiSelector>
            <SelectMultiple
              value = { config.industry }
              options = {[ ...props.customerIndustries ]}
              changeHandler = { value => {
                props.updateConfigHandler('industry', value);
              }}
            />
          </DivLongMultiSelector>
        </DivFullWidthWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivButtonsWrapper>
          <div>
            <ButtonAdd
              disabled = { !userAddable }
              onClick = { () => { history.push('/Information/CustomerCreation') } }
            >新增</ButtonAdd>
            <ButtonExport
              disabled = { !userExportable }
              onClick = { props.exportHandler }
            >匯出</ButtonExport>
          </div>
          <SearchButtonSet
            clearHandler = { props.clearHandler }
            searchHandler = { props.searchHandler }
          />
        </DivButtonsWrapper>
      </DivFlexRow>
    </PanelWrapper>
  );
};

export default PanelCustomerSearch;
