import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

// import { postToGetProjectDetail } from '@/api/projectService';
// import { postToGetUploadFiles } from '@/api/storageService';
// import { postToGetProjectQuotations } from '@/api/financeService';

import {
  postToGetVenues,
  putNewVenueReservations,
} from '@/api/venueService';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import {
  FETCH_VENUE_EQUIPMENTS,
  FETCH_VENUE_TYPE_OPTIONS,
  FETCH_VENUE_CHARACTERISTICS,
} from '@/store/actions/srpOptionsAction';

import { FETCH_PROJECT_ID_OPTIONS } from '@/store/actions/srpOptionsAction';

import MainContent from '@/component-style/MainContent';
import PanelVenueSearch from '@/components/Panel/PanelVenueSearch';
import TableImportVenues from '@/components/Table/TableImportVenues';
import SearchButtonSet from '@/components/UI/SearchButtonSet';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import { ButtonCancel } from '@/component-style/Button';
import { DivFlexRow } from '@/component-style/RWDWrapper';

import { searchConfigGenerator } from '@/utils/apiRequestUtils';
import { integerConverter, floatConverter } from '@/utils/textUtils';
// import { diffTime } from '@/utils/timeUtils';

const TableContainer = styled.div`
  margin-top: 40px;
`;

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding: 0;
  justify-content: flex-end;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const ButtonExit = styled(ButtonCancel)`
  margin-left: 10px;
  width: 120px;

  @media (max-width: 430px) {
    margin-left: 0;
    margin-top: 10px;
  }
`;

const VenueReservationCreation = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const venueEquipments = useSelector(state => state.srpOptions.venueEquipments);
  const venueTypeOptions = useSelector(state => state.srpOptions.venueTypeOptions);
  const venueCharacteristics = useSelector(state => state.srpOptions.venueCharacteristics);
  const projectIDOptions = useSelector(state => state.srpOptions.projectIDOptions);
  const showSaveFailPopup = useSelector(state => state.apiFail.showSaveFailPopup);
  const apiFailPopupTitle = useSelector(state => state.apiFail.title);
  const apiFailPopupMessage = useSelector(state => state.apiFail.message);

  const [venues, setVenues] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [projectID, setProjectID] = useState('')
  const [searchConfig, setSearchConfig] = useState({
    type: '',
    city: '',
    town: '',
    minCapacity: '',
    maxCapacity: '',
    characteristics: [],
    equipments: [],
    minSize: '',
    maxSize: '',
    amount: 10,
  });

  const [selectAll, setSelectAll] = useState(false);
  // const [alertText, setAlertText] = useState('');
  // const [showAlertPopup, setShowAlertPopup] = useState(false);
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [showSaveSuccessPopup, setShowSaveSuccessPopup] = useState(false);

  const selectedItemNumber = venues
    .filter(venue => venue.isChecked).length;

  const updateConfigHandler = (key, value) => {
    const updateObj = { ...searchConfig };
    updateObj[key] = value;
    setSearchConfig({ ...updateObj });
  };

  const cityChangeHandler = value => {
    setSearchConfig(prevState => ({
      ...prevState,
      city: value,
      town: '',
    }));
  };

  const checkedHandler = (index, value) => {
    const updateVenues = [ ...venues ];
    updateVenues[index].isChecked = value;
    setVenues([ ...updateVenues ]);
  };

  const selectAllHandler = value => {
    setSelectAll(value);
    const updateVenues = venues
      .map(venue => ({ ...venue, isChecked: value }));
      setVenues([ ...updateVenues ]);
  };

  const clearHandler = () => {
    setSearchConfig({
      type: '',
      city: '',
      town: '',
      minCapacity: '',
      maxCapacity: '',
      characteristics: [],
      equipments: [],
      minSize: '',
      maxSize: '',
      amount: 10,
    });
  };

  const searchHandler = async () => {
    setCurrentPageHandler(1);
  };

  const setCurrentPageHandler = page => {
    setCurrentPage(page);
    updateVenues(page);
  };

  const closeSuccessPopupHandler = () => {
    clearHandler();
    setVenues([]);
    setProjectID('')
    setSelectAll(false);
    setShowSaveSuccessPopup(false);
  };

  const importHandler = async () => {
    setShowLoadingPopup(true);

    try {
      // const projectData = await postToGetProjectDetail(projectID);
      // const projectFinanceFiles = await postToGetUploadFiles('projectFinance', projectID);
      // const projectQuotations = await postToGetProjectQuotations({
      //   projectID, amount: 1, page: 1,
      // });

      // const unlocked = projectData.data.projectUnlock !== null
      //   && projectData.data.projectUnlock !== undefined
      //   && diffTime(projectData.data.projectUnlock, Date.now(), 'days') <= 1;

      // const notQuoted = projectQuotations.data.totalCount === undefined
      //   && diffTime(projectData.data.actionTimeEnd, Date.now(), 'days') < 8;

      // const noPO = projectFinanceFiles.data.length === 0
      //   && diffTime(projectData.data.actionTimeEnd, Date.now(), 'days') < 4;

      // const isProjectLocked = !unlocked && (noPO || notQuoted);

      // if (isProjectLocked) {
      //   const alertText = `
      //     ${ noPO ? '無 PO 資料，' : '' }
      //     ${ notQuoted ? '無報價資料，' : '' }
      //     專案已被鎖定，請專案總監或總經理解鎖。
      //   `;
      //   setAlertText(alertText);
      //   setShowLoadingPopup(false);
      //   setShowAlertPopup(true);
      //   return
      // }

      const venueIDs = venues
        .filter(venue => venue.isChecked)
        .map(venue => venue.venueID);

      await putNewVenueReservations({ projectID, venueIDs })
      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '匯入失敗', err }));
      console.log(err);
    }
  };

  const updateVenues = async (page = currentPage) => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetVenues(
        searchConfigGenerator({
          ...searchConfig,
          minCapacity: integerConverter(searchConfig.minCapacity),
          maxCapacity: integerConverter(searchConfig.maxCapacity),
          minSize: floatConverter(searchConfig.minSize),
          maxSize: floatConverter(searchConfig.maxSize),
          page,
        })
      );

      const { venues, totalCount } = data;
      const venueList = venues
        .map(venue => ({ ...venue, isChecked: false }))
        .sort((a, b) => a.venueName > b.venueName ? 1 : -1);

      setVenues([ ...venueList ]);
      setTotalCount(totalCount);
    } catch(err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const initVenues = useCallback(
    updateVenues, []);

  const initOptions = useCallback(() => {
    dispatch(FETCH_VENUE_EQUIPMENTS());
    dispatch(FETCH_PROJECT_ID_OPTIONS());
    dispatch(FETCH_VENUE_TYPE_OPTIONS());
    dispatch(FETCH_VENUE_CHARACTERISTICS());
  }, [dispatch]);

  useEffect(() => {
    initOptions();
    initVenues();
  }, [initOptions, initVenues]);

  return (
    <MainContent>
      <PanelVenueSearch
        title = "新增專案場地"
        config = { searchConfig }
        projectID = { projectID }
        showProjectSelect = { true }
        venueEquipments = { venueEquipments }
        venueTypeOptions = { venueTypeOptions }
        projectIDOptions = { projectIDOptions }
        venueCharacteristics = { venueCharacteristics }
        cityChangeHandler = { cityChangeHandler }
        updateConfigHandler = { updateConfigHandler }
        updateProjectIDHandler = { setProjectID }
      >
        <DivFlexRow>
          <DivButtonsWrapper>
            <SearchButtonSet
              clearHandler = { clearHandler }
              searchHandler = { searchHandler }
            />
            <ButtonExit
              onClick = { () => { history.goBack() } }
            >離開</ButtonExit>
          </DivButtonsWrapper>
        </DivFlexRow>
      </PanelVenueSearch>
      <TableContainer>
        <TableImportVenues
          disabledImport = { projectID === '' || selectedItemNumber === 0 }
          venues = { venues }
          totalCount = { totalCount }
          currentPage = { currentPage }
          selectAll = { selectAll }
          selectAllHandler = { selectAllHandler }
          checkHandler = { checkedHandler }
          importHandler = { importHandler }
          pageChangedHandler = { value => {
            selectAllHandler(false);
            setCurrentPageHandler(value);
          }}
        />
      </TableContainer>
      { showLoadingPopup && <PopupLoading/> }
      {
        showSaveSuccessPopup &&
          <PopupExecuteResult
            title = "匯入成功"
            confirmHandler = { closeSuccessPopupHandler }
          />
      }
      {/* {
        showAlertPopup &&
          <PopupExecuteResult
            text = { alertText }
            confirmHandler = { () => { setShowAlertPopup(false) } }
          />
      } */}
      {
        showSaveFailPopup &&
          <PopupExecuteResult
            title = { apiFailPopupTitle }
            text = { apiFailPopupMessage }
            confirmHandler = { () => {
              dispatch(CLOSE_SAVE_FAIL_POPUP());
            }}
          />
      }
    </MainContent>
  );
};

export default VenueReservationCreation;
