import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  deleteCustomers,
  postToGetCustomers,
  postToGetCustomerDetail,
} from '@/api/customerService';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import {
  FETCH_CUSTOMER_INDUSTRIES,
  FETCH_CONTACT_WINDOW_OPTIONS,
} from '@/store/actions/srpOptionsAction';

import MainContent from '@/component-style/MainContent';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import PopupExecuteConfirm from '@/components/UI/Popup/PopupExecuteConfirm';
import PanelCustomerSearch from '@/components/Panel/PanelCustomerSearch';
import TableCustomers from '@/components/Table/TableCustomers';
import { exportCustomerData } from '@/utils/xlsx';
import {
  getAddressString,
  getCustomerTypeString,
  getCustomerSourceString,
} from '@/utils/textUtils';
import { formatTime } from '@/utils/timeUtils';

const DivPanelContainer = styled.div`
  margin-bottom: 40px;
`;

const CustomerManagement = props => {
  const dispatch = useDispatch();
  const customerIndustries = useSelector(state => state.srpOptions.customerIndustries);
  const contactWindowOptions = useSelector(state => state.srpOptions.contactWindowOptions);
  const showSaveFailPopup = useSelector(state => state.apiFail.showSaveFailPopup);
  const apiFailPopupTitle = useSelector(state => state.apiFail.title);
  const apiFailPopupMessage = useSelector(state => state.apiFail.message);

  const [customers, setCustomers] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [checkedItems, setCheckedItems] = useState([]);

  const [searchConfig, setSearchConfig] = useState({
    customerCode: '',
    customerName: '',
    customerTaxId: '',
    contactWindowID: '',
    industry: [],
    amount: 10,
  });

  const [selectAll, setSelectAll] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [showDeleteSuccessPopup, setShowDeleteSuccessPopup] = useState(false);

  const selectedItemNumber = customers
    .filter(customer => customer.isChecked).length;

  const updateConfigHandler = (key, value) => {
    const updateObj = { ...searchConfig };
    updateObj[key] = value;
    setSearchConfig({ ...updateObj });
  };

  const setCurrentPageHandler = (page, clearCheckedItem = false) => {
    setCurrentPage(page);
    updateCustomers(page);
    if (clearCheckedItem === true) {
      // console.log("customereManagement setCurrentPageHandler clear checkedItems");
      setCheckedItems([]);
    }
  };

  const searchHandler = async () => {
    setCurrentPageHandler(1, true);
  };

  const customerCheckedHandler = (index, value) => {
    const updateCustomers = [...customers];
    updateCustomers[index].isChecked = value;
    setCustomers([...updateCustomers]);
    if (value === true) {
      // console.log("customereManagement customerCheckedHandler customerCode:" +updateCustomers[index].customerCode)
      setCheckedItems([...checkedItems, updateCustomers[index]]);
    } else {
      setCheckedItems(checkedItems.filter(item => item.customerCode !== updateCustomers[index].customerCode));
    }
  };

  const selectAllHandler = value => {
    setSelectAll(value);
    const updateCustomers = customers
      .map(customer => ({ ...customer, isChecked: value }));
    setCustomers([...updateCustomers]);
    if (value === true) {
      setCheckedItems([...updateCustomers]);
    } else {
      setCheckedItems([]);
    }
  };

  const clearHandler = () => {
    setSearchConfig({
      customerCode: '',
      customerName: '',
      customerTaxId: '',
      contactWindowID: '',
      industry: [],
      amount: 10,
    });
  };

  const exportHandler = async () => {
    setShowLoadingPopup(true);
    const customerDatas = [];

    try {
      // const { data } = await postToGetCustomers({
      //   ...searchConfig,
      //   page: 1,
      //   amount: totalCount || 1,
      // });

      // const { customers } = data;
      for (let i = 0; i < checkedItems.length; i += 1) {
        const { customerID }  = checkedItems[i];
        const { data } = await postToGetCustomerDetail(customerID);
        customerDatas.push([
          data.customerCode,
          data.customerName,
          data.customerTaxId,
          data.principal,
          getAddressString(data.contactAddress),
          getAddressString(data.invoiceAddress),
          data.website,
          getCustomerTypeString(data.partnership.type),
          data.partnership.startDate === '0001-01-01'
            ? '' : formatTime(data.partnership.startDate, 'YYYY/MM/DD'),
          data.partnership.endDate === '0001-01-01'
            ? '' : formatTime(data.partnership.endDate, 'YYYY/MM/DD'),
            getCustomerSourceString(data.partnership.source),
          data.partnership.note,
          data.isComparePrice === true ? '是' : '否',
        ]);
      }

    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
    exportCustomerData(customerDatas);
  };

  const deleteCustomersHandler = async () => {
    setShowDeletePopup(false);
    setShowLoadingPopup(true);

    try {
      const selectedCustomers = checkedItems
        .filter(customer => customer.isChecked)
        .map(customer => customer.customerID);

      await deleteCustomers(selectedCustomers);

      setShowLoadingPopup(false);
      setShowDeleteSuccessPopup(true);
    } catch(err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '刪除失敗', err }));
      console.log(err);
    }
  };

  function isItemChecked(needle, haystack) {
    var checked = false;
    for (let i = 0; i < haystack.length; i++) {
      // console.log("customereManagement isItemChecked needle customerCode:" + needle.customerCode)
      if (haystack[i].customerCode === needle.customerCode) {
        // console.log("customereManagement isItemChecked customerCode:" + haystack[i].customerCode)
        if (haystack[i].isChecked === true) {
          checked = true;
          break;
        }
      }
    }
    return checked;
  }

  const updateCustomers = async (page = currentPage) => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetCustomers({
        ...searchConfig,
        page,
      });
      const { customers, totalCount } = data;
      // console.log("customereManagement updateCustomers checkedItems:" + JSON.stringify(checkedItems));
      const customerList = customers
        .map(customer => ({ ...customer, isChecked: isItemChecked(customer, checkedItems) }));

      setCustomers([...customerList]);
      setTotalCount(totalCount);
      // console.log("customereManagement updateCustomers 2 checkedItems:" + JSON.stringify(checkedItems));
    } catch(err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const initCustomers = useCallback(updateCustomers, []);

  const initOptions = useCallback(() => {
    dispatch(FETCH_CUSTOMER_INDUSTRIES());
    dispatch(FETCH_CONTACT_WINDOW_OPTIONS({
      type: 'customer',
      companyName: true,
    }));
  }, [dispatch]);

  useEffect(() => {
    initOptions();
    initCustomers();
  }, [initOptions, initCustomers]);

  return (
    <MainContent>
      <DivPanelContainer>
        <PanelCustomerSearch
          contactWindows = { contactWindowOptions }
          customerIndustries = { customerIndustries }
          config = { searchConfig }
          clearHandler = { clearHandler }
          exportHandler = { exportHandler }
          searchHandler = { searchHandler }
          updateConfigHandler = { updateConfigHandler }
        />
      </DivPanelContainer>
      <TableCustomers
        disableDelete = { selectedItemNumber === 0 }
        customers = { customers }
        selectAll = { selectAll }
        selectAllHandler = { selectAllHandler }
        totalCount = { totalCount }
        currentPage = { currentPage }
        checkHandler = { customerCheckedHandler }
        deleteHandler = { () =>  {
          setShowDeletePopup(true);
        }}
        pageChangedHandler = { value => {
          // selectAllHandler(false);
          setCurrentPageHandler(value);
        }}
      />
      { showLoadingPopup && <PopupLoading/> }
      {
        showDeletePopup &&
          <PopupExecuteConfirm
            title = "刪除客戶名單"
            text = { `確定刪除${ selectedItemNumber }個項目` }
            confirmHandler = { deleteCustomersHandler }
            cancelHandler = { () => {
              setShowDeletePopup(false);
            }}
          />
      }
      {
        showDeleteSuccessPopup &&
          <PopupExecuteResult
            title = "刪除成功"
            confirmHandler = { () => {
              setShowDeleteSuccessPopup(false);
              updateCustomers();
            }}
          />
      }
      {
        showSaveFailPopup &&
          <PopupExecuteResult
            title = { apiFailPopupTitle }
            text = { apiFailPopupMessage }
            confirmHandler = { () => {
              dispatch(CLOSE_SAVE_FAIL_POPUP());
            }}
          />
      }
    </MainContent>
  );
};

export default CustomerManagement;
