import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import SelectEvaluation from '@/components/UI/Select/SelectEvaluation';

import { TdItem, TableRow } from '@/component-style/Table';
import { evaluationOptions } from '@/constant/options';
import { ButtonBasic } from '@/component-style/Button';

import { getOptionDesc } from '@/utils/textUtils';

const TrWrapper = styled(TableRow)`
  border-bottom: solid 2px #f2f2f2;
  box-sizing: border-box;
  min-width: 920px;

  &:last-child {
    border-bottom: none;
  }
`;

const Td80 = styled(TdItem)`
  width: 80px;
`;

const Td120 = styled(TdItem)`
  width: 120px;
`;

const ButtonSave = styled(ButtonBasic)`
  width: 60px;
`;

const Item = props => {
  const { evaluation } = props;
  const memberOptions = useSelector(state => state.srpOptions.memberOptions);

  const total = evaluation.attitude 
    + evaluation.timeManagement 
    + evaluation.quality 
    + evaluation.teamwork 
    + evaluation.communication;

  const memberName = getOptionDesc(memberOptions, evaluation.userID);

  return (
    <TrWrapper>
      <Td120>{ memberName }</Td120>
      <Td120>
        <SelectEvaluation
          disabled = { props.disabled }
          value = { evaluation.attitude }
          options = { evaluationOptions }
          changeHandler = { value => {
            props.evaluationChangeHandler({
              ...evaluation,
              attitude: value,
            })
          }}
        />
      </Td120>
      <Td120>
        <SelectEvaluation
          disabled = { props.disabled }
          value = { evaluation.timeManagement }
          options = { evaluationOptions }
          changeHandler = { value => {
            props.evaluationChangeHandler({
              ...evaluation,
              timeManagement: value,
            })
          }}
        />
      </Td120>
      <Td120>
        <SelectEvaluation
          disabled = { props.disabled }
          value = { evaluation.quality }
          options = { evaluationOptions }
          changeHandler = { value => {
            props.evaluationChangeHandler({
              ...evaluation,
              quality: value,
            })
          }}
        />
      </Td120>
      <Td120>
        <SelectEvaluation
          disabled = { props.disabled }
          value = { evaluation.teamwork }
          options = { evaluationOptions }
          changeHandler = { value => {
            props.evaluationChangeHandler({
              ...evaluation,
              teamwork: value,
            })
          }}
        />
      </Td120>
      <Td120>
        <SelectEvaluation
          disabled = { props.disabled }
          value = { evaluation.communication }
          options = { evaluationOptions }
          changeHandler = { value => {
            props.evaluationChangeHandler({
              ...evaluation,
              communication: value,
            })
          }}
        />
      </Td120>
      <Td80>{ total }</Td80>
      <Td120>
        <ButtonSave
          disabled = { props.disabled }
          onClick = { props.saveHandler }
        >儲存
        </ButtonSave>
      </Td120>
    </TrWrapper>
  );
};

export default Item;
