import React from 'react';

import TableWrapper from '@/components/UI/TableWrapper';
import SelectNormal from '@/components/UI/Select/SelectNormal';
import SelectSearchable from '@/components/UI/Select/SelectSearchable';
import { InputBasic } from '@/component-style/Input';
import { PSubtitle } from '@/component-style/TextElement';
import {
  DivFlexRow,
  DivItemWrapper,
  DivFullWidthWrapper,
  DivLongMultiSelector,
} from '@/component-style/RWDWrapper';

import MyInputNumber from '@/components/UI/MyInputNumber';

// import { integerConverter } from '@/utils/textUtils';

const PanelExpenseQuotationDetail = props => {

  const { quotation } = props;

  // const isFinanceBargain = integerConverter(quotation.quotedPrice) >= 100000;

  return (
    <TableWrapper title = { props.title }>
      <DivFlexRow>
        <DivFullWidthWrapper>
          <PSubtitle>
            專案代號
            <span>*</span>
          </PSubtitle>
          <DivLongMultiSelector>
            <SelectSearchable
              disabled = { props.disabled || props.disableProjectID }
              value = { quotation.projectID }
              options = {[
                { value: '', desc: '請選擇專案代號' },
                ...props.projectIDOptions,
              ]}
              changeHandler = { value => {
                props.updateQuotationHandler('projectID', value);
              }}
            />
          </DivLongMultiSelector>
        </DivFullWidthWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>
            廠商名稱
            <span>*</span>
          </PSubtitle>
          <SelectSearchable
            disabled = { props.disabled }
            value = { quotation.vendorID }
            options = {[
              { value: '', desc: '請選擇廠商名稱' },
              ...props.vendorIDs,
            ]}
            changeHandler = { value => {
              props.updateQuotationHandler('vendorID', value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>報價種類</PSubtitle>
          <SelectNormal
            disabled = { props.disabled }
            value = { quotation.quotationType }
            options = {[
              { value: 'project', desc: '專案報價' },
              { value: 'hourly', desc: '時薪' },
            ]}
            changeHandler = { value => {
              props.quotationTypeChangeHandler(value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>時薪</PSubtitle>
          <InputBasic
            type = "number"
            disabled = { props.disabled || quotation.quotationType === 'project' }
            value = { quotation.hourlyRate }
            onChange = { ({ target }) => {
              props.updateQuotationHandler('hourlyRate', target.value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>服務說明</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { quotation.note }
            onChange = { ({ target }) => {
              props.updateQuotationHandler('note', target.value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>報價金額(含稅)</PSubtitle>
          <MyInputNumber
            disabled = { props.disabled }
            value = { quotation.quotedPrice }
            onChange = { value => {
              console.log("PanelExpenseQuotationDetail value:" + value);
              props.quotedPriceChangeHandler(value);
            }}
          />
          {/* <InputBasic
            type = "number"
            disabled = { props.disabled }
            value = { quotation.quotedPrice }
            onChange = { ({ target }) => {
              props.quotedPriceChangeHandler(target.value);
            }}
          /> */}
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>議價後金額(含稅)</PSubtitle>
          <MyInputNumber
            disabled = { props.disabled }
            value = { quotation.price }
            onChange = { value => {
              props.updateQuotationHandler('price', value);
            }}
          />
          {/* <InputBasic
            type = "number"
            disabled = { props.disabled }
            // disabled = { props.disabled || isFinanceBargain }
            value = { quotation.price }
            onChange = { ({ target }) => {
              props.updateQuotationHandler('price', target.value);
            }}
          /> */}
        </DivItemWrapper>
      </DivFlexRow>
      { props.children }
    </TableWrapper>
  );
};

export default PanelExpenseQuotationDetail;
