import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Table, TableBody } from '@/component-style/Table';
import PageSelector from '@/components/UI/PageSelector';
import Header from './Header.js';
import Item from './Item.js';

const DivWrapper = styled.div`
  width: 100%;
  border-top: 2px solid #de2027;
  background-color: #ffffff;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
`;

const TitleWrapper = styled.div`
  margin-top: 35px;
  margin-bottom: 20px;
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const H3Title = styled.h3`
  margin: 0;
  font-size: 20px;
  font-weight: normal;
  color: #57585a;
`;

const TablePunchTimes = props => {
  const history = useHistory();
  const attendanceRoutes = useSelector(state => state.login.attendanceRoutes);
  const disableDetailPage = attendanceRoutes.indexOf('PunchTimeDetail') === -1;

  const { punchTimes } = props;

  return (
    <DivWrapper>
      <TitleWrapper>
        <H3Title>打卡記錄列表</H3Title>
      </TitleWrapper>
      <Table>
        <Header/>
        <TableBody>
          {
            punchTimes.map(punchTime =>
              <Item
                key = { punchTime.attendanceID }
                punchTime = { punchTime }
                disableDetailPage = { disableDetailPage }
                goDetailHandler = {() => {
                  history.push(`/Attendance/PunchTimeDetail/${ punchTime.userID }/${punchTime.date}`);
                }}
              />
            )
          }
        </TableBody>
      </Table>
      <PageSelector
        current = { props.currentPage }
        total = { props.totalCount }
        pageChangedHandler = { props.pageChangedHandler }
      />
    </DivWrapper>
  );
};

export default TablePunchTimes;


