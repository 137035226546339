import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

// import { postToGetProjectDetail } from '@/api/projectService';
// import { postToGetUploadFiles } from '@/api/storageService';
// import { postToGetProjectQuotations } from '@/api/financeService';

import {
  postToGetVenueDetail,
  postToGetVenueReservations,
  postToGetReservationDetail,
} from '@/api/venueService';

import { FETCH_PROJECT_ID_OPTIONS } from '@/store/actions/srpOptionsAction';

import MainContent from '@/component-style/MainContent';
import PanelWrapper from '@/components/UI/PanelWrapper';
import SelectSearchable from '@/components/UI/Select/SelectSearchable';
import SearchButtonSet from '@/components/UI/SearchButtonSet';
import TableVenueReservations from '@/components/Table/TableVenueReservations';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
// import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';

import { ButtonBasic } from '@/component-style/Button';
import { PSubtitle } from '@/component-style/TextElement';
import {
  DivFlexRow,
  DivFullWidthWrapper,
  DivLongMultiSelector,
} from '@/component-style/RWDWrapper';

import { getMultiplyResult } from '@/utils/mathUtils';
// import { diffTime } from '@/utils/timeUtils';

const TableContainer = styled.div`
  margin-top: 40px;
`;

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding: 0;
  justify-content: space-between;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const ButtonAdd = styled(ButtonBasic)`
  margin-right: 10px;
  width: 120px;

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`;

// const ButtonExport = styled(ButtonBasic)`
//   width: 120px;

//   @media (max-width: 767px) {
//     margin-bottom: 10px;
//   }
// `;

const VenueReservations = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const projectRoutes = useSelector(state => state.login.projectRoutes);
  const projectIDOptions = useSelector(state => state.srpOptions.projectIDOptions);

  const [reservations, setReservations] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [searchConfig, setSearchConfig] = useState({
    projectID: '',
    amount: 10,
  });

  // const [alertText, setAlertText] = useState('');
  // const [showAlertPopup, setShowAlertPopup] = useState(false);
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);

  const userAddable = projectRoutes.indexOf('VenueReservationCreation') !== -1;
  // const userExportable = projectRoutes.indexOf('VenueReservations-Export') !== -1;

  const searchHandler = async () => {
    setShowLoadingPopup(true);
    // const { projectID } = searchConfig;

    try {
      // const projectData = await postToGetProjectDetail(projectID);
      // const projectFinanceFiles = await postToGetUploadFiles('projectFinance', projectID);
      // const projectQuotations = await postToGetProjectQuotations({
      //   projectID, amount: 1, page: 1,
      // });

      // const unlocked = projectData.data.projectUnlock !== null
      //   && projectData.data.projectUnlock !== undefined
      //   && diffTime(projectData.data.projectUnlock, Date.now(), 'days') <= 1;

      // const notQuoted = projectQuotations.data.totalCount === undefined
      //   && diffTime(projectData.data.actionTimeEnd, Date.now(), 'days') < 8;

      // const noPO = projectFinanceFiles.data.length === 0
      //   && diffTime(projectData.data.actionTimeEnd, Date.now(), 'days') < 4;

      // const isProjectLocked = !unlocked && (noPO || notQuoted);

      // if (isProjectLocked) {
      //   const alertText = `
      //     ${ noPO ? '無 PO 資料，' : '' }
      //     ${ notQuoted ? '無報價資料，' : '' }
      //     專案已被鎖定，請專案總監或總經理解鎖。
      //   `;
      //   setShowLoadingPopup(false);
      //   setAlertText(alertText);
      //   setShowAlertPopup(true);
      //   return
      // }

      setCurrentPageHandler(1);
    } catch (err) {
      console.log(err);
      setShowLoadingPopup(false);
    }
  };

  const setCurrentPageHandler = page => {
    setCurrentPage(page);
    updateVenues(page);
  };

  const clearHandler = () => {
    setReservations([]);
    setSearchConfig(prevState =>({ ...prevState, projectID: '' }));
  };

  const updateVenues = async (page = currentPage) => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetVenueReservations({ ...searchConfig, page });

      const reservationList = [];
      const { venueReservations, totalCount } = data;

      for (let i = 0; i < venueReservations.length; i += 1) {
        const { venueID, venueReservationID } = venueReservations[i];

        const venue = await postToGetVenueDetail(venueID);
        const reservation = await postToGetReservationDetail(venueReservationID);
        const { venueFees, mealFees, deviceFees } = reservation.data;


        const totalVenueFees = Object.keys(venueFees)
          .map(key => venueFees[key])
          .map(({ unit, unitPrice }) => getMultiplyResult(unit, unitPrice))
          .reduce((acc, cur) => acc + cur, 0);

        const totalMealFees = mealFees
          .map(({ unit, unitPrice }) => getMultiplyResult(unit, unitPrice))
          .reduce((acc, cur) => acc + cur, 0);

        const totalDeviceFees = deviceFees
          .map(({ unit, unitPrice }) => getMultiplyResult(unit, unitPrice))
          .reduce((acc, cur) => acc + cur, 0);


        reservationList.push({
          venueID,
          venueReservationID,
          name: venue.data.name,
          room: venue.data.basicInfo.name,
          phone: venue.data.phone,
          budget: totalVenueFees + totalMealFees + totalDeviceFees,
          note: reservation.data.note || '',
          used: reservation.data.used === true,
          contact: reservation.data.contact,
          reservations: [...reservation.data.reservations],
        })
      }

      setReservations([ ...reservationList ]);
      setTotalCount(totalCount || 0);
    } catch(err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const initOptions = useCallback(() => {
    dispatch(FETCH_PROJECT_ID_OPTIONS());
  }, [dispatch]);

  useEffect(() => {
    initOptions();
  }, [initOptions])

  return (
    <MainContent>
      <PanelWrapper title = "專案活動場地">
        <DivFlexRow>
          <DivFullWidthWrapper>
            <PSubtitle>專案代號</PSubtitle>
            <DivLongMultiSelector>
              <SelectSearchable
                value = { searchConfig.projectID }
                options = {[
                  { value: '', desc: '請選擇專案代號' },
                  ...projectIDOptions
                ]}
                changeHandler = { value => {
                  setSearchConfig(prevState =>({
                    ...prevState,
                    projectID: value,
                  }));
                }}
              />
            </DivLongMultiSelector>
          </DivFullWidthWrapper>
        </DivFlexRow>
        <DivFlexRow>
          <DivButtonsWrapper>
            <div>
              <ButtonAdd
                disabled = { !userAddable }
                onClick = { () => {
                  history.push('/Project/VenueReservationCreation/')
                }}
              >新增</ButtonAdd>
            </div>
            <SearchButtonSet
              searchable = { searchConfig.projectID !== '' }
              clearHandler = { clearHandler }
              searchHandler = { searchHandler }
            />
          </DivButtonsWrapper>
        </DivFlexRow>
      </PanelWrapper>
      <TableContainer>
        <TableVenueReservations
          reservations = { reservations }
          totalCount = { totalCount }
          currentPage = { currentPage }
          pageChangedHandler = { value => {
            setCurrentPageHandler(value);
          }}
        />
      </TableContainer>
      { showLoadingPopup && <PopupLoading/> }
      {/* {
        showAlertPopup &&
          <PopupExecuteResult
            text = { alertText }
            confirmHandler = { () => { setShowAlertPopup(false) } }
          />
      } */}
    </MainContent>
  );
};

export default VenueReservations;
