import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import TableWrapper  from '@/components/UI/TableWrapper';
import PageSelector from '@/components/UI/PageSelector';
import { Table, TableBody } from '@/component-style/Table';
import Header from './Header';
import Item from './Item';

const TableSalaryOvertimeList = props => {
  // const history = useHistory();
  // const financeRoutes = useSelector(state => state.login.financeRoutes);
  // const disableDetailPage = financeRoutes.indexOf('FinanceApprovalDetail') === -1;

  const { overtimeDetail } = props;
  console.log(`TableSalaryOvertimeList index.js JSON.stringify(overtimeDetail)=${JSON.stringify(overtimeDetail)}`);

  return (
    <TableWrapper title = "同仁加班列表">
      <Table>
        <Header/>
        <TableBody>
          {
            overtimeDetail !== undefined && overtimeDetail.map((overtime, index) =>
              <Item
                key = { overtime.id }
                overtime = { overtime }
                // disableDetailPage = { disableDetailPage }
                goDetailHandler = { (userId) => {
                  props.editDetailHandler(userId);
                }}
              />
            )
          }
        </TableBody>
      </Table>
      <PageSelector
        current = { props.currentPage }
        total = { props.totalCount }
        pageChangedHandler = { props.pageChangedHandler }
      />
    </TableWrapper>
  );
};

export default TableSalaryOvertimeList;
