import React, { useCallback, useEffect, useState, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { putNewQuotation } from '@/api/expenseService';
import { postToGetProjectDetail } from '@/api/projectService';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import {
  FETCH_VENDOR_ID_OPTIONS,
  FETCH_PROJECT_ID_OPTIONS,
} from '@/store/actions/srpOptionsAction';

import MainContent from '@/component-style/MainContent';
import PanelExpenseQuotationDetail from '@/components/Panel/Expense/PanelExpenseQuotationDetail';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import { ButtonBasic, ButtonCancel } from '@/component-style/Button';
import { DivFlexRow } from '@/component-style/RWDWrapper';

import { integerConverter } from '@/utils/textUtils';
// import { diffTime, isTimeAfter } from '@/utils/timeUtils';
import { expenseQuotationConfig } from '@/constant/dataConfig/expenseModule';

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding-right: 0;
  padding-bottom: 30px;
  justify-content: flex-end;

  @media (max-width: 425px) {
    justify-content: center;
    padding-left: 0;
  }
`;

const ButtonSave = styled(ButtonBasic)`
  width: 120px;
  margin-right: 10px;
  margin-bottom: 10px;
`;

const ButtonClear = styled(ButtonCancel)`
  width: 120px;
  margin-bottom: 10px;
  margin-right: 10px;

  &:last-child { margin-right: 0 }
`;

const ExpenseQuotationCreation = props => {
  const history = useHistory();
  const dispatch = useDispatch();
  const userID = useSelector(state => state.login.token);
  const vendorIDs = useSelector(state => state.srpOptions.vendorIDOptions);
  const projectIDOptions = useSelector(state => state.srpOptions.projectIDOptions);
  const showSaveFailPopup = useSelector(state => state.apiFail.showSaveFailPopup);
  const apiFailPopupTitle = useSelector(state => state.apiFail.title);
  const apiFailPopupMessage = useSelector(state => state.apiFail.message);

  const [quotation, setQuotation] = useState({ ...expenseQuotationConfig });

  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [showSaveSuccessPopup, setShowSaveSuccessPopup] = useState(false);
  const [showFinanceLockedPopup ,setShowFinanceLockedPopup] = useState(false);

  const updateQuotationHandler = (key, value) => {
    const updateObj = { ...quotation };
    updateObj[key] = value;
    setQuotation({ ...updateObj });
  };

  const quotationTypeChangeHandler = value => {
    setQuotation(prevState => ({
      ...prevState,
      quotationType: value,
      hourlyRate: '',
    }));
  };

  const quotedPriceChangeHandler = value => {
    const price = integerConverter(value) >= 50000 ? '' : value;

    setQuotation(prevState => ({
      ...prevState,
      quotedPrice: value,
      price,
    }));
  };

  const clearHandler = () => {
    setQuotation(prevState => ({
      ...expenseQuotationConfig,
      projectID: prevState.projectID,
    }));
  };

  const closeSuccessPopupHandler = () => {
    clearHandler();
    setShowSaveSuccessPopup(false);
  };

  const initOptions = useCallback(() => {
    dispatch(FETCH_VENDOR_ID_OPTIONS({ available: true }));
    dispatch(FETCH_PROJECT_ID_OPTIONS());
  }, [dispatch]);

  const postNewQuotation = async () => {
    setShowLoadingPopup(true);

    try {
      const projectDetail = await postToGetProjectDetail(quotation.projectID);
      console.log("postNewQuotation() projectDetail:" + JSON.stringify(projectDetail) + "projectDetail.data.done:" + projectDetail.data.done);
      if (projectDetail.data.done === true) {
        return setShowFinanceLockedPopup(true)
      }
      // const { actionTimeStart, financeUnlock } = projectDetail.data;

      // const isAfterAction = isTimeAfter(Date.now(), actionTimeStart);
      // const isFinanceLocked = financeUnlock === undefined
      //   ? isAfterAction
      //   : isAfterAction && diffTime(financeUnlock, Date.now(), 'days') > 1;

      // if (isFinanceLocked) {
      //   setShowLoadingPopup(false);
      //   return setShowFinanceLockedPopup(true)
      // }

      await putNewQuotation({
        ...quotation,
        user: userID,
        price: integerConverter(quotation.price),
        quotedPrice: integerConverter(quotation.quotedPrice),
        hourlyRate: integerConverter(quotation.hourlyRate),
      });
      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '儲存失敗', err }));
      console.log(err);
    }
  };

  useEffect(() => {
    initOptions();
  }, [initOptions]);

  return (
    <MainContent>
      <PanelExpenseQuotationDetail
        title = "新增採購項目"
        quotation = { quotation }
        vendorIDs = { vendorIDs }
        projectIDOptions = { projectIDOptions }
        updateQuotationHandler = { updateQuotationHandler }
        quotedPriceChangeHandler = { quotedPriceChangeHandler }
        quotationTypeChangeHandler = { quotationTypeChangeHandler }
      >
      {
        <DivFlexRow>
          <DivButtonsWrapper>
            <ButtonSave
              onClick = { postNewQuotation }
            >儲存</ButtonSave>
            <ButtonClear
              onClick = { clearHandler }
            >清除欄位</ButtonClear>
            <ButtonClear
              onClick = { () => { history.goBack() } }
            >離開</ButtonClear>
          </DivButtonsWrapper>
        </DivFlexRow>
      }
      </PanelExpenseQuotationDetail>
      { showLoadingPopup && <PopupLoading/> }
      {
        showFinanceLockedPopup &&
          <PopupExecuteResult
            text = "此專案已結案!"
            confirmHandler = { () => { setShowFinanceLockedPopup(false) } }
          />
      }
      {
        showSaveSuccessPopup &&
          <PopupExecuteResult
            title = "儲存成功"
            confirmHandler = { closeSuccessPopupHandler }
          />
      }
      {
        showSaveFailPopup &&
          <PopupExecuteResult
            title = { apiFailPopupTitle }
            text = { apiFailPopupMessage }
            confirmHandler = { () => {
              dispatch(CLOSE_SAVE_FAIL_POPUP());
            }}
          />
      }
    </MainContent>
  )
};

export default ExpenseQuotationCreation;
