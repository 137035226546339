import React from 'react';

import { Table, TableBody } from '@/component-style/Table';
import TableWrapper  from '@/components/UI/TableWrapper';
import PageSelector from '@/components/UI/PageSelector';
import Header from './Header';
import Item from './Item';

const TableRevenueByCustomerReceived = props => {
  const { vouchers } = props;

  return (
    <TableWrapper title = '各組預收'>
      <Table>
        <Header/>
        <TableBody>
          {
            vouchers.map((voucher, index) =>
              <Item
                key = { index }
                voucher = { voucher }
              />
            )
          }
        </TableBody>
      </Table>
      <PageSelector
        current = { props.currentPage }
        total = { props.totalCount }
        pageChangedHandler = { props.pageChangedHandler }
      />
    </TableWrapper>
  );
};

export default TableRevenueByCustomerReceived;
