import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { cloneDeep } from 'lodash';

import {
  putNewVendorType,
  deleteVendorTypes,
  postToGetVendorTypes,
  patchModifyVendorType,
} from '@/api/vendorService';

import { FETCH_VENDOR_TYPE_OPTIONS } from '@/store/actions/srpOptionsAction';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import MainContent from '@/component-style/MainContent';
import PanelWrapper from '@/components/UI/PanelWrapper';
import TableVendorTypes from '@/components/Table/SysConfig/TableVendorTypes';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import PopupExecuteConfirm from '@/components/UI/Popup/PopupExecuteConfirm';
import PopupTypeDetail from '@/components/UI/Popup/PopupTypeDetail';
import SelectSearchable from '@/components/UI/Select/SelectSearchable';
import SearchButtonSet from '@/components/UI/SearchButtonSet';
import { PSubtitle } from '@/component-style/TextElement';
import { ButtonBasic } from '@/component-style/Button';
import {
  DivFlexRow,
  DivFullWidthWrapper,
  DivLongMultiSelector,
} from '@/component-style/RWDWrapper';

import { searchConfigGenerator } from '@/utils/apiRequestUtils';
import { vendorTypeConfig } from '@/constant/dataConfig/sysConfigModule';

const DivPanelContainer = styled.div`
  margin-bottom: 40px;
`;

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding: 0;
  justify-content: space-between;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const ButtonAdd = styled(ButtonBasic)`
  margin-right: 10px;
  width: 120px;

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`;

const VendorTypeManagement = () => {
  const dispatch = useDispatch();
  const sysConfigRoutes = useSelector(state => state.login.sysConfigRoutes);
  const vendorTypeOptions = useSelector(state => state.srpOptions.vendorTypeOptions);
  const showSaveFailPopup = useSelector(state => state.apiFail.showSaveFailPopup);
  const apiFailPopupTitle = useSelector(state => state.apiFail.title);
  const apiFailPopupMessage = useSelector(state => state.apiFail.message);

  const [types, setTypes] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [vendorTypeID, setVendorTypeID] = useState('');
  const [selectedType, setSelectedType] = useState(cloneDeep(vendorTypeConfig));

  const [isAdding, setIsAdding] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [showTypeDetailPopup, setShowTypeDetailPopup] = useState(false);
  const [showSaveSuccessPopup, setShowSaveSuccessPopup] = useState(false);
  const [showDeleteSuccessPopup, setShowDeleteSuccessPopup] = useState(false);

  const userAddable = sysConfigRoutes.indexOf('VendorTypeManagement-Add') !== -1;
  const userEditable = sysConfigRoutes.indexOf('VendorTypeManagement-Edit') !== -1;
  const userDeletable = sysConfigRoutes.indexOf('VendorTypeManagement-Delete') !== -1;

  const selectedItemNumber = types
    .filter(type => type.isChecked).length;

  const addVendorTypeHandler = () => {
    setIsAdding(true);
    setSelectedType(cloneDeep(vendorTypeConfig));
    setShowTypeDetailPopup(true);
  };

  const updateTypeDetailHandler = (key, value) => {
    const updateObj = { ...selectedType };
    updateObj[key] = value;
    setSelectedType({ ...updateObj });
  };

  const clearHandler = () => {
    setVendorTypeID('');
    setTypes([]);
  };

  const checkedHandler = (index, value) => {
    const updateTypes = [...types];
    updateTypes[index].isChecked = value;
    setTypes(updateTypes);
  };

  const selectAllHandler = value => {
    setSelectAll(value);
    const updateTypes = types
      .map(type => ({ ...type, isChecked: value }));
    setTypes(updateTypes);
  };

  const showTypeDetailHandler = index => {
    const selectedType = types[index];
    setSelectedType(cloneDeep(selectedType));
    setShowTypeDetailPopup(true);
  };

  const pageChangedHandler = page => {
    selectAllHandler(false);
    setCurrentPageHandler(page);
  };

  const setCurrentPageHandler = page => {
    setCurrentPage(page);
    fetchTypes(page);
  };

  const searchHandler = () => {
    setCurrentPageHandler(1);
  };

  const apiFailConfirmHandler = () => {
    dispatch(CLOSE_SAVE_FAIL_POPUP());

    apiFailPopupTitle === '儲存失敗'
      && setShowTypeDetailPopup(true);
  };

  const closeSaveSuccessPopupHandler = () => {
    setIsAdding(false);
    setShowSaveSuccessPopup(false);
    searchHandler();
  };

  const closeDetailPopupHandler = () => {
    setIsAdding(false);
    setShowTypeDetailPopup(false);
  };

  const updateVendorTypeHandler = async () => {
    setShowTypeDetailPopup(false);
    setShowLoadingPopup(true);

    try {
      isAdding
        ? await putNewVendorType(selectedType)
        : await patchModifyVendorType(selectedType)

      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
      dispatch(FETCH_VENDOR_TYPE_OPTIONS());
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '儲存失敗', err }));
      console.log(err);
    }
  };

  const deleteVendorTypeHandler = async () => {
    setShowDeletePopup(false);
    setShowLoadingPopup(true);

    try {
      const selectedTypes = types
        .filter(type => type.isChecked)
        .map(type => type.vendorTypeID);

      await deleteVendorTypes(selectedTypes);
      setShowLoadingPopup(false);
      setShowDeleteSuccessPopup(true);
      dispatch(FETCH_VENDOR_TYPE_OPTIONS());
    } catch(err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '刪除失敗', err }));
      console.log(err);
    }
  };

  const fetchTypes = async (page = currentPage) => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetVendorTypes(
        searchConfigGenerator({
          vendorTypeID, amount: 10, page
        })
      );

      const { types, totalCount } = data;
      const vendorTypes = types.map(type => ({
        ...type,
        isChecked: false,
        enable: type.enable || false,
      }));

      setTypes(vendorTypes);
      setTotalCount(totalCount || 0);
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const initTypes = useCallback(
    fetchTypes, []);
  
  const initOptions = useCallback(() => {
    dispatch(FETCH_VENDOR_TYPE_OPTIONS());
  }, [dispatch]);

  useEffect(() => {
    initTypes();
    initOptions();
  }, [initOptions, initTypes]);

  return (
    <MainContent>
      <DivPanelContainer>
        <PanelWrapper title = "廠商分類管理">
          <DivFlexRow>
            <DivFullWidthWrapper>
              <PSubtitle>廠商大分類</PSubtitle>
              <DivLongMultiSelector>
                <SelectSearchable
                  value = { vendorTypeID }
                  options = {[
                    { value: '', desc: '請選擇廠商大分類' },
                    ...vendorTypeOptions,
                  ]}
                  changeHandler = { setVendorTypeID }
                />
              </DivLongMultiSelector>
            </DivFullWidthWrapper>
          </DivFlexRow>
          <DivFlexRow>
            <DivButtonsWrapper>
              <ButtonAdd
                disabled = { !userAddable }
                onClick = { addVendorTypeHandler }
              >新增</ButtonAdd>
              <SearchButtonSet
                clearHandler = { clearHandler }
                searchHandler = { searchHandler }
              />
            </DivButtonsWrapper>
          </DivFlexRow>
        </PanelWrapper>
      </DivPanelContainer>
      <TableVendorTypes
        types = { types }
        selectAll = { selectAll }
        totalCount = { totalCount }
        currentPage = { currentPage }
        checkHandler = { checkedHandler }
        disableDelete = { selectedItemNumber === 0 || !userDeletable }
        selectAllHandler = { selectAllHandler }
        deleteHandler = { () =>  { setShowDeletePopup(true) }}
        pageChangedHandler = { pageChangedHandler }
        showTypeDetailHandler = { showTypeDetailHandler }
      />
      { showLoadingPopup && <PopupLoading/> }
      {
        showTypeDetailPopup &&
          <PopupTypeDetail
            title = "廠商大分類"
            type = { selectedType }
            disableName = { !isAdding }
            disableSave = { isAdding ? false : !userEditable }
            confirmHandler = { updateVendorTypeHandler }
            updateTypeDetailHandler = { updateTypeDetailHandler }
            cancelHandler = { closeDetailPopupHandler }
          />
      }
      {
        showDeletePopup &&
          <PopupExecuteConfirm
            title = "刪除廠商分類"
            text = { `確定刪除${ selectedItemNumber }個項目` }
            confirmHandler = { deleteVendorTypeHandler }
            cancelHandler = { () => {
              setShowDeletePopup(false);
            }}
          />
      }
      {
        showDeleteSuccessPopup &&
          <PopupExecuteResult
            title = "刪除成功"
            confirmHandler = { () => {
              setShowDeleteSuccessPopup(false);
              searchHandler();
            }}
          />
      }
      {
        showSaveSuccessPopup &&
          <PopupExecuteResult
            title = "儲存成功"
            confirmHandler = { closeSaveSuccessPopupHandler }
          />
      }
      {
        showSaveFailPopup &&
          <PopupExecuteResult
            title = { apiFailPopupTitle }
            text = { apiFailPopupMessage }
            confirmHandler = { apiFailConfirmHandler }
          />
      }
    </MainContent>
  );
};

export default VendorTypeManagement;
