import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { putProjectSetStatus } from '@/api/projectService';

import { FETCH_PROJECT_ID_OPTIONS } from '@/store/actions/srpOptionsAction';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import MainContent from '@/component-style/MainContent';
import PanelWrapper from '@/components/UI/PanelWrapper';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import PopupExecuteConfirm from '@/components/UI/Popup/PopupExecuteConfirm';
import SelectSearchable from '@/components/UI/Select/SelectSearchable';
import { ButtonBasic } from '@/component-style/Button';
import { PSubtitle } from '@/component-style/TextElement';
import {
  DivFlexRow,
  DivFullWidthWrapper,
  DivLongMultiSelector,
} from '@/component-style/RWDWrapper';

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding: 0;
  justify-content: flex-end;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const ButtonExecute = styled(ButtonBasic)`
  width: 120px;
`;

const ProjectReopen = props => {
  const dispatch = useDispatch();
  const projectIDList = useSelector(state => state.srpOptions.projectIDOptions);
  const showSaveFailPopup = useSelector(state => state.apiFail.showSaveFailPopup);
  const apiFailPopupTitle = useSelector(state => state.apiFail.title);
  const apiFailPopupMessage = useSelector(state => state.apiFail.message);

  const [projectID, setProjectID] = useState('');
  const [showPopupDelete, setShowPopupDelete] = useState(false);
  const [showPopupLoading, setShowPopupLoading] = useState(false);
  const [showPopupReopenSuccess, setShowPopupReopenSuccess] = useState(false);

  const initList = useCallback(() => {
    setProjectID('');
    dispatch(FETCH_PROJECT_ID_OPTIONS({ done: true }));
  }, [dispatch]);

  const reopenProjectHandler = async () => {
    setShowPopupDelete(false);
    setShowPopupLoading(true);

    try {
      await putProjectSetStatus({ projectID, done: false });
      setShowPopupLoading(false);
      setShowPopupReopenSuccess(true);
    } catch (err) {
      console.log(err);
      setShowPopupLoading(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '更新失敗', err }));
    }
  };

  useEffect(() => {
    initList();
  }, [initList])

  return (
    <MainContent>
      <PanelWrapper title = "取消專案結案">
        <DivFlexRow>
          <DivFullWidthWrapper>
            <PSubtitle>專案代號</PSubtitle>
            <DivLongMultiSelector>
              <SelectSearchable
                value = { projectID }
                options = {[
                  { value: '', desc: '請選擇專案代碼' },
                  ...projectIDList,
                ]}
                changeHandler = { setProjectID }
              />
            </DivLongMultiSelector>
          </DivFullWidthWrapper>
        </DivFlexRow>
        <DivFlexRow>
          <DivButtonsWrapper>
            <ButtonExecute
              disabled = { projectID === '' }
              onClick = { () => { setShowPopupDelete(true) } }
            >取消結案</ButtonExecute>
          </DivButtonsWrapper>
        </DivFlexRow>
      </PanelWrapper>
      { showPopupLoading && <PopupLoading/> }
      {
        showPopupDelete &&
          <PopupExecuteConfirm
            title = "取消專案結案"
            text = "是否確定取消結案?"
            cancelHandler = { () => { setShowPopupDelete(false) } }
            confirmHandler = { reopenProjectHandler }
          />
      }
      {
        showPopupReopenSuccess &&
          <PopupExecuteResult
            title = "更新成功"
            confirmHandler = {() => {
              setShowPopupReopenSuccess(false);
              initList();
            }}
          />
      }
      {
        showSaveFailPopup &&
          <PopupExecuteResult
            title = { apiFailPopupTitle }
            text = { apiFailPopupMessage }
            confirmHandler = { () => {
              dispatch(CLOSE_SAVE_FAIL_POPUP());
            }}
          />
      }
    </MainContent>
  );
};

export default ProjectReopen;
