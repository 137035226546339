import styled from 'styled-components';

export const DivPopupWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
`;

export const DivPopup = styled.div`
  max-height: 80vh;
  overflow-y: auto;
  border-radius: 8px;
  padding: 30px;
  width: 480px;
  box-sizing: border-box;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;

  @media (max-width: 480px) {
    width: 300px;
  }
`;

export const DivPopupLarge = styled.div`
  max-height: 80vh;
  overflow-y: auto;
  border-radius: 8px;
  padding: 30px;
  width: 1380px;
  box-sizing: border-box;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
`;

export const DivPopupXLarge = styled.div`
  max-height: 80vh;
  overflow-y: auto;
  border-radius: 8px;
  padding: 30px;
  width: 1500px;
  box-sizing: border-box;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
`;
