import React from 'react';
import styled from 'styled-components';

import { TableHead, TableRow, ThItem } from '../../../component-style/Table';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';

const THead = styled(TableHead)`
  min-width: ${ props => props.showDetailButton
    ? '1000px'
    : '920px'
  };
`;

const Th80 = styled(ThItem)`
  width: 80px;
`;

const Th120 = styled(ThItem)`
  width: 120px;
`;

const Cursor = styled(ThItem)`
  cursor: pointer;
`;

const Th160 = styled(ThItem)`
  width: 160px;
`;

const Header = props => {
  
  return (
    <THead
      showDetailButton = { props.showDetailButton }
    >
      <TableRow>
        <Th120 
          onClick = { () => props.tableSortHandler("companyType") }
        >
          <Cursor>
            所屬公司
            { props.getTableSortIcon('companyType') }
            </Cursor>
        </Th120>
        <Th120
          onClick = { () => props.tableSortHandler("projectCode") }
        >
          <Cursor>
            專案代號
            { props.getTableSortIcon('projectCode') }
          </Cursor>
        </Th120>
        <Th160>專案名稱</Th160>
        <Th120
          onClick = { () => props.tableSortHandler("actionTimeStart") }
        >
          <Cursor>
            活動日期
            { props.getTableSortIcon('actionTimeStart') }
          </Cursor>
        </Th120>
        <Th80>財務狀況</Th80>
        <Th80>報價</Th80>
        <Th80>核可</Th80>
        <Th80>請款</Th80>
        <Th80>入帳</Th80>
        {
          props.showDetailButton &&
          <Th80></Th80>
        }
      </TableRow>
    </THead>
  );
};

export default Header;
