import axiosConfig from './axiosConfig';
import { getDefaultPort } from '@/utils/apiRequestUtils';

const { baseURL } = axiosConfig.defaults;

const port = process.env.REACT_APP_SERVICE_PORT_MEDIA !== undefined
  ? process.env.REACT_APP_SERVICE_PORT_MEDIA
  : getDefaultPort(baseURL);

const mediaModuleUrl = process.env.NODE_ENV === 'development'
  ? `${baseURL}:${port}/api`
  : `${baseURL}/media/api`;

export const postToGetMediaTypes = config => axiosConfig
  .post(`${mediaModuleUrl}/media/types`, config);

export const putNewMediaType = config => axiosConfig
  .put(`${mediaModuleUrl}/media/types`, config);

export const patchMediaType = config => axiosConfig
  .patch(`${mediaModuleUrl}/media/types`, config);

export const deleteMediaType = ids => axiosConfig
  .delete(`${mediaModuleUrl}/media/types`, { data: [...ids] });

export const getMediaIDs = () => axiosConfig
  .get(`${mediaModuleUrl}/media/mediaIDs`);

export const postToGetMedias = config => axiosConfig
  .post(`${mediaModuleUrl}/media`, config);

export const putNewMedia = media => axiosConfig
  .put(`${mediaModuleUrl}/media`, media);

export const deleteMedias = mediaIDs => axiosConfig
  .delete(`${mediaModuleUrl}/media`, { data: [ ...mediaIDs ] });

export const postToGetMediaDetail = mediaID => axiosConfig
  .post(`${mediaModuleUrl}/media/detail`, { mediaID });

export const putModifyMedia = media => axiosConfig
  .put(`${mediaModuleUrl}/media/detail`, media);

export const postToGetMediaReports = config => axiosConfig
  .post(`${mediaModuleUrl}/media/project/reports`, config);

export const putNewMediaReport = mediaReport => axiosConfig
  .put(`${mediaModuleUrl}/media/project/reports`, mediaReport);

export const deleteMediaReport = announcementID => axiosConfig
  .delete(`${mediaModuleUrl}/media/project/reports`, { data: { announcementID } });

export const postToGetMediaReportDetail = announcementID => axiosConfig
  .post(`${mediaModuleUrl}/media/project/reportDetail`, { announcementID });

export const putModifyMediaReport = mediaReport => axiosConfig
  .put(`${mediaModuleUrl}/media/project/reportDetail`, mediaReport);
