import React from 'react';
import styled from 'styled-components';

import { TableHead, TableRow, ThItem } from '@/component-style/Table';

const THead = styled(TableHead)`
  min-width: 840px;
`;

const Th120 = styled(ThItem)`
  width: 120px;
`;

const Th160 = styled(ThItem)`
  width: 160px;
`;

const Th80 = styled(ThItem)`
  width: 80px;
`;

const Header = props => {
  
  return (
    <THead>
      <TableRow>
        <Th160>生效年、月</Th160>
        <Th160>基本薪資</Th160>
        <Th160>勞、健保投保薪資</Th160>
        <Th160>勞保費</Th160>
        <Th160>健保費</Th160>
        <Th160>健保眷屬人數</Th160>
        <Th160>勞退自提</Th160>
        <Th80></Th80>
      </TableRow>
    </THead>
  );
};

export default Header;
