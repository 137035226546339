import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { cloneDeep, isNull } from 'lodash';

// import { postToGetExpenseVouchers, postToGetVouchersTotal } from '@/api/expenseService';

import { getCompanyProfit, getCompanyProfitExcel } from '@/api/financeService';

import { FETCH_COMPANY_TYPE_OPTIONS } from '@/store/actions/srpOptionsAction';

import PanelRevenueByCompanySearch from '@/components/Panel/Finance/PanelRevenueByCompanySearch';
import TableRevenueByCompany from '@/components/Table/Finance/TableRevenueByCompany';
import PopupLoading from '@/components/UI/Popup/PopupLoading';

import { formatTime } from '@/utils/timeUtils';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
// import { getOptionDesc } from '@/utils/textUtils';
// import { vendorInvoiceTypes } from '@/constant/options';
// import { searchConfigGenerator } from '@/utils/apiRequestUtils';
// import { exportExpenseVouchersFile } from '@/utils/xlsx';

// import moment from 'moment';

// import {
//   postToGetProjectPRServices,
// } from '@/api/financeService';

const DivPanelContainer = styled.div`
  margin-bottom: 40px;
`;

const searchConfig = {
  voucherPrefix: '',
  voucherCode: {
    start: '',
    end: '',
  },
  date: {
    start: null,
    end: null,
  },
  notForBusinessTax: '',
  companyType: '',
  amount: 10,
};

const RevenueByCompanySearch = () => {
  const dispatch = useDispatch();
  const companyTypeOptions = useSelector(state => state.srpOptions.companyTypeOptions);
  // console.log("RevenueByCompanySearch companyTypeOptions:" + JSON.stringify(companyTypeOptions));


  const [vouchers, setVouchers] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [config, setConfig] = useState(cloneDeep(searchConfig));

  const [showLoadingPopup, setShowLoadingPopup] = useState(false);

  const [alertText, setAlertText] = useState('');
  const [showAlertPopup, setShowAlertPopup] = useState(false);
  

  const openAlertPopup = text => {
    setAlertText(text);
    setShowAlertPopup(true);
  };

  const updateConfigHandler = (key, value) => {
    // console.log("fetchExpenseVouchers updateConfigHandler");
    const updateObj = { ...config };
    updateObj[key] = value;
    setConfig({ ...updateObj });
  };

  const clearHandler = () => {
    setVouchers([]);
    setTotalCount(0);
    setConfig(cloneDeep(searchConfig));
  };

  const searchHandler = async () => {
    setCurrentPageHandler(1);
  };

  const setCurrentPageHandler = page => {
    setCurrentPage(page);
    fetchExpenseVouchers(page);
  };

  const exportHandler = async () => {
    if (config.companyType === "" || config.companyType == null) {
      return openAlertPopup("請選擇公司");
    }
    if (config.date.start === "" || config.date.start == null) {
      return openAlertPopup("請選擇發票開始日期");
    }
    if (config.date.end === "" || config.date.end == null) {
      return openAlertPopup("請選擇發票結束日期");
    }
    
    setShowLoadingPopup(true);

    try {
      const { data } = await getCompanyProfitExcel(
        {
          startDate: formatTime(config.date.start, 'YYYY-MM-DD'),
          endDate: formatTime(config.date.end, 'YYYY-MM-DD'),
          companyTypeID: config.companyType,
        }
      );
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'CompanyProfit.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      setShowLoadingPopup(false);
      console.log(JSON.stringify(err));
    }
    setShowLoadingPopup(false);
  };

  const fetchExpenseVouchers = async (page = currentPage) => {
    // console.log("fetchExpenseVouchers config.date.start:" + config.date.start);
    if (config.companyType === "" || config.companyType == null) {
      return openAlertPopup("請選擇公司");
    }
    if (config.date.start === "" || config.date.start == null) {
      return openAlertPopup("請選擇發票開始日期");
    }
    if (config.date.end === "" || config.date.end == null) {
      return openAlertPopup("請選擇發票結束日期");
    }

    setShowLoadingPopup(true);
    console.log("fetchExpenseVouchers config:" + JSON.stringify(config));
    try {
      const { data } = await getCompanyProfit(
        {
          startDate: formatTime(config.date.start, 'YYYY-MM-DD'),
          endDate: formatTime(config.date.end, 'YYYY-MM-DD'),
          companyTypeID: config.companyType,
        }
      );
      console.log("fetchExpenseVouchers data:" + JSON.stringify(data));
      // const { vouchers } = data;
      // console.log("fetchExpenseVouchers vouchers:" + JSON.stringify(vouchers));

      setVouchers(data);
      setTotalCount(1);
    } catch(err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const initOptions = useCallback(() => {
    dispatch(FETCH_COMPANY_TYPE_OPTIONS());
  }, [dispatch]);

  useEffect(() => {
    initOptions();
  }, [initOptions]);

  return(
    <>
      <DivPanelContainer>
        <PanelRevenueByCompanySearch
          config = { config }
          companyTypeOptions = { companyTypeOptions }
          clearHandler = { clearHandler }
          exportHandler = { exportHandler }
          searchHandler = { searchHandler }
          updateConfigHandler = { updateConfigHandler }
        />
      </DivPanelContainer>
      <TableRevenueByCompany
        vouchers = { vouchers }
        totalCount = { totalCount }
        currentPage = { currentPage }
        pageChangedHandler = { setCurrentPageHandler }
      />
      { showLoadingPopup && <PopupLoading/> }
      {
        showAlertPopup &&
          <PopupExecuteResult
            title = "請選擇欄位"
            text = { alertText }
            confirmHandler = { () => { setShowAlertPopup(false) } }
          />
      }
    </>
  );
};

export default RevenueByCompanySearch;
