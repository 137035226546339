import React from 'react';
import { Route } from 'react-router-dom';

import ProjectsList from '@/containers/projectModule/projectsList';
import ProjectCreation from '@/containers/projectModule/projectCreation';
import ProjectDetail from '@/containers/projectModule/projectDetail';
import ProjectSchedule from '@/containers/projectModule/projectSchedule';
import ProjectFinance from '@/containers/projectModule/projectFinance';
// import ProjectTimeCost from '@/containers/projectModule/projectTimeCost';
import ProjectUnlock from '@/containers/projectModule/projectUnlock';
import ProjectReopen from '@/containers/projectModule/projectReopen';
import VenueReservations from '@/containers/projectModule/venueReservations';
import VenueReservationCreation from '@/containers/projectModule/venueReservationCreation';
import VenueReservationDetail from '@/containers/projectModule/venueReservationDetail';
import ScheduleCreation from '@/containers/projectModule/scheduleCreation';
import ScheduleDetail from '@/containers/projectModule/scheduleDetail';
import MemberEvaluation from '@/containers/projectModule/memberEvaluation';
import VendorEvaluation from '@/containers/projectModule/vendorEvaluation';
import ActivityIdeas from '@/containers/projectModule/activityIdeas';
import ActivityIdeaDetail from  '@/containers/projectModule/activityIdeaDetail';
import ActivityIdeaCreation from '@/containers/projectModule/activityIdeaCreation';
import MediaReports from '@/containers/projectModule/mediaReports';
import MediaReportDetail from '@/containers/projectModule/mediaReportDetail';
import MediaReportCreation from '@/containers/projectModule/mediaReportCreation';
import ReporterInvitation from '@/containers/projectModule/reporterInvitation';
import ImportSchedules from '@/containers/projectModule/importSchedules';
import ProjectFinanceInfo from '@/containers/financeModule/projectFinanceInfo';

const projectRoutes = [
  {
    key: 'ProjectsList',
    path: "/Project/ProjectsList",
    component: ProjectsList,
  },
  {
    key: 'ProjectCreation',
    path: "/Project/ProjectCreation",
    component: ProjectCreation,
  },
  {
    key: 'ProjectDetail',
    path: "/Project/ProjectDetail/:id",
    component: ProjectDetail,
  },
  {
    key: 'ProjectSchedule',
    path: "/Project/ProjectSchedule",
    component: ProjectSchedule,
  },
  {
    key: 'ProjectFinance',
    path: "/Project/ProjectFinance",
    component: ProjectFinance,
  },
  // {
  //   key: ProjectTimeCost,
  //   path: "/Project/ProjectTimeCost",
  //   component: ProjectTimeCost,
  // },
  {
    key: 'ProjectUnlock',
    path: "/Project/ProjectUnlock",
    component: ProjectUnlock,
  },
  {
    key: 'ProjectReopen',
    path: "/Project/ProjectReopen",
    component: ProjectReopen,
  },
  {
    key: 'VenueReservations',
    path: "/Project/VenueReservations",
    component: VenueReservations
  },
  {
    key: 'VenueReservationCreation',
    path: "/Project/VenueReservationCreation",
    component: VenueReservationCreation,
  },
  {
    key: 'VenueReservationDetail',
    path: "/Project/VenueReservationDetail/:id",
    component: VenueReservationDetail,
  },
  {
    key: 'ScheduleCreation',
    path: "/Project/ScheduleCreation",
    component: ScheduleCreation,
  },
  {
    key: 'ScheduleDetail',
    path: "/Project/ScheduleDetail/:id",
    component: ScheduleDetail,
  },
  {
    key: 'MemberEvaluation',
    path: "/Project/MemberEvaluation",
    component: MemberEvaluation,
  },
  {
    key: 'VendorEvaluation',
    path: "/Project/VendorEvaluation",
    component: VendorEvaluation,
  },
  {
    key: 'ActivityIdeas',
    path: "/Project/ActivityIdeas",
    component: ActivityIdeas,
  },
  {
    key: 'ActivityIdeaDetail',
    path: "/Project/ActivityIdeaDetail/:id",
    component: ActivityIdeaDetail,
  },
  {
    key: 'ActivityIdeaCreation',
    path: "/Project/ActivityIdeaCreation",
    component: ActivityIdeaCreation,
  },
  {
    key: 'MediaReports',
    path: "/Project/MediaReports",
    component: MediaReports,
  },
  {
    key: 'MediaReportDetail',
    path: "/Project/MediaReportDetail/:id",
    component: MediaReportDetail,
  },
  {
    key: 'MediaReportCreation',
    path: "/Project/MediaReportCreation",
    component: MediaReportCreation,
  },
  {
    key: 'ReporterInvitation',
    path: "/Project/ReporterInvitation",
    component: ReporterInvitation,
  },
  {
    key: 'ImportSchedules',
    path: "/Project/ImportSchedules",
    component: ImportSchedules,
  },
  {
    key: 'ProjectFinanceInfo',
    path: "/Project/ProjectFinanceInfo",
    component: ProjectFinanceInfo,
  },
];

const RoutesProjectModule = validRoutes => projectRoutes
  .filter(({ key }) => validRoutes.indexOf(key) !== -1)
  .map(({ path, component }) =>
    <Route
      key = { path }
      path = { path }
      component = { component }
    />
  );

export default RoutesProjectModule;
