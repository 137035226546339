import { applyMiddleware, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger';

import rootReducer from './reducer';
import rootSaga from './saga';

const sagaMiddleware = createSagaMiddleware();

const isDev = () => process.env.NODE_ENV === 'development';

const middlewares = isDev()
  ? [sagaMiddleware, logger]
  : [sagaMiddleware] ;

const store = createStore(
  rootReducer,
  applyMiddleware(
    ...middlewares,
  ),
);

sagaMiddleware.run(rootSaga);

export default store;