import React from 'react';
import styled from 'styled-components';

import { Table, TableBody } from '@/component-style/Table';
import SelectGray from '@/components/UI/Select/SelectGray';
import SelectSearchable from '@/components/UI/Select/SelectSearchable';
import PageSelector from '@/components/UI/PageSelector';
import Header from './Header';
import Item from './Item';
import { InputBasic } from '@/component-style/Input';
import { ButtonBasic } from '@/component-style/Button';

import { invitationConfirmStatus } from '@/constant/options';

const DivWrapper = styled.div`
  width: 100%;
  border-top: 2px solid #de2027;
  background-color: #ffffff;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
`;

const TitleWrapper = styled.div`
  margin-top: 35px;
  margin-bottom: 20px;
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const H3Title = styled.h3`
  margin: 0;
  font-size: 20px;
  font-weight: normal;
  color: #57585a;
`;

const SearchContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 0 30px;
  padding-bottom: 18px;
  flex-wrap: wrap;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const SearchWrapper = styled.div`
  margin-right: 20px;
  margin-bottom: 10px;

  input, .ant-select {
    width: 300px;
  }

  @media (min-width: 1920px) {
    input, .ant-select {
      width: 300px;
    }
  }

  @media (max-width: 320px) {
    input, .ant-select {
      width: 260px;
    }
  }

  @media (max-width: 425px) {
    margin-right: 0;
  }
`;

const InputSearch = styled(InputBasic)`
color: #4d4d4d;
background-color: #EEEEEE;
`;

const ButtonSearch = styled(ButtonBasic)`
  margin-right: 10px;
  width: 120px;
`;

const ButtonDelete = styled(ButtonBasic)`
  width: 120px;
`;

const SpanCount = styled.span`
  margin-left: 10px;
  font-size: 16px;
  font-weight: normal;
  color: #57585a;
`;

const TableInvitationReporters = props => {
  const { config } = props;

  return (
    <DivWrapper>
      <TitleWrapper>
        <H3Title>記者列表
          <SpanCount>已邀約人數: { props.totalCount }</SpanCount>
        </H3Title>
      </TitleWrapper>
      <SearchContainer>
        <SearchWrapper>
          <SelectSearchable
            value = { config.journalistID }
            options = {[
              { value: '', desc: '記者搜尋' },
              ...props.journalistIDOptions,
            ]}
            changeHandler = { value => {
              props.updateConfigHandler('journalistID', value);
            }}
          />
        </SearchWrapper>
        <SearchWrapper>
          <SelectGray
            value = { config.confirmStatus }
            options = { invitationConfirmStatus }
            changeHandler = { value => {
              props.updateConfigHandler('confirmStatus', value);
            }}
          />
        </SearchWrapper>
        <SearchWrapper>
          <InputSearch
            placeholder = "分工搜尋"
            value = { config.job }
            onChange = { ({ target }) => {
              props.updateConfigHandler('job', target.value);
            }}
          />
        </SearchWrapper>
        <ButtonSearch
          disabled = { props.searchable === false }
          onClick = { props.searchHandler }
        >搜尋</ButtonSearch>
        <ButtonDelete
          disabled = { props.deletable === false }
          onClick = { props.deleteHandler }
        >刪除選取記者</ButtonDelete>
      </SearchContainer>
      <Table>
        <Header
          selectAll = { props.selectAll }
          selectAllHandler = { props.selectAllHandler }
        />
        <TableBody>
          {
            props.journalists.map((journalist, index) =>
              <Item
                key = { journalist.invitationID }
                journalist = { journalist }
                checkHandler = { value => {
                  props.checkHandler(index, value);
                }}
                goDetailHandler = { () => {
                  props.goDetailHandler(index);
                }}
                editJournalistHandler = { () => {
                  props.editJournalistHandler(index);
                }}
              />
            )
          }
        </TableBody>
      </Table>
      <PageSelector
        current = { props.currentPage }
        total = { props.totalCount }
        pageChangedHandler = { props.pageChangedHandler }
      />
    </DivWrapper>
  );
};

export default TableInvitationReporters;
