import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { cloneDeep } from 'lodash';

import { postToGetProjectDetail } from '@/api/projectService';
import { postToGetVouchersTotal } from '@/api/expenseService';
import { postToGetDirectorBenefits } from '@/api/financeService';


import {
  FETCH_MEMBER_OPTIONS,
  FETCH_DIRECTOR_OPTIONS,
  FETCH_CUSTOMER_OPTIONS,
  FETCH_PROJECT_ID_OPTIONS,
  FETCH_COMPANY_TYPE_OPTIONS,
} from '@/store/actions/srpOptionsAction';

import MainContent from '@/component-style/MainContent';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PanelDirectorRevenueSearch from '@/components/Panel/Finance/PanelDirectorRevenueSearch';
import TableDirectorRevenues from '@/components/Table/Finance/TableDirectorRevenues';

import { formatTime } from '@/utils/timeUtils';
import { getOptionDesc } from '@/utils/textUtils';
import { searchConfigGenerator } from '@/utils/apiRequestUtils';
import { exportDirectorRevenueFile } from '@/utils/xlsx';

const DivPanelContainer = styled.div`
  margin-bottom: 40px;
`;

const searchConfig = {
  projectID: '',
  companyType: '',
  customerID: '',
  director: '',
  invoiceCode: '',
  po: '',
  mbr: {
    start: null,
    end: null,
  },
  createDate: {
    start: null,
    end: null,
  },
  amount: 10,
};

const DirectorRevenue = () => {
  const dispatch = useDispatch();
  const memberOptions = useSelector(state => state.srpOptions.memberOptions);
  const directorOptions = useSelector(state => state.srpOptions.directorOptions);
  const customerOptions = useSelector(state => state.srpOptions.customerOptions);
  const projectIDOptions = useSelector(state => state.srpOptions.projectIDOptions);
  const companyTypeOptions = useSelector(state => state.srpOptions.companyTypeOptions);

  const [benefits, setBenefits] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [config, setConfig] = useState(cloneDeep(searchConfig));

  const [showLoadingPopup, setShowLoadingPopup] = useState(false);

  const updateConfigHandler = (key, value) => {
    const updateObj = { ...config };
    updateObj[key] = value;
    setConfig({ ...updateObj });
  };

  const initOptions = useCallback(() => {
    dispatch(FETCH_MEMBER_OPTIONS());
    dispatch(FETCH_DIRECTOR_OPTIONS());
    dispatch(FETCH_CUSTOMER_OPTIONS());
    dispatch(FETCH_PROJECT_ID_OPTIONS());
    dispatch(FETCH_COMPANY_TYPE_OPTIONS());
  }, [dispatch]);

  const clearHandler = () => {
    setBenefits([]);
    setTotalCount(0);
    setConfig(cloneDeep(searchConfig));
  };

  const searchHandler = async () => {
    setCurrentPageHandler(1);
  };

  const setCurrentPageHandler = page => {
    setCurrentPage(page);
    fetchDirectorBenefits(page);
  };

  const exportHandler = async () => {
    setShowLoadingPopup(true);
    const revenueData = [];

    try {
      const { data } = await postToGetDirectorBenefits(
        searchConfigGenerator({
          ...config,
          mbr: {
            end: formatTime(config.mbr.end, 'YYYY-MM-DD'),
            start: formatTime(config.mbr.start, 'YYYY-MM-DD'),
          },
          createDate: {
            end: formatTime(config.createDate.end, 'YYYY-MM-DD'),
            start: formatTime(config.createDate.start, 'YYYY-MM-DD'),
          },
          page: 1,
          amount: totalCount || 1,
        })
      );

      const { benefits } = data;

      for(let i = 0; i < benefits.length; i += 1) {
        const benefit = benefits[i];
        const { projectID } = benefit;
        const projectDetail = await postToGetProjectDetail(projectID);
        const {
          director, projectCode, projectName, projectManager
        } = projectDetail.data;

        const voucherTotalDetail = await postToGetVouchersTotal({
          projectID,
          object: [],
          item: 'amount',
          approvalStatus: [
            'projectManagerVerify',
            'projectDirectorVerify',
            'financeVerify',
            'generalManagerVerifying',
            'generalManagerApproved',
          ],
        });
        const voucherTotal = voucherTotalDetail.data.total || 0;

        revenueData.push([
          projectCode,
          projectName,
          getOptionDesc(memberOptions, director),
          getOptionDesc(memberOptions, projectManager),
          formatTime(benefit.mbr, 'YYYY/MM/DD') || '',
          benefit.prPrice || 0,
          benefit.oopPrice || 0,
          benefit.serviceIncome || 0,
          benefit.invoiceIncome || 0,
          benefit.index === undefined ? voucherTotal : 0,
          formatTime(benefit.predictDate, 'YYYY/MM/DD') || '',
        ]);    
      }

    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
    exportDirectorRevenueFile(revenueData);
  };

  const fetchDirectorBenefits = async (page = currentPage) => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetDirectorBenefits(
        searchConfigGenerator({
          ...config,
          mbr: {
            end: formatTime(config.mbr.end, 'YYYY-MM-DD'),
            start: formatTime(config.mbr.start, 'YYYY-MM-DD'),
          },
          createDate: {
            end: formatTime(config.createDate.end, 'YYYY-MM-DD'),
            start: formatTime(config.createDate.start, 'YYYY-MM-DD'),
          },
          page,
        })
      );

      const { benefits, totalCount } = data;

      for(let i = 0; i < benefits.length; i += 1) {
        const benefit = benefits[i];
        const { projectID } = benefit;
        const projectDetail = await postToGetProjectDetail(projectID);
        benefit.director = projectDetail.data.director;
        benefit.projectCode = projectDetail.data.projectCode;
        benefit.projectName = projectDetail.data.projectName;
        benefit.projectManager = projectDetail.data.projectManager;

        if (benefit.index === undefined) {
          const voucherTotalDetail = await postToGetVouchersTotal({
            projectID,
            object: [],
            item: 'amount',
            approvalStatus: [
              'projectManagerVerify',
              'projectDirectorVerify',
              'financeVerify',
              'generalManagerVerifying',
              'generalManagerApproved',
            ],
          });
          benefit.voucherTotal = voucherTotalDetail.data.total || 0;
        }        
      }

      setBenefits([...benefits]);
      setTotalCount(totalCount || 0);
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  useEffect(() => {
    initOptions();
  }, [initOptions]);

  return (
    <MainContent>
      <DivPanelContainer>
        <PanelDirectorRevenueSearch
          config = { config }
          directorOptions = { directorOptions }
          customerOptions = { customerOptions }
          projectIDOptions = { projectIDOptions }
          companyTypeOptions = { companyTypeOptions }
          clearHandler = { clearHandler }
          exportHandler = { exportHandler }
          searchHandler = { searchHandler }
          updateConfigHandler = { updateConfigHandler }
        />
      </DivPanelContainer>
      <TableDirectorRevenues
        benefits = { benefits }
        totalCount = { totalCount }
        currentPage = { currentPage }
        pageChangedHandler = { setCurrentPageHandler }
      />
      { showLoadingPopup && <PopupLoading/> }
    </MainContent>
  );
};

export default DirectorRevenue;
