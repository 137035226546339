import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { Table, TableBody } from '@/component-style/Table';
import TableWrapper  from '@/components/UI/TableWrapper';
import PageSelector from '@/components/UI/PageSelector';
// import SearchInput from '@/components/UI/SearchInput';
import SelectGray from '@/components/UI/Select/SelectGray';
import Header from './Header';
import Item from './Item';

const SearchContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 30px;
  padding-bottom: 18px;
  flex-wrap: wrap;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const SearchWrapper = styled.div`
  @media (max-width: 767px) {
    margin-bottom: 10px;

    &:last-child { margin: 0 }
  }
`;

const TableProjects = props => {
  const projectRoutes = useSelector(state => state.login.projectRoutes);
  const disableDetailPage = projectRoutes.indexOf('ProjectDetail') === -1;

  const validModeOptions = props.showProject
    ? [
        { value: 'all', desc: '全部專案' },
        { value: 'involved', desc: '參與專案'},
      ]
    : [{ value: 'involved', desc: '參與專案'}];

  return (
    <TableWrapper title = '專案列表'>
      <SearchContainer>
        <SearchWrapper>
          <SelectGray
            disabled = { !props.showProject }
            value = { props.mode }
            options = { validModeOptions }
            changeHandler = { props.modeChangeHandler }
          >
          </SelectGray>
        </SearchWrapper>
        {/* <SearchWrapper>
          <SearchInput
            placeholder = "專案代碼搜尋"
            value = { props.projectCodeFilter }
            setValue = { props.projectCodeFilterChangeHandler }
            search = { props.searchHandler }
          />
        </SearchWrapper> */}
      </SearchContainer>
      <Table>
        <Header
          showDetailButton = { props.showDetailButton }          
          getTableSortIcon = { props.getTableSortIcon }
          tableSortConfig = { props.tableSortConfig }
          tableSortHandler = { props.tableSortHandler }
        />
        <TableBody>
          {
            props.projects.map(project => 
              <Item
                key = { project.projectID }
                project = { project }
                disableDetailPage = { disableDetailPage }
                showDetailButton = { props.showDetailButton }
                goDetailHandler = { () => {
                  props.goProjectDetailHandler(project.projectID);
                }}
              />
            )
          }
        </TableBody>
      </Table>
      <PageSelector
        current = { props.currentPage }
        total = { props.totalCount }
        pageChangedHandler = { props.pageChangedHandler }
      />
    </TableWrapper>
  );
};

export default TableProjects;
