import React, { useCallback, useEffect, useState, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

import {
  deleteUploadFile,
  postNewUploadFile,
  postToGetUploadFiles,
} from '@/api/storageService';

import {
  deleteVendors,
  postToGetVendorDetail,
  putModifyVendorDetail,
  postToGetVendorWindows,
} from '@/api/vendorService';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import {
  FETCH_SPECIAL_FEATURES,
  FETCH_VENDOR_TYPE_OPTIONS,
} from '@/store/actions/srpOptionsAction';

import MainContent from '@/component-style/MainContent';
import TableContactWindows from '@/components/Table/TableContactWindows';
import PanelVendorDetail from '@/components/Panel/PanelVendorDetail';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import PopupAttachmentList from '@/components/UI/Popup/PopupAttachmentList';
import PopupExecuteConfirm from '@/components/UI/Popup/PopupExecuteConfirm';
import { ButtonBasic, ButtonCancel } from '@/component-style/Button';
import { DivFlexRow } from '@/component-style/RWDWrapper';

import { vendorConfig } from '@/constant/dataConfig/informationModule';
import { integerConverter } from '@/utils/textUtils';

const TableContainer = styled.div`
  margin-bottom: 40px;
`;

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding-right: 0;
  padding-left: 135px;
  padding-bottom: 30px;
  justify-content: flex-end;

  @media (max-width: 425px) {
    justify-content: center;
    padding-left: 0;
  }
`;

const ButtonSave = styled(ButtonBasic)`
  width: 120px;
  margin-right: 10px;
  margin-bottom: 10px;
`;

const ButtonClear = styled(ButtonCancel)`
  width: 120px;
  margin-bottom: 10px;
`;

const VendorDetail = props => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const projectRole = useSelector(state => state.login.projectRole);
  const memberOptions = useSelector(state => state.srpOptions.memberOptions);
  const informationRoutes = useSelector(state => state.login.informationRoutes);
  const specialFeatures = useSelector(state => state.srpOptions.specialFeatures);
  const vendorTypeOptions = useSelector(state => state.srpOptions.vendorTypeOptions);
  const vendorTagDictionary = useSelector(state => state.srpOptions.vendorTagDictionary);
  const showSaveFailPopup = useSelector(state => state.apiFail.showSaveFailPopup);
  const apiFailPopupTitle = useSelector(state => state.apiFail.title);
  const apiFailPopupMessage = useSelector(state => state.apiFail.message);

  const isAccounting = projectRole.indexOf('accounting') !== -1;
  const userEditable = informationRoutes.indexOf('VendorDetail-Edit') !== -1;
  const userDeletable = informationRoutes.indexOf('VendorDetail-Delete') !== -1;
  const recordReadable = informationRoutes.indexOf('VendorRecords') !== -1;
  const userUploadable = informationRoutes.indexOf('VendorDetail-Attachments') !== -1;

  const [contactWindows, setContactWindows] = useState([]);
  const [windowsCount, setWindowsCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [deleteFileId, setDeleteFileId] = useState('');
  const [fileList, setFileList] = useState([]);
  const [uploadFiles, setUploadFiles] = useState([]);

  const [vendor, setVendor] = useState({
    ...vendorConfig,
    vendorID: '',
    tags: [],
    paymentMethods: [],
    transferInfo: { ...vendorConfig.transferInfo },
    contactAddress: { ...vendorConfig.contactAddress },
    invoiceAddress: { ...vendorConfig.invoiceAddress },
    specialProperties: {
      ...vendorConfig.specialProperties,
      personalServiceDetail: {
        ...vendorConfig.specialProperties.personalServiceDetail,
      },
    },
  });

  const [isEditing, setIsEditing] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [showDeleteFilePopup, setShowDeleteFilePopup] = useState(false);
  const [showAttachmentsPopup, setShowAttachmentsPopup] = useState(false);
  const [showSaveSuccessPopup, setShowSaveSuccessPopup] = useState(false);
  const [showDeleteSuccessPopup, setShowDeleteSuccessPopup] = useState(false);
  const [showUploadSuccessPopup, setShowUploadSuccessPopup] = useState(false);
  const [showDeleteFileSuccessPopup, setShowDeleteFileSuccessPopup] = useState(false);
  const [showDeleteErrorPopup, setShowDeleteErrorPopup] = useState(false);

  const updateVendorHandler = (key, value) => {
    const updateObj = { ...vendor };
    updateObj[key] = value;
    setVendor({ ...updateObj });
  };

  const closeSuccessPopupHandler = () => {
    setIsEditing(false);
    setShowSaveSuccessPopup(false);
  };

  const updateUploadFiles = value => {
    setUploadFiles([ ...value ]);
  };

  const uploadSuccessConfirmHandler = () => {
    setUploadFiles([]);
    setShowUploadSuccessPopup(false);
    setShowAttachmentsPopup(true);
  };

  const apiFailConfirmHandler = () => {
    dispatch(CLOSE_SAVE_FAIL_POPUP());

    apiFailPopupTitle === '上傳失敗'
      && setShowAttachmentsPopup(true);
  };

  const paymentChangedHandler = value => {
    const updateObject = { ...vendor };
    updateObject.paymentMethods = [ ...value ];

    if (value.indexOf('credit') === -1) {
      updateObject.credictCardOwner = '';
    }

    if (value.indexOf('transfer') === -1) {
      updateObject.transferInfo = {
        ...vendorConfig.transferInfo
      };
    }

    setVendor(updateObject);
  };

  const setCurrentPageHandler = page => {
    setCurrentPage(page);
    updateContactWindows(page);
  };

  const deleteFileHandler = id => {
    setDeleteFileId(id);
    setShowDeleteFilePopup(true);
  };

  const fileUploadHandler = async () => {
    setShowAttachmentsPopup(false);
    setShowLoadingPopup(true);

    try {
      const [file] = uploadFiles;
      const formData = new FormData();
      formData.append('upfile', file);
      formData.append('objectType', 'vendor');
      formData.append('objectID', id);
      formData.append('name', file.name);
      await postNewUploadFile(formData);
      await fetchUploadFiles();
      setShowLoadingPopup(false);
      setShowUploadSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '上傳失敗', err }));
      console.log(err);
    };
  };

  const deleteFile = async () => {
    setShowDeleteFilePopup(false);
    setShowAttachmentsPopup(false);
    setShowLoadingPopup(true);

    try {
      await deleteUploadFile(deleteFileId);
      await fetchUploadFiles();
      setShowLoadingPopup(false);
      setShowDeleteFileSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '刪除檔案失敗', err }));
      console.log(err);
    }
  };

  const fetchUploadFiles = async () => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetUploadFiles('vendor', id);
      setFileList([ ...data ]);
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const deleteVendorErrorConfirmHandler = () => {
    setShowDeleteErrorPopup(false);
  };

  const deleteVendorHandler = async () => {
    setShowDeletePopup(false);
    setShowLoadingPopup(true);

    try {
      const { data } = await deleteVendors([vendor.vendorID]);
      // console.log("JSON.stringify(data):" + JSON.stringify(data.length));
      // console.log("JSON.stringify(data):" + JSON.stringify(data[0].Expenses));
      if (data.length > 0 && data[0].Expenses.length > 0) {
        console.log("JSON.stringify(data):");
        setShowLoadingPopup(false);
        throw(new Error(''));
      }
      setShowLoadingPopup(false);
      setShowDeleteSuccessPopup(true);
    } catch(err) {
      setShowLoadingPopup(false);
      setShowDeleteErrorPopup(true);
      // console.log("JSON.stringify(data):");
      // dispatch(OPEN_SAVE_FAIL_POPUP({ title: '刪除失敗', err }));
      console.log(err);
    }
  }

  const updateVendorData = async () => {
    setShowLoadingPopup(true);

    try {
      await putModifyVendorDetail({
        ...vendor,
        transferInfo: {
          ...vendor.transferInfo,
          handlingFee: integerConverter(vendor.transferInfo.handlingFee),
        },
        specialProperties: {
          ...vendor.specialProperties,
          personalServiceDetail : {
            ...vendor.specialProperties.personalServiceDetail,
            wageB: integerConverter(vendor.specialProperties.personalServiceDetail.wageB),
            wageE: integerConverter(vendor.specialProperties.personalServiceDetail.wageE),
            specialFeature: vendor.specialProperties.personalServiceDetail.specialFeature || null,
          }
        },
      });
      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '更新失敗', err }));
      console.log(err);
    }
  };

  const updateContactWindows = useCallback(
    async (page = currentPage) => {
      setShowLoadingPopup(true);

      try {
        const { data } = await postToGetVendorWindows({
          page,
          amount: 10,
          vendorID: id,
        });
        const { contactWindows, totalCount } = data;
        setContactWindows([...contactWindows]);
        setWindowsCount(totalCount);
      } catch(err) { console.log(err) }

      setShowLoadingPopup(false);
    },
    [currentPage, id],
  );

  const fetchVendorDetail = async () => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetVendorDetail(id);

      const {
        transferInfo,
        contactAddress,
        invoiceAddress,
        paymentMethods,
        specialProperties,
        ...remainItems
      } = data;
      
      setVendor(prevState => ({
        ...prevState,
        ...remainItems,
        available: data.available === true,
        paymentMethods: paymentMethods === null ? [] : paymentMethods,
        contactAddress: {
          ...prevState.contactAddress,
          ...contactAddress,
        },
        invoiceAddress: {
          ...prevState.invoiceAddress,
          ...invoiceAddress,
        },
        transferInfo: {
          ...prevState.transferInfo,
          ...transferInfo,
        },
        specialProperties: {
          ...prevState.specialProperties,
          ...specialProperties,
          personalServiceDetail: {
            ...prevState.specialProperties.personalServiceDetail,
            ...specialProperties.personalServiceDetail,
            specialFeature: specialProperties.personalServiceDetail.specialFeature || '',
          },
        },
      }));
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const initVendorData = useCallback(
    fetchVendorDetail, []);
  
  const initContactWindows = useCallback(
    updateContactWindows, []);

  const initUploadFiles = useCallback(
    fetchUploadFiles, []);

  const initData = useCallback(
    async () => {
      await initVendorData();
      await initContactWindows();
      await initUploadFiles();
  },[initContactWindows, initUploadFiles, initVendorData]);

  const initOptions = useCallback(() => {
    dispatch(FETCH_SPECIAL_FEATURES());
    dispatch(FETCH_VENDOR_TYPE_OPTIONS());
  }, [dispatch]);

  useEffect(() => {
    initOptions();
    initData();
  }, [initData, initOptions]);

  return (
    <MainContent>
      <TableContainer>
        <TableContactWindows
          windowType = "Vendor"
          contactWindows = { contactWindows }
          totalCount = { windowsCount }
          currentPage = { currentPage }
          pageChangedHandler = { value => {
            setCurrentPageHandler(value);
          }}
        />
      </TableContainer>
      <PanelVendorDetail
        title = "廠商資料明細"
        disabled = { !isEditing }
        showAccountingItems = { isAccounting }
        vendor = { vendor }
        memberOptions = { memberOptions }
        specialFeatures = { specialFeatures }
        vendorTypeOptions = { vendorTypeOptions }
        vendorTagDictionary = { vendorTagDictionary }
        updateVendorHandler = { updateVendorHandler }
        paymentChangedHandler = { paymentChangedHandler }
      >
        <DivFlexRow>
          <DivButtonsWrapper>
            <ButtonSave
              disabled = { !recordReadable }
              onClick = { () => {
                history.push(`/Information/VendorRecords/${id}`);
              }}
            >廠商紀錄</ButtonSave>
            <ButtonSave
              disabled = { !userUploadable }
              onClick = { () => { setShowAttachmentsPopup(true) } }
            >附件管理</ButtonSave>
            {
              isEditing
                ? <ButtonSave
                    onClick = { updateVendorData }
                  >儲存</ButtonSave>
                : <ButtonSave
                    disabled = { !userEditable }
                    onClick = { () => { setIsEditing(true) }}
                  >編輯</ButtonSave>
            }
            {
              <ButtonSave
                disabled = { !userDeletable }
                onClick = { () => { setShowDeletePopup(true) }}
              >刪除</ButtonSave>
            }
            <ButtonClear
              onClick = { () => { history.goBack() }}
            >離開</ButtonClear>
          </DivButtonsWrapper>
        </DivFlexRow>
      </PanelVendorDetail>
      { showLoadingPopup && <PopupLoading/> }
      {
        showAttachmentsPopup &&
        <PopupAttachmentList
          fileChangable = { true }
          fileList = { fileList }
          uploadFiles = { uploadFiles }
          updateUploadFiles = { updateUploadFiles }
          fileUploadHandler = { fileUploadHandler }
          deleteFileHandler = { deleteFileHandler }
          cancelHandler = { () => { setShowAttachmentsPopup(false) }}
        />
      }
      {
        showDeleteFilePopup &&
          <PopupExecuteConfirm
            title = "刪除檔案"
            text = "確定刪除此上傳檔案"
            confirmHandler = { deleteFile }
            cancelHandler = { () => {
              setShowDeleteFilePopup(false);
            }}
          />
      }
      {
        showDeletePopup &&
          <PopupExecuteConfirm
            title = "刪除廠商名單"
            text = "確定刪除此廠商資料"
            confirmHandler = { deleteVendorHandler }
            cancelHandler = { () => { setShowDeletePopup(false) }}
          />
      }
      {
        showSaveSuccessPopup &&
          <PopupExecuteResult
            title = "更新成功"
            confirmHandler = { closeSuccessPopupHandler }
          />
      }
      {
        showDeleteSuccessPopup &&
          <PopupExecuteResult
            title = "刪除成功"
            confirmHandler = { () => {
              history.goBack();
            }}
          />
      }
      {
        showUploadSuccessPopup &&
          <PopupExecuteResult
            title = "上傳成功"
            confirmHandler = { uploadSuccessConfirmHandler }
          />
      }
      {
        showDeleteFileSuccessPopup &&
          <PopupExecuteResult
            title = "刪除檔案成功"
            confirmHandler = { () => {
              setShowDeleteFileSuccessPopup(false);
              setShowAttachmentsPopup(true);
            }}
          />
      }
      {
        showSaveFailPopup &&
          <PopupExecuteResult
            title = { apiFailPopupTitle }
            text = { apiFailPopupMessage }
            confirmHandler = { apiFailConfirmHandler }
          />
      }
      {
        showDeleteErrorPopup &&
          <PopupExecuteResult
            title = "刪除廠商資料"
            text = "無法刪除，請確認此廠商未在任一支憑中。"
            confirmHandler = { deleteVendorErrorConfirmHandler }
          />
      }
    </MainContent>
  )
};

export default VendorDetail;
