import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import PanelWrapper from '@/components/UI/PanelWrapper';
import SelectMultiple from '@/components/UI/Select/SelectMultiple';
import SelectNormal from '@/components/UI/Select/SelectNormal';
import SearchButtonSet from '@/components/UI/SearchButtonSet';
import { InputBasic } from '@/component-style/Input';
import { ButtonBasic } from '@/component-style/Button';
import { PSubtitle } from '@/component-style/TextElement';
import {
  DivFlexRow,
  DivItemWrapper,
  DivFullWidthWrapper,
  DivLongMultiSelector,
} from '@/component-style/RWDWrapper';

import { projectRolesOptions } from '@/constant/options';

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding: 0;
  justify-content: space-between;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const ButtonAdd = styled(ButtonBasic)`
  margin-right: 10px;
  width: 120px;

  @media (max-width: 767px) {
    margin-right: 0;
    margin-bottom: 10px;
  }
`;

const PanelUserSearch = props => {
  const history = useHistory();
  const authorityRoutes = useSelector(state => state.login.authorityRoutes);
  const companyTypeOptions = useSelector(state => state.srpOptions.companyTypeOptions);
  const { config } = props;

  const userAddable = authorityRoutes.indexOf('UserCreation') !== -1;

  return (
    <PanelWrapper title = "同仁查詢">
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>同仁編號</PSubtitle>
          <InputBasic
            value = { config.code }
            onChange = { ({ target }) => {
              props.updateConfigHandler('code', target.value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>同仁姓名</PSubtitle>
          <InputBasic
            value = { config.name }
            onChange = { ({ target }) => {
              props.updateConfigHandler('name', target.value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>信用卡持卡人</PSubtitle>
          <SelectNormal
            value = { config.hasCreditCard }
            options = {[
              { value: '', desc: '請選擇' },
              { value: true, desc: '是' },
              { value: false, desc: '否' },
            ]}
            changeHandler = { value => {
              props.updateConfigHandler('hasCreditCard', value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>部門</PSubtitle>
          <SelectNormal
            value = { config.department }
            options = {[
              { value: '', desc: '請選擇部門' },
              ...props.departmentOptions,
            ]}
            changeHandler = { value => {
              props.updateConfigHandler('department', value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>所屬總監</PSubtitle>
          <SelectNormal
            value = { config.director }
            options = {[
              { value: '', desc: '請選擇總監' },
              ...props.directorOptions,
            ]}
            changeHandler = { value => {
              props.updateConfigHandler('director', value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>狀態</PSubtitle>
          <SelectNormal
            value = { config.enable }
            options = {[
              { value: '', desc: '請選擇狀態' },
              { value: true, desc: '啟用' },
              { value: false, desc: '停用' },
            ]}
            changeHandler = { value => {
              props.updateConfigHandler('enable', value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>所屬公司</PSubtitle>
          <SelectNormal
            disabled = { props.disabled }
            value = { config.companyType }
            options = {[
              { value: '', desc: '請選擇公司' },
              ...companyTypeOptions,
            ]}
            changeHandler = { value => {
              props.updateConfigHandler('companyType', value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivFullWidthWrapper>
          <PSubtitle>專案角色</PSubtitle>
          <DivLongMultiSelector>
            <SelectMultiple
              value = { config.projectRole }
              options = { projectRolesOptions }
              changeHandler = { value => {
                props.updateConfigHandler('projectRole', value);
              }}
            />
          </DivLongMultiSelector>
        </DivFullWidthWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivFullWidthWrapper>
          <PSubtitle>權限設定</PSubtitle>
          <DivLongMultiSelector>
            <SelectMultiple
              value = { config.systemRole }
              options = { props.systemRoleOptions }
              changeHandler = { value => {
                props.updateConfigHandler('systemRole', value);
              }}
            />
          </DivLongMultiSelector>
        </DivFullWidthWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivButtonsWrapper>
          { !props.popupWageStructureWhenEditingUser &&
            <ButtonAdd
              disabled = { !userAddable }
              onClick = { () => { history.push('/Authority/UserCreation') } }
            >新增</ButtonAdd>
          }
          {
            props.popupWageStructureWhenEditingUser && 
              <div>&nbsp;</div>
          }
          <SearchButtonSet
            clearHandler = { props.clearHandler }
            searchHandler = { props.searchHandler }
          />
        </DivButtonsWrapper>
      </DivFlexRow>
    </PanelWrapper>
  );
};

export default PanelUserSearch;
