import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation } from '@fortawesome/free-solid-svg-icons';

const DivErrorAlert = styled.div`
  border-radius: 8px;
  background-color: #fceded;
  padding: 20px;
  margin-bottom: 20px;
`;

const DivAlertIcon = styled.div`
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 16px;
  color: #de2027;
  border: 2px solid #de2027;
  border-radius: 50%;
  text-align: center;
`;

const PErrorMsg = styled.p`
  display: inline-block;
  margin-left: 10px;
  font-size: 14px;
  letter-spacing: 0.4px;
  color: #57585a;
  width: calc(100% - 35px);
  vertical-align: baseline;
`;

const ErrorAlert = props => {

  return (
    <DivErrorAlert>
      <DivAlertIcon>
        <FontAwesomeIcon
          size = "xs"
          icon = { faExclamation }
        />
      </DivAlertIcon>
      <PErrorMsg>
        { props.errorMsg }
      </PErrorMsg>
    </DivErrorAlert>
  );
};

export default ErrorAlert;
