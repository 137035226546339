import React from 'react';
import styled from 'styled-components';

import { TableHead, TableRow, ThItem } from '../../../component-style/Table';

const THead = styled(TableHead)`
  min-width: ${ props => props.showDetailButton
    ? '1050px'
    : '990px'
  };
`;

const Th60 = styled(ThItem)`
  width: 60px;
`;

const Th70 = styled(ThItem)`
  width: 70px;
`;

const Th80 = styled(ThItem)`
  width: 80px;
`;

const Th120 = styled(ThItem)`
  width: 120px;
`;

const Th160 = styled(ThItem)`
  width: 160px;
`;

const Header = props => {
  
  return (
    <THead
      showDetailButton = { props.showDetailButton }
    >
      <TableRow>
        <Th120>專案代號</Th120>
        <Th160>專案名稱</Th160>
        <Th70>確定會來</Th70>
        <Th70>可能會來</Th70>
        <Th80>另派人參加</Th80>
        <Th80>視截稿狀況</Th80>
        <Th70>當天決定</Th70>
        <Th60>不確定</Th60>	
        <Th80>尚未連絡上</Th80>	
        <Th60>不會來</Th60>	
        <Th70>稍後再撥</Th70>	
        <Th70>尚未確認</Th70>
        {
          props.showDetailButton &&
          <Th60></Th60>
        }
      </TableRow>
    </THead>
  );
};

export default Header;
