import React from 'react';
import styled from 'styled-components';

import Checkbox from '@/components/UI/Checkbox';
import { TableHead, TableRow, ThItem } from '@/component-style/Table';

const THead = styled(TableHead)`
  min-width: 760px;
`;

const Th80 = styled(ThItem)`
  width: 80px;
`;

const Th120 = styled(ThItem)`
  width: 120px;
`;

const Th240 = styled(ThItem)`
  width: 240px;
`;

const Header = props => {
  
  return (
    <THead>
      <TableRow>
        <Th80>
          <Checkbox
            checked = { props.selectAll }
            changeHandler = { props.selectAllHandler }
          />
        </Th80>
        <Th120>客戶代號</Th120>
        <Th240>客戶名稱</Th240>
        <Th120>統一編號</Th120>
        <Th120>負責人</Th120>
        <Th80></Th80>
      </TableRow>
    </THead>
  );
};

export default Header;
