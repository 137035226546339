import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { DivPopupWrapper, DivPopupXLarge } from '@/component-style/Popup';
import { ButtonCancel, ButtonBasic } from '@/component-style/Button';
// import { DivFlexRow } from '@/component-style/RWDWrapper';
import { InputBasic } from '@/component-style/Input';
import { PSubtitle } from '@/component-style/TextElement';
import MainContent from '@/component-style/MainContent';
import moment from 'moment';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import {
  getOptionDesc,
} from '@/utils/textUtils';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import { leaveApprovalOptions } from '@/constant/options';

import { TableHead, TableRow, ThItem } from '@/component-style/Table';

import {
  postUpdateLeaveApplicationStatus,
} from '@/api/financeService';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import {
  DivFlexRow,
  DivItemWrapper,
} from '@/component-style/RWDWrapper';

const THead = styled(TableHead)`
  min-width: 1560px;
`;

const Th120 = styled(ThItem)`
  width: 120px;
`;

const DivButtonsWrapper = styled.div`
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const H3Title = styled.h3`
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: #8b8b8b;
  margin-bottom: 10px;
`;

const ButtonDiscard = styled(ButtonCancel)`
  width: 120px;
  margin-right: 10px;
`;

const ButtonSave = styled(ButtonBasic)`
  width: 120px;

  &:first-child { margin-right: 10px }
`;

const DivWrapper = styled.div`
  width: 100%;
  
  @media (min-width: 768px) {
    width: calc(100% - 135px);
  }
`;

const PopupEdiLeaveApplication = props => {
  const dispatch = useDispatch();
  const showSaveFailPopup = useSelector(state => state.apiFail.showSaveFailPopup);
  const apiFailPopupTitle = useSelector(state => state.apiFail.title);
  const apiFailPopupMessage = useSelector(state => state.apiFail.message);

  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [showSaveSuccessPopup, setShowSaveSuccessPopup] = useState(false);


  const fetchLeaveApplication = async () => {

  };

  const closeSuccessPopupHandler = () => {
    setShowSaveSuccessPopup(false);
  };

  const cancelLeaveApplicationHandler = async (leaveApplicationId) => {
    setShowLoadingPopup(true);
    // const { workingHoursID, freeTimeMins, extraPayMins } = leaveRecords[index];

    try {
      console.log(`PopupEditLeaveApplication.js cancelLeaveApplicationHandler() leaveApplicationId=${leaveApplicationId}`);

      await postUpdateLeaveApplicationStatus(leaveApplicationId, "cancelled");
      props.reloadLeaveApplicationByIdHandler(leaveApplicationId);
      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
      
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '審核失敗', err }));
      console.log(err);
    }
  };

  const reloadHandler = () => {
    console.log(`PoupEditLeaveApplication.js reloadHandler()`);
    fetchLeaveApplication();
  };

  const updateData = async () => {
    console.log("PoupEditLeaveApplication.js updateData() props.editUserId:" + JSON.stringify(props.editUserId));
    fetchLeaveApplication();
  };

  const initData = useCallback(
    updateData
  , []
  );

  useEffect(() => {
    initData();
  }, [initData]);

  return (
    <MainContent>
      <DivPopupWrapper>
        <DivPopupXLarge>
          <H3Title>休假修改</H3Title>
          <DivFlexRow>
            <DivItemWrapper>
                <PSubtitle>申請人</PSubtitle>
                <InputBasic
                  disabled = { true }
                  value = { props.editingLeaveApplication.username }
                />
            </DivItemWrapper>
            <DivItemWrapper>
                <PSubtitle>假別</PSubtitle>
                <InputBasic
                  disabled = { true }
                  value = { props.editingLeaveApplication.leaveName }
                />
            </DivItemWrapper>
            <DivItemWrapper>
                <PSubtitle>開始日期及時間</PSubtitle>
                <InputBasic
                  disabled = { true }
                  value = { moment(props.editingLeaveApplication.leaveFrom).format('YYYY-MM-DD HH:mm') }
                />
            </DivItemWrapper>
          </DivFlexRow>
          <DivFlexRow>
            <DivItemWrapper>
                <PSubtitle>結束日期及時間</PSubtitle>
                <InputBasic
                  disabled = { true }
                  value = { moment(props.editingLeaveApplication.leaveTo).format('YYYY-MM-DD HH:mm') }
                />
            </DivItemWrapper>
            <DivItemWrapper>
                <PSubtitle>請假時數</PSubtitle>
                <InputBasic
                  disabled = { true }
                  value = { props.editingLeaveApplication.leaveHours }
                />
            </DivItemWrapper>
            <DivItemWrapper>
                <PSubtitle>狀態</PSubtitle>
                <InputBasic
                  disabled = { true }
                  value = { getOptionDesc(leaveApprovalOptions, props.editingLeaveApplication.status) }
                />
            </DivItemWrapper>
          </DivFlexRow>
          <DivFlexRow>
            <DivButtonsWrapper>
              <div>&nbsp;</div>
              {/* { props.editingLeaveApplication.status !== "cancelled" && */}
                <ButtonSave
                  onClick = { () => { cancelLeaveApplicationHandler(props.editingLeaveApplication.id); } }
                >取消此請假申請單</ButtonSave>
              {/* } */}
              &nbsp;&nbsp;
              <ButtonDiscard
                onClick = { () => { props.handleOnClosePopupEditLeaveApplication(); } }
              >離開</ButtonDiscard>
            </DivButtonsWrapper>
          </DivFlexRow>
        </DivPopupXLarge>
      </DivPopupWrapper>
      { showLoadingPopup && <PopupLoading/> }
      {
        showSaveSuccessPopup &&
          <PopupExecuteResult
            title = "審核成功"
            confirmHandler = { closeSuccessPopupHandler }
          />
      }
      {
        showSaveFailPopup &&
          <PopupExecuteResult
            title = { apiFailPopupTitle }
            text = { apiFailPopupMessage }
            confirmHandler = { () => {
              dispatch(CLOSE_SAVE_FAIL_POPUP());
            }}
          />
      }
    </MainContent>
  );
};

export default PopupEdiLeaveApplication;
