import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import TableWrapper  from '@/components/UI/TableWrapper';
import PageSelector from '@/components/UI/PageSelector';
import { Table, TableBody } from '@/component-style/Table';
import Header from './Header';
import Item from './Item';
import { decimalCommaConverter } from '@/utils/textUtils';

const TablePersonnelWageList = props => {
  const history = useHistory();
  const financeRoutes = useSelector(state => state.login.financeRoutes);
  // const disableDetailPage = financeRoutes.indexOf('FinanceApprovalDetail') === -1;

  const { wages } = props;
  console.log(`TablePersonnelWageList index.js JSON.stringify(wages):${JSON.stringify(wages)}`);

  return (
    <TableWrapper title =  { `同仁月薪資列表-員工數:${props.totalCount} 撥付總額:${decimalCommaConverter(props.totalSalary)}` }>
      <Table>
        <Header/>
        <TableBody>
          {
            wages.map((wage, index) =>
              <Item
                key = { wage.id }
                wage = { wage }
                // disableDetailPage = { disableDetailPage }
                goDetailHandler = { (userId) => {
                  props.editDetailHandler(userId);
                }}
              />
            )
          }
        </TableBody>
      </Table>
      <PageSelector
        current = { props.currentPage }
        total = { props.totalCount }
        pageChangedHandler = { props.pageChangedHandler }
      />
    </TableWrapper>
  );
};

export default TablePersonnelWageList;
