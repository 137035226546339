import axiosConfig from './axiosConfig';
import { getDefaultPort } from '@/utils/apiRequestUtils';

const { baseURL } = axiosConfig.defaults;

const port = process.env.REACT_APP_SERVICE_PORT_PROJECT_TODO !== undefined
  ? process.env.REACT_APP_SERVICE_PORT_PROJECT_TODO
  : getDefaultPort(baseURL);

const projectTodoModuleUrl = process.env.NODE_ENV === 'development'
  ? `${baseURL}:${port}/api`
  : `${baseURL}/projectTodo/api`;

export const postToGetProjectTodoTypes = config => axiosConfig
  .post(`${projectTodoModuleUrl}/projectTodo/types`, config);

export const deleteProjectTodoTypes = ids => axiosConfig
  .delete(`${projectTodoModuleUrl}/projectTodo/types`, { data: [ ...ids ]});

export const putNewProjectTodoType = config => axiosConfig
  .put(`${projectTodoModuleUrl}/projectTodo/types`, config);

export const patchModifyProjectTodoType = config => axiosConfig
  .patch(`${projectTodoModuleUrl}/projectTodo/types`, config);

export const postToGetProjectTodoTags = config => axiosConfig
  .post(`${projectTodoModuleUrl}/projectTodo/type/tag`, config);

export const putNewProjectTodoTag = config => axiosConfig
  .put(`${projectTodoModuleUrl}/projectTodo/type/tag`, config);

export const patchModifyProjectTodoTag = config => axiosConfig
  .patch(`${projectTodoModuleUrl}/projectTodo/type/tag`, config);

export const deleteProjectTodoTag = config => axiosConfig
  .delete(`${projectTodoModuleUrl}/projectTodo/type/tag`, { data: { ...config } });

export const postTodGetProjectTodoDetail = todoID => axiosConfig
  .post(`${projectTodoModuleUrl}/projectTodo/detail`, { todoID });

export const deleteProjectTodos = todoIDs => axiosConfig
  .delete(`${projectTodoModuleUrl}/projectTodo`, { data: { deleteTodos: [...todoIDs] }});

export const postToGetProjectTodos = config => axiosConfig
  .post(`${projectTodoModuleUrl}/projectTodo`, config);

export const putNewProjectTodo = config => axiosConfig
  .put(`${projectTodoModuleUrl}/projectTodo`, config);

export const putModifiedProjectTodo = todoConfig => axiosConfig
  .put(`${projectTodoModuleUrl}/projectTodo/detail`, todoConfig);
