import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';

export function useSort(field) {
  const [tableSortConfig, setTableSortConfig] = useState({
    "sortField": field,
    "sortOrder": "ascending"
  });
 
  const tableSortHandler = (clickedFieldName) => {
    console.log("tableSortHandler clickedFieldName:" + clickedFieldName + " tableSortConfig.sortField:" + tableSortConfig.sortField + " tableSortConfig.sortOrder:" + tableSortConfig.sortOrder);
    const updateTableSortConfig = { ...tableSortConfig };
    if (clickedFieldName === tableSortConfig.sortField) {
      if (tableSortConfig.sortOrder === "descending") {
        updateTableSortConfig.sortOrder = "ascending";
      } else {
        updateTableSortConfig.sortOrder = "descending";
      }
    } else {
      updateTableSortConfig.sortField = clickedFieldName;
    }
    setTableSortConfig(updateTableSortConfig);
  }
 
  const getTableSortIcon = (fieldName) => {
    // console.log("getTableSortIcon fieldName:" + fieldName);
    if (tableSortConfig.sortField === fieldName) {
      if (tableSortConfig.sortOrder === "ascending") {
        return <FontAwesomeIcon
                  size = "1x"
                  icon = { faArrowDown }
              />;
      } else if (tableSortConfig.sortOrder === "descending") {
        return <FontAwesomeIcon
                  size = "1x"
                  icon = { faArrowUp }
              />;
      }
    }
  }
 
  return {
    tableSortConfig,
    tableSortHandler,
    getTableSortIcon
  };
}