import React from 'react';
import styled from 'styled-components';

import { ButtonBasic } from '@/component-style/Button';
import { InputBasic } from '@/component-style/Input';
import SelectSearchable from '@/components/UI/Select/SelectSearchable';
import SelectNormal from '@/components/UI/Select/SelectNormal';
import RemoveImg from '@/assets/images/icon_remove.svg';

import { invitationConfirmStatus } from '@/constant/options';

const DivWrapper = styled.div`
  width: 100%;
  
  @media (min-width: 768px) {
    width: calc(100% - 135px);
  }
`;

const DivFWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const DivSelectorWrapper = styled.div`
  margin-right: 20px;
  margin-bottom: 10px;

  @media (min-width: 1920px) {
    .ant-select { width: 300px; }
  }
`;

const Input = styled(InputBasic)`
  width: 300px;
  margin-right: 20px;
  margin-bottom: 10px;

  @media (max-width: 320px) {
    width: 260px;
  };

  @media (min-width: 1920px) {
    width: 300px;
  }
`;

const ButtonAdd = styled(ButtonBasic)`
  width: 150px;
`;

const DivIconRemove = styled.div`
  cursor: pointer;
  width: 20px;
  height: 40px;
  background-image: url(${ props => props.url });
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  @media (max-width: 375px) {
    background-position: top;
  }
`;

const ReporterInvitationList = props => {
  const { invitation } = props;

  return (
    <DivWrapper>
      {
        props.isAdding &&
        <DivFWrapper>
          <DivSelectorWrapper>
            <SelectSearchable
              value = { invitation.journalistID }
              options = {[
                { value: '', desc: '請選擇記者' },
                ...props.journalistIDOptions,
              ]}
              changeHandler = { value => {
                props.updateInvitationHandler('journalistID', value);
              }}
            />
          </DivSelectorWrapper>
          <DivSelectorWrapper>
            <SelectNormal
              value = { invitation.confirmStatus }
              options = { invitationConfirmStatus }
              changeHandler = { value => {
                props.updateInvitationHandler('confirmStatus', value);
              }}
            />
          </DivSelectorWrapper>
          <Input
            placeholder = "狀況說明"
            value = { invitation.statusDesc }
            onChange = { ({ target }) => {
              props.updateInvitationHandler('statusDesc', target.value);
            }}
          />
          <Input
            placeholder = "分工"
            value = { invitation.job }
            onChange = { ({ target }) => {
              props.updateInvitationHandler('job', target.value);
            }}
          />
          <DivIconRemove
            url = { RemoveImg }
            onClick = { props.closeHandler } 
          />
        </DivFWrapper>
      }
      {
        props.isAdding
          ? <ButtonAdd
              disabled = { invitation.journalistID === '' || invitation.projectID === '' }
              onClick = { props.saveHandler }
            >加入記者</ButtonAdd>
          : <ButtonAdd
              disabled = { invitation.projectID === '' || !props.userEditable }
              onClick = { props.addHandler }
            >新增記者</ButtonAdd>
      }
    </DivWrapper>
  );
};

export default ReporterInvitationList;
