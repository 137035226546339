import React from 'react';
import styled from 'styled-components';

import { TableHead, TableRow, ThItem } from '../../../component-style/Table';

const THead = styled(TableHead)`
  min-width: 1120px;
`;

const Th80 = styled(ThItem)`
  width: 80px;
`;

const Th120 = styled(ThItem)`
  width: 120px;
`;

const Th200 = styled(ThItem)`
  width: 200px;
`;

const Header = props => {
  
  return (
    <THead>
      <TableRow>
        {
          props.displayMode
            ? <React.Fragment>
                <Th120>專案代號</Th120>
                <Th200>專案名稱</Th200>
              </React.Fragment>
            : <Th200>廠商名稱</Th200>
        }
        <Th120>滿意度</Th120>
        <Th120>交件時程</Th120>
        <Th120>價格合理</Th120>
        <Th120>配合度</Th120>
        <Th120>態度</Th120>
        <Th80>總分</Th80>
        <Th120>總評</Th120>
        {
          props.displayMode
            ? null
            : <Th120></Th120>
        }
      </TableRow>
    </THead>
  );
};

export default Header;
