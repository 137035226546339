import * as types from '../action-types/apiFailPopupActionTypes';

export const OPEN_SAVE_FAIL_POPUP = payload => ({
  type: types.OPEN_SAVE_FAIL_POPUP,
  payload,
});

export const CLOSE_SAVE_FAIL_POPUP = () => ({
  type: types.CLOSE_SAVE_FAIL_POPUP,
});
