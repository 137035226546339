import { Column, Pie } from '@ant-design/plots';

import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { cloneDeep } from 'lodash';

// import { postToGetExpenseVouchers, postToGetVouchersTotal } from '@/api/expenseService';
import { getGroupProfitReport, getGroupProfitReportExcel, 
          getGroupProfitSales, getGroupProfitSalesExcel, 
          getGroupProfitProfit, getGroupProfitProfitExcel, 
          getGroupProfitProfitPie, getGroupProfitProfitPieExcel,
          getGroupProfitUnitGrossProfit, getGroupProfitUnitGrossProfitExcel 
} from '@/api/financeService';
import moment from 'moment';
import { decimalCommaConverter } from '@/utils/textUtils';

import { FETCH_COMPANY_TYPE_OPTIONS, FETCH_DIRECTOR_OPTIONS } from '@/store/actions/srpOptionsAction';

import PanelRevenueByTeamSearch from '@/components/Panel/Finance/PanelRevenueByTeamSearch';
import TableRevenueByTeam from '@/components/Table/Finance/TableRevenueByTeam';
import PopupLoading from '@/components/UI/Popup/PopupLoading';

import { formatTime } from '@/utils/timeUtils';
import { getOptionDesc } from '@/utils/textUtils';
import { vendorInvoiceTypes } from '@/constant/options';
import { searchConfigGenerator } from '@/utils/apiRequestUtils';
import { exportExpenseVouchersFile } from '@/utils/xlsx';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';

import { postToGetUserInfo } from '@/api/authService';

const DivPanelContainer = styled.div`
  margin-bottom: 40px;
`;

const searchConfig = {
  date: {
    start: null,
    end: null,
  },
  companyTypeID: '',
  director: [],
  resultType: '',
  chartType: '',
  statisticInterval: '',
  statisticIntervalOptions: [],
};

const RevenueByTeamSearch = () => {
  // console.log("revenueByTeamSearch.js RevenueByTeamSearch :");
  const dispatch = useDispatch();
  const companyTypeOptions = useSelector(state => state.srpOptions.companyTypeOptions);
  const directorOptions = useSelector(state => state.srpOptions.directorOptions);
  const [directorByCompany, setDirectorByCompany] = useState([]);

  const [vouchers, setVouchers] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [config, setConfig] = useState(cloneDeep(searchConfig));

  const [showLoadingPopup, setShowLoadingPopup] = useState(false);

  const [alertText, setAlertText] = useState('');
  const [showAlertPopup, setShowAlertPopup] = useState(false);
  
  

  const openAlertPopup = text => {
    setAlertText(text);
    setShowAlertPopup(true);
  };

  const [dataGroupProfitSales, setDataGroupProfitSales] = useState(
    [
      // {
      //   "year": "202206Ian Chao",
      //   "value": 40000,
      //   "type": "服務費總額"
      // },
      // {
      //   "year": "202206Ian Chao",
      //   "value": 150000,
      //   "type": "專案其它收入"
      // },
      // {
      //   "year": "202207Ian Chao",
      //   "value": 3,
      //   "type": "服務費總額"
      // },
      // {
      //   "year": "202207Ian Chao",
      //   "value": 4,
      //   "type": "專案其它收入"
      // },
    ]
  );

  const [dataPie, setDataPie] = useState(
    [
      // {
      //   type: '組一',
      //   value: 27,
      // },
      // {
      //   type: '組二',
      //   value: 25,
      // },
      // {
      //   type: '組三',
      //   value: 18,
      // },
      // {
      //   type: '組四',
      //   value: 15,
      // },
      // {
      //   type: '組五',
      //   value: 10,
      // },
      // {
      //   type: '其他',
      //   value: 5,
      // },
    ]
  );

  const chartConfig = {
    data: dataGroupProfitSales,
    isStack: true,
    xField: 'year',
    yField: 'value',
    seriesField: 'type',
    label: {
      // 可手动配置 label 数据标签位置
      position: 'middle',
      // 'top', 'bottom', 'middle'
      // 可配置附加的布局方法
      layout: [
        // 柱形图数据标签位置自动调整
        {
          type: 'interval-adjust-position',
        }, // 数据标签防遮挡
        {
          type: 'interval-hide-overlap',
        }, // 数据标签文颜色自动调整
        {
          type: 'adjust-color',
        },
      ],
      content: ( obj ) => {
        if (obj == null || obj.value == null) {
          return "";
        } else {
          return "$ " + obj.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
      },
    },
    tooltip: {
      formatter: (obj) => {
        return { name: obj.year, value: "$ " + obj.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") };
      },
    },
    yAxis: {
      label: {
        formatter: (v) => {
          return "$ " + v.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
      },
    },
    // customContent: (item, group, cfg) => {
    //   console.log(item);
    // }
  };

  const pieConfig = {
    appendPadding: 10,
    data: dataPie,
    angleField: 'value',
    colorField: 'type',
    radius: 0.9,
    label: {
      type: 'inner',
      offset: '-30%',
      content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
      style: {
        fontSize: 14,
        textAlign: 'center',
      },
    },
    interactions: [
      {
        type: 'element-active',
      },
    ],
  };

  const getDirectorDetail = async (companyType) => {
    // console.log("revenueByTeamSearch.js getDirectorDetail userInfo:" + JSON.stringify(directorOptions));
    // console.log("revenueByTeamSearch.js getDirectorDetail companyType:" + companyType);
    var directorOfCompany = [];
    for (let i = 0; i < directorOptions.length; i += 1 ) {
      // console.log("revenueByTeamSearch.js initOptions getDirectorDetail:" + JSON.stringify(directorOptions[i]));
      const userInfo = await postToGetUserInfo(directorOptions[i].value);
      // console.log("revenueByTeamSearch.js getDirectorDetail userInfo:" + JSON.stringify(userInfo));
      if (userInfo.data.companyType === companyType) {
        console.log("revenueByTeamSearch.js getDirectorDetail userInfo:" + JSON.stringify(userInfo));
        // setDirectorOptions
        directorOfCompany.push({"value": directorOptions[i].value, "desc": userInfo.data.name});
      }
    }
    setDirectorByCompany(directorOfCompany);
  };

  // getDirectorDetail();

  const updateConfigHandler = (key, value) => {
    // console.log("key:" + key + " value:" + value);
    const updateObj = { ...config };
    // console.log(updateObj);
    updateObj[key] = value;
    if (key === "companyType") {
      updateObj["director"] = [];
      getDirectorDetail(value);
    }
    if (key === "chartType") {
      if (value === "revenue" || value === "grossProfit") {
        // updateObj['period'] = 'monthly';
        updateObj['statisticIntervalOptions'] = [
          { value: 'monthly', desc: '每月'},
          { value: 'halfYear', desc: '每半年'},
          { value: 'yearly', desc: '每年'},
        ];
      } else if (value === "grossProfitPercentage") {
        // updateObj['period'] = '';
        updateObj['statisticIntervalOptions'] = [];
      } else if (value === "unitGrossProfit") {
        // updateObj['period'] = 'firstHalf';
        updateObj['statisticIntervalOptions'] = [
          { value: 'halfYear', desc: '每半年'},
          { value: 'yearly', desc: '每年'},
        ];
      }
    }
    // console.log(updateObj['statisticInterval']);
    if (updateObj['statisticInterval'] === "yearly") {
      // console.log(formatTime(updateObj['date']['start'], 'YYYY-MM-DD'));
      var momentStart = moment(updateObj['date']['start'], 'YYYY-MM-DD');
      var momentEnd = moment(updateObj['date']['end'], 'YYYY-MM-DD');
      momentStart.set('month', 0);
      momentStart.set('date', 31);
      momentEnd.set('month', 11);
      momentEnd.set('date', 31);
      // console.log(formatTime(momentEnd));
      updateObj['date']['start'] = formatTime(momentStart, 'YYYY-MM-DD');
      updateObj['date']['end'] = formatTime(momentEnd, 'YYYY-MM-DD');
    }
    if (updateObj['statisticInterval'] === "halfYear") {
      console.log(formatTime(updateObj['date']['start'], 'YYYY-MM-DD'));
      if (updateObj['date']['start'] !== null && updateObj['date']['end'] !== null) {
        var momentHalfStart = moment(updateObj['date']['start'], 'YYYY-MM-DD');
        var momentHalfEnd = moment(updateObj['date']['end'], 'YYYY-MM-DD');
        // console.log(momentHalfStart.month());
        if (momentHalfStart.month() <= 5) {
          momentHalfStart.set('month', 0);
          momentHalfStart.set('date', 1);
          momentHalfEnd.set('month', 5);
          momentHalfEnd.set('date', 30);
        } else {
          momentHalfStart.set('month', 6);
          momentHalfStart.set('date', 1);
          momentHalfEnd.set('month', 11);
          momentHalfEnd.set('date', 31);
        }
        updateObj['date']['start'] = formatTime(momentHalfStart, 'YYYY-MM-DD');
        updateObj['date']['end'] = formatTime(momentHalfEnd, 'YYYY-MM-DD');
      }
    }
    setConfig({ ...updateObj });
  };

  const clearHandler = () => {
    setVouchers([]);
    setTotalCount(0);
    setConfig(cloneDeep(searchConfig));
  };

  const searchHandler = async () => {
    // console.log("config:" + JSON.stringify(config));
    if (config.companyType === "" || config.companyType == null) {
      return openAlertPopup("請選擇公司");
    }
    if (config.resultType === "" || config.resultType == null) {
      return openAlertPopup("請選擇圖表或報表");
    }
    if (config.resultType === 1) {//報表
      setCurrentPageHandler(1);
    } else if (config.resultType === 2) {//圖表
      if (config.chartType === "" || config.chartType == null) {
        return openAlertPopup("請選擇圖表名稱");
      }
      if (config.chartType === 'revenue') {//各組營收比較長條圖
        if (config.director.length === 0) {
          return openAlertPopup("請選擇總監");
        }
        if (config.date.start === "" || config.date.start == null) {
          return openAlertPopup("請選擇發票開始日期");
        }
        if (config.date.end === "" || config.date.end == null) {
          return openAlertPopup("請選擇發票結束日期");
        }
        if (config.statisticInterval === "" || config.statisticInterval == null) {
          return openAlertPopup("請選擇統計區間");
        }
        fetchGroupProfitSales();
      } else if (config.chartType === 'grossProfit') {//各組毛利比較長條圖
        if (config.director.length === 0) {
          return openAlertPopup("請選擇總監");
        }
        if (config.date.start === "" || config.date.start == null) {
          return openAlertPopup("請選擇發票開始日期");
        }
        if (config.date.end === "" || config.date.end == null) {
          return openAlertPopup("請選擇發票結束日期");
        }
        if (config.statisticInterval === "" || config.statisticInterval == null) {
          return openAlertPopup("請選擇統計區間");
        }
        fetchGroupProfitProfit();
      } else if (config.chartType === 'grossProfitPercentage') {//各組毛利佔比圓餅圖
        if (config.director.length === 0) {
          return openAlertPopup("請選擇總監");
        }
        if (config.date.start === "" || config.date.start == null) {
          return openAlertPopup("請選擇發票開始日期");
        }
        if (config.date.end === "" || config.date.end == null) {
          return openAlertPopup("請選擇發票結束日期");
        }
        fetchGroupProfitProfitPie();
      } else if (config.chartType === 'unitGrossProfit') {//各組單位毛利比較長條圖
        if (config.director.length === 0) {
          return openAlertPopup("請選擇總監");
        }
        if (config.date.start === "" || config.date.start == null) {
          return openAlertPopup("請選擇發票開始日期");
        }
        if (config.date.end === "" || config.date.end == null) {
          return openAlertPopup("請選擇發票結束日期");
        }
        if (config.statisticInterval === "" || config.statisticInterval == null) {
          return openAlertPopup("請選擇統計區間");
        }
        fetchGroupProfitUnitGrossProfit();
      }
    }
  };

  const setCurrentPageHandler = page => {
    setCurrentPage(page);
    fetchExpenseVouchers(page);
  };

  const exportGroupProfitReportExcel = async () => {
    setShowLoadingPopup(true);
    try {
      const { data } = await getGroupProfitReportExcel(
        {
          startDate: formatTime(config.date.start, 'YYYY-MM-DD'),
          endDate: formatTime(config.date.end, 'YYYY-MM-DD'),
          companyTypeID: config.companyType,
          director: config.director.join(','),
          // statisticInterval: config.statisticInterval,
          statisticInterval: "monthly",
        }
      );
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'GroupProfitReport.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      setShowLoadingPopup(false);
      console.log(JSON.stringify(err));
    }
    setShowLoadingPopup(false);
  }

  const exportgroupProfitSalesExcel = async () => {
    setShowLoadingPopup(true);
    try {
      const { data } = await getGroupProfitSalesExcel(
        {
          startDate: formatTime(config.date.start, 'YYYY-MM-DD'),
          endDate: formatTime(config.date.end, 'YYYY-MM-DD'),
          companyTypeID: config.companyType,
          director: config.director.join(','),
          statisticInterval: config.statisticInterval,
        }
      );
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'GroupProfitReport.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      setShowLoadingPopup(false);
      console.log(JSON.stringify(err));
    }
    setShowLoadingPopup(false);
  }

  const exportGroupProfitProfitExcel = async () => {
    setShowLoadingPopup(true);
    try {
      const { data } = await getGroupProfitProfitExcel(
        {
          startDate: formatTime(config.date.start, 'YYYY-MM-DD'),
          endDate: formatTime(config.date.end, 'YYYY-MM-DD'),
          companyTypeID: config.companyType,
          director: config.director.join(','),
          statisticInterval: config.statisticInterval,
        }
      );
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'GroupProfitProfitReport.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      setShowLoadingPopup(false);
      console.log(JSON.stringify(err));
    }
    setShowLoadingPopup(false);
  }

  const exportGroupProfitProfitPieExcel = async () => {
    setShowLoadingPopup(true);
    try {
      const { data } = await getGroupProfitProfitPieExcel(
        {
          startDate: formatTime(config.date.start, 'YYYY-MM-DD'),
          endDate: formatTime(config.date.end, 'YYYY-MM-DD'),
          companyTypeID: config.companyType,
          director: config.director.join(','),
          statisticInterval: config.statisticInterval,
        }
      );
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'GroupProfitProfitPie.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      setShowLoadingPopup(false);
      console.log(JSON.stringify(err));
    }
    setShowLoadingPopup(false);
  }

  const exportGroupProfitUnitGrossProfitExcel = async () => {
    setShowLoadingPopup(true);
    try {
      const { data } = await getGroupProfitUnitGrossProfitExcel(
        {
          startDate: formatTime(config.date.start, 'YYYY-MM-DD'),
          endDate: formatTime(config.date.end, 'YYYY-MM-DD'),
          companyTypeID: config.companyType,
          director: config.director.join(','),
          statisticInterval: config.statisticInterval,
        }
      );
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'GroupProfitUnitGrossProfitExcel.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      setShowLoadingPopup(false);
      console.log(JSON.stringify(err));
    }
    setShowLoadingPopup(false);
  }

  const exportHandler = async () => {
    if (config.resultType === 1) {//報表
      exportGroupProfitReportExcel();
    } else if (config.resultType === 2) {//圖表
      if (config.chartType === 'revenue') {//各組營收比較長條圖
        exportgroupProfitSalesExcel();
      } else if (config.chartType === 'grossProfit') {//各組毛利比較長條圖
        exportGroupProfitProfitExcel();
      } else if (config.chartType === 'grossProfitPercentage') {//各組毛利佔比圓餅圖
        exportGroupProfitProfitPieExcel();
      } else if (config.chartType === 'unitGrossProfit') {//各組單位毛利比較長條圖
        exportGroupProfitUnitGrossProfitExcel();
      }
    }
  };

  const fetchExpenseVouchers = async (page = currentPage) => {
    setShowLoadingPopup(true);

    try {
      const { data } = await getGroupProfitReport(
        {
          startDate: formatTime(config.date.start, 'YYYY-MM-DD'),
          endDate: formatTime(config.date.end, 'YYYY-MM-DD'),
          companyTypeID: config.companyType,
          director: config.director.join(','),
          statisticInterval: "monthly",
        }
      );

      // console.log("revenueByTeamSearch.js fetchExpenseVouchers data:" + JSON.stringify(data));
      setVouchers(data);
      setTotalCount(1);
    } catch(err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const fetchGroupProfitSales = async () => {
    setShowLoadingPopup(true);

    try {
      // setDataGroupProfitSales(
      //   [
      //     {
      //       "year": "202206Ian Chao",
      //       "value": 40000,
      //       "type": "服務費總額"
      //     },
      //     {
      //       "year": "202206Ian Chao",
      //       "value": 150000,
      //       "type": "專案其它收入"
      //     },
      //     {
      //       "year": "202207Ian Chao",
      //       "value": 200000,
      //       "type": "服務費總額"
      //     },
      //     {
      //       "year": "202207Ian Chao",
      //       "value": 200000,
      //       "type": "專案其它收入"
      //     },
      //   ]
      // );
      // console.log("revenueByTeamSearch.js fetchGroupProfitSales director:" + JSON.stringify(config.director));
      const { data } = await getGroupProfitSales(
        {
          startDate: formatTime(config.date.start, 'YYYY-MM-DD'),
          endDate: formatTime(config.date.end, 'YYYY-MM-DD'),
          companyTypeID: config.companyType,
          director: config.director.join(','),
          statisticInterval: config.statisticInterval,
        }
      );
      // console.log("revenueByTeamSearch.js fetchGroupProfitSales data:" + JSON.stringify(data));
      data.sort((a, b) => a.director > b.director ? 1 : -1);
      // const mockData = [
      //   {
      //     "director": "Ian",
      //     "statisticInterval": "7",
      //     "invoiceQueryPeriod": "string",
      //     "prPriceTotal": 20000,
      //     "incomeTotal": 20000,
      //   },
      //   {
      //     "director": "Ian",
      //     "statisticInterval": "8",
      //     "invoiceQueryPeriod": "string",
      //     "prPriceTotal": 25000,
      //     "incomeTotal": 28000,
      //   },
      //   {
      //     "director": "Tina",
      //     "statisticInterval": "7",
      //     "invoiceQueryPeriod": "string",
      //     "prPriceTotal": 55000,
      //     "incomeTotal": 68000,
      //   },
      //   {
      //     "director": "Tina",
      //     "statisticInterval": "8",
      //     "invoiceQueryPeriod": "string",
      //     "prPriceTotal": 65000,
      //     "incomeTotal": 78000,
      //   },
      // ];
      data.sort((a,b) => a.statisticInterval.localeCompare(b.statisticInterval));
      var sortOutData = data.reduce((array, val) => {
        array.push({ year: val.director + val.statisticInterval, value: (val.prPriceTotal), type: "服務費總額" });
        array.push({ year: val.director + val.statisticInterval, value: (val.incomeTotal), type: "專案其它收入" });
        return array;
      }, []);
      // console.log(sortOutData);
      setDataGroupProfitSales(sortOutData);
    } catch(err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const fetchGroupProfitProfit = async () => {
    setShowLoadingPopup(true);

    try {
      // setDataGroupProfitSales(
      //   [
      //     {
      //       "year": "202206Ian Chao",
      //       "value": 40000,
      //       "type": "服務費總額"
      //     },
      //     {
      //       "year": "202206Ian Chao",
      //       "value": 150000,
      //       "type": "專案其它收入"
      //     },
      //     {
      //       "year": "202207Ian Chao",
      //       "value": 200000,
      //       "type": "服務費總額"
      //     },
      //     {
      //       "year": "202207Ian Chao",
      //       "value": 200000,
      //       "type": "專案其它收入"
      //     },
      //   ]
      // );
      const { data } = await getGroupProfitProfit(
        {
          startDate: formatTime(config.date.start, 'YYYY-MM-DD'),
          endDate: formatTime(config.date.end, 'YYYY-MM-DD'),
          companyTypeID: config.companyType,
          director: config.director.join(','),
          statisticInterval: config.statisticInterval,
        }
      );
      // console.log("revenueByTeamSearch.js fetchGroupProfitProfit() data:" + data);
      // const mockData = [
      //   {
      //     "director": "Ian",
      //     "statisticInterval": "2021/9",
      //     "invoiceQueryPeriod": "string",
      //     "invoiceTotal": 20000,
      //     "expenseTotal": 20000,
      //     "grossProfit": 10000,
      //   },
      //   {
      //     "director": "Ian",
      //     "statisticInterval": "2021/10",
      //     "invoiceQueryPeriod": "string",
      //     "invoiceTotal": 20000,
      //     "expenseTotal": 20000,
      //     "grossProfit": 20000,
      //   },
      // ];
      data.sort((a,b) => a.statisticInterval.localeCompare(b.statisticInterval));
      // var sortOutMockData = mockData.reduce((array, val) => {
      //   array.push({ year: val.director + val.statisticInterval, value: val.grossProfit, type: "公司毛利" });
      //   return array;
      // }, []);
      var sortOutData = data.reduce((array, val) => {
        array.push({ year: val.director + val.statisticInterval, value: val.grossProfit, type: "公司毛利" });
        return array;
      }, []);
      // console.log(sortOutData);
      setDataGroupProfitSales(sortOutData);
    } catch(err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const fetchGroupProfitProfitPie = async () => {
    setShowLoadingPopup(true);

    try {
      const { data } = await getGroupProfitProfitPie(
        {
          startDate: formatTime(config.date.start, 'YYYY-MM-DD'),
          endDate: formatTime(config.date.end, 'YYYY-MM-DD'),
          companyTypeID: config.companyType,
          director: config.director.join(','),
        }
      );
      // const mockData = [
      //   {
      //     "director": "Ian",
      //     "invoiceQueryPeriod": "string",
      //     "invoiceTotal": 20000,
      //     "expenseTotal": 20000,
      //     "grossProfit": 40,
      //     "invoiceTotalOther": 0,
      //     "expenseTotalOther": 0,
      //     "grossProfitOther": 60,
      //   },
      // ];
      // var sortOutMockData = mockData.reduce((array, val) => {
      //   array.push({ type: val.director, value: val.grossProfit});
      //   array.push({ type: "其它組", value: val.grossProfitOther});
      //   return array;
      // }, []);
      var sortOutData = data.reduce((array, val) => {
        array.push({ type: val.director, value: val.grossProfit});
        // array.push({ type: "其它組", value: val.grossProfitOther});
        return array;
      }, []);
      console.log(sortOutData);
      console.log("revenueByTeamSearch.js fetchGroupProfitProfitPie");
      if (data.length > 0) {
        sortOutData.push({ type: "其它組", value: data[0].grossProfitOther});
      }
      setDataPie(sortOutData);
    } catch(err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const fetchGroupProfitUnitGrossProfit = async () => {
    setShowLoadingPopup(true);

    try {
      const { data } = await getGroupProfitProfit(
        {
          startDate: formatTime(config.date.start, 'YYYY-MM-DD'),
          endDate: formatTime(config.date.end, 'YYYY-MM-DD'),
          companyTypeID: config.companyType,
          director: config.director.join(','),
          statisticInterval: config.statisticInterval,
        }
      );
      // const mockData = [
      //   {
      //     "director": "Ian",
      //     "statisticInterval": "2021/9",
      //     "invoiceQueryPeriod": "string",
      //     "invoiceTotal": 20000,
      //     "expenseTotal": 20000,
      //     "grossProfit": 10000,
      //     "projectMCount": 0,
      //     "unitGrossProfit": 5000,
      //   },
      //   {
      //     "director": "Ian",
      //     "statisticInterval": "2021/10",
      //     "invoiceQueryPeriod": "string",
      //     "invoiceTotal": 20000,
      //     "expenseTotal": 20000,
      //     "grossProfit": 10000,
      //     "projectMCount": 0,
      //     "unitGrossProfit": 10000,
      //   },
      // ];
      // var sortOutMockData = mockData.reduce((array, val) => {
      //   array.push({ year: val.director + val.statisticInterval, value: val.grossProfit, type: "單位毛利" });
      //   return array;
      // }, []);
      data.sort((a,b) => a.statisticInterval.localeCompare(b.statisticInterval));
      var sortOutData = data.reduce((array, val) => {
        array.push({ year: val.director + val.statisticInterval, value: val.grossProfit, type: "單位毛利" });
        return array;
      }, []);
      console.log(sortOutData);
      setDataGroupProfitSales(sortOutData);
    } catch(err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const initOptions = useCallback( async () => {
    dispatch(FETCH_COMPANY_TYPE_OPTIONS());
    dispatch(FETCH_DIRECTOR_OPTIONS());
  }, [dispatch]);

  useEffect(() => {
    initOptions();
  }, [initOptions]);

  return(
    <>
      <DivPanelContainer>
        <PanelRevenueByTeamSearch
          config = { config }
          companyTypeOptions = { companyTypeOptions }
          directorOptions = { directorByCompany }
          clearHandler = { clearHandler }
          exportHandler = { exportHandler }
          searchHandler = { searchHandler }
          updateConfigHandler = { updateConfigHandler }
        />
      </DivPanelContainer>
      {config.resultType === 1 && 
        <TableRevenueByTeam
          vouchers = { vouchers }
          totalCount = { totalCount }
          currentPage = { currentPage }
          pageChangedHandler = { setCurrentPageHandler }
        />
      }
      {config.resultType === 2 && (config.chartType === "revenue" || config.chartType === "grossProfit" || config.chartType === "unitGrossProfit") && 
        <Column {...chartConfig}></Column>
      }
      {config.resultType === 2 && config.chartType === "grossProfitPercentage" && 
        <Pie {...pieConfig}></Pie>
      }
      { showLoadingPopup && <PopupLoading/> }
      {
        showAlertPopup &&
          <PopupExecuteResult
            title = "請選擇欄位"
            text = { alertText }
            confirmHandler = { () => { setShowAlertPopup(false) } }
          />
      }
    </>
  );
};

export default RevenueByTeamSearch;
