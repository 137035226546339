import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { DivPopupWrapper, DivPopupXLarge } from '@/component-style/Popup';
import { ButtonCancel, ButtonBasic } from '@/component-style/Button';
// import { DivFlexRow } from '@/component-style/RWDWrapper';
import { InputBasic } from '@/component-style/Input';
import SelectSearchable from '@/components/UI/Select/SelectSearchable';

import PopupLoading from '@/components/UI/Popup/PopupLoading';

import { PSubtitle } from '@/component-style/TextElement';
import { integerConverter, floatConverter } from '@/utils/textUtils';
import MyInputNumber from '@/components/UI/MyInputNumber';
import { cloneDeep } from 'lodash';
import { leaveParamsConfig, leaveGiveHourParamsConfig, leaveApprovalParamsConfig } from '@/constant/dataConfig/sysConfigModule.js';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import { leaveTypeApplicableForOptions, basicBooleanOptions, leaveTypePostExpiryActionOptions, leaveTypGiveDurationTypeOptions, projectRolesOptions } from '@/constant/options';
import TableWrapper from '@/components/UI/TableWrapper';
import PanelAddWageStructure from '@/components/Panel/Wage/PanelAddWageStructure';

import {
  DivFullWidthWrapper,
} from '@/component-style/RWDWrapper';

import { TableHead, TableRow, ThItem } from '@/component-style/Table';

import TableWageStructure from '@/components/Table/TableWageStructure';


import {
  // postCreateLeaveSetting,
  patchLeaveSetting,
  postCreateLeaveGiveSetting,
  deleteLeaveGiveSetting,
  postCreateLeaveGiveHourSetting,
  postCreateLeave,
  patchUpdateLeave,
  postAddLeaveGiveHour,
  getLeaveGiveHourById,
  deleteRemoveLeaveGiveHours,
  putSaveLeaveApprovalSetting,
  deleteLeaveApprovalSetting,
  getPaycheckStructures,
} from '@/api/financeService';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import {
  DivFlexRow,
  DivItemWrapper,
} from '@/component-style/RWDWrapper';

const THead = styled(TableHead)`
  min-width: 1560px;
`;

const Th120 = styled(ThItem)`
  width: 120px;
`;

const DivButtonsWrapper = styled.div`
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const H3Title = styled.h3`
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: #8b8b8b;
  margin-bottom: 10px;
`;

const ButtonDiscard = styled(ButtonCancel)`
  width: 120px;
  margin-right: 10px;
`;

const ButtonSave = styled(ButtonBasic)`
  width: 120px;

  &:first-child { margin-right: 10px }
`;

const DivWrapper = styled.div`
  width: 100%;
  
  @media (min-width: 768px) {
    width: calc(100% - 135px);
  }
`;

var existingGiveHourIds = [];

const PopupAddOrEditWageStructure = props => {
  const dispatch = useDispatch();
  const showSaveFailPopup = useSelector(state => state.apiFail.showSaveFailPopup);
  const apiFailPopupTitle = useSelector(state => state.apiFail.title);
  const apiFailPopupMessage = useSelector(state => state.apiFail.message);
  
  const [leaveId, setLeaveId] = useState("");
  const [leave, setLeave] = useState(cloneDeep(leaveParamsConfig));

  const [leaveGiveHour, setLeaveGiveHour] = useState([]);
  const [leaveApproval, setLeaveApproval] = useState([]);

  const [paycheckStructures, setPaycheckStructures] = useState([]);
  const [modifyPaycheckStructureId, setModifyPaycheckStructureId] = useState("");
  
  const [showSaveSuccessPopup, setShowSaveSuccessPopup] = useState(false);
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);

  const updateLeaveParamsHandler = (key, value) => {
    const updateObj = { ...leave };
    updateObj[key] = value;
    setLeave({ ...updateObj });
  };

  const updateLeaveApprovalParamsHandler = (index, key, value) => {
    console.log("PoupEditLeaveType.js updateLeaveApprovalParamsHandler() index:" + JSON.stringify(index) + " key:" + JSON.stringify(key) + " value:" + JSON.stringify(value));
    const updateObj = [ ...leaveApproval ];
    updateObj.forEach(function (element, i) {
      console.log("PoupEditLeaveType.js updateLeaveApprovalParamsHandler() i:" + JSON.stringify(i));
      if (i === index) {
        element[key] = value;
      }
    });
    setLeaveApproval([...updateObj]);
  };

  const addLeaveApproval = () => {
    console.log("PoupEditLeaveType.js addLeaveApproval() ");
    setLeaveApproval([...leaveApproval, cloneDeep(leaveApprovalParamsConfig)]);
  }

  const deleteLeaveApproval = (toDeleteIndex) => {
    console.log("PoupEditLeaveType.js deleteGiveHour() ");
    const result = leaveApproval.filter((element, i) => i !== toDeleteIndex);
    setLeaveApproval([...result]);
  }

  const updateLeaveGiveHourParamsHandler = (index, key, value) => {
    console.log("PoupEditLeaveType.js updateLeaveGiveHourParamsHandler() index:" + JSON.stringify(index) + " key:" + JSON.stringify(key));
    const updateObj = [ ...leaveGiveHour ];
    updateObj.forEach(function (element, i) {
      console.log("PoupEditLeaveType.js updateLeaveGiveHourParamsHandler() i:" + JSON.stringify(i));
      if (i === index) {
        element[key] = value;
      }
    });
    setLeaveGiveHour([...updateObj]);
  };

  const addGiveHour = () => {
    console.log("PoupEditWageStructure.js addGiveHour() ");
    setLeaveGiveHour([...leaveGiveHour, cloneDeep(leaveGiveHourParamsConfig)]);
  }

  const deleteGiveHour = (toDeleteIndex) => {
    console.log("PoupEditWageStructure.js deleteGiveHour() ");
    const result = leaveGiveHour.filter((element, i) => i !== toDeleteIndex);
    setLeaveGiveHour([...result]);
  }

  const saveHandler = async () => {
    console.log("PoupEditWageStructure.js saveHandler() leaveSettingConfig:" + JSON.stringify(leave));
    var tempLeaveId = leaveId;
    try {
      if (tempLeaveId === "") {
        const { data } = await postCreateLeave({
          ...leave,
        });
        setLeaveId(data.id);
        tempLeaveId = data.id;
      } else {
        delete leave.id;
        await patchUpdateLeave(tempLeaveId, 
          {
            ...leave,
          }
        );
        console.log("PoupEditLeaveType.js saveHandler() JSON.stringify(existingGiveHourIds):" + JSON.stringify(existingGiveHourIds));
        if (existingGiveHourIds.length > 0) {
          await deleteRemoveLeaveGiveHours(existingGiveHourIds);
        }
        if (leaveApproval.length > 0) {
          await deleteLeaveApprovalSetting(tempLeaveId);
        }
      }
      console.log("PoupEditLeaveType.js saveHandler() JSON.stringify(leaveGiveHour):" + JSON.stringify(leaveGiveHour));
      if (leaveGiveHour.length > 0) {
        const { data } = await postAddLeaveGiveHour(tempLeaveId, leaveGiveHour);
        console.log("PoupEditLeaveType.js saveHandler() JSON.stringify(data):" + JSON.stringify(data));
      }

      const listApproval = leaveApproval.map((element)=> { return element.approvalSequence; });
      if (listApproval.length > 0) {
        const dataApproval = await putSaveLeaveApprovalSetting(tempLeaveId, listApproval);
        console.log("PoupEditLeaveType.js saveHandler() JSON.stringify(dataApproval):" + JSON.stringify(dataApproval));
      }
      
      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '儲存失敗', err }));
      console.log(err);
    }
  };

  const editPaycheckStructureHandler = (modifyPaycheckStructureId) => {
    console.log(`PopupEditWageStructure.js editPaycheckStructureHandler() modifyPaycheckStructureId=${JSON.stringify(modifyPaycheckStructureId)}`);
    setModifyPaycheckStructureId(modifyPaycheckStructureId);
  }

  const clearModifyPaycheckStructureId = () => {
    setModifyPaycheckStructureId("");
  }

  const fetchPaycheckStructures = async () => {
    setShowLoadingPopup(true);

    try {
      console.log(`PopupEditWageStructure.js fetchPaycheckStructures() props.editUserId=${props.editUserId}`);
      const searchParameters = {
        userID: props.editUserId,
        orderBy: "applyDate_desc",
      }
      const { data } = await getPaycheckStructures(searchParameters);
      setPaycheckStructures(data.paycheckStructures);
      console.log(`PopupEditWageStructure.js fetchPaycheckStructures() data=${JSON.stringify(data)}`);
      const tempPaycheckStructures = data.paycheckStructures.filter((element) => {
        return element.userId === props.editUserId;
      });
      console.log("PopupEditWageStructure.js fetchPaycheckStructures() tempPaycheckStructures:" + JSON.stringify(tempPaycheckStructures));
      setPaycheckStructures(tempPaycheckStructures);
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const reloadHandler = () => {
    console.log(`PopupEditWageStructure.js reloadHandler()`);
    // fetchPaycheckStructures();
    props.reloadPaycheckStructuresHandler();
  };

  const cancelHandler = () => {
    console.log(`PopupEditWageStructure.js cancelHandler()`);
    // fetchPaycheckStructures();
    props.cancelHandler();
  };

  const updateData = async () => {
    console.log("PoupEditWageStructure.js updateData() props.editUserId:" + JSON.stringify(props.editUserId));
    fetchPaycheckStructures();
  };

  const initData = useCallback(
    updateData
  , []
  );

  useEffect(() => {
    initData();
  }, [initData]);

  return (
    <DivPopupWrapper>
      <DivPopupXLarge>
        <DivFlexRow>
          <PanelAddWageStructure
            editUserId = { props.editUserId }
            modifyPaycheckStructureId = { props.modifyPaycheckStructureId }
            clearModifyPaycheckStructureId = { clearModifyPaycheckStructureId }
            reloadHandler = { reloadHandler }
            cancelHandler = { cancelHandler }
          />
        </DivFlexRow>
        {/* <DivFlexRow>
          &nbsp;
        </DivFlexRow>
        <DivFlexRow>
          &nbsp;
        </DivFlexRow>
        <DivFlexRow>
          <DivButtonsWrapper>
            <div>&nbsp;</div>
            <ButtonDiscard
              onClick = { () => { props.cancelHandler(); } }
            >離開</ButtonDiscard>
          </DivButtonsWrapper>
        </DivFlexRow> */}
      </DivPopupXLarge>
    </DivPopupWrapper>
  );
};

export default PopupAddOrEditWageStructure;
