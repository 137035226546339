import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

const DivLangSelector = styled.div`
  display: flex;
  color: #8b8b8b;
`;

const PLang = styled.p`
  margin: 0;
  margin-right: 10px;
  font-size: 13px;
  height: 15px;
  line-height: 15px;
`;

const LangSelector = props => (
  <DivLangSelector>
    <PLang>繁體中文</PLang>
    <FontAwesomeIcon
      icon = { faAngleDown }
    />
  </DivLangSelector>
);

export default LangSelector;
