import axiosConfig from './axiosConfig';
import { getDefaultPort } from '@/utils/apiRequestUtils';

const { baseURL } = axiosConfig.defaults;

const port = process.env.REACT_APP_SERVICE_PORT_CONFIG !== undefined
  ? process.env.REACT_APP_SERVICE_PORT_CONFIG
  : getDefaultPort(baseURL);

const sysConfigModuleUrl = process.env.NODE_ENV === 'development'
  ? `${baseURL}:${port}/api`
  : `${baseURL}/sysConfig/api`;

export const postToGetDepartment = config => axiosConfig
  .post(`${sysConfigModuleUrl}/sysConfig/department`, config);

export const putNewDepartment = config => axiosConfig
  .put(`${sysConfigModuleUrl}/sysConfig/department`, config);

export const patchModifyDepartment = config => axiosConfig
  .patch(`${sysConfigModuleUrl}/sysConfig/department`, config);

export const deleteDepartment = ids => axiosConfig
  .delete(`${sysConfigModuleUrl}/sysConfig/department`, { data: [...ids] });

export const postToGetCompanyType = config => axiosConfig
  .post(`${sysConfigModuleUrl}/sysConfig/companyType`, config);

export const putNewCompanyType = config => axiosConfig
  .put(`${sysConfigModuleUrl}/sysConfig/companyType`, config);

export const patchModifyCompanyType = config => axiosConfig
  .patch(`${sysConfigModuleUrl}/sysConfig/companyType`, config);

export const deleteCompanyType = ids => axiosConfig
  .delete(`${sysConfigModuleUrl}/sysConfig/companyType`, { data: [...ids] });

export const postToGetSpecialFeature = config => axiosConfig
  .post(`${sysConfigModuleUrl}/sysConfig/specialFeature`, config);

export const putNewSpecialFeature = config => axiosConfig
  .put(`${sysConfigModuleUrl}/sysConfig/specialFeature`, config);

export const patchModifySpecialFeature = config => axiosConfig
  .patch(`${sysConfigModuleUrl}/sysConfig/specialFeature`, config);

export const deleteSpecialFeature = ids => axiosConfig
  .delete(`${sysConfigModuleUrl}/sysConfig/specialFeature`, { data: [...ids] });

export const postToGetDeclareType = config => axiosConfig
  .post(`${sysConfigModuleUrl}/sysConfig/declareType`, config);

export const putNewDeclareType = config => axiosConfig
  .put(`${sysConfigModuleUrl}/sysConfig/declareType`, config);

export const patchModifyDeclareType = config => axiosConfig
  .patch(`${sysConfigModuleUrl}/sysConfig/declareType`, config);

export const deleteDeclareType = ids => axiosConfig
  .delete(`${sysConfigModuleUrl}/sysConfig/declareType`, { data: [...ids] });

export const postToGetHolidaysParams = config => axiosConfig
  .post(`${sysConfigModuleUrl}/sysConfig/holidaysDetail`, config);

export const putNewHoliday = config => axiosConfig
  .put(`${sysConfigModuleUrl}/sysConfig/holidaysDetail`, config);

export const patchModifyHolidaysParams = config => axiosConfig
  .patch(`${sysConfigModuleUrl}/sysConfig/holidaysDetail`, config);

export const getWorkday = config => axiosConfig
  .get(`${sysConfigModuleUrl}/sysConfig/workday`, config);

export const putWorkday = config => axiosConfig
  .put(`${sysConfigModuleUrl}/sysConfig/workday`, config);