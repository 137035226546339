import React from 'react';
import styled from 'styled-components';

import { TdItem, TableRow } from '@/component-style/Table';
import { formatTime } from '@/utils/timeUtils';

import { decimalCommaConverter } from '@/utils/textUtils';

const TrWrapper = styled(TableRow)`
  border-bottom: solid 2px #f2f2f2;
  box-sizing: border-box;
  min-width: 1240px;

  &:last-child {
    border-bottom: none;
  }
`;

const Td120 = styled(TdItem)`
  width: 120px;
`;

const Td160 = styled(TdItem)`
  width: 160px;
`;

const Item = props => {
  const { invoice } = props;

  const invoiceDate = formatTime(invoice.createDate, 'YYYY/MM/DD');

  return (
    <TrWrapper>
      <Td120>{ invoice.customerTaxId }</Td120>
      <Td160>{ invoice.customerName }</Td160>
      <Td120>{ invoice.projectCode }</Td120>
      <Td120>{ invoice.invoiceCode }</Td120>
      <Td120>{ invoice.invoiceTaxID }</Td120>
      <Td120>{ invoiceDate || '' }</Td120>
      <Td120>
        { invoice.untaxedAmount == null ? "$ 0" : decimalCommaConverter(invoice.untaxedAmount) }
      </Td120>
      <Td120>
        { invoice.tax == null ? "$ 0" : decimalCommaConverter(invoice.tax) }
      </Td120>
      <Td120>
        { invoice.amount == null ? "$ 0" : decimalCommaConverter(invoice.amount) }
      </Td120>
      <Td120>
        { invoice.accountingExported ? '是' : '否' }
      </Td120>
    </TrWrapper>
  );
};

export default Item;
