import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import {
  patchProjectType,
  deleteProjectType,
  putNewProjectType,
  postToGetProjectTypes,
} from '@/api/projectService';

import {
  deleteCustomerIndustry,
  putNewCustomerIndustry,
  postToGetCustomerIndustry,
  patchModifyCustomerIndustry,
} from '@/api/customerService';

import {
  deletJournalistType,
  patchJournalistType,
  putNewJournalistTypes,
  postToGetJournalistTypes,
} from '@/api/journalistService';

import {
  patchMediaType,
  deleteMediaType,
  putNewMediaType,
  postToGetMediaTypes,
} from '@/api/mediaService';

import {
  putNewDepartment,
  deleteDepartment,
  postToGetDepartment,
  patchModifyDepartment,
  postToGetCompanyType,
  putNewCompanyType,
  patchModifyCompanyType,
  deleteCompanyType,
  postToGetSpecialFeature,
  putNewSpecialFeature,
  patchModifySpecialFeature,
  deleteSpecialFeature,
  postToGetDeclareType,
  putNewDeclareType,
  patchModifyDeclareType,
  deleteDeclareType,
} from '@/api/sysConfigService.js';

import MainContent from '@/component-style/MainContent';
import PanelWrapper from '@/components/UI/PanelWrapper';
import TableCommonTypes from '@/components/Table/SysConfig/TableCommonTypes';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import PopupExecuteConfirm from '@/components/UI/Popup/PopupExecuteConfirm';
import PopupCommonTypeDetail from '@/components/UI/Popup/PopupCommonTypeDetail';
import SelectSearchable from '@/components/UI/Select/SelectSearchable';
import SearchButtonSet from '@/components/UI/SearchButtonSet';
import { PSubtitle } from '@/component-style/TextElement';
import { ButtonBasic } from '@/component-style/Button';

import { commonTypeCategorys } from '@/constant/options';

import {
  DivFlexRow,
  DivFullWidthWrapper,
  DivLongMultiSelector,
} from '@/component-style/RWDWrapper';

const DivPanelContainer = styled.div`
  margin-bottom: 40px;
`;

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding: 0;
  justify-content: space-between;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const ButtonAdd = styled(ButtonBasic)`
  margin-right: 10px;
  width: 120px;

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`;

const typeConfig = {
  id: '',
  category: '',
  name: '',
  enable: true,
};

const fetchTypeServiceDict = {
  companyType: postToGetCompanyType,
  declareType: postToGetDeclareType,
  department: postToGetDepartment,
  industry: postToGetCustomerIndustry,
  journalistType: postToGetJournalistTypes,
  mediaType: postToGetMediaTypes,
  projectType: postToGetProjectTypes,
  specialFeature: postToGetSpecialFeature,
};

const createTypeServiceDict = {
  companyType: putNewCompanyType,
  declareType: putNewDeclareType,
  department: putNewDepartment,
  industry: putNewCustomerIndustry,
  journalistType: putNewJournalistTypes,
  mediaType: putNewMediaType,
  projectType: putNewProjectType,
  specialFeature: putNewSpecialFeature,
};

const updateTypeServiceDict = {
  companyType: patchModifyCompanyType,
  declareType: patchModifyDeclareType,
  department: patchModifyDepartment,
  industry: patchModifyCustomerIndustry,
  journalistType: patchJournalistType,
  mediaType: patchMediaType,
  projectType: patchProjectType,
  specialFeature: patchModifySpecialFeature,
};

const deleteTypeServiceDict = {
  companyType: deleteCompanyType,
  declareType: deleteDeclareType,
  department: deleteDepartment,
  industry: deleteCustomerIndustry,
  journalistType: deletJournalistType,
  mediaType: deleteMediaType,
  projectType: deleteProjectType,
  specialFeature: deleteSpecialFeature,
};

const CommonTypeManagement = () => {
  const dispatch = useDispatch();
  const sysConfigRoutes = useSelector(state => state.login.sysConfigRoutes);
  const showSaveFailPopup = useSelector(state => state.apiFail.showSaveFailPopup);
  const apiFailPopupTitle = useSelector(state => state.apiFail.title);
  const apiFailPopupMessage = useSelector(state => state.apiFail.message);

  const [types, setTypes] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [category, setCategory] = useState('');
  const [typeDetail, setTypeDetail] = useState({ ...typeConfig });

  const [isAdding, setIsAdding] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [showAddTypePopup, setShowAddTypePopup] = useState(false);
  const [showTypeDetailPopup, setShowTypeDetailPopup] = useState(false);
  const [showSaveSuccessPopup, setShowSaveSuccessPopup] = useState(false);
  const [showDeleteSuccessPopup, setShowDeleteSuccessPopup] = useState(false);

  const userAddable = sysConfigRoutes.indexOf('CommonTypeManagement-Add') !== -1;
  const userEditable = sysConfigRoutes.indexOf('CommonTypeManagement-Edit') !== -1;
  const userDeletable = sysConfigRoutes.indexOf('CommonTypeManagement-Delete') !== -1;

  const selectedItemNumber = types
    .filter(type => type.isChecked).length;

  const updateTypeDetailHandler = (key, value) => {
    const updateObj = { ...typeDetail };
    updateObj[key] = value;
    setTypeDetail({...updateObj});
  };

  const clearHandler = () => {
    setCategory('');
    setTypes([]);
  };

  const setCurrentPageHandler = page => {
    setCurrentPage(page);
    updateTypes(page);
  };

  const searchHandler = () => {
    setCurrentPageHandler(1);
  };

  const typeCheckedHandler = (index, value) => {
    const updateTypes = [...types];
    updateTypes[index].isChecked = value;
    setTypes([...updateTypes]);
  };

  const selectAllHandler = value => {
    setSelectAll(value);
    const updateTypes = types
      .map(type => ({ ...type, isChecked: value }));
    setTypes([...updateTypes]);
  };

  const createTypeHandler = () => {
    setIsAdding(true);
    setTypeDetail({ ...typeConfig });
    setShowAddTypePopup(true);
  };

  const cancelCreateHandler = () => {
    setIsAdding(false);
    setShowAddTypePopup(false);
  };

  const showTypeDetailHandler = index => {
    setTypeDetail({ ...types[index] });
    setShowTypeDetailPopup(true);
  };

  const openTypeDetailPopup = (isOpen, createPopup = isAdding) => {
    createPopup
      ? setShowAddTypePopup(isOpen)
      : setShowTypeDetailPopup(isOpen)
  };

  const apiFailConfirmHandler = () => {
    dispatch(CLOSE_SAVE_FAIL_POPUP());

    apiFailPopupTitle === '儲存失敗'
      && openTypeDetailPopup(true);
  };

  const closeSaveSuccessPopupHandler = () => {
    setShowAddTypePopup(false);
    setShowTypeDetailPopup(false);
    setShowSaveSuccessPopup(false);
    searchHandler(1);
  };

  const closeDeleteSuccessPopupHandler = () => {
    setShowDeleteSuccessPopup(false);
    searchHandler();
  };

  const deleteTypesHandler = async () => {
    const category = types.map(({ category }) => category).pop();
    const serviceAPI = deleteTypeServiceDict[category];
    if (serviceAPI === undefined) return;
    setShowDeletePopup(false);
    setShowLoadingPopup(true);

    try {
      const seletedTypeIDs = types
        .filter(type => type.isChecked)
        .map(type => {
          const idKey = Object.keys(type)
            .filter(key => key.match(/id$/ig));
          return type[idKey];
        });

      await serviceAPI([...seletedTypeIDs]);
      setShowLoadingPopup(false);
      setShowDeleteSuccessPopup(true);
    } catch (err) {
      console.log(err);
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '刪除失敗', err }));
    }
  };

  const createNewType = async () => {
    const { category } = typeDetail;
    const serviceAPI = createTypeServiceDict[category];
    if (serviceAPI === undefined) return;
    setShowAddTypePopup(false);
    setShowLoadingPopup(true);

    try {
      await serviceAPI({ ...typeDetail });
      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
    } catch (err) {
      console.log(err);
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '儲存失敗', err }));
    }
  };

  const updateTypeDetail = async () => {
    const { category } = typeDetail;
    const serviceAPI = updateTypeServiceDict[category];
    if (serviceAPI === undefined) return;
    setShowTypeDetailPopup(false);
    setShowLoadingPopup(true);

    try {
      await serviceAPI({ ...typeDetail });
      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
    } catch (err) {
      console.log(err);
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '儲存失敗', err }));
    }
  };

  const updateTypes = async (page = currentPage) => {
    const serviceAPI = fetchTypeServiceDict[category];
    if (serviceAPI === undefined) return;
    setShowLoadingPopup(true);    

    try {
      const { data } = await serviceAPI({ page, amount: 10 });
      const typeKey = Object.keys(data)
        .filter(key => key !== 'totalCount');

      const types = data[typeKey]
        .map(type => ({
          ...type,
          category,
          isChecked: false,
          enable: type.enable || false,
        }));
      
      setTypes(types);
      setTotalCount(data.totalCount || 0);
    } catch (err) { console.log(err ) }

    setShowLoadingPopup(false);
  };

  return (
    <MainContent>
      <DivPanelContainer>
        <PanelWrapper title = "共用參數管理">
          <DivFlexRow>
            <DivFullWidthWrapper>
              <PSubtitle>參數類別</PSubtitle>
              <DivLongMultiSelector>
                <SelectSearchable
                  value = { category }
                  options = {[
                    { value: '', desc: '請選擇參數類別' },
                    ...commonTypeCategorys,
                  ]}
                  changeHandler = { setCategory }
                />
              </DivLongMultiSelector>
            </DivFullWidthWrapper>
          </DivFlexRow>
          <DivFlexRow>
            <DivButtonsWrapper>
              <ButtonAdd
                disabled = { !userAddable }
                onClick = { createTypeHandler }
              >新增</ButtonAdd>
              <SearchButtonSet
                searchable = { category !== '' }
                clearHandler = { clearHandler }
                searchHandler = { searchHandler }
              />
            </DivButtonsWrapper>
          </DivFlexRow>
        </PanelWrapper>
      </DivPanelContainer>
      <TableCommonTypes
        disableDelete = { selectedItemNumber === 0 || !userDeletable }
        title = "共用參數列表"
        types = { types }
        selectAll = { selectAll }
        categorys = { commonTypeCategorys }
        selectAllHandler = { selectAllHandler }
        totalCount = { totalCount }
        currentPage = { currentPage }
        checkHandler = { typeCheckedHandler }
        showTypeDetailHandler = { showTypeDetailHandler }
        deleteHandler = { () =>  { setShowDeletePopup(true) }}
        pageChangedHandler = { value => {
          selectAllHandler(false);
          setCurrentPageHandler(value);
        }}
      />
      { showLoadingPopup && <PopupLoading/> }
      {
        showTypeDetailPopup &&
          <PopupCommonTypeDetail
            disableName = { true }
            disableCategory = { true }
            disableSave = { !userEditable }
            type = { typeDetail }
            options = { commonTypeCategorys }
            updateTypeDetailHandler = { updateTypeDetailHandler }
            cancelHandler = { () => { setShowTypeDetailPopup(false) }}
            confirmHandler = { updateTypeDetail }
          />
      }
      {
        showAddTypePopup &&
          <PopupCommonTypeDetail
            type = { typeDetail }
            options = { commonTypeCategorys }
            updateTypeDetailHandler = { updateTypeDetailHandler }
            cancelHandler = { cancelCreateHandler }
            confirmHandler = { createNewType }
          />
      }
      {
        showDeletePopup &&
          <PopupExecuteConfirm
            title = "刪除共用參數"
            text = { `確定刪除${ selectedItemNumber }個項目` }
            confirmHandler = { deleteTypesHandler }
            cancelHandler = { () => {
              setShowDeletePopup(false);
            }}
          />
      }
      {
        showDeleteSuccessPopup &&
          <PopupExecuteResult
            title = "刪除成功"
            confirmHandler = { closeDeleteSuccessPopupHandler }
          />
      }
      {
        showSaveSuccessPopup &&
          <PopupExecuteResult
            title = "儲存成功"
            confirmHandler = { closeSaveSuccessPopupHandler }
          />
      }
      {
        showSaveFailPopup &&
          <PopupExecuteResult
            title = { apiFailPopupTitle }
            text = { apiFailPopupMessage }
            confirmHandler = { apiFailConfirmHandler }
          />
      }
    </MainContent>
  );
};

export default CommonTypeManagement;
