import React from 'react';
import { DatePicker } from 'antd';
import 'antd/dist/antd.css';
import './index.css';
import moment from 'moment';

const periodTimeFormatDict = {
  year: 'YYYY',
  month: 'YYYY-MM',
};

const DateSelectorTwo = props => {
  const normalPickerFormat = props.showTime
    ? 'YYYY/MM/DD HH:mm'
    : 'YYYY/MM/DD';
  
  const dateFormat = props.picker === undefined
    ? normalPickerFormat
    : periodTimeFormatDict[props.picker]

  const dataValue = props.value === null ? null : moment(props.value);

  const changeHandler = value => {
    const date = value === null
      ? null
      : value.toDate()

    props.changeHandler(date);
  };

  const handleOK = value => {
    const date = value === null
      ? null
      : value.toDate()

    props.okHandler(date);
  };

  return(
    <DatePicker
      picker = { props.picker }
      format = { dateFormat }
      value = { dataValue }
      className = { 'datePickerTwo' }
      showNow = { false }
      showTime = { props.showTime }
      disabledDate = { props.disabledDate }
      onChange = { changeHandler }
      onOk = { handleOK }
      disabled = { props.disabled }
      allowClear = { false }
    />
  )
};

export default DateSelectorTwo;
