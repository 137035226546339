export const devAPIModuleDict = {
  [process.env.REACT_APP_SERVICE_PORT_AUTH]: 'auth',
  [process.env.REACT_APP_SERVICE_PORT_PROJECT]: 'project',
  [process.env.REACT_APP_SERVICE_PORT_PROJECT_TODO]: 'projectTodo',
  [process.env.REACT_APP_SERVICE_PORT_ACTIVITY_IDEA]: 'activityIdea',
  [process.env.REACT_APP_SERVICE_PORT_CUSTOMER]: 'customer',
  [process.env.REACT_APP_SERVICE_PORT_MEDIA]: 'media',
  [process.env.REACT_APP_SERVICE_PORT_VENUE]: 'venue',
  [process.env.REACT_APP_SERVICE_PORT_VENDOR]: 'vendor',
  [process.env.REACT_APP_SERVICE_PORT_JOURNALIST]: 'journalist',
  [process.env.REACT_APP_SERVICE_PORT_STORAGE]: 'storage',
  [process.env.REACT_APP_SERVICE_PORT_ATTENDANCE]: 'attendance',
  [process.env.REACT_APP_SERVICE_PORT_EXPENSE]: 'expense',
  [process.env.REACT_APP_SERVICE_PORT_FINANCE]: 'finance',
  [process.env.REACT_APP_SERVICE_PORT_CONFIG]: 'config',
};

export const APIErrorMsgDict = {
  auth: {
    400: '部分欄位格式不符，請重新確認。',
    422: '部分欄位格式不符，請重新確認。',
    602: '部分欄位未填，請重新確認。',
    603: '部分欄位格式不符，請重新確認。',
    604: '帳號未填，請確認。',
    1600: 'email 格式不正確，請重新確認。',
    1601: '帳號格式不正確，請重新確認。',
    1602: '密碼強度太低，請使用安全性較高的密碼。',
    1604: '同仁編號格式不正確，請重新確認。',
    1605: '帳號不存在。',
    1606: '密碼錯誤，請重新確認。',
    1607: '使用者已被鎖定，請洽系統管理員。',
    1609: '系統角色名稱已重複，請確認。',
    1610: '系統角色編號已重複，請確認。',
    1611: '系統角色使用中，無法刪除。',
  },
  project: {
    400: '部分欄位格式不符，請重新確認。',
    602: '專案代碼未填，請確認。',
    603: '部分欄位格式不符，請重新確認。',
    800: '專案類型不存在，請確認。',
    801: 'ProjectID 異常，請 IT 確認相關資料。',
    802: '專案代碼重複，請使用新的專案代碼。',
    803: '專案類型名稱已重複，請確認。',
    804: '專案類型使用中，無法刪除。',
  },
  projectTodo: {
    400: '部分欄位格式不符，請重新確認。',
    422: '部分欄位格式不符，請重新確認。',
    602: '部分欄位未填，請重新確認。',
    603: '部分欄位格式不符，請重新確認。',
    1800: '分類細項名稱已重複，請確認。',
    1801: '分類名稱已重複，請確認。',
    1802: '進度分類名稱不存在，請確認。',
    1803: '進度分類使用中，無法刪除。',
  },
  customer: {
    400: '部分欄位格式不符，請重新確認。',
    422: '部分欄位格式不符，請重新確認。',
    602: '部分欄位未填，請重新確認。',
    603: '部分欄位格式不符，請重新確認。',
    1200: '產業類別不存在，請確認。',
    1201: 'CustomerID 異常，請 IT 確認相關資料。',
    1202: '客戶代號重複，請使用新的客戶代號。',
    1203: '產業類別名稱已重複，請確認。',
    1204: '產業類別使用中，無法刪除。',
  },
  media: {
    400: '部分欄位格式不符，請重新確認。',
    422: '部分欄位格式不符，請重新確認。',
    602: '部分欄位未填，請重新確認。',
    603: '部分欄位格式不符，請重新確認。',
    1700: 'MediaTypeID 異常，請 IT 確認相關資料。',
    1701: 'MediaID 異常，請 IT 確認相關資料。',
    1702: '媒體類別名稱已重複，請確認。',
    1703: '媒體類別使用中，無法刪除。',
    1704: '媒體使用中，無法刪除。',
  },
  venue: {
    400: '部分欄位格式不符，請重新確認。',
    422: '部分欄位格式不符，請重新確認。',
    602: '部分欄位未填，請重新確認。',
    603: '部分欄位格式不符，請重新確認。',
    2100: 'VenueID 異常，請 IT 確認相關資料。',
    2101: '場地參數使用中，無法刪除。',
    2102: 'VenueTypeID 異常，請 IT 確認相關資料。',
    2103: '場地設備名稱已重複，請確認。',
    2104: '設備使用中，無法刪除。',
    2105: 'EquipmentID 異常，請 IT 確認相關資料。',
    2106: '場地特色名稱已重複，請確認。',
    2107: '場地特色使用中，無法刪除。',
    2108: 'CharacteristicID 異常，請 IT 確認相關資料。',
    2109: '場地類別名稱已重複，請確認。',
    2110: '活動場地使用中，無法刪除。',
  },
  vendor: {
    400: '部分欄位格式不符，請重新確認。',
    422: '部分欄位格式不符，請重新確認。',
    602: '部分欄位未填，請重新確認。',
    603: '部分欄位格式不符，請重新確認。',
    2000: '分類細項名稱已重複，請確認。',
    2001: '分類名稱已重複，請確認。',
    2002: '廠商分類名稱不存在，請確認。',
    2003: '廠商分類使用中，無法刪除。',
  },
  activityIdea: {
    400: '部分欄位格式不符，請重新確認。',
    422: '部分欄位格式不符，請重新確認。',
    602: '部分欄位未填，請重新確認。',
    603: '部分欄位格式不符，請重新確認。',
    900: '分類細項名稱已重複，請確認。',
    901: '分類名稱已重複，請確認。',
    902: '活動創意分類名稱不存在，請確認。',
    903: '活動創意分類使用中，無法刪除。',
  },
  journalist: {
    400: '部分欄位格式不符，請重新確認。',
    422: '部分欄位格式不符，請重新確認。',
    602: '部分欄位未填，請重新確認。',
    603: '部分欄位格式不符，請重新確認。',
    1500: '記者路線不存在，請確認。',
    1501: 'JournalistID異常，請 IT 確認相關資料。',
    1503: '記者路線名稱已重複，請確認。',
    1504: '記者路線使用中，無法刪除。',
    1505: '記者使用中，無法刪除。',
  },
  storage: {
    400: '部分欄位格式不符，請重新確認。',
    422: '部分欄位格式不符，請重新確認。',
    602: '部分欄位未填，請重新確認。',
    603: '部分欄位格式不符，請重新確認。',
    1900: '檔案大小超過上限，請確認。',
    1903: '無法下載，該檔案已損毀。',
  },
  attendance: {
    400: '部分欄位格式不符，請重新確認。',
    422: '部分欄位格式不符，請重新確認。',
    602: '部分欄位未填，請重新確認。',
    603: '部分欄位格式不符，請重新確認。',
    1000: '打卡資料重複，，請確認。',
    1001: '工時分類細項名稱已重複，請確認。',
    1002: '工時分類名稱已重複，請確認。',
    1003: '工時分類名稱不存在，請確認。',
    1004: '工時分類使用中，無法刪除。',
  },
  expense: {
    400: '部分欄位格式不符，請重新確認。',
    422: '部分欄位格式不符，請重新確認。',
    602: '部分欄位未填，請重新確認。',
    603: '部分欄位格式不符，請重新確認。',
    1300: '分類細項名稱已重複，請確認。',
    1301: '分類名稱已重複，請確認。',
    1304: '會計分類使用中，無法刪除。',
  },
  finance: {
    400: '部分欄位格式不符，請重新確認。',
    422: '部分欄位格式不符，請重新確認。',
    602: '部分欄位未填，請重新確認。',
    603: '部分欄位格式不符，請重新確認。',
    1400: '此專案無法使用，請 IT 確認相關資料。',
    1401: '此客戶無法使用，請 IT 確認相關資料。',
    1402: '所屬公司無法使用，請 IT 確認相關資料。',
    1403: '專案重複，請 IT 確認相關資料。',
    1404: '專案 PR Service 資料異常，請 IT 確認相關資料。',
  },
  config: {
    400: '部分欄位格式不符，請重新確認。',
    422: '部分欄位格式不符，請重新確認。',
    602: '部分欄位未填，請重新確認。',
    603: '部分欄位格式不符，請重新確認。',
    1100: '參數名稱已重複，請確認。'
  },
};
