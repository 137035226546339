import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { cloneDeep, isNull } from 'lodash';
import { postToGetUsers } from '@/api/authService';

import { FETCH_COMPANY_TYPE_OPTIONS } from '@/store/actions/srpOptionsAction';

import PanelCompensatedLeaveSearch from '@/components/Panel/HR/PanelCompensatedLeaveSearch';

import TableCompensatedLeave from '@/components/Table/HR/TableCompensatedLeave';
import PopupLoading from '@/components/UI/Popup/PopupLoading';

import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';

import { giveCompensatedLeaveConfig } from '@/constant/dataConfig/hrModule.js';
import { searchConfigGenerator } from '@/utils/apiRequestUtils';

import { 
  postIssueCompensatedLeave,
  getUserLeaveOptions,
  getCompensatedLeave,
} from '@/api/financeService';

import {
  OPEN_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';


const DivPanelContainer = styled.div`
  margin-bottom: 40px;
`;

// const searchConfig = {
//   voucherPrefix: '',
//   voucherCode: {
//     start: '',
//     end: '',
//   },
//   date: {
//     start: null,
//     end: null,
//   },
//   notForBusinessTax: '',
//   companyType: '',
//   amount: 10,
// };

const userSearchConfig = {
  // isOverTime: true,
  code: '',
  name: '',
  hasCreditCard: '',
  department: '',
  director: '',
  enable: '',
  systemRole: [],
  projectRole: [],
};

const CompensatedLeaveSearch = () => {
  const dispatch = useDispatch();
  const companyTypeOptions = useSelector(state => state.srpOptions.companyTypeOptions);
  // console.log("RevenueByCompanySearch companyTypeOptions:" + JSON.stringify(companyTypeOptions));


  const [leaveOptions, setLeaveOptions] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  // const [config, setConfig] = useState(cloneDeep(searchConfig));
  const [giveCompensatedLeave, setGiveCompensatedLeave] = useState(cloneDeep(giveCompensatedLeaveConfig));

  const [showLoadingPopup, setShowLoadingPopup] = useState(false);

  const [showSaveSuccessPopup, setShowSaveSuccessPopup] = useState(false);

  const [alertText, setAlertText] = useState('');
  const [showAlertPopup, setShowAlertPopup] = useState(false);

  const [userConfig, setUserConfig] = useState({ ...userSearchConfig });

  const [users, setUsers] = useState([]);
  

  const openAlertPopup = text => {
    setAlertText(text);
    setShowAlertPopup(true);
  };

  // const updateConfigHandler = (key, value) => {
  //   // console.log("fetchExpenseVouchers updateConfigHandler");
  //   const updateObj = { ...config };
  //   updateObj[key] = value;
  //   setConfig({ ...updateObj });
  // };

  const updateGiveCompensatedLeaveHandler = (key, value) => {
    console.log(`compensatedLeaveSearch.js updateGiveCompensatedLeaveHandler() key=${key}&value=${value}`);
    const updateObj = { ...giveCompensatedLeave };
    updateObj[key] = value;
    console.log(`compensatedLeaveSearch.js updateGiveCompensatedLeaveHandler() key=${{ ...updateObj }}`);
    setGiveCompensatedLeave({ ...updateObj });
  };

  // const clearHandler = () => {
  //   setVouchers([]);
  //   setTotalCount(0);
  //   setConfig(cloneDeep(searchConfig));
  // };

  const giveComepnsatedLeaveHandler = async () => {
    setShowLoadingPopup(true);

    try {
      const tempGiveCompensatedLeave = { ...giveCompensatedLeave };
      tempGiveCompensatedLeave.durationInSeconds = tempGiveCompensatedLeave.durationInSeconds * 3600;
      const { data } = await postIssueCompensatedLeave(
        tempGiveCompensatedLeave
      )
      console.log(`compensatedLeaveSerach.js giveComepnsatedLeaveHandler() data=${JSON.stringify(data)}`);
      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '儲存失敗', err }));
      console.log(err) 
    }

    // setShowLoadingPopup(false);
  };

  const fetchCompensatedLeave = async (page) => {
    console.log(`compensatedLeaveSerach.js fetchCompensatedLeave() giveCompensatedLeave.userId=${giveCompensatedLeave.userId}`);
    setShowLoadingPopup(true);

    try {
      const searchParameters = {
        userId: giveCompensatedLeave.userId,
        page: page,
        amount: 10,
      }
      if (searchParameters.userId !== "") {
        const { data } = await getCompensatedLeave(searchParameters);
        setLeaveOptions(data.allocations);
        console.log(`compensatedLeaveSerach.js fetchCompensatedLeave() JSON.stringify(data)=${JSON.stringify(data)}`);
        
        setTotalCount(data.totalCount || 0);
      } else {
        setLeaveOptions(undefined);
        setTotalCount(0);
      }
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  }

  const initCompensatedLeave = useCallback(
    fetchCompensatedLeave, []);

  useEffect(() => {
    initCompensatedLeave();
  }, [initCompensatedLeave]);

  const searchHandler = async () => {
    setCurrentPageHandler(1);
  };

  const setCurrentPageHandler = page => {
    setCurrentPage(page);
    fetchCompensatedLeave(page);
  };

  // const exportHandler = async () => {
  //   if (config.companyType === "" || config.companyType == null) {
  //     return openAlertPopup("請選擇公司");
  //   }
  //   if (config.date.start === "" || config.date.start == null) {
  //     return openAlertPopup("請選擇發票開始日期");
  //   }
  //   if (config.date.end === "" || config.date.end == null) {
  //     return openAlertPopup("請選擇發票結束日期");
  //   }
    
  //   setShowLoadingPopup(true);

  //   try {
  //     const { data } = await getCompanyProfitExcel(
  //       {
  //         startDate: formatTime(config.date.start, 'YYYY-MM-DD'),
  //         endDate: formatTime(config.date.end, 'YYYY-MM-DD'),
  //         companyTypeID: config.companyType,
  //       }
  //     );
  //     const url = window.URL.createObjectURL(new Blob([data]));
  //     const link = document.createElement('a');
  //     link.href = url;
  //     link.setAttribute('download', 'CompanyProfit.xlsx');
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   } catch (err) {
  //     setShowLoadingPopup(false);
  //     console.log(JSON.stringify(err));
  //   }
  //   setShowLoadingPopup(false);
  // };

  // const fetchExpenseVouchers = async (page = currentPage) => {
  //   // console.log("fetchExpenseVouchers config.date.start:" + config.date.start);
  //   if (config.companyType === "" || config.companyType == null) {
  //     return openAlertPopup("請選擇公司");
  //   }
  //   if (config.date.start === "" || config.date.start == null) {
  //     return openAlertPopup("請選擇發票開始日期");
  //   }
  //   if (config.date.end === "" || config.date.end == null) {
  //     return openAlertPopup("請選擇發票結束日期");
  //   }

  //   setShowLoadingPopup(true);
  //   console.log("fetchExpenseVouchers config:" + JSON.stringify(config));
  //   try {
  //     const { data } = await getCompanyProfit(
  //       {
  //         startDate: formatTime(config.date.start, 'YYYY-MM-DD'),
  //         endDate: formatTime(config.date.end, 'YYYY-MM-DD'),
  //         companyTypeID: config.companyType,
  //       }
  //     );
  //     console.log("fetchExpenseVouchers data:" + JSON.stringify(data));
  //     // const { vouchers } = data;
  //     // console.log("fetchExpenseVouchers vouchers:" + JSON.stringify(vouchers));

  //     setVouchers(data);
  //     setTotalCount(1);
  //   } catch(err) { console.log(err) }

  //   setShowLoadingPopup(false);
  // };

  const fetchUsers = async () => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetUsers(
        searchConfigGenerator({
          ...userConfig, page: 1, amount: 1000,
        })
      )
      console.log(`compensatedLeaveSerach.js fetchUsers() data=${JSON.stringify(data)}`);
      const userOptions = data.users.map(function(elem) {
        return {
          "value": elem.userID,
          "desc": elem.name,
        } 
      });
      console.log(`compensatedLeaveSerach.js fetchUsers() userOptions=${JSON.stringify(userOptions)}`);
      setUsers(userOptions);
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const initUsers = useCallback(
    fetchUsers, []);

  useEffect(() => {
    // initOptions();
    initUsers();
  }, [initUsers]);

  const initOptions = useCallback(() => {
    dispatch(FETCH_COMPANY_TYPE_OPTIONS());
  }, [dispatch]);

  useEffect(() => {
    initOptions();
  }, [initOptions]);

  return(
    <>
      <DivPanelContainer>
        <PanelCompensatedLeaveSearch
          // config = { config }
          users = { users }
          giveCompensatedLeave = { giveCompensatedLeave }
          companyTypeOptions = { companyTypeOptions }
          // clearHandler = { clearHandler }
          // exportHandler = { exportHandler }
          searchHandler = { searchHandler }
          giveComepnsatedLeaveHandler = { giveComepnsatedLeaveHandler }
          // updateConfigHandler = { updateConfigHandler }
          updateGiveCompensatedLeaveHandler = { updateGiveCompensatedLeaveHandler }
        />
      </DivPanelContainer>
      <TableCompensatedLeave
        leaveOptions = { leaveOptions }
        totalCount = { totalCount }
        currentPage = { currentPage }
        pageChangedHandler = { setCurrentPageHandler }
      />
      { showLoadingPopup && <PopupLoading/> }
      {
        showAlertPopup &&
          <PopupExecuteResult
            title = "請選擇欄位"
            text = { alertText }
            confirmHandler = { () => { setShowAlertPopup(false) } }
          />
      }
      {
        showSaveSuccessPopup &&
          <PopupExecuteResult
            title = { "新增補休時數成功" }
            confirmHandler = { () => {
              setShowSaveSuccessPopup(false);
            } }
          />
      }
    </>
  );
};

export default CompensatedLeaveSearch;
