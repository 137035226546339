import React from 'react';
import styled from 'styled-components';

import Checkbox from '@/components/UI/Checkbox';
import { TableHead, TableRow, ThItem } from '@/component-style/Table';

const THead = styled(TableHead)`
  min-width: ${ props => {
    if (props.showCheckbox && props.showDetailButton) return '1380px';
    if (props.showCheckbox || props.showDetailButton) return '1320px';
    return '1260px';
  }};
`;

const Th60 = styled(ThItem)`
  width: 60px;
`;

const Th80 = styled(ThItem)`
  width: 80px;
`;

const Th120 = styled(ThItem)`
  width: 120px;
`;

const Th160 = styled(ThItem)`
  width: 160px;
`;

const Th300 = styled(ThItem)`
  width: 300px;
`;

const Header = props => {
  
  return (
    <THead
      showCheckbox = { props.showCheckbox }
      showDetailButton = { props.showDetailButton }
    >
      <TableRow>
        {
          props.showCheckbox &&
          <Th60>
            <Checkbox
              checked = { props.selectAll }
              changeHandler = { props.selectAllHandler }
            />
          </Th60>
        }
        <Th120>專案代號</Th120>
        <Th160>專案名稱</Th160>
        <Th120>新聞來源</Th120>
        <Th120>媒體總類</Th120>
        <Th120>媒體</Th120>
        <Th120>報導日期</Th120>
        <Th80>記者</Th80>
        <Th300>主標</Th300>
        <Th120>效益</Th120>
        { props.showDetailButton && <Th60></Th60> }
      </TableRow>
    </THead>
  );
};

export default Header;
