import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { putNewCustomer } from '@/api/customerService';

import { FETCH_CUSTOMER_INDUSTRIES } from '@/store/actions/srpOptionsAction';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import MainContent from '@/component-style/MainContent';
import PanelCustomerDetail from '@/components/Panel/PanelCustomerDetail';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import { ButtonBasic, ButtonCancel } from '@/component-style/Button';
import { DivFlexRow } from '@/component-style/RWDWrapper';

import { customerConfig } from '@/constant/dataConfig/informationModule';

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding-right: 0;
  padding-left: 135px;
  padding-bottom: 30px;
  justify-content: space-between;

  @media (max-width: 425px) {
    justify-content: center;
    padding-left: 0;
  }
`;

const ButtonSave = styled(ButtonBasic)`
  width: 120px;
  margin-right: 10px;
  margin-bottom: 10px;
`;

const ButtonClear = styled(ButtonCancel)`
  width: 120px;
  margin-bottom: 10px;
`;

const CustomerCreation = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const customerIndustries = useSelector(state => state.srpOptions.customerIndustries);
  const showSaveFailPopup = useSelector(state => state.apiFail.showSaveFailPopup);
  const apiFailPopupTitle = useSelector(state => state.apiFail.title);
  const apiFailPopupMessage = useSelector(state => state.apiFail.message);

  const [customer, setCustomer] = useState({
    ...customerConfig,
    industry: [],
    partnership: { ...customerConfig.partnership },
    contactAddress: { ...customerConfig.contactAddress },
    invoiceAddress: { ...customerConfig.invoiceAddress },
  });

  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [showSaveSuccessPopup, setShowSaveSuccessPopup] = useState(false);

  const updateCustomerHandler = (key, value) => {
    const updateObj = { ...customer };
    updateObj[key] = value;
    setCustomer({...updateObj});
  };

  const updatePartnershipHandler = (item, value) => {
    const updatePartnership = { ...customer.partnership };
    updatePartnership[item] = value;
    updateCustomerHandler('partnership', { ...updatePartnership });
  };

  const initCustomerData = () => {
    setCustomer({
      ...customerConfig,
      industry: [],
      partnership: { ...customerConfig.partnership },
      contactAddress: { ...customerConfig.contactAddress },
      invoiceAddress: { ...customerConfig.invoiceAddress },
    });
  };

  const closeSuccessPopupHandler = () => {
    initCustomerData();
    setShowSaveSuccessPopup(false);
  }

  const saveNewCustomerHandler = async () => {
    setShowLoadingPopup(true);

    try {
      await putNewCustomer(customer);
      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '儲存失敗', err }));
      console.log(err);
    }
  };

  const initOptions = useCallback(() => {
    dispatch(FETCH_CUSTOMER_INDUSTRIES({ enable: true }));
  }, [dispatch]);

  useEffect(() => {
    initOptions();
  }, [initOptions]);

  return (
    <MainContent>
      <PanelCustomerDetail
        title = "新增客戶資料"
        customer = { customer }
        customerIndustries = { customerIndustries }
        updateCustomerHandler = { updateCustomerHandler }
        updatePartnershipHandler = { updatePartnershipHandler }
        copyAddressHandler = { () => {
          updateCustomerHandler(
            'invoiceAddress',
            { ...customer.contactAddress}
          );
        }} 
      >
      {
        <DivFlexRow>
          <DivButtonsWrapper>
            <div>
              <ButtonSave
                onClick = { saveNewCustomerHandler }
              >儲存</ButtonSave>
              <ButtonClear
                onClick = { initCustomerData }
              >清除欄位</ButtonClear>
            </div>
            <ButtonClear
              onClick = { () => { history.goBack() } }
            >離開</ButtonClear>
          </DivButtonsWrapper>
        </DivFlexRow>
      }
      </PanelCustomerDetail>
      { showLoadingPopup && <PopupLoading/> }
      {
        showSaveSuccessPopup &&
          <PopupExecuteResult
            title = "儲存成功"
            confirmHandler = { closeSuccessPopupHandler }
          />
      }
      {
        showSaveFailPopup &&
          <PopupExecuteResult
            title = { apiFailPopupTitle }
            text = { apiFailPopupMessage }
            confirmHandler = { () => {
              dispatch(CLOSE_SAVE_FAIL_POPUP());
            }}
          />
      }
    </MainContent>
  )
};

export default CustomerCreation;
