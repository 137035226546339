import React from 'react';
import styled from 'styled-components';
import { cloneDeep } from 'lodash';

import Checkbox from '@/components/UI/Checkbox';
import DateSelector from '@/components/UI/DateSelector';
import SelectNormal from '@/components/UI/Select/SelectNormal';
import SelectMultiple from '@/components/UI/Select/SelectMultiple';
// import { InputBasic, TextareaBasic } from '@/component-style/Input';
import { DivPopupWrapper, DivPopup } from '@/component-style/Popup';
import { ButtonBasic, ButtonCancel } from '@/component-style/Button';
import { DivFlexRow } from '@/component-style/RWDWrapper';

const DivPopupContainer = styled(DivPopup)`
  width: 540px;
  max-height: 80vh;
  overflow-y: auto;
`;

const DivItemWrapper = styled(DivFlexRow)`
  padding: 0;
  margin-bottom: 10px;
`;

const DivSelectWrapper = styled(DivItemWrapper)`

  & > div:first-of-type,
  .ant-select {
    width: 100%;
  }
`;

const DivCheckboxWrapper = styled.div`
  width: 100px;
  height: 40px;
  line-height: 40px;
`;

// const Input = styled(InputBasic)`
//   width: 100%;
// `;

// const Textarea = styled(TextareaBasic)`
//   width: 100%;
// `;

const DivButtonsWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const H3Title = styled.h3`
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: #8b8b8b;
  margin-bottom: 10px;
`;

const PTexts = styled.p`
  width: 120px;
  font-size: 16px;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0.43px;
  color: #8b8b8b;
`;

const ButtonConfirm = styled(ButtonBasic)`
  width: 120px;
`;

const ButtonDiscard = styled(ButtonCancel)`
  width: 120px;
  margin-right: 10px;
`;

const informMethodList = [
  // { value: 'SMS', desc: '簡訊' },
  { value: 'email', desc: '寄信' },
];

const PopupInformReporter = props => {
  const { sendConfig } = props;

  const sendable = sendConfig.all === true
    || sendConfig.mediaTypes.length !== 0
    || sendConfig.journalists.length !== 0;

  const updateSendConfigHandler = (key, value) => {
    const updateObj = cloneDeep(sendConfig);
    updateObj[key] = value;
    props.updateSendConfigHander(updateObj);
  };

  const sendAllChangeHandler = value => {
    const updateObj = cloneDeep(sendConfig);
    updateObj.all = value;
    updateObj.mediaTypes = [];
    updateObj.journalists = [];
    props.updateSendConfigHander(updateObj);
  };

  return (
    <DivPopupWrapper>
      <DivPopupContainer>
        <H3Title>訊息發送</H3Title>
        <DivSelectWrapper>
          <PTexts>發送方式</PTexts>
          <SelectNormal
            value = { sendConfig.method }
            options = { informMethodList }
            changeHandler = { value => {
              updateSendConfigHandler('method', value);
            }}
          />
        </DivSelectWrapper>
        <DivItemWrapper>
          <PTexts>所有記者</PTexts>
          <DivCheckboxWrapper>
            <Checkbox
              checked = { sendConfig.all }
              changeHandler = { value => {
                sendAllChangeHandler(value);
              }}
            />
          </DivCheckboxWrapper>
        </DivItemWrapper>
        <DivSelectWrapper>
          <PTexts>媒體類別</PTexts>
          <SelectMultiple
            disabled = { sendConfig.all }
            value = { sendConfig.mediaTypes }
            options = { props.mediaTypes }
            changeHandler = { value => {
              updateSendConfigHandler('mediaTypes', value);
            }}
          />
        </DivSelectWrapper>
        <DivSelectWrapper>
          <PTexts>發送記者</PTexts>
          <SelectMultiple
            disabled = { sendConfig.all }
            value = { sendConfig.journalists }
            options = { props.journalistOptions }
            changeHandler = { value => {
              updateSendConfigHandler('journalists', value);
            }}
          />
        </DivSelectWrapper>
        {/* <DivItemWrapper>
          <PTexts>發送內容</PTexts>
          <Textarea/>
        </DivItemWrapper>
        <DivItemWrapper>
          <PTexts>含回覆回覆</PTexts>
          <DivCheckboxWrapper>
            <Checkbox
              checked = { withInviation }
              changeHandler = { setWithInviation }
            />
          </DivCheckboxWrapper>
        </DivItemWrapper> */}
        {
          sendConfig.withInviation &&
          <DivItemWrapper>
            <PTexts>截止日期</PTexts>
            <DateSelector
              showTime = { true }
              value = { null }
            />
          </DivItemWrapper>
        }
        <DivButtonsWrapper>
          <ButtonDiscard
            onClick = { props.cancelHandler }
          >關閉
          </ButtonDiscard>
          <ButtonConfirm
            disabled = { !sendable }
            onClick = { props.confirmHandler }
          >確定
          </ButtonConfirm>
        </DivButtonsWrapper>
      </DivPopupContainer>
    </DivPopupWrapper>
  );
};

export default PopupInformReporter;
