import moment from 'moment';
import {
  postToGetWorkingHours,
} from '@/api/attendanceService';
import { integerConverter } from '@/utils/textUtils';
import {
  postToGetHolidaysParams,
  getWorkday,
} from '@/api/sysConfigService';

export function getDateString(time = null) {
  if (time === null) return '';

  const dateObj = new Date(time);
  const year = dateObj.getFullYear();
  const month = dateObj.getMonth() + 1;
  const date = dateObj.getDate();

  return `${year}/${("0" + month).slice(-2)}/${("0" + date).slice(-2)}`;
};

export function getTimeString(time) {
  const dateObj = new Date(time);
  const hours = dateObj.getHours();
  const mins = dateObj.getMinutes();

  return `${("0" + hours).slice(-2)}:${("0" + mins).slice(-2)}`;
};

export function formatTime(value = null, form) {
  return value === null
    ? null
    : moment(value).format(form)
};

export function toUTCTime(value = null) {
  return value === null
    ? null
    : moment(value).utc();
};

export function diffTimeSubtractLunchBreak(start, end, unit) {
  var diffTimeInUnit = '';
  // var momentHour13 = moment(start).set({'hour': 13, 'minute': 0});
  // console.log("diffTimeSubtractLunchBreak moment(start).format('H') <= 13:" + moment(start).format('H') <= 13);
  // if (start !== null && end !== null) {
  //   if (moment(start).format('H') <= 13 && moment(end).format('H') >= 14) {
  //     if (unit === "minutes") {
  //       diffTimeInUnit = moment(end).diff(moment(start), 'minutes');
  //       if (parseInt(moment(start).format('H')) === 13 && parseInt(moment(start).format('m')) > 0) {
  //         diffTimeInUnit -= moment(start).diff(moment(momentHour13), 'minutes');
  //       } else {
  //         diffTimeInUnit -= 60;
  //       }
  //     } else if (unit === "hours") {
  //       diffTimeInUnit = moment(end).diff(moment(start), 'hours', true);
  //       if (parseInt(moment(start).format('H')) === 13 && parseInt(moment(start).format('m')) > 0) {
  //         diffTimeInUnit -= moment(start).diff(moment(momentHour13), 'hours', true);
  //       } else {
  //         diffTimeInUnit -= 1;
  //       }
  //       diffTimeInUnit = Math.round(diffTimeInUnit * 100) / 100;
  //     }
  //   } else {
      diffTimeInUnit = moment(end).diff(moment(start), unit, true);
      if (unit === "hours") {
        diffTimeInUnit = Math.round(diffTimeInUnit * 10) / 10;
      }
  //   }
  // }
  return diffTimeInUnit
};

export function diffTime(start, end, unit) {
  return start === null || end === null
    ? ''
    : moment(end).diff(moment(start), unit);
};

export function diffTimeWithFloat(start, end, unit) {
  return start === null || end === null
    ? ''
    : moment(end).diff(moment(start), unit, true);
};

export function getStartOfUnit(time, unit) {
  return moment(time).startOf(unit);
};

export function getEndOfUnit(time, unit) {
  return moment(time).endOf(unit);
};

export function addTime(time, count, unit) {
  return moment(time).add(count, unit);
};

export function subtractTime(time, count, unit) {
  return moment(time).subtract(count, unit);
};

export function isTimeAfter(time1, time2) {
  return moment(time1).isAfter(time2);
};

//val format should be YYYY-MM-DD. Use dash will not be converted to local timezone
function checkDateInArray(arr, val) {
  // console.log("timeUtils.js checkDateInArray arr:" + JSON.stringify(arr));
  // console.log("timeUtils.js checkDateInArray val:" + val + " Date : " + Date.parse(val));
  if (arr == null) {
    return false;
  } else {
    return arr.some(arrVal => Date.parse(val) === Date.parse(arrVal));
  }
  // var bFound = false;
  // for (const strDate of arr){
  //   console.log("timeUtils.js checkDateInArray Date.parse(strDate):" + Date.parse(strDate) + " Date : " + Date.parse(val));
  //   if (Date.parse(strDate) === Date.parse(val)) {
  //     bFound = true;
  //   }
  //   if (bFound) {
  //     break;
  //   }
  // }
  // return bFound;
}

//Check Overtime working status
export async function getOtStatus(structOt) {
  console.log("timeUtils.js getOtStatus structOt:" + JSON.stringify(structOt));
  // console.log("timeUtils.js getOtStatus structOt.workingDate:" + structOt.workingDate);
  // var dateWorkingDate = Date.parse(structOt.workingDate);
  // console.log("timeUtils.js getOtStatus dateWorkingDate:" + dateWorkingDate);
  // var dateTest = Date.parse("2021-11-21");
  // console.log("timeUtils.js getOtStatus dateTest:" + dateTest);
  var objResult = {
    overtimeDateType:  ""
  };
  try {
    const { data } = await postToGetWorkingHours({
      isOverTime: true,
      userIDs: [structOt.userID],
      startDate: formatTime(structOt.workingDate, 'YYYY-MM-DD'),
      endDate: formatTime(structOt.workingDate, 'YYYY-MM-DD'),
      amount: 10000,
      page: 1,
    });
    // console.log("fetchOvertimesByIan useEffect :" + JSON.stringify(data));
    const { workingHours } = data;
    // const otTotalMinutes = workingHours.map(({ startTime, endTime }) => 
    //   diffTime(startTime, endTime, 'minutes') || 0
    // )
    // console.log("timeUtils.js getOtStatus otTotalMinutes:" + otTotalMinutes);

    // console.log("timeUtils.js getOtStatus day of wrokingDate:" + moment(structOt.workingDate).format('dddd'));

    const year = integerConverter(
      formatTime(structOt.workingDate, 'YYYY')
    );

    let { restdays, holidays, nationalHolidays, workdays } = [];
    try {
      const { data } = await postToGetHolidaysParams({ year })
      restdays = data.restdays;
      holidays = data.holidays;
      nationalHolidays = data.nationalHolidays;
      workdays = data.workdays;
      // console.log("timeUtils.js getOtStatus holidaysParams:" + JSON.stringify(data.holidays));
    } catch (err) { console.log(err ) }
    console.log("timeUtils.js getOtStatus restdays:" + JSON.stringify(restdays));
    console.log("timeUtils.js getOtStatus holidays:" + JSON.stringify(holidays));
    console.log("timeUtils.js getOtStatus workdays:" + JSON.stringify(workdays));
    console.log("timeUtils.js getOtStatus nationalHolidays:" + JSON.stringify(nationalHolidays));
    objResult.overtimeDateType = "workdays";
    // console.log("timeUtils.js getOtStatus structOt.workingDate:" + structOt.workingDate);
    if (checkDateInArray(nationalHolidays, structOt.workingDate.replaceAll("/", "-"))) {
      objResult.overtimeDateType = "nationalHolidays";
    }
    console.log("timeUtils.js getOtStatus objResult.overtimeDateType:" + objResult.overtimeDateType);
    return objResult;
    // const overtimeRecords = [];

    // for (let i = 0; i < workingHours.length; i += 1 ) {
    //   // const { projectID } = workingHours[i];
    //   // const projectInfo = await postToGetProjectDetail(projectID);
    //   // const { projectCode, projectName } = projectInfo.data;
    //   overtimeRecords.push({
    //     ...workingHours[i],
    //     // username,
    //     // projectCode,
    //     // projectName,
    //   });
    // }

    // console.log("timeUtils.js getOtStatus overtimeRecords:" + JSON.stringify(overtimeRecords));
    // setOvertimeRecords([ ...overtimeRecords ]);
    // setTotalCount(totalCount || 0);
  } catch (err) { console.log(err) }
}

export async function canWorkOvertimeOnSaturdayOrSunday(structOt) {
  console.log("canWorkOvertime structOt:" + JSON.stringify(structOt));
  var boolCanWorkOvertime = true;
    
  // console.log("canWorkOvertimeOnSaturdayOrSunday otDate.workingDate date:" + formatTime(structOt.workingDate, 'dddd'));
  if (formatTime(structOt.workingDate, 'dddd') === "Saturday" || formatTime(structOt.workingDate, 'dddd') === "Sunday") {
    //one day of Saturday or Sunday should be "holidays(例假日)"
    var dateSaturdayOrSunday;
    formatTime(structOt.workingDate, 'dddd') === "Saturday" ? dateSaturdayOrSunday = moment(structOt.workingDate).add(1, 'days') : dateSaturdayOrSunday = moment(structOt.workingDate).subtract(1, 'days');
    console.log("canWorkOvertimeOnSaturdayOrSunday dateSaturdayOrSunday:" + formatTime(dateSaturdayOrSunday, 'dddd'));
    const { data } = await postToGetWorkingHours({
      isOverTime: true,
      userIDs: [structOt.userID],
      startDate: moment(structOt.workingDate).startOf('month').format('YYYY-MM-DD'),
      endDate: moment(structOt.workingDate).endOf('month').format('YYYY-MM-DD'),
      amount: 10000,
      page: 1,
    });
    console.log("canWorkOvertimeOnSaturdayOrSunday :" + JSON.stringify(data));
    console.log("canWorkOvertimeOnSaturdayOrSunday dateSaturdayOrSunday:" + dateSaturdayOrSunday);
    const { workingHours } = data;
    console.log("canWorkOvertimeOnSaturdayOrSunday workingHours:" + JSON.stringify(workingHours));
    // for (const overtimeDate of workingHours) {
    //   console.log("canWorkOvertimeOnSaturdayOrSunday overtimeDate.date:" + moment(overtimeDate.date));
    // }
    if (workingHours.some(overtime => moment(overtime.date).isSame(dateSaturdayOrSunday))) {
      boolCanWorkOvertime = false;
    }
    let { workdays } = [];
    try {
      let year = parseInt(moment(structOt.workingDate).format('YYYY'));
      const { data } = await postToGetHolidaysParams({ year });
      workdays = data.workdays;
      console.log(`canWorkOvertimeOnSaturdayOrSunday JSON.stringify(workdays)=${JSON.stringify(workdays)}`);
      if (workdays.some(workday => moment(workday).isSame(dateSaturdayOrSunday))) {
        boolCanWorkOvertime = false;
      }
    } catch (err) { console.log(err ) }
  }
  return boolCanWorkOvertime;
}

export async function getOvertimeDateType(structOt) {
  console.log("getOvertimeDateType structOt:" + JSON.stringify(structOt));
  var overtimeDateType = "workdays";
  const year = integerConverter(
    formatTime(structOt.workingDate, 'YYYY')
  );
  
  // console.log("getOvertimeDateType otDate.workingDate date:" + formatTime(structOt.workingDate, 'dddd'));
  let { restdays, holidays, nationalHolidays, workdays } = [];
  try {
    const { data } = await postToGetHolidaysParams({ year })
    restdays = data.restdays;
    holidays = data.holidays;
    nationalHolidays = data.nationalHolidays;
    workdays = data.workdays;
    // console.log("timeUtils.js getOtStatus holidaysParams:" + JSON.stringify(data.holidays));
  } catch (err) { console.log(err ) }
  if (workdays == null) {
    workdays = [];
  }


  // console.log("getOvertimeDateType structOt:" + JSON.stringify(structOt));
  if (formatTime(structOt.workingDate, 'dddd') === "Saturday" || formatTime(structOt.workingDate, 'dddd') === "Sunday") {
    //one day of Saturday or Sunday should be "holidays(例假日)"
    var dateSaturdayOrSunday;
    formatTime(structOt.workingDate, 'dddd') === "Saturday" ? dateSaturdayOrSunday = moment(structOt.workingDate).add(1, 'days') : dateSaturdayOrSunday = moment(structOt.workingDate).subtract(1, 'days');
    // console.log("getOvertimeDateType dateSaturdayOrSunday:" + formatTime(dateSaturdayOrSunday, 'dddd'));
    const { data } = await postToGetWorkingHours({
      isOverTime: true,
      userIDs: [structOt.userID],
      startDate: moment(structOt.workingDate).startOf('month').format('YYYY-MM-DD'),
      endDate: moment(structOt.workingDate).endOf('month').format('YYYY-MM-DD'),
      amount: 10000,
      page: 1,
    });
    // console.log("getOvertimeDateType :" + JSON.stringify(data));
    // console.log("getOvertimeDateType dateSaturdayOrSunday:" + dateSaturdayOrSunday);
    const { workingHours } = data;
    console.log("getOvertimeDateType workingHours:" + JSON.stringify(workingHours));
    // for (const overtimeDate of workingHours) {
    //   console.log("getOvertimeDateType overtimeDate.date:" + moment(overtimeDate.date));
    // }
    if (workingHours.some(overtime => moment(overtime.date).isSame(dateSaturdayOrSunday)) || workdays.some(workday => moment(workday).isSame(dateSaturdayOrSunday)) ) {
      overtimeDateType = "holidays";
    } else {
      overtimeDateType = "restdays";
    }
    if (checkDateInArray(workdays, structOt.workingDate.replaceAll("/", "-"))) {
      overtimeDateType = "workdays";
    }
  }
  
  if (checkDateInArray(restdays, structOt.workingDate.replaceAll("/", "-"))) {
    overtimeDateType = "restdays";
  }
  if (checkDateInArray(nationalHolidays, structOt.workingDate.replaceAll("/", "-"))) {
    overtimeDateType = "nationalHolidays";
  }

  console.log("getOvertimeDateType() JSON.stringyfi(holidays):" + JSON.stringify(holidays) + " structOt.workingDate:" + structOt.workingDate);
  if (checkDateInArray(holidays, structOt.workingDate.replaceAll("/", "-"))) {
    overtimeDateType = "holidays";
  }

  console.log("getOvertimeDateType overtimeDateType:" + overtimeDateType);
  return overtimeDateType;
}

export function getOvertimeWeightedHour(workingHoursParamsConfig, overtimeDateType, overtimeRecords, overtimeHours) {
  // console.log("getOvertimeWeightedHour accumulatedOvertimeHours:" + diffTimeSubtractLunchBreak(overtimeHours.startTime, overtimeHours.endTime, 'hours'));
  var accumulatedOvertimeHours = 0.0;
  var overtimeWeighted = 0.0;
  var thisOvertimeHour = diffTimeSubtractLunchBreak(overtimeHours.startTime, overtimeHours.endTime, 'hours');
  if (overtimeDateType === "workdays") {
    // console.log("getOvertimeWeightedHour overtimeRecords:" + JSON.stringify(overtimeRecords));
    // console.log("getOvertimeWeightedHour overtimeHours:" + JSON.stringify(overtimeHours));
    overtimeRecords.forEach(function (item, index) {
      // console.log("getOvertimeWeightedHour accumulatedOvertimeHours:" + accumulatedOvertimeHours);
      if (item.workingHoursID === overtimeHours.workingHoursID) {
        if (accumulatedOvertimeHours + thisOvertimeHour >= 2) {
          // console.log("getOvertimeWeightedHour thisOvertimeHour:" + thisOvertimeHour);
          if (accumulatedOvertimeHours >= 2) {
            // overtimeWeighted += Math.round(2 * workingHoursParamsConfig.normalDayExtraRate.firstTwoHour * 100) / 100;
            // thisOvertimeHour -= 2;
            overtimeWeighted += Math.round((thisOvertimeHour) * workingHoursParamsConfig.normalDayExtraRate.overTwoHour * 100) / 100;
          } else {
            var leftWithinTwoHours = 2 - accumulatedOvertimeHours;
            if (thisOvertimeHour < leftWithinTwoHours) {
              // console.log("thisOvertimeHour:" + thisOvertimeHour);
              overtimeWeighted += Math.round(leftWithinTwoHours * workingHoursParamsConfig.normalDayExtraRate.firstTwoHour * 100) / 100;
              thisOvertimeHour -= leftWithinTwoHours;
              overtimeWeighted += Math.round((thisOvertimeHour) * workingHoursParamsConfig.normalDayExtraRate.overTwoHour * 100) / 100;
            } else {
              // console.log("thisOvertimeHour:" + thisOvertimeHour);
              overtimeWeighted += Math.round(leftWithinTwoHours * workingHoursParamsConfig.normalDayExtraRate.firstTwoHour * 100) / 100;
              // console.log("overtimeWeighted:" + overtimeWeighted);
              thisOvertimeHour -= leftWithinTwoHours;
              overtimeWeighted += Math.round((thisOvertimeHour) * workingHoursParamsConfig.normalDayExtraRate.overTwoHour * 100) / 100;
            }
          }
        } else {
          overtimeWeighted += Math.round((thisOvertimeHour) * workingHoursParamsConfig.normalDayExtraRate.firstTwoHour * 100) / 100;
        }
      }
      accumulatedOvertimeHours += diffTimeSubtractLunchBreak(item.startTime, item.endTime, 'hours');
    });
  } else if (overtimeDateType === "restdays") {
    overtimeRecords.forEach(function (item, index) {
      if (item.workingHoursID === overtimeHours.workingHoursID) {
        // console.log("getOvertimeWeightedHour accumulatedOvertimeHours:" + accumulatedOvertimeHours);
        if (accumulatedOvertimeHours + thisOvertimeHour >= 8) {
          if (accumulatedOvertimeHours >= 2) {
            overtimeWeighted += Math.round((thisOvertimeHour) * workingHoursParamsConfig.restDayExtraRate.overTwoHour * 100) / 100;
            thisOvertimeHour -= 6;
            if (thisOvertimeHour > 0) {
              overtimeWeighted += Math.round((thisOvertimeHour) * workingHoursParamsConfig.restDayExtraRate.overEightHour * 100) / 100;
            }
          } else {
            // console.log("getOvertimeWeightedHour accumulatedOvertimeHours:" + accumulatedOvertimeHours + " thisOvertimeHour:" + thisOvertimeHour);
            if (thisOvertimeHour > 8) {
              overtimeWeighted += Math.round(2 * workingHoursParamsConfig.restDayExtraRate.firstTwoHour * 100) / 100;
              console.log("getOvertimeWeightedHour overtimeWeighted:" + overtimeWeighted);
              thisOvertimeHour -= 2;
              overtimeWeighted += Math.round(6 * workingHoursParamsConfig.restDayExtraRate.overTwoHour * 100) / 100;
              thisOvertimeHour -= 6;
              if (thisOvertimeHour > 0) {
                overtimeWeighted += Math.round((thisOvertimeHour) * workingHoursParamsConfig.restDayExtraRate.overEightHour * 100) / 100;
              }
            } else if (thisOvertimeHour > 2) {
              overtimeWeighted += Math.round(2 * workingHoursParamsConfig.restDayExtraRate.firstTwoHour * 100) / 100;
              thisOvertimeHour -= 2;
              overtimeWeighted += Math.round((thisOvertimeHour) * workingHoursParamsConfig.restDayExtraRate.overTwoHour * 100) / 100;
            } else {
              overtimeWeighted += Math.round((thisOvertimeHour) * workingHoursParamsConfig.restDayExtraRate.overTwoHour * 100) / 100;
            }
          }
        } else if (accumulatedOvertimeHours + thisOvertimeHour > 2) {
          if (accumulatedOvertimeHours >= 2) {
            if (thisOvertimeHour > 7) {
              overtimeWeighted += Math.round(7 * workingHoursParamsConfig.restDayExtraRate.overTwoHour * 100) / 100;
              thisOvertimeHour -= 7;
              if (overtimeHours > 0) {
                overtimeWeighted += Math.round((thisOvertimeHour) * workingHoursParamsConfig.restDayExtraRate.overEightHour * 100) / 100;
              }
            } else {
              overtimeWeighted += Math.round(thisOvertimeHour * workingHoursParamsConfig.restDayExtraRate.overTwoHour * 100) / 100;
            }
          } else {
            if (thisOvertimeHour > 2) {
              overtimeWeighted += Math.round(2 * workingHoursParamsConfig.restDayExtraRate.firstTwoHour * 100) / 100;
              thisOvertimeHour -= 2;
              overtimeWeighted += Math.round((thisOvertimeHour) * workingHoursParamsConfig.restDayExtraRate.overTwoHour * 100) / 100;
            } else {
              overtimeWeighted += Math.round((thisOvertimeHour) * workingHoursParamsConfig.restDayExtraRate.overTwoHour * 100) / 100;
            }
          }
        } else {
          overtimeWeighted += Math.round((thisOvertimeHour) * workingHoursParamsConfig.restDayExtraRate.firstTwoHour * 100) / 100;
        }
      }
      accumulatedOvertimeHours += diffTimeSubtractLunchBreak(item.startTime, item.endTime, 'hours');
    });
  } else if (overtimeDateType === "nationalHolidays") {
    console.log("getOvertimeWeightedHour overtimeRecords:" + JSON.stringify(overtimeRecords));
    overtimeRecords.forEach(function (item, index) {
      if (item.workingHoursID === overtimeHours.workingHoursID) {
        console.log("getOVertimeWeightedHour() JSON.stringify(overtimeHours):" + JSON.stringify(overtimeHours));
        console.log("getOVertimeWeightedHour() JSON.stringify(workingHoursParamsConfig.nationalHolidayExtraRate):" + JSON.stringify(workingHoursParamsConfig.nationalHolidayExtraRate) + " thisOvertimeHour:" + thisOvertimeHour);
        if (accumulatedOvertimeHours + thisOvertimeHour >= 10) {
          if (thisOvertimeHour > 10) {
            overtimeWeighted += Math.round(8 * workingHoursParamsConfig.nationalHolidayExtraRate.underEightHour * 100) / 100;
            thisOvertimeHour -= 8;
            overtimeWeighted += Math.round(2 * workingHoursParamsConfig.nationalHolidayExtraRate.overEightHour * 100) / 100;
            thisOvertimeHour -= 2;
            overtimeWeighted += Math.round((thisOvertimeHour) * workingHoursParamsConfig.nationalHolidayExtraRate.overTenHour * 100) / 100;
          } else if (thisOvertimeHour > 8) {
            overtimeWeighted += Math.round(8 * workingHoursParamsConfig.nationalHolidayExtraRate.underEightHour * 100) / 100;
            thisOvertimeHour -= 8;
            overtimeWeighted += Math.round((thisOvertimeHour) * workingHoursParamsConfig.nationalHolidayExtraRate.overEightHour * 100) / 100;
          } else {
            overtimeWeighted += Math.round((thisOvertimeHour) * workingHoursParamsConfig.nationalHolidayExtraRate.underEightHour * 100) / 100;
          }
        } else if (accumulatedOvertimeHours + thisOvertimeHour >= 8) {
          if (thisOvertimeHour > 8) {
            overtimeWeighted += Math.round(8 * workingHoursParamsConfig.nationalHolidayExtraRate.underEightHour * 100) / 100;
            thisOvertimeHour -= 8;
            overtimeWeighted += Math.round((thisOvertimeHour) * workingHoursParamsConfig.nationalHolidayExtraRate.overEightHour * 100) / 100;
          } else {
            overtimeWeighted += Math.round((thisOvertimeHour) * workingHoursParamsConfig.nationalHolidayExtraRate.underEightHour * 100) / 100;
          }
        } else {
          overtimeWeighted += Math.round((thisOvertimeHour) * workingHoursParamsConfig.nationalHolidayExtraRate.underEightHour * 100) / 100;
        }
      }
      accumulatedOvertimeHours += diffTimeSubtractLunchBreak(item.startTime, item.endTime, 'hours');
    });
  } else if (overtimeDateType === "holidays") {//例假日
    // console.log("getOvertimeWeightedHour() holidays");
    overtimeRecords.forEach(function (item, index) {
      if (item.workingHoursID === overtimeHours.workingHoursID) {
        if (accumulatedOvertimeHours + thisOvertimeHour >= 8) {
          // console.log("getOvertimeWeightedHour() holidays");
          if (thisOvertimeHour >= 12) {
            overtimeWeighted += Math.round((thisOvertimeHour - 10) * workingHoursParamsConfig.holidayExtraRate.overTenHour * 100) / 100;
            thisOvertimeHour = thisOvertimeHour - (thisOvertimeHour - 10);
            overtimeWeighted += Math.round((thisOvertimeHour - 8) * workingHoursParamsConfig.holidayExtraRate.overEightHour * 100) / 100;
            thisOvertimeHour = thisOvertimeHour - (thisOvertimeHour - 8);
            overtimeWeighted += Math.round((thisOvertimeHour) * workingHoursParamsConfig.holidayExtraRate.underEightHour * 100) / 100;
          } else if (thisOvertimeHour >= 10) {
            overtimeWeighted += Math.round((thisOvertimeHour - 8) * workingHoursParamsConfig.holidayExtraRate.overEightHour * 100) / 100;
            thisOvertimeHour = thisOvertimeHour - (thisOvertimeHour - 8);
            overtimeWeighted += Math.round((thisOvertimeHour) * workingHoursParamsConfig.holidayExtraRate.underEightHour * 100) / 100;
          } else {
            overtimeWeighted += Math.round((thisOvertimeHour) * workingHoursParamsConfig.holidayExtraRate.underEightHour * 100) / 100;
          }
        } else if (accumulatedOvertimeHours + thisOvertimeHour >= 2) {
          if (thisOvertimeHour > 10) {
            overtimeWeighted += Math.round((thisOvertimeHour - 8) * workingHoursParamsConfig.holidayExtraRate.overEightHour * 100) / 100;
            thisOvertimeHour = thisOvertimeHour - (thisOvertimeHour - 8);
            overtimeWeighted += Math.round((thisOvertimeHour) * workingHoursParamsConfig.holidayExtraRate.underEightHour * 100) / 100;
          } else {
            overtimeWeighted += Math.round((thisOvertimeHour) * workingHoursParamsConfig.holidayExtraRate.underEightHour * 100) / 100;
          }
        } else {
          overtimeWeighted += Math.round((thisOvertimeHour) * workingHoursParamsConfig.holidayExtraRate.underEightHour * 100) / 100;
        }
      }
      accumulatedOvertimeHours += diffTimeSubtractLunchBreak(item.startTime, item.endTime, 'hours');
    });
  }
  return overtimeWeighted;
}

//Return value
//  1:strStartTime falls between lunch break
//  2:strEndTime falls between lunch break
export async function isValidTimeToPunch(strStartTime, strEndTime) {
  console.log(`isValidTimeToPunch strTime=${JSON.stringify(strStartTime)}`);
  const format = 'HH:mm';
  var result = {
    code: 0,
    message: "",
  };
  try {
    const { data } = await getWorkday()
    console.log(`timeUtils.js isValidTimeToPunch JSON.stringify(data)=${JSON.stringify(data)}`);
    const dateStartTime = moment(strStartTime, format);
    const dateEndTime = moment(strEndTime, format);
    const dateLunchStartTime = moment(data.lunchBreakStart, format);
    const dateLunchEndTime = moment(data.lunchBreakEnd, format);
    const strLunchBreakStart = moment(data.lunchBreakStart, format).format(format);
    const strLunchBreakEnd = moment(data.lunchBreakEnd, format).format(format);
    result.message = `午休時間:${strLunchBreakStart} ~ ${strLunchBreakEnd}`;
    if (strStartTime !== null && strStartTime !== "") {
      if (dateStartTime.isBetween(dateLunchStartTime, dateLunchEndTime)) {
        result.code = 1;
        result.message += `，開始時間為午休時間內。`;
      }
    }
    if (strEndTime !== null && strEndTime !== "") {
      if (dateEndTime.isBetween(dateLunchStartTime, dateLunchEndTime)) {
        result.code = 2;
        result.message += `，結束時間為午休時間內。`;
      }
    }
    if (strStartTime !== null && strStartTime !== "" && strEndTime !== null && strEndTime !== "") {
      if (dateStartTime.isSame(dateLunchStartTime) && dateEndTime.isSame(dateLunchEndTime)) {
        result.code = 3;
        result.message += "，開始及結束時間為午休時間。";
      }
    }
  } catch (err) { console.log(err ) }
  
  
  return result;
}

export async function getWorkdayTime() {
  const format = 'HH:mm';
  var result = "";
  try {
    const { data } = await getWorkday()
    console.log(`timeUtils.js getWorkdayTime()`);
    const strWorkdayStart = moment(data.workdayStart, format).format(format);
    const strWorkdayEnd = moment(data.workdayEnd, format).format(format);
    const strLunchBreakStart = moment(data.lunchBreakStart, format).format(format);
    const strLunchBreakEnd = moment(data.lunchBreakEnd, format).format(format);
    result = `上班時間為：${strWorkdayStart} ~ ${strWorkdayEnd} ，午休時間為：${strLunchBreakStart} ~ ${strLunchBreakEnd}。`;
  } catch (err) { console.log(err ) }
  
  
  return result;
}

// export function getOvertimeWeightedHour(workingHoursParamsConfig, overtimeDateType, overtimeRecords, overtimeHours) {
//   var overtimeWeighted = 0.0;
//   if (overtimeDateType === "workdays") {
//     // console.log("getOvertimeWeightedHour workdays workingHoursParamsConfig.normalDayExtraRate:" + JSON.stringify(workingHoursParamsConfig.normalDayExtraRate));
//     if (overtimeHours >= 2) {
//       overtimeWeighted += Math.round((overtimeHours - 2) * workingHoursParamsConfig.normalDayExtraRate.overTwoHour * 100) / 100;
//       overtimeHours -= overtimeHours - 2;
//       overtimeWeighted += Math.round((overtimeHours) * workingHoursParamsConfig.normalDayExtraRate.firstTwoHour * 100) / 100;
//     } else {
//       overtimeWeighted += Math.round((overtimeHours) * workingHoursParamsConfig.normalDayExtraRate.firstTwoHour * 100) / 100;
//     }
//     // console.log("getOvertimeWeightedHour foundUser:" + JSON.stringify(foundUser));
//     // console.log("getOvertimeWeightedHour userIDObject.overtimeHourWeighted:" + userIDObject.overtimeHourWeighted + " getHourlySalary(foundUser.basicSalary):" + getHourlySalary(foundUser.basicSalary));
//   } else if (overtimeDateType === "restdays") {
//     if (overtimeHours >= 9) {
//       overtimeWeighted += Math.round((overtimeHours - 9) * workingHoursParamsConfig.restDayExtraRate.overEightHour * 100) / 100;
//       overtimeHours -= overtimeHours - 9;
//       overtimeWeighted += Math.round((overtimeHours - 2) * workingHoursParamsConfig.restDayExtraRate.overTwoHour * 100) / 100;
//       overtimeHours -= overtimeHours - 2;
//       overtimeWeighted += Math.round((overtimeHours) * workingHoursParamsConfig.restDayExtraRate.firstTwoHour * 100) / 100;
//     } else if (overtimeHours >= 2) {
//       overtimeWeighted += Math.round((overtimeHours - 2) * workingHoursParamsConfig.restDayExtraRate.overTwoHour * 100) / 100;
//       overtimeHours -= overtimeHours - 2;
//       overtimeWeighted += Math.round((overtimeHours) * workingHoursParamsConfig.restDayExtraRate.firstTwoHour * 100) / 100;
//     } else {
//       overtimeWeighted += Math.round((overtimeHours) * workingHoursParamsConfig.restDayExtraRate.firstTwoHour * 100) / 100;
//     }
//   } else if (overtimeDateType === "nationalHolidays") {
//     // console.log("getOvertimeWeightedHour workdays workingHoursParamsConfig.nationalHolidayExtraRate:" + JSON.stringify(workingHoursParamsConfig.nationalHolidayExtraRate));
//     if (workingHoursParamsConfig.nationalHolidayExtraRate.makeItADay === true) {
//       if (overtimeHours <= 8) {
//         overtimeWeighted = 8;
//       } else {
//         if (overtimeHours >= 12) {
//           overtimeWeighted += Math.round((overtimeHours - 12) * workingHoursParamsConfig.nationalHolidayExtraRate.overTwoHour * 100) / 100;
//           overtimeHours -= overtimeHours - 12;
//           overtimeWeighted += Math.round((overtimeHours - 10) * workingHoursParamsConfig.nationalHolidayExtraRate.firstTwoHour * 100) / 100;
//           overtimeHours -= overtimeHours - 10;
//           overtimeWeighted += Math.round((overtimeHours) * workingHoursParamsConfig.nationalHolidayExtraRate.underEightHour * 100) / 100;
//         } else if (overtimeHours >= 10) {
//           overtimeWeighted += Math.round((overtimeHours - 10) * workingHoursParamsConfig.nationalHolidayExtraRate.firstTwoHour * 100) / 100;
//           overtimeHours -= overtimeHours - 10;
//           overtimeWeighted += Math.round((overtimeHours) * workingHoursParamsConfig.nationalHolidayExtraRate.underEightHour * 100) / 100;
//         } else {
//           overtimeWeighted += Math.round((overtimeHours) * workingHoursParamsConfig.nationalHolidayExtraRate.underEightHour * 100) / 100;
//         }
//       }
//     } else {
//       if (overtimeHours >= 12) {
//         overtimeWeighted += Math.round((overtimeHours - 12) * workingHoursParamsConfig.nationalHolidayExtraRate.overTwoHour * 100) / 100;
//         overtimeHours -= overtimeHours - 12;
//         overtimeWeighted += Math.round((overtimeHours - 10) * workingHoursParamsConfig.nationalHolidayExtraRate.firstTwoHour * 100) / 100;
//         overtimeHours -= overtimeHours - 10;
//         overtimeWeighted += Math.round((overtimeHours) * workingHoursParamsConfig.nationalHolidayExtraRate.underEightHour * 100) / 100;
//       } else if (overtimeHours >= 10) {
//         overtimeWeighted += Math.round((overtimeHours - 10) * workingHoursParamsConfig.nationalHolidayExtraRate.firstTwoHour * 100) / 100;
//         overtimeHours -= overtimeHours - 10;
//         overtimeWeighted += Math.round((overtimeHours) * workingHoursParamsConfig.nationalHolidayExtraRate.underEightHour * 100) / 100;
//       } else {
//         overtimeWeighted += Math.round((overtimeHours) * workingHoursParamsConfig.nationalHolidayExtraRate.underEightHour * 100) / 100;
//       }
//     }
//   }
//   if (overtimeDateType === "holidays") {//例假日
//     if (workingHoursParamsConfig.holidayExtraRate.makeItADay === true) {
//       if (overtimeHours <= 8) {
//         overtimeWeighted = 8;
//       } else {
//         if (overtimeHours >= 12) {
//           overtimeWeighted += Math.round((overtimeHours - 12) * workingHoursParamsConfig.holidayExtraRate.overTwoHour * 100) / 100;
//           overtimeHours -= overtimeHours - 12;
//           overtimeWeighted += Math.round((overtimeHours - 10) * workingHoursParamsConfig.holidayExtraRate.firstTwoHour * 100) / 100;
//           overtimeHours -= overtimeHours - 10;
//           overtimeWeighted += Math.round((overtimeHours) * workingHoursParamsConfig.holidayExtraRate.underEightHour * 100) / 100;
//         } else if (overtimeHours >= 10) {
//           overtimeWeighted += Math.round((overtimeHours - 10) * workingHoursParamsConfig.holidayExtraRate.firstTwoHour * 100) / 100;
//           overtimeHours -= overtimeHours - 10;
//           overtimeWeighted += Math.round((overtimeHours) * workingHoursParamsConfig.holidayExtraRate.underEightHour * 100) / 100;
//         } else {
//           overtimeWeighted += Math.round((overtimeHours) * workingHoursParamsConfig.holidayExtraRate.underEightHour * 100) / 100;
//         }
//       }
//     } else {
//       if (overtimeHours >= 12) {
//         overtimeWeighted += Math.round((overtimeHours - 12) * workingHoursParamsConfig.holidayExtraRate.overTwoHour * 100) / 100;
//         overtimeHours -= overtimeHours - 12;
//         overtimeWeighted += Math.round((overtimeHours - 10) * workingHoursParamsConfig.holidayExtraRate.firstTwoHour * 100) / 100;
//         overtimeHours -= overtimeHours - 10;
//         overtimeWeighted += Math.round((overtimeHours) * workingHoursParamsConfig.holidayExtraRate.underEightHour * 100) / 100;
//       } else if (overtimeHours >= 10) {
//         overtimeWeighted += Math.round((overtimeHours - 10) * workingHoursParamsConfig.holidayExtraRate.firstTwoHour * 100) / 100;
//         overtimeHours -= overtimeHours - 10;
//         overtimeWeighted += Math.round((overtimeHours) * workingHoursParamsConfig.holidayExtraRate.underEightHour * 100) / 100;
//       } else {
//         overtimeWeighted += Math.round((overtimeHours) * workingHoursParamsConfig.holidayExtraRate.underEightHour * 100) / 100;
//       }
//     }
//   }
//   return overtimeWeighted;
// }