import axiosConfig from './axiosConfig';
import { getDefaultPort } from '@/utils/apiRequestUtils';

const { baseURL } = axiosConfig.defaults;

const port = process.env.REACT_APP_SERVICE_PORT_JOURNALIST !== undefined
  ? process.env.REACT_APP_SERVICE_PORT_JOURNALIST
  : getDefaultPort(baseURL);

const journalistModuleUrl = process.env.NODE_ENV === 'development'
  ? `${baseURL}:${port}/api`
  : `${baseURL}/journalist/api`;

export const postToGetJournalistTypes = config => axiosConfig
  .post(`${journalistModuleUrl}/journalist/type`, config);

export const putNewJournalistTypes = config => axiosConfig
  .put(`${journalistModuleUrl}/journalist/type`, config);

export const patchJournalistType = config => axiosConfig
  .patch(`${journalistModuleUrl}/journalist/type`, config);

export const deletJournalistType = ids => axiosConfig
  .delete(`${journalistModuleUrl}/journalist/type`, { data: [...ids] });

export const postToGetJournalists = config => axiosConfig
  .post(`${journalistModuleUrl}/journalist`, config);

export const putNewJournalist = journalist => axiosConfig
  .put(`${journalistModuleUrl}/journalist`, journalist);

export const deleteJournalists = journalists => axiosConfig
  .delete(`${journalistModuleUrl}/journalist`, { data: [ ...journalists ] });

export const postToGetJournalistDetail = journalistID => axiosConfig
  .post(`${journalistModuleUrl}/journalist/detail`, { journalistID });

export const putModifyJournalistDetail = journalists => axiosConfig
  .put(`${journalistModuleUrl}/journalist/detail`, journalists);

export const postToGetJournalistIDs = type => axiosConfig
  .post(`${journalistModuleUrl}/journalist/journalistIDs`, { type });

export const postToGetInvitationStatus = projectID => axiosConfig
  .post(`${journalistModuleUrl}/journalist/invitationStatus`, { projectID });

export const postToGetInvitations = config => axiosConfig
  .post(`${journalistModuleUrl}/journalist/invitation`, config);

export const putNewInvitation = invitation => axiosConfig
  .put(`${journalistModuleUrl}/journalist/invitation`, invitation);

export const patchModifyInvitation = invitation => axiosConfig
  .patch(`${journalistModuleUrl}/journalist/invitation`, invitation);

export const deleteInvitations = invitationIDs => axiosConfig
  .delete(`${journalistModuleUrl}/journalist/invitation`, { data: [ ...invitationIDs ] });

export const postToGetProjectJournalistIDs = projectID => axiosConfig
  .post(`${journalistModuleUrl}/journalist/invitation/journalistIDs`, { projectID });
