import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';

import { postToGetUserInfo } from '@/api/authService';

import {
  getAttendanceDetail,
  putModifyAttendance,
  putNewAttendanceTime,
} from '@/api/attendanceService';

import { isValidTimeToPunch } from '@/utils/timeUtils';

import {
  FETCH_MEMBER_OPTIONS,
} from '@/store/actions/srpOptionsAction';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import MainContent from '@/component-style/MainContent';
import TableWrapper from '@/components/UI/TableWrapper';
import DateSelector from '@/components/UI/DateSelector';
import { TimePicker } from 'antd';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import SelectSearchable from '@/components/UI/Select/SelectSearchable';
import { ButtonBasic, ButtonCancel } from '@/component-style/Button';
import { PSubtitle } from '@/component-style/TextElement';
import {
  DivFlexRow,
  DivItemWrapper,
} from '@/component-style/RWDWrapper';

import {
  // addTime,
  formatTime,
  // subtractTime,
} from '@/utils/timeUtils';

const ButtonPunchIn = styled(ButtonBasic)`
  margin-right: 10px;
  width: 120px;
`;

const ButtonClear = styled(ButtonCancel)`
  width: 120px;
`;

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding: 0;
  padding-bottom: 30px;
  justify-content: flex-end;

  @media (max-width: 425px) {
    justify-content: center;
    padding-left: 0;
  }
`;

const punchTimeConfig = {
  userID: '',
  // startTime: null,
  // endTime: null,
  reportedInTime: false,
  companyType: '',
  startTimeLocal: null,
  endTimeLocal: null,
};

const PunchTimeBackup = () => {
  const dispatch = useDispatch();
  const memberOptions = useSelector(state => state.srpOptions.memberOptions);
  const showSaveFailPopup = useSelector(state => state.apiFail.showSaveFailPopup);
  const apiFailPopupTitle = useSelector(state => state.apiFail.title);
  const apiFailPopupMessage = useSelector(state => state.apiFail.message);

  const [isPunchedIn, setIsPunchedIn] = useState(false);
  const [workingDate, setWorkingDate] = useState(null);

  const [punchTime, setPunchTime] = useState({ ...punchTimeConfig });
  
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [showSaveSuccessPopup, setShowSaveSuccessPopup] = useState(false);
  const [successPopupTitle, setSuccessPopupTitle] = useState("");

  const disableBackup = punchTime.startTimeLocal === null
    || punchTime.endTimeLocal === null;

  const disableDatePicker = punchTime.userID === ''
    || workingDate === null;

  // const disabledStartDate = current => current > addTime(workingDate, 1, 'days')
  //   || current < subtractTime(workingDate, 0, 'days');

  function workiingDisabledStartDate(current) {
    // Can not select days before today and today
    return current && current > moment().endOf('day');
  }

  // const disabledEndDate = current => current > addTime(workingDate, 2, 'days')
  //   || current < subtractTime(workingDate, 0, 'days');

  const userChangeHandler = userID => {
    setPunchTime(prevState => ({
      ...prevState,
      userID,
      startTimeLocal: null,
      endTimeLocal: null,
    }));
    setWorkingDate(null);
  };

  const workingDateChangeHandler = value => {
    const date = formatTime(value, 'YYYY-MM-DD');

    setIsPunchedIn(false);
    setWorkingDate(date);
    setPunchTime(prevState => ({
      ...punchTimeConfig,
      userID: prevState.userID,
    }));
    date !== null && fetchPunchTime(date);
  };

  const closeSuccessPopupHandler = () => {
    setIsPunchedIn(false);
    setWorkingDate(null);
    setShowSaveSuccessPopup(false);
    setPunchTime({ ...punchTimeConfig });
  };

  const backupPunchHandler = () => {
    isPunchedIn === true
      ? backupPunchOut()
      : backupNewPunchTime();
  };

  const backupNewPunchTime = async () => {
    setShowLoadingPopup(true);

    const resultIsValidTimeToPunch = await isValidTimeToPunch(moment(punchTime.startTimeLocal).format('HH:mm'), moment(punchTime.endTimeLocal).format('HH:mm'));
    if (resultIsValidTimeToPunch.code === 1) {
      // console.log(`punchTimeBackup.js backupPunchOut return = 1`);
      setShowLoadingPopup(false);
      setSuccessPopupTitle(resultIsValidTimeToPunch.message);
      setShowSaveSuccessPopup(true);
    } else if (resultIsValidTimeToPunch.code === 2) {
      // console.log(`punchTimeBackup.js backupPunchOut return = 2`);
      setShowLoadingPopup(false);
      setSuccessPopupTitle(resultIsValidTimeToPunch.message);
      setShowSaveSuccessPopup(true);
    } else if (resultIsValidTimeToPunch.code === 3) {
      // console.log(`punchTimeBackup.js backupPunchOut return = 3`);
      setShowLoadingPopup(false);
      setSuccessPopupTitle(resultIsValidTimeToPunch.message);
      setShowSaveSuccessPopup(true);
    } else {
      try {
        const userInfo = await postToGetUserInfo(punchTime.userID);
        const { companyType } = userInfo.data;
        await putNewAttendanceTime({
          ...punchTime,
          companyType,
        });
        setShowLoadingPopup(false);
        setSuccessPopupTitle("補打卡成功");
        setShowSaveSuccessPopup(true);
      } catch (err) {
        setShowLoadingPopup(false);
        dispatch(OPEN_SAVE_FAIL_POPUP({ title: '補打卡失敗', err }));
      }
    }
  };

  const backupPunchOut = async () => {
    setShowLoadingPopup(true);

    // console.log(`punchTimeBackup.js backupPunchOut JSON.stringify(punchTime.startTimeLocal)=${JSON.stringify(punchTime.startTimeLocal)}`);
    // console.log(`punchTimeBackup.js backupPunchOut moment(punchTime.startTimeLocal).format('HH:mm')=${moment(punchTime.startTimeLocal).format('HH:mm')}`);
    const resultIsValidTimeToPunch = await isValidTimeToPunch(moment(punchTime.startTimeLocal).format('HH:mm'), moment(punchTime.endTimeLocal).format('HH:mm'));
    if (resultIsValidTimeToPunch.code === 1) {
      // console.log(`punchTimeBackup.js backupPunchOut return = 1`);
      setShowLoadingPopup(false);
      setSuccessPopupTitle(resultIsValidTimeToPunch.message);
      setShowSaveSuccessPopup(true);
    } else if (resultIsValidTimeToPunch.code === 2) {
      // console.log(`punchTimeBackup.js backupPunchOut return = 2`);
      setShowLoadingPopup(false);
      setSuccessPopupTitle(resultIsValidTimeToPunch.message);
      setShowSaveSuccessPopup(true);
    } else if (resultIsValidTimeToPunch.code === 3) {
      // console.log(`punchTimeBackup.js backupPunchOut return = 3`);
      setShowLoadingPopup(false);
      setSuccessPopupTitle(resultIsValidTimeToPunch.message);
      setShowSaveSuccessPopup(true);
    } else {
      try {
        const userInfo = await postToGetUserInfo(punchTime.userID);
        const { companyType } = userInfo.data;
        await putModifyAttendance({
          ...punchTime,
          companyType,
        });
        setShowLoadingPopup(false);
        setSuccessPopupTitle("補打卡成功");
        setShowSaveSuccessPopup(true);
      } catch (err) {
        setShowLoadingPopup(false);
        dispatch(OPEN_SAVE_FAIL_POPUP({ title: '補打卡失敗', err }));
      }
    }
  };

  const fetchPunchTime = async (workingDate) => {
    setShowLoadingPopup(true);

    try {
      console.log(`punchTimeBackup.js fetchPunchTime() 0`);
      const { data } = await getAttendanceDetail(punchTime.userID, workingDate);
      const { attendanceID, startTimeLocal, endTimeLocal } = data;
      console.log(`punchTimeBackup.js fetchPunchTime() 1`);
      setPunchTime(prevState => ({
        ...punchTimeConfig,
        userID: prevState.userID,
        attendanceID,
        startTimeLocal,
        endTimeLocal,
      }));
      console.log(`punchTimeBackup.js fetchPunchTime() 2`);
      setIsPunchedIn(true);

    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const initOptions = useCallback(() => {
    dispatch(FETCH_MEMBER_OPTIONS());
  }, [dispatch]);

  useEffect(() => {
    initOptions();
  }, [initOptions]);

  return (
    <MainContent>
      <TableWrapper title = "補打卡記錄" >
        <DivFlexRow>
          <DivItemWrapper>
            <PSubtitle>
              員工
              <span>*</span>
            </PSubtitle>
            <SelectSearchable
              value = { punchTime.userID }
              options = {[
                { value: '', desc: '請選擇員工名稱' },
                ...memberOptions,
              ]}
              changeHandler = { userChangeHandler }
            />
          </DivItemWrapper>
          <DivItemWrapper>
            <PSubtitle>
              上班日期
              <span>*</span>
            </PSubtitle>
            <DateSelector
              disabled = { punchTime.userID === '' }
              value = { workingDate }
              changeHandler = { workingDateChangeHandler }
              disabledDate = { workiingDisabledStartDate }
            />
          </DivItemWrapper>
        </DivFlexRow>
        <DivFlexRow>
          <DivItemWrapper>
            <PSubtitle>
              上班時間
              <span>*</span>
            </PSubtitle>
            {/* <DateSelector
              showTime = { true }
              disabled = { disableDatePicker }
              disabledDate = { disabledStartDate }
              value = { punchTime.startTime }
              changeHandler = { value => {
                setPunchTime(prevState => ({ ...prevState, startTime: value }))
              }}
              okHandler = { value => {
                setPunchTime(prevState => ({ ...prevState, startTime: value }))
              }}
            /> */}
            <TimePicker
              disabled = { disableDatePicker }
              value = { punchTime.startTimeLocal == null ? null : moment(moment(new Date(punchTime.startTimeLocal)).format('HH:mm'), 'HH:mm') }
              onChange = { value => {
                // console.log(moment(moment(new Date(punchTime.startTimeLocal)).format('HH:mm'), 'HH:mm'));
                // value = formatTime(Date.now());
                value.set({'year': formatTime(workingDate, 'YYYY'), 'month': formatTime(workingDate, 'MM') - 1, 'date': formatTime(workingDate, 'DD')});
                console.log(formatTime(value));
                setPunchTime(prevState => ({ ...prevState, startTimeLocal: formatTime(value) }));
              }}
              format = { 'HH:mm' } 
            />
          </DivItemWrapper>
          <DivItemWrapper>
            <PSubtitle>
              下班時間
              <span>*</span>
            </PSubtitle>
            {/* <DateSelector
              showTime = { true }
              disabled = { disableDatePicker }
              disabledDate = { disabledEndDate }
              value = { punchTime.endTime }
              changeHandler = { value => {
                setPunchTime(prevState => ({ ...prevState, endTime: value }))
              }}
              okHandler = { value => {
                setPunchTime(prevState => ({ ...prevState, endTime: value }))
              }}
            /> */}
            <TimePicker
              disabled = { disableDatePicker }
              value = { punchTime.endTimeLocal == null ? null : moment(moment(new Date(punchTime.endTimeLocal)).format('HH:mm'), 'HH:mm') }
              onChange = { value => {
                // value = formatTime(Date.now());
                value.set({'year': formatTime(workingDate, 'YYYY'), 'month': formatTime(workingDate, 'MM') - 1, 'date': formatTime(workingDate, 'DD')});
                setPunchTime(prevState => ({ ...prevState, endTimeLocal: formatTime(value) }));
              }}
              format = { 'HH:mm' } 
            />
          </DivItemWrapper>
        </DivFlexRow>
        <DivFlexRow>
          <DivButtonsWrapper>
            <ButtonPunchIn
              disabled = { disableBackup }
              onClick = { backupPunchHandler }
            >補打卡</ButtonPunchIn>
            <ButtonClear
              onClick = { () => {
                setPunchTime({ ...punchTimeConfig });
                workingDateChangeHandler(null);
              }}
            >清除欄位</ButtonClear>
          </DivButtonsWrapper>
        </DivFlexRow>
      </TableWrapper>
      { showLoadingPopup && <PopupLoading/> }
      {
        showSaveSuccessPopup &&
          <PopupExecuteResult
            title = { successPopupTitle }
            confirmHandler = { closeSuccessPopupHandler }
          />
      }
      {
        showSaveFailPopup &&
          <PopupExecuteResult
            title = { apiFailPopupTitle }
            text = { apiFailPopupMessage }
            confirmHandler = { () => {
              dispatch(CLOSE_SAVE_FAIL_POPUP());
            }}
          />
      }
    </MainContent>
  )
};

export default PunchTimeBackup;
