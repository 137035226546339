export const projectQuotationConfig = {
  projectID: '',
  customerID: '',
  companyType: '',
  name: '',
  type: 'oop',
  director: '',
  rate: 10,
  quotationItems: [],
};

export const projectQuotationItem = {
  type: '',
  tag: '',
  note: '',
  price: '',
  amount: '',
  quotedPrice: '',
};

export const projectFinanceConfig = {
  projectID: '',
  specialPrice: '',
  isPaid: false,
  note: '',
  projectOtherIncome: 0,
};

export const projectInvoicesConfig = {
  projectID: '',
  customerID: '',
  companyType: '',
  invoices: [],
};

export const projectInvoiceItem = {
  accountingExportable: true,
  accountingExported: false,
  invoiceCode: '',
  invoiceTaxId: '',
  createDate: null,
  untaxedAmount: '',
  tax: '',
  amount: '',
  note: '',
  po: '',
  income: '',
  predictDate: null,
  paymentDate: null,
  mbr: null,
};

export const projectPRServicesConfig = {
  projectID: '',
  customerID: '',
  companyType: '',
  prServices: [],
};

export const projectPRServiceItem = {
  director: '',
  invoiceCode: '',
  prPrice: '',
  oopPrice: '',
  income: '',
  index: '',
};

export const leaveApplicationConfig = {
  userId: '',
  description: '',
  leaveId: '',
  leaveFrom: null,
  leaveTo: null,
};
