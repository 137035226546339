import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  getAttendanceDetail,
  putModifyAttendance,
  putNewAttendanceTime,
} from '@/api/attendanceService';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import MainContent from '@/component-style/MainContent';
import TableWrapper from '@/components/UI/TableWrapper';
import DateSelector from '@/components/UI/DateSelector';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import PopupExecuteConfirm from '@/components/UI/Popup/PopupExecuteConfirm';
import { InputBasic } from '@/component-style/Input';
import { ButtonBasic } from '@/component-style/Button';
import { PSubtitle } from '@/component-style/TextElement';
import {
  DivFlexRow,
  DivItemWrapper,
} from '@/component-style/RWDWrapper';

import {
  // toUTCTime,
  formatTime,
  subtractTime,
  getWorkdayTime,
} from '@/utils/timeUtils';

const ButtonPunchIn = styled(ButtonBasic)`
  width: 120px;

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`;

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding: 0;
  padding-bottom: 30px;
  justify-content: flex-end;

  @media (max-width: 425px) {
    justify-content: center;
    padding-left: 0;
  }
`;

const punchTimeConfig = {
  startTimeLocal: null,
  endTimeLocal: null,
  reportedInTime: true,
  companyType: '',
};

const PunchTimeCreation = () => {
  const dispatch = useDispatch();
  const userID = useSelector(state => state.login.token);
  const userName = useSelector(state => state.login.user);
  const companyType = useSelector(state => state.login.companyType);
  const showSaveFailPopup = useSelector(state => state.apiFail.showSaveFailPopup);
  const apiFailPopupTitle = useSelector(state => state.apiFail.title);
  const apiFailPopupMessage = useSelector(state => state.apiFail.message);
  
  const [punchTime, setPunchTime] = useState({ ...punchTimeConfig });
  
  const [workingDate, setWorkingDate] = useState(formatTime(Date.now(), 'YYYY-MM-DD'));
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [showPunchTimePopup, setShowPunchTimePopup] = useState(false);
  const [showSaveSuccessPopup, setShowSaveSuccessPopup] = useState(false);

  const [workdayTime, setWorkdayTime] = useState("");

  const punchPopupTitle = punchTime.startTimeLocal === null
    ? '上班打卡' : '下班打卡';

  const disablePunchToday = workingDate === formatTime(Date.now(), 'YYYY-MM-DD')
    && punchTime.startTimeLocal !== null
    && punchTime.endTimeLocal !== null;

  const disablePunchYesterday = workingDate === formatTime(subtractTime(Date.now(), 1, 'days'), 'YYYY-MM-DD')
    && (punchTime.startTimeLocal === null || new Date().getHours() > 8);

  const disabledDate = current => current > Date.now()
    || current < subtractTime(Date.now(), 2, 'days');

  const workingDateChangeHandler = value => {
    const date = formatTime(value, 'YYYY-MM-DD');

    setWorkingDate(date);
    setPunchTime(prevState => ({
      ...prevState,
      startTimeLocal: null,
      endTimeLocal: null,
      reportedInTime: date === formatTime(Date.now(), 'YYYY-MM-DD'),
    }));
    fetchPunchTime(date);
  };

  const punchTimeHandler = () => {
    setShowPunchTimePopup(false);

    punchTime.startTimeLocal === null
      ? postPunchInHandler()
      : putPunchOutHandler();
  };

  const closeSuccessPopupHandler = () => {
    setShowSaveSuccessPopup(false);
    fetchPunchTime();
  };

  const postPunchInHandler = async () => {
    setShowLoadingPopup(true);

    try {
      await putNewAttendanceTime({
        ...punchTime,
        userID,
        companyType,
        // startTime: toUTCTime(Date.now()),
        startTimeLocal: formatTime(Date.now()),
      });
      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '打卡失敗', err }));
    }
  };

  const putPunchOutHandler = async () => {
    setShowLoadingPopup(true);

    try {
      await putModifyAttendance({
        ...punchTime,
        userID,
        companyType,
        // endTime: toUTCTime(Date.now()),
        endTimeLocal: formatTime(Date.now()),
      });
      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '打卡失敗', err }));
    }
  };

  const fetchPunchTime = async (workingDate = formatTime(Date.now(), 'YYYY-MM-DD')) => {
    setShowLoadingPopup(true);

    try {
      console.log(`punchTimeCreation.js fetchPunchTime()`);
      const { data } = await getAttendanceDetail(userID, workingDate);
      const { startTime, ...dataTwo } = data;
      console.log(`punchTimeCreation.js fetchPunchTime() data=${JSON.stringify(data)}`);
      // console.log("fetchPunchTime dataTwo:" + JSON.stringify(dataTwo));
      setPunchTime({
        ...punchTimeConfig,
        ...dataTwo,
      });

    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const initPunchTime = useCallback(
    fetchPunchTime, []);

  useEffect(() => {
    initPunchTime();
  }, [initPunchTime]);

  const fetchWorkdayTime = async () => {
    setShowLoadingPopup(true);
    try {
      console.log(`punchTimeCreation.js fetchWorkdayTime()`);
      const strWorkdayTime = await getWorkdayTime();
      setWorkdayTime(strWorkdayTime);

    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const initWorkdayTime = useCallback(
    fetchWorkdayTime, []);

  useEffect(() => {
    initWorkdayTime();
  }, [initWorkdayTime]);

  return (
    <MainContent>
      <TableWrapper title = {"新增打卡記錄 -" + workdayTime} >
        <DivFlexRow>
          <DivItemWrapper>
            <PSubtitle>員工</PSubtitle>
            <InputBasic
              disabled = { true }
              value = { userName }
            />
          </DivItemWrapper>
          <DivItemWrapper>
            <PSubtitle>上班日期</PSubtitle>
            <DateSelector
              value = { workingDate }
              disabledDate = { disabledDate }
              changeHandler = { workingDateChangeHandler }
            />
          </DivItemWrapper>
        </DivFlexRow>
        <DivFlexRow>
          <DivItemWrapper>
            <PSubtitle>上班時間</PSubtitle>
            <DateSelector
              showTime = { true }
              disabled = { true }
              value = { punchTime.startTimeLocal }
            />
          </DivItemWrapper>
          <DivItemWrapper>
            <PSubtitle>下班時間</PSubtitle>
            <DateSelector
              showTime = { true }
              disabled = { true }
              value = { punchTime.endTimeLocal }
            />
          </DivItemWrapper>
        </DivFlexRow>
        <DivFlexRow>
          <DivButtonsWrapper>
            <ButtonPunchIn
              disabled = { disablePunchToday || disablePunchYesterday }
              onClick = { () => { setShowPunchTimePopup(true) }}
            >打卡</ButtonPunchIn>
          </DivButtonsWrapper>
        </DivFlexRow>
      </TableWrapper>
      { showLoadingPopup && <PopupLoading/> }
      { 
        showPunchTimePopup &&
          <PopupExecuteConfirm
            title = { punchPopupTitle }
            text = "確定打卡？"
            confirmHandler = { punchTimeHandler }
            cancelHandler = { () => { setShowPunchTimePopup(false) }}
          />
      }
      {
        showSaveSuccessPopup &&
          <PopupExecuteResult
            title = "打卡成功"
            confirmHandler = { closeSuccessPopupHandler }
          />
      }
      {
        showSaveFailPopup &&
          <PopupExecuteResult
            title = { apiFailPopupTitle }
            text = { apiFailPopupMessage }
            confirmHandler = { () => {
              dispatch(CLOSE_SAVE_FAIL_POPUP());
            }}
          />
      }
    </MainContent>
  )
};

export default PunchTimeCreation;
