import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import PanelWrapper from '@/components/UI/PanelWrapper';
import { InputBasic } from '@/component-style/Input';
import { ButtonBasic } from '@/component-style/Button';
import { PSubtitle } from '@/component-style/TextElement';
import {
  DivFlexRow,
  DivItemWrapper,
} from '@/component-style/RWDWrapper';
import Checkbox from '@/components/UI/Checkbox';
import { integerConverter } from '@/utils/textUtils';

import DateSelectorTwo from '@/components/UI/DateSelectorTwo';
import SelectSearchable from '@/components/UI/Select/SelectSearchable';
import PopupLoading from '@/components/UI/Popup/PopupLoading';

import { cloneDeep } from 'lodash';
import { healthDependentParamsConfig, paycheckStructureParamsConfig } from '@/constant/dataConfig/sysConfigModule.js';

import { postToGetUserInfo } from '@/api/authService';

import { 
  postCreatePaycheckStructure, 
  patchUpdatePaycheckStructure, 
  getPaycheckStructureById, 
  getLaborHealthInsuranceSettings,
  deletePaycheckStructure,
} from '@/api/financeService';

import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import PopupExecuteConfirm from '@/components/UI/Popup/PopupExecuteConfirm';

import moment from 'moment';
import { healthInsuranceRelationWithUserOptions } from '@/constant/options';

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding: 0;
  justify-content: space-between;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const ButtonAdd = styled(ButtonBasic)`
  margin-right: 10px;
  width: 120px;

  @media (max-width: 767px) {
    margin-right: 0;
    margin-bottom: 10px;
  }
`;

const PanelAddWageStructure = props => {
  const history = useHistory();
  const authorityRoutes = useSelector(state => state.login.authorityRoutes);
  const { config } = props;

  const userAddable = authorityRoutes.indexOf('UserCreation') !== -1;

  const [showSaveSuccessPopup, setShowSaveSuccessPopup] = useState(false);
  const [popupTitle, setPopupTitle] = useState("新增薪資計算成功");
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);

  const [showConfirmPopup, setShowConfirmPopup] = useState(false);

  const [laborHealthInsuranceGrade, setLaborHealthInsuranceGrade] = useState([]);

  const [mode, setMode] = useState(0);  //0=idle, 1=modify, 2=new
  const [paycheckStructure, setPaycheckStructure] = useState(cloneDeep(paycheckStructureParamsConfig));
  const [userInfo, setUserInfo] = useState({});
  // const [modifyPaycheckStructureId, setModifyPaycheckStructureId] = useState("");
  const modifyPaycheckStructureId = useRef("");
  console.log(`PanelAddWageStructure.js props.modifyPaycheckStructureId=${props.modifyPaycheckStructureId}`);

  

  const applyDateChangeHandler = (value) => {
    const updateObj = { ...paycheckStructure };
    var tempApplyDate = moment.utc(value);
    tempApplyDate.set('date', 1);
    tempApplyDate.set('hour', 0);
    tempApplyDate.set('minute', 0);
    tempApplyDate.set('second', 0);
    // console.log(`PanelAddWageStructure.js applyDateChangeHandler() tempApplyDate:${tempApplyDate}`);
    updateObj["applyDate"] = tempApplyDate.format();
    setPaycheckStructure({ ...updateObj });
  };

  const updateHealthDependentHandler = (index, key, value) => {
    console.log(`PanelAddWageStructure.js updateHealthDependentHandler() key=${key}&value=${value}`);
    const updateObj = [ ...paycheckStructure.dependents ];
    var path = key.split('.');
    var last = path.pop();
    console.log(`PanelAddWageStructure.js updateHealthDependentHandler() JSON.stringify(updateObj)=${JSON.stringify(updateObj)}`);
    updateObj.forEach(function (element, i) {
      if (i === index) {
        path.reduce(function (o, k) {
            o[k] = o[k] || {};
            return o[k];
        }, element)[last] = value;
      }
    });
    console.log(`PanelAddWageStructure.js updateHealthDependentHandler() JSON.stringify(updateObj)=${JSON.stringify(updateObj)}`);
    updatePaycheckStructureHandler("dependents", [...updateObj]);
    // console.log(`PanelAddWageStructure.js updateHealthDependentHandler() key=${key}&value=${value}`);
    // const updateObj = [ ...paycheckStructure.dependents ];
    // console.log(`PanelAddWageStructure.js updateHealthDependentHandler() JSON.stringify(updateObj)=${JSON.stringify(updateObj)}`);
    // updateObj.forEach(function (element, i) {
    //   console.log(`PanelAddWageStructure.js updateHealthDependentHandler() i=${i}`);
    //   if (i === index) {
    //     element.key = value;
    //   }
    // });
    // console.log(`PanelAddWageStructure.js updateHealthDependentHandler() JSON.stringify(updateObj)=${JSON.stringify(updateObj)}`);
    // updatePaycheckStructureHandler("dependents", [...updateObj]);
  }

  const addHealthDependentHandler = () => {
    console.log(`PanelAddWageStructure.js addHealthDependentHandler() `);
    var clonedObject = cloneDeep(healthDependentParamsConfig);
    updatePaycheckStructureHandler("dependents", [...paycheckStructure.dependents, clonedObject]);
  }

  const deleteHealthDependentHandler = (toDeleteIndex) => {
    console.log(`PanelAddWageStructure.js deleteHealthDependentHandler() `);
    const result = paycheckStructure.dependents.filter((element, i) => i !== toDeleteIndex);
    updatePaycheckStructureHandler("dependents", [...result]);
  }
  
  const updatePaycheckStructureHandler = (key, value) => {
    console.log(`PanelAddWageStructure.js updatePaycheckStructureHandler() key=${key}&JSON.stringify(value)=${JSON.stringify(value)}`);
    const updateObj = { ...paycheckStructure };
    updateObj[key] = value;
    setPaycheckStructure({ ...updateObj });
  };

  const savePaycheckStructureHandler = async() => {
    setShowLoadingPopup(true);
    try {
      if (props.modifyPaycheckStructureId !== "") {//modify
        const temp = { ...paycheckStructure };
        delete temp.id;
        delete temp.userId;
        const data = await patchUpdatePaycheckStructure(paycheckStructure.id, temp);
        setPopupTitle("更新薪資計算成功");
      } else {
        var tempPaycheckStructure = { ...paycheckStructure };
        tempPaycheckStructure.userId = props.editUserId;
        tempPaycheckStructure.adjustDate = moment().format();
        //healthPay is optional if it's "" delete it
        if (tempPaycheckStructure.healthPay === "") { 
          delete tempPaycheckStructure.healthPay;
        }

        console.log(`PanelAddWageStructure.js savePaycheckStructureHandler() JSON.stringify(tempPaycheckStructure)=${JSON.stringify(tempPaycheckStructure)}`);
        const userInfo = await postCreatePaycheckStructure(tempPaycheckStructure);
        console.log(`PanelAddWageStructure.js savePaycheckStructureHandler() JSON.stringify(userInfo.data)=${JSON.stringify(userInfo.data)}`);
        setPopupTitle("新增薪資計算成功");
      }
      // clearPaycheckStructureHandler();
      setShowSaveSuccessPopup(true);
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const deletePaycheckStructureHandler = async() => {
    setShowLoadingPopup(true);
    try {
      if (props.modifyPaycheckStructureId !== "") {//modify
        const data = await deletePaycheckStructure(paycheckStructure.id);
        setPopupTitle("刪除薪資計算成功");
      } 
      setShowSaveSuccessPopup(true);
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const clearPaycheckStructureHandler = async() => {
    setMode(0);
    console.log(`PanelAddWageStructure.js clearPaycheckStructureHandler()`);
    // setModifyPaycheckStructureId("");
    props.clearModifyPaycheckStructureId();
    setPaycheckStructure(cloneDeep(paycheckStructureParamsConfig));
  }

  const fetchOptions = async () => {
    setShowLoadingPopup(true);

    try {
      const userInfo = await postToGetUserInfo(props.editUserId);
      setUserInfo(userInfo.data);
      console.log(`PanelAddWageStructure.js fetchOptions() JSON.stringify(userInfo.data):${JSON.stringify(userInfo.data)}` );

      const dataLaborHealthInsurance = await getLaborHealthInsuranceSettings();
      // console.log("PanelAddWageStructure.js fetchUser() JSON.stringify(dataLaborHealthInsurance):" + JSON.stringify(dataLaborHealthInsurance));
      const tempLaobrHealthInsuranceGrade = dataLaborHealthInsurance.data.settings.map((element) => {
        return {"desc": element.insuranceGrade + ` 勞保僱主:${element.laborEmployerPay} 勞保勞工:${element.laborEmployeePay} 健保僱主:${element.healthEmployerPay} 健保勞工:${element.healthEmployeePay}`, "value": element.insuranceGrade};
      });
      console.log("PanelAddWageStructure.js fetchOptions() JSON.stringify(laobrHealthInsuranceGrade):" + JSON.stringify(tempLaobrHealthInsuranceGrade));
      setLaborHealthInsuranceGrade(tempLaobrHealthInsuranceGrade);
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const initOptions = useCallback(fetchOptions, []);

  useEffect(() => {
    initOptions();
  }, [initOptions])

  const fetchPaycheckStructureById = async () => {
    setShowLoadingPopup(true);

    try {
      console.log(`PanelAddWageStructure.js fetchPaycheckStructureById() JSON.stringify(props.modifyPaycheckStructureId)=${JSON.stringify(props.modifyPaycheckStructureId)}`);
      const { data } = await getPaycheckStructureById(modifyPaycheckStructureId.current);
      console.log(`PanelAddWageStructure.js fetchPaycheckStructureById() JSON.stringify(data)=${JSON.stringify(data)}`);
      // console.log(`PanelAddWageStructure.js fetchPaycheckStructureById() modifyPaycheckStructureId.current=${modifyPaycheckStructureId.current}`);
      setPaycheckStructure(data);
      // const matchingPaycheckStructure = data.paycheckStructures.find((element) => element.id === modifyPaycheckStructureId.current);
      // console.log(`PanelAddWageStructure.js fetchPaycheckStructureById() JSON.stringify(matchingPaycheckStructure)=${JSON.stringify(matchingPaycheckStructure)}`);
      // setPaycheckStructure(matchingPaycheckStructure);
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const callbackFetchPaycheckStructureById = useCallback(fetchPaycheckStructureById, []);

  useEffect(() => {
    console.log(`PanelAddWageStructure.js useEffect() props.modifyPaycheckStructureId=${JSON.stringify(props.modifyPaycheckStructureId)}`);
    if (props.modifyPaycheckStructureId !== "") {
      setMode(1);
      // console.log(`PanelAddWageStructure.js useEffect() props.modifyPaycheckStructureId=${props.modifyPaycheckStructureId}`);
      // setModifyPaycheckStructureId(props.modifyPaycheckStructureId);
      modifyPaycheckStructureId.current = props.modifyPaycheckStructureId;
      
      callbackFetchPaycheckStructureById();
    }
  }, [props.modifyPaycheckStructureId, callbackFetchPaycheckStructureById])

  return (
    <PanelWrapper title = { 
        props.isViewMode === 1 ? `薪資計算` : 
          props.modifyPaycheckStructureId !== "" ? `修改薪資計算-${userInfo.name}`: `新增薪資計算-${userInfo.name}` 
      }>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>生效月份</PSubtitle>
          <DateSelectorTwo
            disabledDate={(current) => {                 
              return (
                moment(paycheckStructure.applyDate) >= current
              );
            }}
            disabled = { props.isViewMode }
            value = { paycheckStructure.applyDate }
            picker = "month"
            changeHandler = { applyDateChangeHandler }
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <Checkbox
            title = "高齡退休者"
            disabled = { props.isViewMode }
            checked = { paycheckStructure.isSenior }
            changeHandler = { value => {
              updatePaycheckStructureHandler('isSenior', value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>起薪</PSubtitle>
          <InputBasic
            disabled = { props.isViewMode }
            type = "number"
            value = { paycheckStructure.basicSalary }
            onChange = { ({ target }) => {
              updatePaycheckStructureHandler('basicSalary', integerConverter(target.value));
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>勞、健保投保薪資</PSubtitle>
          <SelectSearchable
            disabled = { props.isViewMode }
            value = { paycheckStructure.insuranceGrade }
            options = {[
              { value: '', desc: '請選擇勞、健保投保薪資' },
              ...laborHealthInsuranceGrade,
            ]}
            changeHandler = { value => {
              updatePaycheckStructureHandler('insuranceGrade', value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>勞退自提(%)</PSubtitle>
          <InputBasic
            disabled = { props.isViewMode }
            type = "number"
            value = { paycheckStructure.selfPayPensionPercentage }
            onChange = { ({ target }) => {
              updatePaycheckStructureHandler('selfPayPensionPercentage', integerConverter(target.value));
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>支付健保金額</PSubtitle>
          <InputBasic
            disabled = { props.isViewMode }
            type = "number"
            value = { paycheckStructure.healthPay }
            onChange = { ({ target }) => {
              updatePaycheckStructureHandler('healthPay', integerConverter(target.value));
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
          <PSubtitle>健保眷屬</PSubtitle>
          {
          paycheckStructure.dependents !== undefined && paycheckStructure.dependents.map((dependent, index) => 
            <DivFlexRow>
              <DivItemWrapper>
                <InputBasic
                  disabled = { props.isViewMode }
                  placeholder = "眷屬姓名"
                  value = { dependent.name }
                  onChange = { ({ target }) => {
                    updateHealthDependentHandler(index, 'name', target.value);
                  }}
                />
                &nbsp;&nbsp;
                <SelectSearchable
                  disabled = { props.isViewMode }
                  value = { dependent.relationship }
                  options = {[
                    { value: 0, desc: '請選擇與眷屬關係' },
                    ...healthInsuranceRelationWithUserOptions,
                  ]}
                  changeHandler = { value => {
                    updateHealthDependentHandler(index, 'relationship', value);
                  }}
                />
                &nbsp;&nbsp;
                <Checkbox
                  disabled = { props.isViewMode }
                  title = "特殊身份"
                  checked = { dependent.healthInsurance.isMinor }
                  changeHandler = { value => {
                    updateHealthDependentHandler(index, 'healthInsurance.isMinor', value);
                  }}
                />
                { dependent.healthInsurance.isMinor &&
                  <InputBasic
                    disabled = { props.isViewMode }
                    placeholder = "請輸入支付健保金額"
                    value = { dependent.healthInsurance.amount }
                    onChange = { ({ target }) => {
                      updateHealthDependentHandler(index, 'healthInsurance.amount', integerConverter(target.value));
                    }}
                  />
                }
                &nbsp;&nbsp;
                <ButtonAdd
                  disabled = { props.isViewMode }
                  onClick = { () => {
                    deleteHealthDependentHandler(index);
                  }}
                >刪除</ButtonAdd>
              </DivItemWrapper>
            </DivFlexRow>
          )
        }
      </DivFlexRow>
      { !props.isViewMode &&
        <div>
          <DivFlexRow>
            { paycheckStructure.applyDate !== null &&
              <ButtonAdd
                onClick = { () => { addHealthDependentHandler(); } }
              >新增健保眷屬
              </ButtonAdd>
            }
          </DivFlexRow>
          <DivFlexRow>
            <DivButtonsWrapper>
              <div>&nbsp;</div>
              <div>
                { props.modifyPaycheckStructureId !== "" &&
                  <ButtonAdd
                    onClick = { () => { setShowConfirmPopup(true); } }
                  >{ "刪除薪資計算" }</ButtonAdd>
                }
                <ButtonAdd
                  onClick = { () => { savePaycheckStructureHandler(); } }
                >{ mode === 1 ? "更新薪資計算" : "新增薪資計算" }</ButtonAdd>
                <ButtonAdd
                  onClick = { () => { 
                    // clearPaycheckStructureHandler();
                    props.cancelHandler();
                  } }
                >取消</ButtonAdd>
              </div>
            </DivButtonsWrapper>
          </DivFlexRow>
        </div>
      }
      { showLoadingPopup && <PopupLoading/> }
      {
        showSaveSuccessPopup &&
          <PopupExecuteResult
            title = { popupTitle }
            confirmHandler = { () => {
              setShowSaveSuccessPopup(false);
              props.reloadHandler();
            } }
          />
      }
      {
        showConfirmPopup &&
          <PopupExecuteConfirm
            title = "刪除薪資計算"
            text = { `確定刪除此薪資計算` }
            confirmHandler = { () =>  { 
              setShowConfirmPopup(false);
              deletePaycheckStructureHandler();
            }}
            cancelHandler = { () => {
              setShowConfirmPopup(false);
            }}
          />
      }
    </PanelWrapper>
  );
};

export default PanelAddWageStructure;
