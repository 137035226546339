import React from 'react';
// import { useSelector } from 'react-redux';
import styled from 'styled-components';

import PanelWrapper from '@/components/UI/PanelWrapper';
import SelectNormal from '@/components/UI/Select/SelectNormal';
import DatePeriodSelector from '@/components/UI/DatePeriodSelector';
import SearchButtonSet from '@/components/UI/SearchButtonSet';
// import { InputBasic } from '@/component-style/Input';
import { ButtonBasic } from '@/component-style/Button';
import { PSubtitle } from '@/component-style/TextElement';
import {
  DivFlexRow,
  DivItemWrapper,
  DivLongMultiSelector,
  DivFullWidthWrapper,
} from '@/component-style/RWDWrapper';
import SelectMultiple from '@/components/UI/Select/SelectMultiple';

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding: 0;
  justify-content: space-between;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const ButtonExport = styled(ButtonBasic)`
  width: 120px;

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`;

const PanelRevenueByCustomerReceivedSearch = props => {
  // const financeRoutes = useSelector(state => state.login.financeRoutes);
  const { config } = props;

  // const userExportable = financeRoutes.indexOf('InvoiceSearch-Export') !== -1;

  const dateStartChangeHandler = value => {
    props.updateConfigHandler('date', {
      ...config.date,
      start: value,
    });
  };

  const dateEndChangeHandler = value => {
    props.updateConfigHandler('date', {
      ...config.date,
      end: value,
    });
  };

  return (
    <PanelWrapper title = "各組預收查詢">
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>所屬公司</PSubtitle>
          <SelectNormal
            value = { config.companyType}
            options = {[
              { value: '', desc: '請選擇所屬公司'},
              ...props.companyTypeOptions,
            ]}
            changeHandler = { value => {
              props.updateConfigHandler('companyType', value)
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivFullWidthWrapper>
          <PSubtitle>總監名稱</PSubtitle>
          <DivLongMultiSelector>
            <SelectMultiple
              placeholder="請選擇總監"
              defaultValue={ [] }
              value = { config.director }
              options = {[
                ...props.directorOptions,
              ]}
              changeHandler = { value => {
                props.updateConfigHandler('director', value)
              }}
            />
          </DivLongMultiSelector>
        </DivFullWidthWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>發票日期</PSubtitle>
          <DivLongMultiSelector>
            <DatePeriodSelector
              startTime = { config.date.start }
              endTime = { config.date.end }
              endTimeOKHandler = { dateEndChangeHandler }
              endTimeChangeHandler = { dateEndChangeHandler }
              startTimeOKHandler = { dateStartChangeHandler }
              startTimeChangeHandler = { dateStartChangeHandler }
            />
          </DivLongMultiSelector>
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivButtonsWrapper>
          <ButtonExport
            // disabled = { !userExportable }
            onClick = { props.exportHandler }
          >匯出</ButtonExport>
          <SearchButtonSet
            clearHandler = { props.clearHandler }
            searchHandler = { props.searchHandler }
          />
        </DivButtonsWrapper>
      </DivFlexRow>
    </PanelWrapper>
  );
};

export default PanelRevenueByCustomerReceivedSearch;
