import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';

import {
  putNewProjectTodo,
  postToGetProjectTodos,
} from '@/api/projectTodoService';

import { postToGetProjectDetail } from '@/api/projectService';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import {
  FETCH_TODO_TYPE_OPTIONS,
  FETCH_PROJECT_ID_OPTIONS,
  FETCH_COMPANY_TYPE_OPTIONS,
} from '@/store/actions/srpOptionsAction';

import MainContent from '@/component-style/MainContent';
import PanelWrapper from '@/components/UI/PanelWrapper';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import PopupExecuteConfirm from '@/components/UI/Popup/PopupExecuteConfirm';
import TableSchedules from '@/components/Table/TableSchedules';
import SelectSearchable from '@/components/UI/Select/SelectSearchable';
import { ButtonBasic, ButtonCancel } from '@/component-style/Button';
import { PSubtitle } from '@/component-style/TextElement';
import {
  DivFlexRow,
  DivFullWidthWrapper,
  DivLongMultiSelector,
} from '@/component-style/RWDWrapper';
import { searchConfigGenerator } from '@/utils/apiRequestUtils';

const PanelContainer = styled.div`
  margin-bottom: 40px;
`;

const DivButtonsWrapper = styled(DivFlexRow)`
  padding: 0;
  justify-content: flex-end;
  max-width: 1535px;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const Button = styled(ButtonBasic)`
  width: 120px;
  margin-right: 10px;
  margin-top: 10px;

  &:last-child {
    margin-right: 0;
  }
`;

const ButtonExit = styled(ButtonCancel)`
  width: 120px;
  margin-right: 10px;
  margin-top: 10px;

  &:last-child {
    margin-right: 0;
  }
`;

const ButtonSearch = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
`;


const DivIconWrapper = styled.div`
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const amount = 10;

const ImportSchedules = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const projectIDOptions = useSelector(state => state.srpOptions.projectIDOptions);
  const showSaveFailPopup = useSelector(state => state.apiFail.showSaveFailPopup);
  const apiFailPopupTitle = useSelector(state => state.apiFail.title);
  const apiFailPopupMessage = useSelector(state => state.apiFail.message);

  const [schedules, setSchedules] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [scheduleCurrentPage, setScheduleCurrentPage] = useState(1);

  const [searchProjectID, setSearchProjectID] = useState('');
  const [importProjectID, setImportProjectID] = useState('');
  const [selectAll, setSelectAll] = useState(false);
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [showImportPopup, setShowImportPopup] = useState(false);
  const [showImportSuccessPopup, setShowImportSuccessPopup] = useState(false);

  const setCurrentPageHandler = page => {
    setScheduleCurrentPage(page);
    updateScheduleList(page);
  };

  const searchHandler = async () => {
    setCurrentPageHandler(1);
  };

  const selectAllHandler = value => {
    setSelectAll(value);
    const updateSchedules = schedules
      .map(todo => ({ ...todo, isChecked: value }));
    setSchedules([...updateSchedules]);
  };

  const todoCheckedHandler = (index, value) => {
    const updateSchedules = [...schedules];
    updateSchedules[index].isChecked = value;
    setSchedules([...updateSchedules]);
  };

  const selectedItemNumber = schedules
    .filter(todo => todo.isChecked).length;

  const importProjectTodoHandler = async () => {
    setShowImportPopup(false);
    setShowLoadingPopup(true);

    try {
      const selectedTodos = schedules.filter(todo => todo.isChecked);

      for (let i = 0; i < selectedTodos.length; i += 1) {
        await putNewProjectTodo({
          ...selectedTodos[i],
          projectID: importProjectID,
          estimatedDate: null,
        });
      }
      setShowLoadingPopup(false);
      setShowImportSuccessPopup(true)
    } catch (err) {
      console.log(err);
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '匯入失敗', err }));
    }
  };

  const updateScheduleList = async (page = scheduleCurrentPage) => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetProjectTodos(
        searchConfigGenerator({
          page,
          amount,
          projectID: searchProjectID,
        }));
      const { todos, totalCount } = data;
      const projectTodos = todos
        .map(todo => ({ ...todo, isChecked: false }));
      
      for (let i = 0; i < projectTodos.length; i += 1) {
        const { projectID } = projectTodos[i];
        const { data } = await postToGetProjectDetail(projectID);
        const { projectCode, projectName, companyType } = data;
        projectTodos[i].projectCode = projectCode;
        projectTodos[i].projectName = projectName;
        projectTodos[i].companyType = companyType;
      }

      projectTodos
        .sort((a, b) => a.projectCode > b.projectCode ? 1 : -1);
      
      setSchedules([...projectTodos]);
      setTotalCount(totalCount);
    } catch (err) {
      console.log(err);
    }
    setShowLoadingPopup(false);
  };

  const initOptions = useCallback(
    () => {
      dispatch(FETCH_TODO_TYPE_OPTIONS());
      dispatch(FETCH_PROJECT_ID_OPTIONS());
      dispatch(FETCH_COMPANY_TYPE_OPTIONS());
    },[dispatch]);

  useEffect(() => {
    initOptions();
  }, [initOptions]);

  return (
    <MainContent>
      <PanelContainer>
        <PanelWrapper title = "匯入進度搜尋">
          <DivFlexRow>
            <DivFullWidthWrapper>
              <PSubtitle>專案進度搜尋</PSubtitle>
              <DivLongMultiSelector>
              <SelectSearchable
                value = { searchProjectID }
                options = {[
                  { value: '', desc: '請選擇專案代號' },
                  ...projectIDOptions,
                ]}
                changeHandler = { setSearchProjectID }
              />
              </DivLongMultiSelector>
            </DivFullWidthWrapper>
          </DivFlexRow>
          <DivFlexRow>
            <DivFullWidthWrapper>
              <PSubtitle>
                匯入專案
                <span>*</span>
              </PSubtitle>
              <DivLongMultiSelector>
              <SelectSearchable
                value = { importProjectID }
                options = {[
                  { value: '', desc: '請選擇專案代號' },
                  ...projectIDOptions,
                ]}
                changeHandler = { setImportProjectID }
              />
              </DivLongMultiSelector>
            </DivFullWidthWrapper>
          </DivFlexRow>
          <DivFlexRow>
              <DivButtonsWrapper>
                <Button
                  disabled = { selectedItemNumber === 0 || importProjectID === '' }
                  onClick = { () => { setShowImportPopup(true) } }
                >匯入</Button>
                <ButtonSearch onClick = { searchHandler }>
                  <DivIconWrapper>
                    <FontAwesomeIcon icon = { faSearch }/>
                  </DivIconWrapper>
                  搜尋
                </ButtonSearch>
                <ButtonExit
                  onClick = { () => { history.goBack() } }
                >離開</ButtonExit>
              </DivButtonsWrapper>
            </DivFlexRow>
        </PanelWrapper>
      </PanelContainer>
      <TableSchedules
        schedules = { schedules }
        showCheckbox = { true }
        selectAll = { selectAll }
        selectAllHandler = { selectAllHandler }
        totalCount = { totalCount }
        currentPage = { scheduleCurrentPage }
        checkHandler = { todoCheckedHandler }
        pageChangedHandler = { setCurrentPageHandler }
      />
      { showLoadingPopup && <PopupLoading/> }
      {
        showImportPopup &&
          <PopupExecuteConfirm
            title = "匯入專案進度"
            text = { `確定匯入${ selectedItemNumber }個項目` }
            cancelHandler = { () => { setShowImportPopup(false) } }
            confirmHandler = { importProjectTodoHandler }
          />
      }
      {
        showSaveFailPopup &&
          <PopupExecuteResult
            title = { apiFailPopupTitle }
            text = { apiFailPopupMessage }
            confirmHandler = { () => {
              dispatch(CLOSE_SAVE_FAIL_POPUP());
            }}
          />
      }
      {
        showImportSuccessPopup &&
          <PopupExecuteResult
            title = "匯入成功"
            confirmHandler = { () => { setShowImportSuccessPopup(false) } }
          />
      }
    </MainContent>
  );
};

export default ImportSchedules;
