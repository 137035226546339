import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { TdItem, TableRow } from '@/component-style/Table';
import { ButtonGoDetail } from '@/component-style/Button';
import { getOptionDesc } from '@/utils/textUtils';

const TrWrapper = styled(TableRow)`
  border-bottom: solid 2px #f2f2f2;
  box-sizing: border-box;
  min-width: 760px;

  &:last-child {
    border-bottom: none;
  }
`;

const Td120 = styled(TdItem)`
  width: 120px;
`;
const Td160 = styled(TdItem)`
  width: 160px;
`;

const Item = props => {
  const { user } = props;
  const directorOptions = useSelector(state => state.srpOptions.directorOptions);
  const departmentOptions = useSelector(state => state.srpOptions.departmentOptions);
  const companyTypeOptions = useSelector(state => state.srpOptions.companyTypeOptions);

  const director = getOptionDesc(directorOptions, user.director);
  const department = getOptionDesc(departmentOptions, user.department);

  return (
    <TrWrapper>
      <Td160>{ user.account }</Td160>
      <Td160>{ user.name }</Td160>
      <Td120>{ getOptionDesc(companyTypeOptions, user.companyType) }</Td120>
      <Td120>{ department }</Td120>
      <Td160>{ director }</Td160>
      <Td120>{ user.enable ? '啟動' : '停用' }</Td120>
      <Td120>
        <ButtonGoDetail
          disabled = { props.disableDetailPage }
          onClick = { props.goDetailHandler }
        >...
        </ButtonGoDetail>
      </Td120>
    </TrWrapper>
  );
};

export default Item;
