import React from 'react';
import styled from 'styled-components';

import { TableHead, TableRow, ThItem } from '@/component-style/Table';

const THead = styled(TableHead)`
  min-width: 1080px;
`;


const Th120 = styled(ThItem)`
  width: 120px;
`;

const Header = props => {
  
  return (
    <THead>
      <TableRow>
        <Th120>支憑流水號</Th120>
        <Th120>廠商統編</Th120>
        <Th120>發票類型</Th120>
        <Th120>發票號碼</Th120>
        <Th120>發票日期</Th120>
        <Th120>未稅金額</Th120>
        <Th120>稅額</Th120>
        <Th120>發票總金額</Th120>
        <Th120>實際付款金額</Th120>
        <Th120>營業稅申報</Th120>
      </TableRow>
    </THead>
  );
};

export default Header;
