import React, { useCallback, useEffect, useState, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { postToGetUserInfo } from '@/api/authService';
import { postToGetProjectDetail } from '@/api/projectService';
import { postToGetWorkingHours } from '@/api/attendanceService';

import {
  FETCH_MEMBER_OPTIONS,
  FETCH_PROJECT_ID_OPTIONS,
  FETCH_WORKINGHOURS_TYPE_OPTIONS,
} from '@/store/actions/srpOptionsAction';

import MainContent from '@/component-style/MainContent';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PanelWorkingHoursSearch from '@/components/Panel/PanelWorkingHoursSearch';
import TableWorkingHours from '@/components/Table/Attendance/TableWorkingHours';

import { exportWorkingHoursFile } from '@/utils/xlsx';
import { searchConfigGenerator } from '@/utils/apiRequestUtils';
import { getOptionDesc } from '@/utils/textUtils';
import { diffTime, formatTime } from '@/utils/timeUtils';

const DivPanelContainer = styled.div`
  margin-bottom: 40px;
`;

const searchConfig = {
  startDate: null,
  endDate: null,
  userIDs: [],
  projectID: '',
  typeID: '',
  tag: '',
  // isOverTime: false,
  crossTeam: false,
  amount: 10,
};

const WorkingHoursList = () => {
  const dispatch = useDispatch();
  const userID = useSelector(state => state.login.token);
  const memberOptions = useSelector(state => state.srpOptions.memberOptions);
  const projectIDOptions = useSelector(state => state.srpOptions.projectIDOptions);
  const workingHoursTypeOptions = useSelector(state => state.srpOptions.workingHoursTypeOptions);
  const workingHoursTagDictionary = useSelector(state => state.srpOptions.workingHoursTagDictionary);

  const [workingRecords, setWorkingRecords] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [userFilter, setUserFilter] = useState('self');
  const [config, setConfig] = useState({
    ...searchConfig,
    userIDs: [userID],
  });

  const [showLoadingPopup, setShowLoadingPopup] = useState(false);

  const updateConfigHandler = (key, value) => {
    const updateObj = { ...config };
    updateObj[key] = value;
    setConfig({...updateObj});
  };

  const typeChangeHandler = value => {
    setConfig(prevState => ({
      ...prevState,
      typeID: value,
      tag: '',
    }));
  };

  const userFilterChangeHandler = value => {
    setUserFilter(value);
    setConfig(prevState => ({
      ...prevState,
      userIDs: value === 'self' ? [userID] : [],
    }));
  };

  const clearHandler = () => {
    setUserFilter('self');
    setConfig({
      ...searchConfig,
      userIDs: [userID],
    });
  };

  const setCurrentPageHandler = page => {
    setCurrentPage(page);
    fetchWorkingHours(page);
  };

  const fetchWorkingHours = async (page = currentPage) => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetWorkingHours(
        searchConfigGenerator({ ...config, page })
      );

      const { workingHours, totalCount } = data;
      const workingRecords = [];

      for (let i = 0; i < workingHours.length; i += 1 ) {
        const { projectID, userID } = workingHours[i];
        const projectInfo = await postToGetProjectDetail(projectID);
        const userInfo = await postToGetUserInfo(userID);
        const { projectCode, projectName } = projectInfo.data;
        const { name } = userInfo.data;
        workingRecords.push({
          ...workingHours[i],
          username: name,
          projectCode,
          projectName,
        })
      }

      setWorkingRecords([ ...workingRecords ]);
      setTotalCount(totalCount || 0);
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const searchHandler = () => {
    setCurrentPageHandler(1);
  };

  const exportHandler = async () => {
    setShowLoadingPopup(true);
    const workingHoursData = [];

    try {
      const { data } = await postToGetWorkingHours(
        searchConfigGenerator({
          ...config,
          amount: totalCount || 1,
          page: 1,
        })
      );

      const { workingHours } = data;

      for (let i = 0; i < workingHours.length; i += 1 ) {
        const {
          projectID, userID, date, startTime, endTime, typeID, tag,
        } = workingHours[i];
        const projectInfo = await postToGetProjectDetail(projectID);
        const userInfo = await postToGetUserInfo(userID);
        const { projectCode, projectName } = projectInfo.data;
        const { name } = userInfo.data;
        workingHoursData.push([
          name,
          date,
          projectCode,
          projectName,
          getOptionDesc(workingHoursTypeOptions, typeID),
          tag,
          '一般',
          `${formatTime(startTime, 'MM/DD HH:mm')} ~ ${formatTime(endTime, 'MM/DD HH:mm')}`,
          `${diffTime(startTime, endTime, 'minutes')}`,
        ])
      }

    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
    exportWorkingHoursFile(workingHoursData);
  };

  const initOptions = useCallback(() => {
    dispatch(FETCH_PROJECT_ID_OPTIONS());
    dispatch(FETCH_MEMBER_OPTIONS());
    dispatch(FETCH_WORKINGHOURS_TYPE_OPTIONS());
  }, [dispatch]);

  useEffect(() => {
    initOptions();
  }, [initOptions]);

  return (
    <MainContent>
      <DivPanelContainer>
        <PanelWorkingHoursSearch
          config = { config }
          userFilter = { userFilter }
          memberOptions = { memberOptions }
          projectIDOptions = { projectIDOptions }
          workingHoursTypeOptions = { workingHoursTypeOptions }
          workingHoursTagDictionary = { workingHoursTagDictionary }
          clearHandler = { clearHandler }
          searchHandler = { searchHandler }
          exportHandler = { exportHandler }
          updateConfigHandler = { updateConfigHandler }
          typeChangeHandler = { typeChangeHandler }
          userFilterChangeHandler = { userFilterChangeHandler }
        />
      </DivPanelContainer>
      <TableWorkingHours
        workingRecords = { workingRecords }
        totalCount = { totalCount }
        currentPage = { currentPage }
        pageChangedHandler = { value => {
          setCurrentPageHandler(value);
        }}
      />
      { showLoadingPopup && <PopupLoading/> }
    </MainContent>
  );
};

export default WorkingHoursList;
