export const projectConfig = {
  projectCode: '',
  projectName: '',
  companyType: '',
  internal: false,
  theme: '',
  receiveDate: null,
  actionTimeStart: null,
  actionTimeEnd: null,
  mediaDays: [],
  customer: '',
  customerWindows: [],
  director: '',
  projectManager: '',
  allmembers: false,
  members: [],
  supports: [],
  projectTypes: [],
  note: '',
  isPaid: false,
  isReceived: false,
};

export const projectTodoConfig = {
  projectID: '',
  todoTypeID: '',
  todoTag: '',
  displayTypeChanged: false,
  displayType: { default: '', english: '' },
  estimatedDate: null,
  finishedDate: null,
  department: '',
  userID: '',
  note: '',
};

export const mediaReportConfig = {
  projectID: '',
  origin: '',
  publishDate: null,
  mediaID: '',
  journalistID: '',
  title: '',
  link: '',
  benefit: '', //integer
};

export const activityIdeaConfig = {
  projectID: '',
  activityIdeaTypeID: '',
  tag: '',
  content: '',
  title: '',
  vendorID: '',
  phone: '',
  email: '',
  budget: '', //integer
  website: '',
  executed: false,
  cloudStorageUrl: '',
};

export const projectStatusConfig = {
  projectCode: '',
  projectName: '',
  companyType: '',
  actionTimeStart: null,
  actionTimeEnd: null,
  financeStatys: '', // success warning fail
  isQuoted: false,
  isVerified: false,
  isInvoiced: false,
  isPaid: false,
  predictDate: null, 
};

export const vendorEvaluationConfig = {
  vendorID: '',
  price: 0,
  attitude: 0,
  satisfaction: 0,
  compatibility: 0,
  timeManagement: 0,
};

export const memberEvaluationConfig = {
  userID: '',
  quality: 0,
  attitude: 0,
  teamwork: 0,
  communication: 0,
  timeManagement: 0,
};
