import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { cloneDeep } from 'lodash';

import {
  putNewProjectTodoType,
  deleteProjectTodoTypes,
  postToGetProjectTodoTypes,
  patchModifyProjectTodoType,
} from '@/api/projectTodoService';

import {
  FETCH_TODO_TYPE_OPTIONS,
  FETCH_PROJECT_TYPE_OPTIONS,
} from '@/store/actions/srpOptionsAction';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import MainContent from '@/component-style/MainContent';
import PanelWrapper from '@/components/UI/PanelWrapper';
import TableWorkingHoursTypes from '@/components/Table/SysConfig/TableWorkingHoursTypes';
import PopupTodoTypeDetail from '@/components/UI/Popup/PopupTodoTypeDetail';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import PopupExecuteConfirm from '@/components/UI/Popup/PopupExecuteConfirm';
import SelectNormal from '@/components/UI/Select/SelectNormal';
import SelectMultiple from '@/components/UI/Select/SelectMultiple';
import SearchButtonSet from '@/components/UI/SearchButtonSet';
import { PSubtitle } from '@/component-style/TextElement';
import { ButtonBasic } from '@/component-style/Button';

import {
  DivFlexRow,
  DivItemWrapper,
} from '@/component-style/RWDWrapper';

import { searchConfigGenerator } from '@/utils/apiRequestUtils';
import { todoTypeConfig } from '@/constant/dataConfig/sysConfigModule';

const DivPanelContainer = styled.div`
  margin-bottom: 40px;
`;

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding: 0;
  justify-content: space-between;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const ButtonAdd = styled(ButtonBasic)`
  margin-right: 10px;
  width: 120px;

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`;

const searchConfig = {
  todoTypeID: '',
  projectTypes: [],
};

const TodoTypeManagement = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const sysConfigRoutes = useSelector(state => state.login.sysConfigRoutes);
  const todoTypeOptions = useSelector(state => state.srpOptions.todoTypeOptions);
  const projectTypeOptions = useSelector(state => state.srpOptions.projectTypeOptions);
  const showSaveFailPopup = useSelector(state => state.apiFail.showSaveFailPopup);
  const apiFailPopupTitle = useSelector(state => state.apiFail.title);
  const apiFailPopupMessage = useSelector(state => state.apiFail.message);

  const [types, setTypes] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  
  const [config, setConfig] = useState(cloneDeep(searchConfig));
  const [selectedType, setSelectedType] = useState(cloneDeep(todoTypeConfig));

  const [isAdding, setIsAdding] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [showTypeDetailPopup, setShowTypeDetailPopup] = useState(false);
  const [showSaveSuccessPopup, setShowSaveSuccessPopup] = useState(false);
  const [showDeleteSuccessPopup, setShowDeleteSuccessPopup] = useState(false);

  const disableTagPage = sysConfigRoutes.indexOf('TodoTagManagement') === -1;
  const userAddable = sysConfigRoutes.indexOf('TodoTypeManagement-Add') !== -1;
  const userEditable = sysConfigRoutes.indexOf('TodoTypeManagement-Edit') !== -1;
  const userDeletable = sysConfigRoutes.indexOf('TodoTypeManagement-Delete') !== -1;

  const selectedItemNumber = types
    .filter(type => type.isChecked).length;
  
  const goTagsHandler = index => {
    const { todoTypeID } = types[index];
    history.push(`/SysConfig/TodoTagManagement/${todoTypeID}`);
  };

  const addTypeHandler = () => {
    setIsAdding(true);
    setSelectedType(cloneDeep(todoTypeConfig));
    setShowTypeDetailPopup(true);
  };

  const updateTypeDetailHandler = (key, value) => {
    const updateObj = { ...selectedType };
    updateObj[key] = value;
    setSelectedType({ ...updateObj });
  };

  const clearHandler = () => {
    setConfig(cloneDeep(todoTypeConfig));
    setTypes([]);
  };

  const checkedHandler = (index, value) => {
    const updateTypes = [...types];
    updateTypes[index].isChecked = value;
    setTypes(updateTypes);
  };

  const selectAllHandler = value => {
    setSelectAll(value);
    const updateTypes = types
      .map(type => ({ ...type, isChecked: value }));
    setTypes(updateTypes);
  };

  const showTypeDetailHandler = index => {
    const selectedType = types[index];
    setSelectedType(cloneDeep(selectedType));
    setShowTypeDetailPopup(true);
  };

  const pageChangedHandler = page => {
    selectAllHandler(false);
    setCurrentPageHandler(page);
  };

  const setCurrentPageHandler = page => {
    setCurrentPage(page);
    fetchTypes(page);
  };

  const searchHandler = () => {
    setCurrentPageHandler(1);
  };

  const apiFailConfirmHandler = () => {
    dispatch(CLOSE_SAVE_FAIL_POPUP());

    apiFailPopupTitle === '儲存失敗'
      && setShowTypeDetailPopup(true);
  };

  const closeSaveSuccessPopupHandler = () => {
    setIsAdding(false);
    setShowSaveSuccessPopup(false);
    searchHandler();
  };

  const closeDetailPopupHandler = () => {
    setIsAdding(false);
    setShowTypeDetailPopup(false);
  };

  const updateTypeHandler = async () => {
    setShowTypeDetailPopup(false);
    setShowLoadingPopup(true);

    try {
      isAdding
        ? await putNewProjectTodoType(selectedType)
        : await patchModifyProjectTodoType(selectedType)

      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
      dispatch(FETCH_TODO_TYPE_OPTIONS());
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '儲存失敗', err }));
      console.log(err);
    }
  };

  const deleteTypeHandler = async () => {
    setShowDeletePopup(false);
    setShowLoadingPopup(true);

    try {
      const selectedTypes = types
        .filter(type => type.isChecked)
        .map(type => type.todoTypeID);

      await deleteProjectTodoTypes(selectedTypes);
      setShowLoadingPopup(false);
      setShowDeleteSuccessPopup(true);
      dispatch(FETCH_TODO_TYPE_OPTIONS());
    } catch(err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '刪除失敗', err }));
      console.log(err);
    }
  };

  const fetchTypes = async (page = currentPage) => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetProjectTodoTypes(
        searchConfigGenerator({
          page,
          amount: 10,
          todoTypeID: config.todoTypeID,
          projectTypes: config.projectTypes,
        })
      );

      const { types, totalCount } = data;
      const todoTypes = types.map(type => ({
        ...type,
        isChecked: false,
        enable: type.enable || false,
      }));

      setTypes(todoTypes);
      setTotalCount(totalCount || 0);
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const initTypes = useCallback(fetchTypes, []);

  const initOptions = useCallback(() => {
    dispatch(FETCH_TODO_TYPE_OPTIONS());
    dispatch(FETCH_PROJECT_TYPE_OPTIONS());
  }, [dispatch]);

  useEffect(() => {
    initTypes();
    initOptions();
  }, [initOptions, initTypes]);

  return (
    <MainContent>
      <DivPanelContainer>
        <PanelWrapper title = "進度分類管理">
          <DivFlexRow>
            <DivItemWrapper>
              <PSubtitle>分類名稱</PSubtitle>
              <SelectNormal
                value = { config.todoTypeID }
                options = {[
                  { value: '', desc: '請選擇分類名稱' },
                  ...todoTypeOptions,
                ]}
                changeHandler = { value => {
                  setConfig(prevState => ({
                    ...prevState,
                    todoTypeID: value,
                  }))
                }}
              />
            </DivItemWrapper>
            <DivItemWrapper>
              <PSubtitle>專案型態</PSubtitle>
              <SelectMultiple
                value = { config.projectTypes }
                options = { projectTypeOptions }
                changeHandler = { value => {
                  setConfig(prevState => ({
                    ...prevState,
                    projectTypes: value,
                  }))
                }}
              />
            </DivItemWrapper>
          </DivFlexRow>
          <DivFlexRow>
            <DivButtonsWrapper>
              <ButtonAdd
                disabled = { !userAddable }
                onClick = { addTypeHandler }
              >新增</ButtonAdd>
              <SearchButtonSet
                clearHandler = { clearHandler }
                searchHandler = { searchHandler }
              />
            </DivButtonsWrapper>
          </DivFlexRow>
        </PanelWrapper>
      </DivPanelContainer>
      <TableWorkingHoursTypes
        disableDelete = { selectedItemNumber === 0 || !userDeletable }
        selectAll = { selectAll }
        types = { types }
        totalCount = { totalCount }
        currentPage = { currentPage }
        disableTagPage = { disableTagPage }
        checkHandler = { checkedHandler }
        goTagsHandler = { goTagsHandler }
        deleteHandler = { () =>  { setShowDeletePopup(true) }}
        selectAllHandler = { selectAllHandler }
        pageChangedHandler = { pageChangedHandler }
        showTypeDetailHandler = { showTypeDetailHandler }
      />
      { showLoadingPopup && <PopupLoading/> }
      {
        showTypeDetailPopup &&
          <PopupTodoTypeDetail
            title = "進度大分類"
            type = { selectedType }
            disableName = { !isAdding }
            disableSave = { isAdding ? false : !userEditable }
            projectTypeOptions = { projectTypeOptions }
            confirmHandler = { updateTypeHandler }
            updateTypeDetailHandler = { updateTypeDetailHandler }
            cancelHandler = { closeDetailPopupHandler }
          />
      }
      {
        showDeletePopup &&
          <PopupExecuteConfirm
            title = "刪除進度分類"
            text = { `確定刪除${ selectedItemNumber }個項目` }
            confirmHandler = { deleteTypeHandler }
            cancelHandler = { () => {
              setShowDeletePopup(false);
            }}
          />
      }
      {
        showDeleteSuccessPopup &&
          <PopupExecuteResult
            title = "刪除成功"
            confirmHandler = { () => {
              setShowDeleteSuccessPopup(false);
              searchHandler();
            }}
          />
      }
      {
        showSaveSuccessPopup &&
          <PopupExecuteResult
            title = "儲存成功"
            confirmHandler = { closeSaveSuccessPopupHandler }
          />
      }
      {
        showSaveFailPopup &&
          <PopupExecuteResult
            title = { apiFailPopupTitle }
            text = { apiFailPopupMessage }
            confirmHandler = { apiFailConfirmHandler }
          />
      }
    </MainContent>
  );
};

export default TodoTypeManagement;
