import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { postToGetProjectDetail } from '@/api/projectService';

import {
  postToGetExpenses,
  postToGetExpenseDetail,
  putModifyExpenseDetail,
} from '@/api/expenseService';

import { FETCH_MEMBER_OPTIONS } from '@/store/actions/srpOptionsAction';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import MainContent from '@/component-style/MainContent';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import PopupExecuteConfirm from '@/components/UI/Popup/PopupExecuteConfirm';
import TableCashManagements from '@/components/Table/Finance/TableCashManagements';
import PanelWrapper from '@/components/UI/PanelWrapper';
import SelectSearchable from '@/components/UI/Select/SelectSearchable';
import SearchButtonSet from '@/components/UI/SearchButtonSet';
import { PSubtitle } from '@/component-style/TextElement';
import SelectNormal from '@/components/UI/Select/SelectNormal';
import {
  DivFlexRow,
  DivFullWidthWrapper,
  DivLongMultiSelector,
  DivItemWrapper,
} from '@/component-style/RWDWrapper';

import { toUTCTime } from '@/utils/timeUtils';
import { integerConverter } from '@/utils/textUtils';
import { searchConfigGenerator } from '@/utils/apiRequestUtils';
import moment from 'moment';

const DivPanelContainer = styled.div`
  margin-bottom: 40px;
`;

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding: 0;
  justify-content: flex-end;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const CashExpenseManagement = () => {
  const dispatch = useDispatch();
  const financeRoutes = useSelector(state => state.login.financeRoutes);
  const memberOptions = useSelector(state => state.srpOptions.memberOptions);
  const showSaveFailPopup = useSelector(state => state.apiFail.showSaveFailPopup);
  const apiFailPopupTitle = useSelector(state => state.apiFail.title);
  const apiFailPopupMessage = useSelector(state => state.apiFail.message);
  const companyTypeOptions = useSelector(state => state.srpOptions.companyTypeOptions);

  const [expenses, setExpenses] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [user, setUser] = useState('');
  const [companyType, setCompanyType] = useState('');
  const [cancelExpenseID ,setCancelExpenseID] = useState('');

  const [showCancelPopup, setShowCancelPopup] = useState(false);
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [showSaveSuccessPopup, setShowSaveSuccessPopup] = useState(false);
  const [showCancelSuccessPopup, setShowCancelSuccessPopup] = useState(false);

  const userApprovable = financeRoutes.indexOf('CashExpenseManagement-Approve') !== -1;

  const selectedItemNumber = expenses
    .filter(expense => expense.isChecked).length;

  const totalAmount = expenses
    .filter(expense => expense.isChecked)
    .map(({ price }) => integerConverter(price))
    .reduce((acc, cur) => acc + cur, 0);

  const searchHandler = () => {
    setCurrentPageHandler(1);
  };

  const setCurrentPageHandler = page => {
    setCurrentPage(page);
    fetchExpenses(page);
  };

  const expenseCheckHandler = (index, value) => {
    const updateExpenses = [...expenses];
    updateExpenses[index].isChecked = value;
    // updateExpenses[index].updateTime = value === true
    //   ? toUTCTime(Date.now()) : null;
    updateExpenses[index].updateTime = value === true
      ? toUTCTime(moment().add(1,'days')) : null;
    setExpenses([...updateExpenses]);
  };

  const updatePredictTimeHandler = (index, value) => {
    console.log(index, value);
    const updateExpenses = [...expenses];
    updateExpenses[index].updateTime = value === null
      ? null : value;
    setExpenses([...updateExpenses]);
  };

  const clearHandler = () => { setUser('') };

  const closeSuccessPopupHandler = () => {
    setShowSaveSuccessPopup(false);
    fetchExpenses();
  };

  const closeCancelSuccessPopupHandler = () => {
    setShowCancelSuccessPopup(false);
    fetchExpenses();
  };

  const cancelHandler = id => {
    setCancelExpenseID(id);
    setShowCancelPopup(true);
  };

  const expenseCancelHandler = async () => {
    setShowCancelPopup(false);
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetExpenseDetail(cancelExpenseID);
      await putModifyExpenseDetail({ ...data, predictDate: null });
      setShowLoadingPopup(false);
      setShowCancelSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '取消失敗', err }));
      console.log(err);
    }
  };

  const expenseSubmitHandler = async () => {
    setShowLoadingPopup(true);

    try {
      const selectedItems = expenses
        .filter(expense => expense.isChecked);

      for (let i = 0; i < selectedItems.length; i += 1) {
        const { expenseID, updateTime } = selectedItems[i];
        const { data } = await postToGetExpenseDetail(expenseID);
        await putModifyExpenseDetail({
          ...data,
          predictDate: toUTCTime(updateTime),
        });
      }

      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '請款失敗', err }));
      console.log(err);
    }
  };

  const fetchExpenses = async (page = currentPage) => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetExpenses(
        searchConfigGenerator({
          page,
          amount: 10,
          isPaid: false,
          paymentMethod: ['advance'],
          user: user === '' ? '' : [user],
          approvalStatus: ['generalManagerApproved'],
          companyType: companyType,
        })
      );

      const { expenses, totalCount } = data;
      const expenseForms = [];

      for (let i = 0; i < expenses.length; i += 1) {
        const expense = expenses[i];
        const { projectID, expenseID } = expense;
        const expenseDetail = await postToGetExpenseDetail(expenseID);
        const projectDetail = await postToGetProjectDetail(projectID);
        const { predictDate } = expenseDetail.data;
        const { projectCode, projectName } = projectDetail.data;
        expenseForms.push({
          ...expense,
          projectCode,
          projectName,
          isChecked: false,
          updateTime: predictDate || null,
          // updateTime: predictDate || moment().add(1,'days'),
          predictDate: predictDate || null,
        });
      };

      expenseForms
        .sort((a, b) => new Date(a.date) - new Date(b.date));
      
      setExpenses([...expenseForms]);
      setTotalCount(totalCount || 0);
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const initOptions = useCallback(() => {
    dispatch(FETCH_MEMBER_OPTIONS());
  }, [dispatch]);

  useEffect(() => {
    initOptions();
  }, [initOptions])

  return (
    <MainContent>
      <DivPanelContainer>
      <PanelWrapper title = "內部代墊款支付">
        <DivFlexRow>
          <DivItemWrapper>
            <PSubtitle>所屬公司</PSubtitle>
            <SelectNormal
              value = { companyType }
              options = {[
                { value: '', desc: '請選擇公司' },
                ...companyTypeOptions,
              ]}
              changeHandler = { setCompanyType }
            />
          </DivItemWrapper>
        </DivFlexRow>
        <DivFlexRow>
          <DivFullWidthWrapper>
            <PSubtitle>使用者名稱</PSubtitle>
            <DivLongMultiSelector>
              <SelectSearchable
                value = { user }
                options = {[
                  { value: '', desc: '請選擇專案成員' },
                  ...memberOptions,
                ]}
                changeHandler = { setUser }
              />
            </DivLongMultiSelector>
          </DivFullWidthWrapper>
        </DivFlexRow>
        <DivFlexRow>
          <DivButtonsWrapper>
            <SearchButtonSet
              clearHandler = { clearHandler }
              searchHandler = { searchHandler }
            />
          </DivButtonsWrapper>
        </DivFlexRow>
        </PanelWrapper>
      </DivPanelContainer>
      <TableCashManagements
        summitable = { selectedItemNumber > 0 && userApprovable }
        totalAmount = { totalAmount }
        expenses = { expenses }
        totalCount = { totalCount }
        currentPage = { currentPage }
        checkHandler = { expenseCheckHandler }
        cancelHandler = { cancelHandler }
        submitHandler = { expenseSubmitHandler }
        pageChangedHandler = { setCurrentPageHandler }
        updatePredictTimeHandler = { updatePredictTimeHandler }
      />
      { showLoadingPopup && <PopupLoading/> }
      {
        showCancelPopup &&
          <PopupExecuteConfirm
            title = "取消付款"
            text = { `確定取消付款？` }
            confirmHandler = { expenseCancelHandler }
            cancelHandler = { () => {
              setShowCancelPopup(false);
            }}
          />
      }
      {
        showSaveSuccessPopup &&
          <PopupExecuteResult
            title = "請款成功"
            confirmHandler = { closeSuccessPopupHandler }
          />
      }
      {
        showCancelSuccessPopup &&
          <PopupExecuteResult
            title = "取消成功"
            confirmHandler = { closeCancelSuccessPopupHandler }
          />
      }
      {
        showSaveFailPopup &&
          <PopupExecuteResult
            title = { apiFailPopupTitle }
            text = { apiFailPopupMessage }
            confirmHandler = { () => {
              dispatch(CLOSE_SAVE_FAIL_POPUP());
            }}
          />
      }
    </MainContent>
  );
};

export default CashExpenseManagement;
