import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { cloneDeep } from 'lodash';

import {
  putNewUser,
  postToGetPasswordParams,
} from '@/api/authService';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import {
  FETCH_DIRECTOR_OPTIONS,
  FETCH_DEPARTMENT_OPTIONS,
  FETCH_SYSTEM_ROLE_OPTIONS,
  FETCH_COMPANY_TYPE_OPTIONS,
} from '@/store/actions/srpOptionsAction';

import MainContent from '@/component-style/MainContent';
import PanelUserDetail from '@/components/Panel/Authority/PanelUserDetail';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import PopupPasswordLimitation from '@/components/UI/Popup/PopupPasswordLimitation';
import { ButtonBasic, ButtonCancel } from '@/component-style/Button';
import { DivFlexRow } from '@/component-style/RWDWrapper';

import { searchConfigGenerator } from '@/utils/apiRequestUtils';
import { userConfig } from '@/constant/dataConfig/authorityModule';

import { integerConverter } from '@/utils/textUtils';

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding-right: 0;
  padding-left: 135px;
  padding-bottom: 30px;
  justify-content: space-between;

  @media (max-width: 425px) {
    justify-content: center;
    padding-left: 0;
  }
`;

const ButtonSave = styled(ButtonBasic)`
  width: 120px;
  margin-right: 10px;
  margin-bottom: 10px;
`;

const ButtonClear = styled(ButtonCancel)`
  width: 120px;
  margin-bottom: 10px;
`;

const UserCreation = props => {
  const history = useHistory();
  const dispatch = useDispatch();
  const directorOptions = useSelector(state => state.srpOptions.directorOptions);
  const systemRoleOptions = useSelector(state => state.srpOptions.systemRoleOptions);
  const departmentOptions = useSelector(state => state.srpOptions.departmentOptions);
  const companyTypeOptions = useSelector(state => state.srpOptions.companyTypeOptions);
  const showSaveFailPopup = useSelector(state => state.apiFail.showSaveFailPopup);
  const apiFailPopupTitle = useSelector(state => state.apiFail.title);
  const apiFailPopupMessage = useSelector(state => state.apiFail.message);

  const [pwMinLength, setPWMinLength] = useState(8);
  const [user, setUser] = useState(cloneDeep(userConfig));

  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [showSaveSuccessPopup, setShowSaveSuccessPopup] = useState(false);
  const [showWeakPasswordPopup, setShowWeakPasswordPopup] = useState(false);

  const unsavable = user.account === ''
    || user.password === ''
    || user.email === ''
    || user.name === '';

  const updateUserHandler = (key, value) => {
    const updateObj = cloneDeep(user);
    if (key === "basicSalary") {
      updateObj[key] = integerConverter(value);
    } else {
      updateObj[key] = value;
    }
    setUser({...updateObj});
  };

  const clearHandler = () => {
    setUser(cloneDeep(userConfig));
  };

  const closeSuccessPopupHandler = () => {
    clearHandler();
    setShowSaveSuccessPopup(false);
  };

  const fetchPasswordConfig = async () => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetPasswordParams();
      const { minLength } = data;
      setPWMinLength(minLength || 0);
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const saveNewUserHandler = async () => {
    setShowLoadingPopup(true);

    if (user.password.length < pwMinLength) {
      return setShowWeakPasswordPopup(true);
    }

    try {
      await putNewUser(searchConfigGenerator(user));
      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '新增失敗', err }));
      console.log(err);
    }
  };

  const initOptions = useCallback(() => {
    dispatch(FETCH_DIRECTOR_OPTIONS());
    dispatch(FETCH_DEPARTMENT_OPTIONS({ enable: true }));
    dispatch(FETCH_SYSTEM_ROLE_OPTIONS({ enable: true }));
    dispatch(FETCH_COMPANY_TYPE_OPTIONS({ enable: true }));
  }, [dispatch]);

  useEffect(() => {
    initOptions();
    fetchPasswordConfig();
  }, [initOptions])

  return (
    <MainContent>
      <PanelUserDetail
        title = "新增使用者"
        user = { user }
        directorOptions = { directorOptions }
        departmentOptions = { departmentOptions }
        systemRoleOptions = { systemRoleOptions }
        companyTypeOptions = { companyTypeOptions }
        updateUserHandler = { updateUserHandler }
      >
        <DivFlexRow>
          <DivButtonsWrapper>
            <div>
              <ButtonSave
                disabled = { unsavable }
                onClick = { saveNewUserHandler }
              >儲存</ButtonSave>
              <ButtonClear
                onClick = { clearHandler }
              >清除欄位</ButtonClear>
            </div>
            <ButtonClear
              onClick = { () => { history.goBack() } }
            >離開</ButtonClear>
          </DivButtonsWrapper>
        </DivFlexRow>
      </PanelUserDetail>
      { showLoadingPopup && <PopupLoading/> }
      {
        showSaveSuccessPopup &&
          <PopupExecuteResult
            title = "新增成功"
            confirmHandler = { closeSuccessPopupHandler }
          />
      }
      {
        showWeakPasswordPopup &&
          <PopupPasswordLimitation
            minLength = { pwMinLength }
            confirmHandler = { () => { setShowWeakPasswordPopup(false) } }
          />
      }
      {
        showSaveFailPopup &&
          <PopupExecuteResult
            title = { apiFailPopupTitle }
            text = { apiFailPopupMessage }
            confirmHandler = { () => {
              dispatch(CLOSE_SAVE_FAIL_POPUP());
            }}
          />
      }
    </MainContent>
  );
};

export default UserCreation;
