import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import PanelWrapper from '@/components/UI/PanelWrapper';
import SelectNormal from '@/components/UI/Select/SelectNormal';
import SelectSearchable from '@/components/UI/Select/SelectSearchable';
import DatePeriodSelector from '@/components/UI/DatePeriodSelector';
import SearchButtonSet from '@/components/UI/SearchButtonSet';
import { InputBasic } from '@/component-style/Input';
import { ButtonBasic } from '@/component-style/Button';
import { PSubtitle } from '@/component-style/TextElement';
import {
  DivFlexRow,
  DivItemWrapper,
  DivLongMultiSelector,
} from '@/component-style/RWDWrapper';

import {
  vendorPayments,
  expenseApprovalStates,
} from '@/constant/options';
import { formatTime } from '@/utils/timeUtils';

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding: 0;
  justify-content: space-between;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const ButtonExport = styled(ButtonBasic)`
  width: 120px;

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`;

const DivBudgetWrapper = styled.div`
  width: 300px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 320px) { width: 260px }
  @media (min-width: 1920px) { width: 622px }
`;

const InputBudget = styled(InputBasic)`
  width: 120px;

  @media (max-width: 320px) { width: 100px }
  @media (min-width: 1920px) { width: 300px }
`;

const PSpacer = styled.p`
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  color: #767676;
`;

const PanelExpenseSearch = props => {
  const expenseRoutes = useSelector(state => state.login.expenseRoutes);
  const { config, userFilter, objectFilter } = props;

  const userExportable = expenseRoutes.indexOf('ExpenseForms-Export') !== -1;

  const applyDateStartChangeHandler = value => {
    props.updateConfigHandler('applyDate', {
      ...config.applyDate,
      start: formatTime(value, 'YYYY-MM-DD'),
    });
  };

  const applyDateEndChangeHandler = value => {
    props.updateConfigHandler('applyDate', {
      ...config.applyDate,
      end: formatTime(value, 'YYYY-MM-DD'),
    });
  };

  const predictDateStartChangeHandler = value => {
    props.updateConfigHandler('predictDate', {
      ...config.predictDate,
      start: formatTime(value, 'YYYY-MM-DD'),
    });
  };

  const predictDateEndChangeHandler = value => {
    props.updateConfigHandler('predictDate', {
      ...config.predictDate,
      end: formatTime(value, 'YYYY-MM-DD'),
    });
  };

  const paymentDateStartChangeHandler = value => {
    props.updateConfigHandler('paymentDate', {
      ...config.paymentDate,
      start: formatTime(value, 'YYYY-MM-DD'),
    });
  };

  const paymentDateEndChangeHandler = value => {
    props.updateConfigHandler('paymentDate', {
      ...config.paymentDate,
      end: formatTime(value, 'YYYY-MM-DD'),
    });
  };

  return (
    <PanelWrapper title = "支出憑單搜尋">
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>專案代號</PSubtitle>
          <SelectSearchable
            value = { config.projectID }
            options = {[
              { value: '', desc: '請選擇專案代號' },
              ...props.projectIDOptions,
            ]}
            changeHandler = { value => {
              props.updateConfigHandler('projectID', value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>付款方式</PSubtitle>
          <SelectSearchable
            value = { config.paymentMethod }
            options = {[
              { value: '', desc: '請選擇付款方式' },
              ...vendorPayments,
            ]}
            changeHandler = { value => {
              props.updateConfigHandler('paymentMethod', value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>審核狀態</PSubtitle>
          <SelectNormal
            value = { config.approvalStatus }
            options = {[
              { value: '', desc: '請選擇審核狀態' },
              ...expenseApprovalStates,
            ]}
            changeHandler = { value => {
              props.updateConfigHandler('approvalStatus', value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>支憑流水號</PSubtitle>
          <InputBasic
            value = { config.expenseCode }
            onChange = { ({ target }) => {
              props.updateConfigHandler('expenseCode', target.value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>付款對象</PSubtitle>
          <SelectNormal
            value = { objectFilter }
            options = {[
              { value: '', desc: '全部' },
              { value: 'vendor', desc: '廠商' },
              { value: 'user', desc: '同仁' },
            ]}
            changeHandler = { value => {
              props.objectFilterChangeHandler(value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>付款對象名稱</PSubtitle>
          <SelectSearchable
            disabled = { objectFilter === '' }
            value = { config.objectID }
            options = {[
              { value: '', desc: '請選擇對象名稱' },
              ...props.objectList,
            ]}
            changeHandler = { value => {
              props.updateConfigHandler('objectID', value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>申請對象</PSubtitle>
          <SelectNormal
            value = { userFilter }
            options = {[
              { value: '', desc: '全部' },
              { value: 'self', desc: '自己' },
              { value: 'other', desc: '同仁' },
            ]}
            changeHandler = { value => {
              props.userFilterChangeHandler(value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>申請對象名稱</PSubtitle>
          <SelectSearchable
            disabled = { userFilter !== 'other' }
            value = { config.user }
            options = {[
              { value: '', desc: '請選擇對象名稱' },
              ...props.memberList,
            ]}
            changeHandler = { value => {
              props.updateConfigHandler('user', value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>付款狀態</PSubtitle>
          <SelectNormal
            value = { config.isPaid }
            options = {[
              { value: '', desc: '請選擇付款狀態' },
              { value: true, desc: '已付款' },
              { value: false, desc: '未付款' },
            ]}
            changeHandler = { value => {
              props.updateConfigHandler('isPaid', value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>預算</PSubtitle>
          <DivBudgetWrapper>
            <InputBudget
              type = "number"
              value = { config.price.min }
              onChange = {({ target }) => {
                props.updateConfigHandler('price', {
                  ...config.price,
                  min: target.value,
                });
              }}
            />
            <PSpacer>~</PSpacer>
            <InputBudget
              type = "number"
              value = { config.price.max }
              onChange = {({ target }) => {
                props.updateConfigHandler('price', {
                  ...config.price,
                  max: target.value,
                });
              }}
            />
          </DivBudgetWrapper>
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>申請時間</PSubtitle>
          <DivLongMultiSelector>
            <DatePeriodSelector
              startTime = { config.applyDate.start }
              endTime = { config.applyDate.end }
              endTimeOKHandler = { applyDateEndChangeHandler }
              endTimeChangeHandler = { applyDateEndChangeHandler }
              startTimeOKHandler = { applyDateStartChangeHandler }
              startTimeChangeHandler = { applyDateStartChangeHandler }
            />
          </DivLongMultiSelector>
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>預計付款日</PSubtitle>
          <DivLongMultiSelector>
            <DatePeriodSelector
              startTime = { config.predictDate.start }
              endTime = { config.predictDate.end }
              endTimeOKHandler = { predictDateEndChangeHandler }
              endTimeChangeHandler = { predictDateEndChangeHandler }
              startTimeOKHandler = { predictDateStartChangeHandler }
              startTimeChangeHandler = { predictDateStartChangeHandler }
            />
          </DivLongMultiSelector>
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>實際付款日</PSubtitle>
          <DivLongMultiSelector>
            <DatePeriodSelector
              startTime = { config.paymentDate.start }
              endTime = { config.paymentDate.end }
              endTimeOKHandler = { paymentDateEndChangeHandler }
              endTimeChangeHandler = { paymentDateEndChangeHandler }
              startTimeOKHandler = { paymentDateStartChangeHandler }
              startTimeChangeHandler = { paymentDateStartChangeHandler }
            />
          </DivLongMultiSelector>
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivButtonsWrapper>
          <ButtonExport
            disabled = { !userExportable }
            onClick = { props.exportHandler }
          >匯出</ButtonExport>
          <SearchButtonSet
            clearHandler = { props.clearHandler }
            searchHandler = { props.searchHandler }
          />
        </DivButtonsWrapper>
      </DivFlexRow>
    </PanelWrapper>
  );
};

export default PanelExpenseSearch;
