import React, { useCallback, useEffect, useState, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { postToGetProjectDetail } from '@/api/projectService';

import {
  putNewWorkingHours,
  postToGetWorkingHours,
} from '@/api/attendanceService';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import {
  FETCH_PROJECT_ID_OPTIONS,
  FETCH_WORKINGHOURS_TYPE_OPTIONS,
} from '@/store/actions/srpOptionsAction';

import MainContent from '@/component-style/MainContent';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import PanelWorkingHoursDetail from '@/components/Panel/PanelWorkingHoursDetail';
import TableWorkingHours from '@/components/Table/Attendance/TableWorkingHours';
import { ButtonBasic, ButtonCancel } from '@/component-style/Button';
import { DivFlexRow } from '@/component-style/RWDWrapper';
import {
  diffTime,
  toUTCTime,
  formatTime,
} from '@/utils/timeUtils';

const DivPanelContainer = styled.div`
  margin-bottom: 40px;
`;

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding: 0;
  padding-left: 135px;
  padding-bottom: 30px;
  justify-content: flex-end;

  @media (max-width: 425px) {
    justify-content: center;
    padding-left: 0;
  }
`;

const ButtonSave = styled(ButtonBasic)`
  margin-right: 10px;
  width: 120px;

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`;

const ButtonClear = styled(ButtonCancel)`
  width: 120px;

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`;

const config = {
  submitDate: null,
  workingDate: formatTime(Date.now(), 'YYYY/MM/DD'),
  projectID: '',
  typeID: '',
  tag: '',
  startTimeLocal: null,
  endTimeLocal: null,
  crossTeam: false,
  isOverTime: false,
  note: '',
};

const WorkingHoursCreation = () => {
  const dispatch = useDispatch();
  const userID = useSelector(state => state.login.token);
  const username = useSelector(state => state.login.user);
  const projectIDOptions = useSelector(state => state.srpOptions.projectIDOptions);
  const workingHoursTypeOptions = useSelector(state => state.srpOptions.workingHoursTypeOptions);
  const workingHoursTagDictionary = useSelector(state => state.srpOptions.workingHoursTagDictionary);
  const showSaveFailPopup = useSelector(state => state.apiFail.showSaveFailPopup);
  const apiFailPopupTitle = useSelector(state => state.apiFail.title);
  const apiFailPopupMessage = useSelector(state => state.apiFail.message);

  const [workingRecords, setWorkingRecords] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [workingHours, setWorkingHours] = useState({ ...config });

  const [showAlertPopup, setShowAlertPopup] = useState(false);
  const [showAlertPopupOverlapped, setShowAlertPopupOverlapped] = useState(false);
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [showSaveSuccessPopup, setShowSaveSuccessPopup] = useState(false);

  const workingMinutes = diffTime(workingHours.startTimeLocal, workingHours.endTimeLocal, 'minutes') || 0;

  /*Comment by Ian
  const currentHours = workingRecords
    .map(({ startTime, endTime }) => ({ startTime, endTime }))
    .reduce((acc, { startTime, endTime }) =>
      acc + diffTime(startTime, endTime, 'minutes')
    , 0);
  */

  const updateWorkingHoursHandler = (key, value) => {
    const updateObj = { ...workingHours };
    updateObj[key] = value;
    setWorkingHours({...updateObj});
  };

  const typeChangeHandler = value => {
    setWorkingHours(prevState => ({
      ...prevState,
      typeID: value,
      tag: '',
    }));
  };

  const closeSuccessPopupHandler = () => {
    setShowSaveSuccessPopup(false);
    setWorkingHours(prevState => ({
      ...config,
      workingDate: prevState.workingDate,
    }));
    fetchWorkingHours();
  };

  const setCurrentPageHandler = page => {
    setCurrentPage(page);
    fetchWorkingHours(workingHours.workingDate, page);
  };

  const clearHandler = () => {
    setWorkingHours({
      ...config,
      workingDate: null,
    });
    setWorkingRecords([]);
  };

  //Added by Ian
  const isOverWorkingHour = mins => 
  {
    //console.log("isOvertimeAlert overtimeParams:" + JSON.stringify(overtimeParams));
    let thisDayWorkingMinutes = workingMinutes;
    for (const objWorkingRecord of workingRecords) {
      //console.log("isOvertimeAlert objOvertimeRecord.startTime" + objOvertimeRecord.startTime);
      thisDayWorkingMinutes += diffTime(objWorkingRecord.startTimeLocal, objWorkingRecord.endTimeLocal, 'minutes')
    }
    //console.log("isOvertimeAlert thisDayOvertimerWorkingHours:" + thisDayOvertimerWorkingHours);
    if (thisDayWorkingMinutes > 480) {
      return true;  
    } else {
      return false;
    }
  };

  const isWorkingHourOverlapped = () =>
  {
    let overlapped = false;
    let workingHourArray = [];
    workingHourArray.push({startTimeLocal:workingHours.startTimeLocal, endTimeLocal: workingHours.endTimeLocal});
    for (const objWorkingRecord of workingRecords) {
      workingHourArray.push({startTimeLocal:objWorkingRecord.startTimeLocal, endTimeLocal: objWorkingRecord.endTimeLocal});
    }
    workingHourArray.sort((a,b)=>new Date(a.startTimeLocal) - new Date(b.startTimeLocal));
    if (workingHourArray.length >= 2) {
      for (var i = 0; i < workingHourArray.length - 1; i++) {
        // if (new Date(workingHourArray[i+1].startTime) > new Date(workingHourArray[i].startTimeLocal) && new Date(workingHourArray[i+1].starstartTimeLocaltTime) <= new Date(workingHourArray[i].endTimeLocal)) {
        if (new Date(workingHourArray[i+1].startTimeLocal) < new Date(workingHourArray[i].endTimeLocal)) {
          // console.log("isWorkingHourOverlapped workingHourArray:" + new Date(workingHourArray[i+1].startTimeLocal));
          // console.log("isWorkingHourOverlapped workingHourArray:" + new Date(workingHourArray[i].startTimeLocal));
          // console.log("isWorkingHourOverlapped workingHourArray:" + workingHourArray[i+1].endTimeLocal);
          overlapped = true;
          break;
        }
      }
    }
    //console.log("isWorkingHourOverlapped workingHourArray:" + JSON.stringify(workingHourArray));
    //console.log("isWorkingHourOverlapped overlapped" + overlapped);
    return overlapped;
  }
  //Added by Ian

  const saveHandler = () => {
    /*workingMinutes + currentHours > 480
      ? setShowAlertPopup(true)
      : saveNewWorkingHours();*/
      isOverWorkingHour() 
        ? setShowAlertPopup(true)
        : isWorkingHourOverlapped()
          ? setShowAlertPopupOverlapped(true)
          : saveNewWorkingHours();
  };

  const workingDateChangeHanlder = value => {
    setWorkingHours(prevState => ({
      ...config,
      workingDate: value,
    }));
    setWorkingRecords([]);
    setCurrentPage(1);
    value !== null && fetchWorkingHours(value, 1);
  };

  const saveNewWorkingHours = async () => {
    setShowLoadingPopup(true);

    try {
      await putNewWorkingHours({
        ...workingHours,
        userID,
        workingDate: formatTime(workingHours.workingDate, 'YYYY-MM-DD'),
        startTimeLocal: formatTime(workingHours.startTimeLocal),
        endTimeLocal: formatTime(workingHours.endTimeLocal),
        submitDate: formatTime(Date.now(), 'YYYY-MM-DD'),
      });
      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '儲存失敗', err }));
      console.log(err);
    }
  };

  const fetchWorkingHours = async (date = workingHours.workingDate, page = currentPage) => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetWorkingHours({
        isOverTime: false,
        userIDs: [userID],
        startDate: formatTime(date, 'YYYY-MM-DD'),
        endDate: formatTime(date, 'YYYY-MM-DD'),
        amount: 10,
        page,
      });

      const { workingHours, totalCount } = data;
      const workingRecords = [];

      for (let i = 0; i < workingHours.length; i += 1 ) {
        const { projectID } = workingHours[i];
        const projectInfo = await postToGetProjectDetail(projectID);
        const { projectCode, projectName } = projectInfo.data;
        workingRecords.push({
          ...workingHours[i],
          username,
          projectCode,
          projectName,
        })
      }

      setWorkingRecords([ ...workingRecords ]);
      setTotalCount(totalCount || 0);
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const initWorkingHours = useCallback(
    fetchWorkingHours, []);

  const initOptions = useCallback(() => {
    dispatch(FETCH_PROJECT_ID_OPTIONS());
    dispatch(FETCH_WORKINGHOURS_TYPE_OPTIONS({ enable: true, enableTag: true }));
  }, [dispatch]);

  useEffect(() => {
    initOptions();
    initWorkingHours();
  }, [initOptions, initWorkingHours]);

  return (
    <MainContent>
      <DivPanelContainer>
        <PanelWorkingHoursDetail
          title = "工時填寫"
          workingHours = { workingHours }
          projectIDOptions = { projectIDOptions }
          workingHoursTypeOptions = { workingHoursTypeOptions }
          workingHoursTagDictionary = { workingHoursTagDictionary }
          typeChangeHandler = { typeChangeHandler }
          workingDateChangeHanlder = { workingDateChangeHanlder }
          updateWorkingHoursHandler = { updateWorkingHoursHandler }
        >
          <DivFlexRow>
            <DivButtonsWrapper>
              <ButtonSave
                onClick = { saveHandler }
                disabled = {  workingHours.startTimeLocal === null
                  || workingHours.endTimeLocal === null
                  || workingMinutes <= 0
                }
              >儲存</ButtonSave>
              <ButtonClear
                onClick = { clearHandler }
              >清除欄位</ButtonClear>
            </DivButtonsWrapper>
          </DivFlexRow>
        </PanelWorkingHoursDetail>
      </DivPanelContainer>
      <TableWorkingHours
        workingRecords = { workingRecords }
        totalCount = { totalCount }
        currentPage = { currentPage }
        pageChangedHandler = { value => {
          setCurrentPageHandler(value);
        }}
      />
      { showLoadingPopup && <PopupLoading/> }
      {
        showSaveSuccessPopup &&
          <PopupExecuteResult
            title = "儲存成功"
            confirmHandler = { closeSuccessPopupHandler }
          />
      }
      {
        showAlertPopup &&
          <PopupExecuteResult
            title = "新增失敗"
            text = "超過工作日期工時上限，無法新增工時"
            confirmHandler = { () => { setShowAlertPopup(false) } }
          />
      }
      {
        showAlertPopupOverlapped &&
          <PopupExecuteResult
            title = "新增失敗"
            text = "工時重覆，無法新增工時"
            confirmHandler = { () => { setShowAlertPopupOverlapped(false) } }
          />
      }
      {
        showSaveFailPopup &&
          <PopupExecuteResult
            title = { apiFailPopupTitle }
            text = { apiFailPopupMessage }
            confirmHandler = { () => {
              dispatch(CLOSE_SAVE_FAIL_POPUP());
            }}
          />
      }
    </MainContent>
  );
};

export default WorkingHoursCreation;
