import React from 'react';
import styled from 'styled-components';

import NavigationItem from '../NavigationItem';
import NavigationCategory from '../NavigationCategory';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandHoldingUsd } from '@fortawesome/free-solid-svg-icons';

const UlWrapper = styled.ul`
  margin: 0;
  padding: 0;
`;


const IconWrapper = styled.div`
  margin-left: 50px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const financeRoutes = [
  {
    title: '專案財務資料',
    key: 'ProjectFinanceInfo',
    url: '/Finance/ProjectFinanceInfo',
  },
  {
    title: '會計審核作業',
    key: 'FinanceApprovals',
    url: '/Finance/FinanceApprovals',
  },
  {
    title: '支出憑單管理',
    key: 'FinanceManagement',
    url: '/Finance/FinanceManagement',
  },
  {
    title: '內部代墊款支付',
    key: 'CashExpenseManagement',
    url: '/Finance/CashExpenseManagement',
  },
  {
    title: '代墊款支領報表',
    key: 'CashExpenseList',
    url: '/Finance/CashExpenseList',
  },
  {
    title: '發票查詢',
    key: 'InvoiceSearch',
    url: '/Finance/InvoiceSearch',
  },
  // {
  //   title: '營收搜尋',
  //   key: 'DirectorRevenue',
  //   url: '/Finance/DirectorRevenue',
  // },
  {
    title: '營收搜尋',
    key: 'RevenueSearch',
    url: '/Finance/RevenueSearch',
  },
  // {
  //   title: '採購議價',
  //   key: 'PurchaseBargain',
  //   url: '/Finance/PurchaseBargain',
  // },
  // {
  //   title: '採購解鎖',
  //   key: 'UnlockPurchase',
  //   url: '/Finance/UnlockPurchase',
  // },
];

const NavigationListFinance = props => {
  const { validRoutes } = props;

  const routeItems = financeRoutes
    .filter(({ key }) => validRoutes.indexOf(key) !== -1)
    .map(({ title, url }) => (
      <NavigationItem
        key = { url }
        url = { url }
        title = { title }
      />
    ));

  return (
    <UlWrapper>
      <NavigationCategory
        title = "財務資料管理"
        opened = { props.opened }
        clickHandler = { props.toogleList }
      >
        <IconWrapper>
          <FontAwesomeIcon
            size = "1x"
            icon = { faHandHoldingUsd }
          />
        </IconWrapper>
      </NavigationCategory>
      { props.opened && routeItems }
    </UlWrapper>
  );
};

export default NavigationListFinance;
