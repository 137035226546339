import React from 'react';
import styled from 'styled-components';

import { TableHead, TableRow, ThItem } from '../../../component-style/Table';

const THead = styled(TableHead)`
  min-width: 960px;
`;

const Th80 = styled(ThItem)`
  width: 80px;
`;

const Th120 = styled(ThItem)`
  width: 120px;
`;

const Th160 = styled(ThItem)`
  width: 160px;
`;

const Th360 = styled(ThItem)`
  width: 360px;
`;

const Header = props => {
  
  return (
    <THead>
      <TableRow>
        <Th160>場地</Th160>
        <Th120>廳室</Th120>
        <Th120>坪數</Th120>
        <Th120>容納人數</Th120>
        <Th360>地址</Th360>
        <Th80></Th80>
      </TableRow>
    </THead>
  );
};

export default Header;
