import React from 'react';
import styled from 'styled-components';

import SelectNormal from '@/components/UI/Select/SelectNormal';
import DateSelector from '@/components/UI/DateSelector';
import Checkbox from '@/components/UI/Checkbox';
import { InputBasic } from '@/component-style/Input';
import { PSubtitle } from '@/component-style/TextElement';
import MyInputNumber from '@/components/UI/MyInputNumber';

import { formatTime } from '@/utils/timeUtils';

import { integerConverter } from '@/utils/textUtils';

const DivFWrapper = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  border-bottom: solid 2px #e5e5e5;
`;

const DivCheckboxContainer = styled(DivFWrapper)`
  border-bottom: none;
`;

const InputWrapper = styled.div`
  margin-right: 20px;
  margin-bottom: 10px;

  @media (max-width: 375px) {
    margin-right: 0;
  }

  @media (min-width: 1920px) {
    .ant-select, .datePicker { width: 300px; }
  }

  @media (max-width: 320px) {
    .ant-select, .datePicker { width: 260px; }
  };
`;

const Input = styled(InputBasic)`
  @media (max-width: 320px) { width: 260px; }
  @media (min-width: 1920px) { width: 300px }
`;

const PLabel = styled(PSubtitle)`
  width: auto;
  height: 20px;
  line-height: 20px;
`;

const ItemFinanceApproval = props => {
  const { accounting } = props;

  const updateAccountingHandler = (key, value) => {
    const updateObj = { ...accounting };
    updateObj[key] = value;
    props.accountingChangeHandler(updateObj);
  };

  const updatePersonalServiceHandler = value => {
    // if (value === true) {
    //   props.accounting.notForBusinessTax = false
    //   updateBusinessTaxHandler(false);
    // }
    props.accountingChangeHandler({
      ...accounting,
      personalService: value,
      declareType: '',
      personalIncomeDeclareDate: null,
    });
  };

  const updateBusinessTaxHandler = value => {
    // if (value === true) {
    //   props.accounting.personalService = false
    //   updatePersonalServiceHandler(false);
    // }
    props.accountingChangeHandler({
      ...accounting,
      notForBusinessTax: value,
      declareMonth: null,
      accountingType: '',
    });
  };

  return (
    <React.Fragment>
      <PSubtitle>會計申報相關欄位</PSubtitle>
      <DivCheckboxContainer>
        <Checkbox
          title = "不申報營業稅"
          disabled = { props.disabled }
          checked = { accounting.notForBusinessTax }
          changeHandler = { value => {
            updateBusinessTaxHandler(value);
          }}
        />
        {/* <Checkbox
          title = "不匯出會計系統"
          disabled = { props.disabled }
          checked = { accounting.notForAccountingSys }
          changeHandler = { value => {
            updateAccountingHandler('notForAccountingSys', value);
          }}
        /> */}
        <Checkbox
          title = "申報個人所得"
          disabled = { props.disabled }
          checked = { accounting.personalService }
          changeHandler = { value => {
            updatePersonalServiceHandler(value);
          }}
        />
      </DivCheckboxContainer>
      <DivFWrapper>
        <InputWrapper>
          <PLabel>營業稅申報月份</PLabel>
          <DateSelector
            disabled = { props.disabled || accounting.notForBusinessTax || accounting.personalService }
            picker = "month"
            value = { accounting.declareMonth }
            changeHandler = { value => {
              updateAccountingHandler(
                'declareMonth',
                formatTime(value, 'YYYY-MM')
              );
            }}
          />
        </InputWrapper>
        {/* <InputWrapper>
          <PLabel>發票種類</PLabel>
          <SelectNormal
            disabled = { props.disabled || accounting.notForBusinessTax }
            value = { accounting.accountingType }
            options = {[
              { value: '', desc: '請選擇發票種類' }
            ]}
            changeHandler = { value => {
              updateAccountingHandler('accountingType', value);
            }}
          />
        </InputWrapper> */}
        <InputWrapper>
          <PLabel>未稅金額</PLabel>
          <MyInputNumber
            disabled = { props.disabled }
            value = { accounting.untaxedAmount }
            onChange = { value => {
              updateAccountingHandler('untaxedAmount', integerConverter(value));
            }}
          />
          {/* <Input
            type = "number"
            disabled = { props.disabled }
            value = { accounting.untaxedAmount }
            onChange = {({ target }) => {
              updateAccountingHandler('untaxedAmount', integerConverter(target.value))
            }}
          /> */}
        </InputWrapper>
        <InputWrapper>
          <PLabel>稅額</PLabel>
          <MyInputNumber
            disabled = { props.disabled }
            value = { accounting.tax }
            onChange = { value => {
              updateAccountingHandler('tax', integerConverter(value));
            }}
          />
          {/* <Input
            type = "number"
            disabled = { props.disabled }
            value = { accounting.tax }
            onChange = {({ target }) => {
              updateAccountingHandler('tax', integerConverter(target.value))
            }}
          /> */}
        </InputWrapper>
        <InputWrapper>
          <PLabel>發票總金額</PLabel>
          <MyInputNumber
            disabled = { props.disabled }
            value = { accounting.amount }
            onChange = { value => {
              updateAccountingHandler('amount', integerConverter(value));
            }}
          />
          {/* <Input
            type = "number"
            disabled = { props.disabled }
            value = { accounting.amount }
            onChange = {({ target }) => {
              updateAccountingHandler('amount', integerConverter(target.value))
            }}
          /> */}
        </InputWrapper>
        {
          accounting.personalService && 
            <>
              <InputWrapper>
                <PLabel>個人所得申報月份</PLabel>
                <DateSelector
                  picker = "month"
                  disabled = { props.disabled }
                  value = { accounting.personalIncomeDeclareDate }
                  changeHandler = { value => {
                    updateAccountingHandler(
                      'personalIncomeDeclareDate',
                      formatTime(value, 'YYYY-MM')
                    );
                  }}
                />
              </InputWrapper>
              <InputWrapper>
                <PLabel>申報個人所得種類</PLabel>
                <SelectNormal
                  disabled = { props.disabled }
                  value = { accounting.declareType }
                  options = {[
                    { value: '', desc: '請選擇種類' },
                    ...props.declareTypeOptions,
                  ]}
                  changeHandler = { value => {
                    updateAccountingHandler('declareType', value);
                  }}
                />
              </InputWrapper>
            </>
        }
      </DivFWrapper>
    </React.Fragment>
  );
};

export default ItemFinanceApproval;