import React from 'react';
import styled from 'styled-components';

import { DivPopupWrapper, DivPopup } from '@/component-style/Popup';
import { ButtonBasic } from '@/component-style/Button';

const DivButtonsWrapper = styled.div`
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const H3Title = styled.h3`
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: #8b8b8b;
  margin-bottom: 10px;
`;

const PTexts = styled.p`
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.43px;
  color: #8b8b8b;
`;

const ButtonConfirm = styled(ButtonBasic)`
  width: 120px;
`;

const PopupPasswordLimitation = props => {

  return (
    <DivPopupWrapper>
      <DivPopup>
        <H3Title>密碼強度過低</H3Title>
        <PTexts>1. 密碼長度至少為{ props.minLength }</PTexts>
        <PTexts>2. 須包含數字、大小寫字母及符號</PTexts>
        <PTexts>3. 避免重複、過於簡單或帳號相同的字母或數字</PTexts>
        <DivButtonsWrapper>
          <ButtonConfirm
            onClick = { props.confirmHandler }
          >確定
          </ButtonConfirm>
        </DivButtonsWrapper>
      </DivPopup>
    </DivPopupWrapper>
  );
};

export default PopupPasswordLimitation;
