import React from 'react';
import styled from 'styled-components';

import { TdItem, TableRow } from '@/component-style/Table';
// import { vendorInvoiceTypes } from '@/constant/options';
// import { getOptionDesc } from '@/utils/textUtils';
import { formatTime } from '@/utils/timeUtils';
import { decimalCommaConverter } from '@/utils/textUtils';

const TrWrapper = styled(TableRow)`
  border-bottom: solid 2px #f2f2f2;
  box-sizing: border-box;
  min-width: 1080px;

  &:last-child {
    border-bottom: none;
  }
`;

const Td160 = styled(TdItem)`
  width: 160px;
`;

const Td100 = styled(TdItem)`
  width: 100px;
`;

const Td120 = styled(TdItem)`
  width: 120px;
`;

const Item = props => {
  const { voucher } = props;

  // const type = getOptionDesc(vendorInvoiceTypes, voucher.voucherType);

  return (
    <TrWrapper>
      <Td100>{ voucher.companyType }</Td100>
      <Td100>{ voucher.director }</Td100>
      <Td100>{ voucher.invoiceQueryPeriod.replace('~', '\n~') }</Td100>
      <Td100>{ voucher.predProjectTotal == null ? "$ 0" : decimalCommaConverter(voucher.predProjectTotal) }</Td100>
      <Td100>{ voucher.customer }</Td100>
      <Td120>{ voucher.projectName }</Td120>
      <Td120>{ voucher.invoiceTotal == null ? "$ 0" : decimalCommaConverter(voucher.invoiceTotal) }</Td120>
      <Td120>{ voucher.expenseTotal == null ? "$ 0" : decimalCommaConverter(voucher.expenseTotal) }</Td120>
      <Td120>{ voucher.prPriceTotal == null ? "$ 0" : decimalCommaConverter(voucher.prPriceTotal) }</Td120>
      <Td100>{ voucher.incomeTotal == null ? "$ 0" : decimalCommaConverter(voucher.incomeTotal) }</Td100>
      <Td100>{ voucher.grossProfit == null ? "$ 0" : decimalCommaConverter(voucher.grossProfit) }</Td100>
      <Td100>{ voucher.grossProfitRate == null ? "0%" : voucher.grossProfitRate + "%" }</Td100>
      <Td100>{ voucher.hrExpense == null ? "$ 0" : decimalCommaConverter(voucher.hrExpense) }</Td100>
      <Td100>{ voucher.maExpense == null ? "$ 0" : decimalCommaConverter(voucher.maExpense) }</Td100>
      <Td100>{ voucher.netProfit == null ? "$ 0" : decimalCommaConverter(voucher.netProfit) }</Td100>
    </TrWrapper>
  )
};

export default Item;
