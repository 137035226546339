import React from 'react';
import styled from 'styled-components';

import DatePeriodSelector from '@/components/UI/DatePeriodSelector';
import SelectNormal from '@/components/UI/Select/SelectNormal';
import { ButtonCancel } from '@/component-style/Button';
import RemoveImg from '@/assets/images/icon_remove.svg';
import { formatTime } from '@/utils/timeUtils';

const DivWrapper = styled.div`
  width: 100%;
  
  @media (min-width: 768px) {
    width: calc(100% - 135px);
  }
`;

const DivFWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const DivSelectorWrapper = styled.div`
  margin-right: 20px;
  margin-bottom: 10px;
`;

const ButtonAdd = styled(ButtonCancel)`
  width: 150px;
`;

const DivSelectContainer = styled.div`
  margin-right: 20px;
  margin-bottom: 10px;
`;

const DivIconRemove = styled.div`
  cursor: pointer;
  width: 20px;
  height: 40px;
  background-image: url(${ props => props.url });
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  @media (max-width: 375px) {
    background-position: top;
  }
`;

const statusOptions = [
  { value: 'pending', desc: '詢問中' },
  { value: 'waiting', desc: '候補中' },
  { value: 'reserved', desc: '已預留' },
  { value: 'canceled', desc: '已取消' },
];

const VenueDateList = props => {

  const updateStartTime = (time, index) => {
    const updatedList = [ ...props.timeList ];
    updatedList[index].date = formatTime(time, 'YYYY-MM-DD');
    updatedList[index].startTime = time;

    props.updateReservationHandler([ ...updatedList ]);
  };

  const updateEndTime = (time, index) => {
    const updatedList = [ ...props.timeList ];
    updatedList[index].endTime = time;

    props.updateReservationHandler([ ...updatedList ]);
  };

  const updateStatus = (value, index) => {
    const updatedList = [ ...props.timeList ];
    updatedList[index].status = value;

    props.updateReservationHandler([ ...updatedList ]);
  };

  const removeHandler = index => {
    const filteredList = props.timeList
      .filter((item, i) => i !== index);

    props.updateReservationHandler([ ...filteredList ]);
  };

  const addDateHandler = () => {
    props.updateReservationHandler([
      ...props.timeList,
      {
        date: null,
        startTime: null,
        endTime: null,
        status: 'pending',
      },
    ]);
  };

  return (
    <DivWrapper>
      {
        props.timeList
          .map(({ startTime, endTime, status }, index) =>
            <DivFWrapper key = { index }>
              <DivSelectorWrapper>
                <DatePeriodSelector
                  disabled = { props.disabled }
                  startTime = { startTime }
                  endTime = { endTime }
                  showTime = { true }
                  endTimeOKHandler = { value => { updateEndTime(value, index) }}
                  endTimeChangeHandler = { value => { updateEndTime(value, index) }}
                  startTimeOKHandler = { value => { updateStartTime(value, index) }}
                  startTimeChangeHandler = { value => { updateStartTime(value, index) }}
                />
              </DivSelectorWrapper>
              <DivSelectContainer>
                <SelectNormal
                  disabled = { props.disabled }
                  value = { status }
                  options = { statusOptions }
                  changeHandler = { value => { updateStatus(value, index) }}
                />
              </DivSelectContainer>              
              <DivIconRemove
                url = { RemoveImg }
                onClick = { () => {
                  !props.disabled && removeHandler(index);
                }}
              />
            </DivFWrapper>
          )
      }
      <ButtonAdd
        disabled = { props.disabled }
        onClick = { addDateHandler }
      >新增日期
      </ButtonAdd>
    </DivWrapper>
  );
};

export default VenueDateList;
