import { toUTCTime } from '@/utils/timeUtils';
import { getUntaxedPrice } from '@/utils/mathUtils';
import { integerConverter } from '@/utils/textUtils';
import { expenseVoucherConfig } from '@/constant/dataConfig/expenseModule';

const voucherCodeDict = {
  backup: '後補',
  taxedVoucher: '收據',
  invoice: '票根',
  receipt: '收據',
  certificate: '證明單',
  handlingFee: '手續費',
  service: '勞務單',
  note: '折讓單',
  others: '其他',
};

const valid = n => (n % 10 === 0 || n % 5 === 0);

const cal = n => {
  let sum = 0;
  while (n !== 0) {
    sum += (n % 10);
    n = (n - n%10) / 10;
  }
  return sum;
};

export const isTaxIDValid = taxID => {
  const tmp = '12121241';
  let sum = 0;
  const re = /^\d{8}$/;

  if (!re.test(taxID)) return false;

  for (let i=0; i < 8; i += 1) {
    const s1 = parseInt(taxID.substr(i,1));
    const s2 = parseInt(tmp.substr(i,1));
    sum += cal(s1 * s2);
  }

  if (!valid(sum)) {
    if (taxID.substr(6,1) === "7") return(valid(sum+1));
  }
  
  return(valid(sum));
};

export const isVoucherContentValid = voucher => {

  const vocuherCodeValid = voucher.voucherType === 'taxable'
    ? voucher.voucherCode.match(/^[A-Z]{2}\d{8}/)
    : true;

  const taxIDValid = voucher.voucherType === 'taxable'
    ? isTaxIDValid(voucher.taxID)
    : true;

  console.log("isVoucherContentValid voucher.voucherType:" + voucher.voucherType + " voucher.date:" + voucher.date + " voucher.voucherCode:" + voucher.voucherCode + " voucher.type:" + voucher.type + " voucher.tag:" + voucher.tag);

  return voucher.voucherType !== ''
    && voucher.date !== null
    && voucher.voucherCode !== ''
    && voucher.type !== ''
    && voucher.tag !== ''
    && vocuherCodeValid
    && taxIDValid;
};

export const isVoucherAmountValid = voucher => {
  return integerConverter(voucher.amount) === integerConverter(voucher.tax) + integerConverter(voucher.untaxedAmount);
};

export const isAccountingValid = ({ accounting }) => accounting.notForBusinessTax === false
  || accounting.declareMonth !== null;

export const isAccountingValidTwo = ({ accounting }) => ((accounting.notForBusinessTax === true && accounting.declareMonth === null) || ((accounting.notForBusinessTax === false || accounting.notForBusinessTax === null) && accounting.declareMonth !== null));

export const isAccountingValidResult = ({ accounting }) => {
    // console.log("isAccountingValidRegardingPersonalService : " + accounting.notForBusinessTax);
    // console.log("isAccountingValidRegardingPersonalService : " + accounting.declareMonth);
    var result = 0;
    if (accounting.notForBusinessTax === true && accounting.declareMonth !== null) {
      result = 1;
    }
    if (accounting.personalService === true && accounting.personalIncomeDeclareDate === null) {
      result = 2;
    }
    if (accounting.notForBusinessTax === false && accounting.personalService === false && accounting.declareMonth === null) {
      result = 3;
    }
    return result;
}

export const getUserSubmitRecord = approval => ({
  time: toUTCTime(Date.now()),
  approval,
  status: '送出',
});

const getPMVerifyRecord = approval => ({
  approval,
  status: '專案負責人審核中',
});

const getDirectorVerifyRecord = director => ({
  approval: director,
  status: '總監審核中',
});

const getFinanceVerifyRecord = () => ({
  approval: '會計',
  status: '財會審核中',
});

const getGMVerifyRecord = () => ({
  approval: '總經理',
  status: '總經理審核中',
});

export const getNextApprovalRecord = state => {
  const reocordDict = {
    draft: getPMVerifyRecord,
    projectManagerVerify: getDirectorVerifyRecord,
    projectDirectorVerify: getFinanceVerifyRecord,
    financeVerify: getGMVerifyRecord,
  };

  return reocordDict[state];
};

export const getNextApprovalState = state => {
  const stateDict = {
    draft: 'projectManagerVerify',
    projectManagerVerify: 'projectDirectorVerify',
    projectDirectorVerify: 'financeVerify',
    financeVerify: 'generalManagerVerifying',
    generalManagerVerifying: 'generalManagerApproved',
  };

  return stateDict[state];
};

export const getPaymentMethod = methods => {
  if (methods === null) {
    return '';
  }
  const filteredMethod = methods
    .filter(method => method !==  'advance');

  return filteredMethod.length !== 0
    ? filteredMethod[0]
    : '';
};

export const normalVoucherConverter = (vendorConfig, quotations) => {
  const { taxID, invoiceType } = vendorConfig;
  const taxRate = invoiceType === 'taxable'
    || invoiceType === 'taxedVoucher' ? 5 : 0;

  console.log("expenseUtils.js normalVoucherConverter invoiceType:" + invoiceType + " taxRate:" + taxRate);
  const vouchers = quotations.map(quotation => {
    const { price, note } = quotation;
    console.log("expenseUtils.js normalVoucherConverter price:" + price + " taxRate:" + taxRate);
    const untaxedAmount = getUntaxedPrice(price, taxRate);
    const tax = price - untaxedAmount;

    return {
      ...expenseVoucherConfig,
      tax,
      note,
      taxID,
      untaxedAmount,
      exchangeRate: 0,
      amount: price,
      voucherType: invoiceType,
      voucherCode: voucherCodeDict[invoiceType],
      quotationID: quotation.quotationID,
    };
  });

  return vouchers;
};

const under183VoucherConverter = (vendorConfig, expenseParams, quotations) => {
  const { taxID, payTax, invoiceType } = vendorConfig;

  const {
    basicPay, under183TaxMultiple, underMultipleTaxRate, overMultipleTaxRate
  } = expenseParams;

  const vouchers = quotations.reduce((acc, quotation) => {
    const { price, note } = quotation;
    const taxRate =  price < basicPay * under183TaxMultiple
      ? underMultipleTaxRate : overMultipleTaxRate;

      console.log("expenseUtils.js under183VoucherConverter payTax:" + payTax + " price:" + price + " taxRate:" + taxRate);
      const amount = payTax === true
        ? price
        : Math.round(price * (1 - taxRate));
      console.log("expenseUtils.js under183VoucherConverter amount:" + amount);
  
      const taxAmount = payTax === true
        ? Math.round(price / (1 - taxRate)) - price
        : price - amount;
        // : price * taxRate;

    const amountVoucher = {
      ...expenseVoucherConfig,
      note,
      taxID,
      amount,
      tax: 0,
      exchangeRate: 0,
      untaxedAmount: amount,
      voucherType: invoiceType,
      voucherCode: voucherCodeDict[invoiceType],
      quotationID: quotation.quotationID,
    };

    const taxVoucher = {
      ...expenseVoucherConfig,
      taxID,
      tax: 0,
      exchangeRate: 0,
      amount: taxAmount,
      untaxedAmount: taxAmount,
      note: `${note}稅金`,
      voucherType: invoiceType,
      voucherCode: voucherCodeDict[invoiceType],
    };
    
    return [...acc, amountVoucher, taxVoucher];
  }, []);

  return vouchers;
};

const getPremiumVouchers = (vendorConfig, expenseParams, quotations) => {
  console.log("expenseUtils.js getPremiumVouchers")
  const { payTax } = vendorConfig;

  return payTax === true
    ? getTaxIncludedVouchers(vendorConfig, expenseParams, quotations)
    : getTaxedVouchers(vendorConfig, expenseParams, quotations);
};

// 不需扣補充保費
const getPremiumFreeVouchers = (vendorConfig, expenseParams, quotations) => {
  console.log("expenseUtils.js getPremiumFreeVouchers")
  const { payTax, identityType, taxID, invoiceType } = vendorConfig;
  const { taxableAmount } = expenseParams;

  const taxRateDict = {
    local: expenseParams.localTaxRate,
    over183: expenseParams.foreignerTaxRate,
    resident: expenseParams.residentTaxRate,
    work: expenseParams.workTaxRate,
  };

  const vouchers = quotations.reduce((acc, quotation) => {
    const { price, note } = quotation;
    const taxRate = price < taxableAmount
      ? 0 : taxRateDict[identityType];

    const amount = payTax === true
      ? price
      : Math.round(price * (1 - taxRate));

    const taxAmount = payTax === true
      ? Math.round(price / (1 - taxRate)) - price
      : Math.round(price * taxRate);

    acc.push({
      ...expenseVoucherConfig,
      note,
      taxID,
      tax: 0,
      amount,
      exchangeRate: 0,
      untaxedAmount: amount,
      voucherType: invoiceType,
      voucherCode: voucherCodeDict[invoiceType],
      quotationID: quotation.quotationID,
    });

    taxAmount > 0 && acc.push({
      ...expenseVoucherConfig,
      taxID,
      tax: 0,
      exchangeRate: 0,
      amount: taxAmount,
      untaxedAmount: taxAmount,
      note: `${note}稅金`,
      voucherType: invoiceType,
      voucherCode: voucherCodeDict[invoiceType],
    });

    return [...acc];
  }, []);

  return vouchers;
};

const getTaxedVouchers = (vendorConfig, expenseParams, quotations) => {
  console.log("expenseUtils.js getTaxedVouchers vendorConfig:" + JSON.stringify(vendorConfig))
  let { taxID, identityType, invoiceType } = vendorConfig;
  const { basicPay, taxableAmount, premiumRate } = expenseParams;
  console.log("expenseUtils.js getTaxedVouchers basicPay:" + basicPay  +  " taxableAmount:" + taxableAmount + " premiumRate:" + premiumRate);

  const taxRateDict = {
    local: expenseParams.localTaxRate,
    over183: expenseParams.foreignerTaxRate,
    resident: expenseParams.residentTaxRate,
    work: expenseParams.workTaxRate,
  };

  const vouchers = quotations.reduce((acc, quotation) => {
    console.log("expenseUtils.js getTaxedVouchers quotation:" + JSON.stringify(quotation))
    console.log("expenseUtils.js getTaxedVouchers expenseParams:" + JSON.stringify(expenseParams))
    console.log("expenseUtils.js getTaxedVouchers identityType:" + identityType)
    // identityType = "local";
    const { price, note } = quotation;
    const taxRate = price < taxableAmount
      ? 0 : taxRateDict[identityType];

    const taxAmount = price < taxableAmount
      ? 0 : Math.round(price * taxRate);

    const premiumAmount = price < basicPay
      ? 0 : Math.round(price * premiumRate);

    console.log("expenseUtils.js getTaxedVouchers price:" + price)
    console.log("expenseUtils.js getTaxedVouchers taxAmount:" + taxAmount)
    console.log("expenseUtils.js getTaxedVouchers premiumAmount:" + premiumAmount)

    const amount = price - (taxAmount + premiumAmount);
    console.log("expenseUtils.js getTaxedVouchers amount:" + amount)
    acc.push({
      ...expenseVoucherConfig,
      note,
      taxID,
      amount,
      tax: 0,
      exchangeRate: 0,
      untaxedAmount: amount,
      voucherType: invoiceType,
      voucherCode: voucherCodeDict[invoiceType],
      quotationID: quotation.quotationID,
    });

    taxAmount > 0 && acc.push({
      ...expenseVoucherConfig,
      taxID,
      tax: 0,
      exchangeRate: 0,
      amount: taxAmount,
      untaxedAmount: taxAmount,
      note: `${note}稅金`,
      voucherType: invoiceType,
      voucherCode: voucherCodeDict[invoiceType],
    });

    premiumAmount > 0 && acc.push({
      ...expenseVoucherConfig,
      taxID,
      tax: 0,
      exchangeRate: 0,
      amount: premiumAmount,
      untaxedAmount: premiumAmount,
      note: `${note}補充保費`,
      voucherType: invoiceType,
      voucherCode: voucherCodeDict[invoiceType],
    });

    return [...acc];
  }, []);

  return vouchers;
};

//稅外加
const getTaxIncludedVouchers = (vendorConfig, expenseParams, quotations) => {
  console.log("expenseUtils.js getTaxIncludedVouchers")
  const { taxID, identityType, invoiceType } = vendorConfig;
  const { basicPay, taxableAmount, premiumRate } = expenseParams;

  const taxRateDict = {
    local: expenseParams.localTaxRate,
    over183: expenseParams.foreignerTaxRate,
    resident: expenseParams.residentTaxRate,
    work: expenseParams.workTaxRate,
  };
  const taxRate = taxRateDict[identityType];

  const vouchers = quotations.reduce((acc, quotation) => {
    let taxAmount = 0;
    let premiumAmount = 0;
    const { price, note } = quotation;

    const isTaxed = price / (1 - taxRate) >= taxableAmount;
    const isPremiumed = price / (1 - (taxRate + premiumRate)) >= basicPay;

    if (isPremiumed === true) {
      taxAmount = Math.round((price / (1 - (taxRate + premiumRate))) * taxRate);
      premiumAmount = Math.round((price / (1 - (taxRate + premiumRate))) * premiumRate);
    } else if (isTaxed === true) {
      taxAmount = Math.round((price / (1 - taxRate)) * taxRate);
    }

    acc.push({
      ...expenseVoucherConfig,
      note,
      taxID,
      tax: 0,
      exchangeRate: 0,
      amount: price,
      untaxedAmount: price,
      voucherType: invoiceType,
      voucherCode: voucherCodeDict[invoiceType],
      quotationID: quotation.quotationID,
    });

    taxAmount > 0 && acc.push({
      ...expenseVoucherConfig,
      taxID,
      tax: 0,
      exchangeRate: 0,
      amount: taxAmount,
      untaxedAmount: taxAmount,
      note: `${note}稅金`,
      voucherType: invoiceType,
      voucherCode: voucherCodeDict[invoiceType],
    });

    premiumAmount > 0 && acc.push({
      ...expenseVoucherConfig,
      taxID,
      tax: 0,
      exchangeRate: 0,
      amount: premiumAmount,
      untaxedAmount: premiumAmount,
      note: `${note}補充保費`,
      voucherType: invoiceType,
      voucherCode: voucherCodeDict[invoiceType],
    });

    return [...acc];
  }, []);

  return vouchers;
};

const basicPersonalVoucherConverter = (vendorConfig, expenseParams, quotations) => {
  const { payPremium } = vendorConfig;

  return payPremium === true
    ? getPremiumFreeVouchers(vendorConfig, expenseParams, quotations)
    : getPremiumVouchers(vendorConfig, expenseParams, quotations);
};

export const personalVoucherConverter = (vendorConfig, expenseParams, quotations) => {
  const { taxID, identityType, payHandlingFee, handlingFee } = vendorConfig;

  const vouchers = identityType === 'under183'
    ? under183VoucherConverter(vendorConfig, expenseParams, quotations)
    : basicPersonalVoucherConverter(vendorConfig, expenseParams, quotations);
  
  payHandlingFee === true && handlingFee !== undefined
    && vouchers.push({
      ...expenseVoucherConfig,
      taxID,
      tax: 0,
      note: '轉帳手續費',
      amount: 0,
      untaxedAmount: 0,
      // amount: handlingFee,
      // untaxedAmount: handlingFee,
      voucherType: 'handlingFee',
      exchangeRate: 0,
      voucherCode: '手續費',
    });

  return vouchers;
};

export const addTTVoucher = (vendorConfig, expenseParams, quotations) => {
  console.log("addTTVoucher start");
  const { taxID, payHandlingFee, handlingFee } = vendorConfig;

  // const vouchers = basicPersonalVoucherConverter(vendorConfig, expenseParams, quotations);
  const vouchers = normalVoucherConverter(vendorConfig, quotations);
  
  payHandlingFee === true && handlingFee !== undefined
    && vouchers.push({
      ...expenseVoucherConfig,
      taxID,
      tax: 0,
      note: '轉帳手續費',
      amount: 0,
      untaxedAmount: 0,
      // amount: handlingFee,
      // untaxedAmount: handlingFee,
      voucherType: 'handlingFee',
      exchangeRate: 0,
      voucherCode: '手續費',
    });

  return vouchers;
};