import React from 'react';
import styled from 'styled-components';

import { TableHead, TableRow, ThItem } from '../../../component-style/Table';

const THead = styled(TableHead)`
  min-width: 920px;
`;

const Th80 = styled(ThItem)`
  width: 80px;
`;

const Th120 = styled(ThItem)`
  width: 120px;
`;

const Header = props => {
  
  return (
    <THead>
      <TableRow>
        <Th120>同仁姓名</Th120>
        <Th120>工作態度</Th120>
        <Th120>時間管理</Th120>
        <Th120>專案品質</Th120>
        <Th120>團隊合作</Th120>
        <Th120>溝通技巧</Th120>
        <Th80>總分</Th80>
        <Th120></Th120>
      </TableRow>
    </THead>
  );
};

export default Header;
