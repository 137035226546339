import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import PanelWrapper from '@/components/UI/PanelWrapper';
import SelectNormal from '@/components/UI/Select/SelectNormal';
import SelectMultiple from '@/components/UI/Select/SelectMultiple';
import SelectSearchable from '@/components/UI/Select/SelectSearchable';
import SearchButtonSet from '@/components/UI/SearchButtonSet';
import { InputBasic } from '@/component-style/Input';

import { ButtonBasic } from '@/component-style/Button';
import { PSubtitle } from '@/component-style/TextElement';
import {
  DivFlexRow,
  DivItemWrapper,
  DivFullWidthWrapper,
  DivLongMultiSelector,
} from '@/component-style/RWDWrapper';

import {
  countyOptions,
  taiwanAreaOptions,
} from '@/constant/options';

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding: 0;
  justify-content: space-between;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const ButtonAdd = styled(ButtonBasic)`
  margin-right: 10px;
  width: 120px;

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`;

const ButtonExport = styled(ButtonBasic)`
  width: 120px;

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`;

const PanelJournalistSearch = props => {
  const history = useHistory();
  const informationRoutes = useSelector(state => state.login.informationRoutes);
  const { config } = props;

  const userAddable = informationRoutes.indexOf('JournalistCreation') !== -1;
  const userExportable = informationRoutes.indexOf('JournalistManagement-Export') !== -1;

  return (
    <PanelWrapper title = "記者搜尋">
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>媒體名稱</PSubtitle>
          <SelectSearchable
            value = { config.mediaID }
            options = {[
              { value: '', desc: '請選擇媒體' },
              ...props.mediaIDOptions,
            ]}
            changeHandler = { value => {
              props.updateConfigHandler('mediaID', value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>記者名稱</PSubtitle>
          <InputBasic
            value = { config.name }
            onChange = {({ target }) => {
              props.updateConfigHandler('name', target.value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>區域</PSubtitle>
          <SelectNormal
            value = { config.area }
            options = {[
              { value: '', desc: '請選擇區域' },
              ...taiwanAreaOptions,
            ]}
            changeHandler = { value => {
              props.updateConfigHandler('area', value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>縣市</PSubtitle>
          <SelectNormal
             value = { config.city }
             options = {[
               { value: '', desc: '請選擇縣市' },
               ...countyOptions,
             ]}
             changeHandler = { value => {
               props.updateConfigHandler('city', value);;
             }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>在職</PSubtitle>
          <SelectNormal
            value = { config.online }
            options = {[
              { value: '', desc: '請選擇' },
              { value: true, desc: '是' },
              { value: false, desc: '否' },
            ]}
            changeHandler = { value => {
              props.updateConfigHandler('online', value);;
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>參與LineBot</PSubtitle>
          <SelectNormal
            disabled = { true }
            value = { '' }
            options = {[
              { value: '', desc: '請選擇' },
            ]}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivFullWidthWrapper>
          <PSubtitle>採訪路線</PSubtitle>
          <DivLongMultiSelector>
            <SelectMultiple
              value = { config.type }
              options = {[ ...props.journalistTypes ]}
              changeHandler = { value => {
                props.updateConfigHandler('type', value);;
              }}
            />
          </DivLongMultiSelector>
        </DivFullWidthWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>電子郵件</PSubtitle>
          <InputBasic
            value = { config.email }
            onChange = {({ target }) => {
              props.updateConfigHandler('email', target.value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivButtonsWrapper>
          <div>
            <ButtonAdd
              disabled = { !userAddable }
              onClick = { () => {
                history.push('/Information/JournalistCreation');
              }}
            >新增</ButtonAdd>
            <ButtonExport
              disabled = { !userExportable }
              onClick = { props.exportHandler }
            >匯出</ButtonExport>
          </div>
          <SearchButtonSet
            clearHandler = { props.clearHandler }
            searchHandler = { props.searchHandler }
          />
        </DivButtonsWrapper>
      </DivFlexRow>
    </PanelWrapper>
  );
};

export default PanelJournalistSearch;
