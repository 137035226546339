import React from 'react';
import styled from 'styled-components';

import NavigationItem from '../NavigationItem';
import NavigationCategory from '../NavigationCategory';
import IconSysConfig from '@/assets/images/icon_settings.svg';

const UlWrapper = styled.ul`
  margin: 0;
  padding: 0;
`;

const DivIcon = styled.span`
  display: inline-block;
  margin-left: 50px;
  width: 24px;
  height: 24px;
  background-image: url(${props => props.url});
`;

const sysConfigRoutes = [
  {
    title: '會計科目管理',
    key: 'ExpenseTypeManagement',
    url: '/SysConfig/ExpenseTypeManagement',
  },
  {
    title: '會計參數管理',
    key: 'ExpenseParams',
    url: '/SysConfig/ExpenseParams',
  },
  {
    title: '廠商科目管理',
    key: 'VendorTypeManagement',
    url: '/SysConfig/VendorTypeManagement',
  },
  {
    title: '系統參數管理',
    key: 'SystemParams',
    url: '/SysConfig/SystemParams',
  },
  {
    title: '共用參數管理',
    key: 'CommonTypeManagement',
    url: '/SysConfig/CommonTypeManagement',
  },
  {
    title: '工時分類管理',
    key: 'WorkingHoursTypeManagement',
    url: '/SysConfig/WorkingHoursTypeManagement',
  },
  // {
  //   title: '進度分類管理',
  //   key: 'TodoTypeManagement',
  //   url: '/SysConfig/TodoTypeManagement',
  // },
  // {
  //   title: '活動創意分類管理',
  //   key: 'ActivityIdeaTypeManagement',
  //   url: '/SysConfig/ActivityIdeaTypeManagement',
  // },
  {
    title: '活動場地參數管理',
    key: 'VenueTypeManagement',
    url: '/SysConfig/VenueTypeManagement',
  },
  {
    title: '工時參數管理',
    key: 'WorkingHoursParams',
    url: '/SysConfig/WorkingHoursParams',
  },
  {
    title: '假日參數管理',
    key: 'HolidayManagement',
    url: '/SysConfig/HolidayManagement',
  },
  {
    title: '勞、健保參數管理',
    key: 'LaborHealthInsuranceManagement',
    url: '/SysConfig/LaborHealthInsuranceManagement',
  },
  {
    title: '假別管理',
    key: 'LeaveManagement',
    url: '/SysConfig/LeaveManagement',
  },
  // {
  //   title: '獎金設定',
  //   key: 'ExtraPaymentsManagement',
  //   url: '/SysConfig/ExtraPaymentsManagement',
  // },
];

const NavigationListSysConfig = props => {
  const { validRoutes } = props;

  const routeItems = sysConfigRoutes
    .filter(({ key }) => validRoutes.indexOf(key) !== -1)
    .map(({ key, title, url }) => (
      <NavigationItem
        key = { key }
        url = { url }
        title = { title }
      />
    ));

  return (
    <UlWrapper>
      <NavigationCategory
        title = "系統參數管理"
        opened = { props.opened }
        clickHandler = { props.toogleList }
      >
        <DivIcon url = { IconSysConfig }/>
      </NavigationCategory>
      { props.opened && routeItems }
    </UlWrapper>
  );
};

export default NavigationListSysConfig;
