import React, { useCallback, useEffect, useState, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

import {
  deleteWorkingHours,
  putModifyWorkingHours,
  postToGetWorkingHours,
  postToGetWorkingHoursDetail,
} from '@/api/attendanceService';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import {
  FETCH_PROJECT_ID_OPTIONS,
  FETCH_WORKINGHOURS_TYPE_OPTIONS,
} from '@/store/actions/srpOptionsAction';

import MainContent from '@/component-style/MainContent';
import PanelWorkingHoursDetail from '@/components/Panel/PanelWorkingHoursDetail';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import PopupExecuteConfirm from '@/components/UI/Popup/PopupExecuteConfirm';
import { ButtonBasic, ButtonCancel } from '@/component-style/Button';
import { DivFlexRow } from '@/component-style/RWDWrapper';
import {
  diffTime,
  toUTCTime,
  formatTime,
} from '@/utils/timeUtils';

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding-right: 0;
  padding-left: 135px;
  padding-bottom: 30px;
  justify-content: flex-end;

  @media (max-width: 425px) {
    justify-content: center;
    padding-left: 0;
  }
`;

const ButtonSave = styled(ButtonBasic)`
  margin-right: 10px;
  width: 120px;

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`;

const ButtonClear = styled(ButtonCancel)`
  width: 120px;

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`;

const config = {
  submitDate: null,
  workingDate: null,
  projectID: '',
  typeID: '',
  tag: '',
  startTimeLocal: null,
  endTimeLocal: null,
  crossTeam: false,
  isOverTime: false,
  note: '',
};

const WorkingHoursDetail = () => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const projectIDOptions = useSelector(state => state.srpOptions.projectIDOptions);
  const workingHoursTypeOptions = useSelector(state => state.srpOptions.workingHoursTypeOptions);
  const workingHoursTagDictionary = useSelector(state => state.srpOptions.workingHoursTagDictionary);
  const showSaveFailPopup = useSelector(state => state.apiFail.showSaveFailPopup);
  const apiFailPopupTitle = useSelector(state => state.apiFail.title);
  const apiFailPopupMessage = useSelector(state => state.apiFail.message);

  const [workingHours, setWorkingHours] = useState({ ...config });

  const [isEditing, setIsEditing] = useState(false);
  const [showAlertPopup, setShowAlertPopup] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [showSaveSuccessPopup, setShowSaveSuccessPopup] = useState(false);
  const [showDeleteSuccessPopup, setShowDeleteSuccessPopup] = useState(false);

  const workingMinutes = diffTime(workingHours.startTimeLocal, workingHours.endTimeLocal, 'minutes') || 0;

  const updateWorkingHoursHandler = (key, value) => {
    const updateObj = { ...workingHours };
    updateObj[key] = value;
    setWorkingHours({...updateObj});
  };

  const typeChangeHandler = value => {
    setWorkingHours(prevState => ({
      ...prevState,
      typeID: value,
      tag: '',
    }));
  };

  const closeSuccessPopupHandler = () => {
    setIsEditing(false);
    setShowSaveSuccessPopup(false);
  };

  const saveHandler = async () => {
    setShowLoadingPopup(true);
    const { userID, workingDate } = workingHours;

    try {
      const { data } = await postToGetWorkingHours({
        userIDs: [userID],
        startDate:workingDate,
        endDate: workingDate,
        amount: 100,
        page: 1,
      });

      const currentHours = data.workingHours
        .filter(({ workingHoursID }) => workingHoursID !== workingHours.workingHoursID)
        .map(({ startTimeLocal, endTimeLocal }) => ({ startTimeLocal, endTimeLocal }))
        .reduce((acc, { startTimeLocal, endTimeLocal }) =>
          acc + diffTime(startTimeLocal, endTimeLocal, 'minutes')
        , 0);

      console.log("workingHoursDetail workingMinutes:" + workingMinutes + " currentHours:" + currentHours);
      workingMinutes > 480
        ? setShowAlertPopup(true)
        : updateWorkingHoursData();

    } catch (err) { console.log(err) };

    setShowLoadingPopup(false);
  };

  const updateWorkingHoursData = async () => {
    setShowLoadingPopup(true);

    try {
      delete workingHours.endTime;
      delete workingHours.startTime;
      await putModifyWorkingHours({
        ...workingHours,
        startTimeLocal: formatTime(workingHours.startTimeLocal),
        endTimeLocal: formatTime(workingHours.endTimeLocal),
      });
      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '更新失敗', err }));
      console.log(err);
    }
  };

  const deleteWorkingHoursHandler = async () => {
    setShowDeletePopup(false);
    setShowLoadingPopup(true);

    try {
      await deleteWorkingHours([id]);
      setShowLoadingPopup(false);
      setShowDeleteSuccessPopup(true);
    } catch(err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '刪除失敗', err }));
      console.log(err);
    }
  };

  const fetchWorkingHoursDetail = async () => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetWorkingHoursDetail(id);
      const { approvementUserID, ...remainItems } = data;
      setWorkingHours({ ...config, ...remainItems });

    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const initWorkingHoursInfo = useCallback(
    fetchWorkingHoursDetail, []);

  const initOptions = useCallback(() => {
    dispatch(FETCH_PROJECT_ID_OPTIONS());
    dispatch(FETCH_WORKINGHOURS_TYPE_OPTIONS());
  }, [dispatch]);

  useEffect(() => {
    initOptions();
    initWorkingHoursInfo();
  }, [initOptions, initWorkingHoursInfo]);

  return (
    <MainContent>
      <PanelWorkingHoursDetail
        title = "工時明細"
        disabled = { !isEditing }
        disableDate = { true }
        workingHours = { workingHours }
        projectIDOptions = { projectIDOptions }
        workingHoursTypeOptions = { workingHoursTypeOptions }
        workingHoursTagDictionary = { workingHoursTagDictionary }
        typeChangeHandler = { typeChangeHandler }
        updateWorkingHoursHandler = { updateWorkingHoursHandler }
      >
        <DivFlexRow>
          <DivButtonsWrapper>
            {
              isEditing
                ? <ButtonSave
                    onClick = { saveHandler }
                    disabled = {
                      workingHours.startTimeLocal === null
                        || workingHours.endTimeLocal === null
                        || workingMinutes <= 0
                    }
                  >儲存</ButtonSave>
                : <ButtonSave
                    onClick = { () => { setIsEditing(true) }}
                  >編輯</ButtonSave>
            }
            <ButtonSave
              onClick = { () => {
                setShowDeletePopup(true);
              }}
            >刪除</ButtonSave>
            <ButtonClear
              onClick = { () => { history.goBack() }}
            >離開</ButtonClear>
          </DivButtonsWrapper>
        </DivFlexRow>
      </PanelWorkingHoursDetail>
      { showLoadingPopup && <PopupLoading/> }
      {
        showDeletePopup &&
          <PopupExecuteConfirm
            title = "刪除工時紀錄"
            text = "確定刪除此工時紀錄"
            confirmHandler = { deleteWorkingHoursHandler }
            cancelHandler = { () => {
              setShowDeletePopup(false);
            }}
          />
      }
      {
        showSaveSuccessPopup &&
          <PopupExecuteResult
            title = "更新成功"
            confirmHandler = { closeSuccessPopupHandler }
          />
      }
      {
        showDeleteSuccessPopup &&
          <PopupExecuteResult
            title = "刪除成功"
            confirmHandler = { () => {
              history.goBack();
            }}
          />
      }
      {
        showAlertPopup &&
          <PopupExecuteResult
            title = "修改失敗"
            text = "超過工作日期工時上限，無法修改工時"
            confirmHandler = { () => { setShowAlertPopup(false) } }
          />
      }
      {
        showSaveFailPopup &&
          <PopupExecuteResult
            title = { apiFailPopupTitle }
            text = { apiFailPopupMessage }
            confirmHandler = { () => {
              dispatch(CLOSE_SAVE_FAIL_POPUP());
            }}
          />
      }
    </MainContent>
  );
};

export default WorkingHoursDetail;
