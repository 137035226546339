import React, { useState } from 'react';
import { Tabs } from 'antd';

import MainContent from '@/component-style/MainContent';
import IncomeInvoiceSearch from './incomeInvoiceSearch';
import CostInvoiceSearch from './costInvoiceSearch';

const { TabPane } = Tabs;

const InvoiceSearch = () => {
  const [activeKey, setActiveKey] = useState('1');

  return(
    <MainContent>
      <Tabs
        type = "card"
        animated = { false }
        activeKey = { activeKey }
        onChange = { setActiveKey }
      >
        <TabPane tab="進項發票" key="1">
          {
            activeKey === '1' &&
              <IncomeInvoiceSearch/>
          }
        </TabPane>
        <TabPane tab="銷項發票" key="2">
          {
            activeKey === '2' &&
              <CostInvoiceSearch/>
          }
        </TabPane>
      </Tabs>
    </MainContent>
  );
};

export default InvoiceSearch;
