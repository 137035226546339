import styled from 'styled-components';

export const PSubtitle = styled.p`
  width: 135px;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  // color: #767676;
  color: #4d4d4d;
  
  span { color: #de2027 }
  
  @media (max-width: 767px) {
    height: 20px;
    line-height: 20px;
    margin-bottom: 5px;
  }
`;
