import React from 'react';
import styled from 'styled-components';

import { TableHead, TableRow, ThItem } from '@/component-style/Table';

const THead = styled(TableHead)`
  min-width: 1080px;
`;

const Th160 = styled(ThItem)`
  width: 160px;
`;

const Th100 = styled(ThItem)`
  width: 100px;
`;

const Th120 = styled(ThItem)`
  width: 120px;
`;

const Header = props => {
  
  return (
    <THead>
      <TableRow>
        <Th100>所屬公司</Th100>
        <Th100>主管名稱</Th100>
        <Th100>發票開立期間</Th100>
        <Th100>預估專案總收入</Th100>
        <Th100>客戶名稱</Th100>
        <Th120>專案名稱</Th120>
        <Th120>發票稅前金額</Th120>
        <Th120>支出金額</Th120>
        <Th120>服務費總額</Th120>
        <Th100>專案其他收入</Th100>
        <Th100>公司毛利</Th100>
        <Th100>毛利率</Th100>
        <Th100>人力成本</Th100>
        <Th100>管銷費用</Th100>
        <Th100>公司淨利(利潤)</Th100>
      </TableRow>
    </THead>
  );
};

export default Header;
