import React from 'react';
import styled from 'styled-components';

import NavigationItem from '../NavigationItem';
import IconLogout from '@/assets/images/icon_logout.svg';
import IconMyAccount from '@/assets/images/icon_my_account.svg';

const DivIcon = styled.span`
  display: inline-block;
  margin-left: 50px;
  width: 24px;
  height: 24px;
  background-image: url(${props => props.url});
`;

const NavigationListUser = props => {

  const { needToChangePassword } = props;

  return (
    needToChangePassword
      ? <NavigationItem
          url = "/LoginFirstTime"
          title = "更換密碼"
        >
          <DivIcon url = { IconMyAccount }/>
        </NavigationItem>
      : <>
          <NavigationItem
            url = "/AccountSetting"
            title = "密碼設定"
          >
            <DivIcon url = { IconMyAccount }/>
          </NavigationItem>
          <NavigationItem
            url = "/Logout"
            title = "登出"
          >
            <DivIcon url = { IconLogout }/>
          </NavigationItem>
        </>
  );
};

export default NavigationListUser;
