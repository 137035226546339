import React from 'react';

import TableWrapper from '@/components/UI/TableWrapper';
import { TableBody, Table } from '@/component-style/Table';
import Header from './Header';
import Item from './Item';

const TableProjectStatus = props => {
  const { project } = props;

  return (
    <TableWrapper title = { props.title }>
      <Table>
        <Header/>
        <TableBody>
          <Item
            project = { project }
          />
        </TableBody>
      </Table>
      { props.children }
    </TableWrapper>
  );
};

export default TableProjectStatus;
