import React from 'react';
import styled from 'styled-components';

import NavigationItem from '../NavigationItem';
import NavigationCategory from '../NavigationCategory';
import IconProjects from '@/assets/images/icon_projects.svg';

const UlWrapper = styled.ul`
  margin: 0;
  padding: 0;
`;

const DivIcon = styled.span`
  display: inline-block;
  margin-left: 50px;
  width: 24px;
  height: 24px;
  background-image: url(${props => props.url});
`;

const projectRoutes = [
  { 
    title: '專案清單',
    key: 'ProjectsList',
    url: '/Project/ProjectsList',
  },
  { 
    title: '新增專案',
    key: 'ProjectCreation',
    url: '/Project/ProjectCreation',
  },
  // { 
  //   title: '專案進度',
  //   key: 'ProjectSchedule',
  //   url: '/Project/ProjectSchedule',
  // },
  // { 
  //   title: '專案進度匯入',
  //   key: 'ImportSchedules',
  //   url: '/Project/ImportSchedules',
  // },
  { 
    title: '財務資料',
    key: 'ProjectFinance',
    url: '/Project/ProjectFinance',
  },
  // { 
  //   title: '專案解鎖',
  //   key: 'ProjectUnlock',
  //   url: '/Project/ProjectUnlock',
  // },
  // { 
  //   title: '取消專案結案',
  //   key: 'ProjectReopen',
  //   url: '/Project/ProjectReopen',
  // },
  // {
  //   title: '專案工時成本',
  //   key: 'ProjectTimeCost',
  //   url: '/Project/ProjectTimeCost',
  // },
  {
    title: '活動場地',
    key: 'VenueReservations',
    url: '/Project/VenueReservations',
  },
  // {
  //   title: '活動創意',
  //   key: 'ActivityIdeas',
  //   url: '/Project/ActivityIdeas',
  // },
  // {
  //   title: '媒體披露',
  //   key: 'MediaReports',
  //   url: '/Project/MediaReports',
  // },
  {
    title: '媒體邀約',
    key: 'ReporterInvitation',
    url: '/Project/ReporterInvitation',
  },
  // {
  //   title: '同仁評鑑',
  //   key: 'MemberEvaluation',
  //   url: '/Project/MemberEvaluation',
  // },
  // {
  //   title: '廠商評鑑',
  //   key: 'VendorEvaluation',
  //   url: '/Project/VendorEvaluation',
  // },
];

const NavigationListProjects = props => {
  const { validRoutes } = props;

  const routeItems = projectRoutes
    .filter(({ key }) => validRoutes.indexOf(key) !== -1)
    .map(({ key, title, url }) => (
      <NavigationItem
        key = { key }
        url = { url }
        title = { title }
      />
    ));

  return (
    <UlWrapper>
      <NavigationCategory
        title = "專案管理"
        opened = { props.opened }
        clickHandler = { () => { props.toogleList() }}
      >
        <DivIcon
          url = { IconProjects }
        />
      </NavigationCategory>
      { props.opened && routeItems }
    </UlWrapper>
  );
};

export default NavigationListProjects;
