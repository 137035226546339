import React from 'react';
import styled from 'styled-components';
import { cloneDeep } from 'lodash';

import TableWrapper from '@/components/UI/TableWrapper';
import PRServiceInvoice from '@/components/UI/PRServiceInvoice';
import { PSubtitle } from '@/component-style/TextElement';
import { InputBasic } from '@/component-style/Input';
import { ButtonBasic } from '@/component-style/Button';
import {
  DivFlexRow,
  DivItemWrapper,
} from '@/component-style/RWDWrapper';

import { integerConverter, currencyConverter } from '@/utils/textUtils';
import { projectPRServiceItem } from '@/constant/dataConfig/financeModule';

import MyInputNumber from '@/components/UI/MyInputNumber';

const DivWrapper = styled.div`
  width: 100%;
  
  @media (min-width: 768px) {
    width: calc(100% - 135px);
  }
`;

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding: 0;
  padding-bottom: 15px;
  justify-content: flex-end;

  @media (max-width: 425px) {
    justify-content: center;
  }
`;

const ButtonSave = styled(ButtonBasic)`
  width: 120px;

  &:first-child { margin-right: 10px }
`;

const PanelPRServiceInvoices = props => {
  const { projectPRServices } = props;

  const totalPrice = projectPRServices.prServices
    .map(({ prPrice }) => integerConverter(prPrice))
    .reduce((acc, cur) => acc + cur, 0);

  const addPRServicesHandler = () => {
    props.updateProjectPRServiceHandler('prServices', [
      ...projectPRServices.prServices,
      { ...projectPRServiceItem }
    ]);
  };

  const removeHandler = index => {
    const filteredList = projectPRServices.prServices
      .filter((item, i) => i !== index);

    props.updateProjectPRServiceHandler('prServices', filteredList);
  };

  const prServiceChangeHandler = (index, value) => {
    const updatePRServices = cloneDeep(projectPRServices.prServices);
    updatePRServices[index] = value;
    props.updateProjectPRServiceHandler('prServices', [...updatePRServices]);
  };

  return (
    <TableWrapper title = "服務費資訊">
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>總服務費</PSubtitle>
          <MyInputNumber
            disabled = { true }
            value = { totalPrice }
          />
          {/* <InputBasic
            disabled = { true }
            value = { currencyConverter(totalPrice) }
          /> */}
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>服務費</PSubtitle>
          <DivWrapper>
            {
              projectPRServices.prServices
                .map((prService, index) => 
                  <PRServiceInvoice
                    key = { index }
                    prService = { prService }
                    disabled = { !props.isEditing }
                    invoiceOptions = { props.invoiceOptions }
                    directorOptions = {[
                      // { value: '', desc: '請選擇總監' },
                      ...props.directorOptions,
                    ]}
                    removeHandler = { () => { removeHandler(index) }}
                    prServiceChangeHandler = { value => {
                      prServiceChangeHandler(index, value);
                    }}
                  />
              )
            }
            <ButtonSave
              disabled = { !props.isEditing }
              onClick = { addPRServicesHandler }
            >新增項目</ButtonSave>
          </DivWrapper>
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivButtonsWrapper>
          {
            props.isEditing
              ? <div>
                  <ButtonSave
                    onClick = { props.saveHandler }
                  >儲存</ButtonSave>
                  <ButtonSave
                    onClick = { props.cancelHandler }
                  >取消</ButtonSave>
                </div>
              : <ButtonSave
                  onClick = { props.editHandler }
                  disabled = { projectPRServices.projectID === '' || props.uneditable === true }
                >編輯</ButtonSave>
          }
        </DivButtonsWrapper>
      </DivFlexRow>
    </TableWrapper>
  )
};

export default PanelPRServiceInvoices;
