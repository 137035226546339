import React, { useCallback, useEffect, useState, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

import {
  putModifyQuotationDetail,
  postToGetQuotationDetail,
} from '@/api/expenseService';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import {
  FETCH_VENDOR_ID_OPTIONS,
  FETCH_PROJECT_ID_OPTIONS,
} from '@/store/actions/srpOptionsAction';

import MainContent from '@/component-style/MainContent';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import PanelExpenseQuotationDetail from '@/components/Panel/Expense/PanelExpenseQuotationDetail';
import { ButtonBasic, ButtonCancel } from '@/component-style/Button';
import { DivFlexRow } from '@/component-style/RWDWrapper';

import { integerConverter } from '@/utils/textUtils';
import { expenseQuotationConfig } from '@/constant/dataConfig/expenseModule';

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding-right: 0;
  padding-bottom: 30px;
  justify-content: flex-end;

  @media (max-width: 425px) {
    justify-content: center;
    padding-left: 0;
  }
`;

const ButtonSave = styled(ButtonBasic)`
  width: 120px;
  margin-right: 10px;
  margin-bottom: 10px;
`;

const ButtonClear = styled(ButtonCancel)`
  width: 120px;
  margin-bottom: 10px;
  margin-right: 10px;

  &:last-child { margin-right: 0 }
`;

const ExpenseQuotationDetail = props => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const vendorIDs = useSelector(state => state.srpOptions.vendorIDOptions);
  const expenseRoutes = useSelector(state => state.login.expenseRoutes);
  const projectIDOptions = useSelector(state => state.srpOptions.projectIDOptions);
  const showSaveFailPopup = useSelector(state => state.apiFail.showSaveFailPopup);
  const apiFailPopupTitle = useSelector(state => state.apiFail.title);
  const apiFailPopupMessage = useSelector(state => state.apiFail.message);
  
  const [quotation, setQuotation] = useState({...expenseQuotationConfig});
  
  const [isEditing, setIsEditing] = useState(false);
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [showSaveSuccessPopup, setShowSaveSuccessPopup] = useState(false);

  const userEditable = expenseRoutes.indexOf('ExpenseQuotationDetail-Edit') !== -1;

  const updateQuotationHandler = (key, value) => {
    const updateObj = { ...quotation };
    updateObj[key] = value;
    setQuotation({ ...updateObj });
  };

  const quotationTypeChangeHandler = value => {
    setQuotation(prevState => ({
      ...prevState,
      quotationType: value,
      hourlyRate: '',
    }));
  };

  const quotedPriceChangeHandler = value => {
    const price = integerConverter(value) >= 50000 ? '' : value;

    setQuotation(prevState => ({
      ...prevState,
      quotedPrice: value,
      price,
    }));
  };

  const closeSuccessPopupHandler = () => {
    setIsEditing(false);
    setShowSaveSuccessPopup(false);
  };

  const updateQuotationData = async () => {
    setShowLoadingPopup(true);

    try {
      await putModifyQuotationDetail({
        ...quotation,
        price: integerConverter(quotation.price),
        quotedPrice: integerConverter(quotation.quotedPrice),
        hourlyRate: integerConverter(quotation.hourlyRate),
      });
      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '儲存失敗', err }));
      console.log(err);
    }
  };

  const fetchQuotationDetail = async () => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetQuotationDetail(id);
      setQuotation({
        ...expenseQuotationConfig,
        ...data,
      });

    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const initQuotationData = useCallback(
    fetchQuotationDetail, []);

  const initOptions = useCallback(() => {
    dispatch(FETCH_VENDOR_ID_OPTIONS());
    dispatch(FETCH_PROJECT_ID_OPTIONS());
  }, [dispatch]);

  useEffect(() => {
    initOptions();
    initQuotationData();
  }, [initOptions, initQuotationData]);

  return (
    <MainContent>
      <PanelExpenseQuotationDetail
        title = "採購項目明細"
        disabled = { !isEditing }
        quotation = { quotation }
        vendorIDs = { vendorIDs }
        projectIDOptions = { projectIDOptions }
        disableProjectID = { true }
        updateQuotationHandler = { updateQuotationHandler }
        quotedPriceChangeHandler = { quotedPriceChangeHandler }
        quotationTypeChangeHandler = { quotationTypeChangeHandler }
      >
      {
        <DivFlexRow>
          <DivButtonsWrapper>
            {
              isEditing
                ? <ButtonSave
                    onClick = { updateQuotationData }
                  >儲存</ButtonSave>
                : <ButtonSave
                    disabled = { quotation.expenseCode !== undefined || !userEditable }
                    onClick = { () => { setIsEditing(true) } }
                  >編輯</ButtonSave>
            }
            <ButtonClear
              onClick = { () => { history.goBack() } }
            >離開</ButtonClear>
          </DivButtonsWrapper>
        </DivFlexRow>
      }
      </PanelExpenseQuotationDetail>
      { showLoadingPopup && <PopupLoading/> }
      {
        showSaveSuccessPopup &&
          <PopupExecuteResult
            title = "儲存成功"
            confirmHandler = { closeSuccessPopupHandler }
          />
      }
      {
        showSaveFailPopup &&
          <PopupExecuteResult
            title = { apiFailPopupTitle }
            text = { apiFailPopupMessage }
            confirmHandler = { () => {
              dispatch(CLOSE_SAVE_FAIL_POPUP());
            }}
          />
      }
    </MainContent>
  )
};

export default ExpenseQuotationDetail;
