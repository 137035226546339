import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Table, TableBody } from '@/component-style/Table';
import TableWrapper  from '@/components/UI/TableWrapper';
import PageSelector from '@/components/UI/PageSelector';
import Header from './Header';
import Item from './Item';

const TableVenueReservations = props => {
  const history = useHistory();
  const projectRoutes = useSelector(state => state.login.projectRoutes);
  const disableDetailPage = projectRoutes.indexOf('VenueReservationDetail') === -1;

  return (
    <TableWrapper title = "專案場地列表">
      <Table>
        <Header/>
        <TableBody>
          {
            props.reservations.map(reservation =>
              <Item
                key = { reservation.venueReservationID }
                reservation = { reservation }
                disableDetailPage = { disableDetailPage }
                goDetailHandler = { () => {
                  history.push(`/Project/VenueReservationDetail/${reservation.venueReservationID}`);
                }}
              />
            )
          }
        </TableBody>
      </Table>
      <PageSelector
        current = { props.currentPage }
        total = { props.totalCount }
        pageChangedHandler = { props.pageChangedHandler }
      />
    </TableWrapper>
  );
};

export default TableVenueReservations;
