import React from 'react';
import styled from 'styled-components';

import { TableHead, TableRow, ThItem } from '@/component-style/Table';

const THead = styled(TableHead)`
  min-width: 1080px;
`;

const Th100 = styled(ThItem)`
  width: 100px;
`;

const Th120 = styled(ThItem)`
  width: 120px;
`;

const Th160 = styled(ThItem)`
  width: 160px;
`;

const Th180 = styled(ThItem)`
  width: 180px;
`;

const Header = props => {
  
  return (
    <THead>
      <TableRow>
        <Th100>給予名稱</Th100>
        <Th120>給予日期</Th120>
        <Th120>可使用小時數</Th120>
        <Th120>已使用小時數</Th120>
        <Th120>到期日期</Th120>
      </TableRow>
    </THead>
  );
};

export default Header;
