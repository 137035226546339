import React from 'react';

import { Table, TableBody } from '@/component-style/Table';
import TableWrapper  from '@/components/UI/TableWrapper';
import Header from './Header';
import Item from './Item';

const TableVendorProjectRecords = props => {
  const { quotations } = props;

  return (
    <TableWrapper title = '廠商專案紀錄'>
      <Table>
        <Header/>
        <TableBody>
          {
            quotations.map((quotation, index) =>
              <Item
                key = { index }
                quotation = { quotation }
              />
            )
          }
        </TableBody>
      </Table>
    </TableWrapper>
  );
};

export default TableVendorProjectRecords;
