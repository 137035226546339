import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from "react-router-dom"

import { postToGetUserInfo } from '@/api/authService';
import { postToGetProjectDetail } from '@/api/projectService';

import {
  postToGetWorkingHours,
} from '@/api/attendanceService';

import { FETCH_WORKINGHOURS_TYPE_OPTIONS } from '@/store/actions/srpOptionsAction';

import MainContent from '@/component-style/MainContent';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import TableOvertimeByPersonnelDetail from '@/components/Table/Attendance/TableOvertimeByPersonnelDetail';
import { workingHoursParams } from '@/constant/dataConfig/sysConfigModule';
import {
  postToGetScheduleTimeConfig,
} from '@/api/attendanceService';

const searchConfig = {
  isOverTime: true,
  // approvementStatus: 'approved',
  // userIDs: [],
  amount: 100000
};

const OvertimeByPersonnelDetail = () => {
  const dispatch = useDispatch();
  const { searchedUserId, searchedOvertimeStartDate, searchedOvertimeEndDate } = useParams();
  

  const [overtimeRecords, setOvertimeRecords] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [showLoadingPopup, setShowLoadingPopup] = useState(false);

  const [workingHoursParamsConfig, setWorkingHoursParamsConfig] = useState({ ...workingHoursParams });
  
  const setCurrentPageHandler = page => {
    setCurrentPage(page);
    fetchApprovedOvertime(page);
  };

  const fetchApprovedOvertime = async (page = currentPage) => {
    console.log("fetchApprovedOvertime searchedUserId:" + searchedUserId + " searchedOvertimeStartDate: " + searchedOvertimeStartDate + " searchedOvertimeEndDate: " + searchedOvertimeEndDate);
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetWorkingHours({
        ...searchConfig,
        page,
        startDate: searchedOvertimeStartDate,
        endDate: searchedOvertimeEndDate,
        userIDs: [searchedUserId],
        approvementStatus: "approved",
        overtimeType: "extraPay",
      });

      const { workingHours, totalCount } = data;
      const overtimeRecords = [];

      for (let i = 0; i < workingHours.length; i += 1 ) {
        const { projectID, userID } = workingHours[i];
        const projectInfo = await postToGetProjectDetail(projectID);
        const userInfo = await postToGetUserInfo(userID);
        const { projectCode, projectName } = projectInfo.data;
        const { name, basicSalary } = userInfo.data;
        overtimeRecords.push({
          ...workingHours[i],
          username: name,
          projectCode,
          projectName,
          basicSalary,
        });
      }

      setOvertimeRecords([ ...overtimeRecords ]);
      setTotalCount(totalCount || 0);
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const initUnapprovedOvertimes = useCallback(
    fetchApprovedOvertime, []);

  const initOptions = useCallback(() => {
    dispatch(FETCH_WORKINGHOURS_TYPE_OPTIONS());
  }, [dispatch]);

  useEffect(() => {
    initOptions();
    initUnapprovedOvertimes();
  }, [initOptions, initUnapprovedOvertimes]);

  const fetchScheduleTimeConfig = async () => {
    // console.log("fetchScheduleTimeConfig");
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetScheduleTimeConfig();
      const {
        normalDayExtraRate,
        restDayExtraRate,
        holidayExtraRate,
        nationalHolidayExtraRate,
        overTimeTotalLimit,
        ...remainItems
      } = data;

      setWorkingHoursParamsConfig({
        ...workingHoursParams,
        ...remainItems,
        normalDayExtraRate: {
          ...workingHoursParams.normalDayExtraRate,
          ...normalDayExtraRate,
        },
        restDayExtraRate: {
          ...workingHoursParams.restDayExtraRate,
          ...restDayExtraRate,
        },
        holidayExtraRate: {
          ...workingHoursParams.holidayExtraRate,
          ...holidayExtraRate,
        },
        nationalHolidayExtraRate: {
          ...workingHoursParams.nationalHolidayExtraRate,
          ...nationalHolidayExtraRate,
        },
        overTimeTotalLimit: {
          ...workingHoursParams.overTimeTotalLimit,
          ...overTimeTotalLimit,
        },
      });

    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  useEffect(() => {
    fetchScheduleTimeConfig();
  }, []);

  return (
    <MainContent>
      <TableOvertimeByPersonnelDetail
        overtimeRecords = { overtimeRecords }
        totalCount = { totalCount }
        currentPage = { currentPage }
        pageChangedHandler = { value => {
          setCurrentPageHandler(value);
        }}
        workingHoursParamsConfig = { workingHoursParamsConfig }
      />
      { showLoadingPopup && <PopupLoading/> }
    </MainContent>
  )
};

export default OvertimeByPersonnelDetail;
