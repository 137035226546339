import React from 'react';
import styled from 'styled-components';

import Checkbox from '@/components/UI/Checkbox';
import { TdItem, TableRow } from '@/component-style/Table';
import { ButtonGoDetail } from '@/component-style/Button';

const TrWrapper = styled(TableRow)`
  border-bottom: solid 2px #f2f2f2;
  box-sizing: border-box;
  min-width: 760px;

  &:last-child {
    border-bottom: none;
  }
`;

const Td80 = styled(TdItem)`
  width: 80px;
`;

const Td120 = styled(TdItem)`
  width: 120px;
`;
const Td240 = styled(TdItem)`
  width: 240px;
`;

const Item = props => {
  const { customer } = props;

  return (
    <TrWrapper>
      <Td80>
        <Checkbox
          checked = { customer.isChecked }
          changeHandler = { props.checkHandler }
        />
      </Td80>
      <Td120>{ customer.customerCode }</Td120>
      <Td240>{ customer.customerName }</Td240>
      <Td120>{ customer.customerTaxID }</Td120>
      <Td120>{ customer.principal }</Td120>
      <Td80>
        <ButtonGoDetail
          disabled = { props.disableDetailPage }
          onClick = { props.goDetailHandler }
        >...
        </ButtonGoDetail>
      </Td80>
    </TrWrapper>
  );
};

export default Item;
