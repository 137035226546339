import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import PanelWrapper from '@/components/UI/PanelWrapper';
import SelectNormal from '@/components/UI/Select/SelectNormal';
import SelectSearchable from '@/components/UI/Select/SelectSearchable';
import DatePeriodSelector from '@/components/UI/DatePeriodSelector';
import SearchButtonSet from '@/components/UI/SearchButtonSet';
import { InputBasic } from '@/component-style/Input';

import { ButtonBasic } from '@/component-style/Button';
import { PSubtitle } from '@/component-style/TextElement';
import {
  DivFlexRow,
  DivItemWrapper,
  DivFullWidthWrapper,
  DivLongMultiSelector,
} from '@/component-style/RWDWrapper';

import { formatTime } from '@/utils/timeUtils';

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding: 0;
  justify-content: space-between;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const DivBudgetWrapper = styled.div`
  width: 300px;
  display: flex;
  justify-content: space-between;

  @media (min-width: 1920px) {
    width: 622px;
  }

  @media (max-width: 320px) {
    width: 260px;
  }
`;

const ButtonAdd = styled(ButtonBasic)`
  margin-right: 10px;
  width: 120px;

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`;

const ButtonExport = styled(ButtonBasic)`
  width: 120px;

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`;
const InputBudget = styled(InputBasic)`
  width: 120px;

  @media (min-width: 1920px) {
    width: 300px;
  }

  @media (max-width: 320px) {
    width: 100px;
  }
`;

const PSpacer = styled.p`
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  color: #767676;
`;

const PanelActivityIdeasSearch = props => {
  const history = useHistory();
  const projectRoutes = useSelector(state => state.login.projectRoutes);
  const { config } = props;

  const userAddable = projectRoutes.indexOf('MediaReportCreation') !== -1;
  const userExportable = projectRoutes.indexOf('MediaReports-Export') !== -1;

  const updateStartTimeHandler = value => {
    props.updateConfigHandler('publishDate', {
      ...config.publishDate,
      start: formatTime(value, 'YYYY-MM-DD'),
    });
  };

  const updateEndTimeHandler = value => {
    props.updateConfigHandler('publishDate', {
      ...config.publishDate,
      end: formatTime(value, 'YYYY-MM-DD'),
    });
  };

  return (
    <PanelWrapper title = "專案媒體披露">
      <DivFlexRow>
        <DivFullWidthWrapper>
          <PSubtitle>專案代號</PSubtitle>
          <DivLongMultiSelector>
            <SelectSearchable
              value = { config.projectID }
              options = {[
                { value: '', desc: '請選擇專案代號' },
                ...props.projectIDOptions
              ]}
              changeHandler = { value => {
                props.updateConfigHandler('projectID', value);
              }}
            />
          </DivLongMultiSelector>
        </DivFullWidthWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>媒體</PSubtitle>
          <SelectSearchable
            value = { config.mediaID }
            options = {[
              { value: '', desc: '請選擇媒體' },
              ...props.mediaIDOptions,
            ]}
            changeHandler = { value => {
              props.mediaChangeHandler(value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>記者</PSubtitle>
          <SelectNormal
            value = { config.journalistID }
            options = {[
              { value: '', desc: '請選擇記者' },
              ...props.journalistIDOptions,
            ]}
            changeHandler = { value => {
              props.updateConfigHandler('journalistID', value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>新聞來源</PSubtitle>
          <InputBasic
            value = { config.origin }
            onChange = { ({ target }) => {
              props.updateConfigHandler('origin', target.value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>主標</PSubtitle>
          <InputBasic
            value = { config.title }
            onChange = { ({ target }) => {
              props.updateConfigHandler('title', target.value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>報導日期</PSubtitle>
          <DatePeriodSelector
            endTime = { config.publishDate.end }
            startTime = { config.publishDate.start }
            endTimeOKHandler = { updateEndTimeHandler }
            endTimeChangeHandler = { updateEndTimeHandler }
            startTimeOKHandler = { updateStartTimeHandler }
            startTimeChangeHandler = { updateStartTimeHandler }
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>效益</PSubtitle>
          <DivBudgetWrapper>
            <InputBudget
              value = { config.benefit.min }
              onChange = { ({ target }) => {
                props.updateConfigHandler('benefit', {
                  ...config.benefit,
                  min: target.value,
                });
              }}
            />
              <PSpacer>~</PSpacer>
            <InputBudget
              value = { config.benefit.max }
              onChange = { ({ target }) => {
                props.updateConfigHandler('benefit', {
                  ...config.benefit,
                  max: target.value,
                });
              }}
            />
          </DivBudgetWrapper>
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivButtonsWrapper>
          <div>
            <ButtonAdd
              disabled = { !userAddable }
              onClick = { () => { history.push('/Project/MediaReportCreation') } }
            >新增</ButtonAdd>
            <ButtonExport
              disabled = { !userExportable }
              onClick = { props.exportHandler }
            >匯出</ButtonExport>
          </div>
          <SearchButtonSet
            clearHandler = { props.clearHandler }
            searchHandler = { props.searchHandler }
          />
        </DivButtonsWrapper>
      </DivFlexRow>
    </PanelWrapper>
  );
};

export default PanelActivityIdeasSearch;
