import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

import Checkbox from '@/components/UI/Checkbox';
import StatusCheck from '@/components/UI/StatusCheck';
import { TdItem, TableRow } from '@/component-style/Table';
import { ButtonGoDetail } from '@/component-style/Button';
import { getPhoneString, getOptionDesc } from '@/utils/textUtils';

const TrWrapper = styled(TableRow)`
  border-bottom: solid 2px #f2f2f2;
  box-sizing: border-box;
  min-width: 1120px;

  &:last-child {
    border-bottom: none;
  }
`;

const Td60 = styled(TdItem)`
  width: 60px;
`;

const Td80 = styled(TdItem)`
  width: 80px;
`;

const Td120 = styled(TdItem)`
  width: 120px;
`;

const Td160 = styled(TdItem)`
  width: 160px;
`;

const Td280 = styled(TdItem)`
  width: 280px;
`;

const SpanPhone = styled.span`
  cursor: pointer;
  color: #de2027;
`;

const ButtonEmail = styled(ButtonGoDetail)`
  background-color: rgba(0, 0, 0, 0);
  color: #21DFD8;

  &:hover {
    background-color: rgba(0, 0, 0, 0);
  }
`;

const Item = props => {
  const { journalist } = props;
  const mediaIDList = useSelector(state => state.srpOptions.mediaIDOptions);

  const mediaName = getOptionDesc(mediaIDList, journalist.mediaID);
  const businessPhone = getPhoneString(journalist.businessPhone);

  const makePhoneCall = number => {
    window.location.href = `tel:${number}`;
  };

  return (
    <TrWrapper>
      <Td80>
        <Checkbox
          checked = { journalist.isChecked }
          changeHandler = { props.checkHandler }
        />
      </Td80>
      <Td280>{ mediaName }</Td280>
      <Td120>{ journalist.name }</Td120>
      <Td160>{ journalist.jobTitle }</Td160>
      <Td160>
        <SpanPhone
          onClick = { () => { makePhoneCall(businessPhone) } }
        >{ businessPhone }
        </SpanPhone>
      </Td160>
      <Td120>
        <SpanPhone
          onClick = { () => { makePhoneCall(journalist.businessMobile) } }
        >{ journalist.businessMobile }
        </SpanPhone>
      </Td120>
      <Td80>
        <ButtonEmail
          onClick = { () => {
            window.location.href = `mailto:${journalist.email}`
          }}
        ><FontAwesomeIcon icon = { faEnvelope }/>
        </ButtonEmail>
      </Td80>
      <Td60>
        <StatusCheck
          checked = { journalist.online }
        />
      </Td60>
      <Td60>
        <ButtonGoDetail
          disabled = { props.disableDetailPage }
          onClick = { props.goDetailHandler }
        >...
        </ButtonGoDetail>
      </Td60>
    </TrWrapper>
  );
};

export default Item;
