import React, { useCallback, useEffect, useState, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { cloneDeep } from 'lodash';

import {
  putModifyExpenseDetail,
  postToGetExpenseDetail,
  postToSetExportedAccountingSystem,
} from '@/api/expenseService';

import {
  getDownloadFile,
  deleteUploadFile,
  postNewUploadFile,
  postToGetUploadFiles,
} from '@/api/storageService';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import {
  FETCH_MEMBER_OPTIONS,
  FETCH_VENDOR_ID_OPTIONS,
  FETCH_PROJECT_ID_OPTIONS,
  FETCH_EXPENSE_TYPE_OPTIONS,
  FETCH_DECLARE_TYPE_OPTIONS,
} from '@/store/actions/srpOptionsAction';

import MainContent from '@/component-style/MainContent';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PopupDraggable from '@/components/UI/Popup/PopupDraggable';
import PopupDraggablePdf from '@/components/UI/Popup/PopupDraggablePdf';
import PopupSelectFiles from '@/components/UI/Popup/PopupSelectFiles';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import PopupExecuteConfirm from '@/components/UI/Popup/PopupExecuteConfirm';
import PopupAttachmentList from '@/components/UI/Popup/PopupAttachmentList';
import PanelExpenseFormDetail from '@/components/Panel/Expense/PanelExpenseFormDetail';
import TableApprovalRecords from '@/components/Table/Expense/TableApprovalRecords';
import { ButtonBasic, ButtonCancel } from '@/component-style/Button';
import { DivFlexRow } from '@/component-style/RWDWrapper';

import { toUTCTime } from '@/utils/timeUtils';
import { integerConverter, floatConverter } from '@/utils/textUtils';

import {
  // isAccountingValid,
  isVoucherAmountValid,
  isVoucherContentValid,
  // isAccountingValidTwo,
  isAccountingValidResult,
} from '@/utils/expenseUtils';

import {
  expenseFormConfig,
  expenseVoucherConfig,
} from '@/constant/dataConfig/expenseModule';

import moment from 'moment';


const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding-right: 0;
  padding-bottom: 20px;
  justify-content: flex-end;

  @media (max-width: 425px) {
    justify-content: center;
    padding-left: 0;
  }
`;

const ButtonSave = styled(ButtonBasic)`
  margin-left: 10px;
  margin-top: 10px;
  width: 120px;

  @media (max-width: 425px) {
    &:nth-child(2n+1) { margin-left: 0 }
  }
`;

const ButtonClear = styled(ButtonCancel)`
  margin-left: 10px;
  margin-top: 10px;
  width: 120px;
`;

const DivTableWrapper = styled.div`
  padding-bottom: 30px;
`;

const FinanceManagementDetail = () => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const userID = useSelector(state => state.login.token);
  const vendorIDs = useSelector(state => state.srpOptions.vendorIDOptions);
  const financeRoutes = useSelector(state => state.login.financeRoutes);
  const memberOptions = useSelector(state => state.srpOptions.memberOptions);
  const projectIDOptions = useSelector(state => state.srpOptions.projectIDOptions);
  const declareTypeOptions = useSelector(state => state.srpOptions.declareTypeOptions);
  const showSaveFailPopup = useSelector(state => state.apiFail.showSaveFailPopup);
  const apiFailPopupTitle = useSelector(state => state.apiFail.title);
  const apiFailPopupMessage = useSelector(state => state.apiFail.message);

  const [fileList, setFileList] = useState([]);
  const [uploadFiles, setUploadFiles] = useState([]);
  const [deleteFileID, setDeleteFileID] = useState('');
  const [selectFilesList, setSelectFilesList] = useState([]);
  const [fileUploadConfig, setFileUploadConfig] = useState({ type: '', index: 0 });

  const [expenseForm, setExpenseForm] = useState(cloneDeep(expenseFormConfig));

  const [isEditing, setIsEditing] = useState(false);
  const [isExportedAccountingSystem, setIsExportedAccountingSystem] = useState(false);
  const [fileUrl, setFileUrl] = useState('');
  const [file, setFile] = useState(null);
  const [alertText, setAlertText] = useState('');
  const [showAlertPopup, setShowAlertPopup] = useState(false);
  const [showSelectFilesPopup, setShowSelectFilesPopup] = useState(false);
  const [showAttachmentsPopup, setShowAttachmentsPopup] = useState(false);
  const [showVouchersPopup, setShowVouchersPopup] = useState(false);
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [showSaveSuccessPopup, setShowSaveSuccessPopup] = useState(false);
  const [showUploadSuccessPopup, setShowUploadSuccessPopup] = useState(false);
  const [showDeleteFilePopup, setShowDeleteFilePopup] = useState(false);
  const [showDeleteFileSuccessPopup, setShowDeleteFileSuccessPopup] = useState(false);
  const [showDraggablePDFPopup, setShowDraggablePDFPopup] = useState(false);
  const [showDraggableImagePopup, setShowDraggableImagePopup] = useState(false);
  const [showDiscardPopup, setShowDiscardPopup] = useState(false);
  const [showDiscardSuccessPopup, setShowDiscardSuccessPopup] = useState(false);

  const [mapQuotationIDHasFile, setMapQuotationIDHasFile] = useState(new Map());

  const userEditable = financeRoutes.indexOf('FinanceManagementDetail-Edit') !== -1;

  const voucherAddable = expenseForm.object === 'vendor'
    && isEditing;

  const selectFilesPopupTitle = fileUploadConfig.type === 'quotationVoucher'
    ? '憑證管理' : '附件管理';

  const getSelectFilesList = () => {
    const newItemIndex = fileUploadConfig.index - expenseForm.vouchers
      .filter(({ quotationID }) => quotationID !== undefined).length;

    return selectFilesList[newItemIndex] === undefined
      || selectFilesList[newItemIndex][fileUploadConfig.type] === undefined
      ? [] : selectFilesList[newItemIndex][fileUploadConfig.type];
  };

  const amountTotal = expenseForm.vouchers
    .map(({ amount }) => integerConverter(amount))
    .reduce((acc, cur) => acc + cur, 0);

  const unTaxedAmountTotal = expenseForm.vouchers
    .map(({ untaxedAmount }) => integerConverter(untaxedAmount))
    .reduce((acc, cur) => acc + cur, 0);

  const taxTotal = expenseForm.vouchers
    .map(({ tax }) => integerConverter(tax))
    .reduce((acc, cur) => acc + cur, 0);

  const formSavable = expenseForm.projectID !== ''
    && expenseForm.payment.method !== '';

  const objectList = () => {
    if (expenseForm.object === 'user') return memberOptions;
    if (expenseForm.object === 'vendor') return vendorIDs;

    return [];
  };

  const updateExpenseFormHandler = (key, value) => {
    if (key === "predictDate") {
      const momentObjectPredictDate = moment(value);
      momentObjectPredictDate.set({hour:8,minute:0,second:0,millisecond:0});
      console.log("financeManagementDetails.js momentObjectPredictDate:" + momentObjectPredictDate.toDate());
      value = momentObjectPredictDate.toDate();
    }
    const updateObj = { ...expenseForm };
    updateObj[key] = value;
    setExpenseForm({ ...updateObj });
  };

  const closeSuccessPopupHandler = () => {
    setIsEditing(false);
    setShowSaveSuccessPopup(false);
    fetchExpenseFormDetail();
  };

  const apiFailConfirmHandler = () => {
    dispatch(CLOSE_SAVE_FAIL_POPUP());

    apiFailPopupTitle === '上傳失敗'
      && openUploadPopup(true);
  };

  const deleteFileSuccessHandler = () => {
    setShowDeleteFileSuccessPopup(false);
    openUploadPopup(true);
  };

  const uploadSuccessConfirmHandler = () => {
    setUploadFiles([]);
    setShowUploadSuccessPopup(false);
    openUploadPopup(true);
  };

  const openAlertPopup = text => {
    setAlertText(text);
    setShowAlertPopup(true);
  };

  const openDeleteFilePopupHandler = id => {
    setDeleteFileID(id);
    setShowDeleteFilePopup(true);
  };

  const openFilesPopupHandler = (type, index) => {
    setFileUploadConfig({ type, index });
    const { quotationID } = expenseForm.vouchers[index];

    quotationID === undefined
      ? setShowSelectFilesPopup(true)
      : openUploadFilesPopupHandler(type, quotationID);
  };

  const openUploadPopup = (isOpen, type = fileUploadConfig.type) => {
    type === 'quotationVoucher'
      ? setShowVouchersPopup(isOpen)
      : setShowAttachmentsPopup(isOpen);
  };

  const addFileListHandler = () => {
    setSelectFilesList(prevState => [...prevState, {
      quotationFiles: [],
      quotationVoucher: [],
    }]);
  };

  const removeFileListHandler = index => {
    const newItemIndex = index - expenseForm.vouchers
      .filter(({ quotationID }) => quotationID !== undefined).length;
    const filteredVouchers = selectFilesList
      .filter((item, i) => i !== newItemIndex);

    setSelectFilesList([...filteredVouchers]);
  };

  const updateUploadFilesHandler = file => {
    const { index, type } = fileUploadConfig;
    const newItemIndex = index - expenseForm.vouchers
      .filter(({ quotationID }) => quotationID !== undefined).length;

    const updateUploadLists = [...selectFilesList];
    updateUploadLists[newItemIndex][type] = file;

    const updateVouchers = cloneDeep(expenseForm.vouchers);

    if (type === 'quotationVoucher') {
      updateVouchers[index].isVoucherUpload = file.length > 0;
    }

    if (type === 'quotationFiles') {
      updateVouchers[index].isFileUpload = file.length > 0;
    }

    setSelectFilesList(updateUploadLists);
    setExpenseForm(prevState => ({
      ...prevState,
      vouchers: updateVouchers,
    }));
  };

  const openUploadFilesPopupHandler = async (type, quotationID) => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetUploadFiles(type, quotationID);
      setFileList([ ...data ]);

    } catch(err) { console.log(err) }

    setShowLoadingPopup(false);
    openUploadPopup(true, type);
  };

  const displayFileHandler = async fileInfo => {
    const { name, fileID, downloadURL } = fileInfo;

    if (name.match(/\.pdf$/)) {
      openUploadPopup(false);
      setShowLoadingPopup(true);
      const { data } = await getDownloadFile(fileID);
      
      setFile(data);
      setShowLoadingPopup(false);
      setShowDraggablePDFPopup(true);
    } else {
      setFileUrl(downloadURL);
      openUploadPopup(false);
      setShowDraggableImagePopup(true);
    }
  };

  const fileUploadHandler = async () => {
    const { type, index } = fileUploadConfig;
    const { quotationID } = expenseForm.vouchers[index];
    setShowLoadingPopup(true);

    type === 'quotationFiles'
      ? setShowAttachmentsPopup(false)
      : setShowVouchersPopup(false);

    try {
      for (let i = 0; i < uploadFiles.length; i += 1) {
        const file = uploadFiles[i];
        const formData = new FormData();
        formData.append('upfile', file);
        formData.append('objectType', type);
        formData.append('objectID', quotationID);
        formData.append('name', file.name);
        await postNewUploadFile(formData);
      }

      const { data } = await postToGetUploadFiles(type, quotationID);
      const vouchers = [...expenseForm.vouchers];
      
      if (type === 'quotationFiles') {
        vouchers[index].isFileUpload = data.length > 0;
      } else {
        vouchers[index].isVoucherUpload = data.length > 0;
      }

      setExpenseForm(prevState => ({ ...prevState, vouchers }));
      setFileList([ ...data ]);
      setShowLoadingPopup(false);
      setShowUploadSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '上傳失敗', err }));
      console.log(err);
    };
  };

  const deleteFileHandler = async () => {
    const { type, index } = fileUploadConfig;
    const { quotationID } = expenseForm.vouchers[index];
    setShowDeleteFilePopup(false);
    openUploadPopup(false);
    setShowLoadingPopup(true);

    try {
      await deleteUploadFile(deleteFileID);
      const { data } = await postToGetUploadFiles(type, quotationID);
      const vouchers = [...expenseForm.vouchers];
      
      if (type === 'quotationFiles') {
        vouchers[index].isFileUpload = data.length > 0;
      } else {
        vouchers[index].isVoucherUpload = data.length > 0;
      }
      
      setExpenseForm(prevState => ({ ...prevState, vouchers }));
      setFileList([ ...data ]);
      setShowLoadingPopup(false);
      setShowDeleteFileSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '刪除檔案失敗', err }));
      console.log(err);
    }
  };

  const expenseDiscardHandler = async () => {
    setShowDiscardPopup(false);
    setShowLoadingPopup(true);
    const oldRecords = cloneDeep(expenseForm.records);
    oldRecords.pop();

    const records = [
      ...oldRecords,
      {
        time: toUTCTime(Date.now()),
        approval: userID,
        status: '會計作廢',
      },
    ];

    try {
      await putModifyExpenseDetail({
        ...expenseForm,
        approvalStatus: 'financeDiscard',
        records,
      });
      setShowLoadingPopup(false);
      setShowDiscardSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '支憑作廢失敗', err }));
      console.log(err);
    }
  };

  const setExportedAccountingSystemHandler = async() => {
    setShowLoadingPopup(true);

    try {
      await postToSetExportedAccountingSystem({
        exportedAccountingSystem : false,
        expenseIDs: [id],
      });
    } catch (err) { console.log(err) }
    setIsExportedAccountingSystem(false);
    setShowLoadingPopup(false);
  };

  const updateExpenseFormData = async () => {
    const { vouchers } = expenseForm;
    const savedVouchers = vouchers
      .filter(voucher => voucher.quotationID !== undefined);

    const newVoucherIndexs = vouchers
      .filter(voucher => voucher.quotationID === undefined)
      .map((voucher, index) => index + savedVouchers.length);

    if (!vouchers.every(voucher => isVoucherContentValid(voucher))) {
      return openAlertPopup('支出憑證欄位未填或格式錯誤，請重新確認！');
    }

    if (!vouchers.every(voucher => isVoucherAmountValid(voucher))) {
      return openAlertPopup('支出憑證請款總金額與未稅金額、稅額加總不符，請重新確認！');
    }

    var result = 0;
    for (let i = 0; i < vouchers.length; i++) {
      result = isAccountingValidResult(vouchers[i]);
      if (result === 1) {
        openAlertPopup('勾選【不申報營業稅】，營業稅申報月份應為空，請重新確認！');
        break;
      } else if (result === 2) {
        openAlertPopup('勾選【申報個人所得】，個人所得申報月份不應為空，請重新確認！');
        break;
      } else if (result === 3) {
        openAlertPopup('未勾選【不申報營業稅及申報個人所得】，營業稅申報月份不應為空，請重新確認');
        break;
      }
    }
    if (result > 0) {
      return;
    }

    setShowLoadingPopup(true);

    try {
      await putModifyExpenseDetail({
        ...expenseForm,
        price: amountTotal,
        payment: {
          ...expenseForm.payment,
          creditCards: expenseForm.payment.creditCards
            .map(({ user, amount }) => ({ user, amount: integerConverter(amount) }))
        },
        vouchers: expenseForm.vouchers
          .map(({ tax, amount, exchangeRate, untaxedAmount, accounting, ...remainItems }) => ({
            ...remainItems,
            tax: integerConverter(tax),
            amount: integerConverter(amount),
            untaxedAmount: integerConverter(untaxedAmount),
            exchangeRate: floatConverter(exchangeRate),
            accounting: {
              ...accounting,
              tax: integerConverter(accounting.tax),
              amount: integerConverter(accounting.amount),
              untaxedAmount: integerConverter(accounting.untaxedAmount),
            }
          })),
      });

      const expenseDetail = await postToGetExpenseDetail(expenseForm.expenseID);
      const { vouchers } = expenseDetail.data;

      const quotationIDs = vouchers.map(({ quotationID }) => quotationID);

      for (let i = 0; i < newVoucherIndexs.length; i += 1) {
        const objectTypes = Object.keys(selectFilesList[i]);
        for (let j = 0; j < objectTypes.length; j += 1) {
          const objectType = objectTypes[j];
          const files = selectFilesList[i][objectType];
          for (let k = 0; k < files.length; k += 1) {
            const file = files[k];
            const quotationID = quotationIDs[newVoucherIndexs[i]];
            const formData = new FormData();
            formData.append('upfile', file);
            formData.append('objectType', objectType);
            formData.append('objectID', quotationID);
            formData.append('name', file.name);
            await postNewUploadFile(formData);
          }
        }
      }

      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '儲存失敗', err }));
      console.log(err);
    }
  };

  const fetchExpenseFormDetail = async () => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetExpenseDetail(id);
      setIsExportedAccountingSystem(data.exportedAccountingSystem);
      const vouchers = [];
      // console.log("financeManagementDetail " + JSON.stringify(data));

      for (let i = 0; i < data.vouchers.length; i += 1) {
        const voucher = data.vouchers[i];
        const {
          quotationID, tax, amount, untaxedAmount, type, tag, accounting, exchangeRate,
        } = voucher;
        const quotationFiles = await postToGetUploadFiles('quotationFiles', quotationID);
        const quotationVoucher = await postToGetUploadFiles('quotationVoucher', quotationID);
        const attachmentFiles = quotationFiles.data;
        const voucherFiles = quotationVoucher.data;

        vouchers.push({
          ...expenseVoucherConfig,
          ...data.vouchers[i],
          tag: tag || '',
          type: type || '',
          tax: tax || 0,
          amount: amount || 0,
          exchangeRate: exchangeRate || 0,
          untaxedAmount: untaxedAmount || 0,
          // isFileUpload: attachmentFiles.length > 0,
          // isVoucherUpload: voucherFiles.length > 0,
          accounting: {
            ...expenseVoucherConfig.accounting,
            ...accounting,
            tax: tax || 0,
            amount: amount || 0,
            untaxedAmount: untaxedAmount || 0,
          },
        })
        setMapQuotationIDHasFile(prev => new Map([...prev, [quotationID, {isFileUpload: attachmentFiles.length > 0,  isVoucherUpload: voucherFiles.length > 0}]]));
      }

      setExpenseForm({
        ...expenseFormConfig,
        ...data,
        vouchers,
      });

    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const initExpenseForm = useCallback(
    fetchExpenseFormDetail, []);

  const initOptions = useCallback(() => {
    dispatch(FETCH_MEMBER_OPTIONS());
    dispatch(FETCH_VENDOR_ID_OPTIONS());
    dispatch(FETCH_PROJECT_ID_OPTIONS());
    dispatch(FETCH_EXPENSE_TYPE_OPTIONS());
    dispatch(FETCH_DECLARE_TYPE_OPTIONS());
  }, [dispatch]);

  useEffect(() => {
    initOptions()
    initExpenseForm();
  }, [initExpenseForm, initOptions]);

  return (
    <MainContent>
      <PanelExpenseFormDetail
        title = "支憑管理明細"
        disabled = { !isEditing }
        voucherAddable = { voucherAddable }
        showFinanceItem = { true }
        disableProjectID = { true }
        expenseForm = { expenseForm }
        memberOptions = { memberOptions }
        objectIDOptions = { objectList() }
        projectIDOptions = { projectIDOptions }
        declareTypeOptions = { declareTypeOptions }
        taxTotal = { taxTotal }
        amountTotal = { amountTotal }
        // disableAmount = { expenseForm.approvalStatus === "generalManagerApproved" }
        disableAmount = { true }
        unTaxedAmountTotal = { unTaxedAmountTotal }
        hideAttachmentButton = { expenseForm.object === 'user' }
        fileManageHandler = { () => { setShowVouchersPopup(true) }}
        addFileListHandler = { addFileListHandler }
        removeFileListHandler = { removeFileListHandler }
        openFilesPopupHandler = { openFilesPopupHandler }
        updateExpenseFormHandler = { updateExpenseFormHandler }
        mapQuotationIDHasFile = { mapQuotationIDHasFile }
      >
        <DivFlexRow>
          <DivButtonsWrapper>
            {
              isExportedAccountingSystem &&
                <ButtonSave
                  onClick = { setExportedAccountingSystemHandler }
                >取消匯出會計系統</ButtonSave>
            }
            {
              isEditing
                ? <ButtonSave
                    disabled = { !formSavable }
                    onClick = { updateExpenseFormData }
                  >儲存</ButtonSave>
                : <ButtonSave
                    disabled = { !userEditable }
                    onClick = { () => { setIsEditing(true) } }
                  >編輯</ButtonSave>
            }
            {
              (expenseForm.approvalStatus === 'draft' || expenseForm.approvalStatus === "generalManagerApproved")&&
                <ButtonSave
                  disabled = { !userEditable }
                  onClick = { () => { setShowDiscardPopup(true) }}
                >作廢</ButtonSave>
            }
            <ButtonClear
              onClick = { () => { history.goBack() } }
            >離開</ButtonClear>
          </DivButtonsWrapper>
        </DivFlexRow>
        <DivTableWrapper>
          <TableApprovalRecords
            records = { expenseForm.records }
          />
        </DivTableWrapper>
      </PanelExpenseFormDetail>
      { showLoadingPopup && <PopupLoading/> }
      {
        showSelectFilesPopup &&
        <PopupSelectFiles
          title = { selectFilesPopupTitle }
          uploadFiles = { getSelectFilesList() }
          confirmHandler = { () => { setShowSelectFilesPopup(false) }}
          updateUploadFiles = { updateUploadFilesHandler }
        />
      }
      {
        showAttachmentsPopup &&
        <PopupAttachmentList
          multiple = { true }
          displayable = { true }
          fileChangable = { isEditing }
          fileList = { fileList }
          uploadFiles = { uploadFiles }
          fileUploadHandler = { fileUploadHandler }
          displayFileHandler = { displayFileHandler }
          deleteFileHandler = { openDeleteFilePopupHandler }
          cancelHandler = { () => { setShowAttachmentsPopup(false) }}
          updateUploadFiles = { files => { setUploadFiles([ ...files ]) }}
        />
      }
      {
        showVouchersPopup &&
        <PopupAttachmentList
          title = "憑證管理"
          displayable = { true }
          fileChangable = { isEditing }
          fileList = { fileList }
          uploadFiles = { uploadFiles }
          fileUploadHandler = { fileUploadHandler }
          deleteFileHandler = { openDeleteFilePopupHandler }
          displayFileHandler = { displayFileHandler }
          cancelHandler = { () => { setShowVouchersPopup(false) }}
          updateUploadFiles = { files => { setUploadFiles([ ...files ]) }}
        />
      }
      {
        showDeleteFilePopup &&
          <PopupExecuteConfirm
            title = "刪除檔案"
            text = "確定刪除此上傳檔案"
            confirmHandler = { deleteFileHandler }
            cancelHandler = { () => {
              setShowDeleteFilePopup(false);
            }}
          />
      }
      {
        showDiscardPopup &&
          <PopupExecuteConfirm
            title = "支出憑單作廢"
            text = "確定作廢此支出憑單"
            confirmHandler = { expenseDiscardHandler }
            cancelHandler = { () => {
              setShowDiscardPopup(false);
            }}
          />
      }
      {
        showDraggableImagePopup &&
          <PopupDraggable
            url = { fileUrl }
            cancelHandler = { () => {
              setShowDraggableImagePopup(false);
              openUploadPopup(true);
            }}
          />
      }
      {
        showDraggablePDFPopup &&
          <PopupDraggablePdf
            file = { file }
            cancelHandler = { () => {
              setShowDraggablePDFPopup(false);
              openUploadPopup(true);
            }}
          />
      }
      {
        showDiscardSuccessPopup &&
          <PopupExecuteResult
            title = "支憑作廢成功"
            confirmHandler = { () => { history.goBack() }}
          />
      }
      {
        showDeleteFileSuccessPopup &&
          <PopupExecuteResult
            title = "刪除檔案成功"
            confirmHandler = { deleteFileSuccessHandler }
          />
      }
      {
        showUploadSuccessPopup &&
          <PopupExecuteResult
            title = "上傳成功"
            confirmHandler = { uploadSuccessConfirmHandler }
          />
      }
      {
        showSaveSuccessPopup &&
          <PopupExecuteResult
            title = "儲存成功"
            confirmHandler = { closeSuccessPopupHandler }
          />
      }
      {
        showAlertPopup &&
          <PopupExecuteResult
            title = "儲存失敗"
            text = { alertText }
            confirmHandler = { () => { setShowAlertPopup(false) } }
          />
      }
      {
        showSaveFailPopup &&
          <PopupExecuteResult
            title = { apiFailPopupTitle }
            text = { apiFailPopupMessage }
            confirmHandler = { apiFailConfirmHandler }
          />
      }
    </MainContent>
  );
};

export default FinanceManagementDetail;
