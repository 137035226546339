import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  deleteVendorTag,
  putNewVendorTag,
  postToGetVendorTags,
  patchModifyVendorTag,
} from '@/api/vendorService';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import MainContent from '@/component-style/MainContent';
import TableVendorTags from '@/components/Table/SysConfig/TableVendorTags';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import PopupExecuteConfirm from '@/components/UI/Popup/PopupExecuteConfirm';
import PopupVendorTagDetail from '@/components/UI/Popup/PopupVendorTagDetail';

import { vendorTagConfig } from '@/constant/dataConfig/sysConfigModule';

const VendorTagManagement = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const sysConfigRoutes = useSelector(state => state.login.sysConfigRoutes);
  const showSaveFailPopup = useSelector(state => state.apiFail.showSaveFailPopup);
  const apiFailPopupTitle = useSelector(state => state.apiFail.title);
  const apiFailPopupMessage = useSelector(state => state.apiFail.message);

  const [tags, setTags] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [selectedTag, setSelectedTag] = useState({ ...vendorTagConfig });

  const [isAdding, setIsAdding] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [showTagDetailPopup, setShowTagDetailPopup] = useState(false);
  const [showSaveSuccessPopup, setShowSaveSuccessPopup] = useState(false);
  const [showDeleteSuccessPopup, setShowDeleteSuccessPopup] = useState(false);

  const userEditable = sysConfigRoutes.indexOf('VendorTagManagement-Edit') !== -1;

  const addTagHandler = () => {
    setIsAdding(true);
    setSelectedTag({ ...vendorTagConfig });
    setShowTagDetailPopup(true);
  };

  const updateTagDetailHandler = (key, value) => {
    const updateObj = { ...selectedTag };
    updateObj[key] = value;
    setSelectedTag({ ...updateObj });
  };

  const closeDetailPopupHandler = () => {
    setIsAdding(false);
    setShowTagDetailPopup(false);
  };

  const showDetailHandler = index => {
    const selectedTag = tags[index];
    setSelectedTag({ ...selectedTag });
    setShowTagDetailPopup(true);
  };

  const setCurrentPageHandler = page => {
    setCurrentPage(page);
    fetchTags(page);
  };

  const deleteHandler = index => {
    const selectedTag = tags[index];
    setSelectedTag({ ...selectedTag });
    setShowDeletePopup(true);
  };

  const apiFailConfirmHandler = () => {
    dispatch(CLOSE_SAVE_FAIL_POPUP());

    apiFailPopupTitle === '儲存失敗'
      && setShowTagDetailPopup(true);
  };

  const closeSaveSuccessPopupHandler = () => {
    setIsAdding(false);
    setShowSaveSuccessPopup(false);
    setCurrentPageHandler(1);
  };

  const updateVendorTagHandler = async () => {
    setShowTagDetailPopup(false);
    setShowLoadingPopup(true);
    const config = { vendorTypeID: id, tag: selectedTag }

    try {
      isAdding
        ? await putNewVendorTag(config)
        : await patchModifyVendorTag(config)

      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '儲存失敗', err }));
      console.log(err);
    }
  };

  const deleteVendorTagHandler = async () => {
    setShowDeletePopup(false);
    setShowLoadingPopup(true);
    const config = {
      vendorTypeID: id,
      name: selectedTag.name,
    };

    try {
      await deleteVendorTag(config);
      setShowLoadingPopup(false);
      setShowDeleteSuccessPopup(true);
    } catch(err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '刪除失敗', err }));
      console.log(err);
    }
  };

  const fetchTags = async (page = currentPage) => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetVendorTags({
        vendorTypeID: id, page, amount: 10, 
      });

      const { vendorTags, totalCount } = data;
      const tags = vendorTags.map(tag => ({
        ...vendorTagConfig,
        ...tag,
        enable: tag.enable || false,
      }));
      setTotalCount(totalCount || 0);
      setTags(tags);
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const initTags = useCallback(fetchTags, []);

  useEffect(() => {
    initTags();
  }, [initTags]);

  return (
    <MainContent>
      <TableVendorTags
        title = "廠商中分類列表"
        tags = { tags }
        totalCount = { totalCount }
        currentPage = { currentPage }
        addTagHandler = { addTagHandler }
        deleteHandler = { deleteHandler }
        showDetailHandler = { showDetailHandler }
        pageChangedHandler = { setCurrentPageHandler }
      />
      { showLoadingPopup && <PopupLoading/> }
      {
        showTagDetailPopup &&
          <PopupVendorTagDetail
            title = "廠商中分類"
            tag = { selectedTag }
            disableName = { !isAdding }
            disableSave = { isAdding ? false : !userEditable }
            cancelHandler = { closeDetailPopupHandler }
            confirmHandler = { updateVendorTagHandler }
            updateTagDetailHandler = { updateTagDetailHandler }
          />
      }
      {
        showDeletePopup &&
          <PopupExecuteConfirm
            title = "刪除廠商中分類"
            text = { `確定刪除 ${ selectedTag.name } 中分類？` }
            confirmHandler = { deleteVendorTagHandler }
            cancelHandler = { () => { setShowDeletePopup(false) }}
          />
      }
      {
        showDeleteSuccessPopup &&
          <PopupExecuteResult
            title = "刪除成功"
            confirmHandler = { () => {
              setShowDeleteSuccessPopup(false);
              setCurrentPageHandler(1);
            }}
          />
      }
      {
        showSaveSuccessPopup &&
          <PopupExecuteResult
            title = "儲存成功"
            confirmHandler = { closeSaveSuccessPopupHandler }
          />
      }
      {
        showSaveFailPopup &&
          <PopupExecuteResult
            title = { apiFailPopupTitle }
            text = { apiFailPopupMessage }
            confirmHandler = { apiFailConfirmHandler }
          />
      }
    </MainContent>
  );
};

export default VendorTagManagement;
