import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import TableWrapper from '@/components/UI/TableWrapper';
import PageSelector from '@/components/UI/PageSelector';
import { Table, TableBody } from '@/component-style/Table';
import Header from './Header';
import Item from './Item';
import styled from 'styled-components';
import { ButtonBasic } from '@/component-style/Button';

// import moment from 'moment';

// import PopupOvertimeDetail from '@/components/UI/Popup/PopupOvertimeDetail';

const TableLeaveSetting = props => {
  const authorityRoutes = useSelector(state => state.login.authorityRoutes);

  const { leaveSetting } = props;

  return (
    <TableWrapper 
      title = "假別列表"
      buttonAdd = {
       props.buttonAdd
      }
    >
      <Table>
        <Header/>
        <TableBody>
          {
            leaveSetting !== undefined && leaveSetting.map(singleLeaveSetting =>
              <Item
                key = { singleLeaveSetting.insuranceGrade }
                leaveSetting = { singleLeaveSetting }
                editDetailHandler = { (insuranceGrade) => {
                  // console.log("TableLeaveSetting index.js editDetailHandler() insuranceGrade:" + insuranceGrade);
                  props.editDetailHandler(insuranceGrade);
                }}
              />
            )
          }
        </TableBody>
      </Table>
      <PageSelector
        total = { props.totalCount }
        current = { props.currentPage }
        pageChangedHandler = { props.pageChangedHandler }
        showSizeChanger = { false }
      />
    </TableWrapper>
  );
};

export default TableLeaveSetting;
