import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { TdItem, TableRow } from '@/component-style/Table';
import { ButtonGoDetail, ButtonBasic } from '@/component-style/Button';

import { getOptionDesc } from '@/utils/textUtils';
import { formatTime } from '@/utils/timeUtils';

import { decimalCommaConverter } from '@/utils/textUtils';

const TrWrapper = styled(TableRow)`
  border-bottom: solid 2px #f2f2f2;
  box-sizing: border-box;
  min-width: 1120px;

  &:last-child {
    border-bottom: none;
  }
`;

const Td80 = styled(TdItem)`
  width: 80px;
`;

const Td120 = styled(TdItem)`
  width: 120px;
`;

const ButtonDelete = styled(ButtonBasic)`
  width: 80px;
  height: 30px;
`;

const Item = props => {
  const { quotation } = props;
  const memberOptions = useSelector(state => state.srpOptions.memberOptions);
  const director = getOptionDesc(memberOptions, quotation.director);

  return (
    <TrWrapper>
      <Td120>{ quotation.name }</Td120>
      <Td120>
        { 
          quotation.type === 'oop' ? '專案支出費用' : '服務費收入'
        }
      </Td120>
      <Td80>{ quotation.itemAmount }</Td80>
      <Td120>{ decimalCommaConverter(quotation.quotedPrice) }</Td120>
      <Td120>{ decimalCommaConverter(quotation.price) }</Td120>
      <Td120>{ director }</Td120>
      <Td120>
        { formatTime(quotation.createdDate, 'YYYY/MM/DD') || '' }
      </Td120>
      <Td120>
        { formatTime(quotation.modifiedDate, 'YYYY/MM/DD') || '' }
      </Td120>
      <Td120>
        <ButtonDelete
          disabled = { props.disabled || !props.userEditable }
          onClick = { props.deleteHandler }
        >刪除</ButtonDelete>
      </Td120>
      <Td80>
        <ButtonGoDetail
          disabled = { props.disabled }
          onClick = { props.goDetailHandler }
        >...
        </ButtonGoDetail>
      </Td80>
    </TrWrapper>
  );
};

export default Item;
