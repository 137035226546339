import React from 'react';
import { useSelector } from 'react-redux';

import { Table, TableBody } from '@/component-style/Table';
import TableWrapper  from '@/components/UI/TableWrapper';
import PageSelector from '@/components/UI/PageSelector';
import Header from './Header';
import Item from './Item';

const TableMediaReports = props => {
  const projectRoutes = useSelector(state => state.login.projectRoutes);
  const disableDetailPage = projectRoutes.indexOf('MediaReportDetail') === -1;

  return (
    <TableWrapper title = "媒體披露列表">
      <Table>
        <Header
          selectAll = { props.selectAll }
          showCheckbox = { props.showCheckbox }
          showDetailButton = { props.showDetailButton }
          selectAllHandler = { props.selectAllHandler }
        />
        <TableBody>
          {
            props.reports.map((mediaReport, index) =>
              <Item
                key = { mediaReport.announcementID }
                showCheckbox = { props.showCheckbox }
                showDetailButton = { props.showDetailButton }
                disableDetailPage = { disableDetailPage }
                mediaReport = { mediaReport }
                checkHandler = { value => {
                  props.checkHandler(index, value);
                }}
                goDetailHandler = { () => {
                  props.goDetailHandler(
                    mediaReport.projectID,
                    mediaReport.announcementID
                  )
                }}
              />
            )
          }
        </TableBody>
      </Table>
      <PageSelector
        current = { props.currentPage }
        total = { props.totalCount }
        pageChangedHandler = { props.pageChangedHandler }
      />
    </TableWrapper>
  );
};

export default TableMediaReports;
