import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { TdItem, TableRow } from '@/component-style/Table';
import { ButtonGoDetail } from '@/component-style/Button';
import { getOptionDesc } from '@/utils/textUtils';
import moment from 'moment';

import {
  diffTimeSubtractLunchBreak,
  // formatTime,
} from '@/utils/timeUtils';

const TrWrapper = styled(TableRow)`
  border-bottom: solid 2px #f2f2f2;
  box-sizing: border-box;
  min-width: 1200px;

  &:last-child {
    border-bottom: none;
  }
`;

const Td80 = styled(TdItem)`
  width: 80px;
`;

const Td120 = styled(TdItem)`
  width: 120px;
`;

const Td160 = styled(TdItem)`
  width: 160px;
`;

const Td240 = styled(TdItem)`
  width: 240px;
`;

const Item = props => {
  const { workingRecord } = props;
  const { startTimeLocal, endTimeLocal } = workingRecord;
  const workingHoursTypeOptions = useSelector(state => state.srpOptions.workingHoursTypeOptions);

  const workingHoursType = getOptionDesc(workingHoursTypeOptions, workingRecord.typeID);

  return (
    <TrWrapper>
      <Td120>{ workingRecord.username }</Td120>
      <Td120>{ workingRecord.date }</Td120>
      <Td160>{ workingRecord.projectCode }</Td160>
      <Td160>{ workingRecord.projectName }</Td160>
      <Td120>{ workingHoursType }</Td120>
      <Td120>{ workingRecord.tag }</Td120>
      <Td240>
        {
          `${moment.parseZone(startTimeLocal).format('MM/DD HH:mm')} ~ ${moment.parseZone(endTimeLocal).format('MM/DD HH:mm')}`
        }
      </Td240>
      {/* <Td80>{ diffTime(startTime, endTime, 'minutes') }</Td80> */}
      <Td80>{ diffTimeSubtractLunchBreak(startTimeLocal, endTimeLocal, 'hours') }</Td80>
      <Td80>
        <ButtonGoDetail
          disabled = { props.disableDetailPage }
          onClick = { props.goDetailHandler }
        >...
        </ButtonGoDetail>
      </Td80>
    </TrWrapper>
  );
};

export default Item;
