import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';

import {
  postToGetUserInfo,
  postToGetPasswordParams,
  postToModifyPassword,
} from '@/api/authService';

import MainContent from '@/component-style/MainContent';
import TableWrapper from '@/components/UI/TableWrapper';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import PopupExecuteConfirm from '@/components/UI/Popup/PopupExecuteConfirm';
import PopupPasswordLimitation from '@/components/UI/Popup/PopupPasswordLimitation';
import { InputBasic } from '@/component-style/Input';
import { ButtonBasic, ButtonCancel } from '@/component-style/Button';
import { PSubtitle } from '@/component-style/TextElement';
import {
  DivFlexRow,
  DivItemWrapper,
} from '@/component-style/RWDWrapper';

import { LOGOUT } from '@/store/actions/loginAction';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding: 0;
  padding-bottom: 30px;
  justify-content: flex-end;

  @media (max-width: 425px) {
    justify-content: center;
    padding-left: 0;
  }
`;

const ButtonExecute = styled(ButtonBasic)`
  width: 120px;

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`;

const ButtonDiscard = styled(ButtonCancel)`
  margin-left: 10px;
  width: 120px;

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`;

const userConfig = {
  account: '',
  name: '',
  password: '',
  confirmPassword: '',
};

const LoginFirstTime = props => {
  const dispatch = useDispatch();

  const [userInfo, setUserInfo] = useState({ ...userConfig });

  const [pwMinLength, setPWMinLength] = useState(8);
  const [alertText, setAlertText] = useState('');
  const [showInfoPopup, setShowInfoPopup] = useState(false);
  const [showSavePopup, setShowSavePopup] = useState(false);
  const [showAlertPopup, setShowAlertPopup] = useState(false);
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [showSaveFailPopup, setShowSaveFailPopup] = useState(false);
  const [showSaveSuccessPopup, setShowSaveSuccessPopup] = useState(false);
  const [showWeakPasswordPopup, setShowWeakPasswordPopup] = useState(false);

  const disableSave = userInfo.password === ''
    || userInfo.confirmPassword === '';

  const [changePasswordConfig, setChangePasswordConfig] = useState({
    oldPassword: '',
    newPassword: '',
  });

  const clearHandler = () => {
    setUserInfo(prevState => ({
      ...prevState,
      password: '',
      confirmPassword: '',
    }));
  };

  const closeSuccessPopupHandler = () => {
    clearHandler();
    setShowSaveSuccessPopup(false);
    Cookies.remove('veda__srp-token');
    dispatch(LOGOUT());
  };

  const fetchPasswordConfig = async () => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetPasswordParams();
      const { minLength } = data;
      setPWMinLength(minLength || 0);
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const fetchUserData = async () => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetUserInfo();
      const { account, name } = data;
      setUserInfo({ ...userConfig, name, account })

    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
    setShowInfoPopup(true);
  };

  const saveHandler = async () => {
    console.log("saveHandler userInfo.newPassword:" + userInfo.newPassword + " userInfo.confirmPassword:" + userInfo.confirmPassword);
    if (userInfo.password !== userInfo.confirmPassword) {
      return openAlertPopup('新密碼與確認新密碼不符，請確認');
    }

    if (userInfo.password.length < pwMinLength) {
      return openAlertPopup('');
    }

    setShowLoadingPopup(true);
    try {
      await postToModifyPassword(changePasswordConfig);
      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
    } catch (err) {
      console.log(err);
      setShowLoadingPopup(false);
      err.response.message === 'password is too weak'
        ? setShowWeakPasswordPopup(true)
        : setShowSaveFailPopup(true);
    }
  };

  const openAlertPopup = text => {
    setAlertText(text);
    setShowAlertPopup(true);
  };

  const initUserData = useCallback(fetchUserData, []);
  const initPasswordConfig = useCallback(fetchPasswordConfig, []);

  const initData = useCallback(
    async () => {
      await initPasswordConfig();
      await initUserData();
  }, [initPasswordConfig, initUserData]);

  useEffect(() => {
    initData();
  }, [initData]);

  return (
    <MainContent>
      <TableWrapper title = "更換密碼">
        <DivFlexRow>
          <DivItemWrapper>
            <PSubtitle>使用者帳號</PSubtitle>
            <InputBasic
              disabled = { true }
              value = { userInfo.account }
            />
          </DivItemWrapper>
          <DivItemWrapper>
            <PSubtitle>使用者名稱</PSubtitle>
            <InputBasic
              disabled = { true }
              value = { userInfo.name }
            />
          </DivItemWrapper>
        </DivFlexRow>
        <DivFlexRow>
          <DivItemWrapper>
            <PSubtitle>舊密碼</PSubtitle>
            <InputBasic
              type = "password"
              utocomplete = { false }
              value = { changePasswordConfig.oldPassword }
              onChange = { ({ target }) => {
                setChangePasswordConfig(prevState => ({
                  ...prevState,
                  oldPassword: target.value,
                }));
              }}
            />
          </DivItemWrapper>
        </DivFlexRow>
        <DivFlexRow>
          <DivItemWrapper>
            <PSubtitle>新密碼</PSubtitle>
            <InputBasic
              type = "password"
              autocomplete = { false }
              value = { userInfo.password }
              onChange = { ({ target }) => {
                setUserInfo(prevState => ({
                  ...prevState,
                  password: target.value,
                }));
                setChangePasswordConfig(prevState => ({
                  ...prevState,
                  newPassword: target.value,
                }));
              }}
            />
          </DivItemWrapper>
          <DivItemWrapper>
            <PSubtitle>確認新密碼</PSubtitle>
            <InputBasic
              type = "password"
              autocomplete = { false }
              value = { userInfo.confirmPassword }
              onChange = { ({ target }) => {
                setUserInfo(prevState => ({
                  ...prevState,
                  confirmPassword: target.value,
                }));
              }}
            />
          </DivItemWrapper>
        </DivFlexRow>
        <DivFlexRow>
          <DivButtonsWrapper>
            <ButtonExecute
              disabled = { disableSave }
              onClick = { saveHandler }
              cancelHandler = { () => { setShowSavePopup(true) }}
            >更換密碼</ButtonExecute>
            <ButtonDiscard
              onClick = { clearHandler } 
            >清除重填</ButtonDiscard>
          </DivButtonsWrapper>
        </DivFlexRow>
      </TableWrapper>
      { showLoadingPopup && <PopupLoading/> }
      { 
        showSavePopup &&
          <PopupExecuteConfirm
            title = "更新密碼"
            text = "確定更換新密碼？"
            confirmHandler = { saveHandler }
            cancelHandler = { () => { setShowSavePopup(false) }}
          />
      }
      {
        showSaveSuccessPopup &&
          <PopupExecuteResult
            title = "更新成功"
            confirmHandler = { closeSuccessPopupHandler }
          />
      }
      {
        showSaveFailPopup &&
          <PopupExecuteResult
            title = "更新失敗"
            confirmHandler = { () => { setShowSaveFailPopup(false) } }
          />
      }
      {
        showAlertPopup &&
          <PopupExecuteResult
            title = "更新失敗"
            text = { alertText }
            confirmHandler = { () => { setShowAlertPopup(false) } }
          />
      }
      {
        showWeakPasswordPopup &&
          <PopupPasswordLimitation
            minLength = { pwMinLength }
            confirmHandler = { () => { setShowWeakPasswordPopup(false) } }
          />
      }
      {
        showInfoPopup &&
          <PopupExecuteResult
            title = "更換密碼"
            text = "第一次登入，請設定新密碼！"
            confirmHandler = { () => { setShowInfoPopup(false) } }
          />
      }
    </MainContent>
  );
};

export default LoginFirstTime;
