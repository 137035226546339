export const allInvitations = [
  {
    id: "1",
    projectCode: "MS1505R",
    projectName: "五月份公關專業服務",
    yes: 2,
    maybe: 1,
    delegate: 1,
    depends: 1,
    onDay: 0,
    notSure: 2,
    uncontacted: 1,
    no: 1,
    later: 1,
    uncomfirmed: 1,
  },
  {
    id: "1",
    projectCode: 'MS150505-1',
    projectName: 'Microsoft Ignite 媒體邀訪',
    yes: 2,
    maybe: 1,
    delegate: 1,
    depends: 1,
    onDay: 0,
    notSure: 2,
    uncontacted: 1,
    no: 1,
    later: 1,
    uncomfirmed: 1,
  },
  {
    id: "1",
    projectCode: 'MS170601-1',
    projectName: 'Windows Partner workshop',
    yes: 2,
    maybe: 1,
    delegate: 1,
    depends: 1,
    onDay: 0,
    notSure: 2,
    uncontacted: 1,
    no: 1,
    later: 1,
    uncomfirmed: 1,
  },
  {
    id: "1",
    projectCode: 'MS171020',
    projectName: '台灣微軟與新北市政府程式開發競賽總決賽頒獎典禮',
    yes: 2,
    maybe: 1,
    delegate: 1,
    depends: 1,
    onDay: 0,
    notSure: 2,
    uncontacted: 1,
    no: 1,
    later: 1,
    uncomfirmed: 1,
  },
];

export const memberEvaluationList = [
  {
    evaluationId: '1',
    memberName: 'Peter',
    attitude: 10,
    timeManagement: 10,
    quality: 10,
    teamwork: 10,
    communication: 10,
  },
  {
    evaluationId: '2',
    memberName: 'Peter',
    attitude: 10,
    timeManagement: 10,
    quality: 10,
    teamwork: 10,
    communication: 10,
  },
  {
    evaluationId: '3',
    memberName: 'Peter',
    attitude: 10,
    timeManagement: 10,
    quality: 10,
    teamwork: 10,
    communication: 10,
  },
  {
    evaluationId: '4',
    memberName: 'Peter',
    attitude: 10,
    timeManagement: 10,
    quality: 10,
    teamwork: 10,
    communication: 10,
  },
  {
    evaluationId: '5',
    memberName: 'Peter',
    attitude: 10,
    timeManagement: 10,
    quality: 10,
    teamwork: 10,
    communication: 10,
  },
];

export const vendorEvaluationList = [
  {
    projectCode: 'CSR16P',
    projectName: 'CSRone平台專案費用與收入',
    evaluationId: '1',
    vendorName: '陳德怡',
    satisfaction: 0,
    timeManagement: 0,
    price: 0,
    compatibility: 0,
    attitude: 0
  },
  {
    projectCode: 'CSR16P',
    projectName: 'CSRone平台專案費用與收入',
    evaluationId: '2',
    vendorName: '世貿花坊',
    satisfaction: 0,
    timeManagement: 0,
    price: 0,
    compatibility: 0,
    attitude: 0
  },
  {
    projectCode: 'CSR16P',
    projectName: 'CSRone平台專案費用與收入',
    evaluationId: '3',
    vendorName: '長日網路多媒體股份有限公司',
    satisfaction: 0,
    timeManagement: 0,
    price: 0,
    compatibility: 0,
    attitude: 0
  },
  {
    projectCode: 'CSR16P',
    projectName: 'CSRone平台專案費用與收入',
    evaluationId: '4',
    vendorName: '潤利艾克曼國際事業有限公司',
    satisfaction: 0,
    timeManagement: 0,
    price: 0,
    compatibility: 0,
    attitude: 0
  },
];

export const activityIdeasList = [
  {
    activityIdeaUUID: "1",
    projectCode: "CCS16CSR",
    category: "虛實整合",
    subCategory: "互動遊戲",
    content: "為台灣永續研訓中心製作五分鐘的資訊動畫介紹影片，包含成立宗旨、長官的話、會員組成、中心活動概況等資訊",
    title: "mograph, 動畫, CSR",
    vendor: "",
    phone: "",
    email: "",
    budget: 180000,
    website: "",
    executed: true,
    cloudStorageUrl: "",
  },
  {
    activityIdeaUUID: "2",
    projectCode: "FET16CSR",
    category: "虛實整合",
    subCategory: "互動遊戲",
    content: "遠傳申請國際電信大展GSMA新增的SDGs作為獎項 需求為三分鐘動畫，表現企業針對SDGs所做出的貢獻 遠傳在影片中傳達他們與兒福長達十年合作，從門市捐款箱、社群媒體、電信用戶、到實體活動 近年募款總額佔兒福全年的40%",
    title: "mograph, 動畫, CSR, 公益, 戶外活動",
    vendor: "Boky Chen",
    phone: "0930450248",
    email: "wonderoow@gmail.com",
    budget: 50000,
    website: "",
    executed: true,
    cloudStorageUrl: "",
  },
  {
    activityIdeaUUID: "3",
    projectCode: "GVB1612r",
    category: "伴手禮",
    subCategory: "完全客製",
    content: "關島製作包含著色頁面的功能筆記本，共80頁，含20張設計師手繪線稿，主題包含各種關島旅遊元素，A5尺寸。設計費130,000，印製費用1000本75,000",
    title: "品牌宣傳，筆記本，塗鴉上色",
    vendor: "伯納廣告",
    phone: "87720532",
    email: "",
    budget: 200000,
    website: "",
    executed: true,
    cloudStorageUrl: "",
  },
];

export const mediaReportsList = [
  {
    reportId: '1',
    projectCode: 'MS170104',
    projectName: '運動區塊鏈記者會',
    origin: '聯合新聞網',
    mediaName: '聯合新聞網',
    mediaType: 'Online',
    publishDate: '2017/01/04',
    reporter: '孫中英',
    title: '蔡承儒端成果 富邦首攻運動區塊鏈',
    link: 'string',
    benefit: 50000,
  },
  {
    reportId: '2',
    projectCode: 'MS170104',
    projectName: '運動區塊鏈記者會',
    origin: '經濟日報',
    mediaName: '聯合新聞網',
    mediaType: 'Online',
    publishDate: '2017/01/04',
    reporter: '韓化宇',
    title: '富邦金攻區塊鏈 運動賽事先行',
    link: 'string',
    benefit: 50000,
  },
  {
    reportId: '3',
    projectCode: 'MS170104',
    projectName: '運動區塊鏈記者會',
    origin: '數位時代',
    mediaName: '數位時代',
    mediaType: 'Business Magazine',
    publishDate: '2017/01/04',
    reporter: '顏理謙',
    title: '走出試驗階段，運動區塊鏈實作BraveLog上線',
    link: 'string',
    benefit: 30000,
  },
];
