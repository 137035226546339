import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Table, TableBody } from '@/component-style/Table';
import TableWrapper  from '@/components/UI/TableWrapper';
import PageSelector from '@/components/UI/PageSelector';
import Header from './Header';
import Item from './Item';

const TableWorkingHours = props => {
  const history = useHistory();
  const attendanceRoutes = useSelector(state => state.login.attendanceRoutes);
  const disableDetailPage = attendanceRoutes.indexOf('WorkingHoursDetail') === -1;

  const { workingRecords } = props;

  return (
    <TableWrapper title = '工時列表'>
      <Table>
        <Header/>
        <TableBody>
          {
            workingRecords.map(workingRecord =>
              <Item
                key = { workingRecord.workingHoursID }
                workingRecord = { workingRecord }
                disableDetailPage = { disableDetailPage }
                goDetailHandler = {() => {
                  history.push(`/Attendance/WorkingHoursDetail/${workingRecord.workingHoursID}`);
                }}
              />
            )
          }
        </TableBody>
      </Table>
      <PageSelector
        current = { props.currentPage }
        total = { props.totalCount }
        pageChangedHandler = { props.pageChangedHandler }
      />
    </TableWrapper>
  );
};

export default TableWorkingHours;
