import React from 'react';
import styled from 'styled-components';

import Checkbox from '@/components/UI/Checkbox';
import { TableHead, TableRow, ThItem } from '@/component-style/Table';

const THead = styled(TableHead)`
  min-width: 1520px;
`;

const Th80 = styled(ThItem)`
  width: 80px;
`;

const Th120 = styled(ThItem)`
  width: 120px;
`;

const Th160 = styled(ThItem)`
  width: 160px;
`;

const Header = props => {
  
  return (
    <THead>
      <TableRow>
      <Th80>
          <Checkbox
            checked = { props.selectAll }
            changeHandler = { props.selectAllHandler }
          />
        </Th80>
        <Th120>申請時間</Th120>
        <Th120>支憑流水號</Th120>
        <Th120>預計付款日</Th120>
        <Th120>審核狀態</Th120>
        <Th160>專案代號</Th160>
        <Th120>申請人</Th120>
        <Th160>付款對象</Th160>
        <Th120>請款總額</Th120>
        <Th80>列印</Th80>
        <Th80>付款狀態</Th80>
        <Th120>匯會計系統</Th120>
        <Th80>申報營業稅</Th80>
        <Th80></Th80>
      </TableRow>
    </THead>
  );
};

export default Header;
