import React from 'react';
import styled from 'styled-components';

import UploadNormal from '@/components/UI/Upload/UploadNormal';
import UploadMultiple from '@/components/UI/Upload/UploadMultiple';
import TableAttachmentList from '@/components/Table/TableAttachmentList';
import { DivPopupWrapper} from '@/component-style/Popup';
import { ButtonBasic, ButtonCancel } from '@/component-style/Button';
import { PresetColorTypes } from 'antd/lib/_util/colors';

const DivButtonsWrapper = styled.div`
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const DivUploadWrapper = styled.div`
  margin-bottom: 20px;
`;

const H3Title = styled.h3`
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: #8b8b8b;
  margin-bottom: 10px;
`;

const ButtonConfirm = styled(ButtonBasic)`
  width: 120px;
`;

const ButtonDiscard = styled(ButtonCancel)`
  width: 120px;
  margin-right: 10px;
`;

const DivPopup = styled.div`
  max-height: 80vh;
  overflow-y: auto;
  border-radius: 8px;
  padding: 30px;
  width: 580px;
  box-sizing: border-box;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;

  @media (max-width: 480px) {
    width: 300px;
  }
`;

const PopupAttachmentList = props => {

  const uploadComponent = props.multiple === true
    ? <UploadMultiple
        uploadFiles = { props.uploadFiles }
        updateUploadFiles = { props.updateUploadFiles }
      />
    : <UploadNormal
        uploadFiles = { props.uploadFiles }
        updateUploadFiles = { props.updateUploadFiles }
      />

  return (
    <DivPopupWrapper>
      <DivPopup>
        <H3Title>{ props.title || '附件管理' }</H3Title>
        <DivUploadWrapper>
          {
            props.fileChangable && uploadComponent
          }
        </DivUploadWrapper>
        <TableAttachmentList
          displayable = { props.displayable }
          fileChangable = { props.fileChangable }
          fileList = { props.fileList }
          deleteFileHandler = { props.deleteFileHandler }
          displayFileHandler = { props.displayFileHandler }
        />
        <DivButtonsWrapper>
          <ButtonDiscard
            onClick = { props.cancelHandler }
          >關閉
          </ButtonDiscard>
          {
            props.fileChangable &&
              <ButtonConfirm
                disabled = { props.uploadFiles.length === 0  }
                onClick = { props.fileUploadHandler }
              >上傳
              </ButtonConfirm>
          }
        </DivButtonsWrapper>
      </DivPopup>
    </DivPopupWrapper>
  );
};

export default PopupAttachmentList;
