import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import PageSelector from '@/components/UI/PageSelector';
import { Table, TableBody } from '@/component-style/Table';
import { ButtonBasic } from '@/component-style/Button';
import Header from './Header';
import Item from './Item';

const DivWrapper = styled.div`
  width: 100%;
  border-top: 2px solid #de2027;
  background-color: #ffffff;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
`;

const TitleWrapper = styled.div`
  margin-top: 35px;
  margin-bottom: 20px;
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const H3Title = styled.h3`
  margin: 0;
  font-size: 20px;
  font-weight: normal;
  color: #57585a;
`;

const ButtonDelete = styled(ButtonBasic)`
  width: 120px;
  height: 40px;
`;

const TableVendorEvaluation = props => {
  const history = useHistory();
  const { evaluations } = props;

  return (
    <DivWrapper>
      <TitleWrapper>
        <H3Title>廠商評鑑清單</H3Title>
        {
          props.displayMode &&
            <ButtonDelete
              onClick = { () => { history.goBack() } }
            >離開</ButtonDelete>
        }
      </TitleWrapper>
      <Table>
        <Header
          displayMode = { props.displayMode }
        />
        <TableBody>
          {
            evaluations.map((evaluation, index) =>
              <Item
                key = { index }
                disabled = { props.disabled }
                evaluation = { evaluation }
                displayMode = { props.displayMode }
                saveHandler = { props.saveHandler }
                evaluationChangeHandler = { value => {
                  props.evaluationChangeHandler(value, index);
                }}
              />
            )
          }
        </TableBody>
      </Table>
      <PageSelector
        total = { props.totalCount }
        current = { props.currentPage }
        disabled = { props.disablePageSelector }
        pageChangedHandler = { props.pageChangedHandler }
      />
    </DivWrapper>
  );
};

export default TableVendorEvaluation;
