import React from 'react';
import 'antd/dist/antd.css';
import './index.css';
import { Radio } from 'antd';

const RadioGroup = props => {

  return (
    <Radio.Group
      disabled = { props.disabled }
      value = { props.value }
      onChange = { ({ target }) => {
        props.changeHandler(target.value)
      }}
    >
      {
        props.options.map(option =>
          <Radio
            key = { option.value }
            value = { option.value }
          >{ option.desc }
          </Radio>
        )
      }
    </Radio.Group>
  )
};

export default RadioGroup;
