import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { InputBasic } from '@/component-style/Input';
import { TdItem, TableRow } from '@/component-style/Table';
import { ButtonBasic, ButtonCancel } from '@/component-style/Button';

import { getOptionDesc } from '@/utils/textUtils';
import {
  // diffTime,
  formatTime,
  diffTimeSubtractLunchBreak,
} from '@/utils/timeUtils';

import moment from 'moment';

const TrWrapper = styled(TableRow)`
  border-bottom: solid 2px #f2f2f2;
  box-sizing: border-box;
  min-width: 1480px;

  &:last-child {
    border-bottom: none;
  }
`;

const Td80 = styled(TdItem)`
  width: 80px;
`;

const Td120 = styled(TdItem)`
  width: 120px;
`;

const Td160 = styled(TdItem)`
  width: 160px;
`;

const Td240 = styled(TdItem)`
  width: 240px;
`;

const InputMins = styled(InputBasic)`
  width: 100px;

  @media (max-width: 320px) {
    width: 100px;
  }

  @media (min-width: 1920px) {
    width: 100px;
  }
`;

const ButtonAgree = styled(ButtonBasic)`
  width: 60px;
`;

const ButtonDisagree = styled(ButtonCancel)`
width: 60px;
`;

const Item = props => {
  const { overtimeRecord } = props;
  console.log(`TableLeaveApprovement Item.js JSON.stringify(overtimeRecord)=${JSON.stringify(overtimeRecord)}`);
  const { startTimeLocal, endTimeLocal } = overtimeRecord;
  const workingHoursTypeOptions = useSelector(state => state.srpOptions.workingHoursTypeOptions);

  const workingHoursType = getOptionDesc(workingHoursTypeOptions, overtimeRecord.typeID);

  const type = overtimeType => {
    if (overtimeType === '') return '';

    return overtimeType === 'freeTime'
      ? '補休'
      : '加班費';
  };

  const approvedMinutes = () => overtimeRecord.overtimeType === 'freeTime'
    ? overtimeRecord.freeTimeMins
    : overtimeRecord.extraPayMins;

  return (
    <TrWrapper>
      <Td120>{ overtimeRecord.username }</Td120>
      <Td120>{ overtimeRecord.leaveName }</Td120>
      <Td160>{ `${moment.parseZone(overtimeRecord.applyTime).format('YYYY-MM-DD HH:mm')}` }</Td160>
      <Td160>{ `${moment.parseZone(overtimeRecord.leaveFrom).format('YYYY-MM-DD HH:mm')}` }</Td160>
      <Td160>{ `${moment.parseZone(overtimeRecord.leaveTo).format('YYYY-MM-DD HH:mm')}` }</Td160>
      <Td120>{ overtimeRecord.description }</Td120>
      <Td120>{ Math.round(overtimeRecord.durationInSeconds / 3600 * 100) / 100 }</Td120>
      <Td160>
        <ButtonAgree
          onClick = { () => {
            props.openAttachmentListLeaveByApplicationId(overtimeRecord.id);
          }}
        >附件管理</ButtonAgree>
      </Td160>
      <Td160>
        <ButtonAgree
          onClick = { props.agreeHandler }
        >同意</ButtonAgree>
        &nbsp;&nbsp;
        <ButtonDisagree
          onClick = { props.disagreeHandler }
        >不同意</ButtonDisagree>
      </Td160>
    </TrWrapper>
  );
};

export default Item;
