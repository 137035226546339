import React from 'react';
import styled from 'styled-components';

import Checkbox from '@/components/UI/Checkbox';
import { TableHead, TableRow, ThItem } from '@/component-style/Table';

const THead = styled(TableHead)`
  min-width: ${ props => props.showCheckbox
    ? '1320px'
    : '1240px'
  };
`;

const Th80 = styled(ThItem)`
  width: 80px;
`;

const Th120 = styled(ThItem)`
  width: 120px;
`;

const Th160 = styled(ThItem)`
  width: 160px;
`;

const Th240 = styled(ThItem)`
  width: 240px;
`;

const Header = props => {
  
  return (
    <THead
      showCheckbox = { props.showCheckbox }
    >
      <TableRow>
        {
          props.showCheckbox &&
          <Th80>
            <Checkbox
              checked = { props.selectAll }
              changeHandler = { props.selectAllHandler }
            />
          </Th80>
        }
        <Th120>支憑流水號</Th120>
        <Th160>專案代號</Th160>
        <Th160>專案名稱</Th160>
        <Th120>申請人</Th120>
        <Th120>申請時間</Th120>
        <Th240>付款對象</Th240>
        <Th120>請款總額</Th120>
        <Th120>審核狀態</Th120>
        <Th80></Th80>
      </TableRow>
    </THead>
  );
};

export default Header;
