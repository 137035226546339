import Cookies from 'js-cookie';
import { put, call, takeEvery } from 'redux-saga/effects';

import {
  LOGIN_FAIL,
  LOGIN_START,
  LOGIN_SUCCESS,
} from '../actions/loginAction';

import {
  postLogin,
  postToGetUserInfo,
  postToGetSystemRoleDetail,
} from '@/api/authService';
import { errorMsgConverter } from '@/utils/textUtils';

function* login(action) {
  const { payload } = action;
  let projectRoutes = [];
  let expenseRoutes = [];
  let financeRoutes = [];
  let authorityRoutes = [];
  let sysConfigRoutes = [];
  let attendanceRoutes = [];
  let informationRoutes = [];
  let hrRoutes = [];

  try {
    yield put(LOGIN_START());
    const loginDetail = yield call(postLogin, payload);
    const { data } = yield call(postToGetUserInfo);
    // console.log("loginDetail:" + JSON.stringify(loginDetail.data));
    const { systemRole } = data;

    for (let i = 0; i < systemRole.length; i += 1) {
      const roleID = systemRole[i];
      const rolesDetail = yield call(postToGetSystemRoleDetail, roleID);
      if (rolesDetail.data.enable) {
        projectRoutes = [ ...projectRoutes, ...rolesDetail.data.projectRoutes ];
        expenseRoutes = [ ...expenseRoutes, ...rolesDetail.data.expenseRoutes ];
        financeRoutes = [ ...financeRoutes, ...rolesDetail.data.financeRoutes ];
        authorityRoutes = [ ...authorityRoutes, ...rolesDetail.data.authorityRoutes ];
        sysConfigRoutes = [ ...sysConfigRoutes, ...rolesDetail.data.sysConfigRoutes ];
        attendanceRoutes = [ ...attendanceRoutes, ...rolesDetail.data.attendanceRoutes ];
        informationRoutes = [ ...informationRoutes, ...rolesDetail.data.informationRoutes ];
        hrRoutes = [ ...hrRoutes, ...rolesDetail.data.hrRoutes ];
      }
    }

    yield put(LOGIN_SUCCESS({
      ...data,
      projectRoutes,
      expenseRoutes,
      financeRoutes,
      authorityRoutes,
      sysConfigRoutes,
      attendanceRoutes,
      informationRoutes,
      hrRoutes,
      needToChangePassword: loginDetail.data.needToChangePassword,
    }));

    Cookies.set('veda__srp-token', data.id);

  } catch (err) {
    const errorMsg = errorMsgConverter(err);
    yield put(LOGIN_FAIL(errorMsg));
  }
};

function* azureLoginSuccess() {
  let projectRoutes = [];
  let expenseRoutes = [];
  let financeRoutes = [];
  let authorityRoutes = [];
  let sysConfigRoutes = [];
  let attendanceRoutes = [];
  let informationRoutes = [];
  let hrRoutes = [];

  try {
    yield put(LOGIN_START());
    const { data } = yield call(postToGetUserInfo);
    const { systemRole } = data;

    for (let i = 0; i < systemRole.length; i += 1) {
      const roleID = systemRole[i];
      const rolesDetail = yield call(postToGetSystemRoleDetail, roleID);
      if (rolesDetail.data.enable) {
        projectRoutes = [ ...projectRoutes, ...rolesDetail.data.projectRoutes ];
        expenseRoutes = [ ...expenseRoutes, ...rolesDetail.data.expenseRoutes ];
        financeRoutes = [ ...financeRoutes, ...rolesDetail.data.financeRoutes ];
        authorityRoutes = [ ...authorityRoutes, ...rolesDetail.data.authorityRoutes ];
        sysConfigRoutes = [ ...sysConfigRoutes, ...rolesDetail.data.sysConfigRoutes ];
        attendanceRoutes = [ ...attendanceRoutes, ...rolesDetail.data.attendanceRoutes ];
        informationRoutes = [ ...informationRoutes, ...rolesDetail.data.informationRoutes ];
        hrRoutes = [ ...hrRoutes, ...rolesDetail.data.hrRoutes ];
      }
    }

    yield put(LOGIN_SUCCESS({
      ...data,
      projectRoutes,
      expenseRoutes,
      financeRoutes,
      authorityRoutes,
      sysConfigRoutes,
      attendanceRoutes,
      informationRoutes,
      hrRoutes,
    }));

    Cookies.set('veda__srp-token', data.id);

  } catch (err) {
    const errorMsg = errorMsgConverter(err);
    yield put(LOGIN_FAIL(errorMsg));
  }
};

export default function* loginSaga() {
  yield takeEvery('LOGIN', login);
  yield takeEvery('AZURE_LOGIN_SUCCESS', azureLoginSuccess);
};
