import React from 'react';
// import { useSelector } from 'react-redux';
import styled from 'styled-components';

import PanelWrapper from '@/components/UI/PanelWrapper';
import SelectNormal from '@/components/UI/Select/SelectNormal';
// import SelectSearchable from '@/components/UI/Select/SelectSearchable';
import SelectMultiple from '@/components/UI/Select/SelectMultiple';
import SearchButtonSet from '@/components/UI/SearchButtonSet';
import { ButtonBasic } from '@/component-style/Button';
import { PSubtitle } from '@/component-style/TextElement';
import {
  DivFlexRow,
  DivItemWrapper,
  // DivLongMultiSelector,
  DivLongMultiSelector,
  DivFullWidthWrapper
} from '@/component-style/RWDWrapper';

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding: 0;
  justify-content: space-between;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const ButtonExport = styled(ButtonBasic)`
  width: 120px;

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`;

const PanelRevenueByProjectSearch = props => {
  // const financeRoutes = useSelector(state => state.login.financeRoutes);
  const { config } = props;
  // console.log("PanelRevenueByProjectSearch props:" + JSON.stringify(props));
    // const userExportable = financeRoutes.indexOf('InvoiceSearch-Export') !== -1;

  return (
    <PanelWrapper title = "專案營收查詢">
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>所屬公司</PSubtitle>
          <SelectNormal
            value = { config.companyType}
            options = {[
              { value: '', desc: '請選擇所屬公司'},
              ...props.companyTypeOptions,
            ]}
            changeHandler = { value => {
              props.updateConfigHandler('companyType', value)
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivFullWidthWrapper>
          <PSubtitle>專案代號</PSubtitle>
          <DivLongMultiSelector>
            <SelectMultiple
              placeholder="請選擇專案"
              value = { config.projectID }
              defaultValue={[]}
              options = {[
                ...props.projectIDOptions,
              ]}
              changeHandler = { value => {
                props.updateConfigHandler('projectID', value);
              }}
            />
          </DivLongMultiSelector>
        </DivFullWidthWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivButtonsWrapper>
          <ButtonExport
            onClick = { props.exportHandler }
          >匯出</ButtonExport>
          <SearchButtonSet
            clearHandler = { props.clearHandler }
            searchHandler = { props.searchHandler }
          />
        </DivButtonsWrapper>
      </DivFlexRow>
    </PanelWrapper>
  );
};

export default PanelRevenueByProjectSearch;
