import React from 'react';
import styled from 'styled-components';

import SelectNormal from '@/components/UI/Select/SelectNormal';
import { ButtonCancel } from '@/component-style/Button';
import RemoveImg from '@/assets/images/icon_remove.svg';

const DivWrapper = styled.div`
  width: 100%;
  
  @media (min-width: 768px) {
    width: calc(100% - 135px);
  }
`;

const DivFWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const DivSelectorWrapper = styled.div`
  margin-right: 20px;
  margin-bottom: 10px;
  width: 300px;

  @media (max-width: 768px) {
    &:first-child {
      margin-bottom: 10px;
    }
  }

  @media (max-width: 320px) {
    width: 260px;
  }

  @media (min-width: 1920px) {
    .ant-select { width: 300px; }
  }
`;

const ButtonAdd = styled(ButtonCancel)`
  width: 150px;
`;

const DivIconRemove = styled.div`
  cursor: pointer;
  width: 20px;
  height: 40px;
  background-image: url(${ props => props.url });
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  @media (max-width: 375px) {
    background-position: top;
  }
`;

const VendorTypeList = props => {
  const { tags } = props;

  const addTypeHandler = () => {
    props.updateVendorTypesHandler([
      ...tags,
      { vendorTypeID: '', tag: '' },
    ]);
  };

  const removeHandler = index => {
    const filteredList = tags
      .filter((item, i) => i !== index);

    props.updateVendorTypesHandler(filteredList);
  };

  const updateTypeHandler = (index, value) => {
    const updateList = [ ...tags ]
    updateList[index] = { vendorTypeID: value, tag: '' };
    props.updateVendorTypesHandler(updateList);
  };

  const updateTagHandler = (index, value) => {
    const updateList = [ ...tags ]
    updateList[index].tag = value;
    props.updateVendorTypesHandler(updateList);
  };

  const vendorTagOptions = vendorTypeID => 
    props.vendorTagDictionary[vendorTypeID] === undefined
      ? []
      : props.vendorTagDictionary[vendorTypeID];

  return (
    <DivWrapper>
      {
        tags.map(({ vendorTypeID, tag }, index) =>
          <DivFWrapper key = { index }>
            <DivSelectorWrapper>
              <SelectNormal
                disabled = { props.disabled }
                value = { vendorTypeID }
                options = {[
                  { value: '', desc: '選擇大分類' },
                  ...props.vendorTypeOptions
                ]}
                changeHandler = { value => {
                  updateTypeHandler(index, value)
                }}
              />
            </DivSelectorWrapper>
            <DivSelectorWrapper>
              <SelectNormal
                disabled = { props.disabled }
                value = { tag }
                options = {[
                  { value: '', desc: '選擇小分類' },
                  ...vendorTagOptions(vendorTypeID)
                ]}
                changeHandler = { value => {
                  updateTagHandler(index, value)
                }}
              />
            </DivSelectorWrapper>
            <DivIconRemove
              url = { RemoveImg }
              onClick = { () => {
                !props.disabled && removeHandler(index);
              }}
            />
          </DivFWrapper>
        )
      }
      <ButtonAdd
        disabled = { props.disabled }
        onClick = { addTypeHandler }
      >新增分類
      </ButtonAdd>
    </DivWrapper>
  );
};

export default VendorTypeList;
