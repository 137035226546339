import React from 'react';
import { Tree } from 'antd';
import 'antd/dist/antd.css';
import './index.css';

const TreeUI = props => {

  return (
    <Tree
      disabled = { props.disabled }
      checkable = { true }
      selectable = { false }
      autoExpandParent = { true }
      defaultExpandAll = { props.defaultExpandAll }
      onCheck = { props.changeHandler }
      treeData = { props.treeData }
      checkedKeys = { props.value }
    />
  );
};

export default TreeUI;
