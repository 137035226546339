import React from 'react';
import styled from 'styled-components';

import { Table, TableBody } from '@/component-style/Table';
import { ButtonBasic } from '@/component-style/Button';
import PageSelector from '@/components/UI/PageSelector';
import Header from './Header';
import Item from './Item';

import { decimalCommaConverter } from '@/utils/textUtils';

const DivWrapper = styled.div`
  width: 100%;
  border-top: 2px solid #de2027;
  background-color: #ffffff;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
`;

const TitleWrapper = styled.div`
  margin-top: 35px;
  margin-bottom: 20px;
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const H3Title = styled.h3`
  margin: 0;
  font-size: 16px;
  line-height: 20px;
  font-weight: normal;
  color: #57585a;

  @media (max-width: 375px) { width: 120px }
`;

const ButtonDelete = styled(ButtonBasic)`
  width: 120px;
  height: 40px;
`;

const TableCashManagements = props => {
  const { expenses, totalAmount } = props;

  return (
    <DivWrapper>
      <TitleWrapper>
        <H3Title>付款總金額: { totalAmount == null ? '' : decimalCommaConverter(totalAmount) }</H3Title>
        <ButtonDelete
          disabled = { !props.summitable }
          onClick = { props.submitHandler }
        >確認請領</ButtonDelete>
      </TitleWrapper>
      <Table>
        <Header/>
        <TableBody>
          {
            expenses.map((expense, index) =>
              <Item
                key = { expense.expenseID }
                expense = { expense }
                checkHandler = { value => {
                  props.checkHandler(index, value);
                }}
                updatePredictTimeHandler = { value => {
                  props.updatePredictTimeHandler(index, value);
                }}
                cancelHandler = { () => {
                  props.cancelHandler(expense.expenseID);
                }}
              />
            )
          }
        </TableBody>
      </Table>
      <PageSelector
        current = { props.currentPage }
        total = { props.totalCount }
        pageChangedHandler = { props.pageChangedHandler }
      />
    </DivWrapper>
  );
};

export default TableCashManagements;