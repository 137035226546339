import React from 'react';
import { useSelector } from 'react-redux';

import TableWrapper  from '@/components/UI/TableWrapper';
import PageSelector from '@/components/UI/PageSelector';
import Header from './Header';
import Item from './Item';

import { Table, TableBody } from '@/component-style/Table';


const TableActivityIdeas = props => {
  const projectRoutes = useSelector(state => state.login.projectRoutes);
  const disableDetailPage = projectRoutes.indexOf('ActivityIdeaDetail') === -1;

  return (
    <TableWrapper title = "活動創意列表">
      <Table>
        <Header/>
        <TableBody>
          {
            props.activityIdeas.map(activityIdea =>
              <Item
                key = { activityIdea.id }
                activityIdea = { activityIdea }
                disableDetailPage = { disableDetailPage }
                goDetailHandler = { () => {
                  props.goDetailHandler(activityIdea.projectID, activityIdea.id)
                }}
              />
            )
          }
        </TableBody>
      </Table>
      <PageSelector
        current = { props.currentPage }
        total = { props.totalCount }
        pageChangedHandler = { props.pageChangedHandler }
      />
    </TableWrapper>
  );
};

export default TableActivityIdeas;
