import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { postToGetAttendances } from '@/api/attendanceService';

import MainContent from '@/component-style/MainContent';
import TableWrapper from '@/components/UI/TableWrapper';
import DateSelector from '@/components/UI/DateSelector';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import SearchButtonSet from '@/components/UI/SearchButtonSet';
import TablePunchTimes from '@/components/Table/Attendance/TablePunchTimes';
import { PSubtitle } from '@/component-style/TextElement';
import {
  DivFlexRow,
  DivItemWrapper,
} from '@/component-style/RWDWrapper';
import { formatTime } from '@/utils/timeUtils';

const DivTableContainer = styled.div`
  margin-bottom: 40px;
`;

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding: 0;
  padding-bottom: 30px;
  justify-content: flex-end;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const PunchTimeRecords = () => {
  const userID = useSelector(state => state.login.token);
  const username = useSelector(state => state.login.user);

  const [punchTimes, setPunchTimes] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [searchConfig, setSearchConfig] = useState({
    startDate: null,
    endDate: null,
    amount: 10,
  });

  const [showLoadingPopup, setShowLoadingPopup] = useState(false);

  const searchHandler = async () => {
    setCurrentPageHandler(1);
  };

  const setCurrentPageHandler = page => {
    setCurrentPage(page);
    updatePunchRecords(page);
  };

  const updatePunchRecords = async (page = currentPage) => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetAttendances({
        userIDs: [userID],
        ...searchConfig,
        page,
      });

      const { punchTimes, totalCount } = data;
      const records = punchTimes
        .map(punchTime => ({ username, ...punchTime }))
        .sort((a, b) => new Date(a.date) - new Date(b.date));
      
      setPunchTimes([ ...records ]);
      setTotalCount(totalCount);
    } catch(err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  return (
    <MainContent>
      <DivTableContainer>
        <TableWrapper title = "打卡記錄" >
          <DivFlexRow>
            <DivItemWrapper>
              <PSubtitle>
                起始日期
                <span>*</span>
              </PSubtitle>
              <DateSelector
                value = { searchConfig.startDate }
                changeHandler = { value => {
                  setSearchConfig(prevState => ({
                    ...prevState,
                    startDate: formatTime(value, 'YYYY-MM-DD'),
                  }))
                }}
              />
            </DivItemWrapper>
            <DivItemWrapper>
              <PSubtitle>
                結束時間
                <span>*</span>
              </PSubtitle>
              <DateSelector
                value = { searchConfig.endDate }
                changeHandler = { value => {
                  setSearchConfig(prevState => ({
                    ...prevState,
                    endDate: formatTime(value, 'YYYY-MM-DD'),
                  }))
                }}
              />
            </DivItemWrapper>
          </DivFlexRow>
          <DivFlexRow>
            <DivButtonsWrapper>
              <SearchButtonSet
                searchHandler = { searchHandler }
                clearHandler = { () => {
                  setSearchConfig({
                    startDate: null,
                    endDate: null,
                    amount: 10,
                  });
                }}
              />
            </DivButtonsWrapper>
          </DivFlexRow>
        </TableWrapper>
      </DivTableContainer>
      <TablePunchTimes
        punchTimes = { punchTimes }
        totalCount = { totalCount }
        currentPage = { currentPage }
        pageChangedHandler = { value => {
          setCurrentPageHandler(value);
        }}
      />
      { showLoadingPopup && <PopupLoading/> }
    </MainContent>
  )
};

export default PunchTimeRecords;
