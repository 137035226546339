import React, { useRef } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

import { Select } from 'antd';
import 'antd/dist/antd.css';
import '../index.css';

const DivWrapper = styled.div`
  position: relative;
`;

const IconWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #979797;
`;

const { Option } = Select; 

const SelectGray = props => {
  const selectEl = useRef(null);
  const options = props.options
    .map(option =>
      <Option
        key = { option.value }
        value = { option.value }
        disabled = { option.disabled }
      >
        { option.desc }
      </Option>
    )

  return (
    <DivWrapper>
      <Select
        style = {{
          backgroundColor: '#EEEEEE',
        }}
        ref = { selectEl }
        open = { props.open }
        value = { props.value }
        defaultValue = { props.default }
        onChange = { props.changeHandler }
        showArrow = { false }
        disabled = { props.disabled }
      >
        { options }
      </Select>
      <IconWrapper
        onClick = { () => { selectEl.focuse() } }
      >
        <FontAwesomeIcon
          size = "lg"
          icon = { faAngleDown }
        />
      </IconWrapper>
    </DivWrapper>
  );
};

export default SelectGray;
