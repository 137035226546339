import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

// import { postToGetUploadFiles } from '@/api/storageService';
// import { postToGetProjectQuotations } from '@/api/financeService';
import { postToGetProjectDetail } from '@/api/projectService';
import { postToGetActivityIdeas } from '@/api/activityIdeaService';
import {
  FETCH_VENDOR_ID_OPTIONS,
  FETCH_PROJECT_ID_OPTIONS,
  FETCH_ACTIVITY_IDEA_TYPE_OPTIONS,
} from '@/store/actions/srpOptionsAction';

import MainContent from '@/component-style/MainContent';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
// import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import PanelActivityIdeasSearch from '@/components/Panel/PanelActivityIdeasSearch';
import TableActivityIdeas from '@/components/Table/TableActivityIdeas';

// import { diffTime } from '@/utils/timeUtils';
import { integerConverter } from '@/utils/textUtils';
import { searchConfigGenerator } from '@/utils/apiRequestUtils';

const TableContainer = styled.div`
  margin-top: 40px;
`;

const ActivityIdeas = props => {
  const history = useHistory();
  const dispatch = useDispatch();
  const vendorIDs = useSelector(state => state.srpOptions.vendorIDOptions);
  const projectIDOptions = useSelector(state => state.srpOptions.projectIDOptions);
  const activityIdeaTypes = useSelector(state => state.srpOptions.activityIdeaTypeOptions);
  const activityIdeaTagDictionary = useSelector(state => state.srpOptions.activityIdeaTagDictionary);

  const [activityIdeas, setActivityIdeas] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  // const [alertText, setAlertText] = useState('');
  // const [showAlertPopup, setShowAlertPopup] = useState(false);
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);

  const [searchConfig, setSearchConfig] = useState({
    projectID: '',
    activityIdeaTypeID: '',
    tag: '',
    vendorID: '',
    minBudget: '',
    maxBudget: '',
    amount: 10,
  });

  const updateConfigHandler = (key, value) => {
    const updateObj = { ...searchConfig };
    updateObj[key] = value;
    setSearchConfig({ ...updateObj });
  };

  const activityIdeaTypeChangeHandler = value => {
    setSearchConfig(prevState => ({
      ...prevState,
      activityIdeaTypeID: value,
      tag: '',
    }));
  };

  const clearHandler = () => {
    setSearchConfig({
      projectID: '',
      activityIdeaTypeID: '',
      tag: '',
      vendorID: '',
      minBudget: '',
      maxBudget: '',
    });
  };

  const searchHandler = async () => {
    setCurrentPageHandler(1);
  };

  const setCurrentPageHandler = page => {
    setCurrentPage(page);
    updateActivityIdeas(page);
  };

  const goDetailHandler = async (projectID, activityIdeaId) => {
    setShowLoadingPopup(true);

    try {
      // const projectData = await postToGetProjectDetail(projectID);
      // const projectFinanceFiles = await postToGetUploadFiles('projectFinance', projectID);
      // const projectQuotations = await postToGetProjectQuotations({
      //   projectID, amount: 1, page: 1,
      // });

      // const unlocked = projectData.data.projectUnlock !== null
      //   && projectData.data.projectUnlock !== undefined
      //   && diffTime(projectData.data.projectUnlock, Date.now(), 'days') <= 1;

      // const notQuoted = projectQuotations.data.totalCount === undefined
      //   && diffTime(projectData.data.actionTimeEnd, Date.now(), 'days') < 8;

      // const noPO = projectFinanceFiles.data.length === 0
      //   && diffTime(projectData.data.actionTimeEnd, Date.now(), 'days') < 4;

      setShowLoadingPopup(false);

      // const isProjectLocked = !unlocked && (noPO || notQuoted);

      history.push(`/Project/ActivityIdeaDetail/${activityIdeaId}`)
      // if (isProjectLocked) {
      //   const alertText = `
      //     ${ noPO ? '無 PO 資料，' : '' }
      //     ${ notQuoted ? '無報價資料，' : '' }
      //     專案已被鎖定，請專案總監或總經理解鎖。
      //   `;
      //   setAlertText(alertText);
      //   setShowAlertPopup(true);
      // } else {
      //   history.push(`/Project/ActivityIdeaDetail/${activityIdeaId}`)
      // }
    } catch (err) {
      console.log(err);
      setShowLoadingPopup(false);
    }
  };

  const updateActivityIdeas = async (page = currentPage) => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetActivityIdeas(
        searchConfigGenerator({
          ...searchConfig,
          minBudget: integerConverter(searchConfig.minBudget),
          maxBudget: integerConverter(searchConfig.minBudget),
          page,
        })
      );

      const { activityIdeas, totalCount } = data;
      const activityIdeaList = activityIdeas
        .map(activityIdea => ({ ...activityIdea, isChecked: false }));

      for (let i = 0; i < activityIdeaList.length; i += 1) {
        const { projectID } = activityIdeaList[i];
        const projectInfo = await postToGetProjectDetail(projectID);
        activityIdeaList[i].projectCode = projectInfo.data.projectCode;
      }

      setActivityIdeas([...activityIdeaList]);
      setTotalCount(totalCount);
    } catch(err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const initActivityIdeas = useCallback(
    updateActivityIdeas, []);

  const initOptions = useCallback(() => {
    dispatch(FETCH_VENDOR_ID_OPTIONS());
    dispatch(FETCH_PROJECT_ID_OPTIONS());
    dispatch(FETCH_ACTIVITY_IDEA_TYPE_OPTIONS());
  }, [dispatch]);

  useEffect(() => {
    initOptions();
    initActivityIdeas();
  }, [initActivityIdeas, initOptions]);

  return (
    <MainContent>
      <PanelActivityIdeasSearch
        config = { searchConfig }
        vendorIDs = { vendorIDs }
        projectIDOptions = { projectIDOptions }
        activityIdeaTypes = { activityIdeaTypes }
        activityIdeaTagDictionary = { activityIdeaTagDictionary }
        clearHandler = { clearHandler }
        searchHandler = { searchHandler }
        updateConfigHandler = { updateConfigHandler }
        activityIdeaTypeChangeHandler = { activityIdeaTypeChangeHandler }
      />
      <TableContainer>
        <TableActivityIdeas
          activityIdeas = { activityIdeas }
          totalCount = { totalCount }
          currentPage = { currentPage }
          goDetailHandler = { goDetailHandler }
          pageChangedHandler = { value => {
            setCurrentPageHandler(value);
          }}
        />
      </TableContainer>
      { showLoadingPopup && <PopupLoading/> }
      {/* {
        showAlertPopup &&
          <PopupExecuteResult
            text = { alertText }
            confirmHandler = { () => { setShowAlertPopup(false) } }
          />
      } */}
    </MainContent>
  );
};

export default ActivityIdeas;
