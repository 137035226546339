import React from 'react';
// import { useSelector } from 'react-redux';
import styled from 'styled-components';

import PanelWrapper from '@/components/UI/PanelWrapper';
import { InputBasic } from '@/component-style/Input';
import SearchButtonSet from '@/components/UI/SearchButtonSet';
import { ButtonBasic } from '@/component-style/Button';
import { PSubtitle } from '@/component-style/TextElement';
import {
  DivFlexRow,
  DivItemWrapper,
} from '@/component-style/RWDWrapper';

import { Upload } from 'antd';

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding: 0;
  justify-content: space-between;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const ButtonDownload = styled(ButtonBasic)`
  margin-right: 10px;
  width: 120px;

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`;

const PanelSetSpecialLeaveHours = props => {
  // const financeRoutes = useSelector(state => state.login.financeRoutes);
  const { config } = props;
  console.log(`PanelAllLeaveSearch.js `);

  const uploadHandler = () => {
    console.log("uploadHandler");
  }

  const beforeUploadHandler = () => {
    return false;
  }

  return (
    <PanelWrapper title = "設定特休時數">
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>特休ID</PSubtitle>
          <InputBasic
            value = { props.specialLeaveId }
            onChange = { ({ target }) => {
              props.updateSpecialLeaveIdHandler('specialLeaveId', target.value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>上傳特休時數</PSubtitle>
          <InputBasic
            disabled = { true }
            value = { props.uploadFile }
          />
          &nbsp;&nbsp;&nbsp;
          <Upload
            multiple = { false }
            maxCount = { 1 }
            showUploadList = { false }
            onChange = { props.onEIPChangeHandler }
            action = { uploadHandler }
            beforeUpload = { beforeUploadHandler }
          >
            <ButtonDownload>上傳特休時數</ButtonDownload>
          </Upload>
        </DivItemWrapper>
      </DivFlexRow>
    </PanelWrapper>
  );
};

export default PanelSetSpecialLeaveHours;
