import axiosConfig from './axiosConfig';
import { searchConfigGenerator } from '@/utils/apiRequestUtils';
import { getDefaultPort } from '@/utils/apiRequestUtils';

const { baseURL } = axiosConfig.defaults;

const port = process.env.REACT_APP_SERVICE_PORT_CUSTOMER !== undefined
  ? process.env.REACT_APP_SERVICE_PORT_CUSTOMER
  : getDefaultPort(baseURL);

const customerModuleUrl = process.env.NODE_ENV === 'development'
  ? `${baseURL}:${port}/api`
  : `${baseURL}/customer/api`;

export const postToGetCustomerIndustry = config => axiosConfig
  .post(`${customerModuleUrl}/customer/industry`, config);

export const putNewCustomerIndustry = config => axiosConfig
  .put(`${customerModuleUrl}/customer/industry`, config);

export const patchModifyCustomerIndustry = config => axiosConfig
  .patch(`${customerModuleUrl}/customer/industry`, config);

export const deleteCustomerIndustry = ids => axiosConfig
  .delete(`${customerModuleUrl}/customer/industry`, { data: [...ids] });

export const postToGetCustomers = config => axiosConfig
  .post(`${customerModuleUrl}/customer`, searchConfigGenerator(config));

export const postToGetCustomerIDs = () => axiosConfig
  .post(`${customerModuleUrl}/customer/customerIDs`);

export const putNewCustomer = customer => axiosConfig
  .put(`${customerModuleUrl}/customer`, customer);

export const deleteCustomers = customers => axiosConfig
  .delete(`${customerModuleUrl}/customer`, { data: [ ...customers ] });

export const postToGetCustomerDetail = customerID => axiosConfig
  .post(`${customerModuleUrl}/customer/detail`,{ customerID });

export const putModifyCustomerDetail = customer => axiosConfig
  .put(`${customerModuleUrl}/customer/detail`, customer);

export const postToGetCustomerWindows = config => axiosConfig
  .post(`${customerModuleUrl}/customer/contactWindow`, config);

export const putNewCustomerWindow = contactWindow => axiosConfig
  .put(`${customerModuleUrl}/customer/contactWindow`, contactWindow);

export const deleteCustomerWindow = contactWindowID => axiosConfig
  .delete(`${customerModuleUrl}/customer/contactWindow`, { data: { contactWindowID } });

export const postToGetCustomerWindowList = () => axiosConfig
  .post(`${customerModuleUrl}/customer/contactWindowIDs`, { customerID: null });

export const postToGetCustomerWindowDetail = contactWindowID => axiosConfig
  .post(`${customerModuleUrl}/customer/contactWindow/detail`, { contactWindowID });

export const putModifyCustomerWindowDetail = contactWindow => axiosConfig
  .put(`${customerModuleUrl}/customer/contactWindow/detail`, contactWindow);
