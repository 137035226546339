export const userConfig = {
  account: '',
  password: '',
  code: '',
  name: '',
  hasCreditCard: false,
  showProject: false,
  department: '',
  director: '',
  email: '',
  phone: '',
  companyType: '',
  enable: true,
  projectRole: [],
  systemRole: [],
  azureID: '',
  basicSalary: 0,
  gender: 0,
  isEmployer: false,
};

export const roleConfig = {
  roleCode: '',
  roleName: '',
  enable: true,
  projectRoutes: [],
  informationRoutes: [],
  attendanceRoutes: [],
  expenseRoutes: [],
  financeRoutes: [],
  authorityRoutes: [],
  sysConfigRoutes: [],
  hrRoutes: [],
};

export const projectModuleTree = [{
  title: '專案管理',
  key: 'ProjectModule',
  children: [
    {
      title: '專案清單',
      key: 'ProjectsListPage',
      children: [
        { title: '檢視', key: 'ProjectsList' }
      ],
    },
    {
      title: '新增專案',
      key: 'ProjectCreationPage',
      children: [
        { title: '新增', key: 'ProjectCreation' }
      ],
    },
    {
      title: '專案資料',
      key: 'ProjectDetailPage',
      children: [
        { title: '檢視', key: 'ProjectDetail' },
        { title: '編輯', key: 'ProjectDetail-Edit' },
        { title: '結案', key: 'ProjectDetail-Close' },
        { title: '刪除', key: 'ProjectDetail-Delete' },
      ],
    },
    // {
    //   title: '專案進度',
    //   key: 'ProjectSchedulePage',
    //   children: [
    //     { title: '檢視', key: 'ProjectSchedule' },
    //     { title: '新增', key: 'ScheduleCreation' },
    //     { title: '匯出', key: 'ProjectSchedule-Export' },
    //   ],
    // },
    // {
    //   title: '專案進度資料',
    //   key: 'ScheduleDetailPage',
    //   children: [
    //     { title: '檢視', key: 'ScheduleDetail' },
    //     { title: '編輯', key: 'ScheduleDetail-Edit' },
    //     { title: '刪除', key: 'ScheduleDetail-Delete' },
    //   ],
    // },
    // {
    //   title: '專案進度匯入',
    //   key: 'ImportSchedulesPage',
    //   children: [
    //     { title: '匯入', key: 'ImportSchedules' }
    //   ],
    // },
    {
      title: '財務資料',
      key: 'ProjectFinancePage',
      children: [
        { title: '檢視', key: 'ProjectFinance' },
        { title: '編輯', key: 'ProjectFinance-Edit' },
        { title: '匯出', key: 'ProjectFinance-Export' },
        { title: '全數進項已結清', key: 'ProjectFinance-Input-Cleared' },
      ],
    },
    // {
    //   title: '專案解鎖',
    //   key: 'ProjectUnlockPage',
    //   children: [
    //     { title: '解鎖', key: 'ProjectUnlock' },
    //   ]
    // },
    {
      title: '取消專案結案',
      key: 'ProjectReopenPage',
      children: [
        { title: '取消結案', key: 'ProjectReopen' },
      ]
    },
    {
      title: '活動場地',
      key: 'VenueReservationsPage',
      children: [
        { title: '檢視', key: 'VenueReservations' },
        { title: '匯出', key: 'VenueReservations-Export' },
        { title: '新增', key: 'VenueReservationCreation' },
      ],
    },
    {
      title: '活動場地資料',
      key: 'VenueReservationDetailPage',
      children: [
        { title: '檢視', key: 'VenueReservationDetail' },
        { title: '編輯', key: 'VenueReservationDetail-Edit' },
        { title: '刪除', key: 'VenueReservationDetail-Delete' },
      ],
    },
    // {
    //   title: '活動創意',
    //   key: 'ActivityIdeasPage',
    //   children: [
    //     { title: '檢視', key: 'ActivityIdeas' },
    //     { title: '新增', key: 'ActivityIdeaCreation' },
    //   ],
    // },
    // {
    //   title: '活動創意資料',
    //   key: 'ActivityIdeaDetailPage',
    //   children: [
    //     { title: '檢視', key: 'ActivityIdeaDetail' },
    //     { title: '編輯', key: 'ActivityIdeaDetail-Edit' },
    //     { title: '刪除', key: 'ActivityIdeaDetail-Delete' },
    //     { title: '附件管理', key: 'ActivityIdeaDetail-Attachments' },
    //   ],
    // },
    {
      title: '媒體披露',
      key: 'MediaReportsPage',
      children: [
        { title: '檢視', key: 'MediaReports' },
        { title: '匯出', key: 'MediaReports-Export' },
        { title: '新增', key: 'MediaReportCreation' },
      ],
    },
    {
      title: '媒體披露資料',
      key: 'MediaReportDetailPage',
      children:[
        { title: '檢視', key: 'MediaReportDetail' },
        { title: '編輯', key: 'MediaReportDetail-Edit' },
        { title: '刪除', key: 'MediaReportDetail-Delete' },
      ],
    },
    {
      title: '媒體邀約',
      key: 'ReporterInvitationPage',
      children: [
        { title: '檢視', key: 'ReporterInvitation' },
        { title: '編輯', key: 'ReporterInvitation-Edit' },
        { title: '匯出', key: 'ReporterInvitation-Export' },
        { title: '訊息發送', key: 'ReporterInvitation-Massage' },
      ],
    },
    // {
    //   title: '同仁評鑑',
    //   key: 'MemberEvaluationPage',
    //   children: [
    //     { title: '檢視', key: 'MemberEvaluation' },
    //     { title: '編輯', key: 'MemberEvaluation-Edit' },
    //   ],
    // },
    // {
    //   title: '廠商評鑑',
    //   key: 'VendorEvaluationPage',
    //   children: [
    //     { title: '檢視', key: 'VendorEvaluation' },
    //     { title: '編輯', key: 'VendorEvaluation-Edit' },
    //   ],
    // },
  ],
}];

export const informationModuleTree = [{
  title: '資料管理',
  key: 'InformationModule',
  children: [
    {
      title: '客戶資料管理',
      key: 'CustomerManagementPage',
      children: [
        { title: '檢視', key: 'CustomerManagement' },
        { title: '新增', key: 'CustomerCreation' },
        { title: '刪除', key: 'CustomerManagement-Delete' },
        { title: '匯出', key: 'CustomerManagement-Export' },
      ],
    },
    {
      title: '客戶資料',
      key: 'CustomerDetailPage',
      children: [
        { title: '檢視', key: 'CustomerDetail' },
        { title: '編輯', key: 'CustomerDetail-Edit' },
        { title: '刪除', key: 'CustomerDetail-Delete' },
        { title: '檢視聯絡人', key: 'CustomerWindowDetail' },
        { title: '新增聯絡人', key: 'CustomerWindowCreation' },
        { title: '編輯聯絡人', key: 'CustomerWindowDetail-Edit' },
        { title: '刪除聯絡人', key: 'CustomerWindowDetail-Delete' },
      ],
    },
    {
      title: '廠商資料管理',
      key: 'VendorManagementPage',
      children: [
        { title: '檢視', key: 'VendorManagement' },
        { title: '新增', key: 'VendorCreation' },
        { title: '刪除', key: 'VendorManagement-Delete' },
        { title: '匯出', key: 'VendorManagement-Export' },
      ],
    },
    {
      title: '廠商資料',
      key: 'VendorDetailPage',
      children: [
        { title: '檢視', key: 'VendorDetail' },
        { title: '編輯', key: 'VendorDetail-Edit' },
        { title: '刪除', key: 'VendorDetail-Delete' },
        { title: '附件管理', key: 'VendorDetail-Attachments' },
        { title: '廠商紀錄', key: 'VendorRecords' },
        { title: '檢視聯絡人', key: 'VendorWindowDetail' },
        { title: '新增聯絡人', key: 'VendorWindowCreation' },
        { title: '編輯聯絡人', key: 'VendorWindowDetail-Edit' },
        { title: '刪除聯絡人', key: 'VendorWindowDetail-Delete' },
      ]
    },
    {
      title: '媒體資料管理',
      key: 'MediaManagementPage',
      children: [
        { title: '檢視', key: 'MediaManagement' },
        { title: '新增', key: 'MediaCreation' },
        { title: '刪除', key: 'MediaManagement-Delete' },
        { title: '匯出', key: 'MediaManagement-Export' },
      ],
    },
    {
      title: '媒體資料',
      key: 'MediaDetailPage',
      children: [
        { title: '檢視', key: 'MediaDetail' },
        { title: '編輯', key: 'MediaDetail-Edit' },
        { title: '刪除', key: 'MediaDetail-Delete' },
        { title: '附件管理', key: 'MediaDetail-Attachments' },
      ],
    },
    {
      title: '記者資料管理',
      key: 'JournalistManagementPage',
      children: [
        { title: '檢視', key: 'JournalistManagement' },
        { title: '新增', key: 'JournalistCreation' },
        { title: '刪除', key: 'JournalistManagement-Delete' },
        { title: '匯出', key: 'JournalistManagement-Export' },
      ],
    },
    {
      title: '記者資料',
      key: 'JournalistDetailPage',
      children: [
        { title: '檢視', key: 'JournalistDetail' },
        { title: '編輯', key: 'JournalistDetail-Edit' },
        { title: '刪除', key: 'JournalistDetail-Delete' },
        { title: '附件管理', key: 'JournalistDetail-Attachments' },
        { title: '記者紀錄', key: 'JournalistRecords' },
      ],
    },
    {
      title: '活動場地管理',
      key: 'VenueManagementPage',
      children: [
        { title: '檢視', key: 'VenueManagement' },
        { title: '新增', key: 'VenueCreation' },
      ],
    },
    {
      title: '活動場地資料',
      key: 'VenueDetailPage',
      children: [
        { title: '檢視', key: 'VenueDetail' },
        { title: '編輯', key: 'VenueDetail-Edit' },
        { title: '刪除', key: 'VenueDetail-Delete' },
        { title: '附件管理', key: 'VenueDetail-Attachments' },
      ]
    },
  ],
}];

export const attendanceModuleTree = [{
  title: '同仁差勤管理',
  key: 'AttendanceModule',
  children: [
    { title: '打卡', key: 'PunchTimeCreation' },
    { title: '補打卡', key: 'PunchTimeBackup' },
    { title: '打卡記錄', key: 'PunchTimeRecords' },
    {
      title: '打卡管理',
      key: 'PunchTimeManagementPage',
      children: [
        { title: '檢視', key: 'PunchTimeManagement' },
        { title: '匯出', key: 'PunchTimeManagement-Export' },
      ],
    },
    { title: '打卡資料', key: 'PunchTimeDetail' },
    { title: '工時填寫', key: 'WorkingHoursCreation' },
    {
      title: '工時總表',
      key: 'WorkingHoursListPage',
      children: [
        { title: '檢視', key: 'WorkingHoursList' },
        { title: '匯出', key: 'WorkingHoursList-Export' },
      ],
    },
    { title: '工時資料', key: 'WorkingHoursDetail' },
    { title: '加班申請', key: 'OvertimeApplication' },
    {
      title: '加班總表',
      key: 'OvertimesListPage',
      children: [
        { title: '檢視', key: 'OvertimesList' },
        { title: '匯出', key: 'OvertimesList-Export' },
      ],
    },
    { title: '加班審核', key: 'OvertimeApprovement' },
    { title: '加班資料', key: 'OvertimeDetail' },
    {
      title: '加班統計',
      key: 'OvertimeByPersonnel',
      children: [
        { title: '檢視', key: 'OvertimeByPersonnelDetail' },
      ],
    },
    { title: '休假申請', key: 'LeaveApplication' },
    { title: '休假資料', key: 'LeaveDetail' },
    { title: '休假審核', key: 'LeaveApprovement' },
    { title: '休假總表', key: 'LeaveList' },
    // { title: '個人薪資', key: 'PersonalSalary' },
  ],
}];

export const expenseModuleTree = [{
  title: '費用報支',
  key: 'ExpenseModule',
  children: [
    {
      title: '廠商費用申請',
      key: 'ExpenseQuotationsPage',
      children: [
        { title: '檢視', key: 'ExpenseQuotations' },
        { title: '新增', key: 'ExpenseQuotationCreation' },
        { title: '結轉', key: 'ExpenseQuotations-Export' },
      ],
    },
    {
      title: '廠商費用申請資料',
      key: 'ExpenseQuotationDetailPage',
      children: [
        { title: '檢視', key: 'ExpenseQuotationDetail' },
        { title: '編輯', key: 'ExpenseQuotationDetail-Edit' },
        { title: '刪除', key: 'ExpenseQuotationDetail-Delete' },
        { title: '附件管理', key: 'ExpenseQuotationDetail-Attachments' },
        { title: '憑證管理', key: 'ExpenseQuotationDetail-License' },
      ],
    },
    {
      title: '新增支出憑單',
      key: 'ExpenseFormCreationPage',
      children: [
        { title: '新增', key: 'ExpenseFormCreation' },
      ],
    },
    {
      title: '支出憑單總表',
      key: 'ExpenseFormsPage',
      children: [
        { title: '檢視', key: 'ExpenseForms' },
        { title: '匯出', key: 'ExpenseForms-Export' },
      ]
    },
    {
      title: '支出憑單資料',
      key: 'ExpenseFormDetailPage',
      children: [
        { title: '檢視', key: 'ExpenseFormDetail' },
      ],
    },
    {
      title: '支出憑單審核',
      key: 'ExpenseApprovalPage',
      children: [
        { title: '檢視', key: 'ExpenseApproval' },
        { title: '審核', key: 'ExpenseApproval-Approve' },
      ],
    },
    {
      title: '支憑審核資料',
      key: 'ExpenseApprovalDetailPage',
      children: [
        { title: '檢視', key: 'ExpenseApprovalDetail' },
      ],
    },
    {
      title: '支出憑單代審',
      key: 'ExpenseProxyApprovalPage',
      children: [
        { title: '代審', key: 'ExpenseProxyApproval' },
      ]
    },
  ],
}];

export const financeModuleTree = [{
  title: '財務資料管理',
  key: 'FinanceModule',
  children: [
    {
      title: '專案財務資料',
      key: 'ProjectFinanceInfoPage',
      children: [
        { title: '檢視', key: 'ProjectFinanceInfo' },
        { title: '編輯', key: 'ProjectFinanceInfo-Edit' },
        { title: '全數銷項已入帳', key: 'ProjectFinanceInfo-Output-Cleared' },
      ],
    },
    {
      title: '會計審核作業',
      key: 'FinanceApprovalsPage',
      children: [
        { title: '檢視', key: 'FinanceApprovals' },
        { title: '匯出', key: 'FinanceApprovals-Export' },
      ],
    },
    {
      title: '支憑審核資料',
      key: 'FinanceApprovalDetailPage',
      children: [
        { title: '檢視', key: 'FinanceApprovalDetail' },
        { title: '審核', key: 'FinanceApprovalDetail-Approve' },
      ],
    },
    {
      title: '支出憑單管理',
      key: 'FinanceManagementPage',
      children: [
        { title: '檢視', key: 'FinanceManagement' },
        { title: '匯出', key: 'FinanceManagement-Export' },
        { title: '財務作業', key: 'FinanceManagement-Action' },
      ],
    },
    {
      title: '支憑管理資料',
      key: 'FinanceManagementDetailPage',
      children: [
        { title: '檢視', key: 'FinanceManagementDetail' },
        { title: '編輯', key: 'FinanceManagementDetail-Edit' },
      ],
    },
    {
      title: '現領零用金作業',
      key: 'CashExpenseManagementPage',
      children: [
        { title: '檢視', key: 'CashExpenseManagement' },
        { title: '請領作業', key: 'CashExpenseManagement-Approve' },
      ],
    },
    {
      title: '代墊款支領報表',
      key: 'CashExpenseListPage',
      children: [
        { title: '檢視', key: 'CashExpenseList' },
        { title: '匯出', key: 'CashExpenseList-Export' },
      ],
    },
    {
      title: '發票查詢',
      key: 'InvoiceSearchPage',
      children: [
        { title: '檢視', key: 'InvoiceSearch' },
        { title: '匯出', key: 'InvoiceSearch-Export' },
      ],
    },
    {
      title: '營收搜尋',
      key: 'DirectorRevenuePage',
      children: [
        { title: '檢視', key: 'DirectorRevenue' },
        { title: '匯出', key: 'DirectorRevenue-Export' },
      ],
    },
    {
      title: '營收圖表',
      key: 'RevenueSearchPage',
      children: [
        { title: '檢視', key: 'RevenueSearch' },
      ],
    },
    // {
    //   title: '採購議價',
    //   key: 'PurchaseBargainPage',
    //   children: [
    //     { title: '檢視', key: 'PurchaseBargain' },
    //     { title: '議價', key: 'PurchaseBargain-Approve' },
    //   ],
    // },
    // {
    //   title: '採購解鎖',
    //   key: 'UnlockPurchasePage',
    //   children: [
    //     { title: '檢視', key: 'UnlockPurchase' },
    //     { title: '解鎖', key: 'UnlockPurchase-Unlock' },
    //   ],
    // },
  ]
}];

export const authorityModuleTree = [{
  title: '權限管理',
  key: 'AuthorityModule',
  children: [
    {
      title: '使用者管理',
      key: 'UserManagementPage',
      children: [
        { title: '檢視', key: 'UserManagement' },
        { title: '新增', key: 'UserCreation' },
      ],
    },
    {
      title: '使用者資料',
      key: 'UserDetailPage',
      children: [
        { title: '檢視', key: 'UserDetail' },
        { title: '編輯', key: 'UserDetail-Edit' },
        { title: '刪除', key: 'UserDetail-Delete' },
      ],
    },
    {
      title: '系統角色管理',
      key: 'RoleManagementPage',
      children: [
        { title: '檢視', key: 'RoleManagement' },
        { title: '新增', key: 'RoleCreation' },
      ],
    },
    {
      title: '系統角色資料',
      key: 'RoleDetailPage',
      children: [
        { title: '檢視', key: 'RoleDetail' },
        { title: '編輯', key: 'RoleDetail-Edit' },
        { title: '刪除', key: 'RoleDetail-Delete' },
      ],
    },
    {
      title: '使用者薪資資料',
      key: 'UserDetailSalaryPage',
      children: [
        { title: '檢視', key: 'UserDetailSalary' },
        { title: '編輯', key: 'UserDetailSalary-Edit' },
        { title: '刪除', key: 'UserDetailSalary-Delete' },
      ],
    },
  ],
}];

export const sysConfigModuleTree = [{
  title: '系統參數管理',
  key: 'SysConfigModule',
  children: [
    {
      title: '會計科目管理',
      key: 'ExpenseTypeManagementPage',
      children: [
        { title: '檢視', key: 'ExpenseTypeManagement' },
        { title: '新增', key: 'ExpenseTypeManagement-Add' },
        { title: '編輯', key: 'ExpenseTypeManagement-Edit' },
        { title: '刪除', key: 'ExpenseTypeManagement-Delete' },
      ],
    },
    {
      title: '會計中分類列表',
      key: 'ExpenseTagManagementPage',
      children: [
        { title: '檢視', key: 'ExpenseTagManagement' },
        { title: '新增', key: 'ExpenseTagManagement-Add' },
        { title: '編輯', key: 'ExpenseTagManagement-Edit' },
        { title: '刪除', key: 'ExpenseTagManagement-Delete' },
      ],
    },
    {
      title: '會計參數管理',
      key: 'ExpenseParamsPage',
      children: [
        { title: '檢視', key: 'ExpenseParams' },
        { title: '編輯', key: 'ExpenseParams-Edit' },
      ],
    },
    {
      title: '廠商科目管理',
      key: 'VendorTypeManagementPage',
      children: [
        { title: '檢視', key: 'VendorTypeManagement' },
        { title: '新增', key: 'VendorTypeManagement-Add' },
        { title: '編輯', key: 'VendorTypeManagement-Edit' },
        { title: '刪除', key: 'VendorTypeManagement-Delete' },
      ],
    },
    {
      title: '廠商中分類列表',
      key: 'VendorTagManagementPage',
      children: [
        { title: '檢視', key: 'VendorTagManagement' },
        { title: '新增', key: 'VendorTagManagement-Add' },
        { title: '編輯', key: 'VendorTagManagement-Edit' },
        { title: '刪除', key: 'VendorTagManagement-Delete' },
      ],
    },
    {
      title: '系統參數管理',
      key: 'SystemParamsPage',
      children: [
        { title: '檢視', key: 'SystemParams' },
        { title: '編輯', key: 'SystemParams-Edit' },
      ],
    },
    {
      title: '共用參數管理',
      key: 'CommonTypeManagementPage',
      children: [
        { title: '檢視', key: 'CommonTypeManagement' },
        { title: '新增', key: 'CommonTypeManagement-Add' },
        { title: '編輯', key: 'CommonTypeManagement-Edit' },
        { title: '刪除', key: 'CommonTypeManagement-Delete' },
      ],
    },
    {
      title: '工時分類管理',
      key: 'WorkingHoursTypeManagementPage',
      children: [
        { title: '檢視', key: 'WorkingHoursTypeManagement' },
        { title: '新增', key: 'WorkingHoursTypeManagement-Add' },
        { title: '編輯', key: 'WorkingHoursTypeManagement-Edit' },
        { title: '刪除', key: 'WorkingHoursTypeManagement-Delete' },
      ],
    },
    {
      title: '工時細項列表',
      key: 'WorkingHoursTagManagementPage',
      children: [
        { title: '檢視', key: 'WorkingHoursTagManagement' },
        { title: '新增', key: 'WorkingHoursTagManagement-Add' },
        { title: '編輯', key: 'WorkingHoursTagManagement-Edit' },
        { title: '刪除', key: 'WorkingHoursTagManagement-Delete' },
      ],
    },
    {
      title: '進度分類管理',
      key: 'TodoTypeManagementPage',
      children: [
        { title: '檢視', key: 'TodoTypeManagement' },
        { title: '新增', key: 'TodoTypeManagement-Add' },
        { title: '編輯', key: 'TodoTypeManagement-Edit' },
        { title: '刪除', key: 'TodoTypeManagement-Delete' },
      ],
    },
    {
      title: '進度細項列表',
      key: 'TodoTagManagementPage',
      children: [
        { title: '檢視', key: 'TodoTagManagement' },
        { title: '新增', key: 'TodoTagManagement-Add' },
        { title: '編輯', key: 'TodoTagManagement-Edit' },
        { title: '刪除', key: 'TodoTagManagement-Delete' },
      ],
    },
    {
      title: '活動創意分類管理',
      key: 'ActivityIdeaTypeManagementPage',
      children: [
        { title: '檢視', key: 'ActivityIdeaTypeManagement' },
        { title: '新增', key: 'ActivityIdeaTypeManagement-Add' },
        { title: '編輯', key: 'ActivityIdeaTypeManagement-Edit' },
        { title: '刪除', key: 'ActivityIdeaTypeManagement-Delete' },
      ],
    },
    {
      title: '活動創意細項列表',
      key: 'ActivityIdeaTagManagementPage',
      children: [
        { title: '檢視', key: 'ActivityIdeaTagManagement' },
        { title: '新增', key: 'ActivityIdeaTagManagement-Add' },
        { title: '編輯', key: 'ActivityIdeaTagManagement-Edit' },
        { title: '刪除', key: 'ActivityIdeaTagManagement-Delete' },
      ],
    },
    {
      title: '活動場地參數管理',
      key: 'VenueTypeManagementPage',
      children: [
        { title: '檢視', key: 'VenueTypeManagement' },
        { title: '新增', key: 'VenueTypeManagement-Add' },
        { title: '編輯', key: 'VenueTypeManagement-Edit' },
        { title: '刪除', key: 'VenueTypeManagement-Delete' },
      ],
    },
    {
      title: '工時參數管理',
      key: 'WorkingHoursParamsPage',
      children: [
        { title: '檢視', key: 'WorkingHoursParams' },
        { title: '編輯', key: 'WorkingHoursParams-Edit' },
      ],
    },
    {
      title: '假日參數管理',
      key: 'HolidayManagementPage',
      children: [
        { title: '檢視', key: 'HolidayManagement' },
        { title: '新增', key: 'HolidayManagement-Add' },
        { title: '編輯', key: 'HolidayManagement-Edit' },
      ],
    },
    {
      title: '勞、健保參數管理',
      key: 'LaborHealthInsuranceManagementPage',
      children: [
        { title: '檢視', key: 'LaborHealthInsuranceManagement' },
        { title: '新增', key: 'LaborHealthInsuranceManagement-Add' },
        { title: '編輯', key: 'LaborHealthInsuranceManagement-Edit' },
      ],
    },
    {
      title: '假別參數管理',
      key: 'LeaveManagementPage',
      children: [
        { title: '檢視', key: 'LeaveManagement' },
        { title: '新增', key: 'LeaveManagement-Add' },
        { title: '編輯', key: 'LeaveManagement-Edit' },
      ],
    },
    // {
    //   title: '獎金管理',
    //   key: 'ExtraPaymentsManagementPage',
    //   children: [
    //     { title: '檢視', key: 'ExtraPaymentsManagement' },
    //     { title: '新增', key: 'ExtraPaymentsManagement-Add' },
    //     { title: '編輯', key: 'ExtraPaymentsManagement-Edit' },
    //   ],
    // },
  ],
}];

export const hrModuleTree = [{
  title: '人事資料管理',
  key: 'HRModule',
  children: [
    {
      title: '專案成本',
      key: 'ProjectCostPage',
      children: [
        { title: '編輯', key: 'ProjectCost' },
      ],
    },
    {
      title: '同仁薪資計算',
      key: 'WageStructureManagementPage',
      children: [
        { title: '編輯', key: 'WageStructureManagement' },
      ],
    },
    {
      title: '同仁月薪資',
      key: 'PersonnelWagePage',
      children: [
        { title: '編輯', key: 'PersonnelWage' },
      ],
    },
    {
      title: '同仁休假管理',
      key: 'PersonnelCompensatedLeaveManagementPage',
      children: [
        { title: '編輯', key: 'PersonnelCompensatedLeaveManagement' },
      ],
    },
  ],
}];

export const userHistoryConfig = {
  isFullTime: 0,
  onboardDate: '',
  offboardDate: '',
};