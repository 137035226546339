import React from 'react';
// import { useSelector } from 'react-redux';
import styled from 'styled-components';

import PanelWrapper from '@/components/UI/PanelWrapper';
import SelectNormal from '@/components/UI/Select/SelectNormal';
import DateSelectorTwo from '@/components/UI/DateSelectorTwo';
import SearchButtonSet from '@/components/UI/SearchButtonSet';
import { ButtonBasic } from '@/component-style/Button';
import { PSubtitle } from '@/component-style/TextElement';
import {
  DivFlexRow,
  DivItemWrapper,
  DivFullWidthWrapper,
  DivLongMultiSelector,
} from '@/component-style/RWDWrapper';
import RadioGroup from '@/components/UI/Radio/RadioGroup';
import SelectMultiple from '@/components/UI/Select/SelectMultiple';
import moment from 'moment';

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding: 0;
  justify-content: space-between;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const ButtonExport = styled(ButtonBasic)`
  width: 120px;

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`;

const PanelRevenueByCustomerSearch = props => {
  const { config } = props;

  const dateStartChangeHandler = value => {
    props.updateConfigHandler('date', {
      ...config.date,
      start: moment(value).startOf('month').format('YYYY-MM-DD'),
    });
  };

  const dateEndChangeHandler = value => {
    props.updateConfigHandler('date', {
      ...config.date,
      end: moment(value).endOf('month').format('YYYY-MM-') + moment(value).daysInMonth(),
    });
  };

  return (
    <PanelWrapper title = "客戶營收查詢">
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>所屬公司</PSubtitle>
          <SelectNormal
            value = { config.companyType}
            options = {[
              { value: '', desc: '請選擇所屬公司'},
              ...props.companyTypeOptions,
            ]}
            changeHandler = { value => {
              props.updateConfigHandler('companyType', value)
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivFullWidthWrapper>
          <PSubtitle>客戶名稱</PSubtitle>
          <DivLongMultiSelector>
            <SelectMultiple
              placeholder="請選擇客戶"
              defaultValue={ [] }
              value = { config.customerID }
              options = {[
                ...props.customerOptions,
              ]}
              changeHandler = { value => {
                props.updateConfigHandler('customerID', value)
              }}
            />
          </DivLongMultiSelector>
        </DivFullWidthWrapper>
      </DivFlexRow>
        {/* <DivItemWrapper>
          <PSubtitle>客戶名稱</PSubtitle>
          <SelectNormal
            value = { config.customerID}
            options = {[
              { value: '', desc: '請選擇客戶'},
              ...props.customerOptions,
            ]}
            changeHandler = { value => {
              props.updateConfigHandler('customerID', value)
            }}
          />
        </DivItemWrapper>
      </DivFlexRow> */}
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>類型</PSubtitle>
          <RadioGroup
            value = { config.resultType }
            options = {[
              { value: 1, desc: '報表' },
              { value: 2, desc: '圖表' },
            ]}
            changeHandler = { value => {
              props.updateConfigHandler('resultType', value)
            }}
          />
        </DivItemWrapper>
        { config.resultType === 2 &&
          <DivItemWrapper>
            <PSubtitle>圖表名稱</PSubtitle>
            <SelectNormal
              value = { config.chartType}
              options = {[
                { value: '', desc: '請選擇圖表'},
                { value: 'revenue', desc: '各客戶營收比較長條圖'},
                { value: 'grossProfit', desc: '各客戶毛利比較長條圖'},
                { value: 'grossProfitPercentage', desc: '各客戶毛利佔比圓餅圖'},
                { value: 'unitGrossProfit', desc: '各客戶單位毛利比較長條圖'},
              ]}
              changeHandler = { value => {
                props.updateConfigHandler('chartType', value)
              }}
            />
          </DivItemWrapper>
        }
      </DivFlexRow>
      <DivFlexRow>
        { config.resultType === 2 && config.chartType !== "grossProfitPercentage" && 
          <DivItemWrapper>
            <PSubtitle>統計區間</PSubtitle>
            <SelectNormal
              value = { config.statisticInterval}
              options = { config.statisticIntervalOptions }
              changeHandler = { value => {
                props.updateConfigHandler('statisticInterval', value)
              }}
            />
            </DivItemWrapper>
        }
        <DivItemWrapper>
          <PSubtitle>發票開立起迄</PSubtitle>
          <DateSelectorTwo
            value = { config.date.start  }
            picker = "month"
            changeHandler = { dateStartChangeHandler }
          />
          &nbsp;&nbsp;
          <DateSelectorTwo
            value = { config.date.end  }
            picker = "month"
            changeHandler = { dateEndChangeHandler }
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivButtonsWrapper>
          <ButtonExport
            onClick = { props.exportHandler }
          >匯出</ButtonExport>
          <SearchButtonSet
            clearHandler = { props.clearHandler }
            searchHandler = { props.searchHandler }
          />
        </DivButtonsWrapper>
      </DivFlexRow>
    </PanelWrapper>
  );
};

export default PanelRevenueByCustomerSearch;
