import React from 'react';
import styled from 'styled-components';

import NavigationItem from '../NavigationItem';
import NavigationCategory from '../NavigationCategory';
import IconManagement from '@/assets/images/icon_basic_manage.svg';

const UlWrapper = styled.ul`
  margin: 0;
  padding: 0;
`;

const DivIcon = styled.span`
  display: inline-block;
  margin-left: 50px;
  width: 24px;
  height: 24px;
  background-image: url(${props => props.url});
`;

const informationRoutes = [
  {
    title: '客戶資料管理',
    key: 'CustomerManagement',
    url: '/Information/CustomerManagement',
  },
  {
    title: '廠商資料管理',
    key: 'VendorManagement',
    url: '/Information/VendorManagement',
  },
  {
    title: '媒體資料管理',
    key: 'MediaManagement',
    url: '/Information/MediaManagement',
  },
  {
    title: '記者資料管理',
    key: 'JournalistManagement',
    url: '/Information/JournalistManagement',
  },
  {
    title: '活動場地管理',
    key: 'VenueManagement',
    url: '/Information/VenueManagement',
  },
];

const NavigationListInformation = props => {
  const { validRoutes } = props;

  const routeItems = informationRoutes
    .filter(({ key }) => validRoutes.indexOf(key) !== -1)
    .map(({ title, url }) => (
      <NavigationItem
        key = { url }
        url = { url }
        title = { title }
      />
    ));

  return (
    <UlWrapper>
      <NavigationCategory
        title = "專案資料管理"
        opened = { props.opened }
        clickHandler = { () => { props.toogleList() }}
      >
        <DivIcon url = { IconManagement }/>
      </NavigationCategory>
      { props.opened && routeItems }
    </UlWrapper>
  );
};

export default NavigationListInformation;
