import * as types from '../action-types/apiFailPopupActionTypes';
import { errorMsgConverter } from '@/utils/textUtils';

const initState = {
  showSaveFailPopup: false,
  title: '',
  message: '',
};

const apiFailPopupReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.OPEN_SAVE_FAIL_POPUP:
      return {
        ...initState,
        showSaveFailPopup: true,
        title: payload.title,
        message: errorMsgConverter(payload.err),
      }
    case types.CLOSE_SAVE_FAIL_POPUP:
      return {
        ...initState,
        showSaveFailPopup: false,
      }
    default:
      return state;
  };
};

export default apiFailPopupReducer;
