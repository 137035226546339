import React from 'react';
import styled from 'styled-components';

import { TdItem, TableRow } from '@/component-style/Table';
import { ButtonGoDetail } from '@/component-style/Button';
import { getPhoneString } from '@/utils/textUtils';

const TrWrapper = styled(TableRow)`
  border-bottom: solid 2px #f2f2f2;
  box-sizing: border-box;
  min-width: 1160px;

  &:last-child {
    border-bottom: none;
  }
`;

const Td80 = styled(TdItem)`
  width: 80px;
`;

const Td120 = styled(TdItem)`
  width: 120px;
`;
const Td160 = styled(TdItem)`
  width: 160px;
`;

const Item = props => {
  const { contactWindow } = props;

  const name = contactWindow.contactWindowName
    ? contactWindow.contactWindowName : '';
  
  const englishName = contactWindow.englishName
    ? contactWindow.englishName : '';

  const department = contactWindow.department
    ? contactWindow.department : '';

  const title = contactWindow.title
    ? contactWindow.title : '';

  const businessMobile = contactWindow.businessMobile
    ? contactWindow.businessMobile : '';
  
  const privateMobile = contactWindow.privateMobile
    ? contactWindow.privateMobile : '';

  const email = contactWindow.email
    ? contactWindow.email : '';

  const employed = contactWindow.employed === true
    ? "是" : '否';
  

  return (
    <TrWrapper>
      <Td80>{ name }</Td80>
      <Td120>{ englishName }</Td120>
      <Td160>{ department }</Td160>
      <Td160>{ title }</Td160>
      <Td160>{ getPhoneString(contactWindow.businessPhone) }</Td160>
      <Td120>{ businessMobile }</Td120>
      <Td120>{ privateMobile }</Td120>
      <Td160>{ email }</Td160>
      <Td120>{ employed }</Td120>
      <Td80>
        <ButtonGoDetail
          disabled = { props.disableDetailPage }
          onClick = { props.goDetailHandler }
        >...
        </ButtonGoDetail>
      </Td80>
    </TrWrapper>
  );
};

export default Item;
