import React from 'react';
import styled from 'styled-components';

import { TdItem, TableRow } from '@/component-style/Table';
import { ButtonGoDetail } from '@/component-style/Button';

import { getAddressString } from '@/utils/textUtils';

const TrWrapper = styled(TableRow)`
  border-bottom: solid 2px #f2f2f2;
  box-sizing: border-box;
  min-width: 960px;

  &:last-child {
    border-bottom: none;
  }
`;

const Td120 = styled(TdItem)`
  width: 120px;
`;

const Td80 = styled(TdItem)`
  width: 80px;
`;

const Td160 = styled(TdItem)`
  width: 160px;
`;

const Td360 = styled(TdItem)`
  width: 360px;
`;

const Item = props => {
  const { venue } = props;

  return (
    <TrWrapper>
      <Td160>{ venue.venueName }</Td160>
      <Td120>{ venue.roomName }</Td120>
      <Td120>{ venue.size }</Td120>
      <Td120>{ venue.totalCapacity }</Td120>
      <Td360>{ getAddressString(venue.address) }</Td360>
      <Td80>
        <ButtonGoDetail
          disabled = { props.disableDetailPage }
          onClick = { props.goDetailHandler }
        >...
        </ButtonGoDetail>
      </Td80>
    </TrWrapper>
  );
};

export default Item;
