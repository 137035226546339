import React from 'react';

import TableWrapper from '@/components/UI/TableWrapper';
import DateSelector from '@/components/UI/DateSelector';
import SelectNormal from '@/components/UI/Select/SelectNormal';
import SelectSearchable from '@/components/UI/Select/SelectSearchable';
import { InputBasic } from '@/component-style/Input';
import { PSubtitle } from '@/component-style/TextElement';
import {
  DivFlexRow,
  DivItemWrapper,
} from '@/component-style/RWDWrapper';
import { formatTime } from '@/utils/timeUtils';

const PanelMediaReportDetail = props => {
  const { mediaReport} = props;

  return(
    <TableWrapper title = { props.title }>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>
            專案代號
            <span>*</span>
          </PSubtitle>
          <SelectSearchable
            disabled = { props.disabled }
            value = { mediaReport.projectID }
            options = {[
              { value: '', desc: '請選擇專案代號' },
              ...props.projectIDOptions
            ]}
            changeHandler = { value => {
              props.updateMediaReportHandler('projectID', value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>報導日期</PSubtitle>
          <DateSelector
            disabled = { props.disabled }
            value = { mediaReport.publishDate }
            changeHandler = { value => {
              props.updateMediaReportHandler(
                'publishDate',
                formatTime(value, 'YYYY-MM-DD'),
              );
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>新聞來源</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { mediaReport.origin }
            onChange = { ({ target }) => {
              props.updateMediaReportHandler('origin', target.value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>效益</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { mediaReport.benefit }
            onChange = { ({ target }) => {
              props.updateMediaReportHandler('benefit', target.value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>
            媒體
            <span>*</span>
          </PSubtitle>
          <SelectNormal
            disabled = { props.disabled }
            value = { mediaReport.mediaID }
            options = {[
              { value: '', desc: '請選擇媒體' },
              ...props.mediaIDOptions,
            ]}
            changeHandler = { value => {
              props.mediaChangeHandler(value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>
            記者
            <span>*</span>
          </PSubtitle>
          <SelectNormal
            disabled = { props.disabled }
            value = { mediaReport.journalistID }
            options = {[
              { value: '', desc: '請選擇記者' },
              ...props.journalistIDOptions,
            ]}
            changeHandler = { value => {
              props.updateMediaReportHandler('journalistID', value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>主標</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { mediaReport.title }
            onChange = { ({ target }) => {
              props.updateMediaReportHandler('title', target.value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>系統連結</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { mediaReport.link }
            onChange = { ({ target }) => {
              props.updateMediaReportHandler('link', target.value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      { props.children }
    </TableWrapper>
  );
};

export default PanelMediaReportDetail;
