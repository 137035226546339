import React from 'react';
import styled from 'styled-components';

import SelectNormal from '@/components/UI/Select/SelectNormal';
import SelectMultiple from '@/components/UI/Select/SelectMultiple';
import SelectSearchable from '@/components/UI/Select/SelectSearchable';
import { DivPopupWrapper, DivPopup } from '@/component-style/Popup';
import { ButtonBasic, ButtonCancel } from '@/component-style/Button';
import { DivFlexRow } from '@/component-style/RWDWrapper';

const DivItemWrapper = styled(DivFlexRow)`
  padding: 0;
  margin-bottom: 10px;
`;

const DivSelectWrapper = styled(DivItemWrapper)`

  & > div:first-of-type,
  .ant-select {
    width: 100%;
  }
`;

const DivButtonsWrapper = styled.div`
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;


const H3Title = styled.h3`
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: #8b8b8b;
  margin-bottom: 10px;
`;

const PTexts = styled.p`
  width: 80px;
  font-size: 16px;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0.43px;
  color: #8b8b8b;
`;

const ButtonConfirm = styled(ButtonBasic)`
  width: 120px;
`;

const ButtonDiscard = styled(ButtonCancel)`
  width: 120px;
  margin-right: 10px;
`;

const importMethods = [
  { value: 'project', desc: '專案查詢匯入' },
  { value: 'type', desc: '記者路線匯入' },
];

const PopupReporterInvitation = props => {
  const { config } = props;

  return (
    <DivPopupWrapper>
      <DivPopup>
        <H3Title>記者匯入</H3Title>
        <DivSelectWrapper>
          <PTexts>匯入方式</PTexts>
          <SelectNormal
            value = { config.importMethod }
            options = { importMethods }
            changeHandler = { value =>  {
              props.methodChangeHandler(value);
            }}
          />
        </DivSelectWrapper>
        {
          config.importMethod === 'project' &&
            <DivSelectWrapper>
              <PTexts>專案代號</PTexts>
              <SelectSearchable
                value = { config.projectID }
                options = {[
                  { value: '', desc: '請選擇專案代號' },
                  ...props.projectIDOptions,
                ]}
                changeHandler = { value =>  {
                  props.updateConfigHandler('projectID', value);
                }}
              />
            </DivSelectWrapper>
        }
        {
          config.importMethod === 'type' &&
          <DivSelectWrapper>
            <PTexts>記者路線</PTexts>
            <SelectMultiple
              value = { config.type }
              options = {[ ...props.journalistTypes ]}
              changeHandler = { value =>  {
                props.updateConfigHandler('type', value);
              }}
            />
          </DivSelectWrapper>
        }
        <DivButtonsWrapper>
          <ButtonDiscard
            onClick = { props.cancelHandler }
          >關閉
          </ButtonDiscard>
          <ButtonConfirm
            onClick = { props.confirmHandler }
          >確認
          </ButtonConfirm>
        </DivButtonsWrapper>
      </DivPopup>
    </DivPopupWrapper>
  );
};

export default PopupReporterInvitation;
