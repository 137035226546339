import React from 'react';
import styled from 'styled-components';

import { TableHead, TableRow, ThItem } from '@/component-style/Table';

const THead = styled(TableHead)`
  min-width: 1080px;
`;

const Th100 = styled(ThItem)`
  width: 100px;
`;

const Th120 = styled(ThItem)`
  width: 120px;
`;

const Th160 = styled(ThItem)`
  width: 160px;
`;

const Th180 = styled(ThItem)`
  width: 180px;
`;

const Header = props => {
  
  return (
    <THead>
      <TableRow>
        <Th100>所屬公司</Th100>
        <Th120>專案代號</Th120>
        <Th120>專案名稱</Th120>
        <Th120>主管名稱</Th120>
        <Th120>發票開立期間</Th120>
        <Th120>預估專案支出費用</Th120>
        <Th120>預估服務費收入</Th120>
        <Th120>預估專案總金額</Th120>
      </TableRow>
    </THead>
  );
};

export default Header;
