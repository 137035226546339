import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Table, TableBody } from '@/component-style/Table';
import TableWrapper  from '@/components/UI/TableWrapper';
import PageSelector from '@/components/UI/PageSelector';
import Header from './Header';
import Item from './Item';

const TableVenueList = props => {
  const history = useHistory();
  const informationRoutes = useSelector(state => state.login.informationRoutes);
  const disableDetailPage = informationRoutes.indexOf('VenueDetail') === -1;

  return (
    <TableWrapper title = "活動場地列表">
      <Table>
        <Header/>
        <TableBody>
          {
            props.venues.map(venue =>
              <Item
                key = { venue.venueID }
                venue = { venue }
                disableDetailPage = { disableDetailPage }
                goDetailHandler = {() => {
                  history.push(`/Information/VenueDetail/${venue.venueID}`)
                }}
              />   
            )
          }
        </TableBody>
      </Table>
      <PageSelector
        current = { props.currentPage }
        total = { props.totalCount }
        pageChangedHandler = { props.pageChangedHandler }
        showSizeChanger = { false }
      />
    </TableWrapper>
  );
};

export default TableVenueList;
