import React from 'react';
import { Route } from 'react-router-dom';

import ExpenseQuotations from '@/containers/expenseModule/expenseQuotations';
import ExpenseQuotationDetail from '@/containers/expenseModule/expenseQuotationDetail';
import ExpenseQuotationCreation from '@/containers/expenseModule/expenseQuotationCreation';
import ExpenseForms from '@/containers/expenseModule/expenseForms';
import ExpenseApproval from '@/containers/expenseModule/expenseApproval';
import ExpenseApprovalDetail from '@/containers/expenseModule/expenseApprovalDetail';
import ExpenseProxyApproval from '@/containers/expenseModule/expenseProxyApproval';
import ExpenseFormCreation from '@/containers/expenseModule/expenseFormCreation';
import ExpenseFormDetail from '@/containers/expenseModule/expenseFormDetail';

const expenseRoutes = [
  {
    key: 'ExpenseQuotations',
    path: "/Expense/ExpenseQuotations",
    component: ExpenseQuotations,
  },
  {
    key: 'ExpenseQuotationDetail',
    path: "/Expense/ExpenseQuotationDetail/:id",
    component: ExpenseQuotationDetail,
  },
  {
    key: 'ExpenseQuotationCreation',
    path: "/Expense/ExpenseQuotationCreation",
    component: ExpenseQuotationCreation,
  },
  {
    key: 'ExpenseForms',
    path: "/Expense/ExpenseForms",
    component: ExpenseForms,
  },
  {
    key: 'ExpenseApproval',
    path: "/Expense/ExpenseApproval",
    component: ExpenseApproval,
  },
  {
    key: 'ExpenseApprovalDetail',
    path: "/Expense/ExpenseApprovalDetail/:id",
    component: ExpenseApprovalDetail,
  },
  {
    key: 'ExpenseFormCreation',
    path: "/Expense/ExpenseFormCreation",
    component: ExpenseFormCreation,
  },
  {
    key: 'ExpenseFormDetail',
    path: "/Expense/ExpenseFormDetail/:id",
    component: ExpenseFormDetail,
  },
  {
    key: 'ExpenseProxyApproval',
    path: "/Expense/ExpenseProxyApproval",
    component: ExpenseProxyApproval,
  },
];


const RoutesExpenseModule = validRoutes => expenseRoutes
  .filter(({ key }) => validRoutes.indexOf(key) !== -1)
  .map(({ path, component }) =>
    <Route
      key = { path }
      path = { path }
      component = { component }
    />
  );

export default RoutesExpenseModule;
