import React from 'react';
import styled from 'styled-components';

import { ButtonCancel } from '../../component-style/Button';
import { InputBasic } from '../../component-style/Input';
import DatePeriodSelector from '../UI/DatePeriodSelector';
import RemoveImg from '../../assets/images/icon_remove.svg';

const DivWrapper = styled.div`
  width: 100%;
  
  @media (min-width: 768px) {
    width: calc(100% - 135px);
  }
`;

const DivFWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const DivSelectorWrapper = styled.div`
  margin-right: 20px;
  margin-bottom: 10px;
`;

const ButtonAdd = styled(ButtonCancel)`
  width: 150px;
`;

const InputRemark = styled(InputBasic)`
  width: 200px;
  margin-right: 20px;
  margin-bottom: 10px;

  @media (max-width: 320px) {
    width: 200px;
  };
`;

const DivIconRemove = styled.div`
  cursor: pointer;
  width: 20px;
  height: 40px;
  background-image: url(${ props => props.url });
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  @media (max-width: 375px) {
    background-position: top;
  }
`;

const MediaDateList = props => {

  const removeHandler = index => {
    !props.disabled
      && props.deleteDateHandler(index);
  };

  const addDateHandler = () => {
    !props.disabled
      && props.addDateHandler();
  };

  return (
    <DivWrapper>
      {
        props.timeList
          .map((time, index) =>
            <DivFWrapper
              key = { index }
            >
              <DivSelectorWrapper>
                <DatePeriodSelector
                  disabled = { props.disabled }
                  startTime = { time.start }
                  endTime = { time.end }
                  showTime = { true }
                  endTimeOKHandler = { value => {
                    !props.disabled
                      && props.updateMediaDayHandler(index, {
                        ...time,
                        end: value,
                    });
                  }}
                  endTimeChangeHandler = { value => {
                    !props.disabled
                      && props.updateMediaDayHandler(index, {
                        ...time,
                        end: value,
                    });
                  }}
                  startTimeOKHandler = { value => {
                    !props.disabled
                      && props.updateMediaDayHandler(index, {
                        ...time,
                        start: value,
                    });
                  }}
                  startTimeChangeHandler = { value => {
                    !props.disabled
                      && props.updateMediaDayHandler(index, {
                        ...time,
                        start: value,
                    });
                  }}
                />
              </DivSelectorWrapper>
              <InputRemark
                disabled = { props.disabled }
                value = { time.remark }
                onChange = {({ target }) => {
                  !props.disabled
                      && props.updateMediaDayHandler(index, {
                        ...time,
                        remark: target.value,
                    });
                }}
              />
              {
                !props.disabled &&
                  <DivIconRemove
                    url = { RemoveImg }
                    onClick = { () => { removeHandler(index) } }
                  />
              }
            </DivFWrapper>
          )
      }
      <ButtonAdd
        onClick = { () => { addDateHandler() } }
      >
        新增日期
      </ButtonAdd>
    </DivWrapper>
  );
};

export default MediaDateList;
