import React from 'react';
import styled from 'styled-components';

import { TableHead, TableRow, ThItem } from '@/component-style/Table';

const THead = styled(TableHead)`
  min-width: 1120px;
`;

const Th80 = styled(ThItem)`
  width: 80px;
`;

const Th120 = styled(ThItem)`
  width: 120px;
`;

const Th160 = styled(ThItem)`
  width: 160px;
`;

const Th240 = styled(ThItem)`
  width: 240px;
`;

const Header = props => {
  
  return (
    <THead>
      <TableRow>
        <Th120>公司別</Th120>
        <Th120>同仁</Th120>
        <Th120>本薪</Th120>
        <Th120>勞保</Th120>
        <Th120>健保</Th120>
        <Th120>健保眷屬</Th120>
        <Th120>請假</Th120>
        <Th120>加班薪資</Th120>
        <Th120>加、減項</Th120>
        <Th120>月薪</Th120>
        <Th120>勞退</Th120>
        <Th120>勞退自提</Th120>
        <Th80></Th80>
      </TableRow>
    </THead>
  );
};

export default Header;
