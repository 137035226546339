import React from 'react';
import styled from 'styled-components';

import Checkbox from '@/components/UI/Checkbox';
import { TableHead, TableRow, ThItem } from '@/component-style/Table';

const THead = styled(TableHead)`
  min-width: 1000px;
`;

const Th80 = styled(ThItem)`
  width: 80px;
`;

const Th160 = styled(ThItem)`
  width: 160px;
`;

const ThCharacter = styled(Th160)`
  text-align: left;
`;

const ThAddress = styled(ThItem)`
  width: 200px;
  text-align: left;
`;

const Header = props => {
  
  return (
    <THead>
      <TableRow>
        <Th80>
          <Checkbox
            checked = { props.selectAll }
            changeHandler = { props.selectAllHandler }
          />
        </Th80>
        <Th160>場地</Th160>
        <Th160>廳室</Th160>
        <Th80>可容納人數</Th80>
        <Th80>坪數</Th80>
        <ThCharacter>風格特色</ThCharacter>
        <ThAddress>地址</ThAddress>
        <Th80></Th80>
      </TableRow>
    </THead>
  );
};

export default Header;
