import React from 'react';
import styled from 'styled-components';

import { TableHead, TableRow, ThItem } from '@/component-style/Table';

const THead = styled(TableHead)`
  min-width: 680px;
`;

const Th120 = styled(ThItem)`
  width: 120px;
`;

const Th160 = styled(ThItem)`
  width: 160px;
`;

const Header = () => {
  
  return (
    <THead>
      <TableRow>
        <Th160>細項名稱</Th160>
        <Th160>備註</Th160>
        <Th120>狀態</Th120>
        <Th120>刪除</Th120>
        <Th120>明細</Th120>
      </TableRow>
    </THead>
  );
};

export default Header;
