import React from 'react';
import styled from 'styled-components';

import StatusCheck from '../../UI/StatusCheck';
import { TdItem, TableRow } from '../../../component-style/Table';

const TrWrapper = styled(TableRow)`
  border-bottom: solid 2px #f2f2f2;
  box-sizing: border-box;
  min-width: 480px;

  &:last-child {
    border-bottom: none;
  }
`;

const Td80 = styled(TdItem)`
  width: 80px;
`;

const Td120 = styled(TdItem)`
  width: 120px;
`;
const Td160 = styled(TdItem)`
  width: 160px;
`;

const Item = props => {

  return (
    <TrWrapper>
      <Td120>MS150902</Td120>
      <Td160>微軟萬用折疊鍵盤記者會</Td160>
      <Td120>尚未連絡上</Td120>
      <Td80>
        <StatusCheck/>
      </Td80>
    </TrWrapper>
  )
};

export default Item;
