import React from 'react';
import styled from 'styled-components';

import TableWrapper from '@/components/UI/TableWrapper';
import Checkbox from '@/components/UI/Checkbox';
import SelectNormal from '@/components/UI/Select/SelectNormal';
import SelectSearchable from '@/components/UI/Select/SelectSearchable';
import { InputBasic, TextareaBasic } from '@/component-style/Input';
import { PSubtitle } from '@/component-style/TextElement';
import {
  DivFlexRow,
  DivItemWrapper,
  DivFullWidthWrapper,
  DivLongMultiSelector,
} from '@/component-style/RWDWrapper';

const DivCheckboxWrapper = styled.div`
  width: 300px;
  height: 40px;
  line-height: 40px;

  @media (max-width: 767px) {
    width: auto;
    height: 20px;
    line-height: 20px;
    margin-bottom: 5px;
  }
`;

const PanelActivityDetail = props => {
  const { activityIdea } = props;

  const activityIdeaTagOptions = activityIdeaTypeID => 
    props.activityIdeaTagDictionary[activityIdeaTypeID] === undefined
      ? []
      : props.activityIdeaTagDictionary[activityIdeaTypeID];

  return (
    <TableWrapper title = { props.title }>
      <DivFlexRow>
        <DivFullWidthWrapper>
          <PSubtitle>
            專案代號
            <span>*</span>
          </PSubtitle>
          <DivLongMultiSelector>
            <SelectSearchable
              disabled = { props.disabled || props.disableProjectID }
              value = { activityIdea.projectID }
              options = {[
                { value: '', desc: '請選擇專案代號' },
                ...props.projectIDOptions
              ]}
              changeHandler = { value => {
                props.updateActivityIdeaHandler('projectID', value);
              }}
            />
          </DivLongMultiSelector>
        </DivFullWidthWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>
            大分類
            <span>*</span>
          </PSubtitle>
          <SelectNormal
            disabled = { props.disabled }
            value = { activityIdea.activityIdeaTypeID }
            options = {[
              { value: '', desc: '請選擇大分類' },
              ...props.activityIdeaTypes,
            ]}
            changeHandler = { props.activityIdeaTypeChangeHandler }
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>
            小分類
            <span>*</span>
          </PSubtitle>
          <SelectNormal
            disabled = { props.disabled }
            value = { activityIdea.tag }
            options = {[
              { value: '', desc: '請選擇小分類' },
              ...activityIdeaTagOptions(activityIdea.activityIdeaTypeID)
            ]}
            changeHandler = { value => {
              props.updateActivityIdeaHandler('tag', value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivFullWidthWrapper>
          <PSubtitle>內容</PSubtitle>
          <DivLongMultiSelector>
            <TextareaBasic
              disabled = { props.disabled }
              value = { activityIdea.content }
              onChange = {({ target }) => {
                props.updateActivityIdeaHandler('content', target.value);
              }}
            />
          </DivLongMultiSelector>
        </DivFullWidthWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>標籤</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { activityIdea.title }
            onChange = {({ target }) => {
              props.updateActivityIdeaHandler('title', target.value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>
            合作廠商
            <span>*</span>
          </PSubtitle>
          <SelectSearchable
            disabled = { props.disabled }
            options = {[
              { value: '', desc: '請選擇廠商' },
              ...props.vendorIDs,
            ]}
            value = { activityIdea.vendorID }
            changeHandler = { value => {
              props.updateActivityIdeaHandler('vendorID', value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>電話</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { activityIdea.phone }
            onChange = {({ target }) => {
              props.updateActivityIdeaHandler('phone', target.value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>EMail</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { activityIdea.email }
            onChange = {({ target }) => {
              props.updateActivityIdeaHandler('email', target.value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>預算</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { activityIdea.budget }
            onChange = {({ target }) => {
              props.updateActivityIdeaHandler('budget', target.value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>網址</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { activityIdea.website }
            onChange = {({ target }) => {
              props.updateActivityIdeaHandler('website', target.value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>分享空間連結</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { activityIdea.cloudStorageUrl }
            onChange = {({ target }) => {
              props.updateActivityIdeaHandler('cloudStorageUrl', target.value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>執行</PSubtitle>
          <DivCheckboxWrapper>
            <Checkbox
              disabled = { props.disabled }
              checked = { activityIdea.executed }
              changeHandler = { value => {
                props.updateActivityIdeaHandler('executed', value);
              }}
            />
          </DivCheckboxWrapper>
        </DivItemWrapper>
      </DivFlexRow>
      { props.children }
    </TableWrapper>
  );
};

export default PanelActivityDetail;
