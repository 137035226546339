import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { LOGOUT } from '@/store/actions/loginAction';
import Cookies from 'js-cookie';

import PopupExecuteConfirm from '@/components/UI/Popup/PopupExecuteConfirm';

const Logout = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const logout = () => {
    Cookies.remove('veda__srp-token');
    dispatch(LOGOUT());
  };

  return (
    <PopupExecuteConfirm
      title = "系統登出"
      text = "確定登出 SRP ?"
      confirmHandler = { logout }
      cancelHandler = { () => { history.goBack() }}
    />
  );
};

export default Logout;
