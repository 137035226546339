import React from 'react';
import styled from 'styled-components';
import { cloneDeep } from 'lodash';

import ProjectQuotationItem from '@/components/ProjectQuotationItem';
import TableWrapper from '@/components/UI/TableWrapper';
import SelectNormal from '@/components/UI/Select/SelectNormal';
import SelectSearchable from '@/components/UI/Select/SelectSearchable';
import { InputBasic } from '@/component-style/Input';
import { PSubtitle } from '@/component-style/TextElement';
import { ButtonBasic, ButtonCancel } from '@/component-style/Button';
import {
  DivFlexRow,
  DivItemWrapper,
} from '@/component-style/RWDWrapper';

import { integerConverter } from '@/utils/textUtils';
import { getMultiplyResult } from '@/utils/mathUtils';
import { projectQuotationItem } from '@/constant/dataConfig/financeModule';

const DivWrapper = styled.div`
  width: 100%;
  
  @media (min-width: 768px) {
    width: calc(100% - 135px);
  }
`;

const DivFWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const InputWrapper = styled.div`
  margin-right: 20px;
  margin-bottom: 10px;

  @media (max-width: 375px) {
    margin-right: 0;
  }

  @media (min-width: 1920px) {
    .ant-select, .datePicker { width: 300px; }
  }

  @media (max-width: 320px) {
    .ant-select, .datePicker { width: 260px; }
  };
`;

const Input = styled(InputBasic)`
  @media (max-width: 320px) { width: 260px; }
  @media (min-width: 1920px) { width: 300px }
`;

const InputNumber = styled(InputBasic)`
  width: 120px;

  @media (max-width: 320px) {
    width: 120px;
  };

  @media (min-width: 1920px) {
    width: 120px;
  }
`;

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding: 0;
  padding-left: 135px;
  padding-bottom: 20px;
  justify-content: flex-end;

  @media (max-width: 767px) {
    justify-content: center;
    padding-left: 0;
  }
`;

const ButtonAdd = styled(ButtonBasic)`
  width: 120px;
  margin-right: 10px;
  margin-bottom: 10px;
`;

const ButtonClear = styled(ButtonCancel)`
  width: 120px;
  margin-bottom: 10px;
`;

const PLabel = styled(PSubtitle)`
  width: auto;
  height: 20px;
  line-height: 20px;

  span { color: #de2027 }
`;

const PanelQuotationDetail = props => {
  const { quotationDetail } = props;
  const { quotationItems } = quotationDetail;

  const totalPrice = quotationItems
    .map(({ price, amount }) => getMultiplyResult(
      integerConverter(price), integerConverter(amount)
    ))
    .reduce((acc, cur) => acc + cur, 0);

  const addItemHandler = () => {
    props.updateQuotationDetailHandler(
      'quotationItems',
      [...quotationItems, { ...projectQuotationItem }]
    );
  };

  const removeItemHandler = index => {
    const filteredList = quotationItems
      .filter((item, i) => i !== index);
    
    props.updateQuotationDetailHandler(
      'quotationItems',
      filteredList
    );
  };

  const itemChangeHandler = (index, value) => {
    const updateQuotations = cloneDeep(quotationItems);
    updateQuotations[index] = value;

    props.updateQuotationDetailHandler(
      'quotationItems',
      updateQuotations
    );
  };

  return (
    <TableWrapper title = "編輯報價單">
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>報價單資訊</PSubtitle>
          <DivWrapper>
            <DivFWrapper>
              <InputWrapper>
                <PLabel>報價單名稱</PLabel>
                <Input
                  disabled = { !props.userEditable }
                  value = { quotationDetail.name }
                  onChange = { ({ target }) => {
                    props.updateQuotationDetailHandler(
                      'name', target.value,
                    );
                  }}
                />
              </InputWrapper>
              <InputWrapper>
                <PLabel>報價單類型</PLabel>
                <SelectNormal
                  disabled = { props.isAdding === false }
                  value = { quotationDetail.type }
                  options = {[
                    { value: 'oop', desc: '專案支出費用' },
                    { value: 'pr', desc: '服務費' },
                  ]}
                  changeHandler = { props.updateQuotationTypeHandler }
                />
              </InputWrapper>
              <InputWrapper>
                <PLabel>報價總監</PLabel>
                <SelectSearchable
                  disabled = { !props.userEditable }
                  value = { quotationDetail.director }
                  options = {[
                    { value: '', desc: '請選擇報價總監' },
                    ...props.directorOptions,
                  ]}
                  changeHandler = { value => {
                    props.updateQuotationDetailHandler(
                      'director', value,
                    );
                  }}
                />
              </InputWrapper>
              <InputWrapper>
                <PLabel>服務費率</PLabel>
                <InputNumber
                  type = "number"
                  disabled = { !props.userEditable }
                  value = { quotationDetail.rate }
                  onChange = { ({ target }) => {
                    props.updateQuotationDetailHandler(
                      'rate', target.value,
                    );
                  }}
                />
              </InputWrapper>
              <InputWrapper>
                <PLabel>總金額</PLabel>
                <InputNumber
                  disabled = { true }
                  value = { totalPrice }
                />
              </InputWrapper>
            </DivFWrapper>
          </DivWrapper>
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>報價項目</PSubtitle>
          <DivWrapper>
            {
              quotationDetail.quotationItems
                .map((quotation, index) =>
                  <ProjectQuotationItem
                    key = { index }
                    disabled = { !props.userEditable }
                    type = { quotationDetail.type }
                    quotation = { quotation }
                    removeHandler = { () => {
                      removeItemHandler(index)
                    }}
                    itemChangeHandler = { value => {
                      itemChangeHandler(index, value);
                    }}
                  />
                )
            }
            <ButtonAdd
              disabled = { !props.userEditable }
              onClick = { addItemHandler }
            >新增項目</ButtonAdd>
          </DivWrapper>
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivButtonsWrapper>
          <ButtonAdd
            disabled = { quotationDetail.name === '' || !props.userEditable }
            onClick = { props.saveQuotationHandler }
          >儲存</ButtonAdd>
          <ButtonClear
            onClick = { props.cancelHandler }
          >取消</ButtonClear>
        </DivButtonsWrapper>
      </DivFlexRow>
    </TableWrapper>
  );
};

export default PanelQuotationDetail;
