import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { TdItem, TableRow } from '@/component-style/Table';
import { ButtonGoDetail } from '@/component-style/Button';
import { ButtonBasic } from '@/component-style/Button';

import {
  getOptionDesc,
  getOvertimeApprovementStatus,
  getOvertimeDateTypeString,
} from '@/utils/textUtils';

import { leaveApprovalOptions } from '@/constant/options';

import {
  // diffTime,
  // diffTimeWithFloat,
  diffTimeSubtractLunchBreak,
  // formatTime,
} from '@/utils/timeUtils';

import moment from 'moment';

const TrWrapper = styled(TableRow)`
  border-bottom: solid 2px #f2f2f2;
  box-sizing: border-box;
  min-width: 1480px;

  &:last-child {
    border-bottom: none;
  }
`;

const Td80 = styled(TdItem)`
  width: 80px;
`;

const Td120 = styled(TdItem)`
  width: 120px;
`;

const Td160 = styled(TdItem)`
  width: 160px;
`;

const Td240 = styled(TdItem)`
  width: 240px;
`;

const ButtonSave = styled(ButtonBasic)`
  margin-right: 10px;
  width: 120px;

  @media (max-width: 767px) {
    margin-right: 0;
    margin-bottom: 10px;
  }
`;

const Item = props => {
  const { leaveRecord, leaveSettinsOptions } = props;
  const { leaveFrom, leaveTo } = leaveRecord;

  const projectRole = useSelector(state => state.login.projectRole);
  console.log(`TableLeaveList Item.js projectRole=${projectRole}`);

  return (
    <TrWrapper>
      <Td120>{ leaveRecord.username }</Td120>
      <Td120>{ leaveRecord.leaveName }</Td120>
      <Td160>{ `${moment.parseZone(leaveFrom).format('YYYY-MM-DD HH:mm')}` }</Td160>
      <Td160>{ `${moment.parseZone(leaveTo).format('YYYY-MM-DD HH:mm')}` }</Td160>
      <Td120>{ Math.round(leaveRecord.durationInSeconds / 3600 * 100) / 100 }</Td120>
      <Td120>{ getOptionDesc(leaveApprovalOptions, leaveRecord.status) }</Td120>
        { 
          projectRole.includes('hr') &&
          <Td120>
            { leaveRecord.status === "cancelled" ? "" :
              <ButtonSave
                onClick = { () => { props.cancelLeaveApplicationHandler(leaveRecord.id); } }
              >取消
              </ButtonSave>
            }
          </Td120>
        }
      {/* <Td240>
        {
          
        }
      </Td240>
      <Td80>{  }</Td80>
      <Td80>
        {
          getOvertimeApprovementStatus(overtimeRecord.approvementStatus)
        }
      </Td80>
      <Td80>{ type(overtimeRecord.overtimeType) }</Td80>
      <Td80>{ getOvertimeDateTypeString(overtimeRecord.overtimeDateType) }</Td80>
      <Td120>{ approvedMinutes() }</Td120>
      <Td80>
        <ButtonGoDetail
          disabled = { props.disableDetailPage }
          onClick = { props.goDetailHandler }
        >...
        </ButtonGoDetail>
      </Td80> */}
    </TrWrapper>
  );
};

export default Item;
