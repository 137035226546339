import React from 'react';
import styled from 'styled-components';

import Checkbox from '@/components/UI/Checkbox/index.js';
import SelectNormal from '@/components/UI/Select/SelectNormal';
import { InputBasic } from '@/component-style/Input';
import { DivPopupWrapper, DivPopup } from '@/component-style/Popup';
import { ButtonBasic, ButtonCancel } from '@/component-style/Button';
import { DivFlexRow } from '@/component-style/RWDWrapper';

import { invitationConfirmStatus } from '@/constant/options';

const DivItemWrapper = styled(DivFlexRow)`
  padding: 0;
  margin-bottom: 10px;
`;

const DivSelectWrapper = styled(DivItemWrapper)`

  & > div:first-of-type,
  .ant-select {
    width: 100%;
  }
`;

const InputFilter = styled(InputBasic)`
  width: 100%;
`;

const DivCheckboxWrapper = styled.div`
  width: 100px;
  height: 40px;
  line-height: 40px;
`;

const DivButtonsWrapper = styled.div`
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;


const H3Title = styled.h3`
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: #8b8b8b;
  margin-bottom: 10px;
`;

const PTexts = styled.p`
  width: 80px;
  font-size: 16px;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0.43px;
  color: #8b8b8b;
`;

const ButtonConfirm = styled(ButtonBasic)`
  width: 120px;
`;

const ButtonDiscard = styled(ButtonCancel)`
  width: 120px;
  margin-right: 10px;
`;


const PopupInvitationReporterStatus = props => {
  const { status } = props;

  return (
    <DivPopupWrapper>
      <DivPopup>
        <H3Title>媒體邀約狀態 - { status.journalistName }</H3Title>
        <DivSelectWrapper>
          <PTexts>確認狀況</PTexts>
          <SelectNormal
            value = { status.confirmStatus }
            options = { invitationConfirmStatus }
            changeHandler = { value => {
              props.updateStatusHandler('confirmStatus', value);
            }}
          />
        </DivSelectWrapper>
        <DivItemWrapper>
          <PTexts>狀況說明</PTexts>
          <InputFilter
            value = { status.statusDesc }
            onChange = { ({ target }) => {
              props.updateStatusHandler('statusDesc', target.value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PTexts>分工</PTexts>
          <InputFilter
            value = { status.job }
            onChange = { ({ target }) => {
              props.updateStatusHandler('job', target.value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PTexts>出席</PTexts>
          <DivCheckboxWrapper>
            <Checkbox
              checked = { status.attend === true }
              changeHandler = { value => {
                props.updateStatusHandler('attend', value);
              }}
            />
          </DivCheckboxWrapper>
        </DivItemWrapper>
        <DivButtonsWrapper>
          <ButtonDiscard
            onClick = { props.cancelHandler }
          >關閉
          </ButtonDiscard>
          <ButtonConfirm
            onClick = { props.confirmHandler }
          >儲存
          </ButtonConfirm>
        </DivButtonsWrapper>
      </DivPopup>
    </DivPopupWrapper>
  );
};

export default PopupInvitationReporterStatus;
