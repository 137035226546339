import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import TableWrapper  from '@/components/UI/TableWrapper';
import PageSelector from '@/components/UI/PageSelector';
import { Table, TableBody } from '@/component-style/Table';
import Header from './Header';
import Item from './Item';

const TableFinanceManagements = props => {
  // const history = useHistory();
  const financeRoutes = useSelector(state => state.login.financeRoutes);
  const disableDetailPage = financeRoutes.indexOf('FinanceManagementDetail') === -1;

  const { expenses } = props;

  return (
    <TableWrapper title = "支憑管理列表">
      <Table>
        <Header
          selectAll = { props.selectAll }
          selectAllHandler = { props.selectAllHandler }
        />
        <TableBody>
          {
            expenses.map((expense, index) =>
              <Item
                key = { expense.expenseID }
                expense = { expense }
                disableDetailPage = { disableDetailPage }
                checkHandler = { value => {
                  props.checkHandler(index, value);
                }}
                goDetailHandler = {() => {
                  // history.push(`/Finance/FinanceManagementDetail/${expense.expenseID}`);
                  window.open(`/Finance/FinanceManagementDetail/${expense.expenseID}`, "_blank");
                }}
              />
            )
          }
        </TableBody>
      </Table>
      <PageSelector
        current = { props.currentPage }
        total = { props.totalCount }
        pageChangedHandler = { props.pageChangedHandler }
        pageSize = { props.pageSize }
        showSizeChanger = { props.showSizeChanger }
        pageSizeOptions = { props.pageSizeOptions }
        onShowSizeChange = { props.onShowSizeChange }
      />
    </TableWrapper>
  );
};

export default TableFinanceManagements;
