import React from 'react';
import styled from 'styled-components';

import { TableHead, TableRow, ThItem } from '@/component-style/Table';

const THead = styled(TableHead)`
  min-width: 1200px;
`;

const Th120 = styled(ThItem)`
  width: 120px;
`;

const Th160 = styled(ThItem)`
  width: 160px;
`;

const Th240 = styled(ThItem)`
  width: 240px;
`;

const Header = props => {
  
  return (
    <THead>
      <TableRow>
        <Th160>專案代號</Th160>
        <Th240>專案名稱</Th240>
        <Th120>支憑流水號</Th120>
        <Th160>付款對象</Th160>
        <Th120>發票總金額</Th120>
        <Th120>請款總金額</Th120>
        <Th160>預計入帳日</Th160>
        <Th120>是否列印</Th120>
      </TableRow>
    </THead>
  );
};

export default Header;
