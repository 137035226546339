import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import PanelSetSpecialLeaveHours from '@/components/Panel/HR/PanelSetSpecialLeaveHours';

import { 
  postLoadEIPHours,
} from '@/api/financeService';

import {
  OPEN_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';

const DivPanelContainer = styled.div`
  margin-bottom: 40px;
`;

const SetSpecialLeaveHours = () => {
  const dispatch = useDispatch();

  const [specialLeaveId, setSpecialLeaveId] = useState("");

  const [uploadFile, setUploadFile] = useState("");

  const [popupTitle, setPopupTitle] = useState("");
  const [showSaveSuccessPopup, setShowSaveSuccessPopup] = useState(false);

  const updateSpecialLeaveIdHandler = (key, value) => {
    console.log(`SetSpecialLeaveHours.js updateSpecialLeaveHandler() key=${key}&value=${value}`);
    setSpecialLeaveId(value);
  };

  const onEIPChangeHandler = async(info) => {
    console.log(`onEIPChangeHandler JSON.stringify(info)=${JSON.stringify(info)}`);
    try {
      const formData = new FormData();
      formData.append('csvEipData', info.file);
      // formData.append(info.file);
      // formData.append('upfile', info.file);
      // formData.append('objectID', info.id);
      // formData.append('name', info.file.name);
      setUploadFile(info.file.name);
      await postLoadEIPHours(specialLeaveId, formData);
      setPopupTitle("上傳檔案成功");
      setShowSaveSuccessPopup(true);
    } catch (err) {
      setPopupTitle("上傳檔案失敗");
      setShowSaveSuccessPopup(true);
      console.log(err);
    }
  }

  return(
    <>
      <DivPanelContainer>
        <PanelSetSpecialLeaveHours
          specialLeaveId = { specialLeaveId }
          uploadFile = { uploadFile }
          onEIPChangeHandler = { onEIPChangeHandler }
          updateSpecialLeaveIdHandler = { updateSpecialLeaveIdHandler }
        />
      </DivPanelContainer>
      {
        showSaveSuccessPopup &&
          <PopupExecuteResult
            title = { popupTitle }
            confirmHandler = { () => {
              setShowSaveSuccessPopup(false);
            } }
          />
      }
    </>
  );
};

export default SetSpecialLeaveHours;
