import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { TdItem, TableRow } from '@/component-style/Table';
import { ButtonGoDetail } from '@/component-style/Button';

import { getOptionDesc } from '@/utils/textUtils';
import moment from 'moment';

import { decimalCommaConverter } from '@/utils/textUtils';

const TrWrapper = styled(TableRow)`
  border-bottom: solid 2px #f2f2f2;
  box-sizing: border-box;
  min-width: 1120px;

  &:last-child {
    border-bottom: none;
  }
`;

const Td80 = styled(TdItem)`
  width: 80px;
`;

const Td120 = styled(TdItem)`
  width: 120px;
`;

const Td160 = styled(TdItem)`
  width: 160px;
`;

const Td240 = styled(TdItem)`
  width: 240px;
`;

const Item = props => {
  const { leave } = props;
  console.log(`TablePersonnelLeaveList Item.js JSON.stringify(leave):${JSON.stringify(leave)}`);
  // const { user, objectID } = expense;
  // const vendorIDs = useSelector(state => state.srpOptions.vendorIDOptions);
  // const memberOptions = useSelector(state => state.srpOptions.memberOptions);
  const companyTypeOptions = useSelector(state => state.srpOptions.companyTypeOptions);

  // const username = getOptionDesc(memberOptions, user);

  // const objectName = expense.object === 'user'
  //   ? getOptionDesc(memberOptions, objectID)
  //   : getOptionDesc(vendorIDs, objectID);

  return (
    <TrWrapper>
      <Td120>{ leave.date }</Td120>
      <Td120>{ leave.startTime == null ? '' : 
        `${moment.parseZone(leave.startTime).format('HH:mm')}`}
      </Td120>
      <Td120>{ leave.endTime == null ? '' : 
        `${moment.parseZone(leave.endTime).format('HH:mm')}`}
      </Td120>
      <Td120>{ leave.hours == null ? '' : leave.hours }</Td120>
      <Td120>{ leave.hourlySalary == null ? '' : decimalCommaConverter(leave.hourlySalary) }</Td120>
      <Td120>{ leave.salary == null ? '' : decimalCommaConverter(leave.salary) }</Td120>
    </TrWrapper>
  );
};

export default Item;
