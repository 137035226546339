import React from 'react';

import PageSelector from '@/components/UI/PageSelector';
import TableWrapper  from '@/components/UI/TableWrapper';
import { Table, TableBody } from '@/component-style/Table';
import Header from './Header';
import Item from './Item';


const TableMemberEvaluation = props => {
  const { evaluations } = props;

  return (
    <TableWrapper title = "成員評鑑清單">
      <Table>
        <Header/>
        <TableBody>
          {
            evaluations.map((evaluation, index) =>
              <Item
                key = { index }
                disabled = { props.disabled }
                evaluation = { evaluation }
                saveHandler = { props.saveHandler }
                evaluationChangeHandler = { value => {
                  props.evaluationChangeHandler(value, index);
                }}
              />
            )
          }
        </TableBody>
      </Table>
      <PageSelector
        disabled = { true }
      />
    </TableWrapper>
  );
};

export default TableMemberEvaluation;
