import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import {
  deleteCustomerWindow,
  postToGetCustomerWindowDetail,
  putModifyCustomerWindowDetail,
} from '@/api/customerService';

import {
  deleteVendorWindow,
  postToGetVendorWindowDetail,
  putModifyVendorWindowDetail,
} from '@/api/vendorService';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import MainContent from '@/component-style/MainContent';
import PanelContactWindowDetail from '@/components/Panel/PanelContactWindowDetail';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import PopupExecuteConfirm from '@/components/UI/Popup/PopupExecuteConfirm';
import { ButtonBasic, ButtonCancel } from '@/component-style/Button';
import { DivFlexRow } from '@/component-style/RWDWrapper';

import { contactWindowConfig } from '@/constant/dataConfig/informationModule';

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding-right: 0;
  padding-left: 135px;
  padding-bottom: 30px;
  justify-content: flex-end;

  @media (max-width: 425px) {
    justify-content: center;
    padding-left: 0;
  }
`;

const ButtonSave = styled(ButtonBasic)`
  width: 120px;
  margin-right: 10px;
  margin-bottom: 10px;
`;

const ButtonClear = styled(ButtonCancel)`
  width: 120px;
  margin-bottom: 10px;
`;

const ContactWindowDetail = props => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const informationRoutes = useSelector(state => state.login.informationRoutes);
  const showSaveFailPopup = useSelector(state => state.apiFail.showSaveFailPopup);
  const apiFailPopupTitle = useSelector(state => state.apiFail.title);
  const apiFailPopupMessage = useSelector(state => state.apiFail.message);
  
  const [contactWindow, setContactWindow] = useState({
    ...contactWindowConfig,
    businessPhone: { ...contactWindowConfig.businessPhone },
    companyAddress: { ...contactWindowConfig.companyAddress },
    privateAddress: { ...contactWindowConfig.privateAddress },
  });
  
  const [isEditing, setIsEditing] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [showSaveSuccessPopup, setShowSaveSuccessPopup] = useState(false);
  const [showDeleteSuccessPopup, setShowDeleteSuccessPopup] = useState(false);
  
  const windowTypeKey = pathname.indexOf('Customer') !== -1
    ? 'customerID'
    : 'vendorID';

  const userEditable = windowTypeKey === 'customerID'
    ? informationRoutes.indexOf('CustomerWindowDetail-Edit') !== -1
    : informationRoutes.indexOf('VendorWindowDetail-Edit') !== -1;

  const userDeletable = windowTypeKey === 'customerID'
    ? informationRoutes.indexOf('CustomerWindowDetail-Delete') !== -1
    : informationRoutes.indexOf('VendorWindowDetail-Delete') !== -1;

  const updateContactWindowHandler = (key, value) => {
    const updateObj = { ...contactWindow };
    updateObj[key] = value;
    setContactWindow({ ...updateObj });
  };

  const closeSuccessPopupHandler = () => {
    setIsEditing(false);
    setShowSaveSuccessPopup(false);
  };

  const updateContactWindowData = async () => {
    setShowLoadingPopup(true);

    try {
      windowTypeKey === 'customerID'
        ? await putModifyCustomerWindowDetail(contactWindow)
        : await putModifyVendorWindowDetail(contactWindow);

      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '更新失敗', err }));
      console.log(err);
    }
  };

  const deleteContactWindowHandler = async () => {
    setShowDeletePopup(false);
    setShowLoadingPopup(true);

    try {
      windowTypeKey === 'customerID'
        ? await deleteCustomerWindow(id)
        : await deleteVendorWindow(id);

      setShowLoadingPopup(false);
      setShowDeleteSuccessPopup(true);
    } catch(err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '刪除失敗', err }));
      console.log(err);
    }
  };

  const fetchContactWindowDetail = async () => {
    setShowLoadingPopup(true);

    try {
      const { data } = windowTypeKey === 'customerID'
        ? await postToGetCustomerWindowDetail(id)
        : await postToGetVendorWindowDetail(id);

      const {
        birthday,
        businessPhone,
        companyAddress,
        privateAddress,
        ...remainItems
      } = data;

      setContactWindow(prevState => ({
        ...prevState,
        ...remainItems,
        birthday: birthday === '0001-01-01'
          ? null
          : birthday
        ,
        businessPhone: {
          ...prevState.businessPhone,
          ...businessPhone,
        },
        companyAddress: {
          ...prevState.companyAddress,
          ...companyAddress,
        },
        privateAddress: {
          ...prevState.privateAddress,
          ...privateAddress,
        },
      }));

    } catch (err) { console.log(err) };

    setShowLoadingPopup(false);
  };

  const initContactWindowData = useCallback(
    fetchContactWindowDetail, []);
  
  useEffect(() => {
    initContactWindowData();
  }, [initContactWindowData]);

  return (
    <MainContent>
      <PanelContactWindowDetail
        title = "聯絡人資料明細"
        disabled = { !isEditing }
        contactWindow = { contactWindow }
        updateContactWindowHandler = { updateContactWindowHandler }
      >
      {
        <DivFlexRow>
         <DivButtonsWrapper>
            {
              isEditing
                ? <ButtonSave
                    onClick = { updateContactWindowData }
                  >儲存</ButtonSave>
                : <ButtonSave
                    disabled = { !userEditable }
                    onClick = { () => { setIsEditing(true) } }
                  >編輯</ButtonSave>
            }
            <ButtonSave
              disabled = { !userDeletable }
              onClick = { () => {
                setShowDeletePopup(true);
              }}
            >刪除</ButtonSave>
            <ButtonClear
              onClick = { () => { history.goBack() } }
            >離開</ButtonClear>
          </DivButtonsWrapper>
        </DivFlexRow>
      }
      </PanelContactWindowDetail>
      { showLoadingPopup && <PopupLoading/> }
      {
        showDeletePopup &&
          <PopupExecuteConfirm
            title = "刪除聯絡人名單"
            text = "確定刪除此聯絡人資料"
            confirmHandler = { deleteContactWindowHandler }
            cancelHandler = { () => {
              setShowDeletePopup(false);
            }}
          />
      }
      {
        showSaveSuccessPopup &&
          <PopupExecuteResult
            title = "更新成功"
            confirmHandler = { closeSuccessPopupHandler }
          />
      }
      {
        showDeleteSuccessPopup &&
          <PopupExecuteResult
            title = "刪除成功"
            confirmHandler = { () => {
              history.goBack();
            }}
          />
      }
      {
        showSaveFailPopup &&
          <PopupExecuteResult
            title = { apiFailPopupTitle }
            text = { apiFailPopupMessage }
            confirmHandler = { () => {
              dispatch(CLOSE_SAVE_FAIL_POPUP());
            }}
          />
      }
    </MainContent>
  )
};

export default ContactWindowDetail;
