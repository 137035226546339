import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Checkbox from '@/components/UI/Checkbox';
import { TdItem, TableRow } from '@/component-style/Table';
import { ButtonGoDetail } from '@/component-style/Button';

import { getOptionDesc } from '@/utils/textUtils';

const TrWrapper = styled(TableRow)`
  border-bottom: solid 2px #f2f2f2;
  box-sizing: border-box;
  min-width: ${ props => props.showDetailButton
    ? '1320px'
    : '1260px'
  };

  &:last-child {
    border-bottom: none;
  }
`;

const Td60 = styled(TdItem)`
  width: 60px;
`;

const Td80 = styled(TdItem)`
  width: 80px;
`;

const Td120 = styled(TdItem)`
  width: 120px;
`;
const Td160 = styled(TdItem)`
  width: 160px;
`;

const Td300 = styled(TdItem)`
  width: 300px;
`;

const Item = props => {
  const { mediaReport } = props;
  const journalistIDList = useSelector(state => state.srpOptions.journalistIDOptions);

  const journalist = getOptionDesc(journalistIDList, mediaReport.journalistID);

  return (
    <TrWrapper
      showCheckbox = { props.showCheckbox }
      showDetailButton = { props.showDetailButton }
    >
      {
        props.showCheckbox &&
        <Td60>
          <Checkbox
            checked = { mediaReport.isChecked }
            changeHandler = { props.checkHandler }
          />
        </Td60>
      }
      <Td120>{ mediaReport.projectCode }</Td120>
      <Td160>{ mediaReport.projectName }</Td160>
      <Td120>{ mediaReport.origin }</Td120>
      <Td120>{ mediaReport.mediaTypeName }</Td120>
      <Td120>{ mediaReport.mediaName }</Td120>
      <Td120>{ mediaReport.publishDate }</Td120>
      <Td80>{ journalist }</Td80>
      <Td300>{mediaReport.title }</Td300>
      <Td120>{ mediaReport.benefit }</Td120>
      {
        props.showDetailButton &&
          <Td60>
            <ButtonGoDetail
              disabled = { props.disableDetailPage }
              onClick = { props.goDetailHandler }
            >...
            </ButtonGoDetail>
          </Td60>
      }
    </TrWrapper>
  );
};

export default Item;
