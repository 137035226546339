import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

import IconMenu from '../../assets/images/icon_menu.svg';
import { useHeaderTitle } from '../../hooks';
import { Menu, Dropdown } from 'antd';
import { LogoutOutlined, UserOutlined, InfoOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

const DivWrapper = styled.div`
  position: relative;
  display: flex;
  height: 80px;
  align-items: center;
`;

const H3PageTitle = styled.h3`
  width: 100%;
  margin: 0;
  color: #57585a;
  font-size: 24px;
  text-align: center;
`;

const DivUserInfo = styled.div`
  position: absolute;
  right: 30px;
  height: 20px;
  display: flex;
  align-items: center;
  color: #8b8b8b;

  @media (max-width: 375px) {
    display: none;
  }
`;

// const PUserName = styled.p`
//   margin-right: 10px;
//   height: 15px;
//   line-height: 15px;
//   font-size: 13px;
//   font-weight: lighter;
// `;

const GrayRedLink = styled.a`
  color: gray;
  &:hover { color: #de2027 }
`;

const DivIcon = styled.div`
  position: absolute;
  left: 30px;
  cursor: pointer;
  width: 20px;
  height: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${props => props.url});

  @media (min-width: 1025px) {
    display: none;
  }
`;

const Header = props => {
  const username = useSelector(state => state.login.user);
  const title = useHeaderTitle();
  const history = useHistory();

  const handleMenuClick = (e) => {
    console.log(e.key);
    if (e.key === "1") {
      history.push('/AccountSetting');
    } else if (e.key === "2") {
        history.push('/MyInfoSetting');
    } else if (e.key === "3") {
      history.push('/logout');
    }
  }

  const menuUnderAccount = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1" icon={<UserOutlined />}>
        密碼設定
      </Menu.Item>
      <Menu.Item key="2" icon={<InfoOutlined />}>
        個人資訊
      </Menu.Item>
      <Menu.Item key="3" icon={<LogoutOutlined />}>
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <DivWrapper>
      <DivIcon
        url = { IconMenu }
        onClick = { () => { props.openNavigation(true) } }
      />
      <H3PageTitle>{ title }</H3PageTitle>

      <DivUserInfo>
        <Dropdown overlay={menuUnderAccount}>
          <GrayRedLink onClick={e => e.preventDefault()} href="null">
            { username }<span>{'    '}</span><FontAwesomeIcon
          icon = { faAngleDown }
          onClick = { () => { console.log("11111") } }
        />
          </GrayRedLink>
        </Dropdown>
      </DivUserInfo>
      {/* <DivUserInfo>
      <PUserName>{ username }</PUserName>
        <FontAwesomeIcon
          icon = { faAngleDown }
          onClick = { () => { console.log("11111") } }
        />
      </DivUserInfo> */}
    </DivWrapper>
  );
};

export default Header;
