import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import Checkbox from '@/components/UI/Checkbox';
import StatusCheck from '@/components/UI/StatusCheck';
import DateSelector from '@/components/UI/DateSelector';
import { TdItem, TableRow } from '@/component-style/Table';
import { ButtonBasic } from '@/component-style/Button';

import { getOptionDesc } from '@/utils/textUtils';

import { decimalCommaConverter } from '@/utils/textUtils';

const TrWrapper = styled(TableRow)`
  border-bottom: solid 2px #f2f2f2;
  box-sizing: border-box;
  min-width: 1160px;

  &:last-child {
    border-bottom: none;
  }
`;

const DivSelectWrapper = styled.div`
  & .datePicker { width: 200px }

  @media (min-width: 1920px) {
    .datePicker { width: 200px; }
  }

  @media (max-width: 320px) {
    .datePicker { width: 200px; }
  };
`;

const Td80 = styled(TdItem)`
  width: 80px;
`;

const Td120 = styled(TdItem)`
  width: 120px;
`;

const Td160 = styled(TdItem)`
  width: 160px;
`;

const Td280 = styled(TdItem)`
  width: 280px;
`;

const ButtonCancel = styled(ButtonBasic)`
  width: 80px;
`;

const Item = props => {
  const { expense } = props;
  
  const memberOptions = useSelector(state => state.srpOptions.memberOptions);
  const username = getOptionDesc(memberOptions, expense.user);


  return (
    <TrWrapper>
      <Td80>
        <Checkbox
          disabled = { expense.predictDate !== null }
          checked = { expense.isChecked }
          changeHandler = { props.checkHandler }
        />
      </Td80>
      <Td120>{ expense.expenseCode }</Td120>
      <Td120>{ expense.projectCode }</Td120>
      <Td160>{ expense.projectName }</Td160>
      <Td120>{ username }</Td120>
      <Td80>{ expense.price == null ? '' : decimalCommaConverter(expense.price) }</Td80>
      <Td80>
        <StatusCheck
          checked = { expense.predictDate !== null }
        />
      </Td80>
      <Td280>
        <DivSelectWrapper>
          <DateSelector
            showTime = { true }
            disabled = { expense.predictDate !== null || expense.isChecked === false }
            value = { expense.updateTime }
            okHandler = { props.updatePredictTimeHandler }
            changeHandler = { props.updatePredictTimeHandler }
          />
        </DivSelectWrapper>
      </Td280>
      <Td120>
        <ButtonCancel
          disabled = { expense.predictDate === null }
          onClick = { props.cancelHandler }
        >取消付款</ButtonCancel>
      </Td120>
    </TrWrapper>
  );
};

export default Item;
