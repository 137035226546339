import React from 'react';
import styled from 'styled-components';

import { TdItem, TableRow } from '@/component-style/Table';
import { ButtonBasic } from '@/component-style/Button';

const TrWrapper = styled(TableRow)`
  border-bottom: solid 2px #f2f2f2;
  box-sizing: border-box;
  min-width: 800px;

  &:last-child {
    border-bottom: none;
  }
`;

const Td120 = styled(TdItem)`
  width: 120px;
`;

const Td160 = styled(TdItem)`
  width: 160px;
`;

const ButtonUnlock = styled(ButtonBasic)`
  width: 80px;
`;

const Item = props => {
  const { project } = props;

  return (
    <TrWrapper>
      <Td120>{ project.projectCode }</Td120>
      <Td160>{ project.projectName }</Td160>
      <Td120>{ project.start }</Td120>
      <Td120>{ project.end }</Td120>
      <Td160>{ project.unlockTime }</Td160>
      <Td120>
        <ButtonUnlock
          disabled = { !props.unlockable }
          onClick = { props.financeUnlockHandler }
        >解鎖</ButtonUnlock>
      </Td120>
    </TrWrapper>
  );
};

export default Item;
