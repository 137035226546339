import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Table, TableBody } from '@/component-style/Table';
import { ButtonBasic } from '@/component-style/Button';
import PageSelector from '@/components/UI/PageSelector';
import Header from './Header';
import Item from './Item';

const DivWrapper = styled.div`
  width: 100%;
  border-top: 2px solid #de2027;
  background-color: #ffffff;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
`;

const TitleWrapper = styled.div`
  margin-top: 35px;
  margin-bottom: 20px;
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const H3Title = styled.h3`
  margin: 0;
  font-size: 20px;
  font-weight: normal;
  color: #57585a;
`;

const ButtonDelete = styled(ButtonBasic)`
  width: 120px;
  height: 40px;
`;

const TableJournalists = props => {
  const history = useHistory();
  const informationRoutes = useSelector(state => state.login.informationRoutes);

  const userDeletable = informationRoutes.indexOf('JournalistManagement-Delete') !== -1;
  const disableDetailPage = informationRoutes.indexOf('JournalistDetail') === -1;

  return (
    <DivWrapper>
      <TitleWrapper>
        <H3Title>記者列表</H3Title>
        <ButtonDelete
          disabled = { props.disableDelete || !userDeletable }
          onClick = { props.deleteHandler }
        >刪除</ButtonDelete>
      </TitleWrapper>
      <Table>
        <Header
          selectAll = { props.selectAll }
          selectAllHandler = { props.selectAllHandler }
        />
        <TableBody>
          {
            props.journalists.map((journalist, index) => 
              <Item
                key = { journalist.journalistID }
                journalist = { journalist }
                disableDetailPage = { disableDetailPage }
                checkHandler = { value => {
                  props.checkHandler(index, value);
                }}
                goDetailHandler = { () => {
                  history.push(`/Information/JournalistDetail/${journalist.journalistID}`)
                }}
              />
            )
          }
        </TableBody>
      </Table>
      <PageSelector
        current = { props.currentPage }
        total = { props.totalCount }
        pageChangedHandler = { props.pageChangedHandler }
        showSizeChanger = { false }
      />
    </DivWrapper>
  );
};

export default TableJournalists;
