import React from 'react';

import TableWrapper from '@/components/UI/TableWrapper';
import Checkbox from '@/components/UI/Checkbox';
import DateSelector from '@/components/UI/DateSelector';
import SelectNormal from '@/components/UI/Select/SelectNormal';
import SelectMultiple from '@/components/UI/Select/SelectMultiple';
import SelectSearchable from '@/components/UI/Select/SelectSearchable';
import AddressInfoSelector from '@/components/UI/AddressInfoSelector';
import PhoneInfoComponent from '@/components/UI/PhoneInfoComponent';
import { InputBasic, TextareaBasic } from '@/component-style/Input';
import { PSubtitle } from '@/component-style/TextElement';
import {
  DivFlexRow,
  DivItemWrapper,
  DivFullWidthWrapper,
  DivLongMultiSelector,
} from '@/component-style/RWDWrapper';

import {
  genderOptions,
  taiwanAreaOptions,
} from '@/constant/options';
import { formatTime } from '@/utils/timeUtils';

const PanelJournalistDetail = props => {
  const { journalist } = props;

  return (
    <TableWrapper title = { props.title }>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>
            媒體名稱
            <span>*</span>
          </PSubtitle>
          <SelectSearchable
            disabled = { props.disabled }
            value = { journalist.mediaID }
            options = {[
              { value: '', desc: '請選擇媒體' },
              ...props.mediaIDList,
            ]}
            changeHandler = { value => {
              props.updateJournalistHandler('mediaID', value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>性別</PSubtitle>
          <SelectNormal
            disabled = { props.disabled }
            value = { journalist.gender }
            options = { genderOptions }
            changeHandler = { value => {
              props.updateJournalistHandler('gender', value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>
            記者姓名
            <span>*</span>
          </PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { journalist.name }
            onChange = {({ target }) => {
              props.updateJournalistHandler('name', target.value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>記者姓名 (英)</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { journalist.englishName }
            onChange = {({ target }) => {
              props.updateJournalistHandler('englishName', target.value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>
            職稱
            <span>*</span>
          </PSubtitle>
          <div>
            <InputBasic
              style = {{ marginBottom: '10px' }}
              disabled = { props.disabled }
              value = { journalist.jobTitle }
              onChange = {({ target }) => {
                props.updateJournalistHandler('jobTitle', target.value);
              }}
            />
            <Checkbox title = "在職"
              disabled = { props.disabled }
              checked = { journalist.online }
              changeHandler = { value => {
                props.updateJournalistHandler('online', value);
              }}
            />
          </div>
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>職稱 (英)</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { journalist.jobTitleEng }
            onChange = {({ target }) => {
              props.updateJournalistHandler('jobTitleEng', target.value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>商務電話</PSubtitle>
          <PhoneInfoComponent
            disabled = { props.disabled }
            phoneInfo = { journalist.businessPhone }
            updatePhoneInfoHandler = { value => {
              props.updateJournalistHandler('businessPhone', value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>商務傳真</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { journalist.fax }
            onChange = {({ target }) => {
              props.updateJournalistHandler('fax', target.value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>行動電話 (公)</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { journalist.businessMobile }
            onChange = {({ target }) => {
              props.updateJournalistHandler('businessMobile', target.value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>行動電話 (私)</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { journalist.privateMobile }
            onChange = {({ target }) => {
              props.updateJournalistHandler('privateMobile', target.value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>電子郵件 (公)</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { journalist.email1 }
            onChange = {({ target }) => {
              props.updateJournalistHandler('email1', target.value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>電子郵件 (私)</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { journalist.email2 }
            onChange = {({ target }) => {
              props.updateJournalistHandler('email2', target.value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>區域</PSubtitle>
          <SelectNormal
            disabled = { props.disabled }
            value = { journalist.area }
            options = {[
              { value: '', desc: '請選擇區域' },
              ...taiwanAreaOptions,
            ]}
            changeHandler = { value => {
              props.updateJournalistHandler('area', value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>所屬版面 / 版別</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { journalist.page }
            onChange = {({ target }) => {
              props.updateJournalistHandler('page', target.value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivFullWidthWrapper>
          <PSubtitle>商務地址</PSubtitle>
          <AddressInfoSelector
            disabled = { props.disabled }
            address = { journalist.companyAddress }
            updateAddressHandler = { value => {
              props.updateJournalistHandler('companyAddress', value);
            }}
          />
        </DivFullWidthWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivFullWidthWrapper>
          <PSubtitle>住家地址</PSubtitle>
          <AddressInfoSelector
            disabled = { props.disabled }
            address = { journalist.privateAddress }
            updateAddressHandler = { value => {
              props.updateJournalistHandler('privateAddress', value);
            }}
          />
        </DivFullWidthWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivFullWidthWrapper>
          <PSubtitle>採訪路線</PSubtitle>
          <DivLongMultiSelector>
            <SelectMultiple
              disabled = { props.disabled }
              value = { journalist.type }
              options = {[ ...props.journalistTypes ]}
              changeHandler = { value => {
                props.updateJournalistHandler('type', value);
              }}
            />
          </DivLongMultiSelector>
        </DivFullWidthWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>生日</PSubtitle>
          <DateSelector
            disabled = { props.disabled }
            value = { journalist.birthday }
            changeHandler = { value => {
              props.updateJournalistHandler(
                'birthday',
                formatTime(value, 'YYYY-MM-DD'),
              );
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>Facebook</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { journalist.facebook }
            onChange = {({ target }) => {
              props.updateJournalistHandler('facebook', target.value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>Line</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { journalist.lineId }
            onChange = {({ target }) => {
              props.updateJournalistHandler('lineId', target.value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>Skype</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { journalist.skype }
            onChange = {({ target }) => {
              props.updateJournalistHandler('skype', target.value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivFullWidthWrapper>
          <PSubtitle>其它通訊軟體</PSubtitle>
          <DivLongMultiSelector>
            <TextareaBasic
              disabled = { props.disabled }
              value = { journalist.otherCommunicationApp }
              onChange = {({ target }) => {
                props.updateJournalistHandler('otherCommunicationApp', target.value);
              }}
            />
          </DivLongMultiSelector>
        </DivFullWidthWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivFullWidthWrapper>
          <PSubtitle>資歷與特性</PSubtitle>
          <DivLongMultiSelector>
            <TextareaBasic
              disabled = { props.disabled }
              value = { journalist.jobDesc }
              onChange = {({ target }) => {
                props.updateJournalistHandler('jobDesc', target.value);
              }}
            />
          </DivLongMultiSelector>
        </DivFullWidthWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivFullWidthWrapper>
          <PSubtitle>備註</PSubtitle>
          <DivLongMultiSelector>
            <TextareaBasic
              disabled = { props.disabled }
              value = { journalist.note }
              onChange = {({ target }) => {
                props.updateJournalistHandler('note', target.value);
              }}
            />
          </DivLongMultiSelector>
        </DivFullWidthWrapper>
      </DivFlexRow>
      { props.children }
    </TableWrapper>
  );
};

export default PanelJournalistDetail;
