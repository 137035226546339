import React from 'react';

import TableWrapper from '@/components/UI/TableWrapper';
import Checkbox from '@/components/UI/Checkbox';
import SelectNormal from '@/components/UI/Select/SelectNormal';
import SelectMultiple from '@/components/UI/Select/SelectMultiple';
import AddressInfoSelector from '@/components/UI/AddressInfoSelector';
import { InputBasic, TextareaBasic } from '@/component-style/Input';
import { PSubtitle } from '@/component-style/TextElement';
import {
  DivFlexRow,
  DivItemWrapper,
  DivFullWidthWrapper,
  DivLongMultiSelector,
} from '@/component-style/RWDWrapper';

const PanelVenueDetail = props => {
  const { venue } = props;

  return (
    <TableWrapper title = { props.title }>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>
            場地名稱
            <span>*</span>
          </PSubtitle>
          <div>
            <InputBasic
              style = {{ marginBottom: '10px' }}
              disabled = { props.disabled }
              value = { venue.name }
              onChange = {({ target }) => {
                props.updateVenueHandler('name', target.value);
              }}
            />
            <Checkbox
              title = "已歇業"
              disabled = { props.disabled }
              checked = { venue.shutDown }
              changeHandler = { value => {
                props.updateVenueHandler('shutDown', value);
              }}
            />
          </div>
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>場地名稱 (英)</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { venue.nameEng }
            onChange = {({ target }) => {
              props.updateVenueHandler('nameEng', target.value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>
            電話
            <span>*</span>
          </PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { venue.phone }
            onChange = {({ target }) => {
              props.updateVenueHandler('phone', target.value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>網址</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { venue.website }
            onChange = {({ target }) => {
              props.updateVenueHandler('website', target.value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivFullWidthWrapper>
          <PSubtitle>
            場地地址
            <span>*</span>
          </PSubtitle>
          <AddressInfoSelector
            disabled = { props.disabled }
            address = { venue.address }
            updateAddressHandler = { value => {
              props.updateVenueHandler('address', value);
            }}
          />
        </DivFullWidthWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>
            空間名稱
            <span>*</span>
          </PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { venue.basicInfo.name }
            onChange = {({ target }) => {
              props.updateVenueHandler('basicInfo', {
                ...venue.basicInfo,
                name: target.value,
              });
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>
            空間類型
            <span>*</span>
          </PSubtitle>
          <SelectNormal
            disabled = { props.disabled }
            value = { venue.basicInfo.type }
            options = {[
              { value: '', desc: '請選擇類型' },
              ...props.venueTypeOptions,
            ]}
            changeHandler = { value => {
              props.updateVenueHandler('basicInfo', {
                ...venue.basicInfo,
                type: value,
              });
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivFullWidthWrapper>
          <PSubtitle>特色標籤</PSubtitle>
          <DivLongMultiSelector>
            <SelectMultiple
              disabled = { props.disabled }
              value = { venue.basicInfo.characteristics }
              options = { props.venueCharacteristics }
              changeHandler = { value => {
                props.updateVenueHandler('basicInfo', {
                  ...venue.basicInfo,
                  characteristics: [...value],
                });
              }}
            />
          </DivLongMultiSelector>
        </DivFullWidthWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivFullWidthWrapper>
          <PSubtitle>設備標籤</PSubtitle>
          <DivLongMultiSelector>
            <SelectMultiple
              disabled = { props.disabled }
              value = { venue.basicInfo.equipments }
              options = { props.venueEquipments }
              changeHandler = { value => {
                props.updateVenueHandler('basicInfo', {
                  ...venue.basicInfo,
                  equipments: [...value],
                });
              }}
            />
          </DivLongMultiSelector>
        </DivFullWidthWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>上午場租</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { venue.fees.morning }
            onChange = {({ target }) => {
              props.updateVenueHandler('fees', {
                ...venue.fees,
                morning: target.value,
              });
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>下午場租</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { venue.fees.afternoon }
            onChange = {({ target }) => {
              props.updateVenueHandler('fees', {
                ...venue.fees,
                afternoon: target.value,
              });
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>全日場租</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { venue.fees.allDay }
            onChange = {({ target }) => {
              props.updateVenueHandler('fees', {
                ...venue.fees,
                allDay: target.value,
              });
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>白天場租</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { venue.fees.day }
            onChange = {({ target }) => {
              props.updateVenueHandler('fees', {
                ...venue.fees,
                day: target.value,
              });
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>晚間場租</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { venue.fees.evenining }
            onChange = {({ target }) => {
              props.updateVenueHandler('fees', {
                ...venue.fees,
                evenining: target.value,
              });
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>夜間場佈</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { venue.fees.decorateAtNight }
            onChange = {({ target }) => {
              props.updateVenueHandler('fees', {
                ...venue.fees,
                decorateAtNight: target.value,
              });
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>逾時費用</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { venue.fees.overtime }
            onChange = {({ target }) => {
              props.updateVenueHandler('fees', {
                ...venue.fees,
                overtime: target.value,
              });
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>平均消費</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { venue.basicInfo.avgCost }
            onChange = {({ target }) => {
              props.updateVenueHandler('basicInfo', {
                ...venue.basicInfo,
                avgCost: target.value,
              });
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>所在樓層</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { venue.spaceInfo.floor }
            onChange = {({ target }) => {
              props.updateVenueHandler('spaceInfo', {
                ...venue.spaceInfo,
                floor: target.value,
              });
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>坪數</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { venue.spaceInfo.size }
            onChange = {({ target }) => {
              props.updateVenueHandler('spaceInfo', {
                ...venue.spaceInfo,
                size: target.value,
              });
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>樓高</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { venue.spaceInfo.height }
            onChange = {({ target }) => {
              props.updateVenueHandler('spaceInfo', {
                ...venue.spaceInfo,
                height: target.value,
              });
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>平方公尺</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { venue.spaceInfo.squareMeter }
            onChange = {({ target }) => {
              props.updateVenueHandler('spaceInfo', {
                ...venue.spaceInfo,
                squareMeter: target.value,
              });
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>可容納人數</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { venue.capacity.total }
            onChange = {({ target }) => {
              props.updateVenueHandler('capacity', {
                ...venue.capacity,
                total: target.value,
              });
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>劇院型</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { venue.capacity.theater }
            onChange = {({ target }) => {
              props.updateVenueHandler('capacity', {
                ...venue.capacity,
                theater: target.value,
              });
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>教室型</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { venue.capacity.classroom }
            onChange = {({ target }) => {
              props.updateVenueHandler('capacity', {
                ...venue.capacity,
                classroom: target.value,
              });
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>馬蹄型</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { venue.capacity.horseshoe }
            onChange = {({ target }) => {
              props.updateVenueHandler('capacity', {
                ...venue.capacity,
                horseshoe: target.value,
              });
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>口字型</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { venue.capacity.square }
            onChange = {({ target }) => {
              props.updateVenueHandler('capacity', {
                ...venue.capacity,
                square: target.value,
              });
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>酒會</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { venue.capacity.cocktailParty }
            onChange = {({ target }) => {
              props.updateVenueHandler('capacity', {
                ...venue.capacity,
                cocktailParty: target.value,
              });
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>圓桌</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { venue.capacity.roundTable }
            onChange = {({ target }) => {
              props.updateVenueHandler('capacity', {
                ...venue.capacity,
                roundTable: target.value,
              });
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>小圓桌</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { venue.capacity.smalllRoundTable }
            onChange = {({ target }) => {
              props.updateVenueHandler('capacity', {
                ...venue.capacity,
                smalllRoundTable: target.value,
              });
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivFullWidthWrapper>
          <PSubtitle>包廂資訊</PSubtitle>
          <DivLongMultiSelector>
            <TextareaBasic
              disabled = { props.disabled }
              value = { venue.basicInfo.roomInfo }
              options = { props.venueEquipments }
              onChange = {({ target }) => {
                props.updateVenueHandler('basicInfo', {
                  ...venue.basicInfo,
                  roomInfo: target.value,
                });
              }}
            />
          </DivLongMultiSelector>
        </DivFullWidthWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivFullWidthWrapper>
          <PSubtitle>備註</PSubtitle>
          <DivLongMultiSelector>
            <TextareaBasic
              disabled = { props.disabled }
              value = { venue.basicInfo.note }
              options = { props.venueEquipments }
              onChange = {({ target }) => {
                props.updateVenueHandler('basicInfo', {
                  ...venue.basicInfo,
                  note: target.value,
                });
              }}
            />
          </DivLongMultiSelector>
        </DivFullWidthWrapper>
      </DivFlexRow>
      { props.children }
    </TableWrapper>
  );
};

export default PanelVenueDetail;
