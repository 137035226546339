import React from 'react';
import styled from 'styled-components';

import { TableHead, TableRow, ThItem } from '@/component-style/Table';

const THead = styled(TableHead)`
  min-width: 840px;
`;

const Th120 = styled(ThItem)`
  width: 120px;
`;

const Th160 = styled(ThItem)`
  width: 160px;
`;

const Th80 = styled(ThItem)`
  width: 80px;
`;

const Header = props => {
  
  return (
    <THead>
      <TableRow>
        <Th160>加、減項</Th160>
        <Th160>名稱</Th160>
        <Th160>開始月份</Th160>
        <Th160>月數</Th160>
        <Th160>每個月金額</Th160>
        <Th160>是否撥付</Th160>
        { props.removable ?
          <Th80>刪除</Th80> : ""
        }
      </TableRow>
    </THead>
  );
};

export default Header;
