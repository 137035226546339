import { put, call, takeEvery } from 'redux-saga/effects';
import * as types from '../action-types/srpOptionsActionTypes';

import {
  UPDATE_SYSTEM_ROLE_OPTIONS,
  UPDATE_MEMBER_OPTIONS,
  UPDATE_DIRECTOR_OPTIONS,
  UPDATE_PROJECT_MANAGER_OPTIONS,
  UPDATE_PROJECT_ID_OPTIONS,
  UPDATE_PROJECT_CODE_OPTIONS,
  UPDATE_PROJECT_TYPE_OPTIONS,
  UPDATE_TODO_TYPE_OPTIONS,
  UPDATE_TODO_TAG_DICTIONARY,
  UPDATE_CUSTOMER_OPTIONS,
  UPDATE_CUSTOMER_INDUSTRIES,
  UPDATE_CONTACT_WINDOW_OPTIONS,
  UPDATE_MEDIA_ID_OPTIONS,
  UPDATE_MEDIA_TYPES,
  UPDATE_VENDOR_ID_OPTIONS,
  UPDATE_VENDOR_ID_OPTIONS_TAXID,
  UPDATE_VENDOR_TYPE_OPTIONS,
  UPDATE_VENDOR_TAG_DICTIONARY,
  UPDATE_VENUE_TYPE_OPTIONS,
  UPDATE_VENUE_EQUIPMENTS,
  UPDATE_VENUE_CHARACTERISTICS,
  UPDATE_JOURNALIST_TYPES,
  UPDATE_JOURNALIST_ID_OPTIONS,
  UPDATE_ACTIVITY_IDEA_TYPE_OPTIONS,
  UPDATE_ACTIVITY_IDEA_TAG_DICTIONARY,
  UPDATE_EXPENSE_TYPE_OPTIONS,
  UPDATE_EXPENSE_TAG_DICTIONARY,
  UPDATE_DEPARTMENT_OPTIONS,
  UPDATE_COMPANY_TYPE_OPTIONS,
  UPDATE_SPECIAL_FEATURES,
  UPDATE_DECLARE_TYPE_OPTIONS,
  UPDATE_WORKINGHOURS_TYPE_OPTIONS,
  UPDATE_WORKINGHOURS_TAG_DICTIONARY,
} from '../actions/srpOptionsAction';

import {
  postToGetUsers,
  postToGetSystemRoles,
} from '@/api/authService';

import {
  postToGetProjectCodes,
  postToGetProjectTypes,
} from '@/api/projectService';

import {
  getMediaIDs,
  postToGetMediaTypes,
} from '@/api/mediaService';

import {
  postToGetJournalistIDs,
  postToGetJournalistTypes,
} from '@/api/journalistService';

import {
  postToGetDepartment,
  postToGetCompanyType,
  postToGetDeclareType,
  postToGetSpecialFeature,
} from '@/api/sysConfigService';

import { postToGetWorkingHoursTypes } from '@/api/attendanceService';
import { postToGetActivityIdeaTypes } from '@/api/activityIdeaService';
import { postToGetProjectTodoTypes } from '@/api/projectTodoService';

import {
  postToGetVendorIDs,
  postToGetVendorTypes,
  postToGetVendorWindowList,
} from '@/api/vendorService';

import {
  postToGetCustomerIDs,
  postToGetCustomerIndustry,
  postToGetCustomerWindowList,
} from '@/api/customerService';

import {
  postToGetVenueTypes,
  postToGetEquipments,
  postToGetCharacteristics,
} from '@/api/venueService';

import { postToGetExpenseTypes } from '@/api/expenseService';

import { vendorOptionTaxIDConverter } from '@/utils/textUtils';

function* fetchUserIDs() {
  try {
    const { data } = yield call(
      postToGetUsers,
      { page: 1, amount: 1 }
    );

    const usersDetail = yield call(
      postToGetUsers,
      {page: 1, amount: data.totalCount || 1 }
    );

    const { users } = usersDetail.data;
    const options = users
      .map(({ userID, name }) => ({ value: userID, desc: name}));

    yield put(UPDATE_MEMBER_OPTIONS(options));
  } catch (err) { console.log(err) }
};

function* fetchDirectors() {
  try {
    const { data } = yield call(
      postToGetUsers,
      { projectRole: ['director'], page: 1, amount: 1 }
    );

    const usersDetail = yield call(
      postToGetUsers,
      { projectRole: ['director'], page: 1, amount: data.totalCount || 1 }
    );

    const { users } = usersDetail.data;
    const options = users
      .map(({ userID, name }) => ({ value: userID, desc: name }));

    yield put(UPDATE_DIRECTOR_OPTIONS(options));
  } catch (err) { console.log(err) }
};

function* fetchProjectManagers() {
  try {
    const { data } = yield call(
      postToGetUsers,
      { projectRole: ['projectManager'], page: 1, amount: 1 }
    );

    const usersDetail = yield call(
      postToGetUsers,
      { projectRole: ['projectManager'], page: 1, amount: data.totalCount || 1 }
    );

    const { users } = usersDetail.data;
    const options = users
      .map(({ userID, name }) => ({ value: userID, desc: name }));

    yield put(UPDATE_PROJECT_MANAGER_OPTIONS(options));
  } catch (err) { console.log(err) }
};

function* fetchProjectCodes() {

  try {
    const { data } = yield call(postToGetProjectCodes);
    const options = data
      .map(({ projectCode, projectName }) =>({
        value: projectCode,
        desc: `${projectCode}-${projectName}`,
      }))
      .sort((a, b) => a.desc > b.desc ? 1 : -1);
    yield put(UPDATE_PROJECT_CODE_OPTIONS(options));
  } catch (err) {
    console.log(err);
  }
};

function* fetchProjectIDs(action) {
  const { payload } = action;

  try {
    const { data } = yield call(postToGetProjectCodes, payload);
    const options = data
      .map(({ projectID, projectCode, projectName }) =>({
        value: projectID,
        desc: `${projectCode}-${projectName}`,
      }))
      .sort((a, b) => a.desc > b.desc ? 1 : -1);
    yield put(UPDATE_PROJECT_ID_OPTIONS(options));
  } catch (err) {
    console.log(err);
  }
};

function* fetchProjectTypes(action) {
  const { payload } = action;
  const enable = (payload && payload.enable) || null;

  try {
    const { data } = yield call(
      postToGetProjectTypes,
      { enable, page: 1, amount: 1 }
    );

    const typesDetail = yield call(
      postToGetProjectTypes,
      { enable, page: 1, amount: data.totalCount || 1 }
    );

    const { types } = typesDetail.data;
    const options = types
      .map(({ id, name }) => ({ value: id, desc: name}))
      .sort((a, b) => a.desc > b.desc ? 1 : -1);

    yield put(UPDATE_PROJECT_TYPE_OPTIONS(options));
  } catch (err) { console.log(err) }

};

function* fetchProjectTodoTypes(action) {
  const { payload } = action;
  const enable = (payload && payload.enable) || null;
  const enableTags = payload && payload.enableTags;

  try {
    const { data } = yield call(
      postToGetProjectTodoTypes,
      { enable, page: 1, amount: 1 }
    );

    const typesDetail = yield call(
      postToGetProjectTodoTypes,
      { enable, page: 1, amount: data.totalCount || 1 }
    );

    const { types } = typesDetail.data;
    const options = types
      .map(({ todoTypeID, name }) => ({
        value: todoTypeID,
        desc: name,
      }));

    const todoTagDictionary = types
      .reduce((acc, cur) => {
        const { todoTypeID, tags } = cur;
        acc[todoTypeID] = tags
        .filter(tag => enableTags === undefined ? true : tag.enable === enableTags)
          .map(({ name }) => ({ value: name, desc: name}));
        return { ...acc };
      }, {});

    yield put(UPDATE_TODO_TYPE_OPTIONS(options));
    yield put(UPDATE_TODO_TAG_DICTIONARY(todoTagDictionary));
  } catch (err) { console.log(err) }
};

function* fetchCustomerOptions() {
  try {
    const { data } = yield call(postToGetCustomerIDs);
    const options = data
      .map(({ customerID, customerName }) => ({
        value: customerID,
        desc: customerName,
      }))
      .sort((a, b) => a.desc > b.desc ? 1 : -1);
    yield put(UPDATE_CUSTOMER_OPTIONS(options));
  } catch (err) {
    console.log(err);
  }
};

function* fetchContactWindows(action) {
  const { payload } = action;
  const { type, companyName } = payload

  try {
    const { data } = type === 'customer'
      ? yield call(postToGetCustomerWindowList)
      : yield call(postToGetVendorWindowList);

    const options = data
      .map(contactInfo => ({
        value: contactInfo.contactWindowID,
        desc: companyName === true
          ? `${contactInfo.contactWindowName} - ${ type === 'customer'
            ? contactInfo.customerName : contactInfo.vendorName}`
          : contactInfo.contactWindowName,
        vendorID: type !== 'customer' ? contactInfo.vendorID : null,
        customerID: type === 'customer' ? contactInfo.customerID : null,
      }))
      .sort((a, b) => a.desc > b.desc ? 1 : -1);
    yield put(UPDATE_CONTACT_WINDOW_OPTIONS(options));
  } catch (err) {
    console.log(err);
  }
};

function* fetchCustomerIndustries(action) {
  const { payload } = action;
  const enable = (payload && payload.enable) || null;

  try {
    const { data } = yield call(
      postToGetCustomerIndustry,
      { enable, page: 1, amount: 1 }
    );

    const industryDetail = yield call(
      postToGetCustomerIndustry,
      { enable, page: 1, amount: data.totalCount || 1 }
    );

    const { industries } = industryDetail.data;

    const options = industries
      .map(({ id, name }) => ({
        value: id,
        desc: name,
      }))
      .sort((a, b) => a.desc > b.desc ? 1 : -1);
    yield put(UPDATE_CUSTOMER_INDUSTRIES(options));
  } catch (err) {
    console.log(err);
  }
};

function* fetchMediaIDs() {
  try {
    const { data } = yield call(getMediaIDs);
    const options = data
      .map(({ mediaID, mediaName }) => ({ value: mediaID, desc: mediaName }))
      .sort((a, b) => a.desc > b.desc ? 1 : -1);

    yield put(UPDATE_MEDIA_ID_OPTIONS(options));
  } catch (err) { console.log(err) }
};

function* fetchMediaTypes(action) {
  const { payload } = action;
  const enable = (payload && payload.enable) || null;

  try {
    const { data } = yield call(
      postToGetMediaTypes,
      { enable, page: 1, amount: 1 }
    );

    const typesDetail = yield call(
      postToGetMediaTypes,
      { enable, page: 1, amount: data.totalCount || 1 }
    );

    const { types } = typesDetail.data;
    const options = types
      .map(({ id, name }) => ({ value: id, desc: name }))
      .sort((a, b) => a.desc > b.desc ? 1 : -1);
    
    yield put(UPDATE_MEDIA_TYPES(options));
  } catch (err) { console.log(err) }
};

function* fetchVenueTypes(action) {
  const { payload } = action;
  const enable = (payload && payload.enable) || null;

  try {
    const { data } = yield call(
      postToGetVenueTypes,
      { enable, page: 1, amount: 1 }
    );

    const typesDetail = yield call(
      postToGetVenueTypes,
      { enable, page: 1, amount: data.totalCount || 1 }
    );

    const { type } = typesDetail.data;
    const options = type
      .map(({ id, name }) => ({ value: id, desc: name }));

    yield put(UPDATE_VENUE_TYPE_OPTIONS(options));
  } catch (err) { console.log(err) }
};

function* fetchVenueEquipments(action) {
  const { payload } = action;
  const enable = (payload && payload.enable) || null;

  try {
    const { data } = yield call(
      postToGetEquipments,
      { enable, page: 1, amount: 1 }
    );

    const equipmentsDetail = yield call(
      postToGetEquipments,
      { enable, page: 1, amount: data.totalCount || 1 }
    );

    const { equipments } = equipmentsDetail.data;
    const options = equipments
      .map(({ id, name }) => ({ value: id, desc: name }));

    yield put(UPDATE_VENUE_EQUIPMENTS(options));
  } catch (err) { console.log(err) }
};

function* fetchVenueCharacteristics(action) {
  const { payload } = action;
  const enable = (payload && payload.enable) || null;

  try {
    const { data } = yield call(
      postToGetCharacteristics,
      { enable, page: 1, amount: 1 }
    );

    const characteristicsDetail = yield call(
      postToGetCharacteristics,
      { enable, page: 1, amount: data.totalCount || 1 }
    );

    const { characteristics } = characteristicsDetail.data;
    const options = characteristics
      .map(({ id, name }) => ({ value: id, desc: name }));

    yield put(UPDATE_VENUE_CHARACTERISTICS(options));
  } catch (err) {
    console.log(err);
  }
};

function* fetchVendorIDs(action) {
  const { payload } = action;

  try {
    const { data } = yield call(postToGetVendorIDs, payload);
    const options = data
      .map(vendor => {
        const available = vendor.available
          ? '' : '(已禁用)';
        const financeApproved = vendor.financeApproved
          ? '' : '(未審核)';
        const desc = `${vendor.vendorName}${available}${financeApproved}`;
        return { desc, value: vendor.vendorID }
      });
    
    const taxIDOptions = data
      .map(vendor => {
        const available = vendor.available
          ? '' : '(已禁用)';
        const financeApproved = vendor.financeApproved
          ? '' : '(未審核)';
        const desc = `${vendor.vendorName}(${vendorOptionTaxIDConverter(vendor.taxId)})${available}${financeApproved}`;
        return { desc, value: vendor.vendorID }
      });
    
    console.log('taxIDOptions', taxIDOptions);

    yield put(UPDATE_VENDOR_ID_OPTIONS(options));
    yield put(UPDATE_VENDOR_ID_OPTIONS_TAXID(taxIDOptions));
  } catch(err) { console.log(err) }
};

function* fetchVendorTypes(action) {
  const { payload } = action;
  const enable = (payload && payload.enable) || null;
  const enableTags = payload && payload.enableTags;

  try {
    const { data } = yield call(
      postToGetVendorTypes,
      { enable, page: 1, amount: 1 }
    );

    const typesDetail = yield call(
      postToGetVendorTypes,
      { enable, page: 1, amount: data.totalCount || 1 }
    );

    const { types } = typesDetail.data;
    const options = types
      .map(({ vendorTypeID, name }) => ({
        value: vendorTypeID,
        desc: name,
      }))
      .sort((a, b) => a.desc > b.desc ? 1 : -1);

    const vendorTagDictionary = types
      .reduce((acc, cur) => {
        const { vendorTypeID, tags } = cur;
        acc[vendorTypeID] = tags
          .filter(tag => enableTags === undefined ? true : tag.enable === enableTags)
          .map(({ name }) => ({ value: name, desc: name}));
        return { ...acc };
      }, {});
    yield put(UPDATE_VENDOR_TYPE_OPTIONS(options));
    yield put(UPDATE_VENDOR_TAG_DICTIONARY(vendorTagDictionary));
  } catch (err) { console.log(err) }
};

function* fetchActivityIdeaTypes(action) {
  const { payload } = action;
  const enable = (payload && payload.enable) || null;
  const enableTags = payload && payload.enableTags;

  try {
    const { data } = yield call(
      postToGetActivityIdeaTypes,
      { enable, page: 1, amount: 1 }
    );

    const typesDetail = yield call(
      postToGetActivityIdeaTypes,
      { enable, page: 1, amount: data.totalCount || 1 }
    );

    const { types } = typesDetail.data;
    const options = types
      .map(({ id, name }) => ({ value: id, desc: name }));

    const activityIdeaTagDictionary = types
      .reduce((acc, cur) => {
        const { id, tags } = cur;
        acc[id] = tags
          .filter(tag => enableTags === undefined ? true : tag.enable === enableTags)
          .map(({ name }) => ({ value: name, desc: name}));
        return { ...acc };
      }, {});
    yield put(UPDATE_ACTIVITY_IDEA_TYPE_OPTIONS(options));
    yield put(UPDATE_ACTIVITY_IDEA_TAG_DICTIONARY(activityIdeaTagDictionary));
  } catch (err) { console.log(err) }
};

function* fetchJournalistTypes(action) {
  const { payload } = action;
  const enable = (payload && payload.enable) || null;

  try {
    const { data } = yield call(
      postToGetJournalistTypes,
      { enable, page: 1, amount: 1 }
    );

    const typesDetail = yield call(
      postToGetJournalistTypes,
      { enable, page: 1, amount: data.totalCount || 1 }
    );

    const { types } = typesDetail.data;
    const options = types
      .map(({ id, name }) => ({ value: id, desc: name }))
      .sort((a, b) => a.desc > b.desc ? 1 : -1);
    
    yield put(UPDATE_JOURNALIST_TYPES(options));
  } catch (err) {
    console.log(err);
  }
};

function* fetchJournalistIDs(action) {
  const { payload } = action;

  try {
    const { data } = yield call(postToGetJournalistIDs, payload);
    const options = data
      .map(({ journalistID, journalistName, mediaID, mediaName }) => ({
        value: journalistID,
        desc: `${journalistName} - ${mediaName}`,
        mediaID,
      }))
      .sort((a, b) => a.desc > b.desc ? 1 : -1);
    
    yield put(UPDATE_JOURNALIST_ID_OPTIONS(options));
  } catch (err) { console.log(err) }
};

function* fetchExpenseTypes(action) {
  const { payload } = action;
  const enable = (payload && payload.enable) || null;
  const enableTags = payload && payload.enableTags;

  try {
    const { data } = yield call(
      postToGetExpenseTypes,
      { enable, page: 1, amount: 1 }
    );

    const typesDetail = yield call(
      postToGetExpenseTypes,
      { enable, page: 1, amount: data.totalCount || 1 }
    );

    const { expenseTypes } = typesDetail.data;
    const options = expenseTypes
      .map(({ expenseTypeID, name }) => ({
        value: expenseTypeID,
        desc: name,
      }))
      .sort((a, b) => a.desc > b.desc ? 1 : -1);

    const expenseTagDictionary = expenseTypes
      .reduce((acc, cur) => {
        const { expenseTypeID, tags } = cur;
        acc[expenseTypeID] = tags
          .filter(tag => enableTags === undefined ? true : tag.enable === enableTags)
          .map(({ name }) => ({ value: name, desc: name}));
        return { ...acc };
      }, {});

    yield put(UPDATE_EXPENSE_TYPE_OPTIONS(options));
    yield put(UPDATE_EXPENSE_TAG_DICTIONARY(expenseTagDictionary));
  } catch (err) { console.log(err) }
};

function* fetchDepartments(action) {
  const { payload } = action;
  const enable = (payload && payload.enable) || null;

  try {
    const { data } = yield call(
      postToGetDepartment,
      { enable, page: 1, amount: 1 }
    );

    const departmentDetail = yield call(
      postToGetDepartment,
      { enable, page: 1, amount: data.totalCount || 1 }
    );

    const { departments } = departmentDetail.data;

    const options = departments
      .map(({ departmentID, name }) => ({
        value: departmentID,
        desc: name,
      }))
      .sort((a, b) => a.desc > b.desc ? 1 : -1);
    yield put(UPDATE_DEPARTMENT_OPTIONS(options));
  } catch (err) {
    console.log(err);
  }
}

function* fetchCompanyTypes(action) {
  const { payload } = action;
  const enable = (payload && payload.enable) || null;

  try {
    const { data } = yield call(
      postToGetCompanyType,
      { enable, page: 1, amount: 1 }
    );

    const companyTypesDetail = yield call(
      postToGetCompanyType,
      { enable, page: 1, amount: data.totalCount || 1 }
    );

    const { types } = companyTypesDetail.data;

    const options = types
      .map(({ companyTypeID, name }) => ({
        value: companyTypeID,
        desc: name,
      }))
      .sort((a, b) => a.desc > b.desc ? 1 : -1);
    yield put(UPDATE_COMPANY_TYPE_OPTIONS(options));
  } catch (err) {
    console.log(err);
  }
}

function* fetchSpecialFeatures (action) {
  const { payload } = action;
  const enable = (payload && payload.enable) || null;

  try {
    const { data } = yield call(
      postToGetSpecialFeature,
      { enable, page: 1, amount: 1 }
    );

    const specialFeaturesDetail = yield call(
      postToGetSpecialFeature,
      { enable, page: 1, amount: data.totalCount || 1 }
    );

    const { features } = specialFeaturesDetail.data;

    const options = features
      .map(({ specialFeatureID, name }) => ({
        value: specialFeatureID,
        desc: name,
      }));

    yield put(UPDATE_SPECIAL_FEATURES(options));
  } catch (err) { console.log(err) }
}

function* fetchDeclareTypes (action) {
  const { payload } = action;
  const enable = (payload && payload.enable) || null;

  try {
    const { data } = yield call(
      postToGetDeclareType,
      { enable, page: 1, amount: 1 }
    );

    const declareTypesDetail = yield call(
      postToGetDeclareType,
      { enable, page: 1, amount: data.totalCount || 1 }
    );

    const { types } = declareTypesDetail.data;

    const options = types
      .map(({ declareTypeID, name }) => ({
        value: declareTypeID,
        desc: name,
      }));

    yield put(UPDATE_DECLARE_TYPE_OPTIONS(options));
  } catch (err) { console.log(err) }
}

function* fetchWorkingHoursTypes(action) {
  const { payload } = action;
  // const enable = (payload && payload.enable) || null;
  const enableTags = payload && payload.enableTags;

  try {
    const { data } = yield call(
      postToGetWorkingHoursTypes,
      { page: 1, amount: 1 }//remove enable or backend returns nothing
      // { enable, page: 1, amount: 1 }
    );

    const typesDetail = yield call(
      postToGetWorkingHoursTypes,
      { page: 1, amount: data.totalCount || 1 }//remove enable or backend returns nothing
      // { enable, page: 1, amount: data.totalCount || 1 }
    );

    const { types } = typesDetail.data;
    const options = types
      .map(({ workingHoursTypeID, name }) => ({
        value: workingHoursTypeID,
        desc: name,
      }))
      .sort((a, b) => a.desc > b.desc ? 1 : -1);

    const tagDictionary = types
      .reduce((acc, cur) => {
        const { workingHoursTypeID, tags } = cur;
        acc[workingHoursTypeID] = tags
          .filter(tag => enableTags === undefined ? true : tag.enable === enableTags)
          .map(({ name }) => ({ value: name, desc: name}));
        return { ...acc };
      }, {});

    yield put(UPDATE_WORKINGHOURS_TYPE_OPTIONS(options));
    yield put(UPDATE_WORKINGHOURS_TAG_DICTIONARY(tagDictionary));
  } catch (err) { console.log(err) }
};

function* fetchSystemRoles(action) {
  const { payload } = action;
  const enable = (payload && payload.enable) || undefined;

  try {
    const { data } = yield call(
      postToGetSystemRoles,
      { page: 1, amount: 1 }
    );

    const rolesDetail = yield call(
      postToGetSystemRoles,
      { page: 1, amount: data.totalCount || 1 }
    );

    const { roles } = rolesDetail.data;
    const options = roles
      .filter(role => enable === undefined ? true : role.enable === enable)
      .map(({ roleID, roleName }) => ({ value: roleID, desc: roleName }));

    yield put(UPDATE_SYSTEM_ROLE_OPTIONS(options));
  } catch (err) { console.log(err) }
}

export default function* srpOptionsSaga() {
  yield takeEvery(types.FETCH_SYSTEM_ROLE_OPTIONS, fetchSystemRoles);
  yield takeEvery(types.FETCH_MEMBER_OPTIONS, fetchUserIDs);
  yield takeEvery(types.FETCH_DIRECTOR_OPTIONS, fetchDirectors);
  yield takeEvery(types.FETCH_PROJECT_MANAGER_OPTIONS, fetchProjectManagers);
  yield takeEvery(types.FETCH_PROJECT_ID_OPTIONS, fetchProjectIDs);
  yield takeEvery(types.FETCH_PROJECT_CODE_OPTIONS, fetchProjectCodes);
  yield takeEvery(types.FETCH_PROJECT_TYPE_OPTIONS, fetchProjectTypes);
  yield takeEvery(types.FETCH_TODO_TYPE_OPTIONS, fetchProjectTodoTypes);
  yield takeEvery(types.FETCH_CUSTOMER_OPTIONS, fetchCustomerOptions);
  yield takeEvery(types.FETCH_CONTACT_WINDOW_OPTIONS, fetchContactWindows);
  yield takeEvery(types.FETCH_CUSTOMER_INDUSTRIES, fetchCustomerIndustries);
  yield takeEvery(types.FETCH_MEDIA_ID_OPTIONS, fetchMediaIDs);
  yield takeEvery(types.FETCH_MEDIA_TYPES, fetchMediaTypes);
  yield takeEvery(types.FETCH_VENDOR_ID_OPTIONS, fetchVendorIDs)
  yield takeEvery(types.FETCH_VENDOR_TYPE_OPTIONS, fetchVendorTypes);
  yield takeEvery(types.FETCH_VENUE_TYPE_OPTIONS, fetchVenueTypes);
  yield takeEvery(types.FETCH_VENUE_EQUIPMENTS, fetchVenueEquipments);
  yield takeEvery(types.FETCH_VENUE_CHARACTERISTICS, fetchVenueCharacteristics);
  yield takeEvery(types.FETCH_JOURNALIST_TYPES, fetchJournalistTypes);
  yield takeEvery(types.FETCH_JOURNALIST_ID_OPTIONS, fetchJournalistIDs);
  yield takeEvery(types.FETCH_ACTIVITY_IDEA_TYPE_OPTIONS, fetchActivityIdeaTypes);
  yield takeEvery(types.FETCH_EXPENSE_TYPE_OPTIONS, fetchExpenseTypes);
  yield takeEvery(types.FETCH_DEPARTMENT_OPTIONS, fetchDepartments);
  yield takeEvery(types.FETCH_COMPANY_TYPE_OPTIONS, fetchCompanyTypes);
  yield takeEvery(types.FETCH_SPECIAL_FEATURES, fetchSpecialFeatures);
  yield takeEvery(types.FETCH_DECLARE_TYPE_OPTIONS, fetchDeclareTypes);
  yield takeEvery(types.FETCH_WORKINGHOURS_TYPE_OPTIONS, fetchWorkingHoursTypes);
};
