import React from 'react';
import styled from 'styled-components';

const DivWrapper = styled.div`
  padding-top: 25px;
  padding-bottom: 30px;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
`;

const H3Title = styled.h3`
  margin-bottom: 15px;
  padding: 0 30px;
  font-size: 20px;
  font-weight: normal;
  color: #57585a;
`;

const PanelWrapper = props => {

  return (
    <DivWrapper>
      <H3Title>{ props.title }</H3Title>
      { props.children }
    </DivWrapper>
  );
};

export default PanelWrapper;
