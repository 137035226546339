import React from 'react';

import TableWrapper from '@/components/UI/TableWrapper';
import Checkbox from '@/components/UI/Checkbox';
import DateSelector from '@/components/UI/DateSelector';
import SelectNormal from '@/components/UI/Select/SelectNormal';
import PhoneInfoComponent from '@/components/UI/PhoneInfoComponent';
import AddressInfoSelector from '@/components/UI/AddressInfoSelector';
import { InputBasic, TextareaBasic } from '@/component-style/Input';
import { PSubtitle } from '@/component-style/TextElement';
import {
  DivFlexRow,
  DivItemWrapper,
  DivFullWidthWrapper,
  DivLongMultiSelector,
} from '@/component-style/RWDWrapper';

import { genderOptions } from '@/constant/options';
import { formatTime } from '@/utils/timeUtils';

const PanelContactWindowDetail = props => {

  const { contactWindow } = props;

  return (
    <TableWrapper title = { props.title }>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>
            姓名
            <span>*</span>
          </PSubtitle>
          <div>
            <InputBasic
              style = {{ marginBottom: '10px' }}
              disabled = { props.disabled }
              value = { contactWindow.name }
              onChange = {({ target }) => {
                props.updateContactWindowHandler('name', target.value);
              }}
            />
            <Checkbox
              title = "是否在職"
              disabled = { props.disabled }
              checked = { ('employed' in contactWindow  && contactWindow.employed === true) ? true : false }
              changeHandler = { value => {
                props.updateContactWindowHandler('employed', value);
              }}
            />
          </div>
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>性別</PSubtitle>
          <SelectNormal
            disabled = { props.disabled }
            value = { contactWindow.gender }
            options = { genderOptions }
            changeHandler = { value => {
              props.updateContactWindowHandler('gender', value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>英文名稱</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { contactWindow.englishName }
            onChange = {({ target }) => {
              props.updateContactWindowHandler('englishName', target.value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>部門名稱</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { contactWindow.department }
            onChange = {({ target }) => {
              props.updateContactWindowHandler('department', target.value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>職稱</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { contactWindow.title }
            onChange = {({ target }) => {
              props.updateContactWindowHandler('title', target.value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>生日</PSubtitle>
          <DateSelector
            disabled = { props.disabled }
            value = { contactWindow.birthday }
            changeHandler = { value => {
              props.updateContactWindowHandler(
                'birthday',
                formatTime(value, 'YYYY-MM-DD'),
              );
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>商務電話</PSubtitle>
          <PhoneInfoComponent
            disabled = { props.disabled }
            phoneInfo = { contactWindow.businessPhone }
            updatePhoneInfoHandler = { value => {
              props.updateContactWindowHandler('businessPhone', value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>商務傳真</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { contactWindow.fax }
            onChange = {({ target }) => {
              props.updateContactWindowHandler('fax', target.value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>行動電話 (公)</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { contactWindow.businessMobile }
            onChange = {({ target }) => {
              props.updateContactWindowHandler('businessMobile', target.value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>行動電話 (私)</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { contactWindow.privateMobile }
            onChange = {({ target }) => {
              props.updateContactWindowHandler('privateMobile', target.value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>電子郵件 (公)</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { contactWindow.email1 }
            onChange = {({ target }) => {
              props.updateContactWindowHandler('email1', target.value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>電子郵件 (私)</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { contactWindow.email2 }
            onChange = {({ target }) => {
              props.updateContactWindowHandler('email2', target.value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>Facebook</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { contactWindow.facebook }
            onChange = {({ target }) => {
              props.updateContactWindowHandler('facebook', target.value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>Line</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { contactWindow.lineId }
            onChange = {({ target }) => {
              props.updateContactWindowHandler('lineId', target.value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivFullWidthWrapper>
          <PSubtitle>公司地址</PSubtitle>
          <AddressInfoSelector
            disabled = { props.disabled }
            address = { contactWindow.companyAddress }
            updateAddressHandler = { value => {
              props.updateContactWindowHandler('companyAddress', value);
            }}
          />
        </DivFullWidthWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivFullWidthWrapper>
          <PSubtitle>家中地址</PSubtitle>
          <AddressInfoSelector
            disabled = { props.disabled }
            address = { contactWindow.privateAddress }
            updateAddressHandler = { value => {
              props.updateContactWindowHandler('privateAddress', value);
            }}
          />
        </DivFullWidthWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivFullWidthWrapper>
          <PSubtitle>備註說明	</PSubtitle>
          <DivLongMultiSelector>
            <TextareaBasic
              disabled = { props.disabled }
              value = { contactWindow.note }
              onChange = {({ target }) => {
                props.updateContactWindowHandler('note', target.value);
              }}
            />
          </DivLongMultiSelector>
        </DivFullWidthWrapper>
      </DivFlexRow>
      { props.children }
    </TableWrapper>
  );
};

export default PanelContactWindowDetail;
