import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { AZURE_LOGIN_SUCCESS } from '@/store/actions/loginAction';

const AzureLoginSuccess = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(AZURE_LOGIN_SUCCESS());
    history.push('/Login');
  }, [dispatch, history]);

  return null;
};

export default AzureLoginSuccess;
