import React from 'react';
import { Checkbox } from 'antd';
import 'antd/dist/antd.css';
import './index.css';

const CheckboxUI = props => {

  const changeHandler = event => {
    const { checked } = event.target;
    props.changeHandler(checked);
  }

  return (
    <Checkbox
      checked = { props.checked }
      disabled = { props.disabled }
      onChange = { changeHandler }
    >
      { props.title }
    </Checkbox>
  );
};

export default CheckboxUI;
