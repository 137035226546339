import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Checkbox from '@/components/UI/Checkbox';
import StatusCheck from '@/components/UI/StatusCheck';
import { TdItem, TableRow } from '@/component-style/Table';
import { ButtonGoDetail } from '@/component-style/Button';

import { getOptionDesc } from '@/utils/textUtils';
import { diffTime } from '@/utils/timeUtils';

const TrWrapper = styled(TableRow)`
  border-bottom: solid 2px #f2f2f2;
  box-sizing: border-box;
  min-width: ${ props => {
    if (props.showCheckbox && props.showDetailButton) return '1460px';
    if (props.showCheckbox || props.showDetailButton) return '1400px';
    return '1340px';
  }};

  &:last-child {
    border-bottom: none;
  }
`;

const Td60 = styled(TdItem)`
  width: 60px;
`;

const Td80 = styled(TdItem)`
  width: 80px;
`;

const Td120 = styled(TdItem)`
  width: 120px;
`;
const Td160 = styled(TdItem)`
  width: 160px;
`;

const Item = props => {
  const { schedule } = props;
  const memberOptions = useSelector(state => state.srpOptions.memberOptions);
  const todoTypeOptions = useSelector(state => state.srpOptions.todoTypeOptions);
  const companyTypeOptions = useSelector(state => state.srpOptions.companyTypeOptions);

  const company = getOptionDesc(companyTypeOptions, schedule.companyType);

  const departmentDict = {
    'veda': company,
    'customer': '客戶',
    'both': `${company} / 客戶`
  };

  const estimatedDate = schedule.estimatedDate === '0001-01-01'
    ? '' : schedule.estimatedDate;

  const finishedDate = schedule.finishedDate === '0001-01-01'
    ? '' : schedule.finishedDate;

  const delayDays = () => {
    if (schedule.estimatedDate === '0001-01-01') return '';
  
    const finishedDate = schedule.finishedDate === '0001-01-01'
      ? Date.now()
      : schedule.finishedDate;

    const diff = diffTime(schedule.estimatedDate, finishedDate, 'days')
    return diff > 0 ? diff : 0;
  };

  return (
    <TrWrapper
      showCheckbox = { props.showCheckbox }
      showDetailButton = { props.showDetailButton }
    >
      {
        props.showCheckbox &&
        <Td60>
          <Checkbox
            checked = { schedule.isChecked }
            changeHandler = { props.checkHandler }
          />
        </Td60>
      }
      <Td80>{ company }</Td80>
      <Td120>{ schedule.projectCode }</Td120>
      <Td160>{ schedule.projectName }</Td160>
      <Td120>
        { getOptionDesc([...todoTypeOptions], schedule.todoTypeID) }
      </Td120>
      <Td120>{ schedule.todoTag }</Td120>
      <Td120>{ estimatedDate }</Td120>
      <Td120>{ finishedDate }</Td120>
      <Td120>{ departmentDict[schedule.department] }</Td120>
      <Td120>{ getOptionDesc([...memberOptions], schedule.userID) }</Td120>
      <Td60>
        <StatusCheck
          checked = { finishedDate !== '' }
        />
      </Td60>
      <Td80>{ delayDays() }</Td80>
      <Td120>{ schedule.note }</Td120>
      {
        props.showDetailButton &&
        <Td60>
          <ButtonGoDetail
            disabled = { props.disableDetailPage }
            onClick = { props.goDetailHandler }
          >...
          </ButtonGoDetail>
        </Td60>
      }
    </TrWrapper>
  );
};

export default Item;
