import React from 'react';
import styled from 'styled-components';

import TableWrapper from '@/components/UI/TableWrapper';
import ReporterInvitationList from '@/components/ReporterInvitationList';
import { PSubtitle } from '@/component-style/TextElement';
import { ButtonBasic } from '@/component-style/Button';

import {
  DivFlexRow,
  DivItemWrapper,
} from '@/component-style/RWDWrapper';

const DivFunctionButtonsWrapper = styled(DivFlexRow)`
  margin-bottom: 10px;
  max-width: 1535px;
  padding: 0;
  justify-content: flex-start;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const ButtonExecute = styled(ButtonBasic)`
  width: 120px;
  margin-right: 10px;
  margin-bottom: 10px;
`;


const TableInvitationManagement = props => {
  const { journalistOptions, journalistIDOptions } = props;

  const seletableOptions = journalistIDOptions
    .filter(({ value }) => !journalistOptions.some(option => option.value === value));

  return (
    <TableWrapper title = "記者名單維護">
      <DivFlexRow>
        <DivFunctionButtonsWrapper>
          <ButtonExecute
            disabled = { props.invitation.projectID === '' || !props.userEditable }
            onClick = { props.importHandler }
          >記者匯入</ButtonExecute>
          <ButtonExecute
            disabled = { props.invitation.projectID === '' || !props.userExportable }
            onClick = { props.exportHandler }
          >檔案匯出</ButtonExecute>
          <ButtonExecute
            disabled = { journalistOptions.length === 0 || !props.userSendable }
            onClick = { props.sendMessageHandler }
          >訊息發送</ButtonExecute>
        </DivFunctionButtonsWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>新增邀約記者</PSubtitle>
          <ReporterInvitationList
            isAdding = { props.isAdding }
            invitation = { props.invitation }
            addHandler = { props.addHandler }
            saveHandler = { props.saveHandler }
            userEditable = { props.userEditable }
            closeHandler = { props.closeHandler }
            journalistIDOptions = { seletableOptions }
            updateInvitationHandler = { props.updateInvitationHandler }
          />
        </DivItemWrapper>
      </DivFlexRow>
    </TableWrapper>
  );
};

export default TableInvitationManagement;