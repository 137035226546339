import React from 'react';
import styled from 'styled-components';

import Checkbox from '@/components/UI/Checkbox';
import { TdItem, TableRow } from '@/component-style/Table';
import { ButtonGoDetail } from '@/component-style/Button';

import { getAddressString } from '@/utils/textUtils';

const TrWrapper = styled(TableRow)`
  border-bottom: solid 2px #f2f2f2;
  box-sizing: border-box;
  min-width: 1000px;

  &:last-child {
    border-bottom: none;
  }
`;

const Td80 = styled(TdItem)`
  width: 80px;
`;

const Td160 = styled(TdItem)`
  width: 160px;
`;

const TdCharacter = styled(Td160)`
  justify-content: flex-start;
`;

const TdAddress = styled(TdItem)`
  width: 200px;
  justify-content: flex-start;
`;

const Item = props => {

  return (
    <TrWrapper>
      <Td80>
        <Checkbox
          checked = { props.isChecked }
          changeHandler = { props.checkHandler }
        />
      </Td80>
      <Td160>{ props.venueName }</Td160>
      <Td160>{ props.roomName }</Td160>
      <Td80>{ props.totalCapacity }</Td80>
      <Td80>{ props.size }</Td80>
      <TdCharacter></TdCharacter>
      <TdAddress>{ getAddressString(props.address) }</TdAddress>
      <Td80>
        <ButtonGoDetail
          onClick = { props.goDetailHandler }
        >...
        </ButtonGoDetail>
      </Td80>
    </TrWrapper>
  );
};

export default Item;
