import React from 'react';
import styled from 'styled-components';

const PNoneHighlighted = styled.p`
  padding: 0 6px;
  position: absolute;
`;

const PHighlighted = styled.p`
  padding: 0 6px;
  position: absolute;
  border-radius: 25px;
  background-color: #DE2027;
  color: #FFFFFF;
`;

const Highlighted = props => {

  // console.log("ExportAccountingSystem notForAccountingSys:" + props.notForAccountingSys);
  // const text = props.notForAccountingSys ? "未匯出" : "已匯出";

  return (
    <React.Fragment>
      { props.highlighted ? 
        (<PHighlighted>
          { props.text }
        </PHighlighted>) :
        (
          <PNoneHighlighted>
            { props.text }
          </PNoneHighlighted>
        )
      }
      
    </React.Fragment>
  );
};

export default Highlighted;
