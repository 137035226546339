import React from 'react';
import styled from 'styled-components';

import PageSelector from '@/components/UI/PageSelector';
import Header from './Header';
import Item from './Item';
import { Table, TableBody } from '@/component-style/Table';
import { ButtonBasic } from '@/component-style/Button';

const DivWrapper = styled.div`
  width: 100%;
  border-top: 2px solid #de2027;
  background-color: #ffffff;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
`;

const TitleWrapper = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const H3Title = styled.h3`
  margin: 0;
  font-size: 16px;
  font-weight: normal;
  color: #57585a;
`;

const ButtonAdd = styled(ButtonBasic)`
  width: 120px;
`;

const TableProjectQuotations = props => {
  const { quotations } = props;

  return (
    <DivWrapper>
      <TitleWrapper>
        <H3Title>預估服務費用</H3Title>
        <ButtonAdd
          disabled = { props.disabled || !props.userEditable }
          onClick = { props.addHandler }
        >新增報價單</ButtonAdd>
      </TitleWrapper>
      <Table>
        <Header/>
        <TableBody>
          {
            quotations.map(quotation =>
              <Item
                key = { quotation.projectQuotationID }
                disabled = { props.disabled }
                userEditable = { props.userEditable }
                quotation = { quotation }
                deleteHandler = { () => {
                  props.deleteHandler(quotation.projectQuotationID)
                }}
                goDetailHandler = { () => {
                  props.goDetailHandler(quotation.projectQuotationID)
                }}
              />
            )
          }
        </TableBody>
      </Table>
      <PageSelector
        current = { props.currentPage }
        total = { props.totalCount }
        pageChangedHandler = { props.pageChangedHandler }
      />
    </DivWrapper>
  );
};

export default TableProjectQuotations;
