import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { Table, TableBody } from '@/component-style/Table';
import { ButtonBasic } from '@/component-style/Button';
import PageSelector from '@/components/UI/PageSelector';
import Header from './Header';
import Item from './Item';

const DivWrapper = styled.div`
  width: 100%;
  border-top: 2px solid #de2027;
  background-color: #ffffff;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
`;

const TitleWrapper = styled.div`
  margin-top: 35px;
  margin-bottom: 20px;
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const H3Title = styled.h3`
  margin: 0;
  font-size: 20px;
  font-weight: normal;
  color: #57585a;
`;

const ButtonDelete = styled(ButtonBasic)`
  width: 120px;
  height: 40px;
`;

const TableContactWindows = props => {
  const history = useHistory();
  const { id } = useParams();
  const informationRoutes = useSelector(state => state.login.informationRoutes);

  const userAddable = props.windowType === 'Customer'
    ? informationRoutes.indexOf('CustomerWindowCreation') !== -1
    : informationRoutes.indexOf('VendorWindowCreation') !== -1;

  const disableDetailPage = props.windowType === 'Customer'
    ? informationRoutes.indexOf('CustomerWindowDetail') === -1
    : informationRoutes.indexOf('VendorWindowDetail') === -1;

  const createContactWindowHandler = () => {
    history
      .push(`/Information/${ props.windowType }WindowCreation/${id}`);
  };

  const goDetailHandler = contactWindowID => {
    history
      .push(`/Information/${ props.windowType }WindowDetail/${contactWindowID}`);
  };

  return (
    <DivWrapper>
      <TitleWrapper>
        <H3Title>聯絡人列表</H3Title>
        <ButtonDelete
          disabled = { !userAddable }
          onClick = { createContactWindowHandler }
        >新增聯絡人</ButtonDelete>
      </TitleWrapper>
      <Table>
        <Header/>
        <TableBody>
          {
            props.contactWindows.map(contactWindow =>
              <Item
                key = { contactWindow.contactWindowID }
                contactWindow = { contactWindow }
                disableDetailPage = { disableDetailPage }
                goDetailHandler = { () => {
                  goDetailHandler(contactWindow.contactWindowID);
                }}
              />
            )
          }
        </TableBody>
      </Table>
      <PageSelector
        current = { props.currentPage }
        total = { props.totalCount }
        pageChangedHandler = { props.pageChangedHandler }
      />
    </DivWrapper>
  );
};

export default TableContactWindows;