import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import TableWrapper from '@/components/UI/TableWrapper';
import PageSelector from '@/components/UI/PageSelector';
import { Table, TableBody } from '@/component-style/Table';
import Header from './Header';
import Item from './Item';

// import moment from 'moment';

// import PopupOvertimeDetail from '@/components/UI/Popup/PopupOvertimeDetail';

const TableWageStructure = props => {
  const authorityRoutes = useSelector(state => state.login.authorityRoutes);

  const { paycheckStructures } = props;

  // const disableDetailPage = authorityRoutes.indexOf('UserDetail') === -1;

  // const [showOvertimeDetailPopup, setShowOvertimeDetailPopup] = useState(false);

  // const [viewOvertimeDetailUserID, setViewOvertimeDetailUserID] = useState("");

  return (
    <TableWrapper title = "薪資計算">
      <Table>
        <Header/>
        <TableBody>
          {
            paycheckStructures !== undefined && paycheckStructures.map(singlePaycheckStructure =>
              <Item
                key = { singlePaycheckStructure.id }
                paycheckStructure = { singlePaycheckStructure }
                editOrViewPaycheckStructureHandler = { (paycheckStructureId) => {
                  console.log(`TableWageStructure index.js editOrViewPaycheckStructureHandler() paycheckStructureId=paycheckStructureId`);
                  // if (typeof props.editPaycheckStructureHandler === 'function') {
                  // props.viewPaycheckStructureHandler(paycheckStructureId);
                  if (props.editPaycheckStructureHandler !== undefined) {
                    props.editPaycheckStructureHandler(paycheckStructureId);
                  } else {
                    props.viewPaycheckStructureHandler(paycheckStructureId);
                  }
                }}
              />
            )
          }
        </TableBody>
      </Table>
      <PageSelector
        total = { props.totalCount }
        current = { props.currentPage }
        pageChangedHandler = { props.pageChangedHandler }
        showSizeChanger = { false }
      />
    </TableWrapper>
  );
};

export default TableWageStructure;
