import React from 'react';
import styled from 'styled-components';

import TableWrapper from '@/components/UI/TableWrapper';
import VenueDateList from '@/components/VenueDateList';
import Checkbox from '@/components/UI/Checkbox';
import ProjectVenueExpenseList from '@/components/ProjectVenueExpenseList';
import { PSubtitle } from '@/component-style/TextElement';
import { InputBasic, TextareaBasic } from '@/component-style/Input';

import {
  DivFlexRow,
  DivItemWrapper,
  DivFullWidthWrapper,
} from '@/component-style/RWDWrapper';

import { getAddressString } from '@/utils/textUtils';
import { getMultiplyResult } from '@/utils/mathUtils';

const PDesc = styled.p`
  display: flex;
  align-items: center;
  width: 300px;
  min-height: 40px;
  line-height: 16px;
  font-size: 14px;
  color: #8b8b8b;

  @media (min-width: 1920px) {
    width: 622px;
  };

  @media (max-width: 320px) {
    width: 260px;
  };
`;

const Textarea = styled(TextareaBasic)`
  width: calc(100% - 135px);

  @media (max-width: 767px) {
    width: 100%;
  }
`;

const DivCheckboxWrapper = styled.div`
  width: 300px;
  height: 40px;
  line-height: 40px;

  @media (max-width: 767px) {
    width: auto;
    height: 20px;
    line-height: 20px;
    margin-bottom: 5px;
  }
`;

const PanelVenueReservationDetail = props => {
  const { projectInfo, venueInfo, reservation } = props;

  const totalVenueFees = Object.keys(reservation.venueFees)
    .map(key => reservation.venueFees[key])
    .map(({ unit, unitPrice }) => getMultiplyResult(unit, unitPrice))
    .reduce((acc, cur) => acc + cur, 0);

  const totalMealFees = reservation.mealFees
    .map(({ unit, unitPrice }) => getMultiplyResult(unit, unitPrice))
    .reduce((acc, cur) => acc + cur, 0);

  const totalDeviceFees = reservation.deviceFees
    .map(({ unit, unitPrice }) => getMultiplyResult(unit, unitPrice))
    .reduce((acc, cur) => acc + cur, 0);

  return(
    <TableWrapper title = "活動場地明細">
        <DivFlexRow>
          <DivItemWrapper>
            <PSubtitle>專案代號</PSubtitle>
            <PDesc>{ projectInfo.projectCode }</PDesc>
          </DivItemWrapper>
          <DivItemWrapper>
            <PSubtitle>專案名稱</PSubtitle>
            <PDesc>{ projectInfo.projectName }</PDesc>
          </DivItemWrapper>
        </DivFlexRow>
        <DivFlexRow>
          <DivItemWrapper>
            <PSubtitle>場地名稱</PSubtitle>
            <PDesc>{ venueInfo.name }</PDesc>
          </DivItemWrapper>
          <DivItemWrapper>
            <PSubtitle>廳室</PSubtitle>
            <PDesc>{ venueInfo.room }</PDesc>
          </DivItemWrapper>
        </DivFlexRow>
        <DivFlexRow>
          <DivItemWrapper>
            <PSubtitle>英文名稱</PSubtitle>
            <PDesc>{ venueInfo.nameEng }</PDesc>
          </DivItemWrapper>
          <DivItemWrapper>
            <PSubtitle>電話</PSubtitle>
            <PDesc>{ venueInfo.phone }</PDesc>
          </DivItemWrapper>
        </DivFlexRow>
        <DivFlexRow>
          <DivItemWrapper>
            <PSubtitle>網址</PSubtitle>
            <PDesc>{ venueInfo.website }</PDesc>
          </DivItemWrapper>
          <DivItemWrapper>
            <PSubtitle>地址</PSubtitle>
            <PDesc>{ getAddressString(venueInfo.address) }</PDesc>
          </DivItemWrapper>
        </DivFlexRow>
        <DivFlexRow>
          <DivItemWrapper>
            <PSubtitle>聯絡人</PSubtitle>
            <InputBasic
              disabled = { props.disabled }
              value = { reservation.contact.name }
              onChange = {({ target }) => {
                props.updateContactHandler('name', target.value);
              }}
            />
          </DivItemWrapper>
          <DivItemWrapper>
            <PSubtitle>分機</PSubtitle>
            <InputBasic
              disabled = { props.disabled }
              value = { reservation.contact.extension }
              onChange = {({ target }) => {
                props.updateContactHandler('extension', target.value);
              }}
            />
          </DivItemWrapper>
        </DivFlexRow>
        <DivFlexRow>
          <DivItemWrapper>
            <PSubtitle>手機</PSubtitle>
            <InputBasic
              disabled = { props.disabled }
              value = { reservation.contact.mobile }
              onChange = {({ target }) => {
                props.updateContactHandler('mobile', target.value);
              }}
            />
          </DivItemWrapper>
          <DivItemWrapper>
            <PSubtitle>E-Mail</PSubtitle>
            <InputBasic
              disabled = { props.disabled }
              value = { reservation.contact.email }
              onChange = {({ target }) => {
                props.updateContactHandler('email', target.value);
              }}
            />
          </DivItemWrapper>
        </DivFlexRow>
        <DivFlexRow>
          <DivItemWrapper>
            <PSubtitle>預定日期</PSubtitle>
            <VenueDateList
              disabled = { props.disabled }
              timeList = { reservation.reservations }
              updateReservationHandler = { value => {
                props.updateReservationHandler('reservations', value);
              }}
            />
          </DivItemWrapper>
        </DivFlexRow>
        <DivFlexRow>
          <DivItemWrapper>
            <PSubtitle>場租費用項目</PSubtitle>
            <ProjectVenueExpenseList
              disabled = { props.disabled }
              itemAddable = { false }
              itemRemovable = { false }
              disableItemName = { true }
              itemList = {[
                { name: '活動場租', unitPrice: reservation.venueFees.venue.unitPrice, unit: reservation.venueFees.venue.unit },
                { name: '場佈場租', unitPrice: reservation.venueFees.decorate.unitPrice, unit: reservation.venueFees.decorate.unit },
                { name: '逾時費用', unitPrice: reservation.venueFees.overtime.unitPrice, unit: reservation.venueFees.overtime.unit },
              ]}
              updateItemValueHandler = { (key, value, index) => {
                const updateItem = Object.keys(reservation.venueFees)[index];
                const updateObj = { ...reservation.venueFees }
                updateObj[updateItem][key] = value;
                props.updateReservationHandler('venueFees', { ...updateObj });
              }}
            />
          </DivItemWrapper>
        </DivFlexRow>
        <DivFlexRow>
          <DivItemWrapper>
            <PSubtitle>場租費用小計</PSubtitle>
            <PDesc>{ totalVenueFees }</PDesc>
          </DivItemWrapper>
        </DivFlexRow>
        <DivFlexRow>
          <DivItemWrapper>
            <PSubtitle>餐點項目</PSubtitle>
            <ProjectVenueExpenseList
              disabled = { props.disabled }
              itemNamePlaceholder = "餐點名稱"
              itemList = { reservation.mealFees }
              updateVenueItemHandler = { value => {
                props.updateReservationHandler('mealFees', value);
              }}
              updateItemValueHandler = { (key, value, index) => {
                const updateList = [ ...reservation.mealFees ];
                updateList[index][key] = value;
                props.updateReservationHandler('mealFees', updateList);
              }}
            />
          </DivItemWrapper>
        </DivFlexRow>
        <DivFlexRow>
          <DivItemWrapper>
            <PSubtitle>餐點費用小計</PSubtitle>
            <PDesc>{ totalMealFees }</PDesc>
          </DivItemWrapper>
        </DivFlexRow>
        <DivFlexRow>
          <DivItemWrapper>
            <PSubtitle>設備項目</PSubtitle>
            <ProjectVenueExpenseList
              disabled = { props.disabled }
              itemNamePlaceholder = "設備名稱"
              itemList = { reservation.deviceFees }
              updateVenueItemHandler = { value => {
                props.updateReservationHandler('deviceFees', value);
              }}
              updateItemValueHandler = { (key, value, index) => {
                const updateList = [ ...reservation.deviceFees ];
                updateList[index][key] = value;
                props.updateReservationHandler('deviceFees', updateList);
              }}
            />
          </DivItemWrapper>
        </DivFlexRow>
        <DivFlexRow>
          <DivItemWrapper>
            <PSubtitle>設備費用小計</PSubtitle>
            <PDesc>{ totalDeviceFees }</PDesc>
          </DivItemWrapper>
        </DivFlexRow>
        <DivFlexRow>
          <DivItemWrapper>
            <PSubtitle>預算總計</PSubtitle>
            <PDesc>
              { totalVenueFees + totalMealFees + totalDeviceFees }
            </PDesc>
          </DivItemWrapper>
        </DivFlexRow>
        <DivFlexRow>
          <DivItemWrapper>
            <PSubtitle>優惠價</PSubtitle>
            <InputBasic
              disabled = { props.disabled }
              value = { reservation.favorablePrice }
              onChange = {({ target }) => {
                props.updateReservationHandler('favorablePrice', target.value);
              }}
            />
          </DivItemWrapper>
          <DivItemWrapper>
            <PSubtitle>優惠價說明</PSubtitle>
            <InputBasic
              disabled = { props.disabled }
              value = { reservation.discountInfo }
              onChange = {({ target }) => {
                props.updateReservationHandler('discountInfo', target.value);
              }}
            />
          </DivItemWrapper>
        </DivFlexRow>
        <DivFlexRow>
          <DivFullWidthWrapper>
            <PSubtitle>備註說明</PSubtitle>
            <Textarea
              disabled = { props.disabled }
              value = { reservation.note }
              onChange = {({ target }) => {
                props.updateReservationHandler('note', target.value);
              }}
            />
          </DivFullWidthWrapper>
        </DivFlexRow>
        <DivFlexRow>
          <DivItemWrapper>
            <PSubtitle>是否採用</PSubtitle>
            <DivCheckboxWrapper>
              <Checkbox
                disabled = { props.disabled }
                checked = { reservation.used }
                changeHandler = { value => {
                  props.updateReservationHandler('used', value);
                }}
              />
            </DivCheckboxWrapper>
          </DivItemWrapper>
        </DivFlexRow>
        { props.children }
      </TableWrapper>
  );
};

export default PanelVenueReservationDetail;
