import React from 'react';
import styled from 'styled-components';

const LampWrapper = styled.span`
  border-radius: 50%;
  width: 10px;
  height: 10px;
`;

const SuccessLamp = styled(LampWrapper)`
  background-color: #82CD64;
`;

const WarningLamp = styled(LampWrapper)`
  background-color: #FFDC64;
`;

const FailLamp = styled(LampWrapper)`
  background-color: #DE2027;
`;

const StatusLamp = props => {

  const lampDict = {
    'success': <SuccessLamp/>,
    'warning': <WarningLamp/>,
    'fail': <FailLamp/>,
  };

  const displayLamp = lampDict[props.status];

  return (
    <React.Fragment>
      {
        props.status && displayLamp
      }
    </React.Fragment>
  );
};

export default StatusLamp;
