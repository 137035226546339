import React from 'react';
import styled from 'styled-components';

import { TableHead, TableRow, ThItem } from '../../../component-style/Table';

const THead = styled(TableHead)`
  min-width: 1020px;
`;

const Th60 = styled(ThItem)`
  width: 60px;
`;

const Th80 = styled(ThItem)`
  width: 80px;
`;

const Th120 = styled(ThItem)`
  width: 120px;
`;

const Th160 = styled(ThItem)`
  width: 160px;
`;

const ThContact = styled(Th160)`
  text-align: left;
`;

const ThReservation = styled(ThItem)`
  width: 200px;
  text-align: left;
`;

const Header = props => {
  
  return (
    <THead>
      <TableRow>
        <Th160>場地</Th160>
        <Th120>廳室</Th120>
        <ThReservation>預定日期</ThReservation>
        <Th120>場地預算</Th120>
        <Th80>使用狀態</Th80>
        <ThContact>聯繫資訊</ThContact>
        <Th120>備註</Th120>
        <Th60></Th60>
      </TableRow>
    </THead>
  );
};

export default Header;
