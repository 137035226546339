import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { postToGetProjectDetail } from '@/api/projectService';

import {
  postToGetVenueDetail,
  deleteVenueReservation,
  postToGetReservationDetail,
  putModifyReservationDetail,
} from '@/api/venueService';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import MainContent from '@/component-style/MainContent';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import PopupExecuteConfirm from '@/components/UI/Popup/PopupExecuteConfirm';
import PanelVenueReservationDetail from '@/components/Panel/Project/PanelVenueReservationDetail';

import { ButtonBasic, ButtonCancel } from '@/component-style/Button';
import { DivFlexRow } from '@/component-style/RWDWrapper';

import { integerConverter } from '@/utils/textUtils';
import { reservationConfig } from '@/constant/dataConfig/informationModule';

const DivButtonsWrapper = styled(DivFlexRow)`
  padding: 0;
  padding-bottom: 30px;
  justify-content: flex-end;
  max-width: 1535px;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const Button = styled(ButtonBasic)`
  width: 120px;
  margin-right: 10px;
  margin-top: 10px;

  &:last-child {
    margin-right: 0;
  }
`;

const ButtonExit = styled(ButtonCancel)`
  width: 120px;
  margin-right: 10px;
  margin-top: 10px;

  &:last-child {
    margin-right: 0;
  }
`;

const VenueReservationDetail = props => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const projectRoutes = useSelector(state => state.login.projectRoutes);
  const showSaveFailPopup = useSelector(state => state.apiFail.showSaveFailPopup);
  const apiFailPopupTitle = useSelector(state => state.apiFail.title);
  const apiFailPopupMessage = useSelector(state => state.apiFail.message);

  const [projectInfo, setProjectInfo] = useState({
    projectCode: '',
    projectName: '',
  });

  const [venueInfo, setVenueInfo] = useState({
    name: '',
    nameEng: '',
    room: '',
    phone: '',
    website: '',
    address: {
      country: 'taiwan',
      city: '',
      town: '',
      postal: '',
      address: '',
    },
  });

  const [reservation, setReservation] = useState({
    ...reservationConfig,
    contact: { ...reservationConfig.contact },
    venueFees: {
      venue: { ...reservationConfig.venueFees.venue },
      decorate: { ...reservationConfig.venueFees.decorate },
      overtime: { ...reservationConfig.venueFees.overtime },
    },
    mealFees: [],
    deviceFees: [],
    reservations: [],
  });

  const [isEditing, setIsEditing] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [showSaveSuccessPopup, setShowSaveSuccessPopup] = useState(false);
  const [showDeleteSuccessPopup, setShowDeleteSuccessPopup] = useState(false);

  const userEditable = projectRoutes.indexOf('VenueReservationDetail-Edit') !== -1;
  const userDeletable = projectRoutes.indexOf('VenueReservationDetail-Delete') !== -1;

  const updateReservationHandler = (key, value) => {
    const updateObj = { ...reservation };
    updateObj[key] = value;
    setReservation({ ...updateObj });
  };

  const updateContactHandler = (key, value) => {
    const updateObj = { ...reservation.contact };
    updateObj[key] = value;
    setReservation(prevState => ({
      ...prevState,
      contact: { ...updateObj },
    }));
  };

  const closeSuccessPopupHandler = () => {
    setIsEditing(false);
    setShowSaveSuccessPopup(false);
  };

  const deleteReservationHandler = async () => {
    setShowDeletePopup(false);
    setShowLoadingPopup(true);

    try {
      await deleteVenueReservation(id);
      setShowLoadingPopup(false);
      setShowDeleteSuccessPopup(true);
    } catch(err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '刪除失敗', err }));
      console.log(err);
    }
  };

  const updateReservationData = async () => {
    setShowLoadingPopup(true);

    try {
      await putModifyReservationDetail({
        ID: id,
        ...reservation,
        favorablePrice: integerConverter(reservation.favorablePrice),
        venueFees: {
          venue: {
            unit: integerConverter(reservation.venueFees.venue.unit),
            unitPrice: integerConverter(reservation.venueFees.venue.unitPrice),
          },
          decorate: {
            unit: integerConverter(reservation.venueFees.decorate.unit),
            unitPrice: integerConverter(reservation.venueFees.decorate.unitPrice),
          },
          overtime: {
            unit: integerConverter(reservation.venueFees.overtime.unit),
            unitPrice: integerConverter(reservation.venueFees.overtime.unitPrice),
          },
        },
        mealFees: reservation.mealFees.map(fee => ({
          ...fee,
          unit: integerConverter(fee.unit),
          unitPrice: integerConverter(fee.unitPrice),
        })),
        deviceFees: reservation.deviceFees.map(fee => ({
          ...fee,
          unit: integerConverter(fee.unit),
          unitPrice: integerConverter(fee.unitPrice),
        })),
      })
      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '更新失敗', err }));
      console.log(err);
    }
  };

  const fetchReservationData = async () => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetReservationDetail(id);
      const { projectID, venueID, contact, venueFees } = data;

      const projectDetail = await postToGetProjectDetail(projectID);
      const venueDetail = await postToGetVenueDetail(venueID);
      setProjectInfo({
        projectCode: projectDetail.data.projectCode,
        projectName: projectDetail.data.projectName,
      });
      setVenueInfo(prveState => ({
        name: venueDetail.data.name,
        nameEng: venueDetail.data.nameEng,
        room: venueDetail.data.basicInfo.name,
        phone: venueDetail.data.phone,
        website: venueDetail.data.website,
        address: { ...prveState, ...venueDetail.data.address },
      }));
      setReservation(prevState => ({
        ...prevState,
        ...data,
        contact: { ...prevState.contact, ...contact },
        venueFees: {
          venue: {
            ...prevState.venueFees.venue,
            ...venueFees.venue,
          },
          decorate: {
            ...prevState.venueFees.decorate,
            ...venueFees.decorate,
          },
          overtime: {
            ...prevState.venueFees.overtime,
            ...venueFees.overtime,
          },
        }
      }));

    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const initReservationData = useCallback(
    fetchReservationData, []);

  useEffect(() => {
    initReservationData();
  }, [initReservationData]);

  return (
    <MainContent>
      <PanelVenueReservationDetail
        title = "活動場地明細"
        disabled = { !isEditing }
        venueInfo = { venueInfo }
        projectInfo = { projectInfo }
        reservation = { reservation }
        updateContactHandler = { updateContactHandler }
        updateReservationHandler = { updateReservationHandler }
      >
        <DivFlexRow>
          <DivButtonsWrapper>
            {
              isEditing
                ? <Button
                    onClick = { updateReservationData }
                  >儲存</Button>
                : <Button
                    disabled = { !userEditable }
                    onClick = { () => { setIsEditing(true) } }
                  >編輯</Button>
            }
            <ButtonExit
              disabled = { !userDeletable }
              onClick = { () => { setShowDeletePopup(true) } }
            >刪除</ButtonExit>
            <ButtonExit
              onClick = { () => { history.goBack() } }
            >離開</ButtonExit>
          </DivButtonsWrapper>
        </DivFlexRow>
      </PanelVenueReservationDetail>
      { showLoadingPopup && <PopupLoading/> }
      {
        showDeletePopup &&
          <PopupExecuteConfirm
            title = "刪除活動場地"
            text = "確定刪除此活動場地?"
            confirmHandler = { deleteReservationHandler }
            cancelHandler = { () => {
              setShowDeletePopup(false);
            }}
          />
      }
      {
        showSaveSuccessPopup &&
          <PopupExecuteResult
            title = "更新成功"
            confirmHandler = { closeSuccessPopupHandler }
          />
      }
      {
        showDeleteSuccessPopup &&
          <PopupExecuteResult
            title = "刪除成功"
            confirmHandler = { () => { history.goBack() }}
          />
      }
      {
        showSaveFailPopup &&
          <PopupExecuteResult
            title = { apiFailPopupTitle }
            text = { apiFailPopupMessage }
            confirmHandler = { () => {
              dispatch(CLOSE_SAVE_FAIL_POPUP());
            }}
          />
      }
    </MainContent>
  );
};

export default VenueReservationDetail;
