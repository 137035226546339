import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import PanelWrapper from '@/components/UI/PanelWrapper';
import SelectNormal from '@/components/UI/Select/SelectNormal';
import SelectSearchable from '@/components/UI/Select/SelectSearchable';
import SearchButtonSet from '@/components/UI/SearchButtonSet';
import DatePeriodSelector from '@/components/UI/DatePeriodSelector';
import { InputBasic } from '@/component-style/Input';
import { ButtonBasic } from '@/component-style/Button';
import { PSubtitle } from '@/component-style/TextElement';
import {
  DivFlexRow,
  DivItemWrapper,
  DivLongMultiSelector,
} from '@/component-style/RWDWrapper';

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding: 0;
  justify-content: space-between;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

// const ButtonAdd = styled(ButtonBasic)`
//   margin-right: 10px;
//   width: 120px;

//   @media (max-width: 767px) {
//     margin-bottom: 10px;
//   }
// `;

const ButtonExport = styled(ButtonBasic)`
  width: 120px;

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`;

const PanelDirectorRevenueSearch = props => {
  const financeRoutes = useSelector(state => state.login.financeRoutes);
  const { config } = props;

  const userExportable = financeRoutes.indexOf('DirectorRevenue-Export') !== -1;

  const mbrStartChangeHandler = value => {
    props.updateConfigHandler('mbr', {
      ...config.mbr,
      start: value,
    });
  };

  const mbrEndChangeHandler = value => {
    props.updateConfigHandler('mbr', {
      ...config.mbr,
      end: value,
    });
  };

  const createDateStartChangeHandler = value => {
    props.updateConfigHandler('createDate', {
      ...config.createDate,
      start: value,
    });
  };

  const createDateEndChangeHandler = value => {
    props.updateConfigHandler('createDate', {
      ...config.createDate,
      end: value,
    });
  };

  return (
    <PanelWrapper title = "營收搜尋">
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>專案代號</PSubtitle>
          <SelectSearchable
            value = { config.projectID }
            options = {[
              { value: '', desc: '請選擇專案代號' },
              ...props.projectIDOptions,
            ]}
            changeHandler = { value => {
              props.updateConfigHandler('projectID', value)
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>所屬公司</PSubtitle>
          <SelectNormal
            value = { config.companyType }
            options = {[
              { value: '', desc: '請選擇所屬公司' },
              ...props.companyTypeOptions,
            ]}
            changeHandler = { value => {
              props.updateConfigHandler('companyType', value)
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>客戶名稱</PSubtitle>
          <SelectSearchable
            value = { config.customerID }
            options = {[
              { value: '', desc: '請選擇客戶名稱' },
              ...props.customerOptions,
            ]}
            changeHandler = { value => {
              props.updateConfigHandler('customerID', value)
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>總監</PSubtitle>
          <SelectSearchable
            value = { config.director }
            options = {[
              { value: '', desc: '請選擇總監' },
              ...props.directorOptions,
            ]}
            changeHandler = { value => {
              props.updateConfigHandler('director', value)
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>發票號碼</PSubtitle>
          <InputBasic
            value = { config.invoiceCode }
            onChange = { ({ target }) => {
              props.updateConfigHandler('invoiceCode', target.value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>客戶採購編號</PSubtitle>
          <InputBasic
            value = { config.po }
            onChange = { ({ target }) => {
              props.updateConfigHandler('po', target.value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>業績認列日期</PSubtitle>
          <DivLongMultiSelector>
            <DatePeriodSelector
              startTime = { config.mbr.start }
              endTime = { config.mbr.end }
              endTimeOKHandler = { mbrEndChangeHandler }
              endTimeChangeHandler = { mbrEndChangeHandler }
              startTimeOKHandler = { mbrStartChangeHandler }
              startTimeChangeHandler = { mbrStartChangeHandler }
            />
          </DivLongMultiSelector>
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>發票開立日期</PSubtitle>
          <DivLongMultiSelector>
            <DatePeriodSelector
              startTime = { config.createDate.start }
              endTime = { config.createDate.end }
              endTimeOKHandler = { createDateEndChangeHandler }
              endTimeChangeHandler = { createDateEndChangeHandler }
              startTimeOKHandler = { createDateStartChangeHandler }
              startTimeChangeHandler = { createDateStartChangeHandler }
            />
          </DivLongMultiSelector>
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivButtonsWrapper>
          <div>
            {/* <ButtonAdd>更新MBR資料</ButtonAdd> */}
            <ButtonExport
              disabled = { !userExportable }
              onClick = { props.exportHandler }
            >匯出</ButtonExport>
          </div>
          <SearchButtonSet
            clearHandler = { props.clearHandler }
            searchHandler = { props.searchHandler }
          />
        </DivButtonsWrapper>
      </DivFlexRow>
    </PanelWrapper>
  );
};

export default PanelDirectorRevenueSearch;
