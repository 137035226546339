import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { cloneDeep } from 'lodash';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import {
  postToGetSystemRoleDetail,
  putModifySystemRoleDetail,
} from '@/api/authService';

import MainContent from '@/component-style/MainContent';
import PanelSystemRoleDetail from '@/components/Panel/Authority/PanelSystemRoleDetail';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import { ButtonBasic, ButtonCancel } from '@/component-style/Button';
import { DivFlexRow } from '@/component-style/RWDWrapper';

import { roleConfig } from '@/constant/dataConfig/authorityModule';

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding-right: 0;
  padding-left: 135px;
  padding-bottom: 30px;
  justify-content: flex-end;

  @media (max-width: 425px) {
    justify-content: center;
    padding-left: 0;
  }
`;

const ButtonSave = styled(ButtonBasic)`
  width: 120px;
  margin-right: 10px;
  margin-bottom: 10px;
`;

const ButtonClear = styled(ButtonCancel)`
  width: 120px;
  margin-bottom: 10px;
`;

const RoleDetail = props => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const authorityRoutes = useSelector(state => state.login.authorityRoutes);
  const showSaveFailPopup = useSelector(state => state.apiFail.showSaveFailPopup);
  const apiFailPopupTitle = useSelector(state => state.apiFail.title);
  const apiFailPopupMessage = useSelector(state => state.apiFail.message);

  const [role, setRole] = useState(cloneDeep(roleConfig));

  const [isEditing, setIsEditing] = useState(false);
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [showSaveSuccessPopup, setShowSaveSuccessPopup] = useState(false);

  const userEditable  = authorityRoutes.indexOf('RoleDetail-Edit') !== -1;

  const unsavable = role.roleCode === ''
    || role.roleName === '';

  const updateRoleHandler = (key, value) => {
    const updateObj = cloneDeep(role);
    updateObj[key] = value;
    setRole({...updateObj});
  };

  const closeSuccessPopupHandler = () => {
    setIsEditing(false);
    setShowSaveSuccessPopup(false);
  };

  const saveHandler = async () => {
    setShowLoadingPopup(true);

    try {
      await putModifySystemRoleDetail(role);
      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '儲存失敗', err }));
      console.log(err);
    }
  };

  const fetchRoleDetail = async () => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetSystemRoleDetail(id);
      setRole({
        ...roleConfig,
        ...data,
        projectRoutes: [ ...data.projectRoutes ],
        informationRoutes: [ ...data.informationRoutes ],
        attendanceRoutes: [ ...data.attendanceRoutes ],
        expenseRoutes: [ ...data.expenseRoutes ],
        financeRoutes: [ ...data.financeRoutes ],
        authorityRoutes: [ ...data.authorityRoutes ],
        sysConfigRoutes: [ ...data.sysConfigRoutes ],
        hrRoutes: [ ...data.hrRoutes ],
      });
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const initRole = useCallback(fetchRoleDetail, []);

  useEffect(() => {
    initRole();
  }, [initRole]);

  return (
    <MainContent>
      <PanelSystemRoleDetail
        title = "系統角色資料"
        role = { role }
        disabled = { !isEditing }
        disableRoleCode = { true }
        updateRoleHandler = { updateRoleHandler }
      >
        <DivFlexRow>
          <DivButtonsWrapper>
            {
              isEditing
                ? <ButtonSave
                    disabled = { unsavable }
                    onClick = { saveHandler }
                  >儲存</ButtonSave>
                : <ButtonSave
                    disabled = { !userEditable }
                    onClick = { () => { setIsEditing(true) } }
                  >編輯</ButtonSave>
            }
            <ButtonClear
              onClick = { () => { history.goBack() } }
            >離開</ButtonClear>
          </DivButtonsWrapper>
        </DivFlexRow>
      </PanelSystemRoleDetail>
      { showLoadingPopup && <PopupLoading/> }
      {
        showSaveSuccessPopup &&
          <PopupExecuteResult
            title = "儲存成功"
            confirmHandler = { closeSuccessPopupHandler }
          />
      }
      {
        showSaveFailPopup &&
          <PopupExecuteResult
            title = { apiFailPopupTitle }
            text = { apiFailPopupMessage }
            confirmHandler = { () => {
              dispatch(CLOSE_SAVE_FAIL_POPUP());
            }}
          />
      }
    </MainContent>
  );
};

export default RoleDetail;
