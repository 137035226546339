import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { cloneDeep } from 'lodash';

// import { postToGetExpenseVouchers, postToGetVouchersTotal } from '@/api/expenseService';

import { getGroupPreQuote, getGroupPreQuoteExcel } from '@/api/financeService';

import { FETCH_COMPANY_TYPE_OPTIONS, FETCH_DIRECTOR_OPTIONS } from '@/store/actions/srpOptionsAction';

import PanelRevenueByCustomerReceivedSearch from '@/components/Panel/Finance/PanelRevenueByCustomerReceivedSearch';
import TableRevenueByCustomerReceived from '@/components/Table/Finance/TableRevenueByCustomerReceived';
import PopupLoading from '@/components/UI/Popup/PopupLoading';

import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';

import { formatTime } from '@/utils/timeUtils';
import { postToGetUserInfo } from '@/api/authService';
// import { getOptionDesc } from '@/utils/textUtils';
// import { vendorInvoiceTypes } from '@/constant/options';
// import { searchConfigGenerator } from '@/utils/apiRequestUtils';
// import { exportExpenseVouchersFile } from '@/utils/xlsx';

// import moment from 'moment';

// import {
//   postToGetProjectPRServices,
// } from '@/api/financeService';

const DivPanelContainer = styled.div`
  margin-bottom: 40px;
`;

const searchConfig = {
  voucherPrefix: '',
  voucherCode: {
    start: '',
    end: '',
  },
  date: {
    start: null,
    end: null,
  },
  notForBusinessTax: '',
  companyType: '',
  director:[],
  amount: 10,
};

const RevenueByCustomerReceivedSearch = () => {
  const dispatch = useDispatch();
  const companyTypeOptions = useSelector(state => state.srpOptions.companyTypeOptions);
  const directorOptions = useSelector(state => state.srpOptions.directorOptions);
  const [directorByCompany, setDirectorByCompany] = useState([]);
  // console.log("RevenueByCompanySearch companyTypeOptions:" + JSON.stringify(companyTypeOptions));


  const [vouchers, setVouchers] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [config, setConfig] = useState(cloneDeep(searchConfig));

  const [showLoadingPopup, setShowLoadingPopup] = useState(false);

  const [alertText, setAlertText] = useState('');
  const [showAlertPopup, setShowAlertPopup] = useState(false);

  const openAlertPopup = text => {
    setAlertText(text);
    setShowAlertPopup(true);
  };

  const updateConfigHandler = (key, value) => {
    // console.log("fetchExpenseVouchers updateConfigHandler key:", key);
    const updateObj = { ...config };
    updateObj[key] = value;
    if (key === "companyType") {
      updateObj["director"] = [];
      getDirectorDetail(value);
    }
    setConfig({ ...updateObj });
  };

  const clearHandler = () => {
    setVouchers([]);
    setTotalCount(0);
    setConfig(cloneDeep(searchConfig));
  };

  const getDirectorDetail = async (companyType) => {
    // console.log("revenueByTeamSearch.js getDirectorDetail userInfo:" + JSON.stringify(directorOptions));
    // console.log("revenueByTeamSearch.js getDirectorDetail companyType:" + companyType);
    var directorOfCompany = [];
    for (let i = 0; i < directorOptions.length; i += 1 ) {
      // console.log("revenueByTeamSearch.js initOptions getDirectorDetail:" + JSON.stringify(directorOptions[i]));
      const userInfo = await postToGetUserInfo(directorOptions[i].value);
      // console.log("revenueByTeamSearch.js getDirectorDetail userInfo:" + JSON.stringify(userInfo));
      if (userInfo.data.companyType === companyType) {
        // console.log("revenueByTeamSearch.js getDirectorDetail userInfo:" + JSON.stringify(userInfo));
        // setDirectorOptions
        directorOfCompany.push({"value": directorOptions[i].value, "desc": userInfo.data.name});
      }
    }
    setDirectorByCompany(directorOfCompany);
  };

  const searchHandler = async () => {
    if (config.companyType === "" || config.companyType == null) {
      return openAlertPopup("請選擇公司");
    }
    if (config.director.length === 0) {
      return openAlertPopup("請選擇總監");
    }
    if (config.date.start === "" || config.date.start == null) {
      return openAlertPopup("請選擇發票開始日期");
    }
    if (config.date.end === "" || config.date.end == null) {
      return openAlertPopup("請選擇發票結束日期");
    }
    setCurrentPageHandler(1);
  };

  const setCurrentPageHandler = page => {
    setCurrentPage(page);
    fetchExpenseVouchers(page);
  };

  const exportHandler = async () => {
    setShowLoadingPopup(true);

    try {
      const { data } = await getGroupPreQuoteExcel(
        {
          startDate: formatTime(config.date.start, 'YYYY-MM-DD'),
          endDate: formatTime(config.date.end, 'YYYY-MM-DD'),
          companyTypeID: config.companyType,
          director: config.director.join(","),
        }
      );
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'CompanyPreReceived.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      setShowLoadingPopup(false);
      console.log(JSON.stringify(err));
    }
    setShowLoadingPopup(false);
  };

  const fetchExpenseVouchers = async (page = currentPage) => {
    setShowLoadingPopup(true);
    console.log("fetchExpenseVouchers config:" + JSON.stringify(config));
    try {
      const { data } = await getGroupPreQuote(
        {
          startDate: formatTime(config.date.start, 'YYYY-MM-DD'),
          endDate: formatTime(config.date.end, 'YYYY-MM-DD'),
          companyTypeID: config.companyType,
          director: config.director.join(","),
        }
      );
      console.log("fetchExpenseVouchers data:" + JSON.stringify(data));
      // const { vouchers } = data;
      // console.log("fetchExpenseVouchers vouchers:" + JSON.stringify(vouchers));

      setVouchers(data);
      setTotalCount(1);
    } catch(err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const initOptions = useCallback(() => {
    dispatch(FETCH_COMPANY_TYPE_OPTIONS());
    dispatch(FETCH_DIRECTOR_OPTIONS());
  }, [dispatch]);

  useEffect(() => {
    initOptions();
  }, [initOptions]);

  return(
    <>
      <DivPanelContainer>
        <PanelRevenueByCustomerReceivedSearch
          config = { config }
          companyTypeOptions = { companyTypeOptions }
          directorOptions = { directorByCompany }
          clearHandler = { clearHandler }
          exportHandler = { exportHandler }
          searchHandler = { searchHandler }
          updateConfigHandler = { updateConfigHandler }
        />
      </DivPanelContainer>
      <TableRevenueByCustomerReceived
        vouchers = { vouchers }
        totalCount = { totalCount }
        currentPage = { currentPage }
        pageChangedHandler = { setCurrentPageHandler }
      />
      { showLoadingPopup && <PopupLoading/> }
      {
        showAlertPopup &&
          <PopupExecuteResult
            title = "請選擇欄位"
            text = { alertText }
            confirmHandler = { () => { setShowAlertPopup(false) } }
          />
      }
    </>
  );
};

export default RevenueByCustomerReceivedSearch;
