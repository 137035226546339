import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { cloneDeep } from 'lodash';

import {
  putNewHoliday,
  postToGetHolidaysParams,
  patchModifyHolidaysParams,
} from '@/api/sysConfigService';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import MainContent from '@/component-style/MainContent';
import PanelWrapper from '@/components/UI/PanelWrapper';
import TableWrapper from '@/components/UI/TableWrapper';
import DateSelector from '@/components/UI/DateSelector';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import PopupHolidayDetail from '@/components/UI/Popup/PopupHolidayDetail';
import SelectMultiple from '@/components/UI/Select/SelectMultiple';
import SearchButtonSet from '@/components/UI/SearchButtonSet';
import { PSubtitle } from '@/component-style/TextElement';
import { ButtonBasic, ButtonCancel } from '@/component-style/Button';
import {
  DivFlexRow,
  DivItemWrapper,
  DivFullWidthWrapper,
  DivLongMultiSelector,
} from '@/component-style/RWDWrapper';

import { formatTime } from '@/utils/timeUtils';
import { integerConverter } from '@/utils/textUtils';
import { holidayParamsConfig } from '@/constant/dataConfig/sysConfigModule.js';

const DivPanelContainer = styled.div`
  margin-bottom: 40px;
`;

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding: 0;
  justify-content: space-between;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const DivEditButtonsWrapper = styled(DivButtonsWrapper)`
  justify-content: flex-end;
  padding-bottom: 30px;
`;

const ButtonAdd = styled(ButtonBasic)`
  margin-right: 10px;
  width: 120px;

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`;

const ButtonDiscard = styled(ButtonCancel)`
  width: 120px;

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`;

const newDayConfig = {
  type: '',
  year: null,
  date: null,
};

const HolidayManagement = () => {
  const dispatch = useDispatch();
  const sysConfigRoutes = useSelector(state => state.login.sysConfigRoutes);
  const showSaveFailPopup = useSelector(state => state.apiFail.showSaveFailPopup);
  const apiFailPopupTitle = useSelector(state => state.apiFail.title);
  const apiFailPopupMessage = useSelector(state => state.apiFail.message);

  const [holidayParams, setHolidayParams] = useState(cloneDeep(holidayParamsConfig));
  const [newDayDetail, setNewDayDetail] = useState({ ...newDayConfig });

  const [isEditing, setIsEditing] = useState(false);
  const [showNewDayPopup, setShowNewDayPopup] = useState(false);
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [showSaveSuccessPopup, setShowSaveSuccessPopup] = useState(false);

  const userAddable = sysConfigRoutes.indexOf('HolidayManagement-Add') !== -1;
  const userEditable = sysConfigRoutes.indexOf('HolidayManagement-Edit') !== -1;

  const uneditable = holidayParams.holidays.length === 0
    && holidayParams.restdays.length === 0
    && holidayParams.workdays.length === 0;

  const clearHandler = () => {
    setIsEditing(false);
    setNewDayDetail({ ...newDayConfig });
    setHolidayParams(cloneDeep(holidayParamsConfig))
  };

  const cancelHandler = () => {
    setIsEditing(false);
    fetchHolidayParams();
  };

  const yearChangeHandler = year => {
    clearHandler();
    setHolidayParams({
      ...cloneDeep(holidayParamsConfig),
      year,
    });
  };

  const updateNewDayDetailHandler = (key, value) => {
    const updateObj = { ...newDayDetail };
    updateObj[key] = value;
    setNewDayDetail({ ...updateObj });
  };

  const updateNewDayYearHandler = year => {
    setNewDayDetail(prevState => ({
      ...prevState,
      date: null,
      year,
    }));
  };

  const addNewDayHandler = () => {
    setNewDayDetail({ ...newDayConfig });
    setShowNewDayPopup(true);
  };

  const apiFailConfirmHandler = () => {
    dispatch(CLOSE_SAVE_FAIL_POPUP());
    setShowNewDayPopup(true);
  };

  const closeSaveSuccessPopupHandler = () => {
    setShowSaveSuccessPopup(false);
    setIsEditing(false);
    fetchHolidayParams();
  };

  const saveNewDayHandler = async () => {
    setShowNewDayPopup(false);
    setShowLoadingPopup(true);

    try {
      await putNewHoliday({
        ...newDayDetail,
        date: formatTime(newDayDetail.date, 'YYYY-MM-DD'),
        year: integerConverter(formatTime(newDayDetail.year, 'YYYY')),
      });
      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '儲存失敗', err }));
      console.log(err);
    }
  };

  const updateHolidayParamsHandler = async () => {
    setShowLoadingPopup(true);
    const year = integerConverter(
      formatTime(holidayParams.year, 'YYYY')
    );

    try {
      await patchModifyHolidaysParams({
        ...holidayParams,
        year,
      });
      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '儲存失敗', err }));
      console.log(err);
    }
  };

  const fetchHolidayParams = async () => {
    if (holidayParams.year === null) return; 
  
    setShowLoadingPopup(true);
    const year = integerConverter(
      formatTime(holidayParams.year, 'YYYY')
    );

    try {
      const { data } = await postToGetHolidaysParams({ year })

      setHolidayParams({
        year: holidayParams.year,
        holidays: data.holidays || [],
        restdays: data.restdays || [],
        workdays: data.workdays || [],
        nationalHolidays: data.nationalHolidays || [],
      });
    } catch (err) { console.log(err ) }

    setShowLoadingPopup(false);
  };

  return (
    <MainContent>
      <DivPanelContainer>
        <PanelWrapper title = "假日參數管理">
          <DivFlexRow>
            <DivItemWrapper>
              <PSubtitle>年份</PSubtitle>
              <DateSelector
                picker = "year"
                value = { holidayParams.year }
                changeHandler = { yearChangeHandler }
              />
            </DivItemWrapper>
          </DivFlexRow>
          <DivFlexRow>
            <DivButtonsWrapper>
              <ButtonAdd
                disabled = { !userAddable }
                onClick = { addNewDayHandler }
              >新增</ButtonAdd>
              <SearchButtonSet
                searchable = { holidayParams.year !== null }
                clearHandler = { clearHandler }
                searchHandler = { fetchHolidayParams }
              />
            </DivButtonsWrapper>
          </DivFlexRow>
        </PanelWrapper>
      </DivPanelContainer>
      <TableWrapper>
        <DivFlexRow>
          <DivFullWidthWrapper>
            <PSubtitle>工作日</PSubtitle>
            <DivLongMultiSelector>
              <SelectMultiple
                open = { false }
                disabled = { !isEditing }
                value = { holidayParams.workdays }
                options = { holidayParams.workdays.map( day => ({ value: day, desc: day })) }
                changeHandler = { value => {
                  setHolidayParams(prevState => ({
                    ...prevState,
                    workdays: value,
                  }))
                }}
              />
            </DivLongMultiSelector>
          </DivFullWidthWrapper>
        </DivFlexRow>
        <DivFlexRow>
          <DivFullWidthWrapper>
            <PSubtitle>休息日</PSubtitle>
            <DivLongMultiSelector>
              <SelectMultiple
                open = { false }
                disabled = { !isEditing }
                value = { holidayParams.restdays }
                options = { holidayParams.restdays.map( day => ({ value: day, desc: day })) }
                changeHandler = { value => {
                  setHolidayParams(prevState => ({
                    ...prevState,
                    restdays: value,
                  }))
                }}
              />
            </DivLongMultiSelector>
          </DivFullWidthWrapper>
        </DivFlexRow>
        <DivFlexRow>
          <DivFullWidthWrapper>
            <PSubtitle>例假日</PSubtitle>
            <DivLongMultiSelector>
              <SelectMultiple
                open = { false }
                disabled = { !isEditing }
                value = { holidayParams.holidays }
                options = { holidayParams.holidays.map( day => ({ value: day, desc: day })) }
                changeHandler = { value => {
                  setHolidayParams(prevState => ({
                    ...prevState,
                    holidays: value,
                  }))
                }}
              />
            </DivLongMultiSelector>
          </DivFullWidthWrapper>
        </DivFlexRow>
        <DivFlexRow>
          <DivFullWidthWrapper>
            <PSubtitle>國定假日</PSubtitle>
            <DivLongMultiSelector>
              <SelectMultiple
                open = { false }
                disabled = { !isEditing }
                value = { holidayParams.nationalHolidays }
                options = { holidayParams.nationalHolidays.map( day => ({ value: day, desc: day })) }
                changeHandler = { value => {
                  setHolidayParams(prevState => ({
                    ...prevState,
                    nationalHolidays: value,
                  }))
                }}
              />
            </DivLongMultiSelector>
          </DivFullWidthWrapper>
        </DivFlexRow>
        <DivFlexRow>
          <DivEditButtonsWrapper>
            {
              isEditing
                ? <ButtonAdd
                    onClick = { updateHolidayParamsHandler }
                  >儲存</ButtonAdd>
                : <ButtonAdd
                    disabled = { uneditable || !userEditable }
                    onClick = { () => { setIsEditing(true) }}
                  >編輯</ButtonAdd>
            }
            <ButtonDiscard
              onClick = { cancelHandler }
            >關閉</ButtonDiscard>
          </DivEditButtonsWrapper>
        </DivFlexRow>
      </TableWrapper>
      { showLoadingPopup && <PopupLoading/> }
      {
        showNewDayPopup &&
        <PopupHolidayDetail
          title = "新增日期"
          newDayDetail = { newDayDetail }
          cancelHandler = { () => { setShowNewDayPopup(false) }}
          confirmHandler = { saveNewDayHandler }
          updateNewDayYearHandler = { updateNewDayYearHandler }
          updateNewDayDetailHandler = { updateNewDayDetailHandler }
        />
      }
      {
        showSaveSuccessPopup &&
          <PopupExecuteResult
            title = "儲存成功"
            confirmHandler = { closeSaveSuccessPopupHandler }
          />
      }
      {
        showSaveFailPopup &&
          <PopupExecuteResult
            title = { apiFailPopupTitle }
            text = { apiFailPopupMessage }
            confirmHandler = { apiFailConfirmHandler }
          />
      }
    </MainContent>
  );
};

export default HolidayManagement;
