import axiosConfig from './axiosConfig';
import { getDefaultPort } from '@/utils/apiRequestUtils';

const { baseURL } = axiosConfig.defaults;

const port = process.env.REACT_APP_SERVICE_PORT_ACTIVITY_IDEA !== undefined
  ? process.env.REACT_APP_SERVICE_PORT_ACTIVITY_IDEA
  : getDefaultPort(baseURL);

const activityIdeaModuleUrl = process.env.NODE_ENV === 'development'
  ? `${baseURL}:${port}/api`
  : `${baseURL}/activityIdea/api`;

export const postToGetActivityIdeas = config => axiosConfig
  .post(`${activityIdeaModuleUrl}/activityIdea`, config);

export const putNewActivityIdea = activityIdea => axiosConfig
  .put(`${activityIdeaModuleUrl}/activityIdea`, activityIdea);

export const deleteActivityIdea = id => axiosConfig
  .delete(`${activityIdeaModuleUrl}/activityIdea`, { data: { id } });

export const postToGetActivityIdeaDetail = id => axiosConfig
  .post(`${activityIdeaModuleUrl}/activityIdea/detail`, { id });

export const putModifyActivityIdeaDetail = activityIdea => axiosConfig
  .put(`${activityIdeaModuleUrl}/activityIdea/detail`, activityIdea);

export const postToGetActivityIdeaTypes = config => axiosConfig
  .post(`${activityIdeaModuleUrl}/activityIdea/types`, config);

export const deleteActivityIdeaTypes = ids => axiosConfig
  .delete(`${activityIdeaModuleUrl}/activityIdea/types`, { data: [ ...ids ]});

export const putNewActivityIdeaType = config => axiosConfig
  .put(`${activityIdeaModuleUrl}/activityIdea/types`, config);

export const patchModifyActivityIdeaType = config => axiosConfig
  .patch(`${activityIdeaModuleUrl}/activityIdea/types`, config);

export const postToGetActivityIdeaTags = config => axiosConfig
  .post(`${activityIdeaModuleUrl}/activityIdea/type/tag`, config);

export const putNewActivityIdeaTag = config => axiosConfig
  .put(`${activityIdeaModuleUrl}/activityIdea/type/tag`, config);

export const patchModifyActivityIdeaTag = config => axiosConfig
  .patch(`${activityIdeaModuleUrl}/activityIdea/type/tag`, config);

export const deleteActivityIdeaTag = config => axiosConfig
  .delete(`${activityIdeaModuleUrl}/activityIdea/type/tag`, { data: { ...config } });
