import React from 'react';
import styled from 'styled-components';

import { TableHead, TableRow, ThItem } from '../../../component-style/Table';

const THead = styled(TableHead)`
  min-width: 640px;
`;

const Th120 = styled(ThItem)`
  width: 120px;
`;

const Th160 = styled(ThItem)`
  width: 160px;
`;

const Th240 = styled(ThItem)`
  width: 240px;
`;

const Header = props => {
  
  return (
    <THead>
      <TableRow>
        <Th120>專案代號</Th120>
        <Th240>專案名稱</Th240>
        <Th160>服務說明</Th160>
        <Th120>報價金額</Th120>
      </TableRow>
    </THead>
  );
};

export default Header;
