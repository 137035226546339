import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
// import 'antd/dist/antd.css';
// import { Table as ADTable, Tag, Space } from 'antd';

import { postToGetUploadFiles, 
  postToGetUploadFilesWithArrayOfProjects 
} from '@/api/storageService';

import {
  postToGetProjectList,
  postToGetProjectDetail,
  postToGetProjectDetailWithArrayOfProjects,
} from '@/api/projectService';

import {
  postToGetProjectQuotations,
  postToGetProjectQuotationsWithArrayOfProjects,
  postToGetProjectInvoicesDetail,
  postToGetProjectInvoicesDetailWithArrayOfProjects,
} from '@/api/financeService';

import {
  postToGetQuotationsWithArrayOfProjectIDs,
  postToGetQuotations,
  postToGetVouchersTotal,
  postToGetVouchersTotalWithArrayOfProjectIDs,
  postToGetExpenseParamsConfig
} from '@/api/expenseService';

import {
  FETCH_CUSTOMER_OPTIONS,
  FETCH_PROJECT_TYPE_OPTIONS,
  FETCH_PROJECT_CODE_OPTIONS,
  FETCH_COMPANY_TYPE_OPTIONS,
} from '@/store/actions/srpOptionsAction';

import MainContent from '@/component-style/MainContent';
import PanelProjectSearch from '@/components/Panel/PanelProjectSearch';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
// import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import TableWrapper from '@/components/UI/TableWrapper';
import TableHeader from '@/components/Table/TableProjects/Header';
import TableItem from '@/components/Table/TableProjects/Item';
import PageSelector from '@/components/UI/PageSelector';
import { TableBody, Table } from '@/component-style/Table';
import { searchConfigGenerator } from '@/utils/apiRequestUtils';

import { integerConverter } from '@/utils/textUtils';
import { diffTime } from '@/utils/timeUtils';
import {
  useSort
} from '@/hooks/sort';

const DivPanelContainer = styled.div`
  margin-bottom: 40px;
`;

const ProjectList = props => {
  const dispatch = useDispatch();
  // const history = useHistory();
  const projectRoutes = useSelector(state => state.login.projectRoutes);
  const customerOptions = useSelector(state => state.srpOptions.customerOptions);
  const projectCodeOptions = useSelector(state => state.srpOptions.projectCodeOptions);
  const projectTypeOptions = useSelector(state => state.srpOptions.projectTypeOptions);

  const [projects, setProjects] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [searchConfig, setSearchConfig] = useState({
    projectCode: '',
    projectName: '',
    customer: '',
    projectType: '',
    actionTimeStart: {
      start: null,
      end: null,
    },
    amount: 10,
  });

  // const [alertText, setAlertText] = useState('');
  // const [showAlertPopup, setShowAlertPopup] = useState(false);
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);

  const { tableSortConfig, tableSortHandler, getTableSortIcon } = useSort("companyType");

  const disableDetailPage = projectRoutes.indexOf('ProjectDetail') === -1;

  const goProjectDetailHandler = projectID => {
    // history.push(`/Project/ProjectDetail/${projectID}`);
    window.open(`/Project/ProjectDetail/${projectID}`, "_blank");
    // const project = projects.filter(project => project.projectID === projectID).pop();
    // const { noPO, notQuoted, unlocked } = project;
    // const isProjectLocked = !unlocked && (noPO || notQuoted);

    // if (isProjectLocked) {
    //   const alertText = `
    //     專案已被鎖定，
    //     ${ noPO ? '無PO資料，' : '' }
    //     ${ notQuoted ? '無報價資料，' : '' }
    //     請專案總監或總經理解鎖。
    //   `;
    //   setAlertText(alertText);
    //   setShowAlertPopup(true);
    // } else {
    //   history.push(`/Project/ProjectDetail/${projectID}`);
    // }
  };

  const updateConfigHandler = (key, value) => {
    const updateObj = { ...searchConfig };
    if (key === "startTime") {
      updateObj["actionTimeStart"].start = value;
    } else if (key === "endTime") {
      updateObj["actionTimeStart"].end = value;
    } else {
      updateObj[key] = value;
    }
    setSearchConfig({ ...updateObj });
  }; 

  const searchHandler = () => {
    setCurrentPageHandler(1);
  };

  const setCurrentPageHandler = page => {
    setCurrentPage(page);
    updateProjectList(page);
  };

  const clearHandler = () => {
    setSearchConfig({
      projectCode: '',
      projectName: '',
      customer: '',
      projectType: '',
      actionTimeStart: {
        start: null,
        end: null,
      },
      amount: 10,
    })
  };

  const updateProjectList = async (page = currentPage) => {
    setShowLoadingPopup(true);
    let agentDifference = 0;

    try {
      const { sortField, sortOrder } = tableSortConfig;
      const { data } = await postToGetProjectList(
        searchConfigGenerator({ ...searchConfig, page, sortField, sortOrder }));
      // console.log("searchConfigGenerator:" + JSON.stringify(searchConfigGenerator({ ...searchConfig, page, sortField })));
      const { projects, totalCount } = data;
      // projects.sort((a, b) => a.projectCode > b.projectCode ? 1 : -1);
      console.log("projectList.js JSON.stringify(projects):" + JSON.stringify(projects));
      const projectIDs = projects.map(element => element.projectID);
      // console.log("projectList.js JSON.stringify(projectIDs):" + JSON.stringify(projectIDs));
      const projectsData = await postToGetProjectDetailWithArrayOfProjects(projectIDs);
      // console.log("projectList.js JSON.stringify(projectsData):" + JSON.stringify(projectsData));
      const projectsInvoicesDetail = await postToGetProjectInvoicesDetailWithArrayOfProjects(projectIDs);
      // console.log("projectList.js JSON.stringify(projectsInvoicesDetail):" + JSON.stringify(projectsInvoicesDetail));

      const arrayOfObject = projects.map(element => ({ objectType: "projectFinance", objectID: element.projectID }) );
      const arrayOfProjectFiles = await postToGetUploadFilesWithArrayOfProjects(arrayOfObject);
      // console.log("projectList.js JSON.stringify(arrayOfProjectFiles):" + JSON.stringify(arrayOfProjectFiles));

      const arrayOfProjectQuotations = await postToGetProjectQuotationsWithArrayOfProjects(
        {
          projectID: projectIDs,
          amount: totalCount || 1,
          page: 1,
        }
      );
      // console.log("projectList.js JSON.stringify(arrayOfProjectQuotations):" + JSON.stringify(arrayOfProjectQuotations));

      const arrayOfExpenseTotal = await postToGetVouchersTotalWithArrayOfProjectIDs(
        {
          projectID: projectIDs,
          object: [],
          item: 'amount',
          approvalStatus: [
            'projectManagerVerify',
            'projectDirectorVerify',
            'financeVerify',
            'generalManagerVerifying',
            'generalManagerApproved',
          ],
        }
      );
      // console.log("projectList.js JSON.stringify(arrayOfExpenseTotal):" + JSON.stringify(arrayOfExpenseTotal));

      const arrayOfQuotationsDetail = await postToGetQuotationsWithArrayOfProjectIDs( {
        projectID: projectIDs
      });
      console.log("projectList.js JSON.stringify(arrayOfQuotationsDetail):" + JSON.stringify(arrayOfQuotationsDetail));

      const expenseParamsDetail = await postToGetExpenseParamsConfig();
      agentDifference = expenseParamsDetail.data.agentDifference || 0;

      for (let i = 0; i < projects.length; i += 1) {
        const project = projects[i];
        const { projectID } = project;
        // const projectData = await postToGetProjectDetail(projectID);
        const projectData = projectsData.data.find(singleProject => singleProject.projectID === projectID);
        // const projectInvoices = await postToGetProjectInvoicesDetail(projectID);
        const projectInvoices = projectsInvoicesDetail.data.find(singleProject => singleProject.projectID === projectID);
        // const projectFinanceFiles = await postToGetUploadFiles('projectFinance', projectID);
        // console.log("projectList.js JSON.stringify(arrayOfProjectFiles):" + JSON.stringify(arrayOfProjectFiles));
        const projectFinanceFiles = arrayOfProjectFiles.data?.find(singleProject => singleProject.objectID === projectID);
        // const projectQuotations = await postToGetProjectQuotations({
        //   projectID,
        //   amount: totalCount || 1,
        //   page: 1,
        // });
        // console.log("projectList.js JSON.stringify(projectQuotations.data):" + JSON.stringify(projectQuotations.data));
        var projectQuotations = {
          data: { 
            quotations: arrayOfProjectQuotations.data.quotations?.filter((obj) => {
              if (obj["projectID"] === projectID) {
                  return true;
              }
              return false;
            })
          }
        };
        projectQuotations.data.totalCount = projectQuotations.data.quotations?.length;
        // console.log("projectList.js JSON.stringify(projectQuotations):" + JSON.stringify(projectQuotations));

        // const quotationsDetail = await postToGetQuotations({ projectID });
        var quotationsDetail = {
          data: [
            arrayOfQuotationsDetail.data.filter((obj) => {
              if (obj["projectID"] === projectID) {
                  return true;
              }
              return false;
            })
          ]
        };

        // const voucherTotalDetail = await postToGetVouchersTotal({
        //   projectID,
        //   object: [],
        //   item: 'amount',
        //   approvalStatus: [
        //     'projectManagerVerify',
        //     'projectDirectorVerify',
        //     'financeVerify',
        //     'generalManagerVerifying',
        //     'generalManagerApproved',
        //   ],
        // });

        console.log("projectList.js JSON.stringify(quotationsDetail):" + JSON.stringify(quotationsDetail));
        const quotationPriceTotal = quotationsDetail.data?.map(({ price }) => integerConverter(price))
          .reduce((acc, cur) => acc + cur, 0);
        // const quotationPriceTotal = quotationsDetail.data
        //   .map(({ price }) => integerConverter(price))
        //   .reduce((acc, cur) => acc + cur, 0);
          
        // const voucherPriceTotal = voucherTotalDetail.data.total || 0;
        var voucherPriceTotal = 0;
        const foundExpenseTotal = arrayOfExpenseTotal.data.find(singleProject => singleProject.projectID === projectID);
        if (foundExpenseTotal !== null) {
          voucherPriceTotal = foundExpenseTotal?.total;
        }
        // console.log("projectList.js voucherPriceTotal:" + voucherPriceTotal);

        const oopQuotations = projectQuotations.data.quotations?.filter(quotation => quotation.type === 'oop');

        const oopPriceTotal = oopQuotations?.map(({ price }) => integerConverter(price || 0))
          .reduce((acc, cur) => acc + cur, 0);
        
        let financeStatus = 'success';

        const isFinanceFail = quotationPriceTotal - oopPriceTotal > agentDifference
          || voucherPriceTotal - oopPriceTotal > agentDifference;
  
        const isFinanceWarning = quotationPriceTotal > oopPriceTotal
          || voucherPriceTotal > oopPriceTotal;
  
        if (isFinanceWarning) { financeStatus = 'warning' }
        if (isFinanceFail) { financeStatus = 'fail' }

        // const { invoices } = projectInvoices.data;
        const { invoices } = projectInvoices;
        // console.log("projectList.js JSON.stringify(projectInvoices):" + JSON.stringify(projectInvoices));
        const isInvoiced = invoices !== undefined && invoices.length > 0;
        // console.log("projectList.js JSON.stringify(projectInvoices):" + JSON.stringify(projectInvoices));
        const isPaid = invoices?.some(quotation => quotation.paymentDate !== undefined);
        // console.log("projectList.js JSON.stringify(projectInvoices):" + JSON.stringify(projectInvoices));
        const predictDates = invoices?.filter(({ predictDate }) => predictDate !== undefined)
          .map(({ predictDate }) => predictDate);
        // console.log("projectList.js JSON.stringify(projectInvoices):" + JSON.stringify(projectInvoices));
        const predictDate = predictDates?.length > 0
          ? predictDates.shift()
          : null;
        // console.log("projectList.js JSON.stringify(projectInvoices):" + JSON.stringify(projectInvoices));
        /*const unlocked = projectData.data.projectUnlock !== null
          && projectData.data.projectUnlock !== undefined
          && diffTime(projectData.data.projectUnlock, Date.now(), 'days') <= 1;*/
        const unlocked = diffTime(projectData.actionTimeStart, Date.now(), 'days') <= 10;

        const notQuoted = projectQuotations.data.totalCount === undefined
          && diffTime(projectData.actionTimeEnd, Date.now(), 'days') < 8;

        const noPO = projectFinanceFiles?.data?.length === 0
          && diffTime(projectData.actionTimeEnd, Date.now(), 'days') < 4;

        project.noPO = noPO;
        project.isPaid = isPaid;
        project.unlocked = unlocked;
        project.notQuoted = notQuoted;
        project.isInvoiced = isInvoiced;
        project.financeStatus = financeStatus;
        project.predictDate = isInvoiced ? predictDate : null;
        project.actionTimeEnd = projectData.actionTimeEnd;
        // project.isVerified = projectFinanceFiles?.data?.length > 0;
        project.isVerified = (projectFinanceFiles !== undefined);
        project.isQuoted = projectQuotations.data.quotations.length
          && projectQuotations.data.quotations.length > 0;
        if (projectQuotations.data.quotations.length === 0) {
          project.isQuoted = false;
        }
      }

      setProjects([...projects]);
      setTotalCount(totalCount);
    } catch (err) {
      console.log(err);
    }

    setShowLoadingPopup(false);
  };

  const initOptions = useCallback(() => {
    dispatch(FETCH_CUSTOMER_OPTIONS());
    dispatch(FETCH_PROJECT_CODE_OPTIONS());
    dispatch(FETCH_PROJECT_TYPE_OPTIONS());
    dispatch(FETCH_COMPANY_TYPE_OPTIONS());
  }, [dispatch]);

  const initData = useCallback(
    updateProjectList,[]);

  useEffect(() => {
    initData();
    initOptions();
    // sortConfigChanged();
  }, [initData, initOptions]);

  useEffect(() => {
    updateProjectList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableSortConfig]);

  // const TomatoADTable = styled(ADTable)`
  //   .ant-table-thead > tr > th {
  //     color: rgba(118, 118, 118, 0.85);
  //     font-weight: 500;
  //     text-align: center;
  //   }
  // `;

  // const columns = [
  //   {
  //     title: '所屬公司',
  //     dataIndex: 'name',
  //     key: 'name',
  //     render: text => <a>{text}</a>,
  //   },
  //   {
  //     title: '專案代號',
  //     dataIndex: 'age',
  //     key: 'age',
  //   },
  //   {
  //     title: '專案名稱',
  //     dataIndex: 'address',
  //     key: 'address',
  //   },
  //   {
  //     title: 'Tags',
  //     key: 'tags',
  //     dataIndex: 'tags',
  //     render: tags => (
  //       <>
  //         {tags.map(tag => {
  //           let color = tag.length > 5 ? 'geekblue' : 'green';
  //           if (tag === 'loser') {
  //             color = 'volcano';
  //           }
  //           return (
  //             <Tag color={color} key={tag}>
  //               {tag.toUpperCase()}
  //             </Tag>
  //           );
  //         })}
  //       </>
  //     ),
  //   },
  //   {
  //     title: 'Action',
  //     key: 'action',
  //     render: (text, record) => (
  //       <Space size="middle">
  //         <a>Invite {record.name}</a>
  //         <a>Delete</a>
  //       </Space>
  //     ),
  //   },
  // ];
  
  // const data = [
  //   {
  //     key: '1',
  //     name: 'John Brown',
  //     age: 32,
  //     address: 'New York No. 1 Lake Park',
  //     tags: ['nice', 'developer'],
  //   },
  //   {
  //     key: '2',
  //     name: 'Jim Green',
  //     age: 42,
  //     address: 'London No. 1 Lake Park',
  //     tags: ['loser'],
  //   },
  //   {
  //     key: '3',
  //     name: 'Joe Black',
  //     age: 32,
  //     address: 'Sidney No. 1 Lake Park',
  //     tags: ['cool', 'teacher'],
  //   },
  // ];

  return (
    <MainContent>
      <DivPanelContainer>
        <PanelProjectSearch
          config = { searchConfig }
          customerOptions = { customerOptions }
          projectCodeOptions = { projectCodeOptions }
          projectTypeOptions = { projectTypeOptions }
          clearHandler = { clearHandler }
          searchHandler = { searchHandler }
          updateConfigHandler = { updateConfigHandler }
        />
      </DivPanelContainer>
      <TableWrapper title = "專案清單">
        <Table>
          <TableHeader 
            showDetailButton = { true }
            getTableSortIcon = { getTableSortIcon }
            tableSortConfig = { tableSortConfig }
            tableSortHandler = { tableSortHandler }
          />
          <TableBody>
            {
              projects.map(project => 
                <TableItem
                  key = { project.projectID }
                  project = { project }
                  showDetailButton = { true }
                  disableDetailPage = { disableDetailPage }
                  goDetailHandler = { () => {
                    goProjectDetailHandler(project.projectID);
                  }}
                />
              )
            }
          </TableBody>
        </Table>
        <PageSelector
          total = { totalCount }
          current = { currentPage }
          pageChangedHandler = { setCurrentPageHandler }
        />
      </TableWrapper>
      {/* <TableWrapper title = "專案清單2">
        <TomatoADTable
          columns={columns} 
          dataSource={data}
        />
      </TableWrapper> */}
      { showLoadingPopup && <PopupLoading/> }
      {/* {
        showAlertPopup &&
          <PopupExecuteResult
            text = { alertText }
            confirmHandler = { () => { setShowAlertPopup(false) } }
          />
      } */}
    </MainContent>
  );
};

export default ProjectList;
