import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  postToModifyPassword,
  getSyncAzureAccountUrl,
  postToGetPasswordParams,
} from '@/api/authService';

import {
  AZURE_SYNC_START,
  CLOSE_AZURE_SYNC_POP,
} from '@/store/actions/loginAction';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import MainContent from '@/component-style/MainContent';
import TableWrapper from '@/components/UI/TableWrapper';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import PopupExecuteConfirm from '@/components/UI/Popup/PopupExecuteConfirm';
import PopupPasswordLimitation from '@/components/UI/Popup/PopupPasswordLimitation';
import { InputBasic } from '@/component-style/Input';
import { ButtonBasic, ButtonCancel } from '@/component-style/Button';
import { PSubtitle } from '@/component-style/TextElement';
import {
  DivFlexRow,
  DivItemWrapper,
} from '@/component-style/RWDWrapper';

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding: 0;
  padding-bottom: 30px;
  justify-content: space-between;

  @media (max-width: 425px) {
    justify-content: center;
    padding-left: 0;
  }
`;

const ButtonExecute = styled(ButtonBasic)`
  width: 120px;

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`;

const ButtonDiscard = styled(ButtonCancel)`
  margin-left: 10px;
  width: 120px;

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`;

const AccountSetting = () => {
  const dispatch = useDispatch();
  const userName = useSelector(state => state.login.user);
  const syncLoading = useSelector(state => state.login.loading);
  const syncAzure = useSelector(state => state.login.syncAzure);
  const syncAzureFail = useSelector(state => state.login.syncAzureFail);
  const syncAzureSuccess = useSelector(state => state.login.syncAzureSuccess);
  const showSaveFailPopup = useSelector(state => state.apiFail.showSaveFailPopup);
  const apiFailPopupTitle = useSelector(state => state.apiFail.title);
  const apiFailPopupMessage = useSelector(state => state.apiFail.message);

  const [config, setConfig] = useState({
    oldPassword: '',
    newPassword: '',
  });

  const [pwMinLength, setPWMinLength] = useState(8);
  const [confirmPassword, setConfirmPassword] = useState('');

  const [alertText, setAlertText] = useState('');
  const [showSavePopup, setShowSavePopup] = useState(false);
  const [showAlertPopup, setShowAlertPopup] = useState(false);
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [showSaveSuccessPopup, setShowSaveSuccessPopup] = useState(false);
  const [showWeakPasswordPopup, setShowWeakPasswordPopup] = useState(false);

  const disableSave = config.oldPassword === ''
    || config.newPassword === ''
    || confirmPassword === '';

  const clearHandler = () => {
    setConfirmPassword('');
    setConfig({
      oldPassword: '',
      newPassword: '',
    });
  };

  const openAlertPopup = text => {
    setAlertText(text);
    setShowAlertPopup(true);
  };

  const closeSuccessPopupHandler = () => {
    clearHandler();
    setShowSaveSuccessPopup(false);
  };

  const syncAzureAccount = () => {
    dispatch(AZURE_SYNC_START());
    window.location.href = getSyncAzureAccountUrl();
  };

  const closeSyncPopupHandler = () => {
    dispatch(CLOSE_AZURE_SYNC_POP());
  };

  const fetchPasswordConfig = async () => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetPasswordParams();
      const { minLength } = data;
      setPWMinLength(minLength || 0);
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const saveHandler = async () => {
    if (config.newPassword !== confirmPassword) {
      return openAlertPopup('新密碼與確認新密碼不符，請確認');
    }

    if (config.newPassword.length < pwMinLength) {
      return setShowWeakPasswordPopup(true);
    }

    setShowLoadingPopup(true);
    try {
      await postToModifyPassword(config);
      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
    } catch (err) {
      console.log(err);
      setShowLoadingPopup(false);
      err.response && err.response.data.message === 'password is too weak'
        ? setShowWeakPasswordPopup(true)
        : dispatch(OPEN_SAVE_FAIL_POPUP({ title: '更新失敗', err }));
    }
  };

  useEffect(() => {
    fetchPasswordConfig();
  }, []);

  return (
    <MainContent>
      <TableWrapper title = "密碼設定">
        <DivFlexRow>
          <DivItemWrapper>
            <PSubtitle>使用者名稱</PSubtitle>
            <InputBasic
              disabled = { true }
              value = { userName }
            />
          </DivItemWrapper>
          <DivItemWrapper>
            <PSubtitle>舊密碼</PSubtitle>
            <InputBasic
              type = "password"
              autocomplete = { false }
              value = { config.oldPassword }
              onChange = { ({ target }) => {
                setConfig({
                  ...config,
                  oldPassword: target.value,
                });
              }}
            />
          </DivItemWrapper>
        </DivFlexRow>
        <DivFlexRow>
          <DivItemWrapper>
            <PSubtitle>新密碼</PSubtitle>
            <InputBasic
              type = "password"
              autocomplete = { false }
              value = { config.newPassword }
              onChange = { ({ target }) => {
                setConfig({
                  ...config,
                  newPassword: target.value,
                });
              }}
            />
          </DivItemWrapper>
          <DivItemWrapper>
            <PSubtitle>確認新密碼</PSubtitle>
            <InputBasic
              type = "password"
              autocomplete = { false }
              value = { confirmPassword }
              onChange = { ({ target }) => {
                setConfirmPassword(target.value);
              }}
            />
          </DivItemWrapper>
        </DivFlexRow>
        <DivFlexRow>
          <DivButtonsWrapper>
            <div>
              <ButtonExecute
                disabled = { syncAzure }
                onClick = { syncAzureAccount }
              >
              { syncAzure ? '已連動 Azure': '連動 Azure 登入 ' }
              </ButtonExecute>
            </div>
            <div>
              <ButtonExecute
                disabled = { disableSave }
                onClick = { saveHandler }
              >更新密碼</ButtonExecute>
              <ButtonDiscard
                onClick = { clearHandler } 
              >清除重填</ButtonDiscard>
            </div>
          </DivButtonsWrapper>
        </DivFlexRow>
      </TableWrapper>
      { (showLoadingPopup || syncLoading) && <PopupLoading/> }
      { 
        showSavePopup &&
          <PopupExecuteConfirm
            title = "更新密碼"
            text = "確定更換新密碼？"
            confirmHandler = { saveHandler }
            cancelHandler = { () => { setShowSavePopup(false) }}
          />
      }
      {
        showSaveSuccessPopup &&
          <PopupExecuteResult
            title = "更新成功"
            confirmHandler = { closeSuccessPopupHandler }
          />
      }
      {
        showSaveFailPopup &&
          <PopupExecuteResult
            title = { apiFailPopupTitle }
            text = { apiFailPopupMessage }
            confirmHandler = { () => {
              dispatch(CLOSE_SAVE_FAIL_POPUP());
            }}
          />
      }
      {
        showAlertPopup &&
          <PopupExecuteResult
            title = "更新失敗"
            text = { alertText }
            confirmHandler = { () => { setShowAlertPopup(false) } }
          />
      }
      {
        showWeakPasswordPopup &&
          <PopupPasswordLimitation
            minLength = { pwMinLength }
            confirmHandler = { () => { setShowWeakPasswordPopup(false) } }
          />
      }
      {
        syncAzureSuccess &&
          <PopupExecuteResult
            title = "連動成功"
            confirmHandler = { closeSyncPopupHandler }
          />
      }
      {
        syncAzureFail &&
          <PopupExecuteResult
            title = "連動失敗"
            confirmHandler = { closeSyncPopupHandler }
          />
      }
    </MainContent>
  );
};

export default AccountSetting;
