import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import SelectSearchable from '@/components/UI/Select/SelectSearchable';
import { InputBasic } from '@/component-style/Input';
import { ButtonBasic } from '@/component-style/Button';
import RemoveImg from '@/assets/images/icon_remove.svg';
import { integerConverter } from '@/utils/textUtils';

const DivWrapper = styled.div`
  width: 100%;
  
  @media (min-width: 768px) {
    width: calc(100% - 135px);
  }
`;

const DivFWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const DivSelectorWrapper = styled.div`
  margin-right: 20px;
  margin-bottom: 10px;
  width: 300px;

  @media (max-width: 768px) {
    &:first-child { margin-bottom: 10px }
  }

  @media (max-width: 320px) { width: 260px }

  @media (min-width: 1920px) {
    .ant-select { width: 300px; }
  }
`;

const InputBudget = styled(InputBasic)`
  width: 300px;

  @media (min-width: 1920px) { width: 300px }
`;

const ButtonAdd = styled(ButtonBasic)`
  width: 150px;
`;

const DivIconRemove = styled.div`
  cursor: pointer;
  width: 20px;
  height: 40px;
  background-image: url(${ props => props.url });
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  @media (max-width: 375px) {
    background-position: top;
  }
`;

const CreditCardPayments = props => {
  const { creditCards } = props;
  var memberOptions = useSelector(state => state.srpOptions.memberOptions);
  console.log("CreditCardPayments memberOptions:" + JSON.stringify(memberOptions));
  memberOptions = memberOptions.filter(
    (member) => {
      return member.desc.startsWith('Tsai (蔡明勳)') 
              || member.desc.startsWith('Gennie (嚴德芬)')
              || member.desc.startsWith('Felix (嚴樹芬)')
              || member.desc.startsWith('Doreen (秦鴻英)')
              || member.desc.startsWith('Peter (鄧文勤)')
              || member.desc.startsWith('Jason (劉振崗)')
              || member.desc.startsWith('Linyang (楊煜霖)')
              || member.desc.startsWith('Scarly (馮達君)')
              || member.desc.startsWith('Winnie (陳家興)')
              || member.desc.startsWith('Virginia (李惠真)')
              || member.desc.startsWith('Tino (陳厚儒)')
              || member.desc.startsWith('Richard (陳建佑)')
              || member.desc.startsWith('Xinyu (張馨予)');
    }
  );

  const addCreditCardHandler = () => {
    props.updateCreditCardsHandler([
      ...creditCards,
      { user: '', amount: '' },
    ]);
  };

  const removeHandler = index => {
    const filteredList = creditCards
      .filter((item, i) => i !== index);

    props.updateCreditCardsHandler(filteredList);
  };

  const updateMemberHandler = (index, value) => {
    const updateList = [ ...creditCards ]
    updateList[index] = { user: value, amount: '' };
    props.updateCreditCardsHandler(updateList);
  };

  const updateAmountHandler = (index, value) => {
    const updateList = [ ...creditCards ]
    updateList[index].amount = integerConverter(value);
    props.updateCreditCardsHandler(updateList);
  };

  return (
    <DivWrapper>
      {
        creditCards.map(({ user, amount }, index) =>
          <DivFWrapper key = { index }>
            <DivSelectorWrapper>
              <SelectSearchable
                disabled = { props.disabled }
                value = { user }
                options = {[
                  { value: '', desc: '選擇持卡人' },
                  ...memberOptions,
                ]}
                changeHandler = { value => {
                  updateMemberHandler(index, value)
                }}
              />
            </DivSelectorWrapper>
            <DivSelectorWrapper>
              <InputBudget
                disabled = { props.disabled }
                value = { amount }
                onChange = { ({ target }) => {
                  updateAmountHandler(index, target.value);
                }}
              />
            </DivSelectorWrapper>
            <DivIconRemove
              url = { RemoveImg }
              onClick = { () => {
                !props.disabled && removeHandler(index);
              }}
            />
          </DivFWrapper>
        )
      }
      <ButtonAdd
        disabled = { props.disabled }
        onClick = { addCreditCardHandler }
      >新增
      </ButtonAdd>
    </DivWrapper>
  );
};

export default CreditCardPayments;
