import styled from 'styled-components';

export const Table = styled.table`
  display: block;
  width: 100%;
  overflow: scroll;
`;

export const TableHead = styled.thead`
  height: 60px;
  line-height: 60px;
  display: block;
  padding: 0;
  border-top: 2px solid #f2f2f2;
  border-bottom: 2px solid #f2f2f2;
`;

export const TableRow = styled.tr`
  display: flex;
  justify-content: space-between;
`;

export const ThItem = styled.th`
  padding: 0;
  display: block;
  text-align: center;
  font-size: 14px;
  font-weight: normal;
  // color: #767676;
  color: #4d4d4d;
  flex-shrink: 0;
`;

export const TableBody = styled.tbody`
  display: block;
  padding: 0;
`;

export const TdItem = styled.td`
  padding: 20px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 60px;
  font-size: 12px;
  font-weight: normal;
  // color: #767676;
  color: #4d4d4d;
  box-sizing: border-box;
  text-align: center;
  flex-shrink: 0;
`;
