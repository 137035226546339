import React from 'react';

import TableWrapper from '@/components/UI/TableWrapper';
import SelectNormal from '@/components/UI/Select/SelectNormal';
import Tree from '@/components/UI/Tree';
import { InputBasic } from '@/component-style/Input';
import { PSubtitle } from '@/component-style/TextElement';
import {
  DivFlexRow,
  DivItemWrapper,
} from '@/component-style/RWDWrapper';

import {
  projectModuleTree,
  informationModuleTree,
  attendanceModuleTree,
  expenseModuleTree,
  financeModuleTree,
  authorityModuleTree,
  sysConfigModuleTree,
  hrModuleTree,
} from '@/constant/dataConfig/authorityModule';

const PanelSystemRoleDetail = props => {
  const { role } = props;

  return (
    <TableWrapper title = { props.title }>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>角色編號<span>*</span></PSubtitle>
          <InputBasic
            disabled = { props.disabled || props.disableRoleCode }
            value = { role.roleCode }
            onChange = { ({ target }) => {
              props.updateRoleHandler('roleCode', target.value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>角色名稱<span>*</span></PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { role.roleName }
            onChange = { ({ target }) => {
              props.updateRoleHandler('roleName', target.value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>狀態</PSubtitle>
          <SelectNormal
            disabled = { props.disabled }
            value = { role.enable }
            options = {[
              { value: true, desc: '啟用' },
              { value: false, desc: '停用' },
            ]}
            changeHandler = { value => {
              props.updateRoleHandler('enable', value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>專案管理頁面權限</PSubtitle>
          <DivItemWrapper>
            <Tree
              disabled = { props.disabled }
              defaultExpandAll = { true }
              value = { role.projectRoutes }
              treeData = { projectModuleTree }
              changeHandler = { value => {
                props.updateRoleHandler('projectRoutes', [ ...value ]);
              }}
            />
          </DivItemWrapper>
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>資料管理頁面權限</PSubtitle>
          <DivItemWrapper>
            <Tree
              disabled = { props.disabled }
              defaultExpandAll = { true }
              value = { role.informationRoutes }
              treeData = { informationModuleTree }
              changeHandler = { value => {
                props.updateRoleHandler('informationRoutes', [ ...value ]);
              }}
            />
          </DivItemWrapper>
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>同仁差勤頁面權限</PSubtitle>
          <DivItemWrapper>
            <Tree
              disabled = { props.disabled }
              value = { role.attendanceRoutes }
              treeData = { attendanceModuleTree }
              changeHandler = { value => {
                props.updateRoleHandler('attendanceRoutes', [ ...value ]);
              }}
            />
          </DivItemWrapper>
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>費用報支頁面權限</PSubtitle>
          <DivItemWrapper>
            <Tree
              disabled = { props.disabled }
              value = { role.expenseRoutes }
              treeData = { expenseModuleTree }
              changeHandler = { value => {
                props.updateRoleHandler('expenseRoutes', [ ...value ]);
              }}
            />
          </DivItemWrapper>
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>財務資料頁面權限</PSubtitle>
          <DivItemWrapper>
            <Tree
              disabled = { props.disabled }
              value = { role.financeRoutes }
              treeData = { financeModuleTree }
              changeHandler = { value => {
                props.updateRoleHandler('financeRoutes', [ ...value ]);
              }}
            />
          </DivItemWrapper>
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>權限頁面權限</PSubtitle>
          <DivItemWrapper>
            <Tree
              disabled = { props.disabled }
              value = { role.authorityRoutes }
              treeData = { authorityModuleTree }
              changeHandler = { value => {
                props.updateRoleHandler('authorityRoutes', [ ...value ]);
              }}
            />
          </DivItemWrapper>
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>系統參數頁面權限</PSubtitle>
          <DivItemWrapper>
            <Tree
              disabled = { props.disabled }
              value = { role.sysConfigRoutes }
              treeData = { sysConfigModuleTree }
              changeHandler = { value => {
                props.updateRoleHandler('sysConfigRoutes', [ ...value ]);
              }}
            />
          </DivItemWrapper>
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>人事資料權限</PSubtitle>
          <DivItemWrapper>
            <Tree
              disabled = { props.disabled }
              value = { role.hrRoutes }
              treeData = { hrModuleTree }
              changeHandler = { value => {
                props.updateRoleHandler('hrRoutes', [ ...value ]);
              }}
            />
          </DivItemWrapper>
        </DivItemWrapper>
      </DivFlexRow>
      { props.children }
    </TableWrapper>
  );
};

export default PanelSystemRoleDetail;
