import React from 'react';
import styled from 'styled-components';

import { TdItem, TableRow } from '@/component-style/Table';
import { vendorInvoiceTypes } from '@/constant/options';
import { getOptionDesc } from '@/utils/textUtils';
import { formatTime } from '@/utils/timeUtils';

import { decimalCommaConverter } from '@/utils/textUtils';

const TrWrapper = styled(TableRow)`
  border-bottom: solid 2px #f2f2f2;
  box-sizing: border-box;
  min-width: 1080px;

  &:last-child {
    border-bottom: none;
  }
`;

const Td120 = styled(TdItem)`
  width: 120px;
`;

const Item = props => {
  const { voucher } = props;

  const type = getOptionDesc(vendorInvoiceTypes, voucher.voucherType);

  return (
    <TrWrapper>
      <Td120>{ voucher.expenseCode }</Td120>
      <Td120>{ voucher.taxID }</Td120>
      <Td120>{ type }</Td120>
      <Td120>{ voucher.voucherCode }</Td120>
      <Td120>
        { formatTime(voucher.date, 'YYYY/MM/DD') || '' }
      </Td120>
      <Td120>{ voucher.untaxedAmount == null ? "$ 0" : decimalCommaConverter(voucher.untaxedAmount) }</Td120>
      <Td120>{ voucher.tax  == null ? "$ 0" : decimalCommaConverter(voucher.untaxedAmount) }</Td120>
      <Td120>{ voucher.amount == null ? "$ 0" : decimalCommaConverter(voucher.amount) }</Td120>
      <Td120>{ voucher.amount == null ? "$ 0" : decimalCommaConverter(voucher.amount) }</Td120>
      <Td120>
        { 
          voucher.accounting && voucher.accounting.notForBusinessTax
            ? '否' : '是'
        }
      </Td120>
    </TrWrapper>
  )
};

export default Item;
