import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import MainContent from '@/component-style/MainContent';
import TableJouralistActivityRecords from '@/components/Table/TableJouralistActivityRecords';
import TableMediaReports from '@/components/Table/TableMediaReports';

import { mediaReportsList } from '../../mock/mockDataProjects';

const TableContainer = styled.div`
  margin-bottom: 40px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const JournalistRecords = props => {
  const [reports, setReports] = useState([]);

  useEffect(() => {
    setReports([...mediaReportsList]);
  }, []);

  return (
    <MainContent>
      <TableContainer>
        <TableJouralistActivityRecords/>
      </TableContainer>
      <TableContainer>
        <TableMediaReports
          reports = { reports }
        />
      </TableContainer>
    </MainContent>
  );
};

export default JournalistRecords;
