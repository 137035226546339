import React from 'react';
import styled from 'styled-components';

import LangSelector from './LangSelector';
import LoginForm from './LoginForm';
import Footer from '../UI/Footer';
import Logo from '../UI/Logo';

const DivLoginPanel = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  min-height: 600px;
  background-color: #f8f8f8;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DivLogoWrapper = styled.div`
  position: absolute;
  top: 30px;
  left: 30px;
  width: 80px;

  @media (min-width: 769px) {
    display: none;
  };
`;

const DivLangSelectorWrapper = styled.div`
  position: absolute;
  top: 30px;
  right: 30px;
`;

const DivFooterWrapper = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;

  @media (min-width: 769px) {
    display: none;
  };
`;

const LoginPanel = props => {

  return (
    <DivLoginPanel>
      <DivLogoWrapper>
        <Logo />
      </DivLogoWrapper>
      <DivLangSelectorWrapper>
        <LangSelector />
      </DivLangSelectorWrapper>
      <LoginForm
        errorMsg = { props.errorMsg }
        showError = { props.showError }
        setAccount = { props.setAccount }
        setPassword = { props.setPassword }
        login = { props.login }
        loginByAzureHandler = { props.loginByAzureHandler }
      />
      <DivFooterWrapper>
        <Footer /> 
      </DivFooterWrapper>
    </DivLoginPanel>
  );
};

export default LoginPanel;
