import React from 'react';

import TableWrapper from '@/components/UI/TableWrapper';
import CreditCardPayments from '@/components/UI/CreditCardPayments';
// import RadioGroup from '@/components/UI/Radio/RadioGroup';
import DateSelector from '@/components/UI/DateSelector';
import SelectNormal from '@/components/UI/Select/SelectNormal';
import SelectSearchable from '@/components/UI/Select/SelectSearchable';
import ExpenseVouchers from '@/components/ExpenseVouchers';
import { InputBasic, TextareaBasic } from '@/component-style/Input';
import { PSubtitle } from '@/component-style/TextElement';
import {
  DivFlexRow,
  DivItemWrapper,
  DivFullWidthWrapper,
  DivLongMultiSelector,
} from '@/component-style/RWDWrapper';

import { vendorInvoicePeriod } from '@/constant/options';
import { formatTime } from '@/utils/timeUtils';

const userPayments = [
  { value: 'creditCard', desc: '公司信用卡' },
  { value: 'advance', desc: '代墊款' },
  { value: 'loan', desc: '預借零用金' },
  { value: 'internal', desc: '內部轉帳' },
];

const vendorPayments = [
  { value: 'transfer', desc: '銀行轉帳' },
  { value: 'creditCard', desc: '公司信用卡' },
  { value: 'loan', desc: '預借零用金' },
  { value: 'cash', desc: '現金' },
  { value: 'internal', desc: '內部轉帳' },
];

function getProjectName(projectIDOptions, projectID) {
  for (var i=0; i < projectIDOptions.length; i++) {
    if (projectIDOptions[i].value === projectID) {
        return projectIDOptions[i].desc;
    }
}
}

const PanelExpenseFormDetail = props => {
  const { expenseForm } = props;

  // console.log("expenseForm.projectID:" + expenseForm.projectID);
  // console.log("props.projectIDOptions:" + JSON.stringify(props.projectIDOptions));
  const projectName = getProjectName(props.projectIDOptions, expenseForm.projectID);
  // console.log("projectName:" + projectName);

  const payments = expenseForm.object === 'user'
    ? userPayments
    : vendorPayments;

  const paymentChangeHandler = value => {
    props.updateExpenseFormHandler('payment', {
      method: value,
      settleDays: '',
      creditCards: [],
    });
  };

  const settleDaysChangeHandler = value => {
    props.updateExpenseFormHandler('payment', {
      ...expenseForm.payment,
      settleDays: value,
    });
  };

  const updateCreditCardsHandler = value => {
    props.updateExpenseFormHandler('payment', {
      ...expenseForm.payment,
      creditCards: [...value],
    });
  };

  const updateVouchersHandler = value => {
    props.updateExpenseFormHandler('vouchers', value);
  };

  return (
    <TableWrapper title = { props.title }>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>支憑流水號</PSubtitle>
          <InputBasic
            disabled = { true }
            value = { expenseForm.expenseCode || '' }
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>
            專案代號
            <span>*</span>
          </PSubtitle>
          <SelectSearchable
            disabled = { props.disabled || props.disableProjectID }
            value = { expenseForm.projectID }
            options = {[
              { value: '', desc: '請選擇專案代號' },
              ...props.projectIDOptions,
            ]}
            changeHandler = { value => {
              props.updateExpenseFormHandler('projectID', value);
            }}
          />
        </DivItemWrapper>
        {/* <DivItemWrapper>
          <PSubtitle>重要性</PSubtitle>
          <RadioGroup
            disabled = { props.disabled }
            value = { expenseForm.isImportant }
            options = {[
              { value: false, desc: '普件' },
              { value: true, desc: '急件' },
            ]}
            changeHandler = { value => {
              props.updateExpenseFormHandler('isImportant', value);
            }}
          />
        </DivItemWrapper> */}
      </DivFlexRow>
      <DivFlexRow>
        {/* <DivItemWrapper>
          <PSubtitle>支付對象</PSubtitle>
          <RadioGroup
            disabled = { true }
            value = { expenseForm.object }
            options = {[
              { value: 'user', desc: '同仁' },
              { value: 'vendor', desc: '廠商' },
            ]}
          />
        </DivItemWrapper> */}
        <DivItemWrapper>
          <PSubtitle>對象名稱</PSubtitle>
          <SelectSearchable
            disabled = { props.disabledObjectId }
            value = { expenseForm.objectID }
            options = {[
              { value: '', desc: '請選擇對象名稱' },
              ...props.objectIDOptions,
            ]}
            changeHandler = { value => {
              props.updateExpenseFormHandler('objectID', value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>
            付款方式
            <span>*</span>
          </PSubtitle>
          <SelectNormal
            disabled = { props.disabled }
            value = { expenseForm.payment.method }
            options = {[
              { value: '', desc: '請選擇付款方式' },
              ...payments,
            ]}
            changeHandler = { paymentChangeHandler }
          />
        </DivItemWrapper>
        {
          expenseForm.payment.method === 'transfer' &&
            <DivItemWrapper>
              <PSubtitle>結帳天數</PSubtitle>
              <SelectNormal
                disabled = { props.disabled }
                value = { expenseForm.payment.settleDays }
                options = {[ ...vendorInvoicePeriod ]}
                changeHandler = { settleDaysChangeHandler }
              />
            </DivItemWrapper>
        }
      </DivFlexRow>
      <DivFlexRow>
        {
          expenseForm.payment.method === 'creditCard' &&
            <DivItemWrapper>
              <PSubtitle>信用卡資訊</PSubtitle>
              <CreditCardPayments
                disabled = { props.disabled }
                creditCards = { expenseForm.payment.creditCards }
                updateCreditCardsHandler = { updateCreditCardsHandler }x
              />
            </DivItemWrapper>
        }
      </DivFlexRow>
      {
        // props.showFinanceItem &&
        <DivFlexRow>
          <DivItemWrapper>
            <PSubtitle>預計付款日</PSubtitle>
            <DateSelector
              disabled = { props.disabled || props.disablePaymentDate }
              value = { expenseForm.predictDate }
              changeHandler = { value => {
                props.updateExpenseFormHandler(
                  'predictDate',
                  value === null ? null : value,
                );
              }}
            />
          </DivItemWrapper>
          <DivItemWrapper>
            <PSubtitle>實際付款日</PSubtitle>
            <DateSelector
              disabled = { props.disabled || props.disablePaymentDate }
              value = { expenseForm.paymentDate }
              changeHandler = { value => {
                props.updateExpenseFormHandler(
                  'paymentDate',
                  formatTime(value, 'YYYY-MM-DD')
                );
              }}
            />
          </DivItemWrapper>
        </DivFlexRow>
      }
      {
        props.showFinanceItem &&
        <DivFlexRow>
          <DivItemWrapper>
            <PSubtitle>申請人</PSubtitle>
            <SelectNormal
              disabled = { props.disabled }
              value = { expenseForm.user }
              options = {[
                { value: '', desc: '請選擇使用者' },
                ...props.memberOptions,
              ]}
              changeHandler = { value => {
                props.updateExpenseFormHandler('user', value);
              }}
            />
          </DivItemWrapper>
        </DivFlexRow>
      }
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>支出單據</PSubtitle>
          <ExpenseVouchers
            object = { expenseForm.object }
            addable = { props.voucherAddable }
            vouchers = { expenseForm.vouchers }
            disabled = { props.disabled }
            taxTotal = { props.taxTotal }
            amountTotal = { props.amountTotal }
            disableAmount = { props.disableAmount }
            showFinanceItem = { props.showFinanceItem }
            addFileListHandler = { props.addFileListHandler }
            declareTypeOptions = { props.declareTypeOptions }
            unTaxedAmountTotal = { props.unTaxedAmountTotal }
            hideAttachmentButton = { props.hideAttachmentButton }
            removeFileListHandler = { props.removeFileListHandler }
            updateVouchersHandler = { updateVouchersHandler }
            openFilesPopupHandler = { props.openFilesPopupHandler }
            mapQuotationIDHasFile = { props.mapQuotationIDHasFile }
            projectName = { projectName }
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivFullWidthWrapper>
          <PSubtitle>備註說明</PSubtitle>
          <DivLongMultiSelector>
            <TextareaBasic
              disabled = { props.disabled }
              value = { expenseForm.note }
              onChange = { ({ target }) => {
                props.updateExpenseFormHandler('note', target.value);
              }}
            />
          </DivLongMultiSelector>
        </DivFullWidthWrapper>
      </DivFlexRow>
      {
        props.showSuggestion &&
          <DivFlexRow>
            <DivFullWidthWrapper>
              <PSubtitle>簽核意見</PSubtitle>
              <DivLongMultiSelector>
                <TextareaBasic
                  value = { props.suggestion }
                  onChange = { ({ target }) => {
                    props.updateSuggestionHandler(target.value);
                  }}
                />
              </DivLongMultiSelector>
            </DivFullWidthWrapper>
          </DivFlexRow>
      }
      { props.children }
    </TableWrapper>
  );
};

export default PanelExpenseFormDetail;
