import React from 'react';
import styled from 'styled-components';

import { TableHead, TableRow, ThItem } from '@/component-style/Table';

const THead = styled(TableHead)`
  min-width: 1080px;
`;

const Th100 = styled(ThItem)`
  width: 100px;
`;

const Th120 = styled(ThItem)`
  width: 120px;
`;

const Th160 = styled(ThItem)`
  width: 160px;
`;

const Th180 = styled(ThItem)`
  width: 180px;
`;

const Header = props => {
  
  return (
    <THead>
      <TableRow>
        <Th100>所屬公司</Th100>
        <Th100>主管名稱</Th100>
        <Th120>發票開立期間</Th120>
        <Th120>預估專案總收入</Th120>
        <Th120>發票稅前金額</Th120>
        <Th120>支出總額</Th120>
        <Th120>服務費總額</Th120>
        <Th120>專案其他收入</Th120>
        <Th120>公司毛利</Th120>
        <Th120>毛利率</Th120>
        <Th120>人力成本</Th120>
        <Th120>管銷費用</Th120>
        <Th120>公司淨利(利潤)</Th120>
      </TableRow>
    </THead>
  );
};

export default Header;
