import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

// import { postToGetUploadFiles } from '@/api/storageService';
// import { postToGetProjectQuotations } from '@/api/financeService';

import { searchConfigGenerator } from '@/utils/apiRequestUtils';
import { postToGetProjectTodos } from '@/api/projectTodoService';
import { postToGetProjectDetail } from '@/api/projectService';
import { postToGetProjectMembers } from '@/api/projectService';

import {
  FETCH_TODO_TYPE_OPTIONS,
  FETCH_PROJECT_ID_OPTIONS,
  FETCH_COMPANY_TYPE_OPTIONS,
} from '@/store/actions/srpOptionsAction';

import MainContent from '@/component-style/MainContent';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
// import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import PanelScheduleSearch from '@/components/Panel/PanelScheduleSearch';
import TableSchedules from '@/components/Table/TableSchedules';

import { getOptionDesc } from '@/utils/textUtils';
import { exportProjectTodos } from '@/utils/xlsx';
// import { diffTime } from '@/utils/timeUtils';

const PanelContainer = styled.div`
  margin-bottom: 40px;
`;

const ProjectSchedule = props => {
  const history = useHistory();
  const dispatch = useDispatch();
  const projectIDOptions = useSelector(state => state.srpOptions.projectIDOptions);
  const todoTypeOptions = useSelector(state => state.srpOptions.todoTypeOptions);
  const todoTagDictionary = useSelector(state => state.srpOptions.todoTagDictionary);
  const memberOptions = useSelector(state => state.srpOptions.memberOptions);
  const [projectMemberOptions, setProjectMemberOptions] = useState([]);

  const [todoTagOptions, setTodoTagOptions] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [scheduleCurrentPage, setScheduleCurrentPage] = useState(1);

  const [searchConfig, setSearchConfig] = useState({
    projectID: '',
    todoTypeID: '',
    todoTag: '',
    department: '',
    memberID: '',
    isDelayed: '',
    isFinished: '',
    estimatedDate: { start: null, end: null },
    finishedDate: { start: null, end: null },
    amount: 10,
  });

  const [selectAll, setSelectAll] = useState(false);
  // const [alertText, setAlertText] = useState('');
  // const [showAlertPopup, setShowAlertPopup] = useState(false);
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);

  const updateConfigHandler = (key, value) => {
    if (key === "projectID") {
      /*console.log("Change to projectID:" + value);
      console.log("memberOptions:" + JSON.stringify(memberOptions))*/
      getProjectMembers(value);
    }
    const updateObj = { ...searchConfig };
    updateObj[key] = value;
    setSearchConfig({ ...updateObj });
  };

  const todoTypeChangeHandler = todoTypeID => {
    setSearchConfig(prevState => ({
      ...prevState,
      todoTag: '',
      todoTypeID,
    }));
    setTodoTagOptions([...todoTagDictionary[todoTypeID]]);
  };

  const setCurrentPageHandler = page => {
    setScheduleCurrentPage(page);
    updateScheduleList(page);
  };

  const selectAllHandler = value => {
    setSelectAll(value);
    const updateSchedules = schedules
      .map(todo => ({ ...todo, isChecked: value }));
    setSchedules([...updateSchedules]);
  };

  const clearHandler = () => {
    setSearchConfig({
      projectID: '',
      todoTypeID: '',
      todoTag: '',
      department: '',
      memberID: '',
      isDelayed: '',
      isFinished: '',
      estimatedDate: { start: null, end: null },
      finishedDate: { start: null, end: null },
      amount: 10,
    });
  };

  const getProjectMembers = async(projectID) => {
    console.log("getProjectMembers projectId:" + projectID);
    try {
      const projectMembers = await postToGetProjectMembers(projectID);
      //console.log(projectMembers.data);

      const myMemberOptions = [];
      for (let objMemberOption of memberOptions) {
        if (objMemberOption.value !== undefined && projectMembers.data.includes(objMemberOption.value)) {
          myMemberOptions.push(JSON.parse(JSON.stringify(objMemberOption)));
        }
      }
      setProjectMemberOptions(myMemberOptions);
    } catch (err) {
      console.log(err);
    }
  };

  const goScheduleDetailHandler = async (projectID, todoID) => {
    setShowLoadingPopup(true);

    try {
      // const projectData = await postToGetProjectDetail(projectID);
      // const projectFinanceFiles = await postToGetUploadFiles('projectFinance', projectID);
      // const projectQuotations = await postToGetProjectQuotations({
      //   projectID, amount: 1, page: 1,
      // });

      // const unlocked = projectData.data.projectUnlock !== null
      //   && projectData.data.projectUnlock !== undefined
      //   && diffTime(projectData.data.projectUnlock, Date.now(), 'days') <= 1;

      // const notQuoted = projectQuotations.data.totalCount === undefined
      //   && diffTime(projectData.data.actionTimeEnd, Date.now(), 'days') < 8;

      // const noPO = projectFinanceFiles.data.length === 0
      //   && diffTime(projectData.data.actionTimeEnd, Date.now(), 'days') < 4;

      setShowLoadingPopup(false);

      // const isProjectLocked = !unlocked && (noPO || notQuoted);

      history.push(`/Project/ScheduleDetail/${todoID}`);
      // if (isProjectLocked) {
      //   const alertText = `
      //     ${ noPO ? '無 PO 資料，' : '' }
      //     ${ notQuoted ? '無報價資料，' : '' }
      //     專案已被鎖定，請專案總監或總經理解鎖。
      //   `;
      //   setAlertText(alertText);
      //   setShowAlertPopup(true);
      // } else {
      //   history.push(`/Project/ScheduleDetail/${todoID}`);
      // }
    } catch (err) {
      console.log(err);
      setShowLoadingPopup(false);
    }
  };

  const exportHandler = async () => {
    setShowLoadingPopup(true);
    const todoDatas = [];

    try {
      const { data } = await postToGetProjectTodos(
        searchConfigGenerator({
          ...searchConfig, page: 1, amount: totalCount
        })
      );

      const { todos } = data;
      for (let i = 0; i < todos.length; i += 1) {
        const todo = todos[i];
        todoDatas.push([
          getOptionDesc(todoTypeOptions, todo.todoTypeID),
          todo.todoTag,
          todo.estimatedDate === '0001-01-01' ? '' : todo.estimatedDate,
          todo.finishedDate === '0001-01-01' ? '' : todo.finishedDate,
          todo.finishedDate === '0001-01-01' ? '否' : '是',
          todo.delayDays !== undefined && todo.delayDays === 106751
            ? '' : todo.delayDays,
          getOptionDesc(memberOptions, todo.userID),
          todo.note === undefined ? '' : todo.note,
        ])
      }

    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
    exportProjectTodos(todoDatas);
  };

  const searchHandler = () => {
    setCurrentPageHandler(1);
  };

  const todoCheckedHandler = (index, value) => {
    const updateSchedules = [...schedules];
    updateSchedules[index].isChecked = value;

    setSchedules([...updateSchedules]);
  };

  const updateScheduleList = async (page = scheduleCurrentPage) => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetProjectTodos(
        searchConfigGenerator({ ...searchConfig, page }));
      const { todos, totalCount } = data;
      const projectTodos = todos
        .map(todo => ({ ...todo, isChecked: false }));
      
      for (let i = 0; i < projectTodos.length; i += 1) {
        const { projectID } = projectTodos[i];
        const { data } = await postToGetProjectDetail(projectID);
        const { projectCode, projectName, companyType } = data;
        projectTodos[i].projectCode = projectCode;
        projectTodos[i].projectName = projectName;
        projectTodos[i].companyType = companyType;
      }

      setSchedules([...projectTodos]);
      setTotalCount(totalCount);
    } catch (err) {
      console.log(err);
    }
    setShowLoadingPopup(false);
  };

  const initSchedules = useCallback(
    updateScheduleList, []);

  const initOptions = useCallback(
    () => {
      dispatch(FETCH_TODO_TYPE_OPTIONS());
      dispatch(FETCH_PROJECT_ID_OPTIONS());
      dispatch(FETCH_COMPANY_TYPE_OPTIONS());
    },[dispatch]);

  useEffect(() => {
    initOptions();
    initSchedules();
  }, [initOptions, initSchedules]);

  return (
    <MainContent>
      <PanelContainer>
        <PanelScheduleSearch
          config = { searchConfig }
          projectIDOptions = { projectIDOptions }
          todoTypeOptions = { todoTypeOptions }
          todoTagOptions = { todoTagOptions }
          memberOptions = { projectMemberOptions }
          clearHandler = { clearHandler }
          exportHandler = { exportHandler }
          searchHandler = { searchHandler }
          updateConfigHandler = { updateConfigHandler }
          todoTypeChangeHandler = { todoTypeChangeHandler }
        />
      </PanelContainer>
      <TableSchedules
        schedules = { schedules }
        showDetailButton = { true }
        selectAll = { selectAll }
        selectAllHandler = { selectAllHandler }
        totalCount = { totalCount }
        currentPage = { scheduleCurrentPage }
        checkHandler = { todoCheckedHandler }
        goScheduleDetailHandler = { goScheduleDetailHandler }
        pageChangedHandler = { value => {
          selectAllHandler(false);
          setCurrentPageHandler(value);
        }}
      />
      { showLoadingPopup && <PopupLoading/> }
      {/* {
        showAlertPopup &&
          <PopupExecuteResult
            text = { alertText }
            confirmHandler = { () => { setShowAlertPopup(false) } }
          />
      } */}
    </MainContent>
  );
};

export default ProjectSchedule;
