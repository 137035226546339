import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import {
  FETCH_MEDIA_ID_OPTIONS,
  FETCH_PROJECT_ID_OPTIONS,
  FETCH_JOURNALIST_ID_OPTIONS,
} from '@/store/actions/srpOptionsAction';

import {
  deleteMediaReport,
  putModifyMediaReport,
  postToGetMediaReportDetail,
} from '@/api/mediaService';

import MainContent from '@/component-style/MainContent';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import PopupExecuteConfirm from '@/components/UI/Popup/PopupExecuteConfirm';
import PanelMediaReportDetail from '@/components/Panel/PanelMediaReportDetail';
import { ButtonBasic, ButtonCancel } from '@/component-style/Button';
import { DivFlexRow } from '@/component-style/RWDWrapper';

import { integerConverter } from '@/utils/textUtils';
import { mediaReportConfig } from '@/constant/dataConfig/projectModule';

const DivButtonsWrapper = styled(DivFlexRow)`
  padding: 0;
  padding-bottom: 30px;
  justify-content: flex-end;
  max-width: 1535px;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const Button = styled(ButtonBasic)`
  width: 120px;
  margin-right: 10px;
  margin-top: 10px;

  &:last-child {
    margin-right: 0;
  }
`;

const ButtonExit = styled(ButtonCancel)`
  width: 120px;
  margin-right: 10px;
  margin-top: 10px;

  &:last-child {
    margin-right: 0;
  }
`;

const MediaReportDetail = props => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const mediaIDList = useSelector(state => state.srpOptions.mediaIDOptions);
  const projectRoutes = useSelector(state => state.login.projectRoutes);
  const projectIDList = useSelector(state => state.srpOptions.projectIDOptions);
  const journalistIDList = useSelector(state => state.srpOptions.journalistIDOptions);
  const showSaveFailPopup = useSelector(state => state.apiFail.showSaveFailPopup);
  const apiFailPopupTitle = useSelector(state => state.apiFail.title);
  const apiFailPopupMessage = useSelector(state => state.apiFail.message);

  const [mediaReport, setMediaReport] = useState({ ...mediaReportConfig });

  const [isEditing, setIsEditing] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [showSaveSuccessPopup, setShowSaveSuccessPopup] = useState(false);
  const [showDeleteSuccessPopup, setShowDeleteSuccessPopup] = useState(false);

  const userEditable = projectRoutes.indexOf('MediaReportDetail-Edit') !== -1;
  const userDeletable = projectRoutes.indexOf('MediaReportDetail-Delete') !== -1;

  const journalistIDOptions = journalistIDList
    .filter(({ mediaID }) => mediaID === mediaReport.mediaID);

  const updateMediaReportHandler = (key, value) => {
    const updateObj = { ...mediaReport };
    updateObj[key] = value;
    setMediaReport({ ...updateObj });
  };

  const mediaChangeHandler = value => {
    setMediaReport(prevState => ({
      ...prevState,
      mediaID: value,
      journalistID: '',
    }));
  };

  const closeSuccessPopupHandler = () => {
    setIsEditing(false);
    setShowSaveSuccessPopup(false);
  };

  const deleteMediaReportHandler = async () => {
    setShowDeletePopup(false);
    setShowLoadingPopup(true);

    try {
      await deleteMediaReport(id);
      setShowLoadingPopup(false);
      setShowDeleteSuccessPopup(true);
    } catch(err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '刪除失敗', err }));
      console.log(err);
    }
  };

  const updateMediaReportData = async () => {
    try {
      await putModifyMediaReport({
        ...mediaReport,
        benefit: integerConverter(mediaReport.benefit),
      });
      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '更新失敗', err }));
      console.log(err);
    }
  };

  const fetchMediaReport = async () => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetMediaReportDetail(id);
      setMediaReport(prevState => ({
        ...prevState,
        ...data,
        publishDate: data.publishDate === '0001-01-01' ? null : data.publishDate,
      }));
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const initData = useCallback(
    fetchMediaReport, []);

  const initOptions = useCallback(() => {
    dispatch(FETCH_MEDIA_ID_OPTIONS());
    dispatch(FETCH_PROJECT_ID_OPTIONS());
    dispatch(FETCH_JOURNALIST_ID_OPTIONS([]));
  }, [dispatch]);

  useEffect(() => {
    initOptions();
    initData();
  }, [initData, initOptions]);

  return (
    <MainContent>
      <PanelMediaReportDetail
        title = "活動創意明細"
        disabled = { !isEditing }
        mediaReport = { mediaReport }
        projectIDOptions = { projectIDList }
        mediaIDOptions = { mediaIDList }
        journalistIDOptions = { journalistIDOptions }
        mediaChangeHandler = { mediaChangeHandler }
        updateMediaReportHandler = { updateMediaReportHandler }
      >
        <DivFlexRow>
          <DivButtonsWrapper>
            {
              isEditing
                ? <Button
                    onClick = { updateMediaReportData }
                  >儲存</Button>
                : <Button
                    disabled = { !userEditable }
                    onClick = { () => { setIsEditing(true) } }
                  >編輯</Button>
            }
            <ButtonExit
              disabled = { !userDeletable }
              onClick = { () => { setShowDeletePopup(true) } }
            >刪除</ButtonExit>
            <ButtonExit
              onClick = { () => { history.goBack() } }
            >離開</ButtonExit>
          </DivButtonsWrapper>
        </DivFlexRow>
      </PanelMediaReportDetail>
      { showLoadingPopup && <PopupLoading/> }
      {
        showDeletePopup &&
          <PopupExecuteConfirm
            title = "刪除媒體披露"
            text = "確定刪除此媒體披露"
            confirmHandler = { deleteMediaReportHandler }
            cancelHandler = { () => { setShowDeletePopup(false) }}
          />
      }
      {
        showSaveSuccessPopup &&
          <PopupExecuteResult
            title = "更新成功"
            confirmHandler = { closeSuccessPopupHandler }
          />
      }
      {
        showDeleteSuccessPopup &&
          <PopupExecuteResult
            title = "刪除成功"
            confirmHandler = { () => {
              history.goBack();
            }}
          />
      }
      {
        showSaveFailPopup &&
          <PopupExecuteResult
            title = { apiFailPopupTitle }
            text = { apiFailPopupMessage }
            confirmHandler = { () => {
              dispatch(CLOSE_SAVE_FAIL_POPUP());
            }}
          />
      }
    </MainContent>
  );
};

export default MediaReportDetail;
