import React, { useState, useEffect, useCallback  } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { cloneDeep } from 'lodash';

import {
  getLeave,
} from '@/api/financeService';

import MyInputNumber from '@/components/UI/MyInputNumber';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import MainContent from '@/component-style/MainContent';
import PanelWrapper from '@/components/UI/PanelWrapper';
import TableWrapper from '@/components/UI/TableWrapper';
import DateSelector from '@/components/UI/DateSelector';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import PopupHolidayDetail from '@/components/UI/Popup/PopupHolidayDetail';
import SelectMultiple from '@/components/UI/Select/SelectMultiple';
import SearchButtonSet from '@/components/UI/SearchButtonSet';
import { PSubtitle } from '@/component-style/TextElement';
import { ButtonBasic, ButtonCancel } from '@/component-style/Button';
import {
  DivFlexRow,
  DivItemWrapper,
  DivFullWidthWrapper,
  DivLongMultiSelector,
} from '@/component-style/RWDWrapper';

// import { integerConverter } from '@/utils/textUtils';
import {
  postToGetDepartment,
} from '@/api/sysConfigService';

import { laborHealthInsuranceParamsConfig } from '@/constant/dataConfig/sysConfigModule.js';

import TableLeaveSetting  from '@/components/Table/TableLeaveSetting';

import PopupEditLeaveType from '@/components/UI/Popup/PopupEditLeaveType';

const DivPanelContainer = styled.div`
  margin-bottom: 40px;
`;

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding: 0;
  justify-content: space-between;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const DivEditButtonsWrapper = styled(DivButtonsWrapper)`
  justify-content: flex-end;
  padding-bottom: 30px;
`;

const ButtonAdd = styled(ButtonBasic)`
  margin-right: 10px;
  width: 120px;

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`;

const ButtonDiscard = styled(ButtonCancel)`
  width: 120px;

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`;

const LeaveManagement = () => {
  const dispatch = useDispatch();
  // const sysConfigRoutes = useSelector(state => state.login.sysConfigRoutes);
  const showSaveFailPopup = useSelector(state => state.apiFail.showSaveFailPopup);
  const apiFailPopupTitle = useSelector(state => state.apiFail.title);
  const apiFailPopupMessage = useSelector(state => state.apiFail.message);

  const [laborHealthInsuranceParams, setLaborHealthInsuranceParams] = useState(cloneDeep(laborHealthInsuranceParamsConfig));
  const [editLeaveId, setEditLeaveId] = useState("");
  // const [newDayDetail, setNewDayDetail] = useState({ ...newDayConfig });

  const [leave, setLeave] = useState();
  const [showPopupEditLeaveType, setShowPopupEditLeaveType] = useState(false);

  const [isEditing, setIsEditing] = useState(false);
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [showSaveSuccessPopup, setShowSaveSuccessPopup] = useState(false);

  const [departmentOptions, setDepartmentOptions] = useState([]);

  // const userAddable = sysConfigRoutes.indexOf('LeaveManagement-Add') !== -1;
  // const userEditable = sysConfigRoutes.indexOf('LeaveManagement-Edit') !== -1;

  // const uneditable = laborHealthInsuranceParams.holidays.length === 0
  //   && laborHealthInsuranceParams.restdays.length === 0
  //   && laborHealthInsuranceParams.workdays.length === 0;

  const reloadHandler = () => {
    setShowPopupEditLeaveType(false);
    fetchLeaveSetting();
  }

  // const updateLaborHealthInsuranceParamsHandler = (key, value) => {
  const updateLeaveParamsHandler = (key, value) => {
    console.log("leaveManagement.js updateLeaveParamsHandler() key:" + JSON.stringify(key));
    const updateObj = { ...laborHealthInsuranceParams };
    updateObj[key] = value;
    setLaborHealthInsuranceParams({ ...updateObj });
  };

  const clearHandler = () => {
    setIsEditing(false);
    // setNewDayDetail({ ...newDayConfig });
    setLaborHealthInsuranceParams(cloneDeep(laborHealthInsuranceParamsConfig))
  };

  const cancelHandler = () => {
    // setIsEditing(false);
    setShowPopupEditLeaveType(false);
  };

  const apiFailConfirmHandler = () => {
    dispatch(CLOSE_SAVE_FAIL_POPUP());
  };

  const closeSaveSuccessPopupHandler = () => {
    setShowSaveSuccessPopup(false);
    setIsEditing(false);
  };

  const addLeaveTypeHandler  = () => {
    setEditLeaveId("");
    setShowPopupEditLeaveType(true);
  };

  const fetchLeaveSetting = async () => {
    setShowLoadingPopup(true);

    try {
      const { data } = await getLeave({
        // isCompensated: false,
      });
      // console.log(`leaveManagement.js fetchLeaveSetting() JSON.stringify(data)=${JSON.stringify(data)}`);
      const tempLeaves = data.leaves.map(function(leave) {
          if (leave["departmentId"] === null || leave["departmentId"] === undefined) {
            leave["departmentId"] = "all";
            // leave["departmentId"] = {
            //   value: "all",
            //   specified: false,
            // };
          }
          // console.log(`leaveManagement.js fetchLeaveSetting() JSON.stringify(leave)=${JSON.stringify(leave)}`);
          return leave;
      });
      console.log(`leaveManagement.js fetchLeaveSetting() JSON.stringify(tempLeaves)=${JSON.stringify(tempLeaves)}`);
      setLeave(tempLeaves);

      const departments = await postToGetDepartment({
        "enable": true,
        "amount": 100,
        "page": 1
      });
      // console.log(`leaveManagement.js fetchLeaveSetting() JSON.stringify(departments)=${JSON.stringify(departments)}`);
      const tempDepartmentOptions = departments.data.departments.map((element) => {
        return {"desc": `${element.name}`, "value": element.departmentID};
      });
      // console.log(`leaveManagement.js fetchLeaveSetting() JSON.stringify(tempDepartmentOptions)=${JSON.stringify(tempDepartmentOptions)}`);
      setDepartmentOptions(tempDepartmentOptions);
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const initConfigData = useCallback(
    fetchLeaveSetting, [])

  useEffect(() => {
    initConfigData();
  }, [initConfigData])


  return (
    <MainContent>
      <TableLeaveSetting
        leaveSetting = { leave }
        editDetailHandler = { async (id) => {
          console.log("leaveManagement.js editDetailHandler id:" + id);
          setEditLeaveId(id);
          setShowPopupEditLeaveType(true);
        }}
        buttonAdd = {
          <ButtonAdd
            // disabled = { !userAddable }
            onClick = { addLeaveTypeHandler }
          >新增</ButtonAdd>
        }
      />
      { showLoadingPopup && <PopupLoading/> }
      {
        showSaveSuccessPopup &&
          <PopupExecuteResult
            title = "儲存成功"
            confirmHandler = { closeSaveSuccessPopupHandler }
          />
      }
      {
        showSaveFailPopup &&
          <PopupExecuteResult
            title = { apiFailPopupTitle }
            text = { apiFailPopupMessage }
            confirmHandler = { apiFailConfirmHandler }
          />
      }
      { 
        showPopupEditLeaveType &&
          <PopupEditLeaveType
            updateLeaveParamsHandler = { updateLeaveParamsHandler }
            leaves = { leave }
            departmentOptions = { departmentOptions }
            editLeaveId = { editLeaveId }
            cancelHandler = { cancelHandler }
            reloadHandler = { reloadHandler }
          />
      }
    </MainContent>
  );
};

export default LeaveManagement;
