import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { FETCH_COMPANY_TYPE_OPTIONS } from '@/store/actions/srpOptionsAction';

import PanelAllLeaveSearch from '@/components/Panel/HR/PanelAllLeaveSearch';

import TableAllLeave from '@/components/Table/HR/TableAllLeave';
import PopupLoading from '@/components/UI/Popup/PopupLoading';

import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';

import { 
  getLeaveApplications,
  deleteLeaveApplicationById,
} from '@/api/financeService';

import PopupExecuteConfirm from '@/components/UI/Popup/PopupExecuteConfirm';

import { leaveApprovalOptions } from '@/constant/options';

import {
  getOptionDesc,
} from '@/utils/textUtils';

import moment from 'moment';

import { exportAllLeaveXLS } from '@/utils/xlsx';


const DivPanelContainer = styled.div`
  margin-bottom: 40px;
`;

const AllLeaveSearch = () => {
  const dispatch = useDispatch();
  const companyTypeOptions = useSelector(state => state.srpOptions.companyTypeOptions);
  // console.log("RevenueByCompanySearch companyTypeOptions:" + JSON.stringify(companyTypeOptions));


  const [allLeaves, setAllLeaves] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [showLoadingPopup, setShowLoadingPopup] = useState(false);

  const [alertText, setAlertText] = useState('');
  const [showAlertPopup, setShowAlertPopup] = useState(false);

  const [searchConfig, setSearchConfig] = useState({
    companyType: '',
    leaveFrom: moment().startOf('month').format('YYYY-MM-DD'),
    leaveTo: moment().add(1, 'months').endOf('month').format('YYYY-MM-DD'),
  });

  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [toDeleteApplicationId, setToDeleteApplicationId] = useState(false);

  const [users, setUsers] = useState([]);

  const openAlertPopup = text => {
    setAlertText(text);
    setShowAlertPopup(true);
  };

  const exportLeaveApplicationXLSHandler = async() => {
    console.log(`allLeaveSearch.js exportLeaveApplicationXLSHandler()`);
    setShowLoadingPopup(true);
    try {
      var page = 1;
      var totalPages = 999;
      var leaveApplications = [];
      leaveApplications.push([
        "姓名",
        "假別",
        "申請時間",
        "休假時數",
        "開始時間",
        "結束時間",
        "狀態",
      ]);
      if (searchConfig.companyType !== "") {
        while (page < totalPages) {
          const { data } = await getLeaveApplications( {
            ...searchConfig,
            page: page,
            amount: 100,
          });
          for (let indexApplication = 0; indexApplication < data.applications.length; indexApplication += 1) {
            leaveApplications.push([
              data.applications[indexApplication].username,
              data.applications[indexApplication].leaveName,
              moment.parseZone(data.applications[indexApplication].applyTime).format('YYYY/MM/DD'),
              Math.round(data.applications[indexApplication].durationInSeconds / 3600 * 100) / 100,
              moment.parseZone(data.applications[indexApplication].leaveFrom).format('YYYY/MM/DD HH:mm:ss'),
              moment.parseZone(data.applications[indexApplication].leaveTo).format('YYYY/MM/DD HH:mm:ss'),
              getOptionDesc(leaveApprovalOptions, data.applications[indexApplication].status),
            ]);
          }
          page++;
          totalPages = data.totalPages;
        }
        
        console.log(`allLeaveSearch.js exportLeaveApplicationXLSHandler() JSON.stringify(leaveApplications)=${JSON.stringify(leaveApplications)}`);
        exportAllLeaveXLS(leaveApplications);
      } else {
      }
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const deleteLeaveApplicationHandler = (leaveApplicataionId) => {
    console.log(`allLeaveSearch.js deleteLeaveApplicationHandler() leaveApplicataionId=${leaveApplicataionId}`);
    setToDeleteApplicationId(leaveApplicataionId);
    setShowConfirmPopup(true);
  };

  const deleteLeaveApplication = async() => {
    try {
      console.log(`allLeaveSearch.js deleteLeaveApplicationHandler() toDeleteApplicationId=${toDeleteApplicationId}`);
      if (toDeleteApplicationId !== "") {
        console.log(`allLeaveSearch.js deleteLeaveApplication() toDeleteApplicationId=${toDeleteApplicationId}`);
        const data = await deleteLeaveApplicationById(toDeleteApplicationId);
        console.log(`allLeaveSearch.js deleteLeaveApplication() data=${data}`);
      } 
    } catch (err) { console.log(err) }
    setCurrentPage(currentPage);
    fetchAllLeaves(currentPage);
  };

  const updateSearchConfigHandler = (key, value) => {
    console.log(`allLeaveSearch.js updateSearchConfigHandler() key=${key}&value=${value}`);
    const updateObj = { ...searchConfig };
    if (key === "leaveFrom") {
      updateObj[key] = moment(value).startOf('month').format('YYYY-MM-DD');
    } else if (key === "leaveTo") {
      updateObj[key] = moment(value).endOf('month').format('YYYY-MM-DD');
    } else {
      updateObj[key] = value;
    }
    console.log(`allLeaveSearch.js updateSearchConfigHandler() JSON.stringify(updateObj)=${JSON.stringify(updateObj)}`);
    setSearchConfig({ ...updateObj });
  };

  // const clearHandler = () => {
  //   setVouchers([]);
  //   setTotalCount(0);
  //   setConfig(cloneDeep(searchConfig));
  // };

  const fetchAllLeaves = async (page) => {
    console.log(`allLeaveSearch.js fetchAllLeaves()`);
    setShowLoadingPopup(true);

    try {
      if (searchConfig.companyType !== "") {
        const { data } = await getLeaveApplications( {
          ...searchConfig,
          page: page,
          amount: 10,
        });
        setAllLeaves(data.applications);
        console.log(`allLeaveSearch.js fetchAllLeaves() JSON.stringify(data.applications)=${JSON.stringify(data.applications)}`);
        
        setTotalCount(data.totalCount || 0);
      } else {
        setAllLeaves(undefined);
        setTotalCount(0);
      }
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  }

  const initCompensatedLeave = useCallback(
    fetchAllLeaves, []);

  useEffect(() => {
    initCompensatedLeave();
  }, [initCompensatedLeave]);

  const searchHandler = async () => {
    setCurrentPageHandler(1);
  };

  const setCurrentPageHandler = page => {
    setCurrentPage(page);
    fetchAllLeaves(page);
  };

  const initOptions = useCallback(() => {
    dispatch(FETCH_COMPANY_TYPE_OPTIONS());
  }, [dispatch]);

  useEffect(() => {
    initOptions();
  }, [initOptions]);

  return(
    <>
      <DivPanelContainer>
        <PanelAllLeaveSearch
          users = { users }
          searchConfig = { searchConfig }
          companyTypeOptions = { companyTypeOptions }
          // clearHandler = { clearHandler }
          exportLeaveApplicationXLSHandler = { exportLeaveApplicationXLSHandler }
          searchHandler = { searchHandler }
          updateSearchConfigHandler = { updateSearchConfigHandler }
        />
      </DivPanelContainer>
      <TableAllLeave
        allLeaves = { allLeaves }
        totalCount = { totalCount }
        currentPage = { currentPage }
        pageChangedHandler = { setCurrentPageHandler }
        deleteLeaveApplicationHandler = { deleteLeaveApplicationHandler }
      />
      { showLoadingPopup && <PopupLoading/> }
      {
        showAlertPopup &&
          <PopupExecuteResult
            title = "請選擇欄位"
            text = { alertText }
            confirmHandler = { () => { setShowAlertPopup(false) } }
          />
      }
      {
        showConfirmPopup &&
          <PopupExecuteConfirm
            title = "刪除休假"
            text = { `確定刪除此休假` }
            confirmHandler = { () =>  { 
              setShowConfirmPopup(false);
              deleteLeaveApplication();
            }}
            cancelHandler = { () => {
              setShowConfirmPopup(false);
            }}
          />
      }
    </>
  );
};

export default AllLeaveSearch;
