import axiosConfig from './axiosConfig';
import { getDefaultPort } from '@/utils/apiRequestUtils';

const { baseURL } = axiosConfig.defaults;

const port = process.env.REACT_APP_SERVICE_PORT_PROJECT !== undefined
  ? process.env.REACT_APP_SERVICE_PORT_PROJECT
  : getDefaultPort(baseURL);

const projectModuleUrl = process.env.NODE_ENV === 'development'
  ? `${baseURL}:${port}/api`
  : `${baseURL}/project/api`;

export const postToGetProjectCodes = filterConfig => axiosConfig
  .post(`${projectModuleUrl}/project/projectCodes`, filterConfig);

export const postToGetProjectList = searchConfig => axiosConfig
  .post(`${projectModuleUrl}/project`, searchConfig);

export const putToPostNewProject = project => axiosConfig
  .put(`${projectModuleUrl}/project`, project);

export const putProjectDetail = project => axiosConfig
  .put(`${projectModuleUrl}/project/detail`, { project });

export const postToGetProjectTypes = config => axiosConfig
  .post(`${projectModuleUrl}/project/type`, config);

export const putNewProjectType = config => axiosConfig
  .put(`${projectModuleUrl}/project/type`, config);

export const patchProjectType = config => axiosConfig
  .patch(`${projectModuleUrl}/project/type`, config);

export const deleteProjectType = ids => axiosConfig
  .delete(`${projectModuleUrl}/project/type`, { data: [...ids] });

export const postToGetProjectDetail = projectID => axiosConfig
  .post(`${projectModuleUrl}/project/detail`, { projectID });

export const postToGetProjectDetailWithArrayOfProjects = projectIDs => axiosConfig
  .post(`${projectModuleUrl}/v2/project/detail`, { projectID:[...projectIDs] });

export const putProjectSetStatus = config => axiosConfig
  .put(`${projectModuleUrl}/project/set-status`, config);

export const deleteProject = projectID => axiosConfig
  .delete(`${projectModuleUrl}/project`, { data: { projectID } });

export const postToGetVendorEvaluations = projectID => axiosConfig
  .post(`${projectModuleUrl}/project/evaluation/vendor`, { projectID });

export const patchModifyVendorEvaluations = ({ projectID, evaluations }) => axiosConfig
  .patch(`${projectModuleUrl}/project/evaluation/vendor`, { projectID, evaluations });

export const postToGetVendorProjectRecords = config => axiosConfig
  .post(`${projectModuleUrl}/project/projectRecord/vendor`, config);

export const postToGetEvaluationRecords = config => axiosConfig
  .post(`${projectModuleUrl}/project/evaluation/vendorRecords`, config);

export const postToGetMemberEvaluations = projectID => axiosConfig
  .post(`${projectModuleUrl}/project/evaluation/member`, { projectID });

export const patchModifyMemberEvaluations = ({ projectID, evaluations }) => axiosConfig
  .patch(`${projectModuleUrl}/project/evaluation/member`, { projectID, evaluations });

export const postToGetProjectMembers = projectID => axiosConfig
  .post(`${projectModuleUrl}/project/members`, { projectID });

export const postToGetDashboardProjectList = searchConfig => axiosConfig
  .post(`${projectModuleUrl}/dashboard`, searchConfig);

  export const getCompanyCustomers = data => axiosConfig
  .get(`${projectModuleUrl}/project/companyCustomers`, {
    params: { 
      companyTypeID: data.companyTypeID,
    }
});