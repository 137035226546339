import axiosConfig from './axiosConfig';
import { getDefaultPort } from '@/utils/apiRequestUtils';

const { baseURL } = axiosConfig.defaults;

const port = process.env.REACT_APP_SERVICE_PORT_AUTH !== undefined
  ? process.env.REACT_APP_SERVICE_PORT_AUTH
  : getDefaultPort(baseURL);

const loginModuleUrl = process.env.NODE_ENV === 'development'
  ? `${baseURL}:${port}/api`
  : `${baseURL}/auth/api`;

export const postLogin = ({ account, password, reCaptchaToken }) => axiosConfig
  .post(
    `${loginModuleUrl}/login`,
    {
      account,
      password,
      recaptcha: reCaptchaToken,
      recaptchaVersion: 'v3',
    },
  );

export const resendPassword = ({ account, reCaptchaToken}) => axiosConfig
  .post(
    `${loginModuleUrl}/resetPassword`,
    {
      account,
      recaptcha: reCaptchaToken,
      recaptchaVersion: 'v3',
    },
  );

export const postToGetUsers = config => axiosConfig
  .post(`${loginModuleUrl}/users`, config);

export const putNewUser = config => axiosConfig
  .put(`${loginModuleUrl}/users`, config);

export const deleteUser = userID => axiosConfig
  .delete(`${loginModuleUrl}/users`, { data: { userID } });

export const postToGetUserInfo = (userID = null) => axiosConfig
  .post(`${loginModuleUrl}/user`, { userID });

export const getUserHistoryById = (id, strSort) => axiosConfig
  .get(`${loginModuleUrl}/users/${id}/history`, { params: { 
    sort: strSort,
  }}
);

export const putModifyUser = config => axiosConfig
  .put(`${loginModuleUrl}/user`, config);

export const getUserIDs = () => axiosConfig
  .get(`${loginModuleUrl}/user/userIDs`);

export const postToModifyPassword = config => axiosConfig
  .post(`${loginModuleUrl}/password`, config);

export const postToSyncAzureAccount = config => axiosConfig
  .post(`${loginModuleUrl}/syncAzureAccount`, config);

export const getAzureLoginUrl = () => `${loginModuleUrl}/loginByAzure`;

export const getSyncAzureAccountUrl = () => `${loginModuleUrl}/syncAzureAccount`;

export const postToLoginByAzure = config => axiosConfig
  .post(`${loginModuleUrl}/loginByAzure`, config);

export const postToGetPasswordParams = () => axiosConfig
  .post(`${loginModuleUrl}/sysConfig/passwordParams`);

export const putModifyPasswordParams = config => axiosConfig
  .post(`${loginModuleUrl}/sysConfig/passwordParams`, config);

export const postToGetSystemRoles = config => axiosConfig
  .post(`${loginModuleUrl}/systemRoles`, config);

export const putNewSystemRole = config => axiosConfig
  .put(`${loginModuleUrl}/systemRoles`, config);

export const deleteSystemRole = roleID => axiosConfig
  .delete(`${loginModuleUrl}/systemRoles`, { data: { roleID } });

export const postToGetSystemRoleDetail = roleID => axiosConfig
  .post(`${loginModuleUrl}/systemRole`, { roleID });

export const putModifySystemRoleDetail = config => axiosConfig
  .put(`${loginModuleUrl}/systemRole`, config);

export const patchUpdateUserHistory = (userId, historyId, data) => axiosConfig
  .patch(`${loginModuleUrl}/users/${userId}/history/${historyId}`, data);
