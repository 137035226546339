import React from 'react';
import { Route } from 'react-router-dom';

import UserManagement from '@/containers/authorityModule/userManagement';
import UserCreation from '@/containers/authorityModule/userCreation';
import UserDetail from '@/containers/authorityModule/userDetail';
import UserDetailSalary from '@/containers/authorityModule/userDetailSalary';
import RoleManagement from '@/containers/authorityModule/roleManagement';
import RoleCreation from '@/containers/authorityModule/roleCreation';
import RoleDetail from '@/containers/authorityModule/roleDetail';

const authorityModuleRoutes = [
  {
    key: 'UserManagement',
    path: '/Authority/UserManagement',
    component: UserManagement,
  },
  {
    key: 'UserCreation',
    path: '/Authority/UserCreation',
    component: UserCreation,
  },
  {
    key: 'UserDetail',
    path: '/Authority/UserDetail/:id',
    component: UserDetail,
  },
  {
    key: 'UserDetailSalary',
    path: '/Authority/UserDetailSalary/:id',
    component: UserDetailSalary,
  },
  {
    key: 'RoleManagement',
    path: '/Authority/RoleManagement',
    component: RoleManagement,
  },
  {
    key: 'RoleCreation',
    path: '/Authority/RoleCreation',
    component: RoleCreation,
  },
  {
    key: 'RoleDetail',
    path: '/Authority/RoleDetail/:id',
    component: RoleDetail,
  },
];

const RoutesAuthorityModule = validRoutes => authorityModuleRoutes
  .filter(({ key }) => validRoutes.indexOf(key) !== -1)
  .map(({ path, component }) =>
    <Route
      key = { path }
      path = { path }
      component = { component }
    />
  );

export default RoutesAuthorityModule;
