import XLSX from 'xlsx';
import { formatTime } from '@/utils/timeUtils';

function timestamp () {
  return formatTime(Date.now(), 'YYYYMMDDhhmmss');
}

export const exportProjectTodos = projectTodos => {
  const ws = XLSX.utils.aoa_to_sheet([
    [
      '工作項目', '工作細項', '預計完成日', '實際完成日',
      '完成', '延遲天數', '負責成員', '工作備註'
    ],
    ...projectTodos,
  ]);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  XLSX.writeFile(wb, `ExportProjectTodos-${timestamp()}.xlsx`);
};

export const exportCustomerData = customerData => {
  const ws = XLSX.utils.aoa_to_sheet([
    [
      '客戶代號', '客戶名稱', '統一編號', '負責人',
      '聯絡地址', '發票地址', '網址', '合作關係',
      '合作時間(起)', '合作時間(迄)', '客戶來源',
      '備註說明', '是否比稿',
    ],
    ...customerData,
  ]);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  XLSX.writeFile(wb, `ExportCustomers-${timestamp()}.xlsx`);
};

export const exportMediaData = mediaData => {
  const ws = XLSX.utils.aoa_to_sheet([
    [
      '媒體名稱', '媒體名稱(英)', '集團名稱', '集團名稱(英)',
      '頻道名稱', '頻道名稱(英)', '類別', '駐台媒體',
      '編譯中心', '停刊/停播', '發行量', '權重',
      '連絡地址', '連絡地址(英)', 'Web網址', '集團背景',
      '集團背景(英)', '截稿時間與操作需求', '截稿時間與操作需求(英)',
    ],
    ...mediaData,
  ]);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  XLSX.writeFile(wb, `ExportMedias-${timestamp()}.xlsx`);
};

export const exportVendorData = vendorData => {
  const ws = XLSX.utils.aoa_to_sheet([
    [
      '廠商名稱', '統一編號', '負責人', '個人勞務',
      '代收代付', '外國廠商', '稅外加', '不需扣補充保費',
      '身分', '國籍', '特殊資格', '地址',
      '發票地址', '網址', '服務項目', '廠商資格',
      '發票收據種類', '付款方式', '備註說明', '禁止使用',
    ],
    ...vendorData,
  ]);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  XLSX.writeFile(wb, `ExportVendors-${timestamp()}.xlsx`);
};

export const exportMediaReportData = mediaReportData => {
  const ws = XLSX.utils.aoa_to_sheet([
    [
      '專案代號', '專案名稱', '新聞來源', '媒體種類',
      '媒體', '報導日期', '記者姓名', '主標',
      '效益',
    ],
    ...mediaReportData,
  ]);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  XLSX.writeFile(wb, `ExportMediaReports-${timestamp()}.xlsx`);
};

export const exportJournalistData = journalistData => {
  const ws = XLSX.utils.aoa_to_sheet([
    [
      '媒體名稱', '姓名', '姓名 (英)', '職稱',
      '職稱 (英)', '在職', '商務電話', '商務傳真',
      '行動電話 (公)', '行動電話 (私)', '電子郵件 (公)', '電子郵件 (私)',
      '所屬版面、版別', '商務地址', '住家地址', '生日',
      'Facebook', 'LINE', 'Skype', '其它通訊軟體',
      '資歷與特性', '備註',
    ],
    ...journalistData,
  ]);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  XLSX.writeFile(wb, `ExportJournalists-${timestamp()}.xlsx`);
};

export const exportInvitationFile = invitationData => {
  const ws = XLSX.utils.aoa_to_sheet([
    [
      '媒體類別', '媒體名稱', '媒體名稱 (英)', '姓名',
      '姓名 (英)', '職稱', '職稱 (英)', '商務電話',
      '行動電話', '電子郵件', '商務地址', '住家地址',
      '生日', '出席', '分工', '確認狀況', '狀況說明',
    ],
    ...invitationData,
  ]);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  XLSX.writeFile(wb, `ExportInvitations-${timestamp()}.xlsx`);
};

export const exportInvitationFaxFile = invitationData => {
  const ws = XLSX.utils.aoa_to_sheet([
    ['媒體類別', '媒體名稱', '姓名', '商務傳真'],
    ...invitationData,
  ]);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  XLSX.writeFile(wb, `ExportInvitationFaxFile-${timestamp()}.xlsx`);
};

export const exportPunchTimeFile = punchTimeData => {
  const ws = XLSX.utils.aoa_to_sheet([
    ['工作日期', '員工', '上班打卡', '下班打卡', '打卡類型'],
    ...punchTimeData,
  ]);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  XLSX.writeFile(wb, `ExportPunchTimeFile-${timestamp()}.xlsx`);
};

export const exportWorkingHoursFile = workingHoursData => {
  const ws = XLSX.utils.aoa_to_sheet([
    [
      '姓名', '工作日期', '專案代號',
      '專案名稱', '工作項目', '工作細項',
      '工時種類', '起訖時間', '工時',
    ],
    ...workingHoursData,
  ]);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  XLSX.writeFile(wb, `ExportWorkingHoursFile-${timestamp()}.xlsx`);
};

export const exportOvertimesFile = overtimesData => {
  const ws = XLSX.utils.aoa_to_sheet([
    [
      '姓名', '工作日期', '專案代號',
      '專案名稱', '工作項目', '工作細項',
      '工時種類', '起訖時間', '工時',
      '狀態', '核可工時',
    ],
    ...overtimesData,
  ]);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  XLSX.writeFile(wb, `ExportOvertimesFile-${timestamp()}.xlsx`);
};

export const exportOvertimePayment = overtimePaymentData => {
  const ws = XLSX.utils.aoa_to_sheet([
    [
      '同仁名稱', '時薪', '核可原始總工時',
      '核可加權總工時', '應發加班費用',
    ],
    ...overtimePaymentData,
  ]);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  XLSX.writeFile(wb, `ExportOvertimePayment-${timestamp()}.xlsx`);
};

export const exportExpensesFile = expensesData => {
  const ws = XLSX.utils.aoa_to_sheet([
    [
      '支憑流水號', '專案代號', '專案名稱',
      '付款對象', '申請人', '發票總金額',
      '申請時間', '狀態'
    ],
    ...expensesData,
  ]);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  XLSX.writeFile(wb, `ExportExpensesFile-${timestamp()}.xlsx`);
};

export const exportFinanceVerifyFile = expensesData => {
  const ws = XLSX.utils.aoa_to_sheet([
    [
      '申請時間', '支憑流水號', '狀態', '專案代號',
      '專案名稱', '付款對象', '申請人', '申請說明',
      '請款總金額', '重要性', '匯會計系統', '申報營業稅',
      '單據號碼', '預計付款日',
    ],
    ...expensesData,
  ]);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  XLSX.writeFile(wb, `ExportFinanceVerifyFile-${timestamp()}.xlsx`);
};

export const exportPettyCashFile = cashes => {
  const ws = XLSX.utils.aoa_to_sheet([
    [
      '支憑流水號', '申請時間', '專案代號', '摘要備註',
      '金額', '付款對象', '預計付款日',
    ],
    ...cashes,
  ]);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  XLSX.writeFile(wb, `ExportPettyCashFile-${timestamp()}.xlsx`);
};

export const exportFinanceManagementFile = expensesData => {
  const ws = XLSX.utils.aoa_to_sheet([
    [
      '申請時間', '支憑流水號', '狀態', '專案代號',
      '專案名稱', '付款對象', '申請人', '申請說明',
      '請款總金額', '重要性', '匯會計系統', '申報營業稅',
      '單據號碼', '單據日期', '預計付款日', '備註',
      '廠商統編', '會計大分類', '會計中分類'
    ],
    ...expensesData,
  ]);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  XLSX.writeFile(wb, `ExportFinanceManagementFile-${timestamp()}.xlsx`);
};

export const exportPersonalServiceTaxFile = voucherDatas => {
  const ws = XLSX.utils.aoa_to_sheet([
    [
      '所屬公司', '單據號碼', '申報所得種類','申報日', 
      '廠商名稱', '統一編號', '通訊地址', '請款內容', 
      '請款金額', '申請時間', '支出憑單流水號', '付款日期'
    ],
    ...voucherDatas,
  ]);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  XLSX.writeFile(wb, `ExportPersonalServiceTaxFile-${timestamp()}.xlsx`);
};

export const exportExpenseVoucherFile = voucherDatas => {
  const ws = XLSX.utils.aoa_to_sheet([
    [
      '公司別', '傳票類別', '傳票日期',
      '科目編號', '摘要備註', '借/貸', '金額',
      '對象別', '對象編號', '支憑流水號', '部門'
      //'專案代號'
    ],
    ...voucherDatas,
  ]);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  XLSX.writeFile(wb, `ExportExpenseVoucherFile-${timestamp()}.xlsx`);
};

export const exportExpenditureFile = voucherDatas => {
  const ws = XLSX.utils.aoa_to_sheet([
    [
      '申請人', '支憑流水號', '廠商名稱', '發票/單據種類',
      '單據日期', '單據號碼', '廠商統編', '未稅金額',
      '稅額', '請款金額', '會計大分類', '會計中分類',
      '申請說明',
    ],
    ...voucherDatas,
  ]);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  XLSX.writeFile(wb, `ExportExpenditureFile-${timestamp()}.xlsx`);
};

export const exportExpenseVouchersFile = voucherDatas => {
  const ws = XLSX.utils.aoa_to_sheet([
    [
      '廠商統編', '發票類型', '發票號碼', '發票日期',
      '未稅金額', '稅額', '發票總金額', '實際付款金額',
    ],
    ...voucherDatas,
  ]);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  XLSX.writeFile(wb, `ExportExpenseVouchersFile-${timestamp()}.xlsx`);
};

export const exportProjectInvoicesFile = invoicesData => {
  const ws = XLSX.utils.aoa_to_sheet([
    [
      '客戶統一編號', '客戶名稱', '專案代號', '發票號碼',
      '發票開立日期', '未稅金額', '稅額', '發票金額',
      '匯會計系統',
    ],
    ...invoicesData,
  ]);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  XLSX.writeFile(wb, `ExportProjectInvoicesFile-${timestamp()}.xlsx`);
};

export const exportDirectorRevenueFile = revenueData => {
  const ws = XLSX.utils.aoa_to_sheet([
    [
      '專案代號', '專案名稱', '總監', '專案負責人',
      'MBR日期', '服務費', '專案支出費用', '代辦服務收入',
      '入帳金額', '支出金額', '預計入帳日',
    ],
    ...revenueData,
  ]);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  XLSX.writeFile(wb, `ExportProjectInvoicesFile-${timestamp()}.xlsx`);
};

export const exportSalaryListXLS = salaryList => {
  const ws = XLSX.utils.aoa_to_sheet([
    ...salaryList,
  ]);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  XLSX.writeFile(wb, `ExportSalaryListFile-${timestamp()}.xlsx`);
};

export const exportAllLeaveTypesXLS = salaryList => {
  const ws = XLSX.utils.aoa_to_sheet([
    ...salaryList,
  ]);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  XLSX.writeFile(wb, `ExportAllLeaveTypeFile-${timestamp()}.xlsx`);
};

export const exportAllLeaveXLS = salaryList => {
  const ws = XLSX.utils.aoa_to_sheet([
    ...salaryList,
  ]);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  XLSX.writeFile(wb, `ExportAllLeaveFile-${timestamp()}.xlsx`);
};