import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';

import {
  postToGetUserInfo,
  postToGetSystemRoleDetail,
} from '@/api/authService';

import { LOGIN_SUCCESS } from '@/store/actions/loginAction';

import Wrapper from '@/components/UI/Wrapper';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import ScrollTopHook from '@/hooks/scrollTopHook';
import Login from '@/containers/login';
import Logout from '@/containers/logout';
import loginFirstTime from '@/containers/loginFirstTime';
import AzureLoginFail from '@/containers/azureLoginFail';
import AzureLoginSuccess from '@/containers/azureLoginSuccess';
import AzureSyncFail from '@/containers/azureSyncFail';
import AzureSyncSuccess from '@/containers/azureSyncSuccess';
import AccountSetting from '@/containers/accountSetting';
import MyInfoSetting from '@/containers/myInfoSetting';
import ResetPassword from '@/containers/resetPassword';
import Dashboard from '@/containers/dashboard';
// import HR from '@/containers/hr';
import RoutesProjectModule from '@/Routes/RoutesProjectModule';
import RoutesInformationModule from '@/Routes/RoutesInformationModule';
import RoutesAttendanceModule from '@/Routes/RoutesAttendanceModule';
import RoutesExpenseModule from '@/Routes/RoutesExpenseModule';
import RoutesFinanceModule from '@/Routes/RoutesFinanceModule';
import RoutesAuthorityModule from '@/Routes/RoutesAuthorityModule';
import RoutesSysConfigModule from '@/Routes/RoutesSysConfigModule';
import RoutesHRModule from '@/Routes/RoutesHRModule';

const App = props => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isLogin = useSelector(state => state.login.token) !== null;
  const needToChangePassword = useSelector(state => state.login.needToChangePassword);
  const projectRoutes = useSelector(state => state.login.projectRoutes);
  const expenseRoutes = useSelector(state => state.login.expenseRoutes);
  const financeRoutes = useSelector(state => state.login.financeRoutes);
  const authorityRoutes = useSelector(state => state.login.authorityRoutes);
  const sysConfigRoutes = useSelector(state => state.login.sysConfigRoutes);
  const attendanceRoutes = useSelector(state => state.login.attendanceRoutes);
  const informationRoutes = useSelector(state => state.login.informationRoutes);
  const hrRoutes = useSelector(state => state.login.hrRoutes);
  
  const [showLoadingPopup, setShowLoadingPopup] = useState(true);

  const autoLogin = async () => {
    setShowLoadingPopup(true);
    let projectRoutes = [];
    let expenseRoutes = [];
    let financeRoutes = [];
    let authorityRoutes = [];
    let sysConfigRoutes = [];
    let attendanceRoutes = [];
    let informationRoutes = [];
    let hrRoutes = [];

    if (Cookies.get('veda__srp-token') !== undefined) {
      try {
        const { data } = await postToGetUserInfo();
        const { systemRole } = data;

        for (let i = 0; i < systemRole.length; i += 1) {
          const roleID = systemRole[i];
          const rolesDetail = await postToGetSystemRoleDetail(roleID);
          if (rolesDetail.data.enable) {
            projectRoutes = [ ...projectRoutes, ...rolesDetail.data.projectRoutes ];
            expenseRoutes = [ ...expenseRoutes, ...rolesDetail.data.expenseRoutes ];
            financeRoutes = [ ...financeRoutes, ...rolesDetail.data.financeRoutes ];
            authorityRoutes = [ ...authorityRoutes, ...rolesDetail.data.authorityRoutes ];
            sysConfigRoutes = [ ...sysConfigRoutes, ...rolesDetail.data.sysConfigRoutes ];
            attendanceRoutes = [ ...attendanceRoutes, ...rolesDetail.data.attendanceRoutes ];
            informationRoutes = [ ...informationRoutes, ...rolesDetail.data.informationRoutes ];
            hrRoutes = [ ...hrRoutes, ...rolesDetail.data.hrRoutes ];
          }
        }

        dispatch(LOGIN_SUCCESS({
          ...data,
          projectRoutes,
          expenseRoutes,
          financeRoutes,
          authorityRoutes,
          sysConfigRoutes,
          attendanceRoutes,
          informationRoutes,
          hrRoutes,
        }));

      } catch(err) {
        console.log(err)
        history.push('/Login');
      }
    }

    setShowLoadingPopup(false);
  };

  const startAutoLogin = useCallback(
    autoLogin, []);

  useEffect(() => {
    startAutoLogin();
  }, [startAutoLogin]);

  const authorizedRoutes = needToChangePassword
    ? <Switch>
        <Route path="/LoginFirstTime" component = { loginFirstTime }/>
        <Redirect to="/LoginFirstTime"/>
      </Switch>
    : <Switch>
        { RoutesProjectModule(projectRoutes) }
        { RoutesInformationModule(informationRoutes) }
        { RoutesAttendanceModule(attendanceRoutes) }
        { RoutesExpenseModule(expenseRoutes) }
        { RoutesFinanceModule(financeRoutes) }
        { RoutesAuthorityModule(authorityRoutes) }
        { RoutesSysConfigModule(sysConfigRoutes) }
        { RoutesHRModule(hrRoutes) }
        <Route path="/AccountSetting" component = { AccountSetting }/>
        <Route path="/MyInfoSetting" component = { MyInfoSetting }/>
        <Route path="/azureSyncFail" component = { AzureSyncFail }/>
        <Route path="/azureSyncSuccess" component = { AzureSyncSuccess }/>
        <Route path="/Logout" component = { Logout }/>
        <Route path="/Dashboard" component = { Dashboard }/>
        {/* <Route path="/HR" component = { HR }/> */}
        <Redirect to="/Dashboard"/>
      </Switch>

  const routes = showLoadingPopup || isLogin
    ? authorizedRoutes
    : <Switch>
        <Route
          path="/Login"
          component = { Login }
        />
        <Route
          path="/azureLoginFail"
          component = { AzureLoginFail }
        />
        <Route
          path="/azureLoginSuccess"
          component = { AzureLoginSuccess }
        />
        <Route
          path="/ResetPassword"
          component = { ResetPassword }
        />
        <Redirect to="/Login"/>
      </Switch>;

  return (
    <div className="App">
      {
        showLoadingPopup === false &&
          <Wrapper isLogin = { isLogin } >
            <ScrollTopHook/>
            { routes }
          </Wrapper>
      }
      { showLoadingPopup && <PopupLoading/> }
    </div>
  );
}

export default App;
