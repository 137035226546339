import React from 'react';
import styled from 'styled-components';

import { ButtonCancel } from '@/component-style/Button';
import { InputBasic } from '@/component-style/Input';
import RemoveImg from '@/assets/images/icon_remove.svg';

import { getMultiplyResult } from '@/utils/mathUtils';

const DivWrapper = styled.div`
  width: 100%;
  
  @media (min-width: 768px) {
    width: calc(100% - 135px);
  }
`;

const DivFWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ButtonAdd = styled(ButtonCancel)`
  width: 150px;
`;

const InputItemName = styled(InputBasic)`
  margin-right: 20px;
  margin-bottom: 10px;

  @media (min-width: 1920px) {
    width: 300px;
  }
`;

const InputItemAccount = styled(InputBasic)`
  width: 140px;
  margin-right: 20px;
  margin-bottom: 10px;

  @media (max-width: 320px) {
    width: 120px;
  };

  @media (min-width: 1920px) {
    width: 140px;
  }
`;

const PSubtotal = styled.p`
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  width: 120px;
  margin-right: 20px;
  margin-bottom: 10px;

  @media (max-width: 320px) {
    width: 120px;
  };

  @media (min-width: 1920px) {
    width: 120px;
  }
`;

const DivIconRemove = styled.div`
  cursor: pointer;
  width: 20px;
  height: 40px;
  background-image: url(${ props => props.url });
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  @media (max-width: 375px) {
    background-position: top;
  }
`;

const ProjectVenueExpenseItem = props => {

  const removeHandler = index => {
    const filteredList = props.itemList
      .filter((item, i) => i !== index);

    props.updateVenueItemHandler([ ...filteredList ]);
  };

  const addItemHandler = () => {
    props.updateVenueItemHandler([
      ...props.itemList,
      { name: '', unitPrice: '', unit: '' },
    ]);
  };

  return (
    <DivWrapper>
      {
        props.itemList
          .map(({ name, unitPrice, unit }, index) =>
            <DivFWrapper
              key = { index }
            >
              <InputItemName
                disabled = { props.disableItemName || props.disabled }
                placeholder = { props.itemNamePlaceholder }
                value = { name }
                onChange = { ({ target }) => {
                  props.updateItemValueHandler('name', target.value, index);
                }}
              />
              <InputItemAccount
                placeholder = "單價"
                disabled = { props.disabled }
                value = { unitPrice }
                onChange = { ({ target }) => {
                  props.updateItemValueHandler('unitPrice', target.value, index);
                }}
              />
              <InputItemAccount
                placeholder = "數量"
                disabled = { props.disabled }
                value = { unit }
                onChange = { ({ target }) => {
                  props.updateItemValueHandler('unit', target.value, index);
                }}
              />
              <PSubtotal>小計: { getMultiplyResult(unitPrice, unit) }</PSubtotal>
              {
                props.itemRemovable !== false &&
                  <DivIconRemove
                    url = { RemoveImg }
                    onClick = { () => {
                      !props.disabled && removeHandler(index)
                    }}
                  />
              }
            </DivFWrapper>
          )
      }
      {
        props.itemAddable !== false &&
          <ButtonAdd
            disabled = { props.disabled }
            onClick = { () => { addItemHandler() } }
          >新增項目</ButtonAdd>
      }
    </DivWrapper>
  );
};

export default ProjectVenueExpenseItem;
