import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { TdItem, TableRow } from '@/component-style/Table';
import { ButtonBasic } from '@/component-style/Button';
import { InputBasic } from '@/component-style/Input';

import { getOptionDesc } from '@/utils/textUtils';

const TrWrapper = styled(TableRow)`
  border-bottom: solid 2px #f2f2f2;
  box-sizing: border-box;
  min-width: 1360px;

  &:last-child {
    border-bottom: none;
  }
`;

const Td120 = styled(TdItem)`
  width: 120px;
`;

const Td160 = styled(TdItem)`
  width: 160px;
`;

const ButtonUnlock = styled(ButtonBasic)`
  width: 80px;
`;

const InputBargain = styled(InputBasic)`
  width: 120px;

  @media (max-width: 320px) { width: 120px }
  @media (min-width: 1920px) { width: 120px }
`;

const Item = props => {
  const { quotation } = props;

  const memberOptions = useSelector(state => state.srpOptions.memberOptions);

  const username = getOptionDesc(memberOptions, quotation.user);

  return (
    <TrWrapper>
      <Td120>{ quotation.projectCode }</Td120>
      <Td160>{ quotation.projectName }</Td160>
      <Td160>{ quotation.vendorName }</Td160>
      <Td120>{ quotation.quotedPrice }</Td120>
      <Td160>
        <InputBargain
          type = "number"
          value = { quotation.price }
          disabled = { !quotation.isEditing }
          onChange = {({ target }) => {
            props.updatePriceHandler(target.value)
          }}
        />
      </Td160>
      <Td160>{ quotation.note }</Td160>
      <Td120>{ username }</Td120>
      <Td120>
        <ButtonUnlock
          onClick = { props.openFilesPopupHandler }
        >報價單</ButtonUnlock>
      </Td120>
      <Td120>
        {
          quotation.isEditing
            ? <ButtonUnlock
                onClick = { props.saveQuotationHandler }
              >儲存</ButtonUnlock>
            : <ButtonUnlock
                disabled = { quotation.expenseCode !== undefined && !props.approvable }
                onClick = { () => {
                  props.editQuotationHandler(true);
                }}
              >編輯</ButtonUnlock>
        }
      </Td120>
      <Td120>
        <ButtonUnlock
          disabled = { !quotation.isEditing }
          onClick = { () => {
            props.editQuotationHandler(false);
          }}
        >關閉</ButtonUnlock>
      </Td120>
    </TrWrapper>
  );
};

export default Item;
