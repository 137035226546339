import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import TableWrapper from '@/components/UI/TableWrapper';
import PageSelector from '@/components/UI/PageSelector';
import { Table, TableBody } from '@/component-style/Table';
import Header from './Header';
import Item from './Item';

// import moment from 'moment';

// import PopupOvertimeDetail from '@/components/UI/Popup/PopupOvertimeDetail';

const TableSalaryExtraPayment = props => {
  // const authorityRoutes = useSelector(state => state.login.authorityRoutes);

  const { extraPayments } = props;

  // const disableDetailPage = authorityRoutes.indexOf('UserDetail') === -1;

  // const [showOvertimeDetailPopup, setShowOvertimeDetailPopup] = useState(false);

  // const [viewOvertimeDetailUserID, setViewOvertimeDetailUserID] = useState("");

  return (
    <TableWrapper title = "薪資加、減項">
      <Table>
        <Header
          removable = { props.removable }
        />
        <TableBody>
          {
            extraPayments !== undefined && extraPayments.map(singleExtraPayment =>
              <Item
                key = { singleExtraPayment.id }
                removable = { props.removable }
                extraPayment = { singleExtraPayment }
                removeExtraPaymentHandler = { (extraPaymentId) => {
                  console.log(`TableSalaryExtraPayment index.js removeExtraPaymentHandler() extraPaymentId=${extraPaymentId}`);
                  props.removeExtraPaymentHandler(extraPaymentId);
                }}
              />
            )
          }
        </TableBody>
      </Table>
      <PageSelector
        // pageSize = { 1 }
        total = { props.extraPaymentTotalCount }
        current = { props.extraPaymentCurrentPage }
        pageChangedHandler = { props.extraPaymentPageChangedHandler }
        showSizeChanger = { false }
      />
    </TableWrapper>
  );
};

export default TableSalaryExtraPayment;
