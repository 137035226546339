import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { postToGetUsers } from '@/api/authService';

import {
  FETCH_DIRECTOR_OPTIONS,
  FETCH_DEPARTMENT_OPTIONS,
  FETCH_SYSTEM_ROLE_OPTIONS,
} from '@/store/actions/srpOptionsAction';

import MainContent from '@/component-style/MainContent';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PanelUserSearch from '@/components/Panel/Authority/PanelUserSearch';
import TableSystemUsers from '@/components/Table/Authority/TableSystemUsers';

import { searchConfigGenerator } from '@/utils/apiRequestUtils';

const DivPanelContainer = styled.div`
  margin-bottom: 40px;
`;

const searchConfig = {
  code: '',
  name: '',
  hasCreditCard: '',
  department: '',
  director: '',
  enable: '',
  systemRole: [],
  projectRole: [],
  companyType: '',
};

const WageStructureManagement = props => {
  const dispatch = useDispatch();
  const directorOptions = useSelector(state => state.srpOptions.directorOptions);
  const systemRoleOptions = useSelector(state => state.srpOptions.systemRoleOptions);
  const departmentOptions = useSelector(state => state.srpOptions.departmentOptions);

  const [users, setUsers] = useState([]);

  const [config, setConfig] = useState({ ...searchConfig });
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [showLoadingPopup, setShowLoadingPopup] = useState(false);

  const updateConfigHandler = (key, value) => {
    const updateObj = { ...config };
    updateObj[key] = value;
    setConfig({ ...updateObj });
  };

  const clearHandler = () => {
    setConfig({
      ...searchConfig,
      systemRole: [],
      projectRole: [],
    });
    setUsers([]);
    setTotalCount(0);
    setCurrentPage(1);
  };

  const searchHandler = () => {
    setCurrentPageHandler(1);
  };

  const setCurrentPageHandler = page => {
    setCurrentPage(page);
    fetchUsers(page);
  };

  const fetchUsers = async (page = currentPage) => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetUsers(
        searchConfigGenerator({
          ...config, page, amount: 10,
        })
      )
      
      const { users, totalCount } = data;
      setUsers([...users]);
      setTotalCount(totalCount|| 0);
    } catch(err) { console.log(err) }

    setShowLoadingPopup(false);
  }; 

  const initUsers = useCallback(fetchUsers, []);

  const initOptions = useCallback(() => {
    dispatch(FETCH_DIRECTOR_OPTIONS());
    dispatch(FETCH_DEPARTMENT_OPTIONS());
    dispatch(FETCH_SYSTEM_ROLE_OPTIONS());
  }, [dispatch]);

  useEffect(() => {
    initUsers();
    initOptions();
  }, [initOptions, initUsers]);

  return (
    <MainContent>
      <DivPanelContainer>
        <PanelUserSearch
          popupWageStructureWhenEditingUser = { true }
          config = { config }
          directorOptions = { directorOptions }
          systemRoleOptions = { systemRoleOptions }
          departmentOptions = { departmentOptions }
          clearHandler = { clearHandler }
          searchHandler = { searchHandler }
          updateConfigHandler = { updateConfigHandler }
        />
      </DivPanelContainer>
      <TableSystemUsers
        popupWageStructureWhenEditingUser = { true }
        users = { users }
        totalCount = { totalCount }
        currentPage = { currentPage }
        pageChangedHandler = { setCurrentPageHandler }
      />
      { showLoadingPopup && <PopupLoading/> }
    </MainContent>
  );
};

export default WageStructureManagement;
