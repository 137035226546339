import React from 'react';
import { Select } from 'antd';
import 'antd/dist/antd.css';
import '../index.css'

const { Option } = Select;

const SelectMultiple = props => {

  const options = props.options
    .map(option =>
      <Option
        key = { option.value }
        value = { option.value }
        disabled = { option.disabled }
      >
        { option.desc }
      </Option>
    )

  return (
    <Select
      mode="multiple"
      open = { props.open }
      value = { props.value }
      disabled = { props.disabled }
      onChange = { props.changeHandler }
      filterOption={(input, option) => 
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      // filterOption={(input, option) =>
      //   option.children.toLowerCase().startsWith(input.toLowerCase())
      // }
    >
      { options }
  </Select>
  );
};

export default SelectMultiple;
