import * as types from '../action-types/loginActionTypes';

const initState = {
  user: '',
  token: null,
  azureID: '',
  companyType: '',
  showProject: false,
  systemRole: [],
  projectRole: [],
  projectRoutes: [],
  informationRoutes: [],
  attendanceRoutes: [],
  expenseRoutes: [],
  financeRoutes: [],
  authorityRoutes: [],
  sysConfigRoutes: [],
  hrRoutes: [],
  errorMsg: '',
  syncAzureFail: false,
  syncAzureSuccess: false,
  syncAzure: false,
  loginFail: false,
  loading: false,
  needToChangePassword: false,
};

const loginReducer = (state = initState, action) => {
  const { type ,payload } = action;

  switch (type) {
    case types.LOGOUT:
      return {
        ...initState,
      };
    case types.LOGIN_START:
      return {
        ...state,
        loginFail: false,
        errorMsg: '',
        loading: true,
      };
    case types.LOGIN_FAIL:
      return {
        ...state,
        loginFail: true,
        errorMsg: payload,
        loading: false,
      };
    case types.AZURE_LOGIN_START:
      return {
        ...state,
        loginFail: false,
        errorMsg: '',
        loading: true,
      };
    case types.AZURE_LOGIN_FAIL:
      return {
        ...state,
        loginFail: true,
        errorMsg: '從 Azure 帳戶登入失敗',
        loading: false,
      };
    case types.LOGIN_SUCCESS:
      console.log("loginReducer types.LOGIN_SUCCESS payload.needToChangePassword:" + payload.needToChangePassword);
      return {
        ...state,
        user: payload.name,
        token: payload.userID,
        azureID: payload.azureID,
        companyType: payload.companyType,
        showProject: payload.showProject || false,
        systemRole: [ ...payload.systemRole ],
        projectRole: [ ...payload.projectRole ],
        projectRoutes: [ ...payload.projectRoutes ],
        expenseRoutes: [ ...payload.expenseRoutes ],
        financeRoutes: [ ...payload.financeRoutes ],
        authorityRoutes: [ ...payload.authorityRoutes ],
        sysConfigRoutes: [ ...payload.sysConfigRoutes ],
        attendanceRoutes: [ ...payload.attendanceRoutes ],
        informationRoutes: [ ...payload.informationRoutes ],
        hrRoutes: [ ...payload.hrRoutes ],
        syncAzure: payload.azureID || false,
        loading: false,
        needToChangePassword: payload.needToChangePassword,
        // needToChangePassword: true,
      };
    case types.AZURE_SYNC_START:
      return {
        ...state,
        loading: true,
        syncAzureFail: false,
        syncAzureSuccess: false,
      };
    case types.AZURE_SYNC_SUCCESS:
      return {
        ...state,
        loading: false,
        syncAzureFail: false,
        syncAzureSuccess: true,
        syncAzure: true,
      };
    case types.AZURE_SYNC_FAIL:
      return {
        ...state,
        loading: false,
        syncAzureFail: true,
        syncAzureSuccess: false,
      };
    case types.CLOSE_AZURE_SYNC_POP:
      return {
        ...state,
        loading: false,
        syncAzureFail: false,
        syncAzureSuccess: false,
      };
    default:
      return state;
  };
};

export default loginReducer;
