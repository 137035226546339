import React from 'react';
import styled from 'styled-components';

import TableWrapper from '@/components/UI/TableWrapper';
import Checkbox from '@/components/UI/Checkbox';
import SelectNormal from '@/components/UI/Select/SelectNormal';
import SelectMultiple from '@/components/UI/Select/SelectMultiple';
import SelectSearchable from '@/components/UI/Select/SelectSearchable';
import DateSelector from '@/components/UI/DateSelector';
import DatePeriodSelector from '@/components/UI/DatePeriodSelector';
import MediaDateList from '@/components/MediaDateList';
import { InputBasic, TextareaBasic } from '@/component-style/Input';
import { PSubtitle } from '@/component-style/TextElement';
import {
  DivFlexRow,
  DivItemWrapper,
  DivFullWidthWrapper,
  DivLongMultiSelector,
} from '@/component-style/RWDWrapper';

import { formatTime } from '@/utils/timeUtils';

const Textarea = styled(TextareaBasic)`
  width: calc(100% - 135px);

  @media (max-width: 767px) {
    width: 100%;
  }
`;

const PanelProjectDetail = props => {
  const { project, contactWindowOptions } = props;

  const filteredOptions = contactWindowOptions
    .filter(item => item.customerID === project.customer);

  const updateActionTimeStartHandler = value => {
    props.updateProjectHandler('actionTimeStart', value);
  };

  const updateActionTimeEndHandler = value => {
    props.updateProjectHandler('actionTimeEnd', value);
  };

  const addMediaDayHandler = () => {
    props.updateProjectHandler(
      'mediaDays',
      [
        ...project.mediaDays,
        { start: Date.now(), end: Date.now(), remark: '' }
      ],
    );
  };

  const deleteMediaDayHandler = index => {
    const filteredList = [ ...project.mediaDays ]
      .filter((item, i) => i !== index);
    props.updateProjectHandler('mediaDays', filteredList);
  };

  const updateMediaDayHandler = (index, value) => {
    const updatedList = [ ...project.mediaDays ];
    updatedList[index] = value;

    props.updateProjectHandler('mediaDays', updatedList);
  };

  return (
    <TableWrapper title = { props.title }>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>
            專案代號
            <span>*</span>
          </PSubtitle>
          <div>
            <InputBasic
              disabled = { props.disabled }
              style = {{ marginBottom: '10px' }}
              value = { project.projectCode }
              onChange = {({ target }) => {
                props.updateProjectHandler('projectCode', target.value);
              }}
            />
            <br></br>
            <Checkbox
              disabled = { props.disabled }
              title = "內部專案"
              checked = { project.internal }
              changeHandler = { value => {
                props.updateProjectHandler('internal', value);
              }}
            />
          </div>
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>
            專案名稱
            <span>*</span>
          </PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { project.projectName }
            onChange = {({ target }) => {
              props.updateProjectHandler('projectName', target.value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>
            所屬公司
            <span>*</span>
          </PSubtitle>
          <SelectNormal
            disabled = { props.disabled }
            value = { project.companyType }
            options = {[
              { value: '', desc: '請選擇所屬公司'},
              ...props.companyTypeOptions,
            ]}
            changeHandler = { value => {
              props.updateProjectHandler('companyType', value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>主題/部門</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { project.theme }
            onChange = {({ target }) => {
              props.updateProjectHandler('theme', target.value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>
            預計發票開立日期
            <span>*</span>
          </PSubtitle>
          <DateSelector
            disabled = { props.disabled }
            value = { project.receiveDate }
            changeHandler = { value => {
              props.updateProjectHandler(
                'receiveDate',
                formatTime(value, 'YYYY-MM-DD'),
              );
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>
            活動日期
            <span>*</span>
          </PSubtitle>
          <DivLongMultiSelector>
            <DatePeriodSelector
              disabled = { props.disabled }
              startTime = { project.actionTimeStart }
              endTime = { project.actionTimeEnd }
              // showTime = { true }
              endTimeOKHandler = { updateActionTimeEndHandler }
              endTimeChangeHandler = { updateActionTimeEndHandler }
              startTimeOKHandler = { updateActionTimeStartHandler }
              startTimeChangeHandler = { updateActionTimeStartHandler }
            />
          </DivLongMultiSelector>
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>
            媒體日期
          </PSubtitle>
          <MediaDateList
            disabled = { props.disabled }
            timeList = { project.mediaDays }
            addDateHandler = { addMediaDayHandler }
            deleteDateHandler = { deleteMediaDayHandler }
            updateMediaDayHandler = { updateMediaDayHandler }
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>
            客戶名稱
            <span>*</span>
          </PSubtitle>
          <SelectSearchable
            value = { project.customer }
            disabled = { props.disabled }
            options = {[
              { value: '', desc: '請選擇客戶' },
              ...props.customerOptions
            ]}
            changeHandler = { props.updateCustomerHandler }
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>
            客戶窗口
            <span>*</span>
          </PSubtitle>
          <SelectMultiple
            disabled = { props.disabled || project.customer === '' }
            value = { project.customerWindows }
            options = { filteredOptions }
            changeHandler = { value => {
              props.updateProjectHandler('customerWindows', [ ...value ]);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>
            總監
            <span>*</span>
          </PSubtitle>
          <SelectSearchable
            disabled = { props.disabled }
            value = { project.director }
            options = {[
              { value: '', desc: '請選擇總監' },
              ...props.directorOptions,
            ]}
            changeHandler = { value => {
              props.updateProjectHandler('director', value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>
            專案負責人
            <span>*</span>
          </PSubtitle>
          <SelectSearchable
            disabled = { props.disabled }
            value = { project.projectManager }
            options = {[
              { value: '', desc: '請選擇專案負責人' },
              ...props.projectManagerOptions,
            ]}
            changeHandler = { value => {
              props.updateProjectHandler('projectManager', value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivFullWidthWrapper>
          <PSubtitle>
            專案成員
          </PSubtitle>
          <DivLongMultiSelector>
            <div
              style = {{ height: '40px', lineHeight: '40px' }}
            >
              <Checkbox
                disabled = { props.disabled }
                title = "全部成員"
                checked = { project.allmembers }
                changeHandler = { value => {
                  props.updateAllMemberHandler(value);
                }}
              />
            </div>
            <SelectMultiple
              value = { project.members }
              disabled = { props.disabled || project.allmembers }
              options = { props.memberOptions }
              changeHandler = { value => {
                props.updateProjectHandler('members', [ ...value ]);
              }}
            />
          </DivLongMultiSelector>
        </DivFullWidthWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivFullWidthWrapper>
          <PSubtitle>
            支援成員
          </PSubtitle>
          <DivLongMultiSelector>
            <SelectMultiple
              value = { project.supports }
              disabled = { props.disabled || project.allmembers }
              options = { props.memberOptions }
              changeHandler = { value => {
                props.updateProjectHandler('supports', [ ...value ]);
              }}
            />
          </DivLongMultiSelector>
        </DivFullWidthWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivFullWidthWrapper>
          <PSubtitle>
            專案類型
          </PSubtitle>
          <DivLongMultiSelector>
            <SelectMultiple
              disabled = { props.disabled }
              value = { project.projectTypes }
              options = { props.projectTypeOptions }
              changeHandler = { value => {
                props.updateProjectHandler('projectTypes', [ ...value ]);
              }}
            />
          </DivLongMultiSelector>
        </DivFullWidthWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivFullWidthWrapper>
          <PSubtitle>
            備註說明
          </PSubtitle>
          <Textarea
            disabled = { props.disabled }
            value = { project.note }
            onChange = {({ target }) => {
              props.updateProjectHandler('note', target.value);
            }}
          />
        </DivFullWidthWrapper>
      </DivFlexRow>
      { props.children }
    </TableWrapper>
  );
};

export default PanelProjectDetail;
