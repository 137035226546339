import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { FETCH_PROJECT_ID_OPTIONS } from '@/store/actions/srpOptionsAction';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import {
  putProjectDetail,
  postToGetProjectDetail,
} from '@/api/projectService';

import MainContent from '@/component-style/MainContent';
import PanelWrapper from '@/components/UI/PanelWrapper';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import TableUnlockPurchase from '@/components/Table/Finance/TableUnlockPurchase';
import SelectSearchable from '@/components/UI/Select/SelectSearchable';
import SearchButtonSet from '@/components/UI/SearchButtonSet';
import { PSubtitle } from '@/component-style/TextElement';
import {
  DivFlexRow,
  DivFullWidthWrapper,
  DivLongMultiSelector,
} from '@/component-style/RWDWrapper';

import {
  toUTCTime,
  formatTime,
} from '@/utils/timeUtils';

const DivPanelContainer = styled.div`
  margin-bottom: 40px;
`;

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding: 0;
  justify-content: flex-end;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const UnlockPurchase = () => {
  const dispatch = useDispatch();
  const projectIDOptions = useSelector(state => state.srpOptions.projectIDOptions);
  const showSaveFailPopup = useSelector(state => state.apiFail.showSaveFailPopup);
  const apiFailPopupTitle = useSelector(state => state.apiFail.title);
  const apiFailPopupMessage = useSelector(state => state.apiFail.message);

  const [projectID, setProjectID] = useState('');
  const [projects, setProjects] = useState([]);

  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [showUnlockSuccessPopup, setShowUnlockSuccessPopup] = useState(false);

  const clearHandler = () => {
    setProjects([]);
    setProjectID('');
  };

  const closeSuccessPopupHandler = () => {
    setShowUnlockSuccessPopup(false);
    searchHandler();
  };

  const financeUnlockHandler = async projectID => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetProjectDetail(projectID);
      await putProjectDetail({
        ...data,
        financeUnlock: toUTCTime(Date.now())
      });
      setShowLoadingPopup(false);
      setShowUnlockSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '解鎖失敗', err }));
      console.log(err);
    }
  };

  const searchHandler = async () => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetProjectDetail(projectID);

      const {
        projectCode, projectName,
        actionTimeStart, actionTimeEnd, financeUnlock
      } = data;

      setProjects([{
        projectID,
        projectCode,
        projectName,
        start: formatTime(actionTimeStart, 'YYYY/MM/DD'),
        end: formatTime(actionTimeEnd, 'YYYY/MM/DD'),
        unlockTime: formatTime(financeUnlock, 'YYYY/MM/DD HH:mm:ss') || '',
      }]);
     
    } catch(err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const initOptions = useCallback(() => {
    dispatch(FETCH_PROJECT_ID_OPTIONS());
  }, [dispatch]);

  useEffect(() => {
    initOptions();
  }, [initOptions]);

  return(
    <MainContent>
      <DivPanelContainer>
        <PanelWrapper title = "專案採購解鎖">
          <DivFlexRow>
            <DivFullWidthWrapper>
              <PSubtitle>專案代號</PSubtitle>
              <DivLongMultiSelector>
                <SelectSearchable
                  value = { projectID }
                  options = {[
                    { value: '', desc: '請選擇專案代號' },
                    ...projectIDOptions,
                  ]}
                  changeHandler = { setProjectID }
                />
              </DivLongMultiSelector>
            </DivFullWidthWrapper>
          </DivFlexRow>
          <DivFlexRow>
            <DivButtonsWrapper>
              <SearchButtonSet
                searchable = { projectID !== '' }
                clearHandler = { clearHandler }
                searchHandler = { searchHandler }
              />
            </DivButtonsWrapper>
        </DivFlexRow>
        </PanelWrapper>
      </DivPanelContainer>
      <TableUnlockPurchase
        projects = { projects }
        financeUnlockHandler = { financeUnlockHandler }
      />
      { showLoadingPopup && <PopupLoading/> }
      {
        showUnlockSuccessPopup &&
          <PopupExecuteResult
            title = "解鎖成功"
            confirmHandler = { closeSuccessPopupHandler }
          />
      }
      {
        showSaveFailPopup &&
          <PopupExecuteResult
            title = { apiFailPopupTitle }
            text = { apiFailPopupMessage }
            confirmHandler = { () => {
              dispatch(CLOSE_SAVE_FAIL_POPUP());
            }}
          />
      }
    </MainContent>
  );
};

export default UnlockPurchase;
