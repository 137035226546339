import React from 'react';
import styled from 'styled-components';

import UploadNormal from '@/components/UI/Upload/UploadNormal';
import UploadMultiple from '@/components/UI/Upload/UploadMultiple';
import { DivPopupWrapper, DivPopup } from '@/component-style/Popup';
import { ButtonBasic } from '@/component-style/Button';

const DivButtonsWrapper = styled.div`
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const DivUploadWrapper = styled.div`
  margin-bottom: 20px;
`;

const H3Title = styled.h3`
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: #8b8b8b;
  margin-bottom: 10px;
`;

const ButtonConfirm = styled(ButtonBasic)`
  width: 120px;
`;

const PopupSelectFiles = props => {

  return (
    <DivPopupWrapper>
      <DivPopup>
        <H3Title>{ props.title || '附件管理' }</H3Title>
        <DivUploadWrapper>
        {
            props.multiple === true
              ? <UploadMultiple
                  uploadFiles = { props.uploadFiles }
                  updateUploadFiles = { props.updateUploadFiles }
                />
              : <UploadNormal
                  uploadFiles = { props.uploadFiles }
                  updateUploadFiles = { props.updateUploadFiles }
                />
          }
        </DivUploadWrapper>
        <DivButtonsWrapper>
          <ButtonConfirm
            onClick = { props.confirmHandler }
          >確定</ButtonConfirm>
        </DivButtonsWrapper>
      </DivPopup>
    </DivPopupWrapper>
  );
};

export default PopupSelectFiles;
