import React from 'react';
import styled from 'styled-components';

import { TdItem, TableRow } from '@/component-style/Table';

const TrWrapper = styled(TableRow)`
  border-bottom: solid 2px #f2f2f2;
  box-sizing: border-box;
  min-width: 640px;

  &:last-child {
    border-bottom: none;
  }
`;

const Td120 = styled(TdItem)`
  width: 120px;
`;

const Td160 = styled(TdItem)`
  width: 160px;
`;

const Td240 = styled(TdItem)`
  width: 240px;
`;


const Item = props => {
  const { quotation } = props;

  return (
    <TrWrapper>
      <Td120>{ quotation.projectCode }</Td120>
      <Td240>{ quotation.projectName }</Td240>
      <Td160>{ quotation.note }</Td160>
      <Td120>{ quotation.quotedPrice || 0 }</Td120>
    </TrWrapper>
  )
};

export default Item;
