import React from 'react';

import TableWrapper from '@/components/UI/TableWrapper';
import DateSelector from '@/components/UI/DateSelector';
import SelectNormal from '@/components/UI/Select/SelectNormal';
import SelectSearchable from '@/components/UI/Select/SelectSearchable';
import { InputBasic } from '@/component-style/Input';
import { PSubtitle } from '@/component-style/TextElement';
import {
  DivFlexRow,
  DivItemWrapper,
} from '@/component-style/RWDWrapper';

import {
  addTime,
  // diffTimeWithFloat,
  diffTimeSubtractLunchBreak,
  toUTCTime,
  formatTime,
} from '@/utils/timeUtils';

import moment from 'moment';

// import { overtimeDateTypeOptions } from '@/constant/options';

const PanelOvertimeDetail = props => {
  const { overtime, workingHoursTagDictionary } = props;
  // console.log(`PanelOvertimeDetail index.js JSON.stringify(props.overtime)=${JSON.stringify(props.overtime)}`);

  const tagOptions = typeID => 
    workingHoursTagDictionary[typeID] === undefined
      ? []
      : workingHoursTagDictionary[typeID];

  const workingHours = diffTimeSubtractLunchBreak(overtime.startTimeLocal, overtime.endTimeLocal, 'hours');

  const disabledStartDate = current => current > addTime(overtime.workingDate, 1, 'days')
    || current <= toUTCTime(overtime.workingDate);

  const disabledEndDate = current => current > addTime(overtime.workingDate, 1, 'days')
    || current <= toUTCTime(overtime.workingDate);

  const updateStartTimeHandler = value => {
    props.updateOvertimeHandler(
      'startTimeLocal',
      formatTime(value, 'YYYY/MM/DD HH:mm')
    );
  };

  const updateEndTimeHandler = value => {
    props.updateOvertimeHandler(
      'endTimeLocal',
      formatTime(value, 'YYYY/MM/DD HH:mm')
    )
  };

  return (
    <TableWrapper title = { props.title }>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>
            加班日期
            <span>*</span>
          </PSubtitle>
          <DateSelector
            disabled = { props.disabled || props.disableDate }
            value = { overtime.workingDate }
            changeHandler = { value => {
              props.workingDateChangeHanlder(
                formatTime(value, 'YYYY/MM/DD')
              );
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>
            專案名稱
            <span>*</span>
          </PSubtitle>
          <SelectSearchable
            disabled = { props.disabled || overtime.workingDate === null }
            value = { overtime.projectID }
            options = {[
              { value: '', desc: '請選擇專案代號' },
              ...props.projectIDOptions,
            ]}
            changeHandler = { value => {
              props.updateOvertimeHandler('projectID', value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>
            工作項目
            <span>*</span>
          </PSubtitle>
          <SelectSearchable
            disabled = { props.disabled || overtime.workingDate === null }
            value = { overtime.typeID }
            options = {[
              { value: '', desc: '請選擇工作項目' },
              ...props.workingHoursTypeOptions,
            ]}
            changeHandler = { value => {
              props.typeChangeHandler(value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>
            工作細項
          </PSubtitle>
          <SelectSearchable
            disabled = { props.disabled || overtime.workingDate === null || overtime.typeID === '' }
            value = { overtime.tag }
            options = {[
              { value: '', desc: '請選擇工作細項' },
              ...tagOptions(overtime.typeID),
            ]}
            changeHandler = { value => {
              props.updateOvertimeHandler('tag', value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>
            開始時間
            <span>*</span>
          </PSubtitle>
          <DateSelector
            showTime = { true }
            disabled = { props.disabled || overtime.workingDate === null }
            disabledDate = { disabledStartDate }
            value = { overtime.startTimeLocal == null ? null : moment.parseZone(overtime.startTimeLocal).format('YYYY/MM/DD HH:mm') }
            // value = { overtime.startTimeLocal }
            okHandler = { updateStartTimeHandler }
            changeHandler = { updateStartTimeHandler }
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>
            結束時間
            <span>*</span>
          </PSubtitle>
          <DateSelector
            showTime = { true }
            disabled = { props.disabled || overtime.workingDate === null }
            disabledDate = { disabledEndDate }
            value = { overtime.endTimeLocal == null ? null : moment.parseZone(overtime.endTimeLocal).format('YYYY/MM/DD HH:mm') }
            // value = { overtime.endTimeLocal }
            okHandler = { updateEndTimeHandler }
            changeHandler = { updateEndTimeHandler }
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>工時</PSubtitle>
          <InputBasic
            disabled = { true }
            value = { workingHours }
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>
            跨組
            <span>*</span>
          </PSubtitle>
          <SelectNormal
            disabled = { props.disabled || overtime.workingDate === null }
            value = { overtime.crossTeam }
            options = {[
              { value: true, desc: '是'},
              { value: false, desc: '否'},
            ]}
            changeHandler = { value => {
              props.updateOvertimeHandler('crossTeam', value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>加班補償</PSubtitle>
          <SelectNormal
            disabled = { props.disabled || overtime.workingDate === null }
            value = { overtime.overtimeType }
            options = {[
              { value: 'freeTime', desc: '補休'},
              { value: 'extraPay', desc: '加班費'},
            ]}
            changeHandler = { value => {
              props.updateOvertimeHandler('overtimeType', value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>備註</PSubtitle>
          <InputBasic
            disabled = { props.disabled || overtime.workingDate === null }
            value = { overtime.note }
            onChange = { ({ target }) => {
              props.updateOvertimeHandler('note', target.value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      {
        props.showApprovement &&
        <React.Fragment>
          <DivFlexRow>
            <DivItemWrapper>
              <PSubtitle>核准人</PSubtitle>
              <InputBasic
                disabled = { true }
                value = { props.approvedUser.name == null ? "" : props.approvedUser.name }
                // value = { props.approvedUser == null ? "" : props.approvedUser.approvementUserID }
                // value = { props.approvedUser == null ? "" : props.approvedUser.account }
              />
            </DivItemWrapper>
            <DivItemWrapper>
              <PSubtitle>核准日期</PSubtitle>
              <InputBasic
                disabled = { true }
                value = { overtime.approvementDate }
              />
            </DivItemWrapper>
          </DivFlexRow>
          <DivFlexRow>
            <DivItemWrapper>
              <PSubtitle>申請日期</PSubtitle>
              <InputBasic
                disabled = { true }
                value = { overtime.submitDate }
              />
            </DivItemWrapper>
          </DivFlexRow>
        </React.Fragment>
      }
      { props.children }
    </TableWrapper>
  );
};

export default PanelOvertimeDetail;
