import React, { useState } from 'react';
import { Tabs } from 'antd';

import MainContent from '@/component-style/MainContent';
import RevenueByCompanySearch from './revenueByCompanySearch';
import RevenueByProjectSearch from './revenueByProjectSearch';
import RevenueByTeamSearch from './revenueByTeamSearch';
import RevenueByCustomerSearch from './revenueByCustomerSearch';
import RevenueByCustomerReceivedSearch from './revenueByCustomerReceivedSearch';
// import CostInvoiceSearch from './costInvoiceSearch';

const { TabPane } = Tabs;

const RevenueSearch = () => {
  const [activeKey, setActiveKey] = useState('1');

  return(
    <MainContent>
      <Tabs
        type = "card"
        animated = { false }
        activeKey = { activeKey }
        onChange = { setActiveKey }
      >
        <TabPane tab="公司營收" key="1">
          {
            activeKey === '1' &&
              <RevenueByCompanySearch/>
          }
        </TabPane>
        <TabPane tab="專案營收" key="2">
          {
            activeKey === '2' &&
              <RevenueByProjectSearch/>
          }
        </TabPane>
        <TabPane tab="各組營收" key="3">
          {
            activeKey === '3' &&
              <RevenueByTeamSearch/>
          }
        </TabPane>
        <TabPane tab="客戶營收" key="4">
          {
            activeKey === '4' &&
              <RevenueByCustomerSearch/>
          }
        </TabPane>
        <TabPane tab="各組預收" key="5">
          {
            activeKey === '5' &&
              <RevenueByCustomerReceivedSearch/>
          }
        </TabPane>
      </Tabs>
    </MainContent>
  );
};

export default RevenueSearch;
