import React from 'react';
import styled from 'styled-components';

import { TableHead, TableRow, ThItem } from '@/component-style/Table';

const THead = styled(TableHead)`
  min-width: 840px;
`;

const Th120 = styled(ThItem)`
  width: 120px;
`;

const Th160 = styled(ThItem)`
  width: 160px;
`;

const Th80 = styled(ThItem)`
  width: 80px;
`;

const Header = props => {
  
  return (
    <THead>
      <TableRow>
        <Th160>假別名稱</Th160>
        <Th160>假別描述</Th160>
        <Th160>適用對象</Th160>
        <Th160>最小請假時間(小時)</Th160>
        <Th160>是否支薪</Th160>
        <Th80></Th80>
      </TableRow>
    </THead>
  );
};

export default Header;
