import React, { useState } from 'react';
import { Tabs } from 'antd';

import MainContent from '@/component-style/MainContent';
import CompensatedLeaveSearch from './compensatedLeaveSearch';
import AllLeaveSearch from './allLeaveSearch';
import AllUserLeaveType from './allUserLeaveType';
import SetSpecialLeaveHours from './setSpecialLeaveHours';
const { TabPane } = Tabs;

const PersonnelCompensatedLeaveManagement = () => {
  const [activeKey, setActiveKey] = useState('1');

  return(
    <MainContent>
      <Tabs
        type = "card"
        animated = { false }
        activeKey = { activeKey }
        onChange = { setActiveKey }
      >
        <TabPane tab="同仁補休管理" key="1">
          {
            activeKey === '1' &&
              <CompensatedLeaveSearch/>
          }
        </TabPane>
        <TabPane tab="同仁假別匯出" key="2">
          {
            activeKey === '2' &&
              <AllUserLeaveType/>
          }
        </TabPane>
        <TabPane tab="同仁休假查詢" key="3">
          {
            activeKey === '3' &&
              <AllLeaveSearch/>
          }
        </TabPane>
        <TabPane tab="設定特休時數" key="4">
          {
            activeKey === '4' &&
              <SetSpecialLeaveHours/>
          }
        </TabPane>
      </Tabs>
    </MainContent>
  );
};

export default PersonnelCompensatedLeaveManagement;
