import React from 'react';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const DivWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  color: #82CD64;
`;

const PWarningValue = styled.p`
  padding: 0 6px;
  position: absolute;
  top: -10px;
  left: 10px;
  font-size: 12px;
  height: 18px;
  line-height: 18px;
  border-radius: 10px;
  background-color: #DE2027;
  color: #FFFFFF;
`;


const StatusCheck = props => {

  const showWarningValue = props.value !== 0
    && props.value !== undefined
    && isNaN(props.value) === false;

  return (
    <DivWrapper>
      {
        props.checked &&
          <FontAwesomeIcon
            size = "lg"
            icon = { faCheck }
          />
      }
      {
        showWarningValue &&
          <PWarningValue>
            { props.value }
          </PWarningValue> 
      }
    </DivWrapper>
  );
};

export default StatusCheck;
