import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { uniq } from 'lodash';

import { postToGetProjectVendorIDs } from '@/api/expenseService';

import {
  postToGetVendorEvaluations,
  patchModifyVendorEvaluations,
} from '@/api/projectService';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import {
  FETCH_VENDOR_ID_OPTIONS,
  FETCH_PROJECT_ID_OPTIONS,
} from '@/store/actions/srpOptionsAction';

import MainContent from '@/component-style/MainContent';
import PanelWrapper from '@/components/UI/PanelWrapper';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import SelectSearchable from '@/components/UI/Select/SelectSearchable';
import SearchButtonSet from '@/components/UI/SearchButtonSet';
import TableVendorEvaluation from '@/components/Table/TableVendorEvaluation';

import { PSubtitle } from '@/component-style/TextElement';
import {
  DivFlexRow,
  DivFullWidthWrapper,
  DivLongMultiSelector,
} from '@/component-style/RWDWrapper';

import { integerConverter } from '@/utils/textUtils';
import { vendorEvaluationConfig } from '@/constant/dataConfig/projectModule';

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding: 0;
  justify-content: flex-end;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const TableContainer = styled.div`
  margin-top: 40px;
`;

const VendorEvaluation = () => {
  const dispatch = useDispatch();
  const projectRoutes = useSelector(state => state.login.projectRoutes);
  const projectIDOptions = useSelector(state => state.srpOptions.projectIDOptions);
  const showSaveFailPopup = useSelector(state => state.apiFail.showSaveFailPopup);
  const apiFailPopupTitle = useSelector(state => state.apiFail.title);
  const apiFailPopupMessage = useSelector(state => state.apiFail.message);

  const [projectID, setProjectID] = useState('');
  const [evaluations, setEvaluations] = useState([]);

  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [showSaveSuccessPopup, setShowSaveSuccessPopup] = useState(false);

  const userEditable = projectRoutes.indexOf('VendorEvaluation-Edit') !== -1;

  const evaluationChangeHandler = (value, index) => {
    const updateList = [...evaluations];
    updateList[index] = value;
    setEvaluations([...updateList]);
  };

  const clearHandler = () => {
    setProjectID('');
    setEvaluations([]);
  };

  const updateEvaluations = async () => {
    setShowLoadingPopup(true);

    try {
      await patchModifyVendorEvaluations({
        projectID,
        evaluations: evaluations.map(evaluation => ({
          ...evaluation,
          price: integerConverter(evaluation.price),
          attitude: integerConverter(evaluation.attitude),
          satisfaction: integerConverter(evaluation.satisfaction),
          compatibility: integerConverter(evaluation.compatibility),
          timeManagement: integerConverter(evaluation.timeManagement),
        })),
      });

      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
    } catch (err) {
      console.log(err);
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '儲存失敗', err }));
    }
  };

  const searchHandler = async () => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetProjectVendorIDs(projectID);
      const vendorEvaluationDetail = await postToGetVendorEvaluations(projectID);

      const { vendors } = data;
      const evaluationRecords = vendorEvaluationDetail.data;

      const evaluations = uniq(vendors).reduce((acc, vendorID) => {
        const evaluationRecord = evaluationRecords
          .filter(record => record.vendorID === vendorID);

        return [...acc, {
          ...vendorEvaluationConfig,
          ...evaluationRecord[0],
          vendorID,
        }];
      }, []);

      setEvaluations([...evaluations]);
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const initOptions = useCallback(() => {
    dispatch(FETCH_VENDOR_ID_OPTIONS());
    dispatch(FETCH_PROJECT_ID_OPTIONS());
  }, [dispatch]);

  useEffect(() => {
    initOptions();
  }, [initOptions]);

  return (
    <MainContent>
      <PanelWrapper title = "專案廠商評鑑">
        <DivFlexRow>
          <DivFullWidthWrapper>
            <PSubtitle>專案代號</PSubtitle>
            <DivLongMultiSelector>
              <SelectSearchable
                value = { projectID }
                options = {[
                  { value: '', desc: '請選擇專案代號' },
                  ...projectIDOptions,
                ]}
                changeHandler = { setProjectID }
              />
            </DivLongMultiSelector>
          </DivFullWidthWrapper>
        </DivFlexRow>
        <DivFlexRow>
          <DivButtonsWrapper>
            <SearchButtonSet
              searchable = { projectID !== '' }
              clearHandler = { clearHandler }
              searchHandler = { searchHandler }
            />
          </DivButtonsWrapper>
        </DivFlexRow>
      </PanelWrapper>
      <TableContainer>
        <TableVendorEvaluation
          disablePageSelector = { true }
          disabled = { !userEditable }
          evaluations = { evaluations }
          saveHandler = { updateEvaluations }
          evaluationChangeHandler = { evaluationChangeHandler }
        />
      </TableContainer>
      { showLoadingPopup && <PopupLoading/> }
      {
        showSaveSuccessPopup &&
          <PopupExecuteResult
            title = "儲存成功"
            confirmHandler = { () => { setShowSaveSuccessPopup(false) }}
          />
      }
      {
        showSaveFailPopup &&
          <PopupExecuteResult
            title = { apiFailPopupTitle }
            text = { apiFailPopupMessage }
            confirmHandler = { () => {
              dispatch(CLOSE_SAVE_FAIL_POPUP());
            }}
          />
      }
    </MainContent>
  );
};

export default VendorEvaluation;
