import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import StatusLamp from '@/components/UI/StatusLamp';
import StatusCheck from '@/components/UI/StatusCheck';
import { TdItem, TableRow } from '@/component-style/Table';

import {
  diffTime,
  getDateString,
} from '@/utils/timeUtils';
import { getOptionDesc } from '@/utils/textUtils';

const TrWrapper = styled(TableRow)`
  border-bottom: solid 2px #f2f2f2;
  box-sizing: border-box;
  min-width: 920px;

  &:last-child {
    border-bottom: none;
  }
`;

const Td80 = styled(TdItem)`
  width: 80px;
`;

const Td120 = styled(TdItem)`
  width: 120px;
`;
const Td160 = styled(TdItem)`
  width: 160px;
`;

const Item = ({ project }) => {
  const companyTypeOptions = useSelector(state => state.srpOptions.companyTypeOptions);

  const company = getOptionDesc(companyTypeOptions, project.companyType);

  const invoiceDelay = project.isInvoiced
    || diffTime(project.actionTimeEnd, Date.now(), 'days') <= 0
      ? 0
      : diffTime(project.actionTimeEnd, Date.now(), 'days');

  const paymentDelay = project.isPaid
    || project.predictDate === null
      ? 0
      : diffTime(project.predictDate, Date.now(), 'days');

  return (
    <TrWrapper>
      <Td120>{ company }</Td120>
      <Td120>{ project.projectCode }</Td120>
      <Td160>{ project.projectName }</Td160>
      <Td120>{ getDateString(project.actionTimeStart) }
      </Td120>
      <Td80>
        <StatusLamp
          status = { project.financeStatus }
        />
      </Td80>
      <Td80>
        <StatusCheck
          checked = { project.isQuoted }
        />
      </Td80>
      <Td80>
        <StatusCheck
          checked = { project.isVerified }
        />
      </Td80>
      <Td80>
        <StatusCheck
          checked = { project.isInvoiced }
          value = { invoiceDelay }
        />
      </Td80>
      <Td80>
        <StatusCheck
          checked = { project.isPaid }
          value = { paymentDelay }
        />
      </Td80>
    </TrWrapper>
  )
};

export default Item;
