import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { postToGetUsers } from '@/api/authService';
import { postToGetWorkingHours } from '@/api/attendanceService';
import { getPaycheckStructureByUserId } from '@/api/financeService';

import moment from 'moment';

import {
  FETCH_DIRECTOR_OPTIONS,
  FETCH_DEPARTMENT_OPTIONS,
  FETCH_SYSTEM_ROLE_OPTIONS,
} from '@/store/actions/srpOptionsAction';

import MainContent from '@/component-style/MainContent';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import TableOvertimeByPersonnel from '@/components/Table/Attendance/TableOvertimeByPersonnel';
import PanelOvertimeByPersonnel from '@/components/Panel/PanelOvertimeByPersonnel';

import { searchConfigGenerator } from '@/utils/apiRequestUtils';
// import { map } from 'lodash';
import {
  // diffTimeWithFloat,
  diffTimeSubtractLunchBreak,
} from '@/utils/timeUtils';

import {getHourlySalary,} from '@/utils/mathUtils';

import { exportOvertimePayment } from '@/utils/xlsx.js';

import { workingHoursParams } from '@/constant/dataConfig/sysConfigModule';

import {
  postToGetScheduleTimeConfig,
} from '@/api/attendanceService';

const DivPanelContainer = styled.div`
  margin-bottom: 40px;
`;

const searchConfig = {
  startDate: null,
  endDate: null,
};

const userSearchConfig = {
  // isOverTime: true,
  code: '',
  name: '',
  hasCreditCard: '',
  department: '',
  director: '',
  enable: '',
  systemRole: [],
  projectRole: [],
};

const OvertimeByPersonnel = props => {
  const dispatch = useDispatch();

  const [users, setUsers] = useState([]);

  const [config, setConfig] = useState({ ...searchConfig });
  const [workingHoursParamsConfig, setWorkingHoursParamsConfig] = useState({ ...workingHoursParams });
  const [userConfig, setUserConfig] = useState({ ...userSearchConfig });
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [exportable, setExportable] = useState(false);

  const [showLoadingPopup, setShowLoadingPopup] = useState(false);

  const updateConfigHandler = (key, value) => {
    const updateObj = { ...config };
    key === "startDate" ? setExportable(true) : setExportable(false);
    updateObj[key] = value;
    setConfig({ ...updateObj });
  };

  const clearHandler = () => {
    setConfig({
      ...searchConfig,
    });
    setUsers([]);
    setTotalCount(0);
    setCurrentPage(1);
  };

  const searchHandler = () => {
    setCurrentPageHandler(1);
  };

  const setCurrentPageHandler = page => {
    setCurrentPage(page);
    fetchUsers(page);
  };

  const sortOutOvertimeHours = (workingHours, workingHoursParamsConfig, users, paycheckStructures) => {
    // console.log("sortOutOvertimeHours users:" + JSON.stringify(users));
    // console.log("sortOutOvertimeHours workingHours:" + JSON.stringify(workingHours));
    // console.log("sortOutOvertimeHours workingHoursParamsConfig:" + JSON.stringify(workingHoursParamsConfig));
    var mapResult = new Map();
    workingHours.forEach(function (item, index) {
      // console.log(item, index);
      // console.log("sortOutOvertimeHours item:" + JSON.stringify(item));
      var foundUser = users.find((user) => user.userID === item.userID);
      if (foundUser !== undefined) {
        var userIDObject = mapResult[item.userID];
        if (userIDObject == null) {
          userIDObject = {overtimeHour: 0};
          mapResult[item.userID] = userIDObject;
          userIDObject.overtimeHour = 0;
          userIDObject.overtimeHourWeighted = 0.0;
          userIDObject.overtimePayable = 0.0;
          userIDObject.accumulatedHoursByDate = new Map();
          userIDObject.accumulatedHoursByDate[item.date] = 0;
        }
        if (userIDObject.accumulatedHoursByDate[item.date] == null) {
          userIDObject.accumulatedHoursByDate[item.date] = 0;
        }
        // console.log("sortOutOvertimeHours item.userID:" + item.userID + " date:" + moment(item.startTime).format('YYYY-MM-DD'));
        // console.log("sortOutOvertimeHours item.userID:" + item.userID + " overtimeHour:" + diffTimeWithFloat(item.startTime, item.endTime, 'hours'));
        // mapResult[item.userID].overtimeHour = mapResult[item.userID].overtimeHour + diffTimeWithFloat(item.startTime, item.endTime, 'hours');
        // userIDObject.overtimeHour += diffTimeWithFloat(item.startTime, item.endTime, 'hours');
        // userIDObject.overtimeHour += Math.round(diffTimeWithFloat(item.startTime, item.endTime, 'hours') * 100) / 100;
        // userIDObject.accumulatedHoursByDate[moment(item.startTime).format('YYYY-MM-DD')] += diffTimeSubtractLunchBreak(item.startTime, item.endTime, 'hours');
        // console.log("sortOutOvertimeHours userIDObject:" + JSON.stringify(userIDObject));
        userIDObject.overtimeHour += diffTimeSubtractLunchBreak(item.startTime, item.endTime, 'hours');
        // console.log("sortOutOvertimeHours Math.round(diffTimeSubtractLunchBreak(item.startTime, item.endTime, 'hours' * 100) / 100):" + Math.round(diffTimeSubtractLunchBreak(item.startTime, item.endTime, 'hours') * 100) / 100);
        var overtimeHours = Math.round(diffTimeSubtractLunchBreak(item.startTime, item.endTime, 'hours') * 100) / 100;
        console.log("overtimeHours item.overtimeDateType:" + item.overtimeDateType + " item.startTime:" + item.startTime + "item.endTime:" + item.endTime + "diffTimeSubtractLunchBreak(item.startTime, item.endTime, 'hours'):" + diffTimeSubtractLunchBreak(item.startTime, item.endTime, 'hours'));
        var overtimeWeighted = 0;
        if (item.overtimeDateType === "workdays") {
          // console.log("sortOutOvertimeHours workdays workingHoursParamsConfig.normalDayExtraRate:" + JSON.stringify(workingHoursParamsConfig.normalDayExtraRate));
          // if (overtimeHours >= 2) {
          //   overtimeWeighted += Math.round((overtimeHours - 2) * workingHoursParamsConfig.normalDayExtraRate.overTwoHour * 100) / 100;
          //   overtimeHours -= overtimeHours - 2;
          //   overtimeWeighted += Math.round((overtimeHours) * workingHoursParamsConfig.normalDayExtraRate.firstTwoHour * 100) / 100;
          // } else {
          //   overtimeWeighted += Math.round((overtimeHours) * workingHoursParamsConfig.normalDayExtraRate.firstTwoHour * 100) / 100;
          // }
          if (userIDObject.accumulatedHoursByDate[item.date] + overtimeHours >= 2) {
            if (userIDObject.accumulatedHoursByDate[item.date] > 2) {
              // overtimeWeighted += Math.round(2 * workingHoursParamsConfig.normalDayExtraRate.firstTwoHour * 100) / 100;
              // overtimeHours -= 2;
              overtimeWeighted += Math.round((overtimeHours) * workingHoursParamsConfig.normalDayExtraRate.overTwoHour * 100) / 100;
              // console.log("overtimeWeighted:" + overtimeWeighted);
            } else {
              // console.log("userIDObject.accumulatedHoursByDate[moment(item.startTime).format('YYYY-MM-DD')]:" + userIDObject.accumulatedHoursByDate[moment(item.startTime).format('YYYY-MM-DD')]);
              var leftWithinTwoHours = 2 - userIDObject.accumulatedHoursByDate[item.date];
              // console.log("leftWithinTwoHours:" + leftWithinTwoHours);
              if (overtimeHours < leftWithinTwoHours) {
                // console.log("overtimeWeighted:" + overtimeWeighted);
                overtimeWeighted += Math.round(leftWithinTwoHours * workingHoursParamsConfig.normalDayExtraRate.firstTwoHour * 100) / 100;
                overtimeHours -= leftWithinTwoHours;
                overtimeWeighted += Math.round((overtimeHours) * workingHoursParamsConfig.normalDayExtraRate.overTwoHour * 100) / 100;
              } else {
                overtimeWeighted += Math.round(leftWithinTwoHours * workingHoursParamsConfig.normalDayExtraRate.firstTwoHour * 100) / 100;
                overtimeHours -= leftWithinTwoHours;
                overtimeWeighted += Math.round((overtimeHours) * workingHoursParamsConfig.normalDayExtraRate.overTwoHour * 100) / 100;
              }
            }
          } else {
            overtimeWeighted += Math.round((overtimeHours) * workingHoursParamsConfig.normalDayExtraRate.firstTwoHour * 100) / 100;
            // console.log("overtimeWeighted:" + overtimeWeighted);
          }
          userIDObject.accumulatedHoursByDate[item.date] += diffTimeSubtractLunchBreak(item.startTime, item.endTime, 'hours');
          // console.log("sortOutOvertimeHours overtimeHours:" + overtimeHours);
          // console.log("sortOutOvertimeHours (overtimeHours) * workingHoursParamsConfig.normalDayExtraRate.firstTwoHour * 100 :" + (overtimeHours) * workingHoursParamsConfig.normalDayExtraRate.firstTwoHour * 100);
          // console.log("sortOutOvertimeHours workdays workingHoursParamsConfig.normalDayExtraRate:" + JSON.stringify(workingHoursParamsConfig.normalDayExtraRate) + " overtimeWeighted:" + overtimeWeighted);
          // console.log("sortOutOvertimeHours foundUser:" + JSON.stringify(foundUser));
          // console.log("sortOutOvertimeHours userIDObject.overtimeHourWeighted:" + userIDObject.overtimeHourWeighted + " getHourlySalary(foundUser.basicSalary):" + getHourlySalary(foundUser.basicSalary));
        } else if (item.overtimeDateType === "restdays") {
          // console.log("sortOutOvertimeHours userIDObject.accumulatedHoursByDate[item.date]:" + userIDObject.accumulatedHoursByDate[item.date]);
          console.log("sortOutOvertimeHours userIDObject.accumulatedHoursByDate[item.date]" + userIDObject.accumulatedHoursByDate[item.date] + " overtimeHours:" + overtimeHours);
          if (userIDObject.accumulatedHoursByDate[item.date] + overtimeHours >= 8) {
            if (userIDObject.accumulatedHoursByDate[item.date] >= 2) {
              overtimeWeighted += Math.round((overtimeHours) * workingHoursParamsConfig.restDayExtraRate.overTwoHour * 100) / 100;
              overtimeHours -= 6;
              if (overtimeHours > 0) {
                overtimeWeighted += Math.round((overtimeHours) * workingHoursParamsConfig.restDayExtraRate.overEightHour * 100) / 100;
              }
            } else {
              // console.log("sortOutOvertimeHours overtimeHours:" + overtimeHours);
              if (overtimeHours > 8) {
                overtimeWeighted += Math.round(2 * workingHoursParamsConfig.restDayExtraRate.firstTwoHour * 100) / 100;
                overtimeHours -= 2;
                overtimeWeighted += Math.round(6 * workingHoursParamsConfig.restDayExtraRate.overTwoHour * 100) / 100;
                overtimeHours -= 6;
                if (overtimeHours > 0) {
                  overtimeWeighted += Math.round((overtimeHours) * workingHoursParamsConfig.restDayExtraRate.overEightHour * 100) / 100;
                }
              } else if (overtimeHours > 2) {
                overtimeWeighted += Math.round(2 * workingHoursParamsConfig.restDayExtraRate.firstTwoHour * 100) / 100;
                overtimeHours -= 2;
                overtimeWeighted += Math.round((overtimeHours) * workingHoursParamsConfig.restDayExtraRate.overTwoHour * 100) / 100;
              } else {
                overtimeWeighted += Math.round((overtimeHours) * workingHoursParamsConfig.restDayExtraRate.firstTwoHour * 100) / 100;
              }
            }
          } else if (userIDObject.accumulatedHoursByDate[item.date] + overtimeHours > 2) {
            // console.log("overtimeHours workingHoursParamsConfig.restDayExtraRate.overTwoHour:" + workingHoursParamsConfig.restDayExtraRate.overTwoHour);
            if (userIDObject.accumulatedHoursByDate[item.date] >= 2) {
              if (overtimeHours > 7) {
                overtimeWeighted += Math.round(7 * workingHoursParamsConfig.restDayExtraRate.overTwoHour * 100) / 100;
                overtimeHours -= 7;
                if (overtimeHours > 0) {
                  overtimeWeighted += Math.round((overtimeHours) * workingHoursParamsConfig.restDayExtraRate.overEightHour * 100) / 100;
                }
              } else {
                overtimeWeighted += Math.round((overtimeHours) * workingHoursParamsConfig.restDayExtraRate.overTwoHour * 100) / 100;
              }
            } else {
              if (overtimeHours > 2) {
                // console.log("overtimeHours overtimeHours:" + overtimeHours);
                overtimeWeighted += Math.round(2 * workingHoursParamsConfig.restDayExtraRate.firstTwoHour * 100) / 100;
                // console.log("overtimeHours overtimeWeighted:" + overtimeWeighted);
                overtimeHours -= 2;
                overtimeWeighted += Math.round((overtimeHours) * workingHoursParamsConfig.restDayExtraRate.overTwoHour * 100) / 100;
                // console.log("overtimeHours overtimeWeighted:" + overtimeWeighted);
              } else {
                // console.log("overtimeHours overtimeHours:" + overtimeHours);
                overtimeWeighted += Math.round((overtimeHours) * workingHoursParamsConfig.restDayExtraRate.overTwoHour * 100) / 100;
                // console.log("overtimeHours overtimeWeighted:" + overtimeWeighted);
              }
              // overtimeWeighted += Math.round((overtimeHours - 2) * workingHoursParamsConfig.restDayExtraRate.overTwoHour * 100) / 100;
              // console.log("overtimeHours overtimeWeighted:" + overtimeWeighted);
              // overtimeHours -= overtimeHours - 2;
              // overtimeWeighted += Math.round((overtimeHours) * workingHoursParamsConfig.restDayExtraRate.firstTwoHour * 100) / 100;
              // console.log("overtimeHours overtimeWeighted:" + overtimeWeighted);
            }
          } else {
            overtimeWeighted += Math.round((overtimeHours) * workingHoursParamsConfig.restDayExtraRate.firstTwoHour * 100) / 100;
          }
          // console.log("overtimeHours:" + diffTimeSubtractLunchBreak(item.startTimeLocal, item.endTime, 'hours'));
          // console.log("sortOutOvertimeHours overtimeWeighted:" + overtimeWeighted);
          // console.log("sortOutOvertimeHours item.date:" + item.date);
          userIDObject.accumulatedHoursByDate[item.date] += diffTimeSubtractLunchBreak(item.startTimeLocal, item.endTimeLocal, 'hours');
          // console.log("sortOutOvertimeHours userIDObject.accumulatedHoursByDate[item.date]:" + userIDObject.accumulatedHoursByDate[item.date]);
        } else if (item.overtimeDateType === "nationalHolidays") {
          console.log("sortOutOvertimeHours workdays workingHoursParamsConfig.nationalHolidayExtraRate:" + JSON.stringify(workingHoursParamsConfig.nationalHolidayExtraRate));
          if (workingHoursParamsConfig.nationalHolidayExtraRate.makeItADay === true) {
            // console.log("sortOutOvertimeHours overtimeHours:" + overtimeHours);
            if (overtimeHours <= 8) {
              overtimeWeighted = 8;
            } else {
              if (overtimeHours > 10) {
                overtimeWeighted += Math.round((overtimeHours - 10) * workingHoursParamsConfig.nationalHolidayExtraRate.overTenHour * 100) / 100;
                overtimeHours -= overtimeHours - 10;
                overtimeWeighted += Math.round((overtimeHours - 8) * workingHoursParamsConfig.nationalHolidayExtraRate.overEightHour * 100) / 100;
                overtimeHours -= overtimeHours - 8;
                overtimeWeighted += Math.round((overtimeHours) * workingHoursParamsConfig.nationalHolidayExtraRate.underEightHour * 100) / 100;
              } else {
                overtimeWeighted += Math.round((overtimeHours) * workingHoursParamsConfig.nationalHolidayExtraRate.underEightHour * 100) / 100;
              }
            }
          } else {
            console.log("sortOutOvertimeHours overtimeHours:" + overtimeHours);
            if (overtimeHours >= 12) {
              overtimeWeighted += Math.round((overtimeHours - 12) * workingHoursParamsConfig.nationalHolidayExtraRate.overTwoHour * 100) / 100;
              overtimeHours -= overtimeHours - 12;
              overtimeWeighted += Math.round((overtimeHours - 10) * workingHoursParamsConfig.nationalHolidayExtraRate.firstTwoHour * 100) / 100;
              overtimeHours -= overtimeHours - 10;
              overtimeWeighted += Math.round((overtimeHours) * workingHoursParamsConfig.nationalHolidayExtraRate.underEightHour * 100) / 100;
            } else if (overtimeHours >= 10) {
              overtimeWeighted += Math.round((overtimeHours - 10) * workingHoursParamsConfig.nationalHolidayExtraRate.firstTwoHour * 100) / 100;
              overtimeHours -= overtimeHours - 10;
              overtimeWeighted += Math.round((overtimeHours) * workingHoursParamsConfig.nationalHolidayExtraRate.underEightHour * 100) / 100;
            } else {
              overtimeWeighted += Math.round((overtimeHours) * workingHoursParamsConfig.nationalHolidayExtraRate.underEightHour * 100) / 100;
            }
          }
        } else if (item.overtimeDateType === "holidays") {//例假日
          if (workingHoursParamsConfig.holidayExtraRate.makeItADay === true) {
            if (overtimeHours <= 8) {
              overtimeWeighted = 8;
            } else {
              // console.log("sortOutOvertimeHours overtimeHours:" + overtimeHours + "  JSON.stringify(workingHoursParamsConfig.holidayExtraRate):" + JSON.stringify(workingHoursParamsConfig.holidayExtraRate));
              if (overtimeHours >= 12) {
                overtimeWeighted += Math.round((overtimeHours - 10) * workingHoursParamsConfig.holidayExtraRate.overTenHour * 100) / 100;
                overtimeHours = overtimeHours - (overtimeHours - 10);
                // console.log("sortOutOvertimeHours overtimeWeighted:" + overtimeWeighted + " overtimeHours:" + overtimeHours );
                overtimeWeighted += Math.round((overtimeHours - 8) * workingHoursParamsConfig.holidayExtraRate.overEightHour * 100) / 100;
                overtimeHours = overtimeHours - (overtimeHours - 8);
                // console.log("sortOutOvertimeHours overtimeWeighted:" + overtimeWeighted + " overtimeHours:" + overtimeHours );
                overtimeWeighted += Math.round((overtimeHours) * workingHoursParamsConfig.holidayExtraRate.underEightHour * 100) / 100;
              } else if (overtimeHours >= 10) {
                overtimeWeighted += Math.round((overtimeHours - 10) * workingHoursParamsConfig.holidayExtraRate.overEightHour * 100) / 100;
                overtimeHours -= overtimeHours - 10;
                overtimeWeighted += Math.round((overtimeHours) * workingHoursParamsConfig.holidayExtraRate.underEightHour * 100) / 100;
              } else {
                overtimeWeighted += Math.round((overtimeHours) * workingHoursParamsConfig.holidayExtraRate.underEightHour * 100) / 100;
              }
            }
          } else {
            if (overtimeHours >= 12) {
              overtimeWeighted += Math.round((overtimeHours - 12) * workingHoursParamsConfig.holidayExtraRate.overTwoHour * 100) / 100;
              overtimeHours -= overtimeHours - 12;
              overtimeWeighted += Math.round((overtimeHours - 10) * workingHoursParamsConfig.holidayExtraRate.firstTwoHour * 100) / 100;
              overtimeHours -= overtimeHours - 10;
              overtimeWeighted += Math.round((overtimeHours) * workingHoursParamsConfig.holidayExtraRate.underEightHour * 100) / 100;
            } else if (overtimeHours >= 10) {
              overtimeWeighted += Math.round((overtimeHours - 10) * workingHoursParamsConfig.holidayExtraRate.firstTwoHour * 100) / 100;
              overtimeHours -= overtimeHours - 10;
              overtimeWeighted += Math.round((overtimeHours) * workingHoursParamsConfig.holidayExtraRate.underEightHour * 100) / 100;
            } else {
              overtimeWeighted += Math.round((overtimeHours) * workingHoursParamsConfig.holidayExtraRate.underEightHour * 100) / 100;
            }
          }
        }
        // console.log("sortOutOvertimeHours userIDObject.overtimeHourWeighted:" + userIDObject.overtimeHourWeighted + " getHourlySalary(foundUser.basicSalary):" + getHourlySalary(foundUser.basicSalary));
        // console.log("overtimeWeighted:" + overtimeWeighted);
        // console.log("userIDObject.overtimeHourWeighted:" + userIDObject.overtimeHourWeighted);
        userIDObject.overtimeHourWeighted += Math.round(overtimeWeighted * 100) / 100;
        // console.log("userIDObject.overtimeHourWeighted:" + userIDObject.overtimeHourWeighted);
        userIDObject.overtimePayable += Math.round(overtimeWeighted * getHourlySalary(getBasicSalary(item.userID, paycheckStructures)));
        // userIDObject.overtimePayable += Math.round(overtimeWeighted * getHourlySalary(foundUser.basicSalary));
        // console.log("sortOutOvertimeHours mapResult[item.userID].overtimeHour:" + mapResult[item.userID].overtimeHour);
      }
    });
    for (const [key] of Object.entries(mapResult)) {
      mapResult[key].overtimePayable = Math.ceil(mapResult[key].overtimePayable);
    }
    return mapResult;
  };

  const exportHandler = async () => {
    setShowLoadingPopup(true);
    
    const arrayOvertimeData = [];
    try {
      const dataWorkingHours = await postToGetWorkingHours(
        searchConfigGenerator({
          ...config,
          amount: 99999,
          page: 1,
          startDate: moment(config.startDate).startOf('month').format('YYYY-MM-DD'),
          endDate: moment(config.startDate).endOf('month').format('YYYY-MM-DD'),
          approvementStatus: "approved",
          overtimeType: "extraPay",
        })
      );
      // console.log("exportHandler dataWorkingHours:" + JSON.stringify(dataWorkingHours));
      const workingHours = dataWorkingHours.data.workingHours;
      // console.log("exportHandler workingHours:" + JSON.stringify(workingHours));
      // console.log("exportHandler mapOverTime:" + JSON.stringify(mapOverTime));

      const { data } = await postToGetUsers(
        searchConfigGenerator({
          ...userConfig, page: 1, amount: 1000,
        })
      )

      const userIds = data.users.map( obj=> obj.userID);
      console.log(`fetchUsers JSON.stringify(userIds)=${JSON.stringify(userIds)}`);
      const searchParameters = {
        userIDs: userIds,
        asOfDate: moment(config.startDate).startOf('month').format('YYYY-MM-DD'),
      };
      // try {
        const paycheckStructures = await getPaycheckStructureByUserId(searchParameters);
        console.log(`fetchUsers JSON.stringify(paycheckStructures)=${JSON.stringify(paycheckStructures)}`);
      // } catch (err) {
      // }
      
      let { users } = data;
      let mapOverTime = await sortOutOvertimeHours(workingHours, workingHoursParamsConfig, users, paycheckStructures);
      //add basic salary
      users = users.map(obj=> (
        { 
          ...obj, 
          hourlySalary: getHourlySalary(getBasicSalary(obj.userID, paycheckStructures)),
          // hourlySalary: getHourlySalary(obj.basicSalary),
          overtimeHour: (mapOverTime[obj.userID]) ? mapOverTime[obj.userID]?.overtimeHour : 0,
          overtimeHourWeighted: (mapOverTime[obj.userID]) ? mapOverTime[obj.userID]?.overtimeHourWeighted : 0,
          overtimePayable: (mapOverTime[obj.userID]) ? mapOverTime[obj.userID]?.overtimePayable : 0,
        }
      ))
      //add basic salary

      for (let i = 0; i < users.length; i += 1) {
        if (users[i].overtimeHour > 0) {
          arrayOvertimeData.push([
            users[i].name,
            users[i].hourlySalary,
            users[i].overtimeHour,
            users[i].overtimeHourWeighted,
            users[i].overtimePayable,
          ]);
        }
      }
      console.log("exportHandler arrayOvertimeData:" + JSON.stringify(arrayOvertimeData));
    } catch(err) { console.log(err) }


    setShowLoadingPopup(false);
    exportOvertimePayment(arrayOvertimeData);
  };

  const fetchUsers = async (page = currentPage) => {
    setShowLoadingPopup(true);

    try {
      const dataWorkingHours = await postToGetWorkingHours(
        searchConfigGenerator({
          ...config,
          amount: 99999,
          page: 1,
          approvementStatus: "approved",
          overtimeType: "extraPay",
          startDate: moment(config.startDate).startOf('month').format('YYYY-MM-DD'),
          endDate: moment(config.startDate).endOf('month').format('YYYY-MM-DD'),
        })
      );
      // console.log("fetchUsers dataWorkingHours:" + JSON.stringify(dataWorkingHours));
      const workingHours = dataWorkingHours.data.workingHours;
      // console.log("fetchUsers workingHours:" + JSON.stringify(workingHours));
      // console.log("fetchUsers workingHoursParamsConfig:" + JSON.stringify(workingHoursParamsConfig));
      // console.log("fetchUsers mapOverTime:" + JSON.stringify(mapOverTime));

      const { data } = await postToGetUsers(
        searchConfigGenerator({
          ...userConfig, page, amount: 10,
        })
      );

      const userIds = data.users.map( obj=> obj.userID);
      console.log(`fetchUsers JSON.stringify(userIds)=${JSON.stringify(userIds)}`);
      const searchParameters = {
        userIDs: userIds,
        asOfDate: moment(config.startDate).startOf('month').format('YYYY-MM-DD'),
      };
      // try {
        const paycheckStructures = await getPaycheckStructureByUserId(searchParameters);
        console.log(`fetchUsers JSON.stringify(paycheckStructures)=${JSON.stringify(paycheckStructures)}`);
      // } catch (err) {
      // }

      let { users, totalCount } = data;
      let mapOverTime = await sortOutOvertimeHours(workingHours, workingHoursParamsConfig, users, paycheckStructures);
      console.log(`fetchUsers JSON.stringify(users)=${JSON.stringify(users)}`);
      //insert basicSalary
      users = users.map(obj=> ({
          ...obj,
          basicSalary: getBasicSalary(obj.userID, paycheckStructures),
        }
      ));
      console.log(`fetchUsers JSON.stringify(users)=${JSON.stringify(users)}`);
      //insert basicSalary
      //add basic salary
      users = users.map(obj=> (
        { 
          ...obj, 
          hourlySalary: getHourlySalary(obj.basicSalary),
          overtimeHour: (mapOverTime[obj.userID]) ? mapOverTime[obj.userID]?.overtimeHour : 0,
          overtimeHourWeighted: (mapOverTime[obj.userID]) ? mapOverTime[obj.userID]?.overtimeHourWeighted : 0,
          overtimePayable: (mapOverTime[obj.userID]) ? mapOverTime[obj.userID]?.overtimePayable : 0,
        }
      ))
      //add basic salary
      // console.log("fetchUsers users:" + JSON.stringify(users));
      setUsers([...users]);
      setTotalCount(totalCount|| 0);
    } catch(err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const getBasicSalary = (userId, paycheckStructures) => {
    var basicSalary = 0;
    for(let el of paycheckStructures.data.results) {
      if (userId === el.userId) {
        basicSalary = el.paycheck.basicSalary;
      }
    }
    return basicSalary;
  };

  // const initUsers = useCallback(fetchUsers, []);

  const initOptions = useCallback(() => {
    dispatch(FETCH_DIRECTOR_OPTIONS());
    dispatch(FETCH_DEPARTMENT_OPTIONS());
    dispatch(FETCH_SYSTEM_ROLE_OPTIONS());
  }, [dispatch]);

  useEffect(() => {
    // initUsers();
    initOptions();
  }, [initOptions]);
  // }, [initOptions, initUsers]);

  const fetchScheduleTimeConfig = async () => {
    // console.log("fetchScheduleTimeConfig");
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetScheduleTimeConfig();
      const {
        normalDayExtraRate,
        restDayExtraRate,
        holidayExtraRate,
        nationalHolidayExtraRate,
        overTimeTotalLimit,
        ...remainItems
      } = data;

      setWorkingHoursParamsConfig({
        ...workingHoursParams,
        ...remainItems,
        normalDayExtraRate: {
          ...workingHoursParams.normalDayExtraRate,
          ...normalDayExtraRate,
        },
        restDayExtraRate: {
          ...workingHoursParams.restDayExtraRate,
          ...restDayExtraRate,
        },
        holidayExtraRate: {
          ...workingHoursParams.holidayExtraRate,
          ...holidayExtraRate,
        },
        nationalHolidayExtraRate: {
          ...workingHoursParams.nationalHolidayExtraRate,
          ...nationalHolidayExtraRate,
        },
        overTimeTotalLimit: {
          ...workingHoursParams.overTimeTotalLimit,
          ...overTimeTotalLimit,
        },
      });

    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  useEffect(() => {
    fetchScheduleTimeConfig();
  }, []);

  return (
    <MainContent>
      {<DivPanelContainer>
        <PanelOvertimeByPersonnel
          config = { config }
          updateConfigHandler = { updateConfigHandler }
          clearHandler = { clearHandler }
          searchHandler = { searchHandler }
          exportable = { exportable }
          exportHandler = { exportHandler }
        />
      </DivPanelContainer>}
      <TableOvertimeByPersonnel
        users = { users }
        startDate = { moment(config.startDate).startOf('month').format('YYYY-MM-DD') }
        endDate = { moment(config.startDate).endOf('month').format('YYYY-MM-DD') }
        totalCount = { totalCount }
        currentPage = { currentPage }
        pageChangedHandler = { setCurrentPageHandler }
      />
      { showLoadingPopup && <PopupLoading/> }
    </MainContent>
  );
};

export default OvertimeByPersonnel;
