import React from 'react';
import styled from 'styled-components';

import SearchButtonSet from '@/components/UI/SearchButtonSet';
import PanelWrapper from '@/components/UI/PanelWrapper';
import DatePeriodSelector from '@/components/UI/DatePeriodSelector';
import SelectSearchable from '@/components/UI/Select/SelectSearchable';
import SelectNormal from '@/components/UI/Select/SelectNormal';
import { InputBasic } from '@/component-style/Input';
import { PSubtitle } from '@/component-style/TextElement';
import {
  DivFlexRow,
  DivItemWrapper,
  DivLongMultiSelector,
} from '@/component-style/RWDWrapper';

import { formatTime } from '@/utils/timeUtils';

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding: 0;
  justify-content: flex-end;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const PanelProjectSearch = props => {
  const { config } = props

  const updateStartTimeHandler = value => {
    props.updateConfigHandler(
      'startTime',
      formatTime(value, 'YYYY-MM-DD')
    );
  };

  const updateEndTimeHandler = value => {
    props.updateConfigHandler(
      'endTime',
      formatTime(value, 'YYYY-MM-DD')
    );
  };

  return(
    <PanelWrapper
      title = "專案搜尋"
    >
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>專案代號</PSubtitle>
          <SelectSearchable
            value = { config.projectCode }
            placeholder = "請選擇專案代號"
            options = {[
              { value: '', desc: '請選擇專案' },
              ...props.projectCodeOptions,
            ]}
            changeHandler = { value => {
              props.updateConfigHandler('projectCode', value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>專案名稱</PSubtitle>
          <InputBasic
            placeholder = "請填入專案名稱"
            value = { config.projectName }
            onChange = { ({ target }) => {
              props.updateConfigHandler('projectName', target.value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>客戶名稱</PSubtitle>
          <SelectSearchable
            value = { config.customer }
            options = {[
              { value: '', desc: '請選擇客戶' },
              ...props.customerOptions,
            ]}
            changeHandler = { value => {
              props.updateConfigHandler('customer', value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>專案型態</PSubtitle>
          <SelectNormal
            value = { config.projectType }
            options = {[
              { value: '', desc: '請選擇專案類型' },
              ...props.projectTypeOptions,
            ]}
            changeHandler = { value => {
              props.updateConfigHandler('projectType', value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>活動日期</PSubtitle>
          <DivLongMultiSelector>
            <DatePeriodSelector
              endTime = { config['actionTimeStart'].end }
              startTime = { config['actionTimeStart'].start }
              endTimeOKHandler = { updateEndTimeHandler }
              endTimeChangeHandler = { updateEndTimeHandler }
              startTimeOKHandler = { updateStartTimeHandler }
              startTimeChangeHandler = { updateStartTimeHandler }
            />
          </DivLongMultiSelector>
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivButtonsWrapper>
          <SearchButtonSet
            clearHandler = { props.clearHandler }
            searchHandler = { props.searchHandler }
          />
        </DivButtonsWrapper>
      </DivFlexRow>
    </PanelWrapper>
  );
};

export default PanelProjectSearch;
