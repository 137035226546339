import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { cloneDeep } from 'lodash';

import { postToGetProjectDetail } from '@/api/projectService';
import { postToGetCustomerDetail } from '@/api/customerService';
import { postToGetProjectInvoices } from '@/api/financeService';

import {
  FETCH_PROJECT_ID_OPTIONS,
  FETCH_COMPANY_TYPE_OPTIONS,
} from '@/store/actions/srpOptionsAction';

import PanelCostInvoiceSearch from '@/components/Panel/Finance/PanelCostInvoiceSearch';
import TableCostInvoices from '@/components/Table/Finance/TableCostInvoices';
import PopupLoading from '@/components/UI/Popup/PopupLoading';

import { formatTime } from '@/utils/timeUtils';
import { integerConverter } from '@/utils/textUtils';
import { searchConfigGenerator } from '@/utils/apiRequestUtils';
import { exportProjectInvoicesFile } from '@/utils/xlsx';

const DivPanelContainer = styled.div`
  margin-bottom: 40px;
`;

const searchConfig = {
  projectID: '',
  invoicePrefix: '',
  invoiceCode: {
    start: '',
    end: '',
  },
  createDate: {
    start: null,
    end: null,
  },
  accountingExported: '',
  companyType: '',
  amount: 10,
};

const CostInvoiceSearch = () => {
  const dispatch = useDispatch();
  const projectIDOptions = useSelector(state => state.srpOptions.projectIDOptions);
  const companyTypeOptions = useSelector(state => state.srpOptions.companyTypeOptions);

  const [invoices, setInvoices] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [config, setConfig] = useState(cloneDeep(searchConfig));

  const [showLoadingPopup, setShowLoadingPopup] = useState(false);

  const updateConfigHandler = (key, value) => {
    const updateObj = { ...config };
    updateObj[key] = value;
    setConfig({ ...updateObj });
  };

  const clearHandler = () => {
    setInvoices([]);
    setTotalCount(0);
    setConfig(cloneDeep(searchConfig));
  };

  const searchHandler = async () => {
    setCurrentPageHandler(1);
  };

  const setCurrentPageHandler = page => {
    setCurrentPage(page);
    fetchProjectInvoices(page);
  };

  const exportHandler = async () => {
    setShowLoadingPopup(true);
    const invoicesData = [];

    try {
      const { data } = await postToGetProjectInvoices(
        searchConfigGenerator({
          ...config,
          createDate: {
            end: formatTime(config.createDate.end, 'YYYY-MM-DD'),
            start: formatTime(config.createDate.start, 'YYYY-MM-DD'),
          },
          invoiceCode: {
            start: config.invoiceCode.start
              ? integerConverter(config.invoiceCode.start) : null,
            end: config.invoiceCode.end
              ? integerConverter(config.invoiceCode.end) : null,
          },
          page: 1,
          amount: totalCount || 1,
        })
      );

      const { invoices } = data;
      for (let i = 0; i < invoices.length; i += 1) {
        const invoice = invoices[i];
        const { projectID } = invoice;
        const projectDetail = await postToGetProjectDetail(projectID);
        const { customer, projectCode } = projectDetail.data;
        const customerDetail = await postToGetCustomerDetail(customer);
        const { customerName, customerTaxId } = customerDetail.data;
        invoicesData.push([
          customerTaxId || '',
          customerName || '',
          projectCode || '',
          invoice.invoiceCode || '',
          formatTime(invoice.createDate, 'YYYY/MM/DD') || '',
          invoice.untaxedAmount || 0,
          invoice.tax || 0,
          invoice.amount || 0,
          invoice.accountingExported ? '是' : '否'
        ]);
      }

    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);    
    exportProjectInvoicesFile(invoicesData);
  };

  const fetchProjectInvoices = async (page = currentPage) => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetProjectInvoices(
        searchConfigGenerator({
          ...config,
          createDate: {
            end: formatTime(config.createDate.end, 'YYYY-MM-DD'),
            start: formatTime(config.createDate.start, 'YYYY-MM-DD'),
          },
          invoiceCode: {
            start: config.invoiceCode.start
              ? integerConverter(config.invoiceCode.start) : null,
            end: config.invoiceCode.end
              ? integerConverter(config.invoiceCode.end) : null,
          },
          page,
        })
      );

      const { invoices, totalCount } = data;

      for (let i = 0; i < invoices.length; i += 1) {
        const invoice = invoices[i];
        const { projectID } = invoice;
        const projectDetail = await postToGetProjectDetail(projectID);
        const { customer, projectCode } = projectDetail.data;
        invoice.projectCode = projectCode;
        console.log("costInvoiceSearch.js fetchProjectInvoices() customer:" + customer);
        if (customer === '7XE2TYYZS7N6NPYBR1ETM122JB') {
          invoice.customerName = "";
          invoice.customerTaxId = "";
        } else {
          const customerDetail = await postToGetCustomerDetail(customer);
          const { customerName, customerTaxId } = customerDetail.data;
          invoice.customerName = customerName;
          invoice.customerTaxId = customerTaxId;
        }
      }

      setInvoices([...invoices]);
      setTotalCount(totalCount || 0);
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const initOptions = useCallback(() => {
    dispatch(FETCH_PROJECT_ID_OPTIONS());
    dispatch(FETCH_COMPANY_TYPE_OPTIONS());
  }, [dispatch]);

  useEffect(() => {
    initOptions();
  }, [initOptions]);

  return(
    <>
      <DivPanelContainer>
        <PanelCostInvoiceSearch
          config = { config }
          projectIDOptions = { projectIDOptions }
          companyTypeOptions = { companyTypeOptions }
          clearHandler = { clearHandler }
          exportHandler = { exportHandler }
          searchHandler = { searchHandler }
          updateConfigHandler = { updateConfigHandler }
        />
      </DivPanelContainer>
      <TableCostInvoices
        invoices = { invoices }
        totalCount = { totalCount }
        currentPage = { currentPage }
        pageChangedHandler = { setCurrentPageHandler }
      />
      { showLoadingPopup && <PopupLoading/> }
    </>
  );
};

export default CostInvoiceSearch;
