import React from 'react';
import styled from 'styled-components';

import RadioGroup from '@/components/UI/Radio/RadioGroup';
import SelectMultiple from '@/components/UI/Select/SelectMultiple';
import { InputBasic } from '@/component-style/Input';
import { DivPopupWrapper, DivPopup } from '@/component-style/Popup';
import { ButtonBasic, ButtonCancel } from '@/component-style/Button';
import { DivFlexRow } from '@/component-style/RWDWrapper';

const DivSelectWrapper = styled(DivFlexRow)`
  padding: 0;
  margin-bottom: 10px;

  & > div:first-of-type,
  .ant-select {
    width: 100%;
  }
`;

const DivRadioWrapper = styled(DivSelectWrapper)`
  flex-wrap: nowrap;
`;

const DivButtonsWrapper = styled.div`
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;


const H3Title = styled.h3`
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: #8b8b8b;
  margin-bottom: 10px;
`;

const PTexts = styled.p`
  min-width: 110px;
  font-size: 16px;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0.43px;
  color: #8b8b8b;

  span { color: #de2027 }
`;

const PStatus = styled(PTexts)`
  min-width: 80px;
`;

const ButtonConfirm = styled(ButtonBasic)`
  width: 120px;
`;

const ButtonDiscard = styled(ButtonCancel)`
  width: 120px;
  margin-right: 10px;
`;

const PopupTodoTypeDetail = props => {
  const { type } = props;

  return (
    <DivPopupWrapper>
      <DivPopup>
        <H3Title>{ props.title }</H3Title>
        <DivSelectWrapper>
          <PTexts>分類名稱<span>*</span></PTexts>
          <InputBasic
            disabled = { props.disableName }
            value = { type.name }
            onChange = { ({ target }) => {
              props.updateTypeDetailHandler('name', target.value)
            }}
          />
        </DivSelectWrapper>
        <DivSelectWrapper>
          <PTexts>英文名稱</PTexts>
          <InputBasic
            value = { type.englishName }
            onChange = { ({ target }) => {
              props.updateTypeDetailHandler('englishName', target.value)
            }}
          />
        </DivSelectWrapper>
        <DivSelectWrapper>
          <PTexts>備註說明</PTexts>
          <InputBasic
            value = { type.note }
            onChange = { ({ target }) => {
              props.updateTypeDetailHandler('note', target.value)
            }}
          />
        </DivSelectWrapper>
        <DivSelectWrapper>
          <PTexts>專案型態</PTexts>
          <SelectMultiple
            value = { type.projectType }
            options = { props.projectTypeOptions }
            changeHandler = { value => {
              props.updateTypeDetailHandler('projectType', value)
            }}
          />
        </DivSelectWrapper>
        <DivRadioWrapper>
          <PStatus>狀態</PStatus>
          <RadioGroup
            value = { type.enable }
            options = {[
              { value: true, desc: '啟動' },
              { value: false, desc: '停用' },
            ]}
            changeHandler = { value => {
              props.updateTypeDetailHandler('enable', value)
            }}
          />
        </DivRadioWrapper>
        <DivButtonsWrapper>
          <ButtonDiscard
            onClick = { props.cancelHandler }
          >關閉
          </ButtonDiscard>
          <ButtonConfirm
            disabled = { type.name === '' || props.disableSave }
            onClick = { props.confirmHandler }
          >儲存
          </ButtonConfirm>
        </DivButtonsWrapper>
      </DivPopup>
    </DivPopupWrapper>
  );
};

export default PopupTodoTypeDetail;
