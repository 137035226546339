import React from 'react';
import styled from 'styled-components';

import TableWrapper from '@/components/UI/TableWrapper';
import { InputBasic } from '@/component-style/Input';
import { PSubtitle } from '@/component-style/TextElement';
import { ButtonBasic } from '@/component-style/Button';
import {
  DivFlexRow,
  DivItemWrapper,
} from '@/component-style/RWDWrapper';

import { currencyConverter } from '@/utils/textUtils';

import MyInputNumber from '@/components/UI/MyInputNumber';

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding: 0;
  padding-bottom: 30px;
  justify-content: space-between;

  @media (max-width: 425px) {
    justify-content: center;
  }
`;

const ButtonSave = styled(ButtonBasic)`
  width: 120px;

  &:first-child { margin-right: 10px }
`;

const PanelProjectQuotation = props => {

  return (
    <TableWrapper title = "專案報價資訊">
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>客戶採購編號PO</PSubtitle>
          <ButtonSave
            disabled = { props.disableAttachment }
            onClick = { props.openAttachmentPopup }
          >採購附件管理</ButtonSave>
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>廠商報價總金額</PSubtitle>
          <MyInputNumber
            disabled = { true }
            value = { currencyConverter(props.quotationPriceTotal) }
          />
          {/* <InputBasic
            disabled = { true }
            value = { currencyConverter(props.quotationPriceTotal) }
          /> */}
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>優惠價</PSubtitle>
          <MyInputNumber
            disabled = { props.disabled }
            value = { props.specialPrice }
            onChange = { value => {
              props.specialPriceChangeHandler(value);
            }}
          />
          {/* <InputBasic
            type = "number"
            disabled = { props.disabled }
            value = { props.specialPrice }
            onChange = { ({ target }) => {
              props.specialPriceChangeHandler(target.value);
            }}
          /> */}
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>預估專案總金額</PSubtitle>
          <MyInputNumber
            disabled = { true }
            value = { props.prQuotedPriceTotal + props.oopQuotedPriceTotal }
          />
          {/* <InputBasic
            disabled = { true }
            value = { currencyConverter(props.prQuotedPriceTotal + props.oopQuotedPriceTotal) }
          /> */}
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>議價後專案總金額</PSubtitle>
          <MyInputNumber
            disabled = { true }
            value = { props.prPriceTotal + props.oopPriceTotal }
          />
          {/* <InputBasic
            disabled = { true }
            value = { currencyConverter(props.prPriceTotal + props.oopPriceTotal) }
          /> */}
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>預估專案支出費用</PSubtitle>
          <MyInputNumber
            disabled = { true }
            value = { props.oopQuotedPriceTotal }
          />
          {/* <InputBasic
            disabled = { true }
            value = { currencyConverter(props.oopQuotedPriceTotal) }
          /> */}
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>議價後專案支出費用</PSubtitle>
          <MyInputNumber
            disabled = { true }
            value = { props.oopPriceTotal }
          />
          {/* <InputBasic
            disabled = { true }
            value = { currencyConverter(props.oopPriceTotal) }
          /> */}
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>預估服務費收入</PSubtitle>
          <MyInputNumber
            disabled = { true }
            value = { props.prQuotedPriceTotal }
          />
          {/* <InputBasic
            disabled = { true }
            value = { currencyConverter(props.prQuotedPriceTotal) }
          /> */}
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>議價後服務費收入</PSubtitle>
          <MyInputNumber
            disabled = { true }
            value = { props.prPriceTotal }
          />
          {/* <InputBasic
            disabled = { true }
            value = { currencyConverter(props.prPriceTotal) }
          /> */}
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivButtonsWrapper>
          <ButtonSave
            disabled = { props.disabled || !props.userExportable }
            onClick = { props.openExportPopup }
          >匯出功能</ButtonSave>
          <ButtonSave
            disabled = { props.disabled || !props.userEditable }
            onClick = { props.saveSpecialPriceHandler }
          >儲存</ButtonSave>
        </DivButtonsWrapper>
      </DivFlexRow>
    </TableWrapper>
  );
};

export default PanelProjectQuotation;
