import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from "react-router-dom";
import queryString from 'query-string';
import styled from 'styled-components';
import { cloneDeep } from 'lodash';

import { postToGetProjectDetail } from '@/api/projectService';
// import { postToGetUploadFiles } from '@/api/storageService';
// import { postToGetProjectQuotations } from '@/api/financeService';
import { postToGetMediaDetail } from '@/api/mediaService';

import {
  putNewInvitation,
  deleteInvitations,
  postToGetInvitations,
  patchModifyInvitation,
  postToGetJournalistIDs,
  postToGetJournalistDetail,
  postToGetInvitationStatus,
  postToGetProjectJournalistIDs,
} from '@/api/journalistService';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import {
  FETCH_MEDIA_TYPES,
  FETCH_JOURNALIST_TYPES,
  FETCH_PROJECT_ID_OPTIONS,
  FETCH_JOURNALIST_ID_OPTIONS,
} from '@/store/actions/srpOptionsAction';

import MainContent from '@/component-style/MainContent';
import PanelInvitationSearch from '@/components/Panel/Project/PanelInvitationSearch';
import TableInvitations from '@/components/Table/TableInvitations';
import TableInvitationManagement from '@/components/Table/Project/TableInvitationManagement';
import TableInvitationReporters from '@/components/Table/TableInvitationReporters';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import PopupExecuteConfirm from '@/components/UI/Popup/PopupExecuteConfirm';
import PopupInformReporter from '@/components/UI/Popup/PopupInformReporter';
import PopupReporterInvitation from '@/components/UI/Popup/PopupReporterInvitation';
import PopupExportMethodSelect from '@/components/UI/Popup/PopupExportMethodSelect';
import PopupInvitationReporterStatus from '@/components/UI/Popup/PopupInvitationReporterStatus';
import TableInvitationReportersTwo from '@/components/Table/TableInvitationReportersTwo';

import {
  invitationConfig,
  invitationStatusConfig,
} from '@/constant/dataConfig/informationModule';

import {
  getPhoneString,
  getAddressString,
} from '@/utils/textUtils';

import {
  exportInvitationFile,
  exportInvitationFaxFile, 
} from '@/utils/xlsx';

import { invitationConfirmStatus } from '@/constant/options';
import { searchConfigGenerator } from '@/utils/apiRequestUtils';
// import { diffTime } from '@/utils/timeUtils';

const TableContainer = styled.div`
  margin-top: 40px;
`;

const exportMethodOptions = [
  { value: 'normal', desc: '一般匯出' },
  { value: 'fax', desc: '傳真檔案匯出' },
];

const sendMessageConfig = {
  method: 'email',
  all: false,
  mediaTypes: [],
  journalists: [],
  withInviation: false,
};

const ReporterInvitation = props => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const id = queryString.parse(location.search).id;
  const mediaTypes = useSelector(state => state.srpOptions.mediaTypeOptions);
  const projectRoutes = useSelector(state => state.login.projectRoutes);
  const projectIDList = useSelector(state => state.srpOptions.projectIDOptions);
  const journalistTypes = useSelector(state => state.srpOptions.journalistTypeOptions);
  const journalistIDList = useSelector(state => state.srpOptions.journalistIDOptions);
  const showSaveFailPopup = useSelector(state => state.apiFail.showSaveFailPopup);
  const apiFailPopupTitle = useSelector(state => state.apiFail.title);
  const apiFailPopupMessage = useSelector(state => state.apiFail.message);
  
  const [journalists, setJournalists] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0,showSizeChanger:false,  });


  const [journalistOptions, setJournalistOptions] = useState([]);

  const [sendConfig, setSendConfig] = useState(cloneDeep(sendMessageConfig));

  const [searchConfig, setSearchConfig] = useState({
    journalistID: '',
    confirmStatus: '',
    job: '',
    amount: 10,
  }); 
  
  const [projectInfo, setProjectInfo] = useState({
    projectID: id || '',
    projectCode: '',
    projectName: '',
  });

  const [updateStatus, setUpdateStatus] = useState({
    confirmStatus: '',
    statusDesc: '',
    job: '',
    attend: false,
  });

  const [importConfig, setImportConfig] = useState({
    importMethod: 'project',
    projectID: '',
    type: [],
  });

  const [invitation, setInvitation] = useState({ ...invitationConfig });
  const [invitationStatus, setInvitationStatus] = useState({ ...invitationStatusConfig });
  
  const [selectAll, setSelectAll] = useState(false);
  const [updateItemIndex, setUpdateItemIndex] = useState(0);
  const [exportMethod, setExportMethod] = useState('normal');
  const [showInformPopup, setShowInformPopup] = useState(false);
  const [isAddingReporter, setIsAddingReporter] = useState(false);
  // const [alertText, setAlertText] = useState('');
  // const [showAlertPopup, setShowAlertPopup] = useState(false);
  const [showExportPopup, setShowExportPopup] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [showSaveSuccessPopup, setShowSaveSuccessPopup] = useState(false);
  const [showDeleteSuccessPopup, setShowDeleteSuccessPopup] = useState(false);
  const [showImportSuccessPopup, setShowImportSuccessPopup] = useState(false);
  const [showReporterStatusPopup, setShowReporterStatusPopup] = useState(false);
  const [showImportReporterPopup, setShowImportReporterPopup] = useState(false);

  const userEditable = projectRoutes.indexOf('ReporterInvitation-Edit') !== -1;
  const userSendable = projectRoutes.indexOf('ReporterInvitation-Massage') !== -1;
  const userExportable = projectRoutes.indexOf('ReporterInvitation-Export') !== -1;

  const importableProjects = projectIDList
    .filter(({ value }) => value !== projectInfo.projectID )
  
  const selectedItemNumber = journalists
    .filter(journalist => journalist.isChecked).length;

  const updateSendConfigHander = config => {
    setSendConfig(prevState => ({
      ...prevState,
      ...config,
    }))
  };

  const sendMessageHandler = () => {
    const { method, mediaTypes  } = sendConfig;
    const reporterlist = sendConfig.journalists.length === 0
      ? journalistOptions
      : journalistOptions
          .filter(({ value }) => sendConfig.journalists.indexOf(value) !== -1 );

    const selectedReporters = reporterlist
      .filter(({ mediaType }) => mediaTypes.length === 0
        || mediaTypes.indexOf(mediaType) !== -1);

    method === 'email'
      ? sendMessageByEmailHandler(selectedReporters)
      : sendMessageBySMSHandler(selectedReporters);
  };

  const sendMessageByEmailHandler = journalists => {
    const mails = journalists.map(({ mail }) => mail);
    const link = document.createElement('a');
    link.href = `mailto:?bcc=${ mails.join(',') }`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const sendMessageBySMSHandler = journalists => {};

  const updateConfigHandler = (key, value) => {
    const updateObj = { ...searchConfig };
    updateObj[key] = value;
    setSearchConfig({ ...updateObj });
  };

  const updateImportConfigHandler = (key, value) => {
    const updateObj = { ...importConfig };
    updateObj[key] = value;
    setImportConfig({ ...updateObj });
  };

  const updateStatusHandler = (key, value) => {
    const updateObj = { ...updateStatus };
    updateObj[key] = value;
    setUpdateStatus({ ...updateObj });
  };

  const updateInvitationHandler = (key, value) => {
    const updateObj = { ...invitation };
    updateObj[key] = value;
    setInvitation({ ...updateObj });
  };

  const importMethodChangeHandler = value => {
    setImportConfig({
      importMethod: value,
      projectID: '',
      type: [],
    });
  };

  const clearHandler = () => {
    setProjectInfo({
      projectID: '',
      projectCode: '',
      projectName: '',
    });
    setSearchConfig({
      journalistID: '',
      confirmStatus: '',
      job: '',
      amount: 10,
    });
    setInvitation({ ...invitationConfig });
    setJournalists([]);
    setJournalistOptions([]);
    setTotalCount(0);
    setCurrentPage(1);
  };

  const setCurrentPageHandler = page => {
    console.log("setCurrentPageHandler" + page)
    setCurrentPage(page);
    updateInvitations(page);
  };

  // const alertPopupCloseHandler = () => {
  //   clearHandler();
  //   // setShowAlertPopup(false)
  // }

  const searchHandler = async () => {
    setShowLoadingPopup(true);
    // const { projectID } = projectInfo;

    try {
      // const projectData = await postToGetProjectDetail(projectID);
      // const projectFinanceFiles = await postToGetUploadFiles('projectFinance', projectID);
      // const projectQuotations = await postToGetProjectQuotations({
      //   projectID, amount: 1, page: 1,
      // });

      /*const unlocked = projectData.data.projectUnlock !== null
        && projectData.data.projectUnlock !== undefined
        && diffTime(projectData.data.projectUnlock, Date.now(), 'days') <= 1;*/
      // const unlocked = diffTime(projectData.data.actionTimeStart, Date.now(), 'days') <= 10;

      // const notQuoted = projectQuotations.data.totalCount === undefined
      //   && diffTime(projectData.data.actionTimeEnd, Date.now(), 'days') < 8;

      // const noPO = projectFinanceFiles.data.length === 0
      //   && diffTime(projectData.data.actionTimeEnd, Date.now(), 'days') < 4;

      setShowLoadingPopup(false);

      // const isProjectLocked = !unlocked && (noPO || notQuoted);

      // if (isProjectLocked) {
      //   const alertText = `
      //     ${ noPO ? '無 PO 資料，' : '' }
      //     ${ notQuoted ? '無報價資料，' : '' }
      //     專案已被鎖定，請專案總監或總經理解鎖。
      //   `;
      //   setAlertText(alertText);
      //   setShowAlertPopup(true);
      //   return
      // }

      setCurrentPageHandler(1);

    } catch (err) {
      console.log(err);
      setShowLoadingPopup(false);
    }
  };

  const openStatusPopupHandler = index => {
    const journalist = journalists[index];
    setShowReporterStatusPopup(true);
    setUpdateItemIndex(index);
    setUpdateStatus(prevState => ({
      journalistName: journalist.journalistName,
      confirmStatus: journalist.confirmStatus || prevState.confirmStatus,
      statusDesc: journalist.statusDesc || prevState.statusDesc,
      job: journalist.job || prevState.job,
      attend: journalist.attend || prevState.attend,
    }));
  };

  const openStatusPopupByJournalistIdHandler = journalistId => {
    const index = journalists.findIndex(journalist => journalist.journalistID === journalistId);
    const journalist = journalists[index];
    setShowReporterStatusPopup(true);
    setUpdateItemIndex(index);
    setUpdateStatus(prevState => ({
      journalistName: journalist.journalistName,
      confirmStatus: journalist.confirmStatus || prevState.confirmStatus,
      statusDesc: journalist.statusDesc || prevState.statusDesc,
      job: journalist.job || prevState.job,
      attend: journalist.attend || prevState.attend,
    }));
  };

  const editJournalistHandler = index => {
    const updateJournalists = [...journalists];
    history.push(`/Information/JournalistDetail/${updateJournalists[index].journalistID}`)
  };

  const journalistCheckedHandler = (index, value) => {
    const updateJournalists = [...journalists];
    updateJournalists[index].isChecked = value;
    setJournalists([...updateJournalists]);
  };

  const selectAllHandler = value => {
    setSelectAll(value);
    const updateCustomers = journalists
      .map(journalist => ({ ...journalist, isChecked: value }));
    setJournalists([...updateCustomers]);
  };

  const closeSuccessPopupHandler = () => {
    setInvitation({ ...invitationConfig, projectID: projectInfo.projectID});
    setUpdateStatus({
      confirmStatus: '',
      statusDesc: '',
      job: '',
      attend: false,
    });
    setIsAddingReporter(false);
    setShowSaveSuccessPopup(false);
    updateInvitations();
  };

  const closeImportSuccessPopupHandler = () => {
    setShowImportSuccessPopup(false);
    setImportConfig({
      importMethod: 'project',
      projectID: '',
      type: [],
    });
    updateInvitations();
  };

  const exportInvitationHandler = () => {
    setShowExportPopup(false);
    exportMethod === 'normal'
      ? exportNormalFile()
      : exportFaxFile();
  };

  const getMediaType = typeID => typeID === '' || typeID === undefined
    ? ''
    : mediaTypes
        .filter(({ value }) => value === typeID)
        .pop().desc;

  const getStatusString = status => status === '' || status === undefined
    ? ''
    : invitationConfirmStatus
        .filter(({ value }) => value === status)
        .pop().desc;

  const exportNormalFile = async () => {
    setShowLoadingPopup(true);
    const invitationDatas = [];

    try {
      const { data } = await postToGetInvitations({
        ...searchConfigGenerator({ ...searchConfig }),
        page: 1,
        amount: totalCount || 1,
        projectID: projectInfo.projectID,
      });
      const { invitations } = data;

      for(let i = 0; i < invitations.length; i += 1 ) {
        const { mediaID, journalistID } = invitations[i];
        const mediaInfo = await postToGetMediaDetail(mediaID);
        const journalistInfo = await postToGetJournalistDetail(journalistID);
        const { mediaName, type, mediaEnglishName } = mediaInfo.data;

        invitationDatas.push([
          getMediaType(type),
          mediaName || '',
          mediaEnglishName || '',
          journalistInfo.data.name || '',
          journalistInfo.data.englishName || '',
          journalistInfo.data.jobTitle || '',
          journalistInfo.data.jobTitleEng || '',
          getPhoneString(journalistInfo.data.businessPhone),
          journalistInfo.data.businessMobile || '',
          journalistInfo.data.email1 || '',
          getAddressString(journalistInfo.data.companyAddress),
          getAddressString(journalistInfo.data.privateAddress),
          journalistInfo.data.birthday === '0001-01-01' ? '' : journalistInfo.data.birthday,
          invitations[i].attend === true ? '是' : '否',
          invitations[i].job || '',
          getStatusString(invitations[i].confirmStatus),
          invitations[i].statusDesc || '',
        ]);
      }
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
    exportInvitationFile(invitationDatas);
  };

  const exportFaxFile = async () => {
    setShowLoadingPopup(true);
    const invitationDatas = [];

    try {
      const { data } = await postToGetInvitations({
        ...searchConfigGenerator({ ...searchConfig }),
        page: 1,
        amount: totalCount || 1,
        projectID: projectInfo.projectID,
      });
      const { invitations } = data;

      for(let i = 0; i < invitations.length; i += 1 ) {
        const { mediaID, journalistID, journalistName } = invitations[i];
        const mediaInfo = await postToGetMediaDetail(mediaID);
        const journalistInfo = await postToGetJournalistDetail(journalistID);
        const { mediaName, type } = mediaInfo.data;

        invitationDatas.push([
          getMediaType(type),
          mediaName || '',
          journalistName || '',
          journalistInfo.data.fax || '',
        ]);
      }
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
    exportInvitationFaxFile(invitationDatas);
  };

  const importJournalistHandler = async () => {
     setShowLoadingPopup(true);
     setShowImportReporterPopup(false);
     const { importMethod, projectID, type } = importConfig;

     try {
      const { data } = importMethod === 'project'
        ? await postToGetProjectJournalistIDs(projectID)
        : await postToGetJournalistIDs(type);
      
      const importJournalists = data
        .map(({ journalistID }) => journalistID)
        .filter(journalistID => journalists
          .every(journalist => journalist.journalistID !== journalistID));
      
      for (let i = 0; i < importJournalists.length; i += 1 ) {
        await putNewInvitation({
          projectID: projectInfo.projectID,
          journalistID: importJournalists[i],
          confirmStatus: 'unchecked',
          statusDesc: '',
          job: '',
          attend: false,
        });
      }

      setShowLoadingPopup(false);
      setShowImportSuccessPopup(true);
     } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '匯入失敗', err }));
      console.log(err);
     };
  };

  const saveNewReporterHandler = async () => {
    setShowLoadingPopup(true);

    try {
      await putNewInvitation(invitation);
      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '儲存失敗', err }));
      console.log(err);
    }
  };

  const deleteJournalistHandler = async () => {
    setShowDeletePopup(false);
    setShowLoadingPopup(true);

    try {
      const selectedJournalists = journalists
        .filter(journalist => journalist.isChecked)
        .map(journalist => journalist.invitationID);

      await deleteInvitations(selectedJournalists);
      setShowLoadingPopup(false);
      setShowDeleteSuccessPopup(true);
    } catch(err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '刪除失敗', err }));
      console.log(err);
    }
  };

  const updateJournalistStatusHandler = async () => {
    setShowReporterStatusPopup(false);
    setShowLoadingPopup(true);

    try {
      const { journalistID, invitationID } = journalists[updateItemIndex];
      await patchModifyInvitation({
        id: invitationID,
        journalistID,
        projectID: projectInfo.projectID,
        ...updateStatus,
      });
      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '儲存失敗', err }));
      console.log(err);
    }
  };

  const updateInvitations = async (page = currentPage) => {
    setShowLoadingPopup(true);

    try {
      const projectData = await postToGetProjectDetail(projectInfo.projectID);
      const invitationStatus = await postToGetInvitationStatus(projectInfo.projectID);
      const { data } = await postToGetInvitations({
        ...searchConfigGenerator({ ...searchConfig, page }),
        projectID: projectInfo.projectID,
      });
      const { projectCode, projectName } = projectData.data;
      const { invitations, totalCount } = data;

      const journalists = invitations
        .map(invitation => ({
          ...invitation,
          mail: invitation.journalistEmail,
          isChecked: false,
          key: invitation.invitationID
        }));

      const allInvitationsData = await postToGetInvitations({
        ...searchConfigGenerator({ ...searchConfig }),
        projectID: projectInfo.projectID,
        page: 1,
        amount: totalCount,
      });

      const allJournalists = allInvitationsData.data.invitations;

      const journalistOptions = allJournalists
        .map(({ journalistName, journalistID, mediaName, mediaType, journalistEmail }) => ({
          mediaType,
          mail: journalistEmail,
          value: journalistID,
          desc: `${journalistName} - ${mediaName}`,
        }));
      
      setProjectInfo(prevState => ({
        ...prevState,
        projectCode,
        projectName,
      }));
      setInvitationStatus({
        ...invitationStatusConfig,
        ...invitationStatus.data,
      });
      setJournalists([ ...journalists ]);
      setJournalistOptions([...journalistOptions]);
      setTotalCount(totalCount || 0);
      setPagination(prevState => ({
        ...prevState,
        current: page,
        total: totalCount
      }));
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const initInvitationsData = useCallback(
    updateInvitations, []);
  
  const initOptions = useCallback(() => {
    dispatch(FETCH_MEDIA_TYPES());
    dispatch(FETCH_JOURNALIST_TYPES());
    dispatch(FETCH_PROJECT_ID_OPTIONS());
    dispatch(FETCH_JOURNALIST_ID_OPTIONS([]));
  }, [dispatch]);

  useEffect(() => {
    initOptions();
  }, [initOptions]);

  useEffect(() => {
    id !== undefined
      && initInvitationsData()
  }, [id, initInvitationsData]);

  useEffect(() => {
    if (id !== undefined) {
      setProjectInfo(prevState => ({ ...prevState, projectID: id }));
      setInvitation(prevState => ({ ...prevState, projectID: id }));
    }
  }, [id]);

  return (
    <MainContent>
      <PanelInvitationSearch
        projectInfo = { projectInfo }
        projectIDOptions = { projectIDList }
        clearHandler = { clearHandler }
        searchHandler = { searchHandler }
        updateProjectIDHandler = { value => {
          setProjectInfo(prevState => ({ ...prevState, projectID: value }));
          setInvitation(prevState => ({ ...prevState, projectID: value }));
        }}
      />
      <TableContainer>
        <TableInvitations
          projectID = { projectInfo.projectID }
          projectCode = { projectInfo.projectCode }
          projectName = { projectInfo.projectName }
          invitationStatus = { invitationStatus }
        />
      </TableContainer>
      <TableContainer>
        <TableInvitationManagement
          userEditable = { userEditable }
          userSendable = { userSendable }
          userExportable = { userExportable }
          journalistOptions = { journalistOptions }
          journalistIDOptions = { journalistIDList }
          isAdding = { isAddingReporter }
          invitation = { invitation }
          saveHandler = { saveNewReporterHandler }
          addHandler = { () => { setIsAddingReporter(true) } }
          closeHandler = { () => { setIsAddingReporter(false) } }
          importHandler = { () => { setShowImportReporterPopup(true) } }
          exportHandler = { () => { setShowExportPopup(true) } }
          sendMessageHandler = { () => { setShowInformPopup(true) } }
          updateInvitationHandler = { updateInvitationHandler }
        />
      </TableContainer>
      <TableContainer>
        <TableInvitationReporters
          config = { searchConfig }
          selectAll = { selectAll }
          totalCount = { totalCount }
          journalists = { journalists }
          currentPage = { currentPage }
          deletable = { selectedItemNumber !== 0 }
          searchable = { projectInfo.projectID !== '' }
          searchHandler = { searchHandler }
          selectAllHandler = { selectAllHandler }
          journalistIDOptions = { journalistOptions }
          updateConfigHandler = { updateConfigHandler }
          goDetailHandler = { openStatusPopupHandler }
          editJournalistHandler = { editJournalistHandler }
          checkHandler = { journalistCheckedHandler }
          deleteHandler = { () =>  { setShowDeletePopup(true) }}
          pageChangedHandler = { value => {
            selectAllHandler(false);
            setCurrentPageHandler(value);
          }}
        />
        <TableInvitationReportersTwo
          config = { searchConfig }
          selectAll = { selectAll }
          totalCount = { totalCount }
          journalists = { journalists }
          currentPage = { currentPage }
          deletable = { selectedItemNumber !== 0 }
          searchable = { projectInfo.projectID !== '' }
          searchHandler = { searchHandler }
          selectAllHandler = { selectAllHandler }
          journalistIDOptions = { journalistOptions }
          updateConfigHandler = { updateConfigHandler }
          goDetailHandler = { openStatusPopupHandler }
          goDetailByJournalIdHandler = { openStatusPopupByJournalistIdHandler }
          editJournalistHandler = { editJournalistHandler }
          checkHandler = { journalistCheckedHandler }
          deleteHandler = { () =>  { setShowDeletePopup(true) }}
          pageChangedHandler = { (pagination, filters, sorter) => {
            selectAllHandler(false);
            setCurrentPageHandler(pagination.current);
          }}
          pagination = { pagination }
        />
      </TableContainer>
      { showLoadingPopup && <PopupLoading/> }
      {
        showReporterStatusPopup  &&
          <PopupInvitationReporterStatus
            status = { updateStatus }
            cancelHandler = { () => { setShowReporterStatusPopup(false) } }
            confirmHandler = { updateJournalistStatusHandler }
            updateStatusHandler = { updateStatusHandler }
          />
      }
      {
        showImportReporterPopup  &&
          <PopupReporterInvitation
            config = { importConfig }
            projectIDOptions = { importableProjects }
            journalistTypes = { journalistTypes }
            confirmHandler = { importJournalistHandler }
            updateConfigHandler = { updateImportConfigHandler }
            methodChangeHandler = { importMethodChangeHandler }
            cancelHandler = { () => {
              setShowImportReporterPopup(false);
              setImportConfig({
                importMethod: 'project',
                projectID: '',
                type: [],
              });
            }}
          />
      }
      {
        showExportPopup &&
          <PopupExportMethodSelect
            title = "檔案匯出"
            value = { exportMethod }
            options = { exportMethodOptions }
            changeHandler = { setExportMethod }
            cancelHandler = { () => { setShowExportPopup(false) } }
            confirmHandler = { exportInvitationHandler }
          />
      }
      {
        showInformPopup &&
          <PopupInformReporter
            sendConfig = { sendConfig }
            mediaTypes = { mediaTypes }
            journalistOptions = { journalistOptions }
            updateSendConfigHander = { updateSendConfigHander }            
            cancelHandler = { () => { setShowInformPopup(false) } }
            confirmHandler = { sendMessageHandler }
          />
      }
      {
        showDeletePopup &&
          <PopupExecuteConfirm
            title = "刪除邀請記者"
            text = { `確定刪除${ selectedItemNumber }位記者` }
            confirmHandler = { deleteJournalistHandler }
            cancelHandler = { () => {
              setShowDeletePopup(false);
            }}
          />
      }
      {
        showSaveSuccessPopup &&
          <PopupExecuteResult
            title = "儲存成功"
            confirmHandler = { closeSuccessPopupHandler }
          />
      }
      {
        showImportSuccessPopup &&
          <PopupExecuteResult
            title = "匯入成功"
            confirmHandler = { closeImportSuccessPopupHandler }
          />
      }
      {
        showDeleteSuccessPopup &&
          <PopupExecuteResult
            title = "刪除成功"
            confirmHandler = { () => {
              setShowDeleteSuccessPopup(false);
              updateInvitations();
            }}
          />
      }
      {/* {
        showAlertPopup &&
          <PopupExecuteResult
            text = { alertText }
            confirmHandler = { alertPopupCloseHandler }
          />
      } */}
      {
        showSaveFailPopup &&
          <PopupExecuteResult
            title = { apiFailPopupTitle }
            text = { apiFailPopupMessage }
            confirmHandler = { () => {
              dispatch(CLOSE_SAVE_FAIL_POPUP());
            }}
          />
      }
    </MainContent>
  );
};

export default ReporterInvitation;
