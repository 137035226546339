import React from 'react';
import styled from 'styled-components';

import TableWrapper from '@/components/UI/TableWrapper';
import SelectSearchable from '@/components/UI/Select/SelectSearchable';
import SelectNormal from '@/components/UI/Select/SelectNormal';
import Checkbox from '@/components/UI/Checkbox';
import DateSelector from '@/components/UI/DateSelector';
import { InputBasic, TextareaBasic } from '@/component-style/Input';
import { PSubtitle } from '@/component-style/TextElement';
import {
  DivFlexRow,
  DivItemWrapper,
  DivFullWidthWrapper,
  DivLongMultiSelector,
} from '@/component-style/RWDWrapper';

import { scheduleChargeOptions } from '@/constant/options';
import { formatTime } from '@/utils/timeUtils';

const DivCheckboxWrapper = styled.div`
  height: 40px;
  line-height: 40px;

  @media (max-width: 767px) {
    height: 20px;
    line-height: 20px;
    margin-bottom: 5px;
  }
`;

const PanelScheduleDetail = props => {
  const { schedule } = props;

  return (
    <TableWrapper title = { props.title }>
      <DivFlexRow>
        <DivFullWidthWrapper>
          <PSubtitle>
            專案代號
            <span>*</span>
          </PSubtitle>
          <DivLongMultiSelector>
            <SelectSearchable
              disabled = { props.disabled }
              value = { schedule.projectID }
              placeholder = "請選擇專案代號"
              options = {[
                { value: '', desc: '請選擇專案代號', disabled: true },
                ...props.projectIDOptions,
              ]}
              changeHandler = { value => {
                props.updateScheduleHandler('projectID', value);
              }}
            />
          </DivLongMultiSelector>
        </DivFullWidthWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>
            工作項目
            <span>*</span>
          </PSubtitle>
          <SelectSearchable
            disabled = { props.disabled }
            value = { schedule.todoTypeID }
            options = {[
              { value: '', desc: '請選擇工作項目', disabled: true },
              ...props.todoTypeOptions,
            ]}
            changeHandler = { value => {
              props.todoTypeChangeHandler(value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>
            工作細項
            <span>*</span>
          </PSubtitle>
          <SelectSearchable
            disabled = { props.disabled }
            value = { schedule.todoTag }
            options = {[
              { value: '', desc: '請選擇工作細項', disabled: true },
              ...props.todoTagOptions,
            ]}
            changeHandler = { value => {
              props.updateScheduleHandler('todoTag', value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>是否更名</PSubtitle>
          <DivCheckboxWrapper>
            <Checkbox
              disabled = { props.disabled }
              checked = { schedule.displayTypeChanged }
              changeHandler = { value => {
                props.updateScheduleHandler('displayTypeChanged', value);
              }}
            />
          </DivCheckboxWrapper>
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>顯示名稱</PSubtitle>
          <InputBasic
            value = { schedule.displayType.default || '' }
            disabled = { props.disabled || !schedule.displayTypeChanged }
            onChange = { ({ target }) => {
              props.updateScheduleHandler('displayType', {
                ...schedule.displayType,
                default: target.value,
              });
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>顯示名稱 (英文)</PSubtitle>
          <InputBasic
            value = { schedule.displayType.english || '' }
            disabled = { props.disabled || !schedule.displayTypeChanged }
            onChange = { ({ target }) => {
              props.updateScheduleHandler('displayType', {
                ...schedule.displayType,
                english: target.value,
              });
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>預計完成日</PSubtitle>
          <DateSelector
            disabled = { props.disabled }
            value = { schedule.estimatedDate }
            changeHandler = { value => {
              props.updateScheduleHandler(
                'estimatedDate',
                formatTime(value, 'YYYY-MM-DD'),
              );
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>實際完成日</PSubtitle>
          <DateSelector
            value = { schedule.finishedDate }
            disabled = { true }
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>負責單位<span>*</span></PSubtitle>
          <SelectNormal
            disabled = { props.disabled }
            value = { schedule.department }
            options = { scheduleChargeOptions }
            changeHandler = { value => {
              props.departmentChangedHandler(value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>負責成員
            {
              (schedule.department === 'veda' || schedule.department === 'both')
              && <span>*</span>
            }
          </PSubtitle>
          <SelectSearchable

            value = { schedule.userID }
            disabled = {
              props.disabled
                || schedule.department === 'customer'
                || schedule.department === ''
            }
            options = {[
              { value: '', desc: '請選擇負責成員' },
              ...props.memberOptions,
            ]}
            changeHandler = { value => {
              props.updateScheduleHandler('userID', value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivFullWidthWrapper>
          <PSubtitle>工作備註</PSubtitle>
          <DivLongMultiSelector>
            <TextareaBasic
              disabled = { props.disabled }
              value = { schedule.note }
              onChange = {({ target }) => { 
                props.updateScheduleHandler('note', target.value);
              }}
            />
          </DivLongMultiSelector>
        </DivFullWidthWrapper>
      </DivFlexRow>
      { props.children }
    </TableWrapper>
  );
};

export default PanelScheduleDetail;
