import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import RemoveImg from '@/assets/images/icon_remove.svg';
import SelectNormal from '@/components/UI/Select/SelectNormal';
import { InputBasic } from '@/component-style/Input';
import { PSubtitle } from '@/component-style/TextElement';

import { integerConverter } from '@/utils/textUtils';
import { getMultiplyResult } from '@/utils/mathUtils';

const DivFWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
`;

const InputWrapper = styled.div`
  margin-right: 20px;
  margin-bottom: 10px;

  @media (max-width: 375px) {
    margin-right: 0;
  }

  @media (min-width: 1920px) {
    .ant-select, .datePicker { width: 300px; }
  }

  @media (max-width: 320px) {
    .ant-select, .datePicker { width: 260px; }
  };
`;

const InputNumberWrapper = styled(InputWrapper)`
  @media (max-width: 375px) {
    margin-right: 15px;
  }
`;

const Input = styled(InputBasic)`
  @media (max-width: 320px) { width: 260px; }
  @media (min-width: 1920px) { width: 300px }
`;

const InputNumber = styled(InputBasic)`
  width: 120px;

  @media (max-width: 320px) {
    width: 120px;
  };

  @media (min-width: 1920px) {
    width: 120px;
  }
`;

const DivIconRemove = styled.div`
  cursor: pointer;
  margin-top: 20px;
  width: 20px;
  height: 40px;
  background-image: url(${ props => props.url });
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`;

const PLabel = styled(PSubtitle)`
  width: auto;
  height: 20px;
  line-height: 20px;

  span { color: #de2027 }
`;

const ProjectQuotationItem = props => {
  const { quotation, type } = props;
  const todoTypeOptions = useSelector(state => state.srpOptions.todoTypeOptions);
  const todoTagDictionary = useSelector(state => state.srpOptions.todoTagDictionary);
  const vendorTypeOptions = useSelector(state => state.srpOptions.vendorTypeOptions);
  const vendorTagDictionary = useSelector(state => state.srpOptions.vendorTagDictionary);

  const typeOptions = type === 'oop'
    ? vendorTypeOptions
    : todoTypeOptions;

  const tagOptions = typeID => {
    const tagDictionary = type === 'oop'
      ? vendorTagDictionary
      : todoTagDictionary

    return tagDictionary[typeID] === undefined
      ? []
      : tagDictionary[typeID]
  };

  const quotedPriceTotal = getMultiplyResult(
    integerConverter(quotation.quotedPrice),
    integerConverter(quotation.amount),
  );

  const priceTotal = getMultiplyResult(
    integerConverter(quotation.price),
    integerConverter(quotation.amount),
  );

  const quotationChangeHandler = (key, value) => {
    const updateObj = { ...quotation };
    updateObj[key] = value;
    props.itemChangeHandler(updateObj);
  };

  const quotationTypeChangeHandler = value => {
    props.itemChangeHandler({
      ...quotation,
      type: value,
      tag: '',
    });
  };

  const quotationQuotedPriceChangeHandler = value => {
    props.itemChangeHandler({
      ...quotation,
      quotedPrice: value,
      price: value,
    });
  };

  return (
    <DivFWrapper>
      {/* <InputWrapper>
        <PLabel>服務類別</PLabel>
        <SelectNormal
          disabled = { props.disabled }
          value = { quotation.type }
          options = {[
            { value: '', desc: '請選擇服務類別' },
            ...typeOptions,
          ]}
          changeHandler = { value => {
            quotationTypeChangeHandler(value);
          }}
        />
      </InputWrapper>
      <InputWrapper>
        <PLabel>服務項目</PLabel>
        <SelectNormal
          disabled = { props.disabled }
          value = { quotation.tag }
          options = {[
            { value: '', desc: '請選擇服務項目' },
            ...tagOptions(quotation.type),
          ]}
          changeHandler = { value => {
            quotationChangeHandler('tag', value);
          }}
        />
      </InputWrapper> */}
      <InputWrapper>
        <PLabel>服務說明</PLabel>
        <Input
          disabled = { props.disabled }
          value = { quotation.note }
          onChange = { ({ target }) => {
            quotationChangeHandler('note', target.value);
          }}
        />
      </InputWrapper>
      <InputNumberWrapper>
        <PLabel>單價</PLabel>
        <InputNumber
          type = "number"
          disabled = { props.disabled }
          value = { quotation.quotedPrice }
          onChange = { ({ target }) => {
            quotationQuotedPriceChangeHandler(target.value);
          }}
        />
      </InputNumberWrapper>
      <InputNumberWrapper>
        <PLabel>數量</PLabel>
        <InputNumber
          type = "number"
          disabled = { props.disabled }
          value = { quotation.amount }
          onChange = { ({ target }) => {
            quotationChangeHandler('amount', target.value);
          }}
        />
      </InputNumberWrapper>
      <InputNumberWrapper>
        <PLabel>合計</PLabel>
        <InputNumber
          disabled = { true }
          value = { quotedPriceTotal }
        />
      </InputNumberWrapper>
      <InputNumberWrapper>
        <PLabel>議價後單價</PLabel>
        <InputNumber
          type = "number"
          disabled = { props.disabled }
          value = { quotation.price }
          onChange = { ({ target }) => {
            quotationChangeHandler('price', target.value);
          }}
        />
      </InputNumberWrapper>
      <InputNumberWrapper>
        <PLabel>議價後合計</PLabel>
        <InputNumber
          disabled = { true }
          value = { priceTotal }
        />
      </InputNumberWrapper>
      <InputNumberWrapper>
        <DivIconRemove
          url = { RemoveImg }
          onClick = { () => {
            if (props.disabled) return
            props.removeHandler();
          }}
        />
      </InputNumberWrapper>
    </DivFWrapper> 
  );
};

export default ProjectQuotationItem;
