import React from 'react';
import styled from 'styled-components';

import NavigationItem from '../NavigationItem';
import NavigationCategory from '../NavigationCategory';
import IconExpense from '@/assets/images/icon_expenses.svg';

const UlWrapper = styled.ul`
  margin: 0;
  padding: 0;
`;

const DivIcon = styled.span`
  display: inline-block;
  margin-left: 50px;
  width: 24px;
  height: 24px;
  background-image: url(${props => props.url});
`;

const expenseNavigations = [
  {
    title: '廠商費用申請',
    key: 'ExpenseQuotations',
    url: '/Expense/ExpenseQuotations',
  },
  {
    title: '同仁款項申請',
    key: 'ExpenseFormCreation',
    url: '/Expense/ExpenseFormCreation',
  },
  {
    title: '支出憑單總表',
    key: 'ExpenseForms',
    url: '/Expense/ExpenseForms',
  },
  {
    title: '支出憑單審核',
    key: 'ExpenseApproval',
    url: '/Expense/ExpenseApproval',
  },
  {
    title: '支出憑單代審',
    key: 'ExpenseProxyApproval',
    url: '/Expense/ExpenseProxyApproval',
  },
];

const NavigationListExpense = props => {
  const { validRoutes } = props;

  const navigations = expenseNavigations
    .filter(({ key }) => validRoutes.indexOf(key) !== -1)
    .map(({ title, url }) => (
      <NavigationItem
        key = { url }
        url = { url }
        title = { title }
      />
    ));

  return (
    <UlWrapper>
      <NavigationCategory
        title = "費用報支管理"
        opened = { props.opened }
        clickHandler = { () => { props.toogleList() }}
      >
        <DivIcon url = { IconExpense }/>
      </NavigationCategory>
      { props.opened && navigations }
    </UlWrapper>
  );
};

export default NavigationListExpense;
