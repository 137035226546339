import React from 'react';
import styled from 'styled-components';

import Checkbox from './Checkbox';
import SelectNormal from '@/components/UI/Select/SelectNormal';
import { PSubtitle } from '@/component-style/TextElement';
import { InputBasic } from '@/component-style/Input';

import { identityTypeOptions } from '@/constant/options';

const DivWrapper = styled.div`
  width: 100%;
  
  @media (min-width: 768px) {
    width: calc(100% - 135px);
  }
`;

const DivFWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const CheckboxWrapper = styled.div`
  margin-right: 15px;
  margin-bottom: 10px;
  min-width: 100px;
  height: 40px;
  line-height: 40px;
`;

const DivSelectorWrapper = styled.div`
  margin-right: 15px;
  margin-bottom: 10px;

  & .ant-select {
    width: 140px;
  }

  @media (min-width: 1920px) {
    .ant-select { width: 140px; }
  }
`;

const Input = styled(InputBasic)`
  width: 140px;
  margin-right: 15px;
  margin-bottom: 10px;

  @media (min-width: 1920px) {
    width: 140px;
  }
`;

const InputBudget = styled(InputBasic)`
  width: 100px;
  margin-right: 10px;
  margin-bottom: 10px;

  @media (max-width: 320px) {
    width: 85px;
  }

  @media (min-width: 1920px) {
    width: 100px;
  }
`;

const PLableTitle = styled(PSubtitle)`
  width: 40px;
  line-height: 40px;

  @media (max-width: 767px) {
    height: 40px;
    line-height: 40px;
    margin-bottom: 0;
  }
`;

const PSpacer = styled.p`
  margin-right: 10px;
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  color: #767676;
`;


const VendorSpecialProperties = props => {
  const { properties } = props;

  return (
    <DivWrapper>
      <DivFWrapper>
        <CheckboxWrapper>
          <Checkbox
            title = "個人勞務"
            disabled = { props.disabled }
            checked = { properties.personalService }
            changeHandler = { value => {
              props.updatePropertiesHandler({
                ...properties,
                personalService: value,
                personalServiceDetail: {
                  payTax: false,
                  payPremium: false,
                  identityType: '',
                  nation: '',
                  wageB: '',
                  wageE: '',
                  specialFeature: '',
                },
              });
            }}
          />
        </CheckboxWrapper>
        <CheckboxWrapper>
          <Checkbox
            title = "代收代付"
            disabled = { props.disabled }
            checked = { properties.transfer }
            changeHandler = { value => {
              props.updatePropertiesHandler({
                ...properties,
                transfer: value,
              });
            }}
          />
        </CheckboxWrapper>
        <CheckboxWrapper>
          <Checkbox
            title = "外國廠商"
            disabled = { props.disabled }
            checked = { properties.foreigner }
            changeHandler = { value => {
              props.updatePropertiesHandler({
                ...properties,
                foreigner: value,
              });
            }}
          />
        </CheckboxWrapper>
      </DivFWrapper>
      {
        properties.personalService &&
        <React.Fragment>
          <DivFWrapper>
            <CheckboxWrapper>
              <Checkbox
                title = "稅外加"
                disabled = { props.disabled }
                checked = { properties.personalServiceDetail.payTax }
                changeHandler = { value => {
                  props.updatePropertiesHandler({
                    ...properties,
                    personalServiceDetail: {
                      ...properties.personalServiceDetail,
                      payTax: value,
                    },
                  });
                }}
              />
            </CheckboxWrapper>
            <CheckboxWrapper>
              <Checkbox
                disabled = { props.disabled }
                title = "不需扣補充保費"
                checked = { properties.personalServiceDetail.payPremium }
                changeHandler = { value => {
                  props.updatePropertiesHandler({
                    ...properties,
                    personalServiceDetail: {
                      ...properties.personalServiceDetail,
                      payPremium: value,
                    },
                  });
                }}
              />
            </CheckboxWrapper>
            <DivSelectorWrapper>
              <SelectNormal
                disabled = { props.disabled }
                value = { properties.personalServiceDetail.identityType }
                options = { identityTypeOptions }
                changeHandler = { value => {
                  props.updatePropertiesHandler({
                    ...properties,
                    personalServiceDetail: {
                      ...properties.personalServiceDetail,
                      identityType: value,
                    },
                  });
                }}
              />
            </DivSelectorWrapper>
            <DivSelectorWrapper>
              <SelectNormal
                disabled = { props.disabled }
                value = { properties.personalServiceDetail.specialFeature }
                options = {[
                  { value: '', desc: '請選擇特殊資格' },
                  ...props.specialFeatures,
                ]}
                changeHandler = { value => {
                  props.updatePropertiesHandler({
                    ...properties,
                    personalServiceDetail: {
                      ...properties.personalServiceDetail,
                      specialFeature: value,
                    },
                  });
                }}
              />
            </DivSelectorWrapper>
            <Input
              placeholder = "國籍"
              disabled = { props.disabled }
              value = { properties.personalServiceDetail.nation }
              onChange = { ({ target }) => {
                props.updatePropertiesHandler({
                  ...properties,
                  personalServiceDetail: {
                    ...properties.personalServiceDetail,
                    nation: target.value,
                  },
                });
              }}
            />
          </DivFWrapper>
          <DivFWrapper>
            <PLableTitle>時薪:</PLableTitle>
            <InputBudget
              type = "number"
              disabled = { props.disabled }
              value = { properties.personalServiceDetail.wageB }
              onChange = { ({ target }) => {
                props.updatePropertiesHandler({
                  ...properties,
                  personalServiceDetail: {
                    ...properties.personalServiceDetail,
                    wageB: target.value,
                  },
                });
              }}
            />
            <PSpacer>~</PSpacer>
            <InputBudget
              type = "number"
              disabled = { props.disabled }
              value = { properties.personalServiceDetail.wageE }
              onChange = { ({ target }) => {
                props.updatePropertiesHandler({
                  ...properties,
                  personalServiceDetail: {
                    ...properties.personalServiceDetail,
                    wageE: target.value,
                  },
                });
              }}
            />
          </DivFWrapper>
        </React.Fragment>
      }
    </DivWrapper>
  );
};

export default VendorSpecialProperties;
