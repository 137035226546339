import React from 'react';
import { Route } from 'react-router-dom';

import PersonnelWage from '@/containers/hrModule/personnelWage';
import WageStructureManagement from '@/containers/hrModule/wageStructureManagement';
import ProjectCost from '@/containers/hrModule/projectCost';
import PersonnelCompensatedLeaveManagement from '@/containers/hrModule/personnelCompensatedLeaveManagement';

const hrModuleRoutes = [
  {
    key: 'ProjectCost',
    path: "/HR/ProjectCost",
    component: ProjectCost,
  },
  {
    key: 'PersonnelWage',
    path: "/HR/PersonnelWage",
    component: PersonnelWage,
  },
  {
    key: 'WageStructureManagement',
    path: "/HR/WageStructureManagement",
    component: WageStructureManagement,
  },
  {
    key: 'PersonnelCompensatedLeaveManagement',
    path: "/HR/PersonnelCompensatedLeaveManagement",
    component: PersonnelCompensatedLeaveManagement,
  },
];

const RoutesHRModule = validRoutes => hrModuleRoutes
  .filter(({ key }) => validRoutes.indexOf(key) !== -1)
  .map(({ path, component }) =>
    <Route
      key = { path }
      path = { path }
      component = { component }
    />
  );

export default RoutesHRModule;
