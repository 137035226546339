import React from 'react';
import styled from 'styled-components';

import DateSelector from './DateSelector';

const DivWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const DivSelectorWrapper = styled.div`
  width: 300px;

  @media (max-width: 768px) {
    &:first-child {
      margin-bottom: 10px;
    }
  }

  @media (max-width: 320px) {
    width: 260px;
  }

  @media (min-width: 1920px) {
    .datePicker { width: 300px; }
  }
`;

const PSpacer = styled.p`
  width: 20px;
  height: 40px;
  line-height: 40px;
  font-size: 12px;
  color: #d9d9d9;
  text-align: center;
  font-weight: bold;

  @media (max-width: 767px) {
    display: none;
  }
`;

const DatePeriodSelector = props => {

  return(
    <DivWrapper>
      <DivSelectorWrapper>
        <DateSelector
          disabled = { props.disabled }
          picker = { props.picker }
          value = { props.startTime }
          showTime = { props.showTime }
          changeHandler = { props.startTimeChangeHandler }
          okHandler = { props.startTimeOKHandler }
        />
      </DivSelectorWrapper>
      <PSpacer>-</PSpacer>
      <DivSelectorWrapper>
        <DateSelector
          disabled = { props.disabled }
          picker = { props.picker }
          value = { props.endTime }
          showTime = { props.showTime }
          changeHandler = { props.endTimeChangeHandler }
          okHandler = { props.endTimeOKHandler }
        />
      </DivSelectorWrapper>
    </DivWrapper>
  )
};

export default DatePeriodSelector;
