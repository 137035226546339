import React from 'react';
import styled from 'styled-components';

import { DivPopupWrapper, DivPopup } from '../../../../component-style/Popup';
import { ButtonBasic, ButtonCancel } from '../../../../component-style/Button';

const DivButtonsWrapper = styled.div`
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const H3Title = styled.h3`
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: #8b8b8b;
  margin-bottom: 10px;
`;

const PTexts = styled.p`
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.43px;
  color: #8b8b8b;
`;

const ButtonConfirm = styled(ButtonBasic)`
  width: 120px;
`;

const ButtonDiscard = styled(ButtonCancel)`
  width: 120px;
  margin-right: 10px;
`;


const PopupExecuteConfirm = props => {

  return (
    <DivPopupWrapper>
      <DivPopup>
        <H3Title>{ props.title }</H3Title>
        <PTexts>{ props.text }</PTexts>
        <DivButtonsWrapper>
          <ButtonDiscard
            onClick = { props.cancelHandler }
          >關閉
          </ButtonDiscard>
          <ButtonConfirm
            onClick = { props.confirmHandler }
          >確定
          </ButtonConfirm>
        </DivButtonsWrapper>
      </DivPopup>
    </DivPopupWrapper>
  );
};

export default PopupExecuteConfirm;
