import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { cloneDeep } from 'lodash';

import { postToGetExpenseVouchers } from '@/api/expenseService';

import { FETCH_COMPANY_TYPE_OPTIONS } from '@/store/actions/srpOptionsAction';

import PanelIncomeInvoiceSearch from '@/components/Panel/Finance/PanelIncomeInvoiceSearch';
import TableIncomeInvoices from '@/components/Table/Finance/TableIncomeInvoices';
import PopupLoading from '@/components/UI/Popup/PopupLoading';

import { formatTime } from '@/utils/timeUtils';
import { getOptionDesc } from '@/utils/textUtils';
import { vendorInvoiceTypes } from '@/constant/options';
import { searchConfigGenerator } from '@/utils/apiRequestUtils';
import { exportExpenseVouchersFile } from '@/utils/xlsx';

const DivPanelContainer = styled.div`
  margin-bottom: 40px;
`;

const searchConfig = {
  voucherPrefix: '',
  voucherCode: {
    start: '',
    end: '',
  },
  date: {
    start: null,
    end: null,
  },
  notForBusinessTax: '',
  companyType: '',
  amount: 10,
};

const IncomeInvoiceSearch = () => {
  const dispatch = useDispatch();
  const companyTypeOptions = useSelector(state => state.srpOptions.companyTypeOptions);

  const [vouchers, setVouchers] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [config, setConfig] = useState(cloneDeep(searchConfig));

  const [showLoadingPopup, setShowLoadingPopup] = useState(false);

  const updateConfigHandler = (key, value) => {
    const updateObj = { ...config };
    updateObj[key] = value;
    setConfig({ ...updateObj });
  };

  const clearHandler = () => {
    setVouchers([]);
    setTotalCount(0);
    setConfig(cloneDeep(searchConfig));
  };

  const searchHandler = async () => {
    setCurrentPageHandler(1);
  };

  const setCurrentPageHandler = page => {
    setCurrentPage(page);
    fetchExpenseVouchers(page);
  };

  const exportHandler = async () => {
    setShowLoadingPopup(true);
    const vouchersData = [];

    try {
      const { data } = await postToGetExpenseVouchers(
        searchConfigGenerator({
          ...config,
          date: {
            start: formatTime(config.date.start, 'YYYY-MM-DD'),
            end: formatTime(config.date.end, 'YYYY-MM-DD'),
          },
          voucherCode: {
            start: parseInt(config.voucherCode.start) || null,
            end: parseInt(config.voucherCode.end) || null,
          },
          page: 1,
          amount: totalCount || 1,
        })
      );

      const { vouchers } = data;

      for (let i = 0; i < vouchers.length; i += 1) {
        const voucher = vouchers[i];
        vouchersData.push([
          voucher.taxID,
          getOptionDesc(vendorInvoiceTypes, voucher.voucherType),
          voucher.voucherCode,
          formatTime(voucher.date, 'YYYY/MM/DD') || '',
          voucher.untaxedAmount || 0,
          voucher.tax || 0,
          voucher.amount || 0,
          voucher.amount || 0,
        ]);
      }

    } catch(err) { console.log(err) }

    setShowLoadingPopup(false);    
    exportExpenseVouchersFile(vouchersData);
  };

  const fetchExpenseVouchers = async (page = currentPage) => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetExpenseVouchers(
        searchConfigGenerator({
          ...config,
          date: {
            start: formatTime(config.date.start, 'YYYY-MM-DD'),
            end: formatTime(config.date.end, 'YYYY-MM-DD'),
          },
          voucherCode: {
            start: parseInt(config.voucherCode.start) || null,
            end: parseInt(config.voucherCode.end) || null,
          },
          page,
        })
      );

      const { vouchers, totalCount } = data;

      setVouchers([...vouchers]);
      setTotalCount(totalCount || 0);
    } catch(err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const initOptions = useCallback(() => {
    dispatch(FETCH_COMPANY_TYPE_OPTIONS());
  }, [dispatch]);

  useEffect(() => {
    initOptions();
  }, [initOptions]);

  return(
    <>
      <DivPanelContainer>
        <PanelIncomeInvoiceSearch
          config = { config }
          companyTypeOptions = { companyTypeOptions }
          clearHandler = { clearHandler }
          exportHandler = { exportHandler }
          searchHandler = { searchHandler }
          updateConfigHandler = { updateConfigHandler }
        />
      </DivPanelContainer>
      <TableIncomeInvoices
        vouchers = { vouchers }
        totalCount = { totalCount }
        currentPage = { currentPage }
        pageChangedHandler = { setCurrentPageHandler }
      />
      { showLoadingPopup && <PopupLoading/> }
    </>
  );
};

export default IncomeInvoiceSearch;
