export const FETCH_SYSTEM_ROLE_OPTIONS = 'FETCH_SYSTEM_ROLE_OPTIONS';
export const FETCH_CUSTOMER_OPTIONS = 'FETCH_CUSTOMER_OPTIONS';
export const FETCH_COMPANY_TYPE_OPTIONS = 'FETCH_COMPANY_TYPE_OPTIONS';
export const FETCH_CONTACT_WINDOW_OPTIONS = 'FETCH_CONTACT_WINDOW_OPTIONS';
export const FETCH_DEPARTMENT_OPTIONS = 'FETCH_DEPARTMENT_OPTIONS';
export const FETCH_DIRECTOR_OPTIONS = 'FETCH_DIRECTOR_OPTIONS';
export const FETCH_MEMBER_OPTIONS = 'FETCH_MEMBER_OPTIONS';
export const FETCH_PROJECT_ID_OPTIONS = 'FETCH_PROJECT_ID_OPTIONS';
export const FETCH_PROJECT_CODE_OPTIONS = 'FETCH_PROJECT_CODE_OPTIONS';
export const FETCH_PROJECT_TYPE_OPTIONS = 'FETCH_PROJECT_TYPE_OPTIONS';
export const FETCH_PROJECT_MANAGER_OPTIONS = 'FETCH_PROJECT_MANAGER_OPTIONS';
export const FETCH_TODO_TYPE_OPTIONS = 'FETCH_TODO_TYPE_OPTIONS';
export const FETCH_ACTIVITY_IDEA_TYPE_OPTIONS = 'FETCH_ACTIVITY_IDEA_TYPE_OPTIONS';
export const FETCH_CUSTOMER_INDUSTRIES = 'FETCH_CUSTOMER_INDUSTRIES';
export const FETCH_MEDIA_ID_OPTIONS = 'FETCH_MEDIA_ID_OPTIONS';
export const FETCH_MEDIA_TYPES = 'FETCH_MEDIA_TYPES';
export const FETCH_VENDOR_ID_OPTIONS = 'FETCH_VENDOR_ID_OPTIONS';
export const FETCH_VENDOR_TYPE_OPTIONS = 'FETCH_VENDOR_TYPE_OPTIONS';
export const FETCH_VENUE_TYPE_OPTIONS = 'FETCH_VENUE_TYPE_OPTIONS';
export const FETCH_VENUE_EQUIPMENTS = 'FETCH_VENUE_EQUIPMENTS';
export const FETCH_VENUE_CHARACTERISTICS = 'FETCH_VENUE_CHARACTERISTICS';
export const FETCH_JOURNALIST_TYPES = 'FETCH_JOURNALIST_TYPES';
export const FETCH_JOURNALIST_ID_OPTIONS = 'FETCH_JOURNALIST_ID_OPTIONS';
export const FETCH_EXPENSE_TYPE_OPTIONS = 'FETCH_EXPENSE_TYPE_OPTIONS';
export const FETCH_SPECIAL_FEATURES = 'FETCH_SPECIAL_FEATURES';
export const FETCH_DECLARE_TYPE_OPTIONS = 'FETCH_DECLARE_TYPE_OPTIONS';
export const FETCH_WORKINGHOURS_TYPE_OPTIONS = 'FETCH_WORKINGHOURS_TYPE_OPTIONS';

export const UPDATE_SYSTEM_ROLE_OPTIONS = 'UPDATE_SYSTEM_ROLE_OPTIONS';
export const UPDATE_CUSTOMER_OPTIONS = 'UPDATE_CUSTOMER_OPTIONS';
export const UPDATE_COMPANY_TYPE_OPTIONS = 'UPDATE_COMPANY_TYPE_OPTIONS';
export const UPDATE_CONTACT_WINDOW_OPTIONS = 'UPDATE_CONTACT_WINDOW_OPTIONS';
export const UPDATE_DEPARTMENT_OPTIONS = 'UPDATE_DEPARTMENT_OPTIONS';
export const UPDATE_DIRECTOR_OPTIONS = 'UPDATE_DIRECTOR_OPTIONS';
export const UPDATE_MEMBER_OPTIONS = 'UPDATE_MEMBER_OPTIONS';
export const UPDATE_PROJECT_ID_OPTIONS = 'UPDATE_PROJECT_ID_OPTIONS';
export const UPDATE_PROJECT_CODE_OPTIONS = 'UPDATE_PROJECT_CODE_OPTIONS';
export const UPDATE_PROJECT_TYPE_OPTIONS = 'UPDATE_PROJECT_TYPE_OPTIONS';
export const UPDATE_PROJECT_MANAGER_OPTIONS = 'UPDATE_PROJECT_MANAGER_OPTIONS';
export const UPDATE_TODO_TYPE_OPTIONS = 'UPDATE_TODO_TYPE_OPTIONS';
export const UPDATE_TODO_TAG_DICTIONARY = 'UPDATE_TODO_TAG_DICTIONARY';
export const UPDATE_ACTIVITY_IDEA_TYPE_OPTIONS = 'UPDATE_ACTIVITY_IDEA_TYPE_OPTIONS';
export const UPDATE_ACTIVITY_IDEA_TAG_DICTIONARY = 'UPDATE_ACTIVITY_IDEA_TAG_DICTIONARY';
export const UPDATE_CUSTOMER_INDUSTRIES = 'UPDATE_CUSTOMER_INDUSTRIES';
export const UPDATE_MEDIA_ID_OPTIONS = 'UPDATE_MEDIA_ID_OPTIONS';
export const UPDATE_MEDIA_TYPES = 'UPDATE_MEDIA_TYPES';
export const UPDATE_VENDOR_ID_OPTIONS = 'UPDATE_VENDOR_ID_OPTIONS';
export const UPDATE_VENDOR_ID_OPTIONS_TAXID = 'UPDATE_VENDOR_ID_OPTIONS_TAXID';
export const UPDATE_VENDOR_TYPE_OPTIONS = 'UPDATE_VENDOR_TYPE_OPTIONS';
export const UPDATE_VENDOR_TAG_DICTIONARY = 'UPDATE_VENDOR_TAG_DICTIONARY';
export const UPDATE_VENUE_TYPE_OPTIONS = 'UPDATE_VENUE_TYPE_OPTIONS';
export const UPDATE_VENUE_EQUIPMENTS = 'UPDATE_VENUE_EQUIPMENTS';
export const UPDATE_VENUE_CHARACTERISTICS = 'UPDATE_VENUE_CHARACTERISTICS';
export const UPDATE_JOURNALIST_TYPES = 'UPDATE_JOURNALIST_TYPES';
export const UPDATE_JOURNALIST_ID_OPTIONS = 'UPDATE_JOURNALIST_ID_OPTIONS';
export const UPDATE_EXPENSE_TYPE_OPTIONS = 'UPDATE_EXPENSE_TYPE_OPTIONS';
export const UPDATE_EXPENSE_TAG_DICTIONARY = 'UPDATE_EXPENSE_TAG_DICTIONARY';
export const UPDATE_SPECIAL_FEATURES = 'UPDATE_SPECIAL_FEATURES';
export const UPDATE_DECLARE_TYPE_OPTIONS = 'UPDATE_DECLARE_TYPE_OPTIONS';
export const UPDATE_WORKINGHOURS_TYPE_OPTIONS = 'UPDATE_WORKINGHOURS_TYPE_OPTIONS';
export const UPDATE_WORKINGHOURS_TAG_DICTIONARY = 'UPDATE_WORKINGHOURS_TAG_DICTIONARY';
