export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const AZURE_LOGIN_START = 'AZURE_LOGIN_START';
export const AZURE_LOGIN_FAIL = 'AZURE_LOGIN_FAIL';
export const AZURE_LOGIN_SUCCESS = 'AZURE_LOGIN_SUCCESS';
export const AZURE_SYNC_START = 'AZURE_SYNC_START';
export const AZURE_SYNC_FAIL = 'AZURE_SYNC_FAIL';
export const AZURE_SYNC_SUCCESS = 'AZURE_SYNC_SUCCESS';
export const CLOSE_AZURE_SYNC_POP = 'CLOSE_AZURE_SYNC_POP';
