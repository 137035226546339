import React from 'react';
import styled from 'styled-components';

import DateSelector from '@/components/UI/DateSelector';
import SelectNormal from '@/components/UI/Select/SelectNormal';
import { DivPopupWrapper, DivPopup } from '@/component-style/Popup';
import { ButtonBasic, ButtonCancel } from '@/component-style/Button';
import { DivFlexRow } from '@/component-style/RWDWrapper';
import { formatTime } from '@/utils/timeUtils';

const DivSelectWrapper = styled(DivFlexRow)`
  padding: 0;
  margin-bottom: 10px;

  & > div:first-of-type,
  .ant-select {
    width: 100%;
  }
`;

const DivButtonsWrapper = styled.div`
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;


const H3Title = styled.h3`
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: #8b8b8b;
  margin-bottom: 10px;
`;

const PTexts = styled.p`
  min-width: 80px;
  font-size: 16px;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0.43px;
  color: #8b8b8b;

  span { color: #de2027 }
`;

const ButtonConfirm = styled(ButtonBasic)`
  width: 120px;
`;

const ButtonDiscard = styled(ButtonCancel)`
  width: 120px;
  margin-right: 10px;
`;

const PopupHolidayDetail = props => {
  const { newDayDetail } = props;

  const savable = newDayDetail.type !== ''
    && newDayDetail.year !== null
    && newDayDetail.date !== null;

  const disabledDate = current => formatTime(current, 'YYYY') !== formatTime(newDayDetail.year, 'YYYY');

  return (
    <DivPopupWrapper>
      <DivPopup>
        <H3Title>{ props.title }</H3Title>
        <DivSelectWrapper>
          <PTexts>類別<span>*</span></PTexts>
          <SelectNormal
            value = { newDayDetail.type }
            options = {[
              { value: '', desc: '請選擇參數類別' },
              { value: 'workdays', desc: '工作日' },
              { value: 'restdays', desc: '休息日' },
              { value: 'holidays', desc: '例假日' },
              { value: 'nationalHolidays', desc: '國定假日' },
            ]}
            changeHandler = { value => {
              props.updateNewDayDetailHandler('type', value)
            }}
          />
        </DivSelectWrapper>
        <DivSelectWrapper>
          <PTexts>年份<span>*</span></PTexts>
          <DateSelector
            picker = 'year'
            value = { newDayDetail.year }
            changeHandler = { props.updateNewDayYearHandler }
          />
        </DivSelectWrapper>
        <DivSelectWrapper>
          <PTexts>日期<span>*</span></PTexts>
          <DateSelector
            disabled = { newDayDetail.year === null }
            value = { newDayDetail.date }
            disabledDate = { disabledDate }
            changeHandler = { value => {
              props.updateNewDayDetailHandler('date', value)
            }}
          />
        </DivSelectWrapper>
        <DivButtonsWrapper>
          <ButtonDiscard
            onClick = { props.cancelHandler }
          >關閉
          </ButtonDiscard>
          <ButtonConfirm
            disabled = { !savable }
            onClick = { props.confirmHandler }
          >儲存
          </ButtonConfirm>
        </DivButtonsWrapper>
      </DivPopup>
    </DivPopupWrapper>
  );
};

export default PopupHolidayDetail;
