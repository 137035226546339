import React from 'react';
import styled from 'styled-components';

import Checkbox from '@/components/UI/Checkbox';
import { TdItem, TableRow } from '@/component-style/Table';
import { ButtonGoDetail } from '@/component-style/Button';

import { getOptionDesc } from '@/utils/textUtils';

const TrWrapper = styled(TableRow)`
  border-bottom: solid 2px #f2f2f2;
  box-sizing: border-box;
  min-width: 680px;

  &:last-child {
    border-bottom: none;
  }
`;

const Td80 = styled(TdItem)`
  width: 80px;
`;

const Td120 = styled(TdItem)`
  width: 120px;
`;
const Td240 = styled(TdItem)`
  width: 240px;
`;

const Item = props => {
  const { type, categorys } = props;

  const category = getOptionDesc(categorys, type.category);

  return (
    <TrWrapper>
      <Td80>
        <Checkbox
          checked = { type.isChecked }
          changeHandler = { props.checkHandler }
        />
      </Td80>
      <Td120>{ category }</Td120>
      <Td240>{ type.name }</Td240>
      <Td120>{ type.enable ? '啟動' : '停用' }</Td120>
      <Td120>
        {
          <ButtonGoDetail
            onClick = { props.goDetailHandler }
          >...
          </ButtonGoDetail>
        }
      </Td120>
    </TrWrapper>
  );
};

export default Item;
