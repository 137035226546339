import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  deleteJournalists,
  postToGetJournalists,
  postToGetJournalistDetail,
} from '@/api/journalistService';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import {
  FETCH_MEDIA_ID_OPTIONS,
  FETCH_JOURNALIST_TYPES,
} from '@/store/actions/srpOptionsAction';

import MainContent from '@/component-style/MainContent';
import PanelJournalistSearch from '@/components/Panel/PanelJournalistSearch';
import TableJournalists from '@/components/Table/TableJournalists';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import PopupExecuteConfirm from '@/components/UI/Popup/PopupExecuteConfirm';

import {
  getPhoneString,
  getAddressString,
} from '@/utils/textUtils';
import { searchConfigGenerator } from '@/utils/apiRequestUtils';
import { exportJournalistData } from '@/utils/xlsx';

const DivPanelContainer = styled.div`
  margin-bottom: 40px;
`;

const JournalistManagement = props => {
  const dispatch = useDispatch();
  const mediaIDList = useSelector(state => state.srpOptions.mediaIDOptions);
  const journalistTypes = useSelector(state => state.srpOptions.journalistTypeOptions);
  const showSaveFailPopup = useSelector(state => state.apiFail.showSaveFailPopup);
  const apiFailPopupTitle = useSelector(state => state.apiFail.title);
  const apiFailPopupMessage = useSelector(state => state.apiFail.message);

  const [journalists, setJournalists] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [searchConfig, setSearchConfig] = useState({
    mediaID: '',
    name: '',
    city: '',
    area: '',
    email: '',
    online: '',
    type: [],
    amount: 10,
  });

  const [selectAll, setSelectAll] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [showDeleteSuccessPopup, setShowDeleteSuccessPopup] = useState(false);

  const selectedItemNumber = journalists
    .filter(journalist => journalist.isChecked).length;

  const updateConfigHandler = (key, value) => {
    const updateObj = { ...searchConfig };
    updateObj[key] = value;
    setSearchConfig({ ...updateObj });
  };

  const setCurrentPageHandler = page => {
    setCurrentPage(page);
    updateJournalists(page);
  };

  const searchHandler = async () => {
    setCurrentPageHandler(1);
  };

  const journalistCheckedHandler = (index, value) => {
    const updateJournalists = [ ...journalists ];
    updateJournalists[index].isChecked = value;
    setJournalists([ ...updateJournalists ]);
  };

  const selectAllHandler = value => {
    const updateCustomers = journalists
      .map(journalist => ({ ...journalist, isChecked: value }));
    setSelectAll(value);
    setJournalists([...updateCustomers]);
  };

  const clearHandler = () => {
    setSearchConfig({
      mediaID: '',
      name: '',
      city: '',
      area: '',
      email: '',
      online: '',
      type: [],
      amount: 10,
    });
  };

  const getMediaName = mediaID => mediaID === '' || mediaID === undefined
    ? ''
    : mediaIDList
        .filter(({ value }) => value === mediaID)
        .pop().desc;

  const exportHandler = async () => {
    setShowLoadingPopup(true);
    const journalistDatas = [];

    try {
      const { data } = await postToGetJournalists(
        searchConfigGenerator({
          ...searchConfig, page: 1, amount: totalCount || 1,
        })
      );
      const { journalists } = data;

      for (let i = 0; i < journalists.length; i += 1) {
        const { journalistID, mediaID } = journalists[i];
        const { data } = await postToGetJournalistDetail(journalistID);
        journalistDatas.push([
          getMediaName(mediaID),
          data.name,
          data.englishName,
          data.jobTitle,
          data.jobTitleEng,
          data.online ? '是' : '否',
          getPhoneString(data.businessPhone),
          data.fax || '',
          data.businessMobile || '',
          data.privateMobile || '',
          data.email1 || '',
          data.email2 || '',
          data.page || '',
          getAddressString(data.companyAddress),
          getAddressString(data.privateAddress),
          data.birthday === '0001-01-01' ? '' : data.birthday,
          data.facebook || '',
          data.lineId || '',
          data.skype || '',
          data.otherCommunicationApp || '',
          data.jobDesc || '',
          data.note || '',
        ]);
      }

    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
    exportJournalistData(journalistDatas);
  };

  const deleteJournalistsHandler = async () => {
    setShowDeletePopup(false);
    setShowLoadingPopup(true);

    try {
      const selectedJournalists = journalists
        .filter(journalist => journalist.isChecked)
        .map(journalist => journalist.journalistID);

      await deleteJournalists(selectedJournalists);
      setShowLoadingPopup(false);
      setShowDeleteSuccessPopup(true);
    } catch(err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '刪除失敗', err }));
      console.log(err);
    }
  };

  const updateJournalists = async (page = currentPage) => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetJournalists(
        searchConfigGenerator({ ...searchConfig, page })
      );
      const { journalists, totalCount } = data;
      const journalistList = journalists
        .map(journalist => ({ ...journalist, isChecked: false }));

      setJournalists([ ...journalistList ]);
      setTotalCount(totalCount || 0);
    } catch(err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const initJournalists = useCallback(
    updateJournalists, []);

  const initOptions = useCallback(() => {
    dispatch(FETCH_MEDIA_ID_OPTIONS());
    dispatch(FETCH_JOURNALIST_TYPES());
  }, [dispatch]);

  useEffect(() => {
    initOptions();
    initJournalists();
  }, [initJournalists, initOptions]);

  return (
    <MainContent>
      <DivPanelContainer>
        <PanelJournalistSearch
          config = { searchConfig }
          mediaIDOptions = { mediaIDList }
          journalistTypes = { journalistTypes }
          updateConfigHandler = { updateConfigHandler }
          clearHandler = { clearHandler }
          exportHandler = { exportHandler }
          searchHandler = { searchHandler }
        />
      </DivPanelContainer>
      <TableJournalists
        disableDelete = { selectedItemNumber === 0 }
        journalists = { journalists }
        selectAll = { selectAll }
        selectAllHandler = { selectAllHandler }
        totalCount = { totalCount }
        currentPage = { currentPage }
        checkHandler = { journalistCheckedHandler }
        deleteHandler = { () =>  {
          setShowDeletePopup(true);
        }}
        pageChangedHandler = { value => {
          selectAllHandler(false);
          setCurrentPageHandler(value);
        }}
      />
      { showLoadingPopup && <PopupLoading/> }
      {
        showDeletePopup &&
          <PopupExecuteConfirm
            title = "刪除記者名單"
            text = { `確定刪除${ selectedItemNumber }個項目` }
            confirmHandler = { deleteJournalistsHandler }
            cancelHandler = { () => {
              setShowDeletePopup(false);
            }}
          />
      }
      {
        showDeleteSuccessPopup &&
          <PopupExecuteResult
            title = "刪除成功"
            confirmHandler = { () => {
              setShowDeleteSuccessPopup(false);
              updateJournalists();
            }}
          />
      }
      {
        showSaveFailPopup &&
          <PopupExecuteResult
            title = { apiFailPopupTitle }
            text = { apiFailPopupMessage }
            confirmHandler = { () => {
              dispatch(CLOSE_SAVE_FAIL_POPUP());
            }}
          />
      }
    </MainContent>
  );
};

export default JournalistManagement;
