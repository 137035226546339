import React, { useCallback, useEffect, useState, } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { postToGetExpenses } from '@/api/expenseService';
import { postToGetProjectDetail } from '@/api/projectService';

import {
  FETCH_MEMBER_OPTIONS,
  FETCH_VENDOR_ID_OPTIONS,
  FETCH_PROJECT_ID_OPTIONS,
} from '@/store/actions/srpOptionsAction';

import MainContent from '@/component-style/MainContent';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PanelProxyApprovalSearch from '@/components/Panel/Expense/PanelProxyApprovalSearch';
import TableExpenseFormList from '@/components/Table/Expense/TableExpenseFormList';

import { integerConverter } from '@/utils/textUtils';
import { searchConfigGenerator } from '@/utils/apiRequestUtils';

const DivPanelContainer = styled.div`
  margin-bottom: 40px;
`;

const config = {
  user: '',
  object: '',
  objectID: '',
  projectID: '',
  expenseCode: '',
  approvalStatus: [
    'projectManagerVerify',
    'projectDirectorVerify',
    'generalManagerVerifying',
  ],
  amount: 10,
}

const ExpenseProxyApproval = props => {
  const history = useHistory();
  const dispatch = useDispatch();
  const userID = useSelector(state => state.login.token);
  const vendorIDs = useSelector(state => state.srpOptions.vendorIDOptionsTaxID);
  const memberOptions = useSelector(state => state.srpOptions.memberOptions);
  const projectIDOptions = useSelector(state => state.srpOptions.projectIDOptions);

  const [expenses, setExpenses] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [searchConfig, setSearchConfig] = useState({ ...config });

  const [userFilter, setUserFilter] = useState('self');
  const [objectFilter, setObjectFilter] = useState('');
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);

  const objectList = () => {
    if (objectFilter === 'user') return memberOptions;
    if (objectFilter === 'vendor') return vendorIDs;

    return [];
  };

  const updateConfigHandler = (key, value) => {
    const updateObj = { ...searchConfig };
    updateObj[key] = value;
    setSearchConfig({ ...updateObj });
  };

  const userFilterChangeHandler = value => {
    setUserFilter(value);
    setSearchConfig(prevState => ({
      ...prevState,
      user: value === 'self' ? userID : '',
    }));
  };

  const objectFilterChangeHandler = value => {
    setObjectFilter(value);
    setSearchConfig(prevState => ({
      ...prevState,
      object: value,
      objectID: '',
    }));
  };

  const clearHandler = () => {
    setSearchConfig({...config});
    setUserFilter('self');
    setObjectFilter('');
  };

  const searchHandler = () => {
    setCurrentPageHandler(1);
  };

  const setCurrentPageHandler = page => {
    setCurrentPage(page);
    fetchExpenses(page);
  };

  const fetchExpenses = async (page = currentPage) => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetExpenses(searchConfigGenerator({
        ...searchConfig,
        page,
        user: searchConfig.user === '' ? [] : [searchConfig.user],
        expenseCode: integerConverter(searchConfig.expenseCode),
      }));

      const { expenses, totalCount } = data;
      const expenseForms = [];

      for (let i = 0; i < expenses.length; i += 1) {
        const expense = expenses[i];
        const { projectID, applyDate } = expense;
        const { data } = await postToGetProjectDetail(projectID);
        const { projectCode, projectName } = data;
        expenseForms.push({
          ...expense,
          projectCode,
          projectName,
          applyDate: applyDate === '0001-01-01' ? '' : applyDate,
        });
      };

      setExpenses([...expenseForms]);
      setTotalCount(totalCount);
    } catch(err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const initExpenses = useCallback(
    fetchExpenses, []);

  const initOptions = useCallback(() => {
    dispatch(FETCH_MEMBER_OPTIONS());
    dispatch(FETCH_VENDOR_ID_OPTIONS());
    dispatch(FETCH_PROJECT_ID_OPTIONS());
  }, [dispatch]);

  useEffect(() => {
    initOptions();
    initExpenses();
  }, [initExpenses, initOptions]);

  return (
    <MainContent>
      <DivPanelContainer>
        <PanelProxyApprovalSearch
          config = { searchConfig }
          userFilter = { userFilter }
          objectList = { objectList() }
          memberList = { memberOptions }
          objectFilter = { objectFilter }
          projectIDOptions = { projectIDOptions }
          clearHandler = { clearHandler }
          searchHandler = { searchHandler }
          updateConfigHandler = { updateConfigHandler }
          userFilterChangeHandler = { userFilterChangeHandler }
          objectFilterChangeHandler = { objectFilterChangeHandler }
        />
      </DivPanelContainer>
      <TableExpenseFormList
        expenses = { expenses }
        totalCount = { totalCount }
        currentPage = { currentPage }
        pageChangedHandler = { value => {
          setCurrentPageHandler(value);
        }}
        goDetailHandler = { id => {
          history.push(`/Expense/ExpenseApprovalDetail/${id}`)
        }}
      />
      { showLoadingPopup && <PopupLoading/> }
    </MainContent>
  )
};

export default ExpenseProxyApproval;
