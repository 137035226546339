import React from 'react';
import { Route } from 'react-router-dom';

import CustomerManagement from '../containers/informationModule/customerManagement';
import CustomerCreation from '../containers/informationModule/customerCreation';
import CustomerDetail from '../containers/informationModule/customerDetail';
import ContactWindowCreation from '../containers/informationModule/contactWindowCreation';
import ContactWindowDetail from '../containers/informationModule/contactWindowDetail';
import VendorManagement from '../containers/informationModule/vendorManagement';
import VendorCreation from '../containers/informationModule/vendorCreation';
import VendorDetail from '../containers/informationModule/vendorDetail';
import VendorRecords from '../containers/informationModule/vendorRecords';
import MediaManagement from '../containers/informationModule/mediaManagement';
import MediaCreation from '../containers/informationModule/mediaCreation';
import MediaDetail from '../containers/informationModule/mediaDetail';
import JournalistManagement from '../containers/informationModule/journalistManagement';
import JournalistCreation from '../containers/informationModule/journalistCreation';
import JournalistDetail from '../containers/informationModule/journalistDetail';
import JournalistRecords from '../containers/informationModule/journalistRecords';
import VenueManagement from '../containers/informationModule/venueManagement';
import VenueCreation from '../containers/informationModule/venueCreation';
import VenueDetail from '../containers/informationModule/venueDetail';

const informationRoutes = [
  {
    key: 'CustomerManagement',
    path: '/Information/CustomerManagement',
    component: CustomerManagement,
  },
  {
    key: 'CustomerCreation',
    path: '/Information/CustomerCreation',
    component: CustomerCreation,
  },
  {
    key: 'CustomerDetail',
    path: '/Information/CustomerDetail/:id',
    component: CustomerDetail,
  },
  {
    key: 'CustomerWindowCreation',
    path: '/Information/CustomerWindowCreation/:id',
    component: ContactWindowCreation,
  },
  {
    key: 'CustomerWindowDetail',
    path: '/Information/CustomerWindowDetail/:id',
    component: ContactWindowDetail,
  },
  {
    key: 'VendorManagement',
    path: '/Information/VendorManagement',
    component: VendorManagement,
  },
  {
    key: 'VendorCreation',
    path: '/Information/VendorCreation',
    component: VendorCreation,
  },
  {
    key: 'VendorDetail',
    path: '/Information/VendorDetail/:id',
    component: VendorDetail,
  },
  {
    key: 'VendorRecords',
    path: '/Information/VendorRecords/:id',
    component: VendorRecords,
  },
  {
    key: 'VendorWindowCreation',
    path: '/Information/VendorWindowCreation/:id',
    component: ContactWindowCreation,
  },
  {
    key: 'VendorWindowDetail',
    path: '/Information/VendorWindowDetail/:id',
    component: ContactWindowDetail,
  },
  {
    key: 'MediaManagement',
    path: '/Information/MediaManagement',
    component: MediaManagement,
  },
  {
    key: 'MediaCreation',
    path: '/Information/MediaCreation',
    component: MediaCreation,
  },
  {
    key: 'MediaDetail',
    path: '/Information/MediaDetail/:id',
    component: MediaDetail,
  },
  {
    key: 'JournalistManagement',
    path: '/Information/JournalistManagement',
    component: JournalistManagement,
  },
  {
    key: 'JournalistCreation',
    path: '/Information/JournalistCreation',
    component: JournalistCreation,
  },
  {
    key: 'JournalistDetail',
    path: '/Information/JournalistDetail/:id',
    component: JournalistDetail,
  },
  {
    key: 'JournalistRecords',
    path: '/Information/JournalistRecords/:id',
    component: JournalistRecords,
  },
  {
    key: 'VenueManagement',
    path: '/Information/VenueManagement',
    component: VenueManagement,
  },
  {
    key: 'VenueCreation',
    path: '/Information/VenueCreation',
    component: VenueCreation,
  },
  {
    key: 'VenueDetail',
    path: '/Information/VenueDetail/:id',
    component: VenueDetail,
  },
];

const RoutesInformationModule = validRoutes => informationRoutes
  .filter(({ key }) => validRoutes.indexOf(key) !== -1)
  .map(({ path, component }) =>
    <Route
      key = { path }
      path = { path }
      component = { component }
    />
  );

export default RoutesInformationModule;
