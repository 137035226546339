import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Checkbox from '@/components/UI/Checkbox';
import { TdItem, TableRow } from '@/component-style/Table';
import { ButtonGoDetail } from '@/component-style/Button';
import { getOptionDesc } from '@/utils/textUtils';

const TrWrapper = styled(TableRow)`
  border-bottom: solid 2px #f2f2f2;
  box-sizing: border-box;
  min-width: 940px;

  &:last-child {
    border-bottom: none;
  }
`;

const Td80 = styled(TdItem)`
  width: 80px;
`;

const Td120 = styled(TdItem)`
  width: 120px;
`;

const Td300 = styled(TdItem)`
  width: 300px;
  text-align: center;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Item = props => {
  const { activityIdea } = props;
  const activityIdeaTypes = useSelector(state => state.srpOptions.activityIdeaTypeOptions);

  const activityIdeaType = getOptionDesc(activityIdeaTypes, activityIdea.activityIdeaTypeID);

  return (
    <TrWrapper>
      <Td120>{ activityIdea.projectCode }</Td120>
      <Td120>{ activityIdeaType }</Td120>
      <Td120>{ activityIdea.tag }</Td120>
      <Td300>{ activityIdea.content }</Td300>
      <Td120>{ activityIdea.budget }</Td120>
      <Td80>
        <Checkbox
          disabled = { true }
          checked = { activityIdea.executed }
        />
      </Td80>
      <Td80>
        <ButtonGoDetail
          disabled = { props.disableDetailPage }
          onClick = { props.goDetailHandler }
        >...
        </ButtonGoDetail>
      </Td80>
    </TrWrapper>
  );
};

export default Item;
