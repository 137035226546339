import React from 'react';
import styled from 'styled-components';

import Checkbox from '@/components/UI/Checkbox';
import { TdItem, TableRow } from '@/component-style/Table';
import { ButtonGoDetail } from '@/component-style/Button';

const TrWrapper = styled(TableRow)`
  border-bottom: solid 2px #f2f2f2;
  box-sizing: border-box;
  min-width: 680px;

  &:last-child {
    border-bottom: none;
  }
`;

const Td80 = styled(TdItem)`
  width: 80px;
`;

const Td120 = styled(TdItem)`
  width: 120px;
`;
const Td240 = styled(TdItem)`
  width: 240px;
`;

const Item = props => {
  const { expenseType } = props;

  return (
    <TrWrapper>
      <Td80>
        <Checkbox
          checked = { expenseType.isChecked }
          changeHandler = { props.checkHandler }
        />
      </Td80>
      <Td240>{ expenseType.name }</Td240>
      <Td120>{ expenseType.enable ? '啟動' : '停用' }</Td120>
      <Td120>
        <ButtonGoDetail
          onClick = { props.goDetailHandler }
        >...
        </ButtonGoDetail>
      </Td120>
      <Td120>
        <ButtonGoDetail
          disabled = { props.disableTagPage }
          onClick = { props.goTagsHandler }
        >...
        </ButtonGoDetail>
      </Td120>
    </TrWrapper>
  );
};

export default Item;
