import React from 'react';
import styled from 'styled-components';

import { TableHead, TableRow, ThItem } from '@/component-style/Table';

const THead = styled(TableHead)`
  min-width: 420px;
  height: 40px;
  line-height: 36px;
`;

const Th100 = styled(ThItem)`
  width: 100px;
`;

const Th200 = styled(ThItem)`
  width: 200px;
`;

const Header = props => {
  
  return (
    <THead>
      <TableRow>
        <Th200>{ props.fileDescTitle || '檔案名稱' }</Th200>
        <Th100></Th100>
        <Th100></Th100>
      </TableRow>
    </THead>
  );
};

export default Header;
