import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import PanelWrapper from '@/components/UI/PanelWrapper';
import SelectNormal from '@/components/UI/Select/SelectNormal';
import DatePeriodSelector from '@/components/UI/DatePeriodSelector';
import SearchButtonSet from '@/components/UI/SearchButtonSet';
import { InputBasic } from '@/component-style/Input';
import { ButtonBasic } from '@/component-style/Button';
import { PSubtitle } from '@/component-style/TextElement';
import {
  DivFlexRow,
  DivItemWrapper,
  DivLongMultiSelector,
} from '@/component-style/RWDWrapper';

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding: 0;
  justify-content: space-between;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const ButtonExport = styled(ButtonBasic)`
  width: 120px;

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`;

const DivBudgetWrapper = styled.div`
  width: 300px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 320px) { width: 260px }
  @media (min-width: 1920px) { width: 622px }
`;

const InputBudget = styled(InputBasic)`
  width: 120px;

  @media (max-width: 320px) { width: 100px }
  @media (min-width: 1920px) { width: 300px }
`;

const PSpacer = styled.p`
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  color: #767676;
`;

const PanelIncomeInvoiceSearch = props => {
  const financeRoutes = useSelector(state => state.login.financeRoutes);
  const { config } = props;

  const userExportable = financeRoutes.indexOf('InvoiceSearch-Export') !== -1;

  const dateStartChangeHandler = value => {
    props.updateConfigHandler('date', {
      ...config.date,
      start: value,
    });
  };

  const dateEndChangeHandler = value => {
    props.updateConfigHandler('date', {
      ...config.applyDate,
      end: value,
    });
  };

  return (
    <PanelWrapper title = "進項發票查詢">
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>發票字軌</PSubtitle>
          <InputBasic
            maxLength = "2"
            value = { config.voucherPrefix }
            onChange = { ({ target }) => {
              props.updateConfigHandler('voucherPrefix', target.value)
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>發票號碼</PSubtitle>
          <DivBudgetWrapper>
            <InputBudget
              type = "number"
              value = { config.voucherCode.start }
              onChange = { ({ target }) => {
                props.updateConfigHandler('voucherCode', {
                  ...config.voucherCode,
                  start: target.value
                });
              }}
            />
            <PSpacer>~</PSpacer>
            <InputBudget
              type = "number"
              value = { config.voucherCode.end }
              onChange = { ({ target }) => {
                props.updateConfigHandler('voucherCode', {
                  ...config.voucherCode,
                  end: target.value
                });
              }}
            />
          </DivBudgetWrapper>
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>營業稅申報</PSubtitle>
          <SelectNormal
            value = { config.notForBusinessTax }
            options = {[
              { value: '', desc: '請選擇'},
              { value: true, desc: '否'},
              { value: false, desc: '是'},
            ]}
            changeHandler = { value => {
              props.updateConfigHandler('notForBusinessTax', value)
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>所屬公司</PSubtitle>
          <SelectNormal
            value = { config.companyType}
            options = {[
              { value: '', desc: '請選擇所屬公司'},
              ...props.companyTypeOptions,
            ]}
            changeHandler = { value => {
              props.updateConfigHandler('companyType', value)
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>發票日期</PSubtitle>
          <DivLongMultiSelector>
            <DatePeriodSelector
              startTime = { config.date.start }
              endTime = { config.date.end }
              endTimeOKHandler = { dateEndChangeHandler }
              endTimeChangeHandler = { dateEndChangeHandler }
              startTimeOKHandler = { dateStartChangeHandler }
              startTimeChangeHandler = { dateStartChangeHandler }
            />
          </DivLongMultiSelector>
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivButtonsWrapper>
          <ButtonExport
            disabled = { !userExportable }
            onClick = { props.exportHandler }
          >匯出</ButtonExport>
          <SearchButtonSet
            clearHandler = { props.clearHandler }
            searchHandler = { props.searchHandler }
          />
        </DivButtonsWrapper>
      </DivFlexRow>
    </PanelWrapper>
  );
};

export default PanelIncomeInvoiceSearch;
