import React from 'react';
import styled from 'styled-components';

import Checkbox from '@/components/UI/Checkbox';
import { TdItem, TableRow } from '@/component-style/Table';
import { ButtonGoDetail } from '@/component-style/Button';

const TrWrapper = styled(TableRow)`
  border-bottom: solid 2px #f2f2f2;
  box-sizing: border-box;
  min-width: 920px;

  &:last-child {
    border-bottom: none;
  }
`;

const Td80 = styled(TdItem)`
  width: 80px;
`;

const Td120 = styled(TdItem)`
  width: 120px;
`;

const Td160 = styled(TdItem)`
  width: 160px;
`;

const Td240 = styled(TdItem)`
  width: 240px;
`;

const Item = props => {
  const { vendor } = props;

  return (
    <TrWrapper>
      <Td80>
        <Checkbox
          checked = { vendor.isChecked }
          changeHandler = { props.checkHandler }
        />
      </Td80>
      <Td240>{ vendor.vendorName }</Td240>
      <Td120>{ vendor.vendorTaxId }</Td120>
      <Td160>{ vendor.principal }</Td160>
      <Td160>{ vendor.phone }</Td160>
      <Td80>{ vendor.times }</Td80>
      <Td80>
        <ButtonGoDetail
          disabled = { props.disableDetailPage }
          onClick = { props.goDetailHandler }
        >...
        </ButtonGoDetail>
      </Td80>
    </TrWrapper>
  );
};

export default Item;
