import React from 'react';

import TableWrapper from '@/components/UI/TableWrapper';
import SelectNormal from '@/components/UI/Select/SelectNormal';
import SelectMultiple from '@/components/UI/Select/SelectMultiple';
import { InputBasic } from '@/component-style/Input';
import { PSubtitle } from '@/component-style/TextElement';
import {
  DivFlexRow,
  DivItemWrapper,
  DivFullWidthWrapper,
  DivLongMultiSelector,
} from '@/component-style/RWDWrapper';
import { TableHead, TableRow, ThItem } from '@/component-style/Table';
import styled from 'styled-components';

import { projectRolesOptions, onboardStateOptions } from '@/constant/options';

import { ButtonBasic } from '@/component-style/Button';

const THead = styled(TableHead)`
  min-width: 1560px;
`;

const Th120 = styled(ThItem)`
  width: 120px;
`;

const H3Title = styled.h3`
  margin-top: 35px;
  margin-bottom: 20px;
  padding: 0 30px;
  font-size: 20px;
  font-weight: normal;
  color: #57585a;
`;

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding: 0;
  padding-bottom: 15px;
  justify-content: space-between;

  @media (max-width: 425px) {
    justify-content: center;
  }
`;

const ButtonSave = styled(ButtonBasic)`
  width: 120px;
  margin-bottom: 10px;

  &:first-child { margin-right: 10px }
`;

const PanelMyDetail = props => {
  const { user } = props;
  console.log(`PanelMyDetail.js user=${JSON.stringify(user)}`);

  return (
    <TableWrapper title = { props.title }>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>同仁名稱<span>*</span></PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { user.name }
            onChange = { ({ target }) => {
              props.updateUserHandler('name', target.value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>E-Mail<span>*</span></PSubtitle>
          <InputBasic
            disabled = { true }
            value = { user.email }
            onChange = { ({ target }) => {
              props.updateUserHandler('email', target.value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
      <DivItemWrapper>
          <PSubtitle>所屬公司</PSubtitle>
          <SelectNormal
            disabled = { props.disabled }
            value = { user.companyType }
            options = {[
              { value: '', desc: '請選擇公司' },
              ...props.companyTypeOptions,
            ]}
            changeHandler = { value => {
              props.updateUserHandler('companyType', value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>部門</PSubtitle>
          <SelectNormal
            disabled = { props.disabled }
            value = { user.department }
            options = {[
              { value: '', desc: '請選擇部門' },
              ...props.departmentOptions,
            ]}
            changeHandler = { value => {
              props.updateUserHandler('department', value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>所屬總監</PSubtitle>
          <SelectNormal
            disabled = { props.disabled }
            value = { user.director }
            options = {[
              { value: '', desc: '請選擇總監' },
              ...props.directorOptions,
            ]}
            changeHandler = { value => {
              props.updateUserHandler('director', value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>E-Mail(私)<span>*</span></PSubtitle>
          <InputBasic
            value = { user.emailPrivate }
            onChange = { ({ target }) => {
              props.updateUserHandler('emailPrivate', target.value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>行動電話</PSubtitle>
          <InputBasic
            // disabled = { props.disabled }
            value = { user.phone }
            onChange = { ({ target }) => {
              props.updateUserHandler('phone', target.value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>通訊地址</PSubtitle>
          <InputBasic
            // disabled = { props.disabled }
            value = { user.addressForMail }
            onChange = { ({ target }) => {
              props.updateUserHandler('addressForMail', target.value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivButtonsWrapper>
          <div>
          </div>
          <div>
            <ButtonSave
              onClick = { () => { props.updateUserData(); } }
            >
              儲存
            </ButtonSave>
          </div>
        </DivButtonsWrapper>
      </DivFlexRow>
    </TableWrapper>
  );
};

export default PanelMyDetail;
