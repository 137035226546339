import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { cloneDeep } from 'lodash';
import { getProjectProfit, getProjectProfitExcel } from '@/api/financeService';
import {
  postToGetProjectList,
} from '@/api/projectService';

import { FETCH_COMPANY_TYPE_OPTIONS } from '@/store/actions/srpOptionsAction';

import PanelRevenueByProjectSearch from '@/components/Panel/Finance/PanelRevenueByProjectSearch';
import TableRevenueByProject from '@/components/Table/Finance/TableRevenueByProject';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';

const DivPanelContainer = styled.div`
  margin-bottom: 40px;
`;

const searchConfig = {
  companyType: '',
  projectID: [],
};

const RevenueByProjectSearch = () => {
  const dispatch = useDispatch();
  const companyTypeOptions = useSelector(state => state.srpOptions.companyTypeOptions);

  const [vouchers, setVouchers] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [allProjectOptions, setAllProjectOptions] = useState([]);
  const [projectOptions, setprojectOptions] = useState([]);

  const [config, setConfig] = useState(cloneDeep(searchConfig));

  const [showLoadingPopup, setShowLoadingPopup] = useState(false);

  const [alertText, setAlertText] = useState('');
  const [showAlertPopup, setShowAlertPopup] = useState(false);
  

  const openAlertPopup = text => {
    setAlertText(text);
    setShowAlertPopup(true);
  };

  const updateConfigHandler = (key, value) => {
    const updateObj = { ...config };
    updateObj[key] = value;
    console.log("revenueByProjectSearch.js updateConfigHandler companyTypeID:" + value);
    if (key === "companyType") {
      const companyHasResult = allProjectOptions
                                .filter(single => single.companyType === value)
                                .map(elem => (
                                  {
                                    value: elem.projectID,
                                    desc: elem.projectCode + "-" + elem.projectName,
                                  } 
                                ));
      setprojectOptions(companyHasResult);
    }
    setConfig({ ...updateObj }); 
  };

  const clearHandler = () => {
    setVouchers([]);
    setTotalCount(0);
    setConfig(cloneDeep(searchConfig));
  };

  const searchHandler = async () => {
    setCurrentPageHandler(1);
  };

  const setCurrentPageHandler = page => {
    setCurrentPage(page);
    fetchExpenseVouchers(page);
  };

  const exportHandler = async () => {
    console.log("exportHandler config.companyType:" + config.companyType);
    setShowLoadingPopup(true);

    try {
      const { data } = await getProjectProfitExcel(
        {
          companyTypeID: config.companyType,
          projectID: config.projectID.join(","),
        }
      );
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'ProjectProfit.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      setShowLoadingPopup(false);
      console.log(JSON.stringify(err));
    }
    setShowLoadingPopup(false);
  };

  const fetchExpenseVouchers = async (page = currentPage) => {
    if (config.companyType === "" || config.companyType == null) {
      return openAlertPopup("請選擇公司");
    }

    setShowLoadingPopup(true);
    
    try {
      const { data } = await getProjectProfit(
        {
          companyTypeID: config.companyType,
          projectID: config.projectID.join(","),
        }
      );

      // const { vouchers } = data;

      setVouchers(data);
      setTotalCount(1);
    } catch(err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const initOptions = useCallback( async () => {
    dispatch(FETCH_COMPANY_TYPE_OPTIONS());
    try {
      const { data } = await postToGetProjectList(
        {
          "actionTimeStart": {start: null, end: null},
          "amount": 10000,
          "page": 1,
        }
      );
      // console.log(JSON.stringify(data));
      setAllProjectOptions(data.projects);
    } catch(err) { console.log(err) }
  }, [dispatch]);

  useEffect(() => {
    initOptions();
  }, [initOptions]);

  return(
    <>
      <DivPanelContainer>
        <PanelRevenueByProjectSearch
          config = { config }
          companyTypeOptions = { companyTypeOptions }
          projectIDOptions = { projectOptions }
          clearHandler = { clearHandler }
          exportHandler = { exportHandler }
          searchHandler = { searchHandler }
          updateConfigHandler = { updateConfigHandler }
        />
      </DivPanelContainer>
      <TableRevenueByProject
        vouchers = { vouchers }
        totalCount = { totalCount }
        currentPage = { currentPage }
        pageChangedHandler = { setCurrentPageHandler }
      />
      { showLoadingPopup && <PopupLoading/> }
      {
        showAlertPopup &&
          <PopupExecuteResult
            title = "請選擇欄位"
            text = { alertText }
            confirmHandler = { () => { setShowAlertPopup(false) } }
          />
      }
    </>
  );
};

export default RevenueByProjectSearch;
