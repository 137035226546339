import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { FETCH_COMPANY_TYPE_OPTIONS } from '@/store/actions/srpOptionsAction';

import PanelAllLeaveTypeSearch from '@/components/Panel/HR/PanelAllLeaveTypeSearch';

import PopupLoading from '@/components/UI/Popup/PopupLoading';

import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';

import { 
  getLeaveApplications,
  postInitiateProcessToGetLeaveOptions,
  getLeaveOptionsStatusByJobId,
  // getLeaveOptionsResultsByJobId,
  getLeaveOptionsResultsExcelByJobId,
} from '@/api/financeService';

import moment from 'moment';

import { exportAllLeaveTypesXLS } from '@/utils/xlsx';


const DivPanelContainer = styled.div`
  margin-bottom: 40px;
`;

const AllUserLeaveType = () => {
  const dispatch = useDispatch();
  const companyTypeOptions = useSelector(state => state.srpOptions.companyTypeOptions);
  // console.log("RevenueByCompanySearch companyTypeOptions:" + JSON.stringify(companyTypeOptions));


  const [allLeaves, setAllLeaves] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [showLoadingPopup, setShowLoadingPopup] = useState(false);

  const [alertText, setAlertText] = useState('');
  const [showAlertPopup, setShowAlertPopup] = useState(false);

  const [searchConfig, setSearchConfig] = useState({
    companyType: '',
    startMonth: moment().startOf('month').format('YYYY-MM-DD'),
    endMonth: moment().add(1, 'months').endOf('month').format('YYYY-MM-DD'),
  });

  const [users, setUsers] = useState([]);

  var intervalId = 0;
  var jobId = 0;
  

  const openAlertPopup = text => {
    setAlertText(text);
    setShowAlertPopup(true);
  };

  const exportSalaryListXLSHandler = async() => {
    console.log(`allUserLeaveType.js exportSalaryListXLSHandler()`);
    intervalId = setInterval(fetchJobStatus, 2000);
    setShowLoadingPopup(true);
    const { data } = await postInitiateProcessToGetLeaveOptions(searchConfig.companyType);
    console.log(`allUserLeaveType.js exportSalaryListXLSHandler JSON.stringify(data)=${JSON.stringify(data)}`);
    jobId = data.jobId;
  };

  const fetchJobStatus = async() => {
    const { data } = await getLeaveOptionsStatusByJobId(jobId);
    console.log(`allUserLeaveType.js fetchJobStatus() JSON.stringify(data)=${JSON.stringify(data)}`);
    if (data.status === "completed") {
      clearInterval(intervalId);
      const { data } = await getLeaveOptionsResultsExcelByJobId(jobId);
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'AllUsersLeaveApplications.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setShowLoadingPopup(false);
      // clearInterval(intervalId);
      // const { data } = await getLeaveOptionsResultsByJobId(jobId);
      // console.log(`allUserLeaveType.js fetchJobStatus() JSON.stringify(data)=${JSON.stringify(data)}`);
      // setShowLoadingPopup(false);
      // var allLeaveOptionsId = [];
      // for (let indexUser = 0; indexUser < data.users.length; indexUser += 1) {
      //   for (let indexLeaveOption = 0; indexLeaveOption < data.users[indexUser].leaveOptions.length; indexLeaveOption += 1) {
      //     // if (allLeaveOptionsId.includes(data.users[indexUser].leaveOptions[indexLeaveOption].id) === false) {
      //     if (!allLeaveOptionsId.some(e => e.id === data.users[indexUser].leaveOptions[indexLeaveOption].id)) {
      //       allLeaveOptionsId.push({id: data.users[indexUser].leaveOptions[indexLeaveOption].id,
      //         name: data.users[indexUser].leaveOptions[indexLeaveOption].name
      //       });
      //     }
      //   }
      // }
      // console.log(`allUserLeaveType.js fetchJobStatus() JSON.stringify(allLeaveOptionsId)=${JSON.stringify(allLeaveOptionsId)}`);
      // const dataLeaveOptions = [];
      // const header = allLeaveOptionsId.map(leaveOption => leaveOption.name);
      // dataLeaveOptions.push([
      //   "姓名",
      //   ...header,
      // ]);

      // var userName = "";
      // var userLeaveOptions = [];
      // for (let indexUser = 0; indexUser < data.users.length; indexUser += 1) {
      //   userLeaveOptions = [];
      //   userName = data.users[indexUser].username;
      //   userLeaveOptions.push(userName);
      //   for (let indexAllLeaveOptionsId = 0; indexAllLeaveOptionsId < allLeaveOptionsId.length; indexAllLeaveOptionsId += 1) {
      //     const foundIndex = data.users[indexUser].leaveOptions.findIndex(e => e.id === allLeaveOptionsId[indexAllLeaveOptionsId].id);
      //     if (foundIndex > -1) {
      //       console.log(`allUserLeaveType.js fetchJobStatus() JSON.stringify(foundIndex)=${JSON.stringify(foundIndex)}`);
      //       if (data.users[indexUser].leaveOptions[foundIndex].yearlySeconds !== undefined) {
      //         var strLeaveByYear = "";
      //         for (let indexYear = 0; indexYear < data.users[indexUser].leaveOptions[foundIndex].yearlySeconds.length; indexYear += 1) {
      //           strLeaveByYear += data.users[indexUser].leaveOptions[foundIndex].yearlySeconds[indexYear].year + "(" +  Math.round((data.users[indexUser].leaveOptions[foundIndex].yearlySeconds[indexYear].used / 3600) * 100) / 100 + "/" + Math.round((data.users[indexUser].leaveOptions[foundIndex].yearlySeconds[indexYear].available / 3600) * 100) / 100 + ")";
      //         }
      //         userLeaveOptions.push(strLeaveByYear);
      //       } else {
      //         console.log(`allUserLeaveType.js fetchJobStatus() JSON.stringify(data.users[indexUser].username)=${JSON.stringify(data.users[indexUser].username)}`);
      //         console.log(`allUserLeaveType.js fetchJobStatus() JSON.stringify(data.users[indexUser].leaveOptions[foundIndex].usedSeconds)=${JSON.stringify(data.users[indexUser].leaveOptions[foundIndex].usedSeconds)}`);
      //         console.log(`allUserLeaveType.js fetchJobStatus() JSON.stringify(data.users[indexUser].leaveOptions[foundIndex].availableSeconds)=${JSON.stringify(data.users[indexUser].leaveOptions[foundIndex].availableSeconds)}`);
      //         if (data.users[indexUser].leaveOptions[foundIndex].availableSeconds !== undefined) {
      //           userLeaveOptions.push(Math.round((data.users[indexUser].leaveOptions[foundIndex].usedSeconds / 3600) * 100) / 100 + "/" + Math.round((data.users[indexUser].leaveOptions[foundIndex].availableSeconds / 3600) * 100) / 100);
      //         } else {
      //           userLeaveOptions.push("0/0");
      //         }
      //       }
      //     } else {
      //       userLeaveOptions.push("0/0");
      //     }
      //   }
      //   dataLeaveOptions.push(userLeaveOptions);
      // }
      // exportAllLeaveTypesXLS(dataLeaveOptions);
      // if (data.users.length > 0) {
      //   // var leaveOptions = data.users[0].leaveOptions.map(leaveOption => (leaveOption.name));
      //   // console.log(`allUserLeaveType.js fetchJobStatus() JSON.stringify(leaveOptions)=${JSON.stringify(leaveOptions)}`);
      //   var leaveOptionsWithoutSpecialLeave = [];
      //   var leaveOptionsOfSpecialLeave = [];
      //   for (let indexLeaveOption = 0; indexLeaveOption < data.users[0].leaveOptions.length; indexLeaveOption += 1) {
      //     if (data.users[0].leaveOptions[indexLeaveOption].yearlyMinutes !== undefined) {
      //       leaveOptionsOfSpecialLeave = data.users[0].leaveOptions[indexLeaveOption].yearlyMinutes.map(leaveOption => (leaveOption.name));
      //     } else {
      //       leaveOptionsWithoutSpecialLeave.push(data.users[0].leaveOptions[indexLeaveOption].name);
      //     }
      //   }
      //   dataLeaveOptions.push([
      //     "姓名",
      //     ...leaveOptionsOfSpecialLeave,
      //     ...leaveOptionsWithoutSpecialLeave,
      //   ]);
      // }
      // var userName = "";
      // for (let indexUser = 0; indexUser < data.users.length; indexUser += 1) {
      //   userName = data.users[indexUser].username;
      //   var arrayUserLeaveOptionTime = data.users[indexUser].leaveOptions.map(leaveOption => (leaveOption.usedMinutes / 60 + "/" + leaveOption.availableMinutes / 60 ));
      //   dataLeaveOptions.push([
      //     userName,
      //     ...arrayUserLeaveOptionTime,
      //   ]);
      // }
      // exportAllLeaveTypesXLS(dataLeaveOptions);
      // var userName = "";
      // for (let indexUser = 0; indexUser < data.users.length; indexUser += 1) {
      //   userName = data.users[indexUser].username;
      //   for (let indexLeaveOptions = 0; indexLeaveOptions < data.users[indexUser].leaveOptions.length; indexLeaveOptions += 1) {
      //     if ( data.users[indexUser].leaveOptions[indexLeaveOptions].yearlyMinutes !== undefined) {
      //       for (let indexYearlyMinutes = 0; indexYearlyMinutes < data.users[indexUser].leaveOptions[indexLeaveOptions].yearlyMinutes.length; indexYearlyMinutes += 1) {
      //         dataLeaveOptions.push([
      //           userName,
      //           data.users[indexUser].leaveOptions[indexLeaveOptions].name + "(" + data.users[indexUser].leaveOptions[indexLeaveOptions].yearlyMinutes[indexYearlyMinutes].year + ")",
      //           data.users[indexUser].leaveOptions[indexLeaveOptions].yearlyMinutes[indexYearlyMinutes].used / 60,
      //           data.users[indexUser].leaveOptions[indexLeaveOptions].yearlyMinutes[indexYearlyMinutes].available / 60,
      //         ]);
      //       }
      //     } else {
      //       dataLeaveOptions.push([
      //         userName,
      //         data.users[indexUser].leaveOptions[indexLeaveOptions].name,
      //         data.users[indexUser].leaveOptions[indexLeaveOptions].usedMinutes / 60,
      //         data.users[indexUser].leaveOptions[indexLeaveOptions].availableMinutes / 60,
      //       ]);
      //     }
      //   }
      // }
      // exportAllLeaveTypesXLS(dataLeaveOptions);
    }
  };

  // const exportSalaryListXLSHandler = async() => {
  //   const { data } = await getLeaveApplications( {
  //     ...searchConfig,
  //     page: 1,
  //     amount: 100,
  //   });
  //   console.log(`allLeaveSearch.js exportSalaryListXLSHandler() JSON.stringify(data)=${JSON.stringify(data)}`);
  //   const dataApplications = [];
  //   for (let i = 0; i < data.applications.length; i += 1) {
  //     dataApplications.push([
  //       data.applications[i].username,
  //       data.applications[i].leaveName,
  //       moment.parseZone(data.applications[i].applyTime).format('YYYY/MM/DD'),
  //       data.applications[i].leaveHours,
  //       moment.parseZone(data.applications[i].leaveFrom).format('YYYY/MM/DD'),
  //       moment.parseZone(data.applications[i].leaveTo).format('YYYY/MM/DD'),
  //     ]);
  //   }
  //   console.log(`allLeaveSearch.js exportSalaryListXLSHandler() JSON.stringify(dataApplications)=${JSON.stringify(dataApplications)}`);
  //   exportAllLeaveXLS(dataApplications);
  // };

  const updateSearchConfigHandler = (key, value) => {
    console.log(`allUserLeaveType.js updateSearchConfigHandler() key=${key}&value=${value}`);
    const updateObj = { ...searchConfig };
    if (key === "startMonth") {
      updateObj[key] = moment(value).startOf('month').format('YYYY-MM-DD');
    } else if (key === "endMonth") {
      updateObj[key] = moment(value).endOf('month').format('YYYY-MM-DD');
    } else {
      updateObj[key] = value;
    }
    console.log(`allUserLeaveType.js updateSearchConfigHandler() JSON.stringify(updateObj)=${JSON.stringify(updateObj)}`);
    setSearchConfig({ ...updateObj });
  };

  // const clearHandler = () => {
  //   setVouchers([]);
  //   setTotalCount(0);
  //   setConfig(cloneDeep(searchConfig));
  // };

  const fetchAllLeaves = async (page) => {
    console.log(`allUserLeaveType.js fetchAllLeaves()`);
    setShowLoadingPopup(true);

    try {
      if (searchConfig.companyType !== "") {
        const { data } = await getLeaveApplications( {
          ...searchConfig,
          page: page,
          amount: 10,
        });
        setAllLeaves(data.applications);
        console.log(`allUserLeaveType.js fetchAllLeaves() JSON.stringify(data.applications)=${JSON.stringify(data.applications)}`);
        
        setTotalCount(data.totalCount || 0);
      } else {
        setAllLeaves(undefined);
        setTotalCount(0);
      }
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  }

  const initCompensatedLeave = useCallback(
    fetchAllLeaves, []);

  useEffect(() => {
    initCompensatedLeave();
  }, [initCompensatedLeave]);

  const searchHandler = async () => {
    setCurrentPageHandler(1);
  };

  const setCurrentPageHandler = page => {
    setCurrentPage(page);
    fetchAllLeaves(page);
  };

  const initOptions = useCallback(() => {
    dispatch(FETCH_COMPANY_TYPE_OPTIONS());
  }, [dispatch]);

  useEffect(() => {
    initOptions();
  }, [initOptions]);

  return(
    <>
      <DivPanelContainer>
        <PanelAllLeaveTypeSearch
          users = { users }
          searchConfig = { searchConfig }
          companyTypeOptions = { companyTypeOptions }
          // clearHandler = { clearHandler }
          exportSalaryListXLSHandler = { exportSalaryListXLSHandler }
          searchHandler = { searchHandler }
          updateSearchConfigHandler = { updateSearchConfigHandler }
        />
      </DivPanelContainer>
      { showLoadingPopup && <PopupLoading/> }
      {
        showAlertPopup &&
          <PopupExecuteResult
            title = "請選擇欄位"
            text = { alertText }
            confirmHandler = { () => { setShowAlertPopup(false) } }
          />
      }
    </>
  );
};

export default AllUserLeaveType;
