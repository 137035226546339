import React, { useCallback, useEffect, useState, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { postToGetProjectDetail } from '@/api/projectService';
import {
  postToGetExpenses,
  postToGetExpenseDetail,
} from '@/api/expenseService';

import {
  FETCH_MEMBER_OPTIONS,
  FETCH_VENDOR_ID_OPTIONS,
  FETCH_PROJECT_ID_OPTIONS,
} from '@/store/actions/srpOptionsAction';

import MainContent from '@/component-style/MainContent';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PanelFinanceApprovalsSearch from '@/components/Panel/Finance/PanelFinanceApprovalsSearch';
import TableFinanceApprovalList from '@/components/Table/Finance/TableFinanceApprovalList';

import { searchConfigGenerator } from '@/utils/apiRequestUtils';
import { integerConverter } from '@/utils/textUtils';
import { getOptionDesc } from '@/utils/textUtils';
import { expenseApprovalStates } from '@/constant/options';
import { exportFinanceVerifyFile } from '@/utils/xlsx';

const DivPanelContainer = styled.div`
  margin-bottom: 40px;
`;

const searchConfig = {
  projectID: '',
  expenseCode: '',
  object: '',
  objectID: '',
  approvalStatus: '',
  user: '',
  paymentMethod: '',
  isImportant: '',
  price: { min: '', max: '' },
  applyDate: { start: null, end: null },
  amount: 10,
  managedBy: '',
};

const FinanceAppropvals = () => {
  const dispatch = useDispatch();
  const userID = useSelector(state => state.login.token);
  const vendorIDs = useSelector(state => state.srpOptions.vendorIDOptionsTaxID);
  const memberOptions = useSelector(state => state.srpOptions.memberOptions);
  const projectIDOptions = useSelector(state => state.srpOptions.projectIDOptions);

  const [expenses, setExpenses] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [config, setConfig] = useState({ ...searchConfig });

  const [objectFilter, setObjectFilter] = useState('');
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);

  const objectList = () => {
    if (objectFilter === 'user') return memberOptions;
    if (objectFilter === 'vendor') return vendorIDs;

    return [];
  };

  const updateConfigHandler = (key, value) => {
    const updateObj = { ...config };
    updateObj[key] = value;
    if (key === "user") {
      console.log("updateConfigHandler");
      updateObj[key] = [value];
    }
    setConfig({ ...updateObj });
  };

  const objectFilterChangeHandler = value => {
    setObjectFilter(value);
    setConfig(prevState => ({
      ...prevState,
      object: value,
      objectID: '',
    }));
  };

  const clearHandler = () => {
    setConfig({ ...searchConfig });
    setObjectFilter('');
  };

  const searchHandler = () => {
    setCurrentPageHandler(1);
  };

  const setCurrentPageHandler = page => {
    setCurrentPage(page);
    fetchExpenses(page);
  };

  const fetchExpenses = async (page = currentPage) => {
    console.log("fetchEpense");
    setShowLoadingPopup(true);

    try {
      const searchConfig = searchConfigGenerator(
        {
          ...config,
          approvalStatus: ['financeVerify'],
          managedBy: userID,
          // user: config.user === '' ? [] : [config.user],
          expenseCode: integerConverter(config.expenseCode),
          paymentMethod: config.paymentMethod === '' ? [] : [config.paymentMethod],
          price: {
            min: integerConverter(config.price.min) || null,
            max: integerConverter(config.price.max) || null,
          },
          page,
        }
      );
      // console.log("fetchExpenses searchConfig:" + JSON.stringify(searchConfig));
      delete searchConfig["managedBy"];
      // console.log("fetchExpenses searchConfig:" + JSON.stringify(searchConfig));
      const { data } = await postToGetExpenses(searchConfig);

      const { expenses, totalCount } = data;
      const expenseForms = [];

      for (let i = 0; i < expenses.length; i += 1) {
        const expense = expenses[i];
        const { projectID, applyDate } = expense;
        const { data } = await postToGetProjectDetail(projectID);
        const { projectCode, projectName } = data;
        expenseForms.push({
          ...expense,
          projectCode,
          projectName,
          applyDate: applyDate === '0001-01-01' ? '' : applyDate,
        });
      };

      setExpenses([...expenseForms]);
      setTotalCount(totalCount || 0);
    } catch(err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const exportHandler = async () => {
    setShowLoadingPopup(true);
    const expensesData = [];

    try {
      const { data } = await postToGetExpenses(searchConfigGenerator({
        ...config,
        approvalStatus: ['financeVerify'],
        managedBy: userID,
        // user: config.user === '' ? [] : [config.user],
        expenseCode: integerConverter(config.expenseCode),
        price: {
          min: integerConverter(config.price.min) || null,
          max: integerConverter(config.price.max) || null,
        },
        amount: totalCount || 1,
        page: 1,
      }));

      const { expenses } = data;

      for (let i = 0; i < expenses.length; i += 1) {
        const { projectID, applyDate, predictDate, expenseID } = expenses[i];
        const expenseDetail = await postToGetExpenseDetail(expenseID);
        const expense = expenseDetail.data;
        const projectDetail = await postToGetProjectDetail(projectID);
        const { projectCode, projectName } = projectDetail.data;
        expensesData.push([
          applyDate === '0001-01-01' ? '' : applyDate,
          expense.expenseCode || '',
          getOptionDesc(expenseApprovalStates, expense.approvalStatus),
          projectCode,
          projectName,
          expense.object === 'user'
            ? getOptionDesc(memberOptions, expense.objectID)
            : getOptionDesc(vendorIDs, expense.objectID),
          getOptionDesc(memberOptions, expense.user),
          expense.note,
          expense.price,
          expense.isImportant ? '急件' : '普件',
          expense.vouchers.some(voucher => voucher.notForAccountingSys)
            ? '否' : '是',
          expense.vouchers.some(voucher => voucher.notForBusinessTax)
            ? '否' : '是',
          expense.expenseCode || '',
          predictDate === '0001-01-01' ? '' : predictDate,
        ]);
      };

    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
    exportFinanceVerifyFile(expensesData);
  };

  const initExpenses = useCallback(
    fetchExpenses, []);

  const initOptions = useCallback(() => {
    dispatch(FETCH_MEMBER_OPTIONS());
    dispatch(FETCH_VENDOR_ID_OPTIONS());
    dispatch(FETCH_PROJECT_ID_OPTIONS());
  }, [dispatch]);

  useEffect(() => {
    initOptions();
    initExpenses();
  }, [initExpenses, initOptions]);

  return (
    <MainContent>
      <DivPanelContainer>
        <PanelFinanceApprovalsSearch
          config = { config }
          objectList = { objectList() }
          memberList = { memberOptions }
          objectFilter = { objectFilter }
          projectIDOptions = { projectIDOptions }
          clearHandler = { clearHandler }
          searchHandler = { searchHandler }
          exportHandler = { exportHandler }
          updateConfigHandler = { updateConfigHandler }
          objectFilterChangeHandler = { objectFilterChangeHandler }
        />
      </DivPanelContainer>
      <TableFinanceApprovalList
        expenses = { expenses }
        totalCount = { totalCount }
        currentPage = { currentPage }
        pageChangedHandler = { value => {
          setCurrentPageHandler(value);
        }}
      />
      { showLoadingPopup && <PopupLoading/> }
    </MainContent>
  )
};

export default FinanceAppropvals;
