import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons';

import Checkbox from '@/components/UI/Checkbox';
import { ButtonBasic, ButtonCancel } from '@/component-style/Button';
import { ButtonGoDetail } from '@/component-style/Button';
import { TdItem, TableRow } from '@/component-style/Table';

import {
  integerConverter,
  currencyConverter,
} from '@/utils/textUtils';

const TrWrapper = styled(TableRow)`
  border-bottom: solid 2px #f2f2f2;
  box-sizing: border-box;
  min-width: 1380px;

  &:last-child {
    border-bottom: none;
  }
`;

const Td80 = styled(TdItem)`
  width: 80px;
`;

const Td100 = styled(TdItem)`
  width: 100px;
`;

const Td120 = styled(TdItem)`
  width: 120px;
`;

const Td160 = styled(TdItem)`
  width: 160px;
`;

const Td240 = styled(TdItem)`
  width: 240px;
`;

const ButtonSave = styled(ButtonBasic)`
  width: 80px;
`;

const ButtonFilesUpload = styled(ButtonBasic)`
  width: 100px;

  svg { margin-left: 5px }
`;

const ButtonFilesEmpty = styled(ButtonCancel)`
  width: 100px;
`;

const SpanLink = styled.span`
  cursor: pointer;

  &:hover { color: #de2027 }
`;

const Item = props => {
  const history = useHistory();

  const { quotation } = props;

  const fileUploadButton = quotation.isFileUpload
    ? <ButtonFilesUpload
        disabled = { quotation.expenseCode !== undefined || !props.attachUploadable }
        onClick = { props.attachmentHandler }
      >附件管理
        <FontAwesomeIcon icon = { faFolderOpen }/>
      </ButtonFilesUpload>
    : <ButtonFilesEmpty
        disabled = { quotation.expenseCode !== undefined || !props.attachUploadable }
        onClick = { props.attachmentHandler }
      >附件管理</ButtonFilesEmpty>

  const voucherUploadButton = quotation.isVoucherUpload
    ? <ButtonFilesUpload
        disabled = { quotation.expenseCode !== undefined || !props.licenseUploadable }
        onClick = { props.voucherHandler }
      >單據管理
        <FontAwesomeIcon icon = { faFolderOpen }/>
      </ButtonFilesUpload>
    : <ButtonFilesEmpty
        disabled = { quotation.expenseCode !== undefined || !props.licenseUploadable }
        onClick = { props.voucherHandler }
      >單據管理</ButtonFilesEmpty>

  return (
    <TrWrapper>
      <Td80>
        <Checkbox
          disabled = { quotation.expenseCode !== undefined }
          checked = { quotation.isChecked }
          changeHandler = { props.checkHandler }
        />
      </Td80>
      <Td80>
        {
          quotation.expenseCode === undefined
            ? ''
            : <SpanLink
                onClick = { () => {
                  window.open(`/Expense/ExpenseFormDetail/${quotation.expenseID}`, "_blank");
                  // history.push(`/Expense/ExpenseFormDetail/${quotation.expenseID}`)
                }}
              >{ quotation.expenseCode }</SpanLink>
        }
      </Td80>
      <Td160>{ quotation.vendorName }</Td160>
      <Td80>
        { quotation.quotationType === 'project' ? '專案報價' : '時薪' }
      </Td80>
      <Td80>{ quotation.hourlyRate }</Td80>
      <Td120>
        { currencyConverter(integerConverter(quotation.quotedPrice)) }
      </Td120>
      <Td120>
        { currencyConverter(integerConverter(quotation.price)) }
      </Td120>
      <Td240>{ quotation.note }</Td240>
      <Td120>{ fileUploadButton }</Td120>
      <Td120>{ voucherUploadButton }</Td120>
      <Td100>
        <ButtonSave
          disabled = { quotation.expenseCode !== undefined || !props.userDeletable }
          onClick = { props.deleteHandler }
        >刪除</ButtonSave>
      </Td100>
      <Td80>
        <ButtonGoDetail
          disabled = { props.disableDetailPage }
          onClick = { props.goDetailHandler }
        >...</ButtonGoDetail>
      </Td80>
    </TrWrapper>
  );
};

export default Item;
