import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import styled from 'styled-components';

import { resendPassword } from '@/api/authService';
import { errorMsgConverter } from '@/utils/textUtils';

import Footer from '@/components/UI/Footer';
import ResetPasswordPanel from '@/components/ResetPasswordPanel';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import { recaptchaSiteKey } from '../config';

const DivResetPassword = styled.div`
  position: relative;
`;

const ResetPassword = props => {
  const history = useHistory();

  const [account, setAccount] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [showError, setShowError] = useState(false);

  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [showResetSuccessPopup, setShowResetSuccessPopup] = useState(false);

  const resendPWHandler = async reCaptchaToken => {
    setShowLoadingPopup(true);

    try {
      await resendPassword({ account, reCaptchaToken});
      setShowLoadingPopup(false);
      setShowResetSuccessPopup(true);
    } catch (err) {
      console.log(err);
      const errorMsg = errorMsgConverter(err);
      setErrorMsg(errorMsg);
      setShowError(true);
    }
  };

  return (
    <GoogleReCaptchaProvider reCaptchaKey = { recaptchaSiteKey }>
      <DivResetPassword>
        <ResetPasswordPanel
          errorMsg = { errorMsg }
          showError = { showError }
          account = { account }
          setAccount = { setAccount }
          resetPW = { resendPWHandler }
        />
        <Footer />
      </DivResetPassword>
      { showLoadingPopup && <PopupLoading/> }
      {
        showResetSuccessPopup &&
          <PopupExecuteResult
            title = "重設密碼成功"
            text = "已寄送新密碼到您的信箱，請使用新密碼重新登入"
            confirmHandler = { () => { history.goBack() }}
          />
      }
    </GoogleReCaptchaProvider>
  );
};

export default ResetPassword;
