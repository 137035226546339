import React from 'react';
// import { useSelector } from 'react-redux';
import styled from 'styled-components';

import PanelWrapper from '@/components/UI/PanelWrapper';
import SelectSearchable from '@/components/UI/Select/SelectSearchable';
import { InputBasic } from '@/component-style/Input';
import SearchButtonSet from '@/components/UI/SearchButtonSet';
// import { InputBasic } from '@/component-style/Input';
import { ButtonBasic } from '@/component-style/Button';
import { PSubtitle } from '@/component-style/TextElement';
import {
  DivFlexRow,
  DivItemWrapper,
  DivLongMultiSelector,
} from '@/component-style/RWDWrapper';
import { floatConverter } from '@/utils/textUtils';

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding: 0;
  justify-content: space-between;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const ButtonExport = styled(ButtonBasic)`
  width: 120px;

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`;

const Input = styled(InputBasic)`
  @media (max-width: 320px) { width: 260px; }
  @media (min-width: 1920px) { width: 300px }
`;

const PanelCompensatedLeaveSearch = props => {
  // const financeRoutes = useSelector(state => state.login.financeRoutes);
  const { config } = props;
  console.log(`PanelCompensatedLeaveSearch.js props.giveCompensatedLeave=${props.giveCompensatedLeave}`);

  return (
    <PanelWrapper title = "同仁補休查詢">
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>同仁</PSubtitle>
          <SelectSearchable
            // disabled = { props.disabled }
            value = { props.giveCompensatedLeave.userId }
            options = { props.users}
            changeHandler = { value => {
              props.updateGiveCompensatedLeaveHandler('userId', value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>給予補休假(小時)</PSubtitle>
          <DivLongMultiSelector>
            <Input
              type = "number"
              value = { props.giveCompensatedLeave.durationInSeconds }
              onChange = {({ target }) => {
                props.updateGiveCompensatedLeaveHandler('durationInSeconds', floatConverter(target.value));
              }}
            />
          </DivLongMultiSelector>
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>註記</PSubtitle>
          <DivLongMultiSelector>
            <Input
              value = { props.giveCompensatedLeave.description }
              onChange = {({ target }) => {
                props.updateGiveCompensatedLeaveHandler('description', target.value);
              }}
            />
          </DivLongMultiSelector>
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivButtonsWrapper>
          <ButtonExport
            onClick = { props.giveComepnsatedLeaveHandler }
          >給予補休假(小時)</ButtonExport>
          {<br></br>}
          <SearchButtonSet
            clearHandler = { props.clearHandler }
            searchHandler = { props.searchHandler }
          />
        </DivButtonsWrapper>
      </DivFlexRow>
    </PanelWrapper>
  );
};

export default PanelCompensatedLeaveSearch;
