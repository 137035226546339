import React from 'react';
import styled from 'styled-components';

import PanelWrapper from '@/components/UI/PanelWrapper';
import Checkbox from '@/components/UI/Checkbox';
import SelectNormal from '@/components/UI/Select/SelectNormal';
import SelectSearchable from '@/components/UI/Select/SelectSearchable';
import DatePeriodSelector from '@/components/UI/DatePeriodSelector';
import { InputBasic } from '@/component-style/Input';

import { PSubtitle } from '@/component-style/TextElement';
import {
  DivFlexRow,
  DivItemWrapper,
  DivLongMultiSelector,
} from '@/component-style/RWDWrapper';

import {
  vendorPayments,
  expenseApprovalStates,
} from '@/constant/options';
import { formatTime } from '@/utils/timeUtils';

const DivPeriodSelectorWrapper = styled.div`

  & .ant-checkbox-wrapper { margin-top: 5px }
`;

const DivBudgetWrapper = styled.div`
  width: 300px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 320px) { width: 260px }
  @media (min-width: 1920px) { width: 622px }
`;

const InputBudget = styled(InputBasic)`
  width: 120px;

  @media (max-width: 320px) { width: 100px }
  @media (min-width: 1920px) { width: 300px }
`;

const PSpacer = styled.p`
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  color: #767676;
`;

const PanelFinanceManagementSearch = props => {
  const { config } = props;

  const expenseTagOptions = expenseTypeID => 
    props.expenseTagDictionary[expenseTypeID] === undefined
      ? []
      : props.expenseTagDictionary[expenseTypeID];

  const applyDateStartChangeHandler = value => {
    props.updateConfigHandler('applyDate', {
      ...config.applyDate,
      start: formatTime(value, 'YYYY-MM-DD'),
    });
  };

  const applyDateEndChangeHandler = value => {
    props.updateConfigHandler('applyDate', {
      ...config.applyDate,
      end: formatTime(value, 'YYYY-MM-DD'),
    });
  };

  const predictDateStartChangeHandler = value => {
    props.updateConfigHandler('predictDate', {
      ...config.predictDate,
      start: formatTime(value, 'YYYY-MM-DD'),
    });
  };

  const predictDateEndChangeHandler = value => {
    props.updateConfigHandler('predictDate', {
      ...config.predictDate,
      end: formatTime(value, 'YYYY-MM-DD'),
    });
  };

  const predictDateIsNullChangeHandler = value => {
    props.updateConfigHandler('predictDate', {
      end: null,
      start: null,
      isNull: value,
    });
  };

  const paymentDateStartChangeHandler = value => {
    props.updateConfigHandler('paymentDate', {
      ...config.paymentDate,
      start: formatTime(value, 'YYYY-MM-DD'),
    });
  };

  const paymentDateEndChangeHandler = value => {
    props.updateConfigHandler('paymentDate', {
      ...config.paymentDate,
      end: formatTime(value, 'YYYY-MM-DD'),
    });
  };

  const paymentDateIsNullChangeHandler = value => {
    props.updateConfigHandler('paymentDate', {
      end: null,
      start: null,
      isNull: value,
    });
  };

  // const declareMonthStartChangeHandler = value => {
  //   props.updateConfigHandler('declareMonth', {
  //     ...config.declareMonth,
  //     start: formatTime(value, 'YYYY-MM'),
  //   });
  // };

  // const declareMonthEndChangeHandler = value => {
  //   props.updateConfigHandler('declareMonth', {
  //     ...config.declareMonth,
  //     end: formatTime(value, 'YYYY-MM'),
  //   });
  // };

  const personalIncomeDeclareDateStartChangeHandler = value => {
    props.updateConfigHandler('personalIncomeDeclareDate', {
      ...config.personalIncomeDeclareDate,
      start: formatTime(value, 'YYYY-MM'),
    });
  };

  const personalIncomeDeclareDateEndChangeHandler = value => {
    props.updateConfigHandler('personalIncomeDeclareDate', {
      ...config.personalIncomeDeclareDate,
      end: formatTime(value, 'YYYY-MM'),
    });
  };

  return (
    <PanelWrapper title = "支出憑單管理">
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>專案代號</PSubtitle>
          <SelectSearchable
            value = { config.projectID }
            options = {[
              { value: '', desc: '請選擇專案代號' },
              ...props.projectIDOptions,
            ]}
            changeHandler = { value => {
              props.updateConfigHandler('projectID', value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>支憑流水號</PSubtitle>
          <InputBasic
            value = { config.expenseCode }
            onChange = { ({ target }) => {
              props.updateConfigHandler('expenseCode', target.value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>付款對象</PSubtitle>
          <SelectNormal
            value = { config.object }
            options = {[
              { value: '', desc: '全部' },
              { value: 'vendor', desc: '廠商' },
              { value: 'user', desc: '同仁' },
            ]}
            changeHandler = { value => {
              props.objectChangeHandler(value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>付款對象名稱</PSubtitle>
          <SelectSearchable
            disabled = { config.object === '' }
            value = { config.objectID }
            options = {[
              { value: '', desc: '請選擇對象名稱' },
              ...props.objectList,
            ]}
            changeHandler = { value => {
              props.updateConfigHandler('objectID', value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>申請人</PSubtitle>
          <SelectSearchable
            value = { config.user }
            options = {[
              { value: '', desc: '請選擇申請人' },
              ...props.memberList,
            ]}
            changeHandler = { value => {
              props.updateConfigHandler('user', value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>個人所得申報種類</PSubtitle>
          <SelectNormal
            value = { config.declareType }
            options = {[
              { value: '', desc: '請選擇申報種類' },
              ...props.declareTypeOptions,
            ]}
            changeHandler = { value => {
              props.updateConfigHandler('declareType', value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>付款方式</PSubtitle>
          <SelectNormal
            value = { config.paymentMethod }
            options = {[
              { value: '', desc: '請選擇付款方式' },
              ...vendorPayments,
            ]}
            changeHandler = { value => {
              props.updateConfigHandler('paymentMethod', value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>審核狀態</PSubtitle>
          <SelectNormal
            value = { config.approvalStatus }
            options = {[
              { value: '', desc: '請選擇審核狀態' },
              ...expenseApprovalStates,
            ]}
            changeHandler = { value => {
              props.updateConfigHandler('approvalStatus', value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>營業稅申報</PSubtitle>
          <SelectNormal
            value = { config.notForBusinessTax }
            options = {[
              { value: '', desc: '請選擇' },
              { value: false, desc: '是' },
              { value: true, desc: '否' },
            ]}
            changeHandler = { value => {
              props.updateConfigHandler('notForBusinessTax', value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>匯會計系統</PSubtitle>
          <SelectNormal
            value = { config.notForAccountingSys }
            options = {[
              { value: '', desc: '請選擇' },
              { value: false, desc: '是' },
              { value: true, desc: '否' },
            ]}
            changeHandler = { value => {
              props.updateConfigHandler('notForAccountingSys', value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>會計大分類</PSubtitle>
          <SelectNormal
            value = { config.type }
            options = {[
              { value: '', desc: '請選擇會計大分類' },
              ...props.expenseTypeOptions,
            ]}
            changeHandler = { value => {
              props.typeChangeHandler(value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>會計小分類</PSubtitle>
          <SelectNormal
            value = { config.tag }
            options = {[
              { value: '', desc: '請選擇會計小分類' },
              ...expenseTagOptions(config.type),
            ]}
            changeHandler = { value => {
              props.updateConfigHandler('tag', value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>重要性</PSubtitle>
          <SelectNormal
            value = { config.isImportant }
            options = {[
              { value: '', desc: '請選擇重要性' },
              { value: true, desc: '急件' },
              { value: false, desc: '普件' },
            ]}
            changeHandler = { value => {
              props.updateConfigHandler('isImportant', value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>是否列印</PSubtitle>
          <SelectNormal
            value = { config.isPrinted }
            options = {[
              { value: '', desc: '請選擇重要性' },
              { value: true, desc: '是' },
              { value: false, desc: '否' },
            ]}
            changeHandler = { value => {
              props.updateConfigHandler('isPrinted', value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>發票號碼</PSubtitle>
          <InputBasic
            value = { config.voucherCode }
            onChange = { ({ target }) => {
              props.updateConfigHandler('voucherCode', target.value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>發票總金額</PSubtitle>
          <DivBudgetWrapper>
            <InputBudget
              type = "number"
              value = { config.price.min }
              onChange = {({ target }) => {
                props.updateConfigHandler('price', {
                  ...config.price,
                  min: target.value,
                });
              }}
            />
            <PSpacer>~</PSpacer>
            <InputBudget
              type = "number"
              value = { config.price.max }
              onChange = {({ target }) => {
                props.updateConfigHandler('price', {
                  ...config.price,
                  max: target.value,
                });
              }}
            />
          </DivBudgetWrapper>
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>
            所屬公司
          </PSubtitle>
          <SelectNormal
            options = {[
              { value: '', desc: '請選擇所屬公司'},
              ...props.companyTypeOptions,
            ]}
            changeHandler = { value => {
              props.updateConfigHandler('companyType', value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>申請時間</PSubtitle>
            <DivLongMultiSelector>
              <DatePeriodSelector
                startTime = { config.applyDate.start }
                endTime = { config.applyDate.end }
                endTimeOKHandler = { applyDateEndChangeHandler }
                endTimeChangeHandler = { applyDateEndChangeHandler }
                startTimeOKHandler = { applyDateStartChangeHandler }
                startTimeChangeHandler = { applyDateStartChangeHandler }
              />
            </DivLongMultiSelector>
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>個人所得申報月份</PSubtitle>
            <DivLongMultiSelector>
              <DatePeriodSelector
                picker = "month"
                startTime = { config.personalIncomeDeclareDate.start }
                endTime = { config.personalIncomeDeclareDate.end }
                endTimeOKHandler = { personalIncomeDeclareDateEndChangeHandler }
                endTimeChangeHandler = { personalIncomeDeclareDateEndChangeHandler }
                startTimeOKHandler = { personalIncomeDeclareDateStartChangeHandler }
                startTimeChangeHandler = { personalIncomeDeclareDateStartChangeHandler }
              />
            </DivLongMultiSelector>
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>預計付款日</PSubtitle>
          <DivLongMultiSelector>
            <DivPeriodSelectorWrapper>
              <DatePeriodSelector
                disabled = { config.predictDate.isNull === true }
                startTime = { config.predictDate.start }
                endTime = { config.predictDate.end }
                endTimeOKHandler = { predictDateEndChangeHandler }
                endTimeChangeHandler = { predictDateEndChangeHandler }
                startTimeOKHandler = { predictDateStartChangeHandler }
                startTimeChangeHandler = { predictDateStartChangeHandler }
              />
              <Checkbox
                title = "查詢未填寫"
                checked = { config.predictDate.isNull }
                changeHandler = { predictDateIsNullChangeHandler }
              />
            </DivPeriodSelectorWrapper>
          </DivLongMultiSelector>
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>實際付款日</PSubtitle>
            <DivLongMultiSelector>
              <DivPeriodSelectorWrapper>
                <DatePeriodSelector
                  disabled = { config.paymentDate.isNull === true }
                  startTime = { config.paymentDate.start }
                  endTime = { config.paymentDate.end }
                  endTimeOKHandler = { paymentDateEndChangeHandler }
                  endTimeChangeHandler = { paymentDateEndChangeHandler }
                  startTimeOKHandler = { paymentDateStartChangeHandler }
                  startTimeChangeHandler = { paymentDateStartChangeHandler }
                />
                <Checkbox
                  title = "查詢未填寫"
                  checked = { config.paymentDate.isNull }
                  changeHandler = { paymentDateIsNullChangeHandler }
                />
              </DivPeriodSelectorWrapper>
            </DivLongMultiSelector>
        </DivItemWrapper>
      </DivFlexRow>
      { props.children }
    </PanelWrapper>
  );
};

export default PanelFinanceManagementSearch;
