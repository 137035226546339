import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import TableWrapper from '@/components/UI/TableWrapper';
import DateSelector from '@/components/UI/DateSelector';
import SelectNormal from '@/components/UI/Select/SelectNormal';
import SelectMultiple from '@/components/UI/Select/SelectMultiple';
import SelectSearchable from '@/components/UI/Select/SelectSearchable';
import SearchButtonSet from '@/components/UI/SearchButtonSet';
import { PSubtitle } from '@/component-style/TextElement';
import { ButtonBasic } from '@/component-style/Button';
import {
  DivFlexRow,
  DivItemWrapper,
  DivFullWidthWrapper,
  DivLongMultiSelector,
} from '@/component-style/RWDWrapper';

import { formatTime } from '@/utils/timeUtils';

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding: 0;
  padding-bottom: 30px;
  justify-content: space-between;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const ButtonExport = styled(ButtonBasic)`
  width: 120px;

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`;

const PanelWorkingHoursSearch = props => {
  const attendanceRoutes = useSelector(state => state.login.attendanceRoutes);

  const { config, userFilter, workingHoursTagDictionary } = props;

  const userExportable = attendanceRoutes.indexOf('WorkingHoursList-Export') !== -1;

  const tagOptions = typeID => 
    workingHoursTagDictionary[typeID] === undefined
      ? []
      : workingHoursTagDictionary[typeID];

  return (
    <TableWrapper title = "工時總表" >
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>
            起始日期
            <span>*</span>
          </PSubtitle>
          <DateSelector
            value = { config.startDate }
            changeHandler = { value => {
              props.updateConfigHandler(
                'startDate',
                formatTime(value, 'YYYY-MM-DD')
              );
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>
            結束日期
            <span>*</span>
          </PSubtitle>
          <DateSelector
            value = { config.endDate }
            changeHandler = { value => {
              props.updateConfigHandler(
                'endDate',
                formatTime(value, 'YYYY-MM-DD')
              );
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivFullWidthWrapper>
          <PSubtitle>專案代號</PSubtitle>
          <DivLongMultiSelector>
          <SelectSearchable
            value = { config.projectID }
            options = {[
              { value: '', desc: '請選擇專案代號' },
              ...props.projectIDOptions,
            ]}
            changeHandler = { value => {
              props.updateConfigHandler('projectID', value);
            }}
          />
          </DivLongMultiSelector>
        </DivFullWidthWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>
            工作項目
          </PSubtitle>
          <SelectNormal
            value = { config.typeID }
            options = {[
              { value: '', desc: '請選擇工作項目' },
              ...props.workingHoursTypeOptions,
            ]}
            changeHandler = { value => {
              props.typeChangeHandler(value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>
            工作細項
          </PSubtitle>
          <SelectNormal
            disabled = { config.typeID === '' }
            value = { config.tag }
            options = {[
              { value: '', desc: '請選擇工作細項' },
              ...tagOptions(config.typeID),
            ]}
            changeHandler = { value => {
              props.updateConfigHandler('tag', value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>同仁篩選</PSubtitle>
          <SelectNormal
            value = { userFilter }
            options = {[
              { value: 'all', desc: '全部' },
              { value: 'self', desc: '自己' },
              { value: 'users', desc: '特定同仁' },
            ]}
            changeHandler = { value => {
              props.userFilterChangeHandler(value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>跨組</PSubtitle>
          <SelectNormal
            value = { config.crossTeam }
            options = {[
              { value: true, desc: '是'},
              { value: false, desc: '否'},
            ]}
            changeHandler = { value => {
              props.updateConfigHandler('crossTeam', value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      {
        userFilter === 'users' &&
        <DivFlexRow>
          <DivFullWidthWrapper>
            <PSubtitle>使用者</PSubtitle>
            <DivLongMultiSelector>
              <SelectMultiple
                disabled = { userFilter !== 'users' }
                value = { config.userIDs }
                options = {[ ...props.memberOptions ]}
                changeHandler = { value => {
                  props.updateConfigHandler('userIDs', [...value]);
                }}
              />
            </DivLongMultiSelector>
          </DivFullWidthWrapper>
        </DivFlexRow>
      }   
      <DivFlexRow>
        <DivButtonsWrapper>
          <ButtonExport
            disabled = { !userExportable }
            onClick = { props.exportHandler }
          >匯出</ButtonExport>
          <SearchButtonSet
            searchable = { config.startDate !== null && config.endDate !== null }
            searchHandler = { props.searchHandler }
            clearHandler = { props.clearHandler }
          />
        </DivButtonsWrapper>
      </DivFlexRow>
    </TableWrapper>
  );
};

export default PanelWorkingHoursSearch;
