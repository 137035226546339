import axiosConfig from './axiosConfig';
import { getDefaultPort } from '@/utils/apiRequestUtils';

const { baseURL } = axiosConfig.defaults;

const port = process.env.REACT_APP_SERVICE_PORT_ATTENDANCE !== undefined
  ? process.env.REACT_APP_SERVICE_PORT_ATTENDANCE
  : getDefaultPort(baseURL);

const attendanceModuleUrl = process.env.NODE_ENV === 'development'
  ? `${baseURL}:${port}/api`
  : `${baseURL}/attendance/api`;

export const getAttendanceDetail = (userID, date) => axiosConfig
  .post(`${attendanceModuleUrl}/attendance/detail`, { userID, date });

export const putModifyAttendance = attendance => axiosConfig
  .put(`${attendanceModuleUrl}/attendance/detail`, attendance);

export const putNewAttendanceTime = attendance => axiosConfig
  .put(`${attendanceModuleUrl}/attendance/reporttime`, attendance);

export const postToGetAttendances = config => axiosConfig
  .post(`${attendanceModuleUrl}/attendance`, config);

export const deleteAttendances = attendanceIDs => axiosConfig
  .delete(`${attendanceModuleUrl}/attendance`, { data: [ ...attendanceIDs ] });

export const postToGetWorkingHours = config => axiosConfig
  .post(`${attendanceModuleUrl}/workingHours`, config);

export const putNewWorkingHours = workingHours => axiosConfig
  .put(`${attendanceModuleUrl}/workingHours`, workingHours);

export const deleteWorkingHours = workingHoursIDs => axiosConfig
  .delete(`${attendanceModuleUrl}/workingHours`, { data: [ ...workingHoursIDs ] });

export const postToGetWorkingHoursDetail = workingHoursID => axiosConfig
  .post(`${attendanceModuleUrl}/workingHours/detail`, { workingHoursID });

export const putModifyWorkingHours = workingHours => axiosConfig
  .put(`${attendanceModuleUrl}/workingHours/detail`, workingHours);

export const postToGetScheduleTimeConfig = () => axiosConfig
  .post(`${attendanceModuleUrl}/sysConfig/scheduleTime`);

export const putModifyScheduleTimeConfig = config => axiosConfig
  .put(`${attendanceModuleUrl}/sysConfig/scheduleTime`, config);

export const postToGetWorkingHoursTypes = config => axiosConfig
  .post(`${attendanceModuleUrl}/workingHours/type`, config);

export const deleteWorkingHoursTypes = ids => axiosConfig
  .delete(`${attendanceModuleUrl}/workingHours/type`, { data: [ ...ids ]});

export const putNewWorkingHoursType = config => axiosConfig
  .put(`${attendanceModuleUrl}/workingHours/type`, config);

export const patchModifyWorkingHoursType = config => axiosConfig
  .patch(`${attendanceModuleUrl}/workingHours/type`, config);

export const postToGetWorkingHoursTags = config => axiosConfig
  .post(`${attendanceModuleUrl}/workingHours/type/tag`, config);

export const putNewWorkingHoursTag = config => axiosConfig
  .put(`${attendanceModuleUrl}/workingHours/type/tag`, config);

export const patchModifyWorkingHoursTag = config => axiosConfig
  .patch(`${attendanceModuleUrl}/workingHours/type/tag`, config);

export const deleteWorkingHoursTag = config => axiosConfig
  .delete(`${attendanceModuleUrl}/workingHours/type/tag`, { data: { ...config } });

  export const postWorkingHoursSetApprovementStatus = config => axiosConfig
  .post(`${attendanceModuleUrl}/workingHours/set-approvement-status`, config);