import React from 'react';
import styled from 'styled-components';

import { TdItem, TableRow } from '@/component-style/Table';
import { decimalCommaConverter } from '@/utils/textUtils';
import moment from 'moment';

const TrWrapper = styled(TableRow)`
  border-bottom: solid 2px #f2f2f2;
  box-sizing: border-box;
  min-width: 1080px;

  &:last-child {
    border-bottom: none;
  }
`;

const Td100 = styled(TdItem)`
  width: 100px;
`;

const Td120 = styled(TdItem)`
  width: 120px;
`;

const Td160 = styled(TdItem)`
  width: 160px;
`;

const Td180 = styled(TdItem)`
  width: 180px;
`;

const Item = props => {
  const { leaveOption } = props;

  // const type = getOptionDesc(vendorInvoiceTypes, voucher.voucherType);

  return (
    <TrWrapper>
      <Td100>{ "補休" }</Td100>
      <Td120>{ moment.parseZone(leaveOption.allocationDate).format('YYYY/MM/DD') }</Td120>
      <Td120>{ leaveOption.allocatedSeconds == null ? "0" : Math.round(leaveOption.allocatedSeconds / 3600 * 100) / 100 }</Td120>
      <Td120>{ leaveOption.usedSeconds == null ? "0" : Math.round(leaveOption.usedSeconds  / 3600 * 100) / 100 }</Td120>
      <Td120>{ moment.parseZone(leaveOption.expiryDate).format('YYYY/MM/DD') }</Td120>
    </TrWrapper>
  )
};

export default Item;
