import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

import {
  deleteUploadFile,
  postNewUploadFile,
  postToGetUploadFiles,
} from '@/api/storageService';

import {
  deleteVenue,
  postToGetVenueDetail,
  putModifyVenueDetail,
} from '@/api/venueService';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import {
  FETCH_VENUE_EQUIPMENTS,
  FETCH_VENUE_TYPE_OPTIONS,
  FETCH_VENUE_CHARACTERISTICS,
} from '@/store/actions/srpOptionsAction';

import MainContent from '@/component-style/MainContent';
import PanelVenueDetail from '@/components/Panel/PanelVenueDetail';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import PopupExecuteConfirm from '@/components/UI/Popup/PopupExecuteConfirm';
import PopupAttachmentList from '@/components/UI/Popup/PopupAttachmentList';
import { ButtonBasic, ButtonCancel } from '@/component-style/Button';
import { DivFlexRow } from '@/component-style/RWDWrapper';

import { venueConfig } from '@/constant/dataConfig/informationModule';
import { integerConverter, floatConverter } from '@/utils/textUtils';

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding-right: 0;
  padding-left: 135px;
  padding-bottom: 30px;
  justify-content: flex-end;

  @media (max-width: 425px) {
    justify-content: center;
    padding-left: 0;
  }
`;

const ButtonSave = styled(ButtonBasic)`
  width: 120px;
  margin-right: 10px;
  margin-bottom: 10px;
`;

const ButtonClear = styled(ButtonCancel)`
  width: 120px;
  margin-bottom: 10px;
`;

const VenueDetail = props => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const venueEquipments = useSelector(state => state.srpOptions.venueEquipments);
  const venueTypeOptions = useSelector(state => state.srpOptions.venueTypeOptions);
  const informationRoutes = useSelector(state => state.login.informationRoutes);
  const venueCharacteristics = useSelector(state => state.srpOptions.venueCharacteristics);
  const showSaveFailPopup = useSelector(state => state.apiFail.showSaveFailPopup);
  const apiFailPopupTitle = useSelector(state => state.apiFail.title);
  const apiFailPopupMessage = useSelector(state => state.apiFail.message);

  const [venue, setVenue] = useState({
    ...venueConfig,
    id: '',
    fees: { ...venueConfig.fees },
    capacity: { ...venueConfig.capacity },
    spaceInfo: { ...venueConfig.spaceInfo },
    basicInfo: {
      ...venueConfig.basicInfo,
      equipments: [],
      characteristics: [],
    },
  });

  const [deleteFileId, setDeleteFileId] = useState('');
  const [fileList, setFileList] = useState([]);
  const [uploadFiles, setUploadFiles] = useState([]);

  const [isEditing, setIsEditing] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [showDeleteFilePopup, setShowDeleteFilePopup] = useState(false);
  const [showAttachmentsPopup, setShowAttachmentsPopup] = useState(false);
  const [showSaveSuccessPopup, setShowSaveSuccessPopup] = useState(false);
  const [showUploadSuccessPopup, setShowUploadSuccessPopup] = useState(false);
  const [showDeleteSuccessPopup, setShowDeleteSuccessPopup] = useState(false);
  const [showDeleteFileSuccessPopup, setShowDeleteFileSuccessPopup] = useState(false);

  const userEditable = informationRoutes.indexOf('VenueDetail-Edit') !== -1;
  const userDeletable = informationRoutes.indexOf('VenueDetail-Delete') !== -1;
  const userUploadable = informationRoutes.indexOf('VenueDetail-Attachments') !== -1;

  const updateVenueHandler = (key, value) => {
    const updateObj = { ...venue };
    updateObj[key] = value;
    setVenue({ ...updateObj });
  };

  const closeSuccessPopupHandler = () => {
    setIsEditing(false);
    setShowSaveSuccessPopup(false);
  };

  const updateUploadFiles = value => {
    setUploadFiles([ ...value ]);
  };

  const uploadSuccessConfirmHandler = () => {
    setUploadFiles([]);
    setShowUploadSuccessPopup(false);
    setShowAttachmentsPopup(true);
  };

  const deleteFileHandler = id => {
    setDeleteFileId(id);
    setShowDeleteFilePopup(true);
  };

  const apiFailConfirmHandler = () => {
    dispatch(CLOSE_SAVE_FAIL_POPUP());

    apiFailPopupTitle === '上傳失敗'
      && setShowAttachmentsPopup(true);
  };

  const fileUploadHandler = async () => {
    setShowAttachmentsPopup(false);
    setShowLoadingPopup(true);

    try {
      const [file] = uploadFiles;
      const formData = new FormData();
      formData.append('upfile', file);
      formData.append('objectType', 'venue');
      formData.append('objectID', id);
      formData.append('name', file.name);
      await postNewUploadFile(formData);
      await fetchUploadFiles();
      setShowLoadingPopup(false);
      setShowUploadSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '上傳失敗', err }));
      console.log(err);
    };
  };

  const deleteFile = async () => {
    setShowDeleteFilePopup(false);
    setShowAttachmentsPopup(false);
    setShowLoadingPopup(true);

    try {
      await deleteUploadFile(deleteFileId);
      await fetchUploadFiles();
      setShowLoadingPopup(false);
      setShowDeleteFileSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '刪除檔案失敗', err }));
      console.log(err);
    }
  };

  const deleteVenueHandler = async () => {
    setShowDeletePopup(false);
    setShowLoadingPopup(true);

    try {
      await deleteVenue(venue.id);
      setShowLoadingPopup(false);
      setShowDeleteSuccessPopup(true);
    } catch(err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '刪除失敗', err }));
      console.log(err);
    }
  };

  const updateVenueData = async () => {
    setShowLoadingPopup(true);

    try {
      await putModifyVenueDetail({
        ...venue,
        spaceInfo: {
          ...venue.spaceInfo,
          size: floatConverter(venue.spaceInfo.size),
        },
        capacity: {
          total: integerConverter(venue.capacity.total),
          theater: integerConverter(venue.capacity.theater),
          horseshoe: integerConverter(venue.capacity.horseshoe),
          cocktailParty: integerConverter(venue.capacity.cocktailParty),
          roundTable: integerConverter(venue.capacity.roundTable),
          smalllRoundTable: integerConverter(venue.capacity.smalllRoundTable),
          classroom: integerConverter(venue.capacity.classroom),
          square: integerConverter(venue.capacity.square),
        },
      });
      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '更新失敗', err }));
      console.log(err);
    }
  };

  const fetchUploadFiles = async () => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetUploadFiles('venue', id);
      setFileList([ ...data ]);
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const fetchVenueData = async () => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetVenueDetail(id);
      const {
        address, basicInfo, capacity, fees, spaceInfo,
        ...remainItems
      } = data;

      setVenue(prevState => ({
        ...prevState,
        ...remainItems,
        address: { ...prevState.address, ...address },
        basicInfo: { ...prevState.basicInfo, ...basicInfo },
        capacity: { ...prevState.capacity, ...capacity },
        fees: { ...prevState.fees, ...fees },
        spaceInfo: { ...prevState.spaceInfo, ...spaceInfo },
      }));

    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const initVenue = useCallback(
    fetchVenueData, []);

  const initUploadFiles = useCallback(
    fetchUploadFiles, []);

  const initData = useCallback(
    async () => {
      await initVenue();
      await initUploadFiles(); 
    }, [initUploadFiles, initVenue]);

  const initOptions = useCallback(() => {
    dispatch(FETCH_VENUE_EQUIPMENTS());
    dispatch(FETCH_VENUE_TYPE_OPTIONS());
    dispatch(FETCH_VENUE_CHARACTERISTICS());
  }, [dispatch]);

  useEffect(() => {
    initOptions();
    initData();
  }, [initOptions, initData]);

  return (
    <MainContent>
      <PanelVenueDetail
        title = "活動場地明細"
        disabled = { !isEditing }
        venue = { venue }
        venueEquipments = { venueEquipments }
        venueTypeOptions = { venueTypeOptions }
        venueCharacteristics = { venueCharacteristics }
        updateVenueHandler = { updateVenueHandler }
      >
        <DivFlexRow>
          <DivButtonsWrapper>
            {
              isEditing
                ? <ButtonSave
                    onClick = { updateVenueData }
                  >儲存</ButtonSave>
                : <ButtonSave
                    disabled = { !userEditable }
                    onClick = { () => { setIsEditing(true) }}
                  >編輯</ButtonSave>
            }
            <ButtonSave
              disabled = { !userDeletable }
              onClick = { () => { setShowDeletePopup(true) }}
            >刪除</ButtonSave>
            <ButtonSave
              disabled = { !userUploadable }
              onClick = { () => { setShowAttachmentsPopup(true) }}
            >附件管理</ButtonSave>
            <ButtonClear
              onClick = { () => { history.goBack() }}
            >離開</ButtonClear>
          </DivButtonsWrapper>
        </DivFlexRow>
      </PanelVenueDetail>
      { showLoadingPopup && <PopupLoading/> }
      {
        showAttachmentsPopup &&
        <PopupAttachmentList
          fileChangable = { true }
          fileList = { fileList }
          uploadFiles = { uploadFiles }
          updateUploadFiles = { updateUploadFiles }
          fileUploadHandler = { fileUploadHandler }
          deleteFileHandler = { deleteFileHandler }
          cancelHandler = { () => { setShowAttachmentsPopup(false) }}
        />
      }
      {
        showDeleteFilePopup &&
          <PopupExecuteConfirm
            title = "刪除檔案"
            text = "確定刪除此上傳檔案"
            confirmHandler = { deleteFile }
            cancelHandler = { () => {
              setShowDeleteFilePopup(false);
            }}
          />
      }
      {
        showDeletePopup &&
          <PopupExecuteConfirm
            title = "刪除場地名單"
            text = "確定刪除此場地資料"
            confirmHandler = { deleteVenueHandler }
            cancelHandler = { () => { setShowDeletePopup(false) }}
          />
      }
      {
        showDeleteFileSuccessPopup &&
          <PopupExecuteResult
            title = "刪除檔案成功"
            confirmHandler = { () => {
              setShowDeleteFileSuccessPopup(false);
              setShowAttachmentsPopup(true);
            }}
          />
      }
      {
        showSaveSuccessPopup &&
          <PopupExecuteResult
            title = "更新成功"
            confirmHandler = { closeSuccessPopupHandler }
          />
      }
      {
        showDeleteSuccessPopup &&
          <PopupExecuteResult
            title = "刪除成功"
            confirmHandler = { () => { history.goBack() }}
          />
      }
      {
        showUploadSuccessPopup &&
          <PopupExecuteResult
            title = "上傳成功"
            confirmHandler = { uploadSuccessConfirmHandler }
          />
      }
      {
        showSaveFailPopup &&
          <PopupExecuteResult
            title = { apiFailPopupTitle }
            text = { apiFailPopupMessage }
            confirmHandler = { apiFailConfirmHandler }
          />
      }
    </MainContent>
  )
};

export default VenueDetail;
