import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  deleteExpenseTag,
  putNewExpenseTag,
  postToGetExpenseTags,
  patchModifyExpenseTag,
} from '@/api/expenseService';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import MainContent from '@/component-style/MainContent';
import TableExpenseTags from '@/components/Table/SysConfig/TableExpenseTags';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import PopupExecuteConfirm from '@/components/UI/Popup/PopupExecuteConfirm';
import PopupExpenseTagDetail from '@/components/UI/Popup/PopupExpenseTagDetail';

import { expenseTagConfig } from '@/constant/dataConfig/sysConfigModule';

const ExpenseTagManagement = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const sysConfigRoutes = useSelector(state => state.login.sysConfigRoutes);
  const showSaveFailPopup = useSelector(state => state.apiFail.showSaveFailPopup);
  const apiFailPopupTitle = useSelector(state => state.apiFail.title);
  const apiFailPopupMessage = useSelector(state => state.apiFail.message);

  const [expenseTags, setExpenseTags] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [selectedTag, setSelectedTag] = useState({ ...expenseTagConfig });

  const [isAdding, setIsAdding] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [showTagDetailPopup, setShowTagDetailPopup] = useState(false);
  const [showSaveSuccessPopup, setShowSaveSuccessPopup] = useState(false);
  const [showDeleteSuccessPopup, setShowDeleteSuccessPopup] = useState(false);

  const userEditable = sysConfigRoutes.indexOf('ExpenseTagManagement-Edit') !== -1;

  const addTagHandler = () => {
    setIsAdding(true);
    setSelectedTag({ ...expenseTagConfig });
    setShowTagDetailPopup(true);
  };

  const updateTagDetailHandler = (key, value) => {
    const updateObj = { ...selectedTag };
    updateObj[key] = value;
    setSelectedTag({ ...updateObj });
  };

  const closeDetailPopupHandler = () => {
    setIsAdding(false);
    setShowTagDetailPopup(false);
  };

  const showDetailHandler = index => {
    const selectedTag = expenseTags[index];
    setSelectedTag({ ...selectedTag });
    setShowTagDetailPopup(true);
  };

  const setCurrentPageHandler = page => {
    setCurrentPage(page);
    fetchExpenseTags(page);
  };

  const deleteHandler = index => {
    const selectedTag = expenseTags[index];
    setSelectedTag({ ...selectedTag });
    setShowDeletePopup(true);
  };

  const apiFailConfirmHandler = () => {
    dispatch(CLOSE_SAVE_FAIL_POPUP());

    apiFailPopupTitle === '儲存失敗'
      && setShowTagDetailPopup(true);
  };

  const closeSaveSuccessPopupHandler = () => {
    setIsAdding(false);
    setShowSaveSuccessPopup(false);
    setCurrentPageHandler(1);
  };

  const updateExpenseTagHandler = async () => {
    setShowTagDetailPopup(false);
    setShowLoadingPopup(true);
    const config = { expenseTypeID: id, tag: selectedTag }

    try {
      isAdding
        ? await putNewExpenseTag(config)
        : await patchModifyExpenseTag(config)

      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '儲存失敗', err }));
      console.log(err);
    }
  };

  const deleteExpenseTagHandler = async () => {
    setShowDeletePopup(false);
    setShowLoadingPopup(true);
    const config = {
      expenseTypeID: id,
      name: selectedTag.name,
    };

    try {
      await deleteExpenseTag(config);
      setShowLoadingPopup(false);
      setShowDeleteSuccessPopup(true);
    } catch(err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '刪除失敗', err }));
      console.log(err);
    }
  };

  const fetchExpenseTags = async (page = currentPage) => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetExpenseTags({
        expenseTypeID: id, page, amount: 10, 
      });

      const { expenseTags, totalCount } = data;
      const tags = expenseTags.map(tag => ({
        ...expenseTagConfig,
        ...tag,
        enable: tag.enable || false,
      }));
      setTotalCount(totalCount || 0);
      setExpenseTags(tags);
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const initTags = useCallback(
    fetchExpenseTags, []);

  useEffect(() => {
    initTags();
  }, [initTags]);

  return (
    <MainContent>
      <TableExpenseTags
        tags = { expenseTags }
        totalCount = { totalCount }
        currentPage = { currentPage }
        addTagHandler = { addTagHandler }
        deleteHandler = { deleteHandler }
        showDetailHandler = { showDetailHandler }
        pageChangedHandler = { setCurrentPageHandler }
      />
      { showLoadingPopup && <PopupLoading/> }
      {
        showTagDetailPopup &&
          <PopupExpenseTagDetail
            tag = { selectedTag }
            disableName = { !isAdding }
            disableSave = { isAdding ? false : !userEditable }
            cancelHandler = { closeDetailPopupHandler }
            confirmHandler = { updateExpenseTagHandler }
            updateTagDetailHandler = { updateTagDetailHandler }
          />
      }
      {
        showDeletePopup &&
          <PopupExecuteConfirm
            title = "刪除會計中分類"
            text = { `確定刪除 ${ selectedTag.name } 中分類？` }
            confirmHandler = { deleteExpenseTagHandler }
            cancelHandler = { () => { setShowDeletePopup(false) }}
          />
      }
      {
        showDeleteSuccessPopup &&
          <PopupExecuteResult
            title = "刪除成功"
            confirmHandler = { () => {
              setShowDeleteSuccessPopup(false);
              setCurrentPageHandler(1);
            }}
          />
      }
      {
        showSaveSuccessPopup &&
          <PopupExecuteResult
            title = "儲存成功"
            confirmHandler = { closeSaveSuccessPopupHandler }
          />
      }
      {
        showSaveFailPopup &&
          <PopupExecuteResult
            title = { apiFailPopupTitle }
            text = { apiFailPopupMessage }
            confirmHandler = { apiFailConfirmHandler }
          />
      }
    </MainContent>
  );
};

export default ExpenseTagManagement;
