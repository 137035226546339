import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { DivPopupWrapper, DivPopupXLarge } from '@/component-style/Popup';
import { ButtonCancel, ButtonBasic } from '@/component-style/Button';
// import { DivFlexRow } from '@/component-style/RWDWrapper';
import { InputBasic } from '@/component-style/Input';
import SelectSearchable from '@/components/UI/Select/SelectSearchable';

import PopupLoading from '@/components/UI/Popup/PopupLoading';

import { PSubtitle } from '@/component-style/TextElement';
import { integerConverter, floatConverter } from '@/utils/textUtils';
import MyInputNumber from '@/components/UI/MyInputNumber';
import SelectNormal from '@/components/UI/Select/SelectNormal';
import { cloneDeep } from 'lodash';
import { leaveParamsConfig, leaveGiveHourParamsConfig, leaveApprovalParamsConfig } from '@/constant/dataConfig/sysConfigModule.js';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import { leaveTypeApplicableForOptions, basicBooleanOptions, leaveTypePostExpiryActionOptions, leaveTypGiveDurationTypeOptions, projectRolesOptions } from '@/constant/options';
import TableWrapper from '@/components/UI/TableWrapper';

import {
  // postCreateLeaveSetting,
  patchLeaveSetting,
  postCreateLeaveGiveSetting,
  deleteLeaveGiveSetting,
  postCreateLeaveGiveHourSetting,
  postCreateLeave,
  patchUpdateLeave,
  postAddLeaveGiveHour,
  getLeaveGiveHourById,
  deleteRemoveLeaveGiveHours,
  putSaveLeaveApprovalSetting,
  deleteLeaveApprovalSetting,
  getApprovalSettingByLeaveId,
} from '@/api/financeService';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import {
  DivFlexRow,
  DivItemWrapper,
} from '@/component-style/RWDWrapper';

const DivButtonsWrapper = styled.div`
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const H3Title = styled.h3`
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: #8b8b8b;
  margin-bottom: 10px;
`;

const ButtonDiscard = styled(ButtonCancel)`
  width: 120px;
  margin-right: 10px;
`;

const ButtonSave = styled(ButtonBasic)`
  width: 120px;

  &:first-child { margin-right: 10px }
`;

const DivWrapper = styled.div`
  width: 100%;
  
  @media (min-width: 768px) {
    width: calc(100% - 135px);
  }
`;

var existingGiveHourIds = [];

const PopupEditLeaveType = props => {
  const dispatch = useDispatch();
  const showSaveFailPopup = useSelector(state => state.apiFail.showSaveFailPopup);
  const apiFailPopupTitle = useSelector(state => state.apiFail.title);
  const apiFailPopupMessage = useSelector(state => state.apiFail.message);

  const [leaveId, setLeaveId] = useState("");
  const [leave, setLeave] = useState(cloneDeep(leaveParamsConfig));

  const [leaveGiveHour, setLeaveGiveHour] = useState([]);
  const [leaveApproval, setLeaveApproval] = useState([]);
  
  const [showSaveSuccessPopup, setShowSaveSuccessPopup] = useState(false);
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [showDataErrorMessage, setShowDataErrorMessage] = useState("");

  const updateLeaveParamsHandler = (key, value) => {
    const updateObj = { ...leave };
    updateObj[key] = value;
    setLeave({ ...updateObj });
  };

  const updateLeaveApprovalParamsHandler = (index, key, value) => {
    console.log("PoupEditLeaveType.js updateLeaveApprovalParamsHandler() index:" + JSON.stringify(index) + " key:" + JSON.stringify(key) + " value:" + JSON.stringify(value));
    const updateObj = [ ...leaveApproval ];
    updateObj.forEach(function (element, i) {
      console.log("PoupEditLeaveType.js updateLeaveApprovalParamsHandler() i:" + JSON.stringify(i));
      if (i === index) {
        element[key] = value;
      }
    });
    setLeaveApproval([...updateObj]);
  };

  const addLeaveApproval = () => {
    console.log("PoupEditLeaveType.js addLeaveApproval() ");
    setLeaveApproval([...leaveApproval, cloneDeep(leaveApprovalParamsConfig)]);
  }

  const deleteLeaveApproval = (toDeleteIndex) => {
    console.log("PoupEditLeaveType.js deleteGiveHour() ");
    const result = leaveApproval.filter((element, i) => i !== toDeleteIndex);
    setLeaveApproval([...result]);
  }

  const updateLeaveGiveHourParamsHandler = (index, key, value) => {
    console.log("PoupEditLeaveType.js updateLeaveGiveHourParamsHandler() index:" + JSON.stringify(index) + " key:" + JSON.stringify(key));
    const updateObj = [ ...leaveGiveHour ];
    updateObj.forEach(function (element, i) {
      console.log("PoupEditLeaveType.js updateLeaveGiveHourParamsHandler() i:" + JSON.stringify(i));
      if (i === index) {
        element[key] = value;
      }
    });
    setLeaveGiveHour([...updateObj]);
  };

  const addGiveHour = () => {
    console.log("PoupEditLeaveType.js addGiveHour() ");
    var clonedObject = cloneDeep(leaveGiveHourParamsConfig);
    if (leave.giveDurationType === "yearly") {
      clonedObject.onBoardFrom = 0;
      clonedObject.onBoardTo = 0;
    } else if (leave.giveDurationType === "once") {
      clonedObject.onBoardFrom = 1;
      clonedObject.onBoardTo = 12;
    }
    setLeaveGiveHour([...leaveGiveHour, clonedObject]);
  }

  const deleteGiveHour = (toDeleteIndex) => {
    console.log("PoupEditLeaveType.js deleteGiveHour() ");
    const result = leaveGiveHour.filter((element, i) => i !== toDeleteIndex);
    setLeaveGiveHour([...result]);
  }

  const saveHandler = async () => {
    console.log("PoupEditLeaveType.js saveHandler() leaveSettingConfig:" + JSON.stringify(leave));
    if (leaveGiveHour.length === 0 ) {
      setShowDataErrorMessage("請填請假規則設定");
      return;
    }
    if (leaveGiveHour.length > 0) {
      if (leaveGiveHour[0].leaveHours === "") {
        setShowDataErrorMessage("請填請假規則設定");
        return;
      }
    }
    if (leaveApproval.length === 0) {
      setShowDataErrorMessage("請填簽核順序");
      return;
    }
    if (leaveApproval.length > 0) {
      if (leaveApproval[0].approvalSequence === "") {
        setShowDataErrorMessage("請填簽核順序");
        return;
      }
    }
    
    var tempLeaveId = leaveId;
    try {
      if (tempLeaveId === "") {
        delete leave.departmentId;
        const { data } = await postCreateLeave({
          ...leave,
        });
        setLeaveId(data.id);
        tempLeaveId = data.id;
      } else {
        delete leave.id;
        delete leave.isCompensated;

        let tempDepartmentId = leave.departmentId;
        delete leave.departmentId;
        if (tempDepartmentId === null || tempDepartmentId === undefined || tempDepartmentId === "all") {
          leave["departmentId"] = {
            value: "",
            specified: false,
          };
        } else {
          leave["departmentId"] = {
            value: tempDepartmentId,
            specified: true,
          };
        }
        console.log(`PoupEditLeaveType.js saveHandler() JSON.stringify(leave)=${JSON.stringify(leave)}`);

        await patchUpdateLeave(tempLeaveId, 
          {
            ...leave,
          }
        );
        console.log("PoupEditLeaveType.js saveHandler() JSON.stringify(existingGiveHourIds):" + JSON.stringify(existingGiveHourIds));
        if (existingGiveHourIds.length > 0) {
          await deleteRemoveLeaveGiveHours(existingGiveHourIds);
        }
        if (leaveApproval.length > 0) {
          await deleteLeaveApprovalSetting(tempLeaveId);
        }
      }
      console.log("PoupEditLeaveType.js saveHandler() JSON.stringify(leaveGiveHour):" + JSON.stringify(leaveGiveHour));
      if (leaveGiveHour.length > 0) {
        const { data } = await postAddLeaveGiveHour(tempLeaveId, leaveGiveHour);
        console.log("PoupEditLeaveType.js saveHandler() JSON.stringify(data):" + JSON.stringify(data));
      }

      const listApproval = leaveApproval.map((element)=> { return element.approvalSequence; });
      if (listApproval.length > 0) {
        const dataApproval = await putSaveLeaveApprovalSetting(tempLeaveId, listApproval);
        console.log("PoupEditLeaveType.js saveHandler() JSON.stringify(dataApproval):" + JSON.stringify(dataApproval));
      }
      
      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '儲存失敗', err }));
      console.log(err);
    }
  };

  const updateData = async () => {
    console.log("PoupEditLeaveType.js updateData() editLeaveId:" + JSON.stringify(props.editLeaveId));
    setLeaveId(props.editLeaveId);
    if (props.editLeaveId !== "") {
      var result = props.leaves.filter(obj => {
        return obj.id === props.editLeaveId
      })
      console.log("PoupEditLeaveType.js updateData() result:" + JSON.stringify(result));
      if (result[0].isCompensated === false) {
        delete result[0].isCompensated;
      }
      setLeave(cloneDeep(result[0]));

      const { data } = await getLeaveGiveHourById(props.editLeaveId);
      console.log("leaveManagement.js updateData() data:" + JSON.stringify(data.giveHours));

      existingGiveHourIds = data.giveHours.map((element) => {
        return element.id;
      });
      console.log(`PopupEditLeaveType.js updateData JSON.stringify(existingGiveHourIds): ${JSON.stringify(existingGiveHourIds)}`);

      try {
        const dataApprovalSetting = await getApprovalSettingByLeaveId(props.editLeaveId);
        console.log("leaveManagement.js editDetailHandler JSON.stringify(dataApprovalSetting):" + JSON.stringify(dataApprovalSetting));
        const existingApproval = dataApprovalSetting.data.approvalSequence.map((element) => {
          return { "approvalSequence": element};
        });
        setLeaveApproval(existingApproval);
      } catch (err) {
        console.log(err);
      }

      data.giveHours.forEach((element) => {
        delete element.id;
        delete element.leaveId;
      });
      setLeaveGiveHour(data.giveHours);
    }
  };

  const initData = useCallback(
    updateData
  , []
  );

  useEffect(() => {
    initData();
  }, [initData]);

  return (
    <DivPopupWrapper>
      <DivPopupXLarge>
        <H3Title>假別修改</H3Title>
        <DivFlexRow>
          <DivItemWrapper>
            <PSubtitle>假別名稱</PSubtitle>
            <InputBasic
              placeholder = "請填入假別名稱"
              value = { leave.name }
              onChange = { ({ target }) => {
                updateLeaveParamsHandler('name', target.value);
              }}
            />
          </DivItemWrapper>
          <DivItemWrapper>
            <PSubtitle>假別描述</PSubtitle>
            <InputBasic
            placeholder = "請填入假別描述"
            value = { leave.description }
            onChange = { ({ target }) => {
              updateLeaveParamsHandler('description', target.value);
            }}
          />
          </DivItemWrapper>
          <DivItemWrapper>
            <PSubtitle>部門(新增後可編輯)</PSubtitle>
            <SelectNormal
              disabled = { leaveId === "" }
              value = { leave.departmentId }
              options = {[
                { value: '', desc: '請選擇部門' },
                { value: 'all', desc: '全部' },
                ...props.departmentOptions,
              ]}
              changeHandler = { value => {
                updateLeaveParamsHandler('departmentId', value);
              }}
            />
          </DivItemWrapper>
        </DivFlexRow>
        <DivFlexRow>
          <DivItemWrapper>
            <PSubtitle>適用對象</PSubtitle>
            <SelectSearchable
              value = { leave.applicableFor }
              options = {[
                { value: '', desc: '請選擇適用對象' },
                ...leaveTypeApplicableForOptions,
              ]}
              changeHandler = { value => {
                updateLeaveParamsHandler('applicableFor', value);
              }}
            />
          </DivItemWrapper>
          <DivItemWrapper>
            <PSubtitle>最小請假時間(小時)</PSubtitle>
            <InputBasic
              type = "number"
              placeholder = "請填入最小請假時間"
              value = { floatConverter(leave.minLeaveMinutes / 60) }
              onChange = { ({ target }) => {
                updateLeaveParamsHandler('minLeaveMinutes', integerConverter(target.value * 60));
              }}
            />
          </DivItemWrapper>
          <DivItemWrapper>
            <PSubtitle>是否支薪</PSubtitle>
            <SelectSearchable
              value = { leave.isPaid }
              options = {[
                ...basicBooleanOptions,
              ]}
              changeHandler = { value => {
                updateLeaveParamsHandler('isPaid', value);
              }}
            />
            
          </DivItemWrapper>
        </DivFlexRow>
        <DivFlexRow>
          <DivItemWrapper>
            <PSubtitle>公司支付薪資乘數</PSubtitle>
            <InputBasic
              type = "number"
              placeholder = "請填入公司支付薪資乘數"
              value = { leave.salaryMultiplier }
              onChange = { ({ target }) => {
                updateLeaveParamsHandler('salaryMultiplier', floatConverter(target.value));
              }}
            />
          </DivItemWrapper>
          <DivItemWrapper>
            <PSubtitle>支薪小時數</PSubtitle>
            <InputBasic
              placeholder = "請填入支薪小時數"
              value = { leave.paidLeaveHours }
              onChange = { ({ target }) => {
                updateLeaveParamsHandler('paidLeaveHours', integerConverter(target.value));
              }}
            />
          </DivItemWrapper>
          <DivItemWrapper>
            <PSubtitle>假日計入請假時數</PSubtitle>
            <SelectSearchable
              value = { leave.areHolidaysCounted }
              options = {[
                ...basicBooleanOptions,
              ]}
              changeHandler = { value => {
                updateLeaveParamsHandler('areHolidaysCounted', value);
              }}
            />
          </DivItemWrapper>
        </DivFlexRow>
        <DivFlexRow>
          <DivItemWrapper>
            <PSubtitle>可休效期(日)</PSubtitle>
            <InputBasic
              placeholder = "可休效期(日)"
              value = { leave.expirationDays }
              onChange = { ({ target }) => {
                updateLeaveParamsHandler('expirationDays', integerConverter(target.value));
              }}
            />
          </DivItemWrapper>
          <DivItemWrapper>
            <PSubtitle>未休假時數轉換</PSubtitle>
            <SelectSearchable
              value = { leave.postExpiryAction }
              options = {[
                ...leaveTypePostExpiryActionOptions,
              ]}
              changeHandler = { value => {
                updateLeaveParamsHandler('postExpiryAction', value);
              }}
            />
          </DivItemWrapper>
          <DivItemWrapper>
            <PSubtitle>請假規則</PSubtitle>
            <SelectSearchable
              value = { leave.giveDurationType }
              options = {[
                ...leaveTypGiveDurationTypeOptions,
              ]}
              changeHandler = { value => {
                updateLeaveParamsHandler('giveDurationType', value);
              }}
            />
          </DivItemWrapper>
        </DivFlexRow>
        <DivFlexRow>
          {leave.giveDurationType === "once" &&
            <DivItemWrapper>
              <PSubtitle>能否有多張申請單</PSubtitle>
              <SelectSearchable
                value = { leave.hasMultipleApplications }
                options = {[
                  ...basicBooleanOptions,
                ]}
                changeHandler = { value => {
                  updateLeaveParamsHandler('hasMultipleApplications', value);
                }}
              />
            </DivItemWrapper>
          }
        </DivFlexRow>

        { 
          <TableWrapper
            title = "請假規則設定(必填)"
          >
            <DivFlexRow>
              <PSubtitle></PSubtitle>
            </DivFlexRow>
            {leave.giveDurationType === "onboard" &&
              <DivWrapper>
                {
                  leaveGiveHour !== undefined && leaveGiveHour.map((giveHour, index) => 
                    <DivFlexRow>
                      <DivItemWrapper>
                        <PSubtitle>依年資給予</PSubtitle>
                        <InputBasic
                          type = "number"
                          placeholder = "到職月起"
                          value = { giveHour.onBoardFrom }
                          onChange = { ({ target }) => {
                            updateLeaveGiveHourParamsHandler(index, 'onBoardFrom', floatConverter(target.value));
                          }}
                        />
                        &nbsp;&nbsp;
                        <InputBasic
                          type = "number"
                          placeholder = "到職月迄"
                          value = { giveHour.onBoardTo }
                          onChange = { ({ target }) => {
                            updateLeaveGiveHourParamsHandler(index, 'onBoardTo', floatConverter(target.value));
                          }}
                        />
                        &nbsp;&nbsp;
                        <InputBasic
                          type = "number"
                          placeholder = "給予小時數"
                          value = { giveHour.leaveHours }
                          onChange = { ({ target }) => {
                            updateLeaveGiveHourParamsHandler(index, 'leaveHours', integerConverter(target.value));
                          }}
                        />
                        &nbsp;&nbsp;
                        <ButtonSave
                          onClick = { () => {
                            deleteGiveHour(index);
                          }}
                        >刪除</ButtonSave>
                      </DivItemWrapper>
                    </DivFlexRow>
                  )
                }
                <DivFlexRow>
                  <ButtonSave
                    onClick = { addGiveHour }
                  >新增請假規則
                  </ButtonSave>
                </DivFlexRow>
                <DivFlexRow>
                  <PSubtitle></PSubtitle>
                </DivFlexRow>
              </DivWrapper>
            }
            { (leave.giveDurationType === "yearly" || leave.giveDurationType === "once") &&
              <DivWrapper>
                {
                  leaveGiveHour !== undefined && leaveGiveHour.map((giveHour, index) => 
                    <DivFlexRow>
                      <DivItemWrapper>
                        <PSubtitle>{leave.giveDurationType === "yearly" ? "每年給予" : "每次給予"}</PSubtitle>
                        <InputBasic
                          type = "number"
                          placeholder = "給予小時數"
                          value = { giveHour.leaveHours }
                          onChange = { ({ target }) => {
                            updateLeaveGiveHourParamsHandler(index, 'leaveHours', integerConverter(target.value));
                          }}
                        />
                        &nbsp;&nbsp;
                        <ButtonSave
                          onClick = { () => {
                            deleteGiveHour(index);
                          }}
                        >刪除</ButtonSave>
                      </DivItemWrapper>
                    </DivFlexRow>
                  )
                }
                <DivFlexRow>
                  <ButtonSave
                    disabled = { leaveGiveHour.length !== 0 }
                    onClick = { addGiveHour }
                  >新增請假規則
                  </ButtonSave>
                </DivFlexRow>
                <DivFlexRow>
                  <PSubtitle></PSubtitle>
                </DivFlexRow>
              </DivWrapper>
            }
          </TableWrapper>
        }

        <br></br>

        <TableWrapper
          title = "請假申請簽核順序(必填)"
        >
          <DivWrapper>
            {
              leaveApproval !== undefined && leaveApproval.map((approval, index) => 
                <DivFlexRow>
                  <DivItemWrapper>
                    <PSubtitle>簽核同仁專案角色</PSubtitle>
                    <SelectSearchable
                      value = { approval.approvalSequence }
                      options = {[
                        { value: '', desc: '請選擇簽核同仁專案角色' },
                        ...projectRolesOptions,
                      ]}
                      changeHandler = { value => {
                        updateLeaveApprovalParamsHandler(index, 'approvalSequence', value);
                      }}
                    />
                    &nbsp;
                    <ButtonSave
                      onClick = { () => {
                        deleteLeaveApproval(index);
                      }}
                    >刪除</ButtonSave>
                  </DivItemWrapper>
                </DivFlexRow>
              )
            }
            <DivFlexRow>
              <ButtonSave
                onClick = { addLeaveApproval }
              >新增簽核
              </ButtonSave>
            </DivFlexRow>
            <DivFlexRow>
              <PSubtitle></PSubtitle>
            </DivFlexRow>
          </DivWrapper>
        </TableWrapper>

        <DivButtonsWrapper>
          <ButtonSave
            onClick = { saveHandler }
          >儲存</ButtonSave>
          <ButtonDiscard
            onClick = { props.cancelHandler }
          >關閉
          </ButtonDiscard>
        </DivButtonsWrapper>
      </DivPopupXLarge>
      { showLoadingPopup && <PopupLoading/> }
      {
        showSaveSuccessPopup &&
          <PopupExecuteResult
            title = "更新成功"
            confirmHandler = { () => {
              setShowSaveSuccessPopup(false);
              props.reloadHandler();
            } }
          />
      }
      {
        showSaveFailPopup &&
          <PopupExecuteResult
            title = { apiFailPopupTitle }
            text = { apiFailPopupMessage }
            confirmHandler = { () => {
              dispatch(CLOSE_SAVE_FAIL_POPUP());
            }}
          />
      }
      {
        showDataErrorMessage.length > 0 &&
          <PopupExecuteResult
            title = "更新失敗"
            text = { showDataErrorMessage }
            confirmHandler = { () => {
              setShowDataErrorMessage("");
            } }
          />
      }
    </DivPopupWrapper>
  );
};

export default PopupEditLeaveType;
