import React from 'react';
import { useSelector } from 'react-redux';
// import { useHistory } from 'react-router-dom';

import { Table, TableBody } from '@/component-style/Table';
import TableWrapper  from '@/components/UI/TableWrapper';
import PageSelector from '@/components/UI/PageSelector';
import Header from './Header';
import Item from './Item';

const TableOvertimeByPersonnelDetail = props => {
  const { overtimeRecords } = props;
  // const history = useHistory();
  const attendanceRoutes = useSelector(state => state.login.attendanceRoutes);
  const disableDetailPage = attendanceRoutes.indexOf('OvertimeDetail') === -1;

  return (
    <TableWrapper title = '加班列表'>
      <Table>
        <Header/>
        <TableBody>
          {
            overtimeRecords.map(overtimeRecord =>
              <Item
                key = { overtimeRecord.workingHoursID }
                overtimeRecords = { overtimeRecords }
                overtimeRecord = { overtimeRecord }
                disableDetailPage = { disableDetailPage }
                workingHoursParamsConfig = { props.workingHoursParamsConfig }
              />
            )
          }
        </TableBody>
      </Table>
      <PageSelector
        current = { props.currentPage }
        total = { props.totalCount }
        pageChangedHandler = { props.pageChangedHandler }
      />
    </TableWrapper>
  );
};

export default TableOvertimeByPersonnelDetail;
