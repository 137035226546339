import axiosConfig from './axiosConfig';
import { getDefaultPort } from '@/utils/apiRequestUtils';

const { baseURL } = axiosConfig.defaults;

const port = process.env.REACT_APP_SERVICE_PORT_EXPENSE !== undefined
  ? process.env.REACT_APP_SERVICE_PORT_EXPENSE
  : getDefaultPort(baseURL);

const expenseModuleUrl = process.env.NODE_ENV === 'development'
  ? `${baseURL}:${port}/api`
  : `${baseURL}/expense/api`;

export const putNewQuotation = quotation => axiosConfig
  .put(`${expenseModuleUrl}/expense/quotation`, quotation);

export const postToGetQuotationsWithArrayOfProjectIDs = config => axiosConfig
  .post(`${expenseModuleUrl}/v2/expense/quotation`, config);

export const postToGetQuotations = config => axiosConfig
  .post(`${expenseModuleUrl}/expense/quotation`, config);

export const deleteQuotation = quotationID => axiosConfig
  .delete(`${expenseModuleUrl}/expense/quotation`, { data: { quotationID } });

export const postToGetQuotationDetail = quotationID => axiosConfig
  .post(`${expenseModuleUrl}/expense/quotationDetail`, { quotationID });

export const putModifyQuotationDetail = quotation => axiosConfig
  .put(`${expenseModuleUrl}/expense/quotationDetail`, quotation);

export const postToGetExpenseTypes = config => axiosConfig
  .post(`${expenseModuleUrl}/expense/types`, config);

export const deleteExpenseTypes = ids => axiosConfig
  .delete(`${expenseModuleUrl}/expense/types`, { data: [ ...ids ]});

export const putNewExpenseType = config => axiosConfig
  .put(`${expenseModuleUrl}/expense/types`, config);

export const patchModifyExpenseType = config => axiosConfig
  .patch(`${expenseModuleUrl}/expense/types`, config);

export const postToGetExpenseTags = config => axiosConfig
  .post(`${expenseModuleUrl}/expense/type/tag`, config);

export const putNewExpenseTag = config => axiosConfig
  .put(`${expenseModuleUrl}/expense/type/tag`, config);

export const patchModifyExpenseTag = config => axiosConfig
  .patch(`${expenseModuleUrl}/expense/type/tag`, config);

export const deleteExpenseTag = config => axiosConfig
  .delete(`${expenseModuleUrl}/expense/type/tag`, { data: { ...config } });

export const postToGetExpenses = config => axiosConfig
  .post(`${expenseModuleUrl}/expense`, config);

export const putNewExpense = expense => axiosConfig
  .put(`${expenseModuleUrl}/expense`, expense);

export const deleteExpenses = expenseIDs => axiosConfig
  .delete(`${expenseModuleUrl}/expense`, { data: [ ...expenseIDs ] });

export const postToGetExpenseDetail = expenseID => axiosConfig
  .post(`${expenseModuleUrl}/expense/detail`, { expenseID });

export const putModifyExpenseDetail = expense => axiosConfig
  .put(`${expenseModuleUrl}/expense/detail`,  expense);

export const postToGetExpenseParamsConfig = () => axiosConfig
  .post(`${expenseModuleUrl}/sysConfig/expenseParams`);

export const putModifyExpenseParamsConfig = config => axiosConfig
  .put(`${expenseModuleUrl}/sysConfig/expenseParams`, config);

export const postToGetVouchersTotal = config => axiosConfig
  .post(`${expenseModuleUrl}/expense/vouchersTotal`, config);

export const postToGetVouchersTotalWithArrayOfProjectIDs = config => axiosConfig
  .post(`${expenseModuleUrl}/v2/expense/vouchersTotal`, config);

export const postToGetExpenseQuotationIDs = config => axiosConfig
  .post(`${expenseModuleUrl}/expense/quotationIDs`, config);

export const postToGetExpenseVouchers = config => axiosConfig
  .post(`${expenseModuleUrl}/expense/vouchers`, config);

export const postToGetProjectVendorIDs = projectID => axiosConfig
  .post(`${expenseModuleUrl}/expense/quotation/vendors`, { projectID });

export const postToGetExpectedCost = config => axiosConfig
  .post(`${expenseModuleUrl}/expense/expectedExpenses`,  config);

export const postToGetExpensePDFUrl = expenseIDs => axiosConfig
  .post(`${expenseModuleUrl}/expense/printExpenses`, { expenseIDs });

export const postToCheckInvoices = invoices => axiosConfig
  .post(`${expenseModuleUrl}/expense/checkInvoices`,  invoices );

export const postToSetExportedAccountingSystem = config => axiosConfig
  .post(`${expenseModuleUrl}/expense/setExportedAccountingSystem`, config);

export const postToAddShareInvoice = config => axiosConfig
  .post(`${expenseModuleUrl}/sysConfig/expenseParams/addShareInvoice`, config);

export const postToDeleteShareInvoice = config => axiosConfig
  .post(`${expenseModuleUrl}/sysConfig/expenseParams/delShareInvoice`, config);
