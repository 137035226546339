import React from 'react';
import styled from 'styled-components';

import { TdItem, TableRow } from '@/component-style/Table';
import { ButtonGoDetail } from '@/component-style/Button';
// import { formatTime } from '@/utils/timeUtils';
import moment from 'moment';

const TrWrapper = styled(TableRow)`
  border-bottom: solid 2px #f2f2f2;
  box-sizing: border-box;
  min-width: 640px;

  &:last-child {
    border-bottom: none;
  }
`;

const Td80 = styled(TdItem)`
  width: 80px;
`;

const Td120 = styled(TdItem)`
  width: 120px;
`;
const Td160 = styled(TdItem)`
  width: 160px;
`;

const Item = props => {
  const { punchTime } = props;
  const { startTimeLocal, endTimeLocal } = punchTime;

  const start = moment.parseZone(startTimeLocal).format('YYYY/MM/DD HH:mm:ss');
  const end = endTimeLocal == null ? "" : moment.parseZone(endTimeLocal).format('YYYY/MM/DD HH:mm:ss');
  // const start = formatTime(startTimeLocal, 'YYYY/MM/DD HH:mm:ss');
  // const end = formatTime(endTimeLocal, 'YYYY/MM/DD HH:mm:ss');

  return (
    <TrWrapper>
      <Td120>{ punchTime.username }</Td120>
      <Td120>{ punchTime.date }</Td120>
      <Td160>{ start }</Td160>
      <Td160>{ end }</Td160>
      <Td80>
        <ButtonGoDetail
          disabled = { props.disableDetailPage }
          onClick = { () => { props.goDetailHandler() } }
        >...
        </ButtonGoDetail>
      </Td80>
    </TrWrapper>
  );
};

export default Item;
