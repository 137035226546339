import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { cloneDeep } from 'lodash';

import { postToGetProjectDetail, putProjectDetail } from '@/api/projectService';
import { postToGetExpenseParamsConfig } from '@/api/expenseService';

import {
  postToGetExpenses,
  postToGetQuotations,
  postToGetExpenseDetail,
  postToGetVouchersTotal,
  postToGetExpenseQuotationIDs,
} from '@/api/expenseService';

import {
  deleteUploadFile,
  postNewUploadFile,
  postToGetUploadFiles,
  // getToDownloadCompressedFiles,
  getVoucherDownloadFile,
} from '@/api/storageService';

import {
  postToGetProjectFinance,
  patchModifyProjectFinance,
  postToGetProjectQuotations,
  putNewProjectQuotations,
  deleteProjectQuotations,
  postToGetProjectQuotationDetail,
  putModifyProjectQuotationDetail,
  postToGetProjectInvoicesDetail,
  postToGetProjectPRServices,
  postToGetExportQuotationUrl,
} from '@/api/financeService';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import {
  FETCH_MEMBER_OPTIONS,
  FETCH_DIRECTOR_OPTIONS,
  FETCH_VENDOR_ID_OPTIONS,
  FETCH_TODO_TYPE_OPTIONS,
  FETCH_PROJECT_ID_OPTIONS,
  FETCH_VENDOR_TYPE_OPTIONS,
  FETCH_EXPENSE_TYPE_OPTIONS,
  FETCH_COMPANY_TYPE_OPTIONS,
} from '@/store/actions/srpOptionsAction';

import MainContent from '@/component-style/MainContent';
import PanelWrapper from '@/components/UI/PanelWrapper';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import PopupExecuteConfirm from '@/components/UI/Popup/PopupExecuteConfirm';
import TableProjectStatus from '@/components/Table/Project/TableProjectStatus';
import TableProjectQuotations from '@/components/Table/Finance/TableProjectQuotations';
import PanelQuotationDetail from '@/components/Panel/Finance/PanelQuotationDetail';
import PanelProjectQuotation from '@/components/Panel/Finance/PanelProjectQuotation';
import PanelPRserviceInvoices from '@/components/Panel/Finance/PanelPRserviceInvoices';
import PanelQuotationInvoices from '@/components/Panel/Finance/PanelQuotationInvoices';
import PanelProjectAccounting from '@/components/Panel/Finance/PanelProjectAccounting';
import PopupPurchaseAttachments from '@/components/UI/Popup/PopupPurchaseAttachments';
import PopupExportMethodSelect from '@/components/UI/Popup/PopupExportMethodSelect';
import SelectSearchable from '@/components/UI/Select/SelectSearchable';
import SearchButtonSet from '@/components/UI/SearchButtonSet';
import { PSubtitle } from '@/component-style/TextElement';
import {
  DivFlexRow,
  DivFullWidthWrapper,
  DivLongMultiSelector,
} from '@/component-style/RWDWrapper';

import { integerConverter, floatConverter, getOptionDesc } from '@/utils/textUtils';
import {
  projectFinanceConfig,
  projectPRServiceItem,
  projectInvoicesConfig,
  projectQuotationConfig,
  projectPRServicesConfig,
} from '@/constant/dataConfig/financeModule';

import { vendorInvoiceTypes } from '@/constant/options';
import { exportExpenditureFile } from '@/utils/xlsx';
import { projectStatusConfig } from '@/constant/dataConfig/projectModule';
import { downloadFromUrl } from '@/utils/apiRequestUtils';
import {
  // diffTime,
  formatTime,
} from '@/utils/timeUtils';

const DivPanelContainer = styled.div`
  margin-bottom: 40px;
`;

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding: 0;
  justify-content: flex-end;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const exportMethodOptions = [
  { value: 'quote', desc: '匯出報價單' },
  { value: 'quoteModify', desc: '匯出報價單(議價後)' },
  { value: 'quoteMerge', desc: '匯出合併報價單' },
  { value: 'quoteMergeModify', desc: '匯出合併報價單(議價後)' },
];

const ProjectFinance = () => {
  const dispatch = useDispatch();
  const projectRoutes = useSelector(state => state.login.projectRoutes);
  const vendorIDs = useSelector(state => state.srpOptions.vendorIDOptions);
  const memberOptions = useSelector(state => state.srpOptions.memberOptions);
  const directorOptions = useSelector(state => state.srpOptions.directorOptions);
  const projectIDOptions = useSelector(state => state.srpOptions.projectIDOptions);
  const expenseTypeOptions = useSelector(state => state.srpOptions.expenseTypeOptions);
  const showSaveFailPopup = useSelector(state => state.apiFail.showSaveFailPopup);
  const apiFailPopupTitle = useSelector(state => state.apiFail.title);
  const apiFailPopupMessage = useSelector(state => state.apiFail.message);

  const [agentDifference, setAgentDifference] = useState(0);

  const [quotations, setQuotations] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [deleteFileId, setDeleteFileId] = useState('');
  const [deleteQuotationId, setDeleteQuotationId] = useState('');
  const [fileList, setFileList] = useState([]);
  const [uploadFiles, setUploadFiles] = useState([]);
  const [poOptions, setPoOptions] = useState([]);
  const [invoiceOptions, setInvoiceOptions] = useState([]);

  const [quotationDetail, setQuotationDetail] = useState(cloneDeep(projectQuotationConfig))

  const [projectID, setProjectID] = useState('');
  const [customerID, setCustomerID] = useState('');
  const [projectStatus, setProjectStatus] = useState({ ...projectStatusConfig });
  const [projectDetail, setProjectDetail] = useState({});

  const [poFileName, setPoFileName] = useState('');
  const [projectFinance, setProjectFinance] = useState({
    ...projectFinanceConfig
  });

  const [projectInvoice, setProjectInvoices] = useState(
    cloneDeep(projectInvoicesConfig)
  );
  const [projectPRServices, setProjectPRServices] = useState(
    cloneDeep(projectPRServicesConfig)
  );

  const [prPriceTotal ,setPrPriceTotal] = useState('');
  const [prServiceTotal, setPRServiceTotal] = useState('');
  const [voucherPriceTotal ,setVoucherPriceTotal] = useState('');
  const [oopPriceTotal ,setOopPriceTotal] = useState('');
  const [prQuotedPriceTotal ,setPrQuotedPriceTotal] = useState('');
  const [oopQuotedPriceTotal ,setOopQuotedPriceTotal] = useState('');
  const [quotationPriceTotal, setQuotationPriceTotal] = useState('');

  //Ian calc expense total
  const [untaxedExpenseTotal ,setUntaxedExpenseTotal] = useState('');
  const [taxedExpenseTotal ,setTaxedExpenseTotal] = useState('');

  const [untaxedIncomeTotal ,setUntaxedIncomeTotal] = useState('');
  const [taxedIncomeTotal ,setTaxedIncomeTotal] = useState('');

  const [projectOtherIncome ,setProjectOtherIncome] = useState('');

  const [serviceFeeTotal, setServiceFeeTotal] = useState('');

  const [exportMethod, setExportMethod] = useState('quote');
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  // const [alertText, setAlertText] = useState('');
  // const [showAlertPopup, setShowAlertPopup] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showExportPopup, setShowExportPopup] = useState(false);
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [showAttachmentPopup, setShowAttachmentPopup] = useState(false);
  const [showDeleteFilePopup, setShowDeleteFilePopup] = useState(false);
  const [showSaveSuccessPopup, setShowSaveSuccessPopup] = useState(false);
  const [showUploadSuccessPopup, setShowUploadSuccessPopup] = useState(false);
  const [showDeleteSuccessPopup, setShowDeleteSuccessPopup] = useState(false);
  const [showDeleteFileSuccessPopup, setShowDeleteFileSuccessPopup] = useState(false);

  const userEditable = projectRoutes.indexOf('ProjectFinance-Edit') !== -1;
  const userExportable = projectRoutes.indexOf('ProjectFinance-Export') !== -1;

  const [isEditingAccounting, setIsEditingAccounting] = useState(false);
  const projectFinanceInputClearedEditable = projectRoutes.indexOf('ProjectFinance-Input-Cleared') !== -1;
  // console.log("projectFinance.js projectFinanceInputClearedEditable:" + projectFinanceInputClearedEditable);

  const disableProjectQuotation = projectFinance.projectID === ''
    || isAdding || isEditing;

  const cancelProjectFinanceHandler = async () => {
    await fetchProjectFinance();
    setIsEditingAccounting(false);
  };

  const updateProjectDetailHandler = (key, value) => {
    const updateObj = { ...projectDetail };
    updateObj[key] = value;
    setProjectDetail(updateObj);
  };

  const saveProjectFinanceHandler = async () => {
    setShowLoadingPopup(true);
    console.log("projectFinance.js projectDetail:" + JSON.stringify(projectDetail));

    try {
      await patchModifyProjectFinance({
        ...projectFinance,
        specialPrice: integerConverter(projectFinance.specialPrice),
      });
      await fetchProjectFinance();
      await putProjectDetail({
        ...projectDetail,
      });
      await fetchProjectStatus();
      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
      setIsEditingAccounting(false);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '儲存失敗', err }));
      console.log(err);
    }

    console.log("projectFinance.js projectDetail:" + JSON.stringify(projectDetail));
  };
  
  const updateQuotationDetailHandler = (key, value) => {
    const updateQuotation = cloneDeep(quotationDetail);
    updateQuotation[key] = value;
    setQuotationDetail(updateQuotation);
  };

  const updateQuotationTypeHandler = value => {
    const { quotationItems } = quotationDetail;

    setQuotationDetail(prevState => ({
      ...prevState,
      type: value,
      rate: value === 'oop' ? 10 : 0,
      quotationItems: quotationItems
        .map(item => ({ ...item, type: '', tag: '' })),
    }));
  };

  const clearHandler = () => {
    setProjectID('');
    setCustomerID('');
    setQuotations([]);
    setTotalCount(0);
    setCurrentPage(1);
    setFileList([]);
    setPoOptions([]);
    setInvoiceOptions([]);
    setProjectID('');
    setPrPriceTotal('');
    setOopPriceTotal('');
    setPRServiceTotal('');
    setVoucherPriceTotal('');
    setPrQuotedPriceTotal('');
    setOopQuotedPriceTotal('');
    setQuotationPriceTotal('');
    setProjectFinance({ ...projectFinanceConfig });
    setProjectInvoices(cloneDeep(projectInvoicesConfig));
    setProjectPRServices(cloneDeep(projectPRServicesConfig));
    setProjectStatus({ ...projectStatusConfig });
    setQuotationDetail(cloneDeep(projectQuotationConfig));
  };

  const specialPriceChangeHandler = value => {
    setProjectFinance(prevState => ({
      ...prevState,
      specialPrice: value,
    }));
  };

  const addNewQuotationHandler = () => {
    setQuotationDetail(cloneDeep(projectQuotationConfig));
    setIsAdding(true);
  };

  const quotationDetailCancelHandler = () => {
    setIsAdding(false);
    setIsEditing(false);
  };

  const deleteHandler = id => {
    setDeleteQuotationId(id);
    setShowDeletePopup(true);
  };

  const saveQuotationSuccessHandler = () => {
    setShowSaveSuccessPopup(false);
    fetchProjectQuotations();
    setIsAdding(false);
    setIsEditing(false);
  };

  const updateUploadFiles = value => {
    setUploadFiles([ ...value ]);
  };

  const uploadSuccessConfirmHandler = () => {
    setPoFileName('');
    setUploadFiles([]);
    setShowUploadSuccessPopup(false);
    setShowAttachmentPopup(true);
  };

  const deleteFileHandler = id => {
    setDeleteFileId(id);
    setShowDeleteFilePopup(true);
  };

  const setCurrentPageHandler = page => {
    setCurrentPage(page);
    fetchProjectQuotations(page);
  };

  const apiFailConfirmHandler = () => {
    dispatch(CLOSE_SAVE_FAIL_POPUP());

    apiFailPopupTitle === '上傳失敗'
      && setShowAttachmentPopup(true);
  };

  const openAttachmentPopupHandler = async () => {
    await fetchUploadFiles();
    setShowAttachmentPopup(true);
  };

  const exportQuotationHandler = async () => {
    setShowExportPopup(false);
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetExportQuotationUrl({
        projectID,
        type: exportMethod,
      });
      downloadFromUrl(data);
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  }

  const downloadVoucherHandler = async () => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetExpenseQuotationIDs({ projectID });
      console.log("projectFinance.js downloadVoucherHandler data:" + data.join());
      const zipFile = await getVoucherDownloadFile(
        {
          voucherID: data.join(),
        }
      );
      const url = window.URL.createObjectURL(new Blob([zipFile.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'quotationVoucher.zip');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const exportVouchersHandler = async () => {
    setShowLoadingPopup(true);
    let financeDatas = [];

    try {
      const expensesDetail = await postToGetExpenses({
        projectID,
        page: 1,
        amount: 10,
        approvalStatus: [
          'projectManagerVerify',
          'projectDirectorVerify',
          'financeVerify',
          'generalManagerVerifying',
          'generalManagerApproved',
        ]
      });

      const { data } = await postToGetExpenses({
        projectID,
        page: 1,
        amount: expensesDetail.data.totalCount || 0,
        approvalStatus: [
          'projectManagerVerify',
          'projectDirectorVerify',
          'financeVerify',
          'generalManagerVerifying',
          'generalManagerApproved',
        ]
      });
      const { expenses } = data;
      const expensesData = [];

      for (let i = 0; i < expenses.length; i += 1) {
        const expense = expenses[i];
        const { expenseID } = expense;
        const expenseDetail = await postToGetExpenseDetail(expenseID);

        expensesData.push({ ...expenseDetail.data });

        financeDatas = expensesData
          .reduce((acc, expense) => {
            const expenses = expense.vouchers.map(voucher => [
              getOptionDesc(memberOptions, expense.user),
              expense.expenseCode || '',
              expense.object === 'user'
                ? getOptionDesc(memberOptions, expense.objectID)
                : getOptionDesc(vendorIDs, expense.objectID),
              getOptionDesc(vendorInvoiceTypes, voucher.voucherType),
              voucher.date ? formatTime(voucher.date, 'YYYY/MM/DD') : '',
              voucher.voucherCode,
              voucher.taxID,
              voucher.untaxedAmount || 0,
              voucher.tax || 0,
              voucher.amount || 0,
              getOptionDesc(expenseTypeOptions, voucher.type),
              voucher.tag,
              voucher.note,
            ]);
            return [...acc, ...expenses];
          }, []);
      }

    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
    exportExpenditureFile(financeDatas);
  };

  const showQuotationDetailHandler = async projectQuotationID => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetProjectQuotationDetail(projectQuotationID);
      
      setQuotationDetail({
        ...data,
        quotationItems: data.quotationItems.map(item => ({
          ...item,
          type: item.todoTypeID || item.vendorTypeID,
        })),
      });
      setIsEditing(true);
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false)
  };

  const saveSpecialPriceHandler = async () => {
    setShowLoadingPopup(true);

    try {
      await patchModifyProjectFinance({
        ...projectFinance,
        specialPrice: integerConverter(projectFinance.specialPrice),
      });
      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '儲存失敗', err }));
      console.log(err);
    }
  };

  const fileUploadHandler = async () => {
    setShowAttachmentPopup(false);
    setShowLoadingPopup(true);

    try {
      const [file] = uploadFiles;
      const formData = new FormData();
      formData.append('upfile', file);
      formData.append('objectType', 'projectFinance');
      formData.append('objectID', projectID);
      formData.append('name', poFileName);
      await postNewUploadFile(formData);
      await fetchUploadFiles();
      setShowLoadingPopup(false);
      setShowUploadSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '上傳失敗', err }));
      console.log(err);
    };
  };

  const deleteFile = async () => {
    setShowDeleteFilePopup(false);
    setShowAttachmentPopup(false);
    setShowLoadingPopup(true);

    try {
      await deleteUploadFile(deleteFileId);
      await fetchUploadFiles();
      setShowLoadingPopup(false);
      setShowDeleteFileSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '刪除檔案失敗', err }));
      console.log(err);
    }
  };

  const fetchUploadFiles = async () => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetUploadFiles('projectFinance', projectID);
      const poOptions = data.map(({ name }) => ({ value: name, desc: name }));
      
      setFileList([ ...data ]);
      setPoOptions([ ...poOptions ]);
      setProjectStatus(prevState => ({
        ...prevState,
        isVerified: data.length > 0,
      }));
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const saveQuotationHandler = async () => {
    setShowLoadingPopup(true);
    const { type } = quotationDetail;

    const quotationItems = quotationDetail.quotationItems
      .map(item => ({
        ...item,
        price: integerConverter(item.price),
        amount: integerConverter(item.amount),
        quotedPrice: integerConverter(item.quotedPrice),
        // todoTypeID: type === 'oop' ? item.type : null,
        todoTypeID: null,
        // vendorTypeID: type === 'pr' ? item.type : null,
    }));
    quotationItems.forEach(item => {
      delete item.type;
    });
    console.log("saveQuotationHandler JSON.stringify(quotationItems):" + JSON.stringify(quotationItems));

    try {
      isAdding
        ? await putNewProjectQuotations({
            ...quotationDetail,
            projectID,
            customerID,
            quotationItems,
            rate: floatConverter(quotationDetail.rate),
            createdDate: formatTime(Date.now(), 'YYYY-MM-DD'),
          })
        : await putModifyProjectQuotationDetail({
            ...quotationDetail,
            quotationItems,
            rate: floatConverter(quotationDetail.rate),
            modifiedDate: formatTime(Date.now(), 'YYYY-MM-DD'),
          });
      
      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '儲存失敗', err }));
      console.log(err);
    }
  };

  const fetchProjectStatus = async () => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetProjectDetail(projectID);

      setCustomerID(data.customer || '');
      setProjectStatus({
        projectCode: data.projectCode,
        projectName: data.projectName,
        companyType: data.companyType || '',
        actionTimeEnd: data.actionTimeEnd,
        actionTimeStart: data.actionTimeStart,
      });

      setProjectDetail({
        ...data
      });
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const fetchProjectFinance = async () => {
    console.log("ProjectFinance fetchProjectFinance");
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetProjectFinance(projectID);
      console.log("ProjectFinance fetchProjectFinance data:" + JSON.stringify(data));

      setProjectFinance({
        ...projectFinanceConfig,
        ...data,
        projectID,
        specialPrice: data.specialPrice || '',
      });
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const deleteQuotationHandler = async () => {
    setShowDeletePopup(false);
    setShowLoadingPopup(true);

    try {
      await deleteProjectQuotations(deleteQuotationId);
      setShowLoadingPopup(false);
      setShowDeleteSuccessPopup(true);
    } catch(err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '刪除失敗', err }));
      console.log(err);
    }
  };

  const fetchProjectInvoices = async () => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetProjectInvoicesDetail(projectID);
      const { invoices } = data;
      const invoiceOptions = invoices
        .filter(({ invoiceCode }) => invoiceCode !== '')
        .map(({ invoiceCode }) => ({ value: invoiceCode, desc: invoiceCode }));
      console.log("projectFinanceInfo.js fetchProjectInvoices data.invoices:" + JSON.stringify(data.invoices));
      const prServiceTotal = data.invoices
        .map(({ untaxedAmount }) => integerConverter(untaxedAmount || 0))
        .reduce((acc, cur) => acc + cur, 0);

      setPRServiceTotal(prServiceTotal);

      const isInvoiced = invoices.length > 0;

      // const isPaid = invoices
      //   .some(quotation => quotation.paymentDate !== undefined);

      const predictDates = invoices
        .filter(({ predictDate }) => predictDate !== undefined)
        .map(({ predictDate }) => predictDate);

      const predictDate = predictDates.length > 0
        ? predictDates.shift()
        : null;

      setInvoiceOptions(invoiceOptions);
      setProjectInvoices({
        ...cloneDeep(projectInvoicesConfig),
        ...data,
        companyType: data.companyType || '',
      });
      setProjectStatus(prevState => ({
        ...prevState,
        // isPaid,
        isInvoiced,
        predictDate: isInvoiced ? predictDate : null,
      }));

      //get expenses
      let financeDatas = [];
      const expensesDetail = await postToGetExpenses({
        projectID,
        page: 1,
        amount: 10,
        approvalStatus: [
          'projectManagerVerify',
          'projectDirectorVerify',
          'financeVerify',
          'generalManagerVerifying',
          'generalManagerApproved',
        ]
      });

      const myData = await postToGetExpenses({
        projectID,
        page: 1,
        amount: expensesDetail.data.totalCount || 0,
        approvalStatus: [
          'projectManagerVerify',
          'projectDirectorVerify',
          'financeVerify',
          'generalManagerVerifying',
          'generalManagerApproved',
        ]
      });
      const { expenses } = myData.data;
      const expensesData = [];

      for (let i = 0; i < expenses.length; i += 1) {
        const expense = expenses[i];
        const { expenseID } = expense;
        const expenseDetail = await postToGetExpenseDetail(expenseID);

        expensesData.push({ ...expenseDetail.data });

        financeDatas = expensesData
          .reduce((acc, expense) => {
            const expenses = expense.vouchers.map(voucher => [
              getOptionDesc(memberOptions, expense.user),
              expense.expenseCode || '',
              expense.object === 'user'
                ? getOptionDesc(memberOptions, expense.objectID)
                : getOptionDesc(vendorIDs, expense.objectID),
              getOptionDesc(vendorInvoiceTypes, voucher.voucherType),
              voucher.date ? formatTime(voucher.date, 'YYYY/MM/DD') : '',
              voucher.voucherCode,
              voucher.taxID,
              voucher.untaxedAmount || 0,
              voucher.tax || 0,
              voucher.amount || 0,
              getOptionDesc(expenseTypeOptions, voucher.type),
              voucher.tag,
              voucher.note,
            ]);
            return [...acc, ...expenses];
          }, []);
      }
      // console.log("projectFinanceInfo.js fetchProjectInvoices financeDatas:" + JSON.stringify(financeDatas));
      var untaxedIncome = 0;
      var taxedIncome = 0;
      data.invoices.forEach(function (arrayItem) {
        untaxedIncome += arrayItem.untaxedAmount;
        taxedIncome += arrayItem.amount;
      });
      setUntaxedIncomeTotal(untaxedIncome);
      setTaxedIncomeTotal(taxedIncome);
      console.log("projectFinanceInfo.js fetchProjectInvoices untaxedIncome:" + untaxedIncome + " taxedIncome:" + taxedIncome);
      var untaxedExpense = 0;
      var taxedExpense = 0;
      financeDatas.forEach(function (arrayItem) {
        // var x = arrayItem.prop1 + 2;
        // console.log(arrayItem[7]);
        untaxedExpense += arrayItem[7];
        taxedExpense += arrayItem[9];
      });
      console.log("projectFinanceInfo.js fetchProjectInvoices untaxedExpense:" + untaxedExpense + "taxedExpense:" + taxedExpense);
      setUntaxedExpenseTotal(untaxedExpense);
      setTaxedExpenseTotal(taxedExpense);
      setServiceFeeTotal(untaxedIncome - untaxedExpense - projectFinance.projectOtherIncome);
      //get expenses
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const fetchProjectPRServices = async () => {
    // console.log("projectFinance.js fetchProjectPRservices");
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetProjectPRServices(projectID);
      // const prServiceTotal = data.prServices
      //   .map(({ prPrice, oopPrice, income }) => integerConverter(prPrice || 0)
      //     + integerConverter(oopPrice || 0)
      //     + integerConverter(income || 0))
      //   .reduce((acc, cur) => acc + cur, 0);

      // setPRServiceTotal(prServiceTotal);
      setProjectPRServices({
        ...cloneDeep(projectPRServicesConfig),
        ...data,
        companyType: data.companyType || '',
        prServices: data.prServices
          .map(prServices => ({ ...projectPRServiceItem, ...prServices }))
      });
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const fetchProjectQuotations = async (page = currentPage) => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetProjectQuotations({
        projectID,
        amount: 10,
        page,
      });
      const { totalCount, quotations } = data;

      const totalQuotations = await postToGetProjectQuotations({
        projectID,
        page: 1,
        amount: totalCount || 1,
      });

      const quotationsDetail = await postToGetQuotations({ projectID });
      const voucherTotalDetail = await postToGetVouchersTotal({
        projectID,
        object: [],
        item: 'amount',
        approvalStatus: [
          'projectManagerVerify',
          'projectDirectorVerify',
          'financeVerify',
          'generalManagerVerifying',
          'generalManagerApproved',
        ],
      });

      const quotationPriceTotal = quotationsDetail.data
        .map(({ price }) => integerConverter(price || 0))
        .reduce((acc, cur) => acc + cur, 0);
      
      const voucherPriceTotal = voucherTotalDetail.data.total || 0;

      const oopQuotations = totalQuotations.data.quotations
        .filter(quotation => quotation.type === 'oop');

      const oopPriceTotal = oopQuotations
        .map(({ price }) => integerConverter(price || 0))
        .reduce((acc, cur) => acc + cur, 0);

      const oopQuotedPriceTotal = oopQuotations
        .map(({ quotedPrice }) => integerConverter(quotedPrice || 0))
        .reduce((acc, cur) => acc + cur, 0);

      const prQuotations = totalQuotations.data.quotations
        .filter(quotation => quotation.type === 'pr');

      const prPriceTotal = prQuotations
        .map(({ price }) => integerConverter(price || 0))
        .reduce((acc, cur) => acc + cur, 0);

      const prQuotedPriceTotal = prQuotations
        .map(({ quotedPrice }) => integerConverter(quotedPrice || 0))
        .reduce((acc, cur) => acc + cur, 0);

      let financeStatus = 'success';

      const isFinanceFail = quotationPriceTotal - oopPriceTotal > agentDifference
        || voucherPriceTotal - oopPriceTotal > agentDifference;

      const isFinanceWarning = quotationPriceTotal > oopPriceTotal
        || voucherPriceTotal > oopPriceTotal;

      if (isFinanceWarning) { financeStatus = 'warning' }
      if (isFinanceFail) { financeStatus = 'fail' }

      setPrPriceTotal(prPriceTotal);
      setOopPriceTotal(oopPriceTotal);
      setPrQuotedPriceTotal(prQuotedPriceTotal);
      setOopQuotedPriceTotal(oopQuotedPriceTotal);
      setVoucherPriceTotal(voucherPriceTotal);
      setQuotationPriceTotal(quotationPriceTotal);
      setQuotations([...quotations]);
      setTotalCount(totalCount || 0);
      setProjectStatus(prevState => ({
        ...prevState,
        financeStatus,
        isQuoted: totalCount && totalCount > 0,
      }));
    } catch (err) { console.log(err) }
    
    setShowLoadingPopup(false);
  };

  const fetchExpenseParams = async () => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetExpenseParamsConfig();
      const { agentDifference } = data;
      setAgentDifference(agentDifference || 0);
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const fetchProjectFinanceData = async () => {
    console.log("projectFinance.js fetchProjectFinanceData");
    setShowLoadingPopup(true);
    
    try {
      // const projectData = await postToGetProjectDetail(projectID);
      // const projectFinanceFiles = await postToGetUploadFiles('projectFinance', projectID);
      // const projectQuotations = await postToGetProjectQuotations({
      //   projectID, amount: 1, page: 1,
      // });

      /*const unlocked = projectData.data.projectUnlock !== null
        && projectData.data.projectUnlock !== undefined
        && diffTime(projectData.data.projectUnlock, Date.now(), 'days') <= 1;*/
      // const unlocked = diffTime(projectData.data.actionTimeStart, Date.now(), 'days') <= 10;

      // const notQuoted = projectQuotations.data.totalCount === undefined
      //   && diffTime(projectData.data.actionTimeEnd, Date.now(), 'days') < 8;

      // const noPO = projectFinanceFiles.data.length === 0
      //   && diffTime(projectData.data.actionTimeEnd, Date.now(), 'days') < 4;
      
      // const isProjectLocked = !unlocked && (noPO || notQuoted);
  
      // if (isProjectLocked) {
      //   const alertText = `
      //     ${ noPO ? '無 PO 資料，' : '' }
      //     ${ notQuoted ? '無報價資料，' : '' }
      //     專案已被鎖定，請專案總監或總經理解鎖。
      //   `;
      //   setShowLoadingPopup(false);
      //   setAlertText(alertText);
      //   setShowAlertPopup(true);
      //   return
      // }

      setIsAdding(false);
      setIsEditing(false);
      setCurrentPage(1);

      await fetchExpenseParams();
      await fetchProjectStatus();
      await fetchProjectFinance();
      await fetchUploadFiles();
      await fetchProjectQuotations(1);
      await fetchProjectInvoices();
      await fetchProjectPRServices();

    } catch (err) {
      console.log(err);
      setShowLoadingPopup(false);
    }
  };

  const initOptions = useCallback(() => {
    dispatch(FETCH_MEMBER_OPTIONS());
    dispatch(FETCH_DIRECTOR_OPTIONS());
    dispatch(FETCH_VENDOR_ID_OPTIONS());
    dispatch(FETCH_TODO_TYPE_OPTIONS());
    dispatch(FETCH_PROJECT_ID_OPTIONS());
    dispatch(FETCH_VENDOR_TYPE_OPTIONS());
    dispatch(FETCH_EXPENSE_TYPE_OPTIONS());
    dispatch(FETCH_COMPANY_TYPE_OPTIONS());
  }, [dispatch]);

  useEffect(() => {
    initOptions();
  }, [initOptions]);

  return (
    <MainContent>
      <DivPanelContainer>
        <PanelWrapper title = "專案財務資料">
          <DivFlexRow>
            <DivFullWidthWrapper>
              <PSubtitle>專案代號</PSubtitle>
              <DivLongMultiSelector>
                <SelectSearchable
                  value = { projectID }
                  options = {[
                    { value: '', desc: '請選擇專案代號' },
                    ...projectIDOptions,
                  ]}
                  changeHandler = { setProjectID }
                />
              </DivLongMultiSelector>
            </DivFullWidthWrapper>
          </DivFlexRow>
          <DivFlexRow>
            <DivButtonsWrapper>
              <SearchButtonSet
                searchable = { projectID !== '' }
                searchHandler = { fetchProjectFinanceData }
                clearHandler = { clearHandler }
              />
            </DivButtonsWrapper>
        </DivFlexRow>
        </PanelWrapper>
      </DivPanelContainer>
      <DivPanelContainer>
        <TableProjectStatus
          title = "專案財務詳情"
          project = { projectStatus }
        />
      </DivPanelContainer>
      <DivPanelContainer>
        <PanelProjectQuotation
          disabled = { disableProjectQuotation }
          userEditable = { userEditable }
          userExportable = { userExportable }
          disableAttachment = { disableProjectQuotation }
          prPriceTotal = { prPriceTotal }
          oopPriceTotal = { oopPriceTotal }
          prQuotedPriceTotal = { prQuotedPriceTotal }
          oopQuotedPriceTotal = { oopQuotedPriceTotal }
          specialPrice = { projectFinance.specialPrice }
          quotationPriceTotal = { quotationPriceTotal }
          openAttachmentPopup = { openAttachmentPopupHandler }
          saveSpecialPriceHandler = { saveSpecialPriceHandler }
          specialPriceChangeHandler = { specialPriceChangeHandler }
          openExportPopup = { () => {
            setShowExportPopup(true);
          }}
        />
      </DivPanelContainer>
      {
        isAdding || isEditing
          ? <DivPanelContainer>
              <PanelQuotationDetail
                isAdding = { isAdding }
                userEditable = { userEditable }
                directorOptions = { directorOptions }
                quotationDetail = { quotationDetail }
                cancelHandler = { quotationDetailCancelHandler }
                saveQuotationHandler = { saveQuotationHandler }
                updateQuotationTypeHandler = { updateQuotationTypeHandler }
                updateQuotationDetailHandler = { updateQuotationDetailHandler }
              />
            </DivPanelContainer>
          : <DivPanelContainer>
              <TableProjectQuotations
                quotations = { quotations }
                totalCount = { totalCount }
                currentPage = { currentPage }
                userEditable = { userEditable }
                disabled = { disableProjectQuotation }
                addHandler = { addNewQuotationHandler }
                deleteHandler = { deleteHandler }
                goDetailHandler = { showQuotationDetailHandler }
                pageChangedHandler = { setCurrentPageHandler }
              />
            </DivPanelContainer>
      }
      <DivPanelContainer>
        <PanelProjectAccounting
          isEditing = { isEditingAccounting }
          uneditable = { !projectFinanceInputClearedEditable }
          prServiceTotal = { prServiceTotal }
          projectFinance = { projectFinance }
          cancelHandler = { cancelProjectFinanceHandler }
          voucherPriceTotal = { voucherPriceTotal }
          editHandler = { () =>  { setIsEditingAccounting(true) }}
          saveHandler = { saveProjectFinanceHandler }
          exportVouchersHandler = { exportVouchersHandler }
          downloadVoucherHandler = { downloadVoucherHandler }
          projectDetail = { projectDetail }
          updateProjectDetailHandler = { updateProjectDetailHandler }
          untaxedExpenseTotal = { untaxedExpenseTotal }
          taxedExpenseTotal = { taxedExpenseTotal }
          untaxedIncomeTotal = { untaxedIncomeTotal }
          taxedIncomeTotal = { taxedIncomeTotal }
          serviceFeeTotal = { serviceFeeTotal }
          canEditProjectOtherIncome = { false }
          projectFinanceInputClearedEditable = { projectFinanceInputClearedEditable }
        />
      </DivPanelContainer>
      <DivPanelContainer>
        <PanelQuotationInvoices
          isEditing = { false }
          uneditable = { true }
          poOptions = { poOptions }
          projectInvoice = { projectInvoice }
          projectDetail = { projectDetail }
        />
      </DivPanelContainer>
      {/* <DivPanelContainer>
        <PanelPRserviceInvoices
          uneditable = { true }
          isEditing = { false }
          invoiceOptions = { invoiceOptions }
          directorOptions = { directorOptions }
          projectPRServices = { projectPRServices }
        />
      </DivPanelContainer> */}
      { showLoadingPopup && <PopupLoading/> }
      {
        showSaveSuccessPopup &&
          <PopupExecuteResult
            title = "儲存成功"
            confirmHandler = { saveQuotationSuccessHandler }
          />
      }
      {
        showAttachmentPopup && 
        <PopupPurchaseAttachments
          fileChangable = { true }
          poFileName  = { poFileName }
          fileList = { fileList }
          uploadFiles = { uploadFiles }
          updateUploadFiles = { updateUploadFiles }
          fileUploadHandler = { fileUploadHandler }
          deleteFileHandler = { deleteFileHandler }
          updatePOFileNameHandler = { setPoFileName }
          cancelHandler = { () => {
            setPoFileName('');
            setShowAttachmentPopup(false);
          }}
        />
      }
      {
        showExportPopup &&
          <PopupExportMethodSelect
            title = "檔案匯出"
            value = { exportMethod }
            options = { exportMethodOptions }
            changeHandler = { setExportMethod }
            cancelHandler = { () => { setShowExportPopup(false) } }
            confirmHandler = { exportQuotationHandler }
          />
      }
      {
        showDeletePopup &&
          <PopupExecuteConfirm
            title = "刪除報價單"
            text = { `確定刪除此報價單` }
            confirmHandler = { deleteQuotationHandler }
            cancelHandler = { () => {
              setShowDeletePopup(false);
            }}
          />
      }
      {
        showDeleteFilePopup &&
          <PopupExecuteConfirm
            title = "刪除檔案"
            text = "確定刪除此上傳檔案"
            confirmHandler = { deleteFile }
            cancelHandler = { () => {
              setShowDeleteFilePopup(false);
            }}
          />
      }
      {
        showDeleteFileSuccessPopup &&
          <PopupExecuteResult
            title = "刪除檔案成功"
            confirmHandler = { () => {
              setShowDeleteFileSuccessPopup(false);
              setShowAttachmentPopup(true);
            }}
          />
      }
      {
        showDeleteSuccessPopup &&
          <PopupExecuteResult
            title = "刪除成功"
            confirmHandler = { () => {
              setShowDeleteSuccessPopup(false);
              fetchProjectQuotations();
            }}
          />
      }
            {
        showUploadSuccessPopup &&
          <PopupExecuteResult
            title = "上傳成功"
            confirmHandler = { uploadSuccessConfirmHandler }
          />
      }
      {/* {
        showAlertPopup &&
          <PopupExecuteResult
            text = { alertText }
            confirmHandler = { () => { setShowAlertPopup(false) } }
          />
      } */}
      {
        showSaveFailPopup &&
          <PopupExecuteResult
            title = { apiFailPopupTitle }
            text = { apiFailPopupMessage }
            confirmHandler = { apiFailConfirmHandler }
          />
      }
    </MainContent>
  );
};

export default ProjectFinance;
