import React from 'react';
import styled from 'styled-components';

import { DivPopupWrapper, DivPopup } from '../../../../component-style/Popup';

const DivLoaingWrapper = styled.div`
  margin: 0 auto;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
`;

const DivCube1 = styled.div`
  margin-right: 1px;
  background-color: #de2027;
  height: 100%;
  width: 6px;
  display: inline-block;
  
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;

  @-webkit-keyframes sk-stretchdelay {
    0%, 40%, 100% { -webkit-transform: scaleY(0.4) }  
    20% { -webkit-transform: scaleY(1.0) }
  }

  @keyframes sk-stretchdelay {
    0%, 40%, 100% { 
      transform: scaleY(0.4);
      -webkit-transform: scaleY(0.4);
    }  20% { 
      transform: scaleY(1.0);
      -webkit-transform: scaleY(1.0);
    }
  }
`;

const DivCube2 = styled(DivCube1)`
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
`;

const DivCube3 = styled(DivCube1)`
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
`;

const DivCube4 = styled(DivCube1)`
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
`;

const DivCube5 = styled(DivCube1)`
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
`;

const DivCube6 = styled(DivCube1)`
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
`;

const PopupLoading = props => {

  return (
    <DivPopupWrapper>
      <DivPopup>
        <DivLoaingWrapper>
          <DivCube1/>
          <DivCube2/>
          <DivCube3/>
          <DivCube4/>
          <DivCube5/>
          <DivCube6/>
        </DivLoaingWrapper>
      </DivPopup>
    </DivPopupWrapper>
  );
};

export default PopupLoading;
