import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { TdItem, TableRow } from '@/component-style/Table';
import { getOptionDesc } from '@/utils/textUtils';
import { formatTime } from '@/utils/timeUtils';

const TrWrapper = styled(TableRow)`
  border-bottom: solid 2px #f2f2f2;
  box-sizing: border-box;
  min-width: 1200px;

  &:last-child {
    border-bottom: none;
  }
`;

const Td120 = styled(TdItem)`
  width: 120px;
`;

const Td160 = styled(TdItem)`
  width: 160px;
`;

const Td240 = styled(TdItem)`
  width: 240px;
`;

const Item = props => {
  const { expense } = props;

  const memberOptions = useSelector(state => state.srpOptions.memberOptions);

  const username = getOptionDesc(memberOptions, expense.user);

  return (
    <TrWrapper>
      <Td160>{ expense.projectCode }</Td160>
      <Td240>{ expense.projectName } </Td240>
      <Td120>{ expense.expenseCode }</Td120>
      <Td160>{ username }</Td160>
      <Td120>{ expense.price }</Td120>
      <Td120>{ expense.price }</Td120>
      <Td160>
        { formatTime(expense.predictDate, 'YYYY/MM/DD HH:mm:ss') }
      </Td160>
      <Td120>
        { '否' }
        {/* { expense.isPrinted ? '是' : '否' } */}
      </Td120>
    </TrWrapper>
  );
};

export default Item;
