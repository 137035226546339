import React from 'react';
import styled from 'styled-components';

import NavigationItem from '../NavigationItem';
import NavigationCategory from '../NavigationCategory';
import IconHours from '@/assets/images/icon_hours.svg';

const UlWrapper = styled.ul`
  margin: 0;
  padding: 0;
`;

const DivIcon = styled.span`
  display: inline-block;
  margin-left: 50px;
  width: 24px;
  height: 24px;
  background-image: url(${props => props.url});
`;

const attendanceRoutes = [
  {
    title: '打卡',
    key: 'PunchTimeCreation',
    url: '/Attendance/PunchTimeCreation',
  },
  {
    title: '補打卡',
    key: 'PunchTimeBackup',
    url: '/Attendance/PunchTimeBackup',
  },
  {
    title: '打卡記錄',
    key: 'PunchTimeRecords',
    url: '/Attendance/PunchTimeRecords',
  },
  {
    title: '打卡管理',
    key: 'PunchTimeManagement',
    url: '/Attendance/PunchTimeManagement',
  },
  {
    title: '工時填寫',
    key: 'WorkingHoursCreation',
    url: '/Attendance/WorkingHoursCreation',
  },
  {
    title: '工時總表',
    key: 'WorkingHoursList',
    url: '/Attendance/WorkingHoursList',
  },
  {
    title: '加班申請',
    key: 'OvertimeApplication',
    url: '/Attendance/OvertimeApplication',
  },
  {
    title: '加班總表',
    key: 'OvertimesList',
    url: '/Attendance/OvertimesList',
  },
  {
    title: '加班審核',
    key: 'OvertimeApprovement',
    url: '/Attendance/OvertimeApprovement',
  },
  {
    title: '加班統計',
    key: 'OvertimeByPersonnel',
    url: '/Attendance/OvertimeByPersonnel',
  },
  {
    title: '休假申請',
    key: 'LeaveApplication',
    url: '/Attendance/LeaveApplication',
  },
  {
    title: '休假審核',
    key: 'LeaveApprovement',
    url: '/Attendance/LeaveApprovement',
  },
  {
    title: '休假總表',
    key: 'LeaveList',
    url: '/Attendance/LeaveList',
  },
  // {
  //   title: '個人薪資',
  //   key: 'PersonalSalary',
  //   url: '/Attendance/PersonalSalary',
  // },
];

const NavigationListAttendance = props => {
  const { validRoutes } = props;

  const routeItems = attendanceRoutes
    .filter(({ key }) => validRoutes.indexOf(key) !== -1)
    .map(({ key, title, url }) => (
      <NavigationItem
        key = { key }
        url = { url }
        title = { title }
      />
    ));

  return (
    <UlWrapper>
      <NavigationCategory
        title = "同仁差勤管理"
        opened = { props.opened }
        clickHandler = { props.toogleList }
      >
        <DivIcon url = { IconHours }/>
      </NavigationCategory>
      { props.opened && routeItems }
    </UlWrapper>
  );
};

export default NavigationListAttendance;
