import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import SelectEvaluation from '@/components/UI/Select/SelectEvaluation';

import { TdItem, TableRow } from '@/component-style/Table';
import { ButtonBasic } from '@/component-style/Button';

import { evaluationOptions } from '@/constant/options';
import { getOptionDesc } from '@/utils/textUtils';

const TrWrapper = styled(TableRow)`
  border-bottom: solid 2px #f2f2f2;
  box-sizing: border-box;
  min-width: 1120px;

  &:last-child {
    border-bottom: none;
  }
`;

const Td80 = styled(TdItem)`
  width: 80px;
`;

const Td120 = styled(TdItem)`
  width: 120px;
`;

const Td200 = styled(TdItem)`
  width: 200px;
`;

const ButtonSave = styled(ButtonBasic)`
  width: 60px;
`;

const Item = props => {
  const vendorIDOptions = useSelector(state => state.srpOptions.vendorIDOptions);
  const { evaluation } = props;

  const vendorName = getOptionDesc(vendorIDOptions, evaluation.vendorID);

  const total =  evaluation.price
    + evaluation.attitude
    + evaluation.satisfaction
    + evaluation.compatibility
    + evaluation.timeManagement;

  const comment = () => {
    if (total <= 15) return '考慮停用';
    if (total <= 25) return '有待改善';
    if (total <= 40) return '尚可';
    return '優等';
  };

  return (
    <TrWrapper>
      {
        props.displayMode
          ? <React.Fragment>
              <Td120>{ evaluation.projectCode }</Td120>
              <Td200>{ evaluation.projectName }</Td200>
            </React.Fragment>
          : <Td200>{ vendorName }</Td200>
      }
      <Td120>
        <SelectEvaluation
          disabled = { props.displayMode || props.disabled }
          value = { evaluation.satisfaction }
          options = { evaluationOptions }
          changeHandler = { value => {
            props.evaluationChangeHandler({
              ...evaluation,
              satisfaction: value,
            })
          }}
        />
      </Td120>
      <Td120>
        <SelectEvaluation
          disabled = { props.displayMode || props.disabled }
          value = { evaluation.timeManagement }
          options = { evaluationOptions }
          changeHandler = { value => {
            props.evaluationChangeHandler({
              ...evaluation,
              timeManagement: value,
            })
          }}
        />
      </Td120>
      <Td120>
        <SelectEvaluation
          disabled = { props.displayMode || props.disabled }
          value = { evaluation.price }
          options = { evaluationOptions }
          changeHandler = { value => {
            props.evaluationChangeHandler({
              ...evaluation,
              price: value,
            })
          }}
        />
      </Td120>
      <Td120>
        <SelectEvaluation
          disabled = { props.displayMode || props.disabled }
          value = { evaluation.compatibility }
          options = { evaluationOptions }
          changeHandler = { value => {
            props.evaluationChangeHandler({
              ...evaluation,
              compatibility: value,
            })
          }}
        />
      </Td120>
      <Td120>
        <SelectEvaluation
          disabled = { props.displayMode || props.disabled }
          value = { evaluation.attitude }
          options = { evaluationOptions }
          changeHandler = { value => {
            props.evaluationChangeHandler({
              ...evaluation,
              attitude: value,
            })
          }}
        />
      </Td120>
      <Td80>{ total }</Td80>
      <Td120>{ comment() }</Td120>
      {
        props.displayMode
          ? null
          : <Td120>
              <ButtonSave
                disabled = { props.disabled }
                onClick = { props.saveHandler }
              >儲存
              </ButtonSave>
            </Td120>
      }
    </TrWrapper>
  );
};

export default Item;
