import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { postToGetVenues } from '@/api/venueService';

import {
  FETCH_VENUE_EQUIPMENTS,
  FETCH_VENUE_TYPE_OPTIONS,
  FETCH_VENUE_CHARACTERISTICS,
} from '@/store/actions/srpOptionsAction';

import MainContent from '@/component-style/MainContent';
import PanelVenueSearch from '@/components/Panel/PanelVenueSearch';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import TableVenueList from '@/components/Table/TableVenueList';
import SearchButtonSet from '@/components/UI/SearchButtonSet';
import { ButtonBasic } from '@/component-style/Button';
import { DivFlexRow } from '@/component-style/RWDWrapper';

import { searchConfigGenerator } from '@/utils/apiRequestUtils';
import { integerConverter, floatConverter } from '@/utils/textUtils';

const DivPanelContainer = styled.div`
  margin-bottom: 40px;
`;

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding: 0;
  justify-content: space-between;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const ButtonAdd = styled(ButtonBasic)`
  margin-right: 10px;
  width: 120px;

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`;

const VendorManagement = props => {
  const history = useHistory();
  const dispatch = useDispatch();
  const venueEquipments = useSelector(state => state.srpOptions.venueEquipments);
  const venueTypeOptions = useSelector(state => state.srpOptions.venueTypeOptions);
  const informationRoutes = useSelector(state => state.login.informationRoutes);
  const venueCharacteristics = useSelector(state => state.srpOptions.venueCharacteristics);

  const [venues, setVeNues] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [searchConfig, setSearchConfig] = useState({
    type: '',
    city: '',
    town: '',
    minCapacity: '',
    maxCapacity: '',
    characteristics: [],
    equipments: [],
    minSize: '',
    maxSize: '',
    amount: 10,
  });

  const [showLoadingPopup, setShowLoadingPopup] = useState(false);

  const userAddable = informationRoutes.indexOf('VenueCreation') !== -1;

  const updateConfigHandler = (key, value) => {
    const updateObj = { ...searchConfig };
    updateObj[key] = value;
    setSearchConfig({ ...updateObj });
  };

  const cityChangeHandler = value => {
    setSearchConfig(prevState => ({
      ...prevState,
      city: value,
      town: '',
    }));
  };

  const clearHandler = () => {
    setSearchConfig({
      type: '',
      city: '',
      town: '',
      minCapacity: '',
      maxCapacity: '',
      characteristics: [],
      equipments: [],
      minSize: '',
      maxSize: '',
      amount: 10,
    });
  };

  const searchHandler = async () => {
    setCurrentPageHandler(1);
  };

  const setCurrentPageHandler = page => {
    setCurrentPage(page);
    updateVenues(page);
  };

  const updateVenues = async (page = currentPage) => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetVenues(
        searchConfigGenerator({
          ...searchConfig,
          minCapacity: integerConverter(searchConfig.minCapacity),
          maxCapacity: integerConverter(searchConfig.maxCapacity),
          minSize: floatConverter(searchConfig.minSize),
          maxSize: floatConverter(searchConfig.maxSize),
          page,
        })
      );

      const { venues, totalCount } = data;
      const venueList = [ ...venues ]
        .sort((a, b) => a.venueName > b.venueName ? 1 : -1);

      setVeNues([ ...venueList ]);
      setTotalCount(totalCount);
    } catch(err) { console.log(err) }

    setShowLoadingPopup(false);
  }; 

  const initVenues = useCallback(
    updateVenues, []);

  const initOptions = useCallback(() => {
    dispatch(FETCH_VENUE_EQUIPMENTS());
    dispatch(FETCH_VENUE_TYPE_OPTIONS());
    dispatch(FETCH_VENUE_CHARACTERISTICS());
  }, [dispatch]);

  useEffect(() => {
    initOptions();
    initVenues();
  }, [initOptions, initVenues]);

  return (
    <MainContent>
      <DivPanelContainer>
        <PanelVenueSearch
          title = "活動場地搜尋"
          config = { searchConfig }
          venueEquipments = { venueEquipments }
          venueTypeOptions = { venueTypeOptions }
          venueCharacteristics = { venueCharacteristics }
          cityChangeHandler = { cityChangeHandler }
          updateConfigHandler = { updateConfigHandler }
          clearHandler = { clearHandler }
          searchHandler = { searchHandler }
        >
          <DivFlexRow>
            <DivButtonsWrapper>
              <ButtonAdd
                disabled = { !userAddable }
                onClick = { () => {
                  history.push('/Information/VenueCreation');
                }}
              >新增</ButtonAdd>
              <SearchButtonSet
                clearHandler = { props.clearHandler }
                searchHandler = { props.searchHandler }
              />
            </DivButtonsWrapper>
          </DivFlexRow>
        </PanelVenueSearch>
      </DivPanelContainer>
      <TableVenueList
        venues = { venues }
        totalCount = { totalCount }
        currentPage = { currentPage }
        pageChangedHandler = { value => {
          setCurrentPageHandler(value);
        }}
      />
      { showLoadingPopup && <PopupLoading/> }
    </MainContent>
  );
};

export default VendorManagement;
