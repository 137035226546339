import React from 'react';
import { useSelector } from 'react-redux';

import { Table, TableBody } from '@/component-style/Table';
import TableWrapper  from '@/components/UI/TableWrapper';
import PageSelector from '@/components/UI/PageSelector';
import Header from './Header';
import Item from './Item';

const TableSchedules = props => {
  const projectRoutes = useSelector(state => state.login.projectRoutes);
  const disableDetailPage = projectRoutes.indexOf('ScheduleDetail') === -1;

  return (
    <TableWrapper title = "進度列表">
      <Table>
        <Header
          selectAll = { props.selectAll }
          showCheckbox = { props.showCheckbox }
          showDetailButton = { props.showDetailButton }
          selectAllHandler = { props.selectAllHandler }
        />
        <TableBody>
          {
            props.schedules.map((schedule, index) =>
              <Item
                key = { schedule.todoID }
                schedule = { schedule }
                showCheckbox = { props.showCheckbox }
                showDetailButton = { props.showDetailButton }
                disableDetailPage = { disableDetailPage }
                checkHandler = { value => { props.checkHandler(index, value) } }
                goDetailHandler = { () => {
                  props.goScheduleDetailHandler(
                    schedule.projectID,
                    schedule.todoID,
                  )
                }}
              />
            )
          }
        </TableBody>
      </Table>
      <PageSelector
        current = { props.currentPage }
        total = { props.totalCount }
        pageChangedHandler = { props.pageChangedHandler }
      />
    </TableWrapper>
  );
};

export default TableSchedules;
