import React from 'react';
import styled from 'styled-components';

import Logo from '../UI/Logo';
import ResetPasswordForm from '../ResetPasswordPanel/ResetPasswordForm';

const DivResetPasswordPanel = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  min-height: 600px;
  background-color: #f8f8f8;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DivLogoWrapper = styled.div`
  position: absolute;
  top: 30px;
  left: 30px;
  width: 80px;
`;

const ResetPasswordPanel = props => {

  return (
    <DivResetPasswordPanel>
      <DivLogoWrapper>
        <Logo />
      </DivLogoWrapper>
      <ResetPasswordForm
        errorMsg = { props.errorMsg }
        showError = { props.showError }
        account = { props.account }
        setAccount = { props.setAccount }
        resetPW = { props.resetPW }
      />
    </DivResetPasswordPanel>
  );
};

export default ResetPasswordPanel;
