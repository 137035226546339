import { all } from 'redux-saga/effects';

import loginSaga from './loginSaga';
import srpOptionsSaga from './srpOptionsSaga';


function* rootSaga() {
  yield all([
    loginSaga(),
    srpOptionsSaga(),
  ]);
};

export default rootSaga;
