import React, { useState } from 'react';
import styled from 'styled-components';
import { cloneDeep } from 'lodash';

import ItemVoucherInfo from './ItemVoucherInfo';
import ItemfinanceApproval from './ItemFinanceApproval';
import { ButtonBasic } from '@/component-style/Button';
import { DivFlexRow } from '@/component-style/RWDWrapper';

import { currencyConverter } from '@/utils/textUtils';
import { expenseVoucherConfig } from '@/constant/dataConfig/expenseModule';

import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';

const DivWrapper = styled.div`
  width: 100%;
  
  @media (min-width: 768px) {
    width: calc(100% - 135px);
  }
`;

const DivVoucherWrapper = styled.div`
  margin-bottom: 15px;
`;

const DivButtonsWrapper = styled(DivFlexRow)`
  padding: 0;
  justify-content: space-between;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const ButtonAdd = styled(ButtonBasic)`
  margin-right: 10px;
  margin-bottom: 15px;
  width: 120px;
`;

const DivAmountWrapper = styled.div``;

const DivAmountItem = styled.div`
  width: 200px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  &:last-child { margin-bottom: 0 }

  @media (max-width: 767px) { width: 260px }
`;

const PAmount = styled.p`
  font-size: 14px;
  color: #767676;
`;

const ExpenseVouchers = props => {
  const [showAlertPopup, setShowAlertPopup] = useState(false);
  const { vouchers, object } = props;

  const addVoucherHandler = () => {
    // console.log("addVoucherHandler vouchers.length:" + vouchers.length);
    if (vouchers.length >= 10) {
      setShowAlertPopup(true);
    } else {
      props.updateVouchersHandler([
        ...vouchers,
        cloneDeep(expenseVoucherConfig),
      ]);
      props.addFileListHandler();
    }
  };

  const removeHandler = index => {
    const filteredList = vouchers
      .filter((item, i) => i !== index);

    props.updateVouchersHandler(filteredList);
    props.removeFileListHandler(index);
  };

  const voucherChangeHandler = (index, value) => {
    const updateVouchers = cloneDeep(vouchers);
    updateVouchers[index] = value;
    props.updateVouchersHandler([...updateVouchers]);
  };

  const accountingChangeHandler = (index, value) => {
    const updateVouchers = cloneDeep(vouchers);
    updateVouchers[index].accounting = value;
    props.updateVouchersHandler([...updateVouchers]);
  };

  const itemRemovable = (object, quotationID) => true;
  // const itemRemovable = (object, quotationID) => object === 'user'
  //   ? true
  //   : quotationID === undefined;

  return (
    <DivWrapper>
      {
        vouchers.map((voucher, index) =>
          <DivVoucherWrapper key = { index }>
            <ItemVoucherInfo
              voucher = { voucher }
              disabled = { props.disabled }
              disableAmount = { props.disableAmount }
              removable = { props.addable && itemRemovable(object, voucher.quotationID) }
              hideAttachmentButton = { props.hideAttachmentButton }
              removeHandler = { () => { removeHandler(index) }}
              voucherChangeHandler = { value => { voucherChangeHandler(index, value) }}
              fileManageHandler = { () => {
                props.openFilesPopupHandler('quotationFiles', index);
              }}
              voucherManageHandler = { () => {
                props.openFilesPopupHandler('quotationVoucher', index);
              }}
              isFileUpload = { props.mapQuotationIDHasFile?.has(voucher.quotationID) ? props.mapQuotationIDHasFile.get(voucher.quotationID).isFileUpload : false }
              isVoucherUpload = { props.mapQuotationIDHasFile?.has(voucher.quotationID) ? props.mapQuotationIDHasFile.get(voucher.quotationID).isVoucherUpload : false }
              // isFileUpload = { props.mapQuotationIDHasFile[voucher.quotationID] }
              // isVoucherUpload = { props.mapQuotationIDHasFile?.has(voucher.quotationID) ? props.mapQuotationIDHasFile[voucher.quotationID].isVoucherUpload : false }
              projectName = { props.projectName }
            />
            {
              props.showFinanceItem &&
              <ItemfinanceApproval
                disabled = { props.disabled }
                accounting = { voucher.accounting }
                declareTypeOptions = { props.declareTypeOptions }
                accountingChangeHandler = { value => {
                  accountingChangeHandler(index, value)
                }}
              />
            }
          </DivVoucherWrapper>
        )
      }
      <DivButtonsWrapper>
        <div>
          <ButtonAdd
            disabled = { props.disabled || props.addable !== true }
            onClick = { addVoucherHandler }
          >新增單據</ButtonAdd>
        </div>
        {
          vouchers.length !== 0 &&
            <DivAmountWrapper>
              <DivAmountItem>
                <PAmount>未稅金額</PAmount>
                <PAmount>
                  ＄{currencyConverter(props.unTaxedAmountTotal)}
                </PAmount>
              </DivAmountItem>
              <DivAmountItem>
                <PAmount>稅額</PAmount>
                <PAmount>
                  ＄{currencyConverter(props.taxTotal)}
                </PAmount>
              </DivAmountItem>
              <DivAmountItem>
                <PAmount>請款總額</PAmount>
                <PAmount>
                  ＄{currencyConverter(props.amountTotal)}
                </PAmount>
              </DivAmountItem>
            </DivAmountWrapper>
        }
      </DivButtonsWrapper>
      {
        showAlertPopup &&
          <PopupExecuteResult
            title = "新增失敗"
            text = { "不可超過10個單據" }
            confirmHandler = { () => { setShowAlertPopup(false) } }
          />
      }
    </DivWrapper>
  );
};

export default ExpenseVouchers;
