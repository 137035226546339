import React from 'react';
import styled from 'styled-components';
import { cloneDeep } from 'lodash';

import TableWrapper from '@/components/UI/TableWrapper';
import QuotationInvoice from '@/components/UI/QuotationInvoice';
import { PSubtitle } from '@/component-style/TextElement';
import { InputBasic } from '@/component-style/Input';
import { ButtonBasic } from '@/component-style/Button';
import Checkbox from '@/components/UI/Checkbox';
import {
  DivFlexRow,
  DivItemWrapper,
} from '@/component-style/RWDWrapper';

import { projectInvoiceItem } from '@/constant/dataConfig/financeModule';
import { integerConverter, currencyConverter } from '@/utils/textUtils';

import MyInputNumber from '@/components/UI/MyInputNumber';

const DivWrapper = styled.div`
  width: 100%;
  
  @media (min-width: 768px) {
    width: calc(100% - 135px);
  }
`;

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding: 0;
  padding-bottom: 15px;
  justify-content: flex-end;

  @media (max-width: 425px) {
    justify-content: center;
  }
`;

const DivCheckboxWrapper = styled.div`
  width: 300px;
  height: 40px;
  line-height: 40px;

  @media (max-width: 767px) {
    width: auto;
    height: 20px;
    line-height: 20px;
    margin-bottom: 5px;
  }
`;

const ButtonSave = styled(ButtonBasic)`
  width: 120px;

  &:first-child { margin-right: 10px }
`;

const PanelQuotationInvoices = props => {
  const { projectInvoice } = props;
  
  const totalPrice = projectInvoice.invoices
    .map(({ amount }) => integerConverter(amount))
    .reduce((acc, cur) => acc + cur, 0);

  const addInvoiceHandler = () => {
    props.updateProjectInvoiceHandler('invoices', [
      ...projectInvoice.invoices,
      { ...projectInvoiceItem, isNewlyAdded : true, }
    ]);
  };

  const removeHandler = index => {
    const filteredList = projectInvoice.invoices
      .filter((item, i) => i !== index);

    props.updateProjectInvoiceHandler('invoices', filteredList);
  };

  const invoiceChangeHandler = (index, value) => {
    const updateInvoices = cloneDeep(projectInvoice.invoices);
    updateInvoices[index] = value;
    props.updateProjectInvoiceHandler('invoices', [...updateInvoices]);
  };

  return (
    <TableWrapper title = "發票資訊">
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>金額總計</PSubtitle>
          <MyInputNumber
            disabled = { true }
            value = { totalPrice }
          />
          {/* <InputBasic
            disabled = { true }
            value = { currencyConverter(totalPrice) }
          /> */}
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>全數銷項已入帳</PSubtitle>
          <DivCheckboxWrapper>
            <Checkbox
              disabled = { !props.isEditing }
              checked = { props.projectDetail.isReceived }
              changeHandler = { value => {
                props.updateProjectDetailHandler('isReceived', value);
              }}
            />
          </DivCheckboxWrapper>
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>發票資訊</PSubtitle>
          <DivWrapper>
            {
              projectInvoice.invoices.map((invoice, index) => 
                <QuotationInvoice
                  key = { index }
                  invoice = { invoice }
                  disabled = { !props.isEditing }
                  poOptions = { props.poOptions }
                  removeHandler = { () => { removeHandler(index) }}
                  invoiceChangeHandler = { value => {
                    invoiceChangeHandler(index, value);
                  }}
                />
              )
            }
            <ButtonSave
              disabled = { !props.isEditing }
              onClick = { addInvoiceHandler }
            >新增發票</ButtonSave>
          </DivWrapper>
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivButtonsWrapper>
          {
            props.isEditing
              ? <div>
                  <ButtonSave
                    onClick = { props.saveHandler }
                  >儲存</ButtonSave>
                  <ButtonSave
                     onClick = { props.cancelHandler }
                  >關閉</ButtonSave>
                </div>
              : <ButtonSave
                  onClick = { props.editHandler }
                  disabled = { projectInvoice.projectID === '' || props.uneditable === true }
                >編輯</ButtonSave>
          }
        </DivButtonsWrapper>
      </DivFlexRow>
    </TableWrapper>
  )
};

export default PanelQuotationInvoices;
