import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { TimePicker } from 'antd';
import moment from 'moment';
import {
  formatTime,
} from '@/utils/timeUtils';

import {
  putModifyScheduleTimeConfig,
  postToGetScheduleTimeConfig,
} from '@/api/attendanceService';

import {
  getWorkday,
  putWorkday,
} from '@/api/sysConfigService';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import MainContent from '@/component-style/MainContent';
import TableWrapper from '@/components/UI/TableWrapper';
import Checkbox from '@/components/UI/Checkbox';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import { InputBasic } from '@/component-style/Input';
import { PSubtitle } from '@/component-style/TextElement';
import { ButtonBasic } from '@/component-style/Button';
import {
  DivFlexRow,
  DivItemWrapper,
} from '@/component-style/RWDWrapper';

import {
  floatConverter,
  integerConverter,
} from '@/utils/textUtils';
import { workingHoursParams, workdayHoursParams } from '@/constant/dataConfig/sysConfigModule';

const PTitile = styled(PSubtitle)`
  font-size: 16px;
  color: #57585a;
`;

const PWideTitle = styled(PSubtitle)`
  width: 150px;
`;

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding-right: 0;
  padding-bottom: 30px;
  justify-content: flex-end;

  @media (max-width: 425px) {
    justify-content: center;
    padding-left: 0;
  }
`;

const ButtonSave = styled(ButtonBasic)`
  width: 120px;
`;

const DivCheckboxWrapper = styled.div`
  width: 300px;
  height: 40px;
  line-height: 40px;

  @media (max-width: 767px) {
    height: 20px;
    line-height: 20px;
    margin-bottom: 5px;
  }

  @media (min-width: 1920px) {
    width: 622px;
  };

  @media (max-width: 320px) {
    width: 260px;
  };
`;

const WorkingHoursParams = () => {
  const dispatch = useDispatch();
  const sysConfigRoutes = useSelector(state => state.login.sysConfigRoutes);
  const showSaveFailPopup = useSelector(state => state.apiFail.showSaveFailPopup);
  const apiFailPopupTitle = useSelector(state => state.apiFail.title);
  const apiFailPopupMessage = useSelector(state => state.apiFail.message);

  const [config, setConfig] = useState({ ...workingHoursParams });

  const [workdayHoursConfig, setWorkdayHoursConfig] = useState({ ...workdayHoursParams });

  const [isEditing, setIsEditing] = useState(false);
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [showSaveSuccessPopup, setShowSaveSuccessPopup] = useState(false);

  const userEditable = sysConfigRoutes.indexOf('WorkingHoursParams-Edit') !== -1;

  const updateWorkdayHoursHandler = (key, value) => {
    const updateObj = { ...workdayHoursConfig };
    updateObj[key] = value;
    console.log(`updateExtraPaymentsParamsHandler() JSON.stringify(updateObj)=${JSON.stringify(updateObj)}`);
    setWorkdayHoursConfig({ ...updateObj });
  };

  const updateConfigHandler = (key, value) => {
    const updateObj = { ...config };
    updateObj[key] = value;
  setConfig({ ...updateObj });
  };

  const saveHandler = async () => {
    setShowLoadingPopup(true);

    try {
      await putModifyScheduleTimeConfig({
        ...config,
        todoDays: integerConverter(config.todoDays),
        overTimeRemind: integerConverter(config.overTimeRemind),
        overTimeLimit: integerConverter(config.overTimeLimit),
        freeTimeLimit: integerConverter(config.freeTimeLimit),
        extraPayLimit: integerConverter(config.extraPayLimit),
        normalDayExtraRate: {
          firstTwoHour: floatConverter(config.normalDayExtraRate.firstTwoHour),
          overTwoHour: floatConverter(config.normalDayExtraRate.overTwoHour),
        },
        restDayExtraRate: {
          firstTwoHour: floatConverter(config.restDayExtraRate.firstTwoHour),
          overTwoHour: floatConverter(config.restDayExtraRate.overTwoHour),
          overEightHour: floatConverter(config.restDayExtraRate.overEightHour),
        },
        holidayExtraRate: {
          underEightHour: floatConverter(config.holidayExtraRate.underEightHour),
          overEightHour: floatConverter(config.holidayExtraRate.overEightHour),
          overTenHour: floatConverter(config.holidayExtraRate.overTenHour),
          makeItADay: config.holidayExtraRate.makeItADay,
        },
        nationalHolidayExtraRate: {
          underEightHour: floatConverter(config.nationalHolidayExtraRate.underEightHour),
          overEightHour: floatConverter(config.nationalHolidayExtraRate.overEightHour),
          overTenHour: floatConverter(config.nationalHolidayExtraRate.overTenHour),
          makeItADay: config.nationalHolidayExtraRate.makeItADay,
        },
        overTimeTotalLimit: {
          month: integerConverter(config.overTimeTotalLimit.month),
          hours: integerConverter(config.overTimeTotalLimit.hours),
        },
      });
      await putWorkday(
        workdayHoursConfig
      );
      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '更新失敗', err }));
      console.log(err);
    }
  };

  const closeSuccessPopupHandler = () => {
    setIsEditing(false);
    setShowSaveSuccessPopup(false);
  };

  const fetchScheduleTimeConfig = async () => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetScheduleTimeConfig();
      const {
        normalDayExtraRate,
        restDayExtraRate,
        holidayExtraRate,
        nationalHolidayExtraRate,
        overTimeTotalLimit,
        ...remainItems
      } = data;

      setConfig({
        ...workingHoursParams,
        ...remainItems,
        normalDayExtraRate: {
          ...workingHoursParams.normalDayExtraRate,
          ...normalDayExtraRate,
        },
        restDayExtraRate: {
          ...workingHoursParams.restDayExtraRate,
          ...restDayExtraRate,
        },
        holidayExtraRate: {
          ...workingHoursParams.holidayExtraRate,
          ...holidayExtraRate,
        },
        nationalHolidayExtraRate: {
          ...workingHoursParams.nationalHolidayExtraRate,
          ...nationalHolidayExtraRate,
        },
        overTimeTotalLimit: {
          ...workingHoursParams.overTimeTotalLimit,
          ...overTimeTotalLimit,
        },
      });

    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const initConfigData = useCallback(
    fetchScheduleTimeConfig, [])

  useEffect(() => {
    initConfigData();
  }, [initConfigData])

  const fetchWorkdayConfig = async () => {
    setShowLoadingPopup(true);

    try {
      const { data } = await getWorkday();
      setWorkdayHoursConfig(data);

    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };
  
  const initWorkdayConfigData = useCallback(
    fetchWorkdayConfig, [])

  useEffect(() => {
    initWorkdayConfigData();
  }, [initWorkdayConfigData])

  return (
    <MainContent>
      <TableWrapper title = "工時參數管理">
        <DivFlexRow>
          <DivItemWrapper>
            <PWideTitle>補休啟用(Y/N)</PWideTitle>
            <DivCheckboxWrapper>
              <Checkbox
                disabled = { !isEditing }
                checked = { config.enableFreeTime }
                changeHandler = { value => {
                  updateConfigHandler('enableFreeTime', value);
                }}
              />
            </DivCheckboxWrapper>
          </DivItemWrapper>
          <DivItemWrapper>
            <PWideTitle>加班費啟用(Y/N)</PWideTitle>
            <DivCheckboxWrapper>
              <Checkbox
                disabled = { !isEditing }
                checked = { config.enableExtraPay }
                changeHandler = { value => {
                  updateConfigHandler('enableExtraPay', value);
                }}
              />
            </DivCheckboxWrapper>
          </DivItemWrapper>
        </DivFlexRow>
        <DivFlexRow>
          <DivItemWrapper>
            <PWideTitle>申請加班期限天數</PWideTitle>
            <InputBasic
              disabled = { !isEditing }
              value = { config.todoDays }
              onChange = { ({ target }) => {
                updateConfigHandler('todoDays', target.value);
              }}
            />
          </DivItemWrapper>
          <DivItemWrapper>
            <PWideTitle>加班提醒(時/月)</PWideTitle>
            <InputBasic
              disabled = { !isEditing }
              value = { config.overTimeRemind }
              onChange = { ({ target }) => {
                updateConfigHandler('overTimeRemind', target.value);
              }}
            />
          </DivItemWrapper>
        </DivFlexRow>
        <DivFlexRow>
          <DivItemWrapper>
            <PWideTitle>工作日加班上限(時/日)</PWideTitle>
            <InputBasic
              disabled = { !isEditing }
              value = { config.overTimeLimit }
              onChange = { ({ target }) => {
                updateConfigHandler('overTimeLimit', target.value);
              }}
            />
          </DivItemWrapper>
          <DivItemWrapper>
            <PWideTitle>每月加班上限(時/月)</PWideTitle>
            {/* <PSubtitle>加班換補休(分/月)</PSubtitle> */}
            <InputBasic
              disabled = { !isEditing }
              value = { config.freeTimeLimit }
              onChange = { ({ target }) => {
                updateConfigHandler('freeTimeLimit', target.value);
              }}
            />
          </DivItemWrapper>
        </DivFlexRow>
        {/* <DivFlexRow>
          <DivItemWrapper>
            <PSubtitle>加班換加班費(分/月)</PSubtitle>
            <InputBasic
              disabled = { !isEditing }
              value = { config.extraPayLimit }
              onChange = { ({ target }) => {
                updateConfigHandler('extraPayLimit', target.value);
              }}
            />
          </DivItemWrapper>
        </DivFlexRow> */}
        <DivFlexRow>
          <DivItemWrapper>
            <PWideTitle>工作日加班倍率</PWideTitle>
          </DivItemWrapper>
        </DivFlexRow>
        <DivFlexRow>
          <DivItemWrapper>
            <PWideTitle>2小時前倍率</PWideTitle>
            <InputBasic
              disabled = { !isEditing }
              value = { config.normalDayExtraRate.firstTwoHour }
              onChange = { ({ target }) => {
                updateConfigHandler('normalDayExtraRate', {
                  ...config.normalDayExtraRate,
                  firstTwoHour: target.value,
                });
              }}
            />
          </DivItemWrapper>
          <DivItemWrapper>
            <PWideTitle>2小時後倍率</PWideTitle>
            <InputBasic
              disabled = { !isEditing }
              value = { config.normalDayExtraRate.overTwoHour }
              onChange = { ({ target }) => {
                updateConfigHandler('normalDayExtraRate', {
                  ...config.normalDayExtraRate,
                  overTwoHour: target.value,
                });
              }}
            />
          </DivItemWrapper>
        </DivFlexRow>
        <DivFlexRow>
          <DivItemWrapper>
            <PTitile>休息日加班倍率</PTitile>
          </DivItemWrapper>
        </DivFlexRow>
        <DivFlexRow>
          <DivItemWrapper>
            <PWideTitle>2小時前倍率</PWideTitle>
            <InputBasic
              disabled = { !isEditing }
              value = { config.restDayExtraRate.firstTwoHour }
              onChange = { ({ target }) => {
                updateConfigHandler('restDayExtraRate', {
                  ...config.restDayExtraRate,
                  firstTwoHour: target.value,
                });
              }}
            />
          </DivItemWrapper>
          <DivItemWrapper>
            <PWideTitle>2小時後倍率</PWideTitle>
            <InputBasic
              disabled = { !isEditing }
              value = { config.restDayExtraRate.overTwoHour }
              onChange = { ({ target }) => {
                updateConfigHandler('restDayExtraRate', {
                  ...config.restDayExtraRate,
                  overTwoHour: target.value,
                });
              }}
            />
          </DivItemWrapper>
        </DivFlexRow>
        <DivFlexRow>
          <DivItemWrapper>
            <PWideTitle>9小時後倍率</PWideTitle>
            <InputBasic
              disabled = { !isEditing }
              value = { config.restDayExtraRate.overEightHour }
              onChange = { ({ target }) => {
                updateConfigHandler('restDayExtraRate', {
                  ...config.restDayExtraRate,
                  overEightHour: target.value,
                });
              }}
            />
          </DivItemWrapper>
        </DivFlexRow>
        <DivFlexRow>
          <DivItemWrapper>
            <PTitile>例假日加班倍率</PTitile>
          </DivItemWrapper>
        </DivFlexRow>
        <DivFlexRow>
          <DivItemWrapper>
            <PWideTitle>8小時前倍率</PWideTitle>
            <InputBasic
              disabled = { !isEditing }
              value = { config.holidayExtraRate.underEightHour }
              onChange = { ({ target }) => {
                updateConfigHandler('holidayExtraRate', {
                  ...config.holidayExtraRate,
                  underEightHour: target.value,
                });
              }}
            />
          </DivItemWrapper>
          <DivItemWrapper>
            <PWideTitle>8-9小時倍率</PWideTitle>
            <InputBasic
              disabled = { !isEditing }
              value = { config.holidayExtraRate.overEightHour }
              onChange = { ({ target }) => {
                updateConfigHandler('holidayExtraRate', {
                  ...config.holidayExtraRate,
                  overEightHour: target.value,
                });
              }}
            />
          </DivItemWrapper>
        </DivFlexRow>
        <DivFlexRow>
          <DivItemWrapper>
            <PWideTitle>10小時後倍率</PWideTitle>
            <InputBasic
              disabled = { !isEditing }
              value = { config.holidayExtraRate.overTenHour }
              onChange = { ({ target }) => {
                updateConfigHandler('holidayExtraRate', {
                  ...config.holidayExtraRate,
                  overTenHour: target.value,
                });
              }}
            />
          </DivItemWrapper>
          <DivItemWrapper>
            <PWideTitle>未滿8小時算8小時</PWideTitle>
              <DivCheckboxWrapper>
                <Checkbox
                  disabled = { !isEditing }
                  checked = { config.holidayExtraRate.makeItADay }
                  changeHandler = { value => {
                    updateConfigHandler('holidayExtraRate', {
                      ...config.holidayExtraRate,
                      makeItADay: value,
                    });
                  }}
                />
              </DivCheckboxWrapper>
          </DivItemWrapper>
        </DivFlexRow>
        <DivFlexRow>
          <DivItemWrapper>
            <PTitile>國定假日加班倍率</PTitile>
          </DivItemWrapper>
        </DivFlexRow>
        <DivFlexRow>
          <DivItemWrapper>
            <PWideTitle>8小時前倍率</PWideTitle>
            <InputBasic
              disabled = { !isEditing }
              value = { config.nationalHolidayExtraRate.underEightHour }
              onChange = { ({ target }) => {
                updateConfigHandler('nationalHolidayExtraRate', {
                  ...config.holidayExtraRate,
                  underEightHour: target.value,
                });
              }}
            />
          </DivItemWrapper>
          <DivItemWrapper>
            <PWideTitle>8-9小時倍率</PWideTitle>
            <InputBasic
              disabled = { !isEditing }
              value = { config.nationalHolidayExtraRate.overEightHour }
              onChange = { ({ target }) => {
                updateConfigHandler('nationalHolidayExtraRate', {
                  ...config.nationalHolidayExtraRate,
                  overEightHour: target.value,
                });
              }}
            />
          </DivItemWrapper>
        </DivFlexRow>
        <DivFlexRow>
          <DivItemWrapper>
            <PWideTitle>10小時後倍率</PWideTitle>
            <InputBasic
              disabled = { !isEditing }
              value = { config.nationalHolidayExtraRate.overTenHour }
              onChange = { ({ target }) => {
                updateConfigHandler('nationalHolidayExtraRate', {
                  ...config.nationalHolidayExtraRate,
                  overTenHour: target.value,
                });
              }}
            />
          </DivItemWrapper>
          <DivItemWrapper>
            <PWideTitle>未滿8小時算8小時</PWideTitle>
              <DivCheckboxWrapper>
                <Checkbox
                  disabled = { !isEditing }
                  checked = { config.nationalHolidayExtraRate.makeItADay }
                  changeHandler = { value => {
                    updateConfigHandler('nationalHolidayExtraRate', {
                      ...config.nationalHolidayExtraRate,
                      makeItADay: value,
                    });
                  }}
                />
              </DivCheckboxWrapper>
          </DivItemWrapper>
        </DivFlexRow>
        <DivFlexRow>
          <DivItemWrapper>
            <PTitile>累計加班上限</PTitile>
          </DivItemWrapper>
        </DivFlexRow>
        <DivFlexRow>
          <DivItemWrapper>
            <PWideTitle>累計月數</PWideTitle>
            <InputBasic
              disabled = { !isEditing }
              value = { config.overTimeTotalLimit.month }
              onChange = { ({ target }) => {
                updateConfigHandler('overTimeTotalLimit', {
                  ...config.overTimeTotalLimit,
                  month: target.value,
                });
              }}
            />
          </DivItemWrapper>
          <DivItemWrapper>
            <PWideTitle>累計時數上限</PWideTitle>
            <InputBasic
              disabled = { !isEditing }
              value = { config.overTimeTotalLimit.hours }
              onChange = { ({ target }) => {
                updateConfigHandler('overTimeTotalLimit', {
                  ...config.overTimeTotalLimit,
                  hours: target.value,
                });
              }}
            />
          </DivItemWrapper>
        </DivFlexRow>
        <DivFlexRow>
          <DivItemWrapper>
            <PTitile>上班時間</PTitile>
          </DivItemWrapper>
        </DivFlexRow>
        <DivFlexRow>
          <DivItemWrapper>
            <PSubtitle>
              上班開始時間<span>*</span>
            </PSubtitle>
          </DivItemWrapper>
          <DivItemWrapper>
            <TimePicker
              disabled = { !isEditing }
              value = { workdayHoursConfig.workdayStart === "" ? null : moment(moment(new Date("1978-1-1 " + workdayHoursConfig.workdayStart)).format('HH:mm'), 'HH:mm') }
              onChange = { value => {
                console.log(value);
                updateWorkdayHoursHandler("workdayStart", moment(new Date(value)).format('HH:mm:ss'));
              }}
              format = { 'HH:mm' } 
            />
          </DivItemWrapper>
          <DivItemWrapper>
            <PSubtitle>
              上班結束時間<span>*</span>
            </PSubtitle>
          </DivItemWrapper>
          <DivItemWrapper>
            <TimePicker
              disabled = { !isEditing }
              value = { workdayHoursConfig.workdayEnd === "" ? null : moment(moment(new Date("1978-1-1 " + workdayHoursConfig.workdayEnd)).format('HH:mm'), 'HH:mm') }
              onChange = { value => {
                updateWorkdayHoursHandler("workdayEnd", moment(new Date(value)).format('HH:mm:ss'));
              }}
              format = { 'HH:mm' } 
            />
          </DivItemWrapper>
        </DivFlexRow>
        <DivFlexRow>
          <DivItemWrapper>
            <PSubtitle>
              午休開始時間<span>*</span>
            </PSubtitle>
          </DivItemWrapper>
          <DivItemWrapper>
            <TimePicker
              disabled = { !isEditing }
              value = { workdayHoursConfig.lunchBreakStart === "" ? null : moment(moment(new Date("1978-1-1 " + workdayHoursConfig.lunchBreakStart)).format('HH:mm'), 'HH:mm') }
              onChange = { value => {
                // console.log(moment(new Date(value)).format('HH:mm'));
                updateWorkdayHoursHandler("lunchBreakStart", moment(new Date(value)).format('HH:mm:ss'));
              }}
              format = { 'HH:mm' } 
            />
          </DivItemWrapper>
          <DivItemWrapper>
            <PSubtitle>
            午休結束時間<span>*</span>
            </PSubtitle>
          </DivItemWrapper>
          <DivItemWrapper>
            <TimePicker
              disabled = { !isEditing }
              value = { workdayHoursConfig.lunchBreakEnd === "" ? null : moment(moment(new Date("1978-1-1 " + workdayHoursConfig.lunchBreakEnd)).format('HH:mm'), 'HH:mm') }
              onChange = { value => {
                updateWorkdayHoursHandler("lunchBreakEnd", moment(new Date(value)).format('HH:mm:ss'));
              }}
              format = { 'HH:mm' } 
            />
          </DivItemWrapper>
        </DivFlexRow>
        <DivFlexRow>
          <PWideTitle>加班不扣除午休時間</PWideTitle>
            <DivCheckboxWrapper>
              <Checkbox
                disabled = { !isEditing }
                checked = { workdayHoursConfig.includeLunchInOvertime }
                changeHandler = { value => {
                  updateWorkdayHoursHandler('includeLunchInOvertime', value);
                }}
              />
            </DivCheckboxWrapper>
        </DivFlexRow>
        <DivFlexRow>
          <DivButtonsWrapper>
            {
              isEditing
                ? <ButtonSave
                    onClick = { saveHandler }
                  >儲存</ButtonSave>
                : <ButtonSave
                    disabled = { !userEditable }
                    onClick = { () => { setIsEditing(true) } }
                  >編輯</ButtonSave>
            }
          </DivButtonsWrapper>
        </DivFlexRow>
      </TableWrapper>
      { showLoadingPopup && <PopupLoading/> }
      {
        showSaveSuccessPopup &&
          <PopupExecuteResult
            title = "更新成功"
            confirmHandler = { closeSuccessPopupHandler }
          />
      }
      {
        showSaveFailPopup &&
          <PopupExecuteResult
            title = { apiFailPopupTitle }
            text = { apiFailPopupMessage }
            confirmHandler = { () => {
              dispatch(CLOSE_SAVE_FAIL_POPUP());
            }}
          />
      }
    </MainContent>
  );
};

export default WorkingHoursParams;
