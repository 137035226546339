import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { postToGetScheduleTimeConfig } from '@/api/attendanceService';

import {
  deleteWorkingHours,
  putModifyWorkingHours,
  postToGetWorkingHours,
  postToGetWorkingHoursDetail,
} from '@/api/attendanceService';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import {
  FETCH_PROJECT_ID_OPTIONS,
  FETCH_WORKINGHOURS_TYPE_OPTIONS,
} from '@/store/actions/srpOptionsAction';

import MainContent from '@/component-style/MainContent';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import PopupExecuteConfirm from '@/components/UI/Popup/PopupExecuteConfirm';
import PanelOvertimeDetail from '@/components/Panel/PanelOvertimeDetail';
import { ButtonBasic, ButtonCancel } from '@/component-style/Button';
import { DivFlexRow } from '@/component-style/RWDWrapper';

import { overtimeConfig } from '@/constant/dataConfig/attendanceModule';
import {
  diffTime,
  toUTCTime,
  formatTime,
  getEndOfUnit,
  getStartOfUnit,
  getOtStatus,
  diffTimeWithFloat,
  diffTimeSubtractLunchBreak,
} from '@/utils/timeUtils';

import { postToGetUserInfo } from '@/api/authService';

import moment from 'moment';

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding-right: 0;
  padding-bottom: 20px;
  justify-content: flex-end;

  @media (max-width: 425px) {
    justify-content: center;
    padding-left: 0;
  }
`;

const ButtonSave = styled(ButtonBasic)`
  margin-left: 10px;
  margin-top: 10px;
  width: 120px;

  @media (max-width: 425px) {
    &:nth-child(2n+1) { margin-left: 0 }
  }
`;

const ButtonClear = styled(ButtonCancel)`
  margin-left: 10px;
  margin-top: 10px;
  width: 120px;
`;

const OvertimeDetail = () => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const projectIDOptions = useSelector(state => state.srpOptions.projectIDOptions);
  const workingHoursTypeOptions = useSelector(state => state.srpOptions.workingHoursTypeOptions);
  const workingHoursTagDictionary = useSelector(state => state.srpOptions.workingHoursTagDictionary);
  const showSaveFailPopup = useSelector(state => state.apiFail.showSaveFailPopup);
  const apiFailPopupTitle = useSelector(state => state.apiFail.title);
  const apiFailPopupMessage = useSelector(state => state.apiFail.message);

  const [overtime, setOvertime] = useState({ ...overtimeConfig });
  const [overtimeParams, setOvertimeParam] = useState({
    overTimeLimit: 0,
    freeTimeLimit: 0,
    extraPayLimit: 0,
  });

  const [isEditing, setIsEditing] = useState(false);
  const [showAlertPopup, setShowAlertPopup] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showApprovement, setShowApprovement] = useState(false);
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [showSaveSuccessPopup, setShowSaveSuccessPopup] = useState(false);
  const [showDeleteSuccessPopup, setShowDeleteSuccessPopup] = useState(false);
  const [showCancelSuccessPopup, setShowCancelSuccessPopup] = useState(false);

  const [overtimeRecords, setOvertimeRecords] = useState([]);

  const overtimeMinutes = diffTimeSubtractLunchBreak(overtime.startTimeLocal, overtime.endTimeLocal, 'minutes') || 0;

  const [approvedUser, setApprovedUser] = useState();

  const updateOvertimeHandler = (key, value) => {
    const updateObj = { ...overtime };
    updateObj[key] = value;
    setOvertime({...updateObj});
  };

  const typeChangeHandler = value => {
    setOvertime(prevState => ({
      ...prevState,
      typeID: value,
      tag: '',
    }));
  };

  // const isOvertimeAlert = mins => mins > overtimeParams.overTimeLimit * 60
  //   || (overtime.overtimeType === 'freeTime' && mins > overtimeParams.freeTimeLimit * 60)
  //   || (overtime.overtimeType === 'extraPay' && mins > overtimeParams.extraPayLimit * 60);

  // const isOvertimeAlertTwo = (overtimeMinutes, appliedOvertimeMinutes) => overtimeMinutes > overtimeParams.overTimeLimit * 60
  //       || (overtimeMinutes + appliedOvertimeMinutes) > overtimeParams.freeTimeLimit * 60
    // || (overtime.overtimeType === 'freeTime' && mins > overtimeParams.freeTimeLimit * 60)
    // || (overtime.overtimeType === 'extraPay' && mins > overtimeParams.extraPayLimit * 60);

  const isOvertimeAlert = (overtimeDateType, overtimeMinutes, appliedOvertimeMinutes) => 
  {
    // console.log("isOvertimeAlert overtimeParams:" + JSON.stringify(overtimeParams));
    console.log("isOvertimeAlert overtimeDateType:" + overtimeDateType);
    var boolOvertimeHourOverLimit = false;
    let thisDayOvertimerWorkingHours = 0;
    for (const objOvertimeRecord of overtimeRecords) {
      console.log("isOvertimeAlert objOvertimeRecord.startTimeLocal" + objOvertimeRecord.startTimeLocal);
      thisDayOvertimerWorkingHours += diffTimeWithFloat(objOvertimeRecord.startTimeLocal, objOvertimeRecord.endTimeLocal, 'hours')
    }
    console.log("isOvertimeAlert thisDayOvertimerWorkingHours:" + thisDayOvertimerWorkingHours);
    if (overtimeDateType === "workdays") {
      if (thisDayOvertimerWorkingHours > overtimeParams.overTimeLimit) {
        boolOvertimeHourOverLimit = true;
      }
    } else {
      if (thisDayOvertimerWorkingHours > 12) {
        boolOvertimeHourOverLimit = true;
      }
    }
    return boolOvertimeHourOverLimit;
  };

  const submitHandler = async () => {
    setShowLoadingPopup(true);
    const { workingDate, userID } = overtime;

    try {
      const { data } = await postToGetWorkingHours({
        isOverTime: true,
        userIDs: [userID],
        startDate: formatTime(getStartOfUnit(workingDate, 'month'), 'YYYY-MM-DD'),
        endDate: formatTime(getEndOfUnit(workingDate, 'month'), 'YYYY-MM-DD'),
        amount: 500,
        page: 1,
      });

      const appliedOvertimeMinutes = data.workingHours
        .map(({ startTimeLocal, endTimeLocal }) => ({ startTimeLocal, endTimeLocal }))
        .reduce((acc, { startTimeLocal, endTimeLocal }) =>
          acc + diffTime(startTimeLocal, endTimeLocal, 'minutes')
        , 0);

      setShowLoadingPopup(false);

      // console.log("submitHandler overtimeParams:" + JSON.stringify(overtimeParams));
      // console.log("submitHandler appliedOvertimeMinutes:" + appliedOvertimeMinutes);
      // console.log("submitHandler overtimeMinutes:" + overtimeMinutes);
      
      isOvertimeAlert(overtime.overtimeDateType, overtimeMinutes, appliedOvertimeMinutes)
        ? setShowAlertPopup(true)
        : submitModifiedOvertime();

    } catch (err) {
      setShowLoadingPopup(false);
      console.log(err)
    };
  };

  const saveHandler = async () => {
    setShowLoadingPopup(true);
    const { workingDate, userID } = overtime;

    try {
      const { data } = await postToGetWorkingHours({
        isOverTime: true,
        userIDs: [userID],
        startDate: formatTime(getStartOfUnit(workingDate, 'month'), 'YYYY-MM-DD'),
        endDate: formatTime(getEndOfUnit(workingDate, 'month'), 'YYYY-MM-DD'),
        amount: 500,
        page: 1,
      });

      const appliedOvertimeMinutes = data.workingHours
        .map(({ startTimeLocal, endTimeLocal }) => ({ startTimeLocal, endTimeLocal }))
        .reduce((acc, { startTimeLocal, endTimeLocal }) =>
          acc + diffTime(startTimeLocal, endTimeLocal, 'minutes')
        , 0);

      setShowLoadingPopup(false);

      isOvertimeAlert(overtime.overtimeDateType, overtimeMinutes, appliedOvertimeMinutes)
        ? setShowAlertPopup(true)
        : saveModifiedOvertime();

    } catch (err) {
      setShowLoadingPopup(false);
      console.log(err)
    };
  };

  const saveModifiedOvertime = async () => {
    setShowLoadingPopup(true);

    console.log("overtimeDetail.js saveModifiedOvertime overtime.workingDate:" + formatTime(overtime.workingDate, 'YYYY-MM-DD'));
    getOtStatus(overtime);
    try {
      await putModifyWorkingHours({
        ...overtime,
        workingDate: formatTime(overtime.workingDate, 'YYYY-MM-DD'),
        startTimeLocal: formatTime(overtime.startTimeLocal),
        endTimeLocal: formatTime(overtime.endTimeLocal),
        freeTimeMins: overtime.overtimeType === 'freeTime' ? overtimeMinutes : 0,
        extraPayMins: overtime.overtimeType === 'extraPay' ? overtimeMinutes : 0,
      });
      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '更新失敗', err }));
      console.log(err);
    }
  };

  const submitModifiedOvertime = async () => {
    try {
      await putModifyWorkingHours({
        ...overtime,
        workingDate: formatTime(overtime.workingDate, 'YYYY-MM-DD'),
        startTimeLocal: formatTime(overtime.startTimeLocal),
        endTimeLocal: formatTime(overtime.endTimeLocal),
        submitDate: formatTime(Date.now(), 'YYYY-MM-DD'),
        approvementStatus: 'unapproved',
        freeTimeMins: overtime.overtimeType === 'freeTime' ? overtimeMinutes : 0,
        extraPayMins: overtime.overtimeType === 'extraPay' ? overtimeMinutes : 0,
      });
      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '更新失敗', err }));
      console.log(err);
    }
  };

  const deleteOvertimesHandler = async () => {
    setShowDeletePopup(false);
    setShowLoadingPopup(true);

    try {
      await deleteWorkingHours([overtime.workingHoursID]);
      setShowLoadingPopup(false);
      setShowDeleteSuccessPopup(true);
    } catch(err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '刪除失敗', err }));
      console.log(err);
    }
  };

  const unsubmitHandler = async () => {
    setShowLoadingPopup(true);

    try {
      await putModifyWorkingHours({
        ...overtime,
        approvementStatus: 'draft',
      });
      setShowLoadingPopup(false);
      setShowCancelSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '取消失敗', err }));
      console.log(err);
    }
  };

  const fetchOvertimeParams = async () => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetScheduleTimeConfig();
      const { overTimeLimit, freeTimeLimit, extraPayLimit } = data;

      setOvertimeParam(prevState => ({
        ...prevState,
        overTimeLimit,
        freeTimeLimit,
        extraPayLimit,
      }));

    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const fetchOvertimeHoursInMonth = async(userID, startDate, endDate) => {
    const { data } = await postToGetWorkingHours({
      isOverTime: true,
      userIDs: [userID],
      // startDate: moment(startDate).startOf('month').format('YYYY-MM-DD'),
      // endDate: moment(endDate).endOf('month').format('YYYY-MM-DD'),
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD'),
      amount: 1000,
      page:1,
    });
    const { workingHours } = data;
    const overtimeRecords = [];

    for (let i = 0; i < workingHours.length; i += 1 ) {
      overtimeRecords.push({
        ...workingHours[i],
      });
    }

    setOvertimeRecords([ ...overtimeRecords ]);
    // console.log("fetchOvertimeHoursInMonth data:" + JSON.stringify(data));
  };

  const fetchOvertimeInfo = async () => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetWorkingHoursDetail(id);
      // console.log("overtimeDetail.js dat:" + JSON.stringify(data));
      if (!(data.approvementUserID == null)) {
        console.log(`overtimeDetail.js JSON.stringify(data)=${JSON.stringify(data)}`);
        const userInfo = await postToGetUserInfo(data.approvementUserID);
        console.log(`overtimeDetail.js userInfo=${JSON.stringify(userInfo)}`);
        setApprovedUser(userInfo.data);
      }
      (data.approvementStatus === "draft" ||  data.approvementStatus === "unapproved" )? setShowApprovement(false) :
        data.approvementUserID === ''
          ? delete data.approvementUserID
          : setShowApprovement(true);
      delete data.endTime;
      delete data.startTime;
      setOvertime(prevState => ({ ...prevState, ...data}));

      fetchOvertimeHoursInMonth(data.userID, data.startDate, data.endDate);
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const initOvertimeInfo = useCallback(
    fetchOvertimeInfo, []);

  const initOvertimeParams = useCallback(
    fetchOvertimeParams, []);

  const initOptions = useCallback(() => {
    dispatch(FETCH_PROJECT_ID_OPTIONS());
    dispatch(FETCH_WORKINGHOURS_TYPE_OPTIONS());
  }, [dispatch]);

  useEffect(() => {
    initOptions();
    initOvertimeInfo();
    initOvertimeParams();
  }, [initOptions, initOvertimeInfo, initOvertimeParams]);

  // useEffect(() => {
  //   console.log("useEffect overtime.startTime:" + overtime.startTime);
  // }, []);

  return (
    <MainContent>
      <PanelOvertimeDetail
        title = "加班明細"
        disabled = { !isEditing }
        disableDate = { true }
        overtime = { overtime }
        showApprovement = { showApprovement }
        projectIDOptions = { projectIDOptions }
        workingHoursTypeOptions = { workingHoursTypeOptions }
        workingHoursTagDictionary = { workingHoursTagDictionary }
        typeChangeHandler = { typeChangeHandler }
        updateOvertimeHandler = { updateOvertimeHandler }
        approvedUser = { approvedUser }
      >
        <DivFlexRow>
          <DivButtonsWrapper>
            {
              (overtime.approvementStatus === 'draft'
                || overtime.approvementStatus === 'disagree') &&
                <React.Fragment>
                  {
                    isEditing
                    ? <>
                        <ButtonSave
                          onClick = { saveHandler }
                          disabled = {  overtime.startTimeLocal === null
                            || overtime.endTimeLocal === null
                            || overtimeMinutes <= 0
                          }
                        >儲存</ButtonSave>
                        <ButtonSave
                          onClick = { submitHandler }
                          disabled = {  overtime.startTimeLocal === null
                            || overtime.endTimeLocal === null
                            || overtimeMinutes <= 0
                          }
                        >送審</ButtonSave>
                      </>
                    : <ButtonSave
                        onClick = { () => { setIsEditing(true) }}
                      >編輯</ButtonSave>
                  }
                  <ButtonSave
                    onClick = { () => { setShowDeletePopup(true) }}
                  >刪除</ButtonSave>
                </React.Fragment>
            }
            {
              overtime.approvementStatus === 'unapproved' &&
              <ButtonSave
              onClick = { unsubmitHandler }
              >取消送審</ButtonSave>
            }
            <ButtonClear
              onClick = { () => { history.goBack() }}
            >離開</ButtonClear>
          </DivButtonsWrapper>
        </DivFlexRow>
      </PanelOvertimeDetail>
      { showLoadingPopup && <PopupLoading/> }
      {
        showDeletePopup &&
          <PopupExecuteConfirm
            title = "刪除加班資料"
            text = "確定刪除此加班資料"
            confirmHandler = { deleteOvertimesHandler }
            cancelHandler = { () => {
              setShowDeletePopup(false);
            }}
          />
      }
      {
        showSaveSuccessPopup &&
          <PopupExecuteResult
            title = "更新成功"
            confirmHandler = { () => { history.goBack() }}
          />
      }
      {
        showDeleteSuccessPopup &&
          <PopupExecuteResult
            title = "刪除成功"
            confirmHandler = { () => {
              history.goBack();
            }}
          />
      }
      {
        showCancelSuccessPopup &&
          <PopupExecuteResult
            title = "取消成功"
            confirmHandler = { () => {
              history.goBack();
            }}
          />
      }
      {
        showAlertPopup &&
          <PopupExecuteResult
            title = "編輯失敗"
            text = "超過工作日期工時上限，無法新增工時"
            confirmHandler = { () => { setShowAlertPopup(false) } }
          />
      }
      {
        showSaveFailPopup &&
          <PopupExecuteResult
            title = { apiFailPopupTitle }
            text = { apiFailPopupMessage }
            confirmHandler = { () => {
              dispatch(CLOSE_SAVE_FAIL_POPUP());
            }}
          />
      }
    </MainContent>
  );
};

export default OvertimeDetail;
