import styled from 'styled-components';

export const InputReset = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 300px;
  padding: 0;
  border: none;
  outline: none;
  box-sizing: border-box;

  &::placeholder { color: #4d4d4d; }
  &:-ms-input-placeholder { color: #4d4d4d; }
  &::-ms-input-placeholder { color: #4d4d4d; }

  @media (min-width: 1920px) {
    width: 622px;
  };

  @media (max-width: 320px) {
    width: 260px;
  };
`;

export const InputBasic = styled(InputReset)`
  height: 40px;
  line-height: 40px;
  border-radius: 8px;
  border: solid 2px #d9d9d9;
  padding: 0 15px;
  color: #4d4d4d;
  font-size: 14px;
  font-weight: 500;
  background-color: ${ props => props.disabled
    ? '#f5f5f5'
    : '#ffffff'
  };
    cursor: ${ props => props.disabled
    ? 'not-allowed'
    : 'text'
  };

  &:focus, &:hover {
    border-width: 2px;
    border-style: solid;
    border-color: ${ props => props.disabled
      ? '#d9d9d9'
      : '#de2027'
    };
  }
`;

export const TextareaBasic = styled.textarea`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  width: 100%;
  min-height: 90px;
  border-radius: 8px;
  border: solid 2px #d9d9d9;
  padding: 5px 15px;
  color: #8b8b8b;
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  resize: none;
  background-color: ${ props => props.disabled
    ? '#f5f5f5'
    : '#ffffff'
  };
  cursor: ${ props => props.disabled
    ? 'not-allowed'
    : 'text'
  };

  &:focus {
    border: solid 2px #de2027;
  }
`;