import React from 'react';
import styled from 'styled-components';

import SelectNormal from '@/components/UI/Select/SelectNormal';
import { InputBasic } from '@/component-style/Input';
import { PSubtitle } from '@/component-style/TextElement';
import RemoveImg from '@/assets/images/icon_remove.svg';

import MyInputNumber from '@/components/UI/MyInputNumber';

const DivFWrapper = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  border-bottom: 2px dashed #e5e5e5;
`;

const InputWrapper = styled.div`
  margin-right: 20px;
  margin-bottom: 10px;

  @media (max-width: 375px) {
    margin-right: 0;
  }

  @media (min-width: 1920px) {
    .ant-select, .datePicker { width: 300px; }
  }

  @media (max-width: 320px) {
    .ant-select, .datePicker { width: 260px; }
  };
`;

const DeleteButtonWrapper = styled(InputWrapper)`
  margin-top: 20px;
`;

const Input = styled(InputBasic)`
  @media (max-width: 320px) { width: 260px; }
  @media (min-width: 1920px) { width: 300px }
`;

const PLabel = styled(PSubtitle)`
  width: auto;
  height: 20px;
  line-height: 20px;
`;

const DivIconRemove = styled.div`
  cursor: pointer;
  width: 20px;
  height: 40px;
  background-image: url(${ props => props.url });
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`;

const PRServiceInvoice = props => {
  const { prService } = props;

  const updatePRServiceHandler = (key, value) => {
    const updateObj = { ...prService };
    updateObj[key] = value;
    props.prServiceChangeHandler(updateObj);
  };

  return(
    <DivFWrapper>
      <InputWrapper>
        <PLabel>總監</PLabel>
        <SelectNormal
          disabled = { props.disabled }
          value = { prService.director }
          options = {[
            { value: '', desc: '請選擇總監' },
            ...props.directorOptions,
          ]}
          changeHandler = { value => {
            updatePRServiceHandler('director', value);
          }}
        />
      </InputWrapper>
      <InputWrapper>
        <PLabel>發票號碼</PLabel>
        <SelectNormal
          disabled = { props.disabled }
          value = { prService.invoiceCode }
          options = {[
            { value: '', desc: '請選擇發票' },
            ...props.invoiceOptions,
          ]}
          changeHandler = { value => {
            updatePRServiceHandler('invoiceCode', value);
          }}
        />
      </InputWrapper>
      <InputWrapper>
        <PLabel>服務費</PLabel>
        <MyInputNumber
          disabled = { props.disabled }
          value = { prService.prPrice }
          onChange = { value => {
            updatePRServiceHandler('prPrice', value);
          }}
        />
        {/* <Input
          type = "number"
          disabled = { props.disabled }
          value = { prService.prPrice }
          onChange = { ({ target }) => {
            updatePRServiceHandler('prPrice', target.value);
          }}
        /> */}
      </InputWrapper>
      <InputWrapper>
        <PLabel>專案支出費用</PLabel>
        <MyInputNumber
          disabled = { props.disabled }
          value = { prService.oopPrice }
          onChange = { value => {
            updatePRServiceHandler('oopPrice', value);
          }}
        />
        {/* <Input
          type = "number"
          disabled = { props.disabled }
          value = { prService.oopPrice }
          onChange = { ({ target }) => {
            updatePRServiceHandler('oopPrice', target.value);
          }}
        /> */}
      </InputWrapper>
      <InputWrapper>
        <PLabel>專案其它收入</PLabel>
        <MyInputNumber
          disabled = { props.disabled }
          value = { prService.income }
          onChange = { value => {
            updatePRServiceHandler('income', value);
          }}
        />
        {/* <Input
          type = "number"
          disabled = { props.disabled }
          value = { prService.income }
          onChange = { ({ target }) => {
            updatePRServiceHandler('income', target.value);
          }}
        /> */}
      </InputWrapper>
      {
        props.disabled !== true &&
          <DeleteButtonWrapper>
            <DivIconRemove
              disabled = { props.disabled }
              url = { RemoveImg }
              onClick = { props.removeHandler }
            />
          </DeleteButtonWrapper>
        }
    </DivFWrapper>
  );
};

export default PRServiceInvoice;
