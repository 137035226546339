import React from 'react';
import styled from 'styled-components';
import { Pagination } from 'antd';
import 'antd/dist/antd.css';
import './index.css';

const DivWrapper = styled.div`
  user-select: none; 
  width: 100%;
  height: 65px;
  border-top: solid 2px #f2f2f2;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 30px;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const PageSelector = props => {

  return (
    <DivWrapper>
      <Pagination
        size = "small"
        total = { props.total }
        current = { props.current }
        disabled = { props.disabled }
        onChange = { props.pageChangedHandler }
        showSizeChanger = { props.showSizeChanger }
        pageSizeOptions = { props.pageSizeOptions }
        pageSize = { props.pageSize === undefined ? 10 : props.pageSize }
        onShowSizeChange = { props.onShowSizeChange }
      />
    </DivWrapper>
  );
};

export default PageSelector;
