import axiosConfig from './axiosConfig';
import { getDefaultPort } from '@/utils/apiRequestUtils';

const { baseURL } = axiosConfig.defaults;

const port = process.env.REACT_APP_SERVICE_PORT_STORAGE !== undefined
  ? process.env.REACT_APP_SERVICE_PORT_STORAGE
  : getDefaultPort(baseURL);

const storageModuleUrl = process.env.NODE_ENV === 'development'
  ? `${baseURL}:${port}/api`
  : `${baseURL}/storage/api`;

export const postNewUploadFile = formdata => axiosConfig
  .post(`${storageModuleUrl}/file/upload`, formdata);

export const postToGetUploadFiles = (objectType, objectID) => axiosConfig
  .post(`${storageModuleUrl}/files`, { objectType, objectID });

export const postToGetUploadFilesWithArrayOfProjects = (arrayOfProjectsInfo) => axiosConfig
  .post(`${storageModuleUrl}/v2/files`, { objectInfo:arrayOfProjectsInfo });

export const getToDownloadCompressedFiles = ( objectType, objectID ) => axiosConfig
  .get(`${storageModuleUrl}/files/${objectType}`, {
    params: {
      'objectID': objectID,
    },
    headers: {
      'Accept':  'application/octet-stream, */*',
    }
  });

export const getDownloadFile = fileID => axiosConfig
  .get(`${storageModuleUrl}/file/${fileID}`, {
    responseType: 'blob',
    headers: {
      'Accept':  'application/octet-stream, */*',
    }
  });

export const deleteUploadFile = fileID => axiosConfig
  .delete(`${storageModuleUrl}/file/${fileID}`);

export const getVoucherDownloadFile = data => axiosConfig
  .get(`${storageModuleUrl}/files/quotationVoucher`, {
    params: { objectID: data.voucherID } ,
    responseType: 'blob',
    headers: {
      'Accept':  'application/octet-stream, */*',
    }
  });
