import React from 'react';
import styled from 'styled-components';

import { TdItem, TableRow } from '@/component-style/Table';
import { decimalCommaConverter } from '@/utils/textUtils';

const TrWrapper = styled(TableRow)`
  border-bottom: solid 2px #f2f2f2;
  box-sizing: border-box;
  min-width: 1080px;

  &:last-child {
    border-bottom: none;
  }
`;

const Td100 = styled(TdItem)`
  width: 100px;
`;

const Td120 = styled(TdItem)`
  width: 120px;
`;

const Td160 = styled(TdItem)`
  width: 160px;
`;

const Td180 = styled(TdItem)`
  width: 180px;
`;

const Item = props => {
  const { voucher } = props;

  // const type = getOptionDesc(vendorInvoiceTypes, voucher.voucherType);

  return (
    <TrWrapper>
      <Td100>{ voucher.companyType }</Td100>
      <Td100>{ voucher.director }</Td100>
      <Td120>{ voucher.invoiceQueryPeriod }</Td120>
      <Td120>{ voucher.predProjectTotal == null ? "$ 0" : decimalCommaConverter(voucher.predProjectTotal) }</Td120>
      <Td120>{ voucher.invoiceTotal == null ? "$ 0" : decimalCommaConverter(voucher.invoiceTotal) }</Td120>
      <Td120>{ voucher.expenseTotal == null ? "$ 0" : decimalCommaConverter(voucher.expenseTotal) }</Td120>
      <Td120>{ voucher.prPriceTotal == null ? "$ 0" : decimalCommaConverter(voucher.prPriceTotal) }</Td120>
      <Td120>{ voucher.incomeTotal == null ? "$ 0" : decimalCommaConverter(voucher.incomeTotal) }</Td120>
      <Td120>{ voucher.grossProfit == null ? "$ 0" : decimalCommaConverter(voucher.grossProfit) }</Td120>
      <Td120>{ voucher.grossProfitRate == null ? "0%" : voucher.grossProfitRate + "%" }</Td120>
      <Td120>{ voucher.hrExpense == null ? "$ 0" : decimalCommaConverter(voucher.hrExpense) }</Td120>
      <Td120>{ voucher.maExpense == null ? "$ 0" : decimalCommaConverter(voucher.maExpense) }</Td120>
      <Td120>{ voucher.netProfit == null ? "$ 0" : decimalCommaConverter(voucher.netProfit) }</Td120>
    </TrWrapper>
  )
};

export default Item;
