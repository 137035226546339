import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import SearchButtonSet from '@/components/UI/SearchButtonSet';
import PanelWrapper from '@/components/UI/PanelWrapper';
import DatePeriodSelector from '@/components/UI/DatePeriodSelector';
import SelectSearchable from '@/components/UI/Select/SelectSearchable';
import { ButtonBasic } from '@/component-style/Button';
import { PSubtitle } from '@/component-style/TextElement';
import {
  DivFlexRow,
  DivItemWrapper,
  DivFullWidthWrapper,
  DivLongMultiSelector,
} from '@/component-style/RWDWrapper';

import {
  basicBooleanOptions,
  scheduleChargeOptions,
} from '@/constant/options';

import { formatTime } from '@/utils/timeUtils';

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding: 0;
  justify-content: space-between;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const DivButtonSetWrapper = styled.div`
  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`;

const Button = styled(ButtonBasic)`
  width: 120px;
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }
`;

const PanelScheduleSearch = props => {
  const history = useHistory();
  const projectRoutes = useSelector(state => state.login.projectRoutes);
  const { config } = props;

  const userAddable = projectRoutes.indexOf('ScheduleCreation') !== -1;
  const userExportable = projectRoutes.indexOf('ProjectSchedule-Export') !== -1;

  const updateEstimatedDateStartHandler = value => {
    props.updateConfigHandler('estimatedDate', {
      ...config.estimatedDate,
      start: formatTime(value, 'YYYY-MM-DD'),
    });
  };

  const updateEstimatedDateEndHandler = value => {
    props.updateConfigHandler('estimatedDate', {
      ...config.estimatedDate,
      end: formatTime(value, 'YYYY-MM-DD'),
    });
  };

  const updateFinishedDateStartHandler = value => {
    props.updateConfigHandler('finishedDate', {
      ...config.finishedDate,
      start: formatTime(value, 'YYYY-MM-DD'),
    });
  };

  const updateFinishedDateEndHandler = value => {
    props.updateConfigHandler('finishedDate', {
      ...config.finishedDate,
      end: formatTime(value, 'YYYY-MM-DD'),
    });
  };

  return (
    <PanelWrapper title = "進度搜尋">
      <DivFlexRow>
        <DivFullWidthWrapper>
          <PSubtitle>專案代號</PSubtitle>
          <DivLongMultiSelector>
          <SelectSearchable
            value = { config.projectID }
            options = {[
              { value: '', desc: '請選擇專案代號' },
              ...props.projectIDOptions,
            ]}
            changeHandler = { value => {
              props.updateConfigHandler('projectID', value);
            }}
          />
          </DivLongMultiSelector>
        </DivFullWidthWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>工作項目</PSubtitle>
          <SelectSearchable
            value = { config.todoTypeID }
            options = {[
              { value: '', desc: '請選擇工作項目' },
              ...props.todoTypeOptions
            ]}
            changeHandler = { value => {
              props.todoTypeChangeHandler(value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>工作細項</PSubtitle>
          <SelectSearchable
            value = { config.todoTag }
            options = {[
              { value: '', desc: '請選擇工作細項' },
              ...props.todoTagOptions,
            ]}
            changeHandler = { value => {
              props.updateConfigHandler('todoTag', value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>預計完成日</PSubtitle>
          <DivLongMultiSelector>
            <DatePeriodSelector
              startTime = { config.estimatedDate.start }
              endTime = { config.estimatedDate.end }
              endTimeOKHandler = { updateEstimatedDateEndHandler }
              endTimeChangeHandler = { updateEstimatedDateEndHandler }
              startTimeOKHandler = { updateEstimatedDateStartHandler }
              startTimeChangeHandler = { updateEstimatedDateStartHandler }
            />
          </DivLongMultiSelector>
        </DivItemWrapper>
      </DivFlexRow>
        <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>實際完成日</PSubtitle>
          <DivLongMultiSelector>
            <DatePeriodSelector
              startTime = { config.finishedDate.start }
              endTime = { config.finishedDate.end }
              endTimeOKHandler = { updateFinishedDateEndHandler }
              endTimeChangeHandler = { updateFinishedDateEndHandler }
              startTimeOKHandler = { updateFinishedDateStartHandler }
              startTimeChangeHandler = { updateFinishedDateStartHandler }
            />
          </DivLongMultiSelector>
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>負責單位</PSubtitle>
          <SelectSearchable
            value = { config.department }
            options = { scheduleChargeOptions }
            changeHandler = { value => {
              props.updateConfigHandler('department', value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>負責成員</PSubtitle>
          <SelectSearchable
            value = { config.memberID }
            options = {[
              { value: '', desc: '請選擇成員' },
              ...props.memberOptions,
            ]}
            changeHandler = { value => {
              props.updateConfigHandler('memberID', value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>已完成</PSubtitle>
          <SelectSearchable
            value = { config.isFinished }
            options = { basicBooleanOptions }
            changeHandler = { value => {
              props.updateConfigHandler('isFinished', value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>是否延遲</PSubtitle>
          <SelectSearchable
            value = { config.isDelayed }
            options = { basicBooleanOptions }
            changeHandler = { value => {
              props.updateConfigHandler('isDelayed', value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivButtonsWrapper>
          <DivButtonSetWrapper>
            <Button
              disabled = { !userAddable }
              onClick = { () => {
                history.push('/Project/ScheduleCreation');
              }}
            >新增</Button>
            <Button
              disabled = { !userExportable  }
              onClick = { props.exportHandler }
            >匯出</Button>
          </DivButtonSetWrapper>
          <SearchButtonSet
            clearHandler = { props.clearHandler }
            searchHandler = { props.searchHandler }
          />
        </DivButtonsWrapper>
      </DivFlexRow>
    </PanelWrapper>
  );
};

export default PanelScheduleSearch;
