const zipCodes = {
  taipeiCity: {
    zhongzhengDist: { zipCode: '100', name:	'中正區' },
    datongDist: { zipCode: '103', name:	'大同區' },
    zhongshanDist: { zipCode: '104', name:	'中山區' },
    songshanDist: { zipCode: '105', name:	'松山區' },
    daAnDist: { zipCode: '106', name:	'大安區' },
    wanhuaDist: { zipCode: '108', name:	'萬華區' },
    xinyiDist: { zipCode: '110', name:	'信義區' },
    shilinDist: { zipCode: '111', name:	'士林區' },
    beitouDist: { zipCode: '112', name:	'北投區' },
    neihuDist: { zipCode: '114', name:	'內湖區' },
    nangangDist: { zipCode: '115', name:	'南港區' },
    wenshanDist: { zipCode: '116', name:	'文山區' },
  },
  keelungCity: {
    renAiDist: { zipCode: '200', name: '仁愛區' },
    xinyiDist: { zipCode: '201', name: '信義區' },
    zhongzhengDist: { zipCode: '202', name: '中正區' },
    zhongshanDist: { zipCode: '203', name: '中山區' },
    anleDist: { zipCode: '204', name: '安樂區' },
    nuannuanDist: { zipCode: '205', name: '暖暖區' },
    qiduDist: { zipCode: '206', name: '七堵區' },
  },
  lienchiangCounty: {
    nanganTownship: { zipCode: '209', name:	'南竿鄉' },
    beiganTownship: { zipCode: '210', name:	'北竿鄉' },
    juguangTownship: { zipCode: '211', name:	'莒光鄉' },
    dongyinTownship: { zipCode: '212', name:	'東引鄉' },
  },
  newTaipeiCity: {
    wanliDist: { zipCode: '207', name:	'萬里區' },
    jinshanDist: { zipCode: '208', name:	'金山區' },
    banqiaoDist: { zipCode: '220', name:	'板橋區' },
    xizhiDist: { zipCode: '221', name:	'汐止區' },
    shenkengDist: { zipCode: '222', name:	'深坑區' },
    shidingDist: { zipCode: '223', name:	'石碇區' },
    ruifangDist: { zipCode: '224', name:	'瑞芳區' },
    pingxiDist: { zipCode: '226', name:	'平溪區' },
    shuangxiDist: { zipCode: '227', name:	'雙溪區' },
    gongliaoDist: { zipCode: '228', name:	'貢寮區' },
    xindianDist: { zipCode: '231', name:	'新店區' },
    pinglinDist: { zipCode: '232', name:	'坪林區' },
    wulaiDist: { zipCode: '233', name:	'烏來區' },
    yongheDist: { zipCode: '234', name:	'永和區' },
    zhongheDist: { zipCode: '235', name:	'中和區' },
    tuchengDist: { zipCode: '236', name:	'土城區' },
    sanxiaDist: { zipCode: '237', name:	'三峽區' },
    shulinDist: { zipCode: '238', name:	'樹林區' },
    yinggeDist: { zipCode: '239', name:	'鶯歌區' },
    sanchongDist: { zipCode: '241', name:	'三重區' },
    xinzhuangDist: { zipCode: '242', name:	'新莊區' },
    taishanDist: { zipCode: '243', name:	'泰山區' },
    linkouDist: { zipCode: '244', name:	'林口區' },
    luzhouDist: { zipCode: '247', name:	'蘆洲區' },
    wuguDist: { zipCode: '248', name:	'五股區' },
    baliDist: { zipCode: '249', name:	'八里區' },
    tamsuiDist: { zipCode: '251', name:	'淡水區' },
    sanzhiDist: { zipCode: '252', name:	'三芝區' },
    shimenDist: { zipCode: '253', name:	'石門區' },
  },
  yilanCounty: {
    yilanCity: { zipCode: '260',	name: '宜蘭市' },
    touchengTownship: { zipCode: '261',	name: '頭城鎮' },
    jiaoxiTownship: { zipCode: '262',	name: '礁溪鄉' },
    zhuangweiTownship: { zipCode: '263',	name: '壯圍鄉' },
    yuanshanTownship: { zipCode: '264',	name: '員山鄉' },
    luodongTownship: { zipCode: '265',	name: '羅東鎮' },
    sanxingTownship: { zipCode: '266',	name: '三星鄉' },
    datongTownship: { zipCode: '267',	name: '大同鄉' },
    wujieTownship: { zipCode: '268',	name: '五結鄉' },
    dongshanTownship: { zipCode: '269',	name: '冬山鄉' },
    SuAoTownship: { zipCode: '270',	name: '蘇澳鎮' },
    nanAoTownship: { zipCode: '272',	name: '南澳鄉' },
  },
  diaoyutai: {
    diaoyutai: { zipCode: '290', name:	'釣魚台' },	
  },
  hsinchuCity: {
    eastDist: { zipCode: '300', name:	'東區' },
    northDist: { zipCode: '300', name:	'北區' },
    xiangshanDist: { zipCode: '300', name:	'香山區' },
  },
  hsinchuCounty :{
    zhubeiCity: { zipCode: '302', name:	'竹北市' },
    hukouTownship: { zipCode: '303', name:	'湖口鄉' },
    xinfengTownship: { zipCode: '304', name:	'新豐鄉' },
    xinpuTownship: { zipCode: '305', name:	'新埔鎮' },
    guanxiTownship: { zipCode: '306', name:	'關西鎮' },
    qionglinTownship: { zipCode: '307', name:	'芎林鄉' },
    baoshanTownship: { zipCode: '308', name:	'寶山鄉' },
    zhudongTownship: { zipCode: '310', name:	'竹東鎮' },
    wufengTownship: { zipCode: '311', name:	'五峰鄉' },
    hengshanTownship: { zipCode: '312', name:	'橫山鄉' },
    jianshiTownship: { zipCode: '313', name:	'尖石鄉' },
    beipuTownship: { zipCode: '314', name:	'北埔鄉' },
    emeiTownship: { zipCode: '315', name:	'峨眉鄉' },
  },
  taoyuanCity: {
    zhongliDist: { zipCode: '320',	name: '中壢區' },	
    pingzhenDist: { zipCode: '324',	name: '平鎮區' },	
    longtanDist: { zipCode: '325',	name: '龍潭區' },	
    yangmeiDist: { zipCode: '326',	name: '楊梅區' },
    xinwuDist: { zipCode: '327',	name: '新屋區' },	
    guanyinDist: { zipCode: '328',	name: '觀音區' },	
    taoyuanDist: { zipCode: '330',	name: '桃園區' },	
    guishanDist: { zipCode: '333',	name: '龜山區' },	
    badeDist: { zipCode: '334',	name: '八德區' },	
    daxiDist: { zipCode: '335',	name: '大溪區' },	
    fuxingDist: { zipCode: '336',	name: '復興區' },	
    dayuanDist: { zipCode: '337',	name: '大園區' },	
    luzhuDist: { zipCode: '338',	name: '蘆竹區' },	
  },
  miaoliCounty: {
    zhunanTownship: { zipCode: '350',	name: '竹南鎮' }, 
    toufenCity: { zipCode: '351',	name: '頭份市' }, 
    sanwanTownship: { zipCode: '352',	name: '三灣鄉' }, 
    nanzhuangTownship: { zipCode: '353',	name: '南庄鄉' }, 
    shitanTownship: { zipCode: '354',	name: '獅潭鄉' }, 
    houlongTownship: { zipCode: '356',	name: '後龍鎮' }, 
    tongxiaoTownship: { zipCode: '357',	name: '通霄鎮' }, 
    yuanliTownship: { zipCode: '358',	name: '苑裡鎮' }, 
    miaoliCity: { zipCode: '360',	name: '苗栗市' }, 
    zaoqiaoTownship: { zipCode: '361',	name: '造橋鄉' }, 
    touwuTownship: { zipCode: '362',	name: '頭屋鄉' }, 
    gongguanTownship: { zipCode: '363',	name: '公館鄉' }, 
    dahuTownship: { zipCode: '364',	name: '大湖鄉' }, 
    taiAnTownship: { zipCode: '365',	name: '泰安鄉' }, 
    tongluoTownship: { zipCode: '366',	name: '銅鑼鄉' }, 
    sanyiTownship: { zipCode: '367',	name: '三義鄉' }, 
    xihuTownship: { zipCode: '368',	name: '西湖鄉' }, 
    zhuolanTownship: { zipCode: '369',	name: '卓蘭鎮' }, 
  },
  taichungCity: {
    centralDist: { zipCode: '400', name:	'中區'	},
    eastDist: { zipCode: '401', name:	'東區'	},
    southDist: { zipCode: '402', name:	'南區'	},
    westDist: { zipCode: '403', name:	'西區'	},
    northDist: { zipCode: '404', name:	'北區'	},
    beitunDist: { zipCode: '406', name:	'北屯區'	},
    xitunDist: { zipCode: '407', name:	'西屯區'	},
    nantunDist: { zipCode: '408', name:	'南屯區'	},
    taipingDist: { zipCode: '411', name:	'太平區'	},
    daliDist: { zipCode: '412', name:	'大里區'	},
    wufengDist: { zipCode: '413', name:	'霧峰區'	},
    wuriDist: { zipCode: '414', name:	'烏日區'	},
    fengyuanDist: { zipCode: '420', name:	'豐原區'	},
    houliDist: { zipCode: '421', name:	'后里區'	},
    shigangDist: { zipCode: '422', name:	'石岡區'	},
    dongshiDist: { zipCode: '423', name:	'東勢區'	},
    hepingDist: { zipCode: '424', name:	'和平區'	},
    xinsheDist: { zipCode: '426', name:	'新社區'	},
    tanziDist: { zipCode: '427', name:	'潭子區'	},
    dayaDist: { zipCode: '428', name:	'大雅區'	},
    shengangDist: { zipCode: '429', name:	'神岡區'	},
    daduDist: { zipCode: '432', name:	'大肚區'	},
    shaluDist: { zipCode: '433', name:	'沙鹿區'	},
    longjingDist: { zipCode: '434', name:	'龍井區'	},
    wuqiDist: { zipCode: '435', name:	'梧棲區'	},
    qingshuiDist: { zipCode: '436', name:	'清水區'	},
    dajiaDist: { zipCode: '437', name:	'大甲區'	},
    waipuDist: { zipCode: '438', name:	'外埔區'	},
    DaAnDist: { zipCode: '439', name:	'大安區'	},
  },
  changhuaCounty: {
    changhuaCity: { zipCode: '500',	name: '彰化市'	},
    fenyuanTownship: { zipCode: '502',	name: '芬園鄉'	},
    huatanTownship: { zipCode: '503',	name: '花壇鄉'	},
    xiushuiTownship: { zipCode: '504',	name: '秀水鄉'	},
    lukangTownship: { zipCode: '505',	name: '鹿港鎮'	},
    fuxingTownship: { zipCode: '506',	name: '福興鄉'	},
    xianxiTownship: { zipCode: '507',	name: '線西鄉'	},
    hemeiTownship: { zipCode: '508',	name: '和美鎮'	},
    shengangTownship: { zipCode: '509',	name: '伸港鄉'	},
    yuanlinCity: { zipCode: '510',	name: '員林市'	},
    shetouTownship: { zipCode: '511',	name: '社頭鄉'	},
    yongjingTownship: { zipCode: '512',	name: '永靖鄉'	},
    puxinTownship: { zipCode: '513',	name: '埔心鄉'	},
    xihuTownship: { zipCode: '514',	name: '溪湖鎮'	},
    dacunTownship: { zipCode: '515',	name: '大村鄉'	},
    puyanTownship: { zipCode: '516',	name: '埔鹽鄉'	},
    tianzhongTownship: { zipCode: '520',	name: '田中鎮'	},
    beidouTownship: { zipCode: '521',	name: '北斗鎮'	},
    tianweiTownship: { zipCode: '522',	name: '田尾鄉'	},
    pitouTownship: { zipCode: '523',	name: '埤頭鄉'	},
    xizhouTownship: { zipCode: '524',	name: '溪州鄉'	},
    zhutangTownship: { zipCode: '525',	name: '竹塘鄉'	},
    erlinTownship: { zipCode: '526',	name: '二林鎮'	},
    dachengTownship: { zipCode: '527',	name: '大城鄉'	},
    fangyuanTownship: { zipCode: '528',	name: '芳苑鄉'	},
    ershuiTownship: { zipCode: '530',	name: '二水鄉'	},
  },
  nantouCounty: {
    nantouCity: { zipCode: '540', name:	'南投市' },
    zhongliaoTownship: { zipCode: '541', name:	'中寮鄉' },
    caotunTownship: { zipCode: '542', name:	'草屯鎮' },
    guoxingTownship: { zipCode: '544', name:	'國姓鄉' },
    puliTownship: { zipCode: '545', name:	'埔里鎮' },
    renAiTownship: { zipCode: '546', name:	'仁愛鄉' },
    mingjianTownship: { zipCode: '551', name:	'名間鄉' },
    jijiTownship: { zipCode: '552', name:	'集集鎮' },
    shuiliTownship: { zipCode: '553', name:	'水里鄉' },
    yuchiTownship: { zipCode: '555', name:	'魚池鄉' },
    xinyiTownship: { zipCode: '556', name:	'信義鄉' },
    zhushanTownship: { zipCode: '557', name:	'竹山鎮' },
    luguTownship: { zipCode: '558', name:	'鹿谷鄉' },
  },
  chiayiCity: {
    eastDist: { zipCode: '600', name:	'東區' },
    westDist: { zipCode: '600', name:	'西區' },
  },
  chiayiCounty: {
    fanluTownship: { zipCode: '602', name:	'番路鄉' },
    meishanTownship: { zipCode: '603', name:	'梅山鄉' },
    zhuqiTownship: { zipCode: '604', name:	'竹崎鄉' },
    alishanTownship: { zipCode: '605', name:	'阿里山鄉' },
    zhongpuTownship: { zipCode: '606', name:	'中埔鄉' },
    dapuTownship: { zipCode: '607', name:	'大埔鄉' },
    shuishangTownship: { zipCode: '608', name:	'水上鄉' },
    lucaoTownship: { zipCode: '611', name:	'鹿草鄉' },
    taibaoCity: { zipCode: '612', name:	'太保市' },
    puziCity: { zipCode: '613', name:	'朴子市' },
    dongshiTownship: { zipCode: '614', name:	'東石鄉' },
    liujiaoTownship: { zipCode: '615', name:	'六腳鄉' },
    xingangTownship: { zipCode: '616', name:	'新港鄉' },
    minxiongTownship: { zipCode: '621', name:	'民雄鄉' },
    dalinTownship: { zipCode: '622', name:	'大林鎮' },
    xikouTownship: { zipCode: '623', name:	'溪口鄉' },
    yizhuTownship: { zipCode: '624', name:	'義竹鄉' },
    budaiTownship: { zipCode: '625', name:	'布袋鎮' },
  },
  yunlinCounty: {
    dounanTownship: { zipCode: '630', name:	'斗南鎮' },
    dapiTownship: { zipCode: '631', name:	'大埤鄉' },
    huweiTownship: { zipCode: '632', name:	'虎尾鎮' },
    tukuTownship: { zipCode: '633', name:	'土庫鎮' },
    baozhongTownship: { zipCode: '634', name:	'褒忠鄉' },
    dongshiTownship: { zipCode: '635', name:	'東勢鄉' },
    taixiTownship: { zipCode: '636', name:	'臺西鄉' },
    lunbeiTownship: { zipCode: '637', name:	'崙背鄉' },
    mailiaoTownship: { zipCode: '638', name:	'麥寮鄉' },
    douliuCity: { zipCode: '640', name:	'斗六市' },
    linneiTownship: { zipCode: '643', name:	'林內鄉' },
    gukengTownship: { zipCode: '646', name:	'古坑鄉' },
    citongTownship: { zipCode: '647', name:	'莿桐鄉' },
    xiluoTownship: { zipCode: '648', name:	'西螺鎮' },
    erlunTownship: { zipCode: '649', name:	'二崙鄉' },
    beigangTownship: { zipCode: '651', name:	'北港鎮' },
    shuilinTownship: { zipCode: '652', name:	'水林鄉' },
    kouhuTownship: { zipCode: '653', name:	'口湖鄉' },
    sihuTownship: { zipCode: '654', name:	'四湖鄉' },
    YyuanzhangTownship: { zipCode: '655', name:	'元長鄉' },
  },
  tainanCity: {
    westCentralDist: { zipCode: '700', name:	'中西區'	},
    eastDist: { zipCode: '701', name:	'東區'	},
    southDist: { zipCode: '702', name:	'南區'	},
    northDist: { zipCode: '704', name:	'北區'	},
    anpingDist: { zipCode: '708', name:	'安平區'	},
    annanDist: { zipCode: '709', name:	'安南區'	},
    yongkangDist: { zipCode: '710', name:	'永康區'	},
    guirenDist: { zipCode: '711', name:	'歸仁區'	},
    xinhuaDist: { zipCode: '712', name:	'新化區'	},
    zuozhenDist: { zipCode: '713', name:	'左鎮區'	},
    yujingDist: { zipCode: '714', name:	'玉井區'	},
    nanxiDist: { zipCode: '715', name:	'楠西區'	},
    nanhuaDist: { zipCode: '716', name:	'南化區'	},
    rendeDist: { zipCode: '717', name:	'仁德區'	},
    guanmiaoDist: { zipCode: '718', name:	'關廟區'	},
    longqiDist: { zipCode: '719', name:	'龍崎區'	},
    guantianDist: { zipCode: '720', name:	'官田區'	},
    madouDist: { zipCode: '721', name:	'麻豆區'	},
    jialiDist: { zipCode: '722', name:	'佳里區'	},
    xigangDist: { zipCode: '723', name:	'西港區'	},
    qiguDist: { zipCode: '724', name:	'七股區'	},
    jiangjunDist: { zipCode: '725', name:	'將軍區'	},
    xuejiaDist: { zipCode: '726', name:	'學甲區'	},
    beimenDist: { zipCode: '727', name:	'北門區'	},
    xinyingDist: { zipCode: '730', name:	'新營區'	},
    houbiDist: { zipCode: '731', name:	'後壁區'	},
    baiheDist: { zipCode: '732', name:	'白河區'	},
    dongshanDist: { zipCode: '733', name:	'東山區'	},
    liujiaDist: { zipCode: '734', name:	'六甲區'	},
    xiayingDist: { zipCode: '735', name:	'下營區'	},
    liuyingDist: { zipCode: '736', name:	'柳營區'	},
    yanshuiDist: { zipCode: '737', name:	'鹽水區'	},
    shanhuaDist: { zipCode: '741', name:	'善化區'	},
    daneiDist: { zipCode: '742', name:	'大內區'	},
    shanshangDist: { zipCode: '743', name:	'山上區'	},
    xinshiDist: { zipCode: '744', name:	'新市區'	},
    andingDist: { zipCode: '745', name:	'安定區'	},
  },
  kaohsiungCity: {
    xinxingDist: { zipCode: '800', name:	'新興區'	},
    qianjinDist: { zipCode: '801', name:	'前金區'	},
    lingyaDist: { zipCode: '802', name:	'苓雅區'	},
    yanchengDist: { zipCode: '803', name:	'鹽埕區'	},
    gushanDist: { zipCode: '804', name:	'鼓山區'	},
    qijinDist: { zipCode: '805', name:	'旗津區'	},
    qianzhenDist: { zipCode: '806', name:	'前鎮區'	},
    sanminDist: { zipCode: '807', name:	'三民區'	},
    nanziDist: { zipCode: '811', name:	'楠梓區'	},
    xiaogangDist: { zipCode: '812', name:	'小港區'	},
    zuoyingDist: { zipCode: '813', name:	'左營區'	},
    renwuDist: { zipCode: '814', name:	'仁武區'	},
    dasheDist: { zipCode: '815', name:	'大社區'	},
    dongshaIslands: { zipCode: '817', name:	'東沙群島'	},
    nanshaIslands: { zipCode: '819', name:	'南沙群島'	},
    gangshanDist: { zipCode: '820', name:	'岡山區'	},
    luzhuDist: { zipCode: '821', name:	'路竹區'	},
    alianDist: { zipCode: '822', name:	'阿蓮區'	},
    tianliaoDist: { zipCode: '823', name:	'田寮區'	},
    yanchaoDist: { zipCode: '824', name:	'燕巢區'	},
    qiaotouDist: { zipCode: '825', name:	'橋頭區'	},
    ziguanDist: { zipCode: '826', name:	'梓官區'	},
    mituoDist: { zipCode: '827', name:	'彌陀區'	},
    yongAnDist: { zipCode: '828', name:	'永安區'	},
    huneiDist: { zipCode: '829', name:	'湖內區'	},
    fengshanDist: { zipCode: '830', name:	'鳳山區'	},
    daliaoDist: { zipCode: '831', name:	'大寮區'	},
    linyuanDist: { zipCode: '832', name:	'林園區'	},
    niaosongDist: { zipCode: '833', name:	'鳥松區'	},
    dashuDist: { zipCode: '840', name:	'大樹區'	},
    qishanDist: { zipCode: '842', name:	'旗山區'	},
    meinongDist: { zipCode: '843', name:	'美濃區'	},
    liuguiDist: { zipCode: '844', name:	'六龜區'	},
    neimenDist: { zipCode: '845', name:	'內門區'	},
    shanlinDist: { zipCode: '846', name:	'杉林區'	},
    jiaxianDist: { zipCode: '847', name:	'甲仙區'	},
    taoyuanDist: { zipCode: '848', name:	'桃源區'	},
    namaxiaDist: { zipCode: '849', name:	'那瑪夏區'	},
    maolinDist: { zipCode: '851', name:	'茂林區'	},
    qiedingDist: { zipCode: '852', name:	'茄萣區'	},
  },
  penghuCounty: {
    magongCity: { zipCode: '880', name:	'馬公市'	},
    xiyuTownship: { zipCode: '881', name:	'西嶼鄉'	},
    wangAnTownship: { zipCode: '882', name:	'望安鄉'	},
    qimeiTownship: { zipCode: '883', name:	'七美鄉'	},
    baishaTownship: { zipCode: '884', name:	'白沙鄉'	},
    huxiTownship: { zipCode: '885', name:	'湖西鄉'	},
  },
  kinmenCounty: {
    jinshaTownship: { zipCode: '890', name:	'金沙鎮'	},
    jinhuTownship: { zipCode: '891', name:	'金湖鎮'	},
    jinningTownship: { zipCode: '892', name:	'金寧鄉'	},
    jinchengTownship: { zipCode: '893', name:	'金城鎮'	},
    lieyuTownship: { zipCode: '894', name:	'烈嶼鄉'	},
    wuqiuTownship: { zipCode: '896', name:	'烏坵鄉'	},
  },
  pingtungCounty: {
    pingtungCity: { zipCode: '900', name:	'屏東市' },
    sandimenTownship: { zipCode: '901', name:	'三地門鄉' },
    wutaiTownship: { zipCode: '902', name:	'霧臺鄉' },
    majiaTownship: { zipCode: '903', name:	'瑪家鄉' },
    jiuruTownship: { zipCode: '904', name:	'九如鄉' },
    ligangTownship: { zipCode: '905', name:	'里港鄉' },
    gaoshuTownship: { zipCode: '906', name:	'高樹鄉' },
    yanpuTownship: { zipCode: '907', name:	'鹽埔鄉' },
    changzhiTownship: { zipCode: '908', name:	'長治鄉' },
    linluoTownship: { zipCode: '909', name:	'麟洛鄉' },
    zhutianTownship: { zipCode: '911', name:	'竹田鄉' },
    neipuTownship: { zipCode: '912', name:	'內埔鄉' },
    wandanTownship: { zipCode: '913', name:	'萬丹鄉' },
    chaozhouTownship: { zipCode: '920', name:	'潮州鎮' },
    taiwuTownship: { zipCode: '921', name:	'泰武鄉' },
    laiyiTownship: { zipCode: '922', name:	'來義鄉' },
    wanluanTownship: { zipCode: '923', name:	'萬巒鄉' },
    kandingTownship: { zipCode: '924', name:	'崁頂鄉' },
    XinpiTownship: { zipCode: '925', name:	'新埤鄉' },
    NanzhouTownship: { zipCode: '926', name:	'南州鄉' },
    LinbianTownship: { zipCode: '927', name:	'林邊鄉' },
    DonggangTownship: { zipCode: '928', name:	'東港鎮' },
    LiuqiuTownship: { zipCode: '929', name:	'琉球鄉' },
    JiadongTownship: { zipCode: '931', name:	'佳冬鄉' },
    XinyuanTownship: { zipCode: '932', name:	'新園鄉' },
    FangliaoTownship: { zipCode: '940', name:	'枋寮鄉' },
    FangshanTownship: { zipCode: '941', name:	'枋山鄉' },
    ChunriTownship: { zipCode: '942', name:	'春日鄉' },
    ShiziTownship: { zipCode: '943', name:	'獅子鄉' },
    ChechengTownship: { zipCode: '944', name:	'車城鄉' },
    MudanTownship: { zipCode: '945', name:	'牡丹鄉' },
    HengchunTownship: { zipCode: '946', name:	'恆春鎮' },
    ManzhouTownship: { zipCode: '947', name:	'滿州鄉' },
  },
  taitungCounty: {
    taitungCity: { zipCode: '950', name:	'臺東市' },	
    ludaoTownship: { zipCode: '951', name:	'綠島鄉' },	
    lanyuTownship: { zipCode: '952', name:	'蘭嶼鄉' },	
    yanpingTownship: { zipCode: '953', name:	'延平鄉' },	
    beinanTownship: { zipCode: '954', name:	'卑南鄉' },	
    luyeTownship: { zipCode: '955', name:	'鹿野鄉' },	
    guanshanTownship: { zipCode: '956', name:	'關山鎮' },	
    haiduanTownship: { zipCode: '957', name:	'海端鄉' },	
    chishangTownship: { zipCode: '958', name:	'池上鄉' },	
    dongheTownship: { zipCode: '959', name:	'東河鄉' },	
    chenggongTownship: { zipCode: '961', name:	'成功鎮' },	
    changbinTownship: { zipCode: '962', name:	'長濱鄉' },	
    taimaliTownship: { zipCode: '963', name:	'太麻里鄉' },	
    jinfengTownship: { zipCode: '964', name:	'金峰鄉' },	
    dawuTownship: { zipCode: '965', name:	'大武鄉' },	
    darenTownship: { zipCode: '966', name:	'達仁鄉' },	
  },
  hualienCounty: {
    hualienCity: { zipCode: '970', name:	'花蓮市'	},
    xinchengTownship: { zipCode: '971', name:	'新城鄉'	},
    xiulinTownship: { zipCode: '972', name:	'秀林鄉'	},
    jiAnTownship: { zipCode: '973', name:	'吉安鄉'	},
    shoufengTownship: { zipCode: '974', name:	'壽豐鄉'	},
    fenglinTownship: { zipCode: '975', name:	'鳳林鎮'	},
    guangfuTownship: { zipCode: '976', name:	'光復鄉'	},
    fengbinTownship: { zipCode: '977', name:	'豐濱鄉'	},
    ruisuiTownship: { zipCode: '978', name:	'瑞穗鄉'	},
    wanrongTownship: { zipCode: '979', name:	'萬榮鄉'	},
    yuliTownship: { zipCode: '981', name:	'玉里鎮'	},
    zhuoxiTownship: { zipCode: '982', name:	'卓溪鄉'	},
    fuliTownship: { zipCode: '983', name:	'富里鄉'	},
  },
};

export const getDistOptions = county => county in zipCodes
  ? Object.keys(zipCodes[county]).map(key => {
      const { name } = zipCodes[county][key];
      return { value: key, desc: name };
    })
  : [];

export const getZipCode = (county, dict) => county in zipCodes
  && dict in zipCodes[county]
    ? zipCodes[county][dict].zipCode
    : '';
