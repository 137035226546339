import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import TableWrapper from '@/components/UI/TableWrapper';
import DateSelector from '@/components/UI/DateSelector';
import SearchButtonSet from '@/components/UI/SearchButtonSet';
import { PSubtitle } from '@/component-style/TextElement';
import { ButtonBasic } from '@/component-style/Button';
import {
  DivFlexRow,
  DivItemWrapper,
} from '@/component-style/RWDWrapper';

import { formatTime } from '@/utils/timeUtils';

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding: 0;
  padding-bottom: 30px;
  justify-content: space-between;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const ButtonExport = styled(ButtonBasic)`
  width: 120px;

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`;

const PanelOvertimeByPersonnel = props => {
  const attendanceRoutes = useSelector(state => state.login.attendanceRoutes);
  const { config } = props;

  const userExportable = attendanceRoutes.indexOf('OvertimesList-Export') !== -1;

  return (
    <TableWrapper title = "加班統計" >
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>
            查詢月份
            <span>*</span>
          </PSubtitle>
          <DateSelector
            value = { config.startDate }
            picker = "month"
            changeHandler = { value => {
              props.updateConfigHandler(
                'startDate',
                formatTime(value, 'YYYY-MM'),
              );
            }}
          />
        </DivItemWrapper>
        {/* <DivItemWrapper>
          <PSubtitle>
            結束時間
            <span>*</span>
          </PSubtitle>
          <DateSelector
            value = { config.endDate }
            changeHandler = { value => {
              props.updateConfigHandler(
                'endDate',
                formatTime(value, 'YYYY-MM-DD'),
              );
            }}
          />
        </DivItemWrapper> */}
      </DivFlexRow>
      <DivFlexRow>
        <DivButtonsWrapper>
          <ButtonExport
            disabled = { !userExportable || !props.exportable}
            onClick = { props.exportHandler }
          >匯出</ButtonExport>
          <SearchButtonSet
            searchable = { config.startDate !== null }
            searchHandler = { props.searchHandler }
            clearHandler = { props.clearHandler }
          />
        </DivButtonsWrapper>
      </DivFlexRow>
    </TableWrapper>
  );
};

export default PanelOvertimeByPersonnel;
