import React from 'react';
import styled from 'styled-components';

import NavigationItem from '../NavigationItem';
import NavigationCategory from '../NavigationCategory';
import IconAdmin from '@/assets/images/icon_admin.svg';

const UlWrapper = styled.ul`
  margin: 0;
  padding: 0;
`;

const DivIcon = styled.span`
  display: inline-block;
  margin-left: 50px;
  width: 24px;
  height: 24px;
  background-image: url(${props => props.url});
`;

const authorityRoutes = [
  {
    title: '使用者管理',
    key: 'UserManagement',
    url: '/Authority/UserManagement',
  },
  {
    title: '系統角色管理',
    key: 'RoleManagement',
    url: '/Authority/RoleManagement',
  },
];

const NavigationListAuthority = props => {
  const { validRoutes } = props;

  const routeItems = authorityRoutes
    .filter(({ key }) => validRoutes.indexOf(key) !== -1)
    .map(({ title, url }) => (
      <NavigationItem
        key = { url }
        url = { url }
        title = { title }
      />
    ));

  return (
    <UlWrapper>
      <NavigationCategory
        title = "權限管理"
        opened = { props.opened }
        clickHandler = { props.toogleList }
      >
        <DivIcon url = { IconAdmin }/>
      </NavigationCategory>
      { props.opened && routeItems }
    </UlWrapper>
  );
};

export default NavigationListAuthority;
