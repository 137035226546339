import React from 'react';
import styled from 'styled-components';

import SelectNormal from '@/components/UI/Select/SelectNormal';
import DateSelector from '@/components/UI/DateSelector';
import { DivPopupWrapper, DivPopup } from '@/component-style/Popup';
import { ButtonBasic, ButtonCancel } from '@/component-style/Button';
import { DivFlexRow } from '@/component-style/RWDWrapper';

const DivItemWrapper = styled(DivFlexRow)`
  padding: 0;
  margin-bottom: 10px;
`;

const DivSelectWrapper = styled(DivItemWrapper)`

  & > div:first-of-type,
  .ant-select {
    width: 100%;
  }
`;

const DivButtonsWrapper = styled.div`
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;


const H3Title = styled.h3`
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: #8b8b8b;
  margin-bottom: 10px;
`;

const ButtonConfirm = styled(ButtonBasic)`
  width: 120px;
`;

const ButtonDiscard = styled(ButtonCancel)`
  width: 120px;
  margin-right: 10px;
`;

const PopupFinanceActionsSelect = props => {

  const disableConfirm = props.showDateSelector
    ? props.financeActionTime === null
    : false;

  return (
    <DivPopupWrapper>
      <DivPopup>
        <H3Title>財務作業</H3Title>
        <DivSelectWrapper>
          <SelectNormal
            value = { props.financeAction }
            // options = {[
            //   { value: 'predict', desc: '預計付款作業' },
            //   { value: 'payment', desc: '確定付款作業' },
            //   { value: 'print', desc: '支出憑單列印' },
            //   { value: 'service', desc: '勞務申報匯出' },
            //   { value: 'exportExpenseVoucher', desc: '匯會計系統', disabled: true },
            // ]}
            options = { props.options }
            changeHandler = { props.changeHandler }
          />
        </DivSelectWrapper>
        {
          props.showDateSelector &&
            <DivSelectWrapper>
              <DateSelector
                value = { props.financeActionTime }
                changeHandler = { props.updateFinanceActionTimeHandler }
              />
            </DivSelectWrapper>
        }
        <DivButtonsWrapper>
          <ButtonDiscard
            onClick = { props.cancelHandler }
          >關閉
          </ButtonDiscard>
          <ButtonConfirm
            disabled = { disableConfirm }
            onClick = { props.confirmHandler }
          >確認
          </ButtonConfirm>
        </DivButtonsWrapper>
      </DivPopup>
    </DivPopupWrapper>
  );
};

export default PopupFinanceActionsSelect;
