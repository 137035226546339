import * as types from '../action-types/srpOptionsActionTypes';

const initState = {
  systemRoleOptions: [],
  customerOptions: [],
  companyTypeOptions: [],
  contactWindowOptions: [],
  departmentOptions: [],
  directorOptions: [],
  memberOptions: [],
  projectManagerOptions: [],
  projectIDOptions: [],
  projectCodeOptions: [],
  projectTypeOptions: [],
  todoTypeOptions: [],
  todoTagDictionary: {},
  activityIdeaTypeOptions: [],
  activityIdeaTagDictionary: {},
  customerIndustries: [],
  mediaIDOptions: [],
  mediaTypeOptions: [],
  vendorIDOptions: [],
  vendorIDOptionsTaxID: [],
  vendorTypeOptions: [],
  vendorTagDictionary: {},
  venueTypeOptions: [],
  venueEquipments: [],
  venueCharacteristics: [],
  journalistTypeOptions: [],
  journalistIDOptions: [],
  expenseTypeOptions: [],
  expenseTagDictionary: {},
  specialFeatures: [],
  declareTypeOptions: [],
  workingHoursTypeOptions: [],
  workingHoursTagDictionary: {},
};

const srpOptionsReducer = (state = initState, action) => {
  const { type ,payload } = action;

  switch (type) {
    case types.UPDATE_PROJECT_ID_OPTIONS:
      return {
        ...state,
        projectIDOptions: [...payload],
      }
    case types.UPDATE_PROJECT_CODE_OPTIONS:
      return {
        ...state,
        projectCodeOptions: [...payload],
      }
    case types.UPDATE_CUSTOMER_OPTIONS:
      return {
        ...state,
        customerOptions: [...payload],
      }
    case types.UPDATE_COMPANY_TYPE_OPTIONS:
      console.log("srpOptionsReducer types.UPDATE_COMPANY_TYPE_OPTIONS JSON.stringify(payload):" + JSON.stringify(payload));
      payload.forEach(element => console.log(element.desc));
      let preferedOrder = ["頤德國際", "致德國際", "謙能國際"];
      var newPayload = [];
      preferedOrder.forEach(element => {
        payload.forEach(elementPayload => {
          if (element === elementPayload.desc) {
            newPayload.push(elementPayload);
          }
        });
      });
      return {
        ...state,
        companyTypeOptions: [...newPayload],
      }
    case types.UPDATE_CONTACT_WINDOW_OPTIONS:
      return {
        ...state,
        contactWindowOptions: [...payload],
      }
    case types.UPDATE_PROJECT_TYPE_OPTIONS:
      return {
        ...state,
        projectTypeOptions: [...payload],
      }
    case types.UPDATE_DEPARTMENT_OPTIONS:
      return {
        ...state,
        departmentOptions: [...payload],
      }
    case types.UPDATE_DIRECTOR_OPTIONS:
      return {
        ...state,
        directorOptions: [...payload],
      }
    case types.UPDATE_PROJECT_MANAGER_OPTIONS:
      return {
        ...state,
        projectManagerOptions: [...payload],
      }
    case types.UPDATE_MEMBER_OPTIONS:
      return {
        ...state,
        memberOptions: [...payload],
      }
    case types.UPDATE_TODO_TYPE_OPTIONS:
      return {
        ...state,
        todoTypeOptions: [...payload],
      }
    case types.UPDATE_TODO_TAG_DICTIONARY:
      return {
        ...state,
        todoTagDictionary: { ...payload },
      }
    case types.UPDATE_ACTIVITY_IDEA_TYPE_OPTIONS:
      return {
        ...state,
        activityIdeaTypeOptions: [...payload],
      }
    case types.UPDATE_ACTIVITY_IDEA_TAG_DICTIONARY:
      return {
        ...state,
        activityIdeaTagDictionary: { ...payload },
      }
    case types.UPDATE_CUSTOMER_INDUSTRIES:
      return {
        ...state,
        customerIndustries: [...payload],
      }
    case types.UPDATE_MEDIA_ID_OPTIONS:
      return {
        ...state,
        mediaIDOptions: [...payload],
      }
    case types.UPDATE_MEDIA_TYPES:
      return {
        ...state,
        mediaTypeOptions: [...payload],
      }
    case types.UPDATE_VENDOR_ID_OPTIONS:
      return {
        ...state,
        vendorIDOptions: [...payload],
      }
    case types.UPDATE_VENDOR_ID_OPTIONS_TAXID:
      return {
        ...state,
        vendorIDOptionsTaxID: [...payload],
      }
    case types.UPDATE_VENDOR_TYPE_OPTIONS:
      return {
        ...state,
        vendorTypeOptions: [...payload],
      }
    case types.UPDATE_VENDOR_TAG_DICTIONARY:
      return {
        ...state,
        vendorTagDictionary: { ...payload },
      }
    case types.UPDATE_VENUE_TYPE_OPTIONS:
      return {
        ...state,
        venueTypeOptions: [...payload],
      }
    case types.UPDATE_VENUE_EQUIPMENTS:
      return {
        ...state,
        venueEquipments: [...payload],
      }
    case types.UPDATE_VENUE_CHARACTERISTICS:
      return {
        ...state,
        venueCharacteristics: [...payload],
      }
    case types.UPDATE_JOURNALIST_TYPES:
      return {
        ...state,
        journalistTypeOptions: [...payload],
      }
    case types.UPDATE_JOURNALIST_ID_OPTIONS:
      return {
        ...state,
        journalistIDOptions: [...payload],
      }
    case types.UPDATE_EXPENSE_TYPE_OPTIONS:
      return {
        ...state,
        expenseTypeOptions: [...payload],
      }
    case types.UPDATE_EXPENSE_TAG_DICTIONARY:
      return {
        ...state,
        expenseTagDictionary: { ...payload },
      }
    case types.UPDATE_SPECIAL_FEATURES:
      return {
        ...state,
        specialFeatures: [ ...payload ],
      }
    case types.UPDATE_DECLARE_TYPE_OPTIONS:
      return {
        ...state,
        declareTypeOptions: [ ...payload ],
      }
    case types.UPDATE_WORKINGHOURS_TYPE_OPTIONS:
      return {
        ...state,
        workingHoursTypeOptions: [...payload],
      }
    case types.UPDATE_WORKINGHOURS_TAG_DICTIONARY:
      return {
        ...state,
        workingHoursTagDictionary: { ...payload },
      }
    case types.UPDATE_SYSTEM_ROLE_OPTIONS:
      return {
        ...state,
        systemRoleOptions: [...payload],
      }
    default:
      return state;
  }
};

export default srpOptionsReducer;
