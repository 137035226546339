import React from 'react';
import { Route } from 'react-router-dom';

import ProjectFinanceInfo from '@/containers/financeModule/projectFinanceInfo';
import FinanceApprovals from '@/containers/financeModule/financeApprovals';
import FinanceApprovalDetail from '@/containers/financeModule/financeApprovalDetail';
import FinanceManagement from '@/containers/financeModule/financeManagement';
import FinanceManagementDetail from '@/containers/financeModule/financeManagementDetail';
import InvoiceSearch from '@/containers/financeModule/invoiceSearch';
import DirectorRevenue from '@/containers/financeModule/directorRevenue';
import RevenueSearch from '@/containers/financeModule/revenueSearch';
import CashExpenseManagement from '@/containers/financeModule/cashExpenseManagement';
import CashExpenseList from '@/containers/financeModule/cashExpenseList';
import PurchaseBargain from '@/containers/financeModule/purchaseBargain';
import UnlockPurchase from '@/containers/financeModule/unlockPurchase';
// import PersonnelWage from '@/containers/financeModule/personnelWage';
// import WageStructureManagement from '@/containers/financeModule/wageStructureManagement';

const financeRoutes = [
  {
    key: 'ProjectFinanceInfo',
    path: "/Finance/ProjectFinanceInfo",
    component: ProjectFinanceInfo,
  },
  {
    key: 'FinanceApprovals',
    path: "/Finance/FinanceApprovals",
    component: FinanceApprovals,
  },
  {
    key: 'FinanceApprovalDetail',
    path: "/Finance/FinanceApprovalDetail/:id",
    component: FinanceApprovalDetail,
  },
  {
    key: 'FinanceManagement',
    path: "/Finance/FinanceManagement",
    component: FinanceManagement,
  },
  {
    key: 'FinanceManagementDetail',
    path: "/Finance/FinanceManagementDetail/:id",
    component: FinanceManagementDetail,
  },
  {
    key: 'InvoiceSearch',
    path: "/Finance/InvoiceSearch",
    component: InvoiceSearch,
  },
  {
    key: 'DirectorRevenue',
    path: "/Finance/DirectorRevenue",
    component: DirectorRevenue,
  },
  {
    key: 'RevenueSearch',
    path: "/Finance/RevenueSearch",
    component: RevenueSearch,
  },
  {
    key: 'CashExpenseManagement',
    path: "/Finance/CashExpenseManagement",
    component: CashExpenseManagement,
  },
  {
    key: 'CashExpenseList',
    path: "/Finance/CashExpenseList",
    component: CashExpenseList,
  },
  {
    key: 'PurchaseBargain',
    path: "/Finance/PurchaseBargain",
    component: PurchaseBargain,
  },
  {
    key: 'UnlockPurchase',
    path: "/Finance/UnlockPurchase",
    component: UnlockPurchase,
  },
  // {
  //   key: 'PersonnelWage',
  //   path: "/Finance/PersonnelWage",
  //   component: PersonnelWage,
  // },
  // {
  //   key: 'WageStructureManagement',
  //   path: "/Finance/WageStructureManagement",
  //   component: WageStructureManagement,
  // },
];


const RoutesFinanceModule = validRoutes => financeRoutes
  .filter(({ key }) => validRoutes.indexOf(key) !== -1)
  .map(({ path, component }) =>
    <Route
      key = { path }
      path = { path }
      component = { component }
    />
  );

export default RoutesFinanceModule;
