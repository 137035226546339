import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import MainContent from '@/component-style/MainContent';
import TableWrapper from '@/components/UI/TableWrapper';
import { PSubtitle } from '@/component-style/TextElement';
import { InputBasic } from '@/component-style/Input';
import { ButtonBasic } from '@/component-style/Button';
import { Upload } from 'antd';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import DateSelectorTwo from '@/components/UI/DateSelectorTwo';
import moment from 'moment';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import {
  putHRCostExcel,
  getHRCostExcel,
  deleteHRCostExcel,
} from '@/api/financeService';

import {
  DivFlexRow,
  DivItemWrapper,
} from '@/component-style/RWDWrapper';

const DivTableWrapper = styled.div`
  margin-bottom: 40px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const ButtonDownload = styled(ButtonBasic)`
  margin-right: 10px;
  width: 120px;

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`;

const ProjectCost = props => {
  // const history = useHistory();
  const dispatch = useDispatch();
  // const memberID = useSelector(state => state.login.token);
  // const userID = useSelector(state => state.login.token);
  const showSaveFailPopup = useSelector(state => state.apiFail.showSaveFailPopup);
  const apiFailPopupTitle = useSelector(state => state.apiFail.title);
  const apiFailPopupMessage = useSelector(state => state.apiFail.message);

  const [uploadFile, setUploadFile] = useState("");
  const [config, setConfig] = useState({ "startMonth": null, "endMonth" : null });
  const [deleteUploadFile, setDeleteUploadFile] = useState("");


  const onChangeHandler = async(info) => {
    // console.log("onChangeHandler", info.file, info.fileList);
    console.log("onChangeHandler", info.file);
    // console.log("onChangeHandler", info.fileList.length);
    try {
      const formData = new FormData();
      formData.append('upfile', info.file);
      setUploadFile(info.file.name);
      await putHRCostExcel(formData);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '上傳成功', err: "上傳成功" }));
    } catch (err) {
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '上傳失敗', err }));
      console.log(err);
    }
  }

  const uploadHandler = () => {
    console.log("uploadHandler");
  }

  const beforeUploadHandler = () => {
    return false;
  }

  const downloadHRCostHandler = async() => {
    console.log("downloadHRCostHandler config.startMonth:", moment(config.startMonth).startOf('month').format('YYYY-MM-DD'));
    console.log("downloadHRCostHandler config.startMonth:", moment(config.startMonth).endOf('month').format('YYYY-MM-DD'));
    try {
      const { data } = await getHRCostExcel({
        startDate: moment(config.startMonth).startOf('month').format('YYYY-MM-DD'),
        endDate: moment(config.endMonth).endOf('month').format('YYYY-MM-DD'),
      });
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'HRCost.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '下載失敗', err }));
      console.log(JSON.stringify(err));
    }
  }

  const startMonthChangeHandler = (value) => {
    const updateObj = { ...config };
    updateObj["startMonth"] = value;
    setConfig({ ...updateObj });
  };

  const endMonthChangeHandler = (value) => {
    const updateObj = { ...config };
    updateObj["endMonth"] = value;
    setConfig({ ...updateObj });
  };

  const onChangeHandlerForDelete = async(info) => {
    console.log("onChangeHandlerForDelete", info.file);
    try {
      const formData = new FormData();
      formData.append('upfile', info.file);
      setDeleteUploadFile(info.file.name);
      await deleteHRCostExcel(formData);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '刪除成功', err: "刪除成功" }));
    } catch (err) {
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '刪除失敗', err }));
      console.log(err);
    }
  }

  const beforeUploadHandlerForDelete = () => {
    return false;
  }

  return (
    <MainContent>
      <DivTableWrapper>
        <TableWrapper title = { "專案成本" }>
          <DivFlexRow>
            <DivItemWrapper>
              <PSubtitle>上傳專案成本</PSubtitle>
              <InputBasic
                disabled = { true }
                value = { uploadFile }
              />
            </DivItemWrapper>
            <DivItemWrapper>
              <Upload
                multiple = { false }
                maxCount = { 1 }
                showUploadList = { false }
                onChange = { onChangeHandler }
                action = { uploadHandler }
                beforeUpload = { beforeUploadHandler }
              >
                <ButtonDownload>新增專案成本</ButtonDownload>
              </Upload>
            </DivItemWrapper>
          </DivFlexRow>
          <DivFlexRow>
            <DivItemWrapper>
              <PSubtitle>下載專案成本</PSubtitle>
              <DateSelectorTwo
                value = { config.startMonth }
                picker = "month"
                changeHandler = { startMonthChangeHandler }
              />
              &nbsp;&nbsp;
              <DateSelectorTwo
                value = { config.endMonth }
                picker = "month"
                changeHandler = { endMonthChangeHandler }
              />
            </DivItemWrapper>
            <DivItemWrapper>
              <ButtonDownload
                onClick = { downloadHRCostHandler }
              >下載</ButtonDownload>
            </DivItemWrapper>
          </DivFlexRow>
          <DivFlexRow>
            <DivItemWrapper>
              <PSubtitle>刪除專案成本</PSubtitle>
              <InputBasic
                disabled = { true }
                value = { deleteUploadFile }
              />
            </DivItemWrapper>
            <DivItemWrapper>
              <Upload
                multiple = { false }
                maxCount = { 1 }
                showUploadList = { false }
                onChange = { onChangeHandlerForDelete }
                beforeUpload = { beforeUploadHandlerForDelete }
              >
                <ButtonDownload>刪除專案成本</ButtonDownload>
              </Upload>
            </DivItemWrapper>
          </DivFlexRow>
        </TableWrapper>
      </DivTableWrapper>
      {
        showSaveFailPopup &&
          <PopupExecuteResult
            title = { apiFailPopupTitle }
            text = { apiFailPopupMessage }
            confirmHandler = { () => {
              dispatch(CLOSE_SAVE_FAIL_POPUP());
            }}
          />
      }
    </MainContent>
  );
};

export default ProjectCost;
