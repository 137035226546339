import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

import {
  deleteCustomers,
  postToGetCustomerDetail,
  putModifyCustomerDetail,
  postToGetCustomerWindows,
} from '@/api/customerService';

import { FETCH_CUSTOMER_INDUSTRIES } from '@/store/actions/srpOptionsAction';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import MainContent from '@/component-style/MainContent';
import TableContactWindows from '@/components/Table/TableContactWindows';
import PanelCustomerDetail from '@/components/Panel/PanelCustomerDetail';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import PopupExecuteConfirm from '@/components/UI/Popup/PopupExecuteConfirm';
import { ButtonBasic, ButtonCancel } from '@/component-style/Button';
import { DivFlexRow } from '@/component-style/RWDWrapper';

import { customerConfig } from '@/constant/dataConfig/informationModule';

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding-right: 0;
  padding-left: 135px;
  padding-bottom: 30px;
  justify-content: flex-end;

  @media (max-width: 425px) {
    justify-content: center;
    padding-left: 0;
  }
`;

const ButtonSave = styled(ButtonBasic)`
  width: 120px;
  margin-right: 10px;
  margin-bottom: 10px;
`;

const ButtonClear = styled(ButtonCancel)`
  width: 120px;
  margin-bottom: 10px;
`;

const TableContainer = styled.div`
  margin-bottom: 40px;
`;

const CustomerDetail = props => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const informationRoutes = useSelector(state => state.login.informationRoutes);
  const customerIndustries = useSelector(state => state.srpOptions.customerIndustries);
  const showSaveFailPopup = useSelector(state => state.apiFail.showSaveFailPopup);
  const apiFailPopupTitle = useSelector(state => state.apiFail.title);
  const apiFailPopupMessage = useSelector(state => state.apiFail.message);

  const [customer, setCustomer] = useState({
    ...customerConfig,
    customerID: '',
    industry: [],
    partnership: { ...customerConfig.partnership },
    contactAddress: { ...customerConfig.contactAddress },
    invoiceAddress: { ...customerConfig.invoiceAddress },
  });

  const [contactWindows, setContactWindows] = useState([]);
  const [windowsCount, setWindowsCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [isEditing, setIsEditing] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [showSaveSuccessPopup, setShowSaveSuccessPopup] = useState(false);
  const [showDeleteSuccessPopup, setShowDeleteSuccessPopup] = useState(false);

  const userEditable = informationRoutes.indexOf('CustomerDetail-Edit') !== -1;
  const userDeletable = informationRoutes.indexOf('CustomerDetail-Delete') !== -1;

  const updateCustomerHandler = (key, value) => {
    const updateObj = { ...customer };
    updateObj[key] = value;
    setCustomer({...updateObj});
  };

  const updatePartnershipHandler = (item, value) => {
    const updatePartnership = { ...customer.partnership };
    updatePartnership[item] = value;
    updateCustomerHandler('partnership', { ...updatePartnership });
  };

  const closeSuccessPopupHandler = () => {
    setIsEditing(false);
    setShowSaveSuccessPopup(false);
  };

  const setCurrentPageHandler = page => {
    setCurrentPage(page);
    updateContactWindows(page);
  };

  const updateCustomerData = async () => {
    setShowLoadingPopup(true);

    try {
      await putModifyCustomerDetail(customer)
      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '更新失敗', err }));
      console.log(err);
    }
  };

  const updateContactWindows = useCallback(
    async (page = currentPage) => {
      setShowLoadingPopup(true);

      try {
        const { data } = await postToGetCustomerWindows({
          page,
          amount: 10,
          customerID: id,
        });
        const { contactWindows, totalCount } = data;
        setContactWindows([...contactWindows]);
        setWindowsCount(totalCount);
      } catch(err) { console.log(err) }

      setShowLoadingPopup(false);
    },
    [currentPage, id],
  );

  const deleteCustomersHandler = async () => {
    setShowDeletePopup(false);
    setShowLoadingPopup(true);

    try {
      await deleteCustomers([customer.customerID]);

      setShowLoadingPopup(false);
      setShowDeleteSuccessPopup(true);
    } catch(err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '刪除失敗', err }));
      console.log(err);
    }
  };

  const fetchCustomerDetail = async () => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetCustomerDetail(id);
      const { contactAddress, invoiceAddress, partnership, ...remainItems } = data;

      setCustomer(prevState => ({
        ...prevState,
        ...remainItems,
        contactAddress: {
          ...prevState.contactAddress,
          ...contactAddress,
        },
        invoiceAddress: {
          ...prevState.invoiceAddress,
          ...invoiceAddress,
        },
        partnership: {
          ...prevState.partnership,
          ...partnership,
          startDate: data.partnership.startDate === '0001-01-01'
            ? null : data.partnership.startDate,
          endDate: data.partnership.endDate === '0001-01-01'
            ? null : data.partnership.endDate,
        },
      }));

    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const initCustomerData = useCallback(
    fetchCustomerDetail, []);
  
  const initContactWindows = useCallback(
    updateContactWindows, []);

  const initOptions = useCallback(() => {
    dispatch(FETCH_CUSTOMER_INDUSTRIES());
  }, [dispatch]);

  useEffect(() => {
    initOptions();
    initCustomerData();
    initContactWindows();
  }, [initContactWindows, initCustomerData, initOptions]);

  return (
    <MainContent>
      <TableContainer>
        <TableContactWindows
          windowType = "Customer"
          contactWindows = { contactWindows }
          totalCount = { windowsCount }
          currentPage = { currentPage }
          pageChangedHandler = { value => {
            setCurrentPageHandler(value);
          }}
        />
      </TableContainer>
      <PanelCustomerDetail
        disabled = { isEditing === false }
        title = "客戶資料明細"
        customer = { customer }
        customerIndustries = { customerIndustries }
        updateCustomerHandler = { updateCustomerHandler }
        updatePartnershipHandler = { updatePartnershipHandler }
        copyAddressHandler = { () => {
          updateCustomerHandler(
            'invoiceAddress',
            { ...customer.contactAddress }
          );
        }}
      >
        <DivFlexRow>
          <DivButtonsWrapper>
            {
              isEditing
                ? <ButtonSave
                    onClick = { updateCustomerData }
                  >儲存</ButtonSave>
                : <ButtonSave
                    disabled = { !userEditable }
                    onClick = { () => { setIsEditing(true) } }
                  >編輯</ButtonSave>
            }
            <ButtonSave
              disabled = { !userDeletable }
              onClick = { () => {
                setShowDeletePopup(true);
              }}
            >刪除</ButtonSave>
            <ButtonClear
              onClick = { () => { history.goBack() } }
            >離開</ButtonClear>
          </DivButtonsWrapper>
        </DivFlexRow>
      </PanelCustomerDetail>
      { showLoadingPopup && <PopupLoading/> }
      {
        showDeletePopup &&
          <PopupExecuteConfirm
            title = "刪除客戶名單"
            text = "確定刪除此客戶資料"
            confirmHandler = { deleteCustomersHandler }
            cancelHandler = { () => {
              setShowDeletePopup(false);
            }}
          />
      }
      {
        showSaveSuccessPopup &&
          <PopupExecuteResult
            title = "更新成功"
            confirmHandler = { closeSuccessPopupHandler }
          />
      }
      {
        showDeleteSuccessPopup &&
          <PopupExecuteResult
            title = "刪除成功"
            confirmHandler = { () => {
              history.goBack();
            }}
          />
      }
      {
        showSaveFailPopup &&
          <PopupExecuteResult
            title = { apiFailPopupTitle }
            text = { apiFailPopupMessage }
            confirmHandler = { () => {
              dispatch(CLOSE_SAVE_FAIL_POPUP());
            }}
          />
      }
    </MainContent>
  )
};

export default CustomerDetail;
