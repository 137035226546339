import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { putNewProjectTodo } from '@/api/projectTodoService';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import {
  FETCH_MEMBER_OPTIONS,
  FETCH_TODO_TYPE_OPTIONS,
  FETCH_PROJECT_ID_OPTIONS,
} from '@/store/actions/srpOptionsAction';

import MainContent from '@/component-style/MainContent';
import PanelScheduelDetail from '@/components/Panel/PanelScheduelDetail';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import { ButtonBasic, ButtonCancel } from '@/component-style/Button';
import { DivFlexRow } from '@/component-style/RWDWrapper';

import { projectTodoConfig } from '@/constant/dataConfig/projectModule';

const DivButtonsWrapper = styled(DivFlexRow)`
  padding: 0;
  padding-bottom: 30px;
  justify-content: flex-end;
  max-width: 1535px;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const Button = styled(ButtonBasic)`
  width: 120px;
  margin-right: 10px;
  margin-top: 10px;

  &:last-child {
    margin-right: 0;
  }

  @media (max-width: 425px) {
    &:nth-of-type(2) {
      margin-right: 0;
    }
  }
`;

const ButtonExit = styled(ButtonCancel)`
  width: 120px;
  margin-right: 10px;
  margin-top: 10px;

  &:last-child {
    margin-right: 0;
  }
`;


const ScheduleCreation = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const projectIDOptions = useSelector(state => state.srpOptions.projectIDOptions);
  const todoTypeOptions = useSelector(state => state.srpOptions.todoTypeOptions);
  const todoTagDictionary = useSelector(state => state.srpOptions.todoTagDictionary);
  const memberOptions = useSelector(state => state.srpOptions.memberOptions);
  const showSaveFailPopup = useSelector(state => state.apiFail.showSaveFailPopup);
  const apiFailPopupTitle = useSelector(state => state.apiFail.title);
  const apiFailPopupMessage = useSelector(state => state.apiFail.message);

  const [todoDetail, setTodoDetail] = useState({
    ...projectTodoConfig,
    displayType: { default: '', english: '' },
  });

  const [todoTagOptions, setTodoTagOptions] = useState([]);
  const [showPopupLoading,  setShowPopupLoading] = useState(false);
  const [showSaveSuccessPopup, setShowSaveSuccessPopup] = useState(false);

  const updateTodoDetail = (item, value) => {
    const updateObj = { ...todoDetail };
    updateObj[item] = value;
    setTodoDetail({ ...updateObj });
  };

  const todoTypeChangeHandler = todoTypeID => {
    setTodoDetail(prevState => ({
      ...prevState,
      todoTypeID,
      todoTag: '',
    }));
    setTodoTagOptions([...todoTagDictionary[todoTypeID]]);
  };

  const departmentChangedHandler = department => {
    setTodoDetail(prevState => ({
      ...prevState,
      department,
      userID: '',
    }));
  };

  const clearInputs = () => {
    setTodoDetail({
      ...projectTodoConfig,
      displayType: { default: '', english: '' },
    });
  };

  const addNewTodoHandler = async () => {
    setShowPopupLoading(true);

    try {
      await putNewProjectTodo({...todoDetail});
      setShowPopupLoading(false);
      setShowSaveSuccessPopup(true)
    } catch (err) {
      console.log(err);
      setShowPopupLoading(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '儲存失敗', err }));
    };
  };

  const initOptions = useCallback(() => {
    dispatch(FETCH_MEMBER_OPTIONS());
    dispatch(FETCH_TODO_TYPE_OPTIONS({ enable: true, enableTag: true }));
    dispatch(FETCH_PROJECT_ID_OPTIONS({ done: false }));
  }, [dispatch]);

  useEffect(() => {
    initOptions();
  }, [initOptions])

  return (
    <MainContent>
      <PanelScheduelDetail
        title = "新增專案進度"
        schedule = { todoDetail }
        projectIDOptions = { projectIDOptions }
        todoTypeOptions = { todoTypeOptions }
        memberOptions = { memberOptions }
        todoTagOptions = { todoTagOptions }
        updateScheduleHandler = { updateTodoDetail }
        todoTypeChangeHandler = { todoTypeChangeHandler }
        departmentChangedHandler = { departmentChangedHandler }
      >
        <DivFlexRow>
          <DivButtonsWrapper>
            <Button
              onClick = { addNewTodoHandler }
            >儲存</Button>
            <ButtonExit
              onClick = { clearInputs }
            >清除欄位</ButtonExit>
            <ButtonExit
              onClick = { () => { history.goBack() } }
            >離開</ButtonExit>
          </DivButtonsWrapper>
        </DivFlexRow>
      </PanelScheduelDetail>
      { showPopupLoading && <PopupLoading/> }
      {
        showSaveSuccessPopup &&
        <PopupExecuteResult
          title = "儲存成功"
          confirmHandler = {() => {
            setShowSaveSuccessPopup(false);
            clearInputs();
        }}
        />
      }
      {
        showSaveFailPopup &&
        <PopupExecuteResult
          title = { apiFailPopupTitle }
          text = { apiFailPopupMessage }
          confirmHandler = { () => {
            dispatch(CLOSE_SAVE_FAIL_POPUP());
          }}
        />
      }
    </MainContent>
  );
};

export default ScheduleCreation;
