import React from 'react';
// import { useSelector } from 'react-redux';
import styled from 'styled-components';
// import { Link } from 'react-router-dom';

import { TdItem, TableRow } from '@/component-style/Table';
import { ButtonGoDetail } from '@/component-style/Button';
// import { getOptionDesc } from '@/utils/textUtils';
import { decimalCommaConverter } from '@/utils/textUtils';

const TrWrapper = styled(TableRow)`
  border-bottom: solid 2px #f2f2f2;
  box-sizing: border-box;
  min-width: 760px;

  &:last-child {
    border-bottom: none;
  }
`;

const Td120 = styled(TdItem)`
  width: 120px;
`;
const Td160 = styled(TdItem)`
  width: 160px;
`;

const Td80 = styled(TdItem)`
  width: 80px;
`;

// const LinkElement = styled(Link)`
//   text-decoration: none;
//   cursor: pointer;
//   display: block;
//   margin-top: 10px;
//   height: 14px;
//   line-height: 14px;
//   font-size: 12px;
//   font-weight: 300;
//   letter-spacing: 0.4px;
//   text-align: center;
//   color: #909090;
// `;

const Item = props => {
  const { laborHealthInsuranceSetting } = props;
  // const directorOptions = useSelector(state => state.srpOptions.directorOptions);
  // const departmentOptions = useSelector(state => state.srpOptions.departmentOptions);

  // const director = getOptionDesc(directorOptions, user.director);
  // const department = getOptionDesc(departmentOptions, user.department);

  return (
    <TrWrapper>
      <Td160>{ decimalCommaConverter(laborHealthInsuranceSetting.insuranceGrade) }</Td160>
      <Td160>{ decimalCommaConverter(laborHealthInsuranceSetting.laborEmployerPay) }</Td160>
      <Td160>{ decimalCommaConverter(laborHealthInsuranceSetting.laborEmployeePay) }</Td160>
      <Td160>{ decimalCommaConverter(laborHealthInsuranceSetting.healthEmployerPay) }</Td160>
      <Td160>{ decimalCommaConverter(laborHealthInsuranceSetting.healthEmployeePay) }</Td160>
      <Td80>
        {/* <ButtonGoDetail
          onClick = { () => { props.editDetailHandler(laborHealthInsuranceSetting.insuranceGrade) } }
          // onClick = { () => { console.log("fdsaf"); } }
        >...
        </ButtonGoDetail> */}
        <ButtonGoDetail
          onClick = { () => { props.editDetailHandler(laborHealthInsuranceSetting.insuranceGrade) } }
        >...
        </ButtonGoDetail>
      </Td80>
    </TrWrapper>
  );
};

export default Item;
