import React, { useCallback, useEffect, useState, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';

import { postToGetProjectDetail } from '@/api/projectService';

import {
  postToGetExpenses,
  putModifyExpenseDetail,
  postToGetExpenseDetail,
} from '@/api/expenseService';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import {
  FETCH_MEMBER_OPTIONS,
  FETCH_VENDOR_ID_OPTIONS,
  FETCH_PROJECT_ID_OPTIONS,
} from '@/store/actions/srpOptionsAction';

import MainContent from '@/component-style/MainContent';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import PopupExecuteConfirm from '@/components/UI/Popup/PopupExecuteConfirm';
import TableExpenseApprovals from '@/components/Table/Expense/TableExpenseApprovals';

import {
  getNextApprovalState,
  getNextApprovalRecord,
} from '@/utils/expenseUtils';
import { toUTCTime } from '@/utils/timeUtils';

const ExpenseApproval = () => {
  const dispatch = useDispatch();
  const userID = useSelector(state => state.login.token);
  const projectRole = useSelector(state => state.login.projectRole);
  const expenseRoutes = useSelector(state => state.login.expenseRoutes);
  const showSaveFailPopup = useSelector(state => state.apiFail.showSaveFailPopup);
  const apiFailPopupTitle = useSelector(state => state.apiFail.title);
  const apiFailPopupMessage = useSelector(state => state.apiFail.message);

  const [expenses, setExpenses] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [selectAll, setSelectAll] = useState(false);
  const [showSubmitPopup, setShowSubmitPopup] = useState(false);
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [showSaveSuccessPopup, setShowSaveSuccessPopup] = useState(false);

  const userApprovable = expenseRoutes.indexOf('ExpenseApproval-Approve') !== -1;
  
  const selectedItemNumber = expenses
    .filter(expense => expense.isChecked).length;

  const setCurrentPageHandler = page => {
    setCurrentPage(page);
    fetchExpenses(page);
  };

  const expenseCheckedHandler = (index, value) => {
    const updateExpenses = [...expenses];
    updateExpenses[index].isChecked = value;
    setExpenses([...updateExpenses]);
  };

  const selectAllHandler = value => {
    setSelectAll(value);
    const updateExpenses = expenses
      .map(expense => ({ ...expense, isChecked: value }));
    setExpenses([...updateExpenses]);
  };

  const submitSuccessHandler = () => {
    setShowSaveSuccessPopup(false);
    fetchExpenses();
  };

  const submitExpensesHandler = async () => {
    setShowSubmitPopup(false);
    setShowLoadingPopup(true);

    try {
      const selectedExpenses = expenses
        .filter(expense => expense.isChecked);

      for (let i = 0; i < selectedExpenses.length; i += 1) {
        const { expenseID } = selectedExpenses[i];
        const { data } = await postToGetExpenseDetail(expenseID);

        const oldRecords = cloneDeep(data.records);
        const lastRecord = oldRecords.pop();
        const projectDetail = await postToGetProjectDetail(data.projectID);
        const { director } = projectDetail.data;
        const nextApprover = data.approvalStatus === 'projectManagerVerify'
          ? director : '';

        const records = [
          ...oldRecords,
          {
            time: toUTCTime(Date.now()),
            approval: userID,
            status: lastRecord.status.replace('審核中', '已同意'),
            suggestion: '本單為多筆快速送出',
          },
        ];

        await putModifyExpenseDetail({
          ...data,
          approvalStatus: getNextApprovalState(data.approvalStatus),
          records: data.approvalStatus === 'generalManagerVerifying'
            ? [...records]
            : [...records, getNextApprovalRecord(data.approvalStatus)(nextApprover)],
        });
      }

      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '審核失敗', err }));
      console.log(err);
    }
  };

  const fetchExpenses = async (page = currentPage) => {
    setShowLoadingPopup(true);
    const isDirector = projectRole.indexOf('director') !== -1;
    const isGM = projectRole.indexOf('generalManager') !== -1;
    const isPM = projectRole.indexOf('projectManager') !== -1;
    const approvalStatus = [];

    isGM && approvalStatus.push('generalManagerVerifying');
    isPM && approvalStatus.push('projectManagerVerify');
    isDirector && approvalStatus.push('projectDirectorVerify');

    try {
      var searchConfig = {
        approvalStatus,
        amount: 10,
        page,
        managedBy: userID,
        // director: isDirector && !isGM ? userID : null,
        // projectManager: isPM && !isGM ? userID : null,
      };
      if (isGM) {
        delete searchConfig["managedBy"];
      }
      const { data } = await postToGetExpenses(searchConfig);

      const { expenses, totalCount } = data;
      const expenseForms = [];

      for (let i = 0; i < expenses.length; i += 1) {
        const expense = expenses[i];
        const { projectID, applyDate } = expense;
        const { data } = await postToGetProjectDetail(projectID);
        const { projectCode, projectName } = data;
        expenseForms.push({
          ...expense,
          projectCode,
          projectName,
          applyDate: applyDate === '0001-01-01' ? '' : applyDate,
          isChecked: false,
        });
      };

      setExpenses([...expenseForms]);
      setTotalCount(totalCount || 0);
    } catch(err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const initExpenses = useCallback(
    fetchExpenses, []);

  const initOptions = useCallback(() => {
    dispatch(FETCH_MEMBER_OPTIONS());
    dispatch(FETCH_VENDOR_ID_OPTIONS());
    dispatch(FETCH_PROJECT_ID_OPTIONS());
  }, [dispatch]);

  useEffect(() => {
    initOptions();
    initExpenses();
  }, [initExpenses, initOptions]);

  return (
    <MainContent>
      <TableExpenseApprovals
        disableSubmit = { selectedItemNumber === 0 || !userApprovable }
        expenses = { expenses }
        totalCount = { totalCount }
        currentPage = { currentPage }
        selectAll = { selectAll }
        selectAllHandler = { selectAllHandler }
        checkHandler = { expenseCheckedHandler }
        submitHandler = { () => {
          setShowSubmitPopup(true);
        }}
        pageChangedHandler = { value => {
          setCurrentPageHandler(value);
        }}
      />
      { showLoadingPopup && <PopupLoading/> }
      {
        showSubmitPopup &&
          <PopupExecuteConfirm
            title = "支出憑單"
            text = { `確定簽核${ selectedItemNumber }個項目?` }
            confirmHandler = { submitExpensesHandler }
            cancelHandler = { () => {
              setShowSubmitPopup(false);
            }}
          />
      }
      {
        showSaveSuccessPopup &&
          <PopupExecuteResult
            title = "審核成功"
            confirmHandler = { submitSuccessHandler }
          />
      }
      {
        showSaveFailPopup &&
          <PopupExecuteResult
            title = { apiFailPopupTitle }
            text = { apiFailPopupMessage }
            confirmHandler = { () => {
              dispatch(CLOSE_SAVE_FAIL_POPUP());
            }}
          />
      }
    </MainContent>
  )
};

export default ExpenseApproval;
