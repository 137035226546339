import * as types from '../action-types/loginActionTypes';

export const LOGIN = loginConfig => ({
  type: types.LOGIN,
  payload: loginConfig,
});

export const LOGOUT = () => ({
  type: types.LOGOUT,
});

export const LOGIN_START = () => ({
  type: types.LOGIN_START,
});

export const LOGIN_FAIL = errorMsg => ({
  type: types.LOGIN_FAIL,
  payload: errorMsg,
});

export const LOGIN_SUCCESS = userInfo => ({
  type: types.LOGIN_SUCCESS,
  payload: userInfo,
});

export const AZURE_LOGIN_START = () => ({
  type: types.AZURE_LOGIN_START,
});

export const AZURE_LOGIN_SUCCESS = () => ({
  type: types.AZURE_LOGIN_SUCCESS,
});

export const AZURE_LOGIN_FAIL = () => ({
  type: types.AZURE_LOGIN_FAIL,
});

export const AZURE_SYNC_START = () => ({
  type: types.AZURE_SYNC_START,
});

export const AZURE_SYNC_SUCCESS = () => ({
  type: types.AZURE_SYNC_SUCCESS,
});

export const AZURE_SYNC_FAIL = () => ({
  type: types.AZURE_SYNC_FAIL,
});

export const CLOSE_AZURE_SYNC_POP = () => ({
  type: types.CLOSE_AZURE_SYNC_POP,
});
