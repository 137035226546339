import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { cloneDeep } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons';

import SelectNormal from '@/components/UI/Select/SelectNormal';
import DateSelector from '@/components/UI/DateSelector';
import { InputBasic } from '@/component-style/Input';
import { ButtonCancel, ButtonBasic } from '@/component-style/Button';
import { PSubtitle } from '@/component-style/TextElement';
import RemoveImg from '@/assets/images/icon_remove.svg';

import { vendorInvoiceTypes } from '@/constant/options';
import { integerConverter } from '@/utils/textUtils';
import { getUntaxedPrice } from '@/utils/mathUtils';
import { isTaxIDValid } from '@/utils/expenseUtils';
import { formatTime } from '@/utils/timeUtils';

import {
  postToGetExpenseTags,
} from '@/api/expenseService';

import MyInputNumber from '@/components/UI/MyInputNumber';

const DivFWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-bottom: dashed 2px #e5e5e5;
`;

const InputWrapper = styled.div`
  margin-right: 20px;
  margin-bottom: 10px;

  @media (max-width: 375px) {
    margin-right: 0;
  }

  @media (min-width: 1920px) {
    .ant-select, .datePicker { width: 300px; }
  }

  @media (max-width: 320px) {
    .ant-select, .datePicker { width: 260px; }
  };
`;

const Input = styled(InputBasic)`
  @media (max-width: 320px) { width: 260px; }
  @media (min-width: 1920px) { width: 300px }
`;

const DivIconRemove = styled.div`
  cursor: pointer;
  margin-top: 20px;
  width: 20px;
  height: 40px;
  background-image: url(${ props => props.url });
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`;

const ButtonFilesUpload = styled(ButtonBasic)`
  width: 120px;
  margin-top: 20px;
  margin-right: 20px;
  margin-bottom: 10px;

  svg { margin-left: 5px }
`;

const ButtonFilesEmpty = styled(ButtonCancel)`
  width: 120px;
  margin-top: 20px;
  margin-right: 20px;
  margin-bottom: 10px;
`;

const PLabel = styled(PSubtitle)`
  width: auto;
  height: 20px;
  line-height: 20px;

  span { color: #de2027 }
`;

const PRequired = styled.p`
  display: inline;
  color:red;
`;

const voucherCodeDict = {
  backup: '後補',
  taxedVoucher: '收據',
  invoice: '票根',
  receipt: '收據',
  certificate: '證明單',
  handlingFee: '手續費',
  service: '勞務單',
  note: '折讓單',
  others: '其他',
};

const ItemVoucherInfo = props => {
  const { voucher, projectName } = props;
  // console.log("projectName:" + projectName + " voucher.type:" + voucher.type + " voucher.tag:" + voucher.tag);
  const expenseTypeOptions = useSelector(state => state.srpOptions.expenseTypeOptions);
  // const expenseTagDictionary = useSelector(state => state.srpOptions.expenseTagDictionary);
  // console.log("expenseTagDictionary:" + JSON.stringify(expenseTagDictionary));
  const [expenseTypeId, setExpenseTypeId] = useState(voucher.type);
  const [expenseTagByExpenseType, setExpenseTagByExpenseType] = useState([]);

  const isVoucherCodeError = voucher.voucherType === 'taxable'
    && !(voucher.voucherCode === undefined ? '' : voucher.voucherCode)
      .match(/^[A-Z]{2}\d{8}/)

  useEffect(() => {
    // console.log("useEffect");
    // console.log("useEffect expenseId:" + expenseTypeId);
    // console.log("useEffect projectName:" + projectName);
    (async () => {
      const { data } = await postToGetExpenseTags({
        expenseTypeID: expenseTypeId, page: 1, amount: 1000, 
      });
      // console.log("useEffect data.expenseTags:" + JSON.stringify(data.expenseTags));
      var sortedTags = [];
      sortedTags.push({'value': '', 'desc': '請選擇會計小分類'});
      for (var i = 0; i < data.expenseTags.length; i++) {
        if (projectName.toUpperCase().startsWith('VEDA') || projectName.toUpperCase().startsWith('KM') || projectName.toUpperCase().startsWith('EREADY') || projectName.toUpperCase().startsWith('AMOTOR')) {
          // console.log("useEffect projectName:" + projectName);
          if (data.expenseTags[i].expenseCode.startsWith('6') || data.expenseTags[i].expenseCode.startsWith('1') || data.expenseTags[i].expenseCode.startsWith('2') || data.expenseTags[i].expenseCode.startsWith('4') || data.expenseTags[i].expenseCode.startsWith('7')) {
            sortedTags.push({'value': data.expenseTags[i].name, 'desc': data.expenseTags[i].name});
          }
        } else {
          if (data.expenseTags[i].projectCode.startsWith('5') || data.expenseTags[i].projectCode.startsWith('1') || data.expenseTags[i].projectCode.startsWith('2') || data.expenseTags[i].projectCode.startsWith('4') || data.expenseTags[i].expenseCode.startsWith('7')) {
            sortedTags.push({'value': data.expenseTags[i].name, 'desc': data.expenseTags[i].name});
          }
        }
      }
      setExpenseTagByExpenseType(sortedTags);
    })()
  }, [expenseTypeId, projectName]);
  
  const updateVoucherHandler = (key, value) => {
    const updateObj = cloneDeep(voucher);
    if (key === "voucherCode") {
      value = value.toUpperCase();
    }
    updateObj[key] = value;
    props.voucherChangeHandler(updateObj);
  };

  const voucherTypeChangeHandler = voucherType => {
    const voucherCode = voucherCodeDict[voucherType] === undefined
      ? '' : voucherCodeDict[voucherType];

    const taxRate = voucherType === 'taxable'
      || voucherType === 'taxedVoucher' ? 5 : 0;
    
    const untaxedAmount = getUntaxedPrice(integerConverter(voucher.amount), taxRate);
    const tax = integerConverter(voucher.amount) - untaxedAmount;

    props.voucherChangeHandler({
      ...voucher,
      voucherType,
      voucherCode,
      untaxedAmount,
      tax,
    });
  };

  const amountChangeHandler = amount => {
    const taxRate = voucher.voucherType === 'taxable'
      || voucher.voucherType === 'taxedVoucher' ? 5 : 0;

    const untaxedAmount = getUntaxedPrice(integerConverter(amount), taxRate);
    const tax = integerConverter(amount) - untaxedAmount;
    amount = integerConverter(amount);

    props.voucherChangeHandler({
      ...voucher,
      tax,
      amount,
      untaxedAmount,
    });
  };

  const typeChangeHandler = type => {
    // console.log("typeChangeHandler type:" + type);
    setExpenseTypeId(type);
    props.voucherChangeHandler({
      ...voucher,
      type,
      tag: '',
    });
  };

  // const fileUploadButton = voucher.isFileUpload
  const fileUploadButton = props.isFileUpload
    ? <ButtonFilesUpload
        onClick = { props.fileManageHandler }
      >附件管理
        <FontAwesomeIcon icon = { faFolderOpen }/>
      </ButtonFilesUpload>
    : <ButtonFilesEmpty
        onClick = { props.fileManageHandler }
      >附件管理</ButtonFilesEmpty>

  // const voucherUploadButton = voucher.isVoucherUpload
  const voucherUploadButton = props.isVoucherUpload
    ? <ButtonFilesUpload
        onClick = { props.voucherManageHandler }
      >*單據管理
        <FontAwesomeIcon icon = { faFolderOpen }/>
      </ButtonFilesUpload>
    : <ButtonFilesEmpty
        onClick = { props.voucherManageHandler }
      ><PRequired>*</PRequired>單據管理</ButtonFilesEmpty>

  return (
    <DivFWrapper>
      <InputWrapper>
        <PLabel>
          單據種類
          <span>*</span>
        </PLabel>
        <SelectNormal
          disabled = { props.disabled }
          value = { voucher.voucherType }
          options = { vendorInvoiceTypes }
          changeHandler = { voucherTypeChangeHandler }
        />
      </InputWrapper>
      <InputWrapper>
        <PLabel>
          單據日期
          <span>*</span>
        </PLabel>
        <DateSelector
          disabled = { props.disabled }
          value = { voucher.date }
          changeHandler = { value => {
            updateVoucherHandler(
              'date',
              formatTime(value, 'YYYY-MM-DD')
            );
          }}
        />
      </InputWrapper>
      <InputWrapper>
        <PLabel>
          單據號碼
          <span>
            *
            { isVoucherCodeError && ' (格式錯誤)(BB88888888)' }
          </span>
        </PLabel>
        <Input
          disabled = { props.disabled }
          value = { voucher.voucherCode }
          onChange = {({ target }) => {
            updateVoucherHandler('voucherCode', target.value);
          }}
        />
      </InputWrapper>
      <InputWrapper>
        <PLabel>
          廠商統編
          {
            voucher.voucherType === 'taxable' &&
              <span>*
                {
                  !isTaxIDValid(voucher.taxID) && ' (格式錯誤)(88888888)'
                }
              </span>
          }
        </PLabel>
        <Input
          disabled = { props.disabled }
          value = { voucher.taxID }
          onChange = {({ target }) => {
            updateVoucherHandler('taxID', target.value);
          }}
        />
      </InputWrapper>
      <InputWrapper>
        <PLabel>
          未稅金額
          <span>*</span>
        </PLabel>
        <MyInputNumber
          disabled = { props.disabled }
          value = { voucher.untaxedAmount }
          onChange = { value => {
            updateVoucherHandler('untaxedAmount', integerConverter(value));
          }}
        />
        {/* <Input
          type = "number"
          disabled = { props.disabled }
          value = { voucher.untaxedAmount }
          onChange = {({ target }) => {
            updateVoucherHandler('untaxedAmount', integerConverter(target.value));
          }}
        /> */}
      </InputWrapper>
      <InputWrapper>
        <PLabel>
          稅額
          <span>*</span>
        </PLabel>
        <MyInputNumber
          disabled = { props.disabled }
          value = { voucher.tax }
          onChange = { value => {
            updateVoucherHandler('tax', integerConverter(value));
          }}
        />
        {/* <Input
          type = "number"
          disabled = { props.disabled }
          value = { voucher.tax }
          onChange = {({ target }) => {
            updateVoucherHandler('tax', integerConverter(target.value));
          }}
        /> */}
      </InputWrapper>
      <InputWrapper>
        <PLabel>
          請款金額
          <span>*</span>
        </PLabel>
        <MyInputNumber
          disabled = { props.disabled }
          value = { voucher.amount }
          onChange = { value => {
            amountChangeHandler(integerConverter(value));
          }}
        />
        {/* <Input
          type = "number"
          disabled = { props.disabled || props.disableAmount }
          value = { voucher.amount }
          onChange = {({ target }) => {
            amountChangeHandler(integerConverter(target.value));
          }}
        /> */}
      </InputWrapper>
      <InputWrapper>
        <PLabel>外匯匯率</PLabel>
        <Input
          type = "number"
          disabled = { props.disabled }
          value = { voucher.exchangeRate }
          onChange = {({ target }) => {
            updateVoucherHandler('exchangeRate', target.value);
          }}
        />
      </InputWrapper>
      <InputWrapper>
        <PLabel>
          申請說明
          <span>*</span>
        </PLabel>
        <Input
          value = { voucher.note }
          disabled = { props.disabled }
          onChange = {({ target }) => {
            updateVoucherHandler('note', target.value);
          }}
        />
      </InputWrapper>
      <InputWrapper>
        <PLabel>
          會計大分類
          <span>*</span>
        </PLabel>
        <SelectNormal
          disabled = { props.disabled }
          value = { voucher.type }
          options = {[
            { value: '', desc: '請選擇會計大分類' },
            ...expenseTypeOptions,
          ]}
          changeHandler = { typeChangeHandler }
        />
      </InputWrapper>
      <InputWrapper>
        <PLabel>
          會計小分類
          <span>*</span>
        </PLabel>
        <SelectNormal
          disabled = { props.disabled }
          value = { voucher.tag }
          // options = {[
          //   { value: '', desc: '請選擇會計小分類' },
          //   ...expenseTagOptions(voucher.type),
          // ]}
          options = { expenseTagByExpenseType }
          changeHandler = { value => {
            updateVoucherHandler('tag', value);
          }}
        />
      </InputWrapper>
      {
        !props.hideAttachmentButton && fileUploadButton
      }
      { voucherUploadButton }
      {
        props.removable && !props.disabled &&
          <DivIconRemove
            url = { RemoveImg }
            onClick = { props.removeHandler }
          />
      }
    </DivFWrapper>
  );
};

export default ItemVoucherInfo;