import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import {
  FETCH_MEMBER_OPTIONS,
  FETCH_TODO_TYPE_OPTIONS,
  FETCH_PROJECT_ID_OPTIONS,
} from '@/store/actions/srpOptionsAction';

import { postToGetProjectDetail } from '@/api/projectService';

import {
  deleteProjectTodos,
  putModifiedProjectTodo,
  postTodGetProjectTodoDetail,
} from '@/api/projectTodoService';

import MainContent from '@/component-style/MainContent';
import PanelScheduelDetail from '@/components/Panel/PanelScheduelDetail';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PopupExecuteConfirm from '@/components/UI/Popup/PopupExecuteConfirm';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import { ButtonBasic, ButtonCancel } from '@/component-style/Button';
import { DivFlexRow } from '@/component-style/RWDWrapper';

import { projectTodoConfig } from '@/constant/dataConfig/projectModule';
import { formatTime } from '@/utils/timeUtils';

const DivButtonsWrapper = styled(DivFlexRow)`
  padding: 0;
  padding-bottom: 30px;
  justify-content: flex-end;
  max-width: 1535px;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const Button = styled(ButtonBasic)`
  width: 120px;
  margin-right: 10px;
  margin-top: 10px;

  &:last-child {
    margin-right: 0;
  }
`;

const ButtonExit = styled(ButtonCancel)`
  width: 120px;
  margin-right: 10px;
  margin-top: 10px;

  &:last-child {
    margin-right: 0;
  }
`;

const ScheduleDetail = props => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const userID = useSelector(state => state.login.token);
  const projectRoutes = useSelector(state => state.login.projectRoutes);
  const memberOptions = useSelector(state => state.srpOptions.memberOptions);
  const todoTypeOptions = useSelector(state => state.srpOptions.todoTypeOptions);
  const projectIDOptions = useSelector(state => state.srpOptions.projectIDOptions);
  const todoTagDictionary = useSelector(state => state.srpOptions.todoTagDictionary);
  const showSaveFailPopup = useSelector(state => state.apiFail.showSaveFailPopup);
  const apiFailPopupTitle = useSelector(state => state.apiFail.title);
  const apiFailPopupMessage = useSelector(state => state.apiFail.message);

  const [isProjectOwner, setIsProjectOwner] = useState(false);

  const [todoDetail, setTodoDetail] = useState({
    ...projectTodoConfig,
    displayType: { default: '', english: '' },
  });

  const [isEditing, setIsEditing] = useState(false);
  const [todoTagOptions, setTodoTagOptions] = useState([]);
  const [showFinishPopup, setShowFinishPopup] = useState(false);
  const [showLoadingPopup,  setShowLoadingPopup] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState (false);
  const [showDeleteSuccessPopup, setShowDeleteSuccessPopup] = useState(false);
  const [showSaveSuccessPopup, setShowSaveSuccessPopup] = useState(false);

  const userEditable = projectRoutes.indexOf('ScheduleDetail-Edit') !== -1;
  const userDeletable = projectRoutes.indexOf('ScheduleDetail-Delete') !== -1;

  const updateTodoDetail = (item, value) => {
    const updateObj = { ...todoDetail };
    updateObj[item] = value;
    setTodoDetail({ ...updateObj });
  };

  const todoTypeChangeHandler = todoTypeID => {
    setTodoDetail(prevState => ({
      ...prevState,
      todoTypeID,
      todoTag: '',
    }));
    setTodoTagOptions([...todoTagDictionary[todoTypeID]]);
  };

  const departmentChangedHandler = department => {
    setTodoDetail(prevState => ({
      ...prevState,
      department,
      userID: '',
    }));
  };

  const todoFinishHandler = async () => {
    setShowFinishPopup(false);
    setShowLoadingPopup(true);
    setTodoDetail(prevState => ({
      ...prevState,
      finishedDate: formatTime(Date.now(), 'YYYY-MM-DD'),
    }));

    try {
      await putModifiedProjectTodo({
        ...todoDetail,
        finishedDate: formatTime(Date.now(), 'YYYY-MM-DD'),
      });
      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
    } catch(err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '儲存失敗', err }));
      console.log(err);
    }
  };

  const updateTodoDetailHandler = async () => {
    setShowLoadingPopup(true);

    try {
      await putModifiedProjectTodo({...todoDetail});
      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
    } catch(err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '儲存失敗', err }));
      console.log(err);
    }
  };

  const todoDeleteHandler = async () => {
    setShowDeletePopup(false);
    setShowLoadingPopup(true);

    try {
      await deleteProjectTodos([id]);
      setShowLoadingPopup(false);
      setShowDeleteSuccessPopup(true);
    } catch (err) {
      console.log(err);
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '刪除失敗', err }));
    }
  };

  const initOptions = useCallback(() => {
    dispatch(FETCH_MEMBER_OPTIONS());
    dispatch(FETCH_TODO_TYPE_OPTIONS());
    dispatch(FETCH_PROJECT_ID_OPTIONS());
  }, [dispatch]);

  const initData = useCallback(
    async () => {
      setShowLoadingPopup(true);
      try {
        const { data } = await postTodGetProjectTodoDetail(id);
        const projectDetail = await postToGetProjectDetail(data.projectID);
        const { director, projectManager } = projectDetail.data;
        const { estimatedDate, finishedDate } = data;
        setTodoDetail({
          ...data,
          estimatedDate: estimatedDate === '0001-01-01' ? null : estimatedDate,
          finishedDate: finishedDate === '0001-01-01' ? null : finishedDate,
        });
        setIsProjectOwner(userID === director || userID === projectManager);
      } catch (err) { console.log(err) }
      setShowLoadingPopup(false);
    }, [id, userID]);

  useEffect(() => {
    initOptions();
    initData();
  }, [initData, initOptions]);

  return (
    <MainContent>
      <PanelScheduelDetail
        title = "專案進度明細"
        disabled = { !isEditing }
        schedule = { todoDetail }
        projectIDOptions = { projectIDOptions }
        todoTypeOptions = { todoTypeOptions }
        memberOptions = { memberOptions }
        todoTagOptions = { todoTagOptions }
        updateScheduleHandler = { updateTodoDetail }
        todoTypeChangeHandler = { todoTypeChangeHandler }
        departmentChangedHandler = { departmentChangedHandler }
      >
        <DivFlexRow>
          <DivButtonsWrapper>
            {
              isEditing
                ? <Button
                    onClick = { updateTodoDetailHandler }
                  >儲存</Button>
                : <Button
                    disabled = { !userEditable && isProjectOwner}
                    onClick = { () => { setIsEditing(true) } }
                  >編輯</Button>
            }
            {
              isEditing === false &&
              userID === todoDetail.userID  &&
              todoDetail.finishedDate === null &&
                <Button
                  onClick = { () => { setShowFinishPopup(true) } }
                >完成</Button>
            }
            {
              isProjectOwner &&
                <ButtonExit
                  disabled = { !userDeletable }
                  onClick = { () => { setShowDeletePopup(true) } }
                >刪除</ButtonExit>
            }
            <ButtonExit
              onClick = { () => { history.goBack() } }
            >離開</ButtonExit>
          </DivButtonsWrapper>
        </DivFlexRow>
      </PanelScheduelDetail>
      { showLoadingPopup && <PopupLoading/> }
      {
        showDeletePopup &&
          <PopupExecuteConfirm
            title = "刪除專案進度"
            text = "是否確定刪除?"
            cancelHandler = { () => { setShowDeletePopup(false) } }
            confirmHandler = { todoDeleteHandler }
          />
      }
      {
        showFinishPopup &&
          <PopupExecuteConfirm
            title = "完成專案進度"
            text = "將以今天日期當作實際完成日，確認後將無法再修改，確認工作進度已完成?"
            cancelHandler = { () => { setShowFinishPopup(false) } }
            confirmHandler = { todoFinishHandler }
          />
      }
      {
        showDeleteSuccessPopup &&
          <PopupExecuteResult
            title = "刪除成功"
            confirmHandler = { () => { history.goBack() } }
          />
      }
      {
        showSaveSuccessPopup &&
          <PopupExecuteResult
            title = "儲存成功"
            confirmHandler = { () => {
              setShowSaveSuccessPopup(false);
              setIsEditing(false);
            }}
          />
      }
      {
        showSaveFailPopup &&
          <PopupExecuteResult
            title = { apiFailPopupTitle }
            text = { apiFailPopupMessage }
            confirmHandler = { () => {
              dispatch(CLOSE_SAVE_FAIL_POPUP());
            }}
          />
      }
    </MainContent>
  );
};

export default ScheduleDetail;
