const addressConfig = {
  country: 'taiwan',
  city: '',
  town: '',
  postal: '',
  address: '',
};

const businessPhone = {
  areaCode: '',
  number: '',
  extension: '',
};

export const customerConfig = {
  customerCode: '',
  isComparePrice: false,
  principal: '',
  customerName: '',
  customerTaxId: '',
  website: '',
  industry: [],
  contactAddress: { ...addressConfig },
  invoiceAddress: { ...addressConfig },
  partnership: {
    type: '',
    startDate: null,
    endDate: null,
    source: '',
    paymentDays: '',
    settlementDate: '',
    note: '',
  },
};

export const contactWindowConfig = {
  name: '',
  englishName: '',
  gender: '',
  employed: false,
  department: '',
  title: '',
  email1: '',
  email2: '',
  fax: '',
  businessMobile: '',
  privateMobile: '',
  birthday: null,
  facebook: '',
  lineId: '',
  note: '',
  businessPhone: { ...businessPhone },
  companyAddress: { ...addressConfig },
  privateAddress: { ...addressConfig },
};

export const mediaConfig = {
  mediaName: '',
  mediaEnglishName: '',
  inTwMedia: true,
  groupName: '',
  groupEnglishName: '',
  channelName: '',
  channelEnglishName: '',
  type: '',
  translateCenter: false,
  stopPublish: false,
  publishQty: 0,
  level: '',
  contactAddressEng: '',
  website: '',
  groupBackground: '',
  groupBackgroundEng: '',
  deadlineDesc: '',
  deadlineDescEng: '',
  contactAddress: { ...addressConfig },
};

export const venueConfig = {
  name: '',
  shutDown: false,
  nameEng: '',
  phone: '',
  website: '',
  address: { ...addressConfig },
  basicInfo: {
    name: '',
    type: '',
    note: '',
    roomInfo: '',
    avgCost: '',
    equipments: [],
    characteristics: [],
  },
  fees: {
    allDay: '',
    morning: '',
    afternoon: '',
    day: '',
    evenining: '',
    overtime: '',
    decorateAtNight: '',
  },
  spaceInfo: {
    floor: '',
    height: '',
    size: '',
    squareMeter: '',
  },
  capacity: {
    total: '',
    theater: '',
    horseshoe: '',
    cocktailParty: '',
    roundTable: '',
    smalllRoundTable: '',
    classroom: '',
    square: '',
  },
};

export const vendorConfig = {
  vendorName: '',
  available: true,
  financeApproved: false,
  password: '',
  taxId: '',
  principal: '',
  website: '',
  phone: '',
  services: '',
  qualification: '',
  invoiceType: '',
  credictCardOwner: '',
  note: '',
  tags: [],
  paymentMethods: [],
  transferInfo: {
    settleDays:'',
    bankName:'',
    branchName:'',
    accountName:'',
    accountId:'',
    payHandlingFee: false,
    handlingFee: '',
  },
  specialProperties: {
    personalService: false,
    transfer: false,
    foreigner: false,
    personalServiceDetail: {
      payTax: false,
      payPremium: false,
      identityType: '',
      nation: '',
      wageB: '',
      wageE: '',
      specialFeature: '',
    }
  },  
  contactAddress: { ...addressConfig },
  invoiceAddress: { ...addressConfig },
};

export const journalistConfig = {
  mediaID: '',
  name: '',
  gender: '',
  englishName: '',
  jobTitle: '',
  online: true,
  jobTitleEng: '',
  businessMobile: '',
  privateMobile: '',
  fax: '',
  email1: '',
  email2: '',
  area: '',
  page: '',
  facebook: '',
  lineId: '',
  skype: '',
  jobDesc: '',
  note: '',
  otherCommunicationApp: '',
  birthday: null,
  type: [],
  businessPhone: { ...businessPhone },
  companyAddress: { ...addressConfig },
  privateAddress: { ...addressConfig },
};

export const invitationStatusConfig = {
  unchecked: 0,
  statusYes: 0,
  maybe: 0,
  delegate: 0,
  depends: 0,
  thatDay: 0,
  notSure: 0,
  uncontacted: 0,
  statusNo: 0,
  later: 0,
};

export const invitationConfig = {
  projectID: '',
  journalistID: '',
  confirmStatus: '',
  statusDesc: '',
  job: '',
  attend: false,
};

export const reservationConfig = {
  venueID: '',
  projectID: '',
  favorablePrice: '',
  discountInfo: '',
  note: '',
  used: false,
  contact: {
    name: '',
    mobile: '',
    extension: '',
    email: '',
  },
  venueFees: {
    venue: {
      unit: '',
      unitPrice: '',
    },
    decorate: {
      unit: '',
      unitPrice: '',
    },
    overtime: {
      unit: '',
      unitPrice: '',
    },
  },
  reservations: [],
  mealFees: [],
  deviceFees: [],
};
