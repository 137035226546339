import React from 'react';
import styled from 'styled-components';

import { ButtonGoDetail } from '@/component-style/Button';
import { TdItem, TableRow } from '@/component-style/Table';

const TrWrapper = styled(TableRow)`
  border-bottom: solid 2px #f2f2f2;
  box-sizing: border-box;
  min-width: ${ props => props.showDetailButton
    ? '1050px'
    : '990px'
  };

  &:last-child {
    border-bottom: none;
  }
`;

const Td60 = styled(TdItem)`
  width: 60px;
`;

const Td70 = styled(TdItem)`
  width: 70px;
`;

const Td80 = styled(TdItem)`
  width: 80px;
`;

const Td120 = styled(TdItem)`
  width: 120px;
`;
const Td160 = styled(TdItem)`
  width: 160px;
`;

const Item = props => {
  const { projectCode, projectName, invitationStatus } = props;

  return (
    <TrWrapper
      showDetailButton = { props.showDetailButton }
    >
      <Td120>{ projectCode }</Td120>
      <Td160>{ projectName }</Td160>
      <Td70>{ invitationStatus.statusYes }</Td70>
      <Td70>{ invitationStatus.maybe }</Td70>
      <Td80>{ invitationStatus.delegate }</Td80>
      <Td80>{ invitationStatus.depends }</Td80>
      <Td70>{ invitationStatus.thatDay }</Td70>
      <Td60>{ invitationStatus.notSure }</Td60>	
      <Td80>{ invitationStatus.uncontacted }</Td80>	
      <Td60>{ invitationStatus.statusNo }</Td60>	
      <Td70>{ invitationStatus.later }</Td70>	
      <Td70>{ invitationStatus.unchecked }</Td70>
      {
        props.showDetailButton &&
        <Td60>
          <ButtonGoDetail
            onClick = { props.goDetailHandler }
          >...
          </ButtonGoDetail>
        </Td60>
      }
    </TrWrapper>
  );
};

export default Item;
