import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { postToGetVendorDetail } from '@/api/vendorService';
import { postToGetProjectDetail } from '@/api/projectService';
import { postToGetUploadFiles } from '@/api/storageService';

import {
  postToGetQuotations,
  postToGetQuotationDetail,
  putModifyQuotationDetail,
} from '@/api/expenseService';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import {
  FETCH_MEMBER_OPTIONS,
  FETCH_PROJECT_ID_OPTIONS,
} from '@/store/actions/srpOptionsAction';

import MainContent from '@/component-style/MainContent';
import PanelWrapper from '@/components/UI/PanelWrapper';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import PopupAttachmentList from '@/components/UI/Popup/PopupAttachmentList';
import TablePurchaseBargains from '@/components/Table/Finance/TablePurchaseBargains';
import SelectSearchable from '@/components/UI/Select/SelectSearchable';
import SearchButtonSet from '@/components/UI/SearchButtonSet';
import { PSubtitle } from '@/component-style/TextElement';
import {
  DivFlexRow,
  DivFullWidthWrapper,
  DivLongMultiSelector,
} from '@/component-style/RWDWrapper';

import { integerConverter } from '@/utils/textUtils';
import { searchConfigGenerator } from '@/utils/apiRequestUtils';
import { expenseQuotationConfig } from '@/constant/dataConfig/expenseModule';

const DivPanelContainer = styled.div`
  margin-bottom: 40px;
`;

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding: 0;
  justify-content: flex-end;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const PurchaseBargain = () => {
  const dispatch = useDispatch();
  const projectIDOptions = useSelector(state => state.srpOptions.projectIDOptions);
  const showSaveFailPopup = useSelector(state => state.apiFail.showSaveFailPopup);
  const apiFailPopupTitle = useSelector(state => state.apiFail.title);
  const apiFailPopupMessage = useSelector(state => state.apiFail.message);


  const [fileList, setFileList] = useState([]);
  const [quotations, setQuotations] = useState([]);

  const [searchConfig, setSearchConfig] = useState({
    projectID: '',
    minQuotedPrice: 100000,
  });

  const [alertText, setAlertText] = useState('');
  const [showAlertPopup, setShowAlertPopup] = useState(false);
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [showVouchersPopup, setShowVouchersPopup] = useState(false);
  const [showSaveSuccessPopup, setShowSaveSuccessPopup] = useState(false);

  const clearHandler = () => {
    setSearchConfig({
      projectID: '',
      minQuotedPrice: 100000,
    });
  };

  const editQuotationHandler = (value, index) => {
    const updateQuotations = [...quotations];
    updateQuotations[index].isEditing = value;

    setQuotations([...updateQuotations]);
  };

  const updatePriceHandler = (value, index) => {
    const updateQuotations = [...quotations];
    updateQuotations[index].price = value;

    setQuotations([...updateQuotations]);
  };

  const closeSuccessPopupHandler = () => {
    setShowSaveSuccessPopup(false);
    fetchQuotations();
  };

  const openAlertPopup = text => {
    setAlertText(text);
    setShowAlertPopup(true);
  };

  const openFilesPopupHandler = async id => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetUploadFiles('quotationVoucher', id);
      setFileList([ ...data ]);
      setShowVouchersPopup(true);
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const saveQuotationHandler = async index => {
    const { quotedPrice, price, quotationID } = quotations[index];

    if (integerConverter(price) === 0) {
      return openAlertPopup('議價後金額不可為0，請重新確認！');
    }

    if (integerConverter(price) >= quotedPrice) {
      return openAlertPopup('議價後金額須小於議價前金額，請重新確認！');
    }

    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetQuotationDetail(quotationID);
      await putModifyQuotationDetail({
        ...data,
        price: integerConverter(price),
      });
      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '議價失敗', err }));
      console.log(err);
    }
  };

  const fetchQuotations = async () => {
    setShowLoadingPopup(true);
    const quotationList = [];

    try {
      const { data } = await postToGetQuotations(
        searchConfigGenerator(searchConfig)
      );

      for (let i = 0; i < data.length; i += 1) {
        const { vendorID, projectID } = data[i];
        const projectDetail = await postToGetProjectDetail(projectID);
        const vendorDetail = await postToGetVendorDetail(vendorID);
        const { projectCode, projectName } = projectDetail.data;
        const { vendorName } = vendorDetail.data;

        quotationList.push({
          ...expenseQuotationConfig,
          ...data[i],
          projectCode,
          projectName,
          vendorName,
          isEditing: false,
        });
      }

      setQuotations([...quotationList]);
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const initQuotations = useCallback(
    fetchQuotations, []);

  const initOptions = useCallback(() => {
    dispatch(FETCH_MEMBER_OPTIONS());
    dispatch(FETCH_PROJECT_ID_OPTIONS());
  }, [dispatch]);

  useEffect(() => {
    initOptions();
    initQuotations();
  }, [initOptions, initQuotations]);

  return(
    <MainContent>
      <DivPanelContainer>
        <PanelWrapper title = "專案採購議價">
          <DivFlexRow>
            <DivFullWidthWrapper>
              <PSubtitle>專案代號</PSubtitle>
              <DivLongMultiSelector>
                <SelectSearchable
                  value = { searchConfig.projectID }
                  options = {[
                    { value: '', desc: '請選擇專案代號' },
                    ...projectIDOptions,
                  ]}
                  changeHandler = { projectID => {
                    setSearchConfig(prevState => ({ ...prevState, projectID }))
                  }}
                />
              </DivLongMultiSelector>
            </DivFullWidthWrapper>
          </DivFlexRow>
          <DivFlexRow>
            <DivButtonsWrapper>
              <SearchButtonSet
                clearHandler = { clearHandler }
                searchHandler = { fetchQuotations }
              />
            </DivButtonsWrapper>
        </DivFlexRow>
        </PanelWrapper>
      </DivPanelContainer>
      <TablePurchaseBargains
        quotations = { quotations }
        updatePriceHandler = { updatePriceHandler }
        saveQuotationHandler = { saveQuotationHandler }
        editQuotationHandler = { editQuotationHandler }
        openFilesPopupHandler = { openFilesPopupHandler }
      />
      { showLoadingPopup && <PopupLoading/> }
      {
        showVouchersPopup &&
        <PopupAttachmentList
          title = "憑證管理"
          fileList = { fileList }
          uploadFiles = {[]}
          cancelHandler = { () => { setShowVouchersPopup(false) }}
        />
      }
      {
        showSaveSuccessPopup &&
          <PopupExecuteResult
            title = "議價成功"
            confirmHandler = { closeSuccessPopupHandler }
          />
      }
      {
        showAlertPopup &&
          <PopupExecuteResult
            title = "議價失敗"
            text = { alertText }
            confirmHandler = { () => { setShowAlertPopup(false) } }
          />
      }
      {
        showSaveFailPopup &&
          <PopupExecuteResult
            title = { apiFailPopupTitle }
            text = { apiFailPopupMessage }
            confirmHandler = { () => {
              dispatch(CLOSE_SAVE_FAIL_POPUP());
            }}
          />
      }
    </MainContent>
  );
};

export default PurchaseBargain;
