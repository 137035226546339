import * as types from '../action-types/srpOptionsActionTypes';

export const FETCH_CUSTOMER_OPTIONS = () => ({
  type: types.FETCH_CUSTOMER_OPTIONS,
});

export const FETCH_COMPANY_TYPE_OPTIONS = config => ({
  type: types.FETCH_COMPANY_TYPE_OPTIONS,
  payload: config,
});

export const FETCH_CONTACT_WINDOW_OPTIONS = ({ type, companyName }) => ({
  type: types.FETCH_CONTACT_WINDOW_OPTIONS,
  payload: { type, companyName },
});

export const FETCH_DEPARTMENT_OPTIONS = config => ({
  type: types.FETCH_DEPARTMENT_OPTIONS,
  payload: config,
});

export const FETCH_DIRECTOR_OPTIONS = () => ({
  type: types.FETCH_DIRECTOR_OPTIONS,
});

export const FETCH_MEMBER_OPTIONS = () => ({
  type: types.FETCH_MEMBER_OPTIONS,
});

export const FETCH_PROJECT_ID_OPTIONS = config => ({
  type: types.FETCH_PROJECT_ID_OPTIONS,
  payload: config,
});

export const FETCH_PROJECT_CODE_OPTIONS = () => ({
  type: types.FETCH_PROJECT_CODE_OPTIONS,
});

export const FETCH_PROJECT_TYPE_OPTIONS = config => ({
  type: types.FETCH_PROJECT_TYPE_OPTIONS,
  payload: config,
});

export const FETCH_PROJECT_MANAGER_OPTIONS = () => ({
  type: types.FETCH_PROJECT_MANAGER_OPTIONS,
});

export const FETCH_TODO_TYPE_OPTIONS = config => ({
  type: types.FETCH_TODO_TYPE_OPTIONS,
  payload: config,
});

export const FETCH_ACTIVITY_IDEA_TYPE_OPTIONS = config => ({
  type: types.FETCH_ACTIVITY_IDEA_TYPE_OPTIONS,
  payload: config,
});

export const FETCH_CUSTOMER_INDUSTRIES = filterConfig => ({
  type: types.FETCH_CUSTOMER_INDUSTRIES,
  payload: filterConfig,
});

export const FETCH_MEDIA_ID_OPTIONS = () => ({
  type: types.FETCH_MEDIA_ID_OPTIONS,
});

export const FETCH_MEDIA_TYPES = config => ({
  type: types.FETCH_MEDIA_TYPES,
  payload: config,
});

export const FETCH_VENDOR_ID_OPTIONS = config => ({
  type: types.FETCH_VENDOR_ID_OPTIONS,
  payload: config,
});

export const FETCH_VENDOR_TYPE_OPTIONS = config => ({
  type: types.FETCH_VENDOR_TYPE_OPTIONS,
  payload: config,
});

export const FETCH_VENUE_TYPE_OPTIONS = config => ({
  type: types.FETCH_VENUE_TYPE_OPTIONS,
  payload: config,
});

export const FETCH_VENUE_EQUIPMENTS = config => ({
  type: types.FETCH_VENUE_EQUIPMENTS,
  payload: config,
});

export const FETCH_VENUE_CHARACTERISTICS = config => ({
  type: types.FETCH_VENUE_CHARACTERISTICS,
  payload: config,
});

export const FETCH_JOURNALIST_TYPES = config => ({
  type: types.FETCH_JOURNALIST_TYPES,
  payload: config,
});

export const FETCH_JOURNALIST_ID_OPTIONS = config => ({
  type: types.FETCH_JOURNALIST_ID_OPTIONS,
  payload: config,
});

export const FETCH_EXPENSE_TYPE_OPTIONS = config => ({
  type: types.FETCH_EXPENSE_TYPE_OPTIONS,
  payload: config,
});

export const FETCH_SPECIAL_FEATURES = config => ({
  type: types.FETCH_SPECIAL_FEATURES,
  payload: config,
});

export const FETCH_DECLARE_TYPE_OPTIONS = config => ({
  type: types.FETCH_DECLARE_TYPE_OPTIONS,
  payload: config,
});

export const FETCH_WORKINGHOURS_TYPE_OPTIONS = config => ({
  type: types.FETCH_WORKINGHOURS_TYPE_OPTIONS,
  payload: config,
});

export const FETCH_SYSTEM_ROLE_OPTIONS = config => ({
  type: types.FETCH_SYSTEM_ROLE_OPTIONS,
  payload: config,
});

export const UPDATE_CUSTOMER_OPTIONS = options => ({
  type: types.UPDATE_CUSTOMER_OPTIONS,
  payload: options,
});

export const UPDATE_COMPANY_TYPE_OPTIONS = options => ({
  type: types.UPDATE_COMPANY_TYPE_OPTIONS,
  payload: options,
});

export const UPDATE_CONTACT_WINDOW_OPTIONS = options => ({
  type: types.UPDATE_CONTACT_WINDOW_OPTIONS,
  payload: options,
});

export const UPDATE_DEPARTMENT_OPTIONS = options => ({
  type: types.UPDATE_DEPARTMENT_OPTIONS,
  payload: options,
});

export const UPDATE_DIRECTOR_OPTIONS = options => ({
  type: types.UPDATE_DIRECTOR_OPTIONS,
  payload: options,
});

export const UPDATE_MEMBER_OPTIONS = options => ({
  type: types.UPDATE_MEMBER_OPTIONS,
  payload: options,
});

export const UPDATE_PROJECT_ID_OPTIONS = options => ({
  type: types.UPDATE_PROJECT_ID_OPTIONS,
  payload: options,
});

export const UPDATE_PROJECT_CODE_OPTIONS = options => ({
  type: types.UPDATE_PROJECT_CODE_OPTIONS,
  payload: options,
});

export const UPDATE_PROJECT_TYPE_OPTIONS = options => ({
  type: types.UPDATE_PROJECT_TYPE_OPTIONS,
  payload: options,
});

export const UPDATE_PROJECT_MANAGER_OPTIONS = options => ({
  type: types.UPDATE_PROJECT_MANAGER_OPTIONS,
  payload: options,
});

export const UPDATE_TODO_TYPE_OPTIONS = options => ({
  type: types.UPDATE_TODO_TYPE_OPTIONS,
  payload: options,
});

export const UPDATE_TODO_TAG_DICTIONARY = dictionary => ({
  type: types.UPDATE_TODO_TAG_DICTIONARY,
  payload: dictionary,
});

export const UPDATE_ACTIVITY_IDEA_TYPE_OPTIONS = options => ({
  type: types.UPDATE_ACTIVITY_IDEA_TYPE_OPTIONS,
  payload: options,
});

export const UPDATE_ACTIVITY_IDEA_TAG_DICTIONARY = dictionary => ({
  type: types.UPDATE_ACTIVITY_IDEA_TAG_DICTIONARY,
  payload: dictionary,
});

export const UPDATE_CUSTOMER_INDUSTRIES = industries => ({
  type: types.UPDATE_CUSTOMER_INDUSTRIES,
  payload: industries,
});

export const UPDATE_MEDIA_ID_OPTIONS = options => ({
  type: types.UPDATE_MEDIA_ID_OPTIONS,
  payload: options,
});

export const UPDATE_MEDIA_TYPES = options => ({
  type: types.UPDATE_MEDIA_TYPES,
  payload: options,
});

export const UPDATE_VENDOR_ID_OPTIONS = options => ({
  type: types.UPDATE_VENDOR_ID_OPTIONS,
  payload: options,
});

export const UPDATE_VENDOR_ID_OPTIONS_TAXID = options => ({
  type: types.UPDATE_VENDOR_ID_OPTIONS_TAXID,
  payload: options,
});

export const UPDATE_VENDOR_TYPE_OPTIONS = options => ({
  type: types.UPDATE_VENDOR_TYPE_OPTIONS,
  payload: options,
});

export const UPDATE_VENUE_TYPE_OPTIONS = options => ({
  type: types.UPDATE_VENUE_TYPE_OPTIONS,
  payload: options,
});

export const UPDATE_VENDOR_TAG_DICTIONARY = dictionary => ({
  type: types.UPDATE_VENDOR_TAG_DICTIONARY,
  payload: dictionary,
});

export const UPDATE_VENUE_EQUIPMENTS = equipments => ({
  type: types.UPDATE_VENUE_EQUIPMENTS,
  payload: equipments,
});

export const UPDATE_VENUE_CHARACTERISTICS = characteristics => ({
  type: types.UPDATE_VENUE_CHARACTERISTICS,
  payload: characteristics,
});

export const UPDATE_JOURNALIST_TYPES = options => ({
  type: types.UPDATE_JOURNALIST_TYPES,
  payload: options,
});

export const UPDATE_JOURNALIST_ID_OPTIONS = options => ({
  type: types.UPDATE_JOURNALIST_ID_OPTIONS,
  payload: options,
});

export const UPDATE_EXPENSE_TYPE_OPTIONS = options => ({
  type: types.UPDATE_EXPENSE_TYPE_OPTIONS,
  payload: options,
});

export const UPDATE_EXPENSE_TAG_DICTIONARY = dictionary => ({
  type: types.UPDATE_EXPENSE_TAG_DICTIONARY,
  payload: dictionary,
});

export const UPDATE_SPECIAL_FEATURES = options => ({
  type: types.UPDATE_SPECIAL_FEATURES,
  payload: options,
});

export const UPDATE_DECLARE_TYPE_OPTIONS = options => ({
  type: types.UPDATE_DECLARE_TYPE_OPTIONS,
  payload: options,
});

export const UPDATE_WORKINGHOURS_TYPE_OPTIONS = options => ({
  type: types.UPDATE_WORKINGHOURS_TYPE_OPTIONS,
  payload: options,
});

export const UPDATE_WORKINGHOURS_TAG_DICTIONARY = dictionary => ({
  type: types.UPDATE_WORKINGHOURS_TAG_DICTIONARY,
  payload: dictionary,
});

export const UPDATE_SYSTEM_ROLE_OPTIONS = options => ({
  type: types.UPDATE_SYSTEM_ROLE_OPTIONS,
  payload: options,
});
