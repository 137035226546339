import React from 'react';
import styled from 'styled-components';

import Header from './Header';
import Item from './Item';

import { Table, TableBody } from '@/component-style/Table';

const DivWrapper = styled.div`
  width: 100%;
  border-top: 2px solid #de2027;
  background-color: #ffffff;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  max-height: 280px;
  overflow-y: auto;
`;

const TableAttachmentList = props => {

  return (
    <DivWrapper>
      <Table>
        <Header
          fileDescTitle = { props.fileDescTitle }
        />
        <TableBody>
          {
            props.fileList.map(file =>
              <Item
                key = { file.fileID }
                name = { file.name }
                displayable = { props.displayable }
                fileChangable = { props.fileChangable }
                downloadURL = { file.downloadURL }
                displayFileHandler = { () => {
                  props.displayFileHandler(file);
                }}
                deleteFileHandler = { () => {
                  console.log("TableAttachmentList.js deleteFileHandler");
                  props.deleteFileHandler(file.fileID);
                }}
              />
            )
          }
        </TableBody>
      </Table>
    </DivWrapper>
  );
};

export default TableAttachmentList;
