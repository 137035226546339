import React from 'react';
import styled from 'styled-components';

import { TableHead, TableRow, ThItem } from '../../../component-style/Table';

const THead = styled(TableHead)`
  min-width: 940px;
`;

const Th80 = styled(ThItem)`
  width: 80px;
`;

const Th120 = styled(ThItem)`
  width: 120px;
`;

const Th300 = styled(ThItem)`
  width: 300px;
`;

const Header = props => {
  
  return (
    <THead>
      <TableRow>
        <Th120>專案代號</Th120>
        <Th120>大分類</Th120>
        <Th120>小分類</Th120>
        <Th300>內容</Th300>
        <Th120>預算</Th120>
        <Th80>執行</Th80>
        <Th80></Th80>
      </TableRow>
    </THead>
  );
};

export default Header;
