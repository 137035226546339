import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { cloneDeep } from 'lodash';

import { putNewSystemRole } from '@/api/authService';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import MainContent from '@/component-style/MainContent';
import PanelSystemRoleDetail from '@/components/Panel/Authority/PanelSystemRoleDetail';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import { ButtonBasic, ButtonCancel } from '@/component-style/Button';
import { DivFlexRow } from '@/component-style/RWDWrapper';

import { roleConfig } from '@/constant/dataConfig/authorityModule';

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding-right: 0;
  padding-left: 135px;
  padding-bottom: 30px;
  justify-content: space-between;

  @media (max-width: 425px) {
    justify-content: center;
    padding-left: 0;
  }
`;

const ButtonSave = styled(ButtonBasic)`
  width: 120px;
  margin-right: 10px;
  margin-bottom: 10px;
`;

const ButtonClear = styled(ButtonCancel)`
  width: 120px;
  margin-bottom: 10px;
`;

const RoleCreation = props => {
  const history = useHistory();
  const dispatch = useDispatch();
  const showSaveFailPopup = useSelector(state => state.apiFail.showSaveFailPopup);
  const apiFailPopupTitle = useSelector(state => state.apiFail.title);
  const apiFailPopupMessage = useSelector(state => state.apiFail.message);

  const [role, setRole] = useState(cloneDeep(roleConfig));
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [showSaveSuccessPopup, setShowSaveSuccessPopup] = useState(false);

  const unsavable = role.roleCode === ''
    || role.roleName === '';

  const updateRoleHandler = (key, value) => {
    const updateObj = cloneDeep(role);
    updateObj[key] = value;
    setRole({...updateObj});
  };

  const clearHandler = () => {
    setRole(cloneDeep(roleConfig));
  };

  const closeSuccessPopupHandler = () => {
    clearHandler();
    setShowSaveSuccessPopup(false);
  }

  const saveHandler = async () => {
    setShowLoadingPopup(true);

    try {
      await putNewSystemRole(role);
      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '新增失敗', err }));
      console.log(err);
    }
  };

  return (
    <MainContent>
      <PanelSystemRoleDetail
        title = "新增系統角色"
        role = { role }
        updateRoleHandler = { updateRoleHandler }
      >
        <DivFlexRow>
          <DivButtonsWrapper>
            <div>
              <ButtonSave
                disabled = { unsavable }
                onClick = { saveHandler }
              >儲存</ButtonSave>
              <ButtonClear
                onClick = { clearHandler }
              >清除欄位</ButtonClear>
            </div>
            <ButtonClear
              onClick = { () => { history.goBack() } }
            >離開</ButtonClear>
          </DivButtonsWrapper>
        </DivFlexRow>
      </PanelSystemRoleDetail>
      { showLoadingPopup && <PopupLoading/> }
      {
        showSaveSuccessPopup &&
          <PopupExecuteResult
            title = "新增成功"
            confirmHandler = { closeSuccessPopupHandler }
          />
      }
      {
        showSaveFailPopup &&
          <PopupExecuteResult
            title = { apiFailPopupTitle }
            text = { apiFailPopupMessage }
            confirmHandler = { () => {
              dispatch(CLOSE_SAVE_FAIL_POPUP());
            }}
          />
      }
    </MainContent>
  );
};

export default RoleCreation;
