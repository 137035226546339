import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { Table, TableBody } from '@/component-style/Table';
import { ButtonBasic } from '@/component-style/Button';
import { DivFlexRow } from '@/component-style/RWDWrapper';
import PageSelector from '@/components/UI/PageSelector';
import Header from './Header';
import Item from './Item';

const DivButtonsWrapper = styled(DivFlexRow)`
  padding: 0 30px;
  padding-bottom: 20px;
  justify-content: flex-end;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const DivWrapper = styled.div`
  width: 100%;
  border-top: 2px solid #de2027;
  background-color: #ffffff;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
`;

const TitleWrapper = styled.div`
  margin-top: 35px;
  margin-bottom: 20px;
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const H3Title = styled.h3`
  margin: 0;
  font-size: 20px;
  font-weight: normal;
  color: #57585a;
`;

const Button = styled(ButtonBasic)`
  width: 120px;
  height: 40px;
`;

const TableVendorTags = props => {
  const history = useHistory();
  const sysConfigRoutes = useSelector(state => state.login.sysConfigRoutes);
  const { tags } = props;

  const userAddable = sysConfigRoutes.indexOf('VendorTagManagement-Add') !== -1;
  const userDeletable = sysConfigRoutes.indexOf('VendorTagManagement-Delete') !== -1;

  return (
    <DivWrapper>
      <TitleWrapper>
        <H3Title>{ props.title || '中分類列表' }</H3Title>
        <Button
          disabled = { !userAddable }
          onClick = { props.addTagHandler }
        >新增</Button>
      </TitleWrapper>
      <Table>
        <Header/>
        <TableBody>
          {
            tags.map((tag, index) =>
              <Item
                tag = { tag }
                key = { index }
                deletable = { userDeletable }
                deleteHandler = { () => {
                  props.deleteHandler(index);
                }}
                showDetailHandler = { () => {
                  props.showDetailHandler(index);
                }}
              />
            )
          }
        </TableBody>
      </Table>
      <PageSelector
        current = { props.currentPage }
        total = { props.totalCount }
        pageChangedHandler = { props.pageChangedHandler }
      />
      <DivButtonsWrapper>
        <Button
          onClick = { () => { history.goBack() }}
        >離開</Button>
      </DivButtonsWrapper>
    </DivWrapper>
  );
};

export default TableVendorTags;
