import React from 'react';
import styled from 'styled-components';

import { TdItem, TableRow } from '../../../component-style/Table';
import { ButtonGoDetail } from '../../../component-style/Button';
import { getDateString, getTimeString } from '../../../utils/timeUtils';

const TrWrapper = styled(TableRow)`
  border-bottom: solid 2px #f2f2f2;
  box-sizing: border-box;
  min-width: 1020px;

  &:last-child {
    border-bottom: none;
  }
`;

const Td60 = styled(TdItem)`
  width: 60px;
`;

const Td120 = styled(TdItem)`
  width: 120px;
`;

const Td80 = styled(TdItem)`
  width: 80px;
`;

const Td160 = styled(TdItem)`
  width: 160px;
`;

const TdContact = styled(Td160)`
  flex-direction: column;
  align-items: flex-start;
`;

const TdReservation = styled(TdItem)`
  width: 200px;
  flex-direction: column;
  align-items: flex-start;
`;

const Item = props => {
  const { reservation } = props;

  const status = reservation.used ? '已使用' : '未使用';

  const reservationDict = {
    pending: '詢問中',
    waiting: '候補中',
    reserved: '已預留',
    canceled: '已取消',
  };

  const reservationStatus = reservation.reservations
    .map(({
      status, date, startTime, endTime
    }) => `${reservationDict[status]} ${getDateString(date)} ${getTimeString(startTime)} ~ ${getTimeString(endTime)}`);

  return (
    <TrWrapper>
      <Td160>{ reservation.name }</Td160>
      <Td120>{ reservation.room }</Td120>
      <TdReservation>
        { reservationStatus.map(status => <p key = { status }>{ status }</p>) }
      </TdReservation>
      <Td120>{ reservation.budget }</Td120>
      <Td80>{ status }</Td80>
      <TdContact>
        { reservation.contact.name !== '' && <p>聯絡人: { reservation.contact.name }</p>}
        { reservation.phone !== '' && <p>電話: { reservation.phone }</p> }
        { reservation.contact.extension !== '' && <p>分機: { reservation.contact.extension }</p>}
        { reservation.contact.mobile !== '' && <p>手機: { reservation.contact.mobile }</p>}
        { reservation.contact.email !== '' && <p>信箱: { reservation.contact.email }</p>}
      </TdContact>
      <Td120>{ reservation.note }</Td120>
      <Td60>
        <ButtonGoDetail
          disabled = { props.disableDetailPage }
          onClick = { props.goDetailHandler }
        >...
        </ButtonGoDetail>
      </Td60>
    </TrWrapper>
  );
};

export default Item;
