import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import {
  deleteMedias,
  postToGetMedias,
  postToGetMediaDetail,
} from '@/api/mediaService';

import { FETCH_MEDIA_TYPES } from '@/store/actions/srpOptionsAction';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import MainContent from '@/component-style/MainContent';
import PanelWrapper from '@/components/UI/PanelWrapper';
import SelectNormal from '@/components/UI/Select/SelectNormal';
import SearchButtonSet from '@/components/UI/SearchButtonSet';
import TableMedias from '@/components/Table/TableMedias';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import PopupExecuteConfirm from '@/components/UI/Popup/PopupExecuteConfirm';
import { ButtonBasic } from '@/component-style/Button';
import { InputBasic } from '@/component-style/Input';
import { PSubtitle } from '@/component-style/TextElement';
import {
  DivFlexRow,
  DivItemWrapper,
} from '@/component-style/RWDWrapper';

import { exportMediaData } from '@/utils/xlsx';
import { searchConfigGenerator } from '@/utils/apiRequestUtils';
import {
  getOptionDesc,
  getAddressString,
} from '@/utils/textUtils';

const DivPanelContainer = styled.div`
  margin-bottom: 40px;
`;

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding: 0;
  justify-content: space-between;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const ButtonAdd = styled(ButtonBasic)`
  margin-right: 10px;
  width: 120px;

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`;

const ButtonExport = styled(ButtonBasic)`
  width: 120px;

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`;

const MediaManagement = props => {
  const history = useHistory();
  const dispatch = useDispatch();
  const mediaTypes = useSelector(state => state.srpOptions.mediaTypeOptions);
  const informationRoutes = useSelector(state => state.login.informationRoutes);
  const showSaveFailPopup = useSelector(state => state.apiFail.showSaveFailPopup);
  const apiFailPopupTitle = useSelector(state => state.apiFail.title);
  const apiFailPopupMessage = useSelector(state => state.apiFail.message);

  const [medias, setMedias] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [searchConfig, setSearchConfig] = useState({
    mediaName: '',
    mediaTypeID: '',
    amount: 10,
  });
  
  const [selectAll, setSelectAll] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [showDeleteSuccessPopup, setShowDeleteSuccessPopup] = useState(false);

  const userAddable = informationRoutes.indexOf('MediaCreation') !== -1;
  const userExportable = informationRoutes.indexOf('MediaManagement-Export') !== -1;

  const selectedItemNumber = medias
    .filter(media => media.isChecked).length;

  const selectAllHandler = value => {
    setSelectAll(value);
    const updateMedias = medias
      .map(media => ({ ...media, isChecked: value }));
    setMedias([ ...updateMedias ]);
  };

  const checkedHandler = (index, value) => {
    const updateMedias = [ ...medias ];
    updateMedias[index].isChecked = value;
    setMedias([ ...updateMedias ]);
  };

  const setCurrentPageHandler = page => {
    setCurrentPage(page);
    fetchMedias(page);
  };

  const searchHandler = () => {
    setCurrentPageHandler(1);
  };

  const clearHandler = () => {
    setSearchConfig({
      mediaName: '',
      mediaTypeID: '',
      amount: 10,
    });
  };

  const fetchMedias = async (page = currentPage) => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetMedias(
        searchConfigGenerator({ ...searchConfig, page })
      );
      const { medias, totalCount } = data;
      const mediaList = medias
        .map(media => ({ ...media, isChecked: false }))
        .sort((a, b) => a.mediaName > b.mediaName ? 1 : -1);

      setMedias([ ...mediaList ]);
      setTotalCount(totalCount);
    } catch(err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const deleteMediasHandler = async () => {
    setShowDeletePopup(false);
    setShowLoadingPopup(true);

    try {
      const selectedMedias = medias
        .filter(media => media.isChecked)
        .map(media => media.mediaID);

      await deleteMedias(selectedMedias);

      setShowLoadingPopup(false);
      setShowDeleteSuccessPopup(true);
    } catch(err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '刪除失敗', err }));
      console.log(err);
    }
  };

  const exportHandler = async () => {
    setShowLoadingPopup(true);
    const mediaDatas = [];

    try {
      const { data } = await postToGetMedias(
        searchConfigGenerator({
          ...searchConfig, page: 1, amount: totalCount })
      );

      const { medias } = data;
      for (let i = 0; i < medias.length; i += 1) {
        const { mediaID} = medias[i];
        const { data } = await postToGetMediaDetail(mediaID);

        mediaDatas.push([
          data.media || '',
          data.mediaEnglishName || '',
          data.groupName || '',
          data.groupEnglishName || '',
          data.channelName || '',
          data.channelEnglishName || '',
          getOptionDesc(mediaTypes, mediaID.type),
          data.inTwMedia ? '是' : '否',
          data.translateCenter ? '是' : '否',
          data.stopPublish ? '是' : '否',
          data.publishQty || '',
          data.level || '',
          getAddressString(data.contactAddress),
          data.contactAddressEng || '',
          data.website || '',
          data.groupBackground || '',
          data.groupBackgroundEng || '',
          data.deadlineDesc || '',
          data.deadlineDescEng || '',
        ]);
      }

    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
    exportMediaData(mediaDatas);
  };

  const initMediaTypes = useCallback(() => {
    dispatch(FETCH_MEDIA_TYPES());
  }, [dispatch]);

  const initMedias = useCallback(
    fetchMedias, []);

  useEffect(() => {
    initMediaTypes();
    initMedias();
  }, [initMediaTypes, initMedias]);

  return (
    <MainContent>
      <DivPanelContainer>
        <PanelWrapper title = "媒體搜尋">
          <DivFlexRow>
            <DivItemWrapper>
              <PSubtitle>媒體名稱</PSubtitle>
              <InputBasic
                value = { searchConfig.mediaName }
                onChange = { ({ target }) => {
                  setSearchConfig(prevState => ({
                    ...prevState,
                    mediaName: target.value,
                  }));
                }}
              />
            </DivItemWrapper>
            <DivItemWrapper>
              <PSubtitle>媒體類別</PSubtitle>
              <SelectNormal
                value = { searchConfig.mediaTypeID }
                options = {[
                  { value: '', desc: '請選擇類別' },
                  ...mediaTypes
                ]}
                changeHandler = { value => {
                  setSearchConfig(prevState => ({
                    ...prevState,
                    mediaTypeID: value,
                  }));
                }}
              />
            </DivItemWrapper>
          </DivFlexRow>
          <DivFlexRow>
            <DivButtonsWrapper>
              <div>
                <ButtonAdd
                  disabled = { !userAddable }
                  onClick = { () => { history.push('/Information/MediaCreation') } }
                >新增</ButtonAdd>
                <ButtonExport
                  disabled = { !userExportable }
                  onClick = { exportHandler }
                >匯出</ButtonExport>
              </div>
              <SearchButtonSet
                clearHandler = { clearHandler }
                searchHandler = { searchHandler }
              />
            </DivButtonsWrapper>
          </DivFlexRow>
        </PanelWrapper>
      </DivPanelContainer>
      <TableMedias
        disableDelete = { selectedItemNumber === 0 }
        medias = { medias }
        selectAll = { selectAll }
        selectAllHandler = { selectAllHandler }
        totalCount = { totalCount }
        currentPage = { currentPage }
        checkHandler = { checkedHandler }
        deleteHandler = { () =>  {
          setShowDeletePopup(true);
        }}
        pageChangedHandler = { value => {
          selectAllHandler(false);
          setCurrentPageHandler(value);
        }}
      />
      { showLoadingPopup && <PopupLoading/> }
      {
        showDeletePopup &&
          <PopupExecuteConfirm
            title = "刪除客戶名單"
            text = { `確定刪除${ selectedItemNumber }個項目` }
            confirmHandler = { deleteMediasHandler }
            cancelHandler = { () => {
              setShowDeletePopup(false);
            }}
          />
      }
      {
        showDeleteSuccessPopup &&
          <PopupExecuteResult
            title = "刪除成功"
            confirmHandler = { () => {
              setShowDeleteSuccessPopup(false);
              fetchMedias();
            }}
          />
      }
      {
        showSaveFailPopup &&
          <PopupExecuteResult
            title = { apiFailPopupTitle }
            text = { apiFailPopupMessage }
            confirmHandler = { () => {
              dispatch(CLOSE_SAVE_FAIL_POPUP());
            }}
          />
      }
    </MainContent>
  );
};

export default MediaManagement;
