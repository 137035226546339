export const searchConfigGenerator = config => {
  const searchConfig = { ...config };

  Object.keys(searchConfig)
    .forEach((key) => (searchConfig[key] === ''
      || searchConfig[key] === undefined
      || searchConfig[key] === 0)
      && delete searchConfig[key]);

  return searchConfig;
};

export const getDefaultPort = baseUrl => /https:\/\//.test(baseUrl)
  ? 443 : 80;

export const downloadFromUrl = url => {
  const link = document.createElement('a');
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
