import React, { useState } from 'react';
import styled from 'styled-components';
import Draggable from 'react-draggable';

import { DivPopupWrapper, DivPopup } from '@/component-style/Popup';
import { ButtonBasic, ButtonCancel } from '@/component-style/Button';

const DivPopupContainer = styled(DivPopup)`
  width: auto;
`;

const DivButtonsWrapper = styled.div`
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const ButtonConfirm = styled(ButtonBasic)`
  margin-right: 10px;
  width: 120px;
`;

const ButtonDiscard = styled(ButtonCancel)`
  width: 120px;
`;

const DivBGContainer = styled.div`
  transform: rotate(${ props => props.rotation }deg);
  width: 500px;
  height: 500px;
  background-image: url(${ props => props.url });
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;


const PopupDraggable = props => {

  const [rotation, setRotation] = useState(0);

  return (
    <DivPopupWrapper>
      <Draggable>
        <DivPopupContainer>
          <DivBGContainer
            rotation = { rotation }
            url = { props.url }
          />
          <DivButtonsWrapper>
            <ButtonConfirm
              onClick = { () => { setRotation(prevState => prevState + 90) }}
            >旋轉
            </ButtonConfirm>
            <ButtonDiscard
              onClick = { props.cancelHandler }
            >關閉
            </ButtonDiscard>
          </DivButtonsWrapper>
        </DivPopupContainer>
      </Draggable>
    </DivPopupWrapper>
  );
};

export default PopupDraggable;
