import { combineReducers } from 'redux';

import loginReducer from './loginReducer';
import srpOptionsReducer from './srpOptionsReducer';
import apiFailPopupReducer from './apiFailPopupReducer';

const rootReducer = combineReducers({
  login: loginReducer,
  apiFail: apiFailPopupReducer,
  srpOptions: srpOptionsReducer,
});

export default rootReducer;
