export const overtimeConfig = {
  workingDate: null,
  submitDate: null,
  projectID: '',
  typeID: '',
  tag: '',
  userID: '',
  startTimeLocal: null,
  endTimeLocal: null,
  crossTeam: false,
  isOverTime: true,
  overtimeType: 'freeTime',
  freeTimeMins: 0,
  extraPayMins: 0,
  extraPay: 0,
  approvementStatus: 'draft',
  approvementDate:  null,
  note: '',
  overtimeDateType: 'workdays',
};
