import React, { useCallback, useEffect, useState, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { postToGetProjectDetail } from '@/api/projectService';
import {
  postToGetExpenses,
  postToGetExpenseDetail,
} from '@/api/expenseService';

import {
  FETCH_MEMBER_OPTIONS,
  FETCH_VENDOR_ID_OPTIONS,
  FETCH_PROJECT_ID_OPTIONS,
} from '@/store/actions/srpOptionsAction';

import MainContent from '@/component-style/MainContent';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PanelPersonnelWageSearch from '@/components/Panel/Finance/PanelPersonnelWageSearch';
import TablePersonnelWageList from '@/components/Table/Finance/TablePersonnelWageList';
import PopupSalaryDetail from '@/components/UI/Popup/PopupSalaryDetail';

import { searchConfigGenerator } from '@/utils/apiRequestUtils';
import { integerConverter } from '@/utils/textUtils';
import { getOptionDesc } from '@/utils/textUtils';
import { expenseApprovalStates } from '@/constant/options';
import { exportFinanceVerifyFile } from '@/utils/xlsx';
import moment from 'moment';
import { exportSalaryListXLS } from '@/utils/xlsx';

import {
  getUserSalaries,
  postCalculateAllUsersSalaries,
  // postCalculateUserSalaries,
  getUserSalaryByMonth,
} from '@/api/financeService';

const DivPanelContainer = styled.div`
  margin-bottom: 40px;
`;

const searchConfig = {
  wageMonth: moment().subtract(1, 'M').format("YYYY-MM"),
  payMonth: moment().format("YYYY-MM"),
  companyType: "",
};

const PersonnelWage = () => {
  const dispatch = useDispatch();
  const userID = useSelector(state => state.login.token);
  const vendorIDs = useSelector(state => state.srpOptions.vendorIDOptionsTaxID);
  const memberOptions = useSelector(state => state.srpOptions.memberOptions);

  const [wages, setWages] = useState([]);
  const [enableCalculate, setEnableCalculate] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [totalSalary, setTotalSalary] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [viewDetailOfUserId, setViewDetailOfUserId] = useState();
  const [detailOfUserId, setDetailOfUserId] = useState();

  const [config, setConfig] = useState({ ...searchConfig });

  const [showLoadingPopup, setShowLoadingPopup] = useState(false);

  const [showPopupSalaryDetail, setShowPopupSalaryDetail] = useState(false);


  const updateConfigHandler = (key, value) => {
    // console.log(`personnelWage.js updateConfigHandler() value:${value}`);
    const updateObj = { ...config };
    updateObj[key] = value;
    if (key === "wageMonth") {
      updateObj['payMonth'] = moment(value).add(1, 'M').format("YYYY-MM");
      setConfig({ ...updateObj });

      console.log(`personnelWage.js updateConfigHandler() moment().subtract(1, 'M').format("YYYY-MM"):${moment().subtract(1, 'M').format("YYYY-MM")} moment(value).format("YYYY-MM"):${moment(value).format("YYYY-MM")}`);
      console.log(`personnelWage.js updateConfigHandler() moment().format("D")=${moment().format("D")}`);
      const currentMonth = moment().format("YYYY-MM");
      const calculableMonth = moment().subtract(1, 'M').format("YYYY-MM");
      const toCalculateMonth = moment(value).format("YYYY-MM");
      calculableMonth === toCalculateMonth ? setEnableCalculate(true) : setEnableCalculate(false);
      if (moment().format("D") > 5) {
        setEnableCalculate(false);
      }
      if (currentMonth === toCalculateMonth && moment().format("D") > 5) {
        setEnableCalculate(true);
      }
      // setEnableCalculate(true);
    }
    setConfig(updateObj);
  };

  const searchHandler = () => {
    setCurrentPageHandler(1);
  };

  const setCurrentPageHandler = page => {
    console.log(`personnelWage.js setCurrentPageHandler() page=${page}`);
    setCurrentPage(page);
    fetchWage(page);
  };

  const fetchWage = async (page = currentPage) => {
    console.log(`personnelWage.js fetchWage() page=${page}`);
    setShowLoadingPopup(true);

    try {
      console.log(`personnelWage.js fetchWage() moment(config.wageMonth).startOf('month').format('YYYY-MM-DD'):${moment(config.wageMonth).startOf('month').format('YYYY-MM-DD')}`);
      const userSalariesParameters = {
        // userID: userID,
        month: moment(config.wageMonth).startOf('month').format('YYYY-MM-DD'),
        companyType: config.companyType,
        amount: 10,
        page: page,
      };
      const { data } = await getUserSalaries(userSalariesParameters);
      console.log(`personnelWage.js fetchWage() JSON.stringify(data.salaries):${JSON.stringify(data.salaries)}`);
      // const tempSalaries = data.salaries;
      // console.log(`personnelWage.js fetchWage() JSON.stringify(tempSalaries):${JSON.stringify(tempSalaries)}`);
      setWages(data.salaries);
      setTotalCount(data.totalCount || 0);
      setTotalSalary(data.totalSalary || 0);
    } catch(err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const fetchSalaryDetail = async () => {
    if (viewDetailOfUserId === "" || viewDetailOfUserId === undefined) {
      return;
    }
    try {
      // console.log(`personnelWage.js fetchSalaryDetail() moment(config.wageMonth).startOf('month').format('YYYY-MM-DD'):${moment(config.wageMonth).startOf('month').format('YYYY-MM-DD')}`);
      const { data } = await getUserSalaryByMonth( viewDetailOfUserId, moment(config.wageMonth).startOf('month').format('YYYY-MM-DD') );
      console.log(`personnelWage.js fetchSalaryDetail() JSON.stringify(data)=${JSON.stringify(data)}`);
      setDetailOfUserId(data);
    } catch(err) { console.log(err) }
  };

  const exportSalaryListXLSHandler = async() => {
    const userSalariesParameters = {
      // userID: userID,
      month: moment(config.wageMonth).startOf('month').format('YYYY-MM-DD'),
      companyType: config.companyType,
      amount: 100,
      page: currentPage,
    };
    const { data } = await getUserSalaries(userSalariesParameters);
    console.log(`personnelWage.js exportSalaryListXLSHandler() JSON.stringify(data)=${JSON.stringify(data)}`);
    const salariesData = [];
    var userName = "";
    for (let i = 0; i < data.salaries.length; i += 1) {
      if (data.salaries[i].username.match(/\(([^)]+)\)/) !== null) {
        userName = `${ data.salaries[i].username.match(/\(([^)]+)\)/)[1] }`;
      } else {
        userName = data.salaries[i].username;
      }
      salariesData.push([
        `${(i+1).toString()}`,
        userName,
        data.salaries[i].idNo,
        data.salaries[i].bankAccount,
        data.salaries[i].totalSalary,
      ]);
    }
    console.log(`personnelWage.js exportSalaryListXLSHandler() JSON.stringify(salariesData)=${JSON.stringify(salariesData)}`);
    exportSalaryListXLS(salariesData);
  };

  const exportSalaryListTxtHandler = async() => {
    const userSalariesParameters = {
      // userID: userID,
      month: moment(config.wageMonth).startOf('month').format('YYYY-MM-DD'),
      companyType: config.companyType,
      amount: 100,
      page: currentPage,
    };
    const { data } = await getUserSalaries(userSalariesParameters);
    console.log(`personnelWage.js exportSalaryListTxtHandler() JSON.stringify(data)=${JSON.stringify(data)}`);
    // const salariesData = [];
    var strSalariesData = "";
    for (let i = 0; i < data.salaries.length; i += 1) {
      strSalariesData += `${(i+1).toString().padEnd(5, " ")}`;
      strSalariesData += "\t";
      if (data.salaries[i].username.match(/\(([^)]+)\)/) !== null) {
        strSalariesData += `${ data.salaries[i].username.match(/\(([^)]+)\)/)[1] }`;
      } else {
        strSalariesData += `${ data.salaries[i].username }`;
      }
      strSalariesData += "\t";
      strSalariesData += `${data.salaries[i].idNo}`;
      strSalariesData += "\t";
      strSalariesData += `${data.salaries[i].bankAccount}`;
      strSalariesData += "\t";
      strSalariesData += `${data.salaries[i].totalSalary.toString().padEnd(6, " ")}`;
      strSalariesData += "\t";
      strSalariesData += "\t";
      strSalariesData += "\r";
      strSalariesData += "\r";
      strSalariesData += "\n";
    }
    // console.log(`personnelWage.js exportSalaryListTxtHandler() JSON.stringify(salariesData)=${JSON.stringify(salariesData)}`);
    const blob = new Blob([strSalariesData], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = "人員薪資.txt";
    link.href = url;
    link.click();
  };

  const calculateWageHandler = async () => {
    setShowLoadingPopup(true);
    
    try {
      console.log(`personnelWage.js calculateWageHandler() moment(config.wageMonth).format('YYYY-MM')=${moment(config.wageMonth).format('YYYY-MM')}`);
      var bForCurrentMonth = false;
      if (moment(config.wageMonth).format('YYYY-MM') === moment().format('YYYY-MM')) {
        bForCurrentMonth = true;
      }
      await postCalculateAllUsersSalaries(bForCurrentMonth);
      // await postCalculateUserSalaries("01ECK5VG68HSVXZ43DR0P63DMG");
      fetchWage(1);
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const cancelHandler = () => {
    setShowPopupSalaryDetail(false);
  };

  const searchWageHandler = async () => {
    fetchWage(1);
    // setShowLoadingPopup(true);
    
    // try {
    //   await postCalculateAllUsersSalaries();
    //   // await postCalculateUserSalaries("01ECK5VG68HSVXZ43DR0P63DMG");
    // } catch (err) { console.log(err) }

    // setShowLoadingPopup(false);
  };

  // const exportHandler = async () => {
  //   setShowLoadingPopup(true);
  //   const expensesData = [];

  //   try {
  //     const { data } = await postToGetExpenses(searchConfigGenerator({
  //       ...config,
  //       approvalStatus: ['financeVerify'],
  //       managedBy: userID,
  //       // user: config.user === '' ? [] : [config.user],
  //       expenseCode: integerConverter(config.expenseCode),
  //       price: {
  //         min: integerConverter(config.price.min) || null,
  //         max: integerConverter(config.price.max) || null,
  //       },
  //       amount: totalCount || 1,
  //       page: 1,
  //     }));

  //     const { expenses } = data;

  //     for (let i = 0; i < expenses.length; i += 1) {
  //       const { projectID, applyDate, predictDate, expenseID } = expenses[i];
  //       const expenseDetail = await postToGetExpenseDetail(expenseID);
  //       const expense = expenseDetail.data;
  //       const projectDetail = await postToGetProjectDetail(projectID);
  //       const { projectCode, projectName } = projectDetail.data;
  //       expensesData.push([
  //         applyDate === '0001-01-01' ? '' : applyDate,
  //         expense.expenseCode || '',
  //         getOptionDesc(expenseApprovalStates, expense.approvalStatus),
  //         projectCode,
  //         projectName,
  //         expense.object === 'user'
  //           ? getOptionDesc(memberOptions, expense.objectID)
  //           : getOptionDesc(vendorIDs, expense.objectID),
  //         getOptionDesc(memberOptions, expense.user),
  //         expense.note,
  //         expense.price,
  //         expense.isImportant ? '急件' : '普件',
  //         expense.vouchers.some(voucher => voucher.notForAccountingSys)
  //           ? '否' : '是',
  //         expense.vouchers.some(voucher => voucher.notForBusinessTax)
  //           ? '否' : '是',
  //         expense.expenseCode || '',
  //         predictDate === '0001-01-01' ? '' : predictDate,
  //       ]);
  //     };

  //   } catch (err) { console.log(err) }

  //   setShowLoadingPopup(false);
  //   exportFinanceVerifyFile(expensesData);
  // };

  const initWage = useCallback(
    fetchWage, []);

  const initOptions = useCallback(() => {
    dispatch(FETCH_MEMBER_OPTIONS());
    dispatch(FETCH_VENDOR_ID_OPTIONS());
    dispatch(FETCH_PROJECT_ID_OPTIONS());
  }, [dispatch]);

  useEffect(() => {
    initOptions();
    initWage();
    console.log(`personnelWage.js useEffect moment().format("D")=${moment().format("D")}`);
    if (moment().format("D") > 5) {
      setEnableCalculate(false);
    }
  }, [initWage, initOptions]);

  useEffect(() => {
    fetchSalaryDetail();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewDetailOfUserId]);

  return (
    <MainContent>
      <DivPanelContainer>
        <PanelPersonnelWageSearch
          config = { config }
          memberList = { memberOptions }
          searchHandler = { searchHandler }
          // exportHandler = { exportHandler }
          enableCalculate = { enableCalculate }
          calculateWageHandler = { calculateWageHandler }
          searchWageHandler = { searchWageHandler }
          updateConfigHandler = { updateConfigHandler }
          exportSalaryListXLSHandler = { exportSalaryListXLSHandler }
          exportSalaryListTxtHandler = { exportSalaryListTxtHandler }
        />
      </DivPanelContainer>
      <TablePersonnelWageList
        wages = { wages }
        editDetailHandler = { async (userId) => {
          console.log(`personnelWage.js editDetailHandler userId=${userId}`);
          // setEditLeaveId(id);
          setViewDetailOfUserId(userId);
          setShowPopupSalaryDetail(true);
        }}
        totalCount = { totalCount }
        totalSalary = { totalSalary }
        currentPage = { currentPage }
        pageChangedHandler = { value => {
          setCurrentPageHandler(value);
        }}
      />
      { showLoadingPopup && <PopupLoading/> }
      { 
        showPopupSalaryDetail &&
          <PopupSalaryDetail
            // updateLeaveParamsHandler = { updateLeaveParamsHandler }
            // leaves = { leave }
            // editLeaveId = { editLeaveId }
            detailOfUserId = { detailOfUserId }
            cancelHandler = { cancelHandler }
            // reloadHandler = { reloadHandler }
          />
      }
    </MainContent>
  )
};

export default PersonnelWage;
