import React from 'react';
import styled from 'styled-components';

import NavigationItem from '../NavigationItem';
import NavigationCategory from '../NavigationCategory';
import IconDashboard from '../../../assets/images/icon_dashboard.svg';

const UlWrapper = styled.ul`
  margin: 0;
  padding: 0;
`;

const DivIcon = styled.span`
  display: inline-block;
  margin-left: 50px;
  width: 24px;
  height: 24px;
  background-image: url(${props => props.url});
`;

const hrRoutes = [
  {
    title: '專案成本',
    key: 'ProjectCost',
    url: '/HR/ProjectCost',
  },
  {
    title: '同仁薪資計算',
    key: 'WageStructureManagement',
    url: '/HR/WageStructureManagement',
  },
  {
    title: '同仁月薪資',
    key: 'PersonnelWage',
    url: '/HR/PersonnelWage',
  },
  {
    title: '同仁休假管理',
    key: 'PersonnelCompensatedLeaveManagement',
    url: '/HR/PersonnelCompensatedLeaveManagement',
  },
];

const NavigationListHR = props => {
  const { validRoutes } = props;

  const routeItems = hrRoutes
    .filter(({ key }) => validRoutes.indexOf(key) !== -1)
    .map(({ key, title, url }) => (
      <NavigationItem
        key = { key }
        url = { url }
        title = { title }
      />
    ));

  return (
    <UlWrapper>
      <NavigationCategory
        title = "人事資料管理"
        opened = { props.opened }
        clickHandler = { props.toogleList }
      >
        <DivIcon url = { IconDashboard }/>
      </NavigationCategory>
      { props.opened && routeItems }
    </UlWrapper>
  );
};

export default NavigationListHR;

// const NavigationListHR = props => {

//   return (
//     <NavigationItem
//       url = "/HR"
//       title = "人事資料管理"
//     >
//       <DivIcon
//         url = { IconDashboard }
//       />
//     </NavigationItem>
//   );
// };

// export default NavigationListHR;
