import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import Navigation from '../Navigation';
import Header from '../UI/Header';
import { useRWDState } from '../../hooks';

const DivWrapper = styled.div`
  display: flex;
`;

const DivContent = styled.div`
  width: 100%;
  background-color: #f8f8f8;
  height: ${ props => props.showNavigation
    ? '100vh' : null };
  overflow: ${ props => props.showNavigation
    ? 'hidden' : null };

  @media (min-width: 1025px) {
    margin-left: ${ props => props.isLogin
      ? '240px' : null };
    width: ${ props => props.isLogin
      ? 'calc(100% - 240px)' : '100%' };
  }
`;

const Wrapper = props => {
  const { pathname } = useLocation();
  const isRWD = useRWDState();
  const [showNavigation, openNavigation] = useState(false);

  const displayNavigation = props.isLogin
    && (isRWD ? showNavigation : true);

  useEffect(() => {
    openNavigation(false);
  }, [pathname, isRWD]);

  return (
    <DivWrapper>
      {
        displayNavigation &&
        <Navigation
          showNavigation = { showNavigation }
          openNavigation = { openNavigation }
        />
      }
      <DivContent
        isLogin = { props.isLogin }
        showNavigation = { showNavigation }
      >
        {
          props.isLogin &&
          <Header
            openNavigation = { openNavigation }
          />
        }
        { props.children }
      </DivContent>
    </DivWrapper>
  );
};

export default Wrapper;
