import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Checkbox from '@/components/UI/Checkbox';
import { TdItem, TableRow } from '@/component-style/Table';
import { ButtonGoDetail } from '@/component-style/Button';

import { expenseApprovalStates } from '@/constant/options';
import { getOptionDesc } from '@/utils/textUtils';
import { formatTime } from '@/utils/timeUtils';
import Highlighted from '@/components/UI/Highlighted';

import { decimalCommaConverter } from '@/utils/textUtils';

const TrWrapper = styled(TableRow)`
  border-bottom: solid 2px #f2f2f2;
  box-sizing: border-box;
  min-width: 1520px;

  &:hover {
    background: #D3D3D3;
    cursor: pointer;
  };

  &:last-child {
    border-bottom: none;
  }
`;

const Td80 = styled(TdItem)`
  width: 80px;
`;

const Td120 = styled(TdItem)`
  width: 120px;
`;

const Td160 = styled(TdItem)`
  width: 160px;
`;

const Item = props => {
  const { expense } = props;
  const { user, objectID, approvalStatus } = expense;
  const vendorIDs = useSelector(state => state.srpOptions.vendorIDOptions);
  const memberOptions = useSelector(state => state.srpOptions.memberOptions);

  const username = getOptionDesc(memberOptions, user);

  const objectName = expense.object === 'user'
    ? getOptionDesc(memberOptions, objectID)
    : getOptionDesc(vendorIDs, objectID);

  const status = getOptionDesc(expenseApprovalStates, approvalStatus);

  return (
    <TrWrapper>
      <Td80>
        <Checkbox
          disabled = { expense.approvalStatus !== 'generalManagerApproved' }
          checked = { expense.isChecked }
          changeHandler = { props.checkHandler }
        />
      </Td80>
      <Td120 onClick = { props.goDetailHandler } >
        { formatTime(expense.applyDate, 'YYYY-MM-DD') || '' }
      </Td120>
      <Td120  onClick = { props.goDetailHandler } >{ expense.expenseCode }</Td120>
      <Td120  onClick = { props.goDetailHandler } >
        { formatTime(expense.predictDate, 'YYYY-MM-DD') || '' }
      </Td120>
      <Td120  onClick = { props.goDetailHandler } >{ status }</Td120>
      <Td160 onClick = { props.goDetailHandler } >{ expense.projectCode }</Td160>
      <Td120 onClick = { props.goDetailHandler } >{ username }</Td120>
      <Td160 onClick = { props.goDetailHandler } >{ objectName }</Td160>
      <Td120 onClick = { props.goDetailHandler } >{ expense.price == null ? '' : decimalCommaConverter(expense.price) }</Td120>
      <Td80 onClick = { props.goDetailHandler } >
        {/* { expense.isPrinted ? '是' : '否' } */}
        <Highlighted
          highlighted = { !expense.isPrinted }
          text = { expense.isPrinted ? '已列印' : '未列印' }
        />
      </Td80>
      <Td80 onClick = { props.goDetailHandler } >
        {/* { expense.isPaid ? '已付款' : '未付款' } */}
        <Highlighted
          highlighted = { !expense.isPaid }
          text = { expense.isPaid ? '已付款' : '未付款' }
        />
      </Td80>
      {/* <Td80 onClick = { props.goDetailHandler } >{ expense.notForAccountingSys ? '否' : '是' }</Td80> */}
      <Td120 onClick = { props.goDetailHandler } >
        <Highlighted
          highlighted = { (expense.exportedAccountingSystem === undefined || expense.exportedAccountingSystem === null || expense.exportedAccountingSystem === false ) ? true : false }
          text = { (expense.exportedAccountingSystem === undefined || expense.exportedAccountingSystem === null || expense.exportedAccountingSystem === false ) ? '未匯出' : '已匯出' }
          // highlighted = { expense.notForAccountingSys }
          // text = { expense.notForAccountingSys ? '未匯出' : '已匯出' }
        />
      </Td120>
      <Td80 onClick = { props.goDetailHandler } >
        {/* { expense.notForBusinessTax ? '否' : '是'  } */}
        <Highlighted
          highlighted = { expense.notForBusinessTax }
          text = { expense.notForBusinessTax ? '未申報' : '已申報' }
        />
      </Td80>
      <Td80>
        <ButtonGoDetail
          disabled = { props.disableDetailPage }
          onClick = { () => { props.goDetailHandler() } }
        >...
        </ButtonGoDetail>
      </Td80>
    </TrWrapper>
  );
};

export default Item;
