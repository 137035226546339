import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { cloneDeep } from 'lodash';

import {
  putNewActivityIdeaType,
  deleteActivityIdeaTypes,
  postToGetActivityIdeaTypes,
  patchModifyActivityIdeaType,
} from '@/api/activityIdeaService';

import { FETCH_ACTIVITY_IDEA_TYPE_OPTIONS } from '@/store/actions/srpOptionsAction';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import MainContent from '@/component-style/MainContent';
import PanelWrapper from '@/components/UI/PanelWrapper';
import TableActivityIdeaTypes from '@/components/Table/SysConfig/TableActivityIdeaTypes';
import PopupTypeDetail from '@/components/UI/Popup/PopupTypeDetail';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import PopupExecuteConfirm from '@/components/UI/Popup/PopupExecuteConfirm';
import SelectSearchable from '@/components/UI/Select/SelectSearchable';
import SearchButtonSet from '@/components/UI/SearchButtonSet';
import { PSubtitle } from '@/component-style/TextElement';
import { ButtonBasic } from '@/component-style/Button';

import {
  DivFlexRow,
  DivFullWidthWrapper,
  DivLongMultiSelector,
} from '@/component-style/RWDWrapper';

import { searchConfigGenerator } from '@/utils/apiRequestUtils';
import { activityIdeaTypeConfig } from '@/constant/dataConfig/sysConfigModule';

const DivPanelContainer = styled.div`
  margin-bottom: 40px;
`;

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding: 0;
  justify-content: space-between;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const ButtonAdd = styled(ButtonBasic)`
  margin-right: 10px;
  width: 120px;

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`;

const ActivityIdeaTypeManagement = () => {
  const dispatch = useDispatch();
  const sysConfigRoutes = useSelector(state => state.login.sysConfigRoutes);
  const activityIdeaTypeOptions = useSelector(state => state.srpOptions.activityIdeaTypeOptions);
  const showSaveFailPopup = useSelector(state => state.apiFail.showSaveFailPopup);
  const apiFailPopupTitle = useSelector(state => state.apiFail.title);
  const apiFailPopupMessage = useSelector(state => state.apiFail.message);

  const [types, setTypes] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  
  const [activityIdeaTypeID, setActivityIdeaTypeID] = useState('');
  const [selectedType, setSelectedType] = useState(cloneDeep(activityIdeaTypeConfig));

  const [isAdding, setIsAdding] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [showTypeDetailPopup, setShowTypeDetailPopup] = useState(false);
  const [showSaveSuccessPopup, setShowSaveSuccessPopup] = useState(false);
  const [showDeleteSuccessPopup, setShowDeleteSuccessPopup] = useState(false);

  const userAddable = sysConfigRoutes.indexOf('ActivityIdeaTypeManagement-Add') !== -1;
  const userEditable = sysConfigRoutes.indexOf('ActivityIdeaTypeManagement-Edit') !== -1;
  const userDeletable = sysConfigRoutes.indexOf('ActivityIdeaTypeManagement-Delete') !== -1;

  const selectedItemNumber = types
    .filter(type => type.isChecked).length;

  const addTypeHandler = () => {
    setIsAdding(true);
    setSelectedType(cloneDeep(activityIdeaTypeConfig));
    setShowTypeDetailPopup(true);
  };

  const updateTypeDetailHandler = (key, value) => {
    const updateObj = { ...selectedType };
    updateObj[key] = value;
    setSelectedType({ ...updateObj });
  };

  const clearHandler = () => {
    setTypes([]);
    setActivityIdeaTypeID('');
  };

  const checkedHandler = (index, value) => {
    const updateTypes = [...types];
    updateTypes[index].isChecked = value;
    setTypes(updateTypes);
  };

  const selectAllHandler = value => {
    setSelectAll(value);
    const updateTypes = types
      .map(type => ({ ...type, isChecked: value }));
    setTypes(updateTypes);
  };

  const showTypeDetailHandler = index => {
    const selectedType = types[index];
    setSelectedType(cloneDeep(selectedType));
    setShowTypeDetailPopup(true);
  };

  const pageChangedHandler = page => {
    selectAllHandler(false);
    setCurrentPageHandler(page);
  };

  const setCurrentPageHandler = page => {
    setCurrentPage(page);
    fetchTypes(page);
  };

  const searchHandler = () => {
    setCurrentPageHandler(1);
  };

  const apiFailConfirmHandler = () => {
    dispatch(CLOSE_SAVE_FAIL_POPUP());

    apiFailPopupTitle === '儲存失敗'
      && setShowTypeDetailPopup(true);
  };

  const closeSaveSuccessPopupHandler = () => {
    setIsAdding(false);
    setShowSaveSuccessPopup(false);
    searchHandler();
  };

  const closeDetailPopupHandler = () => {
    setIsAdding(false);
    setShowTypeDetailPopup(false);
  };

  const updateTypeHandler = async () => {
    setShowTypeDetailPopup(false);
    setShowLoadingPopup(true);

    try {
      isAdding
        ? await putNewActivityIdeaType(selectedType)
        : await patchModifyActivityIdeaType(selectedType)

      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
      dispatch(FETCH_ACTIVITY_IDEA_TYPE_OPTIONS());
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '儲存失敗', err }));
      console.log(err);
    }
  };

  const deleteTypeHandler = async () => {
    setShowDeletePopup(false);
    setShowLoadingPopup(true);

    try {
      const selectedTypes = types
        .filter(type => type.isChecked)
        .map(type => type.id);

      await deleteActivityIdeaTypes(selectedTypes);
      setShowLoadingPopup(false);
      setShowDeleteSuccessPopup(true);
      dispatch(FETCH_ACTIVITY_IDEA_TYPE_OPTIONS());
    } catch(err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '刪除失敗', err }));
      console.log(err);
    }
  };

  const fetchTypes = async (page = currentPage) => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetActivityIdeaTypes(
        searchConfigGenerator({
          activityIdeaTypeID, amount: 10, page
        })
      );

      const { types, totalCount } = data;
      const activityIdeaTypes = types.map(type => ({
        ...type,
        isChecked: false,
        enable: type.enable || false,
      }));

      setTypes(activityIdeaTypes);
      setTotalCount(totalCount || 0);
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const initTypes = useCallback(fetchTypes, []);

  const initOptions = useCallback(() => {
    dispatch(FETCH_ACTIVITY_IDEA_TYPE_OPTIONS());
  }, [dispatch]);

  useEffect(() => {
    initOptions();
    initTypes();
  }, [initOptions, initTypes]);

  return (
    <MainContent>
      <DivPanelContainer>
        <PanelWrapper title = "活動創意分類管理">
          <DivFlexRow>
            <DivFullWidthWrapper>
              <PSubtitle>分類名稱</PSubtitle>
              <DivLongMultiSelector>
                <SelectSearchable
                  value = { activityIdeaTypeID }
                  options = {[
                    { value: '', desc: '請選擇分類名稱' },
                    ...activityIdeaTypeOptions,
                  ]}
                  changeHandler = { setActivityIdeaTypeID }
                />
              </DivLongMultiSelector>
            </DivFullWidthWrapper>
          </DivFlexRow>
          <DivFlexRow>
            <DivButtonsWrapper>
              <ButtonAdd
                disabled = { !userAddable }
                onClick = { addTypeHandler }
              >新增</ButtonAdd>
              <SearchButtonSet
                clearHandler = { clearHandler }
                searchHandler = { searchHandler }
              />
            </DivButtonsWrapper>
          </DivFlexRow>
        </PanelWrapper>
      </DivPanelContainer>
      <TableActivityIdeaTypes
        disableDelete = { selectedItemNumber === 0 || !userDeletable }
        types = { types }
        selectAll = { selectAll }
        totalCount = { totalCount }
        currentPage = { currentPage }
        checkHandler = { checkedHandler }
        selectAllHandler = { selectAllHandler }
        deleteHandler = { () =>  { setShowDeletePopup(true) }}
        pageChangedHandler = { pageChangedHandler }
        showTypeDetailHandler = { showTypeDetailHandler }
      />
      { showLoadingPopup && <PopupLoading/> }
      {
        showTypeDetailPopup &&
          <PopupTypeDetail
            title = "活動創意分類"
            type = { selectedType }
            disableName = { !isAdding }
            disableSave = { isAdding ? false : !userEditable }
            confirmHandler = { updateTypeHandler }
            cancelHandler = { closeDetailPopupHandler }
            updateTypeDetailHandler = { updateTypeDetailHandler }
            />
      }
      {
        showDeletePopup &&
          <PopupExecuteConfirm
            title = "刪除活動創意分類"
            text = { `確定刪除${ selectedItemNumber }個項目` }
            confirmHandler = { deleteTypeHandler }
            cancelHandler = { () => {
              setShowDeletePopup(false);
            }}
          />
      }
      {
        showDeleteSuccessPopup &&
          <PopupExecuteResult
            title = "刪除成功"
            confirmHandler = { () => {
              setShowDeleteSuccessPopup(false);
              searchHandler();
            }}
          />
      }
      {
        showSaveSuccessPopup &&
          <PopupExecuteResult
            title = "儲存成功"
            confirmHandler = { closeSaveSuccessPopupHandler }
          />
      }
      {
        showSaveFailPopup &&
          <PopupExecuteResult
            title = { apiFailPopupTitle }
            text = { apiFailPopupMessage }
            confirmHandler = { apiFailConfirmHandler }
          />
      }
    </MainContent>
  );
};

export default ActivityIdeaTypeManagement;
