import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { postToGetUserInfo } from '@/api/authService';
import { postToGetProjectDetail } from '@/api/projectService';

import {
  putModifyWorkingHours,
  postToGetWorkingHours,
  postToGetWorkingHoursDetail,
  postWorkingHoursSetApprovementStatus,
} from '@/api/attendanceService';

import {
  getDownloadFile,
  deleteUploadFile,
  postToGetUploadFiles,
  postNewUploadFile,
} from '@/api/storageService';

import PopupDraggable from '@/components/UI/Popup/PopupDraggable';
import PopupDraggablePdf from '@/components/UI/Popup/PopupDraggablePdf';

import {
  getLeaveSetting,
  postCreateLeaveApplication,
  getLeaveApplication,
  getLeaveApplicationsForApproval,
  postUpdateLeaveApplicationStatus,
} from '@/api/financeService';

import { FETCH_WORKINGHOURS_TYPE_OPTIONS } from '@/store/actions/srpOptionsAction';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import MainContent from '@/component-style/MainContent';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import PopupExecuteConfirm from '@/components/UI/Popup/PopupExecuteConfirm';
import TableLeaveApprovements from '@/components/Table/Attendance/TableLeaveApprovements';
import PopupAttachmentList from '@/components/UI/Popup/PopupAttachmentList';

import { integerConverter } from '@/utils/textUtils';
import { formatTime } from '@/utils/timeUtils';

const searchConfig = {
  isOverTime: true,
  approvementStatus: 'unapproved',
  userIDs: [],
  amount: 10,
};

const LeaveApprovement = () => {
  const dispatch = useDispatch();
  const userID = useSelector(state => state.login.token);
  const showSaveFailPopup = useSelector(state => state.apiFail.showSaveFailPopup);
  const apiFailPopupTitle = useSelector(state => state.apiFail.title);
  const apiFailPopupMessage = useSelector(state => state.apiFail.message);

  const [leaveRecords, setLeaveRecords] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [showAgreeAllPopup, setShowAgreeAllPopup] = useState(false);
  const [showSaveSuccessPopup, setShowSaveSuccessPopup] = useState(false);

  const [showPopupAttachment, setShowPopupAttachment] = useState(false);
  const [showUploadSuccessPopup, setShowUploadSuccessPopup] = useState(false);
  const [leaveApplicationIdPopupAttachment, setLeaveApplicationIdPopupAttachment] = useState("");
  const [fileList, setFileList] = useState([]);
  const [uploadFiles, setUploadFiles] = useState([]);
  const [showDeleteFilePopup, setShowDeleteFilePopup] = useState(false);
  const [deleteFileId, setDeleteFileId] = useState('');
  const [showDeleteFileSuccessPopup, setShowDeleteFileSuccessPopup] = useState(false);
  const [fileUrl, setFileUrl] = useState('');
  const [file, setFile] = useState(null);
  const [showDraggablePDFPopup, setShowDraggablePDFPopup] = useState(false);
  const [showDraggableImagePopup, setShowDraggableImagePopup] = useState(false);

  const setCurrentPageHandler = page => {
    setCurrentPage(page);
    fetchUnapprovedLeave(page);
  };

  const closeSuccessPopupHandler = () => {
    setShowSaveSuccessPopup(false);
    setCurrentPageHandler(1);
  };

  const agreeHandler = async (index) => {
    setShowLoadingPopup(true);
    // const { workingHoursID, freeTimeMins, extraPayMins } = leaveRecords[index];

    try {
      console.log(`leaveApprovement.js agreeHandler() index=${index}&leaveId=${leaveRecords[index].id}`);

      await postUpdateLeaveApplicationStatus(leaveRecords[index].id, "approved");
      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '審核失敗', err }));
      console.log(err);
    }
  };

  const disagreeHandler = async (index) => {
    setShowLoadingPopup(true);
    
    try {
      await postUpdateLeaveApplicationStatus(leaveRecords[index].id, "rejected");
      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '審核失敗', err }));
      console.log(err);
    }
  };

  const agreeAllHandler = async () => {
    setShowAgreeAllPopup(false);
    setShowLoadingPopup(true);

    try {
      for (let index = 0 ; index < leaveRecords.length; index += 1) {
        console.log(`leaveApprovement.js agreeAllHandler() index=${index}&leaveId=${leaveRecords[index].id}`);
        await postUpdateLeaveApplicationStatus(leaveRecords[index].id, "approved");
      }
      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '審核失敗', err }));
      console.log(err);
    }
  };

  const fetchUnapprovedLeave = async (page = currentPage) => {
    setShowLoadingPopup(true);

    try {
      const { data } = await getLeaveApplicationsForApproval();
      const { applications } = data;
      const tempApplicationRecords = [];

      for (let i = 0; i < applications.length; i += 1 ) {
        tempApplicationRecords.push({
          ...applications[i],
        });
      }

      setLeaveRecords([ ...tempApplicationRecords ]);
      setTotalCount(totalCount || 0);
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const displayFileHandler = async fileInfo => {
    const { name, fileID, downloadURL } = fileInfo;

    if (name.match(/\.pdf$/)) {
      // openUploadPopup(false);
      // setShowLoadingPopup(true);
      const { data } = await getDownloadFile(fileID);
      
      setFile(data);
      // setShowLoadingPopup(false);
      setShowDraggablePDFPopup(true);
    } else {
      setFileUrl(downloadURL);
      // openUploadPopup(false);
      setShowDraggableImagePopup(true);
    }
  };

  const upoloadFilesHandler = (files) => {
    console.log(`leaveApprovement.js upoloadFilesHandler files=${files}`);
    setUploadFiles([ ...files ]);
  };

  const fetchUploadFiles = async () => {
    setShowLoadingPopup(true);
    console.log(`leaveApprovement.js leaveApplicationIdPopupAttachment=${JSON.stringify(leaveApplicationIdPopupAttachment)}`);
    try {
      const { data } = await postToGetUploadFiles('leaveApplicationAttachment', leaveApplicationIdPopupAttachment);
      setFileList([ ...data ]);
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const fileUploadHandler = async () => {
    // showPopupAttachment(false);
    setShowLoadingPopup(true);

    try {
      const [file] = uploadFiles;
      const formData = new FormData();
      formData.append('upfile', file);
      formData.append('objectType', 'leaveApplicationAttachment');
      formData.append('objectID', leaveApplicationIdPopupAttachment);
      formData.append('name', file.name);
      await postNewUploadFile(formData);
      await fetchUploadFiles();
      setShowLoadingPopup(false);
      setShowUploadSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '上傳失敗', err }));
      console.log(err);
    };
  };

  const deleteFileHandler = id => {
    setDeleteFileId(id);
    setShowDeleteFilePopup(true);
  };

  const deleteFile = async () => {
    setShowDeleteFilePopup(false);
    setShowDeleteFileSuccessPopup(false);
    setShowLoadingPopup(true);

    try {
      await deleteUploadFile(deleteFileId);
      await fetchUploadFiles();
      setShowLoadingPopup(false);
      setShowDeleteFileSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '刪除檔案失敗', err }));
      console.log(err);
    }
  };

  const uploadSuccessConfirmHandler = () => {
    setUploadFiles([]);
    setShowUploadSuccessPopup(false);
    // setShowAttachmentsPopup(true);
  };

  const openAttachmentListLeaveByApplicationId = (leaveApplicationId) => {
    console.log(`leaveApprovement.js openAttachmentListLeaveByApplicationId() leaveApplicationId=${leaveApplicationId}`);
    setLeaveApplicationIdPopupAttachment(leaveApplicationId);
    setShowPopupAttachment(true);
  };

  useEffect(() => {
    const fetchUploadFiles = async () => {
      setShowLoadingPopup(true);
      console.log(`leaveApprovement.js leaveApplicationIdPopupAttachment=${JSON.stringify(leaveApplicationIdPopupAttachment)}`);
      try {
        const { data } = await postToGetUploadFiles('leaveApplicationAttachment', leaveApplicationIdPopupAttachment);
        setFileList([ ...data ]);
      } catch (err) { console.log(err) }

      setShowLoadingPopup(false);
    }
    fetchUploadFiles();
  }, [leaveApplicationIdPopupAttachment]);

  const initUnapprovedOvertimes = useCallback(
    fetchUnapprovedLeave, []);

  const initOptions = useCallback(() => {
    dispatch(FETCH_WORKINGHOURS_TYPE_OPTIONS());
  }, [dispatch]);

  useEffect(() => {
    initOptions();
    initUnapprovedOvertimes();
  }, [initOptions, initUnapprovedOvertimes]);

  return (
    <MainContent>
      <TableLeaveApprovements
        leaveRecords = { leaveRecords }
        totalCount = { totalCount }
        currentPage = { currentPage }
        agreeHandler = { agreeHandler }
        disagreeHandler = { disagreeHandler }
        // updateValidTimeHandler = { updateValidTimeHandler }
        agreeAllHandler = { () => { setShowAgreeAllPopup(true) } }
        pageChangedHandler = { value => {
          setCurrentPageHandler(value);
        }}
        openAttachmentListLeaveByApplicationId = { (leaveApplicationId) => {
          openAttachmentListLeaveByApplicationId(leaveApplicationId);
        }}
      />
      { showLoadingPopup && <PopupLoading/> }
      {
        showAgreeAllPopup &&
          <PopupExecuteConfirm
            title = "同意全部休假申請"
            text = "確定同意全部休假申請？"
            confirmHandler = { agreeAllHandler }
            cancelHandler = { () => {
              setShowAgreeAllPopup(false);
            }}
          />
      }
      {
        showSaveSuccessPopup &&
          <PopupExecuteResult
            title = "審核成功"
            confirmHandler = { closeSuccessPopupHandler }
          />
      }
      {
        showSaveFailPopup &&
          <PopupExecuteResult
            title = { apiFailPopupTitle }
            text = { apiFailPopupMessage }
            confirmHandler = { () => {
              dispatch(CLOSE_SAVE_FAIL_POPUP());
            }}
          />
      }
      {
        showPopupAttachment &&
        <PopupAttachmentList
          title = "附件管理"
          displayable = { true }
          fileList = { fileList }
          uploadFiles = { uploadFiles }
          displayFileHandler = { displayFileHandler }
          cancelHandler = { () => { setShowPopupAttachment(false) }}
          // updateUploadFiles = { files => { setUploadFiles([ ...files ]) }}
          updateUploadFiles = { files => { upoloadFilesHandler(files) }}
          fileChangable = { true }
          fileUploadHandler = { fileUploadHandler }
          deleteFileHandler = { deleteFileHandler }
        />
      }
      {
        showUploadSuccessPopup &&
          <PopupExecuteResult
            title = "上傳成功"
            confirmHandler = { uploadSuccessConfirmHandler }
          />
      }
      {
        showDeleteFilePopup &&
          <PopupExecuteConfirm
            title = "刪除檔案"
            text = "確定刪除此上傳檔案"
            confirmHandler = { deleteFile }
            cancelHandler = { () => {
              setShowDeleteFilePopup(false);
            }}
          />
      }
      {
        showDraggableImagePopup &&
          <PopupDraggable
            url = { fileUrl }
            cancelHandler = { () => {
              setShowDraggableImagePopup(false);
              // openUploadPopup(true);
            }}
          />
      }
      {
        showDraggablePDFPopup &&
          <PopupDraggablePdf
            file = { file }
            cancelHandler = { () => {
              setShowDraggablePDFPopup(false);
              // openUploadPopup(true);
            }}
          />
      }
    </MainContent>
  )
};

export default LeaveApprovement;
