import React from 'react';
import styled from 'styled-components';

import PanelWrapper from '@/components/UI/PanelWrapper';
import SelectNormal from '@/components/UI/Select/SelectNormal';
import SelectSearchable from '@/components/UI/Select/SelectSearchable';
import SearchButtonSet from '@/components/UI/SearchButtonSet';
import { InputBasic } from '@/component-style/Input';
import { PSubtitle } from '@/component-style/TextElement';
import {
  DivFlexRow,
  DivItemWrapper,
} from '@/component-style/RWDWrapper';

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding: 0;
  justify-content: flex-end;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const PanelProxyApprovalSearch = props => {
  const { config, userFilter, objectFilter } = props;

  return (
    <PanelWrapper title = "憑單代審搜尋">
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>專案代號</PSubtitle>
          <SelectSearchable
            value = { config.projectID }
            options = {[
              { value: '', desc: '請選擇專案代號' },
              ...props.projectIDOptions,
            ]}
            changeHandler = { value => {
              props.updateConfigHandler('projectID', value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>支憑流水號</PSubtitle>
          <InputBasic
            value = { config.expenseCode }
            onChange = { ({ target }) => {
              props.updateConfigHandler('expenseCode', target.value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>付款對象</PSubtitle>
          <SelectNormal
            value = { objectFilter }
            options = {[
              { value: '', desc: '全部' },
              { value: 'vendor', desc: '廠商' },
              { value: 'user', desc: '同仁' },
            ]}
            changeHandler = { value => {
              props.objectFilterChangeHandler(value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>付款對象名稱</PSubtitle>
          <SelectSearchable
            disabled = { objectFilter === '' }
            value = { config.objectID }
            options = {[
              { value: '', desc: '請選擇對象名稱' },
              ...props.objectList,
            ]}
            changeHandler = { value => {
              props.updateConfigHandler('objectID', value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>申請對象</PSubtitle>
          <SelectNormal
            value = { userFilter }
            options = {[
              { value: '', desc: '全部' },
              { value: 'self', desc: '自己' },
              { value: 'other', desc: '同仁' },
            ]}
            changeHandler = { value => {
              props.userFilterChangeHandler(value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>申請對象名稱</PSubtitle>
          <SelectSearchable
            disabled = { userFilter !== 'other' }
            value = { config.user }
            options = {[
              { value: '', desc: '請選擇對象名稱' },
              ...props.memberList,
            ]}
            changeHandler = { value => {
              props.updateConfigHandler('user', value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivButtonsWrapper>
          <SearchButtonSet
            clearHandler = { props.clearHandler }
            searchHandler = { props.searchHandler }
          />
        </DivButtonsWrapper>
      </DivFlexRow>
    </PanelWrapper>
  );
};

export default PanelProxyApprovalSearch;
