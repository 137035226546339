import React from 'react';
import styled from 'styled-components';

import { TableHead, TableRow, ThItem } from '@/component-style/Table';

const THead = styled(TableHead)`
  min-width: 840px;
`;

const Th120 = styled(ThItem)`
  width: 120px;
`;

const Th160 = styled(ThItem)`
  width: 160px;
`;

const Th80 = styled(ThItem)`
  width: 160px;
`;

const Header = props => {
  
  return (
    <THead>
      <TableRow>
        <Th120>同仁名稱</Th120>
        <Th120>時薪</Th120>
        <Th160>核可原始總工時</Th160>
        <Th160>加權總工時</Th160>
        <Th120>應發加班費用</Th120>
        <Th80></Th80>
      </TableRow>
    </THead>
  );
};

export default Header;
