import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import TableWrapper from '@/components/UI/TableWrapper';
import PageSelector from '@/components/UI/PageSelector';
import { Table, TableBody } from '@/component-style/Table';
import Header from './Header';
import Item from './Item';
import PopupEditWageStructure from '@/components/UI/Popup/PopupEditWageStructure';

const TableSystemUsers = props => {
  const history = useHistory();
  const authorityRoutes = useSelector(state => state.login.authorityRoutes);

  const [showPopupEditWageStructure, setShowPopupEditWageStructure] = useState(false);
  const [editUserId, setEditUserId] = useState("");

  const { users } = props;

  const disableDetailPage = authorityRoutes.indexOf('UserDetail') === -1;

  return (
    <TableWrapper title = "使用者列表">
      <Table>
        <Header/>
        <TableBody>
          {
            users.map(user =>
              <Item
                key = { user.userID }
                user = { user }
                disableDetailPage = { disableDetailPage }
                goDetailHandler = { () => {
                  if (props.popupWageStructureWhenEditingUser) {
                    // console.log("TableSystemUsers index.js popupWageStructureWhenEditingUser");
                    // setEditUserId(user.userID);
                    // setShowPopupEditWageStructure(true);
                    history.push(`/Authority/UserDetailSalary/${user.userID}`);
                  } else {
                    history.push(`/Authority/UserDetail/${user.userID}`);
                  }
                }}
              />
            )
          }
        </TableBody>
      </Table>
      <PageSelector
        total = { props.totalCount }
        current = { props.currentPage }
        pageChangedHandler = { props.pageChangedHandler }
        showSizeChanger = { false }
      />
      { 
        showPopupEditWageStructure &&
          <PopupEditWageStructure
            editUserId = { editUserId }
            cancelHandler = { () => {
              setShowPopupEditWageStructure(false);
            } }
            // reloadHandler = { reloadHandler }
          />
      }
    </TableWrapper>
  );
};

export default TableSystemUsers;
