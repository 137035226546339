import React from 'react';
import { InputNumber } from 'antd';
import 'antd/dist/antd.css';
import './index.css';

const MyInputNumber = props => {
  return(
    <InputNumber
      className = { 'inputNumber' }
      formatter = {value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      parser = {value => value.replace(/\$\s?|(,*)/g, '')}
      value = { props.value }
      disabled = { props.disabled }
      onChange = { value => {
        props.onChange(value);
      }}
    />
  )
};

export default MyInputNumber;
