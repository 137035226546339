import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import PanelWrapper from '@/components/UI/PanelWrapper';
import SelectSearchable from '@/components/UI/Select/SelectSearchable';
import SelectNormal from '@/components/UI/Select/SelectNormal';
import SearchButtonSet from '@/components/UI/SearchButtonSet';
import { InputBasic } from '@/component-style/Input';

import { ButtonBasic } from '@/component-style/Button';
import { PSubtitle } from '@/component-style/TextElement';
import {
  DivFlexRow,
  DivItemWrapper,
  DivFullWidthWrapper,
  DivLongMultiSelector,
} from '@/component-style/RWDWrapper';

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding: 0;
  justify-content: space-between;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const DivBudgetWrapper = styled.div`
  width: 300px;
  display: flex;
  justify-content: space-between;

  @media (min-width: 1920px) {
    width: 622px;
  }

  @media (max-width: 320px) {
    width: 260px;
  }
`;

const ButtonAdd = styled(ButtonBasic)`
  width: 120px;

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`;

const InputBudget = styled(InputBasic)`
  width: 120px;

  @media (min-width: 1920px) {
    width: 300px;
  }

  @media (max-width: 320px) {
    width: 100px;
  }
`;

const PSpacer = styled.p`
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  color: #767676;
`;

const PanelActivityIdeasSearch = props => {
  const history = useHistory();
  const projectRoutes = useSelector(state => state.login.projectRoutes);
  const { config } = props;

  const unaddable = projectRoutes.indexOf('ActivityIdeaCreation') === -1;

  const activityIdeaTagOptions = activityIdeaTypeID => 
    props.activityIdeaTagDictionary[activityIdeaTypeID] === undefined
      ? []
      : props.activityIdeaTagDictionary[activityIdeaTypeID];

  return (
    <PanelWrapper title = "專案活動創意">
      <DivFlexRow>
        <DivFullWidthWrapper>
          <PSubtitle>專案代號</PSubtitle>
          <DivLongMultiSelector>
            <SelectSearchable
              value = { config.projectID }
              options = {[
                { value: '', desc: '請選擇專案代號' },
                ...props.projectIDOptions,
              ]}
              changeHandler = { value => {
                props.updateConfigHandler('projectID', value);
              }}
            />
          </DivLongMultiSelector>
        </DivFullWidthWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>大分類</PSubtitle>
          <SelectNormal
            value = { config.activityIdeaTypeID }
            options = {[
              { value: '', desc: '請選擇大分類' },
              ...props.activityIdeaTypes,
            ]}
            changeHandler = { props.activityIdeaTypeChangeHandler }
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>小分類</PSubtitle>
          <SelectNormal
            value = { config.tag }
            options = {[
              { value: '', desc: '請選擇小分類' },
              ...activityIdeaTagOptions(config.activityIdeaTypeID),
            ]}
            changeHandler = { value => {
              props.updateConfigHandler('tag', value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>合作廠商</PSubtitle>
          <SelectSearchable
            value = { config.vendorID }
            options = {[
              { value: '', desc: '請選擇廠商' },
              ...props.vendorIDs,
            ]}
            changeHandler = { value => {
              props.updateConfigHandler('vendorID', value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>預算</PSubtitle>
          <DivBudgetWrapper>
            <InputBudget
              value = { config.minBudget }
              onChange = {({ target }) => {
                props.updateConfigHandler('minBudget', target.value);
              }}
            />
              <PSpacer>~</PSpacer>
            <InputBudget
              value = { config.maxBudget }
              onChange = {({ target }) => {
                props.updateConfigHandler('maxBudget', target.value);
              }}
            />
          </DivBudgetWrapper>
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivButtonsWrapper>
          <ButtonAdd
            disabled = { unaddable }
            onClick = { () => { history.push('/Project/ActivityIdeaCreation') } }
          >新增</ButtonAdd>
          <SearchButtonSet
            clearHandler = { props.clearHandler }
            searchHandler = { props.searchHandler }
          />
        </DivButtonsWrapper>
      </DivFlexRow>
    </PanelWrapper>
  );
};

export default PanelActivityIdeasSearch;
