import React from 'react';
import styled from 'styled-components';

import Checkbox from '../../UI/Checkbox';
import { TableHead, TableRow, ThItem } from '../../../component-style/Table';

const THead = styled(TableHead)`
  min-width: 1120px;
`;

const Th80 = styled(ThItem)`
  width: 80px;
`;

const Th120 = styled(ThItem)`
  width: 120px;
`;

const Th160 = styled(ThItem)`
  width: 160px;
`;

const Header = props => {
  
  return (
    <THead>
      <TableRow>
        <Th80>
          <Checkbox
            checked = { props.selectAll }
            changeHandler = { props.selectAllHandler }
          />
        </Th80>
        <Th80>出席</Th80>
        <Th160>類別</Th160>
        <Th160>公司</Th160>
        <Th80>姓名</Th80>
        <Th80>確認狀況</Th80>
        <Th160>商務電話號碼</Th160>
        <Th120>行動電話</Th120>
        <Th120>分工</Th120>
        <Th80></Th80>
      </TableRow>
    </THead>
  );
};

export default Header;
