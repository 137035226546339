import styled from 'styled-components';

export const ButtonBasic = styled.button`
  cursor: ${ props => props.disabled
    ? 'not-allowed'
    : 'pointer'
  };
  opacity: ${ props => props.disabled ? 0.4 : 1 };
  width: 100%;
  height: 40px;
  border-radius: 8px;
  background-color: #de2027;
  border: none;
  outline: none;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.43px;
  color: #ffffff;
  padding: 0;
`;

export const ButtonCancel = styled(ButtonBasic)`
  color: #8b8b8b;
  background-color: #f2f2f2;
`;

export const ButtonGoDetail = styled.button`
  cursor: ${ props => props.disabled
    ? 'not-allowed'
    : 'pointer'
  };
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 4px;
  border: none;
  padding: 0;
  outline: none;
  text-align: center;
  font-size: 20px;

  &:hover {
    background-color: #d1d1d1;
  }
`;
