import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { TableHead, TableRow, ThItem } from '@/component-style/Table';

const THead = styled(TableHead)`
  min-width: 1560px;
`;

const Th80 = styled(ThItem)`
  width: 80px;
`;

const Th120 = styled(ThItem)`
  width: 120px;
`;

const Th160 = styled(ThItem)`
  width: 160px;
`;

const Th240 = styled(ThItem)`
  width: 240px;
`;

const Header = props => {
  const projectRole = useSelector(state => state.login.projectRole);
  console.log(`TableLeaveList Header.js projectRole=${projectRole}`);

  return (
    <THead>
      <TableRow>
        <Th120>申請人</Th120>
        <Th120>假別</Th120>
        <Th160>開始日期及時間</Th160>
        <Th160>結束日期及時間</Th160>
        <Th120>休假時數</Th120>
        <Th120>審核狀態</Th120>
        { 
          projectRole.includes('hr') &&
            <Th120>取消</Th120>
        }
        {/* <Th80></Th80> */}
      </TableRow>
    </THead>
  );
};

export default Header;
