import styled from 'styled-components';

export const DivFlexRow = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 0 30px;
  box-sizing: border-box;
`;

export const DivItemWrapper = styled.div`
  margin-right: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  &:last-child {
    margin-right: 0;
  }

  @media (max-width: 767px) {
    margin-right: 0;
  }
`;

export const DivFullWidthWrapper = styled(DivItemWrapper)`
  width: 100%;
  max-width: 1535px;
`;

export const DivLongMultiSelector = styled.div`
  width: calc(100% - 135px);

  @media (max-width: 767px) {
    width: 100%;
  }

  & .ant-select {
    width: 100%;
  }
`;
