import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { putNewCustomerWindow } from '@/api/customerService';
import { putNewVendorWindow } from '@/api/vendorService';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import MainContent from '@/component-style/MainContent';
import PanelContactWindowDetail from '@/components/Panel/PanelContactWindowDetail';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import { ButtonBasic, ButtonCancel } from '@/component-style/Button';
import { DivFlexRow } from '@/component-style/RWDWrapper';
import { contactWindowConfig } from '@/constant/dataConfig/informationModule';

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding-right: 0;
  padding-left: 135px;
  padding-bottom: 30px;
  justify-content: space-between;

  @media (max-width: 425px) {
    justify-content: center;
    padding-left: 0;
  }
`;

const ButtonSave = styled(ButtonBasic)`
  width: 120px;
  margin-right: 10px;
  margin-bottom: 10px;
`;

const ButtonClear = styled(ButtonCancel)`
  width: 120px;
  margin-bottom: 10px;
`;

const ContactWindowCreation = props => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const showSaveFailPopup = useSelector(state => state.apiFail.showSaveFailPopup);
  const apiFailPopupTitle = useSelector(state => state.apiFail.title);
  const apiFailPopupMessage = useSelector(state => state.apiFail.message);

  const [contactWindow, setContactWindow] = useState({
    ...contactWindowConfig,
    businessPhone: { ...contactWindowConfig.businessPhone },
    companyAddress: { ...contactWindowConfig.companyAddress },
    privateAddress: { ...contactWindowConfig.privateAddress },
  });

  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [showSaveSuccessPopup, setShowSaveSuccessPopup] = useState(false);
  
  const windowTypeKey = pathname.indexOf('Customer') !== -1
    ? 'customerID'
    : 'vendorID';

  const updateContactWindowHandler = (key, value) => {
    const updateObj = { ...contactWindow };
    updateObj[key] = value;
    setContactWindow({ ...updateObj });
  };

  const closeSuccessPopupHandler = () => {
    initContactWindowData();
    setShowSaveSuccessPopup(false);
  };

  const initContactWindowData = () => {
    setContactWindow({
      ...contactWindowConfig,
      businessPhone: { ...contactWindowConfig.businessPhone },
      companyAddress: { ...contactWindowConfig.companyAddress },
      privateAddress: { ...contactWindowConfig.privateAddress },
    });
  };

  const saveNewContactWindowHandler = async () => {
    setShowLoadingPopup(true);
    const putData = { ...contactWindow };
    putData[windowTypeKey] = id;

    try {
      windowTypeKey === 'customerID'
        ? await putNewCustomerWindow(putData)
        : await putNewVendorWindow(putData);

      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '儲存失敗', err }));
      console.log(err);
    }
  };

  return (
    <MainContent>
      <PanelContactWindowDetail
        title = "新增聯絡人資料"
        contactWindow = { contactWindow }
        updateContactWindowHandler = { updateContactWindowHandler }
      >
      {
        <DivFlexRow>
          <DivButtonsWrapper>
            <div>
              <ButtonSave
                onClick = { saveNewContactWindowHandler }
              >儲存</ButtonSave>
              <ButtonClear
                onClick = { initContactWindowData }
              >清除欄位</ButtonClear>
            </div>
            <ButtonClear
              onClick = { () => { history.goBack() } }
            >離開</ButtonClear>
          </DivButtonsWrapper>
        </DivFlexRow>
      }
      </PanelContactWindowDetail>
      {
        showSaveSuccessPopup &&
          <PopupExecuteResult
            title = "儲存成功"
            confirmHandler = { closeSuccessPopupHandler }
          />
      }
      {
        showSaveFailPopup &&
          <PopupExecuteResult
            title = { apiFailPopupTitle }
            text = { apiFailPopupMessage }
            confirmHandler = { () => {
              dispatch(CLOSE_SAVE_FAIL_POPUP());
            }}
          />
      }
      { showLoadingPopup && <PopupLoading/> }
    </MainContent>
  )
};

export default ContactWindowCreation;
