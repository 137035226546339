import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { TdItem, TableRow } from '@/component-style/Table';
import { getOptionDesc } from '@/utils/textUtils';
import { formatTime } from '@/utils/timeUtils';

const TrWrapper = styled(TableRow)`
  border-bottom: solid 2px #f2f2f2;
  box-sizing: border-box;
  min-width: 700px;

  &:last-child {
    border-bottom: none;
  }
`;

const Td120 = styled(TdItem)`
  width: 120px;
`;

const Td160 = styled(TdItem)`
  width: 160px;
`;

const Td300 = styled(TdItem)`
  width: 300px;
`;

const Item = props => {
  const { record } = props;
  const memberOptions = useSelector(state => state.srpOptions.memberOptions);

  const username = getOptionDesc(memberOptions, record.approval);

  const time = record.time === '0001-01-01T00:00:00.000Z'
    ? '' : formatTime(record.time, 'YYYY/MM/DD HH:mm:ss');

  return (
    <TrWrapper>
      <Td120>{ username }</Td120>
      <Td120>{ record.status }</Td120>
      <Td160>{ time }</Td160>
      <Td300>{ record.suggestion }</Td300>
    </TrWrapper>
  );
};

export default Item;
