import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { postToGetUserInfo } from '@/api/authService';
import { getAttendanceDetail } from '@/api/attendanceService';

import MainContent from '@/component-style/MainContent';
import TableWrapper from '@/components/UI/TableWrapper';
import DateSelector from '@/components/UI/DateSelector';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import { InputBasic } from '@/component-style/Input';
import { ButtonCancel } from '@/component-style/Button';
import { PSubtitle } from '@/component-style/TextElement';
import {
  DivFlexRow,
  DivItemWrapper,
} from '@/component-style/RWDWrapper';

import { formatTime } from '@/utils/timeUtils';

const ButtonClear = styled(ButtonCancel)`
  width: 120px;
`;

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding-right: 0;
  padding-left: 135px;
  padding-bottom: 30px;
  justify-content: flex-end;

  @media (max-width: 425px) {
    justify-content: center;
    padding-left: 0;
  }
`;

const PunchTimeDetail = () => {
  const { id, date } = useParams();
  const history = useHistory();

  const [punchTime, setPunchTime] = useState({
    username: '',
    companyType: '',
    date: null,
    startTime: null,
    endTime: null,
  });

  const [showLoadingPopup, setShowLoadingPopup] = useState(false);

  const fetchPunchTimeDetail = async () => {
    setShowLoadingPopup(true);

    try {
      const { data } = await getAttendanceDetail(id, date);
      const { startTime, endTime, ...remainItems } = data;
      const userInfo = await postToGetUserInfo(id);
      const { name } = userInfo.data;
      setPunchTime(prevState => ({
        ...prevState,
        ...remainItems,
        username: name,
        endTime: formatTime(endTime, 'YYYY/MM/DD HH:mm:ss'),
        startTime: formatTime(startTime, 'YYYY/MM/DD HH:mm:ss'),
      }));

    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const initPunchTimeData = useCallback(
    fetchPunchTimeDetail, []);

  useEffect(() => {
    initPunchTimeData();
  }, [initPunchTimeData]);

  return (
    <MainContent>
      <TableWrapper title = "打卡記錄明細" >
        <DivFlexRow>
          <DivItemWrapper>
            <PSubtitle>員工</PSubtitle>
            <InputBasic
              disabled = { true }
              value = { punchTime.username }
            />
          </DivItemWrapper>
          <DivItemWrapper>
            <PSubtitle>打卡日期</PSubtitle>
            <DateSelector
              disabled = { true }
              value = { punchTime.date }
            />
          </DivItemWrapper>
        </DivFlexRow>
        <DivFlexRow>
          <DivItemWrapper>
            <PSubtitle>上班打卡時間</PSubtitle>
            <DateSelector
              disabled = { true }
              value = { punchTime.startTime }
              showTime = { true }
            />
          </DivItemWrapper>
          <DivItemWrapper>
            <PSubtitle>下班打卡時間</PSubtitle>
            <DateSelector
              disabled = { true }
              value = { punchTime.endTime }
              showTime = { true }
            />
          </DivItemWrapper>
        </DivFlexRow>
        <DivFlexRow>
          <DivButtonsWrapper>
            <ButtonClear
              onClick = { () => { history.goBack() }}
            >離開</ButtonClear>
          </DivButtonsWrapper>
        </DivFlexRow>
      </TableWrapper>
      { showLoadingPopup && <PopupLoading/> }
    </MainContent>
  )
};

export default PunchTimeDetail;
