import React from 'react';
import styled from 'styled-components';

import { TableHead, TableRow, ThItem } from '@/component-style/Table';

const THead = styled(TableHead)`
  min-width: 1360px;
`;

const Th120 = styled(ThItem)`
  width: 120px;
`;

const Th160 = styled(ThItem)`
  width: 160px;
`;

const Header = props => {
  
  return (
    <THead>
      <TableRow>
        <Th120>專案代號</Th120>
        <Th160>專案名稱</Th160>
        <Th120>總監</Th120>
        <Th120>專案負責人</Th120>
        <Th120>MBR 日期</Th120>
        <Th120>服務費</Th120>
        <Th120>專案支出費用</Th120>
        <Th120>專案其它收入</Th120>
        <Th120>入帳金額</Th120>
        <Th120>支出金額</Th120>
        <Th120>預計入帳日</Th120>
      </TableRow>
    </THead>
  );
};

export default Header;
