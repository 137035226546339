import React from 'react';
import styled from 'styled-components';

import SelectNormal from '@/components/UI/Select/SelectNormal';
import DateSelector from '@/components/UI/DateSelector';
import Checkbox from '@/components/UI/Checkbox';
import { InputBasic } from '@/component-style/Input';
import { PSubtitle } from '@/component-style/TextElement';
import RemoveImg from '@/assets/images/icon_remove.svg';

import { integerConverter } from '@/utils/textUtils';
import { getUntaxedPrice } from '@/utils/mathUtils';

const DivFWrapper = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  border-bottom: 2px dashed #e5e5e5;
`;

const CheckboxWrapper = styled(DivFWrapper)`
  height: 40px;
  align-items: center;
  border-bottom: none;
`;

const DivInputWrapper = styled(DivFWrapper)`
  border-bottom: none;
`;

const InputWrapper = styled.div`
  margin-right: 20px;
  margin-bottom: 10px;

  @media (max-width: 375px) {
    margin-right: 0;
  }

  @media (min-width: 1920px) {
    .ant-select, .datePicker { width: 300px; }
  }

  @media (max-width: 320px) {
    .ant-select, .datePicker { width: 260px; }
  };
`;

const DeleteButtonWrapper = styled(InputWrapper)`
  margin-top: 20px;
`;

const Input = styled(InputBasic)`
  @media (max-width: 320px) { width: 260px; }
  @media (min-width: 1920px) { width: 300px }
`;

const PLabel = styled(PSubtitle)`
  width: auto;
  height: 20px;
  line-height: 20px;
`;

const DivIconRemove = styled.div`
  cursor: pointer;
  width: 20px;
  height: 40px;
  background-image: url(${ props => props.url });
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`;

const QuotationInvoice = props => {
  const { invoice } = props;

  const updateInvoiceHandler = (key, value) => {
    const updateObj = { ...invoice };
    updateObj[key] = value;
    props.invoiceChangeHandler(updateObj);
  };

  const amountChangeHandler = amount => {
    const untaxedAmount = getUntaxedPrice(integerConverter(amount), 5);
    const tax = integerConverter(amount) - untaxedAmount;

    const updateObj = { ...invoice };
    updateObj.tax = tax;
    updateObj.amount = amount;
    updateObj.untaxedAmount = untaxedAmount;
    props.invoiceChangeHandler(updateObj);
  };

  return(
    <DivFWrapper>
      <CheckboxWrapper>
        <Checkbox
          title = "不匯出會計系統"
          disabled = { props.disabled }
          checked = { !invoice.accountingExportable }
          changeHandler = { value => {
            updateInvoiceHandler('accountingExportable', !value);
          }}
        />
      </CheckboxWrapper>
      <DivInputWrapper>
        <InputWrapper>
          <PLabel>發票號碼</PLabel>
          <Input
            disabled = { props.disabled }
            value = { invoice.invoiceCode || null }
            onChange = { ({ target }) => {
              updateInvoiceHandler('invoiceCode', target.value);
            }}
          />
        </InputWrapper>
        <InputWrapper>
          <PLabel>發票開立日期</PLabel>
          <DateSelector
            disabled = { props.disabled }
            value = { invoice.createDate || null }
            changeHandler = { value => {
              updateInvoiceHandler('createDate', value);
            }}
          />
        </InputWrapper>
        <InputWrapper>
          <PLabel>未稅金額</PLabel>
          <Input
            type = "number"
            disabled = { props.disabled }
            value = { invoice.untaxedAmount || null }
            onChange = { ({ target }) => {
              updateInvoiceHandler('untaxedAmount', target.value);
            }}
          />
        </InputWrapper>
        <InputWrapper>
          <PLabel>稅額</PLabel>
          <Input
            type = "number"
            disabled = { props.disabled }
            value = { invoice.tax || null }
            onChange = { ({ target }) => {
              updateInvoiceHandler('tax', target.value);
            }}
          />
        </InputWrapper>
        <InputWrapper>
          <PLabel>發票金額</PLabel>
          <Input
            type = "number"
            disabled = { props.disabled }
            value = { invoice.amount || null }
            onChange = { ({ target }) => {
              amountChangeHandler(target.value)
            }}
          />
        </InputWrapper>
        <InputWrapper>
          <PLabel>發票統編</PLabel>
          <Input
            disabled = { props.disabled }
            value = { invoice.invoiceTaxId }
            onChange = { ({ target }) => {
              updateInvoiceHandler('invoiceTaxId', target.value);
            }}
          />
        </InputWrapper>
        <InputWrapper>
          <PLabel>備註</PLabel>
          <Input
            disabled = { props.disabled }
            value = { invoice.hasOwnProperty('note') ? invoice.note : "" }
            onChange = { ({ target }) => {
              updateInvoiceHandler('note', target.value);
            }}
          />
        </InputWrapper>
        <InputWrapper>
          <PLabel>客戶採購編號Po</PLabel>
          <SelectNormal
            disabled = { props.disabled }
            value = { invoice.po }
            options = {[
              { value: '', desc: '請選擇採購PO' },
              ...props.poOptions,
            ]}
            changeHandler = { value => {
              updateInvoiceHandler('po', value);
            }}
          />
        </InputWrapper>
        <InputWrapper>
          <PLabel>預計入帳日</PLabel>
          <DateSelector
            disabled = { props.disabled }
            value = { invoice.predictDate || null }
            changeHandler = { value => {
              updateInvoiceHandler('predictDate', value);
            }}
          />
        </InputWrapper>
        <InputWrapper>
          <PLabel>實際入帳日</PLabel>
          <DateSelector
            disabled = { props.disabled }
            value = { invoice.paymentDate || null }
            changeHandler = { value => {
              updateInvoiceHandler('paymentDate', value);
            }}
          />
        </InputWrapper>
        <InputWrapper>
          <PLabel>入帳金額</PLabel>
          <Input
            type = "number"
            disabled = { props.disabled }
            value = { invoice.hasOwnProperty('income') ? invoice.income : "" }
            onChange = { ({ target }) => {
              updateInvoiceHandler('income', target.value);
            }}
          />
        </InputWrapper>
        <InputWrapper>
          <PLabel>業績認列日</PLabel>
          <DateSelector
            disabled = { props.disabled }
            value = { invoice.mbr || null }
            changeHandler = { value => {
              updateInvoiceHandler('mbr', value);
            }}
          />
        </InputWrapper>
        {
          props.disabled !== true &&
            <DeleteButtonWrapper>
              <DivIconRemove
                disabled = { props.disabled }
                url = { RemoveImg }
                onClick = { props.removeHandler }
              />
            </DeleteButtonWrapper>
        }
      </DivInputWrapper>
    </DivFWrapper>
  );
};

export default QuotationInvoice;
