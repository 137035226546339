import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import TableWrapper from '@/components/UI/TableWrapper';
import DateSelector from '@/components/UI/DateSelector';
import SelectNormal from '@/components/UI/Select/SelectNormal';
import SelectMultiple from '@/components/UI/Select/SelectMultiple';
import SelectSearchable from '@/components/UI/Select/SelectSearchable';
import SearchButtonSet from '@/components/UI/SearchButtonSet';
import { PSubtitle } from '@/component-style/TextElement';
import { ButtonBasic } from '@/component-style/Button';
import {
  DivFlexRow,
  DivItemWrapper,
  DivFullWidthWrapper,
  DivLongMultiSelector,
} from '@/component-style/RWDWrapper';
import RadioGroup from '@/components/UI/Radio/RadioGroup';

import { formatTime } from '@/utils/timeUtils';

import { leaveApprovalOptions } from '@/constant/options';

import moment from 'moment';

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding: 0;
  padding-bottom: 30px;
  justify-content: space-between;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const ButtonExport = styled(ButtonBasic)`
  width: 120px;

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`;

const PanelLeaveSearch = props => {
  // const attendanceRoutes = useSelector(state => state.login.attendanceRoutes);
  const { config, userFilter, workingHoursTagDictionary } = props;
  console.log(`PanelLeaveSearch index.js JSON.stringify(config)=${JSON.stringify(config)}`);

  const projectRole = useSelector(state => state.login.projectRole);
  console.log(`PanelLeaveSearch index.js projectRole=${projectRole}`);

  return (
    <TableWrapper title = "休假總表" >
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>
            起始日期
            <span>*</span>
          </PSubtitle>
          <DateSelector
            // value = { config.leaveFrom }
            value = { config.leaveFrom == null ? null : moment.parseZone(config.leaveFrom).format('YYYY/MM/DD') }
            changeHandler = { value => {
              props.updateConfigHandler(
                'leaveFrom',
                formatTime(value, 'YYYY-MM-DD'),
              );
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>
            結束時間
            <span>*</span>
          </PSubtitle>
          <DateSelector
            // value = { config.leaveTo }
            value = { config.leaveTo == null ? null : moment.parseZone(config.leaveTo).format('YYYY/MM/DD') }
            changeHandler = { value => {
              props.updateConfigHandler(
                'leaveTo',
                formatTime(value, 'YYYY-MM-DD'),
              );
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>審核狀態</PSubtitle>
          <SelectNormal
            value = { config.status }
            options = {
              leaveApprovalOptions
            }
            changeHandler = { value => {
              props.updateConfigHandler('status', value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>同仁篩選</PSubtitle>
          <SelectNormal
            value = { config.userFilter }
            options = { props.userFilterOptions }
            changeHandler = { value => {
              props.updateConfigHandler('userFilter', value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      {
        userFilter === 'team' &&
        <DivFlexRow>
          <DivFullWidthWrapper>
            <PSubtitle>使用者</PSubtitle>
            <DivLongMultiSelector>
              <SelectMultiple
                disabled = { userFilter !== 'users' }
                value = { config.userIDs }
                options = {[ ...props.memberOptions ]}
                changeHandler = { value => {
                  props.updateConfigHandler('userIDs', [...value]);
                }}
              />
            </DivLongMultiSelector>
          </DivFullWidthWrapper>
        </DivFlexRow>
      }
      <DivFlexRow>
        <DivButtonsWrapper>
          {/* <ButtonExport
            disabled = { !userExportable }
            onClick = { props.exportHandler }
          >匯出</ButtonExport> */
          <RadioGroup
            // disabled = { props.disabled }
            value = { props.viewMode }
            options = {[
              { value: 'list', desc: '列表' },
              { value: 'calendar', desc: '月曆' },
            ]}
            changeHandler = { value => {
              props.updateViewModeHandler(value);
            }}
          />}
          {<br></br>}
          <SearchButtonSet
            searchable = { config.leaveFrom != null && config.leaveTo != null }
            searchHandler = { props.searchHandler }
            clearHandler = { props.clearHandler }
          />
        </DivButtonsWrapper>
      </DivFlexRow>
    </TableWrapper>
  );
};

export default PanelLeaveSearch;
