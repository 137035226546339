import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons';

import { postToGetScheduleTimeConfig } from '@/api/attendanceService';

import {
  deleteWorkingHours,
  putModifyWorkingHours,
  postToGetWorkingHours,
} from '@/api/attendanceService';

import {
  patchModifyLeaveApplicationById,
} from '@/api/financeService';

import {
  deleteUploadFile,
  postNewUploadFile,
  postToGetUploadFiles,
} from '@/api/storageService';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import {
  FETCH_PROJECT_ID_OPTIONS,
  FETCH_WORKINGHOURS_TYPE_OPTIONS,
} from '@/store/actions/srpOptionsAction';

import MainContent from '@/component-style/MainContent';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import PopupExecuteConfirm from '@/components/UI/Popup/PopupExecuteConfirm';
import PanelLeaveDetail from '@/components/Panel/PanelLeaveDetail';
import { ButtonBasic, ButtonCancel } from '@/component-style/Button';
import { DivFlexRow } from '@/component-style/RWDWrapper';

import { overtimeConfig } from '@/constant/dataConfig/attendanceModule';
import { leaveApplicationConfig } from '@/constant/dataConfig/financeModule';
import {
  diffTime,
  toUTCTime,
  formatTime,
  getEndOfUnit,
  getStartOfUnit,
  getOtStatus,
  diffTimeWithFloat,
  diffTimeSubtractLunchBreak,
} from '@/utils/timeUtils';

import { postToGetUserInfo } from '@/api/authService';

import {
  getLeaveApplicationById,
  getCalculateLeaveDurationForUser,
  // getLeaveSetting,
  getLeave,
} from '@/api/financeService';

import moment from 'moment';

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding-right: 0;
  padding-bottom: 20px;
  justify-content: flex-end;

  @media (max-width: 425px) {
    justify-content: center;
    padding-left: 0;
  }
`;

const ButtonSave = styled(ButtonBasic)`
  margin-left: 10px;
  margin-top: 10px;
  width: 120px;

  @media (max-width: 425px) {
    &:nth-child(2n+1) { margin-left: 0 }
  }
`;

const ButtonClear = styled(ButtonCancel)`
  margin-left: 10px;
  margin-top: 10px;
  width: 120px;
`;

const LeaveDetail = () => {
  const userID = useSelector(state => state.login.token);

  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const projectIDOptions = useSelector(state => state.srpOptions.projectIDOptions);
  const workingHoursTypeOptions = useSelector(state => state.srpOptions.workingHoursTypeOptions);
  const workingHoursTagDictionary = useSelector(state => state.srpOptions.workingHoursTagDictionary);
  const showSaveFailPopup = useSelector(state => state.apiFail.showSaveFailPopup);
  const apiFailPopupTitle = useSelector(state => state.apiFail.title);
  const apiFailPopupMessage = useSelector(state => state.apiFail.message);

  const [showAlertMessage, setShowAlertMessage] = useState("");

  const [overtime, setOvertime] = useState({ ...overtimeConfig });
  const [overtimeParams, setOvertimeParam] = useState({
    overTimeLimit: 0,
    freeTimeLimit: 0,
    extraPayLimit: 0,
  });

  const [leaveApplication, setLeaveApplication] = useState({ ...leaveApplicationConfig });
  
  const [leaveSettings, setLeaveSettings] = useState([]);
  const [leaveSettinsOptions, setLeaveSettinsOptions] = useState([]);

  const [isEditing, setIsEditing] = useState(false);
  const [showAlertPopup, setShowAlertPopup] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showApprovement, setShowApprovement] = useState(false);
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [showSaveSuccessPopup, setShowSaveSuccessPopup] = useState(false);
  const [showDeleteSuccessPopup, setShowDeleteSuccessPopup] = useState(false);
  const [showCancelSuccessPopup, setShowCancelSuccessPopup] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  const [overtimeRecords, setOvertimeRecords] = useState([]);

  const overtimeMinutes = diffTimeSubtractLunchBreak(overtime.startTimeLocal, overtime.endTimeLocal, 'minutes') || 0;

  const [approvedUser, setApprovedUser] = useState();

  const [showUploadSuccessPopup, setShowUploadSuccessPopup] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [uploadFiles, setUploadFiles] = useState([]);
  const [showDeleteFilePopup, setShowDeleteFilePopup] = useState(false);
  const [deleteFileId, setDeleteFileId] = useState('');
  const [showDeleteFileSuccessPopup, setShowDeleteFileSuccessPopup] = useState(false);

  const updateOvertimeHandler = (key, value) => {
    const updateObj = { ...overtime };
    updateObj[key] = value;
    setOvertime({...updateObj});
  };

  const typeChangeHandler = value => {
    setOvertime(prevState => ({
      ...prevState,
      typeID: value,
      tag: '',
    }));
  };

  // const isOvertimeAlert = mins => mins > overtimeParams.overTimeLimit * 60
  //   || (overtime.overtimeType === 'freeTime' && mins > overtimeParams.freeTimeLimit * 60)
  //   || (overtime.overtimeType === 'extraPay' && mins > overtimeParams.extraPayLimit * 60);

  // const isOvertimeAlertTwo = (overtimeMinutes, appliedOvertimeMinutes) => overtimeMinutes > overtimeParams.overTimeLimit * 60
  //       || (overtimeMinutes + appliedOvertimeMinutes) > overtimeParams.freeTimeLimit * 60
    // || (overtime.overtimeType === 'freeTime' && mins > overtimeParams.freeTimeLimit * 60)
    // || (overtime.overtimeType === 'extraPay' && mins > overtimeParams.extraPayLimit * 60);

  const isOvertimeAlert = (overtimeDateType, overtimeMinutes, appliedOvertimeMinutes) => 
  {
    // console.log("isOvertimeAlert overtimeParams:" + JSON.stringify(overtimeParams));
    console.log("isOvertimeAlert overtimeDateType:" + overtimeDateType);
    var boolOvertimeHourOverLimit = false;
    let thisDayOvertimerWorkingHours = 0;
    for (const objOvertimeRecord of overtimeRecords) {
      console.log("isOvertimeAlert objOvertimeRecord.startTimeLocal" + objOvertimeRecord.startTimeLocal);
      thisDayOvertimerWorkingHours += diffTimeWithFloat(objOvertimeRecord.startTimeLocal, objOvertimeRecord.endTimeLocal, 'hours')
    }
    console.log("isOvertimeAlert thisDayOvertimerWorkingHours:" + thisDayOvertimerWorkingHours);
    if (overtimeDateType === "workdays") {
      if (thisDayOvertimerWorkingHours > overtimeParams.overTimeLimit) {
        boolOvertimeHourOverLimit = true;
      }
    } else {
      if (thisDayOvertimerWorkingHours > 12) {
        boolOvertimeHourOverLimit = true;
      }
    }
    return boolOvertimeHourOverLimit;
  };

  const submitHandler = async () => {
    setShowLoadingPopup(true);
    const { workingDate, userID } = overtime;

    try {
      const { data } = await postToGetWorkingHours({
        isOverTime: true,
        userIDs: [userID],
        startDate: formatTime(getStartOfUnit(workingDate, 'month'), 'YYYY-MM-DD'),
        endDate: formatTime(getEndOfUnit(workingDate, 'month'), 'YYYY-MM-DD'),
        amount: 500,
        page: 1,
      });

      const appliedOvertimeMinutes = data.workingHours
        .map(({ startTimeLocal, endTimeLocal }) => ({ startTimeLocal, endTimeLocal }))
        .reduce((acc, { startTimeLocal, endTimeLocal }) =>
          acc + diffTime(startTimeLocal, endTimeLocal, 'minutes')
        , 0);

      setShowLoadingPopup(false);

      // console.log("submitHandler overtimeParams:" + JSON.stringify(overtimeParams));
      // console.log("submitHandler appliedOvertimeMinutes:" + appliedOvertimeMinutes);
      // console.log("submitHandler overtimeMinutes:" + overtimeMinutes);
      
      isOvertimeAlert(overtime.overtimeDateType, overtimeMinutes, appliedOvertimeMinutes)
        ? setShowAlertPopup(true)
        : submitModifiedOvertime();

    } catch (err) {
      setShowLoadingPopup(false);
      console.log(err)
    };
  };

  const saveHandler = async () => {
    setShowLoadingPopup(true);
    const { workingDate, userID } = overtime;

    try {
      const { data } = await postToGetWorkingHours({
        isOverTime: true,
        userIDs: [userID],
        startDate: formatTime(getStartOfUnit(workingDate, 'month'), 'YYYY-MM-DD'),
        endDate: formatTime(getEndOfUnit(workingDate, 'month'), 'YYYY-MM-DD'),
        amount: 500,
        page: 1,
      });

      const appliedOvertimeMinutes = data.workingHours
        .map(({ startTimeLocal, endTimeLocal }) => ({ startTimeLocal, endTimeLocal }))
        .reduce((acc, { startTimeLocal, endTimeLocal }) =>
          acc + diffTime(startTimeLocal, endTimeLocal, 'minutes')
        , 0);

      setShowLoadingPopup(false);

      isOvertimeAlert(overtime.overtimeDateType, overtimeMinutes, appliedOvertimeMinutes)
        ? setShowAlertPopup(true)
        : saveModifiedOvertime();

    } catch (err) {
      setShowLoadingPopup(false);
      console.log(err)
    };
  };

  const modifyLeaveApplicationHandler = async () => {
    setShowLoadingPopup(true);
    
    try {
      const { data } = await patchModifyLeaveApplicationById(id, {
        description: leaveApplication.description,
        leaveFrom: formatTime(moment.parseZone(leaveApplication.leaveFrom), 'YYYY-MM-DD HH:mm:ss'),
        leaveTo: formatTime(moment.parseZone(leaveApplication.leaveTo), 'YYYY-MM-DD HH:mm:ss'),
        // leaveFrom: formatTime(leaveApplication.leaveFrom, 'YYYY-MM-DD HH:mm:ss'),
        // leaveTo: formatTime(leaveApplication.leaveTo, 'YYYY-MM-DD HH:mm:ss'),
      });
      setShowLoadingPopup(false);
      setShowSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      console.log(err)
    };
  }

  const saveModifiedOvertime = async () => {
    setShowLoadingPopup(true);

    console.log("overtimeDetail.js saveModifiedOvertime overtime.workingDate:" + formatTime(overtime.workingDate, 'YYYY-MM-DD'));
    getOtStatus(overtime);
    try {
      await putModifyWorkingHours({
        ...overtime,
        workingDate: formatTime(overtime.workingDate, 'YYYY-MM-DD'),
        startTimeLocal: formatTime(overtime.startTimeLocal),
        endTimeLocal: formatTime(overtime.endTimeLocal),
        freeTimeMins: overtime.overtimeType === 'freeTime' ? overtimeMinutes : 0,
        extraPayMins: overtime.overtimeType === 'extraPay' ? overtimeMinutes : 0,
      });
      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '更新失敗', err }));
      console.log(err);
    }
  };

  const submitModifiedOvertime = async () => {
    try {
      await putModifyWorkingHours({
        ...overtime,
        workingDate: formatTime(overtime.workingDate, 'YYYY-MM-DD'),
        startTimeLocal: formatTime(overtime.startTimeLocal),
        endTimeLocal: formatTime(overtime.endTimeLocal),
        submitDate: formatTime(Date.now(), 'YYYY-MM-DD'),
        approvementStatus: 'unapproved',
        freeTimeMins: overtime.overtimeType === 'freeTime' ? overtimeMinutes : 0,
        extraPayMins: overtime.overtimeType === 'extraPay' ? overtimeMinutes : 0,
      });
      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '更新失敗', err }));
      console.log(err);
    }
  };

  const deleteOvertimesHandler = async () => {
    setShowDeletePopup(false);
    setShowLoadingPopup(true);

    try {
      await deleteWorkingHours([overtime.workingHoursID]);
      setShowLoadingPopup(false);
      setShowDeleteSuccessPopup(true);
    } catch(err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '刪除失敗', err }));
      console.log(err);
    }
  };

  const unsubmitHandler = async () => {
    setShowLoadingPopup(true);

    try {
      await putModifyWorkingHours({
        ...overtime,
        approvementStatus: 'draft',
      });
      setShowLoadingPopup(false);
      setShowCancelSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '取消失敗', err }));
      console.log(err);
    }
  };

  const fetchOvertimeParams = async () => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetScheduleTimeConfig();
      const { overTimeLimit, freeTimeLimit, extraPayLimit } = data;

      setOvertimeParam(prevState => ({
        ...prevState,
        overTimeLimit,
        freeTimeLimit,
        extraPayLimit,
      }));

    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const fetchOvertimeHoursInMonth = async(userID, startDate, endDate) => {
    const { data } = await postToGetWorkingHours({
      isOverTime: true,
      userIDs: [userID],
      // startDate: moment(startDate).startOf('month').format('YYYY-MM-DD'),
      // endDate: moment(endDate).endOf('month').format('YYYY-MM-DD'),
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD'),
      amount: 1000,
      page:1,
    });
    const { workingHours } = data;
    const overtimeRecords = [];

    for (let i = 0; i < workingHours.length; i += 1 ) {
      overtimeRecords.push({
        ...workingHours[i],
      });
    }

    setOvertimeRecords([ ...overtimeRecords ]);
    // console.log("fetchOvertimeHoursInMonth data:" + JSON.stringify(data));
  };

  const fetchLeaveInfo = async () => {
    setShowLoadingPopup(true);

    try {
      // const { data } = await postToGetWorkingHoursDetail(id);
      // console.log("LeaveDetail.js fetchOvertimeInfo id:" + id);
      const { data } = await getLeaveApplicationById(id);
      await fetchUploadFiles();
      console.log(`LeaveDetail.js fetchOvertimeInfo data=${JSON.stringify(data)}`);
      setLeaveApplication(data);
      // if (!(data.approvementUserID == null)) {
      //   console.log("overtimeDetail.js data:" + JSON.stringify(data));
      //   const userInfo = await postToGetUserInfo(data.approvementUserID);
      //   console.log("overtimeDetail.js userInfo:" + JSON.stringify(userInfo));
      //   setApprovedUser(userInfo.data);
      // }
      // data.approvementUserID === ''
      //   ? delete data.approvementUserID
      //   : setShowApprovement(true);
      // delete data.endTime;
      // delete data.startTime;
      // setOvertime(prevState => ({ ...prevState, ...data}));

      // fetchOvertimeHoursInMonth(data.userID, data.startDate, data.endDate);
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const fetchLeaveSetting = async () => {
    setShowLoadingPopup(true);

    try {
      const { data } = await getLeave();
      console.log(`leaveDetail.js data=${JSON.stringify(data)}`);
      setLeaveSettings(data.leaves);
      const sorted = data.leaves.map(element => {
        return {value: element.id, desc: element.name };
      });
      console.log(`leaveDetail.js sorted=${JSON.stringify(sorted)}`);
      setLeaveSettinsOptions(sorted);
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const initLeaveSetting = useCallback(
    fetchLeaveSetting, []);

  const initLeaveInfo = useCallback(
    fetchLeaveInfo, []);

  const initOvertimeParams = useCallback(
    fetchOvertimeParams, []);

  const initOptions = useCallback(() => {
    dispatch(FETCH_PROJECT_ID_OPTIONS());
    dispatch(FETCH_WORKINGHOURS_TYPE_OPTIONS());
  }, [dispatch]);

  useEffect(() => {
    initOptions();
    initLeaveInfo();
    initOvertimeParams();
    initLeaveSetting();
  }, [initOptions, initLeaveInfo, initOvertimeParams, initLeaveSetting]);

  useEffect(() => {
    console.log("useEffect overtime.startTime:" + overtime.startTime);
  });

  const fetchUploadFiles = async () => {
    setShowLoadingPopup(true);
    console.log(`leaveDetail.js id=${JSON.stringify(id)}`);
    try {
      const { data } = await postToGetUploadFiles('leaveApplicationAttachment', id);
      setFileList([ ...data ]);
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const fileUploadHandler = async () => {
    // showPopupAttachment(false);
    setShowLoadingPopup(true);

    try {
      const [file] = uploadFiles;
      const formData = new FormData();
      formData.append('upfile', file);
      formData.append('objectType', 'leaveApplicationAttachment');
      formData.append('objectID', id);
      formData.append('name', file.name);
      await postNewUploadFile(formData);
      await fetchUploadFiles();
      setShowLoadingPopup(false);
      setShowUploadSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '上傳失敗', err }));
      console.log(err);
    };
  };

  const setUpoloadFilesHandler = (files) => {
    console.log(`leaveDetail.js setUpoloadFilesHandler files=${files}`);
    setUploadFiles([ ...files ]);
  };

  const uploadSuccessConfirmHandler = () => {
    setUploadFiles([]);
    setShowUploadSuccessPopup(false);
    // setShowAttachmentsPopup(true);
  };

  const deleteFileHandler = id => {
    setDeleteFileId(id);
    setShowDeleteFilePopup(true);
  };

  const deleteFile = async () => {
    setShowDeleteFilePopup(false);
    setShowDeleteFileSuccessPopup(false);
    setShowLoadingPopup(true);

    try {
      await deleteUploadFile(deleteFileId);
      await fetchUploadFiles();
      setShowLoadingPopup(false);
      setShowDeleteFileSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '刪除檔案失敗', err }));
      console.log(err);
    }
  };

  const updateLeaveApplicationHandler = (key, value) => {
    console.log(`leaveApplication.js updateLeaveApplicationHandler key=${key}&value=${value}`);
    const updateObj = { ...leaveApplication };
    updateObj[key] = value;
    setLeaveApplication({...updateObj});
  };

  const calculateLeaveHour = async () => {
    setShowLoadingPopup(true);

    // console.log("leaveApplication.js calculateLeaveHour() JSON.stringify(leaveApplication):" + JSON.stringify(leaveApplication));

    try {
      const dataForCalculateLeaveDuration = {};
      dataForCalculateLeaveDuration.leaveId = leaveApplication.leaveId;
      dataForCalculateLeaveDuration.leaveFrom = formatTime(moment.parseZone(leaveApplication.leaveFrom), 'YYYY-MM-DD HH:mm:ss');
      dataForCalculateLeaveDuration.leaveTo = formatTime(moment.parseZone(leaveApplication.leaveTo), 'YYYY-MM-DD HH:mm:ss');
      console.log(`leaveDetail.js calculateLeaveHour() JSON.stringify(leaveApplication)=${JSON.stringify(leaveApplication)}`);
      const singleLeaveSetting = leaveSettings.find((element) => element.id === leaveApplication.leaveId);
      console.log(`leaveDetail.js calculateLeaveHour() JSON.stringify(leaveApplication)=${JSON.stringify(leaveApplication)}`);
      //Liya TG 2024/10/29: Yes, but if there is present "allocationId" in leave application details you need to pass allocationId to "leaveId" and set "isAllocated" to true
      if (leaveApplication.hasOwnProperty('allocationId')) {
        dataForCalculateLeaveDuration.isAllocated = true;
      } else {
        dataForCalculateLeaveDuration.isAllocated = false;
      }
      dataForCalculateLeaveDuration.excludedLeaveApplicationId = singleLeaveSetting.leaveId;
      console.log(`leaveDetail.js calculateLeaveHour() JSON.stringify(dataForCalculateLeaveDuration) ${JSON.stringify(dataForCalculateLeaveDuration)}`);
      const {data} = await getCalculateLeaveDurationForUser(userID, dataForCalculateLeaveDuration);
      console.log(`leaveDetail.js calculateLeaveHour() JSON.stringify(data): + JSON.stringify(data)`);
      // setLeaveHours(data.durationInHours);
      updateLeaveApplicationHandler("durationInSeconds", data.durationInSeconds);

      setShowLoadingPopup(false);
    } catch (err) {
      // console.log(JSON.stringify(err));
      console.log(`calculateLeaveHour() ${JSON.stringify(err.response)}`);
      console.log(`calculateLeaveHour() ${err.response.data.code}`);
      setShowLoadingPopup(false);
      if (err.response.data.code === 400) {
        setShowAlertMessage("請假日為假日");
        setShowAlertPopup(true);
      } else if (err.response.data.code === 1410) {
        setShowAlertMessage("此假別時數不足");
        setShowAlertPopup(true);
      } else if (err.response.data.code === 1409) {
        setShowAlertMessage("請假時數小於最低請假時數");
        setShowAlertPopup(true);
      } else if (err.response.data.code === 1412) {
        setShowAlertMessage("請假時間為午休");
        setShowAlertPopup(true);
      } else if (err.response.data.code === 1414) {
        setShowAlertMessage("此假別不適用您所屬部門");
        setShowAlertPopup(true);
      } else {
        // console.log(`leaveApplication.js calculateLeaveHour()`);
        dispatch(OPEN_SAVE_FAIL_POPUP({ title: '新增失敗', err }));
      }
      console.log(err);
    }
  };

  useEffect(() => {
    if (leaveApplication.leaveFrom !== null && leaveApplication.leaveTo !== null && leaveApplication.leaveId !== "") {
      console.log(`leaveDetail.js useEffect() going to call calculateLeavHour()`);
      // console.log(`leaveApplication.js useEffect() JSON.stringify(leaveApplication)=${JSON.stringify(leaveApplication)}`);
      calculateLeaveHour();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leaveApplication.leaveFrom, leaveApplication.leaveTo]);

  return (
    <MainContent>
      <PanelLeaveDetail
        title = "休假明細"
        disabled = { overtime.status === 'approved' }
        disableDate = { true }
        leaveApplication = { leaveApplication }
        overtime = { overtime }
        showApprovement = { showApprovement }
        projectIDOptions = { projectIDOptions }
        workingHoursTypeOptions = { workingHoursTypeOptions }
        workingHoursTagDictionary = { workingHoursTagDictionary }
        typeChangeHandler = { typeChangeHandler }
        updateOvertimeHandler = { updateOvertimeHandler }
        approvedUser = { approvedUser }
        leaveSettinsOptions = { leaveSettinsOptions }
        updateLeaveApplicationHandler = { updateLeaveApplicationHandler }

        fileUploadHandler = { fileUploadHandler }
        fileList = { fileList }
        uploadFiles = { uploadFiles }
        setUpoloadFilesHandler = { setUpoloadFilesHandler }
        deleteFileHandler = { deleteFileHandler }
      >
        <DivFlexRow>
          <DivButtonsWrapper>
            {
              (overtime.approvementStatus === 'draft'
                || overtime.approvementStatus === 'disagree') &&
                <React.Fragment>
                  {/* {
                    isEditing
                    ? <>
                        <ButtonSave
                          onClick = { saveHandler }
                          disabled = {  overtime.startTimeLocal === null
                            || overtime.endTimeLocal === null
                            || overtimeMinutes <= 0
                          }
                        >儲存</ButtonSave>
                        <ButtonSave
                          onClick = { submitHandler }
                          disabled = {  overtime.startTimeLocal === null
                            || overtime.endTimeLocal === null
                            || overtimeMinutes <= 0
                          }
                        >送審</ButtonSave>
                      </>
                    : <ButtonSave
                        onClick = { () => { setIsEditing(true) }}
                      >編輯</ButtonSave>
                  } */}
                  {/* <ButtonSave
                    onClick = { () => { setShowDeletePopup(true) }}
                  >刪除</ButtonSave> */}
                </React.Fragment>
            }
            {
              overtime.approvementStatus === 'unapproved' &&
              <ButtonSave
                onClick = { unsubmitHandler }
              >取消送審</ButtonSave>
            }
            {
              overtime.status !== 'approved' &&
              <ButtonSave
                onClick = { modifyLeaveApplicationHandler }
              >修改</ButtonSave>
            }
            <ButtonClear
              onClick = { () => { history.goBack() }}
            >離開</ButtonClear>
          </DivButtonsWrapper>
        </DivFlexRow>
      </PanelLeaveDetail>
      { showLoadingPopup && <PopupLoading/> }
      {
        showDeletePopup &&
          <PopupExecuteConfirm
            title = "刪除休假資料"
            text = "確定刪除此休假資料"
            confirmHandler = { deleteOvertimesHandler }
            cancelHandler = { () => {
              setShowDeletePopup(false);
            }}
          />
      }
      {
        showSaveSuccessPopup &&
          <PopupExecuteResult
            title = "更新成功"
            confirmHandler = { () => { history.goBack() }}
          />
      }
      {
        showDeleteSuccessPopup &&
          <PopupExecuteResult
            title = "刪除成功"
            confirmHandler = { () => {
              history.goBack();
            }}
          />
      }
      {
        showCancelSuccessPopup &&
          <PopupExecuteResult
            title = "取消成功"
            confirmHandler = { () => {
              history.goBack();
            }}
          />
      }
      {
        showAlertPopup &&
          <PopupExecuteResult
            title = "編輯失敗"
            text = "超過工作日期工時上限，無法新增工時"
            confirmHandler = { () => { setShowAlertPopup(false) } }
          />
      }
      {
        showSaveFailPopup &&
          <PopupExecuteResult
            title = { apiFailPopupTitle }
            text = { apiFailPopupMessage }
            confirmHandler = { () => {
              dispatch(CLOSE_SAVE_FAIL_POPUP());
            }}
          />
      }
      {
        showUploadSuccessPopup &&
          <PopupExecuteResult
            title = "上傳成功"
            confirmHandler = { uploadSuccessConfirmHandler }
          />
      }
      {
        showDeleteFilePopup &&
          <PopupExecuteConfirm
            title = "刪除檔案"
            text = "確定刪除此上傳檔案"
            confirmHandler = { deleteFile }
            cancelHandler = { () => {
              setShowDeleteFilePopup(false);
            }}
          />
      }
      {
        showAlertPopup &&
          <PopupExecuteResult
            title = "失敗"
            text = { showAlertMessage }
            confirmHandler = { () => { setShowAlertPopup(false) } }
          />
      }
      {
        showSuccessPopup &&
          <PopupExecuteResult
            title = "修改成功"
            text = "修改成功"
            confirmHandler = { () => { setShowSuccessPopup(false) } }
          />
      }
    </MainContent>
  );
};

export default LeaveDetail;
