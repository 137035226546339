import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { DivPopupWrapper, DivPopupXLarge } from '@/component-style/Popup';
import { ButtonCancel, ButtonBasic } from '@/component-style/Button';
// import { DivFlexRow } from '@/component-style/RWDWrapper';

import PopupLoading from '@/components/UI/Popup/PopupLoading';

import { PSubtitle } from '@/component-style/TextElement';
import { integerConverter } from '@/utils/textUtils';
import MyInputNumber from '@/components/UI/MyInputNumber';
import { cloneDeep } from 'lodash';
import { laborHealthInsuranceParamsConfig } from '@/constant/dataConfig/sysConfigModule.js';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import PopupExecuteConfirm from '@/components/UI/Popup/PopupExecuteConfirm';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import {
  DivFlexRow,
  DivItemWrapper,
} from '@/component-style/RWDWrapper';

import {
  patchUpdateLaborHealthInsuranceSetting,
  deleteLaborHealthInsuranceSetting,
} from '@/api/financeService';

const DivButtonsWrapper = styled.div`
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const H3Title = styled.h3`
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: #8b8b8b;
  margin-bottom: 10px;
`;

const ButtonDiscard = styled(ButtonCancel)`
  width: 120px;
  margin-right: 10px;
`;

const ButtonSave = styled(ButtonBasic)`
  width: 120px;

  &:first-child { margin-right: 10px }
`;

const PopupLaborHealthInsurance = props => {
  const dispatch = useDispatch();
  const showSaveFailPopup = useSelector(state => state.apiFail.showSaveFailPopup);
  const apiFailPopupTitle = useSelector(state => state.apiFail.title);
  const apiFailPopupMessage = useSelector(state => state.apiFail.message);

  const [showDeletePopup, setShowDeletePopup] = useState(false);

  const [laborHealthInsuranceParams, setLaborHealthInsuranceParams] = useState(cloneDeep(laborHealthInsuranceParamsConfig));
  
  const [showSaveSuccessPopup, setShowSaveSuccessPopup] = useState(false);
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);

  const updateLaborHealthInsuranceParamsHandler = (key, value) => {
    const updateObj = { ...laborHealthInsuranceParams };
    updateObj[key] = value;
    setLaborHealthInsuranceParams({ ...updateObj });
  };

  const saveHandler = async () => {
    console.log(`PoupLaborHealthInsurance.js saveHandler() laborHealthInsuranceParams=${JSON.stringify(laborHealthInsuranceParams)}`);
    try {
      var data = {...laborHealthInsuranceParams};
      delete data.insuranceGrade;
      delete data.id;
      delete data.isEmployer;
      await patchUpdateLaborHealthInsuranceSetting(laborHealthInsuranceParams.id, data);
      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
    } catch (err) {
      // setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '儲存失敗', err }));
      console.log(err);
    }
  };

  const deleteHandler = async () => {
    console.log("PoupLaborHealthInsurance.js deleteHandler() laborHealthInsuranceParams:" + JSON.stringify(laborHealthInsuranceParams));
    try {
      await deleteLaborHealthInsuranceSetting(laborHealthInsuranceParams.id);
      setShowLoadingPopup(false);
      props.cancelHandler();
      // setShowSaveSuccessPopup(true);
    } catch (err) {
      // setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '刪除失敗', err }));
      console.log(err);
    }
  };

  const updateData = () => {
    var result = props.laborHealthInsuranceSetting.filter(obj => {
      return obj.insuranceGrade === props.editInsuranceGrade
    })
    console.log("PopupLaborHealthInsurance.js updateData() result:" + JSON.stringify(result));
    setLaborHealthInsuranceParams(cloneDeep(result[0]));
  };

  const initData = useCallback(
    updateData
  , []
  );

  useEffect(() => {
    initData();
  }, [initData]);

  return (
    <DivPopupWrapper>
      <DivPopupXLarge>
        <H3Title>勞、健保費用修改</H3Title>
        <DivFlexRow>
          <DivItemWrapper>
            <PSubtitle>投保級距</PSubtitle>
            <MyInputNumber
              disabled = { true }
              value = { laborHealthInsuranceParams.insuranceGrade }
              onChange = { value => {
                updateLaborHealthInsuranceParamsHandler('insuranceGrade', integerConverter(value));
              }}
            />
          </DivItemWrapper>
          <DivItemWrapper>
            <PSubtitle>勞保僱主負擔</PSubtitle>
            <MyInputNumber
              disabled = { false }
              value = { laborHealthInsuranceParams.laborEmployerPay }
              onChange = { value => {
                updateLaborHealthInsuranceParamsHandler('laborEmployerPay', integerConverter(value));
              }}
            />
          </DivItemWrapper>
          <DivItemWrapper>
            <PSubtitle>勞保勞工負擔</PSubtitle>
            <MyInputNumber
              disabled = { false }
              value = { laborHealthInsuranceParams.laborEmployeePay }
              onChange = { value => {
                updateLaborHealthInsuranceParamsHandler('laborEmployeePay', integerConverter(value));
              }}
            />
          </DivItemWrapper>
        </DivFlexRow>
        <DivFlexRow>
          <DivItemWrapper>
            <PSubtitle>健保僱主負擔</PSubtitle>
            <MyInputNumber
              disabled = { false }
              value = { laborHealthInsuranceParams.healthEmployerPay }
              onChange = { value => {
                updateLaborHealthInsuranceParamsHandler('healthEmployerPay', integerConverter(value));
              }}
            />
          </DivItemWrapper>
          <DivItemWrapper>
            <PSubtitle>健保勞工負擔</PSubtitle>
            <MyInputNumber
              disabled = { false }
              value = { laborHealthInsuranceParams.healthEmployeePay }
              onChange = { value => {
                updateLaborHealthInsuranceParamsHandler('healthEmployeePay', integerConverter(value));
              }}
            />
          </DivItemWrapper>
        </DivFlexRow>
        <DivButtonsWrapper>
          <ButtonSave
            onClick = { saveHandler }
          >儲存</ButtonSave>
          <ButtonSave
            onClick = { () => {
              setShowDeletePopup(true);
            }}
          >刪除</ButtonSave>
          <ButtonDiscard
            onClick = { props.cancelHandler }
          >關閉
          </ButtonDiscard>
        </DivButtonsWrapper>
      </DivPopupXLarge>
      { showLoadingPopup && <PopupLoading/> }
      {
        showSaveSuccessPopup &&
          <PopupExecuteResult
            title = "更新成功"
            confirmHandler = { () => {
              setShowSaveSuccessPopup(false);
              props.reloadHandler();
            } }
          />
      }
      {
        showSaveFailPopup &&
          <PopupExecuteResult
            title = { apiFailPopupTitle }
            text = { apiFailPopupMessage }
            confirmHandler = { () => {
              dispatch(CLOSE_SAVE_FAIL_POPUP());
            }}
          />
      }
      {
        showDeletePopup &&
          <PopupExecuteConfirm
            title = "刪除勞、健保投保薪資"
            text = "確定此刪除勞、健保投保薪資"
            confirmHandler = { deleteHandler }
            cancelHandler = { () => {
              setShowDeletePopup(false);
            }}
          />
      }
    </DivPopupWrapper>
  );
};

export default PopupLaborHealthInsurance;
