import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Checkbox from '@/components/UI/Checkbox';
import StatusCheck from '@/components/UI/StatusCheck';
import { TdItem, TableRow } from '@/component-style/Table';
import { ButtonGoDetail } from '@/component-style/Button';

import { getPhoneString, getOptionDesc } from '@/utils/textUtils';
import { invitationConfirmStatus } from '@/constant/options';


const TrWrapper = styled(TableRow)`
  border-bottom: solid 2px #f2f2f2;
  box-sizing: border-box;
  min-width: 1120px;

  &:last-child {
    border-bottom: none;
  }
`;

const Td80 = styled(TdItem)`
  width: 80px;
`;

const Td120 = styled(TdItem)`
  width: 120px;
`;

const Td160 = styled(TdItem)`
  width: 160px;
`;

const SpanPhone = styled.span`
  cursor: pointer;
  color: #de2027;
`;

const SpanJournalistName = styled.span`
  cursor: pointer;
  color: #de2027;
`;

const Item = props => {
  const { journalist } = props;
  const mediaTypeList = useSelector(state => state.srpOptions.mediaTypeOptions);

  const mediaType = getOptionDesc(mediaTypeList, journalist.mediaType);
  const status = getOptionDesc(invitationConfirmStatus, journalist.confirmStatus);
  const businessPhone = getPhoneString(journalist.businessPhone);

  const makePhoneCall = number => {
    window.location.href = `tel:${number}`;
  };

  return (
    <TrWrapper>
      <Td80>
        <Checkbox
          checked = { journalist.isChecked }
          changeHandler = { props.checkHandler }
        />
      </Td80>
      <Td80>
        <StatusCheck
          checked = { journalist.attend }
        />
      </Td80>
      <Td160>{ mediaType }</Td160>
      <Td160>{ journalist.mediaName }</Td160>
      <Td80>
        <SpanJournalistName
          onClick = { props.editJournalistHandler }
        >{ journalist.journalistName }
        </SpanJournalistName>
      </Td80>
      <Td80>{ status }</Td80>
      <Td160>
        <SpanPhone
          onClick = { () => { makePhoneCall(businessPhone) } }
        >{ businessPhone }
        </SpanPhone>
      </Td160>
      <Td120>
        <SpanPhone
          onClick = { () => { makePhoneCall(journalist.businessMobile) } }
        >{ journalist.businessMobile }
        </SpanPhone>
      </Td120>
      <Td120>{ journalist.job }</Td120>
      <Td80>
        <ButtonGoDetail
          onClick = { props.goDetailHandler }
        >...
        </ButtonGoDetail>
      </Td80>
    </TrWrapper>
  );
};

export default Item;
