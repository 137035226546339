import React from 'react';
import styled from 'styled-components';

import PanelWrapper from '@/components/UI/PanelWrapper';
import SelectNormal from '@/components/UI/Select/SelectNormal';
import SelectMultiple from '@/components/UI/Select/SelectMultiple';
import SelectSearchable from '@/components/UI/Select/SelectSearchable';
import { InputBasic } from '@/component-style/Input';
import { PSubtitle } from '@/component-style/TextElement';
import {
  DivFlexRow,
  DivItemWrapper,
  DivFullWidthWrapper,
  DivLongMultiSelector,
} from '@/component-style/RWDWrapper';

import { countyOptions } from '@/constant/options';
import { getDistOptions }  from '@/constant/zipCodes';

const DivBudgetWrapper = styled.div`
  width: 300px;
  display: flex;
  justify-content: space-between;

  @media (min-width: 1920px) {
    width: 622px;
  }

  @media (max-width: 320px) {
    width: 260px;
  }
`;

const InputBudget = styled(InputBasic)`
  width: 120px;

  @media (min-width: 1920px) {
    width: 300px;
  }

  @media (max-width: 320px) {
    width: 100px;
  }
`;

const PSpacer = styled.p`
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  color: #767676;
`;

const PanelVenueSearch = props => {
  const { config, projectID } = props;

  return (
    <PanelWrapper title = { props.title }>
      {
        props.showProjectSelect &&
          <DivFlexRow>
            <DivFullWidthWrapper>
              <PSubtitle>
                專案代號
                <span>*</span>
              </PSubtitle>
              <DivLongMultiSelector>
                <SelectSearchable
                  value = { projectID }
                  options = {[
                    { value: '', desc: '請選擇專案代號' },
                    ...props.projectIDOptions,
                  ]}
                  changeHandler = { props.updateProjectIDHandler }
                />
              </DivLongMultiSelector>
            </DivFullWidthWrapper>
          </DivFlexRow>
      }
      <DivFlexRow>
        <DivFullWidthWrapper>
          <PSubtitle>場地類型</PSubtitle>
          <DivLongMultiSelector>
            <SelectNormal
              value = { config.type }
              options = {[
                { value: '', desc: '請選擇場地類型' },
                ...props.venueTypeOptions,
              ]}
              changeHandler = { value => {
                props.updateConfigHandler('type', value);
              }}
            />
          </DivLongMultiSelector>
        </DivFullWidthWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>縣市地區</PSubtitle>
          <SelectNormal
            value = { config.city }
            options = {[
              { value: '', desc: '請選擇縣市' },
              ...countyOptions,
            ]}
            changeHandler = { value => {
              props.cityChangeHandler(value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>行政區域</PSubtitle>
          <SelectNormal
            value = { config.town }
            options = {[
              { value: '', desc: '請選擇區域' },
              ...getDistOptions(config.city),
            ]}
            changeHandler = { value => {
              props.updateConfigHandler('town', value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivFullWidthWrapper>
          <PSubtitle>特色標籤</PSubtitle>
          <DivLongMultiSelector>
            <SelectMultiple
              value = { config.characteristics }
              options = {[ ...props.venueCharacteristics ]}
              changeHandler = { value => {
                props.updateConfigHandler('characteristics', value);
              }}
            />
          </DivLongMultiSelector>
        </DivFullWidthWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivFullWidthWrapper>
          <PSubtitle>設備標籤</PSubtitle>
          <DivLongMultiSelector>
            <SelectMultiple
              value = { config.equipments }
              options = {[ ...props.venueEquipments ]}
              changeHandler = { value => {
                props.updateConfigHandler('equipments', value);
              }}
            />
          </DivLongMultiSelector>
        </DivFullWidthWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>容納人數</PSubtitle>
          <DivBudgetWrapper>
            <InputBudget
              type = "number"
              value = { config.minCapacity }
              onChange = {({ target }) => {
                props.updateConfigHandler('minCapacity', target.value);
              }}
            />
              <PSpacer>~</PSpacer>
            <InputBudget
              type = "number"
              value = { config.maxCapacity }
              onChange = {({ target }) => {
                props.updateConfigHandler('maxCapacity', target.value);
              }}
            />
          </DivBudgetWrapper>
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>坪數</PSubtitle>
          <DivBudgetWrapper>
            <InputBudget
              type = "number"
              value = { config.minSize }
              onChange = {({ target }) => {
                props.updateConfigHandler('minSize', target.value);
              }}
            />
              <PSpacer>~</PSpacer>
            <InputBudget
              type = "number"
              value = { config.maxSize }
              onChange = {({ target }) => {
                props.updateConfigHandler('maxSize', target.value);
              }}
            />
          </DivBudgetWrapper>
        </DivItemWrapper>
      </DivFlexRow>
      { props.children }
    </PanelWrapper>
  );
};

export default PanelVenueSearch;
