import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { TdItem, TableRow } from '@/component-style/Table';
import { ButtonGoDetail } from '@/component-style/Button';

import { leaveApprovalOptions } from '@/constant/options';

import {
  getOptionDesc,
} from '@/utils/textUtils';

import {
  // diffTime,
  // diffTimeWithFloat,
  diffTimeSubtractLunchBreak,
  // formatTime,
} from '@/utils/timeUtils';

import moment from 'moment';

const TrWrapper = styled(TableRow)`
  border-bottom: solid 2px #f2f2f2;
  box-sizing: border-box;
  min-width: 1480px;

  &:last-child {
    border-bottom: none;
  }
`;

const Td80 = styled(TdItem)`
  width: 80px;
`;

const Td120 = styled(TdItem)`
  width: 120px;
`;

const Td160 = styled(TdItem)`
  width: 160px;
`;

const Td240 = styled(TdItem)`
  width: 240px;
`;

const Item = props => {
  const { leaveApplicationRecord, leaveSettinsOptions } = props;
  const { leaveFrom, leaveTo } = leaveApplicationRecord;
  const workingHoursTypeOptions = useSelector(state => state.srpOptions.workingHoursTypeOptions);

  const workingHoursType = getOptionDesc(workingHoursTypeOptions, leaveApplicationRecord.typeID);

  const type = overtimeType => {
    if (overtimeType === '') return '';

    return overtimeType === 'freeTime'
      ? '補休'
      : '加班費';
  };

  const approvedMinutes = () => {
    if (leaveApplicationRecord.approvementStatus !== 'approved') return 0;

    return leaveApplicationRecord.overtimeType === 'freeTime'
      ? (leaveApplicationRecord.freeTimeMins / 60).toFixed(1)
      : (leaveApplicationRecord.extraPayMins / 60).toFixed(1);
  };

  return (
    <TrWrapper>
      <Td120>{ leaveApplicationRecord.username }</Td120>
      {/* <Td120>{ getOptionDesc(leaveSettinsOptions, leaveApplicationRecord.leaveId) }</Td120> */}
      <Td120>{ leaveApplicationRecord.leaveName }</Td120>
      <Td160>{ `${moment.parseZone(leaveFrom).format('YYYY-MM-DD HH:mm')}` }</Td160>
      <Td160>{ `${moment.parseZone(leaveTo).format('YYYY-MM-DD HH:mm')}` }</Td160>
      <Td80>{ getOptionDesc(leaveApprovalOptions, leaveApplicationRecord.status) }</Td80>
      <Td120>{ Math.round(leaveApplicationRecord.durationInSeconds / 3600 * 100) / 100 }</Td120>
      <Td80>
        <ButtonGoDetail
          disabled = { props.disableDetailPage }
          onClick = { props.goDetailHandler }
        >...
        </ButtonGoDetail>
      </Td80>
    </TrWrapper>
  );
};

export default Item;
