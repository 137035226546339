import React from 'react';

import TableWrapper from '@/components/UI/TableWrapper';
import DateSelector from '@/components/UI/DateSelector';
import SelectNormal from '@/components/UI/Select/SelectNormal';
import SelectSearchable from '@/components/UI/Select/SelectSearchable';
import { InputBasic } from '@/component-style/Input';
import { PSubtitle } from '@/component-style/TextElement';
import {
  DivFlexRow,
  DivItemWrapper,
} from '@/component-style/RWDWrapper';

import {
  addTime,
  diffTime,
  formatTime,
  subtractTime,
} from '@/utils/timeUtils';

import moment from 'moment';

const PanelWorkingHoursDetail = props => {
  const { workingHours, workingHoursTagDictionary } = props;

  console.log("PanelWorkingHoursDetail workingHours:" + JSON.stringify(workingHours));

  const tagOptions = typeID => 
    workingHoursTagDictionary[typeID] === undefined
      ? []
      : workingHoursTagDictionary[typeID];

  const workingMinutes = diffTime(workingHours.startTimeLocal, workingHours.endTimeLocal, 'minutes');

  const disabledStartDate = current => current > addTime(workingHours.workingDate, 1, 'days')
    || current <= subtractTime(workingHours.workingDate, 1, 'days');

  function workingDisabledStartDate(current) {
    // Can not select days before today and today
    return current && current > moment().endOf('day');
  }

  const disabledEndDate = current => current > addTime(workingHours.workingDate, 2, 'days')
    || current <= subtractTime(workingHours.workingDate, 1, 'days');

  const updateStartTimeHandler = value => {
    props.updateWorkingHoursHandler(
      'startTimeLocal',
      formatTime(value, 'YYYY/MM/DD HH:mm'),
    )
  };

  const updateEndTimeHandler = value => {
    props.updateWorkingHoursHandler(
      'endTimeLocal',
      formatTime(value, 'YYYY/MM/DD HH:mm'),
    )
  };

  return (
    <TableWrapper title = { props.title }>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>
            工作日期
            <span>*</span>
          </PSubtitle>
          <DateSelector
            disabled = { props.disabled || props.disableDate }
            value = { workingHours.workingDate }
            changeHandler = { value => {
              props.workingDateChangeHanlder(
                formatTime(value, 'YYYY/MM/DD')
              );
            }}
            disabledDate = { workingDisabledStartDate }
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>
            專案名稱
            <span>*</span>
          </PSubtitle>
          <SelectSearchable
            disabled = { props.disabled }
            value = { workingHours.projectID }
            options = {[
              { value: '', desc: '請選擇專案代號' },
              ...props.projectIDOptions,
            ]}
            changeHandler = { value => {
              props.updateWorkingHoursHandler('projectID', value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>
            工作項目
            <span>*</span>
          </PSubtitle>
          <SelectSearchable
            disabled = { props.disabled }
            value = { workingHours.typeID }
            options = {[
              { value: '', desc: '請選擇工作項目' },
              ...props.workingHoursTypeOptions,
            ]}
            changeHandler = { value => {
              props.typeChangeHandler(value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>
            工作細項
            <span>*</span>
          </PSubtitle>
          <SelectSearchable
            disabled = { props.disabled || workingHours.typeID === '' }
            value = { workingHours.tag }
            options = {[
              { value: '', desc: '請選擇工作細項' },
              ...tagOptions(workingHours.typeID),
            ]}
            changeHandler = { value => {
              props.updateWorkingHoursHandler('tag', value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>
            開始時間
            <span>*</span>
          </PSubtitle>
          <DateSelector
            showTime = { true }
            disabled = { props.disabled }
            disabledDate = { disabledStartDate }
            value = { workingHours.startTimeLocal == null ? null : moment.parseZone(workingHours.startTimeLocal).format('YYYY/MM/DD HH:mm') }
            // value = { workingHours.startTimeLocal }
            okHandler = { updateStartTimeHandler }
            changeHandler = { updateStartTimeHandler }
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>
            結束時間
            <span>*</span>
          </PSubtitle>
          <DateSelector
            showTime = { true }
            disabled = { props.disabled }
            disabledDate = { disabledEndDate }
            value = { workingHours.endTimeLocal == null ? null : moment.parseZone(workingHours.endTimeLocal).format('YYYY/MM/DD HH:mm') }
            // value = { workingHours.endTimeLocal }
            okHandler = { updateEndTimeHandler }
            changeHandler = { updateEndTimeHandler }
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>工時 (分)</PSubtitle>
          <InputBasic
            disabled = { true }
            value = { workingMinutes }
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>跨組</PSubtitle>
          <SelectNormal
            disabled = { props.disabled }
            value = { workingHours.crossTeam }
            options = {[
              { value: true, desc: '是'},
              { value: false, desc: '否'},
            ]}
            changeHandler = { value => {
              props.updateWorkingHoursHandler('crossTeam', value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>備註</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { workingHours.note }
            onChange = { ({ target }) => {
              props.updateWorkingHoursHandler('note', target.value);
            }}
          />
        </DivItemWrapper>
        {
          props.showApprovement &&
          <DivItemWrapper>
            <PSubtitle>申請日期</PSubtitle>
            <InputBasic
              disabled = { props.disabled }
              value = { workingHours.submitDate }
            />
          </DivItemWrapper>
        }
      </DivFlexRow>
      { props.children }
    </TableWrapper>
  );
};

export default PanelWorkingHoursDetail;
