import React from 'react';
import styled from 'styled-components';

import Header from './Header';
import Item from './Item';
import { Table, TableBody } from '@/component-style/Table';

const DivWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 700px;
  background-color: #ffffff;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
`;

const TableApprovalRecords = props => {
  const { records } = props;

  return (
    <DivWrapper>
      <Table>
        <Header/>
        <TableBody>
          {
            records.map((record, index) =>
              <Item
                key = { index }
                record = { record }
              />
            )
          }
        </TableBody>
      </Table>
    </DivWrapper>
  );
};

export default TableApprovalRecords;
