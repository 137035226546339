import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import PanelWrapper from '@/components/UI/PanelWrapper';
import SelectNormal from '@/components/UI/Select/SelectNormal';
import SelectSearchable from '@/components/UI/Select/SelectSearchable';
import DatePeriodSelector from '@/components/UI/DatePeriodSelector';
import SearchButtonSet from '@/components/UI/SearchButtonSet';
import { InputBasic } from '@/component-style/Input';
import { ButtonBasic } from '@/component-style/Button';
import { PSubtitle } from '@/component-style/TextElement';
import {
  DivFlexRow,
  DivItemWrapper,
  DivFullWidthWrapper,
  DivLongMultiSelector,
} from '@/component-style/RWDWrapper';

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding: 0;
  justify-content: space-between;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const ButtonExport = styled(ButtonBasic)`
  width: 120px;

  &:first-child { margin-right: 10px }

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`;

const DivBudgetWrapper = styled.div`
  width: 300px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 320px) { width: 260px }
  @media (min-width: 1920px) { width: 622px }
`;

const InputBudget = styled(InputBasic)`
  width: 120px;

  @media (max-width: 320px) { width: 100px }
  @media (min-width: 1920px) { width: 300px }
`;

const PSpacer = styled.p`
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  color: #767676;
`;

const PanelCostInvoiceSearch = props => {
  const financeRoutes = useSelector(state => state.login.financeRoutes);
  const { config } = props;

  const userExportable = financeRoutes.indexOf('InvoiceSearch-Export') !== -1;

  const createDateStartChangeHandler = value => {
    props.updateConfigHandler('createDate', {
      ...config.createDate,
      start: value,
    });
  };

  const createDateEndChangeHandler = value => {
    props.updateConfigHandler('createDate', {
      ...config.createDate,
      end: value,
    });
  };

  return (
    <PanelWrapper title = "銷項發票查詢">
      <DivFlexRow>
        <DivFullWidthWrapper>
          <PSubtitle>專案代號</PSubtitle>
          <DivLongMultiSelector>
            <SelectSearchable
              value = { config.projectID }
              options = {[
                { value: '', desc: '請選擇專案代號' },
                ...props.projectIDOptions,
              ]}
              changeHandler = { value => {
                props.updateConfigHandler('projectID', value)
              }}
            />
          </DivLongMultiSelector>
        </DivFullWidthWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>發票字軌</PSubtitle>
          <InputBasic
            maxLength = "2"
            value = { config.invoicePrefix }
            onChange = { ({ target }) => {
              props.updateConfigHandler('invoicePrefix', target.value)
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>發票號碼</PSubtitle>
          <DivBudgetWrapper>
            <InputBudget
              type = "number"
              value = { config.invoiceCode.start }
              onChange = { ({ target }) => {
                props.updateConfigHandler('invoiceCode', {
                  ...config.invoiceCode,
                  start: target.value
                });
              }}
            />
            <PSpacer>~</PSpacer>
            <InputBudget
              type = "number"
              value = { config.invoiceCode.end }
              onChange = { ({ target }) => {
                props.updateConfigHandler('invoiceCode', {
                  ...config.invoiceCode,
                  end: target.value
                });
              }}
            />
          </DivBudgetWrapper>
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>匯會計系統</PSubtitle>
          <SelectNormal
            value = { config.accountingExported }
            options = {[
              { value: '', desc: '請選擇'},
              { value: true, desc: '是'},
              { value: false, desc: '否'},
            ]}
            changeHandler = { value => {
              props.updateConfigHandler('accountingExported', value)
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>所屬公司</PSubtitle>
          <SelectNormal
            value = { config.companyType }
            options = {[
              { value: '', desc: '請選擇所屬公司'},
              ...props.companyTypeOptions,
            ]}
            changeHandler = { value => {
              props.updateConfigHandler('companyType', value)
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>發票日期</PSubtitle>
          <DivLongMultiSelector>
            <DatePeriodSelector
              startTime = { config.createDate.start }
              endTime = { config.createDate.end }
              endTimeOKHandler = { createDateEndChangeHandler }
              endTimeChangeHandler = { createDateEndChangeHandler }
              startTimeOKHandler = { createDateStartChangeHandler }
              startTimeChangeHandler = { createDateStartChangeHandler }
            />
          </DivLongMultiSelector>
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivButtonsWrapper>
          <div>
            <ButtonExport
              disabled = { !userExportable }
              onClick = { props.exportHandler }
            >匯出</ButtonExport>
            {/* <ButtonExport>匯會計系統</ButtonExport> */}
          </div>
          <SearchButtonSet
            clearHandler = { props.clearHandler }
            searchHandler = { props.searchHandler }
          />
        </DivButtonsWrapper>
      </DivFlexRow>
    </PanelWrapper>
  );
};

export default PanelCostInvoiceSearch;
