import React from 'react';
import styled from 'styled-components';

import SelectNormal from '../../Select/SelectNormal';
import { DivPopupWrapper, DivPopup } from '../../../../component-style/Popup';
import { ButtonBasic, ButtonCancel } from '../../../../component-style/Button';
import { DivFlexRow } from '../../../../component-style/RWDWrapper';

const DivItemWrapper = styled(DivFlexRow)`
  padding: 0;
  margin-bottom: 10px;
`;

const DivSelectWrapper = styled(DivItemWrapper)`

  & > div:first-of-type,
  .ant-select {
    width: 100%;
  }
`;

const DivButtonsWrapper = styled.div`
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;


const H3Title = styled.h3`
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: #8b8b8b;
  margin-bottom: 10px;
`;

const PTexts = styled.p`
  width: 80px;
  font-size: 16px;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0.43px;
  color: #8b8b8b;
`;

const ButtonConfirm = styled(ButtonBasic)`
  width: 120px;
`;

const ButtonDiscard = styled(ButtonCancel)`
  width: 120px;
  margin-right: 10px;
`;

const PopupExportMethodSelect = props => {

  return (
    <DivPopupWrapper>
      <DivPopup>
        <H3Title>{ props.title }</H3Title>
        <DivSelectWrapper>
          <PTexts>匯出方式</PTexts>
          <SelectNormal
            value = { props.value }
            options = { props.options }
            changeHandler = { props.changeHandler }
          />
        </DivSelectWrapper>
        <DivButtonsWrapper>
          <ButtonDiscard
            onClick = { props.cancelHandler }
          >關閉
          </ButtonDiscard>
          <ButtonConfirm
            onClick = { props.confirmHandler }
          >確認
          </ButtonConfirm>
        </DivButtonsWrapper>
      </DivPopup>
    </DivPopupWrapper>
  );
};

export default PopupExportMethodSelect;
