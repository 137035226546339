import React from 'react';
import { Route } from 'react-router-dom';

import PunchTimeCreation from '@/containers/attendanceModule/punchTimeCreation';
import PunchTimeBackup from '@/containers/attendanceModule/punchTimeBackup';
import PunchTimeRecords from '@/containers/attendanceModule/punchTimeRecords';
import PunchTimeManagement from '@/containers/attendanceModule/punchTimeManagement';
import PunchTimeDetail from '@/containers/attendanceModule/punchTimeDetail';
import WorkingHoursCreation from '@/containers/attendanceModule/workingHoursCreation';
import WorkingHoursList from '@/containers/attendanceModule/workingHoursList';
import WorkingHoursDetail from '@/containers/attendanceModule/workingHoursDetail';
import OvertimeApplication from '@/containers/attendanceModule/overtimeApplication';
import OvertimesList from '@/containers/attendanceModule/overtimesList';
import OvertimeApprovement from '@/containers/attendanceModule/overtimeApprovement';
import OvertimeDetail from '@/containers/attendanceModule/overtimeDetail';
import OvertimeByPersonnel from '@/containers/attendanceModule/overtimeByPersonnel';
import OvertimeByPersonnelDetail from '@/containers/attendanceModule/overtimeByPersonnelDetail';
import LeaveApplication from '@/containers/attendanceModule/leaveApplication';
import LeaveDetail from '@/containers/attendanceModule/leaveDetail';
import LeaveApprovement from '@/containers/attendanceModule/leaveApprovement';
import LeaveList from '@/containers/attendanceModule/leaveList';
import PersonalSalary from '@/containers/attendanceModule/personalSalary';

const attendanceRoutes = [
  {
    key: 'PunchTimeCreation',
    path: '/Attendance/PunchTimeCreation',
    component: PunchTimeCreation,
  },
  {
    key: 'PunchTimeBackup',
    path: '/Attendance/PunchTimeBackup',
    component: PunchTimeBackup,
  },
  {
    key: 'PunchTimeRecords',
    path: '/Attendance/PunchTimeRecords',
    component: PunchTimeRecords,
  },
  {
    key: 'PunchTimeManagement',
    path: '/Attendance/PunchTimeManagement',
    component: PunchTimeManagement,
  },
  {
    key: 'PunchTimeDetail',
    path: '/Attendance/PunchTimeDetail/:id/:date',
    component: PunchTimeDetail,
  },
  {
    key: 'WorkingHoursCreation',
    path: '/Attendance/WorkingHoursCreation',
    component: WorkingHoursCreation,
  },
  {
    key: 'WorkingHoursList',
    path: '/Attendance/WorkingHoursList',
    component: WorkingHoursList,
  },
  {
    key: 'WorkingHoursDetail',
    path: '/Attendance/WorkingHoursDetail/:id',
    component: WorkingHoursDetail,
  },
  {
    key: 'OvertimeApplication',
    path: '/Attendance/OvertimeApplication/:workingDate?',
    component: OvertimeApplication,
  },
  {
    key: 'OvertimesList',
    path: '/Attendance/OvertimesList/:searchedWorkingStartDate?/:searchedWorkingEndDate?',
    component: OvertimesList,
  },
  {
    key: 'OvertimeApprovement',
    path: '/Attendance/OvertimeApprovement',
    component: OvertimeApprovement,
  },
  {
    key: 'OvertimeDetail',
    path: '/Attendance/OvertimeDetail/:id',
    component: OvertimeDetail,
  },
  {
    key: 'OvertimeByPersonnel',
    path: '/Attendance/OvertimeByPersonnel',
    component: OvertimeByPersonnel,
  },
  {
    key: 'OvertimeByPersonnelDetail',
    path: '/Attendance/OvertimeByPersonnelDetail/:searchedUserId/:searchedOvertimeStartDate/:searchedOvertimeEndDate',
    component: OvertimeByPersonnelDetail,
  },
  {
    key: 'LeaveApplication',
    path: '/Attendance/LeaveApplication',
    component: LeaveApplication,
  },
  {
    key: 'LeaveDetail',
    path: '/Attendance/LeaveDetail/:id',
    component: LeaveDetail,
  },
  {
    key: 'LeaveApprovement',
    path: '/Attendance/LeaveApprovement',
    component: LeaveApprovement,
  },
  {
    key: 'LeaveList',
    path: '/Attendance/LeaveList',
    component: LeaveList,
  },
  {
    key: 'PersonalSalary',
    path: '/Attendance/PersonalSalary',
    component: PersonalSalary,
  },
];


const RoutesAttendanceModule = validRoutes => attendanceRoutes
  .filter(({ key }) => validRoutes.indexOf(key) !== -1) 
  .map(({ path, component }) =>
    <Route
      key = { path }
      path = { path }
      component = { component }
    />
  );

export default RoutesAttendanceModule;
