import React from 'react';
import styled from 'styled-components';

import Footer from '../UI/Footer';
import Logo from '../UI/Logo';

const DivLoginAside = styled.div`
  position: relative;
  width: 460px;
  height: 100vh;
  min-height: 600px;
  background-color: #ffffff;
  line-height: 100vh;
  text-align: center;

  @media (max-width: 768px) {
    display: none;
  }
`;

const DivLogoWrapper = styled.div`
  margin: 0 auto;
  width: 200px;

  img {
    vertical-align: baseline;
  }
`;

const LoginAside = () => {

  return (
    <DivLoginAside>
      <DivLogoWrapper>
        <Logo />
      </DivLogoWrapper>
      <Footer />
    </DivLoginAside>
  );
};

export default LoginAside;
