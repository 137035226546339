import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import TableWrapper from '@/components/UI/TableWrapper';
import PageSelector from '@/components/UI/PageSelector';
import { Table, TableBody } from '@/component-style/Table';
import Header from './Header';
import Item from './Item';

import moment from 'moment';

import PopupOvertimeDetail from '@/components/UI/Popup/PopupOvertimeDetail';

const TableOvertimeByPersonnel = props => {
  const authorityRoutes = useSelector(state => state.login.authorityRoutes);

  const { users, startDate, endDate } = props;

  const disableDetailPage = authorityRoutes.indexOf('UserDetail') === -1;

  const [showOvertimeDetailPopup, setShowOvertimeDetailPopup] = useState(false);

  const [viewOvertimeDetailUserID, setViewOvertimeDetailUserID] = useState("");

  return (
    <TableWrapper title = "同仁加班統計列表">
      <Table>
        <Header/>
        <TableBody>
          {
            users.map(user =>
              <Item
                key = { user.userID }
                user = { user }
                startDate = { startDate }
                endDate = { endDate }
                disableDetailPage = { disableDetailPage }
                goDetailHandler = { (userID) => {
                  // console.log("goDetailHandler userID:" + userID);
                  setViewOvertimeDetailUserID(userID);
                  setShowOvertimeDetailPopup(true);
                }}
              />
            )
          }
        </TableBody>
      </Table>
      <PageSelector
        total = { props.totalCount }
        current = { props.currentPage }
        pageChangedHandler = { props.pageChangedHandler }
        showSizeChanger = { false }
      />
      { 
        showOvertimeDetailPopup &&
          <PopupOvertimeDetail
            userID = { viewOvertimeDetailUserID }
            startDate = { startDate }
            endDate = { moment(startDate).endOf('month').format('YYYY-MM-DD') }
            cancelHandler = { () => {
              setShowOvertimeDetailPopup(false);
            }}
          />
      }
    </TableWrapper>
  );
};

export default TableOvertimeByPersonnel;
