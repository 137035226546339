import React, { useState } from 'react';
import styled from 'styled-components';
import Draggable from 'react-draggable';
import { Document, Page, pdfjs } from 'react-pdf';

import { DivPopupWrapper } from '@/component-style/Popup';
import { ButtonBasic, ButtonCancel } from '@/component-style/Button';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.4.456/pdf.worker.js`;

const DivPopup = styled.div`
  border-radius: 8px;
  padding: 30px;
  box-sizing: border-box;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
`;

const DivButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const ButtonConfirm = styled(ButtonBasic)`
  margin-right: 10px;
  width: 120px;
`;

const ButtonDiscard = styled(ButtonCancel)`
  width: 120px;
`;


const PopupDraggablePDF = props => {

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
 
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <DivPopupWrapper>
      <Draggable>
        <DivPopup>
          <Document
            file = { props.file }
            onLoadSuccess = { onDocumentLoadSuccess }
          >
            <Page
              pageNumber = { pageNumber }
              renderTextLayer = { false }
              renderAnnotationLayer = { false }
            />
          </Document>
          <DivButtonsWrapper>
            <div>
              <ButtonConfirm
                disabled = { pageNumber <= 1 }
                onClick = { () => {
                  setPageNumber(prevState => prevState - 1);
                }}
              >上一頁</ButtonConfirm>
              <ButtonConfirm
                disabled = { pageNumber >= numPages }
                onClick = { () => {
                  setPageNumber(prevState => prevState + 1);
                }}
              >下一頁</ButtonConfirm>
            </div>
            <ButtonDiscard
              onClick = { props.cancelHandler }
            >關閉
            </ButtonDiscard>
          </DivButtonsWrapper>
        </DivPopup>
      </Draggable>
    </DivPopupWrapper>
  );
};

export default PopupDraggablePDF;
