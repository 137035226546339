import { useState, useLayoutEffect } from 'react';

function useRWDStateHook () {
  const [isRWD, setIsRWD] = useState(null);

  useLayoutEffect(() => {
    function updateSize() {
      setIsRWD(window.innerWidth <= 1024);
    };
    window.addEventListener('resize', updateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, [isRWD]);

  return isRWD;
};

export default useRWDStateHook;
