import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleUp,
  faAngleDown,
} from '@fortawesome/free-solid-svg-icons';

const LiWrapper = styled.li`
  cursor: default;
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: space-between;
  // color: #979797;
  color: #4d4d4d;

  &:hover {
    background-color: rgba(138, 138, 138, 0.1);
  }
`;

const DivDesc = styled.div`
  display: flex;
  align-items: center;
`;

const SpanTitle = styled.span`
  margin-left: 10px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.4px;
`;

const IconWrapper = styled.div`
  margin-right: 5px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NavigationCategory = props => {

  return (
    <LiWrapper
      onClick = { () => { props.clickHandler() } }
    >
      <DivDesc>
        { props.children }
        <SpanTitle>{ props.title }</SpanTitle>
      </DivDesc>
      <IconWrapper>
        <FontAwesomeIcon
          size = "lg"
          icon = { props.opened ? faAngleUp : faAngleDown }
        />
      </IconWrapper>
    </LiWrapper>
  );
};

export default NavigationCategory;
