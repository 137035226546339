import { Column, Pie } from '@ant-design/plots';

import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { cloneDeep } from 'lodash';

// import { postToGetExpenseVouchers, postToGetVouchersTotal } from '@/api/expenseService';
import { getCustomerProfitReport, getCustomerProfitReportExcel, 
          getCustomerProfitSales, getCustomerProfitSalesExcel, 
          getCustomerProfitProfit, getCustomerProfitProfitExcel, 
          getCustomerProfitProfitPie, getCustomerProfitProfitPieExcel,
          getCustomerProfitUnitGrossProfit, getCustomerProfitUnitGrossProfitExcel 
} from '@/api/financeService';

import { getCompanyCustomers } from '@/api/projectService';

import { 
  FETCH_CUSTOMER_OPTIONS,
  FETCH_COMPANY_TYPE_OPTIONS,
} from '@/store/actions/srpOptionsAction';

import PanelRevenueByCustomerSearch from '@/components/Panel/Finance/PanelRevenueByCustomerSearch';
import TableRevenueByCustomer from '@/components/Table/Finance/TableRevenueByCustomer';
import PopupLoading from '@/components/UI/Popup/PopupLoading';

import { formatTime } from '@/utils/timeUtils';
import { getOptionDesc } from '@/utils/textUtils';
import { vendorInvoiceTypes } from '@/constant/options';
import { searchConfigGenerator } from '@/utils/apiRequestUtils';
import { exportExpenseVouchersFile } from '@/utils/xlsx';

import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';

import { decimalCommaConverter } from '@/utils/textUtils';

const DivPanelContainer = styled.div`
  margin-bottom: 40px;
`;

const searchConfig = {
  date: {
    start: null,
    end: null,
  },
  companyTypeID: '',
  customerID: [],
  resultType: '',
  chartType: '',
  statisticInterval: '',
  statisticIntervalOptions: [],
};

const RevenueByCustomerSearch = () => {
  const dispatch = useDispatch();
  const companyTypeOptions = useSelector(state => state.srpOptions.companyTypeOptions);
  // console.log(companyTypeOptions);
  // const customerOptions = useSelector(state => state.srpOptions.customerOptions);

  const [vouchers, setVouchers] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [customerOptions, setCustomerOptions] = useState([]);
  const [allCustomersByCompany, setAllCustomersByCompany] = useState([]);

  const [config, setConfig] = useState(cloneDeep(searchConfig));

  const [showLoadingPopup, setShowLoadingPopup] = useState(false);

  const [alertText, setAlertText] = useState('');
  const [showAlertPopup, setShowAlertPopup] = useState(false);

  const openAlertPopup = text => {
    setAlertText(text);
    setShowAlertPopup(true);
  };

  const [dataGroupProfitSales, setDataGroupProfitSales] = useState(
    [
      // {
      //   "year": "202206Ian Chao",
      //   "value": 40000,
      //   "type": "服務費總額"
      // },
      // {
      //   "year": "202206Ian Chao",
      //   "value": 150000,
      //   "type": "專案其它收入"
      // },
      // {
      //   "year": "202207Ian Chao",
      //   "value": 3,
      //   "type": "服務費總額"
      // },
      // {
      //   "year": "202207Ian Chao",
      //   "value": 4,
      //   "type": "專案其它收入"
      // },
    ]
  );

  const [dataPie, setDataPie] = useState(
    [
      // {
      //   type: '組一',
      //   value: 27,
      // },
      // {
      //   type: '組二',
      //   value: 25,
      // },
      // {
      //   type: '組三',
      //   value: 18,
      // },
      // {
      //   type: '組四',
      //   value: 15,
      // },
      // {
      //   type: '組五',
      //   value: 10,
      // },
      // {
      //   type: '其他',
      //   value: 5,
      // },
    ]
  );

  const chartConfig = {
    data: dataGroupProfitSales,
    isStack: true,
    xField: 'year',
    yField: 'value',
    seriesField: 'type',
    label: {
      // 可手动配置 label 数据标签位置
      position: 'middle',
      // 'top', 'bottom', 'middle'
      // 可配置附加的布局方法
      layout: [
        // 柱形图数据标签位置自动调整
        {
          type: 'interval-adjust-position',
        }, // 数据标签防遮挡
        {
          type: 'interval-hide-overlap',
        }, // 数据标签文颜色自动调整
        {
          type: 'adjust-color',
        },
      ],
      content: ( obj ) => {
        if (obj == null || obj.value == null) {
          return "";
        } else {
          return "$ " + obj.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
      },
    },
    tooltip: {
      formatter: (obj) => {
        if (obj == null || obj.value == null) {
          return "";
        } else {
          return { name: obj.year, value: "$ " + obj.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") };
        }
      },
    },
    yAxis: {
      label: {
        formatter: (v) => {
          return "$ " + v.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
      },
    },
  };

  const pieConfig = {
    appendPadding: 10,
    data: dataPie,
    angleField: 'value',
    colorField: 'type',
    radius: 0.9,
    label: {
      type: 'inner',
      offset: '-30%',
      content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
      style: {
        fontSize: 14,
        textAlign: 'center',
      },
    },
    interactions: [
      {
        type: 'element-active',
      },
    ],
  };

  const updateConfigHandler = (key, value) => {
    console.log("key:" + key + " value:" + value);
    const updateObj = { ...config };
    // console.log(updateObj);
    updateObj[key] = value;
    if (key === "companyType") {
      // console.log("updateConfigHandler companyTypeOptions:" + JSON.stringify(companyTypeOptions));
      // console.log("updateConfigHandler allCustomersByCompany:" + JSON.stringify(allCustomersByCompany));
      for (var i=0; i < allCustomersByCompany.length; i++) {
        if (allCustomersByCompany[i].companyTypeID === value) {
          const tempCustomerOptions = allCustomersByCompany[i].customers.map(item => {
            // console.log("updateConfigHandler item.customerName:" + item.customerName);
            const container = {};
            container.value = item.customerID;
            container.desc = item.customerName;        
            return container;
          })
          // console.log("updateConfigHandler tempCustomerOptions:" + JSON.stringify(tempCustomerOptions));
          setCustomerOptions(tempCustomerOptions);
        }
    }
    }
    if (key === "chartType") {
      if (value === "revenue" || value === "grossProfit") {
        // updateObj['period'] = 'monthly';
        updateObj['statisticIntervalOptions'] = [
          { value: 'monthly', desc: '每月'},
          { value: 'halfYear', desc: '每半年'},
          { value: 'yearly', desc: '每年'},
        ];
      } else if (value === "grossProfitPercentage") {
        // updateObj['period'] = '';
        updateObj['statisticIntervalOptions'] = [];
      } else if (value === "unitGrossProfit") {
        updateObj['statisticInterval'] = "";
        updateObj['statisticIntervalOptions'] = [
          { value: 'halfYear', desc: '每半年'},
          { value: 'yearly', desc: '每年'},
        ];
      }
    }
    setConfig({ ...updateObj });
  };

  const clearHandler = () => {
    setVouchers([]);
    setTotalCount(0);
    setConfig(cloneDeep(searchConfig));
  };

  const searchHandler = async () => {
    console.log("config:" + JSON.stringify(config));
    if (config.companyType === "" || config.companyType == null) {
      return openAlertPopup("請選擇公司");
    }
    if (config.resultType === "" || config.resultType == null) {
      return openAlertPopup("請選擇圖表或報表");
    }
    if (config.resultType === 1) {//報表
      if (config.date.start === "" || config.date.start == null) {
        return openAlertPopup("請選擇發票開始日期");
      }
      if (config.date.end === "" || config.date.end == null) {
        return openAlertPopup("請選擇發票結束日期");
      }
      setCurrentPageHandler(1);
    } else if (config.resultType === 2) {//圖表
      if (config.chartType === "" || config.chartType == null) {
        return openAlertPopup("請選擇圖表名稱");
      }
      if (config.chartType === 'revenue') {//各客戶營收比較長條圖
        if (config.customerID.length === 0) {
          return openAlertPopup("請選擇客戶");
        }
        if (config.date.start === "" || config.date.start == null) {
          return openAlertPopup("請選擇發票開始日期");
        }
        if (config.date.end === "" || config.date.end == null) {
          return openAlertPopup("請選擇發票結束日期");
        }
        if (config.statisticInterval === "" || config.statisticInterval == null) {
          return openAlertPopup("請選擇統計區間");
        }
        fetchCustomerProfitSales();
      } else if (config.chartType === 'grossProfit') {//各客戶毛利比較長條圖
        if (config.customerID.length === 0) {
          return openAlertPopup("請選擇客戶");
        }
        if (config.date.start === "" || config.date.start == null) {
          return openAlertPopup("請選擇發票開始日期");
        }
        if (config.date.end === "" || config.date.end == null) {
          return openAlertPopup("請選擇發票結束日期");
        }
        if (config.statisticInterval === "" || config.statisticInterval == null) {
          return openAlertPopup("請選擇統計區間");
        }
        fetchCustomerProfitProfit();
      } else if (config.chartType === 'grossProfitPercentage') {//各客戶毛利佔比圓餅圖
        if (config.customerID.length === 0) {
          return openAlertPopup("請選擇客戶");
        }
        if (config.date.start === "" || config.date.start == null) {
          return openAlertPopup("請選擇發票開始日期");
        }
        if (config.date.end === "" || config.date.end == null) {
          return openAlertPopup("請選擇發票結束日期");
        }
        fetchCustomerProfitProfitPie();
      } else if (config.chartType === 'unitGrossProfit') {//各客戶單位毛利比較長條圖
        if (config.customerID.length === 0) {
          return openAlertPopup("請選擇客戶");
        }
        if (config.date.start === "" || config.date.start == null) {
          return openAlertPopup("請選擇發票開始日期");
        }
        if (config.date.end === "" || config.date.end == null) {
          return openAlertPopup("請選擇發票結束日期");
        }
        if (config.statisticInterval === "" || config.statisticInterval == null) {
          return openAlertPopup("請選擇統計區間");
        }
        fetchCustomerProfitUnitGrossProfit();
      }
    }
  };

  const setCurrentPageHandler = page => {
    setCurrentPage(page);
    fetchExpenseVouchers(page);
  };

  const exportGroupProfitReportExcel = async () => {
    setShowLoadingPopup(true);
    try {
      const { data } = await getCustomerProfitReportExcel(
        {
          startDate: formatTime(config.date.start, 'YYYY-MM-DD'),
          endDate: formatTime(config.date.end, 'YYYY-MM-DD'),
          companyTypeID: config.companyType,
          customerID: config.customerID.join(","),
        }
      );
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'CustomerProfitReport.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      setShowLoadingPopup(false);
      console.log(JSON.stringify(err));
    }
    setShowLoadingPopup(false);
  }

  const exportCustomerProfitSalesExcel = async () => {
    setShowLoadingPopup(true);
    try {
      const { data } = await getCustomerProfitSalesExcel(
        {
          startDate: formatTime(config.date.start, 'YYYY-MM-DD'),
          endDate: formatTime(config.date.end, 'YYYY-MM-DD'),
          companyTypeID: config.companyType,
          customerID: config.customerID.join(","),
          statisticInterval: config.statisticInterval,
        }
      );
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'CustomerProfitReport.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      setShowLoadingPopup(false);
      console.log(JSON.stringify(err));
    }
    setShowLoadingPopup(false);
  }

  const exportCustomerProfitProfitExcel = async () => {
    setShowLoadingPopup(true);
    try {
      const { data } = await getCustomerProfitProfitExcel(
        {
          startDate: formatTime(config.date.start, 'YYYY-MM-DD'),
          endDate: formatTime(config.date.end, 'YYYY-MM-DD'),
          companyTypeID: config.companyType,
          customerID: config.customerID.join(","),
          statisticInterval: config.statisticInterval,
        }
      );
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'CustomerProfitProfitReport.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      setShowLoadingPopup(false);
      console.log(JSON.stringify(err));
    }
    setShowLoadingPopup(false);
  }

  const exportCustomerProfitProfitPieExcel = async () => {
    setShowLoadingPopup(true);
    try {
      const { data } = await getCustomerProfitProfitPieExcel(
        {
          startDate: formatTime(config.date.start, 'YYYY-MM-DD'),
          endDate: formatTime(config.date.end, 'YYYY-MM-DD'),
          companyTypeID: config.companyType,
          customerID: config.customerID.join(","),
          statisticInterval: config.statisticInterval,
        }
      );
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'CustomerProfitProfitPie.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      setShowLoadingPopup(false);
      console.log(JSON.stringify(err));
    }
    setShowLoadingPopup(false);
  }

  const exportCustomerProfitUnitGrossProfitExcel = async () => {
    setShowLoadingPopup(true);
    try {
      const { data } = await getCustomerProfitUnitGrossProfitExcel(
        {
          startDate: formatTime(config.date.start, 'YYYY-MM-DD'),
          endDate: formatTime(config.date.end, 'YYYY-MM-DD'),
          companyTypeID: config.companyType,
          customerID: config.customerID.join(","),
          statisticInterval: config.statisticInterval,
        }
      );
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'CustomerProfitUnitGrossProfitExcel.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      setShowLoadingPopup(false);
      console.log(JSON.stringify(err));
    }
    setShowLoadingPopup(false);
  }

  const exportHandler = async () => {
    if (config.resultType === 1) {//報表
      exportGroupProfitReportExcel();
    } else if (config.resultType === 2) {//圖表
      if (config.chartType === 'revenue') {//各客戶營收比較長條圖
        exportCustomerProfitSalesExcel();
      } else if (config.chartType === 'grossProfit') {//各客戶毛利比較長條圖
        exportCustomerProfitProfitExcel();
      } else if (config.chartType === 'grossProfitPercentage') {//各客戶毛利佔比圓餅圖
        exportCustomerProfitProfitPieExcel();
      } else if (config.chartType === 'unitGrossProfit') {//各客戶單位毛利比較長條圖
        exportCustomerProfitUnitGrossProfitExcel();
      }
    }
  };

  const fetchExpenseVouchers = async (page = currentPage) => {
    setShowLoadingPopup(true);

    try {
      const { data } = await getCustomerProfitReport(
        {
          startDate: formatTime(config.date.start, 'YYYY-MM-DD'),
          endDate: formatTime(config.date.end, 'YYYY-MM-DD'),
          companyTypeID: config.companyType,
          customerID: config.customerID.join(","),
        }
      );

      // const { vouchers } = data;

      // setVouchers(vouchers);
      setVouchers(data);
      setTotalCount(1);
    } catch(err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const fetchCustomerProfitSales = async () => {
    setShowLoadingPopup(true);

    try {
      const { data } = await getCustomerProfitSales(
        {
          startDate: formatTime(config.date.start, 'YYYY-MM-DD'),
          endDate: formatTime(config.date.end, 'YYYY-MM-DD'),
          companyTypeID: config.companyType,
          customerID: config.customerID.join(','),
          statisticInterval: config.statisticInterval,
        }
      );
      // const mockData = [
      //   {
      //     "director": "Ian",
      //     "statisticInterval": "7",
      //     "invoiceQueryPeriod": "string",
      //     "prPriceTotal": 20000,
      //     "incomeTotal": 20000,
      //   },
      //   {
      //     "director": "Ian",
      //     "statisticInterval": "8",
      //     "invoiceQueryPeriod": "string",
      //     "prPriceTotal": 25000,
      //     "incomeTotal": 28000,
      //   },
      //   {
      //     "director": "Tina",
      //     "statisticInterval": "7",
      //     "invoiceQueryPeriod": "string",
      //     "prPriceTotal": 55000,
      //     "incomeTotal": 68000,
      //   },
      //   {
      //     "director": "Tina",
      //     "statisticInterval": "8",
      //     "invoiceQueryPeriod": "string",
      //     "prPriceTotal": 65000,
      //     "incomeTotal": 78000,
      //   },
      // ];
      // var sortOutMockData = mockData.reduce((array, val) => {
      //   array.push({ year: val.director + val.statisticInterval, value: val.prPriceTotal, type: "服務費總額" });
      //   array.push({ year: val.director + val.statisticInterval, value: val.incomeTotal, type: "專案其它收入" });
      //   return array;
      // }, []);
      // console.log(sortOutMockData);
      data.sort((a,b) => a.statisticInterval.localeCompare(b.statisticInterval));
      var sortOutData = data.reduce((array, val) => {
        array.push({ year: val.customer + "/" + val.statisticInterval, value: (val.prPriceTotal), type: "服務費總額" });
        array.push({ year: val.customer + "/" + val.statisticInterval, value: (val.incomeTotal), type: "專案其它收入" });
        return array;
      }, []);
      // console.log(sortOutData);
      setDataGroupProfitSales(sortOutData);
    } catch(err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const fetchCustomerProfitProfit = async () => {
    setShowLoadingPopup(true);

    try {
      // setDataGroupProfitSales(
      //   [
      //     {
      //       "year": "202206Ian Chao",
      //       "value": 40000,
      //       "type": "服務費總額"
      //     },
      //     {
      //       "year": "202206Ian Chao",
      //       "value": 150000,
      //       "type": "專案其它收入"
      //     },
      //     {
      //       "year": "202207Ian Chao",
      //       "value": 200000,
      //       "type": "服務費總額"
      //     },
      //     {
      //       "year": "202207Ian Chao",
      //       "value": 200000,
      //       "type": "專案其它收入"
      //     },
      //   ]
      // );
      const { data } = await getCustomerProfitProfit(
        {
          startDate: formatTime(config.date.start, 'YYYY-MM-DD'),
          endDate: formatTime(config.date.end, 'YYYY-MM-DD'),
          companyTypeID: config.companyType,
          customerID: config.customerID.join(','),
          statisticInterval: config.statisticInterval,
        }
      );
      // const mockData = [
      //   {
      //     "director": "Ian",
      //     "statisticInterval": "2021/9",
      //     "invoiceQueryPeriod": "string",
      //     "invoiceTotal": 20000,
      //     "expenseTotal": 20000,
      //     "grossProfit": 10000,
      //   },
      //   {
      //     "director": "Ian",
      //     "statisticInterval": "2021/10",
      //     "invoiceQueryPeriod": "string",
      //     "invoiceTotal": 20000,
      //     "expenseTotal": 20000,
      //     "grossProfit": 20000,
      //   },
      // ];
      // var sortOutMockData = mockData.reduce((array, val) => {
      //   array.push({ year: val.customer + val.statisticInterval, value: val.grossProfit, type: "客戶毛利" });
      //   return array;
      // }, []);
      // console.log(sortOutMockData);
      data.sort((a,b) => a.statisticInterval.localeCompare(b.statisticInterval));
      var sortOutData = data.reduce((array, val) => {
        array.push({ year: val.customer + val.statisticInterval, value: decimalCommaConverter(val.grossProfit), type: "客戶毛利" });
        return array;
      }, []);
      setDataGroupProfitSales(sortOutData);
    } catch(err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const fetchCustomerProfitProfitPie = async () => {
    setShowLoadingPopup(true);

    try {
      const { data } = await getCustomerProfitProfitPie(
        {
          startDate: formatTime(config.date.start, 'YYYY-MM-DD'),
          endDate: formatTime(config.date.end, 'YYYY-MM-DD'),
          companyTypeID: config.companyType,
          customerID: config.customerID.join(','),
          statisticInterval: config.statisticInterval,
        }
      );
      // const mockData = [
      //   {
      //     "director": "Ian",
      //     "invoiceQueryPeriod": "string",
      //     "invoiceTotal": 20000,
      //     "expenseTotal": 20000,
      //     "grossProfit": 40,
      //     "invoiceTotalOther": 0,
      //     "expenseTotalOther": 0,
      //     "grossProfitOther": 60,
      //   },
      // ];
      // var sortOutMockData = mockData.reduce((array, val) => {
      //   array.push({ type: val.director, value: val.grossProfit});
      //   array.push({ type: "其它組", value: val.grossProfitOther});
      //   return array;
      // }, []);
      // console.log(sortOutMockData);
      var sortOutData = data.reduce((array, val) => {
        array.push({ type: val.director, value: val.grossProfit});
        // array.push({ type: "其它組", value: val.grossProfitOther});
        return array;
      }, []);
      if (data.length > 0) {
        sortOutData.push({ type: "其它組", value: data[0].grossProfitOther});
      }
      setDataPie(sortOutData);
    } catch(err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const fetchCustomerProfitUnitGrossProfit = async () => {
    setShowLoadingPopup(true);

    try {
      const { data } = await getCustomerProfitProfit(
        {
          startDate: formatTime(config.date.start, 'YYYY-MM-DD'),
          endDate: formatTime(config.date.end, 'YYYY-MM-DD'),
          companyTypeID: config.companyType,
          customerID: config.customerID.join(','),
          statisticInterval: config.statisticInterval,
        }
      );
      // const mockData = [
      //   {
      //     "director": "Ian",
      //     "statisticInterval": "2021/9",
      //     "invoiceQueryPeriod": "string",
      //     "invoiceTotal": 20000,
      //     "expenseTotal": 20000,
      //     "grossProfit": 10000,
      //     "projectMCount": 0,
      //     "unitGrossProfit": 5000,
      //   },
      //   {
      //     "director": "Ian",
      //     "statisticInterval": "2021/10",
      //     "invoiceQueryPeriod": "string",
      //     "invoiceTotal": 20000,
      //     "expenseTotal": 20000,
      //     "grossProfit": 10000,
      //     "projectMCount": 0,
      //     "unitGrossProfit": 10000,
      //   },
      // ];
      // var sortOutMockData = mockData.reduce((array, val) => {
      //   array.push({ year: val.director + val.statisticInterval, value: val.grossProfit, type: "單位毛利" });
      //   return array;
      // }, []);
      // console.log(sortOutMockData);
      data.sort((a,b) => a.statisticInterval.localeCompare(b.statisticInterval));
      var sortOutData = data.reduce((array, val) => {
        array.push({ year: val.director + val.statisticInterval, value: val.grossProfit, type: "單位毛利" });
        return array;
      }, []);
      setDataGroupProfitSales(sortOutData);
    } catch(err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const fetchCustomerByCompany = useCallback( async () => {
    // console.log("fetchCustomerByCompany companyTypeOptions:" + JSON.stringify(companyTypeOptions));
    try {
      const arrayCompanyType = companyTypeOptions.map(object => object.value);
      console.log("fetchCustomerByCompany arrayCompanyType:" + JSON.stringify(arrayCompanyType));
      const { data } = await getCompanyCustomers(
        {
          companyTypeID: arrayCompanyType.join(','),
        }
      );
      // console.log("fetchCustomerByCompany data:" + JSON.stringify(data));
      setAllCustomersByCompany(data);
    } catch (err) {
      console.log(JSON.stringify(err));
    }
  }, [companyTypeOptions]);

  const initOptions = useCallback(() => {
    dispatch(FETCH_COMPANY_TYPE_OPTIONS());
    dispatch(FETCH_CUSTOMER_OPTIONS());
  }, [dispatch]);

  useEffect(() => {
    initOptions();
  }, [initOptions]);

  useEffect(() => {
    fetchCustomerByCompany();
  }, [fetchCustomerByCompany]);

  return(
    <>
      <DivPanelContainer>
        <PanelRevenueByCustomerSearch
          config = { config }
          companyTypeOptions = { companyTypeOptions }
          customerOptions = { customerOptions }
          clearHandler = { clearHandler }
          exportHandler = { exportHandler }
          searchHandler = { searchHandler }
          updateConfigHandler = { updateConfigHandler }
        />
      </DivPanelContainer>
      {config.resultType === 1 && 
        <TableRevenueByCustomer
          vouchers = { vouchers }
          totalCount = { totalCount }
          currentPage = { currentPage }
          pageChangedHandler = { setCurrentPageHandler }
        />
      }
      {config.resultType === 2 && (config.chartType === "revenue" || config.chartType === "grossProfit" || config.chartType === "unitGrossProfit") && 
        <Column {...chartConfig}></Column>
      }
      {config.resultType === 2 && config.chartType === "grossProfitPercentage" && 
        <Pie {...pieConfig}></Pie>
      }
      { showLoadingPopup && <PopupLoading/> }
      {
        showAlertPopup &&
          <PopupExecuteResult
            title = "請選擇欄位"
            text = { alertText }
            confirmHandler = { () => { setShowAlertPopup(false) } }
          />
      }
    </>
  );
};

export default RevenueByCustomerSearch;
