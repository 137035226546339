import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";
import queryString from 'query-string';
import styled from 'styled-components';
import { cloneDeep } from 'lodash';

import { 
  postToGetProjectDetail,
  putProjectDetail, 
} from '@/api/projectService';

import {
  postToGetUploadFiles,
  // postToDownloadCompressedFiles,
  getVoucherDownloadFile,
} from '@/api/storageService';

import {
  postToGetExpenses,
  postToGetQuotations,
  postToGetExpenseDetail,
  postToGetVouchersTotal,
  postToGetExpenseQuotationIDs,
  postToGetExpenseParamsConfig,
  postToCheckInvoices,
} from '@/api/expenseService';

import {
  postToGetProjectFinance,
  patchModifyProjectFinance,
  postToGetProjectQuotations,
  patchModifyProjectPRServices,
  putModifyProjectInvoicesDetail,
  postToGetProjectInvoicesDetail,
  postToGetProjectPRServices,
} from '@/api/financeService';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import {
  FETCH_MEMBER_OPTIONS,
  FETCH_DIRECTOR_OPTIONS,
  FETCH_VENDOR_ID_OPTIONS,
  FETCH_TODO_TYPE_OPTIONS,
  FETCH_PROJECT_ID_OPTIONS,
  FETCH_VENDOR_TYPE_OPTIONS,
  FETCH_EXPENSE_TYPE_OPTIONS,
  FETCH_COMPANY_TYPE_OPTIONS,
} from '@/store/actions/srpOptionsAction';

import MainContent from '@/component-style/MainContent';
import PanelWrapper from '@/components/UI/PanelWrapper';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import TableProjectStatus from '@/components/Table/Project/TableProjectStatus';
import TableProjectQuotations from '@/components/Table/Finance/TableProjectQuotations';
import PanelProjectQuotation from '@/components/Panel/Finance/PanelProjectQuotation';
import PanelProjectAccounting from '@/components/Panel/Finance/PanelProjectAccounting';
import PanelPRserviceInvoices from '@/components/Panel/Finance/PanelPRserviceInvoices';
import PanelQuotationInvoices from '@/components/Panel/Finance/PanelQuotationInvoices';
import PopupPurchaseAttachments from '@/components/UI/Popup/PopupPurchaseAttachments';
import SelectSearchable from '@/components/UI/Select/SelectSearchable';
import SearchButtonSet from '@/components/UI/SearchButtonSet';
import { PSubtitle } from '@/component-style/TextElement';
import {
  DivFlexRow,
  DivFullWidthWrapper,
  DivLongMultiSelector,
} from '@/component-style/RWDWrapper';

import {
  getOptionDesc,
  integerConverter,
} from '@/utils/textUtils';

import {
  projectFinanceConfig,
  projectPRServiceItem,
  projectInvoicesConfig,
  projectPRServicesConfig,
} from '@/constant/dataConfig/financeModule';

import { projectStatusConfig } from '@/constant/dataConfig/projectModule';
import { vendorInvoiceTypes } from '@/constant/options';
import { exportExpenditureFile } from '@/utils/xlsx';
import { formatTime } from '@/utils/timeUtils';

const DivPanelContainer = styled.div`
  margin-bottom: 40px;
`;

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding: 0;
  justify-content: flex-end;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const ProjectFinanceInfo = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const id = queryString.parse(location.search).id;
  const vendorIDs = useSelector(state => state.srpOptions.vendorIDOptions);
  const financeRoutes = useSelector(state => state.login.financeRoutes);
  const memberOptions = useSelector(state => state.srpOptions.memberOptions);
  const directorOptions = useSelector(state => state.srpOptions.directorOptions);
  const projectIDOptions = useSelector(state => state.srpOptions.projectIDOptions);
  const expenseTypeOptions = useSelector(state => state.srpOptions.expenseTypeOptions);
  const showSaveFailPopup = useSelector(state => state.apiFail.showSaveFailPopup);
  const apiFailPopupTitle = useSelector(state => state.apiFail.title);
  const apiFailPopupMessage = useSelector(state => state.apiFail.message);

  const [agentDifference, setAgentDifference] = useState(0);

  const [quotations, setQuotations] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [fileList, setFileList] = useState([]);
  const [poOptions, setPoOptions] = useState([]);
  const [invoiceOptions, setInvoiceOptions] = useState([]);

  const [alertText, setAlertText] = useState('');
  const [showAlertPopup, setShowAlertPopup] = useState(false);

  const [projectID, setProjectID] = useState(id || '');
  const [projectStatus, setProjectStatus] = useState({
    ...projectStatusConfig,
  });
  const [projectFinance, setProjectFinance] = useState({
    ...projectFinanceConfig,
  });
  const [projectDetail, setProjectDetail] = useState({});

  const [projectInvoice, setProjectInvoices] = useState(
    cloneDeep(projectInvoicesConfig)
  );
  const [projectPRServices, setProjectPRServices] = useState(
    cloneDeep(projectPRServicesConfig)
  );

  const [prPriceTotal, setPrPriceTotal] = useState('');
  const [oopPriceTotal, setOopPriceTotal] = useState('');
  const [prServiceTotal, setPRServiceTotal] = useState('');
  const [voucherPriceTotal, setVoucherPriceTotal] = useState('');
  const [prQuotedPriceTotal, setPrQuotedPriceTotal] = useState('');
  const [oopQuotedPriceTotal, setOopQuotedPriceTotal] = useState('');
  const [quotationPriceTotal, setQuotationPriceTotal] = useState('');

  //Ian calc expense total
  const [untaxedExpenseTotal ,setUntaxedExpenseTotal] = useState('');
  const [taxedExpenseTotal ,setTaxedExpenseTotal] = useState('');

  const [untaxedIncomeTotal ,setUntaxedIncomeTotal] = useState('');
  const [taxedIncomeTotal ,setTaxedIncomeTotal] = useState('');

  const [serviceFeeTotal, setServiceFeeTotal] = useState('');

  const [isEditingAccounting, setIsEditingAccounting] = useState(false);
  const [isEditingQuotation, setIsEditingQuotation] = useState(false);
  const [isEditingPRService, setIsEditingPRService] = useState(false);
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [showSaveSuccessPopup, setShowSaveSuccessPopup] = useState(false);
  const [showAttachmentPopup, setShowAttachmentPopup] = useState(false);

  const userEditable = financeRoutes.indexOf('ProjectFinanceInfo-Edit') !== -1;
  const projectFiannceInfoOutputClearedEditable = financeRoutes.indexOf('ProjectFinanceInfo-Output-Cleared') !== -1;
  console.log("projectFinanceInfo.js projectFiannceInputClearedEditable:" + projectFiannceInfoOutputClearedEditable);
  
  const updateProjectDetailHandler = (key, value) => {
    const updateObj = { ...projectDetail };
    updateObj[key] = value;
    setProjectDetail(updateObj);
  };

  const updateProjectFinanceHandler = (key, value) => {
    const updateObj = { ...projectFinance };
    updateObj[key] = value;
    setProjectFinance(updateObj);
    if (key === "projectOtherIncome") {
      setServiceFeeTotal(untaxedIncomeTotal - untaxedExpenseTotal - value);
    }
  };

  const updateProjectInvoiceHandler = (key, value) => {
    const updateObj = { ...projectInvoice };
    updateObj[key] = value;
    setProjectInvoices(updateObj);
  };

  const updateProjectPRServiceHandler = (key, value) => {
    const updateObj = { ...projectPRServices };
    updateObj[key] = value;
    setProjectPRServices(updateObj);
  };

  const setCurrentPageHandler = page => {
    setCurrentPage(page);
    fetchProjectQuotations(page);
  };

  const clearHandler = () => {
    setQuotations([]);
    setTotalCount(0);
    setCurrentPage(1);
    setFileList([]);
    setPoOptions([]);
    setInvoiceOptions([]);
    setProjectID('');
    setPrPriceTotal('');
    setOopPriceTotal('');
    setPRServiceTotal('');
    setVoucherPriceTotal('');
    setPrQuotedPriceTotal('');
    setOopQuotedPriceTotal('');
    setQuotationPriceTotal('');
    setIsEditingAccounting(false);
    setIsEditingQuotation(false);
    setIsEditingPRService(false);
    setProjectStatus({ ...projectStatusConfig });
    setProjectFinance({ ...projectFinanceConfig });
    setProjectInvoices(cloneDeep(projectInvoicesConfig));
    setProjectPRServices(cloneDeep(projectPRServicesConfig));
  };

  const openAttachmentPopupHandler = async () => {
    await fetchUploadFiles();
    setShowAttachmentPopup(true);
  };

  const downloadVoucherHandler = async () => {
    setShowLoadingPopup(true);

    // try {
    //   const { data } = await postToGetExpenseQuotationIDs({ projectID });
    //   const zipFile = await postToDownloadCompressedFiles({
    //     objectType: 'quotationVoucher',
    //     items: [...data],
    //   });
    //   const url = window.URL.createObjectURL(new Blob([zipFile.data]));
    //   const link = document.createElement('a');
    //   link.href = url;
    //   link.setAttribute('download', 'quotationVoucher.zip');
    //   document.body.appendChild(link);
    //   link.click();
    //   document.body.removeChild(link);
    // } catch (err) { console.log(err) }
    try {
        const { data } = await postToGetExpenseQuotationIDs({ projectID });
        const zipFile = await getVoucherDownloadFile({
          voucherID: data.join(),
        });
        const url = window.URL.createObjectURL(new Blob([zipFile.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'quotationVoucher.zip');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const exportVouchersHandler = async () => {
    setShowLoadingPopup(true);
    let financeDatas = [];

    try {
      const expensesDetail = await postToGetExpenses({
        projectID,
        page: 1,
        amount: 10,
        approvalStatus: [
          'projectManagerVerify',
          'projectDirectorVerify',
          'financeVerify',
          'generalManagerVerifying',
          'generalManagerApproved',
        ]
      });

      const { data } = await postToGetExpenses({
        projectID,
        page: 1,
        amount: expensesDetail.data.totalCount || 0,
        approvalStatus: [
          'projectManagerVerify',
          'projectDirectorVerify',
          'financeVerify',
          'generalManagerVerifying',
          'generalManagerApproved',
        ]
      });
      const { expenses } = data;
      const expensesData = [];

      for (let i = 0; i < expenses.length; i += 1) {
        const expense = expenses[i];
        const { expenseID } = expense;
        const expenseDetail = await postToGetExpenseDetail(expenseID);

        expensesData.push({ ...expenseDetail.data });

        financeDatas = expensesData
          .reduce((acc, expense) => {
            const expenses = expense.vouchers.map(voucher => [
              getOptionDesc(memberOptions, expense.user),
              expense.expenseCode || '',
              expense.object === 'user'
                ? getOptionDesc(memberOptions, expense.objectID)
                : getOptionDesc(vendorIDs, expense.objectID),
              getOptionDesc(vendorInvoiceTypes, voucher.voucherType),
              voucher.date ? formatTime(voucher.date, 'YYYY/MM/DD') : '',
              voucher.voucherCode,
              voucher.taxID,
              voucher.untaxedAmount || 0,
              voucher.tax || 0,
              voucher.amount || 0,
              getOptionDesc(expenseTypeOptions, voucher.type),
              voucher.tag,
              voucher.note,
            ]);
            return [...acc, ...expenses];
          }, []);
      }

    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
    exportExpenditureFile(financeDatas);
  };

  const saveProjectFinanceHandler = async () => {
    setShowLoadingPopup(true);
    console.log("projectFinanceInfo.js projectDetail:" + JSON.stringify(projectDetail));

    try {
      await patchModifyProjectFinance({
        ...projectFinance,
        specialPrice: integerConverter(projectFinance.specialPrice),
      });
      await fetchProjectFinance();
      await putProjectDetail({
        ...projectDetail,
      });
      await fetchProjectStatus();
      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
      setIsEditingAccounting(false);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '儲存失敗', err }));
      console.log(err);
    }

    console.log("projectFinanceInfo.js projectDetail:" + JSON.stringify(projectDetail));
  };

  const cancelProjectFinanceHandler = async () => {
    await fetchProjectFinance();
    setIsEditingAccounting(false);
  };

  const saveProjectInvoiceHandler = async () => {
    setShowLoadingPopup(true);
    const newlyAddedInvoiceCodes = projectInvoice.invoices.filter(invoice => invoice.isNewlyAdded === true).map(invoice => invoice.invoiceCode);
    console.log("Found newly added invoice:" + newlyAddedInvoiceCodes);
    // console.log(projectInvoice);
    try {
      const validInvoiceCodes = await postToCheckInvoices( newlyAddedInvoiceCodes );
      console.log("result of after postToCheckInvoices newly added invoice:" + validInvoiceCodes.data);
    } catch (err) {
    }

    const invoices = projectInvoice.invoices
      .map(invoice => ({
        ...invoice,
        tax: integerConverter(invoice.tax),
        amount: integerConverter(invoice.amount),
        income: integerConverter(invoice.income),
        untaxedAmount: integerConverter(invoice.untaxedAmount),
        createDate: formatTime(invoice.createDate, 'YYYY-MM-DD'),
        predictDate: formatTime(invoice.predictDate, 'YYYY-MM-DD'),
        paymentDate: formatTime(invoice.paymentDate, 'YYYY-MM-DD'),
        mbr: formatTime(invoice.mbr, 'YYYY-MM-DD'),
      }));
    invoices.forEach(invoice => {
      delete invoice.isNewlyAdded;
    });
    console.log("invoices" + JSON.stringify(invoices));
    try {
      await putModifyProjectInvoicesDetail({ ...projectInvoice, invoices });
      // console.log("putModifyProjectInvoicesDetail");
      await fetchProjectInvoices();
      // console.log("fetchProjectInvoices");
      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
      setIsEditingQuotation(false);

      await putProjectDetail({
        ...projectDetail,
      });
      await fetchProjectStatus();
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '儲存失敗', err }));
      console.log(err);
    }
  };

  const cancelProjectInvoiceHandler = async () => {
    await fetchProjectInvoices();
    setIsEditingQuotation(false);
  };

  const openAlertPopup = text => {
    setAlertText(text);
    setShowAlertPopup(true);
  };

  const saveProjectPRServicesHandler = async () => {
    setShowLoadingPopup(true);

    const amountByInvoiceId = projectInvoice.invoices.reduce((acc, invoice) => {
      if (acc[invoice.invoiceCode] == null) {
        acc[invoice.invoiceCode] = 0;
      }
      acc[invoice.invoiceCode] = invoice.untaxedAmount;
      return acc;
    }, {});
    // console.log("saveProjectPRServicesHandler amountByInvoiceId:" + JSON.stringify(amountByInvoiceId));
    
    const totalServiceByInvoiceId = projectPRServices.prServices.reduce((acc, quotation) => {
      if (acc[quotation.invoiceCode] == null) {
        acc[quotation.invoiceCode] = 0;
      }
      if (!isNaN(parseInt(quotation.oopPrice))) {
        acc[quotation.invoiceCode] += parseInt(quotation.oopPrice);
      }
      if (!isNaN(parseInt(quotation.prPrice))) {
        acc[quotation.invoiceCode] += parseInt(quotation.prPrice);
      }
      if (!isNaN(parseInt(quotation.income))) {
        acc[quotation.invoiceCode] += parseInt(quotation.income);
      }
      return acc;
    }, {});
    // console.log("saveProjectPRServicesHandler totalServiceByInvoiceId:" + JSON.stringify(totalServiceByInvoiceId));

    var invoiceIdHasWrongAmount = "";
    const keys = Object.keys(amountByInvoiceId);
    keys.forEach((key, index) => {
      // console.log(`${key}: ${amountByInvoiceId[key]}`);
      if (amountByInvoiceId[key] != null) {
        // console.log(`${key}: ${amountByInvoiceId[key]}`);
        if (!(totalServiceByInvoiceId[key] == null)) {
          if (amountByInvoiceId[key] !== totalServiceByInvoiceId[key]) {
            console.log(`${key}: ${amountByInvoiceId[key]}`);
            console.log(`${key}: ${totalServiceByInvoiceId[key]}`);
            invoiceIdHasWrongAmount = key;
          }
        }
      }
    });
    if (invoiceIdHasWrongAmount !== "") {
      openAlertPopup("發票號碼：" + invoiceIdHasWrongAmount + "金額與服務費加總不相等");
      setShowLoadingPopup(false);
      return;
    }

    const prServices = projectPRServices.prServices
      .map((prServices, index) => ({
        ...prServices,
        index,
        income: integerConverter(prServices.income),
        prPrice: integerConverter(prServices.prPrice),
        oopPrice: integerConverter(prServices.oopPrice),
      }));

    try {
      await patchModifyProjectPRServices({
         ...projectPRServices,
         prServices,
      });
      await fetchProjectPRServices();
      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
      setIsEditingPRService(false);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '儲存失敗', err }));
      console.log(err);
    }
  };

  const cancelProjectPRServicesHandler = async () => {
    await fetchProjectPRServices();
    setIsEditingPRService(false);
  };

  const fetchUploadFiles = async () => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetUploadFiles('projectFinance', projectID);
      const poOptions = data.map(({ name }) => ({ value: name, desc: name }));
      
      setFileList([ ...data ]);
      setPoOptions([ ...poOptions ]);
      setProjectStatus(prevState => ({
        ...prevState,
        isVerified: data.length > 0,
      }));
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const fetchProjectQuotations = async (page = currentPage) => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetProjectQuotations({
        projectID,
        amount: 10,
        page,
      });
      const { totalCount, quotations } = data;

      const totalQuotations = await postToGetProjectQuotations({
        projectID,
        page: 1,
        amount: totalCount || 1,
      });

      const quotationsDetail = await postToGetQuotations({ projectID });
      const voucherTotalDetail = await postToGetVouchersTotal({
        projectID,
        object: [],
        item: 'amount',
        approvalStatus: [
          'projectManagerVerify',
          'projectDirectorVerify',
          'financeVerify',
          'generalManagerVerifying',
          'generalManagerApproved',
        ],
      });

      const quotationPriceTotal = quotationsDetail.data
        .map(({ price }) => integerConverter(price || 0))
        .reduce((acc, cur) => acc + cur, 0);
      
      const voucherPriceTotal = voucherTotalDetail.data.total || 0;

      const oopQuotations = totalQuotations.data.quotations
        .filter(quotation => quotation.type === 'oop');

      const oopPriceTotal = oopQuotations
        .map(({ price }) => integerConverter(price || 0))
        .reduce((acc, cur) => acc + cur, 0);

      const oopQuotedPriceTotal = oopQuotations
        .map(({ quotedPrice }) => integerConverter(quotedPrice || 0))
        .reduce((acc, cur) => acc + cur, 0);

      const prQuotations = totalQuotations.data.quotations
        .filter(quotation => quotation.type === 'pr');

      const prPriceTotal = prQuotations
        .map(({ price }) => integerConverter(price || 0))
        .reduce((acc, cur) => acc + cur, 0);

      const prQuotedPriceTotal = prQuotations
        .map(({ quotedPrice }) => integerConverter(quotedPrice || 0))
        .reduce((acc, cur) => acc + cur, 0);

      let financeStatus = 'success';

      const isFinanceFail = quotationPriceTotal - oopPriceTotal > agentDifference
        || voucherPriceTotal - oopPriceTotal > agentDifference;

      const isFinanceWarning = quotationPriceTotal > oopPriceTotal
        || voucherPriceTotal > oopPriceTotal;

      if (isFinanceWarning) { financeStatus = 'warning' }
      if (isFinanceFail) { financeStatus = 'fail' }

      setPrPriceTotal(prPriceTotal);
      setOopPriceTotal(oopPriceTotal);
      setPrQuotedPriceTotal(prQuotedPriceTotal);
      setOopQuotedPriceTotal(oopQuotedPriceTotal);
      setVoucherPriceTotal(voucherPriceTotal);
      setQuotationPriceTotal(quotationPriceTotal);
      setQuotations([...quotations]);
      setTotalCount(totalCount || 0);
      setProjectStatus(prevState => ({
        ...prevState,
        financeStatus,
        isQuoted: totalCount && totalCount > 0,
      }));
    } catch (err) { console.log(err) }
    
    setShowLoadingPopup(false);
  };

  const fetchProjectStatus = async () => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetProjectDetail(projectID);

      setProjectStatus({
        projectCode: data.projectCode,
        projectName: data.projectName,
        companyType: data.companyType || '',
        actionTimeEnd: data.actionTimeEnd,
        actionTimeStart: data.actionTimeStart,
      });

      setProjectDetail({
        ...data
      });
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const fetchProjectFinance = async () => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetProjectFinance(projectID);

      setProjectFinance({
        ...projectFinanceConfig,
        ...data,
        projectID,
        specialPrice: data.specialPrice || '',
      });
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const fetchProjectInvoices = async () => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetProjectInvoicesDetail(projectID);
      data.invoices.forEach(element => {
        element.isNewlyAdded = false;
      });
      const { invoices } = data;
      const invoiceOptions = invoices
        .filter(({ invoiceCode }) => invoiceCode !== '')
        .map(({ invoiceCode }) => ({ value: invoiceCode, desc: invoiceCode }));
      
      const prServiceTotal = data.invoices
        .map(({ untaxedAmount }) => integerConverter(untaxedAmount || 0))
        .reduce((acc, cur) => acc + cur, 0);

      setPRServiceTotal(prServiceTotal);

      const isInvoiced = invoices.length > 0;

      // const isPaid = invoices
      //   .some(quotation => quotation.paymentDate !== undefined);

      const predictDates = invoices
        .filter(({ predictDate }) => predictDate !== undefined)
        .map(({ predictDate }) => predictDate);

      const predictDate = predictDates.length > 0
        ? predictDates.shift()
        : null;

      setInvoiceOptions(invoiceOptions);
      setProjectInvoices({
        ...cloneDeep(projectInvoicesConfig),
        ...data,
        companyType: data.companyType || '',
      });
      setProjectStatus(prevState => ({
        ...prevState,
        // isPaid,
        isInvoiced,
        predictDate: isInvoiced ? predictDate : null,
      }));

      //get expenses
      let financeDatas = [];
      const expensesDetail = await postToGetExpenses({
        projectID,
        page: 1,
        amount: 1000,
        approvalStatus: [
          'projectManagerVerify',
          'projectDirectorVerify',
          'financeVerify',
          'generalManagerVerifying',
          'generalManagerApproved',
        ]
      });

      // var myData = {data:[]};
      // if (expensesDetail.data.totalCount > 0) {
      //   myData = await postToGetExpenses({
      //     projectID,
      //     page: 1,
      //     amount: expensesDetail.data.totalCount || 0,
      //     approvalStatus: [
      //       'projectManagerVerify',
      //       'projectDirectorVerify',
      //       'financeVerify',
      //       'generalManagerVerifying',
      //       'generalManagerApproved',
      //     ]
      //   });
      // }
      const { expenses } = expensesDetail.data;
      const expensesData = [];

      for (let i = 0; i < expenses.length; i += 1) {
        const expense = expenses[i];
        const { expenseID } = expense;
        const expenseDetail = await postToGetExpenseDetail(expenseID);

        expensesData.push({ ...expenseDetail.data });

        financeDatas = expensesData
          .reduce((acc, expense) => {
            const expenses = expense.vouchers.map(voucher => [
              getOptionDesc(memberOptions, expense.user),
              expense.expenseCode || '',
              expense.object === 'user'
                ? getOptionDesc(memberOptions, expense.objectID)
                : getOptionDesc(vendorIDs, expense.objectID),
              getOptionDesc(vendorInvoiceTypes, voucher.voucherType),
              voucher.date ? formatTime(voucher.date, 'YYYY/MM/DD') : '',
              voucher.voucherCode,
              voucher.taxID,
              voucher.untaxedAmount || 0,
              voucher.tax || 0,
              voucher.amount || 0,
              getOptionDesc(expenseTypeOptions, voucher.type),
              voucher.tag,
              voucher.note,
            ]);
            return [...acc, ...expenses];
          }, []);
      }
      // console.log("projectFinanceInfo.js fetchProjectInvoices financeDatas:" + JSON.stringify(financeDatas));
      var untaxedIncome = 0;
      var taxedIncome = 0;
      data.invoices.forEach(function (arrayItem) {
        untaxedIncome += arrayItem.untaxedAmount;
        taxedIncome += arrayItem.amount;
      });
      setUntaxedIncomeTotal(untaxedIncome);
      setTaxedIncomeTotal(taxedIncome);
      console.log("projectFinanceInfo.js fetchProjectInvoices untaxedIncome:" + untaxedIncome + " taxedIncome:" + taxedIncome);
      var untaxedExpense = 0;
      var taxedExpense = 0;
      financeDatas.forEach(function (arrayItem) {
        // var x = arrayItem.prop1 + 2;
        // console.log(arrayItem[7]);
        untaxedExpense += arrayItem[7];
        taxedExpense += arrayItem[9];
      });
      console.log("projectFinanceInfo.js fetchProjectInvoices untaxedExpense:" + untaxedExpense + "taxedExpense:" + taxedExpense);
      setUntaxedExpenseTotal(untaxedExpense);
      setTaxedExpenseTotal(taxedExpense);
      setServiceFeeTotal(untaxedIncome - untaxedExpense - projectFinance.projectOtherIncome);
      //get expenses
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const fetchProjectPRServices = async () => {
    // console.log("projectFinanceInfo.js fetchProjectPRservices");
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetProjectPRServices(projectID);
      // const prServiceTotal = data.prServices
      //   .map(({ prPrice, oopPrice, income }) => integerConverter(prPrice || 0)
      //     + integerConverter(oopPrice || 0)
      //     + integerConverter(income || 0))
      //   .reduce((acc, cur) => acc + cur, 0);

      // setPRServiceTotal(prServiceTotal);
      setProjectPRServices({
        ...cloneDeep(projectPRServicesConfig),
        ...data,
        companyType: data.companyType || '',
        prServices: data.prServices
          .map(prServices => ({ ...projectPRServiceItem, ...prServices }))
      });
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const fetchExpenseParams = async () => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetExpenseParamsConfig();
      const { agentDifference } = data;
      setAgentDifference(agentDifference || 0);
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };


  const fetchProjectFinanceData = async () => {
    setCurrentPage(1);

    await fetchExpenseParams();
    await fetchProjectStatus();
    await fetchProjectFinance();
    await fetchUploadFiles();
    await fetchProjectQuotations(1);
    await fetchProjectInvoices();
    await fetchProjectPRServices();
  };

  const initProjectFinanceData = useCallback(
    fetchProjectFinanceData, []);

  const initOptions = useCallback(() => {
    dispatch(FETCH_MEMBER_OPTIONS());
    dispatch(FETCH_DIRECTOR_OPTIONS());
    dispatch(FETCH_VENDOR_ID_OPTIONS());
    dispatch(FETCH_TODO_TYPE_OPTIONS());
    dispatch(FETCH_PROJECT_ID_OPTIONS());
    dispatch(FETCH_VENDOR_TYPE_OPTIONS());
    dispatch(FETCH_EXPENSE_TYPE_OPTIONS());
    dispatch(FETCH_COMPANY_TYPE_OPTIONS());
  }, [dispatch]);

  useEffect(() => {
    initOptions();
  }, [initOptions]);

  useEffect(() => {
    id !== undefined
      && initProjectFinanceData()
  }, [id, initProjectFinanceData]);

  return (
    <MainContent>
      <DivPanelContainer>
        <PanelWrapper title = "專案財務資料">
          <DivFlexRow>
            <DivFullWidthWrapper>
              <PSubtitle>專案代號</PSubtitle>
              <DivLongMultiSelector>
                <SelectSearchable
                  value = { projectID }
                  options = {[
                    { value: '', desc: '請選擇專案代號' },
                    ...projectIDOptions,
                  ]}
                  changeHandler = { setProjectID }
                />
              </DivLongMultiSelector>
            </DivFullWidthWrapper>
          </DivFlexRow>
          <DivFlexRow>
            <DivButtonsWrapper>
              <SearchButtonSet
                searchable = { projectID !== '' }
                searchHandler = { fetchProjectFinanceData }
                clearHandler = { clearHandler }
              />
            </DivButtonsWrapper>
        </DivFlexRow>
        </PanelWrapper>
      </DivPanelContainer>
      <DivPanelContainer>
        <TableProjectStatus
          title = "專案財務詳情"
          project = { projectStatus }
        />
      </DivPanelContainer>
      <DivPanelContainer>
        <PanelProjectQuotation
          disabled = { true }
          disableAttachment = { projectFinance.projectID === '' }
          prPriceTotal = { prPriceTotal }
          oopPriceTotal = { oopPriceTotal }
          prQuotedPriceTotal = { prQuotedPriceTotal }
          oopQuotedPriceTotal = { oopQuotedPriceTotal }
          specialPrice = { projectFinance.specialPrice }
          quotationPriceTotal = { quotationPriceTotal }
          openAttachmentPopup = { openAttachmentPopupHandler }
        />
      </DivPanelContainer>
      <DivPanelContainer>
        <TableProjectQuotations
          disabled = { true }
          quotations = { quotations }
          totalCount = { totalCount }
          currentPage = { currentPage }
          pageChangedHandler = { setCurrentPageHandler }
        />
      </DivPanelContainer>
      <DivPanelContainer>
        <PanelProjectAccounting
          isEditing = { isEditingAccounting }
          uneditable = { !userEditable }
          prServiceTotal = { prServiceTotal }
          projectFinance = { projectFinance }
          voucherPriceTotal = { voucherPriceTotal }
          editHandler = { () =>  { setIsEditingAccounting(true) }}
          saveHandler = { saveProjectFinanceHandler }
          cancelHandler = { cancelProjectFinanceHandler }
          exportVouchersHandler = { exportVouchersHandler }
          downloadVoucherHandler = { downloadVoucherHandler }
          updateProjectFinanceHandler = { updateProjectFinanceHandler }
          projectDetail = { projectDetail }
          updateProjectDetailHandler = { updateProjectDetailHandler }
          untaxedExpenseTotal = { untaxedExpenseTotal }
          taxedExpenseTotal = { taxedExpenseTotal }
          untaxedIncomeTotal = { untaxedIncomeTotal }
          taxedIncomeTotal = { taxedIncomeTotal }
          serviceFeeTotal = { serviceFeeTotal }
          canEditProjectOtherIncome = { true }
          projectFinanceInputClearedEditable = { true }
        />
      </DivPanelContainer>
      <DivPanelContainer>
        <PanelQuotationInvoices
          poOptions = { poOptions }
          uneditable = { !userEditable }
          isEditing = { isEditingQuotation }
          projectInvoice = { projectInvoice }
          editHandler = { () =>  { setIsEditingQuotation(true) }}
          saveHandler = { saveProjectInvoiceHandler }
          cancelHandler = { cancelProjectInvoiceHandler }
          updateProjectInvoiceHandler = { updateProjectInvoiceHandler }
          projectDetail = { projectDetail }
          updateProjectDetailHandler = { updateProjectDetailHandler }
        />
      </DivPanelContainer>
      {/* <DivPanelContainer>
        <PanelPRserviceInvoices
          isEditing = { isEditingPRService }
          uneditable = { !userEditable }
          invoiceOptions = { invoiceOptions }
          directorOptions = { directorOptions }
          projectPRServices = { projectPRServices }
          editHandler = { () =>  { setIsEditingPRService(true) }}
          saveHandler = { saveProjectPRServicesHandler }
          cancelHandler = { cancelProjectPRServicesHandler }
          updateProjectPRServiceHandler = { updateProjectPRServiceHandler }
        />
      </DivPanelContainer> */}
      { 
        showAttachmentPopup && 
        <PopupPurchaseAttachments
          fileChangable = { false }
          fileList = { fileList }
          uploadFiles = { [] }
          cancelHandler = { () => {
            setShowAttachmentPopup(false);
          }}
        />
      }
      { showLoadingPopup && <PopupLoading/> }
      {
        showSaveSuccessPopup &&
          <PopupExecuteResult
            title = "儲存成功"
            confirmHandler = { () => {
              setShowSaveSuccessPopup(false);
            }}
          />
      }
      {
        showSaveFailPopup &&
          <PopupExecuteResult
            title = { apiFailPopupTitle }
            text = { apiFailPopupMessage }
            confirmHandler = { () => {
              dispatch(CLOSE_SAVE_FAIL_POPUP());
            }}
          />
      }
      {
        showAlertPopup &&
          <PopupExecuteResult
            title = "更新失敗"
            text = { alertText }
            confirmHandler = { () => { setShowAlertPopup(false) } }
          />
      }
    </MainContent>
  );
};

export default ProjectFinanceInfo;
