import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  postToGetExpenseParamsConfig,
  putModifyExpenseParamsConfig
} from '@/api/expenseService';

import {
  postToAddShareInvoice,
  postToDeleteShareInvoice,
} from '@/api/expenseService';

import SelectMultiple from '@/components/UI/Select/SelectMultiple';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import MainContent from '@/component-style/MainContent';
import TableWrapper from '@/components/UI/TableWrapper';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import { InputBasic, } from '@/component-style/Input';
import { PSubtitle } from '@/component-style/TextElement';
import { ButtonBasic } from '@/component-style/Button';
import {
  DivFlexRow,
  DivItemWrapper,
  DivFullWidthWrapper,
  DivLongMultiSelector,
} from '@/component-style/RWDWrapper';

import { expenseParamsConfig } from '@/constant/dataConfig/sysConfigModule';
import {
  floatConverter,
  integerConverter,
} from '@/utils/textUtils';

const PTitile = styled(PSubtitle)`
  font-size: 16px;
  color: #57585a;
`;

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding-right: 0;
  padding-bottom: 30px;
  justify-content: flex-end;

  @media (max-width: 425px) {
    justify-content: center;
    padding-left: 0;
  }
`;

const ButtonSave = styled(ButtonBasic)`
  width: 120px;
`;

const ExpenseParams = () => {
  const dispatch = useDispatch();
  const sysConfigRoutes = useSelector(state => state.login.sysConfigRoutes);
  const showSaveFailPopup = useSelector(state => state.apiFail.showSaveFailPopup);
  const apiFailPopupTitle = useSelector(state => state.apiFail.title);
  const apiFailPopupMessage = useSelector(state => state.apiFail.message);

  const [config, setConfig] = useState({ ...expenseParamsConfig });

  const [isEditing, setIsEditing] = useState(false);
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [showSaveSuccessPopup, setShowSaveSuccessPopup] = useState(false);

  const userEditable = sysConfigRoutes.indexOf('ExpenseParams-Edit') !== -1;

  const [addSharedInvoice, setAddSharedInvoice] = useState("");
  const [alertText, setAlertText] = useState('');
  const [showAlertPopup, setShowAlertPopup] = useState(false);

  const updateConfigHandler = (key, value) => {
    const updateObj = { ...config };
    updateObj[key] = value;
    setConfig({ ...updateObj });
  };

  const updateSharedInvoiceHandler = (arrayModifiedvalue) => {
    let arrayDiffSharedInvoice = config.shareInvoices.filter(val => !arrayModifiedvalue.includes(val));
    // console.log("updateSharedInvoiceHandler arrayDiffSharedInvoice:" + arrayDiffSharedInvoice);
    arrayDiffSharedInvoice.forEach(async function(element) {
      await postToDeleteShareInvoice({
        invoice: element
      });
    });
    updateConfigHandler('shareInvoices', arrayModifiedvalue)
  };

  const updateAddShareInvoiceHandler = (value) => {
    setAddSharedInvoice(value);
  };

  const addSharedInvoiceHandler = async () => {
    if (addSharedInvoice === "") {
      openAlertPopup("發票共用不可為空值");
      return;
    }
    try {
      await postToAddShareInvoice({
        invoice: addSharedInvoice
      });
      setAddSharedInvoice("");
    } catch (err) {
      console.log("err:" + err.response.data.code);
      if (err.response.data.code === 1306) {
        openAlertPopup("發票共用不可重覆");
      }
      return;
    }
    var arrayAddedSharedInvoice = config.shareInvoices?.map(function (value, index, array) {
        return value;
    });
    arrayAddedSharedInvoice.push(addSharedInvoice);
    updateConfigHandler('shareInvoices', arrayAddedSharedInvoice);
  };

  const openAlertPopup = text => {
    setAlertText(text);
    setShowAlertPopup(true);
  };

  const closeSuccessPopupHandler = () => {
    setIsEditing(false);
    setShowSaveSuccessPopup(false);
  };

  const saveHandler = async () => {
    setShowLoadingPopup(true);

    // const shareInvoices = config.shareInvoices
    //   .replace(' ', '')
    //   .split(',');
    try {
      await putModifyExpenseParamsConfig({
        // shareInvoices,
        basicPay: integerConverter(config.basicPay),
        requestPaymentDays: integerConverter(config.requestPaymentDays),
        stopRequestPaymentDays: integerConverter(config.stopRequestPaymentDays),
        premiumRate: floatConverter(config.premiumRate),
        localTaxRate: floatConverter(config.localTaxRate),
        foreignerTaxRate: floatConverter(config.foreignerTaxRate),
        residentTaxRate: floatConverter(config.residentTaxRate),
        workTaxRate: floatConverter(config.workTaxRate),
        under183TaxMultiple: floatConverter(config.under183TaxMultiple),
        underMultipleTaxRate: floatConverter(config.underMultipleTaxRate),
        overMultipleTaxRate: floatConverter(config.overMultipleTaxRate),
        taxableAmount: integerConverter(config.taxableAmount),
        agentDifference: integerConverter(config.agentDifference),
        agentDifferenceRate: floatConverter(config.agentDifferenceRate),
        specialFeatureTaxableAmount: integerConverter(config.specialFeatureTaxableAmount),
      });
      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
    } catch (err) {
      console.log("saveHandler err");
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '更新失敗', err }));
      console.log(err);
    }
  };

  const fetchScheduleTimeConfig = async () => {
    // console.log("fetchScheduleTimeConfig start");
    setShowLoadingPopup(true);

    try {
      // console.log("fetchScheduleTimeConfig start 2");
      const { data } = await postToGetExpenseParamsConfig();
      setConfig(prevState => ({
        ...prevState,
        ...data,
        shareInvoices: data.shareInvoices === null ? [] : data.shareInvoices,
        // shareInvoices: data.shareInvoices === null ? "":data.shareInvoices.join(', '),
      }));
      // console.log("fetchScheduleTimeConfig data.shareInvoices:" + data.shareInvoices);
      // setFetchedSharedInvoices(data.shareInvoices === null ? "":data.shareInvoices.join(', '));
    } catch (err) { 
      // console.log("fetchScheduleTimeConfig err");
      console.log(err) 
    }

    setShowLoadingPopup(false);
  };

  const initConfigData = useCallback(
    fetchScheduleTimeConfig, []);

  useEffect(() => {
    initConfigData();
  }, [initConfigData]);

  return (
    <MainContent>
      <TableWrapper title = "會計參數管理">
        <DivFlexRow>
          <DivItemWrapper>
            <PSubtitle>基本工資</PSubtitle>
            <InputBasic
              type = "number"
              disabled = { !isEditing }
              value = { config.basicPay }
              onChange = { ({ target }) => {
                updateConfigHandler('basicPay', target.value)
              }}
            />
          </DivItemWrapper>
          <DivItemWrapper>
            <PSubtitle>應請款日期(天)</PSubtitle>
            <InputBasic
              type = "number"
              disabled = { !isEditing }
              value = { config.requestPaymentDays }
              onChange = { ({ target }) => {
                updateConfigHandler('requestPaymentDays', target.value)
              }}
            />
          </DivItemWrapper>
        </DivFlexRow>
        <DivFlexRow>
          <DivItemWrapper>
            <PSubtitle>支憑終止請款日(天)</PSubtitle>
            <InputBasic
              type = "number"
              disabled = { !isEditing }
              value = { config.stopRequestPaymentDays }
              onChange = { ({ target }) => {
                updateConfigHandler('stopRequestPaymentDays', target.value)
              }}
            />
          </DivItemWrapper>
          <DivItemWrapper>
            <PSubtitle>補充保費稅率</PSubtitle>
            <InputBasic
              type = "number"
              disabled = { !isEditing }
              value = { config.premiumRate }
              onChange = { ({ target }) => {
                updateConfigHandler('premiumRate', target.value)
              }}
            />
          </DivItemWrapper>
        </DivFlexRow>
        <DivFlexRow>
          <DivItemWrapper>
            <PSubtitle>本國人稅率</PSubtitle>
            <InputBasic
              type = "number"
              disabled = { !isEditing }
              value = { config.localTaxRate }
              onChange = { ({ target }) => {
                updateConfigHandler('localTaxRate', target.value)
              }}
            />
          </DivItemWrapper>
          <DivItemWrapper>
            <PSubtitle>外國人稅率</PSubtitle>
            <InputBasic
              type = "number"
              disabled = { !isEditing }
              value = { config.foreignerTaxRate }
              onChange = { ({ target }) => {
                updateConfigHandler('foreignerTaxRate', target.value)
              }}
            />
          </DivItemWrapper>
        </DivFlexRow>
        <DivFlexRow>
          <DivItemWrapper>
            <PSubtitle>外國人持有居留證</PSubtitle>
            <InputBasic
              type = "number"
              disabled = { !isEditing }
              value = { config.residentTaxRate }
              onChange = { ({ target }) => {
                updateConfigHandler('residentTaxRate', target.value)
              }}
            />
          </DivItemWrapper>
          <DivItemWrapper>
            <PSubtitle>外國人持有工作證</PSubtitle>
            <InputBasic
              type = "number"
              disabled = { !isEditing }
              value = { config.workTaxRate }
              onChange = { ({ target }) => {
                updateConfigHandler('workTaxRate', target.value)
              }}
            />
          </DivItemWrapper>
        </DivFlexRow>
        <DivFlexRow>
          <DivItemWrapper>
            <PSubtitle>一般勞務扣稅金額</PSubtitle>
            <InputBasic
              type = "number"
              disabled = { !isEditing }
              value = { config.taxableAmount }
              onChange = { ({ target }) => {
                updateConfigHandler('taxableAmount', target.value)
              }}
            />
          </DivItemWrapper>
          <DivItemWrapper>
            <PSubtitle>代辦差額</PSubtitle>
            <InputBasic
              type = "number"
              disabled = { !isEditing }
              value = { config.agentDifference }
              onChange = { ({ target }) => {
                updateConfigHandler('agentDifference', target.value)
              }}
            />
          </DivItemWrapper>
        </DivFlexRow>
        <DivFlexRow>
          <DivItemWrapper>
            <PSubtitle>代辦差額比例</PSubtitle>
            <InputBasic
              type = "number"
              disabled = { !isEditing }
              value = { config.agentDifferenceRate }
              onChange = { ({ target }) => {
                updateConfigHandler('agentDifferenceRate', target.value)
              }}
            />
          </DivItemWrapper>
          <DivItemWrapper>
            <PSubtitle>特殊資格扣稅金額</PSubtitle>
            <InputBasic
              type = "number"
              disabled = { !isEditing }
              value = { config.specialFeatureTaxableAmount }
              onChange = { ({ target }) => {
                updateConfigHandler('specialFeatureTaxableAmount', target.value)
              }}
            />
          </DivItemWrapper>
        </DivFlexRow>
        <DivFlexRow>
          <DivItemWrapper>
            <PSubtitle>新增發票共用</PSubtitle>
            <InputBasic
              disabled = { !isEditing }
              value = { addSharedInvoice }
              onChange = { ({ target }) => {
                updateAddShareInvoiceHandler(target.value);
              }}
            />
          </DivItemWrapper>
          <DivItemWrapper>
            <DivButtonsWrapper>
              {
                isEditing
                  ? <ButtonSave
                      disabled = { false }
                      onClick = { addSharedInvoiceHandler }
                    >新增</ButtonSave>
                  : <ButtonSave
                      disabled = { true }
                    >新增</ButtonSave>
              }
            </DivButtonsWrapper>
          </DivItemWrapper>
          <DivFullWidthWrapper>
            <PSubtitle></PSubtitle>
            <DivLongMultiSelector>
              <SelectMultiple
                open = { false }
                disabled = { !isEditing }
                value = { config.shareInvoices }
                // options = { config.shareInvoices == null ? "" : config.shareInvoices.map( invoice => ({ value: invoice, desc: invoice })) }
                options = { config?.shareInvoices?.map( invoice => ({ value: invoice, desc: invoice })) }
                changeHandler = { (value) => {
                  updateSharedInvoiceHandler(value);
                }}
              />
              {/* <TextareaBasic
                disabled = { !isEditing }
                value = { config.shareInvoices }
                onChange = { ({ target }) => {
                  updateConfigHandler('shareInvoices', target.value)
                }}
              /> */}
            </DivLongMultiSelector>
          </DivFullWidthWrapper>
        </DivFlexRow>
        <DivFlexRow>
          <DivItemWrapper>
            <PTitile>外國人未滿183天</PTitile>
          </DivItemWrapper>
        </DivFlexRow>
        <DivFlexRow>
          <DivItemWrapper>
            <PSubtitle>稅率基準倍數</PSubtitle>
            <InputBasic
              type = "number"
              disabled = { !isEditing }
              value = { config.under183TaxMultiple }
              onChange = { ({ target }) => {
                updateConfigHandler('under183TaxMultiple', target.value)
              }}
            />
          </DivItemWrapper>
          <DivItemWrapper>
            <PSubtitle>未達基準倍數稅率</PSubtitle>
            <InputBasic
              type = "number"
              disabled = { !isEditing }
              value = { config.underMultipleTaxRate }
              onChange = { ({ target }) => {
                updateConfigHandler('underMultipleTaxRate', target.value)
              }}
            />
          </DivItemWrapper>
        </DivFlexRow>
        <DivFlexRow>
          <DivItemWrapper>
            <PSubtitle>達基準倍數稅率</PSubtitle>
            <InputBasic
              type = "number"
              disabled = { !isEditing }
              value = { config.overMultipleTaxRate }
              onChange = { ({ target }) => {
                updateConfigHandler('overMultipleTaxRate', target.value)
              }}
            />
          </DivItemWrapper>
        </DivFlexRow>
        <DivFlexRow>
          <DivButtonsWrapper>
            {
              isEditing
                ? <ButtonSave
                    onClick = { saveHandler }
                  >儲存</ButtonSave>
                : <ButtonSave
                    disabled = { !userEditable }
                    onClick = { () => { setIsEditing(true) } }
                  >編輯</ButtonSave>
            }
          </DivButtonsWrapper>
        </DivFlexRow>
      </TableWrapper>
      { showLoadingPopup && <PopupLoading/> }
      {
        showSaveSuccessPopup &&
          <PopupExecuteResult
            title = "更新成功"
            confirmHandler = { closeSuccessPopupHandler }
          />
      }
      {
        showSaveFailPopup &&
          <PopupExecuteResult
            title = { apiFailPopupTitle }
            text = { apiFailPopupMessage }
            confirmHandler = { () => {
              dispatch(CLOSE_SAVE_FAIL_POPUP());
            }}
          />
      }
      {
        showAlertPopup &&
          <PopupExecuteResult
            title = "更新失敗"
            text = { alertText }
            confirmHandler = { () => { setShowAlertPopup(false) } }
          />
      }
    </MainContent>
  );
};

export default ExpenseParams;
