import React from 'react';
import styled from 'styled-components';

import TableWrapper from '../../UI/TableWrapper';
import Checkbox from '../../UI/Checkbox';
import SelectNormal from '../../UI/Select/SelectNormal';
import AddressInfoSelector from '../../UI/AddressInfoSelector';
import { InputBasic, TextareaBasic } from '../../../component-style/Input';
import { PSubtitle } from '../../../component-style/TextElement';
import {
  DivFlexRow,
  DivItemWrapper,
  DivFullWidthWrapper,
  DivLongMultiSelector,
} from '../../../component-style/RWDWrapper';

const DivCheckboxWrapper = styled.div`
  width: 300px;
  height: 40px;
  line-height: 40px;

  @media (max-width: 767px) {
    width: auto;
    height: 20px;
    line-height: 20px;
    margin-bottom: 5px;
  }
`;

const PanelMediaDetail = props => {
  const { media } = props;

  return (
    <TableWrapper title = { props.title }>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>
            媒體名稱
            <span>*</span>
          </PSubtitle>
          <div>
            <InputBasic
              style = {{ marginBottom: '10px' }}
              disabled = { props.disabled }
              value = { media.mediaName }
              onChange = {({ target }) => {
                props.updateMediaHandler('mediaName', target.value);
              }}
            />
            <Checkbox title = "駐台媒體"
              disabled = { props.disabled }
              checked = { media.inTwMedia }
              changeHandler = { value => {
                props.updateMediaHandler('inTwMedia', value);
              }}
            />
          </div>
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>媒體名稱 (英)</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { media.mediaEnglishName }
            onChange = {({ target }) => {
              props.updateMediaHandler('mediaEnglishName', target.value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>集團名稱</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { media.groupName }
            onChange = {({ target }) => {
              props.updateMediaHandler('groupName', target.value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>集團名稱 (英)</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { media.groupEnglishName }
            onChange = {({ target }) => {
              props.updateMediaHandler('groupEnglishName', target.value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>頻道名稱</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { media.channelName }
            onChange = {({ target }) => {
              props.updateMediaHandler('channelName', target.value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>頻道名稱 (英)</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { media.channelEnglishName }
            onChange = {({ target }) => {
              props.updateMediaHandler('channelEnglishName', target.value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>
            類別
            <span>*</span>
          </PSubtitle>
          <div>
            <div style = {{ marginBottom: '10px' }} >
              <SelectNormal
                disabled = { props.disabled }
                value = { media.type }
                options = {[
                  { value: '', desc: '請選擇類別' },
                  ...props.mediaTypes
                ]}
                changeHandler = { value => {
                  props.updateMediaHandler('type', value);
                }}
              />
            </div>
            <Checkbox title = "編譯中心"
              disabled = { props.disabled }
              checked = { media.translateCenter }
              changeHandler = { value => {
                props.updateMediaHandler('translateCenter', value);
              }}
            />
          </div>
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>停刊/停播</PSubtitle>
          <DivCheckboxWrapper>
            <Checkbox
              disabled = { props.disabled }
              checked = { media.stopPublish }
              changeHandler = { value => {
                props.updateMediaHandler('stopPublish', value);
              }}
            />
          </DivCheckboxWrapper>
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>發行量</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { media.publishQty }
            onChange = {({ target }) => {
              props.updateMediaHandler('publishQty', target.value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>權重</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { media.level }
            onChange = {({ target }) => {
              props.updateMediaHandler('level', target.value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivFullWidthWrapper>
          <PSubtitle>聯絡地址</PSubtitle>
          <AddressInfoSelector
            disabled = { props.disabled }
            address = { media.contactAddress }
            updateAddressHandler = { value => {
              props.updateMediaHandler('contactAddress', value);
            }}
          />
        </DivFullWidthWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>連絡地址 (英)</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { media.contactAddressEng }
            onChange = {({ target }) => {
              props.updateMediaHandler('contactAddressEng', target.value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>網址</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { media.website }
            onChange = {({ target }) => {
              props.updateMediaHandler('website', target.value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivFullWidthWrapper>
          <PSubtitle>集團背景</PSubtitle>
          <DivLongMultiSelector>
            <TextareaBasic
              disabled = { props.disabled }
              value = { media.groupBackground }
              onChange = {({ target }) => {
                props.updateMediaHandler('groupBackground', target.value);
              }}
            />
          </DivLongMultiSelector>
        </DivFullWidthWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivFullWidthWrapper>
          <PSubtitle>集團背景 (英)</PSubtitle>
          <DivLongMultiSelector>
            <TextareaBasic
              disabled = { props.disabled }
              value = { media.groupBackgroundEng }
              onChange = {({ target }) => {
                props.updateMediaHandler('groupBackgroundEng', target.value);
              }}
            />
          </DivLongMultiSelector>
        </DivFullWidthWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivFullWidthWrapper>
          <PSubtitle>截稿與操作需求</PSubtitle>
          <DivLongMultiSelector>
            <TextareaBasic
              disabled = { props.disabled }
              value = { media.deadlineDesc }
              onChange = {({ target }) => {
                props.updateMediaHandler('deadlineDesc', target.value);
              }}
            />
          </DivLongMultiSelector>
        </DivFullWidthWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivFullWidthWrapper>
          <PSubtitle>截稿 與操作需求 (英)</PSubtitle>
          <DivLongMultiSelector>
            <TextareaBasic
              disabled = { props.disabled }
              value = { media.deadlineDescEng }
              onChange = {({ target }) => {
                props.updateMediaHandler('deadlineDescEng', target.value);
              }}
            />
          </DivLongMultiSelector>
        </DivFullWidthWrapper>
      </DivFlexRow>
      { props.children }
    </TableWrapper>
  );
};

export default PanelMediaDetail;
