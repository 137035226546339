import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  deleteActivityIdea,
  postToGetActivityIdeaDetail,
  putModifyActivityIdeaDetail,
} from '@/api/activityIdeaService';

import {
  deleteUploadFile,
  postNewUploadFile,
  postToGetUploadFiles,
} from '@/api/storageService';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import {
  FETCH_VENDOR_ID_OPTIONS,
  FETCH_PROJECT_ID_OPTIONS,
  FETCH_ACTIVITY_IDEA_TYPE_OPTIONS,
} from '@/store/actions/srpOptionsAction';

import MainContent from '@/component-style/MainContent';
import PanelActivityIdeaDetail from '@/components/Panel/PanelActivityIdeaDetail';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import PopupExecuteConfirm from '@/components/UI/Popup/PopupExecuteConfirm';
import PopupAttachmentList from '@/components/UI/Popup/PopupAttachmentList';
import { ButtonBasic, ButtonCancel } from '@/component-style/Button';
import { DivFlexRow } from '@/component-style/RWDWrapper';

import { integerConverter } from '@/utils/textUtils';
import { activityIdeaConfig } from '@/constant/dataConfig/projectModule';

const DivButtonsWrapper = styled(DivFlexRow)`
  padding: 0;
  padding-bottom: 30px;
  justify-content: flex-end;
  max-width: 1535px;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const Button = styled(ButtonBasic)`
  width: 120px;
  margin-right: 10px;
  margin-top: 10px;

  &:last-child {
    margin-right: 0;
  }
`;

const ButtonExit = styled(ButtonCancel)`
  width: 120px;
  margin-right: 10px;
  margin-top: 10px;

  &:last-child {
    margin-right: 0;
  }
`;

const ActivityIdeaDetail = props => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const projectRoutes = useSelector(state => state.login.projectRoutes);
  const vendorIDs = useSelector(state => state.srpOptions.vendorIDOptions);
  const projectIDOptions = useSelector(state => state.srpOptions.projectIDOptions);
  const activityIdeaTypes = useSelector(state => state.srpOptions.activityIdeaTypeOptions);
  const activityIdeaTagDictionary = useSelector(state => state.srpOptions.activityIdeaTagDictionary);
  const showSaveFailPopup = useSelector(state => state.apiFail.showSaveFailPopup);
  const apiFailPopupTitle = useSelector(state => state.apiFail.title);
  const apiFailPopupMessage = useSelector(state => state.apiFail.message);

  const [activityIdea, setActivityIdea] = useState({ ...activityIdeaConfig });

  const [deleteFileId, setDeleteFileId] = useState('');
  const [fileList, setFileList] = useState([]);
  const [uploadFiles, setUploadFiles] = useState([]);

  const [isEditing, setIsEditing] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [showDeleteFilePopup, setShowDeleteFilePopup] = useState(false);
  const [showAttachmentsPopup, setShowAttachmentsPopup] = useState(false);
  const [showSaveSuccessPopup, setShowSaveSuccessPopup] = useState(false);
  const [showDeleteSuccessPopup, setShowDeleteSuccessPopup] = useState(false);
  const [showUploadSuccessPopup, setShowUploadSuccessPopup] = useState(false);
  const [showDeleteFileSuccessPopup, setShowDeleteFileSuccessPopup] = useState(false);

  const userEditable = projectRoutes.indexOf('ActivityIdeaDetail-Edit') !== -1;
  const userDeletable = projectRoutes.indexOf('ActivityIdeaDetail-Delete') !== -1;
  const userUploadable = projectRoutes.indexOf('ActivityIdeaDetail-Attachments') !== -1;

  const updateActivityIdeaHandler = (key, value) => {
    const updateObj = { ...activityIdea };
    updateObj[key] = value;
    setActivityIdea({ ...updateObj });
  };

  const activityIdeaTypeChangeHandler = value => {
    setActivityIdea(prevState => ({
      ...prevState,
      activityIdeaTypeID: value,
      tag: '',
    }));
  };

  const closeSuccessPopupHandler = () => {
    setIsEditing(false);
    setShowSaveSuccessPopup(false);
  };

  const updateUploadFiles = value => {
    setUploadFiles([ ...value ]);
  };

  const uploadSuccessConfirmHandler = () => {
    setUploadFiles([]);
    setShowUploadSuccessPopup(false);
    setShowAttachmentsPopup(true);
  };

  const deleteFileHandler = id => {
    setDeleteFileId(id);
    setShowDeleteFilePopup(true);
  };

  const apiFailConfirmHandler = () => {
    dispatch(CLOSE_SAVE_FAIL_POPUP());

    apiFailPopupTitle === '上傳失敗'
      && setShowAttachmentsPopup(true);
  };

  const fileUploadHandler = async () => {
    setShowAttachmentsPopup(false);
    setShowLoadingPopup(true);

    try {
      const [file] = uploadFiles;
      const formData = new FormData();
      formData.append('upfile', file);
      formData.append('objectType', 'activityIdea');
      formData.append('objectID', id);
      formData.append('name', file.name);
      await postNewUploadFile(formData);
      await fetchUploadFiles();
      setShowLoadingPopup(false);
      setShowUploadSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '上傳失敗', err }));
      console.log(err);
    };
  };

  const deleteFile = async () => {
    setShowDeleteFilePopup(false);
    setShowAttachmentsPopup(false);
    setShowLoadingPopup(true);

    try {
      await deleteUploadFile(deleteFileId);
      await fetchUploadFiles();
      setShowLoadingPopup(false);
      setShowDeleteFileSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '刪除檔案失敗', err }));
      console.log(err);
    }
  };

  const fetchActivityIdeaDetail = async () => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetActivityIdeaDetail(id);
      setActivityIdea(prevState => ({ ...prevState, ...data }));
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const updateActivityIdeaData = async () => {
    setShowLoadingPopup(true);

    try {
      await putModifyActivityIdeaDetail({
        ...activityIdea,
        budget: integerConverter(activityIdea.budget),
      })
      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '更新失敗', err }));
      console.log(err);
    }
  };

  const deleteActivityIdeaHandler = async () => {
    setShowDeletePopup(false);
    setShowLoadingPopup(true);

    try {
      await deleteActivityIdea(id);
      setShowLoadingPopup(false);
      setShowDeleteSuccessPopup(true);
    } catch(err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '刪除失敗', err }));
      console.log(err);
    }
  };

  const fetchUploadFiles = async () => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetUploadFiles('activityIdea', id);
      setFileList([ ...data ]);
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const initActivityIdeaData = useCallback(
    fetchActivityIdeaDetail, [])

  const initUploadFiles = useCallback(
    fetchUploadFiles, []);

  const initData = useCallback(
    async () => {
      await initActivityIdeaData();
      await initUploadFiles();
    }, [initActivityIdeaData, initUploadFiles]);

  const initOptions = useCallback(() => {
    dispatch(FETCH_VENDOR_ID_OPTIONS());
    dispatch(FETCH_PROJECT_ID_OPTIONS());
    dispatch(FETCH_ACTIVITY_IDEA_TYPE_OPTIONS());
  }, [dispatch]);

  useEffect(() => {
    initData();
    initOptions();
  }, [initData, initOptions]);

  return (
    <MainContent>
      <PanelActivityIdeaDetail
        title = "活動創意明細"
        disabled = { !isEditing }
        disableProjectID = { true }
        activityIdea = { activityIdea }
        vendorIDs = { vendorIDs }
        projectIDOptions = { projectIDOptions }
        activityIdeaTypes = { activityIdeaTypes }
        activityIdeaTagDictionary = { activityIdeaTagDictionary }
        updateActivityIdeaHandler = { updateActivityIdeaHandler }
        activityIdeaTypeChangeHandler = { activityIdeaTypeChangeHandler }
      >
        <DivFlexRow>
          <DivButtonsWrapper>
            {
              isEditing
                ? <Button
                    onClick = { updateActivityIdeaData }
                  >儲存</Button>
                : <Button
                    disabled = { !userEditable }
                    onClick = { () => { setIsEditing(true) } }
                  >編輯</Button>
            }
            <Button
              disabled = { !userDeletable }
              onClick = { () => { setShowDeletePopup(true) } }
            >刪除</Button>
            <Button
              disabled = { !userUploadable }
              onClick = { () => { setShowAttachmentsPopup(true) }}
            >附件管理</Button>
            <ButtonExit
              onClick = { () => { history.goBack() } }
            >離開</ButtonExit>
          </DivButtonsWrapper>
        </DivFlexRow>
      </PanelActivityIdeaDetail>
      { showLoadingPopup && <PopupLoading/> }
      {
        showAttachmentsPopup &&
        <PopupAttachmentList
          fileChangable = { true }
          fileList = { fileList }
          uploadFiles = { uploadFiles }
          updateUploadFiles = { updateUploadFiles }
          fileUploadHandler = { fileUploadHandler }
          deleteFileHandler = { deleteFileHandler }
          cancelHandler = { () => { setShowAttachmentsPopup(false) }}
        />
      }
      {
        showDeletePopup &&
          <PopupExecuteConfirm
            title = "刪除活動創意"
            text = "是否確定刪除?"
            confirmHandler = { deleteActivityIdeaHandler }
            cancelHandler = { () => { setShowDeletePopup(false) } }
          />
      }
      {
        showDeleteFilePopup &&
          <PopupExecuteConfirm
            title = "刪除檔案"
            text = "確定刪除此上傳檔案"
            confirmHandler = { deleteFile }
            cancelHandler = { () => {
              setShowDeleteFilePopup(false);
            }}
          />
      }
      {
        showSaveSuccessPopup &&
          <PopupExecuteResult
            title = "更新成功"
            confirmHandler = { closeSuccessPopupHandler }
          />
      }
      {
        showDeleteSuccessPopup &&
          <PopupExecuteResult
            title = "刪除成功"
            confirmHandler = { () => {
              history.goBack();
            }}
          />
      }
      {
        showUploadSuccessPopup &&
          <PopupExecuteResult
            title = "上傳成功"
            confirmHandler = { uploadSuccessConfirmHandler }
          />
      }
      {
        showDeleteFileSuccessPopup &&
          <PopupExecuteResult
            title = "刪除檔案成功"
            confirmHandler = { () => {
              setShowDeleteFileSuccessPopup(false);
              setShowAttachmentsPopup(true);
            }}
          />
      }
      {
        showSaveFailPopup &&
          <PopupExecuteResult
            title = { apiFailPopupTitle }
            text = { apiFailPopupMessage }
            confirmHandler = { apiFailConfirmHandler }
          />
      }
    </MainContent>
  );
};

export default ActivityIdeaDetail;
