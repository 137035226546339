import React from 'react';
import { useSelector } from 'react-redux';

import { Table, TableBody } from '@/component-style/Table';
import TableWrapper  from '@/components/UI/TableWrapper';
import Header from './Header';
import Item from './Item';

const TablePurchaseBargain = props => {
  const financeRoutes = useSelector(state => state.login.financeRoutes);
  const { quotations } = props;

  const userApprovable = financeRoutes.indexOf('PurchaseBargain-Approve') !== -1;

  return (
    <TableWrapper title = "採購議價列表">
      <Table>
        <Header/>
        <TableBody>
          {
            quotations.map((quotation, index) => (
              <Item
                key = { quotation.quotationID }
                quotation = { quotation }
                approvable = { userApprovable }
                openFilesPopupHandler = { () => {
                  props.openFilesPopupHandler(quotation.quotationID);
                }}
                editQuotationHandler = { value => {
                  props.editQuotationHandler(value, index);
                }}
                updatePriceHandler = { value => {
                  props.updatePriceHandler(value, index);
                }}
                saveQuotationHandler = { () => {
                  props.saveQuotationHandler(index);
                }}
              />
            ))
          }
        </TableBody>
      </Table>
    </TableWrapper>
  );
};

export default TablePurchaseBargain;
