import React from 'react';
// import { useSelector } from 'react-redux';
import styled from 'styled-components';
// import { Link } from 'react-router-dom';

import { TdItem, TableRow } from '@/component-style/Table';
import { ButtonGoDetail } from '@/component-style/Button';
// import { getOptionDesc } from '@/utils/textUtils';
import { decimalCommaConverter } from '@/utils/textUtils';
import moment from 'moment';

const TrWrapper = styled(TableRow)`
  border-bottom: solid 2px #f2f2f2;
  box-sizing: border-box;
  min-width: 760px;

  &:last-child {
    border-bottom: none;
  }
`;

const Td160 = styled(TdItem)`
  width: 160px;
`;

const Td80 = styled(TdItem)`
  width: 80px;
`;

const Item = props => {
  const { extraPayment } = props;
  console.log(`TableSalaryExtraPayment Item.js JSON.stringify(extraPayment)=${JSON.stringify(extraPayment)}`);
  // const directorOptions = useSelector(state => state.srpOptions.directorOptions);
  // const departmentOptions = useSelector(state => state.srpOptions.departmentOptions);

  // const director = getOptionDesc(directorOptions, user.director);
  // const department = getOptionDesc(departmentOptions, user.department);

  return (
    <TrWrapper>
      <Td160>{ extraPayment.type === "add" ? "加項" : "減項" }</Td160>
      <Td160>{ extraPayment.name }</Td160>
      <Td160>{ moment(extraPayment.startMonth).format("YYYY-MM") }</Td160>
      <Td160>{ extraPayment.recurringMonth }</Td160>
      <Td160>{ decimalCommaConverter(extraPayment.amountForEachMonth) }</Td160>
      <Td160>{ extraPayment.forRecordOnly === true ? "否" : "是" }</Td160>
      { props.removable ?
          <Td80>
            <ButtonGoDetail
              onClick = { () => { 
                props.removeExtraPaymentHandler(extraPayment.id) 
              } }
            >...
            </ButtonGoDetail>
          </Td80> : ""
        }
    </TrWrapper>
  );
};

export default Item;
