import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

// import { postToGetProjectDetail } from '@/api/projectService';
// import { postToGetUploadFiles } from '@/api/storageService';
// import { postToGetProjectQuotations } from '@/api/financeService';
import { putNewMediaReport } from '@/api/mediaService';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import {
  FETCH_MEDIA_ID_OPTIONS,
  FETCH_PROJECT_ID_OPTIONS,
  FETCH_JOURNALIST_ID_OPTIONS,
} from '@/store/actions/srpOptionsAction';

import MainContent from '@/component-style/MainContent';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import PanelMediaReportDetail from '@/components/Panel/PanelMediaReportDetail';
import { ButtonBasic, ButtonCancel } from '@/component-style/Button';
import { DivFlexRow } from '@/component-style/RWDWrapper';

// import { diffTime } from '@/utils/timeUtils';
import { integerConverter } from '@/utils/textUtils';
import { mediaReportConfig } from '@/constant/dataConfig/projectModule';

const DivButtonsWrapper = styled(DivFlexRow)`
  padding: 0;
  padding-bottom: 30px;
  justify-content: flex-end;
  max-width: 1535px;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const Button = styled(ButtonBasic)`
  width: 120px;
  margin-right: 10px;
  margin-top: 10px;

  &:last-child {
    margin-right: 0;
  }
`;

const ButtonExit = styled(ButtonCancel)`
  width: 120px;
  margin-right: 10px;
  margin-top: 10px;

  &:last-child {
    margin-right: 0;
  }
`;

const MediaReportCreation = props => {
  const history = useHistory();
  const dispatch = useDispatch();
  const mediaIDList = useSelector(state => state.srpOptions.mediaIDOptions);
  const projectIDList = useSelector(state => state.srpOptions.projectIDOptions);
  const journalistIDList = useSelector(state => state.srpOptions.journalistIDOptions);
  const showSaveFailPopup = useSelector(state => state.apiFail.showSaveFailPopup);
  const apiFailPopupTitle = useSelector(state => state.apiFail.title);
  const apiFailPopupMessage = useSelector(state => state.apiFail.message);

  const [mediaReport, setMediaReport] = useState({ ...mediaReportConfig });

  // const [alertText, setAlertText] = useState('');
  // const [showAlertPopup, setShowAlertPopup] = useState(false);
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [showSaveSuccessPopup, setShowSaveSuccessPopup] = useState(false);

  const journalistIDOptions = journalistIDList
    .filter(({ mediaID }) => mediaID === mediaReport.mediaID);

  const updateMediaReportHandler = (key, value) => {
    const updateObj = { ...mediaReport };
    updateObj[key] = value;
    setMediaReport({ ...updateObj });
  };

  const mediaChangeHandler = value => {
    setMediaReport(prevState => ({
      ...prevState,
      mediaID: value,
      journalistID: '',
    }));
  };

  const clearData = () => {
    setMediaReport({ ...mediaReportConfig });
  };

  const closeSuccessPopupHandler = () => {
    clearData();
    setShowSaveSuccessPopup(false);
  };

  const saveNewMediaReportHandler = async () => {
    setShowLoadingPopup(true);
    // const { projectID } = mediaReport;

    try {
      // const projectData = await postToGetProjectDetail(projectID);
      // const projectFinanceFiles = await postToGetUploadFiles('projectFinance', projectID);
      // const projectQuotations = await postToGetProjectQuotations({
      //   projectID, amount: 1, page: 1,
      // });

      // const unlocked = projectData.data.projectUnlock !== null
      //   && projectData.data.projectUnlock !== undefined
      //   && diffTime(projectData.data.projectUnlock, Date.now(), 'days') <= 1;

      // const notQuoted = projectQuotations.data.totalCount === undefined
      //   && diffTime(projectData.data.actionTimeEnd, Date.now(), 'days') < 8;

      // const noPO = projectFinanceFiles.data.length === 0
      //   && diffTime(projectData.data.actionTimeEnd, Date.now(), 'days') < 4;

      // const isProjectLocked = !unlocked && (noPO || notQuoted);
      
      // if (isProjectLocked) {
      //   const alertText = `
      //     ${ noPO ? '無 PO 資料，' : '' }
      //     ${ notQuoted ? '無報價資料，' : '' }
      //     專案已被鎖定，請專案總監或總經理解鎖。
      //   `;
      //   setShowLoadingPopup(false);
      //   setAlertText(alertText);
      //   setShowAlertPopup(true);
      //   return
      // }

      await putNewMediaReport({
        ...mediaReport,
        benefit: integerConverter(mediaReport.benefit),
      });
      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '儲存失敗', err }));
      console.log(err);
    }
  };

  const initOptions = useCallback(() => {
    dispatch(FETCH_MEDIA_ID_OPTIONS());
    dispatch(FETCH_PROJECT_ID_OPTIONS());
    dispatch(FETCH_JOURNALIST_ID_OPTIONS([]));
  }, [dispatch]);

  useEffect(() => {
    initOptions();
  }, [initOptions]);

  return (
    <MainContent>
      <PanelMediaReportDetail
        title = "新增媒體披露"
        mediaReport = { mediaReport }
        projectIDOptions = { projectIDList }
        mediaIDOptions = { mediaIDList }
        journalistIDOptions = { journalistIDOptions }
        mediaChangeHandler = { mediaChangeHandler }
        updateMediaReportHandler = { updateMediaReportHandler }
      >
        <DivFlexRow>
          <DivButtonsWrapper>
            <Button
              onClick = { saveNewMediaReportHandler }
            >儲存</Button>
            <ButtonExit
              onClick = { () => { history.goBack() } }
            >離開</ButtonExit>
          </DivButtonsWrapper>
        </DivFlexRow>
      </PanelMediaReportDetail>
      { showLoadingPopup && <PopupLoading/> }
      {
        showSaveSuccessPopup &&
          <PopupExecuteResult
            title = "儲存成功"
            confirmHandler = { closeSuccessPopupHandler }
          />
      }
      {
        showSaveFailPopup &&
          <PopupExecuteResult
            title = { apiFailPopupTitle }
            text = { apiFailPopupMessage }
            confirmHandler = { () => {
              dispatch(CLOSE_SAVE_FAIL_POPUP());
            }}
          />
      }
      {/* {
        showAlertPopup &&
          <PopupExecuteResult
            text = { alertText }
            confirmHandler = { () => { setShowAlertPopup(false) } }
          />
      } */}
    </MainContent>
  );
};

export default MediaReportCreation;
