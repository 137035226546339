import React from 'react';
import styled from 'styled-components';

import Checkbox from '@/components/UI/Checkbox';
import { TableHead, TableRow, ThItem } from '@/component-style/Table';

const THead = styled(TableHead)`
  min-width: 760px;
`;

const Th80 = styled(ThItem)`
  width: 80px;
`;

const Th120 = styled(ThItem)`
  width: 120px;
`;

const Th160 = styled(ThItem)`
  width: 160px;
`;

const Header = props => {
  
  return (
    <THead>
      <TableRow>
        <Th80>
          <Checkbox
            checked = { props.selectAll }
            changeHandler = { props.selectAllHandler }
          />
        </Th80>
        <Th160>分類名稱</Th160>
        <Th160>備註</Th160>
        <Th120>狀態</Th120>
        <Th120>明細</Th120>
        <Th120>細項設定</Th120>
      </TableRow>
    </THead>
  );
};

export default Header;
