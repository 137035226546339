import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { cloneDeep } from 'lodash';

import { postToGetVendorDetail } from '@/api/vendorService';
import { postToGetProjectDetail } from '@/api/projectService';

import {
  postToGetExpenses,
  postToGetExpenseDetail,
  putModifyExpenseDetail,
  postToGetExpensePDFUrl,
  postToGetExpenseTags,
  postToSetExportedAccountingSystem,
} from '@/api/expenseService';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import {
  FETCH_MEMBER_OPTIONS,
  FETCH_VENDOR_ID_OPTIONS,
  FETCH_PROJECT_ID_OPTIONS,
  FETCH_EXPENSE_TYPE_OPTIONS,
  FETCH_DECLARE_TYPE_OPTIONS,
} from '@/store/actions/srpOptionsAction';

import MainContent from '@/component-style/MainContent';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import PanelFinanceManagementSearch from '@/components/Panel/Finance/PanelFinanceManagementSearch';
import TableFinanceManagements from '@/components/Table/Finance/TableFinanceManagements';
import PopupFinanceActionsSelect from '@/components/UI/Popup/PopupFinanceActionsSelect';
import SearchButtonSet from '@/components/UI/SearchButtonSet';
import { ButtonBasic } from '@/component-style/Button';
import { DivFlexRow } from '@/component-style/RWDWrapper';

import { formatTime } from '@/utils/timeUtils';
import {
  downloadFromUrl,
  searchConfigGenerator,
} from '@/utils/apiRequestUtils';

import {
  getOptionDesc,
  getAddressString,
  integerConverter,
  getCompanyInvoiceNumber,
  getOptionDescAndRemoveBrackets,
} from '@/utils/textUtils';

import {
  exportFinanceManagementFile,
  exportPersonalServiceTaxFile,
  exportExpenseVoucherFile,
} from '@/utils/xlsx.js';

import {
  vendorInvoiceTypes,
  expenseApprovalStates,
} from '@/constant/options';

import { postToGetUserInfo } from '@/api/authService';

import { PDFDownloadLink, Page, Text, View, Document, StyleSheet, Font } from '@react-pdf/renderer';

import TaipeiSans from '../../assets/fonts/TaipeiSansTCBeta-Regular.ttf'

Font.register({
  family: 'Rubik',
  src: TaipeiSans
});

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#FFFFFF'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
    backgroundColor: '#FFFFFF'
  },
  text: {
    margin: 0,
    fontSize: 24,
    textAlign: 'center',
    fontFamily: 'Rubik',
    // backgroundColor: 'tomato',
    // height: 60,
  },
  alignLeft: {
    margin: 0,
    fontSize: 12,
    textAlign: 'left',
    fontFamily: 'Rubik',
    paddingLeft: 4,
    paddingTop: 4,
  },
  alignRight: {
    margin: 0,
    fontSize: 12,
    textAlign: 'right',
    fontFamily: 'Rubik',
    paddingLeft: 4,
    paddingTop: 4,
  },
  table: {
    width: '100%',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    // borderTop: '1px solid #EEE',
    // paddingTop: 4,
    // paddingBottom: 4,
  },
  header: {
    borderTop: 'none',
  },
  test: {
    backgroundColor: 'tomato',
    height: 20,
  },
  bold: {
    fontWeight: 'bold',
  },
  // So Declarative and unDRY 👌
  row1: {
    width: '27%',
    borderLeft: '1px solid #EEE',
    borderTop: '1px solid #EEE',
    borderRight: '1px solid #EEE',
    borderBottom: '1px solid #EEE',
  },
  row2: {
    width: '15%',
  },
  row3: {
    width: '15%',
  },
  row4: {
    width: '20%',
  },
  row5: {
    width: '27%',
  },
  row2Col1: {
    width: '50%',
  },
  row2Col2: {
    width: '50%',
  },
  row3Col1: {
    width: '100%',
  },
  row4Col1: {
    width: '50%',
  },
  row4Col2: {
    width: '50%',
  },
  row5Col1: {
    width: '30%',
    borderLeft: '1px solid #000000',
    borderTop: '1px solid #000000',
    borderRight: '1px solid #000000',
    borderBottom: '1px solid #000000',
  },
  row5Col2: {
    width: '70%',
  },
});

const MyDoc = (props) => {
  const data = props.data;
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <View style={{ marginTop: '0px' }}>
            <Text style={styles.text}>支出憑單</Text>
            <View style={[styles.row, styles.bold, styles.header]}>
              <Text style={[styles.row2Col1, styles.alignLeft]}>{data.projectCode}</Text>
              <Text style={[styles.row2Col2, styles.alignRight]}>流水號：{data.expenseCode}</Text>
            </View>
            <View style={[styles.row, styles.bold, styles.header]}>
              <Text style={[styles.row2Col1, styles.alignLeft]}>申請人</Text>
            </View>
            <View style={[styles.row, styles.bold, styles.header]}>
              <Text style={[styles.row3Col1, styles.alignLeft]}>專案代號：{data.projectCode}</Text>
            </View>
            <View style={[styles.row, styles.bold, styles.header]}>
              <Text style={[styles.row4Col1, styles.alignLeft]}>客戶PO</Text>
              <Text style={[styles.row4Col2, styles.alignLeft]}>中華民國</Text>
            </View>
          </View>
          <View style={styles.table}>
            <View style={styles.row}>
              <Text style={[styles.alignLeft, {width: '25%', borderLeft: '1px solid #000000', borderTop: '1px solid #000000', borderRight: '1px solid #000000', borderBottom: '1px solid #000000'}]}>廠商名稱</Text>
              <Text style={[styles.alignLeft, {width: '75%', borderTop: '1px solid #000000', borderRight: '1px solid #000000', borderBottom: '1px solid #000000'}]}>Section #1</Text>
            </View>
            <View style={styles.row}>
              <Text style={[styles.alignLeft, {width: '25%', borderLeft: '1px solid #000000',borderRight: '1px solid #000000', borderBottom: '1px solid #000000'}]}>總合計</Text>
              <Text style={[styles.alignLeft, {width: '50%', borderRight: '1px solid #000000', borderBottom: '1px solid #000000'}]}>Section #2</Text>
              <Text style={[styles.alignLeft, {width: '25%', borderRight: '1px solid #000000', borderBottom: '1px solid #000000'}]}>Section #2</Text>
            </View>
            <View style={styles.row}>
              <Text style={[styles.alignLeft, {width: '25%', borderLeft: '1px solid #000000',borderRight: '1px solid #000000'}]}></Text>
              <Text style={[styles.alignLeft, {width: '75%', borderRight: '1px solid #000000', borderBottom: '1px solid #000000'}]}>Section #3</Text>
            </View>
            <View style={styles.row}>
              <Text style={[styles.alignLeft, {width: '25%', borderLeft: '1px solid #000000',borderRight: '1px solid #000000'}]}>支付方式</Text>
              <Text style={[styles.alignLeft, {width: '75%', borderRight: '1px solid #000000', borderBottom: '1px solid #000000'}]}>Section #3</Text>
            </View>
            <View style={styles.row}>
              <Text style={[styles.alignLeft, {width: '25%', borderLeft: '1px solid #000000',borderRight: '1px solid #000000', borderBottom: '1px solid #000000'}]}></Text>
              <Text style={[styles.alignLeft, {width: '75%', borderRight: '1px solid #000000', borderBottom: '1px solid #000000'}]}>Section #3</Text>
            </View>
            <View style={[styles.row, {height: '60px'}]}>
              <Text style={[styles.alignLeft, {paddingTop: '25px', width: '25%', borderLeft: '1px solid #000000',borderRight: '1px solid #000000', borderBottom: '1px solid #000000'}]}>備註說明</Text>
              <Text style={[styles.alignLeft, {width: '75%', borderRight: '1px solid #000000', borderBottom: '1px solid #000000'}]}></Text>
            </View>
            <View style={[styles.row, {height: '30px'}]}>
            </View>
            <View style={styles.row}>
              <Text style={[styles.alignLeft, {width: '10%', borderLeft: '1px solid #000000', borderTop: '1px solid #000000', borderRight: '1px solid #000000', borderBottom: '1px solid #000000'}]}>科目</Text>
              <Text style={[styles.alignLeft, {width: '25%', borderTop: '1px solid #000000', borderRight: '1px solid #000000', borderBottom: '1px solid #000000'}]}>分類</Text>
              <Text style={[styles.alignLeft, {width: '10%', borderTop: '1px solid #000000', borderRight: '1px solid #000000', borderBottom: '1px solid #000000'}]}>憑證號碼</Text>
              <Text style={[styles.alignLeft, {width: '30%', borderTop: '1px solid #000000', borderRight: '1px solid #000000', borderBottom: '1px solid #000000'}]}>內容</Text>
              <Text style={[styles.alignLeft, {width: '25%', borderTop: '1px solid #000000', borderRight: '1px solid #000000', borderBottom: '1px solid #000000'}]}>金額</Text>
            </View>
            {data.vouchers && data.vouchers.map((voucher, index) => {
              return (
                <View style={styles.row}>
                  <Text style={[styles.alignLeft, {width: '10%', borderLeft: '1px solid #000000',borderRight: '1px solid #000000', borderBottom: '1px solid #000000'}]}>12121</Text>
                  <Text style={[styles.alignLeft, {width: '25%', borderRight: '1px solid #000000', borderBottom: '1px solid #000000'}]}>{ voucher.tag }</Text>
                  <Text style={[styles.alignLeft, {width: '10%', borderRight: '1px solid #000000', borderBottom: '1px solid #000000'}]}>{ voucher.voucherCode }</Text>
                  <Text style={[styles.alignLeft, {width: '30%', borderRight: '1px solid #000000', borderBottom: '1px solid #000000'}]}></Text>
                  <Text style={[styles.alignLeft, {width: '25%', borderRight: '1px solid #000000', borderBottom: '1px solid #000000'}]}>{voucher == null ? "" : voucher.amount}</Text>
                </View>
              )
            })}
          </View>
        </View>
      </Page>
    </Document>
  )
}

const DivPanelContainer = styled.div`
  margin-bottom: 40px;
`;

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding: 0;
  justify-content: space-between;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const ButtonAdd = styled(ButtonBasic)`
  width: 120px;

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`;

const ButtonExport = styled(ButtonBasic)`
  margin-right: 10px;
  width: 120px;

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`;

const config = {
  projectID: '',
  expenseCode: '',
  // invoiceCode: '',
  voucherCode: '',
  object: '',
  objectID: '',
  user: '',
  declareType: '',
  paymentMethod: '',
  approvalStatus: '',
  notForBusinessTax: '',
  notForAccountingSys: '',
  type: '',
  tag: '',
  isPrinted: '',
  isImportant: '',
  price: { min: '', max: '' },
  applyDate: { start: null, end: null },
  predictDate: { start: null, end: null, isNull: false },
  paymentDate: { start: null, end: null, isNull: false },
  declareMonth: { start: null, end: null },
  personalIncomeDeclareDate: { start: null, end: null },
  amount: 10,
  companyID: '',
}

const FinanceManagement = () => {
  const dispatch = useDispatch();
  const vendorIDs = useSelector(state => state.srpOptions.vendorIDOptionsTaxID);
  const financeRoutes = useSelector(state => state.login.financeRoutes);
  const memberOptions = useSelector(state => state.srpOptions.memberOptions);
  const expenseTypeOptions = useSelector(state => state.srpOptions.expenseTypeOptions);
  const expenseTagDictionary = useSelector(state => state.srpOptions.expenseTagDictionary);
  const projectIDOptions = useSelector(state => state.srpOptions.projectIDOptions);
  const declareTypeOptions = useSelector(state => state.srpOptions.declareTypeOptions);
  const showSaveFailPopup = useSelector(state => state.apiFail.showSaveFailPopup);
  const apiFailPopupTitle = useSelector(state => state.apiFail.title);
  const apiFailPopupMessage = useSelector(state => state.apiFail.message);
  const companyTypeOptions = useSelector(state => state.srpOptions.companyTypeOptions);

  const [expenses, setExpenses] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [checkedExpenses, setCheckedExpenses] = useState([]);
  var allExpenses = [];

  const [financeAction, setFinanceAction] = useState('predict');
  const [financeActionTime, setFinanceActionTime] = useState(null);
  const [searchConfig, setSearchConfig] = useState(cloneDeep(config));

  const [selectAll, setSelectAll] = useState(false);
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [showFinanceActionPopup, setShowFinanceActionPopup] = useState(false);
  const [showFinanceActionSuccessPopup, setShowFinanceActionSuccessPopup] = useState(false);

  const userExportable = financeRoutes.indexOf('FinanceManagement-Export') !== -1;
  const userfinanceAction = financeRoutes.indexOf('FinanceManagement-Action') !== -1;

  const selectedItemNumber = expenses
    .filter(expense => expense.isChecked).length;

  const [popupFinanceActionsSelectOptions, setPopupFinanceActionsSelectOptions] = useState([
    { value: 'predict', desc: '預計付款作業' },
    { value: 'payment', desc: '確定付款作業' },
    { value: 'print', desc: '支出憑單列印' },
    { value: 'service', desc: '勞務申報匯出' },
    { value: 'exportExpenseVoucher', desc: '匯會計系統', disabled: true },
    // { value: 'setExportedAccountingSystem', desc: '設定匯會計系統' },
  ]);

  // const [exportExpensePDF, setExportExpensePDF] = useState({});

  const objectList = () => {
    if (searchConfig.object === 'user') return memberOptions;
    if (searchConfig.object === 'vendor') return vendorIDs;

    return [];
  };

  const updateConfigHandler = (key, value) => {
    // console.log("financeManagement.js updateConfigHandler()");
    const updateObj = { ...searchConfig };
    updateObj[key] = value;
    setSearchConfig({ ...updateObj });
  };

  const objectChangeHandler = object => {
    setSearchConfig(prevState => ({
      ...prevState,
      object,
      objectID: '',
    }));
  };

  const typeChangeHandler = type => {
    setSearchConfig(prevState => ({
      ...prevState,
      type,
      tag: '',
    }));
  };

  const financeActionChangeHandler = value => {
    setFinanceAction(value);
    setFinanceActionTime(null);
  };

  const selectAllHandler = value => {
    setSelectAll(value);
    const updateExpenses = expenses
      .map(expense => ({
        ...expense,
        isChecked: expense.approvalStatus === 'generalManagerApproved' && value,
      }));
    setExpenses([...updateExpenses]);
    if (value === true) {
      setCheckedExpenses([...updateExpenses]);
    } else {
      setCheckedExpenses([]);
    }
  };

  const expenseCheckedHandler = (index, value) => {
    const updateExpenses = [...expenses];
    updateExpenses[index].isChecked = value;
    setExpenses([...updateExpenses]);
    if (value === true) {
      setCheckedExpenses([...checkedExpenses, updateExpenses[index]]);
    } else {
      setCheckedExpenses(checkedExpenses.filter(item => item.expenseCode !== updateExpenses[index].expenseCode));
    }
  };

  const setCurrentPageHandler = (page, clearCheckedExpense = false) => {
    setCurrentPage(page);
    fetchExpenses(page);
    if (clearCheckedExpense === true) {
      // console.log("financeManagement setCurrentPageHandler setCheckedExpenses")
      setCheckedExpenses([]);
    }
  };

  const financeActionHandler = () => {
    setShowFinanceActionPopup(false);
    const financeActionDict = {
      predict: updatePredictDateHandler,
      payment: updatePaymentDateHandler,
      service: exportServiceHandler,
      print: printExpensesHandler,
      exportExpenseVoucher: exportExpenseVoucherHandler,
      setExportedAccountingSystem: setExportedAccountingSystemHandler,
    };

    const executeAction = financeActionDict[financeAction];
    executeAction();
  };

  const financeActionSuccessHandler = () => {
    setSelectAll(false);
    setFinanceAction('predict');
    setFinanceActionTime(null);
    setShowFinanceActionSuccessPopup(false);
    fetchExpenses();
  };

  const updatePredictDateHandler = async () => {
    setShowLoadingPopup(true);
    const selectedItems = expenses
      .filter(expense => expense.isChecked);

    try {
      for (let i = 0 ; i < selectedItems.length; i += 1) {
        const { expenseID } = selectedItems[i];
        const { data } = await postToGetExpenseDetail(expenseID);
        await putModifyExpenseDetail({
          ...data,
          predictDate: financeActionTime,
        });
      }

      setShowLoadingPopup(false);
      setShowFinanceActionSuccessPopup(true);
    } catch (err) {
      console.log(err);
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '更新失敗', err }));
    }
  };

  const updatePaymentDateHandler = async () => {
    setShowLoadingPopup(true);
    const selectedItems = expenses
      .filter(expense => expense.isChecked);

    try {
      for (let i = 0 ; i < selectedItems.length; i += 1) {
        const { expenseID } = selectedItems[i];
        const { data } = await postToGetExpenseDetail(expenseID);
        await putModifyExpenseDetail({
          ...data,
          paymentDate: formatTime(financeActionTime, 'YYYY-MM-DD'),
        });
      }

      setShowLoadingPopup(false);
      setShowFinanceActionSuccessPopup(true);
    } catch (err) {
      console.log(err);
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '更新失敗', err }));
    }
  };

  const exportServiceHandler = async () => {
    setShowLoadingPopup(true);
    const selectedItems = expenses
      .filter(expense => expense.isChecked && expense.object === 'vendor');
    
    const services = [];
    const voucherDatas = [];
    // console.log("financeManagement.js exportServiceHandler voucher:" + JSON.stringify(selectedItems));
    try {
      for (let i = 0 ; i < selectedItems.length; i += 1) {
        const { expenseID, objectID } = selectedItems[i];
        const { data } = await postToGetExpenseDetail(expenseID);
        const { vouchers, applyDate, paymentDate, expenseCode, companyType } = data;
        // console.log("financeManagement.js exportServiceHandler data.companyType:" + data.companyType);
        console.log("financeManagement.js exportServiceHandler vouchers:" + JSON.stringify(vouchers));
        const serviceVouchers = vouchers
          .filter(voucher => voucher.accounting && voucher.accounting.personalService);

        serviceVouchers.forEach(voucher => {
          services.push({
            ...voucher,
            objectID,
            applyDate,
            paymentDate,
            expenseCode,
            companyType,
          })
        });
      }

      for (let i = 0; i < services.length; i += 1) {
        const voucher = services[i];
        const vendorDetail = await postToGetVendorDetail(voucher.objectID);
        console.log("financeManagement.js exportServiceHandler voucher:" + JSON.stringify(voucher));
        const { vendorName, contactAddress } = vendorDetail.data;
        voucherDatas.push([
          getCompanyInvoiceNumber(companyTypeOptions, voucher.companyType),
          getOptionDesc(vendorInvoiceTypes, voucher.voucherType),
          getOptionDesc(declareTypeOptions, voucher.accounting.declareType),
          voucher.accounting.personalIncomeDeclareDate,
          vendorName,
          voucher.taxID,
          getAddressString(contactAddress),
          voucher.note,
          voucher.amount,
          formatTime(voucher.applyDate, 'YYYY/MM/DD') || '',
          voucher.expenseCode,
          formatTime(voucher.paymentDate, 'YYYY/MM/DD') || '',
        ]);
      }
      console.log("financeManagement.js exportServiceHandler voucherDatas:" + JSON.stringify(voucherDatas));
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
    exportPersonalServiceTaxFile(voucherDatas);
  };

  const printExpensesHandler = async() => {
    setShowLoadingPopup(true);
    const selectedItems = expenses
      .filter(expense => expense.isChecked)
      .map(expense => expense.expenseID);
    
    if (selectedItems.length === 0) return setShowLoadingPopup(false);

    try {
      const { data } = await postToGetExpensePDFUrl(selectedItems);
      const { downloadUrl } = data;
      downloadFromUrl(downloadUrl);
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const getAllExpenses = async () => {
    try {
      const { declareMonth, predictDate, paymentDate } = searchConfig;

      const { data } = await postToGetExpenses(
        searchConfigGenerator({
          ...searchConfig,
          page: 1,
          amount: 10000,
          user: searchConfig.user === '' ? [] : [searchConfig.user],
          approvalStatus: searchConfig.approvalStatus === '' ? [] : [searchConfig.approvalStatus],
          paymentMethod: searchConfig.paymentMethod === '' ? [] : [searchConfig.paymentMethod],
          expenseCode: integerConverter(searchConfig.expenseCode),
          declareMonth: {
            end: formatTime(declareMonth.end, 'YYYY-MM'),
            start: formatTime(declareMonth.start, 'YYYY-MM'),
          },
          price: {
            min: integerConverter(searchConfig.price.min) || null,
            max: integerConverter(searchConfig.price.max) || null,
          },
          predictDate: {
            isNull: predictDate.isNull || null,
            end: formatTime(predictDate.end, 'YYYY-MM-DD'),
            start: formatTime(predictDate.start, 'YYYY-MM-DD'),
          },
          paymentDate: {
            isNull: paymentDate.isNull || null,
            end: formatTime(paymentDate.end, 'YYYY-MM-DD'),
            start: formatTime(paymentDate.start, 'YYYY-MM-DD'),
          },
        })
      );
      allExpenses = data.expenses.map(function (value, index, array){
        return { projectID: value.projectID, expenseID: value.expenseID, isChecked: true } ;
      });
      console.log("financeManagement.js getAllExpenses allExpenses:" + JSON.stringify(allExpenses));
    } catch(err) { console.log(err) }
  }

  const exportHandler = async (exportAllExpenses = false) => {
    setShowLoadingPopup(true);
    let financeDatas = [];

    try {
      // const { declareMonth, predictDate, paymentDate } = searchConfig;
      // console.log("financeManagement.js exportHandler searchConfig:" + JSON.stringify(searchConfig));

      // const { data } = await postToGetExpenses(
      //   searchConfigGenerator({
      //     ...searchConfig,
      //     page: 1,
      //     amount: totalCount || 1,
      //     user: searchConfig.user === '' ? [] : [searchConfig.user],
      //     approvalStatus: searchConfig.approvalStatus === '' ? [] : [searchConfig.approvalStatus],
      //     paymentMethod: searchConfig.paymentMethod === '' ? [] : [searchConfig.paymentMethod],
      //     expenseCode: integerConverter(searchConfig.expenseCode),
      //     declareMonth: {
      //       end: formatTime(declareMonth.end, 'YYYY-MM'),
      //       start: formatTime(declareMonth.start, 'YYYY-MM'),
      //     },
      //     price: {
      //       min: integerConverter(searchConfig.price.min) || null,
      //       max: integerConverter(searchConfig.price.max) || null,
      //     },
      //     predictDate: {
      //       isNull: predictDate.isNull || null,
      //       end: formatTime(predictDate.end, 'YYYY-MM-DD'),
      //       start: formatTime(predictDate.start, 'YYYY-MM-DD'),
      //     },
      //     paymentDate: {
      //       isNull: paymentDate.isNull || null,
      //       end: formatTime(paymentDate.end, 'YYYY-MM-DD'),
      //       start: formatTime(paymentDate.start, 'YYYY-MM-DD'),
      //     },
      //   })
      // );

      // const { expenses } = data;
      const expensesData = [];
      var toExportExpenses = checkedExpenses;
      if (exportAllExpenses === true) {
        // console.log("financeManagement.js exportAllHandler allExpenses:" + JSON.stringify(allExpenses));
        await getAllExpenses();
        toExportExpenses = allExpenses;
        console.log("financeManagement.js exportAllHandler toExportExpenses.length:" + toExportExpenses.length);
      }

      for (let i = 0; i < toExportExpenses.length; i += 1) {
        // console.log("financeManagement exportHandler isChecked:" + expenses[i].isChecked)
        if (toExportExpenses[i].isChecked !== true)
          continue;
        const expense = toExportExpenses[i];
        const { projectID, expenseID } = expense;
        const expenseDetail = await postToGetExpenseDetail(expenseID);
        const projectDetail = await postToGetProjectDetail(projectID);

        const { projectCode, projectName } = projectDetail.data;

        expensesData.push({
          ...expenseDetail.data,
          projectCode,
          projectName,
        });

        console.log("vendorIDs:" + JSON.stringify(vendorIDs));
        financeDatas = expensesData
          .reduce((acc, expense) => {
            const expenses = expense.vouchers.map(voucher => [
              formatTime(expense.applyDate, 'YYYY/MM/DD'),
              expense.expenseCode || '',
              getOptionDesc(expenseApprovalStates, expense.approvalStatus),
              expense.projectCode,
              expense.projectName,
              expense.object === 'user'
                ? getOptionDescAndRemoveBrackets(memberOptions, expense.objectID)
                : getOptionDescAndRemoveBrackets(vendorIDs, expense.objectID),
              getOptionDesc(memberOptions, expense.user),
              voucher.note,
              voucher.amount,
              expense.isImportant ? '急件' : '普件',
              voucher.notForAccountingSys ? '否' : '是',
              voucher.notForBusinessTax ? '否' : '是',
              voucher.voucherCode,
              formatTime(voucher.date, 'YYYY/MM/DD') || '',
              formatTime(expense.predictDate, 'YYYY/MM/DD') || '',
              expense.note,
              voucher.taxID,
              getOptionDesc(expenseTypeOptions, voucher.type),
              voucher.tag,
            ]);
            return [...acc, ...expenses];
          }, []);
      }

    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
    exportFinanceManagementFile(financeDatas);
  };

  const exportExpenseVoucherHandler = async () => {
    setShowLoadingPopup(true);
    // console.log("exportExpenseVoucherHandler expenses:" + JSON.stringify(expenses));
    // console.log("exportExpenseVoucherHandler checkedExpenses:" + JSON.stringify(checkedExpenses));
    // const selectedItems = expenses
      // .filter(expense => expense.isChecked);
    
    // const services = [];
    const voucherDatas = [];

    try {
      for (let i = 0 ; i < checkedExpenses.length; i += 1) {
        const { projectID, expenseID } = checkedExpenses[i];
        const { data } = await postToGetExpenseDetail(expenseID);
        const projectDetail = await postToGetProjectDetail(projectID);
        console.log("exportExpenseVoucherHandler data:" + JSON.stringify(data));
        // console.log("exportExpenseVoucherHandler data.payment.method:" + JSON.stringify(data.payment.method));
        console.log("exportExpenseVoucherHandler projectDetail:" + JSON.stringify(projectDetail));
        var accountingSerialNumber = "212100";//科目編號
        if (data.object === "vendor") {
          accountingSerialNumber = "212100";
        } else if (data.object === "user") {
          accountingSerialNumber = "212150";
        }
        if (data.payment.method === "creditCard") {
          accountingSerialNumber = "212190";
        }
        // console.log("exportExpenseVoucherHandler data.user:" + data.user);
        // console.log("exportExpenseVoucherHandler projectDetail.data:" + JSON.stringify(projectDetail.data));
        const userInfoDirector = await postToGetUserInfo(projectDetail.data.director);
        // console.log("exportExpenseVoucherHandler userInfoDirector.data:" + JSON.stringify(userInfoDirector.data));
        await postToSetExportedAccountingSystem({
            exportedAccountingSystem : true,
            expenseIDs: [expenseID],
          });
        
        // const { vouchers, applyDate, paymentDate, expenseCode, price } = data;
        var voucherCreditOrDebit = "";
        if (data.price > 0) {
          console.log("exportExpenseVoucherHandler :amount is greater than 0");
          voucherCreditOrDebit = "D";
          voucherDatas.push([
            "N1",//公司別, 頤德=N1，其它公司不會匯出
            "3",//傳票類別
            formatTime(data.applyDate, 'YYYYMMDD') || '',//傳票日期
            accountingSerialNumber,//科目編號
            "應付帳款" + "(" + data.expenseCode + ")",//摘要備註
            'C',//借/貸
            data.price,//金額
            "C",//對象別
            "",//對象編號
            data.expenseCode,
            userInfoDirector.data.name.substring(0, 4),//部門
            // projectDetail.data.projectName,//專案代號
          ]);
        } else {
          console.log("exportExpenseVoucherHandler :amount is smaller than 0");
          voucherCreditOrDebit = "C";
          voucherDatas.push([
            "N1",//公司別, 頤德=N1，其它公司不會匯出
            "3",//傳票類別
            formatTime(data.applyDate, 'YYYYMMDD') || '',//傳票日期
            accountingSerialNumber,//科目編號
            "應付帳款" + "(" + data.expenseCode + ")",//摘要備註
            'D',//借/貸
            Math.abs(data.price),//金額
            "",//對象別
            "",//對象編號
            data.expenseCode,
            userInfoDirector.data.name.substring(0, 4),//部門
            // projectDetail.data.projectName,//專案代號
          ]);
          console.log("exportExpenseVoucherHandler :amount is smaller than 0");
        }
        var businessTax = false;
        var businessTaxAmount = 0;
        for (let i = 0; i < data.vouchers.length; i += 1) {
          console.log(data.vouchers[i]);
          console.log(data.vouchers[i].accounting.notForBusinessTax);
          var priceOfAmount = 0;
          if (data.vouchers[i].accounting.notForBusinessTax !== true) {
            businessTax = true;
            priceOfAmount = Math.abs(data.vouchers[i].untaxedAmount);
            businessTaxAmount += Math.abs(data.vouchers[i].tax);
          } else {
            priceOfAmount = Math.abs(data.vouchers[i].amount);
          }
          const returnOfRequest = await postToGetExpenseTags({
            expenseTypeID: data.vouchers[i].type, page: 1, amount: 1000, 
          });
          const expenseTags = returnOfRequest.data.expenseTags;
          // console.log(expenseTags);
          console.log("exportExpenseVoucherHandler data.vouchers[i].type:" + data.vouchers[i].type);
          if (data.vouchers[i].type === "01FQY3M96W5M9ZKRNP6FJS0E7F") {
            expenseTags.push({"enable":true,"expenseCode":"652500","name":"銀行手續費","note":"銀行匯款轉帳手續費","projectCode":"552500"})
          }
          console.log("exportExpenseVoucherHandler expenseTags:" + JSON.stringify(expenseTags));
          const objTag = expenseTags.find(function (element) {
            return element.name === data.vouchers[i].tag;
          });
          console.log(objTag);
          var objTagName = "";
          if (objTag !== undefined && objTag.expenseCode !== undefined) {
            if (projectDetail.data.projectCode.toUpperCase().startsWith('VEDA') || projectDetail.data.projectCode.toUpperCase().startsWith('KM') || projectDetail.data.projectCode.toUpperCase().startsWith('EREADY') || projectDetail.data.projectCode.toUpperCase().startsWith('AMOTOR')) {
              objTagName = objTag.expenseCode;
            } else {
              objTagName = objTag.projectCode;
            }
          }
          voucherDatas.push([
            "N1",//公司別, 頤德=N1，其它公司不會匯出
            "3",//傳票類別
            formatTime(data.applyDate, 'YYYYMMDD') || '',//傳票日期
            // objTag.expenseCode,//科目編號
            objTagName,//科目編號
            data.vouchers[i].note,//摘要備註
            // data.vouchers[i].amount > 0 ? 'C' : 'D',//借/貸
            voucherCreditOrDebit,//借/貸
            priceOfAmount,//金額
            "",//對象別
            "",//對象編號
            data.expenseCode,
            userInfoDirector.data.name.substring(0, 4),//部門
            // projectDetail.data.projectName,//專案代號
          ]);
          if (businessTax && i === data.vouchers.length - 1 && businessTaxAmount > 0) {
            voucherDatas.push([
              "N1",//公司別, 頤德=N1，其它公司不會匯出
              "3",//傳票類別
              formatTime(data.applyDate, 'YYYYMMDD') || '',//傳票日期
              // objTag.expenseCode,//科目編號
              "114400",//科目編號
              "進項稅額",//摘要備註
              'D',//借/貸
              businessTaxAmount,//金額
              "",//對象別
              "",//對象編號
              data.expenseCode,
              userInfoDirector.data.name.substring(0, 4),//部門
              // projectDetail.data.projectName,//專案代號
            ]);
          }
        }
      }

    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
    exportExpenseVoucherFile(voucherDatas);
  };

  const setExportedAccountingSystemHandler = async() => {
    setShowLoadingPopup(true);
    const selectedItems = expenses
      .filter(expense => expense.isChecked)
      .map(expense => expense.expenseID);

    console.log("setExportedAccountingSystemHandler selectedItems:" + JSON.stringify(selectedItems));
    
    if (selectedItems.length === 0) return setShowLoadingPopup(false);

    try {
      await postToSetExportedAccountingSystem({
        exportedAccountingSystem : true,
        expenseIDs: selectedItems,
      });
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  function isExpenseChecked(needle, haystack) {
    var checked = false;
    for (let i = 0; i < haystack.length; i++) {
      if (haystack[i].expenseCode === needle.expenseCode) {
        // console.log("financeManagement isExpenseChecked expenseCode:" + haystack[i].expenseCode)
        if (haystack[i].isChecked === true) {
          checked = true;
          break;
        }
      }
    }
    return checked;
  }

  const fetchExpenses = async (page = currentPage) => {
    // console.log("financeManagement.js fetchExpenses:");
    let searchConfigCompanyType = searchConfig.companyType
    let objCompanyVeda = companyTypeOptions.find((object) => {
      // console.log("financeManagement fetchExpenses searchConfig:" + object.value);
      // console.log("financeManagement fetchExpenses searchConfig:" + searchConfig.companyId);
      if (object.desc === "頤德國際" && object.value === searchConfigCompanyType) {
        return true;
      }
    });
    // console.log(objCompanyVeda);
    // if (objCompanyVeda !== undefined) {
      setPopupFinanceActionsSelectOptions([
        { value: 'predict', desc: '預計付款作業' },
        { value: 'payment', desc: '確定付款作業' },
        { value: 'print', desc: '支出憑單列印' },
        { value: 'service', desc: '勞務申報匯出' },
        { value: 'exportExpenseVoucher', desc: '匯會計系統', disabled: false },
        // { value: 'setExportedAccountingSystem', desc: '設定匯會計系統' },
      ]);
    // }
    setShowLoadingPopup(true);

    try {
      const { declareMonth, predictDate, paymentDate } = searchConfig;

      const { data } = await postToGetExpenses(
        searchConfigGenerator({
          ...searchConfig,
          page,
          user: searchConfig.user === '' ? [] : [searchConfig.user],
          approvalStatus: searchConfig.approvalStatus === '' ? [] : [searchConfig.approvalStatus],
          paymentMethod: searchConfig.paymentMethod === '' ? [] : [searchConfig.paymentMethod],
          expenseCode: integerConverter(searchConfig.expenseCode),
          declareMonth: {
            end: formatTime(declareMonth.end, 'YYYY-MM'),
            start: formatTime(declareMonth.start, 'YYYY-MM'),
          },
          price: {
            min: integerConverter(searchConfig.price.min) || null,
            max: integerConverter(searchConfig.price.max) || null,
          },
          predictDate: {
            isNull: predictDate.isNull || null,
            end: formatTime(predictDate.end, 'YYYY-MM-DD'),
            start: formatTime(predictDate.start, 'YYYY-MM-DD'),
          },
          paymentDate: {
            isNull: paymentDate.isNull || null,
            end: formatTime(paymentDate.end, 'YYYY-MM-DD'),
            start: formatTime(paymentDate.start, 'YYYY-MM-DD'),
          },
        })
      );

      // console.log("financeManagement.js fetchExpenses data:" + JSON.stringify(data));
      // eslint-disable-next-line
      // const previousExpenses = [...expenses];
      // if (expenses !== undefined) {
      //   previousExpenses = [...expenses];
      // }

      // const { expenses, totalCount } = data;
      const currentExpenses = data.expenses;
      const totalCount = data.totalCount;
      const expenseForms = [];

      for (let i = 0; i < currentExpenses.length; i += 1) {
        const expense = currentExpenses[i];
        const { projectID, expenseID } = expense;
        const expenseDetail = await postToGetExpenseDetail(expenseID);
        const projectDetail = await postToGetProjectDetail(projectID);
        const { projectCode, projectName } = projectDetail.data;
        expenseForms.push({
          ...expenseDetail.data,
          projectCode,
          projectName,
          isChecked: isExpenseChecked(expense, checkedExpenses),
          notForBusinessTax: expenseDetail.data.vouchers
            .some(voucher => voucher.accounting.notForBusinessTax),
          notForAccountingSys: expenseDetail.data.vouchers
            .some(voucher => voucher.accounting.notForAccountingSys),
          isPaid: expenseDetail.data.paymentDate === undefined ? false : true,
        });
      };

      setExpenses([...expenseForms]);
      setTotalCount(totalCount || 0);
    } catch(err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const initExpenses = useCallback(
    fetchExpenses, []);

  const initOptions = useCallback(() => {
    dispatch(FETCH_MEMBER_OPTIONS());
    dispatch(FETCH_VENDOR_ID_OPTIONS());
    dispatch(FETCH_PROJECT_ID_OPTIONS());
    dispatch(FETCH_EXPENSE_TYPE_OPTIONS());
    dispatch(FETCH_DECLARE_TYPE_OPTIONS());
  }, [dispatch]);

  useEffect(() => {
    initOptions();
    initExpenses();
  }, [initExpenses, initOptions]);

  // useEffect(() => { console.log(state1, state2)}, [state1])
  useEffect(() => {
    // console.log(searchConfig.amount);
    // console.log(searchConfig.amount);
    // fetchExpenses();
    // console.log("123");
    fetchExpenses();
  }, [currentPage, searchConfig.amount]);// eslint-disable-line react-hooks/exhaustive-deps

  const setIsPaymentPrintedFalse = async () => { 
    setShowLoadingPopup(true);
    
    try {
      for (let i = 0; i < checkedExpenses.length; i += 1) {
        console.log("financeManagement setIsPaymentPrintedFalse :" + checkedExpenses[i].expenseID);
        const expense = expenses[i];
        const { expenseID } = expense;
        const expenseDetail = await postToGetExpenseDetail(expenseID);

        putModifyExpenseDetail({
          ...expenseDetail.data,
          isPaymentPrinted: false,
        });
      }

    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };
  // const fetchExportExpensePDF = async () => {
  //   try {
  //     console.log("financeManagement.js fetchExportExpensePDF checkedExpenses:" + JSON.stringify(checkedExpenses));
  //     setExportExpensePDF({
  //       projectCode: checkedExpenses[0].projectCode, 
  //       expenseCode: checkedExpenses[0].expenseCode, 
  //       price: checkedExpenses[0].price,
  //       vouchers: checkedExpenses[0].vouchers,
  //     });
  //     console.log("financeManagement.js fetchExportExpensePDF :");
  //   } catch (err) { console.log(err) }
  //   // setExportExpensePDF({name: "Berkshire Hathaway"});
  // };

  // useEffect(() => {
  //   console.log("useEffect");
  //   fetchExportExpensePDF();
  // }, [checkedExpenses]);// eslint-disable-line react-hooks/exhaustive-deps

  const onShowSizeChange = (current, pageSize) => {
    setSearchConfig(prevState => ({
      ...prevState,
      amount: pageSize,
    }));
    setCurrentPage(1);
    // updateConfigHandler("amount", pageSize);
    // console.log(current, pageSize);
  };

  return (
    <MainContent>
      <DivPanelContainer>
        <PanelFinanceManagementSearch
          config = { searchConfig }
          objectList = { objectList() }
          memberList = { memberOptions }
          projectIDOptions = { projectIDOptions }
          declareTypeOptions = { declareTypeOptions }
          expenseTypeOptions = { expenseTypeOptions }
          expenseTagDictionary = { expenseTagDictionary }
          typeChangeHandler = { typeChangeHandler }
          objectChangeHandler = { objectChangeHandler }
          updateConfigHandler = { updateConfigHandler }
          companyTypeOptions = { companyTypeOptions }
        >
          <DivFlexRow>
            <DivButtonsWrapper>
              <div>
                <ButtonExport
                  disabled = { !userExportable }
                  onClick = { () => exportHandler(false) }
                >匯出</ButtonExport>
                <ButtonAdd
                  disabled = { selectedItemNumber === 0 || !userfinanceAction }
                  onClick = { () => setShowFinanceActionPopup(true) }
                >財務作業</ButtonAdd>
                &nbsp;&nbsp;
                <ButtonExport
                  disabled = { !userExportable }
                  onClick = { () => exportHandler(true) }
                >匯出全部</ButtonExport>
                <ButtonExport
                  onClick = { () => setIsPaymentPrintedFalse() }
                >設定代墊款未列印</ButtonExport>
                {/* <PDFDownloadLink document={<MyDoc data={ exportExpensePDF } />} fileName="somename.pdf">
                  {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download now!')}
                </PDFDownloadLink> */}
              </div>
              <SearchButtonSet
                clearHandler = { () => {
                  setSearchConfig(cloneDeep(config));
                }}
                searchHandler = { () => {
                  setCurrentPageHandler(1, true);
                }}
              />
            </DivButtonsWrapper>
          </DivFlexRow>
        </PanelFinanceManagementSearch>
      </DivPanelContainer>
      <TableFinanceManagements
        expenses = { expenses }
        selectAll = { selectAll }
        totalCount = { totalCount }
        currentPage = { currentPage }
        checkHandler = { expenseCheckedHandler }
        selectAllHandler = { selectAllHandler }
        pageChangedHandler = { setCurrentPageHandler }
        pageSize = { searchConfig.amount }
        showSizeChanger = { true }
        pageSizeOptions = { ['10', '50', '100', '500'] }
        onShowSizeChange = { onShowSizeChange }
      />
      { showLoadingPopup && <PopupLoading/> }
      { 
        showFinanceActionPopup &&
          <PopupFinanceActionsSelect
            showDateSelector = { financeAction === 'predict' || financeAction === 'payment' }
            financeAction = { financeAction }
            financeActionTime = { financeActionTime }
            changeHandler = { financeActionChangeHandler }
            confirmHandler = { financeActionHandler }
            updateFinanceActionTimeHandler = { setFinanceActionTime }
            cancelHandler = { () => {
              setShowFinanceActionPopup(false)
            }}
            options = { popupFinanceActionsSelectOptions }
          />
      }
      {
        showFinanceActionSuccessPopup &&
          <PopupExecuteResult
            title = "更新成功"
            confirmHandler = { financeActionSuccessHandler }
          />
      }
      {
        showSaveFailPopup &&
          <PopupExecuteResult
            title = { apiFailPopupTitle }
            text = { apiFailPopupMessage }
            confirmHandler = { () => {
              dispatch(CLOSE_SAVE_FAIL_POPUP());
            }}
          />
      }
    </MainContent>
  )
};

export default FinanceManagement;
