import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  postToGetPasswordParams,
  putModifyPasswordParams,
} from '@/api/authService';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import MainContent from '@/component-style/MainContent';
import TableWrapper from '@/components/UI/TableWrapper';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import { InputBasic } from '@/component-style/Input';
import { PSubtitle } from '@/component-style/TextElement';
import { ButtonBasic } from '@/component-style/Button';
import {
  DivFlexRow,
  DivItemWrapper,
} from '@/component-style/RWDWrapper';

import { integerConverter } from '@/utils/textUtils';
import { passwordParamsConfig } from '@/constant/dataConfig/sysConfigModule';

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding-right: 0;
  padding-bottom: 30px;
  justify-content: flex-end;

  @media (max-width: 425px) {
    justify-content: center;
    padding-left: 0;
  }
`;

const ButtonSave = styled(ButtonBasic)`
  width: 120px;
`;

const SystemParams = () => {
  const dispatch = useDispatch();
  const sysConfigRoutes = useSelector(state => state.login.sysConfigRoutes);
  const showSaveFailPopup = useSelector(state => state.apiFail.showSaveFailPopup);
  const apiFailPopupTitle = useSelector(state => state.apiFail.title);
  const apiFailPopupMessage = useSelector(state => state.apiFail.message);

  const [config, setConfig] = useState({ ...passwordParamsConfig });

  const [isEditing, setIsEditing] = useState(false);
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [showSaveSuccessPopup, setShowSaveSuccessPopup] = useState(false);

  const userEditable = sysConfigRoutes.indexOf('SystemParams-Edit') !== -1;

  const updateConfigHandler = (key, value) => {
    const updateObj = { ...config };
    updateObj[key] = value;
    setConfig({ ...updateObj });
  };

  const closeSuccessPopupHandler = () => {
    setIsEditing(false);
    setShowSaveSuccessPopup(false);
  };

  const saveHandler = async() => {
    setShowLoadingPopup(true);

    try {
      await putModifyPasswordParams({
        validDays: integerConverter(config.validDays),
        minLength: integerConverter(config.minLength),
        maxLength: integerConverter(config.maxLength),
        errorLimit: integerConverter(config.errorLimit),
      });
      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
    } catch (err) {
      console.log(err);
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '更新失敗', err }));
    }

    setShowLoadingPopup(false)
  };

  const fetchParams = async () => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetPasswordParams();
      setConfig({ ...passwordParamsConfig, ...data })
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false)
  };

  const initParams = useCallback(
    fetchParams, []);

  useEffect(() => {
    initParams();
  }, [initParams]);

  return (
    <MainContent>
      <TableWrapper title = "系統參數管理">
        <DivFlexRow>
          <DivItemWrapper>
            <PSubtitle>密碼最小長度</PSubtitle>
            <InputBasic
              type = "number"
              disabled = { !isEditing }
              value = { config.minLength }
              onChange = { ({ target }) => {
                updateConfigHandler('minLength', target.value);
              }}
            />
          </DivItemWrapper>
          <DivItemWrapper>
            <PSubtitle>密碼最大長度</PSubtitle>
            <InputBasic
              type = "number"
              disabled = { !isEditing }
              value = { config.maxLength }
              onChange = { ({ target }) => {
                updateConfigHandler('maxLength', target.value);
              }}
            />
          </DivItemWrapper>
        </DivFlexRow>
        {/* <DivFlexRow>
          <DivItemWrapper>
            <PSubtitle>密碼可使用天數</PSubtitle>
            <InputBasic
              type = "number"
              disabled = { !isEditing }
              value = { config.validDays }
              onChange = { ({ target }) => {
                updateConfigHandler('validDays', target.value);
              }}
            />
          </DivItemWrapper>
          <DivItemWrapper>
            <PSubtitle>可錯誤密碼次數</PSubtitle>
            <InputBasic
              type = "number"
              disabled = { !isEditing }
              value = { config.errorLimit }
              onChange = { ({ target }) => {
                updateConfigHandler('errorLimit', target.value);
              }}
            />
          </DivItemWrapper>
        </DivFlexRow> */}
        <DivFlexRow>
          <DivButtonsWrapper>
            {
              isEditing
                ? <ButtonSave
                    onClick = { saveHandler }
                  >儲存</ButtonSave>
                : <ButtonSave
                    disabled = { !userEditable }
                    onClick = { () => { setIsEditing(true) } }
                  >編輯</ButtonSave>
            }
          </DivButtonsWrapper>
        </DivFlexRow>
      </TableWrapper>
      { showLoadingPopup && <PopupLoading/> }
      {
        showSaveSuccessPopup &&
          <PopupExecuteResult
            title = "更新成功"
            confirmHandler = { closeSuccessPopupHandler }
          />
      }
      {
        showSaveFailPopup &&
          <PopupExecuteResult
            title = { apiFailPopupTitle }
            text = { apiFailPopupMessage }
            confirmHandler = { () => {
              dispatch(CLOSE_SAVE_FAIL_POPUP());
            }}
          />
      }
    </MainContent>
  );
};

export default SystemParams;
