import React from 'react';

import TableWrapper from '@/components/UI/TableWrapper';
import Checkbox from '@/components/UI/Checkbox';
import SelectNormal from '@/components/UI/Select/SelectNormal';
import SelectMultiple from '@/components/UI/Select/SelectMultiple';
import DatePeriodSelector from '@/components/UI/DatePeriodSelector';
import AddressInfoSelector from '@/components/UI/AddressInfoSelector';
import { InputBasic, TextareaBasic } from '@/component-style/Input';
import { PSubtitle } from '@/component-style/TextElement';
import {
  DivFlexRow,
  DivItemWrapper,
  DivFullWidthWrapper,
  DivLongMultiSelector,
} from '@/component-style/RWDWrapper';

import {
  customerTypeOptions,
  customerSourceOptions,
} from '@/constant/options';
import { formatTime } from '@/utils/timeUtils';

const PanelCustomerDetail = props => {
  const { customer } = props;

  const startDateChangeHandler = value => {
    props.updateCustomerHandler('partnership', {
      ...customer.partnership,
      startDate: formatTime(value,'YYYY-MM-DD'),
    });
  };

  const endDateChangeHandler = value => {
    props.updateCustomerHandler('partnership', {
      ...customer.partnership,
      endDate: formatTime(value,'YYYY-MM-DD'),
    });
  };

  return (
    <TableWrapper title = { props.title }>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>客戶代號</PSubtitle>
          <div>
            <InputBasic
              disabled = { props.disabled }
              value = { customer.customerCode }
              style = {{ marginBottom: '10px' }}
              onChange = {({ target }) => {
                props.updateCustomerHandler('customerCode', target.value);
              }}
            />
            <Checkbox
              title = "是否比稿"
              disabled = { props.disabled }
              checked = { customer.isComparePrice }
              changeHandler = { value => {
                props.updateCustomerHandler('isComparePrice', value);
              }}
            />
          </div>
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>負責人</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { customer.principal }
            onChange = {({ target }) => {
              props.updateCustomerHandler('principal', target.value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>
            客戶名稱
            <span>*</span>
          </PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { customer.customerName }
            onChange = {({ target }) => {
              props.updateCustomerHandler('customerName', target.value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>統一編號</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { customer.customerTaxId }
            onChange = {({ target }) => {
              props.updateCustomerHandler('customerTaxId', target.value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivFullWidthWrapper>
          <PSubtitle>聯絡地址</PSubtitle>
          <AddressInfoSelector
            disabled = { props.disabled }
            address = { customer.contactAddress }
            updateAddressHandler = { value => {
              props.updateCustomerHandler('contactAddress', value);
            }}
          />
        </DivFullWidthWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivFullWidthWrapper>
          <PSubtitle>發票地址</PSubtitle>
          <AddressInfoSelector
            showCopyButton = { true }
            disabled = { props.disabled }
            address = { customer.invoiceAddress }
            copyAddressHandler = { props.copyAddressHandler }
            updateAddressHandler = { value => {
              props.updateCustomerHandler('invoiceAddress', value);
            }}
          />
        </DivFullWidthWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivFullWidthWrapper>
          <PSubtitle>產業類別</PSubtitle>
          <DivLongMultiSelector>
            <SelectMultiple
              disabled = { props.disabled }
              value = { customer.industry }
              options = {[...props.customerIndustries]}
              changeHandler = { value => {
                props.updateCustomerHandler('industry', value);
              }}
            />
          </DivLongMultiSelector>
        </DivFullWidthWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>網址</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { customer.website }
            onChange = {({ target }) => {
              props.updateCustomerHandler('website', target.value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>客戶來源</PSubtitle>
          <SelectNormal
            disabled = { props.disabled }
            value = { customer.partnership.source }
            options = {[
              { value: '', desc: '請選擇客戶來源' },
              ...customerSourceOptions,
            ]}
            changeHandler = { value => {
              props.updateCustomerHandler('partnership', {
                ...customer.partnership,
                source: value,
              });
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>合作關係</PSubtitle>
          <SelectNormal
            disabled = { props.disabled }
            value = { customer.partnership.type }
            options = {[
              { value: '', desc: '請選擇合作關係' },
              ...customerTypeOptions,
            ]}
            changeHandler = { value => {
              props.updateCustomerHandler('partnership', {
                ...customer.partnership,
                type: value,
              });
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>合作時間</PSubtitle>
          <DivLongMultiSelector>
            <DatePeriodSelector
              disabled = { props.disabled }
              startTime = { customer.partnership.startDate }
              endTime = { customer.partnership.endDate }
              endTimeOKHandler = { value => { endDateChangeHandler(value) }}
              endTimeChangeHandler = { value => { endDateChangeHandler(value) }}
              startTimeOKHandler = { value => { startDateChangeHandler(value) }}
              startTimeChangeHandler = { value => { startDateChangeHandler(value) }}
            />
          </DivLongMultiSelector>
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>付款天數</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { customer.partnership.paymentDays }
            onChange = {({ target }) => {
              props.updateCustomerHandler('partnership', {
                ...customer.partnership,
                paymentDays: target.value,
              });
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>每月結帳日</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { customer.partnership.settlementDate }
            onChange = {({ target }) => {
              props.updateCustomerHandler('partnership', {
                ...customer.partnership,
                settlementDate: target.value,
              });
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivFullWidthWrapper>
          <PSubtitle>備註說明	</PSubtitle>
          <DivLongMultiSelector>
            <TextareaBasic
              disabled = { props.disabled }
              value = { customer.partnership.note }
              onChange = {({ target }) => {
                props.updateCustomerHandler('partnership', {
                  ...customer.partnership,
                  note: target.value,
                });
              }}
            />
          </DivLongMultiSelector>
        </DivFullWidthWrapper>
      </DivFlexRow>
      { props.children }
    </TableWrapper>
  );
};

export default PanelCustomerDetail;
