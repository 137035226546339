export const getMultiplyResult = (value1, value2) => {
  return Number(value1 || 0) * Number(value2 || 0);
};

export const getUntaxedPrice = (price, taxRate) => {
  const ratio = 1 + taxRate / 100;
  return Math.round(price / ratio);
};

export const getHourlySalary = (basicSalary) => {
  if (basicSalary == null) {
    return 0;
  } else {
    return Math.round(basicSalary / 30 / 8);
  }
};