import React from 'react';
import styled from 'styled-components';

import { TableHead, TableRow, ThItem } from '@/component-style/Table';

const THead = styled(TableHead)`
  min-width: 720px;
`;

const Th80 = styled(ThItem)`
  width: 80px;
`;

const Th160 = styled(ThItem)`
  width: 160px;
`;


const Header = props => {
  
  return (
    <THead>
      <TableRow>
        <Th160>角色編號</Th160>
        <Th160>角色名稱</Th160>
        <Th160>狀態</Th160>
        <Th160>刪除</Th160>
        <Th80>明細</Th80>
      </TableRow>
    </THead>
  );
};

export default Header;
