import axiosConfig from './axiosConfig';
import { getDefaultPort } from '@/utils/apiRequestUtils';

const { baseURL } = axiosConfig.defaults;

const port = process.env.REACT_APP_SERVICE_PORT_VENUE !== undefined
  ? process.env.REACT_APP_SERVICE_PORT_VENUE
  : getDefaultPort(baseURL);

  const venueModuleUrl = process.env.NODE_ENV === 'development'
  ? `${baseURL}:${port}/api`
  : `${baseURL}/venue/api`;

export const postToGetVenueTypes = config => axiosConfig
  .post(`${venueModuleUrl}/venue/type`, config);

export const putNewVenueType = config => axiosConfig
  .put(`${venueModuleUrl}/venue/type`, config);

export const patchModifyVenueType = config => axiosConfig
  .patch(`${venueModuleUrl}/venue/type`, config);

export const deleteVenueType = typeIDs => axiosConfig
  .delete(`${venueModuleUrl}/venue/type`, { data: [ ...typeIDs ] });

export const postToGetCharacteristics = config => axiosConfig
  .post(`${venueModuleUrl}/venue/characteristics`, config);

export const putNewCharacteristics = config => axiosConfig
  .put(`${venueModuleUrl}/venue/characteristics`, config);

export const patchCharacteristicsStatus = config => axiosConfig
  .patch(`${venueModuleUrl}/venue/characteristics`, config);

export const deleteCharacteristic = characteristicIDs => axiosConfig
  .delete(`${venueModuleUrl}/venue/characteristics`, { data: [ ...characteristicIDs ] });

export const postToGetEquipments = config => axiosConfig
  .post(`${venueModuleUrl}/venue/equipments`, config);

export const putNewEquipments = config => axiosConfig
  .put(`${venueModuleUrl}/venue/equipments`, config);

export const patchEquipmentsStatus = config => axiosConfig
  .patch(`${venueModuleUrl}/venue/equipments`, config);

export const deleteEquipment = equipmentIDs => axiosConfig
  .delete(`${venueModuleUrl}/venue/equipments`, { data: [ ...equipmentIDs ] });

export const postToGetVenues = config => axiosConfig
  .post(`${venueModuleUrl}/venue`, config);

export const putNewVenue = venue => axiosConfig
  .put(`${venueModuleUrl}/venue`, venue);

export const deleteVenue = venueID => axiosConfig
  .delete(`${venueModuleUrl}/venue`, { data: { venueID } });

export const postToGetVenueDetail = venueID => axiosConfig
  .post(`${venueModuleUrl}/venue/detail`, { venueID });

export const putModifyVenueDetail = venue => axiosConfig
  .put(`${venueModuleUrl}/venue/detail`, venue);

export const postToGetVenueReservations = config => axiosConfig
  .post(`${venueModuleUrl}/venue/reservation`, config);

export const putNewVenueReservations = ({ projectID, venueIDs }) => axiosConfig
  .put(`${venueModuleUrl}/venue/reservation`, { projectID, venueIDs });

export const deleteVenueReservation = venueReservationID => axiosConfig
  .delete(`${venueModuleUrl}/venue/reservation`, { data: { venueReservationID } });

export const postToGetReservationDetail = venueReservationID => axiosConfig
  .post(`${venueModuleUrl}/venue/reservation/detail`, { venueReservationID });

export const putModifyReservationDetail = venueReservation => axiosConfig
  .put(`${venueModuleUrl}/venue/reservation/detail`, venueReservation);
