import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { postToGetVendorDetail } from '@/api/vendorService';
// import { postToGetProjectDetail } from '@/api/projectService';

import {
  putNewExpense,
  deleteQuotation,
  postToGetQuotations,
  postToGetExpenseParamsConfig,
} from '@/api/expenseService';

import {
  getDownloadFile,
  deleteUploadFile,
  postNewUploadFile,
  postToGetUploadFiles,
} from '@/api/storageService';

import { FETCH_PROJECT_ID_OPTIONS } from '@/store/actions/srpOptionsAction';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import MainContent from '@/component-style/MainContent';
import PanelWrapper from '@/components/UI/PanelWrapper';
import SelectSearchable from '@/components/UI/Select/SelectSearchable';
import SearchButtonSet from '@/components/UI/SearchButtonSet';
import TableExpenseQuotations from '@/components/Table/Expense/TableExpenseQuotations';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import PopupExecuteConfirm from '@/components/UI/Popup/PopupExecuteConfirm';
import PopupAttachmentList from '@/components/UI/Popup/PopupAttachmentList';
import { ButtonBasic } from '@/component-style/Button';
import { PSubtitle } from '@/component-style/TextElement';
import {
  DivFlexRow,
  DivFullWidthWrapper,
  DivLongMultiSelector,
} from '@/component-style/RWDWrapper';

import { integerConverter } from '@/utils/textUtils';
import { formatTime } from '@/utils/timeUtils';
import { expenseQuotationConfig, expenseFormConfig } from '@/constant/dataConfig/expenseModule';
import {
  getPaymentMethod,
  normalVoucherConverter,
  personalVoucherConverter,
  addTTVoucher,
} from '@/utils/expenseUtils';
import PopupDraggable from '@/components/UI/Popup/PopupDraggable';
import PopupDraggablePdf from '@/components/UI/Popup/PopupDraggablePdf';

const DivPanelContainer = styled.div`
  margin-bottom: 40px;
`;

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding: 0;
  justify-content: space-between;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const Button = styled(ButtonBasic)`
  width: 120px;

  @media (max-width: 425px) {   margin-bottom: 10px }
`;

const ExpenseQuotations = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(state => state.login.token);
  const expenseRoutes = useSelector(state => state.login.expenseRoutes);
  const projectIDOptions = useSelector(state => state.srpOptions.projectIDOptions);
  const showSaveFailPopup = useSelector(state => state.apiFail.showSaveFailPopup);
  const apiFailPopupTitle = useSelector(state => state.apiFail.title);
  const apiFailPopupMessage = useSelector(state => state.apiFail.message);

  const [fileList, setFileList] = useState([]);
  const [uploadFiles, setUploadFiles] = useState([]);
  const [deleteFileID, setDeleteFileID] = useState('');
  const [uploadFileConfig, setUploadFileConfig] = useState({ type: '', quotationID: '' });

  const [deleteID, setDeleteID] = useState('');
  const [projectID, setProjectID] = useState('');
  const [quotations, setQuotations] = useState([]);

  // const [financeLocked, setFinanceLocked] = useState(false);
  const [alertText, setAlertText] = useState('');
  const [showAlertPopup, setShowAlertPopup] = useState(false);
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [showVouchersPopup, setShowVouchersPopup] = useState(false);
  const [showAttachmentsPopup, setShowAttachmentsPopup] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showDeleteSuccessPopup, setShowDeleteSuccessPopup] = useState(false);
  const [showUploadSuccessPopup, setShowUploadSuccessPopup] = useState(false);
  const [showDeleteFilePopup, setShowDeleteFilePopup] = useState(false);
  const [showSaveSuccessPopup, setShowSaveSuccessPopup] = useState(false);
  const [showFinanceLockedPopup ,setShowFinanceLockedPopup] = useState(false);
  const [showDeleteFileSuccessPopup, setShowDeleteFileSuccessPopup] = useState(false);

  const userAddable = expenseRoutes.indexOf('ExpenseQuotationCreation') !== -1;
  const userConvertable = expenseRoutes.indexOf('ExpenseQuotations-Export') !== -1;

  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState('');
  const [showDraggablePDFPopup, setShowDraggablePDFPopup] = useState(false);
  const [showDraggableImagePopup, setShowDraggableImagePopup] = useState(false);

  const disableConvert = quotations
    .every(quotation => quotation.isChecked === false);

  const projectIDChangeHandler = value => {
    setProjectID(value);
    setQuotations([]);
  };

  const clearHandler = () => {
    setProjectID('');
    setQuotations([]);
  };

  const checkedHandler = (index, value) => {
    const updateQuotations = [ ...quotations ];
    updateQuotations[index].isChecked = value;
    setQuotations([ ...updateQuotations ]);
  };

  const openUploadPopup = (isOpen, type = uploadFileConfig.type) => {
    type === 'quotationVoucher'
      ? setShowVouchersPopup(isOpen)
      : setShowAttachmentsPopup(isOpen);
  };

  const uploadCancelHandler = () => {
    setUploadFiles([]);
    openUploadPopup(false);
  };

  const uploadSuccessConfirmHandler = () => {
    setUploadFiles([]);
    setShowUploadSuccessPopup(false);
    openUploadPopup(true);
  };

  const apiFailConfirmHandler = () => {
    dispatch(CLOSE_SAVE_FAIL_POPUP());

    apiFailPopupTitle === '上傳失敗'
      && openUploadPopup(true);
  };

  const openDeleteFilePopupHandler = id => {
    setDeleteFileID(id);
    setShowDeleteFilePopup(true);
  };

  const deleteFileSuccessHandler = () => {
    setShowDeleteFileSuccessPopup(false);
    openUploadPopup(true);
  };

  const deleteHandler = quotationID => {
    setDeleteID(quotationID);
    setShowDeletePopup(true);
  };

  const openAlertPopup = text => {
    setAlertText(text);
    setShowAlertPopup(true);
  };

  const closeSuccessPopupHandler = () => {
    setShowSaveSuccessPopup(false);
    searchHandler();
  };

  const quotationConvertHandler = () => {
    const selectedQuotations = quotations.filter(quotation => quotation.isChecked);
    console.log("expenseQuotations.js selectedQuotations:" + JSON.stringify(selectedQuotations));

    if (selectedQuotations
          .some(quotation => !quotation.isVoucherUpload)
    ) { return openAlertPopup('有報價單或單據未上傳，請重新確認！') }

    if (selectedQuotations
          .some(({ vendorID }) => vendorID !== selectedQuotations[0].vendorID)
    ) { return openAlertPopup('一次只能結轉一間廠商，請重新確認！') }

    if (selectedQuotations
          .some(({ quotedPrice }) => integerConverter(quotedPrice) === 0)
    ) { return openAlertPopup('報價金額不可為 0，請重新確認！') }

    if (selectedQuotations
          .some(({ price, quotedPrice }) => integerConverter(price) === 0
            || integerConverter(price) > integerConverter(quotedPrice))
    ) { return openAlertPopup('議價金額不可為 0 或大於報價金額，請重新確認！') }

    postNewVendorExpense();
  };

  const postNewVendorExpense = async () => {
    setShowLoadingPopup(true);
    const selectedQuotations = quotations.filter(quotation => quotation.isChecked);
    const { vendorID, projectID } = selectedQuotations[0];

    try {
      const expenseParamsData = await postToGetExpenseParamsConfig();
      console.log("expenseQuotation.js postNewVendorExpense expenseParamsData:" + JSON.stringify(expenseParamsData));
      const expenseParams = expenseParamsData.data;
      const vendorDetail = await postToGetVendorDetail(vendorID);
      const { invoiceType, paymentMethods, transferInfo, specialProperties, taxId } = vendorDetail.data;
      console.log("expenseQuotation.js postNewVendorExpense vendorDetail:" + JSON.stringify(vendorDetail));
      console.log("expenseQuotation.js postNewVendorExpense specialProperties:" + JSON.stringify(specialProperties));
      console.log("expenseQuotation.js postNewVendorExpense transferInfo:" + JSON.stringify(transferInfo));
      const vendorConfig = {
        invoiceType,
        taxID: taxId,
        handlingFee: transferInfo.handlingFee,
        payHandlingFee: transferInfo.payHandlingFee,
        payTax: specialProperties.personalServiceDetail.payTax,
        payPremium: specialProperties.personalServiceDetail.payPremium,
        identityType: specialProperties.personalServiceDetail.identityType,
      };

      const method = getPaymentMethod(paymentMethods);
      console.log("expenseQuotation.js postNewVendorExpense vendorConfig.payHandlingFee:" + vendorConfig.payHandlingFee + " specialProperties.personalService:" + specialProperties.personalService);
      const vouchers = (vendorConfig.payHandlingFee && specialProperties.personalService === true) ? personalVoucherConverter(vendorConfig, expenseParams, selectedQuotations) :
        vendorConfig.payHandlingFee ? addTTVoucher(vendorConfig, expenseParams, selectedQuotations) :
          specialProperties.personalService === true
            ? personalVoucherConverter(vendorConfig, expenseParams, selectedQuotations)
            : normalVoucherConverter(vendorConfig, selectedQuotations);

      console.log("expenseQuotation.js postNewVendorExpense vouchers:" + JSON.stringify(vouchers));
      const price = vouchers
        .map(({ amount }) => integerConverter(amount))
        .reduce((acc, cur) => acc + cur, 0);

      await putNewExpense({
        ...expenseFormConfig,
        user,
        price,
        projectID,
        object: 'vendor',
        objectID: vendorID,
        applyDate: formatTime(Date.now(), 'YYYY-MM-DD'),
        approvalStatus: 'draft',
        payment: {
          method,
          settleDays: method === 'transfer' ? transferInfo.settleDays : '',
        },
        vouchers: vouchers.map(({ accounting, ...remainItems }) => ({
          ...remainItems,
          accounting: {
            ...accounting,
            tax: integerConverter(accounting.tax),
            amount: integerConverter(accounting.amount),
            untaxedAmount: integerConverter(accounting.untaxedAmount),
          }
        })),
      });

      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '儲存失敗', err }));
      console.log(err);
    }
  };

  const openFilesPopupHandler = async (type, quotationID) => {
    setUploadFileConfig({ type, quotationID });
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetUploadFiles(type, quotationID);
      setFileList([ ...data ]);

    } catch(err) { console.log(err) }

    setShowLoadingPopup(false);
    openUploadPopup(true, type);
  };

  const fileUploadHandler = async () => {
    const { type, quotationID } = uploadFileConfig;
    setShowLoadingPopup(true);

    type === 'quotationFiles'
      ? setShowAttachmentsPopup(false)
      : setShowVouchersPopup(false);

    try {
      for (let i = 0; i < uploadFiles.length; i += 1) {
        const file = uploadFiles[i];
        const formData = new FormData();
        formData.append('upfile', file);
        formData.append('objectType', type);
        formData.append('objectID', quotationID);
        formData.append('name', file.name);
        await postNewUploadFile(formData);
      }

      const { data } = await postToGetUploadFiles(type, quotationID);
      const updateQuotations = [...quotations];
      const index = updateQuotations
        .map(({ quotationID }) => quotationID)
        .indexOf(quotationID);

      if (type === 'quotationFiles') {
        updateQuotations[index].isFileUpload = data.length > 0;
      } else {
        updateQuotations[index].isVoucherUpload = data.length > 0;
      }

      setFileList([ ...data ]);
      setQuotations(updateQuotations);
      setShowLoadingPopup(false);
      setShowUploadSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '上傳失敗', err }));
      console.log(err);
    };
  };

  const deleteFileHandler = async () => {
    const { type, quotationID } = uploadFileConfig;
    setShowDeleteFilePopup(false);
    setShowLoadingPopup(true);
    openUploadPopup(false);

    try {
      await deleteUploadFile(deleteFileID);
      const { data } = await postToGetUploadFiles(type, quotationID);
      const updateQuotations = [...quotations];
      const index = updateQuotations
        .map(({ quotationID }) => quotationID)
        .indexOf(quotationID);
      
      if (type === 'quotationFiles') {
        updateQuotations[index].isFileUpload = data.length > 0;
      } else {
        updateQuotations[index].isVoucherUpload = data.length > 0;
      }
      
      setFileList([ ...data ]);
      setQuotations(updateQuotations);
      setShowLoadingPopup(false);
      setShowDeleteFileSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '刪除檔案失敗', err }));
      console.log(err);
    }
  };

  const deleteConfirmedHandler = async () => {
    setShowDeletePopup(false);
    setShowLoadingPopup(true);

    try {
      await deleteQuotation(deleteID);
      setShowLoadingPopup(false);
      setShowDeleteSuccessPopup(true);
    } catch(err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '刪除失敗', err }));
      console.log(err);
    }
  };

  const displayFileHandler = async fileInfo => {
    const { name, fileID, downloadURL } = fileInfo;

    if (name.match(/\.pdf$/)) {
      openUploadPopup(false);
      setShowLoadingPopup(true);
      const { data } = await getDownloadFile(fileID);
      
      setFile(data);
      setShowLoadingPopup(false);
      setShowDraggablePDFPopup(true);
    } else {
      setFileUrl(downloadURL);
      openUploadPopup(false);
      setShowDraggableImagePopup(true);
    }
  };

  const searchHandler = async () => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetQuotations({ projectID });
      // const projectDetail = await postToGetProjectDetail(projectID);
      // const { actionTimeStart, financeUnlock } = projectDetail.data;

      const quotationList = [];

      // const isAfterAction = isTimeAfter(Date.now(), actionTimeStart);
      // const isFinanceLocked = financeUnlock === undefined
      //   ? isAfterAction
      //   : isAfterAction && diffTime(financeUnlock, Date.now(), 'days') > 1;

      for (let i = 0; i < data.length; i += 1) {
        const { vendorID, quotationID } = data[i];
        if (vendorID === "01G62J0M351A1851KTAVB0DD43") {//skip this vendor id. Accountant delete it.
          continue;
        }
        var vendorDetail;
        try {
          vendorDetail = await postToGetVendorDetail(vendorID);
        } catch {
          vendorDetail.data = {"vendorName":"找不到此廠商", "financeApproved": true};
        }
        const quotationFiles = await postToGetUploadFiles('quotationFiles', quotationID);
        const quotationVoucher = await postToGetUploadFiles('quotationVoucher', quotationID);
        const { vendorName, financeApproved } = vendorDetail.data;
        const files = quotationFiles.data;
        const vouchers = quotationVoucher.data;

        quotationList.push({
          ...expenseQuotationConfig,
          ...data[i],
          financeApproved,
          vendorName: `${vendorName} ${financeApproved ? '' : '(未審核)'}`,
          isChecked: false,
          isFileUpload: files.length > 0,
          isVoucherUpload: vouchers.length > 0,
        });
      }

      // setFinanceLocked(isFinanceLocked);
      setQuotations([...quotationList]);
      // setShowFinanceLockedPopup(isFinanceLocked);
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const initOptions = useCallback(() => {
    dispatch(FETCH_PROJECT_ID_OPTIONS());
  }, [dispatch]);

  useEffect(() => {
    initOptions();
  }, [initOptions]);

  return (
    <MainContent>
      <DivPanelContainer>
        <PanelWrapper title = "廠商費用申請">
          <DivFlexRow>
            <DivFullWidthWrapper>
              <PSubtitle>專案代號</PSubtitle>
              <DivLongMultiSelector>
                <SelectSearchable
                  value = { projectID }
                  options = {[
                    { value: '', desc: '請選擇專案代號' },
                    ...projectIDOptions,
                  ]}
                  changeHandler = { projectIDChangeHandler }
                />
              </DivLongMultiSelector>
            </DivFullWidthWrapper>
          </DivFlexRow>
          <DivFlexRow>
            <DivButtonsWrapper>
              <Button
                disabled = { !userAddable }
                onClick = { () => {
                  history.push('/Expense/ExpenseQuotationCreation');
                }}
              >新增</Button>
              <SearchButtonSet
                searchable = { projectID !== '' }
                clearHandler = { clearHandler }
                searchHandler = { searchHandler }
              />
            </DivButtonsWrapper>
          </DivFlexRow>
        </PanelWrapper>
      </DivPanelContainer>
      <TableExpenseQuotations
        quotations = { quotations }
        disableConvert = { disableConvert || !userConvertable }
        checkHandler = { checkedHandler }
        deleteHandler = { deleteHandler }
        openFilesPopupHandler = { openFilesPopupHandler }
        quotationConvertHandler = { quotationConvertHandler }
      />
      { showLoadingPopup && <PopupLoading/> }
      {
        showAttachmentsPopup &&
        <PopupAttachmentList
          multiple = { true }
          displayable= { true }
          fileChangable = { true }
          fileList = { fileList }
          uploadFiles = { uploadFiles }
          fileUploadHandler = { fileUploadHandler }
          deleteFileHandler = { openDeleteFilePopupHandler }
          cancelHandler = { uploadCancelHandler }
          updateUploadFiles = { files => { setUploadFiles([ ...files ]) }}
          displayFileHandler = { displayFileHandler }
        />
      }
      {
        showVouchersPopup &&
        <PopupAttachmentList
          title = "單據管理"
          fileChangable = { true }
          displayable= { true }
          fileList = { fileList }
          uploadFiles = { uploadFiles }
          fileUploadHandler = { fileUploadHandler }
          deleteFileHandler = { openDeleteFilePopupHandler }
          cancelHandler = { () => { setShowVouchersPopup(false) }}
          updateUploadFiles = { files => { setUploadFiles([ ...files ]) }}
          displayFileHandler = { displayFileHandler }
        />
      }
      {
        showDeletePopup &&
          <PopupExecuteConfirm
            title = "刪除採購項目"
            text = { `確定採購項目` }
            confirmHandler = { deleteConfirmedHandler }
            cancelHandler = { () => {
              setShowDeletePopup(false);
            }}
          />
      }
      {
        showDeleteFilePopup &&
          <PopupExecuteConfirm
            title = "刪除檔案"
            text = "確定刪除此上傳檔案"
            confirmHandler = { deleteFileHandler }
            cancelHandler = { () => {
              setShowDeleteFilePopup(false);
            }}
          />
      }
      {
        showDeleteSuccessPopup &&
          <PopupExecuteResult
            title = "刪除成功"
            confirmHandler = { () => {
              setShowDeleteSuccessPopup(false);
              searchHandler();
            }}
          />
      }
      {
        showUploadSuccessPopup &&
          <PopupExecuteResult
            title = "上傳成功"
            confirmHandler = { uploadSuccessConfirmHandler }
          />
      }
      {
        showDeleteFileSuccessPopup &&
          <PopupExecuteResult
            title = "刪除檔案成功"
            confirmHandler = { deleteFileSuccessHandler }
          />
      }
      {
        showSaveSuccessPopup &&
          <PopupExecuteResult
            title = "結轉成功"
            confirmHandler = { closeSuccessPopupHandler }
          />
      }
      {
        showFinanceLockedPopup &&
          <PopupExecuteResult
            text = "超過支憑終止請款日，本專案已無法請款!"
            confirmHandler = { () => { setShowFinanceLockedPopup(false) } }
          />
      }
      {
        showAlertPopup &&
          <PopupExecuteResult
            title = "儲存失敗"
            text = { alertText }
            confirmHandler = { () => { setShowAlertPopup(false) } }
          />
      }
      {
        showSaveFailPopup &&
          <PopupExecuteResult
            title = { apiFailPopupTitle }
            text = { apiFailPopupMessage }
            confirmHandler = { apiFailConfirmHandler }
          />
      }
      {
        showDraggableImagePopup &&
          <PopupDraggable
            url = { fileUrl }
            cancelHandler = { () => {
              setShowDraggableImagePopup(false);
              openUploadPopup(true);
            }}
          />
      }
      {
        showDraggablePDFPopup &&
          <PopupDraggablePdf
            file = { file }
            cancelHandler = { () => {
              setShowDraggablePDFPopup(false);
              openUploadPopup(true);
            }}
          />
      }
    </MainContent>
  );
};

export default ExpenseQuotations;
