import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import TableWrapper  from '@/components/UI/TableWrapper';
import PageSelector from '@/components/UI/PageSelector';
import { Table, TableBody } from '@/component-style/Table';
import Header from './Header';
import Item from './Item';

const TablePersonnelLeaveList = props => {
  const history = useHistory();
  const financeRoutes = useSelector(state => state.login.financeRoutes);
  // const disableDetailPage = financeRoutes.indexOf('FinanceApprovalDetail') === -1;

  const { leaveDetail } = props;
  console.log(`TablePersonnelLeaveList index.js JSON.stringify(leaveDetail):${JSON.stringify(leaveDetail)}`);

  return (
    <TableWrapper title = "同仁休假列表">
      <Table>
        <Header/>
        <TableBody>
          {
            leaveDetail !== undefined && leaveDetail.map((leave, index) =>
              <Item
                key = { leave.id }
                leave = { leave }
                // disableDetailPage = { disableDetailPage }
                goDetailHandler = { (userId) => {
                  props.editDetailHandler(userId);
                }}
              />
            )
          }
        </TableBody>
      </Table>
      <PageSelector
        current = { props.currentPage }
        total = { props.totalCount }
        pageChangedHandler = { props.pageChangedHandler }
      />
    </TableWrapper>
  );
};

export default TablePersonnelLeaveList;
